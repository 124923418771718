import {  Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { ReceipientBankAccount } from "app/modules/admin/v2/operation/types/receipiente-bank-account.interface";

@Component({
    selector: "PaymentCards",
    templateUrl: "./payment-cards.component.html",
})
export class PaymentCardsComponent implements OnChanges {
    @Input() paymentCards: ReceipientBankAccount[];
    @Output() changeCardSelectEvent = new EventEmitter();

    public selectedPaymentCard: ReceipientBankAccount = {} as ReceipientBankAccount;


    ngOnChanges(): void {
        this.selectedPaymentCard = this.paymentCards.length === 1 ? this.paymentCards[0] : {} as ReceipientBankAccount;
        if (this.selectedPaymentCard) {
            this.emitChangeCardSelectEvent();
        }
    }

    selectCard(card: ReceipientBankAccount) {
        if (card.id === this.selectedPaymentCard.id) {
            this.selectedPaymentCard = {} as ReceipientBankAccount;
            this.emitChangeCardSelectEvent();
            return;
        }
        this.selectedPaymentCard = this.paymentCards.find((c) => c.id === card.id);
        this.emitChangeCardSelectEvent();
    }

    emitChangeCardSelectEvent() {
        this.changeCardSelectEvent.emit(this.selectedPaymentCard);
    }
}
