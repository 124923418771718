import { NgModule } from "@angular/core";
import { ChooseUploadFileModalComponent } from "./choose-upload-file-modal.component";
import { CommonModule } from "@angular/common";
import { ButtonCardModule } from "app/components/v2/ButtonCard/button-card.module";
import { MatIconModule } from "@angular/material/icon";
import { StandardButtonModule } from "app/components/v2/Button/button.module";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
    imports: [CommonModule, ButtonCardModule, StandardButtonModule, MatIconModule, MatTooltipModule],
    declarations: [ChooseUploadFileModalComponent],
    exports: [ChooseUploadFileModalComponent],
})
export class ChooseUploadFileModalModule {}
