import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { OperationSummaryService } from "./operation-summary.service";
import moment, { MomentInput } from "moment";
import { PayableListService } from "../PayableList/payable-list.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Payable } from "app/modules/admin/v1/operacoes/operacoes.types";
import { parse, max } from "date-fns";
import { MatDialog } from "@angular/material/dialog";
import { FundingInfoModalComponent } from "app/modules/admin/v2/operation/modals/fundingInfoModal/funding-info-modal.component";

export interface OperationData {
    amount: number;
    dueDate: Date | null;
    interestRate: number;
    deduction: number;
    netDeduction: number;
    expenses: number;
    totalDisbursement: number;
    fundingDisbursement: number;
    minibankDisbursement: number;
    profitability: number;
    hasFunding: boolean;
    totalRepurchase?: number;
    penalty?: number;
    lateFees?: number;
}

@Component({
    selector: "OperationSummary",
    templateUrl: "./operation-summary.component.html",
    styleUrls: ["./operation-summary.component.scss"],
})
export class OperationSummaryComponent implements OnInit, OnDestroy {
    @Input() risk: string;
    @Input() minibank: string;
    @Input() assignor: string;
    @Input() operationData: OperationData;
    @Input() expanded = true;
    @Output() expandHideEmitter = new EventEmitter();

    public assignorName = "";
    public minibankName = "";
    public riskName = "";
    public modeName = "";
    public payables: Partial<Payable>[] = [];

    private unsubscribeAll = new Subject();

    constructor(
        private operationSummaryService: OperationSummaryService,
        private payableListService: PayableListService,
        private dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.minibankName = this.minibank || "";
        this.assignorName = this.assignor || "";
        this.riskName = this.risk || "";

        this.operationSummaryService.currentOperationSummaryState
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((data: { type: string; value: string }) => {
                if (!data) return;

                const { type, value } = data;

                const changeParagraph = {
                    updateAssignorName: (name) => this.updateAssignorName(name),
                    updateMinibankName: (name) => this.updateMinibankName(name),
                    updateRiskName: (name) => this.updateRiskName(name),
                    updateModeName: (name) => this.updateModeName(name),
                    updateTotalRepurchase: (name) => this.updateTotalRepurchase(name),
                };
                if (changeParagraph[type]) {
                    changeParagraph[type as keyof typeof changeParagraph](value);
                }
            });

        this.operationSummaryService.currentPayableState
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((payables: Array<{ valor: number; dtVencimento: string }>) => {
                if (!payables.length) {
                    this.payables = [];
                    this.payableListService.sendCurrentPayables([]);

                    this.operationData.amount = 0;
                    this.operationData.dueDate = null;

                    return;
                }

                this.payables = payables;
                this.payableListService.sendCurrentPayables(payables);
                const totalPayablesAmount = payables.reduce((acc, payable) => Number(acc) + Number(payable.valor), 0);

                const dueDates = payables.map((p) => parse(p.dtVencimento, "dd/MM/yyyy", new Date()));
                const mostRecentDueDate = max(dueDates);

                this.operationData = {
                    ...this.operationData,
                    amount: totalPayablesAmount,
                    dueDate: mostRecentDueDate,
                };
            });
    }

    formatDate(dueDate: string): string {
        if (!moment(dueDate as MomentInput).isValid()) return dueDate;
        return moment(dueDate as MomentInput).format("DD/MM/YYYY");
    }

    updateAssignorName(value: string): void {
        this.assignorName = value;
    }

    updateMinibankName(value: string): void {
        this.minibankName = value;
    }

    updateRiskName(value: string): void {
        this.riskName = value;
    }

    updateModeName(value: string): void {
        this.riskName = value;
    }

    updateTotalRepurchase(value: number): void {
        this.operationData.totalRepurchase = value;
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(true);
        this.unsubscribeAll.complete();
    }

    openDialog() {
        this.dialog.open(FundingInfoModalComponent);
    }
}
