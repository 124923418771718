import { Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ApiService } from "../../../core/api/api.service";

@Injectable()
export class UploadModalService {
    /**
     * Constructor
     */
    constructor(private _httpClient: ApiService) {}

    fileUploaded = new BehaviorSubject<boolean | null>(null);

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    uploadFile(files: File[], operacaoId: number, url: string, observacao?: string) {
        return this._httpClient
            .makeFileRequest("crm", `/${url}/upload/${operacaoId}`, files, "files", observacao ? observacao : null)
            .pipe(
                switchMap((res) => {
                    this.fileUploaded.next(true);
                    return of(res);
                }),
            );
    }

    async uploadFileAsync(files: File[], operacaoId: number, url: string, observacao?: string) {
        return this._httpClient
            .makeFileRequest("crm", `/${url}/upload/${operacaoId}`, files, "files", observacao || null)
            .toPromise();
    }
}
