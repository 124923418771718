import { BankAccountModalComponent } from "./bank-account-modal.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NgModule } from "@angular/core";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { CommonModule } from "@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { NgxMaskModule } from "ngx-mask";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { StandardButtonModule } from "../../../../../../../components/v2/Button/button.module";

@NgModule({
    declarations: [BankAccountModalComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDialogModule,
        MatSnackBarModule,
        NgxMaskModule.forRoot(),
        StandardButtonModule,
    ],
    exports: [BankAccountModalComponent],
})
export class BankAccountModalModule {}
