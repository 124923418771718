import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";

@Component({
    selector: "FileUploadCard",
    templateUrl: "./file-upload-card.component.html",
})
export class FileUploadCardComponent implements OnInit, OnChanges {
    ngOnChanges(changes: SimpleChanges): void {
        this.file = changes.file.currentValue;
        if (this.file) {
            this.originalSizeFileInKB = (this.file.size / 1000).toFixed(2);
            this.originalSizeFileInMB = (Number(this.originalSizeFileInKB) / 1024).toFixed(2);
        }
    }
    @Input() file: File = null;
    @Output() onClick = new EventEmitter();

    public originalSizeFileInKB: string = "0";
    public originalSizeFileInMB: string = "0";

    ngOnInit(): void {
        if (this.file) {
            this.originalSizeFileInKB = (this.file.size / 1000).toFixed(2);
            this.originalSizeFileInMB = (Number(this.originalSizeFileInKB) / 1024).toFixed(2);
        }
    }

    click(file: File) {
        this.onClick.emit(file);
    }
}
