/* eslint-disable @typescript-eslint/no-unsafe-call */
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { datadogRum } from "@datadog/browser-rum";
import { AppModule } from "app/app.module";
import { environment } from "environments/environment";
import * as amplitude from "@amplitude/analytics-browser";
import { User } from "app/core/user/user.model";

const IS_PRODUCTION = environment.production;
const USER = JSON.parse(localStorage.getItem("user")) as User;
const TOKEN = localStorage.getItem("access_token");

setTimeout(() => {
    console.log("Carregando script do HubSpot...");
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "//js-na1.hs-scripts.com/20473645.js";
    document.body.appendChild(script);
}, 10000);

datadogRum.init({
    applicationId: environment.vars.datadog.applicationId,
    clientToken: environment.vars.datadog.clientToken,
    site: "us5.datadoghq.com",
    service: "front-licenciado",
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    enableExperimentalFeatures: ["clickmap", "rum-recorder"],
    env: IS_PRODUCTION ? "production" : "staging",
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackFrustrations: true,
});

datadogRum.startSessionReplayRecording();

if (IS_PRODUCTION && TOKEN && USER) {
    const identify = new amplitude.Identify();

    const { minibanco: minibank, user } = USER;
    const { razaoSocial: companyName, nomeFantasia: fantasyName, id: minibankId } = minibank;
    const { email, firstName, id: userId } = user;

    const initAmplitude = () => {
        amplitude.init(environment.vars.amplitude.apiKey, String(userId), {
            logLevel: amplitude.Types.LogLevel.Warn,
            minIdLength: 1,
            defaultTracking: {
                sessions: true,
                formInteractions: true,
            },
        });

        identify.set("name", firstName);
        identify.set("email", email);
        identify.set("minibank_id", minibankId);
        identify.set("minibank_name", fantasyName || companyName);

        amplitude.identify(identify);
    };

    initAmplitude();
    amplitude.track("View app-banqueiro-main");
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
