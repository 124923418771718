import { Observable, of, Subject } from "rxjs";
import { takeUntil, startWith, debounceTime, switchMap, map } from "rxjs/operators";
import { fuseAnimations } from "@fuse/animations";
import { IFormBuilder, IFormGroup } from "@rxweb/types";
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CreatePayerModalComponent } from "app/modules/admin/v1/payer/payer-create/payer-create-modal/payer-create-modal.component";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { FormBuilder, Validators } from "@angular/forms";
import { IPayable } from "../v2/PayableList/interfaces";
import { ClientesService } from "app/modules/admin/v1/cedente/cedente.service";
import { MatSnackBar } from "@angular/material/snack-bar";

interface Payer {
    id: number;
    document: string;
    nomeFantasia?: string;
    razaoSocial?: string;
}

@Component({
    selector: "app-edit-payer-modal",
    templateUrl: "./edit-payer-modal.component.html",
    animations: fuseAnimations,
})
export class EditPayerModalComponent implements OnInit, OnDestroy {
    private unsubscribeAll = new Subject();

    loading = false;
    disabled = false;
    formBuilder: IFormBuilder;
    selectedPayer: Payer;
    payableReference: IPayable;
    payers = [];
    filteredPayers$: Observable<any>;
    changePayerForm: IFormGroup<{ payer: string }>;

    @Output() selectedPayerEmitter = new EventEmitter<Payer & { payableId: number }>();

    constructor(
        public dialog: MatDialog,
        formBuilder: FormBuilder,
        private snack: MatSnackBar,
        private cedenteService: ClientesService,
        @Inject(MAT_DIALOG_DATA) public data: { payable: any },
    ) {
        this.formBuilder = formBuilder;
        this.payableReference = data.payable;
    }

    ngOnInit() {
        this.changePayerForm = this.formBuilder.group<{
            payer: string;
        }>({
            payer: [this.selectedPayer ? this.selectedPayer.razaoSocial : "", Validators.required],
        });
        this.watchPayerAutocomplete();
    }

    watchPayerAutocomplete() {
        this.cedenteService.sacados$.subscribe((res) => {
            this.payers = res;
        });

        this.filteredPayers$ = this.changePayerForm.get("payer").valueChanges.pipe(
            takeUntil(this.unsubscribeAll),
            startWith(""),
            debounceTime(300),
            switchMap((value) => {
                if (value !== "") {
                    return this.cedenteService
                        .searchSacados(this.cleanValueString(value).toLowerCase())
                        .pipe(map((result) => result.data));
                } else {
                    return of(this.payers);
                }
            }),
        );
    }

    cleanValueString(value: number | string): string {
        return String(value).replaceAll(/[-./]/g, "");
    }

    displayName(value: Payer): string {
        return value && value.razaoSocial;
    }

    getPayer() {
        return this.changePayerForm.get("payer").value;
    }

    selectPayer() {
        this.loading = true;

        if (this.changePayerForm.invalid) {
            this.loading = false;

            this.snack.open("Preencha todos os campos corretamente !", "X", {
                duration: 3000,
            });

            return;
        }

        this.selectedPayerEmitter.emit({ ...this.selectedPayer, payableId: this.payableReference.id });
    }

    newPayer() {
        const addPayerDialog = this.dialog.open(CreatePayerModalComponent, {
            width: "100vw",
            height: "80vh",
            id: "dialog-new-payer",
        });

        addPayerDialog.componentInstance.cancelEmitter.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
            addPayerDialog.close();
        });

        addPayerDialog.componentInstance.afterSaveEmitter.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
            addPayerDialog.close();
        });

        addPayerDialog.componentInstance.createdPayerEmitter
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((createdPayer) => {
                this.changePayerForm.controls.payer.setValue(createdPayer);
                this.selectedPayer = createdPayer;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    onPayerSelected(event: MatAutocompleteSelectedEvent) {
        const selectedPayer = event.option.value;
        this.selectedPayer = selectedPayer;
    }

    onBlurPayerInput() {
        const selectedPayer = this.changePayerForm.controls.payer.value;

        if (typeof selectedPayer === "string") {
            this.changePayerForm.controls.payer.setValue(null);
        }
    }
}
