import { NgModule } from "@angular/core";
import { ButtonCardComponent } from "./button-card.component";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [MatIconModule, CommonModule],
    declarations: [ButtonCardComponent],
    exports: [ButtonCardComponent],
})
export class ButtonCardModule {}
