/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, finalize, switchMap } from "rxjs/operators";
import { ApiUtils } from "./api.utils";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class ApiService {
    private environment: string;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        this.environment = environment.environment;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    get(service: string, endpoint: string, options?: any): Observable<any> {
        const url = ApiUtils.build(service, endpoint);
        return this._httpClient.get(url, options).pipe(
            catchError(ApiUtils.handleError),
            switchMap((response: any) => of(response)),
            finalize(() => {}),
        );
    }

    post(service: string, endpoint: string, body?: any, headers?: any): Observable<any> {
        const url = ApiUtils.build(service, endpoint);

        return this._httpClient.post(url, body, headers).pipe(
            catchError(ApiUtils.handleError),
            switchMap((response: any) => of(response)),
            finalize(() => {}),
        );
    }

    put(service: string, endpoint: string, body: any): Observable<any> {
        const url = ApiUtils.build(service, endpoint);

        return this._httpClient.put(url, body).pipe(
            catchError(ApiUtils.handleError),
            switchMap((response: any) => of(response)),
            finalize(() => {}),
        );
    }

    patch(service: string, endpoint: string, body: any): Observable<any> {
        const url = ApiUtils.build(service, endpoint);

        return this._httpClient.patch(url, body).pipe(
            catchError(ApiUtils.handleError),
            switchMap((response: any) => of(response)),
            finalize(() => {}),
        );
    }

    makeFileRequest(
        service: string,
        endpoint: string,
        files: File[],
        formDataName: string,
        observation?: string,
        params?: unknown[],
    ): Observable<unknown> {
        const url = ApiUtils.build(service, endpoint);

        const formData: FormData = new FormData();

        for (const file of files) {
            formData.append(formDataName, file, file.name);
        }

        observation && formData.append("observacao", observation);

        params?.map((element) => {
            const keys = Object.keys(element);
            const values = Object.values(element);
            keys.map((key, i) => {
                if (key !== "files") {
                    formData.append(key, String(values[i]));
                }
            });
        });

        const req = new HttpRequest("POST", url, formData);

        return this._httpClient.request(req).pipe(
            catchError(ApiUtils.handleError),
            switchMap((response: any) => of(response)),
            finalize(() => {}),
        );
    }

    delete(service: string, endpoint: string, options?: any): Observable<any> {
        const url = ApiUtils.build(service, endpoint);
        return this._httpClient.delete(url, options).pipe(
            catchError(ApiUtils.handleError),
            switchMap((response: any) => of(response)),
            finalize(() => {}),
        );
    }
}
