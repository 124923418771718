/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { AuthUtils } from "app/core/auth/auth.utils";
import { UserService } from "app/core/user/user.service";
import { IClaims, RolesInterface } from "app/modules/admin/v1/usuarios/perfil/perfil.types";
import { Observable, of, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import parseJwt from "../../utils/parseJWT";
import { ApiService } from "../api/api.service";
import { MinibankTypes, User } from "../user/user.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { datadogRum } from "@datadog/browser-rum";
import { growthbook } from "app/shared/growthbook";

import { CookieService } from "ngx-cookie-service";
import { FetchService } from "../api/fetch.service";
import { FuseAlertType } from "@fuse/components/alert";

export interface LoginResponse {
    aceitouTermos: boolean;
    accessToken: string;
    permissoes: RolesInterface;
    permissions: Array<string>;
    roles: Array<string>;
}

@Injectable()
export class AuthService {
    public token: string;
    public user: User;
    _authenticated: boolean = false;
    private _aceitouTermos: boolean = false;

    public get aceitouTermos(): boolean {
        return this._aceitouTermos;
    }
    public set aceitouTermos(value: boolean) {
        this._aceitouTermos = value;
    }

    alert: { type: FuseAlertType; message: string } = {
        type: "success",
        message: "",
    };

    constructor(
        private _httpClient: HttpClient,
        private fetchService: FetchService,
        private _userService: UserService,
        private _apiService: ApiService,
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _cookieService: CookieService,
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        this._cookieService.set("bankme.access_token", token);
        localStorage.setItem("access_token", token);
    }

    get accessToken(): string {
        if (localStorage?.getItem("access_token") === "undefined") {
            this.signOut().subscribe();
        }
        return localStorage?.getItem("access_token") ?? "";
    }

    /**
     * Setter & getter for permission
     */

    set claims(data: IClaims) {
        const { permissions, roles } = data;
        localStorage.setItem("permissions", JSON.stringify(permissions));
        localStorage.setItem("roles", JSON.stringify(roles));
    }

    get claims(): IClaims {
        if (localStorage.getItem("permissions") === undefined || localStorage.getItem("roles") === undefined) {
            this.signOut().subscribe();
        }
        const permissions = JSON.parse(localStorage.getItem("permissions")) ?? "";
        const roles = JSON.parse(localStorage.getItem("roles")) ?? "";

        return {
            permissions,
            roles,
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._apiService.post("crm", "/auth/esqueceu-senha", { email });
    }

    async handleGetAuthUserToMinibankLink({
        minibankId,
        userId,
        authorizationToken,
    }: {
        minibankId: number;
        userId: number;
        authorizationToken: string;
    }): Promise<any> {
        return this.fetchService.get("bff", `/auth/minibank/${minibankId}/user/${userId}`, {
            Authorization: `Bearer ${authorizationToken}`,
        });
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string, userId?: number): Observable<any> {
        return this._httpClient.post(
            `${environment.vars.apis.crm}/auth/resetar-senha${userId ? "/" + String(userId) : ""}`,
            {
                newPassword: password,
            },
        );
    }

    async signIn(credentials: { email: string; password: string }): Promise<LoginResponse | boolean> {
        // eslint-disable-next-line rxjs/no-ignored-observable
        if (this._authenticated) throwError(() => Error("User is already logged in."));

        const response = await this.fetchService.post("crm", "/auth/login", credentials);

        if ([400, 401, 403].includes(response.statusCode)) {
            return response;
        }

        const parsedJWT = parseJwt(response?.accessToken);

        if (parsedJWT.user.isAdmin) {
            this._authenticated = false;
            return false;
        }

        this.accessToken = response?.accessToken;

        this.claims = { permissions: response?.permissions, roles: response?.roles };

        this._authenticated = true;

        this._userService.user = parsedJWT;

        if (response?.aceitouTermos) {
            this.aceitouTermos = true;
        }

        datadogRum.setUser({
            id: String(parsedJWT.user.id),
            email: parsedJWT.user.email,
            name: parsedJWT.user?.firstName + " " + parsedJWT.user?.lastName,
            minibank: parsedJWT.minibanco.nomeFantasia,
        });

        growthbook.setAttributes({
            id: parsedJWT.user.id,
            loggedIn: true,
            country: window.navigator.language,
            browser: window.navigator.userAgent,
            url: window.location.href,
            minibankId: parsedJWT.minibanco.id,
        });

        return response;
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        return this._httpClient
            .post(`${environment.vars.apis.crm}/auth/refresh-token`, {
                access_token: this.accessToken,
            })
            .pipe(
                catchError(() => {
                    this._authenticated = false;
                    return of(false);
                }),
                switchMap((response: { accessToken: string }) => {
                    this.accessToken = response.accessToken;
                    this._authenticated = true;
                    const parsedJWT = parseJwt(response.accessToken);
                    this._userService.user = parsedJWT;

                    datadogRum.setUser({
                        id: String(parsedJWT.user.id),
                        email: parsedJWT.user.email,
                        name: parsedJWT.user?.firstName + " " + parsedJWT.user?.lastName,
                        minibank: parsedJWT.minibanco.nomeFantasia,
                    });

                    growthbook.setAttributes({
                        id: parsedJWT.user.id,
                        loggedIn: true,
                        country: window.navigator.language,
                        browser: window.navigator.userAgent,
                        url: window.location.href,
                        minibankId: parsedJWT.minibanco.id,
                    });

                    return of(true);
                }),
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        localStorage.clear();
        // Set the authenticated flag to false
        this._authenticated = false;

        this._router.navigateByUrl("/sign-in");

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post("api/auth/sign-up", user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post("api/auth/unlock-session", credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    acceptTermos() {
        this._apiService.post("crm", "/auth/aceitar-termos", {}).subscribe();
    }

    rejectTermos() {
        this.signOut().subscribe();
        location.reload();
    }

    canActivate(routeAc: ActivatedRouteSnapshot): boolean {
        this.token = localStorage.getItem("access_token");
        this.user = JSON.parse(localStorage.getItem("user"));
        this.user.permissions = this.claims.permissions;
        this.user.roles = this.claims.roles;

        if (!this.token) {
            this._router.navigate(["/login"]);
            return false;
        }

        if (this.token && routeAc.url.toString() === "login") {
            this._router.navigate(["/"]);
            return false;
        }

        if (this.token && AuthUtils.isTokenExpired(this.token)) {
            this._snackBar.open("Seu token expirou, faça login novamente", "Fechar", {
                duration: 2000,
            });
            this.signOut().toPromise();
        }

        if (
            routeAc.url.toString() === "" ||
            routeAc.url.toString() === "home" ||
            routeAc.url.toString() === "usuario/minha-conta"
        ) {
            return true;
        }

        return true;
    }

    checkClaims(claims: Array<string>): boolean {
        const permissions = JSON.parse(localStorage.getItem("permissions")) as Array<string>;

        return claims.every((claim: string) => permissions.includes(claim));
    }

    public showItem(claims: Array<string>) {
        return this.checkClaims(claims);
    }

    private get minibankType(): MinibankTypes {
        return this.user?.minibanco?.tipo;
    }

    public get isStarter(): boolean {
        return this.minibankType === MinibankTypes.STARTER;
    }
}
