<button
    type="button"
    class="w-full p-4 flex gap-4 rounded-md items-center transition-all duration-300"
    [disabled]="disabled"
    (click)="click()"
    [ngClass]="{
        'bg-gray-200 hover:bg-gray-300 cursor-pointer': !disabled,
        'bg-gray-100 text-gray-500 cursor-not-allowed': disabled,
        'bg-blue-600 hover:bg-blue-700 text-white': checked
    }"
>
    <div *ngIf="icon">
        <mat-icon
            class="icon-size-8 transition-colors duration-300"
            [ngClass]="{
                'text-black': !disabled,
                'text-gray-500': disabled,
                'text-white': checked
            }"
            [svgIcon]="'heroicons_outline:' + icon"
        ></mat-icon>
    </div>
    <div class="flex flex-col justify-center items-start gap-2">
        <span *ngIf="title" class="leading-tight text-xl font-bold text-left">{{ title }}</span>
        <span *ngIf="subtitle" class="leading-tight text-lg text-left font-semibold">{{ subtitle }}</span>
    </div>
</button>
