<div class="fuse-vertical-navigation-wrapper font-16">
    <!-- Header -->
    <div class="fuse-vertical-navigation-header">
        <ng-content select="[fuseVerticalNavigationHeader]"></ng-content>
    </div>

    <!-- Content -->
    <div
        class="fuse-vertical-navigation-content"
        fuseScrollbar
        [fuseScrollbarOptions]="{ wheelPropagation: inner, suppressScrollX: true }"
        #navigationContent
    >
        <!-- Content header -->
        <div class="fuse-vertical-navigation-content-header">
            <ng-content select="[fuseVerticalNavigationContentHeader]"></ng-content>
        </div>

        <div class="py-4 px-8 rounded-sm bg-gray-200 mb-4">
            <span class="flex flex-col leading-none">
                <span class="font-bold">{{
                    user.minibanco?.nomeFantasia ? user.minibanco?.nomeFantasia : user.minibanco?.razaoSocial
                }}</span>
                <span class="mt-1.5 text-md font-medium">{{ user?.user?.email }}</span>
            </span>
        </div>

        <!-- Items -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
                <!-- Aside -->
                <ng-container *ngIf="item.type === 'aside'">
                    <fuse-vertical-navigation-aside-item
                        [item]="item"
                        [name]="name"
                        [activeItemId]="activeAsideItemId"
                        [autoCollapse]="autoCollapse"
                        [skipChildren]="true"
                        (click)="toggleAside(item)"
                        class="font-16"
                    ></fuse-vertical-navigation-aside-item>
                </ng-container>

                <!-- Basic -->
                <ng-container *ngIf="item.type === 'basic'">
                    <fuse-vertical-navigation-basic-item
                        [item]="item"
                        [name]="name"
                        class="font-16"
                    ></fuse-vertical-navigation-basic-item>
                </ng-container>

                <!-- Collapsable -->
                <ng-container *ngIf="item.type === 'collapsable'">
                    <fuse-vertical-navigation-collapsable-item
                        [item]="item"
                        [name]="name"
                        [autoCollapse]="autoCollapse"
                        class="font-16"
                    ></fuse-vertical-navigation-collapsable-item>
                </ng-container>

                <!-- Divider -->
                <ng-container *ngIf="item.type === 'divider'">
                    <fuse-vertical-navigation-divider-item
                        [item]="item"
                        [name]="name"
                    ></fuse-vertical-navigation-divider-item>
                </ng-container>

                <!-- Group -->
                <ng-container *ngIf="item.type === 'group'">
                    <fuse-vertical-navigation-group-item
                        [item]="item"
                        [name]="name"
                        [autoCollapse]="autoCollapse"
                    ></fuse-vertical-navigation-group-item>
                </ng-container>

                <!-- Spacer -->
                <ng-container *ngIf="item.type === 'spacer'">
                    <fuse-vertical-navigation-spacer-item
                        [item]="item"
                        [name]="name"
                    ></fuse-vertical-navigation-spacer-item>
                </ng-container>
            </ng-container>
        </ng-container>

        <!-- Beamer & User menu -->
        <div class="flex flex-col px-6 mt-8 gap-4">
            <app-platform-updates iconSize="sm"></app-platform-updates>
            <user-menu [isExpandable]="true"></user-menu>
        </div>

        <!-- Content footer -->
        <div class="fuse-vertical-navigation-content-footer">
            <ng-content select="[fuseVerticalNavigationContentFooter]"></ng-content>
        </div>
    </div>

    <!-- Footer -->
    <div class="fuse-vertical-navigation-footer">
        <ng-content select="[fuseVerticalNavigationFooter]"></ng-content>
    </div>
</div>

<!-- Aside -->
<ng-container *ngIf="activeAsideItemId">
    <div
        class="fuse-vertical-navigation-aside-wrapper"
        fuseScrollbar
        [fuseScrollbarOptions]="{ wheelPropagation: false, suppressScrollX: true }"
        [@fadeInLeft]="position === 'left'"
        [@fadeInRight]="position === 'right'"
        [@fadeOutLeft]="position === 'left'"
        [@fadeOutRight]="position === 'right'"
    >
        <!-- Items -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
                <!-- Aside -->
                <ng-container *ngIf="item.type === 'aside' && item.id === activeAsideItemId">
                    <fuse-vertical-navigation-aside-item
                        [item]="item"
                        [name]="name"
                        [autoCollapse]="autoCollapse"
                        class="font-16"
                    ></fuse-vertical-navigation-aside-item>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
