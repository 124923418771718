export interface IOperationTypes {
    type: string;
    id: string;
    attributes: {
        acronym: string;
        description: string;
    };
}

export enum FileType {
    XML = "XML",
    XLSX = "XLSX",
}
