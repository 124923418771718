import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "app-confirm-close-modal",
    templateUrl: "./funding-info-modal.html",
})
export class FundingInfoModalComponent {
    constructor(public dialogRef: MatDialogRef<FundingInfoModalComponent>) {}

    close(value: string) {
        this.dialogRef.close(value);
    }
}
