import { Injectable } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { AuthService } from "app/core/auth/auth.service";
import { navigation } from "app/navigations/navigation";
import { filter } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class HasPermission {
    constructor(private _authService: AuthService, private _router: Router) {}

    check(): void {
        const permissions = this._authService.claims.permissions;

        this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            const token = localStorage.getItem("access_token");

            const navigationPermissions = navigation
                .map((nav) => {
                    return {
                        ...nav,
                        link: nav.link instanceof Function ? nav.link(token) : nav.link,
                    };
                })
                .find((nav) => nav.link === this._router.url);

            if (!navigationPermissions || !navigationPermissions.permissions) return;

            const hasPermission = navigationPermissions.permissions.every((claim: string) =>
                permissions.includes(claim),
            );

            if (!hasPermission) {
                const newNavigation = navigation
                    .map((nav) => {
                        return {
                            ...nav,
                            link: nav.link instanceof Function ? nav.link(token) : nav.link,
                        };
                    })
                    .filter((nav) => nav.permissions.every((claim: string) => permissions.includes(claim)));

                this._router.navigateByUrl(newNavigation[0].link);
            }
        });
    }
}
