import { Component, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { OperationViewService } from "app/modules/admin/v1/operacoes/operation-view/operation-view.service";
import { ClientesService } from "../../../modules/admin/v1/cedente/cedente.service";

import { TitulosLoadingSnackBarComponent } from "../titulos-loading-snack-bar/titulos-loading-snack-bar.component";
import { ModalData } from "./interfaces/modal-data.interface";
import { UploadModalService } from "./upload-modal.service";
import { MAX_FILE_SIZE } from "app/utils/constants/file-size.constant";
import { FileValidator } from "app/shared/services/file-validator/file-validator.service";

@Component({
    selector: "upload-modal-component",
    templateUrl: "./upload-modal.component.html",
    styleUrls: ["./upload-modal.component.scss"],
})
export class UploadModalComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ModalData,
        private uploadModalService: UploadModalService,
        private snackBar: MatSnackBar,
        private assignorService: ClientesService,
        private operationView: OperationViewService,
        private readonly fileValidator: FileValidator,
    ) {}

    @Input() isOuterModule: boolean = false;

    public files: File[] = [];
    public description: string;
    public maxFileSize = MAX_FILE_SIZE;

    onSelect(event) {
        if (!this.fileValidator.validateEvent(event)) return;

        this.files.push(...event.addedFiles);
    }

    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
    }

    openSnackBar(message: string, status?: "success" | "error") {
        let extraClass = "";
        if (status === "success") {
            extraClass = "snack-bar-success";
        } else if (status == "error") {
            extraClass = "snack-bar-error";
        }

        this.snackBar.open(message, "x", {
            panelClass: extraClass,
            duration: 3000,
        });
    }

    postFile(description?: string) {
        this.snackBar.openFromComponent(TitulosLoadingSnackBarComponent, {
            duration: 3000,
        });
        this.uploadModalService.uploadFile(this.files, this.data.id, this.data.uploadUrl, description).subscribe(
            () => {
                this.openSnackBar("Arquivos enviados com sucesso!", "success");
                if (this.data.uploadUrl == "operacao") this.operationView.setWillUpdate();
                if (this.data.uploadUrl == "cedente") this.assignorService.getCliente(this.data.id).subscribe();
            },
            () => {
                this.openSnackBar("Ocorreu um erro ao enviar os arquivos", "error");
            },
        );
    }
}
