import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NewCTEDueDateService } from "./new-cte-due-date.service";

@Component({
    selector: "NewCTEDueDate",
    templateUrl: "./new-cte-due-date.component.html",
})
export class NewCTEDueDateComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { payableId: number },
        private readonly snack: MatSnackBar,
        private readonly formBuilder: FormBuilder,
        private readonly dueDateService: NewCTEDueDateService,
    ) {
        this.payableId = data.payableId;
    }

    public dueDateform: FormGroup;
    public payableId: number;
    minDate = new Date();

    @Output() cteUpdatedEmitter = new EventEmitter<{ id: number; dtVencimento: string }>();
    @Output() closeEmitter = new EventEmitter<void>();

    ngOnInit(): void {
        this.dueDateform = this.formBuilder.group({
            dueDate: ["", [Validators.required]],
        });
    }

    async updateCteDueDate() {
        try {
            const res = await this.dueDateService.updatePayableDueDate({
                date: this.dueDateform.value.dueDate,
                payableId: this.payableId,
            });

            if (res.dtVencimento) {
                this.snack.open("Vencimento alterado com sucesso", "Fechar", {
                    duration: 3000,
                    panelClass: ["snack-bar-success"],
                });

                const { id, dtVencimento }: { id: number; dtVencimento: string } = res;
                const standardDate: string = dtVencimento.split("T")[0].split("-").reverse().join("/");

                this.cteUpdatedEmitter.emit({ dtVencimento: standardDate, id });
            }
        } catch {
            this.snack.open("Erro ao definir o vencimento", "Fechar", {
                duration: 3000,
                panelClass: ["snack-bar-error"],
            });
        }
    }

    cancel() {
        this.closeEmitter.emit();
    }
}
