<div [formGroup]="createPayableForm">
    <h2 class="text-2xl font-bold">Criar Recebível</h2>

    <div class="">
        <mat-stepper #stepper>
            <!-- Step 1 -->
            <mat-step>
                <div class="w-full flex flex-col md:flex-row items-center mt-1">
                    <div class="md:w-1/2 w-full">
                        <mat-form-field class="w-full" appearance="fill">
                            <mat-label>Sacado</mat-label>
                            <input
                                (blur)="onBlurPayerInput()"
                                #sacado
                                matInput
                                formControlName="sacado"
                                [matAutocomplete]="autoSacado"
                                placeholder="Sacado"
                                type="text"
                                required
                                [value]="selectedPayer"
                            />

                            <mat-autocomplete
                                (optionSelected)="onPayerSelected($event)"
                                autoActiveFirstOption
                                #autoSacado="matAutocomplete"
                                [displayWith]="displayName"
                            >
                                <mat-option *ngFor="let payer of filteredPayers$ | async" [value]="payer">
                                    {{ payer.razaoSocial }} - {{ payer.document | mask : "CPF_CNPJ" }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="md:w-1/2 px-5 items-center">
                        <button class="text-sm" mat-stroked-button color="secondary" (click)="newPayer()">
                            <mat-icon svgIcon="heroicons_solid:document-add"></mat-icon>
                            Cadastrar Novo Sacado
                        </button>
                    </div>
                </div>

                <ngx-dropzone
                    (change)="onSelect($event)"
                    class="w-full lg:h-80 my-4 drag-n-drop dark:bg-gray-600 justify-center bg-primary-50"
                    style="border: 1px dashed lightgrey; overflow: hidden"
                >
                    <ngx-dropzone-label>
                        <p class="font-bold">Arraste e solte ou selecione os arquivos</p>
                        <p class="text-[#64748B]">São aceitos apenas arquivos JPEG, JPG e PNG</p>
                    </ngx-dropzone-label>

                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>

                <div class="flex justify-end gap-5">
                    <button mat-dialog-close>Cancelar</button>
                    <button
                        (click)="uploadCMC7()"
                        mat-button
                        type="button"
                        [disabled]="loading"
                        class="py-3 px-4 font-bold text-white rounded-full"
                        [ngStyle]="{ backgroundColor: '#004BCC' }"
                    >
                        {{ loading ? "Carregando..." : "Ler Arquivos" }}
                    </button>
                </div>
            </mat-step>

            <!-- Step 2 -->
            <mat-step>
                <mat-form-field class="w-full" appearance="fill">
                    <mat-label>Sacado</mat-label>
                    <input
                        #sacado
                        matInput
                        [disabled]="true"
                        readonly
                        formControlName="sacado"
                        placeholder="Sacado"
                        type="text"
                        required
                        [value]="selectedPayer"
                    />
                </mat-form-field>

                <div class="w-full mt-4 mb-6 border-b"></div>

                <div formArrayName="parcelas">
                    <form
                        *ngFor="let parcelas of installmentsFormArray.controls; index as i"
                        @expandCollapse
                        class="animated-box w-full flex flex-col md:flex-row gap-x-4 relative mt-1"
                        [formGroupName]="i"
                    >
                        <div class="relative flex gap-2">
                            <div class="text-gray-600 mt-9 -left-4 absolute">{{ i + 1 }}°</div>

                            <mat-form-field class="animated-box-content w-full" appearance="fill">
                                <mat-label>Valor</mat-label>
                                <input
                                    type="text"
                                    inputmode="numeric"
                                    formControlName="valorDaParcela"
                                    maxlength="15"
                                    matInput
                                    currencyFormatter
                                    currencyMask
                                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                />
                            </mat-form-field>
                        </div>

                        <mat-form-field appearance="fill" [ngClass]="{ 'w-full': !hasCmc7 }">
                            <mat-label>Vencimento</mat-label>
                            <input
                                matInput
                                inputmode="numeric"
                                formControlName="vencimento"
                                [min]="minDate"
                                [matDatepicker]="picker"
                            />
                            <mat-hint class="hidden">DD/MM/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="fill" floatLabel="always">
                            <mat-label>Número do título</mat-label>
                            <input matInput placeholder="NFE0000" formControlName="payableNumber" />
                        </mat-form-field>

                        <div class="flex w-full gap-2">
                            <mat-form-field appearance="fill" floatLabel="always" class="flex-1 min-w-75">
                                <mat-label>CMC7</mat-label>
                                <input
                                    matInput
                                    inputmode="numeric"
                                    placeholder="XXXXXXXX XXXXXXXXXX XXXXXXXXXXXX"
                                    formControlName="cmc7"
                                    mask="00000000 0000000000 000000000000"
                                />
                                <mat-error
                                    *ngIf="
                                        installmentForm?.controls.cmc7.dirty &&
                                        installmentForm?.controls.cmc7.errors?.cmc7
                                    "
                                >
                                    CMC7 inválido
                                </mat-error>
                            </mat-form-field>

                            <div class="w-14 flex items-center justify-center gap-2 -mt-0">
                                <mat-icon
                                    *ngIf="installmentsFormArray.controls.length > 1"
                                    svgIcon="heroicons_outline:trash"
                                    class="text-gray-600 cursor-pointer"
                                    (click)="deleteInstallment(i)"
                                ></mat-icon>

                                <mat-icon
                                    *ngIf="installmentsFormArray.controls.length === i + 1"
                                    svgIcon="heroicons_outline:plus-circle"
                                    class="text-gray-600 cursor-pointer"
                                    (click)="addInstallment()"
                                >
                                </mat-icon>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="flex justify-between items-center">
                    <div class="flex items-center">
                        <p class="text-xl mr-4">TOTAL:</p>
                        <p class="text-2xl font-bold">{{ total | currency : "BRL" }}</p>
                    </div>

                    <div class="flex justify-end gap-5">
                        <button mat-dialog-close>Cancelar</button>
                        <button matStepperPrevious>Voltar</button>
                        <button
                            (click)="createPayable()"
                            type="button"
                            class="py-3 px-4 font-bold text-white rounded-full"
                            [ngStyle]="{ backgroundColor: '#004BCC' }"
                        >
                            Criar recebível
                        </button>
                    </div>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>
