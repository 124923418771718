export enum OperationStatusEnum {
    NEW_OPERATION = "NEW_OPERATION",
    FECHADO = "FECHADO",
    ANAL_CRED = "ANAL-CRED",
    CONSTR = "CONSTR",
    CONCL = "CONCL",
    CANC = "CANC",
    AG_APROV_CED = "AG-APROV-CED",
    AG_APROV_CLI = "AG-APROV-CLI",
    AG_ASS_CED = "AG-ASS-CED",
    APROV = "APROV",
    SUGERIDO = "SUGERIDO",
    SUGERIDO_CED = "SUGERIDO-CED",
    AG_DOC = "AG-DOC",
    AG_APROV_SAC = "AG-APROV-SAC",
    AG_PGTO = "AG-PGTO",
}

export enum PayableType {
    DUPLICATA = "DUPLICATA",
    CHEQUE = "CHEQUE",
    NOTA_PROMISSORIA = "NOTA_PROMISSORIA",
}
