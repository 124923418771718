<div class="m-0 lg:m-4">
    <h2 mat-dialog-title class="font-bold text-3xl mb-4">Operações aguardando aprovação</h2>
    <div mat-dialog-content>
        <ng-container *ngFor="let operacao of operacoesToAprovar">
            <!-- CARD -->
            <div
                *ngIf="operacao.status.sigla === 'AG-APROV-CLI'"
                class="bg-card p-5 rounded-xl shadow-md text-lg leading-relaxed my-4 lg:flex lg:flex-row lg:items-center lg:justify-between lg:min-w-90 max-w-full"
            >
                <div class="flex flex-col md:flex-row items-center md:justify-between w-full">
                    <!-- left part -->
                    <div class="mb-6 text-lg lg:flex lg:flex-row lg:items-center lg:gap-x-8 lg:mb-0 lg:w-full truncate">
                        <div class="flex flex-row justify-between md:max-w-6/12 truncate">
                            <p class="truncate lg:text-xl lg:text-secondary w-full">
                                {{
                                    operacao.cedente.nomeFantasia
                                        ? operacao.cedente.nomeFantasia
                                        : operacao.cedente.razaoSocial
                                }}
                            </p>
                        </div>

                        <p class="font-extrabold text-lg lg:text-xl text-center">
                            {{ operacao.valor | currency : "BRL" : "symbol" : "1.2-2" }}
                        </p>
                    </div>
                    <!-- right part -->
                    <div class="flex flex-row gap-x-4 justify-end pl-4">
                        <button
                            class="font-bold"
                            mat-raised-button
                            [color]="'primary'"
                            (click)="closeDialog()"
                            [routerLink]="'/operacoes/view/' + operacao.operacaoId"
                        >
                            Detalhes
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div mat-dialog-actions class="flex gap-x-4 justify-end mt-8">
        <button (click)="closeDialog()" class="font-bold text-secondary" routerLink="/operacoes">
            Todas as operações
        </button>
        <button
            class="font-bold hover:bg-green-500"
            mat-raised-button
            [color]="'primary'"
            style="background-color: #1dc979"
            (click)="closeDialog()"
        >
            Continuar para painel
        </button>
    </div>
</div>
