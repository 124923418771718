<form
    [formGroup]="createPayerForm"
    class="w-full flex flex-col md:grid md:grid-cols-2 md:gap-4 bg-card py-4 px-5 sm:px-9 md:px-11 lg:px-12 xl:px-16"
>
    <h2 class="col-span-2 font-bold text-lg">Dados Cadastrais</h2>
    <!-- Documento -->
    <div class="w-full">
        <mat-form-field class="w-full">
            <mat-label>Documento</mat-label>
            <input
                #documentPayer
                matInput
                formControlName="document"
                type="text"
                placeholder="CNPJ / CPF"
                mask="CPF_CNPJ"
                required
                (change)="onDocumentChange()"
            />
            <mat-spinner
                *ngIf="isFetchingDocument"
                matSuffix
                color="primary"
                diameter="20"
                mode="indeterminate"
            ></mat-spinner>
            <mat-error *ngIf="createPayerForm.get('document')?.invalid">
                {{ getFormErrorMessage(createPayerForm.controls.document) }}
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Razão Social / Nome -->
    <div class="w-full">
        <mat-form-field class="w-full">
            <mat-label>Razão Social / Nome</mat-label>
            <input
                #payerName
                matInput
                formControlName="companyName"
                type="text"
                placeholder="Razão Social / Nome"
                required
            />
            <mat-error *ngIf="createPayerForm.controls.companyName.invalid">
                {{ getFormErrorMessage(createPayerForm.controls.companyName) }}
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Telefone -->
    <div class="w-full">
        <mat-form-field class="w-full">
            <mat-label>Telefone</mat-label>
            <input
                matInput
                formControlName="phone"
                type="text"
                placeholder="Telefone"
                mask="(00) 0000-0000 || (00) 0 0000-0000"
                required
            />
            <mat-error *ngIf="createPayerForm.controls.phone.invalid">
                {{ getFormErrorMessage(createPayerForm.controls.phone) }}
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Email -->
    <div class="w-full h-full flex flex-col">
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput type="text" placeholder="Email" formControlName="email" />
            <mat-error *ngIf="createPayerForm.controls.email.invalid">
                {{ getFormErrorMessage(createPayerForm.controls.email) }}
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Status -->
    <div class="w-full h-full flex flex-col">
        <mat-label>Status</mat-label>
        <mat-slide-toggle formControlName="isDisabled" color="primary" class="py-4">
            {{ createPayerForm.controls.isDisabled.value ? "Ativo" : "Inativo" }}
        </mat-slide-toggle>
    </div>

    <hr class="col-span-2 my-8" />
    <div class="relative h-2 col-span-2">
        <mat-progress-bar mode="indeterminate" *ngIf="loadingAddress"></mat-progress-bar>
    </div>
    <h2 class="col-span-2 font-bold text-lg">Endereço</h2>

    <!-- CEP -->
    <div class="w-full">
        <mat-form-field class="w-full">
            <mat-label>CEP</mat-label>
            <input
                matInput
                formControlName="zipCode"
                type="text"
                placeholder="CEP"
                mask="00.000-000"
                (keyup)="getAddressByZipCode(createPayerForm.value.zipCode)"
                required
            />
            <mat-error *ngIf="createPayerForm.controls.zipCode.invalid">
                {{ getFormErrorMessage(createPayerForm.controls.zipCode) }}
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Logradouro -->
    <div class="w-full">
        <mat-form-field class="w-full">
            <mat-label>Logradouro</mat-label>
            <input matInput formControlName="street" type="text" placeholder="Rua / Logradouro" required />
            <mat-error *ngIf="createPayerForm.controls.street.invalid">
                {{ getFormErrorMessage(createPayerForm.controls.street) }}
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Número -->
    <div class="w-full">
        <mat-form-field class="w-full">
            <mat-label>Número</mat-label>
            <input matInput formControlName="number" type="text" placeholder="Número" required />
            <mat-error *ngIf="createPayerForm.controls.number.invalid">
                {{ getFormErrorMessage(createPayerForm.controls.number) }}
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Complemento -->
    <div class="w-full">
        <mat-form-field class="w-full">
            <mat-label>Complemento</mat-label>
            <input matInput formControlName="complement" type="text" placeholder="Complemento" />
            <mat-error *ngIf="createPayerForm.controls.complement.invalid">
                {{
                    createPayerForm.controls.complement.errors
                        ? "O campo complemento deve ter no maximo " + limit_complement + " caracteres"
                        : ""
                }}
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Bairro -->
    <div class="w-full">
        <mat-form-field class="w-full">
            <mat-label>Bairro</mat-label>
            <input matInput formControlName="neighborhood" type="text" placeholder="Bairro" required />
            <mat-error *ngIf="createPayerForm.controls.neighborhood.invalid">
                {{ getFormErrorMessage(createPayerForm.controls.neighborhood) }}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="w-full flex flex-row align-bottom gap-4">
        <!-- Cidade -->
        <div class="w-full">
            <mat-form-field class="w-full">
                <mat-label>Cidade</mat-label>
                <input matInput formControlName="city" type="text" placeholder="Cidade" required />
                <mat-error *ngIf="createPayerForm.controls.city.invalid">
                    {{ getFormErrorMessage(createPayerForm.controls.city) }}
                </mat-error>
            </mat-form-field>
        </div>

        <!-- Estado -->
        <div class="w-20">
            <mat-form-field class="w-full">
                <mat-label>UF</mat-label>
                <mat-select formControlName="state" #selectState required>
                    <mat-option *ngFor="let state of allBrazilStates" [value]="state">{{ state }}</mat-option>
                </mat-select>
                <mat-error *ngIf="createPayerForm.controls.state.invalid">
                    {{ getFormErrorMessage(createPayerForm.controls.state) }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</form>
<div class="flex justify-end gap-8 py-4 px-5 pb-16 sm:px-9 md:px-11 lg:px-12 xl:px-16">
    <Button
        label="Cancelar"
        (clickEvent)="emitCancel()"
        color="light"
        type="solid"
        [hideText]="false"
        customClass="min-w-32 flex justify-center"
    ></Button>

    <Button
        label="Salvar"
        (clickEvent)="savePayer(createPayerForm)"
        color="primary"
        type="solid"
        [hideText]="false"
        [heroIcon]="loadingSubmit ? 'clock' : ''"
        [disabled]="!createPayerForm.valid || loadingSubmit"
        customClass="min-w-32 flex justify-center"
    ></Button>
</div>
