import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { InitialData } from "app/app.types";
import { navigation } from "./navigations/navigation";
import { Resolve } from "@angular/router";
import { HasPermission } from "./utils/auth/auth";

@Injectable({
    providedIn: "root",
})
export class InitialDataResolver implements Resolve<InitialData> {
    constructor(private _hasPermissions: HasPermission) {}

    resolve(): Observable<InitialData> {
        const permissions = JSON.parse(localStorage.getItem("permissions")) as Array<string>;
        const token = localStorage.getItem("access_token");

        const navigationFiltred = navigation
            .filter((nav) => nav.permissions.every((claim: string) => permissions.includes(claim)))
            .map((nav) => {
                return {
                    ...nav,
                    link: nav.link instanceof Function ? nav.link(token) : nav.link,
                };
            });

        this._hasPermissions.check();

        return of({
            navigation: {
                compact: navigationFiltred,
                default: navigationFiltred,
                futuristic: navigationFiltred,
                horizontal: navigationFiltred,
            },
        });
    }
}
