import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { IPayable } from "./interfaces/index";
import { ApiService } from "app/core/api/api.service";

@Injectable()
export class PayableListService {
    private payableState = new BehaviorSubject([]);
    public mustUpdateDisbursement = new Subject();
    public currentPayableState = this.payableState.asObservable();

    constructor(private readonly http: ApiService) {}

    sendCurrentPayables(value: Partial<IPayable>[]): void {
        this.payableState.next(value);
    }

    getCurrentPayables() {
        return this.payableState.getValue();
    }

    updatePayableValue({ payable, value }: { payable: IPayable; value: string | number }) {
        const numberValue = typeof value === "number" ? value : Number(value.replace(",", ".").replace("R$ ", ""));

        return this.http
            .put("crm", `/recebivel/${payable.id}`, {
                valor: numberValue,
            })
            .toPromise();
    }

    updatePayablePayer({ payableId, payerId }: { payableId: number; payerId: number }) {
        return this.http
            .put("crm", `/recebivel/${payableId}`, {
                sacadoId: payerId,
            })
            .toPromise();
    }
}
