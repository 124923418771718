import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../../../shared/shared.module";

import { AguardandoAprovacaoModalComponent } from "./aguardando-aprovacao-modal.component";

@NgModule({
    imports: [SharedModule, MatButtonModule, RouterModule, MatDialogModule],
    exports: [AguardandoAprovacaoModalComponent],
    declarations: [AguardandoAprovacaoModalComponent],
    providers: [
        {
            provide: MatDialogRef,
            useValue: {},
        },
    ],
})
export class AguardandoAprovacaoModalModule {}
