<div class="w-full flex flex-row items-center justify-between p-2 border-2 border-gray-400 rounded-md" *ngIf="file">
    <div class="flex flex-col gap-1">
        <span class="text-md">{{ file.name }}</span>
        <div class="flex gap-x-1">
            <span class="text-gray-400 text-base font-normal">
                {{ originalSizeFileInKB }}KB / {{ originalSizeFileInMB }}MB
            </span>
            <span class="text-gray-400 text-base font-normal">-</span>
            <span class="text-green-600">Concluído</span>
        </div>
    </div>

    <div class="flex justify-between items-center gap-x-5">
        <div>
            <span class="text-base">100%</span>
        </div>
        <div class="items-center flex">
            <mat-icon class="icon-size-5 text-green-700" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
        </div>
        <mat-icon
            class="icon-size-5 text-gray-400 cursor-pointer"
            svgIcon="heroicons_outline:trash"
            (click)="click(file)"
        ></mat-icon>
    </div>
</div>
