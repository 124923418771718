import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "CheckboxCard",
    templateUrl: "./checkbox-card.component.html",
})
export class CheckboxCardComponent {
    @Input() title?: string;
    @Input() subtitle?: string;
    @Input() icon?: string;
    @Input() disabled?: boolean = false;
    @Input() checked?: boolean = false;

    @Output() changeValue: EventEmitter<boolean> = new EventEmitter();

    click() {
        if (this.disabled) return;
        this.checked = !this.checked;
        this.changeValue.emit(this.checked);
    }
}
