import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { RepurchasePayablesComponent } from "./repurchase-payables.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgxMaskModule } from "ngx-mask";
import { NgxCurrencyModule } from "ngx-currency";
import { MatDialogModule } from "@angular/material/dialog";
import { RepurchaseFilterModalComponent } from "app/modules/admin/v2/operation/create/components/repurchase-payables-modal/filter-modal/repurchase-filter-modal.component";
import { MatSelectModule } from "@angular/material/select";
import { StandardButtonModule } from "app/components/v2/Button/button.module";

@NgModule({
    imports: [
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        CommonModule,
        MatTableModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        NgxMaskModule.forRoot(),
        NgxCurrencyModule,
        MatDialogModule,
        MatSelectModule,
        StandardButtonModule,
    ],
    declarations: [RepurchasePayablesComponent, RepurchaseFilterModalComponent],
    exports: [RepurchasePayablesComponent],
})
export class RepurchasePayablesModule {}
