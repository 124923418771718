import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { SharedModule } from "app/shared/shared.module";
import { PlatformUpdatesComponent } from "./platform-updates.component";
import { IsStarterModule } from "app/directives/is-starter/is-starter.module";

@NgModule({
    declarations: [PlatformUpdatesComponent],
    imports: [CommonModule, SharedModule, MatIconModule, IsStarterModule],
    exports: [PlatformUpdatesComponent],
})
export class PlatformUpdatesModule {}
