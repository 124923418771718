import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { NgxMaskModule } from "ngx-mask";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CreatePayablesModalService } from "../v1/create-payables-modal/create-payables-modal.service";
import { EditPayerModalComponent } from "./edit-payer-modal.component";

@NgModule({
    declarations: [EditPayerModalComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatIconModule,
        NgxMaskModule.forRoot(),
        MatProgressSpinnerModule,
    ],
    providers: [CreatePayablesModalService],
    exports: [EditPayerModalComponent],
})
export class EditPayerModalModule {}
