<a
    href="javascript:void(0)"
    *ngIf="!disabled"
    mat-flat-button
    (click)="clickAction()"
    (auxclick)="secondaryClickAction($event)"
    [ngClass]="paddingStyle"
    [class]="
        buttonStyle +
        'mat-focus-indicator font-bold hover:opacity-90 rounded-full flex flex-row gap-1 p-2 items-center ' +
        customClass
    "
>
    <mat-icon *ngIf="matIcon" [ngClass]="type === 'solid' ? 'text-white' : 'text-' + buttonColor">
        {{ matIcon }}
    </mat-icon>
    <mat-icon
        *ngIf="heroIcon"
        [ngClass]="type === 'solid' ? 'text-white' : 'text-' + buttonColor"
        [svgIcon]="'heroicons_outline:' + heroIcon"
    ></mat-icon>
    <span
        *ngIf="label"
        class="whitespace-nowrap px-2"
        [ngClass]="{
            'font-semibold': type === 'outline',
            'hidden md:inline-block': hideText
        }"
    >
        {{ label }}
    </span>
</a>

<button
    *ngIf="disabled"
    disabled
    [class]="'cursor-not-allowed rounded-full flex flex-row gap-1 p-2 items-center bg-gray-300 ' + customClass"
>
    <mat-icon *ngIf="matIcon">{{ matIcon }}</mat-icon>
    <mat-icon *ngIf="heroIcon" [svgIcon]="'heroicons_outline:' + heroIcon"></mat-icon>
    <span
        *ngIf="label"
        class="whitespace-nowrap px-2"
        [ngClass]="{
            'font-semibold': type === 'outline',
            'hidden md:inline-block': hideText
        }"
    >
        {{ label }}
    </span>
</button>
