export function calculateCompoundInterest({
    value,
    percentage,
    days,
}: {
    value: number;
    percentage: number;
    days: number;
}) {
    const dailyTax = Math.pow(1 + percentage, 1 / 30) - 1;
    const diff = value * Math.pow(1 + dailyTax, days) - value;

    return Number(diff.toFixed(2));
}
