import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule } from "@angular/router";
import { RoleModule } from "app/directives/role/role.module";
import { UserMenuComponent } from "app/layout/common/user-menu/user-menu.component";
import { SharedModule } from "app/shared/shared.module";

@NgModule({
    declarations: [UserMenuComponent],
    imports: [RouterModule, MatButtonModule, MatDividerModule, MatIconModule, MatMenuModule, SharedModule, RoleModule],
    exports: [UserMenuComponent],
})
export class UserMenuModule {}
