import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { TitulosLoadingSnackBarComponent } from "./titulos-loading-snack-bar.component";

@NgModule({
    imports: [MatProgressSpinnerModule],
    exports: [TitulosLoadingSnackBarComponent],
    declarations: [TitulosLoadingSnackBarComponent],
    providers: [],
})
export class TitulosLoadingSnackBarModule {}
