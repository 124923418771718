import { NgModule } from "@angular/core";
import { OperationSummaryComponent } from "./operation-summary.component";
import { ParagraphModule } from "../Texts/Paragraph/paragraph.module";
import { PayableListModule } from "../PayableList/payable-list.module";
import { OperationSummaryService } from "./operation-summary.service";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { FundingInfoModalComponent } from "app/modules/admin/v2/operation/modals/fundingInfoModal/funding-info-modal.component";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
    declarations: [OperationSummaryComponent, FundingInfoModalComponent],
    imports: [ParagraphModule, PayableListModule, MatIconModule, CommonModule, MatDialogModule],
    exports: [OperationSummaryComponent],
    providers: [OperationSummaryService],
})
export class OperationSummaryModule {}
