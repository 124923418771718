<div class="custom-dialog-container w-full">
    <h1 mat-dialog-title class="text-2xl font-bold">Selecione o Sacado</h1>

    <mat-dialog-content class="mat-typograph" fuseScrollbar>
        <div class="flex">
            <form [formGroup]="changePayerForm" class="w-full relative">
                <div class="w-full flex flex-col items-center p-2 gap-4">
                    <div class="w-full">
                        <mat-form-field class="w-full" appearance="fill">
                            <mat-label>Sacado</mat-label>
                            <input
                                #payer
                                matInput
                                formControlName="payer"
                                [matAutocomplete]="autoSacado"
                                placeholder="Sacado"
                                type="text"
                                required
                                [value]="selectedPayer?.nomeFantasia || selectedPayer?.razaoSocial || ''"
                            />

                            <mat-autocomplete
                                (optionSelected)="onPayerSelected($event)"
                                autoActiveFirstOption
                                #autoSacado="matAutocomplete"
                                [displayWith]="displayName"
                            >
                                <mat-option *ngFor="let payer of filteredPayers$ | async" [value]="payer">
                                    {{ payer.razaoSocial }} - {{ payer.document | mask : "CPF_CNPJ" }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div>
                        <h2 class="text-xl font-bold">ou</h2>
                    </div>
                    <div class="w-full p-2">
                        <button
                            class="text-sm border border-gray-300 bg-white shadow-none rounded-lg w-full flex items-center justify-center text-base font-normal leading-6 tracking-wide h-12"
                            mat-stroked-button
                            color="secondary"
                            (click)="newPayer()"
                        >
                            <mat-icon svgIcon="heroicons_solid:document-add" class="mr-2"></mat-icon>
                            Cadastrar Novo Sacado
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <div class="w-full flex md:flex-row flex-col items-center justify-end mb-3 mt-2">
            <div>
                <button mat-button mat-dialog-close>Cancelar</button>
                <button (click)="selectPayer()" mat-button class="bg-primary text-white">
                    <mat-spinner *ngIf="loading" strokeWidth="2" diameter="20"></mat-spinner>
                    <span *ngIf="!loading">Selecionar Sacado</span>
                </button>
            </div>
        </div>
    </mat-dialog-actions>
</div>
