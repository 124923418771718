export const adjustDateToEndOfDay = (inputDate: string): string => {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/.test(inputDate)) {
        return inputDate;
    }

    const datePart = inputDate.split("T")[0];
    const endDate = `${datePart}T23:59:59.000Z`;

    return endDate;
};
