import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { LOCALE_ID, NgModule } from "@angular/core";

@NgModule({
    providers: [
        {
            provide: MAT_DATE_LOCALE,
            useValue: "pt-BR",
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MAT_MOMENT_DATE_FORMATS,
        },
        {
            provide: LOCALE_ID,
            useValue: "pt-BR",
        },
    ],
})
export class DateLocaleModule {}
