/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";
import { IFormBuilder, IFormGroup } from "@rxweb/types";
import { CreatePayablesModalComponent } from "app/components/v1/create-payables-modal/create-payables-modal.component";
import { PayablesFromDatabaseModalComponent } from "app/components/v1/payables-from-database-modal/payables-from-database-modal.component";
import { UploadModalService } from "app/components/v1/upload-modal/upload-modal.service";
import { EnviarNotasModalComponent } from "app/components/v1/xml-modal/envio-notas-modal.component";
import { EnvioNotasModalService } from "app/components/v1/xml-modal/envio-notas-modal.service";
import { OperationData } from "app/components/v2/OperationSummary/operation-summary.component";
import { OperationSummaryService } from "app/components/v2/OperationSummary/operation-summary.service";
import { PayableListService } from "app/components/v2/PayableList/payable-list.service";
import { AuthService } from "app/core/auth/auth.service";
import { UserService } from "app/core/user/user.service";
import { CalculoService } from "app/modules/admin/v1/calcula-me/calculator.service";
import { ClientesService } from "app/modules/admin/v1/cedente/cedente.service";
import { Cedente } from "app/modules/admin/v1/cedente/interfaces/cedente.types";
import { AskWithoutPaymentSlipModalComponent } from "app/modules/admin/v1/operacoes/components/ask-without-payment-slip-modal/ask-without-payment-slip-modal.component";
import { IPayable } from "app/modules/admin/v1/operacoes/interfaces/payables.interface";
import { OperacoesService } from "app/modules/admin/v1/operacoes/operacoes.service";
import { DATE_FORMAT_REGEX } from "app/shared/constants/date-format-regex.constant";
import { OperationStatusEnum, PayableType } from "app/shared/constants/operation.enum";
import { EnumTypeDocument } from "app/shared/enums/type-document.enum";
import { FileValidator } from "app/shared/services/file-validator/file-validator.service";
import { adjustDateToEndOfDay } from "app/utils/adjust-date-to-end-of-day";
import { MAX_FILE_SIZE } from "app/utils/constants/file-size.constant";
import { compareAsc, parse } from "date-fns";
import { environment } from "environments/environment";
import { NgxDropzoneChangeEvent } from "ngx-dropzone";
import { Observable, of, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { Operacao, OperacaoMode, Payable, TermsData } from "../../../v1/operacoes/operacoes.types";
import { BANK_SERVICE_TYPES, PAYABLE_TYPE_LIST } from "../constants";
import { FileType, IOperationTypes } from "../interfaces";
import { IOutputPayableError } from "../interfaces/output-payable-error.interface";
import messages from "../messages";
import { ChooseUploadFileModalComponent } from "../modals/chooseUploadFileModal/choose-upload-file-modal.component";
import { ConfirmTypePayableModalComponent } from "../modals/confirmTypePayableModal/confirm-type-payable-modal";
import { UploadBankCheckModalComponent } from "../modals/uploadBankCheckModal/upload-bank-check-modal.component";
import { IDataAnticipation } from "../../../v1/calcula-me/types";
import { FeesEnum } from "../types/fees.enum";
import { BasicInformation, FeesForm, FundingForm, PaymentForm } from "../types/form-types.interface";
import { IMinibankFees } from "../types/minibank-fees.interface";
import { ReceipientBankAccount } from "../types/receipiente-bank-account.interface";
import { BankAccountModalComponent } from "./components/bank-account-modal/bank-account-modal.component";
import {
    UnoperatedPayablesResponse,
    UnoperatedPayablesSelection,
} from "./components/repurchase-payables-modal/interfaces/unoperated-payables";
import { CreateOperationService } from "./create-operation.service";
import { RepurchasePayableService } from "app/modules/admin/v2/operation/create/service/repurchase-payable.service";
import { BANKME_ERP_ID } from "app/modules/admin/v2/operation/constants/index";

@Component({
    selector: "app-create-operation",
    templateUrl: "./create-operation.component.html",
    styleUrls: ["./create-operation.component.scss"],
})
export class CreateOperationComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() progressValue = 0;
    @ViewChild("stepper") stepper: MatStepper;

    private FELIPE_OHANIAN_MINIBANK_ID = 143;
    private formBuilder: IFormBuilder;
    private unsubscribeAll = new Subject();
    private typePayableCreateAll = "DUPLICATA";
    private payableTypeSelected: PayableType;
    public hasConfirmedOffRisk = false;
    public rediscountTypeId = "10";
    public totalSteps: number;
    public currentStep = 0;
    public isRediscountOperation = false;

    public basicInfoForm: IFormGroup<BasicInformation>;
    public feesForm: IFormGroup<FeesForm>;
    public fundingForm: IFormGroup<FundingForm>;
    public paymentForm: IFormGroup<PaymentForm>;

    public disableFeesEditing: boolean;
    public filteredAssignors$: Observable<Cedente[]>;
    public filteredOperationModes$: Observable<OperacaoMode[]>;
    public shouldAnalyzePayerCredit = false;
    public shouldCheckPayable = false;
    public minibankPredefinedFees?: IMinibankFees;
    public files: File[] = [];
    public assignors: Cedente[];
    public operationModes: OperacaoMode[];
    public isLoading = false;
    public payables: Partial<Payable>[] = [];
    public statusSigla: OperationStatusEnum;
    public payablesIds: number[] = [];
    public quantitySteps = 6;
    public totalPercentageSteps = 100;
    public payablesAmount = 0;
    public operationTypes: IOperationTypes[];
    public bankServicesValueTooltip = messages.TOOLTIP_BANK_SERVICES_VALUE;
    public bankServiceTypes = BANK_SERVICE_TYPES;
    public bankServiceTypeSelected: string;
    public assignorName = "";
    public minibankName = "";
    public payableTypeList = PAYABLE_TYPE_LIST;
    public disablePayableType: boolean = false;
    public enableCreatePayableAll: boolean = false;
    public isSummaryExpanded = true;
    public inconsistencies: IOutputPayableError;
    public maxFileSize = MAX_FILE_SIZE;
    public isNextStepDisabled = false;
    public fundingData = {
        limit: 0,
        rate: 0,
        id: null,
    };
    public accountBalance = 0;
    public operationData: OperationData = {
        amount: 0,
        dueDate: null,
        interestRate: 0,
        deduction: 0,
        netDeduction: 0,
        expenses: 0,
        totalDisbursement: 0,
        fundingDisbursement: 0,
        minibankDisbursement: 0,
        profitability: 0,
        hasFunding: false,
        totalRepurchase: 0,
    };
    public totalDisbursement: number;
    public oldFundingFormState: FundingForm;

    public hasConfirmedFundingTerms = false;
    public termsData: TermsData = {
        acceptedAt: "",
        userIp: "",
        userAgent: "",
    };

    public paymentCards: ReceipientBankAccount[] = [];
    public bankType: string = "";
    public selectedPayableType = false;
    protected hasCheckCustody: boolean = false;
    protected shouldEmitPaymentSlip: boolean = true;
    public emitPaymentSlip: IFormGroup<{ hasEmit: string }>;

    public payablesSum: IPayable[] = [];
    public payablesToAnalyze: number[] = [];
    public allPayersSelected = false;

    public isOperationValid: boolean = true;
    public repurchasePayables: Partial<Payable>[] = [];
    public allUnoperatedPayablesResponse: UnoperatedPayablesResponse;
    public selectionUnoperatedPayables: UnoperatedPayablesSelection = {} as UnoperatedPayablesSelection;
    public selectedUnoperatedPayables = [];

    public showUnoperatedPayables = false;
    public totalRepurchase: number = 0;
    isShow: boolean = false;
    public showEmit = true;
    public isHighPerformance: boolean;

    constructor(
        private readonly cedenteService: ClientesService,
        private readonly operationService: OperacoesService,
        private readonly createOperationService: CreateOperationService,
        private readonly uploadModalService: UploadModalService,
        private readonly envioNotasModalService: EnvioNotasModalService,
        private readonly payableListService: PayableListService,
        private readonly userService: UserService,
        private readonly operationSummaryService: OperationSummaryService,
        private readonly authService: AuthService,
        private readonly fileValidator: FileValidator,
        private readonly repurchasePayableService: RepurchasePayableService,
        private snackBar: MatSnackBar,
        private router: Router,
        public dialog: MatDialog,
        public _formBuilder: FormBuilder,
        private readonly calculatorService: CalculoService,
    ) {
        this.formBuilder = _formBuilder;
    }

    async ngOnInit() {
        this.minibankName = this.userService.user$.minibanco?.razaoSocial;
        this.bankType = this.userService.user$.minibanco?.tipo ?? "";

        this.initForms();
        this.watchAssignorAutocomplete();
        this.filterOperationMode();
        this.getOperationTypes();

        this.bankServiceTypeChange(FeesEnum.INVOICES);

        this.payableListService.mustUpdateDisbursement.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
            this.calculateDisbursement();
        });

        if (this.userService.user$.minibanco.id === this.FELIPE_OHANIAN_MINIBANK_ID) {
            this.payableTypeList = [
                ...this.payableTypeList,
                {
                    label: "Nota comercial",
                    value: "DUPLICATA",
                },
            ];
        }

        this.basicInfoForm.valueChanges.subscribe((value) => {
            if (value.typeOperation === this.rediscountTypeId) {
                this.isRediscountOperation = true;
                this.payableTypeList = this.payableTypeList.filter((payable) => payable?.value !== "NOTA_PROMISSORIA");
            }
        });

        this.envioNotasModalService.getPayables$.subscribe((payables) => {
            if (!payables.length) {
                this.payablesSum = [];
                this.payablesAmount = 0;
                this.operationSummaryService.updateCurrentPayables([]);
                return;
            }

            this.payables = payables;
            this.payablesSum = payables.reduce((acc, entry) => {
                const payerId = entry.sacado.id;
                const existingEntry = acc.find((e) => e.sacado.id === payerId);
                existingEntry ? (existingEntry.valor += entry.valor) : acc.push({ ...entry });
                return acc;
            }, []);

            this.payablesAmount = payables.reduce((acc, curr) => Number(acc) + Number(curr.valor), 0);
            this.operationSummaryService.updateCurrentPayables(this.payables);
            this.operationSummaryService.updateCurrentAmount(this.payablesAmount);

            const latestDueDate = this.payables.reduce((last, current) => {
                const lastDate = parse(last.dtVencimento as string, "dd/MM/yyyy", new Date());
                const currentDate = parse(current.dtVencimento as string, "dd/MM/yyyy", new Date());
                return compareAsc(lastDate, currentDate) === 1 ? last : current;
            });

            this.operationData = {
                ...this.operationData,
                amount: this.payablesAmount,
                dueDate: parse(latestDueDate.dtVencimento as string, "dd/MM/yyyy", new Date()),
            };

            this.setInitialDisbursementValues();
        });

        this.accountBalance = await this.createOperationService.getAccountBalance();
        const fundingData = await this.createOperationService.getFundingAmount();

        if (fundingData?.amount) {
            this.fundingData = {
                limit: fundingData.amount,
                rate: fundingData.fundingInterestRate,
                id: fundingData.fundingId,
            };

            this.operationData = {
                ...this.operationData,
                hasFunding: true,
            };
            this.fundingForm.get("accountBalance").setValidators(Validators.max(this.accountBalance));
            this.fundingForm.get("fundingUsed").setValidators(Validators.max(fundingData.amount));
        }

        this.operationSummaryService.totalRepurchaseState.subscribe((totalRepurchase) => {
            this.operationData.totalRepurchase = totalRepurchase;
        });

        this.isHighPerformance = this.checkOperationForHighPerformance(this.userService.user$.minibanco);
    }

    checkOperationForHighPerformance(data): boolean {
        const typeName = String(data?.tipoNome || "");
        return typeName.toLowerCase().includes("alta performance");
    }

    async setInitialDisbursementValues() {
        const hasSelectedPayables = this.operationData.amount > 0;

        await this.calculateDisbursement();

        if (hasSelectedPayables) {
            this.calculateFundingDisbursement();
        }
    }

    calculateFundingDisbursement() {
        const moneyLeft = this.totalDisbursement - this.accountBalance;

        if (moneyLeft > 0) {
            this.fundingForm.get("accountBalance").setValue(this.accountBalance);
            this.fundingForm.get("fundingUsed").setValue(moneyLeft);
            const accountBalanceRate = this.accountBalance / this.totalDisbursement;
            this.fundingForm.get("accountBalanceRate").setValue(accountBalanceRate * 100);
            this.fundingForm.get("fundingRate").setValue((1 - accountBalanceRate) * 100);

            return;
        }

        this.fundingForm.get("accountBalance").setValue(this.totalDisbursement);
        this.fundingForm.get("accountBalanceRate").setValue(100);
        this.fundingForm.get("fundingUsed").setValue(0);
        this.fundingForm.get("fundingRate").setValue(0);
    }

    public onStepChange() {
        this.updateProgress();
    }

    public previousProgressValue() {
        if (this.currentStep === 0) {
            return window.history.back();
        }

        this.currentStep -= 1;
        this.stepper.previous();
    }

    public isStarter(): boolean {
        return this.authService.isStarter;
    }

    public taxesForStarter(): void {
        if (this.isStarter && this.feesForm) {
            this.feesForm.get("feePerMonth").setValue(0);
        }
    }

    public skipStepForStarter(): boolean {
        return this.isStarter();
    }

    public isLastStep(): boolean {
        if (!this.stepper) return false;

        return this.stepper.selectedIndex === this.stepper.steps.length - 1;
    }

    private maxPercentageValidator(control: FormControl): { [key: string]: { value: number } } | null {
        const { value } = control;

        if (typeof value === "number") {
            if (value > 100) {
                return { maxPercentage: { value: value } };
            }

            return null;
        }
    }

    initForms() {
        this.basicInfoForm = this.formBuilder.group<BasicInformation>({
            assignor: [null, Validators.required],
            mode: [null, Validators.required],
            typeOperation: [null, Validators.required],
        });

        this.feesForm = this.formBuilder.group<FeesForm>({
            feePerMonth: [
                { value: 0, disabled: this.disableFeesEditing },
                [Validators.required, this.maxPercentageValidator, Validators.min(0.01), Validators.max(100.0)],
            ],
            tac: [{ value: 0, disabled: this.disableFeesEditing }, Validators.min(0)],
            ted: [{ value: 0, disabled: this.disableFeesEditing }, Validators.min(0)],
            digitalSignatureCost: [{ value: 0, disabled: this.disableFeesEditing }, Validators.min(0)],
            creditAnalysis: [{ value: 0, disabled: this.disableFeesEditing }, Validators.min(0)],
            pix: [{ value: 0, disabled: this.disableFeesEditing }, Validators.min(0)],
            bankServiceType: [FeesEnum.INVOICES],
            invoices: [{ value: 0, disabled: this.disableFeesEditing }, Validators.min(0)],
            checkCustody: [{ value: 0, disabled: this.disableFeesEditing }, Validators.min(0)],
            description: null,
            payableType: [null, Validators.required],
        });

        this.fundingForm = this.formBuilder.group<FundingForm>({
            accountBalance: [{ value: null, disabled: true }, Validators.required],
            accountBalanceRate: [null, [Validators.required, Validators.max(100), Validators.min(0)]],
            fundingUsed: [null, Validators.required],
            fundingRate: [null, [Validators.required, Validators.max(100), Validators.min(0)]],
        });

        this.paymentForm = this.formBuilder.group<PaymentForm>({
            paymentType: [this.bankType !== "STANDARD" ? "PIX" : null, Validators.required],
            recipientId: [null, Validators.required],
        });

        this.emitPaymentSlip = this.formBuilder.group<{ hasEmit: string }>({
            hasEmit: ["true"],
        });

        this.emitPaymentSlip.controls.hasEmit.valueChanges.subscribe((value) => {
            const hasEmit = value === "true";

            this.shouldEmitPaymentSlip = this.feesForm.controls.payableType.value !== PayableType.CHEQUE && hasEmit;
            this.hasCheckCustody = this.feesForm.controls.payableType.value === PayableType.CHEQUE && hasEmit;
            if (!this.shouldEmitPaymentSlip && this.feesForm.controls.payableType.value !== PayableType.CHEQUE) {
                this.openDialogAskWithoutPaymentSlip();
            }
        });

        this.basicInfoForm.controls.assignor.valueChanges.subscribe(() => {
            const assignor = this.basicInfoForm.get("assignor").value;
            this.assignorName = assignor ? assignor.razaoSocial || assignor.nomeFantasia : "";
            this.operationSummaryService.updateAssignor(this.assignorName);

            if (!assignor || !this.assignorName) return;

            this.repurchasePayableService.setAssignorId = assignor.id;
            this.repurchasePayableService
                .listUnoperatedPayables(this.basicInfoForm.get("assignor").value.id, {
                    page: 1,
                    take: 10,
                })
                .then((res: UnoperatedPayablesResponse) => {
                    this.allUnoperatedPayablesResponse = res;
                    this.showUnoperatedPayables = res.data.length > 0;
                    this.repurchasePayableService.setUnoperatedPayables = res;
                });

            this.createOperationService
                .getAssignorBankAccounts(assignor.id)
                .then((response: ReceipientBankAccount[]) => {
                    const activeAccounts = response
                        .filter((card) => !card.deletedAt)
                        .map((card) => {
                            return {
                                ...card,
                                accountHolder: card.accountHolder || this.assignorName,
                                document: card.document || assignor.cnpj,
                            };
                        });

                    this.paymentCards = activeAccounts;

                    if (activeAccounts.length === 1) {
                        this.selectCardBankAccount(activeAccounts[0]);
                    }
                });
        });
        this.operationSummaryService.updateMinibank(this.userService.user$.minibanco?.razaoSocial);

        this.feesForm.controls.feePerMonth.valueChanges.subscribe((value) => {
            this.operationSummaryService.updateTaxRate(String(value));
        });

        this.feesForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            const expense = this.getTotalExpenses();

            this.operationSummaryService.updateExpense(expense);

            this.setInitialDisbursementValues();

            this.operationSummaryService.currentOperationSummaryState;

            this.operationData = {
                ...this.operationData,
                expenses: expense,
                interestRate: Number(String(this.feesForm.controls.feePerMonth.value)) / 100,
            };
        });

        this.fundingForm.controls.accountBalance.valueChanges.subscribe((value) => {
            this.operationData = {
                ...this.operationData,
                minibankDisbursement: value,
            };
        });

        this.fundingForm.valueChanges.subscribe((value) => {
            this.isNextStepDisabled = false;
            const { accountBalanceRate, fundingRate, fundingUsed } = value;

            const isAccountBalanceRateChanged = this.oldFundingFormState?.accountBalanceRate !== accountBalanceRate;
            const isFundingRateChanged = this.oldFundingFormState?.fundingRate !== fundingRate;
            const isFundingUsedChanged = this.oldFundingFormState?.fundingUsed !== fundingUsed;

            this.oldFundingFormState = value;

            if (!isAccountBalanceRateChanged && !isFundingRateChanged && !isFundingUsedChanged) {
                return;
            }

            if (isAccountBalanceRateChanged) {
                const minibankDisbursement = (this.totalDisbursement * accountBalanceRate) / 100;
                const fundingDisbursement = (this.totalDisbursement * (100 - accountBalanceRate)) / 100;

                this.fundingForm.get("fundingRate").setValue(100 - accountBalanceRate);
                this.fundingForm.get("fundingUsed").setValue(fundingDisbursement);
                this.fundingForm.get("accountBalance").setValue(minibankDisbursement);

                this.operationData = {
                    ...this.operationData,
                    minibankDisbursement,
                    fundingDisbursement,
                };
            }

            if (isFundingRateChanged) {
                const minibankDisbursement = (this.totalDisbursement * (100 - fundingRate)) / 100;
                const fundingDisbursement = (this.totalDisbursement * fundingRate) / 100;

                this.fundingForm.get("accountBalanceRate").setValue(100 - fundingRate);
                this.fundingForm.get("accountBalance").setValue(minibankDisbursement);
                this.fundingForm.get("fundingUsed").setValue(fundingDisbursement);

                this.operationData = {
                    ...this.operationData,
                    fundingDisbursement,
                    minibankDisbursement,
                };
            }

            if (isFundingUsedChanged) {
                const accountBalance = this.totalDisbursement - (value.fundingUsed || 0);
                const accountBalanceRate = (accountBalance * 100) / this.totalDisbursement;
                const fundingRate = 100 - accountBalanceRate;

                this.fundingForm.get("accountBalance").setValue(accountBalance);
                this.fundingForm.get("accountBalanceRate").setValue(accountBalanceRate);
                this.fundingForm.get("fundingRate").setValue(fundingRate);

                this.operationData = {
                    ...this.operationData,
                    fundingDisbursement: value.fundingUsed,
                    minibankDisbursement: accountBalance,
                };
            }

            if (this.operationData.hasFunding && this.fundingForm.invalid && this.stepper.selectedIndex === 3) {
                this.isNextStepDisabled = true;
                return;
            }

            this.calculateDisbursement();
        });
    }

    private validateFundingForm() {
        if (!this.operationData.hasFunding) {
            this.isNextStepDisabled = false;
            return;
        }

        const insufficientFunds = this.totalDisbursement > this.accountBalance + this.fundingData.limit;
        const negativeProfitability = this.operationData.profitability < 0;
        const fundingMoreThanLimit = this.fundingForm.get("fundingUsed").value > this.fundingData.limit;
        const moreThan100Percent =
            this.fundingForm.get("accountBalanceRate").value > 100 || this.fundingForm.get("fundingRate").value > 100;

        if (
            (insufficientFunds || negativeProfitability || fundingMoreThanLimit || moreThan100Percent) &&
            this.stepper.selectedIndex === 3
        ) {
            this.isNextStepDisabled = true;
            return;
        }
        this.isNextStepDisabled = false;
    }

    confirmOffRisk() {
        this.hasConfirmedOffRisk = true;
        this.shouldAnalyzePayerCredit = false;
        this.shouldCheckPayable = false;

        this.nextProgressValue();
    }

    getTotalExpenses() {
        return (
            this.feesForm.get("tac").value +
            this.feesForm.get("ted").value +
            this.feesForm.get("invoices").value +
            this.feesForm.get("creditAnalysis").value +
            this.feesForm.get("pix").value +
            this.feesForm.get("digitalSignatureCost").value +
            this.feesForm.get("checkCustody").value
        );
    }

    cleanValidator({ field }: { field: FeesEnum }) {
        this.feesForm.controls[field].setValue(0);
    }

    bankServiceTypeChange(serviceType: string) {
        this.bankServiceTypeSelected = serviceType;

        const feeTypeTest = {
            invoices: () => this.cleanValidator({ field: FeesEnum.CHECK_CUSTODY }),
            checkCustody: () => this.cleanValidator({ field: FeesEnum.INVOICES }),
        };

        serviceType && feeTypeTest[serviceType as keyof typeof feeTypeTest]();
    }

    public async getOperationTypesAvailable() {
        const operationTypes = await this.createOperationService.getOperationTypes();

        if (this.authService.isStarter) {
            return operationTypes.filter((operationType) => operationType.attributes.description === "REDESCONTO");
        }

        return operationTypes.filter((operationType) => operationType.attributes.description !== "REDESCONTO");
    }

    public async getOperationTypes() {
        this.operationTypes = await this.getOperationTypesAvailable();
    }

    onSelect(event: NgxDropzoneChangeEvent) {
        if (!this.fileValidator.validateEvent(event)) return;
        this.files.push(...event.addedFiles);
    }

    onRemove(event: File) {
        this.files.splice(this.files.indexOf(event), 1);
    }

    public async filterOperationMode(): Promise<void> {
        const operationModes = await this.operationService.getOperationModes().toPromise();
        const rediscountOperationId = 34;

        const filteredOperationModes = operationModes.filter(
            (operationMode) => operationMode.id !== rediscountOperationId,
        );

        if (this.authService.isStarter) {
            const riskSecOperationId = 22;
            const operationModesForStarter = filteredOperationModes.filter(
                (operationMode) => operationMode.id === riskSecOperationId,
            );

            this.filteredOperationModes$ = of(operationModesForStarter);
            return;
        }

        this.filteredOperationModes$ = of(filteredOperationModes);
    }

    watchAssignorAutocomplete() {
        this.cedenteService
            .listaCedentes()
            .toPromise()
            .then((result) => {
                if (result) {
                    this.assignors = result.data;
                    this.filteredAssignors$ = of(result.data);
                }
            });

        this.basicInfoForm
            .get("assignor")
            .valueChanges.pipe(debounceTime(500))
            // eslint-disable-next-line @typescript-eslint/no-misused-promises, rxjs/no-async-subscribe
            .subscribe(async (value) => {
                const response: Cedente[] = await this.findAssignors(value as string);
                this.filteredAssignors$ = of(response);
            });
    }

    async findAssignors(assignor: string): Promise<Cedente[]> {
        return this.cedenteService.filterAssignors(assignor);
    }

    openChooseUploadFileModal() {
        if (this.basicInfoForm.get("assignor").invalid) {
            return this.snackBar.open(messages.ALERT_FILL_ASSIGNOR_FIELD, "X", { duration: 5000 });
        }

        const dialog = this.dialog.open(ChooseUploadFileModalComponent, {
            data: {
                payableType: this.payableTypeSelected,
            },
        });

        dialog.componentInstance.xmlEmitter.subscribe(() => {
            dialog.close();
            this.openSendPayablesModal(FileType.XML);
        });

        dialog.componentInstance.spreadsheetEmitter.subscribe(() => {
            dialog.close();
            this.openSendPayablesModal(FileType.XLSX);
        });

        dialog.componentInstance.imgEmitter.subscribe(() => {
            dialog.close();
            this.openCheckPayableModal();
        });

        dialog.componentInstance.closeEmitter.subscribe(() => {
            dialog.close();
        });
    }

    openCheckPayableModal() {
        this.dialog.open(UploadBankCheckModalComponent, {
            width: "100%",
            data: {
                assignor: this.basicInfoForm.get("assignor").value,
                payableType: this.payableTypeSelected,
            },
        });
    }

    openSendPayablesModal(fileType: FileType) {
        const sendPayablesDialog = this.dialog.open(EnviarNotasModalComponent, {
            disableClose: false,
            width: "100%",
            data: {
                assignor: this.basicInfoForm.get("assignor").value,
                fileType: fileType,
            },
        });

        sendPayablesDialog.componentInstance.close.subscribe(() => {
            sendPayablesDialog.close();
        });

        sendPayablesDialog.componentInstance.inconsistenciesEmitter.subscribe(
            (inconsistencies: IOutputPayableError) => {
                this.inconsistencies = inconsistencies;
                sendPayablesDialog.close();
            },
        );
    }

    openSearchPayablesInDataBaseModal() {
        if (this.basicInfoForm.get("assignor").invalid) {
            return this.snackBar.open(messages.ALERT_FILL_ASSIGNOR_FIELD, "X", { duration: 5000 });
        }

        this.dialog.open(PayablesFromDatabaseModalComponent, {
            width: "100%",
            data: {
                assignor: this.basicInfoForm.get("assignor").value,
                payableType: this.payableTypeSelected,
            },
        });
    }

    openModalCreatePayables() {
        if (this.basicInfoForm.get("assignor").invalid) {
            return this.snackBar.open(messages.ALERT_FILL_ASSIGNOR_FIELD, "X", { duration: 5000 });
        }

        this.dialog.open(CreatePayablesModalComponent, {
            width: "100%",
            data: {
                assignor: this.basicInfoForm.get("assignor").value,
                payableType: this.payableTypeSelected,
            },
        });
    }

    displayCorporateName(value: Cedente): string {
        if (value !== null) return value.razaoSocial;
    }

    nextProgressValue() {
        const step = this.stepper.selectedIndex;

        if (this.basicInfoForm.invalid && step === 0) {
            this.snackBar.open(messages.ALERT_ALL_FIELDS_REQUIRED, "X", { duration: 5000 });
            return;
        }

        if (
            (this.feesForm.controls.payableType.invalid && step === 1) ||
            (this.feesForm.controls.feePerMonth.value == 0 && step == 2)
        ) {
            this.snackBar.open(messages.ALERT_ALL_FIELDS_REQUIRED, "X", { duration: 5000 });
            return;
        }

        if (!this.payables.length && step === 2) {
            this.snackBar.open(messages.ALERT_OPERATION_SHOULD_CONTAIN_PAYABLE, "X", { duration: 5000 });
            return;
        }

        if (this.operationData.hasFunding && this.fundingForm.invalid && step === 3) {
            this.snackBar.open(messages.ALERT_INVALID_FUNDING, "X", { duration: 5000 });
            return;
        }

        if (step === 2 && this.operationData.totalRepurchase > 0 && this.operationData.totalDisbursement < 0) {
            this.isOperationValid = false;
            this.snackBar.open(messages.ALERT_OPERATION_MUST_CONTAIN_PAYABLE, "X", {
                duration: 5000,
            });

            return;
        }

        if (this.operationData.hasFunding && this.fundingForm.invalid && step === 3) {
            this.snackBar.open(messages.ALERT_INVALID_FUNDING, "X", { duration: 5000 });
        }

        if (!this.hasConfirmedOffRisk && !this.shouldCheckPayable && !this.shouldAnalyzePayerCredit && step === 3) {
            this.snackBar.open(messages.ALERT_SELECT_AT_LEAST_ONE_CHECKBOX, "X", { duration: 5000 });
            return;
        }

        if (this.paymentForm.invalid && step === 4) {
            this.snackBar.open(messages.ALERT_SOME_FIELDS_REQUIRED, "X", {
                duration: 5000,
            });
            return;
        }

        if (
            this.payables.some((payable) => [null, "Invalid date"].includes(payable.dtVencimento as string)) &&
            step === 3
        ) {
            this.snackBar.open(messages.ALERT_PAYABLES_MUST_HAVE_DUE_DATE, "X", { duration: 5000 });
            return;
        }

        if (this.feesForm.invalid && [3, 4].includes(step)) {
            this.snackBar.open(messages.ALERT_ALL_FIELDS_REQUIRED, "X", { duration: 5000 });
            return;
        }

        this.currentStep += 1;
        this.stepper.next();
    }

    public updateProgress() {
        if (this.stepper && this.stepper.selectedIndex >= 0) {
            this.progressValue = (this.currentStep / (this.totalSteps - 1)) * 100;
        } else {
            this.progressValue = 0;
        }
    }

    public initStepper() {
        if (this.stepper) {
            this.totalSteps = this.stepper.steps.length;
            this.updateProgress();
        }
    }

    public viewInit(): void {
        this.taxesForStarter();
        this.initStepper();
    }

    ngAfterViewInit() {
        this.viewInit();
    }

    async saveRecebiveisCreated() {
        this.isLoading = true;

        if (this.basicInfoForm.invalid) {
            this.snackBar.open(messages.ALERT_SOME_FIELDS_REQUIRED, "X", {
                duration: 5000,
            });
            this.isLoading = false;
            return;
        }

        if (!this.payables.length) {
            this.snackBar.open(messages.ALERT_OPERATION_SHOULD_CONTAIN_PAYABLE, "X", {
                duration: 5000,
            });
            this.isLoading = false;
            return;
        }

        if (this.paymentForm.invalid) {
            this.snackBar.open(messages.ALERT_SOME_FIELDS_REQUIRED, "X", {
                duration: 5000,
            });
            this.isLoading = false;
            return;
        }

        if (this.operationData.hasFunding && this.operationData.fundingDisbursement && !this.hasConfirmedFundingTerms) {
            this.snackBar.open(messages.ALERT_ACCEPT_TERMS, "X", { duration: 5000 });
            this.isLoading = false;
            return;
        }

        if (this.files.length === 0 && this.isHighPerformance) {
            this.snackBar.open(messages.ALERT_OPERATION_WITHOUT_FILES, "X");
            this.isLoading = false;
            return;
        }

        const { feePerMonth, description } = this.feesForm.controls;
        const { mode } = this.basicInfoForm.controls;

        if (this.shouldAnalyzePayerCredit || this.shouldCheckPayable) {
            this.addCreditAnalysisNote();
        }
        this.statusSigla = OperationStatusEnum.NEW_OPERATION;

        this.envioNotasModalService.getPayables$.subscribe((payable) => {
            this.payablesIds = payable?.map((payable) => payable?.id);
        });

        const { tac, ted, invoices, creditAnalysis, pix, checkCustody, digitalSignatureCost, payableType } =
            this.feesForm.value;

        const { paymentType, recipientId } = this.paymentForm.value;

        const { hasEmit } = this.emitPaymentSlip.value;

        const shouldEmitPaymentSlip =
            Number(this.basicInfoForm.controls.typeOperation.value) === 4 && payableType !== PayableType.CHEQUE
                ? false
                : this.shouldEmitPaymentSlip && hasEmit === "true";

        const createOperationObject: Partial<Operacao> = {
            taxa: this.convertTax(String(feePerMonth.value)) / 100,
            descricao: description.value ? description.value : null,
            statusSigla: this.statusSigla,
            payablesIds: this.payablesIds,
            typeId: Number(this.basicInfoForm.get("typeOperation").value),
            modalidadeId: mode.value,
            minibancoId: this.userService.user$?.minibanco.id,
            salesmanId: 1,
            keyAccountId: 1,
            payableType: PayableType[payableType],
            fees: {
                tac,
                ted,
                invoices,
                creditAnalysis,
                pix,
                checkCustody,
                digitalSignatureCost,
            },
            shouldCheckPayable: this.shouldCheckPayable,
            shouldAnalyzePayerCredit: this.shouldAnalyzePayerCredit,
            termsData: this.termsData,
            ...(this.operationData.hasFunding &&
                this.fundingForm.get("fundingUsed").value > 0 && {
                    fundingId: this.fundingData.id,
                    fundingAmount: Math.round(this.fundingForm.get("fundingUsed").value * 100) / 100,
                }),
            assignorBankDataId: Number(recipientId),
            paymentType,
            cedenteId: this.basicInfoForm.get("assignor").value.id,
            hasCheckCustody: this.hasCheckCustody && hasEmit === "true",
            shouldEmitPaymentSlip,
            payersToAnalyze: this.payablesToAnalyze,
        };

        try {
            const operation = await this.operationService.createOperacao(createOperationObject).toPromise();

            this.isLoading = false;

            if (this.selectedUnoperatedPayables.length > 0) {
                await this.createOperationService.createRepurchasedPayables({
                    operationId: operation.id,
                    ...this.selectionUnoperatedPayables,
                });
            }

            this.onSuccessPostOperacao(operation.id, messages.SUCCESS_OPERATATION_CREATED);

            if (this.files.length > 0) this.uploadOperacaoFiles(operation.id);
        } catch (error) {
            this.isLoading = false;
        }
    }

    addCreditAnalysisNote() {
        this.shouldAnalyzePayerCredit &&
            this.shouldCheckPayable &&
            this.feesForm.get("description").setValue("Solicitado checagem");
    }

    convertTax(taxa: string): number {
        return Number(taxa?.replace(",", "."));
    }

    onSuccessPostOperacao(operationId: number, snackBarText: string) {
        this.snackBar
            .open(snackBarText, "Ver Operação", {
                duration: 3500,
            })
            .onAction()
            .subscribe(() => {
                if (environment.environment === "demo") {
                    this.router.navigateByUrl("/operacoes");
                    return;
                }

                this.router.navigateByUrl(`/operacoes/view/${operationId}`);
            });

        if (environment.environment === "demo") {
            this.router.navigateByUrl("/operacoes");
            return;
        }

        this.router.navigateByUrl(`/operacoes/view/${operationId}`);
    }

    async uploadOperacaoFiles(operationId: number, description?: string) {
        return await this.uploadModalService.uploadFileAsync(this.files, operationId, "operacao", description);
    }

    async updateOperationMode($event: MatSelectChange) {
        const foundMode = await this.findMode($event.value);
        if (foundMode) {
            const description = foundMode.descricao;
            this.operationSummaryService.updateRisk(description);
        }
    }

    async findMode(id: number) {
        const modes = await this.filteredOperationModes$.toPromise();
        const foundMode = modes.find((mode) => mode.id === id);
        return foundMode;
    }

    ngOnDestroy(): void {
        this.envioNotasModalService.setPayables = [];
        this.operationSummaryService.updateCurrentPayables([]);
        this.payableListService.sendCurrentPayables([]);

        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    changePayableType($event: MatSelectChange) {
        this.dialog
            .open(ConfirmTypePayableModalComponent, {
                disableClose: true,
                width: "auto",
                data: {},
            })
            .afterClosed()
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((result) => {
                if (result == "cancel") {
                    this.payableTypeSelected = null;
                    this.feesForm.controls.payableType.setValue(null);
                    this.selectedPayableType = false;
                    return;
                }

                this.disablePayableType = !this.disablePayableType;
                this.enableCreatePayableAll = $event.value === this.typePayableCreateAll;
                this.payableTypeSelected = $event.value;

                this.hasCheckCustody = $event.value === PayableType.CHEQUE;
                this.shouldEmitPaymentSlip = $event.value !== PayableType.CHEQUE;

                if (PayableType.CHEQUE === $event.value) {
                    this.bankServiceTypeChange(FeesEnum.CHECK_CUSTODY);
                    this.feesForm.controls.bankServiceType.setValue(FeesEnum.CHECK_CUSTODY);
                }

                this.selectedPayableType = true;
                if (
                    Number(this.basicInfoForm.controls.typeOperation.value) === 4 &&
                    $event.value !== PayableType.CHEQUE
                ) {
                    this.showEmit = false;
                    this.nextProgressValue();
                    return;
                }

                if ((this.bankType !== "STANDARD" || !this.showEmit) && PayableType.CHEQUE !== $event.value) {
                    this.nextProgressValue();
                }
            });
    }

    treatCase(label: string) {
        const CamelCase = label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
        return CamelCase.replaceAll("Ccb", "CCB");
    }

    async calculateDisbursement() {
        const isCheque = this.feesForm.controls.payableType.value === EnumTypeDocument.CHEQUE;
        const insertedValues: IDataAnticipation = {
            minibankId: this.userService.user$.minibanco.id,
            typeDocument: isCheque ? EnumTypeDocument.CHEQUE : EnumTypeDocument.DUPLICATA,
            taxRate: Number(String(this.feesForm.controls.feePerMonth.value).replace("%", "").replaceAll(",", ".")),
            costOperation: this.getTotalExpenses(),
            installmentValues: this.payables.map((payable) => Number(payable?.valor)),
            dueDates: this.payables.map((dueDate) => {
                const dueDateFormatted = DATE_FORMAT_REGEX.test(dueDate.dtVencimento as string)
                    ? String(dueDate.dtVencimento).split("/").reverse().join("-")
                    : dueDate.dtVencimento;

                return adjustDateToEndOfDay(new Date(dueDateFormatted).toISOString());
            }),
            ...(this.operationData.hasFunding && {
                fundingInterestRate: this.fundingData.rate * 100,
                fundingAmount: this.fundingForm.get("fundingUsed").value || 0,
            }),
        };

        const { deductions, disbursement, fundingDeductions } = await this.calculatorService
            .calculateFunding(insertedValues)
            .toPromise();

        const deduction = deductions.reduce((acc, curr) => acc + curr, 0);
        const fundingDeduction = fundingDeductions?.reduce((acc, curr) => acc + curr, 0) || 0;

        const netDeduction = deduction - fundingDeduction;

        this.operationData = {
            ...this.operationData,
            deduction,
            netDeduction,
            totalDisbursement: disbursement,
            profitability: netDeduction / this.fundingForm.get("accountBalance").value || 0,
        };

        this.totalDisbursement = disbursement;
        this.validateFundingForm();

        this.calculateRepurchase();
    }

    calculateRepurchase(): void {
        const totalRepurchase = this.selectionUnoperatedPayables.repurchaseValue || 0;

        const totalDisbursement = this.totalDisbursement - totalRepurchase;

        this.isOperationValid = true;

        this.operationData = {
            ...this.operationData,
            totalRepurchase: totalRepurchase,
            totalDisbursement: totalDisbursement,
        };
    }

    async acceptedTerms(event: { checked: boolean }) {
        this.hasConfirmedFundingTerms = event.checked;

        const acceptedAt = new Date().toISOString();
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();

        const userAgent = navigator.userAgent;

        this.termsData = {
            acceptedAt,
            userIp: data.ip,
            userAgent,
        };
    }

    openCreateBankAccountModal() {
        const bankAccountModal = this.dialog.open(BankAccountModalComponent, {
            data: this.basicInfoForm.get("assignor").value,
            width: "80%",
        });

        bankAccountModal.afterClosed().subscribe((result) => {
            if (result) {
                this.createOperationService
                    .getAssignorBankAccounts(this.basicInfoForm.get("assignor").value.id)
                    .then((data) => {
                        this.paymentCards = data;
                    });
            }
        });
    }

    selectCardBankAccount(card: ReceipientBankAccount) {
        if (!card.id) {
            this.paymentForm.get("recipientId").setValue(null);
            return;
        }
        this.paymentForm.get("recipientId").setValue(String(card.id));
    }

    openDialogAskWithoutPaymentSlip() {
        const askWithoutPaymentSlipModal = this.dialog.open(AskWithoutPaymentSlipModalComponent, {
            width: "800px",
            data: { payableType: this.feesForm.controls.payableType.value },
        });

        askWithoutPaymentSlipModal.afterClosed().subscribe((result) => {
            if (result === "cancel") {
                this.emitPaymentSlip.controls.hasEmit.setValue("true");
                return;
            }

            this.nextProgressValue();
        });
    }

    maskDocument(document: string) {
        if (document.length === 11) {
            return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        }
        return document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }

    addPayableToAnalyze(payable: Partial<Payable>) {
        if (this.payablesToAnalyze.includes(payable.sacado.id)) {
            this.payablesToAnalyze = this.payablesToAnalyze.filter((p) => p !== payable.sacado.id);
            this.allPayersSelected = this.payablesToAnalyze.length === this.payables.length;
            return;
        }
        this.payablesToAnalyze.push(payable.sacado.id);
        this.allPayersSelected = this.payablesToAnalyze.length === this.payables.length;
    }

    toggleAllPayersToAnalysis(event: Event) {
        const input = event.target as HTMLInputElement;
        this.allPayersSelected = input.checked;
        this.payablesToAnalyze = input.checked ? this.payables.map((payable) => payable.sacado.id) : [];
    }

    handleChecked(type: string, checked: boolean) {
        this[type] = checked;

        if (type === "shouldAnalyzePayerCredit" && !checked) {
            this.allPayersSelected = false;
            this.payablesToAnalyze = [];
        }
    }

    openRepurchasePayables() {
        this.isShow = true;
    }

    selectedRepurchasedPayables(event: UnoperatedPayablesSelection) {
        this.selectionUnoperatedPayables = event;
        this.selectedUnoperatedPayables = event.payables;

        this.totalRepurchase = event.repurchaseValue;

        this.calculateRepurchase();
    }
}
