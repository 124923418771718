import { Component, Input } from "@angular/core";

@Component({
    selector: "WarningButton",
    templateUrl: "./warning-button.component.html",
})
export class WarningButtonComponent {
    @Input() icon: string;

    public isOpen = true;

    toggle() {
        this.isOpen = !this.isOpen;
    }
}
