import { AbstractControl, ValidationErrors } from "@angular/forms";
import { cmc7Regex } from "app/shared/constants/cmc7-regex.constant";
const errorsMessage = "The CMC7 is invalid.";

class Cmc7 {
    private readonly cmc7: string;

    constructor(cmc7: string) {
        this.cmc7 = cmc7;
    }

    public getValue(): string {
        if (!this.isValid()) throw new Error(errorsMessage);

        return this.cmc7.replaceAll(cmc7Regex.IS_SPACE, "");
    }

    public isValid(): boolean {
        return this.validate(this.cmc7);
    }

    private validate(typedValue: string): boolean {
        typedValue = typedValue.replace(cmc7Regex.IS_SPACE, "");

        if (!typedValue) {
            return false;
        }

        const pieces = {
            firstPiece: typedValue.substring(0, 7),
            secondPiece: typedValue.substring(8, 18),
            thirdPiece: typedValue.substring(19, 29),
        };

        const digits = {
            firstDigit: parseInt(typedValue.substring(7, 8)),
            secondDigit: parseInt(typedValue.substring(18, 19)),
            thirdDigit: parseInt(typedValue.substring(29, 30)),
        };

        const calculatedDigits = {
            firstDigit: this.module10(pieces.firstPiece),
            secondDigit: this.module10(pieces.secondPiece),
            thirdDigit: this.module10(pieces.thirdPiece),
        };

        return (
            calculatedDigits.secondDigit === digits.firstDigit &&
            calculatedDigits.firstDigit === digits.secondDigit &&
            calculatedDigits.thirdDigit === digits.thirdDigit
        );
    }

    private module10(str: string): number {
        if (!str) {
            return 0;
        }

        let result = 0;
        let weight = 2;
        const maxWeight = 2;
        const minWeight = 1;
        const totalThreshold = 9;
        const modValue = 10;
        const zero = 0;

        const digits = str.split("").reverse().map(Number);

        for (const digit of digits) {
            const total = digit * weight;

            if (total > totalThreshold) {
                result += 1 + (total - modValue);
            } else {
                result += total;
            }

            weight = (weight % maxWeight) + minWeight;
        }

        const divisor = modValue - this.mod(result, modValue);

        return divisor === modValue ? zero : divisor;
    }

    private mod(dividend: number, divisor: number): number {
        return Math.round(dividend - Math.floor(dividend / divisor) * divisor);
    }
}

export function validateCmc7(control: AbstractControl): ValidationErrors | null {
    const cmc7 = new Cmc7(control.value);

    return cmc7.isValid() ? null : { cmc7: true };
}
