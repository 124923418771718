import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IBank, IPixKeyType, pixKeyTypes } from "app/modules/admin/v1/cedente/interfaces/account-bank.types";
import { CreateAssignorService } from "app/modules/admin/v2/assignor/create/create-assignor.service";
import { Cedente } from "app/modules/admin/v1/cedente/interfaces/cedente.types";
import { Subject } from "rxjs";
import { debounceTime, startWith, takeUntil } from "rxjs/operators";
import { AccountInfo } from "./interfaces/account.interface";
import { PixKeyTypeEnum } from "app/modules/admin/v1/cedente/constants/pix-key-type.enum";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "BankAccountModal",
    templateUrl: "./bank-account-modal.component.html",
    styleUrls: ["./bank-account-modal.component.scss"],
})
export class BankAccountModalComponent implements OnInit, OnDestroy {
    private allBanksList: IBank[] = [];
    public filteredBanksList: IBank[] = [];

    public assignorBankDataForm: FormGroup;
    private unsubscribeAll: Subject<void> = new Subject<void>();

    public pixTypes: IPixKeyType[] = pixKeyTypes;

    constructor(
        @Inject(MAT_DIALOG_DATA) public card: Partial<Cedente>,
        private dialogRef: MatDialogRef<BankAccountModalComponent>,
        private createAssignorService: CreateAssignorService,
        private readonly formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
    ) {}

    async ngOnInit() {
        this.initForm();
        this.allBanksList = await this.createAssignorService.listAllBanks();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
    }

    initForm() {
        this.assignorBankDataForm = this.formBuilder.group({
            thirdParty: [null, Validators.required],
            accountHolder: [null],
            document: [null],
            bank: [null, this.requiredValidator()],
            agency: [null, [this.requiredValidator(), Validators.minLength(4), Validators.maxLength(5)]],
            agencyDigit: [null, [Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/\d/)]],
            account: [
                null,
                [this.requiredValidator(), Validators.minLength(2), Validators.maxLength(10), Validators.pattern(/\d/)],
            ],
            accountDigit: [
                null,
                [this.requiredValidator(), Validators.minLength(1), Validators.maxLength(1), Validators.pattern(/\d/)],
            ],
            pixKeyType: null,
            pix: [{ value: null, disabled: true }, [Validators.maxLength(100)]],
        });

        this.changeBank();
        this.changePixKeyType();
    }

    async onSubmit() {
        const formValue = this.assignorBankDataForm.value as AccountInfo;
        const data = {
            ...formValue,
            accountDigit: Number(formValue.accountDigit),
            agencyDigit: Number(formValue.agencyDigit),
            bank: `${formValue.bank.codeBank} - ${formValue.bank.shortName}`,
            pixKeyType: formValue.pixKeyType as keyof typeof PixKeyTypeEnum,
        };

        delete data.thirdParty;

        await this.createAssignorService.createBankAccount(data, this.card.id);

        this.snackBar.open("Conta bancária criada com sucesso", "Ok", {
            duration: 2000,
        });

        this.dialogRef.close("refresh");
    }

    displayBanksListFn(bank: IBank): string {
        if (bank) return `${bank.codeBank} - ${bank.shortName}`;

        return "";
    }

    public onBankSelected(event: MatAutocompleteSelectedEvent) {
        const selectedBank = event.option.value;
        this.assignorBankDataForm.controls.bank.setValue(selectedBank);
    }

    public onBlurBankInput() {
        const bankInputValue = this.assignorBankDataForm.controls.bank.value;

        if (typeof bankInputValue === "string") {
            this.assignorBankDataForm.controls.bank.setValue(null);
        }
    }

    convertBankStringToObject(bank: string) {
        const codeBank = bank?.split("-")[0] || "";
        return this.allBanksList.filter((item) => item.codeBank === codeBank.trim())[0];
    }

    changeBank() {
        this.assignorBankDataForm
            .get("bank")
            .valueChanges.pipe(takeUntil(this.unsubscribeAll), startWith(""), debounceTime(300))
            .subscribe((value: string) => {
                const banks = this.allBanksList;

                this.filteredBanksList = banks.filter((bank: IBank) => {
                    return bank.shortName.toLowerCase().includes(value) || bank.codeBank.includes(value);
                });
            });
    }

    setMaskPix() {
        const masks = {
            CPF: "000.000.000-00",
            CNPJ: "00.000.000/0000-00",
            EMAIL: "",
            PHONE: "(00) 0 0000-0000",
            RANDOM_KEY: "AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA",
        };
        return masks[this.assignorBankDataForm.get("pixKeyType").value];
    }

    changePixKeyType() {
        this.assignorBankDataForm
            .get("pixKeyType")
            .valueChanges.pipe(takeUntil(this.unsubscribeAll))
            .subscribe((value) => {
                this.assignorBankDataForm.get("pix")[value ? "enable" : "disable"]();
                this.assignorBankDataForm.get("pix").setValidators(value ? [this.requiredValidator()] : []);
                this.assignorBankDataForm.get("pix").setValue(null);
                this.assignorBankDataForm.get("pix").updateValueAndValidity();
            });
    }

    changeThirdParty() {
        const valueThirdparty = this.assignorBankDataForm.get("thirdParty").value;
        if (valueThirdparty === "titular") {
            this.assignorBankDataForm.get("accountHolder").setValue(this.card.nomeFantasia || this.card.razaoSocial);
            this.assignorBankDataForm.get("document").setValue(this.card.cnpj);
            return;
        }
        this.assignorBankDataForm.get("accountHolder").setValue("");
        this.assignorBankDataForm.get("document").setValue("");
    }

    requiredValidator(): ValidatorFn {
        return Validators.required;
    }
}
