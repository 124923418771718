import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AbstractControl, FormBuilder, Validators } from "@angular/forms";
import { IFormBuilder, IFormGroup } from "@rxweb/types";
import * as states from "app/utils/estados";
import { regexZipCode } from "../../constants/zip-code.regex";
import { ICreatePayerForm } from "../../interfaces/create-payer-form.interface";
import { ICreatePayer } from "../../interfaces/create-payer.interface";
import { IGetPayer } from "../../interfaces/get-payer.interface";
import { IAddress } from "../../interfaces/payer-address.interface";
import { IUpdatePayerDto } from "../../interfaces/update-payer.interface";
import { ViaCepAddress } from "../../interfaces/viacep-address.interface";
import { PayerMessagesService } from "../../payer-message.service";
import { PayerService } from "../../payer.service";
import { documentValid } from "app/modules/admin/v1/cedente/new-cedente/validators/document.validator";
import { RevenueService } from "app/shared/services/revenue-service/revenue-service.service";
import { MatDialog } from "@angular/material/dialog";
import { RevenueDataModalComponent } from "app/components/v2/modals/revenue-data-confirmation/revenue-data-confirmation.component";
import { IRevenueServiceResponse } from "app/shared/interfaces/revernue-service-response.interface";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-payer-create-modal",
    templateUrl: "./payer-create-modal.component.html",
    styleUrls: [],
})
export class CreatePayerModalComponent implements OnInit, OnDestroy {
    @Input() isEditing = false;
    @Input() payer: IGetPayer;
    @Input() address: IAddress;
    @Output() cancelEmitter = new EventEmitter();
    @Output() afterSaveEmitter = new EventEmitter();
    @Output() createdPayerEmitter = new EventEmitter();
    @ViewChild(MatSelect) selectState: MatSelect;

    public loadingAddress = false;
    public loadingSubmit = false;
    public createPayerForm: IFormGroup<ICreatePayerForm>;
    public allBrazilStates: string[];
    private formBuilder: IFormBuilder;
    public isFetchingDocument = false;

    private unsubscribeAll = new Subject();

    public limit_complement = 64;

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly payerService: PayerService,
        private readonly payerMessages: PayerMessagesService,
        private readonly snackBar: MatSnackBar,
        private readonly revenueService: RevenueService,
        private readonly dialog: MatDialog,
    ) {
        this.formBuilder = _formBuilder;
    }

    ngOnInit(): void {
        this.allBrazilStates = states.default;
        this.initForm();

        if (this.isEditing) {
            this.fillPayer(this.payer);
            if (this.payer.address) {
                setTimeout(() => this.fillAddress({ address: this.payer.address, mustFillZipCode: true }), 500);
            }
        }
    }

    initForm() {
        this.createPayerForm = this.formBuilder.group<ICreatePayerForm>({
            document: [{ value: "", disabled: this.isEditing }, [Validators.required, documentValid]],
            companyName: [null, Validators.required],
            phone: [null, Validators.required],
            email: [null, Validators.email],
            isDisabled: [true],

            zipCode: [null, [Validators.required, Validators.pattern(regexZipCode)]],
            street: [null, Validators.required],
            number: [null, Validators.required],
            complement: [null, Validators.maxLength(this.limit_complement)],
            neighborhood: [null, Validators.required],
            city: [null, Validators.required],
            state: [null, Validators.required],
        });
    }

    async onDocumentChange() {
        const document = this.createPayerForm.controls.document.value;

        this.isFetchingDocument = true;

        const response = await this.revenueService.getInformationFromDocument(document);

        if (response) {
            const modal = this.dialog.open(RevenueDataModalComponent, {
                data: response,
            });

            modal.componentInstance.cancelEmitter.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
                modal.close();
                this.autofillPayerData({ data: response, willFillAll: false });
            });

            modal.componentInstance.confirmEmitter.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
                modal.close();
                this.autofillPayerData({ data: response, willFillAll: true });
            });
        }

        this.isFetchingDocument = false;
    }

    autofillPayerData({ data, willFillAll }: { data: IRevenueServiceResponse; willFillAll: boolean }) {
        const { address, phone, companyName, fantasyName } = data;
        const { city, neighborhood, state, street, zipCode, complement } = address;

        this.createPayerForm.patchValue({
            companyName,
            phone: willFillAll ? phone : "",
            fantasyName,
            city,
            complement,
            neighborhood,
            state,
            street,
            zipCode,
        });
    }

    getAddressByZipCode(zipCode: string) {
        const zipCodeLength = zipCode.split("").length;
        if (zipCodeLength < 8) return;

        this.loadingAddress = true;
        this.payerService.getAddressByZipCode(zipCode).subscribe((address) => {
            const { bairro, logradouro, complemento, cep, localidade, uf } = address as ViaCepAddress;
            const engAddress: IAddress = {
                city: localidade,
                neighborhood: bairro,
                state: uf,
                number: Number(""),
                complement: complemento,
                zipCode: cep,
                street: logradouro,
            };
            this.fillAddress({ address: engAddress, mustFillZipCode: false });
            this.loadingAddress = false;
        });
    }

    fillPayer(payer: IGetPayer) {
        const { companyName, document, isDisabled, phone } = payer;
        const form = this.createPayerForm.controls;

        if (companyName) form.companyName.patchValue(companyName);
        if (document) form.document.patchValue(document);
        if (phone) form.phone.patchValue(phone);
        if (isDisabled) form.isDisabled.patchValue(!isDisabled);
    }

    fillAddress(params: { address: IAddress; mustFillZipCode: boolean }) {
        const { zipCode, city, neighborhood, number, state, street, complement } = params.address;
        const form = this.createPayerForm.controls;

        if (params.mustFillZipCode) form.zipCode.patchValue(zipCode);
        if (city) form.city.patchValue(city);
        if (number) form.number.patchValue(number);
        if (neighborhood) form.neighborhood.patchValue(neighborhood);
        if (street) form.street.patchValue(street);
        if (complement) form.complement.patchValue(complement);
        if (state) this.selectState.options.find((element) => element.value === state).select();
    }

    async savePayer(data: IFormGroup<ICreatePayerForm>) {
        this.loadingSubmit = true;
        this.createPayerForm.markAllAsTouched();

        if (this.createPayerForm.invalid) {
            this.loadingSubmit = false;
            this.payerMessages.invalidFormMessage();
            return;
        }

        const payer = this.convertSacadoFormToObject(data.value);

        if (this.isEditing) {
            try {
                const updatePayer: IUpdatePayerDto = { ...payer, id: this.payer.id };
                const response = await this.payerService.updatePayer(updatePayer);
                const { statusCode } = response;

                this.snackBar.open(response.message, "x", {
                    duration: 5000,
                    panelClass: statusCode === 400 ? "snack-bar-error" : "snack-bar-success",
                });

                if (statusCode !== 400) this.afterSaveEmitter.emit();
            } catch (error) {
                this.snackBar.open("Houve um erro ao tentar salvar o sacado, por favor contate o suporte", "x", {
                    duration: 5000,
                    panelClass: "snack-bar-error",
                });
            }
            this.loadingSubmit = false;

            return;
        }

        try {
            const response = await this.payerService.addPayer(payer);
            const { statusCode } = response;

            if (statusCode === 201) {
                const now = new Date().toISOString();
                const { document, phone, companyName } = payer;

                this.createdPayerEmitter.emit({
                    enderecoId: null,
                    id: response.assignorId,
                    createdAt: now,
                    document: document,
                    razaoSocial: companyName,
                    nomeFantasia: companyName,
                    fone: phone,
                    inativo: false,
                    pessoaFisica: document.length === 11,
                    updatedAt: now,
                });
            }

            this.snackBar.open(response.message, "x", {
                duration: 5000,
                panelClass: statusCode === 400 ? "snack-bar-error" : "snack-bar-success",
            });

            if (statusCode !== 400) this.afterSaveEmitter.emit();
        } catch (error) {
            this.snackBar.open("Houve um erro ao tentar salvar o sacado, por favor contate o suporte", "x", {
                duration: 5000,
                panelClass: "snack-bar-error",
            });
        }

        this.loadingSubmit = false;
    }

    convertSacadoFormToObject(formData: ICreatePayerForm): ICreatePayer {
        const {
            document,
            city,
            companyName,
            isDisabled,
            neighborhood,
            number,
            phone,
            email,
            state,
            street,
            zipCode,
            complement,
        } = formData;

        return {
            document: this.isEditing ? this.payer.document : document,
            companyName,
            isDisabled: !isDisabled,
            phone,
            email,
            address: {
                city,
                complement,
                neighborhood,
                number: Number(String(number).replaceAll(/\D/g, "")) || 0,
                state,
                street,
                zipCode,
            },
        };
    }

    getFormErrorMessage(field: AbstractControl) {
        if (field.hasError("required")) return "Este campo é obrigatório";
        return field.errors ? "Esta informação não é válida" : "";
    }

    emitCancel() {
        this.cancelEmitter.emit();
    }

    ngOnDestroy() {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }
}
