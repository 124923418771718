<table mat-table [dataSource]="dataSource" class="w-full mb-5 bg-transparent">
    <!-- Sacado Column -->
    <ng-container matColumnDef="payable-id">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="text-base border-b pb-2 max-h-22 font-normal leading-5 text-gray-400 px-3 truncate"
        >
            Sacado
        </th>
        <td
            mat-cell
            *matCellDef="let element"
            class="text-base font-normal leading-4 px-3 hover:underline cursor-pointer"
        >
            <span (click)="openEditPayerModal(element)">
                {{ element.sacado?.razaoSocial }}
            </span>
        </td>
    </ng-container>
    <!-- Parcelas Column -->
    <ng-container matColumnDef="payable-dtEmissao">
        <th mat-header-cell *matHeaderCellDef class="text-base border-b pb-2 font-normal leading-5 text-gray-400 px-3">
            Parcelas
        </th>
        <td mat-cell *matCellDef="let element" class="text-base font-normal leading-4 px-3">
            {{ element.installment + " de " + payables.length }}
        </td>
    </ng-container>

    <!-- Vencimento Column -->
    <ng-container matColumnDef="payable-dtVencimento">
        <th mat-header-cell *matHeaderCellDef class="text-base border-b pb-2 font-normal leading-5 text-gray-400 px-3">
            Vencimento
        </th>
        <td
            mat-cell
            *matCellDef="let element"
            class="text-base font-normal leading-4 px-3 cursor-pointer"
            (click)="updateDueDate(element)"
            [ngClass]="
                element?.dtVencimento !== 'Invalid date' && element?.dtVencimento !== null
                    ? 'hover:underline'
                    : 'cursor-pointer font-bold text-red-500 hover:text-red-600'
            "
        >
            {{
                element?.dtVencimento !== "Invalid date" && element?.dtVencimento !== null
                    ? element.dtVencimento
                    : "definir vencimento"
            }}
        </td>
    </ng-container>

    <!-- Valor Column -->
    <ng-container matColumnDef="payable-valor">
        <th mat-header-cell *matHeaderCellDef class="text-base border-b pb-2 font-normal leading-5 text-gray-400 px-3">
            Valor
        </th>
        <td
            mat-cell
            *matCellDef="let element"
            class="text-base font-normal leading-4 px-3 hover:underline cursor-pointer"
            (click)="chooseValue(element)"
        >
            {{ element.valor }}
        </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="payable-acao">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="text-base border-b pb-2 font-normal leading-5 text-gray-400 px-3"
        ></th>
        <td mat-cell *matCellDef="let element" class="text-base font-normal leading-4 px-3">
            <button mat-icon-button class="mr-4" (click)="removePayableOfResume(element.id)">
                <mat-icon
                    svgIcon="heroicons_outline:trash"
                    class="text-2xl hover:text-red-800 text-red-600 cursor-pointer"
                >
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="h-auto"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="border-0"></tr>
</table>
<div class="flex m-auto" [class]="payables.length <= totalVisibleDefault ? 'hidden' : ''">
    <Button
        [hideText]="false"
        [label]="labelShowMore"
        type="transparent"
        color="accent"
        (clickEvent)="showMorePayables()"
    ></Button>
</div>
