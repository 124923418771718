import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { PayableListService } from "./payable-list.service";
import moment, { MomentInput } from "moment";
import { IPayable } from "./interfaces";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EnvioNotasModalService } from "app/components/v1/xml-modal/envio-notas-modal.service";
import { Payable } from "app/modules/admin/v1/operacoes/operacoes.types";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NewCTEDueDateComponent } from "../modals/new-cte-due-date/new-cte-due-date.component";
import { OperationSummaryService } from "../OperationSummary/operation-summary.service";
import { NewValueModalComponent } from "../modals/new-value-modal/new-value-modal.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EditPayerModalComponent } from "app/components/edit-payer-modal/edit-payer-modal.component";

@Component({
    selector: "PayableList",
    templateUrl: "./payable-list.component.html",
    styleUrls: ["./payable-list.component.scss"],
})
export class PayableListComponent implements OnInit, OnDestroy {
    @Input() hasTitle: boolean;
    @Input() payables: Partial<Payable>[] = [];

    public dataSource: IPayable[] = [];
    public displayedColumns: string[] = [
        "payable-id",
        "payable-dtEmissao",
        "payable-dtVencimento",
        "payable-valor",
        "payable-acao",
    ];

    public showMore: boolean = false;
    public labelShowMore = "Ver mais";
    public totalVisibleDefault: number = 3;

    private unsubscribeAll = new Subject();

    constructor(
        private operationSummaryService: OperationSummaryService,
        private payableListService: PayableListService,
        private envioNotasModalService: EnvioNotasModalService,
        private readonly dialog: MatDialog,
        private readonly snack: MatSnackBar,
    ) {}

    ngOnInit(): void {
        this.payableListService.currentPayableState
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((payables: Partial<Payable>[]) => {
                this.payables = payables;
                const allPayables = this.showMore
                    ? this.payables
                    : this.payables.filter((_, index) => index < this.totalVisibleDefault);
                this.dataSource = allPayables.length ? this.mapToPayables(allPayables as any) : [];
            });
    }

    removePayableOfResume(idPayable: number) {
        const currentPartialPayables = this.payables.filter((p) => p.id !== idPayable);
        const currentCompletePayables = this.payableListService.getCurrentPayables().filter((p) => p.id !== idPayable);

        this.envioNotasModalService.setPayables = [...currentPartialPayables];
        this.operationSummaryService.updateCurrentPayables(currentPartialPayables);
        this.payableListService.sendCurrentPayables(currentCompletePayables);

        this.payableListService.mustUpdateDisbursement.next();
    }

    showMorePayables() {
        this.showMore = !this.showMore;
        this.labelShowMore = this.showMore ? "Ver menos" : "Ver mais";
        this.payableListService.sendCurrentPayables(this.payables as any);
    }

    mapToPayables(payables: IPayable[]) {
        let installment = 0;
        let sacadoId = payables.length ? payables[0].sacado.id : 0;

        return payables.map((payable, index) => {
            installment = payable.sacado.id == sacadoId ? installment + 1 : 1;
            sacadoId = payable.sacado.id == sacadoId ? sacadoId : payable.sacado.id;
            const installments = payables.filter((payable) => payable.sacado.id == sacadoId).length;
            return {
                ...payable,
                id: payable.id,
                dtEmissao: this.formatDate(payable.dtEmissao),
                dtVencimento: payable.dtVencimento,
                valor: Number(payable.valor as number).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                }),
                installment,
                index: index + 1,
                installments,
            };
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(true);
        this.unsubscribeAll.complete();
    }

    formatDate(dueDate: string): string {
        if (!moment(dueDate as MomentInput).isValid()) return dueDate;
        return moment(dueDate as MomentInput).format("DD/MM/YYYY");
    }

    updateDueDate(payable: IPayable) {
        const dialog = this.dialog.open(NewCTEDueDateComponent, {
            data: { payableId: payable.id },
        });

        dialog.componentInstance.closeEmitter.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
            dialog.close();
        });

        dialog.componentInstance.cteUpdatedEmitter.pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {
            const currentPayables = this.payableListService.getCurrentPayables().map((payable) => {
                if (payable.id == data.id) payable.dtVencimento = data.dtVencimento;
                return payable;
            });

            this.payableListService.sendCurrentPayables(currentPayables);
            this.operationSummaryService.updateCurrentPayables(currentPayables);

            this.payableListService.mustUpdateDisbursement.next();

            dialog.close();
        });
    }

    chooseValue(payable: IPayable) {
        const modal = this.dialog.open(NewValueModalComponent);

        modal.componentInstance.cancelEmitter.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
            modal.close();
        });

        modal.componentInstance.confirmEmitter.pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {
            this.updateValue({ data, payable });
            modal.close();
        });
    }

    async updateValue({ payable, data }: { data: string | number; payable: IPayable }) {
        const currentPayables = this.payableListService.getCurrentPayables().map((eachPayable) => {
            if (eachPayable.id === payable.id) eachPayable.valor = data;
            return eachPayable;
        });

        try {
            const res = await this.payableListService.updatePayableValue({ payable, value: data });

            if (res.statusCode !== 400) {
                this.payableListService.sendCurrentPayables(currentPayables);
                this.operationSummaryService.updateCurrentPayables(currentPayables);
                this.envioNotasModalService.setPayables = currentPayables;

                this.snack.open("Valor atualizado com sucesso", "x", {
                    duration: 3000,
                    panelClass: ["snack-bar-success"],
                });
            }

            this.payableListService.mustUpdateDisbursement.next();
        } catch {
            this.snack.open("Erro ao atualizar valor", "x", { duration: 3000, panelClass: ["snack-bar-error"] });
        }
    }

    openEditPayerModal(element): void {
        const dialogRef = this.dialog.open(EditPayerModalComponent, {
            width: "600px",
            data: { payable: element },
        });

        dialogRef.componentInstance.selectedPayerEmitter.pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {
            const { id: payerId, payableId, nomeFantasia, razaoSocial } = data;

            this.editPayablePayer({ payableId, payerId, nomeFantasia, razaoSocial, dialogRef });
        });
    }

    async editPayablePayer(input: {
        payableId: number;
        payerId: number;
        nomeFantasia: string;
        razaoSocial: string;
        dialogRef: MatDialogRef<EditPayerModalComponent, any>;
    }) {
        const { payableId, payerId, nomeFantasia, razaoSocial, dialogRef } = input;

        try {
            if (payerId) {
                const response = await this.payableListService.updatePayablePayer({ payableId, payerId });
                if (response.sacadoId == payerId) {
                    const currentPayables = this.payableListService.getCurrentPayables().map((payable) => {
                        if (payable.id == payableId) payable.sacado = { id: payerId, nomeFantasia, razaoSocial };
                        return payable;
                    });

                    this.payableListService.sendCurrentPayables(currentPayables);
                    dialogRef.close();
                    this.snack.open("Sacado foi alterado com sucesso", "X", {
                        duration: 3000,
                    });
                }
            }
        } catch (error) {
            this.snack.open("Erro ao atualizar o sacado", "X", {
                duration: 3000,
            });
            console.error("Erro ao atualizar o sacado", error);
        }
    }
}
