<mat-form-field class="w-full">
    <input
        matInput
        maxlength="10"
        mask="d0/M0/0000"
        [(ngModel)]="dateFormated"
        (input)="changeInput($event)"
        [placeholder]="placeholder"
        [min]="min"
        [max]="max"
        autocomplete="off"
    />
    <input
        matInput
        [min]="min"
        [max]="max"
        [matDatepicker]="pickerData"
        [value]="dateMoment"
        (dateChange)="changeInput($event)"
        style="display: none"
        autocomplete="off"
        [placeholder]="placeholder"
    />
    <mat-datepicker-toggle matSuffix [for]="pickerData"> </mat-datepicker-toggle>
    <mat-datepicker #pickerData></mat-datepicker>
</mat-form-field>
