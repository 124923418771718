<div class="px-8">
    <header class="mb-4">
        <div class="flex gap-4 items-center pb-8">
            <mat-icon class="icon-size-8 text-blue-700" svgIcon="heroicons_outline:information-circle"></mat-icon>
            <h1 class="text-3xl font-bold">Autopreencher informações</h1>
        </div>

        <span>Encontramos em nossa base de dados algumas informações a partir do documento inserido</span>
    </header>

    <main class="flex flex-col gap-4">
        <div *ngIf="info.email" class="flex flex-col">
            <span class="font-bold">Email</span>
            <span>{{ info.email }}</span>
        </div>
        <div *ngIf="info.phone" class="flex flex-col">
            <span class="font-bold">Telefone</span>
            <span>{{ info.phone }}</span>
        </div>
    </main>

    <footer class="mt-8">
        <span> Gostaria de utilizar as informações no cadastro? </span>
        <div class="flex gap-10 justify-end">
            <Button type="outline" color="light" label="Cancelar" [hideText]="false" (clickEvent)="cancel()"></Button>

            <Button type="solid" color="primary" label="Confirmar" [hideText]="false" (clickEvent)="confirm()"></Button>
        </div>
    </footer>
</div>
