<h1 mat-dialog-title class="text-bold">{{ assignor?.razaoSocial }}</h1>

<mat-dialog-content fuseScrollbar class="mat-typograph">
    <h3>Recebíveis</h3>

    <div class="w-240 md:w-full h-full mt-12 px-5">
        <div class="flex flex-row items-center justify-between">
            <div class="font-medium text-md text-left text-secondary w-64">Sacado</div>
            <div class="font-medium text-md text-center text-secondary w-40">Minibanco</div>
            <div class="font-medium text-md text-center text-secondary pl-0 w-30">Emissão</div>
            <div class="font-medium text-md text-center text-secondary w-30">Vencimento</div>
            <div class="font-medium text-md text-center text-secondary w-22">Parcelas</div>
            <div class="font-medium text-md text-center text-secondary w-36">Valor</div>
        </div>

        <div class="w-full mt-4 border-b"></div>

        <div *ngIf="!loading">
            <mat-expansion-panel
                *ngFor="let recebivel of dataSource; index as i"
                [hideToggle]="false"
                class="shadow-none w-full m-0 p-0"
                [id]="i"
            >
                <mat-expansion-panel-header class="h-15 p-0 flex flex-row justify-between w-full">
                    <div class="w-full items-center">
                        <div class="flex w-full flex-row items-center justify-between">
                            <div
                                [matTooltip]="recebivel.nfParcelas[0].sacado?.razaoSocial"
                                class="md:text-base flex text-md text-left pt-2 font-medium w-64"
                            >
                                <mat-checkbox
                                    *ngIf="recebivel.nfParcelas.length === 1"
                                    class="select-checkbox mt-2 dark:text-secondary"
                                    [checked]="recebiveisSelected?.includes(recebivel.nfParcelas[0].id)"
                                    (change)="addIdRecebivelSelected($event.checked, recebivel.nfParcelas[0])"
                                >
                                </mat-checkbox>

                                <p class="mt-2 truncate" *ngIf="recebivel.nfParcelas[0].sacado?.razaoSocial">
                                    {{ recebivel.nfParcelas[0].sacado?.razaoSocial }}
                                </p>
                            </div>

                            <div class="text-md text-center w-40 mt-5 truncate">
                                <p>
                                    {{ recebivel.minibanco?.nomeFantasia || recebivel.minibanco?.razaoSocial }}
                                </p>
                            </div>

                            <div class="text-md text-center w-30 mt-5">
                                {{ recebivel.dtEmissao | date : "dd/MM/yyyy" : "UTC" }}
                            </div>

                            <div class="text-md text-center w-30 mt-5">
                                {{ recebivel.dtVencimento | date : "dd/MM/yyyy" : "UTC" }}
                            </div>

                            <div class="text-md text-center w-22 mt-5">{{ recebivel.nfParcelas.length }}</div>

                            <div class="text-md text-center w-36 mt-5">
                                {{ recebivel.valor | currency : "BRL" : "symbol" : "1.2-2" }}
                            </div>
                        </div>

                        <div class="w-full mt-4 border-b"></div>
                    </div>
                </mat-expansion-panel-header>

                <mat-panel-description *ngIf="recebivel.nfParcelas.length > 1" class="w-full h-full flex flex-col">
                    <div
                        class="flex w-full flex-row items-center justify-between py-2"
                        *ngFor="let parcela of recebivel.nfParcelas; index as i"
                    >
                        <div
                            [matTooltip]="parcela.sacado?.razaoSocial"
                            class="md:text-base flex text-md text-left text-secondary font-medium -ml-6 w-64"
                        >
                            <mat-checkbox
                                class="select-checkbox"
                                [checked]="recebiveisSelected?.includes(parcela.id)"
                                (change)="addIdRecebivelSelected($event.checked, parcela)"
                            ></mat-checkbox>
                            <p class="text-sm mt-px truncate">
                                {{ parcela.sacado?.razaoSocial }}
                            </p>
                        </div>

                        <div class="text-sm text-center text-secondary w-40 truncate">
                            {{ recebivel.minibancoId }}
                        </div>

                        <div class="text-sm text-center text-secondary w-30">
                            {{ parcela.dtEmissao | date : "dd/MM/yyyy" : "UTC" }}
                        </div>

                        <div class="text-sm text-center text-secondary w-30">
                            {{ parcela.dtVencimento | date : "dd/MM/yyyy" : "UTC" }}
                        </div>

                        <div class="text-sm text-center text-secondary w-22">
                            {{ i + 1 }} de {{ recebivel.nfParcelas.length }}
                        </div>

                        <div class="text-sm text-center -mr-6 text-secondary w-36">
                            {{ parcela.valor | currency : "BRL" : "symbol" : "1.2-2" }}
                        </div>
                    </div>

                    <div class="w-full mt-0 border-b"></div>
                </mat-panel-description>
            </mat-expansion-panel>
        </div>

        <div class="w-full flex items-center justify-center p-20" *ngIf="loading">
            <mat-spinner strokeWidth="4" diameter="40"></mat-spinner>
        </div>

        <div class="w-full text-center p-20" *ngIf="!dataSource.length && !loading">
            <h2>Não há recebíveis disponíveis para esse cedente</h2>
        </div>

        <div class="col-span-8 self-center text-2xl font-bold tracking-tight hidden md:flex"></div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="w-full flex md:flex-row flex-col items-center justify-between">
        <div class="flex flex-row w-full col-span-12 text-left md:justify-start justify-center my-3">
            <p class="font-medium text-lg mt-1 mr-4 text-right text-secondary">TOTAL</p>
            <p class="text-2xl font-bold">{{ total | currency : "BRL" : "symbol" : "1.2-2" }}</p>
        </div>

        <div class="md:w-1/3 w-full flex flex-row items-center justify-between md:justify-end">
            <button mat-button mat-dialog-close>Cancel</button>
            <button mat-button [mat-dialog-close]="false" class="bg-primary text-white" cdkFocusInitial>Ok</button>
        </div>
    </div>
</mat-dialog-actions>
