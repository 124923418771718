import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DateComponent } from "./date.component";
import { NgxMaskModule } from "ngx-mask";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";

@NgModule({
    declarations: [DateComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        FormsModule,
        NgxMaskModule.forRoot(),
    ],
    exports: [DateComponent],
})
export class DateModule {}
