import { Injectable } from "@angular/core";
import { ApiService } from "app/core/api/api.service";
import { IDataAnticipation, IResultCalc } from "./types";
import { Observable } from "rxjs";
import { debounceTime, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class CalculoService {
    constructor(private http: ApiService) {}

    calculateFunding(operationData: IDataAnticipation): Observable<IResultCalc> {
        return this.http.post("oracle", "/calcula-me/funding", operationData).pipe(
            debounceTime(500),
            map((response) => {
                return response;
            }),
        );
    }
}
