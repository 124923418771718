import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import * as moment from "moment";
import { Moment } from "moment";

@Component({
    selector: "uix-date",
    templateUrl: "./date.component.html",
    styleUrls: ["./date.component.scss"],
})
export class DateComponent implements OnInit, OnChanges {
    @Input() placeholder: string;
    @Input() value: Moment;
    @Input() min: Moment;
    @Input() max: Moment;
    @Output() onChangeDate: EventEmitter<string> = new EventEmitter<string>();

    dateMoment: any;
    dateFormated: string;

    constructor() {}

    ngOnInit(): void {
        this.setDateMoment(this.value);
        this.setDateFormated(this.value);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.min) {
            this.min = this.returnAsMoment(changes.min.currentValue);
        }
        if (changes.max) {
            this.max = this.returnAsMoment(changes.max.currentValue);
        }
    }

    setDateFormated(date): void {
        this.dateFormated = this.returnAsString(date);
    }

    setDateMoment(date): void {
        this.dateMoment = moment(date, "DD/MM/YYYY").utc();
    }

    returnAsString(date): string {
        return typeof date === "object" ? date.utc().format("DD/MM/YYYY") : date;
    }

    returnAsMoment(date): moment.Moment {
        return moment(date, "DD/MM/YYYY").utc();
    }

    changeInput(data): void {
        data = this.returnAsString(data.target.value);
        this.setDateFormated(data);
        this.setDateMoment(data);
        if (data.length === 10) {
            this.onChangeDate.emit(data);
        }
    }
}
