import { Injectable } from "@angular/core";
import {
    HttpErrorResponse,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpStatusCode,
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { AuthService } from "app/core/auth/auth.service";
import { AuthUtils } from "app/core/auth/auth.utils";
import { environment } from "../../../environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { of } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private _authService: AuthService, private _snackBar: MatSnackBar, private router: Router) {}

    private isExternalRequest(req: HttpRequest<unknown>) {
        try {
            const url = new URL(req.url);

            return url.origin in environment.vars.whitelist;
        } catch (error) {
            return false;
        }
    }

    private getRequestWithToken(req: HttpRequest<unknown>, hasBearerTokenRequest: boolean) {
        if (!hasBearerTokenRequest) return req;

        return req.clone({
            headers: req.headers.set("Authorization", "Bearer " + this._authService.accessToken),
        });
    }

    private handleForbiddenError() {
        if (this.router.url === "/") {
            return this.router.navigateByUrl("/sign-in");
        }

        return this.router.navigate([".."]);
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler) {
        const hasAccessToken = this._authService.accessToken;
        const isTokenExpired = AuthUtils.isTokenExpired(this._authService.accessToken);
        const isExternalRequest = this.isExternalRequest(req);

        const hasBearerTokenRequest = hasAccessToken && !isTokenExpired && !isExternalRequest;

        const requestWithToken = this.getRequestWithToken(req, hasBearerTokenRequest);

        return next.handle(requestWithToken).pipe(
            catchError(({ status }: HttpErrorResponse) => {
                if (status === HttpStatusCode.Forbidden) {
                    this.handleForbiddenError();

                    return of(new HttpResponse({ status: HttpStatusCode.Forbidden }));
                }

                return of(new HttpResponse({ status: HttpStatusCode.BadRequest }));
            }),
        );
    }
}
