export const ErrorMessage = {
    SERVER_CONNECTION_ERROR: "Ocorreu um erro ao comunicar com o servidor.",
    FILE_INCONSISTENCY_IMPORT_ERROR: "Encontramos erros que impossibilitam a importação de alguns recebíveis.",
    INVALID_XML: "XML inválido.",
    NO_FILE_SELECTED: "Nenhum arquivo selecionado.",
    UNEXPECTED_ERROR: "Ocorreu um erro inesperado na validação dos arquivos.",
};

export const SuccessMessage = {
    PAYABLES_IMPORT_SUCCESS: "Os recebíveis foram importados com sucesso!",
    PAYABLES_CREATION_SUCCESS: "Os recebíveis foram criados com sucesso!",
    SUCCESSFULLY_IMPORTED_SECURITIES: "Titulos importados com sucesso!",
};
