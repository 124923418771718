import { Component, EventEmitter } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "AskWithoutPaymentSlipModal",
    templateUrl: "./ask-without-payment-slip-modal.component.html",
})
export class AskWithoutPaymentSlipModalComponent {

    constructor(public dialogRef: MatDialogRef<AskWithoutPaymentSlipModalComponent>) {}

    close(value: string) {
        this.dialogRef.close(value);
    }
}
