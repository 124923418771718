/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Subject } from "rxjs";
import { fuseAnimations } from "@fuse/animations";
import { MatAccordion } from "@angular/material/expansion";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Cedente } from "app/modules/admin/v1/cedente/interfaces/cedente.types";
import { PayablesFromDatabaseModalService } from "./payables-from-database-modal.service";
import { EnvioNotasModalService } from "../xml-modal/envio-notas-modal.service";
import { Payable } from "app/modules/admin/v1/operacoes/operacoes.types";
import moment, { MomentInput } from "moment";
import { PayableType } from "app/shared/constants/operation.enum";

@Component({
    selector: "app-payables-from-database-modal",
    templateUrl: "./payables-from-database-modal.component.html",
    animations: fuseAnimations,
})
export class PayablesFromDatabaseModalComponent implements OnInit, OnDestroy {
    @ViewChild(MatAccordion) accordion: MatAccordion;

    constructor(
        public dialog: MatDialog,
        public _payablesFromDataBaseService: PayablesFromDatabaseModalService,
        private _envioNotasModalService: EnvioNotasModalService,
        @Inject(MAT_DIALOG_DATA) public data: { assignor: Cedente, payableType: PayableType },
    ) {}

    public total = 0;
    public parcelas = [];
    public dataSource = [];
    public loading = false;
    public assignor: Cedente;
    public recebiveisSelected = [];
    public payables: Partial<Payable>[] = [];

    private _unsubscribeAll = new Subject();

    ngOnInit() {
        this.assignor = this.data.assignor;

        this.getRecebiveisToAnticipate();

        this._envioNotasModalService.getPayables$.subscribe((payables) => {
            this.payables = payables;

            this.recebiveisSelected = this.payables.map((payable) => payable.id);
        });

        this.total = this.payables.reduce((acc, curr) => Number(acc) + Number(curr.valor), 0);

        this._envioNotasModalService.getUpdatePayables$.subscribe((id) => {
            this.addIdRecebivelSelected(false, { id });
        });
    }

    getRecebiveisToAnticipate() {
        this.loading = true;

        const cedenteId = this.assignor?.id;

        this._payablesFromDataBaseService.payablesToAnticipate(cedenteId, this.data.payableType).subscribe((result) => {
            this.loading = false;

            const data = result.map((recebiveisToAnticipate) => {
                return {
                    ...recebiveisToAnticipate,
                    lastDateInsertPayable: recebiveisToAnticipate.nfParcelas.at(-1).createdAt,
                };
            });

            this.dataSource = data.sort((a, b) => {
                return +new Date(b.lastDateInsertPayable) - +new Date(a.lastDateInsertPayable);
            });
        });
    }

    addIdRecebivelSelected(event, parcela: Partial<Payable>) {
        parcela.dtVencimento = this.formatDate(parcela.dtVencimento);
        if (event) {
            this._envioNotasModalService.setPayables = [...this.payables, parcela];
            this.total += Number(parcela.valor);
        }

        if (!event) {
            this._envioNotasModalService.setPayables = this.payables.filter((payables) => payables.id !== parcela.id);
            this.total -= Number(parcela.valor);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    formatDate(dueDate: string | Date): string {
        return moment(dueDate as MomentInput).format("DD/MM/YYYY");
    }
}
