import { NgModule } from "@angular/core";
import { PayableListComponent } from "./payable-list.component";
import { MatTableModule } from "@angular/material/table";
import { PayableListService } from "./payable-list.service";
import { MatIconModule } from "@angular/material/icon";
import { StandardButtonModule } from "../Button/button.module";
import { CommonModule } from "@angular/common";
import { NewCTEDueDateModule } from "../modals/new-cte-due-date/new-cte-due-date.module";
import { NewValueModalModule } from "../modals/new-value-modal/new-value-modal.module";
import { EditPayerModalModule } from "app/components/edit-payer-modal/edit-payer.module";

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        MatIconModule,
        StandardButtonModule,
        NewCTEDueDateModule,
        NewValueModalModule,
        EditPayerModalModule,
    ],
    declarations: [PayableListComponent],
    exports: [PayableListComponent],
    providers: [PayableListService],
})
export class PayableListModule {}
