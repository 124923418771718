<h2 class="font-bold text-3xl mb-4">Defina a data de vencimento</h2>

<form [formGroup]="dueDateform">
    <mat-form-field class="w-full mb-2 col-span-2" appearance="outline">
        <mat-label>Vencimento</mat-label>
        <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="dueDate" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <div class="flex justify-between">
        <Button label="Cancelar" type="transparent" color="accent" (clickEvent)="cancel()" [hideText]="false"></Button>

        <Button
            label="Salvar"
            type="solid"
            color="primary"
            (clickEvent)="updateCteDueDate()"
            [disabled]="!dueDateform.valid"
            [hideText]="false"
        ></Button>
    </div>
</form>
