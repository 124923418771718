<h2 class="font-bold text-3xl mb-4">Defina o novo valor</h2>

<form [formGroup]="valueForm">
    <mat-form-field class="w-full mb-2 col-span-2" appearance="outline">
        <mat-label>Valor</mat-label>
        <input
            formControlName="value"
            inputmode="numeric"
            matInput
            currencyMask
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
        />
    </mat-form-field>

    <div class="flex justify-between">
        <Button
            label="Cancelar"
            [hideText]="false"
            type="transparent"
            color="accent"
            (clickEvent)="cancelEmitter.emit()"
        ></Button>

        <Button
            label="Salvar"
            type="solid"
            color="primary"
            (clickEvent)="updateValue()"
            [disabled]="!valueForm.valid"
            [hideText]="false"
        ></Button>
    </div>
</form>
