import { NgModule } from "@angular/core";
import { NewCTEDueDateComponent } from "./new-cte-due-date.component";
import { CommonModule } from "@angular/common";
import { StandardButtonModule } from "../../Button/button.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { NewCTEDueDateService } from "./new-cte-due-date.service";

@NgModule({
    declarations: [NewCTEDueDateComponent],
    providers: [NewCTEDueDateService],
    imports: [
        CommonModule,
        StandardButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatInputModule,
    ],
    exports: [NewCTEDueDateComponent],
})
export class NewCTEDueDateModule {}
