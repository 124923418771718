import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import {
    Cedente,
    IAssignorCreate,
    NewClient,
    StatusOperacionalAudit,
    StatusOperacionalEnum,
} from "./interfaces/cedente.types";
import { HttpClient } from "@angular/common/http";
import { FetchService } from "app/core/api/fetch.service";
import { PaginationResponse } from "../../../../app.types";
import { Contact } from "../../../../components/v1/contact-card/contact-card.interfaces";
import { ApiService } from "../../../../core/api/api.service";
import { Sacado } from "../operacoes/operacoes.types";
import { IBank } from "./interfaces/account-bank.types";
import { IListAssignorParams } from "./interfaces/assignor-query-params.interface";
import { Assignor } from "./interfaces/assignor.types";
import { ICreditAnalysis } from "./interfaces/credit-analysis.interface";
import { CEPAddress } from "./interfaces/endereco.types";

@Injectable({
    providedIn: "root",
})
export class ClientesService {
    // Private
    public _cedenteChanged: BehaviorSubject<boolean | null> = new BehaviorSubject(false);
    public _cedenteUpdate: BehaviorSubject<Cedente | null> = new BehaviorSubject(null);
    public _assignor: Assignor | null;

    private _cedente: Cedente | null;
    private _cedentes: BehaviorSubject<Cedente[] | null> = new BehaviorSubject(null);
    private _sacados: BehaviorSubject<Sacado[] | null> = new BehaviorSubject(null);
    private _contatos: BehaviorSubject<Contact[] | null> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

    constructor(private api: ApiService, private http: HttpClient, private fetchService: FetchService) {}

    /**
     * Getters & Setters
     */
    get pagination$(): Observable<Pagination> {
        return this._pagination.asObservable();
    }

    set cedentes(val: Cedente[]) {
        this._cedentes.next(val);
    }

    get cedentes$(): Observable<Cedente[]> {
        return this._cedentes.asObservable();
    }

    set sacados(val: Sacado[]) {
        this._sacados.next(val);
    }

    get sacados$(): Observable<Sacado[]> {
        return this._sacados.asObservable();
    }

    set contatos(val: Contact[]) {
        this._contatos.next(val);
    }

    get contatos$(): Observable<Contact[]> {
        return this._contatos.asObservable();
    }

    get cedente$(): Observable<Cedente> {
        return this._cedenteUpdate.asObservable();
    }

    set cedente(val: Cedente) {
        this._cedenteUpdate.next(val);
    }
    get cedente(): Cedente {
        return this._cedente;
    }

    listaCedentes(
        page: number = 1,
        take: number = 10,
        orderBy: string = "id",
        sort: "asc" | "desc" | "" = "asc",
        search: string = "",
    ) {
        return this.api
            .get("crm", "/cedente", {
                params: {
                    page: page > 0 ? page : 1,
                    take,
                    orderBy,
                    sort,
                    search: search,
                },
            })
            .pipe(
                tap((response: PaginationResponse<Cedente>) => {
                    this._pagination.next({
                        length: response.total,
                        size: response.data.length,
                        page: response.page,
                        lastPage: response.lastPage,
                        startIndex: 0,
                        endIndex: 10,
                    });
                    this.cedentes = response.data;
                }),
            );
    }

    async filterAssignors(assignor: string) {
        return this.api
            .get("crm", "/v2/assignor/search-assignors", {
                params: { assignor },
            })
            .toPromise();
    }

    searchCedentes(query: string): Observable<PaginationResponse<Cedente>> {
        return this.api.get("crm", "/cedente", {
            params: {
                page: 1,
                take: 10,
                orderBy: "id",
                sort: "asc",
                search: query,
            },
        });
    }

    getCliente(cedenteId: number): Observable<Cedente> {
        return this.api.get("crm", `/cedente/${cedenteId}`).pipe(
            tap((res: Cedente) => {
                this._cedente = {
                    ...res,
                };
                this._cedenteUpdate.next(res);

                return of(res);
            }),
        );
    }

    getAssignor(assignorId: number): Observable<Assignor> {
        return this.api.get("crm", `/assignor/${assignorId}`).pipe(
            tap((res: Assignor) => {
                this._assignor = res;

                return of(res);
            }),
        );
    }

    updateAssignorStatus(assignorId: number, status: StatusOperacionalEnum, description: string = null) {
        return this.api.put("crm", `/status-operacional/${assignorId}`, { status, description });
    }

    updateAssignorSuggested(assignorId: number, status: string) {
        return this.api.put("crm", `/cedente/${assignorId}`, { status });
    }

    async addCliente(value: NewClient): Promise<{ assignorId?: number; statusCode?: number; message?: string }> {
        return this.api.post("crm", "/assignor", value).toPromise();
    }

    editCliente(value: { campo: string; valor }[] | Partial<Cedente>, cedenteId: number): Observable<Cedente> {
        if (this._cedente.status !== "EM_ANALISE") {
            return this.api.post("crm", `/cedente/sugestao/alteracao/${cedenteId}`, { campos: value });
        } else {
            return this.api.put("crm", `/cedente/${cedenteId}`, value);
        }
    }

    updateAssignor({ assignorId, data }: { assignorId: number; data: IAssignorCreate }) {
        return this.fetchService.put("crm", `/assignor/${assignorId}`, data);
    }

    uploadClientFiles(files: File[], id: number, observation?: string) {
        return this.api.makeFileRequest("crm", `/cedente/upload/${id}`, files, "files", observation).toPromise();
    }

    async fetchAddressByZipCode(cep: string): Promise<CEPAddress> {
        const cleanZipCode = cep.replace(".", "").replace("-", "");

        return this.http.get<CEPAddress>(`https://viacep.com.br/ws/${cleanZipCode}/json/`).toPromise();
    }

    listaSacados(
        page: number = 1,
        take: number = 10,
        orderBy: string = "id",
        sort: "asc" | "desc" | "" = "asc",
        search: string = "",
    ) {
        return this.api
            .get("crm", "/sacado", {
                params: {
                    page: page > 0 ? page : 1,
                    take,
                    orderBy,
                    sort,
                    search,
                },
            })
            .pipe(
                tap((response: PaginationResponse<Sacado>) => {
                    this._pagination.next({
                        length: response.total,
                        size: response.data.length,
                        page: response.page,
                        lastPage: response.lastPage,
                        startIndex: 0,
                        endIndex: 10,
                    });
                    this.sacados = response.data;
                }),
            );
    }

    searchSacados(query: string): Observable<any> {
        return this.api.get("crm", "/sacado", {
            params: {
                page: 1,
                take: 30,
                orderBy: "id",
                sort: "asc",
                search: query,
            },
        });
    }

    getContatos(
        cedenteId: number,
        page: number = 1,
        take: number = 10,
        orderBy: string = "createdAt",
        sort: "asc" | "desc" | "" = "desc",
    ): Observable<PaginationResponse<Contact>> {
        return this.api
            .get("crm", `/cedente/${cedenteId}/contatos`, {
                params: {
                    page: page > 0 ? page : 1,
                    take,
                    orderBy,
                    sort,
                },
            })
            .pipe(
                tap((response: PaginationResponse<Contact>) => {
                    this._contatos.next(response.data);
                    return of(response);
                }),
            );
    }

    deleteContato(cedenteId: number, contactId: number): Observable<Contact> {
        return this.api.delete("crm", `/cedente/${cedenteId}/contatos/${contactId}`);
    }

    getStatusOperacional(cedenteId: number): Observable<StatusOperacionalAudit[]> {
        return this.api.get("crm", `/cedente/status-operacional/${cedenteId}`);
    }

    listAllBanks(): Promise<IBank[]> {
        return this.api.get("crm", "/v2/banks").toPromise();
    }

    async findCreditAnalysis({ assignorId, minibankId }: { assignorId: number; minibankId: number }) {
        return this.api.get("crm", `/v2/assignor/${assignorId}/minibank/${minibankId}/credit-analysis`).toPromise();
    }

    updateCreditAnalysis({ analysisId, data }: { analysisId: number; data: Partial<ICreditAnalysis> }) {
        return this.api.put("crm", `/v2/assignor/credit-analysis/${analysisId}`, data).toPromise();
    }

    async deleteFile(id: number): Promise<any> {
        return this.api.delete("crm", `/arquivo/${id}`).toPromise();
    }

    async listAssignors({ page = 1, take = 10, orderBy = "id", sort = "asc", search = "" }: IListAssignorParams) {
        return this.api
            .get("crm", "/cedente", {
                params: {
                    page: page > 0 ? page : 1,
                    take,
                    orderBy,
                    sort,
                    search: search,
                },
            })
            .pipe(
                tap((response: PaginationResponse<Cedente>) => {
                    this._pagination.next({
                        length: response.total,
                        size: response.data.length,
                        page: response.page,
                        lastPage: response.lastPage,
                        startIndex: 0,
                        endIndex: 10,
                    });
                    this.cedentes = response.data;
                }),
            )
            .toPromise();
    }
}
