import { NgModule } from "@angular/core";
import { InconsistenciesCardComponent } from "./inconsistencies-card.component";
import { CommonModule } from "@angular/common";
import { MatExpansionModule } from "@angular/material/expansion";

@NgModule({
    imports: [CommonModule, MatExpansionModule],
    declarations: [InconsistenciesCardComponent],
    exports: [InconsistenciesCardComponent],
})
export class InconsistenciesCardsModule {}
