<div style="width: calc(100vw - 90px); max-width: 480px">
    <header class="flex gap-3 justify-between pb-7">
        <span class="flex justify-start gap-3 items-center">
            <mat-icon class="inline-block icon-size-7" style="color: inherit">upload_file</mat-icon>
            <h2 class="font-bold text-3xl leading-tight">Importar recebíveis</h2>
        </span>
        <mat-icon svgIcon="heroicons_outline:x" class="cursor-pointer" (click)="close()"></mat-icon>
    </header>
    <div class="grid gap-4">
        <ButtonCard
            [disabled]="payableType === 'CHEQUE'"
            title="Importar recebíveis via XML"
            subtitle="Envie duplicatas no formato de XML. Você pode enviar vários arquivos por vez."
            (onClick)="selectXML()"
        ></ButtonCard>

        <ButtonCard
            title="Importar recebíveis via planilha"
            subtitle="Utilize o modelo da planilha de recebíveis abaixo para enviar seus recebíveis. Você pode enviar apenas um arquivo por vez."
            (onClick)="selectSpreadsheet()"
        ></ButtonCard>

        <ButtonCard
            [disabled]="payableType !== 'CHEQUE'"
            title="Importar recebível via arquivo de imagem"
            subtitle="Envie as imagens dos cheques da operação. Você pode enviar mais de um arquivo por vez."
            (onClick)="selectImg()"
        >
            <div
                matTooltip="Agora a Bankme utiliza Inteligência artificial para preencher informações dos cheques enviados!
                Funcionalidade em fase de teste, confira os dados para evitar inconsistências."
                class="beta-ai absolute rounded-lg px-2 py-1 -top-1 text-xs right-1 text-white font-medium"
            >
                BETA | AI
            </div>
        </ButtonCard>

        <Button
            color="primary"
            type="transparent"
            heroIcon="download"
            label="Baixar modelo de planilha de recebíveis"
            [hideText]="false"
            (clickEvent)="downloadSpreadsheet()"
        ></Button>
    </div>
</div>
