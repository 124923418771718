import { NgModule } from "@angular/core";
import { FileUploadCardComponent } from "./file-upload-card.component";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [MatIconModule, CommonModule],
    declarations: [FileUploadCardComponent],
    exports: [FileUploadCardComponent],
})
export class FileUploadCardModule {}
