import { Injectable } from "@angular/core";
import { ApiService } from "app/core/api/api.service";
import { IBank } from "app/modules/admin/v1/cedente/interfaces/account-bank.types";
import { NewClient } from "app/modules/admin/v1/cedente/interfaces/cedente.types";
import { CEPAddress } from "app/modules/admin/v1/cedente/interfaces/endereco.types";
import { ICreditAnalysis } from "./interface/create-credit-analysis";
import { FetchService } from "app/core/api/fetch.service";
import { ICreateBankAccount } from "./interface/create-bank-account.interface";

@Injectable({
    providedIn: "root",
})
export class CreateAssignorService {
    constructor(private apiService: ApiService, private fetchService: FetchService) {}

    async fetchAddressByZipCode(cep: string): Promise<CEPAddress> {
        const cleanZipCode = cep.replace(".", "").replace("-", "");

        return this.apiService.get("", `https://viacep.com.br/ws/${cleanZipCode}/json/`).toPromise();
    }

    listAllBanks(): Promise<IBank[]> {
        return this.apiService.get("crm", "/v2/banks").toPromise();
    }

    async addCliente(value: NewClient): Promise<{ assignorId?: number; statusCode?: number; message?: string }> {
        return this.fetchService.post("crm", "/assignor", value);
    }

    uploadClientFiles(files: File[], id: number, observation?: string) {
        return this.apiService.makeFileRequest("crm", `/cedente/upload/${id}`, files, "files", observation).toPromise();
    }

    createCreditAnalysis(params: ICreditAnalysis) {
        return this.apiService.post("crm", "/v2/assignor/credit-analysis", params).toPromise();
    }

    createBankAccount(params: ICreateBankAccount, id: number) {
        return this.apiService.post("oracle", `/bff-front-bankme/assignors/${id}/bank-account`, params).toPromise();
    }
}
