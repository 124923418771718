import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "app/core/api/api.service";
import { IUpdateOperationSuggestedByAssignor } from "app/modules/admin/v1/operacoes/interfaces/update-operation-suggested-by-assignor.interface";
import { BehaviorSubject, Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { IOperationInList } from "./interfaces/operation-in-list.interface";
import { IOperationResponse } from "./interfaces/operation-query-response.interface";
import { IOperationsQueryParams } from "./interfaces/operations-query-params.interface";
import { Operacao, OperacaoMode, OperacaoStatus } from "./operacoes.types";

@Injectable({ providedIn: "root" })
export class OperacoesService {
    public _changedOperations: BehaviorSubject<boolean | null> = new BehaviorSubject(false);
    private _operations: BehaviorSubject<IOperationInList[] | null> = new BehaviorSubject(null);
    private _operationModes: BehaviorSubject<OperacaoMode[] | null> = new BehaviorSubject(null);
    private _operationStatus: BehaviorSubject<OperacaoStatus[] | null> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);
    private _operation: BehaviorSubject<Operacao | null> = new BehaviorSubject(null);

    constructor(private api: ApiService, private router: Router) {}

    get pagination$(): Observable<Pagination> {
        return this._pagination.asObservable();
    }

    set operations(val: IOperationInList[]) {
        this._operations.next(val);
    }

    get operations$(): Observable<IOperationInList[]> {
        return this._operations.asObservable();
    }

    get operation$(): Observable<Operacao> {
        return this._operation.asObservable();
    }
    set operation(val: Operacao) {
        this._operation.next(val);
    }

    get operationModes$(): Observable<OperacaoMode[]> {
        return this._operationModes.asObservable();
    }
    set operationModes(val: OperacaoMode[]) {
        this._operationModes.next(val);
    }

    get operationStatus$(): Observable<OperacaoStatus[]> {
        return this._operationStatus.asObservable();
    }
    set operationStatus(val: OperacaoStatus[]) {
        this._operationStatus.next(val);
    }

    isOperacaoConstrucao = false;

    async listOperations(operationsQueryParams: IOperationsQueryParams) {
        const { page, take, search } = operationsQueryParams;

        const params = new URLSearchParams();

        Object.entries(operationsQueryParams).forEach(([key, value]) => {
            if (value) {
                params.append(key, String(value));
            }
        });

        const response: IOperationResponse = await this.api
            .get("crm", "/v2/operations", {
                params: {
                    page,
                    take,
                    search,
                },
            })
            .toPromise();

        this._pagination.next({
            length: response.meta.totalItems,
            size: take,
            page: page - 1,
            lastPage: response.meta.totalPages,
            startIndex: 1,
            endIndex: 10,
        });

        const { data, included } = response;

        const redFlagsAllOperations = included.filter(({ type }) => type === "operationAuditRedFlags");

        const operationsList: IOperationInList[] = data.map((op) => {
            const redFlags = op.relationships.operationAuditRedFlags.data.map(({ id }) => {
                const redflag = redFlagsAllOperations.find((redflag) => redflag.id === String(id));

                if (redflag.id === String(id))
                    return {
                        name: redflag.attributes.name,
                        createdAt: redflag.attributes.createdAt,
                        resolvedAt: redflag.attributes.resolvedAt,
                    };
            });

            const groupedFlags = redFlags.reduce((acc, flag) => {
                const group = flag["name"];
                acc[group] = acc[group] || [];
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                acc[group].push(flag);
                return acc;
            }, {});

            const mappedFlags = Object.keys(groupedFlags).map((key) => {
                return {
                    ...groupedFlags[key][groupedFlags[key].length - 1],
                };
            });

            const flags = mappedFlags.length ? mappedFlags : [];

            return {
                id: op.id,
                assignor: op.attributes.assignor,
                createdAt: op.attributes.createdAt,
                payer: op.attributes.payer,
                status: op.attributes.status,
                amount: op.attributes.amount,
                tax: op.attributes.tax,
                isBinded: op.attributes.hasIntegration,
                redFlags: flags,
                modality: included.find(({ id }) => id === String(op.relationships.mode.data.id)),
                offRisk: included.find(({ id }) => Number(id) === Number(op.relationships.mode.data.id))?.attributes
                    ?.offRisk,
                expiredAt: op.attributes.expiredAt,
                pendingIssues: op.relationships.pendingIssues.data,
            };
        });

        this._operations.next(operationsList);
    }

    getOperacao(operacaoId: number): Observable<Operacao> {
        return this.api.get("crm", `/operacao/${operacaoId}`).pipe(
            tap((operacaoFull: Operacao & { operacao: Operacao }) => {
                if (!operacaoFull) {
                    this.router.navigate(["/404"]);
                }
                const { operacao } = operacaoFull;
                this.operation = { ...operacao, ...operacaoFull };
                return of(operacao);
            }),
        );
    }

    async updateOperacao(statusOperation, operacaoId: number): Promise<Operacao> {
        return this.api.put("crm", `/operacao/${operacaoId}`, statusOperation).toPromise();
    }

    getAllStatusOperation() {
        return this.api.get("crm", "/operacao/status").toPromise();
    }

    getOperationModes() {
        return this.api.get("crm", "/operacao/modes/all").pipe(
            tap((operationModes: OperacaoMode[]) => {
                this.operationModes = operationModes;
                return of(operationModes);
            }),
        );
    }
    getOperacoesStatus() {
        return this.api.get("crm", "/operacao/status").pipe(
            tap((operacaoStatus: OperacaoStatus[]) => {
                this.operationStatus = operacaoStatus;
                return of(operacaoStatus);
            }),
        );
    }

    createOperacao(operacao: Partial<Operacao>) {
        return this.api.post("crm", "/operacao/suggest", operacao);
    }

    aceitaOperacao(operacaoId: number): Observable<Operacao> {
        return this.api.post("crm", `/operacao/${operacaoId}/aprove`, {});
    }
    recusaOperacao(operacaoId: number): Observable<Operacao> {
        return this.api.post("crm", `/operacao/${operacaoId}/refuse`, {});
    }

    createRecebivel(data) {
        return this.api.post("crm", "/recebivel", data);
    }

    updateRecebivel(data, recebivelId: number) {
        return this.api.put("crm", `/recebivel/${recebivelId}`, data);
    }

    desvinculaOperacaoDeRecebivel(operationId: number, data) {
        return this.api.put("crm", `/recebivel/${operationId}/desvincula-recebivel`, data);
    }

    deleteItem(fileId: number): Observable<any> {
        return this.api.delete("crm", `/arquivo/${fileId}`).pipe(
            tap((response: File[]) => {
                return response;
            }),
        );
    }

    updateOperationSuggestedByAssignor(operationId: number, operationData: IUpdateOperationSuggestedByAssignor) {
        return this.api.patch("crm", `/v2/operations/${operationId}/suggested-assignor`, operationData);
    }

    getMinibankPredefinedTaxes(minibankId: number) {
        return this.api.get("crm", `/v2/fees/minibank/${minibankId}`);
    }

    resolveBankerApprovalFlag(operationId: number) {
        return this.api.patch("crm", "/v3/red-flag/resolve", {
            redFlag: "BANKER_APPROVAL",
            operationId,
            checkFlag: true,
        });
    }
}
