import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IsStarterDirective } from "./is-starter.directive";

@NgModule({
    declarations: [IsStarterDirective],
    imports: [CommonModule],
    exports: [IsStarterDirective],
})
export class IsStarterModule {}
