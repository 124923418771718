<div [ngClass]="isOuterModule ? '' : 'm-4'">
    <h2 mat-dialog-title class="font-bold text-3xl">
        {{ !isOuterModule ? "Anexar Documentos" : "" }}
    </h2>

    <mat-dialog-content class="mat-typography" [ngStyle]="isOuterModule ? {} : { width: '75vw', height: '50vh' }">
        <ngx-dropzone
            (change)="onSelect($event)"
            class="w-full h-full drag-n-drop bg-gray-100 dark:bg-gray-600"
            [maxFileSize]="maxFileSize"
        >
            <ngx-dropzone-label class="px-4">
                <mat-icon
                    svgIcon="heroicons_outline:cloud-upload"
                    class="icon-size-24 text-gray-300 dark:text-white"
                ></mat-icon>
                <p class="dark:text-white mb-0">
                    Arraste e solte ou
                    <a class="text-primary dark:text-secondary">selecione os arquivos</a>
                </p>
                <p class="text-secondary text-sm">São aceitos arquivos com tamanho máximo de 5mb</p>
            </ngx-dropzone-label>
            <ngx-dropzone-preview class="xml-card" *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                <ngx-dropzone-label
                    >{{ f.name }}
                    <mat-icon
                        class="absolute top-1 left-1 opacity-50"
                        svgIcon="heroicons_outline:document-text"
                    ></mat-icon>
                </ngx-dropzone-label>
            </ngx-dropzone-preview>
        </ngx-dropzone>
    </mat-dialog-content>
    <div class="flex mt-4">
        <mat-form-field class="fuse-mat-textarea flex-auto">
            <textarea matInput placeholder="Uma breve descrição sobre os arquivos" [(ngModel)]="description"></textarea>
        </mat-form-field>
    </div>

    <mat-dialog-actions align="end" *ngIf="!isOuterModule" class="mt-6">
        <button mat-flat-button mat-dialog-close>Cancelar</button>
        <button
            mat-flat-button
            type="button"
            color="primary"
            [mat-dialog-close]="true"
            [disabled]="files.length === 0 && !description"
            (click)="postFile(description)"
        >
            Enviar
        </button>
    </mat-dialog-actions>
</div>
