import { Component, ElementRef, EventEmitter, Input, Output, ViewChildren } from "@angular/core";

@Component({
    selector: "ButtonCard",
    templateUrl: "./button-card.component.html",
})
export class ButtonCardComponent {
    @Input() title?: string;
    @Input() subtitle?: string;
    @Input() icon?: string;
    @Input() disabled?: boolean = false;

    @ViewChildren("content") content?: ElementRef;

    @Output() onClick = new EventEmitter();

    click() {
        if (this.disabled) return;
        this.onClick.emit();
    }
}
