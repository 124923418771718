import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { FuseNavigationService, FuseVerticalNavigationComponent } from "@fuse/components/navigation";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import { InitialData } from "app/app.types";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { environment } from "environments/environment";

@Component({
    selector: "material-layout",
    templateUrl: "./material.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class MaterialLayoutComponent implements OnInit, OnDestroy {
    data: InitialData;
    isScreenSM: boolean;
    isScreenMD: boolean;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public showNotifications = false;
    public environment = environment.environment;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the resolved route mock-api
        this._activatedRoute.data.subscribe((data: Data) => {
            this.data = data.initialData;
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSM =
                    (matchingAliases.includes("sm") && !matchingAliases.includes("lg")) || !matchingAliases.length;
                this.isScreenMD =
                    (matchingAliases.includes("md") && !matchingAliases.includes("lg")) || !matchingAliases.length;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    toggleNotifications() {
        this.showNotifications = !this.showNotifications;
    }
}
