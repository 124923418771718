/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { ApiUtils } from "./api.utils";

@Injectable({ providedIn: "root" })
export class FetchService {
    async get(service: string, endpoint: string, headers = {}): Promise<any> {
        const token = localStorage?.getItem("access_token");

        const url = ApiUtils.build(service, endpoint);

        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                ...headers,
            },
        };

        try {
            const response = await fetch(url, options);
            return response.json();
        } catch (error) {
            return error;
        }
    }

    async post(service: string, endpoint: string, body?: unknown, headers = {}): Promise<any> {
        const token = localStorage?.getItem("access_token");

        const url = ApiUtils.build(service, endpoint);

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                ...headers,
            },
            body: JSON.stringify(body),
        };

        try {
            const response = await fetch(url, options);
            return response.json();
        } catch (error) {
            return error;
        }
    }

    async put(service: string, endpoint: string, body?: unknown): Promise<any> {
        const token = localStorage?.getItem("access_token");

        const url = ApiUtils.build(service, endpoint);

        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        };

        try {
            const response = await fetch(url, options);
            return response.json();
        } catch (error) {
            return error;
        }
    }
}
