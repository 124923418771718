<div>
    <h1 mat-dialog-title class="text-2xl font-bold">Saiba mais sobre o Funding Bankme</h1>

    <mat-dialog-content class="mat-typograph flex flex-col gap-y-6" fuseScrollbar>
        <div class="flex flex-col gap-2">
            <h2 class="text-xl">Saiba as regras de uso do seu limite disponibilizado.</h2>

            <div class="flex flex-col gap-3">
                <p>
                    É possível escolher no momento do desembolso de sua operação de antecipação a proporção que
                    representará o Saldo em Conta do seu Mini Banco e de Funding Bankme a ser utilizado.
                </p>
                <p>
                    Ao utilizar seu limite em uma operação iremos submeter à avaliação e aprovação prévia da Bankme,
                    conforme política, seguindo as regras de:
                </p>
                <ul class="list-disc pl-4">
                    <li>
                        Limite máximo de 20% de concentração do sacado em relação ao valor global do Mini Banco
                        utilizado em operações, seja de capital próprio, de terceiro e de Funding Bankme;
                    </li>
                    <li>Prazo máximo de operação de 120 dias;</li>
                </ul>
                <p>
                    Nesse caso, consideramos também algumas condições inegociáveis que justificam a recusa de
                    continuidade da operação, sendo elas:
                </p>
                <ul class="list-disc pl-4">
                    <li>
                        Operações realizadas entre “partes relacionadas” (empresas do mesmo grupo, familiares,
                        intercompany);
                    </li>
                    <li>Operações de Boletos sem lastro em Nota Fiscal;</li>
                    <li>Operação de Cheque sustado;</li>
                    <li>
                        Operações cujo cedente e/ou sacado estejam em ação de recuperação judicial ou falência em
                        andamento;
                    </li>
                </ul>
                <p>
                    Respeitadas as condições acima, acompanhe a operação na plataforma, logo aprovaremos sua operação!
                </p>
                <p>Quando recusada, será justificado o motivo no campo de “Observações” em detalhes da operação.</p>

                <p>
                    Fique atento às regras acima antes de negociar com seu cedente e qualquer dúvida procure o time de
                    suporte da Bankme.
                </p>
            </div>
        </div>
        <div class="flex flex-col gap-2">
            <h2 class="text-xl">Fique ligado em como acompanhar as operações com Funding Bankme</h2>
            <div class="flex flex-col gap-3">
                <p>
                    A cada nova operação aprovada com utilização de Funding Bankme, é possível você, Banqueiro,
                    acompanhar na tela “Relatórios” o relatório de “Operações Alavancadas”.
                </p>
                <p>
                    Lá você terá clareza e identificação sobre as proporções de cada operação e liquidação em relação ao
                    valor principal e deságio do limite de Funding da Bankme utilizado/pago para poder comparar com os
                    demais relatórios do seu Mini Banco.
                </p>
                <p>
                    Lembre-se que o Funding Bankme terá preferência em relação ao patrimônio aportado pelo Mini Banco.
                    No caso de inadimplência da operação, iremos liquidar automaticamente o valor de Funding Bankme
                    proporcional não pago do seu Saldo em Conta. A liquidação automática será realizada ainda que o
                    Saldo em Conta do seu Mini Banco não tenha saldo suficiente, a fim de cobrir o valor utilizado.
                </p>
                <p>
                    Sazonalmente, será realizado estudo e monitoramento da carteira de seu Mini Banco a fim de aferir as
                    condições de manutenção do limite aprovado, considerando os aportes em carteira, concentração de
                    cedente, concentração de sacado, taxa de inadimplência, prazo médio praticado e taxa média
                    praticada.
                </p>
                <p>
                    Para fins de aumento, diminuição ou cancelamento do limite disponibilizado por interesse do
                    Banqueiro, será necessário que não haja nenhuma operação com Funding Bankme ainda não liquidada.
                </p>
                <p>
                    Por fim, no caso de resgate de debêntures pelo Mini Banco, você precisará manter capital suficiente
                    para suprir as operações não liquidadas que esteja utilizando Funding Bankme, sem possibilidade de
                    resgate até a quitação do limite.
                </p>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button
            mat-flat-button
            type="button"
            (click)="close('confirm')"
            class="mat-focus-indicator bg-primary text-white mat-button mat-button-base"
        >
            Ok, entendi
        </button>
    </mat-dialog-actions>
</div>
