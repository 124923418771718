import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "app/core/user/user.model";

@Injectable({
    providedIn: "root",
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor() {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    // set user(value: User) {
    //     // Store the value
    //     this._user.next(value);
    // }

    // get user$(): Observable<User> {
    //     return this._user.asObservable();
    // }

    set user(token: User) {
        localStorage.setItem("user", JSON.stringify(token));
    }

    get user$(): User {
        if (localStorage.getItem("user") == "undefined") {
        }
        return JSON.parse(localStorage.getItem("user")) ?? "";
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
}
