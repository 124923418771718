import { Injectable } from "@angular/core";
import { ApiService } from "app/core/api/api.service";
import { Payable } from "app/modules/admin/v1/operacoes/operacoes.types";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class EnvioNotasModalService {
    constructor(private readonly http: ApiService) {}

    private payables: BehaviorSubject<Partial<Payable>[]> = new BehaviorSubject([]);

    private payable = new BehaviorSubject(null);

    get getPayables$() {
        return this.payables.asObservable();
    }

    set setPayables(payables: Partial<Payable>[]) {
        this.payables.next(payables);
    }

    set removePayable(payableId: number) {
        this.payable.next(payableId);
    }

    get getUpdatePayables$() {
        return this.payable.asObservable();
    }

    async convertXML(files: File[]) {
        const response = await this.http.makeFileRequest("oracle", "/payable/xml/convert", files, "files").toPromise();

        return (response as any).body.data;
    }
}
