import { HttpErrorResponse } from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable, throwError } from "rxjs";
// -----------------------------------------------------------------------------------------------------
// @ Api UTILITIES
//
// -----------------------------------------------------------------------------------------------------

export class ApiUtils {
    /**
     * Constructor
     */
    constructor() {}

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Build url
     *
     * @param service
     * @param endpoint
     */
    static build(service: string, endpoint: string): string {
        // Return if there is no endpoint
        if (!endpoint || endpoint === "") {
            return service;
        }

        const { apis } = environment.vars;

        if (apis.hasOwnProperty(service)) {
            return `${apis[service]}${endpoint}`;
        }

        return endpoint;
    }

    static handleError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
        return throwError(error);
    }
}
