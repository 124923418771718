import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MAX_FILE_SIZE } from "app/utils/constants/file-size.constant";
import { NgxDropzoneChangeEvent } from "ngx-dropzone";

@Injectable({ providedIn: "root" })
export class FileValidator {
    constructor(private readonly snackbar: MatSnackBar) {}

    private maxFileSize = MAX_FILE_SIZE;
    private duration = 5000;

    validateSize(files: File[]): boolean {
        const hasOverSize = files.some((file) => file.size > this.maxFileSize);
        if (hasOverSize) this.showError();

        return !hasOverSize;
    }

    validateType(files: File[]): boolean {
        const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
        const hasValidType = files.every((file) => allowedTypes.includes(file.type));

        if (!hasValidType) this.showError("Os arquivos devem ser do tipo Image ou PDF");

        return hasValidType;
    }

    validateEvent(event: NgxDropzoneChangeEvent): boolean {
        if (event.rejectedFiles?.length) this.showError();

        return event.rejectedFiles?.length === 0;
    }

    private showError(message: string = "Os arquivos não podem ser maiores que 5 MB") {
        this.snackbar.open(message, "x", {
            duration: this.duration,
            panelClass: "snack-bar-error",
        });
    }
}
