import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "app/core/api/api.service";
import { IUpdatePayerDto } from "./interfaces/update-payer.interface";
import { IPayerParams } from "./interfaces/list-payer-params.interface";
import { FetchService } from "app/core/api/fetch.service";
import { ICreatePayer } from "./interfaces/create-payer.interface";

@Injectable({
    providedIn: "root",
})
export class PayerService {
    constructor(private apiService: ApiService, private http: HttpClient, private fetchService: FetchService) {}

    addPayer(data: ICreatePayer) {
        return this.fetchService.post("crm", "/payer", data);
    }

    updatePayer(data: IUpdatePayerDto) {
        return this.fetchService.put("crm", "/payer", data);
    }

    getUser(id: number) {
        return this.apiService.get("crm", `/payer/${id}`);
    }

    getUsersList(params: IPayerParams) {
        return this.apiService.get("crm", "/payer", params ? { params } : null);
    }

    getAddressByZipCode(zipCode: string) {
        return this.http.get(`https://viacep.com.br/ws/${zipCode}/json`);
    }
}
