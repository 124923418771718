export function calculateDiferenceDays(date: Date | string) {
    const dueDate = new Date(date);
    const today = new Date();
    const diffTime = Math.abs(dueDate.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return {
        days: diffDays,
    };
}
