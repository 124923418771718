<div class="modal-height overflow-y-auto p-4">
    <h1 class="text-3xl font-bold">Cadastro de conta bancária</h1>
    <div>
        <form [formGroup]="assignorBankDataForm" class="mt-5" id="bankAccountForm" (submit)="onSubmit()">
            <div class="flex flex-col w-full">
                <span class="font-medium text-black">A quem pertence a conta?*</span>
                <mat-form-field class="w-full">
                    <mat-select
                        formControlName="thirdParty"
                        (selectionChange)="changeThirdParty()"
                        placeholder="Selecione o tipo de conta"
                    >
                        <mat-option value="titular">Titular</mat-option>
                        <mat-option value="terceiro">Terceiro</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="grid grid-cols-2 gap-2" *ngIf="assignorBankDataForm.get('thirdParty').value === 'terceiro'">
                <div class="flex flex-col w-full">
                    <span class="font-medium text-black">Nome ou Razão Social*</span>
                    <mat-form-field class="w-full">
                        <input
                            matInput
                            formControlName="accountHolder"
                            placeholder="Digite o nome ou razão social"
                            inputmode="text"
                            #accountHolder
                            [disabled]="assignorBankDataForm.get('thirdParty').value === 'terceiro'"
                        />
                    </mat-form-field>
                </div>

                <div>
                    <span class="font-medium text-black">CPF ou CPNJ*</span>
                    <mat-form-field class="w-full">
                        <input
                            matInput
                            formControlName="document"
                            placeholder="Digite o CPF ou CPNJ"
                            #document
                            [disabled]="assignorBankDataForm.get('thirdParty').value === 'terceiro'"
                        />
                    </mat-form-field>
                </div>
            </div>

            <div class="w-full flex flex-col">
                <span class="font-medium text-black">Instituição Bancária*</span>
                <mat-form-field>
                    <input
                        (blur)="onBlurBankInput()"
                        matInput
                        placeholder="(código) - nome"
                        formControlName="bank"
                        [matAutocomplete]="banksList"
                    />

                    <mat-autocomplete
                        (optionSelected)="onBankSelected($event)"
                        #banksList="matAutocomplete"
                        [displayWith]="displayBanksListFn"
                    >
                        <mat-option *ngFor="let bank of filteredBanksList" [value]="bank">
                            ({{ bank.codeBank }}) - {{ bank.shortName }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="grid grid-cols-1 gap-2 md:grid-cols-4">
                <div class="flex flex-col w-full md:col-span-3 col-auto">
                    <span class="font-medium text-black">Agência*</span>
                    <mat-form-field>
                        <input
                            matInput
                            formControlName="agency"
                            placeholder="Digite a agencia"
                            mask="0000"
                            inputmode="numeric"
                        />
                    </mat-form-field>
                </div>

                <div class="flex flex-col w-full col-auto">
                    <span class="font-medium text-black">Digito Agência*</span>
                    <mat-form-field>
                        <input
                            matInput
                            formControlName="agencyDigit"
                            placeholder="Digite o digito da agencia"
                            #agencyDigit
                            inputmode="numeric"
                            mask="0"
                            required
                        />
                    </mat-form-field>
                </div>
            </div>

            <div class="grid grid-cols-1 gap-2 md:grid-cols-4">
                <div class="flex flex-col w-full col-auto md:col-span-3">
                    <span class="font-medium text-black">Conta*</span>
                    <mat-form-field floatLabel="never" appearance="fill">
                        <input
                            matInput
                            formControlName="account"
                            placeholder="Digite a conta"
                            #account
                            mask="0*"
                            maxlength="10"
                            inputmode="numeric"
                            required
                        />
                    </mat-form-field>
                </div>

                <div class="flex flex-col w-full col-auto">
                    <span class="font-medium text-black">Digito Conta*</span>
                    <mat-form-field floatLabel="never">
                        <input
                            matInput
                            formControlName="accountDigit"
                            placeholder="Digite o digito da conta"
                            #accountDigit
                            inputmode="numeric"
                            mask="0"
                            required
                        />
                    </mat-form-field>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-2">
                <div class="flex flex-col w-full">
                    <span class="font-medium text-black">Tipo Chave PIX*</span>
                    <mat-form-field floatLabel="never">
                        <mat-select placeholder="Tipo de Chave PIX" formControlName="pixKeyType" required>
                            <mat-option [value]="null">Selecione...</mat-option>
                            <mat-option *ngFor="let pix of pixTypes" [value]="pix.value">
                                {{ pix.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="flex flex-col w-full">
                    <span class="font-medium text-black">Chave PIX*</span>
                    <mat-form-field>
                        <input
                            matInput
                            formControlName="pix"
                            [mask]="setMaskPix()"
                            placeholder="Digite a chave PIX"
                            required
                        />
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="flex items-center justify-end mt-10 gap-8">
    <button mat-button mat-dialog-close>Cancelar</button>
    <Button
        [hideText]="false"
        label="Salvar"
        type="solid"
        color="primary"
        (clickEvent)="onSubmit()"
        [disabled]="false"
    ></Button>
</div>
