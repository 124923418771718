import { Component, Input } from "@angular/core";
import { IOutputPayableError } from "app/modules/admin/v2/operation/interfaces/output-payable-error.interface";

@Component({
    selector: "InconsistenciesCards",
    templateUrl: "./inconsistencies-card.component.html",
})
export class InconsistenciesCardComponent {
    @Input() inconsistencies!: IOutputPayableError;
}
