import { Component, Input, OnInit } from "@angular/core";
import { UserService } from "app/core/user/user.service";

@Component({
    selector: "app-platform-updates",
    templateUrl: "./platform-updates.component.html",
    styleUrls: ["./platform-updates.component.scss"],
})
export class PlatformUpdatesComponent implements OnInit {
    constructor(private readonly userService: UserService) {}

    @Input() hasText: boolean = true;
    @Input() iconSize: "sm" | "md" = "md";

    ngOnInit() {
        window["beamer_config"] = {
            product_id: "wEWyMqtI50025",
            selector: ".beamerButton",
            display: "right",
            top: 0,
            right: -20,
            language: "PT-BR",
            filter: "admin",
            lazy: true,
            alert: true,
            button: false,
            counter: true,
            user_firstname: this.userService.user$.user.firstName,
            user_lastname: this.userService.user$.user.lastName,
            user_email: this.userService.user$.user.email,
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call
        (window as any).Beamer?.init();
    }
}
