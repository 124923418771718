import { GrowthBook } from "@growthbook/growthbook";
import { environment } from "environments/environment";

export const growthbook = new GrowthBook({
    apiHost: "https://api.growthbook.bankme.tech",
    clientKey: environment.vars.growthbook.clientKey,
    enableDevMode: !environment.production,
});

growthbook.loadFeatures({ autoRefresh: true });
