<mat-expansion-panel *ngFor="let item of inconsistencies.errors.details" class="border-2 border-blue-100 mb-4">
    <mat-expansion-panel-header class="bg-gray-100 hover:bg-gray-200">
        <h3 class="font-bold text-xl">{{ item.file }}</h3>
    </mat-expansion-panel-header>
    <div class="bg-white py-5">
        <span class="text-gray-600">Problema identificado</span>
        <hr class="mt-2 mb-4" />
        <p *ngFor="let inconsistency of item.fileFailures" class="my-4 text-black">
            {{ inconsistency }}
        </p>
        <div *ngFor="let payableInconsistency of item.payablesFailures" class="text-black">
            <div *ngFor="let inconsistency of payableInconsistency.inconsistency" class="my-4 flex gap-6">
                <span class="inline-block" *ngIf="payableInconsistency?.row"
                    >Linha: {{ payableInconsistency?.row }}</span
                >
                <span class="inline-block">{{ payableInconsistency.name }}</span>
                <span class="inline-block">{{ inconsistency }}</span>
            </div>
        </div>
    </div>
</mat-expansion-panel>
