import { Injectable } from "@angular/core";
import { Payable } from "app/modules/admin/v1/operacoes/operacoes.types";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class OperationSummaryService {
    private operationSummaryState = new BehaviorSubject(null);
    public currentOperationSummaryState = this.operationSummaryState.asObservable();

    private payableState = new BehaviorSubject([]);
    public currentPayableState = this.payableState.asObservable();

    public operationExpensesState = new BehaviorSubject({ type: "updateExpense", value: 0 });
    public currentExpensesState = this.operationExpensesState.asObservable();

    private totalRepurchaseSourceState = new BehaviorSubject<number>(0);
    public totalRepurchaseState = this.totalRepurchaseSourceState.asObservable();

    updateAssignor(value: string): void {
        this.operationSummaryState.next({ type: "updateAssignorName", value });
    }

    updateMinibank(value: string): void {
        this.operationSummaryState.next({ type: "updateMinibankName", value });
    }

    updateRisk(value: string): void {
        this.operationSummaryState.next({ type: "updateRiskName", value });
    }

    updateMode(value: string): void {
        this.operationSummaryState.next({ type: "updateModeName", value });
    }

    updateTaxRate(value: string): void {
        this.operationSummaryState.next({ type: "updateTaxRate", value });
    }

    updateCurrentPayables(value: Partial<Payable>[]): void {
        this.payableState.next(value);
    }

    updateCurrentAmount(amount: number) {
        this.operationSummaryState.next(amount);
    }

    updateExpense(value: number) {
        this.operationExpensesState.next({ type: "updateExpense", value });
    }

    updateDisbursement(value: string): void {
        this.operationSummaryState.next({ type: "updateDisbursement", value });
    }

    updateTotalRepurchase(value: number): void {
        this.operationSummaryState.next({ type: "updateTotalRepurchase", value });
    }
}
