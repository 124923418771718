import { Component, Input, OnInit } from "@angular/core";

enum ParagraphLevel {
    P1 = "P1",
    P2 = "P2",
    P3 = "P3",
}

@Component({
    selector: "Paragraph",
    templateUrl: "./paragraph.component.html",
    styleUrls: ["./paragraph.component.scss"],
})
export class ParagraphComponent implements OnInit {
    @Input() level: string;
    @Input() text: string;
    @Input() variant: string;
    @Input() isBold: string;
    @Input() isItalic: string;
    @Input() isTruncate: string;
    @Input() isUppercase: string;

    public customClass = {};

    ngOnInit(): void {
        const stylesOptions = {
            "font-bold": this.isBold === "true",
            italic: this.isItalic === "true",
            truncate: this.isTruncate === "true",
            uppercase: this.isUppercase === "true",
        };

        const variantOptions = {
            "text-red-300": this.variant === "error",
            "text-green-300": this.variant === "success",
            "text-yellow-300": this.variant === "warning",
            "text-slate-400": this.variant === "secondary",
            "text-gray-500": this.variant === "neutral",
        };

        const levelOptions = {
            "text-sm": this.level === ParagraphLevel.P1,
            "text-base": this.level === ParagraphLevel.P2,
            "text-xs": this.level === ParagraphLevel.P3,
        };
        Object.entries(stylesOptions).forEach(([key, value]) => {
            this.customClass[key] = value;
        });

        Object.entries(variantOptions).forEach(([key, value]) => {
            this.customClass[key] = value;
        });

        Object.entries(levelOptions).forEach(([key, value]) => {
            this.customClass[key] = value;
        });
    }
}
