import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { DateModule } from "app/components/v1/date/date.module";
import { UploadModalModule } from "app/components/v1/upload-modal/upload-modal.module";
import { StandardButtonModule } from "app/components/v2/Button/button.module";
import { SharedModule } from "app/shared/shared.module";
import { NgxCurrencyModule } from "ngx-currency";
import { NgxDropzoneModule } from "ngx-dropzone";
import { NgxMaskModule } from "ngx-mask";
import { MatMenuModule } from "@angular/material/menu";
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from "@angular/material/radio";
import { ButtonCardModule } from "app/components/v2/ButtonCard/button-card.module";
import { CheckboxCardModule } from "app/components/v2/CheckboxCard/checkbox-card.module";
import { FileUploadCardModule } from "app/components/v2/FileUploadCard/file-upload-card.module";
import { InconsistenciesCardsModule } from "app/components/v2/InconsistenciesCard/inconsistencies-card.module";
import { OperationSummaryModule } from "app/components/v2/OperationSummary/operation-summary.module";
import { WarningButtonModule } from "app/components/v2/WarningButton/warning-button.module";
import { FileValidatorModule } from "app/shared/services/file-validator/file-validator.module";
import { ChooseUploadFileModalModule } from "../modals/chooseUploadFileModal/choose-upload-file-modal.module";
import { UploadBankCheckModalModule } from "../modals/uploadBankCheckModal/upload-bank-check-modal.module";
import { BankAccountModalModule } from "./components/bank-account-modal/bank-account-modal.module";
import { PaymentCardsComponent } from "./components/payment-cards/payment-cards.component";
import { RepurchasePayablesModule } from "./components/repurchase-payables-modal/repurchase-payables.module";
import { CreateOperationComponent } from "./create-operation.component";
import { createOperationRoute } from "./create-operation.routing";

@NgModule({
    providers: [
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: "primary" },
        },
    ],
    declarations: [CreateOperationComponent, PaymentCardsComponent],
    imports: [
        NgxMaskModule.forRoot(),
        RouterModule.forChild(createOperationRoute),
        SharedModule,
        MatProgressBarModule,
        StandardButtonModule,
        MatStepperModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatSelectModule,
        MatIconModule,
        NgxCurrencyModule,
        NgxDropzoneModule,
        UploadModalModule,
        DateModule,
        MatDatepickerModule,
        ButtonCardModule,
        CheckboxCardModule,
        FileUploadCardModule,
        OperationSummaryModule,
        InconsistenciesCardsModule,
        ChooseUploadFileModalModule,
        FileValidatorModule,
        MatMenuModule,
        MatRadioModule,
        BankAccountModalModule,
        WarningButtonModule,
        UploadBankCheckModalModule,
        RepurchasePayablesModule,
    ],
    exports: [CreateOperationComponent],
})
export class CreateOperationModule {}
