/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Injectable } from "@angular/core";
import { ApiService } from "app/core/api/api.service";

@Injectable({ providedIn: "root" })
export class CreatePayablesModalService {
    constructor(private _apiService: ApiService) {}

    createRecebivel(data) {
        return this._apiService.post("crm", "/recebivel", data);
    }
}
