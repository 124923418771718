import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
    providedIn: "root",
})
export class PayerMessagesService {
    constructor(private _snackBar: MatSnackBar) {}

    invalidFormMessage() {
        return this._snackBar.open("Alguns campos não foram preenchidos corretamente", "", {
            duration: 1500,
        });
    }

    invalidDocumentMessage() {
        return this._snackBar.open("O Campo 'Documento' não pode ser alterado", "", {
            duration: 2500,
        });
    }

    successSavePayer() {
        return this._snackBar.open("Sacado salvo com sucesso", "ok", {
            duration: 2000,
        });
    }

    errorSavePayer() {
        return this._snackBar.open("Um erro ocorreu ao salvar o Sacado", "Ok", {
            panelClass: ["mat-toolbar", "mat-warn"],
            duration: 2000,
        });
    }
}
