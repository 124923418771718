import { Injectable } from "@angular/core";
import { ApiService } from "app/core/api/api.service";
import { PayableType } from "app/shared/constants/operation.enum";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class PayablesFromDatabaseModalService {
    constructor(private _apiService: ApiService) {}

    payablesToAnticipate(cedenteId: number, payableType: PayableType) {
        return this._apiService
            .get("crm", `/recebivel/recebiveis-to-anticipate/${cedenteId}`, {
                params: { payableType },
            })
            .pipe(map((result) => result));
    }
}
