<div class="flex flex-col py-5 gap-y-6 w-75">
    <div class="flex gap-4 items-center pb-8">
        <mat-icon class="icon-size-8 text-blue-700" svgIcon="heroicons_solid:information-circle"></mat-icon>
        <h2 mat-dialog-title class="font-bold text-2xl">Prosseguir sem a emissão de boletos?</h2>
    </div>
    <div mat-dialog-content>
        <p class="text-base">
            Você tem certeza que prefere continuar sem emitir boletos para essa operação? Prosseguir sem a emissão de boletos pode ocasionar um maior tempo para a conciliação bancária e atualização do saldo do mini banco.
        </p>
    </div>
    <div mat-dialog-actions class="flex items-end justify-end pt-4 gap-x-5">
        <Button type="outline" color="light" label="Cancelar" (clickEvent)="close('cancel')"></Button>
        <Button
            type="solid"
            color="primary"
            label="Continuar"
            [customClass]="'bg-primary text-white'"
            (clickEvent)="close('confirm')"
        ></Button>
    </div>
</div>
