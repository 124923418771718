<div class="flex flex-col h-full py-5 md:px-3 gap-y-6">
    <div>
        <h1 mat-dialog-title class="font-bold text-center text-2xl">Atenção!</h1>
    </div>
    <div mat-dialog-content>
        <p class="text-base">
            Ao escolher o tipo de recebível <span class="font-bold">NÃO</span> será possível mais alterar. Tem certeza?
        </p>
    </div>
    <div mat-dialog-actions class="w-full justify-end flex">
        <button
            mat-flat-button
            type="button"
            mat-dialog-close
            (click)="close('cancel')"
            class="mat-focus-indicator mat-button mat-button-base"
        >
            Cancelar
        </button>
        <button
            mat-flat-button
            type="button"
            (click)="close('confirm')"
            class="mat-focus-indicator bg-primary text-white mat-button mat-button-base"
        >
            Sim
        </button>
    </div>
</div>
