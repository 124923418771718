import { NgModule } from "@angular/core";
import { ButtonComponent } from "./button.component";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [MatIconModule, CommonModule],
    declarations: [ButtonComponent],
    exports: [ButtonComponent],
})
export class StandardButtonModule {}
