import { NgModule } from "@angular/core";
import { WarningButtonComponent } from "./warning-button.component";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [MatIconModule, CommonModule],
    declarations: [WarningButtonComponent],
    exports: [WarningButtonComponent],
})
export class WarningButtonModule {}
