import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApiService } from "app/core/api/api.service";
import { OperationView } from "app/modules/admin/v1/operacoes/operation-view/interfaces/operation-view.interface";
import { UpdateObsFromAttachmentGQL } from "generated/graphql";
import { BehaviorSubject } from "rxjs";
import {
    IOperationAttachment,
    IOperationAttachmentsHistory,
} from "./interfaces/operation-attachments-history.interface";
import {
    IOperationNotificationData,
    IPendingIssue,
    IPendingIssuesResponse,
    IUpdatePendingIssueResponse,
} from "./interfaces/pending-issues.interface";
import { UpdateOperationStatusInput } from "../interfaces/update-operation-status-input.interface";

@Injectable({ providedIn: "root" })
export class OperationViewService {
    operation$ = new BehaviorSubject<IOperationAttachmentsHistory>(null);
    attachment$ = new BehaviorSubject<IOperationAttachment[]>([]);

    private willUpdate$ = new BehaviorSubject<boolean>(null);

    constructor(
        private readonly updateObsFromAttachmentGQL: UpdateObsFromAttachmentGQL,
        private readonly api: ApiService,
        private readonly snackBar: MatSnackBar,
    ) {}

    get getWillUpdate() {
        return this.willUpdate$.asObservable();
    }

    setWillUpdate() {
        this.willUpdate$.next(true);
        this.willUpdate$.next(null);
    }

    async findOneOperation(operationId: number) {
        const operationData: IOperationAttachmentsHistory = await this.api
            .get("crm", `/v2/operation/${operationId}/attachments-history`)
            .toPromise();
        if (!this.attachment$.value.length) {
            this.operation$.next(operationData);
        }
        this.attachment$.next(operationData.anexos);
    }

    async getOperation(operationId: number): Promise<OperationView> {
        return this.api.get("crm", `/v2/operation/${operationId}`).toPromise();
    }

    updateObsFromAttachment(id: number, obs: string) {
        return this.updateObsFromAttachmentGQL.mutate({ id, obs });
    }

    async deleteFileFromAttachment(fileId: number) {
        try {
            await this.api.delete("crm", `/v2/operations/file/${fileId}`).toPromise();
            this.openSnackBar({ message: "Anexo removido com sucesso", class: "snack-bar-success" });
            this.setWillUpdate();
        } catch (error) {
            this.openSnackBar({ message: "Houve um erro ao deletar o anexo", class: "snack-bar-success" });
        }
    }

    openSnackBar(options: { message: string; class: "snack-bar-success" | "snack-bar-error" }) {
        this.snackBar.open(options.message, "x", {
            duration: 2000,
            panelClass: options.class,
        });
    }

    getOperationFees(operationId: number) {
        return this.api.get("crm", `/v2/fees/operation/${operationId}`);
    }

    getOperationPendingIssues(operationId: number): Promise<IPendingIssuesResponse> {
        return this.api.get("bff", `/pending-issues/${operationId}`).toPromise();
    }

    async resolveIssue({
        issue,
        operationNotificationData,
    }: {
        issue: IPendingIssue;
        operationNotificationData: IOperationNotificationData;
    }): Promise<{
        updateSuccess: boolean;
        notificationSuccess: boolean;
    }> {
        const successValidation = {
            updateSuccess: false,
            notificationSuccess: false,
        };

        try {
            const updateResponse: IUpdatePendingIssueResponse = await this.api
                .put("bff", `/pending-issues/${issue.id}`, {
                    data: { ...issue, pendingIssuesBankerResolved: new Date().toISOString() },
                })
                .toPromise();

            if (updateResponse.data.status === 200) {
                successValidation.updateSuccess = true;
            }

            if (!successValidation.updateSuccess) return successValidation;

            const notificationResponse = await this.api
                .post("bff", "/notify-pending-issues", {
                    data: operationNotificationData,
                })
                .toPromise();

            if (notificationResponse.data.status === 200) {
                successValidation.notificationSuccess = true;
            }

            return successValidation;
        } catch {
            return successValidation;
        }
    }

    async updateOperationStatus({
        operationStatus,
        operationId,
        statusChangeReason,
        observation,
    }: UpdateOperationStatusInput) {
        return this.api
            .patch("crm", "/v3/operation-status/", { operationStatus, operationId, statusChangeReason, observation })
            .toPromise();
    }
}
