import { NgModule } from "@angular/core";
import { UploadBankCheckModalComponent } from "./upload-bank-check-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { NgxDropzoneModule } from "ngx-dropzone";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgxMaskModule } from "ngx-mask";
import { PayerService } from "app/modules/admin/v1/payer/payer.service";
import { MatOptionModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { SharedModule } from "app/shared/shared.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatStepperModule } from "@angular/material/stepper";
import { NgxCurrencyModule } from "ngx-currency";
import { UploadBankCheckModalService } from "./upload-bank-check-modal.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";

@NgModule({
    declarations: [UploadBankCheckModalComponent],
    imports: [
        MatButtonModule,
        MatOptionModule,
        MatDialogModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        NgxDropzoneModule,
        SharedModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        NgxMaskModule,
        NgxDropzoneModule,
        MatStepperModule,
        NgxCurrencyModule,
        MatSnackBarModule,
    ],
    providers: [PayerService, UploadBankCheckModalService],
    exports: [UploadBankCheckModalComponent],
})
export class UploadBankCheckModalModule {}
