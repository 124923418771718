import { Injectable } from "@angular/core";
import { ApiService } from "app/core/api/api.service";
import { UnoperatedPayablesResponse } from "app/modules/admin/v2/operation/create/components/repurchase-payables-modal/interfaces/unoperated-payables";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class RepurchasePayableService {
    private readonly unoperatedPayables = new BehaviorSubject<UnoperatedPayablesResponse>(
        {} as UnoperatedPayablesResponse,
    );
    private assignorId: number;
    constructor(private readonly http: ApiService) {}

    get getAssignorId() {
        return this.assignorId;
    }

    set setAssignorId(id: number) {
        this.assignorId = id;
    }

    get getUnoperatedPayables() {
        return this.unoperatedPayables.asObservable();
    }

    set setUnoperatedPayables(data: UnoperatedPayablesResponse) {
        this.unoperatedPayables.next(data);
    }

    async listUnoperatedPayables(
        assignorId: number,
        params: { page?: number; take?: number; payableNumber?: string; payerName?: string; payableType?: string },
    ): Promise<UnoperatedPayablesResponse> {
        try {
            const searchParams = new URLSearchParams();

            Object.entries(params).forEach(([key, value]) => {
                if (value) searchParams.set(key, String(value));
            });

            return this.http
                .get("oracle", `/bff-front-bankme/payable/unoperated/assignor/${assignorId}?${searchParams.toString()}`)
                .toPromise();
        } catch {
            return null;
        }
    }
}
