import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FuseScrollbarModule } from "@fuse/directives/scrollbar/public-api";
import { FuseHorizontalNavigationBasicItemComponent } from "@fuse/components/navigation/horizontal/components/basic/basic.component";
import { FuseHorizontalNavigationBranchItemComponent } from "@fuse/components/navigation/horizontal/components/branch/branch.component";
import { FuseHorizontalNavigationDividerItemComponent } from "@fuse/components/navigation/horizontal/components/divider/divider.component";
import { FuseHorizontalNavigationSpacerItemComponent } from "@fuse/components/navigation/horizontal/components/spacer/spacer.component";
import { FuseHorizontalNavigationComponent } from "@fuse/components/navigation/horizontal/horizontal.component";
import { FuseVerticalNavigationAsideItemComponent } from "@fuse/components/navigation/vertical/components/aside/aside.component";
import { FuseVerticalNavigationBasicItemComponent } from "@fuse/components/navigation/vertical/components/basic/basic.component";
import { FuseVerticalNavigationCollapsableItemComponent } from "@fuse/components/navigation/vertical/components/collapsable/collapsable.component";
import { FuseVerticalNavigationDividerItemComponent } from "@fuse/components/navigation/vertical/components/divider/divider.component";
import { FuseVerticalNavigationGroupItemComponent } from "@fuse/components/navigation/vertical/components/group/group.component";
import { FuseVerticalNavigationSpacerItemComponent } from "@fuse/components/navigation/vertical/components/spacer/spacer.component";
import { FuseVerticalNavigationComponent } from "@fuse/components/navigation/vertical/vertical.component";
import { RoleModule } from "app/directives/role/role.module";
import { PlatformUpdatesModule } from "app/components/v1/platform-updates/platform-updates.module";
import { UserMenuModule } from "app/layout/common/user-menu/user-menu.module";
import { StandardButtonModule } from "app/components/v2/Button/button.module";

@NgModule({
    declarations: [
        FuseHorizontalNavigationBasicItemComponent,
        FuseHorizontalNavigationBranchItemComponent,
        FuseHorizontalNavigationDividerItemComponent,
        FuseHorizontalNavigationSpacerItemComponent,
        FuseHorizontalNavigationComponent,
        FuseVerticalNavigationAsideItemComponent,
        FuseVerticalNavigationBasicItemComponent,
        FuseVerticalNavigationCollapsableItemComponent,
        FuseVerticalNavigationDividerItemComponent,
        FuseVerticalNavigationGroupItemComponent,
        FuseVerticalNavigationSpacerItemComponent,
        FuseVerticalNavigationComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        FuseScrollbarModule,
        RoleModule,
        StandardButtonModule,
        PlatformUpdatesModule,
        UserMenuModule,
    ],
    exports: [FuseHorizontalNavigationComponent, FuseVerticalNavigationComponent],
})
export class FuseNavigationModule {}
