export const environment = {
    production: false,
    environment: "staging",
    vars: {
        apis: {
            crm: "https://api-bankme-hmg-01.bankme.tech",
            oracle: "https://api-oracle-hmg-01.bankme.tech",
            credito: "https://credito.staging.bankme.tech",
            bff: "https://api-oracle-hmg-01.bankme.tech/bff-front-bankme",
            monitor: "https://worker-monitor-hmg-01.bankme.tech/",
        },
        next: "https://v2-app.staging.bankme.tech",
        datadog: {
            clientToken: "pube0b4d3eb039cc3f49a8e6aab60d17f9e",
            applicationId: "e2a61d17-127e-4647-a485-d65d5eacfc5e",
        },
        amplitude: {
            apiKey: "b14ca311720108b69a988f69c0045160",
        },
        growthbook: {
            clientKey: "sdk-LAk6KJwO1xZsGlu3",
        },
        email: "b543abf53743aecc4e0a5ae470286291",
        whitelist: ["https://viacep.com.br"],
        hj: "2717721",
    },
};
