import { gql } from "apollo-angular";
import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
    DateTime: any;
    /** GraphQL Scalar representing the Prisma.Decimal type, based on Decimal.js library. */
    Decimal: any;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: any;
};

export type AcceptedTerms = {
    __typename?: "AcceptedTerms";
    date: Scalars["DateTime"];
    id: Scalars["Int"];
    ip: Scalars["String"];
    user: Usuario;
    userId: Scalars["Int"];
    version: TermsAndConditions;
    versionId: Scalars["Int"];
};

export type AcceptedTermsAvgAggregate = {
    __typename?: "AcceptedTermsAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    userId?: Maybe<Scalars["Float"]>;
    versionId?: Maybe<Scalars["Float"]>;
};

export type AcceptedTermsAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
    versionId?: InputMaybe<SortOrder>;
};

export type AcceptedTermsCountAggregate = {
    __typename?: "AcceptedTermsCountAggregate";
    _all: Scalars["Int"];
    date: Scalars["Int"];
    id: Scalars["Int"];
    ip: Scalars["Int"];
    userId: Scalars["Int"];
    versionId: Scalars["Int"];
};

export type AcceptedTermsCountOrderByAggregateInput = {
    date?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ip?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
    versionId?: InputMaybe<SortOrder>;
};

export type AcceptedTermsCreateInput = {
    date?: InputMaybe<Scalars["DateTime"]>;
    ip: Scalars["String"];
    user: UsuarioCreateNestedOneWithoutAcceptedTermsInput;
    version?: InputMaybe<TermsAndConditionsCreateNestedOneWithoutAcceptedTermsInput>;
};

export type AcceptedTermsCreateManyInput = {
    date?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    ip: Scalars["String"];
    userId: Scalars["Int"];
    versionId?: InputMaybe<Scalars["Int"]>;
};

export type AcceptedTermsCreateManyUserInput = {
    date?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    ip: Scalars["String"];
    versionId?: InputMaybe<Scalars["Int"]>;
};

export type AcceptedTermsCreateManyUserInputEnvelope = {
    data: Array<AcceptedTermsCreateManyUserInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AcceptedTermsCreateManyVersionInput = {
    date?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    ip: Scalars["String"];
    userId: Scalars["Int"];
};

export type AcceptedTermsCreateManyVersionInputEnvelope = {
    data: Array<AcceptedTermsCreateManyVersionInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AcceptedTermsCreateNestedManyWithoutUserInput = {
    connect?: InputMaybe<Array<AcceptedTermsWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<AcceptedTermsCreateOrConnectWithoutUserInput>>;
    create?: InputMaybe<Array<AcceptedTermsCreateWithoutUserInput>>;
    createMany?: InputMaybe<AcceptedTermsCreateManyUserInputEnvelope>;
};

export type AcceptedTermsCreateNestedManyWithoutVersionInput = {
    connect?: InputMaybe<Array<AcceptedTermsWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<AcceptedTermsCreateOrConnectWithoutVersionInput>>;
    create?: InputMaybe<Array<AcceptedTermsCreateWithoutVersionInput>>;
    createMany?: InputMaybe<AcceptedTermsCreateManyVersionInputEnvelope>;
};

export type AcceptedTermsCreateOrConnectWithoutUserInput = {
    create: AcceptedTermsCreateWithoutUserInput;
    where: AcceptedTermsWhereUniqueInput;
};

export type AcceptedTermsCreateOrConnectWithoutVersionInput = {
    create: AcceptedTermsCreateWithoutVersionInput;
    where: AcceptedTermsWhereUniqueInput;
};

export type AcceptedTermsCreateWithoutUserInput = {
    date?: InputMaybe<Scalars["DateTime"]>;
    ip: Scalars["String"];
    version?: InputMaybe<TermsAndConditionsCreateNestedOneWithoutAcceptedTermsInput>;
};

export type AcceptedTermsCreateWithoutVersionInput = {
    date?: InputMaybe<Scalars["DateTime"]>;
    ip: Scalars["String"];
    user: UsuarioCreateNestedOneWithoutAcceptedTermsInput;
};

export type AcceptedTermsGroupBy = {
    __typename?: "AcceptedTermsGroupBy";
    _avg?: Maybe<AcceptedTermsAvgAggregate>;
    _count?: Maybe<AcceptedTermsCountAggregate>;
    _max?: Maybe<AcceptedTermsMaxAggregate>;
    _min?: Maybe<AcceptedTermsMinAggregate>;
    _sum?: Maybe<AcceptedTermsSumAggregate>;
    date: Scalars["DateTime"];
    id: Scalars["Int"];
    ip: Scalars["String"];
    userId: Scalars["Int"];
    versionId: Scalars["Int"];
};

export type AcceptedTermsListRelationFilter = {
    every?: InputMaybe<AcceptedTermsWhereInput>;
    none?: InputMaybe<AcceptedTermsWhereInput>;
    some?: InputMaybe<AcceptedTermsWhereInput>;
};

export type AcceptedTermsMaxAggregate = {
    __typename?: "AcceptedTermsMaxAggregate";
    date?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    ip?: Maybe<Scalars["String"]>;
    userId?: Maybe<Scalars["Int"]>;
    versionId?: Maybe<Scalars["Int"]>;
};

export type AcceptedTermsMaxOrderByAggregateInput = {
    date?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ip?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
    versionId?: InputMaybe<SortOrder>;
};

export type AcceptedTermsMinAggregate = {
    __typename?: "AcceptedTermsMinAggregate";
    date?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    ip?: Maybe<Scalars["String"]>;
    userId?: Maybe<Scalars["Int"]>;
    versionId?: Maybe<Scalars["Int"]>;
};

export type AcceptedTermsMinOrderByAggregateInput = {
    date?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ip?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
    versionId?: InputMaybe<SortOrder>;
};

export type AcceptedTermsOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type AcceptedTermsOrderByWithAggregationInput = {
    _avg?: InputMaybe<AcceptedTermsAvgOrderByAggregateInput>;
    _count?: InputMaybe<AcceptedTermsCountOrderByAggregateInput>;
    _max?: InputMaybe<AcceptedTermsMaxOrderByAggregateInput>;
    _min?: InputMaybe<AcceptedTermsMinOrderByAggregateInput>;
    _sum?: InputMaybe<AcceptedTermsSumOrderByAggregateInput>;
    date?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ip?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
    versionId?: InputMaybe<SortOrder>;
};

export type AcceptedTermsOrderByWithRelationInput = {
    date?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ip?: InputMaybe<SortOrder>;
    user?: InputMaybe<UsuarioOrderByWithRelationInput>;
    userId?: InputMaybe<SortOrder>;
    version?: InputMaybe<TermsAndConditionsOrderByWithRelationInput>;
    versionId?: InputMaybe<SortOrder>;
};

export enum AcceptedTermsScalarFieldEnum {
    Date = "date",
    Id = "id",
    Ip = "ip",
    UserId = "userId",
    VersionId = "versionId",
}

export type AcceptedTermsScalarWhereInput = {
    AND?: InputMaybe<Array<AcceptedTermsScalarWhereInput>>;
    NOT?: InputMaybe<Array<AcceptedTermsScalarWhereInput>>;
    OR?: InputMaybe<Array<AcceptedTermsScalarWhereInput>>;
    date?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    ip?: InputMaybe<StringFilter>;
    userId?: InputMaybe<IntFilter>;
    versionId?: InputMaybe<IntFilter>;
};

export type AcceptedTermsScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<AcceptedTermsScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<AcceptedTermsScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<AcceptedTermsScalarWhereWithAggregatesInput>>;
    date?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ip?: InputMaybe<StringWithAggregatesFilter>;
    userId?: InputMaybe<IntWithAggregatesFilter>;
    versionId?: InputMaybe<IntWithAggregatesFilter>;
};

export type AcceptedTermsSumAggregate = {
    __typename?: "AcceptedTermsSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    userId?: Maybe<Scalars["Int"]>;
    versionId?: Maybe<Scalars["Int"]>;
};

export type AcceptedTermsSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
    versionId?: InputMaybe<SortOrder>;
};

export type AcceptedTermsUpdateInput = {
    date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    ip?: InputMaybe<StringFieldUpdateOperationsInput>;
    user?: InputMaybe<UsuarioUpdateOneRequiredWithoutAcceptedTermsNestedInput>;
    version?: InputMaybe<TermsAndConditionsUpdateOneRequiredWithoutAcceptedTermsNestedInput>;
};

export type AcceptedTermsUpdateManyMutationInput = {
    date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    ip?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type AcceptedTermsUpdateManyWithWhereWithoutUserInput = {
    data: AcceptedTermsUpdateManyMutationInput;
    where: AcceptedTermsScalarWhereInput;
};

export type AcceptedTermsUpdateManyWithWhereWithoutVersionInput = {
    data: AcceptedTermsUpdateManyMutationInput;
    where: AcceptedTermsScalarWhereInput;
};

export type AcceptedTermsUpdateManyWithoutUserNestedInput = {
    connect?: InputMaybe<Array<AcceptedTermsWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<AcceptedTermsCreateOrConnectWithoutUserInput>>;
    create?: InputMaybe<Array<AcceptedTermsCreateWithoutUserInput>>;
    createMany?: InputMaybe<AcceptedTermsCreateManyUserInputEnvelope>;
    delete?: InputMaybe<Array<AcceptedTermsWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<AcceptedTermsScalarWhereInput>>;
    disconnect?: InputMaybe<Array<AcceptedTermsWhereUniqueInput>>;
    set?: InputMaybe<Array<AcceptedTermsWhereUniqueInput>>;
    update?: InputMaybe<Array<AcceptedTermsUpdateWithWhereUniqueWithoutUserInput>>;
    updateMany?: InputMaybe<Array<AcceptedTermsUpdateManyWithWhereWithoutUserInput>>;
    upsert?: InputMaybe<Array<AcceptedTermsUpsertWithWhereUniqueWithoutUserInput>>;
};

export type AcceptedTermsUpdateManyWithoutVersionNestedInput = {
    connect?: InputMaybe<Array<AcceptedTermsWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<AcceptedTermsCreateOrConnectWithoutVersionInput>>;
    create?: InputMaybe<Array<AcceptedTermsCreateWithoutVersionInput>>;
    createMany?: InputMaybe<AcceptedTermsCreateManyVersionInputEnvelope>;
    delete?: InputMaybe<Array<AcceptedTermsWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<AcceptedTermsScalarWhereInput>>;
    disconnect?: InputMaybe<Array<AcceptedTermsWhereUniqueInput>>;
    set?: InputMaybe<Array<AcceptedTermsWhereUniqueInput>>;
    update?: InputMaybe<Array<AcceptedTermsUpdateWithWhereUniqueWithoutVersionInput>>;
    updateMany?: InputMaybe<Array<AcceptedTermsUpdateManyWithWhereWithoutVersionInput>>;
    upsert?: InputMaybe<Array<AcceptedTermsUpsertWithWhereUniqueWithoutVersionInput>>;
};

export type AcceptedTermsUpdateWithWhereUniqueWithoutUserInput = {
    data: AcceptedTermsUpdateWithoutUserInput;
    where: AcceptedTermsWhereUniqueInput;
};

export type AcceptedTermsUpdateWithWhereUniqueWithoutVersionInput = {
    data: AcceptedTermsUpdateWithoutVersionInput;
    where: AcceptedTermsWhereUniqueInput;
};

export type AcceptedTermsUpdateWithoutUserInput = {
    date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    ip?: InputMaybe<StringFieldUpdateOperationsInput>;
    version?: InputMaybe<TermsAndConditionsUpdateOneRequiredWithoutAcceptedTermsNestedInput>;
};

export type AcceptedTermsUpdateWithoutVersionInput = {
    date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    ip?: InputMaybe<StringFieldUpdateOperationsInput>;
    user?: InputMaybe<UsuarioUpdateOneRequiredWithoutAcceptedTermsNestedInput>;
};

export type AcceptedTermsUpsertWithWhereUniqueWithoutUserInput = {
    create: AcceptedTermsCreateWithoutUserInput;
    update: AcceptedTermsUpdateWithoutUserInput;
    where: AcceptedTermsWhereUniqueInput;
};

export type AcceptedTermsUpsertWithWhereUniqueWithoutVersionInput = {
    create: AcceptedTermsCreateWithoutVersionInput;
    update: AcceptedTermsUpdateWithoutVersionInput;
    where: AcceptedTermsWhereUniqueInput;
};

export type AcceptedTermsVersionIdUserIdCompoundUniqueInput = {
    userId: Scalars["Int"];
    versionId: Scalars["Int"];
};

export type AcceptedTermsWhereInput = {
    AND?: InputMaybe<Array<AcceptedTermsWhereInput>>;
    NOT?: InputMaybe<Array<AcceptedTermsWhereInput>>;
    OR?: InputMaybe<Array<AcceptedTermsWhereInput>>;
    date?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    ip?: InputMaybe<StringFilter>;
    user?: InputMaybe<UsuarioRelationFilter>;
    userId?: InputMaybe<IntFilter>;
    version?: InputMaybe<TermsAndConditionsRelationFilter>;
    versionId?: InputMaybe<IntFilter>;
};

export type AcceptedTermsWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
    versionId_userId?: InputMaybe<AcceptedTermsVersionIdUserIdCompoundUniqueInput>;
};

export type AffectedRowsOutput = {
    __typename?: "AffectedRowsOutput";
    count: Scalars["Int"];
};

export type AggregateAcceptedTerms = {
    __typename?: "AggregateAcceptedTerms";
    _avg?: Maybe<AcceptedTermsAvgAggregate>;
    _count?: Maybe<AcceptedTermsCountAggregate>;
    _max?: Maybe<AcceptedTermsMaxAggregate>;
    _min?: Maybe<AcceptedTermsMinAggregate>;
    _sum?: Maybe<AcceptedTermsSumAggregate>;
};

export type AggregateArquivo = {
    __typename?: "AggregateArquivo";
    _avg?: Maybe<ArquivoAvgAggregate>;
    _count?: Maybe<ArquivoCountAggregate>;
    _max?: Maybe<ArquivoMaxAggregate>;
    _min?: Maybe<ArquivoMinAggregate>;
    _sum?: Maybe<ArquivoSumAggregate>;
};

export type AggregateArquivoAudit = {
    __typename?: "AggregateArquivoAudit";
    _avg?: Maybe<ArquivoAuditAvgAggregate>;
    _count?: Maybe<ArquivoAuditCountAggregate>;
    _max?: Maybe<ArquivoAuditMaxAggregate>;
    _min?: Maybe<ArquivoAuditMinAggregate>;
    _sum?: Maybe<ArquivoAuditSumAggregate>;
};

export type AggregateAssignorBankData = {
    __typename?: "AggregateAssignorBankData";
    _avg?: Maybe<AssignorBankDataAvgAggregate>;
    _count?: Maybe<AssignorBankDataCountAggregate>;
    _max?: Maybe<AssignorBankDataMaxAggregate>;
    _min?: Maybe<AssignorBankDataMinAggregate>;
    _sum?: Maybe<AssignorBankDataSumAggregate>;
};

export type AggregateAssignorCrm = {
    __typename?: "AggregateAssignorCRM";
    _avg?: Maybe<AssignorCrmAvgAggregate>;
    _count?: Maybe<AssignorCrmCountAggregate>;
    _max?: Maybe<AssignorCrmMaxAggregate>;
    _min?: Maybe<AssignorCrmMinAggregate>;
    _sum?: Maybe<AssignorCrmSumAggregate>;
};

export type AggregateAssignorDocumentType = {
    __typename?: "AggregateAssignorDocumentType";
    _avg?: Maybe<AssignorDocumentTypeAvgAggregate>;
    _count?: Maybe<AssignorDocumentTypeCountAggregate>;
    _max?: Maybe<AssignorDocumentTypeMaxAggregate>;
    _min?: Maybe<AssignorDocumentTypeMinAggregate>;
    _sum?: Maybe<AssignorDocumentTypeSumAggregate>;
};

export type AggregateAssignorStatusByMinibank = {
    __typename?: "AggregateAssignorStatusByMinibank";
    _avg?: Maybe<AssignorStatusByMinibankAvgAggregate>;
    _count?: Maybe<AssignorStatusByMinibankCountAggregate>;
    _max?: Maybe<AssignorStatusByMinibankMaxAggregate>;
    _min?: Maybe<AssignorStatusByMinibankMinAggregate>;
    _sum?: Maybe<AssignorStatusByMinibankSumAggregate>;
};

export type AggregateBanks = {
    __typename?: "AggregateBanks";
    _avg?: Maybe<BanksAvgAggregate>;
    _count?: Maybe<BanksCountAggregate>;
    _max?: Maybe<BanksMaxAggregate>;
    _min?: Maybe<BanksMinAggregate>;
    _sum?: Maybe<BanksSumAggregate>;
};

export type AggregateCTe = {
    __typename?: "AggregateCTe";
    _avg?: Maybe<CTeAvgAggregate>;
    _count?: Maybe<CTeCountAggregate>;
    _max?: Maybe<CTeMaxAggregate>;
    _min?: Maybe<CTeMinAggregate>;
    _sum?: Maybe<CTeSumAggregate>;
};

export type AggregateCedente = {
    __typename?: "AggregateCedente";
    _avg?: Maybe<CedenteAvgAggregate>;
    _count?: Maybe<CedenteCountAggregate>;
    _max?: Maybe<CedenteMaxAggregate>;
    _min?: Maybe<CedenteMinAggregate>;
    _sum?: Maybe<CedenteSumAggregate>;
};

export type AggregateCedenteAlteracao = {
    __typename?: "AggregateCedenteAlteracao";
    _avg?: Maybe<CedenteAlteracaoAvgAggregate>;
    _count?: Maybe<CedenteAlteracaoCountAggregate>;
    _max?: Maybe<CedenteAlteracaoMaxAggregate>;
    _min?: Maybe<CedenteAlteracaoMinAggregate>;
    _sum?: Maybe<CedenteAlteracaoSumAggregate>;
};

export type AggregateCedenteAnexos = {
    __typename?: "AggregateCedenteAnexos";
    _avg?: Maybe<CedenteAnexosAvgAggregate>;
    _count?: Maybe<CedenteAnexosCountAggregate>;
    _max?: Maybe<CedenteAnexosMaxAggregate>;
    _min?: Maybe<CedenteAnexosMinAggregate>;
    _sum?: Maybe<CedenteAnexosSumAggregate>;
};

export type AggregateCedenteAudit = {
    __typename?: "AggregateCedenteAudit";
    _avg?: Maybe<CedenteAuditAvgAggregate>;
    _count?: Maybe<CedenteAuditCountAggregate>;
    _max?: Maybe<CedenteAuditMaxAggregate>;
    _min?: Maybe<CedenteAuditMinAggregate>;
    _sum?: Maybe<CedenteAuditSumAggregate>;
};

export type AggregateCedenteCadastro = {
    __typename?: "AggregateCedenteCadastro";
    _avg?: Maybe<CedenteCadastroAvgAggregate>;
    _count?: Maybe<CedenteCadastroCountAggregate>;
    _max?: Maybe<CedenteCadastroMaxAggregate>;
    _min?: Maybe<CedenteCadastroMinAggregate>;
    _sum?: Maybe<CedenteCadastroSumAggregate>;
};

export type AggregateCedenteCamposAlteracao = {
    __typename?: "AggregateCedenteCamposAlteracao";
    _avg?: Maybe<CedenteCamposAlteracaoAvgAggregate>;
    _count?: Maybe<CedenteCamposAlteracaoCountAggregate>;
    _max?: Maybe<CedenteCamposAlteracaoMaxAggregate>;
    _min?: Maybe<CedenteCamposAlteracaoMinAggregate>;
    _sum?: Maybe<CedenteCamposAlteracaoSumAggregate>;
};

export type AggregateCedenteCamposCadastro = {
    __typename?: "AggregateCedenteCamposCadastro";
    _avg?: Maybe<CedenteCamposCadastroAvgAggregate>;
    _count?: Maybe<CedenteCamposCadastroCountAggregate>;
    _max?: Maybe<CedenteCamposCadastroMaxAggregate>;
    _min?: Maybe<CedenteCamposCadastroMinAggregate>;
    _sum?: Maybe<CedenteCamposCadastroSumAggregate>;
};

export type AggregateCedenteContato = {
    __typename?: "AggregateCedenteContato";
    _avg?: Maybe<CedenteContatoAvgAggregate>;
    _count?: Maybe<CedenteContatoCountAggregate>;
    _max?: Maybe<CedenteContatoMaxAggregate>;
    _min?: Maybe<CedenteContatoMinAggregate>;
    _sum?: Maybe<CedenteContatoSumAggregate>;
};

export type AggregateCedentesInfo = {
    __typename?: "AggregateCedentesInfo";
    _avg?: Maybe<CedentesInfoAvgAggregate>;
    _count?: Maybe<CedentesInfoCountAggregate>;
    _max?: Maybe<CedentesInfoMaxAggregate>;
    _min?: Maybe<CedentesInfoMinAggregate>;
    _sum?: Maybe<CedentesInfoSumAggregate>;
};

export type AggregateCnaeClasses = {
    __typename?: "AggregateCnaeClasses";
    _avg?: Maybe<CnaeClassesAvgAggregate>;
    _count?: Maybe<CnaeClassesCountAggregate>;
    _max?: Maybe<CnaeClassesMaxAggregate>;
    _min?: Maybe<CnaeClassesMinAggregate>;
    _sum?: Maybe<CnaeClassesSumAggregate>;
};

export type AggregateCnaeDivisoes = {
    __typename?: "AggregateCnaeDivisoes";
    _avg?: Maybe<CnaeDivisoesAvgAggregate>;
    _count?: Maybe<CnaeDivisoesCountAggregate>;
    _max?: Maybe<CnaeDivisoesMaxAggregate>;
    _min?: Maybe<CnaeDivisoesMinAggregate>;
    _sum?: Maybe<CnaeDivisoesSumAggregate>;
};

export type AggregateCnaeGrupos = {
    __typename?: "AggregateCnaeGrupos";
    _avg?: Maybe<CnaeGruposAvgAggregate>;
    _count?: Maybe<CnaeGruposCountAggregate>;
    _max?: Maybe<CnaeGruposMaxAggregate>;
    _min?: Maybe<CnaeGruposMinAggregate>;
    _sum?: Maybe<CnaeGruposSumAggregate>;
};

export type AggregateCnaeSecoes = {
    __typename?: "AggregateCnaeSecoes";
    _count?: Maybe<CnaeSecoesCountAggregate>;
    _max?: Maybe<CnaeSecoesMaxAggregate>;
    _min?: Maybe<CnaeSecoesMinAggregate>;
};

export type AggregateCnaeSubClasses = {
    __typename?: "AggregateCnaeSubClasses";
    _avg?: Maybe<CnaeSubClassesAvgAggregate>;
    _count?: Maybe<CnaeSubClassesCountAggregate>;
    _max?: Maybe<CnaeSubClassesMaxAggregate>;
    _min?: Maybe<CnaeSubClassesMinAggregate>;
    _sum?: Maybe<CnaeSubClassesSumAggregate>;
};

export type AggregateComercialPartnerAudit = {
    __typename?: "AggregateComercialPartnerAudit";
    _avg?: Maybe<ComercialPartnerAuditAvgAggregate>;
    _count?: Maybe<ComercialPartnerAuditCountAggregate>;
    _max?: Maybe<ComercialPartnerAuditMaxAggregate>;
    _min?: Maybe<ComercialPartnerAuditMinAggregate>;
    _sum?: Maybe<ComercialPartnerAuditSumAggregate>;
};

export type AggregateComercialPartnerCommission = {
    __typename?: "AggregateComercialPartnerCommission";
    _avg?: Maybe<ComercialPartnerCommissionAvgAggregate>;
    _count?: Maybe<ComercialPartnerCommissionCountAggregate>;
    _max?: Maybe<ComercialPartnerCommissionMaxAggregate>;
    _min?: Maybe<ComercialPartnerCommissionMinAggregate>;
    _sum?: Maybe<ComercialPartnerCommissionSumAggregate>;
};

export type AggregateContaBancaria = {
    __typename?: "AggregateContaBancaria";
    _avg?: Maybe<ContaBancariaAvgAggregate>;
    _count?: Maybe<ContaBancariaCountAggregate>;
    _max?: Maybe<ContaBancariaMaxAggregate>;
    _min?: Maybe<ContaBancariaMinAggregate>;
    _sum?: Maybe<ContaBancariaSumAggregate>;
};

export type AggregateContato = {
    __typename?: "AggregateContato";
    _avg?: Maybe<ContatoAvgAggregate>;
    _count?: Maybe<ContatoCountAggregate>;
    _max?: Maybe<ContatoMaxAggregate>;
    _min?: Maybe<ContatoMinAggregate>;
    _sum?: Maybe<ContatoSumAggregate>;
};

export type AggregateContatoAudit = {
    __typename?: "AggregateContatoAudit";
    _avg?: Maybe<ContatoAuditAvgAggregate>;
    _count?: Maybe<ContatoAuditCountAggregate>;
    _max?: Maybe<ContatoAuditMaxAggregate>;
    _min?: Maybe<ContatoAuditMinAggregate>;
    _sum?: Maybe<ContatoAuditSumAggregate>;
};

export type AggregateDebenturista = {
    __typename?: "AggregateDebenturista";
    _avg?: Maybe<DebenturistaAvgAggregate>;
    _count?: Maybe<DebenturistaCountAggregate>;
    _max?: Maybe<DebenturistaMaxAggregate>;
    _min?: Maybe<DebenturistaMinAggregate>;
    _sum?: Maybe<DebenturistaSumAggregate>;
};

export type AggregateDebenturistaAudit = {
    __typename?: "AggregateDebenturistaAudit";
    _avg?: Maybe<DebenturistaAuditAvgAggregate>;
    _count?: Maybe<DebenturistaAuditCountAggregate>;
    _max?: Maybe<DebenturistaAuditMaxAggregate>;
    _min?: Maybe<DebenturistaAuditMinAggregate>;
    _sum?: Maybe<DebenturistaAuditSumAggregate>;
};

export type AggregateEmpresa = {
    __typename?: "AggregateEmpresa";
    _avg?: Maybe<EmpresaAvgAggregate>;
    _count?: Maybe<EmpresaCountAggregate>;
    _max?: Maybe<EmpresaMaxAggregate>;
    _min?: Maybe<EmpresaMinAggregate>;
    _sum?: Maybe<EmpresaSumAggregate>;
};

export type AggregateEmpresaAudit = {
    __typename?: "AggregateEmpresaAudit";
    _avg?: Maybe<EmpresaAuditAvgAggregate>;
    _count?: Maybe<EmpresaAuditCountAggregate>;
    _max?: Maybe<EmpresaAuditMaxAggregate>;
    _min?: Maybe<EmpresaAuditMinAggregate>;
    _sum?: Maybe<EmpresaAuditSumAggregate>;
};

export type AggregateEndereco = {
    __typename?: "AggregateEndereco";
    _avg?: Maybe<EnderecoAvgAggregate>;
    _count?: Maybe<EnderecoCountAggregate>;
    _max?: Maybe<EnderecoMaxAggregate>;
    _min?: Maybe<EnderecoMinAggregate>;
    _sum?: Maybe<EnderecoSumAggregate>;
};

export type AggregateEnderecoAlteracao = {
    __typename?: "AggregateEnderecoAlteracao";
    _avg?: Maybe<EnderecoAlteracaoAvgAggregate>;
    _count?: Maybe<EnderecoAlteracaoCountAggregate>;
    _max?: Maybe<EnderecoAlteracaoMaxAggregate>;
    _min?: Maybe<EnderecoAlteracaoMinAggregate>;
    _sum?: Maybe<EnderecoAlteracaoSumAggregate>;
};

export type AggregateEnderecoAudit = {
    __typename?: "AggregateEnderecoAudit";
    _avg?: Maybe<EnderecoAuditAvgAggregate>;
    _count?: Maybe<EnderecoAuditCountAggregate>;
    _max?: Maybe<EnderecoAuditMaxAggregate>;
    _min?: Maybe<EnderecoAuditMinAggregate>;
    _sum?: Maybe<EnderecoAuditSumAggregate>;
};

export type AggregateEnderecoCadastro = {
    __typename?: "AggregateEnderecoCadastro";
    _avg?: Maybe<EnderecoCadastroAvgAggregate>;
    _count?: Maybe<EnderecoCadastroCountAggregate>;
    _max?: Maybe<EnderecoCadastroMaxAggregate>;
    _min?: Maybe<EnderecoCadastroMinAggregate>;
    _sum?: Maybe<EnderecoCadastroSumAggregate>;
};

export type AggregateEnderecoCamposAlteracao = {
    __typename?: "AggregateEnderecoCamposAlteracao";
    _avg?: Maybe<EnderecoCamposAlteracaoAvgAggregate>;
    _count?: Maybe<EnderecoCamposAlteracaoCountAggregate>;
    _max?: Maybe<EnderecoCamposAlteracaoMaxAggregate>;
    _min?: Maybe<EnderecoCamposAlteracaoMinAggregate>;
    _sum?: Maybe<EnderecoCamposAlteracaoSumAggregate>;
};

export type AggregateEnderecoCamposCadastro = {
    __typename?: "AggregateEnderecoCamposCadastro";
    _avg?: Maybe<EnderecoCamposCadastroAvgAggregate>;
    _count?: Maybe<EnderecoCamposCadastroCountAggregate>;
    _max?: Maybe<EnderecoCamposCadastroMaxAggregate>;
    _min?: Maybe<EnderecoCamposCadastroMinAggregate>;
    _sum?: Maybe<EnderecoCamposCadastroSumAggregate>;
};

export type AggregateEvent = {
    __typename?: "AggregateEvent";
    _avg?: Maybe<EventAvgAggregate>;
    _count?: Maybe<EventCountAggregate>;
    _max?: Maybe<EventMaxAggregate>;
    _min?: Maybe<EventMinAggregate>;
    _sum?: Maybe<EventSumAggregate>;
};

export type AggregateExtratoIntegracao = {
    __typename?: "AggregateExtratoIntegracao";
    _avg?: Maybe<ExtratoIntegracaoAvgAggregate>;
    _count?: Maybe<ExtratoIntegracaoCountAggregate>;
    _max?: Maybe<ExtratoIntegracaoMaxAggregate>;
    _min?: Maybe<ExtratoIntegracaoMinAggregate>;
    _sum?: Maybe<ExtratoIntegracaoSumAggregate>;
};

export type AggregateGrupoNotificacao = {
    __typename?: "AggregateGrupoNotificacao";
    _avg?: Maybe<GrupoNotificacaoAvgAggregate>;
    _count?: Maybe<GrupoNotificacaoCountAggregate>;
    _max?: Maybe<GrupoNotificacaoMaxAggregate>;
    _min?: Maybe<GrupoNotificacaoMinAggregate>;
    _sum?: Maybe<GrupoNotificacaoSumAggregate>;
};

export type AggregateGruposPermissao = {
    __typename?: "AggregateGruposPermissao";
    _avg?: Maybe<GruposPermissaoAvgAggregate>;
    _count?: Maybe<GruposPermissaoCountAggregate>;
    _max?: Maybe<GruposPermissaoMaxAggregate>;
    _min?: Maybe<GruposPermissaoMinAggregate>;
    _sum?: Maybe<GruposPermissaoSumAggregate>;
};

export type AggregateMinibanco = {
    __typename?: "AggregateMinibanco";
    _avg?: Maybe<MinibancoAvgAggregate>;
    _count?: Maybe<MinibancoCountAggregate>;
    _max?: Maybe<MinibancoMaxAggregate>;
    _min?: Maybe<MinibancoMinAggregate>;
    _sum?: Maybe<MinibancoSumAggregate>;
};

export type AggregateMinibancoAudit = {
    __typename?: "AggregateMinibancoAudit";
    _avg?: Maybe<MinibancoAuditAvgAggregate>;
    _count?: Maybe<MinibancoAuditCountAggregate>;
    _max?: Maybe<MinibancoAuditMaxAggregate>;
    _min?: Maybe<MinibancoAuditMinAggregate>;
    _sum?: Maybe<MinibancoAuditSumAggregate>;
};

export type AggregateMinibancoContato = {
    __typename?: "AggregateMinibancoContato";
    _avg?: Maybe<MinibancoContatoAvgAggregate>;
    _count?: Maybe<MinibancoContatoCountAggregate>;
    _max?: Maybe<MinibancoContatoMaxAggregate>;
    _min?: Maybe<MinibancoContatoMinAggregate>;
    _sum?: Maybe<MinibancoContatoSumAggregate>;
};

export type AggregateMinibancoExtrato = {
    __typename?: "AggregateMinibancoExtrato";
    _avg?: Maybe<MinibancoExtratoAvgAggregate>;
    _count?: Maybe<MinibancoExtratoCountAggregate>;
    _max?: Maybe<MinibancoExtratoMaxAggregate>;
    _min?: Maybe<MinibancoExtratoMinAggregate>;
    _sum?: Maybe<MinibancoExtratoSumAggregate>;
};

export type AggregateMinibankIntegration = {
    __typename?: "AggregateMinibankIntegration";
    _avg?: Maybe<MinibankIntegrationAvgAggregate>;
    _count?: Maybe<MinibankIntegrationCountAggregate>;
    _max?: Maybe<MinibankIntegrationMaxAggregate>;
    _min?: Maybe<MinibankIntegrationMinAggregate>;
    _sum?: Maybe<MinibankIntegrationSumAggregate>;
};

export type AggregateNFe = {
    __typename?: "AggregateNFe";
    _avg?: Maybe<NFeAvgAggregate>;
    _count?: Maybe<NFeCountAggregate>;
    _max?: Maybe<NFeMaxAggregate>;
    _min?: Maybe<NFeMinAggregate>;
    _sum?: Maybe<NFeSumAggregate>;
};

export type AggregateNFeParcela = {
    __typename?: "AggregateNFeParcela";
    _avg?: Maybe<NFeParcelaAvgAggregate>;
    _count?: Maybe<NFeParcelaCountAggregate>;
    _max?: Maybe<NFeParcelaMaxAggregate>;
    _min?: Maybe<NFeParcelaMinAggregate>;
    _sum?: Maybe<NFeParcelaSumAggregate>;
};

export type AggregateNivelPermissao = {
    __typename?: "AggregateNivelPermissao";
    _avg?: Maybe<NivelPermissaoAvgAggregate>;
    _count?: Maybe<NivelPermissaoCountAggregate>;
    _max?: Maybe<NivelPermissaoMaxAggregate>;
    _min?: Maybe<NivelPermissaoMinAggregate>;
    _sum?: Maybe<NivelPermissaoSumAggregate>;
};

export type AggregateOccupation = {
    __typename?: "AggregateOccupation";
    _avg?: Maybe<OccupationAvgAggregate>;
    _count?: Maybe<OccupationCountAggregate>;
    _max?: Maybe<OccupationMaxAggregate>;
    _min?: Maybe<OccupationMinAggregate>;
    _sum?: Maybe<OccupationSumAggregate>;
};

export type AggregateOccupationAudit = {
    __typename?: "AggregateOccupationAudit";
    _avg?: Maybe<OccupationAuditAvgAggregate>;
    _count?: Maybe<OccupationAuditCountAggregate>;
    _max?: Maybe<OccupationAuditMaxAggregate>;
    _min?: Maybe<OccupationAuditMinAggregate>;
    _sum?: Maybe<OccupationAuditSumAggregate>;
};

export type AggregateOperacao = {
    __typename?: "AggregateOperacao";
    _avg?: Maybe<OperacaoAvgAggregate>;
    _count?: Maybe<OperacaoCountAggregate>;
    _max?: Maybe<OperacaoMaxAggregate>;
    _min?: Maybe<OperacaoMinAggregate>;
    _sum?: Maybe<OperacaoSumAggregate>;
};

export type AggregateOperacaoAnexos = {
    __typename?: "AggregateOperacaoAnexos";
    _avg?: Maybe<OperacaoAnexosAvgAggregate>;
    _count?: Maybe<OperacaoAnexosCountAggregate>;
    _max?: Maybe<OperacaoAnexosMaxAggregate>;
    _min?: Maybe<OperacaoAnexosMinAggregate>;
    _sum?: Maybe<OperacaoAnexosSumAggregate>;
};

export type AggregateOperacaoAudit = {
    __typename?: "AggregateOperacaoAudit";
    _avg?: Maybe<OperacaoAuditAvgAggregate>;
    _count?: Maybe<OperacaoAuditCountAggregate>;
    _max?: Maybe<OperacaoAuditMaxAggregate>;
    _min?: Maybe<OperacaoAuditMinAggregate>;
    _sum?: Maybe<OperacaoAuditSumAggregate>;
};

export type AggregateOperacaoChecklist = {
    __typename?: "AggregateOperacaoChecklist";
    _avg?: Maybe<OperacaoChecklistAvgAggregate>;
    _count?: Maybe<OperacaoChecklistCountAggregate>;
    _max?: Maybe<OperacaoChecklistMaxAggregate>;
    _min?: Maybe<OperacaoChecklistMinAggregate>;
    _sum?: Maybe<OperacaoChecklistSumAggregate>;
};

export type AggregateOperacaoIntegracao = {
    __typename?: "AggregateOperacaoIntegracao";
    _avg?: Maybe<OperacaoIntegracaoAvgAggregate>;
    _count?: Maybe<OperacaoIntegracaoCountAggregate>;
    _max?: Maybe<OperacaoIntegracaoMaxAggregate>;
    _min?: Maybe<OperacaoIntegracaoMinAggregate>;
    _sum?: Maybe<OperacaoIntegracaoSumAggregate>;
};

export type AggregateOperacaoModalidade = {
    __typename?: "AggregateOperacaoModalidade";
    _avg?: Maybe<OperacaoModalidadeAvgAggregate>;
    _count?: Maybe<OperacaoModalidadeCountAggregate>;
    _max?: Maybe<OperacaoModalidadeMaxAggregate>;
    _min?: Maybe<OperacaoModalidadeMinAggregate>;
    _sum?: Maybe<OperacaoModalidadeSumAggregate>;
};

export type AggregateOperacaoRascunho = {
    __typename?: "AggregateOperacaoRascunho";
    _avg?: Maybe<OperacaoRascunhoAvgAggregate>;
    _count?: Maybe<OperacaoRascunhoCountAggregate>;
    _max?: Maybe<OperacaoRascunhoMaxAggregate>;
    _min?: Maybe<OperacaoRascunhoMinAggregate>;
    _sum?: Maybe<OperacaoRascunhoSumAggregate>;
};

export type AggregateOperacaoStatus = {
    __typename?: "AggregateOperacaoStatus";
    _avg?: Maybe<OperacaoStatusAvgAggregate>;
    _count?: Maybe<OperacaoStatusCountAggregate>;
    _max?: Maybe<OperacaoStatusMaxAggregate>;
    _min?: Maybe<OperacaoStatusMinAggregate>;
    _sum?: Maybe<OperacaoStatusSumAggregate>;
};

export type AggregateOperacaoSugerida = {
    __typename?: "AggregateOperacaoSugerida";
    _avg?: Maybe<OperacaoSugeridaAvgAggregate>;
    _count?: Maybe<OperacaoSugeridaCountAggregate>;
    _max?: Maybe<OperacaoSugeridaMaxAggregate>;
    _min?: Maybe<OperacaoSugeridaMinAggregate>;
    _sum?: Maybe<OperacaoSugeridaSumAggregate>;
};

export type AggregateOperacaoTipo = {
    __typename?: "AggregateOperacaoTipo";
    _avg?: Maybe<OperacaoTipoAvgAggregate>;
    _count?: Maybe<OperacaoTipoCountAggregate>;
    _max?: Maybe<OperacaoTipoMaxAggregate>;
    _min?: Maybe<OperacaoTipoMinAggregate>;
    _sum?: Maybe<OperacaoTipoSumAggregate>;
};

export type AggregateOperacoesRascunhoView = {
    __typename?: "AggregateOperacoesRascunhoView";
    _avg?: Maybe<OperacoesRascunhoViewAvgAggregate>;
    _count?: Maybe<OperacoesRascunhoViewCountAggregate>;
    _max?: Maybe<OperacoesRascunhoViewMaxAggregate>;
    _min?: Maybe<OperacoesRascunhoViewMinAggregate>;
    _sum?: Maybe<OperacoesRascunhoViewSumAggregate>;
};

export type AggregateOperationCommision = {
    __typename?: "AggregateOperationCommision";
    _avg?: Maybe<OperationCommisionAvgAggregate>;
    _count?: Maybe<OperationCommisionCountAggregate>;
    _max?: Maybe<OperationCommisionMaxAggregate>;
    _min?: Maybe<OperationCommisionMinAggregate>;
    _sum?: Maybe<OperationCommisionSumAggregate>;
};

export type AggregateOperationFees = {
    __typename?: "AggregateOperationFees";
    _avg?: Maybe<OperationFeesAvgAggregate>;
    _count?: Maybe<OperationFeesCountAggregate>;
    _max?: Maybe<OperationFeesMaxAggregate>;
    _min?: Maybe<OperationFeesMinAggregate>;
    _sum?: Maybe<OperationFeesSumAggregate>;
};

export type AggregateOperationUpdates = {
    __typename?: "AggregateOperationUpdates";
    _avg?: Maybe<OperationUpdatesAvgAggregate>;
    _count?: Maybe<OperationUpdatesCountAggregate>;
    _max?: Maybe<OperationUpdatesMaxAggregate>;
    _min?: Maybe<OperationUpdatesMinAggregate>;
    _sum?: Maybe<OperationUpdatesSumAggregate>;
};

export type AggregateOperationsInfo = {
    __typename?: "AggregateOperationsInfo";
    _avg?: Maybe<OperationsInfoAvgAggregate>;
    _count?: Maybe<OperationsInfoCountAggregate>;
    _max?: Maybe<OperationsInfoMaxAggregate>;
    _min?: Maybe<OperationsInfoMinAggregate>;
    _sum?: Maybe<OperationsInfoSumAggregate>;
};

export type AggregateParceiroComercial = {
    __typename?: "AggregateParceiroComercial";
    _avg?: Maybe<ParceiroComercialAvgAggregate>;
    _count?: Maybe<ParceiroComercialCountAggregate>;
    _max?: Maybe<ParceiroComercialMaxAggregate>;
    _min?: Maybe<ParceiroComercialMinAggregate>;
    _sum?: Maybe<ParceiroComercialSumAggregate>;
};

export type AggregatePayableUpdates = {
    __typename?: "AggregatePayableUpdates";
    _avg?: Maybe<PayableUpdatesAvgAggregate>;
    _count?: Maybe<PayableUpdatesCountAggregate>;
    _max?: Maybe<PayableUpdatesMaxAggregate>;
    _min?: Maybe<PayableUpdatesMinAggregate>;
    _sum?: Maybe<PayableUpdatesSumAggregate>;
};

export type AggregatePermissoesGrupos = {
    __typename?: "AggregatePermissoesGrupos";
    _avg?: Maybe<PermissoesGruposAvgAggregate>;
    _count?: Maybe<PermissoesGruposCountAggregate>;
    _max?: Maybe<PermissoesGruposMaxAggregate>;
    _min?: Maybe<PermissoesGruposMinAggregate>;
    _sum?: Maybe<PermissoesGruposSumAggregate>;
};

export type AggregatePreCadastroCedente = {
    __typename?: "AggregatePreCadastroCedente";
    _avg?: Maybe<PreCadastroCedenteAvgAggregate>;
    _count?: Maybe<PreCadastroCedenteCountAggregate>;
    _max?: Maybe<PreCadastroCedenteMaxAggregate>;
    _min?: Maybe<PreCadastroCedenteMinAggregate>;
    _sum?: Maybe<PreCadastroCedenteSumAggregate>;
};

export type AggregateRecebiveisInfo = {
    __typename?: "AggregateRecebiveisInfo";
    _avg?: Maybe<RecebiveisInfoAvgAggregate>;
    _count?: Maybe<RecebiveisInfoCountAggregate>;
    _max?: Maybe<RecebiveisInfoMaxAggregate>;
    _min?: Maybe<RecebiveisInfoMinAggregate>;
    _sum?: Maybe<RecebiveisInfoSumAggregate>;
};

export type AggregateRecebiveisToAnticipate = {
    __typename?: "AggregateRecebiveisToAnticipate";
    _avg?: Maybe<RecebiveisToAnticipateAvgAggregate>;
    _count?: Maybe<RecebiveisToAnticipateCountAggregate>;
    _max?: Maybe<RecebiveisToAnticipateMaxAggregate>;
    _min?: Maybe<RecebiveisToAnticipateMinAggregate>;
    _sum?: Maybe<RecebiveisToAnticipateSumAggregate>;
};

export type AggregateRecebivel = {
    __typename?: "AggregateRecebivel";
    _avg?: Maybe<RecebivelAvgAggregate>;
    _count?: Maybe<RecebivelCountAggregate>;
    _max?: Maybe<RecebivelMaxAggregate>;
    _min?: Maybe<RecebivelMinAggregate>;
    _sum?: Maybe<RecebivelSumAggregate>;
};

export type AggregateRecebivelAudit = {
    __typename?: "AggregateRecebivelAudit";
    _avg?: Maybe<RecebivelAuditAvgAggregate>;
    _count?: Maybe<RecebivelAuditCountAggregate>;
    _max?: Maybe<RecebivelAuditMaxAggregate>;
    _min?: Maybe<RecebivelAuditMinAggregate>;
    _sum?: Maybe<RecebivelAuditSumAggregate>;
};

export type AggregateRecebivelIntegracao = {
    __typename?: "AggregateRecebivelIntegracao";
    _avg?: Maybe<RecebivelIntegracaoAvgAggregate>;
    _count?: Maybe<RecebivelIntegracaoCountAggregate>;
    _max?: Maybe<RecebivelIntegracaoMaxAggregate>;
    _min?: Maybe<RecebivelIntegracaoMinAggregate>;
    _sum?: Maybe<RecebivelIntegracaoSumAggregate>;
};

export type AggregateRecebivelOperado = {
    __typename?: "AggregateRecebivelOperado";
    _avg?: Maybe<RecebivelOperadoAvgAggregate>;
    _count?: Maybe<RecebivelOperadoCountAggregate>;
    _max?: Maybe<RecebivelOperadoMaxAggregate>;
    _min?: Maybe<RecebivelOperadoMinAggregate>;
    _sum?: Maybe<RecebivelOperadoSumAggregate>;
};

export type AggregateRecebivelPagarContas = {
    __typename?: "AggregateRecebivelPagarContas";
    _avg?: Maybe<RecebivelPagarContasAvgAggregate>;
    _count?: Maybe<RecebivelPagarContasCountAggregate>;
    _max?: Maybe<RecebivelPagarContasMaxAggregate>;
    _min?: Maybe<RecebivelPagarContasMinAggregate>;
    _sum?: Maybe<RecebivelPagarContasSumAggregate>;
};

export type AggregateRepresentative = {
    __typename?: "AggregateRepresentative";
    _avg?: Maybe<RepresentativeAvgAggregate>;
    _count?: Maybe<RepresentativeCountAggregate>;
    _max?: Maybe<RepresentativeMaxAggregate>;
    _min?: Maybe<RepresentativeMinAggregate>;
    _sum?: Maybe<RepresentativeSumAggregate>;
};

export type AggregateResponsavelCedente = {
    __typename?: "AggregateResponsavelCedente";
    _avg?: Maybe<ResponsavelCedenteAvgAggregate>;
    _count?: Maybe<ResponsavelCedenteCountAggregate>;
    _max?: Maybe<ResponsavelCedenteMaxAggregate>;
    _min?: Maybe<ResponsavelCedenteMinAggregate>;
    _sum?: Maybe<ResponsavelCedenteSumAggregate>;
};

export type AggregateSacado = {
    __typename?: "AggregateSacado";
    _avg?: Maybe<SacadoAvgAggregate>;
    _count?: Maybe<SacadoCountAggregate>;
    _max?: Maybe<SacadoMaxAggregate>;
    _min?: Maybe<SacadoMinAggregate>;
    _sum?: Maybe<SacadoSumAggregate>;
};

export type AggregateSacadoAudit = {
    __typename?: "AggregateSacadoAudit";
    _avg?: Maybe<SacadoAuditAvgAggregate>;
    _count?: Maybe<SacadoAuditCountAggregate>;
    _max?: Maybe<SacadoAuditMaxAggregate>;
    _min?: Maybe<SacadoAuditMinAggregate>;
    _sum?: Maybe<SacadoAuditSumAggregate>;
};

export type AggregateSacadoContato = {
    __typename?: "AggregateSacadoContato";
    _avg?: Maybe<SacadoContatoAvgAggregate>;
    _count?: Maybe<SacadoContatoCountAggregate>;
    _max?: Maybe<SacadoContatoMaxAggregate>;
    _min?: Maybe<SacadoContatoMinAggregate>;
    _sum?: Maybe<SacadoContatoSumAggregate>;
};

export type AggregateStatusOperacionalAudit = {
    __typename?: "AggregateStatusOperacionalAudit";
    _avg?: Maybe<StatusOperacionalAuditAvgAggregate>;
    _count?: Maybe<StatusOperacionalAuditCountAggregate>;
    _max?: Maybe<StatusOperacionalAuditMaxAggregate>;
    _min?: Maybe<StatusOperacionalAuditMinAggregate>;
    _sum?: Maybe<StatusOperacionalAuditSumAggregate>;
};

export type AggregateStatusOperacionalCedenteMinibanco = {
    __typename?: "AggregateStatusOperacionalCedenteMinibanco";
    _avg?: Maybe<StatusOperacionalCedenteMinibancoAvgAggregate>;
    _count?: Maybe<StatusOperacionalCedenteMinibancoCountAggregate>;
    _max?: Maybe<StatusOperacionalCedenteMinibancoMaxAggregate>;
    _min?: Maybe<StatusOperacionalCedenteMinibancoMinAggregate>;
    _sum?: Maybe<StatusOperacionalCedenteMinibancoSumAggregate>;
};

export type AggregateTakeRateMinibanco = {
    __typename?: "AggregateTakeRateMinibanco";
    _avg?: Maybe<TakeRateMinibancoAvgAggregate>;
    _count?: Maybe<TakeRateMinibancoCountAggregate>;
    _max?: Maybe<TakeRateMinibancoMaxAggregate>;
    _min?: Maybe<TakeRateMinibancoMinAggregate>;
    _sum?: Maybe<TakeRateMinibancoSumAggregate>;
};

export type AggregateTaxasMinibanco = {
    __typename?: "AggregateTaxasMinibanco";
    _avg?: Maybe<TaxasMinibancoAvgAggregate>;
    _count?: Maybe<TaxasMinibancoCountAggregate>;
    _max?: Maybe<TaxasMinibancoMaxAggregate>;
    _min?: Maybe<TaxasMinibancoMinAggregate>;
    _sum?: Maybe<TaxasMinibancoSumAggregate>;
};

export type AggregateTermsAndConditions = {
    __typename?: "AggregateTermsAndConditions";
    _avg?: Maybe<TermsAndConditionsAvgAggregate>;
    _count?: Maybe<TermsAndConditionsCountAggregate>;
    _max?: Maybe<TermsAndConditionsMaxAggregate>;
    _min?: Maybe<TermsAndConditionsMinAggregate>;
    _sum?: Maybe<TermsAndConditionsSumAggregate>;
};

export type AggregateTipoMovimentacao = {
    __typename?: "AggregateTipoMovimentacao";
    _avg?: Maybe<TipoMovimentacaoAvgAggregate>;
    _count?: Maybe<TipoMovimentacaoCountAggregate>;
    _max?: Maybe<TipoMovimentacaoMaxAggregate>;
    _min?: Maybe<TipoMovimentacaoMinAggregate>;
    _sum?: Maybe<TipoMovimentacaoSumAggregate>;
};

export type AggregateUsuario = {
    __typename?: "AggregateUsuario";
    _avg?: Maybe<UsuarioAvgAggregate>;
    _count?: Maybe<UsuarioCountAggregate>;
    _max?: Maybe<UsuarioMaxAggregate>;
    _min?: Maybe<UsuarioMinAggregate>;
    _sum?: Maybe<UsuarioSumAggregate>;
};

export type AggregateUsuarioAudit = {
    __typename?: "AggregateUsuarioAudit";
    _avg?: Maybe<UsuarioAuditAvgAggregate>;
    _count?: Maybe<UsuarioAuditCountAggregate>;
    _max?: Maybe<UsuarioAuditMaxAggregate>;
    _min?: Maybe<UsuarioAuditMinAggregate>;
    _sum?: Maybe<UsuarioAuditSumAggregate>;
};

export type AggregateUsuarioCadastro = {
    __typename?: "AggregateUsuarioCadastro";
    _avg?: Maybe<UsuarioCadastroAvgAggregate>;
    _count?: Maybe<UsuarioCadastroCountAggregate>;
    _max?: Maybe<UsuarioCadastroMaxAggregate>;
    _min?: Maybe<UsuarioCadastroMinAggregate>;
    _sum?: Maybe<UsuarioCadastroSumAggregate>;
};

export type AggregateUsuarioCadastroCampos = {
    __typename?: "AggregateUsuarioCadastroCampos";
    _avg?: Maybe<UsuarioCadastroCamposAvgAggregate>;
    _count?: Maybe<UsuarioCadastroCamposCountAggregate>;
    _max?: Maybe<UsuarioCadastroCamposMaxAggregate>;
    _min?: Maybe<UsuarioCadastroCamposMinAggregate>;
    _sum?: Maybe<UsuarioCadastroCamposSumAggregate>;
};

export type Arquivo = {
    __typename?: "Arquivo";
    _count?: Maybe<ArquivoCount>;
    cedentes: Array<CedenteAnexos>;
    createdAt: Scalars["DateTime"];
    descricao?: Maybe<Scalars["String"]>;
    historico: Array<ArquivoAudit>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
    operacoes: Array<OperacaoAnexos>;
    recebivel?: Maybe<Recebivel>;
    updatedAt: Scalars["DateTime"];
    url: Scalars["String"];
    usuario: Usuario;
    usuarioId: Scalars["Int"];
};

export type ArquivoCedentesArgs = {
    cursor?: InputMaybe<CedenteAnexosWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAnexosScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAnexosOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAnexosWhereInput>;
};

export type ArquivoHistoricoArgs = {
    cursor?: InputMaybe<ArquivoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<ArquivoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ArquivoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoAuditWhereInput>;
};

export type ArquivoOperacoesArgs = {
    cursor?: InputMaybe<OperacaoAnexosWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoAnexosScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoAnexosOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoAnexosWhereInput>;
};

export type ArquivoAudit = {
    __typename?: "ArquivoAudit";
    action: AuditAcoes;
    arquivo: Arquivo;
    arquivoId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuario?: Maybe<Usuario>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ArquivoAuditAvgAggregate = {
    __typename?: "ArquivoAuditAvgAggregate";
    arquivoId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type ArquivoAuditAvgOrderByAggregateInput = {
    arquivoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ArquivoAuditCountAggregate = {
    __typename?: "ArquivoAuditCountAggregate";
    _all: Scalars["Int"];
    action: Scalars["Int"];
    arquivoId: Scalars["Int"];
    createdAt: Scalars["Int"];
    field: Scalars["Int"];
    id: Scalars["Int"];
    ipUsuario: Scalars["Int"];
    newValue: Scalars["Int"];
    oldValue: Scalars["Int"];
    service: Scalars["Int"];
    userAgent: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type ArquivoAuditCountOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    arquivoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ArquivoAuditCreateInput = {
    action?: InputMaybe<AuditAcoes>;
    arquivo: ArquivoCreateNestedOneWithoutHistoricoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistArquivoInput>;
};

export type ArquivoAuditCreateManyArquivoInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type ArquivoAuditCreateManyArquivoInputEnvelope = {
    data: Array<ArquivoAuditCreateManyArquivoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ArquivoAuditCreateManyInput = {
    action?: InputMaybe<AuditAcoes>;
    arquivoId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type ArquivoAuditCreateManyUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    arquivoId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type ArquivoAuditCreateManyUsuarioInputEnvelope = {
    data: Array<ArquivoAuditCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ArquivoAuditCreateNestedManyWithoutArquivoInput = {
    connect?: InputMaybe<Array<ArquivoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ArquivoAuditCreateOrConnectWithoutArquivoInput>>;
    create?: InputMaybe<Array<ArquivoAuditCreateWithoutArquivoInput>>;
    createMany?: InputMaybe<ArquivoAuditCreateManyArquivoInputEnvelope>;
};

export type ArquivoAuditCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<ArquivoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ArquivoAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<ArquivoAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<ArquivoAuditCreateManyUsuarioInputEnvelope>;
};

export type ArquivoAuditCreateOrConnectWithoutArquivoInput = {
    create: ArquivoAuditCreateWithoutArquivoInput;
    where: ArquivoAuditWhereUniqueInput;
};

export type ArquivoAuditCreateOrConnectWithoutUsuarioInput = {
    create: ArquivoAuditCreateWithoutUsuarioInput;
    where: ArquivoAuditWhereUniqueInput;
};

export type ArquivoAuditCreateWithoutArquivoInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistArquivoInput>;
};

export type ArquivoAuditCreateWithoutUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    arquivo: ArquivoCreateNestedOneWithoutHistoricoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type ArquivoAuditGroupBy = {
    __typename?: "ArquivoAuditGroupBy";
    _avg?: Maybe<ArquivoAuditAvgAggregate>;
    _count?: Maybe<ArquivoAuditCountAggregate>;
    _max?: Maybe<ArquivoAuditMaxAggregate>;
    _min?: Maybe<ArquivoAuditMinAggregate>;
    _sum?: Maybe<ArquivoAuditSumAggregate>;
    action: AuditAcoes;
    arquivoId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ArquivoAuditListRelationFilter = {
    every?: InputMaybe<ArquivoAuditWhereInput>;
    none?: InputMaybe<ArquivoAuditWhereInput>;
    some?: InputMaybe<ArquivoAuditWhereInput>;
};

export type ArquivoAuditMaxAggregate = {
    __typename?: "ArquivoAuditMaxAggregate";
    action?: Maybe<AuditAcoes>;
    arquivoId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ArquivoAuditMaxOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    arquivoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ArquivoAuditMinAggregate = {
    __typename?: "ArquivoAuditMinAggregate";
    action?: Maybe<AuditAcoes>;
    arquivoId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ArquivoAuditMinOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    arquivoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ArquivoAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type ArquivoAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<ArquivoAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<ArquivoAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<ArquivoAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<ArquivoAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<ArquivoAuditSumOrderByAggregateInput>;
    action?: InputMaybe<SortOrder>;
    arquivoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ArquivoAuditOrderByWithRelationInput = {
    action?: InputMaybe<SortOrder>;
    arquivo?: InputMaybe<ArquivoOrderByWithRelationInput>;
    arquivoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum ArquivoAuditScalarFieldEnum {
    Action = "action",
    ArquivoId = "arquivoId",
    CreatedAt = "createdAt",
    Field = "field",
    Id = "id",
    IpUsuario = "ipUsuario",
    NewValue = "newValue",
    OldValue = "oldValue",
    Service = "service",
    UserAgent = "userAgent",
    UsuarioId = "usuarioId",
}

export type ArquivoAuditScalarWhereInput = {
    AND?: InputMaybe<Array<ArquivoAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<ArquivoAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<ArquivoAuditScalarWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    arquivoId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type ArquivoAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<ArquivoAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<ArquivoAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<ArquivoAuditScalarWhereWithAggregatesInput>>;
    action?: InputMaybe<EnumAuditAcoesWithAggregatesFilter>;
    arquivoId?: InputMaybe<IntWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    field?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ipUsuario?: InputMaybe<StringWithAggregatesFilter>;
    newValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type ArquivoAuditSumAggregate = {
    __typename?: "ArquivoAuditSumAggregate";
    arquivoId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ArquivoAuditSumOrderByAggregateInput = {
    arquivoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ArquivoAuditUpdateInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneRequiredWithoutHistoricoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistArquivoNestedInput>;
};

export type ArquivoAuditUpdateManyMutationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ArquivoAuditUpdateManyWithWhereWithoutArquivoInput = {
    data: ArquivoAuditUpdateManyMutationInput;
    where: ArquivoAuditScalarWhereInput;
};

export type ArquivoAuditUpdateManyWithWhereWithoutUsuarioInput = {
    data: ArquivoAuditUpdateManyMutationInput;
    where: ArquivoAuditScalarWhereInput;
};

export type ArquivoAuditUpdateManyWithoutArquivoNestedInput = {
    connect?: InputMaybe<Array<ArquivoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ArquivoAuditCreateOrConnectWithoutArquivoInput>>;
    create?: InputMaybe<Array<ArquivoAuditCreateWithoutArquivoInput>>;
    createMany?: InputMaybe<ArquivoAuditCreateManyArquivoInputEnvelope>;
    delete?: InputMaybe<Array<ArquivoAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ArquivoAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ArquivoAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<ArquivoAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<ArquivoAuditUpdateWithWhereUniqueWithoutArquivoInput>>;
    updateMany?: InputMaybe<Array<ArquivoAuditUpdateManyWithWhereWithoutArquivoInput>>;
    upsert?: InputMaybe<Array<ArquivoAuditUpsertWithWhereUniqueWithoutArquivoInput>>;
};

export type ArquivoAuditUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<ArquivoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ArquivoAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<ArquivoAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<ArquivoAuditCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<ArquivoAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ArquivoAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ArquivoAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<ArquivoAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<ArquivoAuditUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<ArquivoAuditUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<ArquivoAuditUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type ArquivoAuditUpdateWithWhereUniqueWithoutArquivoInput = {
    data: ArquivoAuditUpdateWithoutArquivoInput;
    where: ArquivoAuditWhereUniqueInput;
};

export type ArquivoAuditUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: ArquivoAuditUpdateWithoutUsuarioInput;
    where: ArquivoAuditWhereUniqueInput;
};

export type ArquivoAuditUpdateWithoutArquivoInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistArquivoNestedInput>;
};

export type ArquivoAuditUpdateWithoutUsuarioInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneRequiredWithoutHistoricoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ArquivoAuditUpsertWithWhereUniqueWithoutArquivoInput = {
    create: ArquivoAuditCreateWithoutArquivoInput;
    update: ArquivoAuditUpdateWithoutArquivoInput;
    where: ArquivoAuditWhereUniqueInput;
};

export type ArquivoAuditUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: ArquivoAuditCreateWithoutUsuarioInput;
    update: ArquivoAuditUpdateWithoutUsuarioInput;
    where: ArquivoAuditWhereUniqueInput;
};

export type ArquivoAuditWhereInput = {
    AND?: InputMaybe<Array<ArquivoAuditWhereInput>>;
    NOT?: InputMaybe<Array<ArquivoAuditWhereInput>>;
    OR?: InputMaybe<Array<ArquivoAuditWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    arquivo?: InputMaybe<ArquivoRelationFilter>;
    arquivoId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type ArquivoAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type ArquivoAvgAggregate = {
    __typename?: "ArquivoAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type ArquivoAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ArquivoCount = {
    __typename?: "ArquivoCount";
    cedentes: Scalars["Int"];
    historico: Scalars["Int"];
    operacoes: Scalars["Int"];
};

export type ArquivoCountAggregate = {
    __typename?: "ArquivoCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    descricao: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    minibancoId: Scalars["Int"];
    nome: Scalars["Int"];
    updatedAt: Scalars["Int"];
    url: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type ArquivoCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    url?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ArquivoCreateInput = {
    cedentes?: InputMaybe<CedenteAnexosCreateNestedManyWithoutArquivosInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<ArquivoAuditCreateNestedManyWithoutArquivoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutArquivosInput;
    nome: Scalars["String"];
    operacoes?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutArquivosInput>;
    recebivel?: InputMaybe<RecebivelCreateNestedOneWithoutArquivoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    url: Scalars["String"];
    usuario: UsuarioCreateNestedOneWithoutArquivosInput;
};

export type ArquivoCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    url: Scalars["String"];
    usuarioId: Scalars["Int"];
};

export type ArquivoCreateManyMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nome: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    url: Scalars["String"];
    usuarioId: Scalars["Int"];
};

export type ArquivoCreateManyMinibancoInputEnvelope = {
    data: Array<ArquivoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ArquivoCreateManyUsuarioInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    url: Scalars["String"];
};

export type ArquivoCreateManyUsuarioInputEnvelope = {
    data: Array<ArquivoCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ArquivoCreateNestedManyWithoutCedentesInput = {
    connect?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ArquivoCreateOrConnectWithoutCedentesInput>>;
    create?: InputMaybe<Array<ArquivoCreateWithoutCedentesInput>>;
};

export type ArquivoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ArquivoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<ArquivoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<ArquivoCreateManyMinibancoInputEnvelope>;
};

export type ArquivoCreateNestedManyWithoutOperacoesInput = {
    connect?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ArquivoCreateOrConnectWithoutOperacoesInput>>;
    create?: InputMaybe<Array<ArquivoCreateWithoutOperacoesInput>>;
};

export type ArquivoCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ArquivoCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<ArquivoCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<ArquivoCreateManyUsuarioInputEnvelope>;
};

export type ArquivoCreateNestedOneWithoutHistoricoInput = {
    connect?: InputMaybe<ArquivoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ArquivoCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<ArquivoCreateWithoutHistoricoInput>;
};

export type ArquivoCreateNestedOneWithoutRecebivelInput = {
    connect?: InputMaybe<ArquivoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ArquivoCreateOrConnectWithoutRecebivelInput>;
    create?: InputMaybe<ArquivoCreateWithoutRecebivelInput>;
};

export type ArquivoCreateOrConnectWithoutCedentesInput = {
    create: ArquivoCreateWithoutCedentesInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoCreateOrConnectWithoutHistoricoInput = {
    create: ArquivoCreateWithoutHistoricoInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoCreateOrConnectWithoutMinibancoInput = {
    create: ArquivoCreateWithoutMinibancoInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoCreateOrConnectWithoutOperacoesInput = {
    create: ArquivoCreateWithoutOperacoesInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoCreateOrConnectWithoutRecebivelInput = {
    create: ArquivoCreateWithoutRecebivelInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoCreateOrConnectWithoutUsuarioInput = {
    create: ArquivoCreateWithoutUsuarioInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoCreateWithoutCedentesInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<ArquivoAuditCreateNestedManyWithoutArquivoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutArquivosInput;
    nome: Scalars["String"];
    operacoes?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutArquivosInput>;
    recebivel?: InputMaybe<RecebivelCreateNestedOneWithoutArquivoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    url: Scalars["String"];
    usuario: UsuarioCreateNestedOneWithoutArquivosInput;
};

export type ArquivoCreateWithoutHistoricoInput = {
    cedentes?: InputMaybe<CedenteAnexosCreateNestedManyWithoutArquivosInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutArquivosInput;
    nome: Scalars["String"];
    operacoes?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutArquivosInput>;
    recebivel?: InputMaybe<RecebivelCreateNestedOneWithoutArquivoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    url: Scalars["String"];
    usuario: UsuarioCreateNestedOneWithoutArquivosInput;
};

export type ArquivoCreateWithoutMinibancoInput = {
    cedentes?: InputMaybe<CedenteAnexosCreateNestedManyWithoutArquivosInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<ArquivoAuditCreateNestedManyWithoutArquivoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nome: Scalars["String"];
    operacoes?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutArquivosInput>;
    recebivel?: InputMaybe<RecebivelCreateNestedOneWithoutArquivoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    url: Scalars["String"];
    usuario: UsuarioCreateNestedOneWithoutArquivosInput;
};

export type ArquivoCreateWithoutOperacoesInput = {
    cedentes?: InputMaybe<CedenteAnexosCreateNestedManyWithoutArquivosInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<ArquivoAuditCreateNestedManyWithoutArquivoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutArquivosInput;
    nome: Scalars["String"];
    recebivel?: InputMaybe<RecebivelCreateNestedOneWithoutArquivoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    url: Scalars["String"];
    usuario: UsuarioCreateNestedOneWithoutArquivosInput;
};

export type ArquivoCreateWithoutRecebivelInput = {
    cedentes?: InputMaybe<CedenteAnexosCreateNestedManyWithoutArquivosInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<ArquivoAuditCreateNestedManyWithoutArquivoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutArquivosInput;
    nome: Scalars["String"];
    operacoes?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutArquivosInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    url: Scalars["String"];
    usuario: UsuarioCreateNestedOneWithoutArquivosInput;
};

export type ArquivoCreateWithoutUsuarioInput = {
    cedentes?: InputMaybe<CedenteAnexosCreateNestedManyWithoutArquivosInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<ArquivoAuditCreateNestedManyWithoutArquivoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutArquivosInput;
    nome: Scalars["String"];
    operacoes?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutArquivosInput>;
    recebivel?: InputMaybe<RecebivelCreateNestedOneWithoutArquivoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    url: Scalars["String"];
};

export type ArquivoGroupBy = {
    __typename?: "ArquivoGroupBy";
    _avg?: Maybe<ArquivoAvgAggregate>;
    _count?: Maybe<ArquivoCountAggregate>;
    _max?: Maybe<ArquivoMaxAggregate>;
    _min?: Maybe<ArquivoMinAggregate>;
    _sum?: Maybe<ArquivoSumAggregate>;
    createdAt: Scalars["DateTime"];
    descricao?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
    updatedAt: Scalars["DateTime"];
    url: Scalars["String"];
    usuarioId: Scalars["Int"];
};

export type ArquivoListRelationFilter = {
    every?: InputMaybe<ArquivoWhereInput>;
    none?: InputMaybe<ArquivoWhereInput>;
    some?: InputMaybe<ArquivoWhereInput>;
};

export type ArquivoMaxAggregate = {
    __typename?: "ArquivoMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    nome?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    url?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ArquivoMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    url?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ArquivoMinAggregate = {
    __typename?: "ArquivoMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    nome?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    url?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ArquivoMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    url?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ArquivoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type ArquivoOrderByWithAggregationInput = {
    _avg?: InputMaybe<ArquivoAvgOrderByAggregateInput>;
    _count?: InputMaybe<ArquivoCountOrderByAggregateInput>;
    _max?: InputMaybe<ArquivoMaxOrderByAggregateInput>;
    _min?: InputMaybe<ArquivoMinOrderByAggregateInput>;
    _sum?: InputMaybe<ArquivoSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    url?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ArquivoOrderByWithRelationInput = {
    cedentes?: InputMaybe<CedenteAnexosOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    historico?: InputMaybe<ArquivoAuditOrderByRelationAggregateInput>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    operacoes?: InputMaybe<OperacaoAnexosOrderByRelationAggregateInput>;
    recebivel?: InputMaybe<RecebivelOrderByWithRelationInput>;
    updatedAt?: InputMaybe<SortOrder>;
    url?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ArquivoRelationFilter = {
    is?: InputMaybe<ArquivoWhereInput>;
    isNot?: InputMaybe<ArquivoWhereInput>;
};

export enum ArquivoScalarFieldEnum {
    CreatedAt = "createdAt",
    Descricao = "descricao",
    Id = "id",
    Inativo = "inativo",
    MinibancoId = "minibancoId",
    Nome = "nome",
    UpdatedAt = "updatedAt",
    Url = "url",
    UsuarioId = "usuarioId",
}

export type ArquivoScalarWhereInput = {
    AND?: InputMaybe<Array<ArquivoScalarWhereInput>>;
    NOT?: InputMaybe<Array<ArquivoScalarWhereInput>>;
    OR?: InputMaybe<Array<ArquivoScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    descricao?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    nome?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    url?: InputMaybe<StringFilter>;
    usuarioId?: InputMaybe<IntFilter>;
};

export type ArquivoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<ArquivoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<ArquivoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<ArquivoScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    descricao?: InputMaybe<StringNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    nome?: InputMaybe<StringWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    url?: InputMaybe<StringWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntWithAggregatesFilter>;
};

export type ArquivoSumAggregate = {
    __typename?: "ArquivoSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ArquivoSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ArquivoUpdateInput = {
    cedentes?: InputMaybe<CedenteAnexosUpdateManyWithoutArquivosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<ArquivoAuditUpdateManyWithoutArquivoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutArquivosNestedInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoAnexosUpdateManyWithoutArquivosNestedInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneWithoutArquivoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    url?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutArquivosNestedInput>;
};

export type ArquivoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ArquivoUpdateManyWithWhereWithoutCedentesInput = {
    data: ArquivoUpdateManyMutationInput;
    where: ArquivoScalarWhereInput;
};

export type ArquivoUpdateManyWithWhereWithoutMinibancoInput = {
    data: ArquivoUpdateManyMutationInput;
    where: ArquivoScalarWhereInput;
};

export type ArquivoUpdateManyWithWhereWithoutOperacoesInput = {
    data: ArquivoUpdateManyMutationInput;
    where: ArquivoScalarWhereInput;
};

export type ArquivoUpdateManyWithWhereWithoutUsuarioInput = {
    data: ArquivoUpdateManyMutationInput;
    where: ArquivoScalarWhereInput;
};

export type ArquivoUpdateManyWithoutCedentesNestedInput = {
    connect?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ArquivoCreateOrConnectWithoutCedentesInput>>;
    create?: InputMaybe<Array<ArquivoCreateWithoutCedentesInput>>;
    delete?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ArquivoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    set?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    update?: InputMaybe<Array<ArquivoUpdateWithWhereUniqueWithoutCedentesInput>>;
    updateMany?: InputMaybe<Array<ArquivoUpdateManyWithWhereWithoutCedentesInput>>;
    upsert?: InputMaybe<Array<ArquivoUpsertWithWhereUniqueWithoutCedentesInput>>;
};

export type ArquivoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ArquivoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<ArquivoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<ArquivoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ArquivoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    set?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    update?: InputMaybe<Array<ArquivoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<ArquivoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<ArquivoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type ArquivoUpdateManyWithoutOperacoesNestedInput = {
    connect?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ArquivoCreateOrConnectWithoutOperacoesInput>>;
    create?: InputMaybe<Array<ArquivoCreateWithoutOperacoesInput>>;
    delete?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ArquivoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    set?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    update?: InputMaybe<Array<ArquivoUpdateWithWhereUniqueWithoutOperacoesInput>>;
    updateMany?: InputMaybe<Array<ArquivoUpdateManyWithWhereWithoutOperacoesInput>>;
    upsert?: InputMaybe<Array<ArquivoUpsertWithWhereUniqueWithoutOperacoesInput>>;
};

export type ArquivoUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ArquivoCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<ArquivoCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<ArquivoCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ArquivoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    set?: InputMaybe<Array<ArquivoWhereUniqueInput>>;
    update?: InputMaybe<Array<ArquivoUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<ArquivoUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<ArquivoUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type ArquivoUpdateOneRequiredWithoutHistoricoNestedInput = {
    connect?: InputMaybe<ArquivoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ArquivoCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<ArquivoCreateWithoutHistoricoInput>;
    update?: InputMaybe<ArquivoUpdateWithoutHistoricoInput>;
    upsert?: InputMaybe<ArquivoUpsertWithoutHistoricoInput>;
};

export type ArquivoUpdateOneWithoutRecebivelNestedInput = {
    connect?: InputMaybe<ArquivoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ArquivoCreateOrConnectWithoutRecebivelInput>;
    create?: InputMaybe<ArquivoCreateWithoutRecebivelInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<ArquivoUpdateWithoutRecebivelInput>;
    upsert?: InputMaybe<ArquivoUpsertWithoutRecebivelInput>;
};

export type ArquivoUpdateWithWhereUniqueWithoutCedentesInput = {
    data: ArquivoUpdateWithoutCedentesInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: ArquivoUpdateWithoutMinibancoInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoUpdateWithWhereUniqueWithoutOperacoesInput = {
    data: ArquivoUpdateWithoutOperacoesInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: ArquivoUpdateWithoutUsuarioInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoUpdateWithoutCedentesInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<ArquivoAuditUpdateManyWithoutArquivoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutArquivosNestedInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoAnexosUpdateManyWithoutArquivosNestedInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneWithoutArquivoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    url?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutArquivosNestedInput>;
};

export type ArquivoUpdateWithoutHistoricoInput = {
    cedentes?: InputMaybe<CedenteAnexosUpdateManyWithoutArquivosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutArquivosNestedInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoAnexosUpdateManyWithoutArquivosNestedInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneWithoutArquivoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    url?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutArquivosNestedInput>;
};

export type ArquivoUpdateWithoutMinibancoInput = {
    cedentes?: InputMaybe<CedenteAnexosUpdateManyWithoutArquivosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<ArquivoAuditUpdateManyWithoutArquivoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoAnexosUpdateManyWithoutArquivosNestedInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneWithoutArquivoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    url?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutArquivosNestedInput>;
};

export type ArquivoUpdateWithoutOperacoesInput = {
    cedentes?: InputMaybe<CedenteAnexosUpdateManyWithoutArquivosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<ArquivoAuditUpdateManyWithoutArquivoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutArquivosNestedInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneWithoutArquivoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    url?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutArquivosNestedInput>;
};

export type ArquivoUpdateWithoutRecebivelInput = {
    cedentes?: InputMaybe<CedenteAnexosUpdateManyWithoutArquivosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<ArquivoAuditUpdateManyWithoutArquivoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutArquivosNestedInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoAnexosUpdateManyWithoutArquivosNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    url?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutArquivosNestedInput>;
};

export type ArquivoUpdateWithoutUsuarioInput = {
    cedentes?: InputMaybe<CedenteAnexosUpdateManyWithoutArquivosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<ArquivoAuditUpdateManyWithoutArquivoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutArquivosNestedInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoAnexosUpdateManyWithoutArquivosNestedInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneWithoutArquivoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ArquivoUpsertWithWhereUniqueWithoutCedentesInput = {
    create: ArquivoCreateWithoutCedentesInput;
    update: ArquivoUpdateWithoutCedentesInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: ArquivoCreateWithoutMinibancoInput;
    update: ArquivoUpdateWithoutMinibancoInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoUpsertWithWhereUniqueWithoutOperacoesInput = {
    create: ArquivoCreateWithoutOperacoesInput;
    update: ArquivoUpdateWithoutOperacoesInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: ArquivoCreateWithoutUsuarioInput;
    update: ArquivoUpdateWithoutUsuarioInput;
    where: ArquivoWhereUniqueInput;
};

export type ArquivoUpsertWithoutHistoricoInput = {
    create: ArquivoCreateWithoutHistoricoInput;
    update: ArquivoUpdateWithoutHistoricoInput;
};

export type ArquivoUpsertWithoutRecebivelInput = {
    create: ArquivoCreateWithoutRecebivelInput;
    update: ArquivoUpdateWithoutRecebivelInput;
};

export type ArquivoWhereInput = {
    AND?: InputMaybe<Array<ArquivoWhereInput>>;
    NOT?: InputMaybe<Array<ArquivoWhereInput>>;
    OR?: InputMaybe<Array<ArquivoWhereInput>>;
    cedentes?: InputMaybe<CedenteAnexosListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    descricao?: InputMaybe<StringNullableFilter>;
    historico?: InputMaybe<ArquivoAuditListRelationFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    nome?: InputMaybe<StringFilter>;
    operacoes?: InputMaybe<OperacaoAnexosListRelationFilter>;
    recebivel?: InputMaybe<RecebivelRelationFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    url?: InputMaybe<StringFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntFilter>;
};

export type ArquivoWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
    url?: InputMaybe<Scalars["String"]>;
};

export type AssignorBankData = {
    __typename?: "AssignorBankData";
    account: Scalars["String"];
    accountDigit: Scalars["Int"];
    agency: Scalars["String"];
    agencyDigit?: Maybe<Scalars["Int"]>;
    assignor: Cedente;
    assignorId: Scalars["Int"];
    bank: Scalars["String"];
    id: Scalars["Int"];
    pix?: Maybe<Scalars["String"]>;
    pixKeyType?: Maybe<PixKeyType>;
};

export type AssignorBankDataAvgAggregate = {
    __typename?: "AssignorBankDataAvgAggregate";
    accountDigit?: Maybe<Scalars["Float"]>;
    agencyDigit?: Maybe<Scalars["Float"]>;
    assignorId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
};

export type AssignorBankDataAvgOrderByAggregateInput = {
    accountDigit?: InputMaybe<SortOrder>;
    agencyDigit?: InputMaybe<SortOrder>;
    assignorId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type AssignorBankDataBankingAccountCompoundUniqueInput = {
    account: Scalars["String"];
    agency: Scalars["String"];
    bank: Scalars["String"];
};

export type AssignorBankDataCountAggregate = {
    __typename?: "AssignorBankDataCountAggregate";
    _all: Scalars["Int"];
    account: Scalars["Int"];
    accountDigit: Scalars["Int"];
    agency: Scalars["Int"];
    agencyDigit: Scalars["Int"];
    assignorId: Scalars["Int"];
    bank: Scalars["Int"];
    id: Scalars["Int"];
    pix: Scalars["Int"];
    pixKeyType: Scalars["Int"];
};

export type AssignorBankDataCountOrderByAggregateInput = {
    account?: InputMaybe<SortOrder>;
    accountDigit?: InputMaybe<SortOrder>;
    agency?: InputMaybe<SortOrder>;
    agencyDigit?: InputMaybe<SortOrder>;
    assignorId?: InputMaybe<SortOrder>;
    bank?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    pix?: InputMaybe<SortOrder>;
    pixKeyType?: InputMaybe<SortOrder>;
};

export type AssignorBankDataCreateInput = {
    account: Scalars["String"];
    accountDigit: Scalars["Int"];
    agency: Scalars["String"];
    agencyDigit?: InputMaybe<Scalars["Int"]>;
    assignor: CedenteCreateNestedOneWithoutAssignorBankDataInput;
    bank: Scalars["String"];
    pix?: InputMaybe<Scalars["String"]>;
    pixKeyType?: InputMaybe<PixKeyType>;
};

export type AssignorBankDataCreateManyInput = {
    account: Scalars["String"];
    accountDigit: Scalars["Int"];
    agency: Scalars["String"];
    agencyDigit?: InputMaybe<Scalars["Int"]>;
    assignorId: Scalars["Int"];
    bank: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    pix?: InputMaybe<Scalars["String"]>;
    pixKeyType?: InputMaybe<PixKeyType>;
};

export type AssignorBankDataCreateNestedOneWithoutAssignorInput = {
    connect?: InputMaybe<AssignorBankDataWhereUniqueInput>;
    connectOrCreate?: InputMaybe<AssignorBankDataCreateOrConnectWithoutAssignorInput>;
    create?: InputMaybe<AssignorBankDataCreateWithoutAssignorInput>;
};

export type AssignorBankDataCreateOrConnectWithoutAssignorInput = {
    create: AssignorBankDataCreateWithoutAssignorInput;
    where: AssignorBankDataWhereUniqueInput;
};

export type AssignorBankDataCreateWithoutAssignorInput = {
    account: Scalars["String"];
    accountDigit: Scalars["Int"];
    agency: Scalars["String"];
    agencyDigit?: InputMaybe<Scalars["Int"]>;
    bank: Scalars["String"];
    pix?: InputMaybe<Scalars["String"]>;
    pixKeyType?: InputMaybe<PixKeyType>;
};

export type AssignorBankDataGroupBy = {
    __typename?: "AssignorBankDataGroupBy";
    _avg?: Maybe<AssignorBankDataAvgAggregate>;
    _count?: Maybe<AssignorBankDataCountAggregate>;
    _max?: Maybe<AssignorBankDataMaxAggregate>;
    _min?: Maybe<AssignorBankDataMinAggregate>;
    _sum?: Maybe<AssignorBankDataSumAggregate>;
    account: Scalars["String"];
    accountDigit: Scalars["Int"];
    agency: Scalars["String"];
    agencyDigit?: Maybe<Scalars["Int"]>;
    assignorId: Scalars["Int"];
    bank: Scalars["String"];
    id: Scalars["Int"];
    pix?: Maybe<Scalars["String"]>;
    pixKeyType?: Maybe<PixKeyType>;
};

export type AssignorBankDataMaxAggregate = {
    __typename?: "AssignorBankDataMaxAggregate";
    account?: Maybe<Scalars["String"]>;
    accountDigit?: Maybe<Scalars["Int"]>;
    agency?: Maybe<Scalars["String"]>;
    agencyDigit?: Maybe<Scalars["Int"]>;
    assignorId?: Maybe<Scalars["Int"]>;
    bank?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    pix?: Maybe<Scalars["String"]>;
    pixKeyType?: Maybe<PixKeyType>;
};

export type AssignorBankDataMaxOrderByAggregateInput = {
    account?: InputMaybe<SortOrder>;
    accountDigit?: InputMaybe<SortOrder>;
    agency?: InputMaybe<SortOrder>;
    agencyDigit?: InputMaybe<SortOrder>;
    assignorId?: InputMaybe<SortOrder>;
    bank?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    pix?: InputMaybe<SortOrder>;
    pixKeyType?: InputMaybe<SortOrder>;
};

export type AssignorBankDataMinAggregate = {
    __typename?: "AssignorBankDataMinAggregate";
    account?: Maybe<Scalars["String"]>;
    accountDigit?: Maybe<Scalars["Int"]>;
    agency?: Maybe<Scalars["String"]>;
    agencyDigit?: Maybe<Scalars["Int"]>;
    assignorId?: Maybe<Scalars["Int"]>;
    bank?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    pix?: Maybe<Scalars["String"]>;
    pixKeyType?: Maybe<PixKeyType>;
};

export type AssignorBankDataMinOrderByAggregateInput = {
    account?: InputMaybe<SortOrder>;
    accountDigit?: InputMaybe<SortOrder>;
    agency?: InputMaybe<SortOrder>;
    agencyDigit?: InputMaybe<SortOrder>;
    assignorId?: InputMaybe<SortOrder>;
    bank?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    pix?: InputMaybe<SortOrder>;
    pixKeyType?: InputMaybe<SortOrder>;
};

export type AssignorBankDataOrderByWithAggregationInput = {
    _avg?: InputMaybe<AssignorBankDataAvgOrderByAggregateInput>;
    _count?: InputMaybe<AssignorBankDataCountOrderByAggregateInput>;
    _max?: InputMaybe<AssignorBankDataMaxOrderByAggregateInput>;
    _min?: InputMaybe<AssignorBankDataMinOrderByAggregateInput>;
    _sum?: InputMaybe<AssignorBankDataSumOrderByAggregateInput>;
    account?: InputMaybe<SortOrder>;
    accountDigit?: InputMaybe<SortOrder>;
    agency?: InputMaybe<SortOrder>;
    agencyDigit?: InputMaybe<SortOrder>;
    assignorId?: InputMaybe<SortOrder>;
    bank?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    pix?: InputMaybe<SortOrder>;
    pixKeyType?: InputMaybe<SortOrder>;
};

export type AssignorBankDataOrderByWithRelationInput = {
    account?: InputMaybe<SortOrder>;
    accountDigit?: InputMaybe<SortOrder>;
    agency?: InputMaybe<SortOrder>;
    agencyDigit?: InputMaybe<SortOrder>;
    assignor?: InputMaybe<CedenteOrderByWithRelationInput>;
    assignorId?: InputMaybe<SortOrder>;
    bank?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    pix?: InputMaybe<SortOrder>;
    pixKeyType?: InputMaybe<SortOrder>;
};

export type AssignorBankDataRelationFilter = {
    is?: InputMaybe<AssignorBankDataWhereInput>;
    isNot?: InputMaybe<AssignorBankDataWhereInput>;
};

export enum AssignorBankDataScalarFieldEnum {
    Account = "account",
    AccountDigit = "accountDigit",
    Agency = "agency",
    AgencyDigit = "agencyDigit",
    AssignorId = "assignorId",
    Bank = "bank",
    Id = "id",
    Pix = "pix",
    PixKeyType = "pixKeyType",
}

export type AssignorBankDataScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<AssignorBankDataScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<AssignorBankDataScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<AssignorBankDataScalarWhereWithAggregatesInput>>;
    account?: InputMaybe<StringWithAggregatesFilter>;
    accountDigit?: InputMaybe<IntWithAggregatesFilter>;
    agency?: InputMaybe<StringWithAggregatesFilter>;
    agencyDigit?: InputMaybe<IntNullableWithAggregatesFilter>;
    assignorId?: InputMaybe<IntWithAggregatesFilter>;
    bank?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    pix?: InputMaybe<StringNullableWithAggregatesFilter>;
    pixKeyType?: InputMaybe<EnumPixKeyTypeNullableWithAggregatesFilter>;
};

export type AssignorBankDataSumAggregate = {
    __typename?: "AssignorBankDataSumAggregate";
    accountDigit?: Maybe<Scalars["Int"]>;
    agencyDigit?: Maybe<Scalars["Int"]>;
    assignorId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
};

export type AssignorBankDataSumOrderByAggregateInput = {
    accountDigit?: InputMaybe<SortOrder>;
    agencyDigit?: InputMaybe<SortOrder>;
    assignorId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type AssignorBankDataUpdateInput = {
    account?: InputMaybe<StringFieldUpdateOperationsInput>;
    accountDigit?: InputMaybe<IntFieldUpdateOperationsInput>;
    agency?: InputMaybe<StringFieldUpdateOperationsInput>;
    agencyDigit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    assignor?: InputMaybe<CedenteUpdateOneRequiredWithoutAssignorBankDataNestedInput>;
    bank?: InputMaybe<StringFieldUpdateOperationsInput>;
    pix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pixKeyType?: InputMaybe<NullableEnumPixKeyTypeFieldUpdateOperationsInput>;
};

export type AssignorBankDataUpdateManyMutationInput = {
    account?: InputMaybe<StringFieldUpdateOperationsInput>;
    accountDigit?: InputMaybe<IntFieldUpdateOperationsInput>;
    agency?: InputMaybe<StringFieldUpdateOperationsInput>;
    agencyDigit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    bank?: InputMaybe<StringFieldUpdateOperationsInput>;
    pix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pixKeyType?: InputMaybe<NullableEnumPixKeyTypeFieldUpdateOperationsInput>;
};

export type AssignorBankDataUpdateOneWithoutAssignorNestedInput = {
    connect?: InputMaybe<AssignorBankDataWhereUniqueInput>;
    connectOrCreate?: InputMaybe<AssignorBankDataCreateOrConnectWithoutAssignorInput>;
    create?: InputMaybe<AssignorBankDataCreateWithoutAssignorInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<AssignorBankDataUpdateWithoutAssignorInput>;
    upsert?: InputMaybe<AssignorBankDataUpsertWithoutAssignorInput>;
};

export type AssignorBankDataUpdateWithoutAssignorInput = {
    account?: InputMaybe<StringFieldUpdateOperationsInput>;
    accountDigit?: InputMaybe<IntFieldUpdateOperationsInput>;
    agency?: InputMaybe<StringFieldUpdateOperationsInput>;
    agencyDigit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    bank?: InputMaybe<StringFieldUpdateOperationsInput>;
    pix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pixKeyType?: InputMaybe<NullableEnumPixKeyTypeFieldUpdateOperationsInput>;
};

export type AssignorBankDataUpsertWithoutAssignorInput = {
    create: AssignorBankDataCreateWithoutAssignorInput;
    update: AssignorBankDataUpdateWithoutAssignorInput;
};

export type AssignorBankDataWhereInput = {
    AND?: InputMaybe<Array<AssignorBankDataWhereInput>>;
    NOT?: InputMaybe<Array<AssignorBankDataWhereInput>>;
    OR?: InputMaybe<Array<AssignorBankDataWhereInput>>;
    account?: InputMaybe<StringFilter>;
    accountDigit?: InputMaybe<IntFilter>;
    agency?: InputMaybe<StringFilter>;
    agencyDigit?: InputMaybe<IntNullableFilter>;
    assignor?: InputMaybe<CedenteRelationFilter>;
    assignorId?: InputMaybe<IntFilter>;
    bank?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    pix?: InputMaybe<StringNullableFilter>;
    pixKeyType?: InputMaybe<EnumPixKeyTypeNullableFilter>;
};

export type AssignorBankDataWhereUniqueInput = {
    assignorId?: InputMaybe<Scalars["Int"]>;
    bankingAccount?: InputMaybe<AssignorBankDataBankingAccountCompoundUniqueInput>;
    id?: InputMaybe<Scalars["Int"]>;
    pix?: InputMaybe<Scalars["String"]>;
};

export type AssignorCrm = {
    __typename?: "AssignorCRM";
    assignorId: Scalars["Int"];
    crmId: Scalars["String"];
    crmType: CrmType;
};

export type AssignorCrmAssignorIdCrmTypeCompoundUniqueInput = {
    assignorId: Scalars["Int"];
    crmType: CrmType;
};

export type AssignorCrmAvgAggregate = {
    __typename?: "AssignorCRMAvgAggregate";
    assignorId?: Maybe<Scalars["Float"]>;
};

export type AssignorCrmAvgOrderByAggregateInput = {
    assignorId?: InputMaybe<SortOrder>;
};

export type AssignorCrmCountAggregate = {
    __typename?: "AssignorCRMCountAggregate";
    _all: Scalars["Int"];
    assignorId: Scalars["Int"];
    crmId: Scalars["Int"];
    crmType: Scalars["Int"];
};

export type AssignorCrmCountOrderByAggregateInput = {
    assignorId?: InputMaybe<SortOrder>;
    crmId?: InputMaybe<SortOrder>;
    crmType?: InputMaybe<SortOrder>;
};

export type AssignorCrmCreateInput = {
    assignorId: Scalars["Int"];
    crmId: Scalars["String"];
    crmType?: InputMaybe<CrmType>;
};

export type AssignorCrmCreateManyInput = {
    assignorId: Scalars["Int"];
    crmId: Scalars["String"];
    crmType?: InputMaybe<CrmType>;
};

export type AssignorCrmGroupBy = {
    __typename?: "AssignorCRMGroupBy";
    _avg?: Maybe<AssignorCrmAvgAggregate>;
    _count?: Maybe<AssignorCrmCountAggregate>;
    _max?: Maybe<AssignorCrmMaxAggregate>;
    _min?: Maybe<AssignorCrmMinAggregate>;
    _sum?: Maybe<AssignorCrmSumAggregate>;
    assignorId: Scalars["Int"];
    crmId: Scalars["String"];
    crmType: CrmType;
};

export type AssignorCrmMaxAggregate = {
    __typename?: "AssignorCRMMaxAggregate";
    assignorId?: Maybe<Scalars["Int"]>;
    crmId?: Maybe<Scalars["String"]>;
    crmType?: Maybe<CrmType>;
};

export type AssignorCrmMaxOrderByAggregateInput = {
    assignorId?: InputMaybe<SortOrder>;
    crmId?: InputMaybe<SortOrder>;
    crmType?: InputMaybe<SortOrder>;
};

export type AssignorCrmMinAggregate = {
    __typename?: "AssignorCRMMinAggregate";
    assignorId?: Maybe<Scalars["Int"]>;
    crmId?: Maybe<Scalars["String"]>;
    crmType?: Maybe<CrmType>;
};

export type AssignorCrmMinOrderByAggregateInput = {
    assignorId?: InputMaybe<SortOrder>;
    crmId?: InputMaybe<SortOrder>;
    crmType?: InputMaybe<SortOrder>;
};

export type AssignorCrmOrderByWithAggregationInput = {
    _avg?: InputMaybe<AssignorCrmAvgOrderByAggregateInput>;
    _count?: InputMaybe<AssignorCrmCountOrderByAggregateInput>;
    _max?: InputMaybe<AssignorCrmMaxOrderByAggregateInput>;
    _min?: InputMaybe<AssignorCrmMinOrderByAggregateInput>;
    _sum?: InputMaybe<AssignorCrmSumOrderByAggregateInput>;
    assignorId?: InputMaybe<SortOrder>;
    crmId?: InputMaybe<SortOrder>;
    crmType?: InputMaybe<SortOrder>;
};

export type AssignorCrmOrderByWithRelationInput = {
    assignorId?: InputMaybe<SortOrder>;
    crmId?: InputMaybe<SortOrder>;
    crmType?: InputMaybe<SortOrder>;
};

export enum AssignorCrmScalarFieldEnum {
    AssignorId = "assignorId",
    CrmId = "crmId",
    CrmType = "crmType",
}

export type AssignorCrmScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<AssignorCrmScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<AssignorCrmScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<AssignorCrmScalarWhereWithAggregatesInput>>;
    assignorId?: InputMaybe<IntWithAggregatesFilter>;
    crmId?: InputMaybe<StringWithAggregatesFilter>;
    crmType?: InputMaybe<EnumCrmTypeWithAggregatesFilter>;
};

export type AssignorCrmSumAggregate = {
    __typename?: "AssignorCRMSumAggregate";
    assignorId?: Maybe<Scalars["Int"]>;
};

export type AssignorCrmSumOrderByAggregateInput = {
    assignorId?: InputMaybe<SortOrder>;
};

export type AssignorCrmUpdateInput = {
    assignorId?: InputMaybe<IntFieldUpdateOperationsInput>;
    crmId?: InputMaybe<StringFieldUpdateOperationsInput>;
    crmType?: InputMaybe<EnumCrmTypeFieldUpdateOperationsInput>;
};

export type AssignorCrmUpdateManyMutationInput = {
    assignorId?: InputMaybe<IntFieldUpdateOperationsInput>;
    crmId?: InputMaybe<StringFieldUpdateOperationsInput>;
    crmType?: InputMaybe<EnumCrmTypeFieldUpdateOperationsInput>;
};

export type AssignorCrmWhereInput = {
    AND?: InputMaybe<Array<AssignorCrmWhereInput>>;
    NOT?: InputMaybe<Array<AssignorCrmWhereInput>>;
    OR?: InputMaybe<Array<AssignorCrmWhereInput>>;
    assignorId?: InputMaybe<IntFilter>;
    crmId?: InputMaybe<StringFilter>;
    crmType?: InputMaybe<EnumCrmTypeFilter>;
};

export type AssignorCrmWhereUniqueInput = {
    assignorId_crmType?: InputMaybe<AssignorCrmAssignorIdCrmTypeCompoundUniqueInput>;
};

export type AssignorDocumentType = {
    __typename?: "AssignorDocumentType";
    _count?: Maybe<AssignorDocumentTypeCount>;
    abbreviation: Scalars["String"];
    cedenteAnexo: Array<CedenteAnexos>;
    createdAt: Scalars["DateTime"];
    description: Scalars["String"];
    id: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type AssignorDocumentTypeCedenteAnexoArgs = {
    cursor?: InputMaybe<CedenteAnexosWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAnexosScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAnexosOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAnexosWhereInput>;
};

export type AssignorDocumentTypeAvgAggregate = {
    __typename?: "AssignorDocumentTypeAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
};

export type AssignorDocumentTypeAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type AssignorDocumentTypeCount = {
    __typename?: "AssignorDocumentTypeCount";
    cedenteAnexo: Scalars["Int"];
};

export type AssignorDocumentTypeCountAggregate = {
    __typename?: "AssignorDocumentTypeCountAggregate";
    _all: Scalars["Int"];
    abbreviation: Scalars["Int"];
    createdAt: Scalars["Int"];
    description: Scalars["Int"];
    id: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type AssignorDocumentTypeCountOrderByAggregateInput = {
    abbreviation?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type AssignorDocumentTypeCreateInput = {
    abbreviation: Scalars["String"];
    cedenteAnexo?: InputMaybe<CedenteAnexosCreateNestedManyWithoutAssignorDocumentTypeInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    description: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type AssignorDocumentTypeCreateManyInput = {
    abbreviation: Scalars["String"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    description: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type AssignorDocumentTypeCreateNestedOneWithoutCedenteAnexoInput = {
    connect?: InputMaybe<AssignorDocumentTypeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<AssignorDocumentTypeCreateOrConnectWithoutCedenteAnexoInput>;
    create?: InputMaybe<AssignorDocumentTypeCreateWithoutCedenteAnexoInput>;
};

export type AssignorDocumentTypeCreateOrConnectWithoutCedenteAnexoInput = {
    create: AssignorDocumentTypeCreateWithoutCedenteAnexoInput;
    where: AssignorDocumentTypeWhereUniqueInput;
};

export type AssignorDocumentTypeCreateWithoutCedenteAnexoInput = {
    abbreviation: Scalars["String"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    description: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type AssignorDocumentTypeGroupBy = {
    __typename?: "AssignorDocumentTypeGroupBy";
    _avg?: Maybe<AssignorDocumentTypeAvgAggregate>;
    _count?: Maybe<AssignorDocumentTypeCountAggregate>;
    _max?: Maybe<AssignorDocumentTypeMaxAggregate>;
    _min?: Maybe<AssignorDocumentTypeMinAggregate>;
    _sum?: Maybe<AssignorDocumentTypeSumAggregate>;
    abbreviation: Scalars["String"];
    createdAt: Scalars["DateTime"];
    description: Scalars["String"];
    id: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type AssignorDocumentTypeMaxAggregate = {
    __typename?: "AssignorDocumentTypeMaxAggregate";
    abbreviation?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AssignorDocumentTypeMaxOrderByAggregateInput = {
    abbreviation?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type AssignorDocumentTypeMinAggregate = {
    __typename?: "AssignorDocumentTypeMinAggregate";
    abbreviation?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AssignorDocumentTypeMinOrderByAggregateInput = {
    abbreviation?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type AssignorDocumentTypeOrderByWithAggregationInput = {
    _avg?: InputMaybe<AssignorDocumentTypeAvgOrderByAggregateInput>;
    _count?: InputMaybe<AssignorDocumentTypeCountOrderByAggregateInput>;
    _max?: InputMaybe<AssignorDocumentTypeMaxOrderByAggregateInput>;
    _min?: InputMaybe<AssignorDocumentTypeMinOrderByAggregateInput>;
    _sum?: InputMaybe<AssignorDocumentTypeSumOrderByAggregateInput>;
    abbreviation?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type AssignorDocumentTypeOrderByWithRelationInput = {
    abbreviation?: InputMaybe<SortOrder>;
    cedenteAnexo?: InputMaybe<CedenteAnexosOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type AssignorDocumentTypeRelationFilter = {
    is?: InputMaybe<AssignorDocumentTypeWhereInput>;
    isNot?: InputMaybe<AssignorDocumentTypeWhereInput>;
};

export enum AssignorDocumentTypeScalarFieldEnum {
    Abbreviation = "abbreviation",
    CreatedAt = "createdAt",
    Description = "description",
    Id = "id",
    UpdatedAt = "updatedAt",
}

export type AssignorDocumentTypeScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<AssignorDocumentTypeScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<AssignorDocumentTypeScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<AssignorDocumentTypeScalarWhereWithAggregatesInput>>;
    abbreviation?: InputMaybe<StringWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    description?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type AssignorDocumentTypeSumAggregate = {
    __typename?: "AssignorDocumentTypeSumAggregate";
    id?: Maybe<Scalars["Int"]>;
};

export type AssignorDocumentTypeSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type AssignorDocumentTypeUpdateInput = {
    abbreviation?: InputMaybe<StringFieldUpdateOperationsInput>;
    cedenteAnexo?: InputMaybe<CedenteAnexosUpdateManyWithoutAssignorDocumentTypeNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    description?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AssignorDocumentTypeUpdateManyMutationInput = {
    abbreviation?: InputMaybe<StringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    description?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AssignorDocumentTypeUpdateOneWithoutCedenteAnexoNestedInput = {
    connect?: InputMaybe<AssignorDocumentTypeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<AssignorDocumentTypeCreateOrConnectWithoutCedenteAnexoInput>;
    create?: InputMaybe<AssignorDocumentTypeCreateWithoutCedenteAnexoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<AssignorDocumentTypeUpdateWithoutCedenteAnexoInput>;
    upsert?: InputMaybe<AssignorDocumentTypeUpsertWithoutCedenteAnexoInput>;
};

export type AssignorDocumentTypeUpdateWithoutCedenteAnexoInput = {
    abbreviation?: InputMaybe<StringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    description?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AssignorDocumentTypeUpsertWithoutCedenteAnexoInput = {
    create: AssignorDocumentTypeCreateWithoutCedenteAnexoInput;
    update: AssignorDocumentTypeUpdateWithoutCedenteAnexoInput;
};

export type AssignorDocumentTypeWhereInput = {
    AND?: InputMaybe<Array<AssignorDocumentTypeWhereInput>>;
    NOT?: InputMaybe<Array<AssignorDocumentTypeWhereInput>>;
    OR?: InputMaybe<Array<AssignorDocumentTypeWhereInput>>;
    abbreviation?: InputMaybe<StringFilter>;
    cedenteAnexo?: InputMaybe<CedenteAnexosListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    description?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AssignorDocumentTypeWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type AssignorStatusByMinibank = {
    __typename?: "AssignorStatusByMinibank";
    assignor: Cedente;
    assignorAttachment?: Maybe<CedenteAnexos>;
    assignorId: Scalars["Int"];
    attachmentId?: Maybe<Scalars["Int"]>;
    id: Scalars["Int"];
    minibank: Minibanco;
    minibankId: Scalars["Int"];
    status: StatusOperationalAssignorByMinibank;
    typeOfAnalysis: TypeOfAnalysis;
};

export type AssignorStatusByMinibankAvgAggregate = {
    __typename?: "AssignorStatusByMinibankAvgAggregate";
    assignorId?: Maybe<Scalars["Float"]>;
    attachmentId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    minibankId?: Maybe<Scalars["Float"]>;
};

export type AssignorStatusByMinibankAvgOrderByAggregateInput = {
    assignorId?: InputMaybe<SortOrder>;
    attachmentId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
};

export type AssignorStatusByMinibankCountAggregate = {
    __typename?: "AssignorStatusByMinibankCountAggregate";
    _all: Scalars["Int"];
    assignorId: Scalars["Int"];
    attachmentId: Scalars["Int"];
    id: Scalars["Int"];
    minibankId: Scalars["Int"];
    status: Scalars["Int"];
    typeOfAnalysis: Scalars["Int"];
};

export type AssignorStatusByMinibankCountOrderByAggregateInput = {
    assignorId?: InputMaybe<SortOrder>;
    attachmentId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    typeOfAnalysis?: InputMaybe<SortOrder>;
};

export type AssignorStatusByMinibankCreateInput = {
    assignor: CedenteCreateNestedOneWithoutAssignorStatusByMinibankInput;
    assignorAttachment?: InputMaybe<CedenteAnexosCreateNestedOneWithoutAssignorStatusByMinibankInput>;
    minibank: MinibancoCreateNestedOneWithoutAssignorStatusByMinibankInput;
    status?: InputMaybe<StatusOperationalAssignorByMinibank>;
    typeOfAnalysis?: InputMaybe<TypeOfAnalysis>;
};

export type AssignorStatusByMinibankCreateManyAssignorAttachmentInput = {
    assignorId: Scalars["Int"];
    id?: InputMaybe<Scalars["Int"]>;
    minibankId: Scalars["Int"];
    status?: InputMaybe<StatusOperationalAssignorByMinibank>;
    typeOfAnalysis?: InputMaybe<TypeOfAnalysis>;
};

export type AssignorStatusByMinibankCreateManyAssignorAttachmentInputEnvelope = {
    data: Array<AssignorStatusByMinibankCreateManyAssignorAttachmentInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AssignorStatusByMinibankCreateManyAssignorInput = {
    attachmentId?: InputMaybe<Scalars["Int"]>;
    id?: InputMaybe<Scalars["Int"]>;
    minibankId: Scalars["Int"];
    status?: InputMaybe<StatusOperationalAssignorByMinibank>;
    typeOfAnalysis?: InputMaybe<TypeOfAnalysis>;
};

export type AssignorStatusByMinibankCreateManyAssignorInputEnvelope = {
    data: Array<AssignorStatusByMinibankCreateManyAssignorInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AssignorStatusByMinibankCreateManyInput = {
    assignorId: Scalars["Int"];
    attachmentId?: InputMaybe<Scalars["Int"]>;
    id?: InputMaybe<Scalars["Int"]>;
    minibankId: Scalars["Int"];
    status?: InputMaybe<StatusOperationalAssignorByMinibank>;
    typeOfAnalysis?: InputMaybe<TypeOfAnalysis>;
};

export type AssignorStatusByMinibankCreateManyMinibankInput = {
    assignorId: Scalars["Int"];
    attachmentId?: InputMaybe<Scalars["Int"]>;
    id?: InputMaybe<Scalars["Int"]>;
    status?: InputMaybe<StatusOperationalAssignorByMinibank>;
    typeOfAnalysis?: InputMaybe<TypeOfAnalysis>;
};

export type AssignorStatusByMinibankCreateManyMinibankInputEnvelope = {
    data: Array<AssignorStatusByMinibankCreateManyMinibankInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type AssignorStatusByMinibankCreateNestedManyWithoutAssignorAttachmentInput = {
    connect?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<AssignorStatusByMinibankCreateOrConnectWithoutAssignorAttachmentInput>>;
    create?: InputMaybe<Array<AssignorStatusByMinibankCreateWithoutAssignorAttachmentInput>>;
    createMany?: InputMaybe<AssignorStatusByMinibankCreateManyAssignorAttachmentInputEnvelope>;
};

export type AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput = {
    connect?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<AssignorStatusByMinibankCreateOrConnectWithoutAssignorInput>>;
    create?: InputMaybe<Array<AssignorStatusByMinibankCreateWithoutAssignorInput>>;
    createMany?: InputMaybe<AssignorStatusByMinibankCreateManyAssignorInputEnvelope>;
};

export type AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput = {
    connect?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<AssignorStatusByMinibankCreateOrConnectWithoutMinibankInput>>;
    create?: InputMaybe<Array<AssignorStatusByMinibankCreateWithoutMinibankInput>>;
    createMany?: InputMaybe<AssignorStatusByMinibankCreateManyMinibankInputEnvelope>;
};

export type AssignorStatusByMinibankCreateOrConnectWithoutAssignorAttachmentInput = {
    create: AssignorStatusByMinibankCreateWithoutAssignorAttachmentInput;
    where: AssignorStatusByMinibankWhereUniqueInput;
};

export type AssignorStatusByMinibankCreateOrConnectWithoutAssignorInput = {
    create: AssignorStatusByMinibankCreateWithoutAssignorInput;
    where: AssignorStatusByMinibankWhereUniqueInput;
};

export type AssignorStatusByMinibankCreateOrConnectWithoutMinibankInput = {
    create: AssignorStatusByMinibankCreateWithoutMinibankInput;
    where: AssignorStatusByMinibankWhereUniqueInput;
};

export type AssignorStatusByMinibankCreateWithoutAssignorAttachmentInput = {
    assignor: CedenteCreateNestedOneWithoutAssignorStatusByMinibankInput;
    minibank: MinibancoCreateNestedOneWithoutAssignorStatusByMinibankInput;
    status?: InputMaybe<StatusOperationalAssignorByMinibank>;
    typeOfAnalysis?: InputMaybe<TypeOfAnalysis>;
};

export type AssignorStatusByMinibankCreateWithoutAssignorInput = {
    assignorAttachment?: InputMaybe<CedenteAnexosCreateNestedOneWithoutAssignorStatusByMinibankInput>;
    minibank: MinibancoCreateNestedOneWithoutAssignorStatusByMinibankInput;
    status?: InputMaybe<StatusOperationalAssignorByMinibank>;
    typeOfAnalysis?: InputMaybe<TypeOfAnalysis>;
};

export type AssignorStatusByMinibankCreateWithoutMinibankInput = {
    assignor: CedenteCreateNestedOneWithoutAssignorStatusByMinibankInput;
    assignorAttachment?: InputMaybe<CedenteAnexosCreateNestedOneWithoutAssignorStatusByMinibankInput>;
    status?: InputMaybe<StatusOperationalAssignorByMinibank>;
    typeOfAnalysis?: InputMaybe<TypeOfAnalysis>;
};

export type AssignorStatusByMinibankGroupBy = {
    __typename?: "AssignorStatusByMinibankGroupBy";
    _avg?: Maybe<AssignorStatusByMinibankAvgAggregate>;
    _count?: Maybe<AssignorStatusByMinibankCountAggregate>;
    _max?: Maybe<AssignorStatusByMinibankMaxAggregate>;
    _min?: Maybe<AssignorStatusByMinibankMinAggregate>;
    _sum?: Maybe<AssignorStatusByMinibankSumAggregate>;
    assignorId: Scalars["Int"];
    attachmentId?: Maybe<Scalars["Int"]>;
    id: Scalars["Int"];
    minibankId: Scalars["Int"];
    status: StatusOperationalAssignorByMinibank;
    typeOfAnalysis: TypeOfAnalysis;
};

export type AssignorStatusByMinibankListRelationFilter = {
    every?: InputMaybe<AssignorStatusByMinibankWhereInput>;
    none?: InputMaybe<AssignorStatusByMinibankWhereInput>;
    some?: InputMaybe<AssignorStatusByMinibankWhereInput>;
};

export type AssignorStatusByMinibankMaxAggregate = {
    __typename?: "AssignorStatusByMinibankMaxAggregate";
    assignorId?: Maybe<Scalars["Int"]>;
    attachmentId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    minibankId?: Maybe<Scalars["Int"]>;
    status?: Maybe<StatusOperationalAssignorByMinibank>;
    typeOfAnalysis?: Maybe<TypeOfAnalysis>;
};

export type AssignorStatusByMinibankMaxOrderByAggregateInput = {
    assignorId?: InputMaybe<SortOrder>;
    attachmentId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    typeOfAnalysis?: InputMaybe<SortOrder>;
};

export type AssignorStatusByMinibankMinAggregate = {
    __typename?: "AssignorStatusByMinibankMinAggregate";
    assignorId?: Maybe<Scalars["Int"]>;
    attachmentId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    minibankId?: Maybe<Scalars["Int"]>;
    status?: Maybe<StatusOperationalAssignorByMinibank>;
    typeOfAnalysis?: Maybe<TypeOfAnalysis>;
};

export type AssignorStatusByMinibankMinOrderByAggregateInput = {
    assignorId?: InputMaybe<SortOrder>;
    attachmentId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    typeOfAnalysis?: InputMaybe<SortOrder>;
};

export type AssignorStatusByMinibankOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type AssignorStatusByMinibankOrderByWithAggregationInput = {
    _avg?: InputMaybe<AssignorStatusByMinibankAvgOrderByAggregateInput>;
    _count?: InputMaybe<AssignorStatusByMinibankCountOrderByAggregateInput>;
    _max?: InputMaybe<AssignorStatusByMinibankMaxOrderByAggregateInput>;
    _min?: InputMaybe<AssignorStatusByMinibankMinOrderByAggregateInput>;
    _sum?: InputMaybe<AssignorStatusByMinibankSumOrderByAggregateInput>;
    assignorId?: InputMaybe<SortOrder>;
    attachmentId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    typeOfAnalysis?: InputMaybe<SortOrder>;
};

export type AssignorStatusByMinibankOrderByWithRelationInput = {
    assignor?: InputMaybe<CedenteOrderByWithRelationInput>;
    assignorAttachment?: InputMaybe<CedenteAnexosOrderByWithRelationInput>;
    assignorId?: InputMaybe<SortOrder>;
    attachmentId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibank?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibankId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    typeOfAnalysis?: InputMaybe<SortOrder>;
};

export enum AssignorStatusByMinibankScalarFieldEnum {
    AssignorId = "assignorId",
    AttachmentId = "attachmentId",
    Id = "id",
    MinibankId = "minibankId",
    Status = "status",
    TypeOfAnalysis = "typeOfAnalysis",
}

export type AssignorStatusByMinibankScalarWhereInput = {
    AND?: InputMaybe<Array<AssignorStatusByMinibankScalarWhereInput>>;
    NOT?: InputMaybe<Array<AssignorStatusByMinibankScalarWhereInput>>;
    OR?: InputMaybe<Array<AssignorStatusByMinibankScalarWhereInput>>;
    assignorId?: InputMaybe<IntFilter>;
    attachmentId?: InputMaybe<IntNullableFilter>;
    id?: InputMaybe<IntFilter>;
    minibankId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumStatusOperationalAssignorByMinibankFilter>;
    typeOfAnalysis?: InputMaybe<EnumTypeOfAnalysisFilter>;
};

export type AssignorStatusByMinibankScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<AssignorStatusByMinibankScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<AssignorStatusByMinibankScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<AssignorStatusByMinibankScalarWhereWithAggregatesInput>>;
    assignorId?: InputMaybe<IntWithAggregatesFilter>;
    attachmentId?: InputMaybe<IntNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    minibankId?: InputMaybe<IntWithAggregatesFilter>;
    status?: InputMaybe<EnumStatusOperationalAssignorByMinibankWithAggregatesFilter>;
    typeOfAnalysis?: InputMaybe<EnumTypeOfAnalysisWithAggregatesFilter>;
};

export type AssignorStatusByMinibankSumAggregate = {
    __typename?: "AssignorStatusByMinibankSumAggregate";
    assignorId?: Maybe<Scalars["Int"]>;
    attachmentId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    minibankId?: Maybe<Scalars["Int"]>;
};

export type AssignorStatusByMinibankSumOrderByAggregateInput = {
    assignorId?: InputMaybe<SortOrder>;
    attachmentId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
};

export type AssignorStatusByMinibankUpdateInput = {
    assignor?: InputMaybe<CedenteUpdateOneRequiredWithoutAssignorStatusByMinibankNestedInput>;
    assignorAttachment?: InputMaybe<CedenteAnexosUpdateOneWithoutAssignorStatusByMinibankNestedInput>;
    minibank?: InputMaybe<MinibancoUpdateOneRequiredWithoutAssignorStatusByMinibankNestedInput>;
    status?: InputMaybe<EnumStatusOperationalAssignorByMinibankFieldUpdateOperationsInput>;
    typeOfAnalysis?: InputMaybe<EnumTypeOfAnalysisFieldUpdateOperationsInput>;
};

export type AssignorStatusByMinibankUpdateManyMutationInput = {
    status?: InputMaybe<EnumStatusOperationalAssignorByMinibankFieldUpdateOperationsInput>;
    typeOfAnalysis?: InputMaybe<EnumTypeOfAnalysisFieldUpdateOperationsInput>;
};

export type AssignorStatusByMinibankUpdateManyWithWhereWithoutAssignorAttachmentInput = {
    data: AssignorStatusByMinibankUpdateManyMutationInput;
    where: AssignorStatusByMinibankScalarWhereInput;
};

export type AssignorStatusByMinibankUpdateManyWithWhereWithoutAssignorInput = {
    data: AssignorStatusByMinibankUpdateManyMutationInput;
    where: AssignorStatusByMinibankScalarWhereInput;
};

export type AssignorStatusByMinibankUpdateManyWithWhereWithoutMinibankInput = {
    data: AssignorStatusByMinibankUpdateManyMutationInput;
    where: AssignorStatusByMinibankScalarWhereInput;
};

export type AssignorStatusByMinibankUpdateManyWithoutAssignorAttachmentNestedInput = {
    connect?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<AssignorStatusByMinibankCreateOrConnectWithoutAssignorAttachmentInput>>;
    create?: InputMaybe<Array<AssignorStatusByMinibankCreateWithoutAssignorAttachmentInput>>;
    createMany?: InputMaybe<AssignorStatusByMinibankCreateManyAssignorAttachmentInputEnvelope>;
    delete?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<AssignorStatusByMinibankScalarWhereInput>>;
    disconnect?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    set?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    update?: InputMaybe<Array<AssignorStatusByMinibankUpdateWithWhereUniqueWithoutAssignorAttachmentInput>>;
    updateMany?: InputMaybe<Array<AssignorStatusByMinibankUpdateManyWithWhereWithoutAssignorAttachmentInput>>;
    upsert?: InputMaybe<Array<AssignorStatusByMinibankUpsertWithWhereUniqueWithoutAssignorAttachmentInput>>;
};

export type AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput = {
    connect?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<AssignorStatusByMinibankCreateOrConnectWithoutAssignorInput>>;
    create?: InputMaybe<Array<AssignorStatusByMinibankCreateWithoutAssignorInput>>;
    createMany?: InputMaybe<AssignorStatusByMinibankCreateManyAssignorInputEnvelope>;
    delete?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<AssignorStatusByMinibankScalarWhereInput>>;
    disconnect?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    set?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    update?: InputMaybe<Array<AssignorStatusByMinibankUpdateWithWhereUniqueWithoutAssignorInput>>;
    updateMany?: InputMaybe<Array<AssignorStatusByMinibankUpdateManyWithWhereWithoutAssignorInput>>;
    upsert?: InputMaybe<Array<AssignorStatusByMinibankUpsertWithWhereUniqueWithoutAssignorInput>>;
};

export type AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput = {
    connect?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<AssignorStatusByMinibankCreateOrConnectWithoutMinibankInput>>;
    create?: InputMaybe<Array<AssignorStatusByMinibankCreateWithoutMinibankInput>>;
    createMany?: InputMaybe<AssignorStatusByMinibankCreateManyMinibankInputEnvelope>;
    delete?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<AssignorStatusByMinibankScalarWhereInput>>;
    disconnect?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    set?: InputMaybe<Array<AssignorStatusByMinibankWhereUniqueInput>>;
    update?: InputMaybe<Array<AssignorStatusByMinibankUpdateWithWhereUniqueWithoutMinibankInput>>;
    updateMany?: InputMaybe<Array<AssignorStatusByMinibankUpdateManyWithWhereWithoutMinibankInput>>;
    upsert?: InputMaybe<Array<AssignorStatusByMinibankUpsertWithWhereUniqueWithoutMinibankInput>>;
};

export type AssignorStatusByMinibankUpdateWithWhereUniqueWithoutAssignorAttachmentInput = {
    data: AssignorStatusByMinibankUpdateWithoutAssignorAttachmentInput;
    where: AssignorStatusByMinibankWhereUniqueInput;
};

export type AssignorStatusByMinibankUpdateWithWhereUniqueWithoutAssignorInput = {
    data: AssignorStatusByMinibankUpdateWithoutAssignorInput;
    where: AssignorStatusByMinibankWhereUniqueInput;
};

export type AssignorStatusByMinibankUpdateWithWhereUniqueWithoutMinibankInput = {
    data: AssignorStatusByMinibankUpdateWithoutMinibankInput;
    where: AssignorStatusByMinibankWhereUniqueInput;
};

export type AssignorStatusByMinibankUpdateWithoutAssignorAttachmentInput = {
    assignor?: InputMaybe<CedenteUpdateOneRequiredWithoutAssignorStatusByMinibankNestedInput>;
    minibank?: InputMaybe<MinibancoUpdateOneRequiredWithoutAssignorStatusByMinibankNestedInput>;
    status?: InputMaybe<EnumStatusOperationalAssignorByMinibankFieldUpdateOperationsInput>;
    typeOfAnalysis?: InputMaybe<EnumTypeOfAnalysisFieldUpdateOperationsInput>;
};

export type AssignorStatusByMinibankUpdateWithoutAssignorInput = {
    assignorAttachment?: InputMaybe<CedenteAnexosUpdateOneWithoutAssignorStatusByMinibankNestedInput>;
    minibank?: InputMaybe<MinibancoUpdateOneRequiredWithoutAssignorStatusByMinibankNestedInput>;
    status?: InputMaybe<EnumStatusOperationalAssignorByMinibankFieldUpdateOperationsInput>;
    typeOfAnalysis?: InputMaybe<EnumTypeOfAnalysisFieldUpdateOperationsInput>;
};

export type AssignorStatusByMinibankUpdateWithoutMinibankInput = {
    assignor?: InputMaybe<CedenteUpdateOneRequiredWithoutAssignorStatusByMinibankNestedInput>;
    assignorAttachment?: InputMaybe<CedenteAnexosUpdateOneWithoutAssignorStatusByMinibankNestedInput>;
    status?: InputMaybe<EnumStatusOperationalAssignorByMinibankFieldUpdateOperationsInput>;
    typeOfAnalysis?: InputMaybe<EnumTypeOfAnalysisFieldUpdateOperationsInput>;
};

export type AssignorStatusByMinibankUpsertWithWhereUniqueWithoutAssignorAttachmentInput = {
    create: AssignorStatusByMinibankCreateWithoutAssignorAttachmentInput;
    update: AssignorStatusByMinibankUpdateWithoutAssignorAttachmentInput;
    where: AssignorStatusByMinibankWhereUniqueInput;
};

export type AssignorStatusByMinibankUpsertWithWhereUniqueWithoutAssignorInput = {
    create: AssignorStatusByMinibankCreateWithoutAssignorInput;
    update: AssignorStatusByMinibankUpdateWithoutAssignorInput;
    where: AssignorStatusByMinibankWhereUniqueInput;
};

export type AssignorStatusByMinibankUpsertWithWhereUniqueWithoutMinibankInput = {
    create: AssignorStatusByMinibankCreateWithoutMinibankInput;
    update: AssignorStatusByMinibankUpdateWithoutMinibankInput;
    where: AssignorStatusByMinibankWhereUniqueInput;
};

export type AssignorStatusByMinibankWhereInput = {
    AND?: InputMaybe<Array<AssignorStatusByMinibankWhereInput>>;
    NOT?: InputMaybe<Array<AssignorStatusByMinibankWhereInput>>;
    OR?: InputMaybe<Array<AssignorStatusByMinibankWhereInput>>;
    assignor?: InputMaybe<CedenteRelationFilter>;
    assignorAttachment?: InputMaybe<CedenteAnexosRelationFilter>;
    assignorId?: InputMaybe<IntFilter>;
    attachmentId?: InputMaybe<IntNullableFilter>;
    id?: InputMaybe<IntFilter>;
    minibank?: InputMaybe<MinibancoRelationFilter>;
    minibankId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumStatusOperationalAssignorByMinibankFilter>;
    typeOfAnalysis?: InputMaybe<EnumTypeOfAnalysisFilter>;
};

export type AssignorStatusByMinibankWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export enum AuditAcoes {
    Create = "CREATE",
    Delete = "DELETE",
    Login = "LOGIN",
    Update = "UPDATE",
}

export type Banks = {
    __typename?: "Banks";
    codeBank: Scalars["String"];
    id: Scalars["Int"];
    longName: Scalars["String"];
    shortName: Scalars["String"];
};

export type BanksAvgAggregate = {
    __typename?: "BanksAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
};

export type BanksAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type BanksCountAggregate = {
    __typename?: "BanksCountAggregate";
    _all: Scalars["Int"];
    codeBank: Scalars["Int"];
    id: Scalars["Int"];
    longName: Scalars["Int"];
    shortName: Scalars["Int"];
};

export type BanksCountOrderByAggregateInput = {
    codeBank?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    longName?: InputMaybe<SortOrder>;
    shortName?: InputMaybe<SortOrder>;
};

export type BanksCreateInput = {
    codeBank: Scalars["String"];
    longName: Scalars["String"];
    shortName: Scalars["String"];
};

export type BanksCreateManyInput = {
    codeBank: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    longName: Scalars["String"];
    shortName: Scalars["String"];
};

export type BanksGroupBy = {
    __typename?: "BanksGroupBy";
    _avg?: Maybe<BanksAvgAggregate>;
    _count?: Maybe<BanksCountAggregate>;
    _max?: Maybe<BanksMaxAggregate>;
    _min?: Maybe<BanksMinAggregate>;
    _sum?: Maybe<BanksSumAggregate>;
    codeBank: Scalars["String"];
    id: Scalars["Int"];
    longName: Scalars["String"];
    shortName: Scalars["String"];
};

export type BanksMaxAggregate = {
    __typename?: "BanksMaxAggregate";
    codeBank?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    longName?: Maybe<Scalars["String"]>;
    shortName?: Maybe<Scalars["String"]>;
};

export type BanksMaxOrderByAggregateInput = {
    codeBank?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    longName?: InputMaybe<SortOrder>;
    shortName?: InputMaybe<SortOrder>;
};

export type BanksMinAggregate = {
    __typename?: "BanksMinAggregate";
    codeBank?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    longName?: Maybe<Scalars["String"]>;
    shortName?: Maybe<Scalars["String"]>;
};

export type BanksMinOrderByAggregateInput = {
    codeBank?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    longName?: InputMaybe<SortOrder>;
    shortName?: InputMaybe<SortOrder>;
};

export type BanksOrderByWithAggregationInput = {
    _avg?: InputMaybe<BanksAvgOrderByAggregateInput>;
    _count?: InputMaybe<BanksCountOrderByAggregateInput>;
    _max?: InputMaybe<BanksMaxOrderByAggregateInput>;
    _min?: InputMaybe<BanksMinOrderByAggregateInput>;
    _sum?: InputMaybe<BanksSumOrderByAggregateInput>;
    codeBank?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    longName?: InputMaybe<SortOrder>;
    shortName?: InputMaybe<SortOrder>;
};

export type BanksOrderByWithRelationInput = {
    codeBank?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    longName?: InputMaybe<SortOrder>;
    shortName?: InputMaybe<SortOrder>;
};

export enum BanksScalarFieldEnum {
    CodeBank = "codeBank",
    Id = "id",
    LongName = "longName",
    ShortName = "shortName",
}

export type BanksScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<BanksScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<BanksScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<BanksScalarWhereWithAggregatesInput>>;
    codeBank?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    longName?: InputMaybe<StringWithAggregatesFilter>;
    shortName?: InputMaybe<StringWithAggregatesFilter>;
};

export type BanksSumAggregate = {
    __typename?: "BanksSumAggregate";
    id?: Maybe<Scalars["Int"]>;
};

export type BanksSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type BanksUpdateInput = {
    codeBank?: InputMaybe<StringFieldUpdateOperationsInput>;
    longName?: InputMaybe<StringFieldUpdateOperationsInput>;
    shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type BanksUpdateManyMutationInput = {
    codeBank?: InputMaybe<StringFieldUpdateOperationsInput>;
    longName?: InputMaybe<StringFieldUpdateOperationsInput>;
    shortName?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type BanksWhereInput = {
    AND?: InputMaybe<Array<BanksWhereInput>>;
    NOT?: InputMaybe<Array<BanksWhereInput>>;
    OR?: InputMaybe<Array<BanksWhereInput>>;
    codeBank?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    longName?: InputMaybe<StringFilter>;
    shortName?: InputMaybe<StringFilter>;
};

export type BanksWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type BoolFieldUpdateOperationsInput = {
    set?: InputMaybe<Scalars["Boolean"]>;
};

export type BoolFilter = {
    equals?: InputMaybe<Scalars["Boolean"]>;
    not?: InputMaybe<NestedBoolFilter>;
};

export type BoolWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedBoolFilter>;
    _min?: InputMaybe<NestedBoolFilter>;
    equals?: InputMaybe<Scalars["Boolean"]>;
    not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type CTe = {
    __typename?: "CTe";
    createdAt: Scalars["DateTime"];
    cteKey: Scalars["String"];
    inativo: Scalars["Boolean"];
    recebivel: Recebivel;
    recebivelId: Scalars["Int"];
};

export type CTeAvgAggregate = {
    __typename?: "CTeAvgAggregate";
    recebivelId?: Maybe<Scalars["Float"]>;
};

export type CTeAvgOrderByAggregateInput = {
    recebivelId?: InputMaybe<SortOrder>;
};

export type CTeCountAggregate = {
    __typename?: "CTeCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    cteKey: Scalars["Int"];
    inativo: Scalars["Int"];
    recebivelId: Scalars["Int"];
};

export type CTeCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    cteKey?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type CTeCreateInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    cteKey: Scalars["String"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    recebivel: RecebivelCreateNestedOneWithoutCTeInput;
};

export type CTeCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    cteKey: Scalars["String"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    recebivelId: Scalars["Int"];
};

export type CTeCreateNestedOneWithoutRecebivelInput = {
    connect?: InputMaybe<CTeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CTeCreateOrConnectWithoutRecebivelInput>;
    create?: InputMaybe<CTeCreateWithoutRecebivelInput>;
};

export type CTeCreateOrConnectWithoutRecebivelInput = {
    create: CTeCreateWithoutRecebivelInput;
    where: CTeWhereUniqueInput;
};

export type CTeCreateWithoutRecebivelInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    cteKey: Scalars["String"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
};

export type CTeGroupBy = {
    __typename?: "CTeGroupBy";
    _avg?: Maybe<CTeAvgAggregate>;
    _count?: Maybe<CTeCountAggregate>;
    _max?: Maybe<CTeMaxAggregate>;
    _min?: Maybe<CTeMinAggregate>;
    _sum?: Maybe<CTeSumAggregate>;
    createdAt: Scalars["DateTime"];
    cteKey: Scalars["String"];
    inativo: Scalars["Boolean"];
    recebivelId: Scalars["Int"];
};

export type CTeMaxAggregate = {
    __typename?: "CTeMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    cteKey?: Maybe<Scalars["String"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
};

export type CTeMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    cteKey?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type CTeMinAggregate = {
    __typename?: "CTeMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    cteKey?: Maybe<Scalars["String"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
};

export type CTeMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    cteKey?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type CTeOrderByWithAggregationInput = {
    _avg?: InputMaybe<CTeAvgOrderByAggregateInput>;
    _count?: InputMaybe<CTeCountOrderByAggregateInput>;
    _max?: InputMaybe<CTeMaxOrderByAggregateInput>;
    _min?: InputMaybe<CTeMinOrderByAggregateInput>;
    _sum?: InputMaybe<CTeSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    cteKey?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type CTeOrderByWithRelationInput = {
    createdAt?: InputMaybe<SortOrder>;
    cteKey?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    recebivel?: InputMaybe<RecebivelOrderByWithRelationInput>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type CTeRelationFilter = {
    is?: InputMaybe<CTeWhereInput>;
    isNot?: InputMaybe<CTeWhereInput>;
};

export enum CTeScalarFieldEnum {
    CreatedAt = "createdAt",
    CteKey = "cteKey",
    Inativo = "inativo",
    RecebivelId = "recebivelId",
}

export type CTeScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CTeScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CTeScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CTeScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    cteKey?: InputMaybe<StringWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    recebivelId?: InputMaybe<IntWithAggregatesFilter>;
};

export type CTeSumAggregate = {
    __typename?: "CTeSumAggregate";
    recebivelId?: Maybe<Scalars["Int"]>;
};

export type CTeSumOrderByAggregateInput = {
    recebivelId?: InputMaybe<SortOrder>;
};

export type CTeUpdateInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    cteKey?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneRequiredWithoutCTeNestedInput>;
};

export type CTeUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    cteKey?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CTeUpdateOneWithoutRecebivelNestedInput = {
    connect?: InputMaybe<CTeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CTeCreateOrConnectWithoutRecebivelInput>;
    create?: InputMaybe<CTeCreateWithoutRecebivelInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<CTeUpdateWithoutRecebivelInput>;
    upsert?: InputMaybe<CTeUpsertWithoutRecebivelInput>;
};

export type CTeUpdateWithoutRecebivelInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    cteKey?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CTeUpsertWithoutRecebivelInput = {
    create: CTeCreateWithoutRecebivelInput;
    update: CTeUpdateWithoutRecebivelInput;
};

export type CTeWhereInput = {
    AND?: InputMaybe<Array<CTeWhereInput>>;
    NOT?: InputMaybe<Array<CTeWhereInput>>;
    OR?: InputMaybe<Array<CTeWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    cteKey?: InputMaybe<StringFilter>;
    inativo?: InputMaybe<BoolFilter>;
    recebivel?: InputMaybe<RecebivelRelationFilter>;
    recebivelId?: InputMaybe<IntFilter>;
};

export type CTeWhereUniqueInput = {
    cteKey?: InputMaybe<Scalars["String"]>;
    recebivelId?: InputMaybe<Scalars["Int"]>;
};

export enum CadastroStatus {
    Aprovado = "APROVADO",
    EmAnalise = "EM_ANALISE",
    Recusado = "RECUSADO",
}

export type Cedente = {
    __typename?: "Cedente";
    AssignorStatusByMinibank: Array<AssignorStatusByMinibank>;
    CedentesInfo?: Maybe<CedentesInfo>;
    ContaBancaria: Array<ContaBancaria>;
    OperationsInfo: Array<OperationsInfo>;
    PreCadastroCedente?: Maybe<PreCadastroCedente>;
    RecebiveisInfo: Array<RecebiveisInfo>;
    RecebiveisToAnticipate: Array<RecebiveisToAnticipate>;
    Representative?: Maybe<Representative>;
    _count?: Maybe<CedenteCount>;
    alteracoes: Array<CedenteAlteracao>;
    anexos: Array<CedenteAnexos>;
    assignorBankData?: Maybe<AssignorBankData>;
    ativo: Scalars["Boolean"];
    cadastro?: Maybe<CedenteCadastro>;
    cadastroId?: Maybe<Scalars["Int"]>;
    cnae?: Maybe<CnaeClasses>;
    cnpj: Scalars["String"];
    codigoCnae?: Maybe<Scalars["Int"]>;
    contaBancId?: Maybe<Scalars["Int"]>;
    contatos: Array<CedenteContato>;
    createdAt: Scalars["DateTime"];
    digitalSignature?: Maybe<DigitalSignature>;
    email?: Maybe<Scalars["String"]>;
    endereco?: Maybe<Endereco>;
    enderecoId?: Maybe<Scalars["Int"]>;
    fone?: Maybe<Scalars["String"]>;
    histStatus: Array<StatusOperacionalAudit>;
    historico: Array<CedenteAudit>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibancos: Array<Minibanco>;
    naoAntecipa: Scalars["Boolean"];
    nomeFantasia?: Maybe<Scalars["String"]>;
    origem: OrigemCedente;
    pessoaFisica: Scalars["Boolean"];
    razaoSocial: Scalars["String"];
    recebiveis: Array<Recebivel>;
    representativeId?: Maybe<Scalars["Int"]>;
    responsaveis: Array<ResponsavelCedente>;
    situation: Scalars["Boolean"];
    status: CedenteStatus;
    statusOperacional: Array<StatusOperacionalCedenteMinibanco>;
    updatedAt: Scalars["DateTime"];
};

export type CedenteAssignorStatusByMinibankArgs = {
    cursor?: InputMaybe<AssignorStatusByMinibankWhereUniqueInput>;
    distinct?: InputMaybe<Array<AssignorStatusByMinibankScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AssignorStatusByMinibankOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorStatusByMinibankWhereInput>;
};

export type CedenteContaBancariaArgs = {
    cursor?: InputMaybe<ContaBancariaWhereUniqueInput>;
    distinct?: InputMaybe<Array<ContaBancariaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ContaBancariaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContaBancariaWhereInput>;
};

export type CedenteOperationsInfoArgs = {
    cursor?: InputMaybe<OperationsInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationsInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationsInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationsInfoWhereInput>;
};

export type CedenteRecebiveisInfoArgs = {
    cursor?: InputMaybe<RecebiveisInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebiveisInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebiveisInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisInfoWhereInput>;
};

export type CedenteRecebiveisToAnticipateArgs = {
    cursor?: InputMaybe<RecebiveisToAnticipateWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebiveisToAnticipateScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebiveisToAnticipateOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisToAnticipateWhereInput>;
};

export type CedenteAlteracoesArgs = {
    cursor?: InputMaybe<CedenteAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAlteracaoWhereInput>;
};

export type CedenteAnexosArgs = {
    cursor?: InputMaybe<CedenteAnexosWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAnexosScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAnexosOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAnexosWhereInput>;
};

export type CedenteContatosArgs = {
    cursor?: InputMaybe<CedenteContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteContatoWhereInput>;
};

export type CedenteHistStatusArgs = {
    cursor?: InputMaybe<StatusOperacionalAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<StatusOperacionalAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<StatusOperacionalAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<StatusOperacionalAuditWhereInput>;
};

export type CedenteHistoricoArgs = {
    cursor?: InputMaybe<CedenteAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAuditWhereInput>;
};

export type CedenteMinibancosArgs = {
    cursor?: InputMaybe<MinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoWhereInput>;
};

export type CedenteRecebiveisArgs = {
    cursor?: InputMaybe<RecebivelWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelWhereInput>;
};

export type CedenteResponsaveisArgs = {
    cursor?: InputMaybe<ResponsavelCedenteWhereUniqueInput>;
    distinct?: InputMaybe<Array<ResponsavelCedenteScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ResponsavelCedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ResponsavelCedenteWhereInput>;
};

export type CedenteStatusOperacionalArgs = {
    cursor?: InputMaybe<StatusOperacionalCedenteMinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<StatusOperacionalCedenteMinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<StatusOperacionalCedenteMinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<StatusOperacionalCedenteMinibancoWhereInput>;
};

export type CedenteAlteracao = {
    __typename?: "CedenteAlteracao";
    _count?: Maybe<CedenteAlteracaoCount>;
    campos: Array<CedenteCamposAlteracao>;
    cedente: Cedente;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    status: CedenteStatus;
    updatedAt: Scalars["DateTime"];
    usuario: Usuario;
    usuarioId: Scalars["Int"];
};

export type CedenteAlteracaoCamposArgs = {
    cursor?: InputMaybe<CedenteCamposAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteCamposAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteCamposAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCamposAlteracaoWhereInput>;
};

export type CedenteAlteracaoAvgAggregate = {
    __typename?: "CedenteAlteracaoAvgAggregate";
    cedenteId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type CedenteAlteracaoAvgOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteAlteracaoCount = {
    __typename?: "CedenteAlteracaoCount";
    campos: Scalars["Int"];
};

export type CedenteAlteracaoCountAggregate = {
    __typename?: "CedenteAlteracaoCountAggregate";
    _all: Scalars["Int"];
    cedenteId: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    minibancoId: Scalars["Int"];
    status: Scalars["Int"];
    updatedAt: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type CedenteAlteracaoCountOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteAlteracaoCreateInput = {
    campos?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutAlteracaoInput>;
    cedente: CedenteCreateNestedOneWithoutAlteracoesInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    minibanco: MinibancoCreateNestedOneWithoutAlteracaoCedentesInput;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutAlteracaoCedentesInput;
};

export type CedenteAlteracaoCreateManyCedenteInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
};

export type CedenteAlteracaoCreateManyCedenteInputEnvelope = {
    data: Array<CedenteAlteracaoCreateManyCedenteInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteAlteracaoCreateManyInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
};

export type CedenteAlteracaoCreateManyMinibancoInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
};

export type CedenteAlteracaoCreateManyMinibancoInputEnvelope = {
    data: Array<CedenteAlteracaoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteAlteracaoCreateManyUsuarioInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteAlteracaoCreateManyUsuarioInputEnvelope = {
    data: Array<CedenteAlteracaoCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteAlteracaoCreateNestedManyWithoutCedenteInput = {
    connect?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAlteracaoCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<CedenteAlteracaoCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<CedenteAlteracaoCreateManyCedenteInputEnvelope>;
};

export type CedenteAlteracaoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAlteracaoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<CedenteAlteracaoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<CedenteAlteracaoCreateManyMinibancoInputEnvelope>;
};

export type CedenteAlteracaoCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAlteracaoCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<CedenteAlteracaoCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<CedenteAlteracaoCreateManyUsuarioInputEnvelope>;
};

export type CedenteAlteracaoCreateNestedOneWithoutCamposInput = {
    connect?: InputMaybe<CedenteAlteracaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteAlteracaoCreateOrConnectWithoutCamposInput>;
    create?: InputMaybe<CedenteAlteracaoCreateWithoutCamposInput>;
};

export type CedenteAlteracaoCreateOrConnectWithoutCamposInput = {
    create: CedenteAlteracaoCreateWithoutCamposInput;
    where: CedenteAlteracaoWhereUniqueInput;
};

export type CedenteAlteracaoCreateOrConnectWithoutCedenteInput = {
    create: CedenteAlteracaoCreateWithoutCedenteInput;
    where: CedenteAlteracaoWhereUniqueInput;
};

export type CedenteAlteracaoCreateOrConnectWithoutMinibancoInput = {
    create: CedenteAlteracaoCreateWithoutMinibancoInput;
    where: CedenteAlteracaoWhereUniqueInput;
};

export type CedenteAlteracaoCreateOrConnectWithoutUsuarioInput = {
    create: CedenteAlteracaoCreateWithoutUsuarioInput;
    where: CedenteAlteracaoWhereUniqueInput;
};

export type CedenteAlteracaoCreateWithoutCamposInput = {
    cedente: CedenteCreateNestedOneWithoutAlteracoesInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    minibanco: MinibancoCreateNestedOneWithoutAlteracaoCedentesInput;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutAlteracaoCedentesInput;
};

export type CedenteAlteracaoCreateWithoutCedenteInput = {
    campos?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutAlteracaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    minibanco: MinibancoCreateNestedOneWithoutAlteracaoCedentesInput;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutAlteracaoCedentesInput;
};

export type CedenteAlteracaoCreateWithoutMinibancoInput = {
    campos?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutAlteracaoInput>;
    cedente: CedenteCreateNestedOneWithoutAlteracoesInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutAlteracaoCedentesInput;
};

export type CedenteAlteracaoCreateWithoutUsuarioInput = {
    campos?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutAlteracaoInput>;
    cedente: CedenteCreateNestedOneWithoutAlteracoesInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    minibanco: MinibancoCreateNestedOneWithoutAlteracaoCedentesInput;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteAlteracaoGroupBy = {
    __typename?: "CedenteAlteracaoGroupBy";
    _avg?: Maybe<CedenteAlteracaoAvgAggregate>;
    _count?: Maybe<CedenteAlteracaoCountAggregate>;
    _max?: Maybe<CedenteAlteracaoMaxAggregate>;
    _min?: Maybe<CedenteAlteracaoMinAggregate>;
    _sum?: Maybe<CedenteAlteracaoSumAggregate>;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    minibancoId: Scalars["Int"];
    status: CedenteStatus;
    updatedAt: Scalars["DateTime"];
    usuarioId: Scalars["Int"];
};

export type CedenteAlteracaoListRelationFilter = {
    every?: InputMaybe<CedenteAlteracaoWhereInput>;
    none?: InputMaybe<CedenteAlteracaoWhereInput>;
    some?: InputMaybe<CedenteAlteracaoWhereInput>;
};

export type CedenteAlteracaoMaxAggregate = {
    __typename?: "CedenteAlteracaoMaxAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    status?: Maybe<CedenteStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type CedenteAlteracaoMaxOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteAlteracaoMinAggregate = {
    __typename?: "CedenteAlteracaoMinAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    status?: Maybe<CedenteStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type CedenteAlteracaoMinOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteAlteracaoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type CedenteAlteracaoOrderByWithAggregationInput = {
    _avg?: InputMaybe<CedenteAlteracaoAvgOrderByAggregateInput>;
    _count?: InputMaybe<CedenteAlteracaoCountOrderByAggregateInput>;
    _max?: InputMaybe<CedenteAlteracaoMaxOrderByAggregateInput>;
    _min?: InputMaybe<CedenteAlteracaoMinOrderByAggregateInput>;
    _sum?: InputMaybe<CedenteAlteracaoSumOrderByAggregateInput>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteAlteracaoOrderByWithRelationInput = {
    campos?: InputMaybe<CedenteCamposAlteracaoOrderByRelationAggregateInput>;
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteAlteracaoRelationFilter = {
    is?: InputMaybe<CedenteAlteracaoWhereInput>;
    isNot?: InputMaybe<CedenteAlteracaoWhereInput>;
};

export enum CedenteAlteracaoScalarFieldEnum {
    CedenteId = "cedenteId",
    CreatedAt = "createdAt",
    Id = "id",
    MinibancoId = "minibancoId",
    Status = "status",
    UpdatedAt = "updatedAt",
    UsuarioId = "usuarioId",
}

export type CedenteAlteracaoScalarWhereInput = {
    AND?: InputMaybe<Array<CedenteAlteracaoScalarWhereInput>>;
    NOT?: InputMaybe<Array<CedenteAlteracaoScalarWhereInput>>;
    OR?: InputMaybe<Array<CedenteAlteracaoScalarWhereInput>>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuarioId?: InputMaybe<IntFilter>;
};

export type CedenteAlteracaoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CedenteAlteracaoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CedenteAlteracaoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CedenteAlteracaoScalarWhereWithAggregatesInput>>;
    cedenteId?: InputMaybe<IntWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    status?: InputMaybe<EnumCedenteStatusWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntWithAggregatesFilter>;
};

export type CedenteAlteracaoSumAggregate = {
    __typename?: "CedenteAlteracaoSumAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type CedenteAlteracaoSumOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteAlteracaoUpdateInput = {
    campos?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutAlteracaoNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutAlteracoesNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutAlteracaoCedentesNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutAlteracaoCedentesNestedInput>;
};

export type CedenteAlteracaoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteAlteracaoUpdateManyWithWhereWithoutCedenteInput = {
    data: CedenteAlteracaoUpdateManyMutationInput;
    where: CedenteAlteracaoScalarWhereInput;
};

export type CedenteAlteracaoUpdateManyWithWhereWithoutMinibancoInput = {
    data: CedenteAlteracaoUpdateManyMutationInput;
    where: CedenteAlteracaoScalarWhereInput;
};

export type CedenteAlteracaoUpdateManyWithWhereWithoutUsuarioInput = {
    data: CedenteAlteracaoUpdateManyMutationInput;
    where: CedenteAlteracaoScalarWhereInput;
};

export type CedenteAlteracaoUpdateManyWithoutCedenteNestedInput = {
    connect?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAlteracaoCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<CedenteAlteracaoCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<CedenteAlteracaoCreateManyCedenteInputEnvelope>;
    delete?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteAlteracaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteAlteracaoUpdateWithWhereUniqueWithoutCedenteInput>>;
    updateMany?: InputMaybe<Array<CedenteAlteracaoUpdateManyWithWhereWithoutCedenteInput>>;
    upsert?: InputMaybe<Array<CedenteAlteracaoUpsertWithWhereUniqueWithoutCedenteInput>>;
};

export type CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAlteracaoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<CedenteAlteracaoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<CedenteAlteracaoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteAlteracaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteAlteracaoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<CedenteAlteracaoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<CedenteAlteracaoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAlteracaoCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<CedenteAlteracaoCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<CedenteAlteracaoCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteAlteracaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteAlteracaoWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteAlteracaoUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<CedenteAlteracaoUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<CedenteAlteracaoUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type CedenteAlteracaoUpdateOneRequiredWithoutCamposNestedInput = {
    connect?: InputMaybe<CedenteAlteracaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteAlteracaoCreateOrConnectWithoutCamposInput>;
    create?: InputMaybe<CedenteAlteracaoCreateWithoutCamposInput>;
    update?: InputMaybe<CedenteAlteracaoUpdateWithoutCamposInput>;
    upsert?: InputMaybe<CedenteAlteracaoUpsertWithoutCamposInput>;
};

export type CedenteAlteracaoUpdateWithWhereUniqueWithoutCedenteInput = {
    data: CedenteAlteracaoUpdateWithoutCedenteInput;
    where: CedenteAlteracaoWhereUniqueInput;
};

export type CedenteAlteracaoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: CedenteAlteracaoUpdateWithoutMinibancoInput;
    where: CedenteAlteracaoWhereUniqueInput;
};

export type CedenteAlteracaoUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: CedenteAlteracaoUpdateWithoutUsuarioInput;
    where: CedenteAlteracaoWhereUniqueInput;
};

export type CedenteAlteracaoUpdateWithoutCamposInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutAlteracoesNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutAlteracaoCedentesNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutAlteracaoCedentesNestedInput>;
};

export type CedenteAlteracaoUpdateWithoutCedenteInput = {
    campos?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutAlteracaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutAlteracaoCedentesNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutAlteracaoCedentesNestedInput>;
};

export type CedenteAlteracaoUpdateWithoutMinibancoInput = {
    campos?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutAlteracaoNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutAlteracoesNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutAlteracaoCedentesNestedInput>;
};

export type CedenteAlteracaoUpdateWithoutUsuarioInput = {
    campos?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutAlteracaoNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutAlteracoesNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutAlteracaoCedentesNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteAlteracaoUpsertWithWhereUniqueWithoutCedenteInput = {
    create: CedenteAlteracaoCreateWithoutCedenteInput;
    update: CedenteAlteracaoUpdateWithoutCedenteInput;
    where: CedenteAlteracaoWhereUniqueInput;
};

export type CedenteAlteracaoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: CedenteAlteracaoCreateWithoutMinibancoInput;
    update: CedenteAlteracaoUpdateWithoutMinibancoInput;
    where: CedenteAlteracaoWhereUniqueInput;
};

export type CedenteAlteracaoUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: CedenteAlteracaoCreateWithoutUsuarioInput;
    update: CedenteAlteracaoUpdateWithoutUsuarioInput;
    where: CedenteAlteracaoWhereUniqueInput;
};

export type CedenteAlteracaoUpsertWithoutCamposInput = {
    create: CedenteAlteracaoCreateWithoutCamposInput;
    update: CedenteAlteracaoUpdateWithoutCamposInput;
};

export type CedenteAlteracaoWhereInput = {
    AND?: InputMaybe<Array<CedenteAlteracaoWhereInput>>;
    NOT?: InputMaybe<Array<CedenteAlteracaoWhereInput>>;
    OR?: InputMaybe<Array<CedenteAlteracaoWhereInput>>;
    campos?: InputMaybe<CedenteCamposAlteracaoListRelationFilter>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntFilter>;
};

export type CedenteAlteracaoWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type CedenteAnexos = {
    __typename?: "CedenteAnexos";
    AssignorStatusByMinibank: Array<AssignorStatusByMinibank>;
    _count?: Maybe<CedenteAnexosCount>;
    arquivos: Array<Arquivo>;
    assignorDocumentType?: Maybe<AssignorDocumentType>;
    assignorDocumentTypeId?: Maybe<Scalars["Int"]>;
    cedente: Cedente;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    observacao?: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
};

export type CedenteAnexosAssignorStatusByMinibankArgs = {
    cursor?: InputMaybe<AssignorStatusByMinibankWhereUniqueInput>;
    distinct?: InputMaybe<Array<AssignorStatusByMinibankScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AssignorStatusByMinibankOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorStatusByMinibankWhereInput>;
};

export type CedenteAnexosArquivosArgs = {
    cursor?: InputMaybe<ArquivoWhereUniqueInput>;
    distinct?: InputMaybe<Array<ArquivoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ArquivoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoWhereInput>;
};

export type CedenteAnexosAvgAggregate = {
    __typename?: "CedenteAnexosAvgAggregate";
    assignorDocumentTypeId?: Maybe<Scalars["Float"]>;
    cedenteId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
};

export type CedenteAnexosAvgOrderByAggregateInput = {
    assignorDocumentTypeId?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type CedenteAnexosCount = {
    __typename?: "CedenteAnexosCount";
    AssignorStatusByMinibank: Scalars["Int"];
    arquivos: Scalars["Int"];
};

export type CedenteAnexosCountAggregate = {
    __typename?: "CedenteAnexosCountAggregate";
    _all: Scalars["Int"];
    assignorDocumentTypeId: Scalars["Int"];
    cedenteId: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    observacao: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type CedenteAnexosCountOrderByAggregateInput = {
    assignorDocumentTypeId?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteAnexosCreateInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorAttachmentInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutCedentesInput>;
    assignorDocumentType?: InputMaybe<AssignorDocumentTypeCreateNestedOneWithoutCedenteAnexoInput>;
    cedente: CedenteCreateNestedOneWithoutAnexosInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteAnexosCreateManyAssignorDocumentTypeInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteAnexosCreateManyAssignorDocumentTypeInputEnvelope = {
    data: Array<CedenteAnexosCreateManyAssignorDocumentTypeInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteAnexosCreateManyCedenteInput = {
    assignorDocumentTypeId?: InputMaybe<Scalars["Int"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteAnexosCreateManyCedenteInputEnvelope = {
    data: Array<CedenteAnexosCreateManyCedenteInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteAnexosCreateManyInput = {
    assignorDocumentTypeId?: InputMaybe<Scalars["Int"]>;
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteAnexosCreateNestedManyWithoutArquivosInput = {
    connect?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAnexosCreateOrConnectWithoutArquivosInput>>;
    create?: InputMaybe<Array<CedenteAnexosCreateWithoutArquivosInput>>;
};

export type CedenteAnexosCreateNestedManyWithoutAssignorDocumentTypeInput = {
    connect?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAnexosCreateOrConnectWithoutAssignorDocumentTypeInput>>;
    create?: InputMaybe<Array<CedenteAnexosCreateWithoutAssignorDocumentTypeInput>>;
    createMany?: InputMaybe<CedenteAnexosCreateManyAssignorDocumentTypeInputEnvelope>;
};

export type CedenteAnexosCreateNestedManyWithoutCedenteInput = {
    connect?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAnexosCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<CedenteAnexosCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<CedenteAnexosCreateManyCedenteInputEnvelope>;
};

export type CedenteAnexosCreateNestedOneWithoutAssignorStatusByMinibankInput = {
    connect?: InputMaybe<CedenteAnexosWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteAnexosCreateOrConnectWithoutAssignorStatusByMinibankInput>;
    create?: InputMaybe<CedenteAnexosCreateWithoutAssignorStatusByMinibankInput>;
};

export type CedenteAnexosCreateOrConnectWithoutArquivosInput = {
    create: CedenteAnexosCreateWithoutArquivosInput;
    where: CedenteAnexosWhereUniqueInput;
};

export type CedenteAnexosCreateOrConnectWithoutAssignorDocumentTypeInput = {
    create: CedenteAnexosCreateWithoutAssignorDocumentTypeInput;
    where: CedenteAnexosWhereUniqueInput;
};

export type CedenteAnexosCreateOrConnectWithoutAssignorStatusByMinibankInput = {
    create: CedenteAnexosCreateWithoutAssignorStatusByMinibankInput;
    where: CedenteAnexosWhereUniqueInput;
};

export type CedenteAnexosCreateOrConnectWithoutCedenteInput = {
    create: CedenteAnexosCreateWithoutCedenteInput;
    where: CedenteAnexosWhereUniqueInput;
};

export type CedenteAnexosCreateWithoutArquivosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorAttachmentInput>;
    assignorDocumentType?: InputMaybe<AssignorDocumentTypeCreateNestedOneWithoutCedenteAnexoInput>;
    cedente: CedenteCreateNestedOneWithoutAnexosInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteAnexosCreateWithoutAssignorDocumentTypeInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorAttachmentInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutCedentesInput>;
    cedente: CedenteCreateNestedOneWithoutAnexosInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteAnexosCreateWithoutAssignorStatusByMinibankInput = {
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutCedentesInput>;
    assignorDocumentType?: InputMaybe<AssignorDocumentTypeCreateNestedOneWithoutCedenteAnexoInput>;
    cedente: CedenteCreateNestedOneWithoutAnexosInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteAnexosCreateWithoutCedenteInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorAttachmentInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutCedentesInput>;
    assignorDocumentType?: InputMaybe<AssignorDocumentTypeCreateNestedOneWithoutCedenteAnexoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteAnexosGroupBy = {
    __typename?: "CedenteAnexosGroupBy";
    _avg?: Maybe<CedenteAnexosAvgAggregate>;
    _count?: Maybe<CedenteAnexosCountAggregate>;
    _max?: Maybe<CedenteAnexosMaxAggregate>;
    _min?: Maybe<CedenteAnexosMinAggregate>;
    _sum?: Maybe<CedenteAnexosSumAggregate>;
    assignorDocumentTypeId?: Maybe<Scalars["Int"]>;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    observacao?: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
};

export type CedenteAnexosListRelationFilter = {
    every?: InputMaybe<CedenteAnexosWhereInput>;
    none?: InputMaybe<CedenteAnexosWhereInput>;
    some?: InputMaybe<CedenteAnexosWhereInput>;
};

export type CedenteAnexosMaxAggregate = {
    __typename?: "CedenteAnexosMaxAggregate";
    assignorDocumentTypeId?: Maybe<Scalars["Int"]>;
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    observacao?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CedenteAnexosMaxOrderByAggregateInput = {
    assignorDocumentTypeId?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteAnexosMinAggregate = {
    __typename?: "CedenteAnexosMinAggregate";
    assignorDocumentTypeId?: Maybe<Scalars["Int"]>;
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    observacao?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CedenteAnexosMinOrderByAggregateInput = {
    assignorDocumentTypeId?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteAnexosOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type CedenteAnexosOrderByWithAggregationInput = {
    _avg?: InputMaybe<CedenteAnexosAvgOrderByAggregateInput>;
    _count?: InputMaybe<CedenteAnexosCountOrderByAggregateInput>;
    _max?: InputMaybe<CedenteAnexosMaxOrderByAggregateInput>;
    _min?: InputMaybe<CedenteAnexosMinOrderByAggregateInput>;
    _sum?: InputMaybe<CedenteAnexosSumOrderByAggregateInput>;
    assignorDocumentTypeId?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteAnexosOrderByWithRelationInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankOrderByRelationAggregateInput>;
    arquivos?: InputMaybe<ArquivoOrderByRelationAggregateInput>;
    assignorDocumentType?: InputMaybe<AssignorDocumentTypeOrderByWithRelationInput>;
    assignorDocumentTypeId?: InputMaybe<SortOrder>;
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteAnexosRelationFilter = {
    is?: InputMaybe<CedenteAnexosWhereInput>;
    isNot?: InputMaybe<CedenteAnexosWhereInput>;
};

export enum CedenteAnexosScalarFieldEnum {
    AssignorDocumentTypeId = "assignorDocumentTypeId",
    CedenteId = "cedenteId",
    CreatedAt = "createdAt",
    Id = "id",
    Observacao = "observacao",
    UpdatedAt = "updatedAt",
}

export type CedenteAnexosScalarWhereInput = {
    AND?: InputMaybe<Array<CedenteAnexosScalarWhereInput>>;
    NOT?: InputMaybe<Array<CedenteAnexosScalarWhereInput>>;
    OR?: InputMaybe<Array<CedenteAnexosScalarWhereInput>>;
    assignorDocumentTypeId?: InputMaybe<IntNullableFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    observacao?: InputMaybe<StringNullableFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CedenteAnexosScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CedenteAnexosScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CedenteAnexosScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CedenteAnexosScalarWhereWithAggregatesInput>>;
    assignorDocumentTypeId?: InputMaybe<IntNullableWithAggregatesFilter>;
    cedenteId?: InputMaybe<IntWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    observacao?: InputMaybe<StringNullableWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type CedenteAnexosSumAggregate = {
    __typename?: "CedenteAnexosSumAggregate";
    assignorDocumentTypeId?: Maybe<Scalars["Int"]>;
    cedenteId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
};

export type CedenteAnexosSumOrderByAggregateInput = {
    assignorDocumentTypeId?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type CedenteAnexosUpdateInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorAttachmentNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutCedentesNestedInput>;
    assignorDocumentType?: InputMaybe<AssignorDocumentTypeUpdateOneWithoutCedenteAnexoNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutAnexosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteAnexosUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteAnexosUpdateManyWithWhereWithoutArquivosInput = {
    data: CedenteAnexosUpdateManyMutationInput;
    where: CedenteAnexosScalarWhereInput;
};

export type CedenteAnexosUpdateManyWithWhereWithoutAssignorDocumentTypeInput = {
    data: CedenteAnexosUpdateManyMutationInput;
    where: CedenteAnexosScalarWhereInput;
};

export type CedenteAnexosUpdateManyWithWhereWithoutCedenteInput = {
    data: CedenteAnexosUpdateManyMutationInput;
    where: CedenteAnexosScalarWhereInput;
};

export type CedenteAnexosUpdateManyWithoutArquivosNestedInput = {
    connect?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAnexosCreateOrConnectWithoutArquivosInput>>;
    create?: InputMaybe<Array<CedenteAnexosCreateWithoutArquivosInput>>;
    delete?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteAnexosScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteAnexosUpdateWithWhereUniqueWithoutArquivosInput>>;
    updateMany?: InputMaybe<Array<CedenteAnexosUpdateManyWithWhereWithoutArquivosInput>>;
    upsert?: InputMaybe<Array<CedenteAnexosUpsertWithWhereUniqueWithoutArquivosInput>>;
};

export type CedenteAnexosUpdateManyWithoutAssignorDocumentTypeNestedInput = {
    connect?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAnexosCreateOrConnectWithoutAssignorDocumentTypeInput>>;
    create?: InputMaybe<Array<CedenteAnexosCreateWithoutAssignorDocumentTypeInput>>;
    createMany?: InputMaybe<CedenteAnexosCreateManyAssignorDocumentTypeInputEnvelope>;
    delete?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteAnexosScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteAnexosUpdateWithWhereUniqueWithoutAssignorDocumentTypeInput>>;
    updateMany?: InputMaybe<Array<CedenteAnexosUpdateManyWithWhereWithoutAssignorDocumentTypeInput>>;
    upsert?: InputMaybe<Array<CedenteAnexosUpsertWithWhereUniqueWithoutAssignorDocumentTypeInput>>;
};

export type CedenteAnexosUpdateManyWithoutCedenteNestedInput = {
    connect?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAnexosCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<CedenteAnexosCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<CedenteAnexosCreateManyCedenteInputEnvelope>;
    delete?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteAnexosScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteAnexosWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteAnexosUpdateWithWhereUniqueWithoutCedenteInput>>;
    updateMany?: InputMaybe<Array<CedenteAnexosUpdateManyWithWhereWithoutCedenteInput>>;
    upsert?: InputMaybe<Array<CedenteAnexosUpsertWithWhereUniqueWithoutCedenteInput>>;
};

export type CedenteAnexosUpdateOneWithoutAssignorStatusByMinibankNestedInput = {
    connect?: InputMaybe<CedenteAnexosWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteAnexosCreateOrConnectWithoutAssignorStatusByMinibankInput>;
    create?: InputMaybe<CedenteAnexosCreateWithoutAssignorStatusByMinibankInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<CedenteAnexosUpdateWithoutAssignorStatusByMinibankInput>;
    upsert?: InputMaybe<CedenteAnexosUpsertWithoutAssignorStatusByMinibankInput>;
};

export type CedenteAnexosUpdateWithWhereUniqueWithoutArquivosInput = {
    data: CedenteAnexosUpdateWithoutArquivosInput;
    where: CedenteAnexosWhereUniqueInput;
};

export type CedenteAnexosUpdateWithWhereUniqueWithoutAssignorDocumentTypeInput = {
    data: CedenteAnexosUpdateWithoutAssignorDocumentTypeInput;
    where: CedenteAnexosWhereUniqueInput;
};

export type CedenteAnexosUpdateWithWhereUniqueWithoutCedenteInput = {
    data: CedenteAnexosUpdateWithoutCedenteInput;
    where: CedenteAnexosWhereUniqueInput;
};

export type CedenteAnexosUpdateWithoutArquivosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorAttachmentNestedInput>;
    assignorDocumentType?: InputMaybe<AssignorDocumentTypeUpdateOneWithoutCedenteAnexoNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutAnexosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteAnexosUpdateWithoutAssignorDocumentTypeInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorAttachmentNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutCedentesNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutAnexosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteAnexosUpdateWithoutAssignorStatusByMinibankInput = {
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutCedentesNestedInput>;
    assignorDocumentType?: InputMaybe<AssignorDocumentTypeUpdateOneWithoutCedenteAnexoNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutAnexosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteAnexosUpdateWithoutCedenteInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorAttachmentNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutCedentesNestedInput>;
    assignorDocumentType?: InputMaybe<AssignorDocumentTypeUpdateOneWithoutCedenteAnexoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteAnexosUpsertWithWhereUniqueWithoutArquivosInput = {
    create: CedenteAnexosCreateWithoutArquivosInput;
    update: CedenteAnexosUpdateWithoutArquivosInput;
    where: CedenteAnexosWhereUniqueInput;
};

export type CedenteAnexosUpsertWithWhereUniqueWithoutAssignorDocumentTypeInput = {
    create: CedenteAnexosCreateWithoutAssignorDocumentTypeInput;
    update: CedenteAnexosUpdateWithoutAssignorDocumentTypeInput;
    where: CedenteAnexosWhereUniqueInput;
};

export type CedenteAnexosUpsertWithWhereUniqueWithoutCedenteInput = {
    create: CedenteAnexosCreateWithoutCedenteInput;
    update: CedenteAnexosUpdateWithoutCedenteInput;
    where: CedenteAnexosWhereUniqueInput;
};

export type CedenteAnexosUpsertWithoutAssignorStatusByMinibankInput = {
    create: CedenteAnexosCreateWithoutAssignorStatusByMinibankInput;
    update: CedenteAnexosUpdateWithoutAssignorStatusByMinibankInput;
};

export type CedenteAnexosWhereInput = {
    AND?: InputMaybe<Array<CedenteAnexosWhereInput>>;
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankListRelationFilter>;
    NOT?: InputMaybe<Array<CedenteAnexosWhereInput>>;
    OR?: InputMaybe<Array<CedenteAnexosWhereInput>>;
    arquivos?: InputMaybe<ArquivoListRelationFilter>;
    assignorDocumentType?: InputMaybe<AssignorDocumentTypeRelationFilter>;
    assignorDocumentTypeId?: InputMaybe<IntNullableFilter>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    observacao?: InputMaybe<StringNullableFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CedenteAnexosWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type CedenteAudit = {
    __typename?: "CedenteAudit";
    action: AuditAcoes;
    cedente: Cedente;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuario?: Maybe<Usuario>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type CedenteAuditAvgAggregate = {
    __typename?: "CedenteAuditAvgAggregate";
    cedenteId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type CedenteAuditAvgOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteAuditCountAggregate = {
    __typename?: "CedenteAuditCountAggregate";
    _all: Scalars["Int"];
    action: Scalars["Int"];
    cedenteId: Scalars["Int"];
    createdAt: Scalars["Int"];
    field: Scalars["Int"];
    id: Scalars["Int"];
    ipUsuario: Scalars["Int"];
    newValue: Scalars["Int"];
    oldValue: Scalars["Int"];
    service: Scalars["Int"];
    userAgent: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type CedenteAuditCountOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteAuditCreateInput = {
    action?: InputMaybe<AuditAcoes>;
    cedente: CedenteCreateNestedOneWithoutHistoricoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistCedenteInput>;
};

export type CedenteAuditCreateManyCedenteInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type CedenteAuditCreateManyCedenteInputEnvelope = {
    data: Array<CedenteAuditCreateManyCedenteInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteAuditCreateManyInput = {
    action?: InputMaybe<AuditAcoes>;
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type CedenteAuditCreateManyUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type CedenteAuditCreateManyUsuarioInputEnvelope = {
    data: Array<CedenteAuditCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteAuditCreateNestedManyWithoutCedenteInput = {
    connect?: InputMaybe<Array<CedenteAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAuditCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<CedenteAuditCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<CedenteAuditCreateManyCedenteInputEnvelope>;
};

export type CedenteAuditCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<CedenteAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<CedenteAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<CedenteAuditCreateManyUsuarioInputEnvelope>;
};

export type CedenteAuditCreateOrConnectWithoutCedenteInput = {
    create: CedenteAuditCreateWithoutCedenteInput;
    where: CedenteAuditWhereUniqueInput;
};

export type CedenteAuditCreateOrConnectWithoutUsuarioInput = {
    create: CedenteAuditCreateWithoutUsuarioInput;
    where: CedenteAuditWhereUniqueInput;
};

export type CedenteAuditCreateWithoutCedenteInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistCedenteInput>;
};

export type CedenteAuditCreateWithoutUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    cedente: CedenteCreateNestedOneWithoutHistoricoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type CedenteAuditGroupBy = {
    __typename?: "CedenteAuditGroupBy";
    _avg?: Maybe<CedenteAuditAvgAggregate>;
    _count?: Maybe<CedenteAuditCountAggregate>;
    _max?: Maybe<CedenteAuditMaxAggregate>;
    _min?: Maybe<CedenteAuditMinAggregate>;
    _sum?: Maybe<CedenteAuditSumAggregate>;
    action: AuditAcoes;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type CedenteAuditListRelationFilter = {
    every?: InputMaybe<CedenteAuditWhereInput>;
    none?: InputMaybe<CedenteAuditWhereInput>;
    some?: InputMaybe<CedenteAuditWhereInput>;
};

export type CedenteAuditMaxAggregate = {
    __typename?: "CedenteAuditMaxAggregate";
    action?: Maybe<AuditAcoes>;
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type CedenteAuditMaxOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteAuditMinAggregate = {
    __typename?: "CedenteAuditMinAggregate";
    action?: Maybe<AuditAcoes>;
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type CedenteAuditMinOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type CedenteAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<CedenteAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<CedenteAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<CedenteAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<CedenteAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<CedenteAuditSumOrderByAggregateInput>;
    action?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteAuditOrderByWithRelationInput = {
    action?: InputMaybe<SortOrder>;
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum CedenteAuditScalarFieldEnum {
    Action = "action",
    CedenteId = "cedenteId",
    CreatedAt = "createdAt",
    Field = "field",
    Id = "id",
    IpUsuario = "ipUsuario",
    NewValue = "newValue",
    OldValue = "oldValue",
    Service = "service",
    UserAgent = "userAgent",
    UsuarioId = "usuarioId",
}

export type CedenteAuditScalarWhereInput = {
    AND?: InputMaybe<Array<CedenteAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<CedenteAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<CedenteAuditScalarWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type CedenteAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CedenteAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CedenteAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CedenteAuditScalarWhereWithAggregatesInput>>;
    action?: InputMaybe<EnumAuditAcoesWithAggregatesFilter>;
    cedenteId?: InputMaybe<IntWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    field?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ipUsuario?: InputMaybe<StringWithAggregatesFilter>;
    newValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type CedenteAuditSumAggregate = {
    __typename?: "CedenteAuditSumAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type CedenteAuditSumOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteAuditUpdateInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutHistoricoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistCedenteNestedInput>;
};

export type CedenteAuditUpdateManyMutationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CedenteAuditUpdateManyWithWhereWithoutCedenteInput = {
    data: CedenteAuditUpdateManyMutationInput;
    where: CedenteAuditScalarWhereInput;
};

export type CedenteAuditUpdateManyWithWhereWithoutUsuarioInput = {
    data: CedenteAuditUpdateManyMutationInput;
    where: CedenteAuditScalarWhereInput;
};

export type CedenteAuditUpdateManyWithoutCedenteNestedInput = {
    connect?: InputMaybe<Array<CedenteAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAuditCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<CedenteAuditCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<CedenteAuditCreateManyCedenteInputEnvelope>;
    delete?: InputMaybe<Array<CedenteAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteAuditUpdateWithWhereUniqueWithoutCedenteInput>>;
    updateMany?: InputMaybe<Array<CedenteAuditUpdateManyWithWhereWithoutCedenteInput>>;
    upsert?: InputMaybe<Array<CedenteAuditUpsertWithWhereUniqueWithoutCedenteInput>>;
};

export type CedenteAuditUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<CedenteAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<CedenteAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<CedenteAuditCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<CedenteAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteAuditUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<CedenteAuditUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<CedenteAuditUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type CedenteAuditUpdateWithWhereUniqueWithoutCedenteInput = {
    data: CedenteAuditUpdateWithoutCedenteInput;
    where: CedenteAuditWhereUniqueInput;
};

export type CedenteAuditUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: CedenteAuditUpdateWithoutUsuarioInput;
    where: CedenteAuditWhereUniqueInput;
};

export type CedenteAuditUpdateWithoutCedenteInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistCedenteNestedInput>;
};

export type CedenteAuditUpdateWithoutUsuarioInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutHistoricoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CedenteAuditUpsertWithWhereUniqueWithoutCedenteInput = {
    create: CedenteAuditCreateWithoutCedenteInput;
    update: CedenteAuditUpdateWithoutCedenteInput;
    where: CedenteAuditWhereUniqueInput;
};

export type CedenteAuditUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: CedenteAuditCreateWithoutUsuarioInput;
    update: CedenteAuditUpdateWithoutUsuarioInput;
    where: CedenteAuditWhereUniqueInput;
};

export type CedenteAuditWhereInput = {
    AND?: InputMaybe<Array<CedenteAuditWhereInput>>;
    NOT?: InputMaybe<Array<CedenteAuditWhereInput>>;
    OR?: InputMaybe<Array<CedenteAuditWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type CedenteAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type CedenteAvgAggregate = {
    __typename?: "CedenteAvgAggregate";
    cadastroId?: Maybe<Scalars["Float"]>;
    codigoCnae?: Maybe<Scalars["Float"]>;
    contaBancId?: Maybe<Scalars["Float"]>;
    enderecoId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    representativeId?: Maybe<Scalars["Float"]>;
};

export type CedenteAvgOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    codigoCnae?: InputMaybe<SortOrder>;
    contaBancId?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    representativeId?: InputMaybe<SortOrder>;
};

export type CedenteCadastro = {
    __typename?: "CedenteCadastro";
    _count?: Maybe<CedenteCadastroCount>;
    campos: Array<CedenteCamposCadastro>;
    cedente?: Maybe<Cedente>;
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    minibanco?: Maybe<Minibanco>;
    minibancoId?: Maybe<Scalars["Int"]>;
    origem: OrigemCedente;
    status: CedenteStatus;
    updatedAt: Scalars["DateTime"];
    usuario?: Maybe<Usuario>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type CedenteCadastroCamposArgs = {
    cursor?: InputMaybe<CedenteCamposCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteCamposCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteCamposCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCamposCadastroWhereInput>;
};

export type CedenteCadastroAvgAggregate = {
    __typename?: "CedenteCadastroAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type CedenteCadastroAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteCadastroCount = {
    __typename?: "CedenteCadastroCount";
    campos: Scalars["Int"];
};

export type CedenteCadastroCountAggregate = {
    __typename?: "CedenteCadastroCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    minibancoId: Scalars["Int"];
    origem: Scalars["Int"];
    status: Scalars["Int"];
    updatedAt: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type CedenteCadastroCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteCadastroCreateInput = {
    campos?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutCadastroInput>;
    cedente?: InputMaybe<CedenteCreateNestedOneWithoutCadastroInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutCadastroCedentesInput>;
    origem?: InputMaybe<OrigemCedente>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutCadastroCedentesInput>;
};

export type CedenteCadastroCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId?: InputMaybe<Scalars["Int"]>;
    origem?: InputMaybe<OrigemCedente>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type CedenteCadastroCreateManyMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    origem?: InputMaybe<OrigemCedente>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type CedenteCadastroCreateManyMinibancoInputEnvelope = {
    data: Array<CedenteCadastroCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteCadastroCreateManyUsuarioInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId?: InputMaybe<Scalars["Int"]>;
    origem?: InputMaybe<OrigemCedente>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCadastroCreateManyUsuarioInputEnvelope = {
    data: Array<CedenteCadastroCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteCadastroCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<CedenteCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCadastroCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<CedenteCadastroCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<CedenteCadastroCreateManyMinibancoInputEnvelope>;
};

export type CedenteCadastroCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<CedenteCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCadastroCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<CedenteCadastroCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<CedenteCadastroCreateManyUsuarioInputEnvelope>;
};

export type CedenteCadastroCreateNestedOneWithoutCamposInput = {
    connect?: InputMaybe<CedenteCadastroWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCadastroCreateOrConnectWithoutCamposInput>;
    create?: InputMaybe<CedenteCadastroCreateWithoutCamposInput>;
};

export type CedenteCadastroCreateNestedOneWithoutCedenteInput = {
    connect?: InputMaybe<CedenteCadastroWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCadastroCreateOrConnectWithoutCedenteInput>;
    create?: InputMaybe<CedenteCadastroCreateWithoutCedenteInput>;
};

export type CedenteCadastroCreateOrConnectWithoutCamposInput = {
    create: CedenteCadastroCreateWithoutCamposInput;
    where: CedenteCadastroWhereUniqueInput;
};

export type CedenteCadastroCreateOrConnectWithoutCedenteInput = {
    create: CedenteCadastroCreateWithoutCedenteInput;
    where: CedenteCadastroWhereUniqueInput;
};

export type CedenteCadastroCreateOrConnectWithoutMinibancoInput = {
    create: CedenteCadastroCreateWithoutMinibancoInput;
    where: CedenteCadastroWhereUniqueInput;
};

export type CedenteCadastroCreateOrConnectWithoutUsuarioInput = {
    create: CedenteCadastroCreateWithoutUsuarioInput;
    where: CedenteCadastroWhereUniqueInput;
};

export type CedenteCadastroCreateWithoutCamposInput = {
    cedente?: InputMaybe<CedenteCreateNestedOneWithoutCadastroInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutCadastroCedentesInput>;
    origem?: InputMaybe<OrigemCedente>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutCadastroCedentesInput>;
};

export type CedenteCadastroCreateWithoutCedenteInput = {
    campos?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutCadastroInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutCadastroCedentesInput>;
    origem?: InputMaybe<OrigemCedente>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutCadastroCedentesInput>;
};

export type CedenteCadastroCreateWithoutMinibancoInput = {
    campos?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutCadastroInput>;
    cedente?: InputMaybe<CedenteCreateNestedOneWithoutCadastroInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    origem?: InputMaybe<OrigemCedente>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutCadastroCedentesInput>;
};

export type CedenteCadastroCreateWithoutUsuarioInput = {
    campos?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutCadastroInput>;
    cedente?: InputMaybe<CedenteCreateNestedOneWithoutCadastroInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutCadastroCedentesInput>;
    origem?: InputMaybe<OrigemCedente>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCadastroGroupBy = {
    __typename?: "CedenteCadastroGroupBy";
    _avg?: Maybe<CedenteCadastroAvgAggregate>;
    _count?: Maybe<CedenteCadastroCountAggregate>;
    _max?: Maybe<CedenteCadastroMaxAggregate>;
    _min?: Maybe<CedenteCadastroMinAggregate>;
    _sum?: Maybe<CedenteCadastroSumAggregate>;
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    minibancoId?: Maybe<Scalars["Int"]>;
    origem: OrigemCedente;
    status: CedenteStatus;
    updatedAt: Scalars["DateTime"];
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type CedenteCadastroListRelationFilter = {
    every?: InputMaybe<CedenteCadastroWhereInput>;
    none?: InputMaybe<CedenteCadastroWhereInput>;
    some?: InputMaybe<CedenteCadastroWhereInput>;
};

export type CedenteCadastroMaxAggregate = {
    __typename?: "CedenteCadastroMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    origem?: Maybe<OrigemCedente>;
    status?: Maybe<CedenteStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type CedenteCadastroMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteCadastroMinAggregate = {
    __typename?: "CedenteCadastroMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    origem?: Maybe<OrigemCedente>;
    status?: Maybe<CedenteStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type CedenteCadastroMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteCadastroOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type CedenteCadastroOrderByWithAggregationInput = {
    _avg?: InputMaybe<CedenteCadastroAvgOrderByAggregateInput>;
    _count?: InputMaybe<CedenteCadastroCountOrderByAggregateInput>;
    _max?: InputMaybe<CedenteCadastroMaxOrderByAggregateInput>;
    _min?: InputMaybe<CedenteCadastroMinOrderByAggregateInput>;
    _sum?: InputMaybe<CedenteCadastroSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteCadastroOrderByWithRelationInput = {
    campos?: InputMaybe<CedenteCamposCadastroOrderByRelationAggregateInput>;
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteCadastroRelationFilter = {
    is?: InputMaybe<CedenteCadastroWhereInput>;
    isNot?: InputMaybe<CedenteCadastroWhereInput>;
};

export enum CedenteCadastroScalarFieldEnum {
    CreatedAt = "createdAt",
    Id = "id",
    MinibancoId = "minibancoId",
    Origem = "origem",
    Status = "status",
    UpdatedAt = "updatedAt",
    UsuarioId = "usuarioId",
}

export type CedenteCadastroScalarWhereInput = {
    AND?: InputMaybe<Array<CedenteCadastroScalarWhereInput>>;
    NOT?: InputMaybe<Array<CedenteCadastroScalarWhereInput>>;
    OR?: InputMaybe<Array<CedenteCadastroScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    minibancoId?: InputMaybe<IntNullableFilter>;
    origem?: InputMaybe<EnumOrigemCedenteFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type CedenteCadastroScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CedenteCadastroScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CedenteCadastroScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CedenteCadastroScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntNullableWithAggregatesFilter>;
    origem?: InputMaybe<EnumOrigemCedenteWithAggregatesFilter>;
    status?: InputMaybe<EnumCedenteStatusWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type CedenteCadastroSumAggregate = {
    __typename?: "CedenteCadastroSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type CedenteCadastroSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type CedenteCadastroUpdateInput = {
    campos?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutCadastroNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneWithoutCadastroNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutCadastroCedentesNestedInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutCadastroCedentesNestedInput>;
};

export type CedenteCadastroUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteCadastroUpdateManyWithWhereWithoutMinibancoInput = {
    data: CedenteCadastroUpdateManyMutationInput;
    where: CedenteCadastroScalarWhereInput;
};

export type CedenteCadastroUpdateManyWithWhereWithoutUsuarioInput = {
    data: CedenteCadastroUpdateManyMutationInput;
    where: CedenteCadastroScalarWhereInput;
};

export type CedenteCadastroUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<CedenteCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCadastroCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<CedenteCadastroCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<CedenteCadastroCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<CedenteCadastroWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteCadastroScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteCadastroWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteCadastroWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteCadastroUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<CedenteCadastroUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<CedenteCadastroUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type CedenteCadastroUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<CedenteCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCadastroCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<CedenteCadastroCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<CedenteCadastroCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<CedenteCadastroWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteCadastroScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteCadastroWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteCadastroWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteCadastroUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<CedenteCadastroUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<CedenteCadastroUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type CedenteCadastroUpdateOneRequiredWithoutCamposNestedInput = {
    connect?: InputMaybe<CedenteCadastroWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCadastroCreateOrConnectWithoutCamposInput>;
    create?: InputMaybe<CedenteCadastroCreateWithoutCamposInput>;
    update?: InputMaybe<CedenteCadastroUpdateWithoutCamposInput>;
    upsert?: InputMaybe<CedenteCadastroUpsertWithoutCamposInput>;
};

export type CedenteCadastroUpdateOneWithoutCedenteNestedInput = {
    connect?: InputMaybe<CedenteCadastroWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCadastroCreateOrConnectWithoutCedenteInput>;
    create?: InputMaybe<CedenteCadastroCreateWithoutCedenteInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<CedenteCadastroUpdateWithoutCedenteInput>;
    upsert?: InputMaybe<CedenteCadastroUpsertWithoutCedenteInput>;
};

export type CedenteCadastroUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: CedenteCadastroUpdateWithoutMinibancoInput;
    where: CedenteCadastroWhereUniqueInput;
};

export type CedenteCadastroUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: CedenteCadastroUpdateWithoutUsuarioInput;
    where: CedenteCadastroWhereUniqueInput;
};

export type CedenteCadastroUpdateWithoutCamposInput = {
    cedente?: InputMaybe<CedenteUpdateOneWithoutCadastroNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutCadastroCedentesNestedInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutCadastroCedentesNestedInput>;
};

export type CedenteCadastroUpdateWithoutCedenteInput = {
    campos?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutCadastroNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutCadastroCedentesNestedInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutCadastroCedentesNestedInput>;
};

export type CedenteCadastroUpdateWithoutMinibancoInput = {
    campos?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutCadastroNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneWithoutCadastroNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutCadastroCedentesNestedInput>;
};

export type CedenteCadastroUpdateWithoutUsuarioInput = {
    campos?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutCadastroNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneWithoutCadastroNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutCadastroCedentesNestedInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteCadastroUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: CedenteCadastroCreateWithoutMinibancoInput;
    update: CedenteCadastroUpdateWithoutMinibancoInput;
    where: CedenteCadastroWhereUniqueInput;
};

export type CedenteCadastroUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: CedenteCadastroCreateWithoutUsuarioInput;
    update: CedenteCadastroUpdateWithoutUsuarioInput;
    where: CedenteCadastroWhereUniqueInput;
};

export type CedenteCadastroUpsertWithoutCamposInput = {
    create: CedenteCadastroCreateWithoutCamposInput;
    update: CedenteCadastroUpdateWithoutCamposInput;
};

export type CedenteCadastroUpsertWithoutCedenteInput = {
    create: CedenteCadastroCreateWithoutCedenteInput;
    update: CedenteCadastroUpdateWithoutCedenteInput;
};

export type CedenteCadastroWhereInput = {
    AND?: InputMaybe<Array<CedenteCadastroWhereInput>>;
    NOT?: InputMaybe<Array<CedenteCadastroWhereInput>>;
    OR?: InputMaybe<Array<CedenteCadastroWhereInput>>;
    campos?: InputMaybe<CedenteCamposCadastroListRelationFilter>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntNullableFilter>;
    origem?: InputMaybe<EnumOrigemCedenteFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type CedenteCadastroWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type CedenteCamposAlteracao = {
    __typename?: "CedenteCamposAlteracao";
    alteracao: CedenteAlteracao;
    alteracaoId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao: Scalars["DateTime"];
    id: Scalars["Int"];
    responsavel: Usuario;
    responsavelId: Scalars["Int"];
    status: CedenteStatus;
    valor: Scalars["String"];
};

export type CedenteCamposAlteracaoAvgAggregate = {
    __typename?: "CedenteCamposAlteracaoAvgAggregate";
    alteracaoId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    responsavelId?: Maybe<Scalars["Float"]>;
};

export type CedenteCamposAlteracaoAvgOrderByAggregateInput = {
    alteracaoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
};

export type CedenteCamposAlteracaoCampoAlteracaoIdCompoundUniqueInput = {
    alteracaoId: Scalars["Int"];
    campo: Scalars["String"];
};

export type CedenteCamposAlteracaoCountAggregate = {
    __typename?: "CedenteCamposAlteracaoCountAggregate";
    _all: Scalars["Int"];
    alteracaoId: Scalars["Int"];
    campo: Scalars["Int"];
    dtVerificacao: Scalars["Int"];
    id: Scalars["Int"];
    responsavelId: Scalars["Int"];
    status: Scalars["Int"];
    valor: Scalars["Int"];
};

export type CedenteCamposAlteracaoCountOrderByAggregateInput = {
    alteracaoId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type CedenteCamposAlteracaoCreateInput = {
    alteracao: CedenteAlteracaoCreateNestedOneWithoutCamposInput;
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    responsavel: UsuarioCreateNestedOneWithoutCamposAlteracaoCedentesInput;
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type CedenteCamposAlteracaoCreateManyAlteracaoInput = {
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    responsavelId: Scalars["Int"];
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type CedenteCamposAlteracaoCreateManyAlteracaoInputEnvelope = {
    data: Array<CedenteCamposAlteracaoCreateManyAlteracaoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteCamposAlteracaoCreateManyInput = {
    alteracaoId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    responsavelId: Scalars["Int"];
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type CedenteCamposAlteracaoCreateManyResponsavelInput = {
    alteracaoId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type CedenteCamposAlteracaoCreateManyResponsavelInputEnvelope = {
    data: Array<CedenteCamposAlteracaoCreateManyResponsavelInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteCamposAlteracaoCreateNestedManyWithoutAlteracaoInput = {
    connect?: InputMaybe<Array<CedenteCamposAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCamposAlteracaoCreateOrConnectWithoutAlteracaoInput>>;
    create?: InputMaybe<Array<CedenteCamposAlteracaoCreateWithoutAlteracaoInput>>;
    createMany?: InputMaybe<CedenteCamposAlteracaoCreateManyAlteracaoInputEnvelope>;
};

export type CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput = {
    connect?: InputMaybe<Array<CedenteCamposAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCamposAlteracaoCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<CedenteCamposAlteracaoCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<CedenteCamposAlteracaoCreateManyResponsavelInputEnvelope>;
};

export type CedenteCamposAlteracaoCreateOrConnectWithoutAlteracaoInput = {
    create: CedenteCamposAlteracaoCreateWithoutAlteracaoInput;
    where: CedenteCamposAlteracaoWhereUniqueInput;
};

export type CedenteCamposAlteracaoCreateOrConnectWithoutResponsavelInput = {
    create: CedenteCamposAlteracaoCreateWithoutResponsavelInput;
    where: CedenteCamposAlteracaoWhereUniqueInput;
};

export type CedenteCamposAlteracaoCreateWithoutAlteracaoInput = {
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    responsavel: UsuarioCreateNestedOneWithoutCamposAlteracaoCedentesInput;
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type CedenteCamposAlteracaoCreateWithoutResponsavelInput = {
    alteracao: CedenteAlteracaoCreateNestedOneWithoutCamposInput;
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type CedenteCamposAlteracaoGroupBy = {
    __typename?: "CedenteCamposAlteracaoGroupBy";
    _avg?: Maybe<CedenteCamposAlteracaoAvgAggregate>;
    _count?: Maybe<CedenteCamposAlteracaoCountAggregate>;
    _max?: Maybe<CedenteCamposAlteracaoMaxAggregate>;
    _min?: Maybe<CedenteCamposAlteracaoMinAggregate>;
    _sum?: Maybe<CedenteCamposAlteracaoSumAggregate>;
    alteracaoId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao: Scalars["DateTime"];
    id: Scalars["Int"];
    responsavelId: Scalars["Int"];
    status: CedenteStatus;
    valor: Scalars["String"];
};

export type CedenteCamposAlteracaoListRelationFilter = {
    every?: InputMaybe<CedenteCamposAlteracaoWhereInput>;
    none?: InputMaybe<CedenteCamposAlteracaoWhereInput>;
    some?: InputMaybe<CedenteCamposAlteracaoWhereInput>;
};

export type CedenteCamposAlteracaoMaxAggregate = {
    __typename?: "CedenteCamposAlteracaoMaxAggregate";
    alteracaoId?: Maybe<Scalars["Int"]>;
    campo?: Maybe<Scalars["String"]>;
    dtVerificacao?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    responsavelId?: Maybe<Scalars["Int"]>;
    status?: Maybe<CedenteStatus>;
    valor?: Maybe<Scalars["String"]>;
};

export type CedenteCamposAlteracaoMaxOrderByAggregateInput = {
    alteracaoId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type CedenteCamposAlteracaoMinAggregate = {
    __typename?: "CedenteCamposAlteracaoMinAggregate";
    alteracaoId?: Maybe<Scalars["Int"]>;
    campo?: Maybe<Scalars["String"]>;
    dtVerificacao?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    responsavelId?: Maybe<Scalars["Int"]>;
    status?: Maybe<CedenteStatus>;
    valor?: Maybe<Scalars["String"]>;
};

export type CedenteCamposAlteracaoMinOrderByAggregateInput = {
    alteracaoId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type CedenteCamposAlteracaoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type CedenteCamposAlteracaoOrderByWithAggregationInput = {
    _avg?: InputMaybe<CedenteCamposAlteracaoAvgOrderByAggregateInput>;
    _count?: InputMaybe<CedenteCamposAlteracaoCountOrderByAggregateInput>;
    _max?: InputMaybe<CedenteCamposAlteracaoMaxOrderByAggregateInput>;
    _min?: InputMaybe<CedenteCamposAlteracaoMinOrderByAggregateInput>;
    _sum?: InputMaybe<CedenteCamposAlteracaoSumOrderByAggregateInput>;
    alteracaoId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type CedenteCamposAlteracaoOrderByWithRelationInput = {
    alteracao?: InputMaybe<CedenteAlteracaoOrderByWithRelationInput>;
    alteracaoId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavel?: InputMaybe<UsuarioOrderByWithRelationInput>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export enum CedenteCamposAlteracaoScalarFieldEnum {
    AlteracaoId = "alteracaoId",
    Campo = "campo",
    DtVerificacao = "dtVerificacao",
    Id = "id",
    ResponsavelId = "responsavelId",
    Status = "status",
    Valor = "valor",
}

export type CedenteCamposAlteracaoScalarWhereInput = {
    AND?: InputMaybe<Array<CedenteCamposAlteracaoScalarWhereInput>>;
    NOT?: InputMaybe<Array<CedenteCamposAlteracaoScalarWhereInput>>;
    OR?: InputMaybe<Array<CedenteCamposAlteracaoScalarWhereInput>>;
    alteracaoId?: InputMaybe<IntFilter>;
    campo?: InputMaybe<StringFilter>;
    dtVerificacao?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    responsavelId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    valor?: InputMaybe<StringFilter>;
};

export type CedenteCamposAlteracaoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CedenteCamposAlteracaoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CedenteCamposAlteracaoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CedenteCamposAlteracaoScalarWhereWithAggregatesInput>>;
    alteracaoId?: InputMaybe<IntWithAggregatesFilter>;
    campo?: InputMaybe<StringWithAggregatesFilter>;
    dtVerificacao?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    responsavelId?: InputMaybe<IntWithAggregatesFilter>;
    status?: InputMaybe<EnumCedenteStatusWithAggregatesFilter>;
    valor?: InputMaybe<StringWithAggregatesFilter>;
};

export type CedenteCamposAlteracaoSumAggregate = {
    __typename?: "CedenteCamposAlteracaoSumAggregate";
    alteracaoId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    responsavelId?: Maybe<Scalars["Int"]>;
};

export type CedenteCamposAlteracaoSumOrderByAggregateInput = {
    alteracaoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
};

export type CedenteCamposAlteracaoUpdateInput = {
    alteracao?: InputMaybe<CedenteAlteracaoUpdateOneRequiredWithoutCamposNestedInput>;
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutCamposAlteracaoCedentesNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CedenteCamposAlteracaoUpdateManyMutationInput = {
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CedenteCamposAlteracaoUpdateManyWithWhereWithoutAlteracaoInput = {
    data: CedenteCamposAlteracaoUpdateManyMutationInput;
    where: CedenteCamposAlteracaoScalarWhereInput;
};

export type CedenteCamposAlteracaoUpdateManyWithWhereWithoutResponsavelInput = {
    data: CedenteCamposAlteracaoUpdateManyMutationInput;
    where: CedenteCamposAlteracaoScalarWhereInput;
};

export type CedenteCamposAlteracaoUpdateManyWithoutAlteracaoNestedInput = {
    connect?: InputMaybe<Array<CedenteCamposAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCamposAlteracaoCreateOrConnectWithoutAlteracaoInput>>;
    create?: InputMaybe<Array<CedenteCamposAlteracaoCreateWithoutAlteracaoInput>>;
    createMany?: InputMaybe<CedenteCamposAlteracaoCreateManyAlteracaoInputEnvelope>;
    delete?: InputMaybe<Array<CedenteCamposAlteracaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteCamposAlteracaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteCamposAlteracaoWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteCamposAlteracaoWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteCamposAlteracaoUpdateWithWhereUniqueWithoutAlteracaoInput>>;
    updateMany?: InputMaybe<Array<CedenteCamposAlteracaoUpdateManyWithWhereWithoutAlteracaoInput>>;
    upsert?: InputMaybe<Array<CedenteCamposAlteracaoUpsertWithWhereUniqueWithoutAlteracaoInput>>;
};

export type CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput = {
    connect?: InputMaybe<Array<CedenteCamposAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCamposAlteracaoCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<CedenteCamposAlteracaoCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<CedenteCamposAlteracaoCreateManyResponsavelInputEnvelope>;
    delete?: InputMaybe<Array<CedenteCamposAlteracaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteCamposAlteracaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteCamposAlteracaoWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteCamposAlteracaoWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteCamposAlteracaoUpdateWithWhereUniqueWithoutResponsavelInput>>;
    updateMany?: InputMaybe<Array<CedenteCamposAlteracaoUpdateManyWithWhereWithoutResponsavelInput>>;
    upsert?: InputMaybe<Array<CedenteCamposAlteracaoUpsertWithWhereUniqueWithoutResponsavelInput>>;
};

export type CedenteCamposAlteracaoUpdateWithWhereUniqueWithoutAlteracaoInput = {
    data: CedenteCamposAlteracaoUpdateWithoutAlteracaoInput;
    where: CedenteCamposAlteracaoWhereUniqueInput;
};

export type CedenteCamposAlteracaoUpdateWithWhereUniqueWithoutResponsavelInput = {
    data: CedenteCamposAlteracaoUpdateWithoutResponsavelInput;
    where: CedenteCamposAlteracaoWhereUniqueInput;
};

export type CedenteCamposAlteracaoUpdateWithoutAlteracaoInput = {
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutCamposAlteracaoCedentesNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CedenteCamposAlteracaoUpdateWithoutResponsavelInput = {
    alteracao?: InputMaybe<CedenteAlteracaoUpdateOneRequiredWithoutCamposNestedInput>;
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CedenteCamposAlteracaoUpsertWithWhereUniqueWithoutAlteracaoInput = {
    create: CedenteCamposAlteracaoCreateWithoutAlteracaoInput;
    update: CedenteCamposAlteracaoUpdateWithoutAlteracaoInput;
    where: CedenteCamposAlteracaoWhereUniqueInput;
};

export type CedenteCamposAlteracaoUpsertWithWhereUniqueWithoutResponsavelInput = {
    create: CedenteCamposAlteracaoCreateWithoutResponsavelInput;
    update: CedenteCamposAlteracaoUpdateWithoutResponsavelInput;
    where: CedenteCamposAlteracaoWhereUniqueInput;
};

export type CedenteCamposAlteracaoWhereInput = {
    AND?: InputMaybe<Array<CedenteCamposAlteracaoWhereInput>>;
    NOT?: InputMaybe<Array<CedenteCamposAlteracaoWhereInput>>;
    OR?: InputMaybe<Array<CedenteCamposAlteracaoWhereInput>>;
    alteracao?: InputMaybe<CedenteAlteracaoRelationFilter>;
    alteracaoId?: InputMaybe<IntFilter>;
    campo?: InputMaybe<StringFilter>;
    dtVerificacao?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    responsavel?: InputMaybe<UsuarioRelationFilter>;
    responsavelId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    valor?: InputMaybe<StringFilter>;
};

export type CedenteCamposAlteracaoWhereUniqueInput = {
    campo_alteracaoId?: InputMaybe<CedenteCamposAlteracaoCampoAlteracaoIdCompoundUniqueInput>;
    id?: InputMaybe<Scalars["Int"]>;
};

export type CedenteCamposCadastro = {
    __typename?: "CedenteCamposCadastro";
    cadastro: CedenteCadastro;
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao: Scalars["DateTime"];
    id: Scalars["Int"];
    responsavel: Usuario;
    responsavelId: Scalars["Int"];
    status: CedenteStatus;
    valor: Scalars["String"];
};

export type CedenteCamposCadastroAvgAggregate = {
    __typename?: "CedenteCamposCadastroAvgAggregate";
    cadastroId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    responsavelId?: Maybe<Scalars["Float"]>;
};

export type CedenteCamposCadastroAvgOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
};

export type CedenteCamposCadastroCampoCadastroIdCompoundUniqueInput = {
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
};

export type CedenteCamposCadastroCountAggregate = {
    __typename?: "CedenteCamposCadastroCountAggregate";
    _all: Scalars["Int"];
    cadastroId: Scalars["Int"];
    campo: Scalars["Int"];
    dtVerificacao: Scalars["Int"];
    id: Scalars["Int"];
    responsavelId: Scalars["Int"];
    status: Scalars["Int"];
    valor: Scalars["Int"];
};

export type CedenteCamposCadastroCountOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type CedenteCamposCadastroCreateInput = {
    cadastro: CedenteCadastroCreateNestedOneWithoutCamposInput;
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    responsavel: UsuarioCreateNestedOneWithoutCamposCadastroCedentesInput;
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type CedenteCamposCadastroCreateManyCadastroInput = {
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    responsavelId: Scalars["Int"];
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type CedenteCamposCadastroCreateManyCadastroInputEnvelope = {
    data: Array<CedenteCamposCadastroCreateManyCadastroInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteCamposCadastroCreateManyInput = {
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    responsavelId: Scalars["Int"];
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type CedenteCamposCadastroCreateManyResponsavelInput = {
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type CedenteCamposCadastroCreateManyResponsavelInputEnvelope = {
    data: Array<CedenteCamposCadastroCreateManyResponsavelInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteCamposCadastroCreateNestedManyWithoutCadastroInput = {
    connect?: InputMaybe<Array<CedenteCamposCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCamposCadastroCreateOrConnectWithoutCadastroInput>>;
    create?: InputMaybe<Array<CedenteCamposCadastroCreateWithoutCadastroInput>>;
    createMany?: InputMaybe<CedenteCamposCadastroCreateManyCadastroInputEnvelope>;
};

export type CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput = {
    connect?: InputMaybe<Array<CedenteCamposCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCamposCadastroCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<CedenteCamposCadastroCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<CedenteCamposCadastroCreateManyResponsavelInputEnvelope>;
};

export type CedenteCamposCadastroCreateOrConnectWithoutCadastroInput = {
    create: CedenteCamposCadastroCreateWithoutCadastroInput;
    where: CedenteCamposCadastroWhereUniqueInput;
};

export type CedenteCamposCadastroCreateOrConnectWithoutResponsavelInput = {
    create: CedenteCamposCadastroCreateWithoutResponsavelInput;
    where: CedenteCamposCadastroWhereUniqueInput;
};

export type CedenteCamposCadastroCreateWithoutCadastroInput = {
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    responsavel: UsuarioCreateNestedOneWithoutCamposCadastroCedentesInput;
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type CedenteCamposCadastroCreateWithoutResponsavelInput = {
    cadastro: CedenteCadastroCreateNestedOneWithoutCamposInput;
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type CedenteCamposCadastroGroupBy = {
    __typename?: "CedenteCamposCadastroGroupBy";
    _avg?: Maybe<CedenteCamposCadastroAvgAggregate>;
    _count?: Maybe<CedenteCamposCadastroCountAggregate>;
    _max?: Maybe<CedenteCamposCadastroMaxAggregate>;
    _min?: Maybe<CedenteCamposCadastroMinAggregate>;
    _sum?: Maybe<CedenteCamposCadastroSumAggregate>;
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao: Scalars["DateTime"];
    id: Scalars["Int"];
    responsavelId: Scalars["Int"];
    status: CedenteStatus;
    valor: Scalars["String"];
};

export type CedenteCamposCadastroListRelationFilter = {
    every?: InputMaybe<CedenteCamposCadastroWhereInput>;
    none?: InputMaybe<CedenteCamposCadastroWhereInput>;
    some?: InputMaybe<CedenteCamposCadastroWhereInput>;
};

export type CedenteCamposCadastroMaxAggregate = {
    __typename?: "CedenteCamposCadastroMaxAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    campo?: Maybe<Scalars["String"]>;
    dtVerificacao?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    responsavelId?: Maybe<Scalars["Int"]>;
    status?: Maybe<CedenteStatus>;
    valor?: Maybe<Scalars["String"]>;
};

export type CedenteCamposCadastroMaxOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type CedenteCamposCadastroMinAggregate = {
    __typename?: "CedenteCamposCadastroMinAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    campo?: Maybe<Scalars["String"]>;
    dtVerificacao?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    responsavelId?: Maybe<Scalars["Int"]>;
    status?: Maybe<CedenteStatus>;
    valor?: Maybe<Scalars["String"]>;
};

export type CedenteCamposCadastroMinOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type CedenteCamposCadastroOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type CedenteCamposCadastroOrderByWithAggregationInput = {
    _avg?: InputMaybe<CedenteCamposCadastroAvgOrderByAggregateInput>;
    _count?: InputMaybe<CedenteCamposCadastroCountOrderByAggregateInput>;
    _max?: InputMaybe<CedenteCamposCadastroMaxOrderByAggregateInput>;
    _min?: InputMaybe<CedenteCamposCadastroMinOrderByAggregateInput>;
    _sum?: InputMaybe<CedenteCamposCadastroSumOrderByAggregateInput>;
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type CedenteCamposCadastroOrderByWithRelationInput = {
    cadastro?: InputMaybe<CedenteCadastroOrderByWithRelationInput>;
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavel?: InputMaybe<UsuarioOrderByWithRelationInput>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export enum CedenteCamposCadastroScalarFieldEnum {
    CadastroId = "cadastroId",
    Campo = "campo",
    DtVerificacao = "dtVerificacao",
    Id = "id",
    ResponsavelId = "responsavelId",
    Status = "status",
    Valor = "valor",
}

export type CedenteCamposCadastroScalarWhereInput = {
    AND?: InputMaybe<Array<CedenteCamposCadastroScalarWhereInput>>;
    NOT?: InputMaybe<Array<CedenteCamposCadastroScalarWhereInput>>;
    OR?: InputMaybe<Array<CedenteCamposCadastroScalarWhereInput>>;
    cadastroId?: InputMaybe<IntFilter>;
    campo?: InputMaybe<StringFilter>;
    dtVerificacao?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    responsavelId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    valor?: InputMaybe<StringFilter>;
};

export type CedenteCamposCadastroScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CedenteCamposCadastroScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CedenteCamposCadastroScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CedenteCamposCadastroScalarWhereWithAggregatesInput>>;
    cadastroId?: InputMaybe<IntWithAggregatesFilter>;
    campo?: InputMaybe<StringWithAggregatesFilter>;
    dtVerificacao?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    responsavelId?: InputMaybe<IntWithAggregatesFilter>;
    status?: InputMaybe<EnumCedenteStatusWithAggregatesFilter>;
    valor?: InputMaybe<StringWithAggregatesFilter>;
};

export type CedenteCamposCadastroSumAggregate = {
    __typename?: "CedenteCamposCadastroSumAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    responsavelId?: Maybe<Scalars["Int"]>;
};

export type CedenteCamposCadastroSumOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
};

export type CedenteCamposCadastroUpdateInput = {
    cadastro?: InputMaybe<CedenteCadastroUpdateOneRequiredWithoutCamposNestedInput>;
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutCamposCadastroCedentesNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CedenteCamposCadastroUpdateManyMutationInput = {
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CedenteCamposCadastroUpdateManyWithWhereWithoutCadastroInput = {
    data: CedenteCamposCadastroUpdateManyMutationInput;
    where: CedenteCamposCadastroScalarWhereInput;
};

export type CedenteCamposCadastroUpdateManyWithWhereWithoutResponsavelInput = {
    data: CedenteCamposCadastroUpdateManyMutationInput;
    where: CedenteCamposCadastroScalarWhereInput;
};

export type CedenteCamposCadastroUpdateManyWithoutCadastroNestedInput = {
    connect?: InputMaybe<Array<CedenteCamposCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCamposCadastroCreateOrConnectWithoutCadastroInput>>;
    create?: InputMaybe<Array<CedenteCamposCadastroCreateWithoutCadastroInput>>;
    createMany?: InputMaybe<CedenteCamposCadastroCreateManyCadastroInputEnvelope>;
    delete?: InputMaybe<Array<CedenteCamposCadastroWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteCamposCadastroScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteCamposCadastroWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteCamposCadastroWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteCamposCadastroUpdateWithWhereUniqueWithoutCadastroInput>>;
    updateMany?: InputMaybe<Array<CedenteCamposCadastroUpdateManyWithWhereWithoutCadastroInput>>;
    upsert?: InputMaybe<Array<CedenteCamposCadastroUpsertWithWhereUniqueWithoutCadastroInput>>;
};

export type CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput = {
    connect?: InputMaybe<Array<CedenteCamposCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCamposCadastroCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<CedenteCamposCadastroCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<CedenteCamposCadastroCreateManyResponsavelInputEnvelope>;
    delete?: InputMaybe<Array<CedenteCamposCadastroWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteCamposCadastroScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteCamposCadastroWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteCamposCadastroWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteCamposCadastroUpdateWithWhereUniqueWithoutResponsavelInput>>;
    updateMany?: InputMaybe<Array<CedenteCamposCadastroUpdateManyWithWhereWithoutResponsavelInput>>;
    upsert?: InputMaybe<Array<CedenteCamposCadastroUpsertWithWhereUniqueWithoutResponsavelInput>>;
};

export type CedenteCamposCadastroUpdateWithWhereUniqueWithoutCadastroInput = {
    data: CedenteCamposCadastroUpdateWithoutCadastroInput;
    where: CedenteCamposCadastroWhereUniqueInput;
};

export type CedenteCamposCadastroUpdateWithWhereUniqueWithoutResponsavelInput = {
    data: CedenteCamposCadastroUpdateWithoutResponsavelInput;
    where: CedenteCamposCadastroWhereUniqueInput;
};

export type CedenteCamposCadastroUpdateWithoutCadastroInput = {
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutCamposCadastroCedentesNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CedenteCamposCadastroUpdateWithoutResponsavelInput = {
    cadastro?: InputMaybe<CedenteCadastroUpdateOneRequiredWithoutCamposNestedInput>;
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CedenteCamposCadastroUpsertWithWhereUniqueWithoutCadastroInput = {
    create: CedenteCamposCadastroCreateWithoutCadastroInput;
    update: CedenteCamposCadastroUpdateWithoutCadastroInput;
    where: CedenteCamposCadastroWhereUniqueInput;
};

export type CedenteCamposCadastroUpsertWithWhereUniqueWithoutResponsavelInput = {
    create: CedenteCamposCadastroCreateWithoutResponsavelInput;
    update: CedenteCamposCadastroUpdateWithoutResponsavelInput;
    where: CedenteCamposCadastroWhereUniqueInput;
};

export type CedenteCamposCadastroWhereInput = {
    AND?: InputMaybe<Array<CedenteCamposCadastroWhereInput>>;
    NOT?: InputMaybe<Array<CedenteCamposCadastroWhereInput>>;
    OR?: InputMaybe<Array<CedenteCamposCadastroWhereInput>>;
    cadastro?: InputMaybe<CedenteCadastroRelationFilter>;
    cadastroId?: InputMaybe<IntFilter>;
    campo?: InputMaybe<StringFilter>;
    dtVerificacao?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    responsavel?: InputMaybe<UsuarioRelationFilter>;
    responsavelId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    valor?: InputMaybe<StringFilter>;
};

export type CedenteCamposCadastroWhereUniqueInput = {
    campo_cadastroId?: InputMaybe<CedenteCamposCadastroCampoCadastroIdCompoundUniqueInput>;
    id?: InputMaybe<Scalars["Int"]>;
};

export type CedenteContato = {
    __typename?: "CedenteContato";
    cedente: Cedente;
    cedenteId: Scalars["Int"];
    contato: Contato;
    contatoId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    inativo: Scalars["Boolean"];
    principal: Scalars["Boolean"];
    updatedAt: Scalars["DateTime"];
};

export type CedenteContatoAvgAggregate = {
    __typename?: "CedenteContatoAvgAggregate";
    cedenteId?: Maybe<Scalars["Float"]>;
    contatoId?: Maybe<Scalars["Float"]>;
};

export type CedenteContatoAvgOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    contatoId?: InputMaybe<SortOrder>;
};

export type CedenteContatoCedenteIdContatoIdCompoundUniqueInput = {
    cedenteId: Scalars["Int"];
    contatoId: Scalars["Int"];
};

export type CedenteContatoCountAggregate = {
    __typename?: "CedenteContatoCountAggregate";
    _all: Scalars["Int"];
    cedenteId: Scalars["Int"];
    contatoId: Scalars["Int"];
    createdAt: Scalars["Int"];
    inativo: Scalars["Int"];
    principal: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type CedenteContatoCountOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteContatoCreateInput = {
    cedente: CedenteCreateNestedOneWithoutContatosInput;
    contato: ContatoCreateNestedOneWithoutCedentesInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteContatoCreateManyCedenteInput = {
    contatoId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteContatoCreateManyCedenteInputEnvelope = {
    data: Array<CedenteContatoCreateManyCedenteInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteContatoCreateManyContatoInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteContatoCreateManyContatoInputEnvelope = {
    data: Array<CedenteContatoCreateManyContatoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteContatoCreateManyInput = {
    cedenteId: Scalars["Int"];
    contatoId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteContatoCreateNestedManyWithoutCedenteInput = {
    connect?: InputMaybe<Array<CedenteContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteContatoCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<CedenteContatoCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<CedenteContatoCreateManyCedenteInputEnvelope>;
};

export type CedenteContatoCreateNestedManyWithoutContatoInput = {
    connect?: InputMaybe<Array<CedenteContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteContatoCreateOrConnectWithoutContatoInput>>;
    create?: InputMaybe<Array<CedenteContatoCreateWithoutContatoInput>>;
    createMany?: InputMaybe<CedenteContatoCreateManyContatoInputEnvelope>;
};

export type CedenteContatoCreateOrConnectWithoutCedenteInput = {
    create: CedenteContatoCreateWithoutCedenteInput;
    where: CedenteContatoWhereUniqueInput;
};

export type CedenteContatoCreateOrConnectWithoutContatoInput = {
    create: CedenteContatoCreateWithoutContatoInput;
    where: CedenteContatoWhereUniqueInput;
};

export type CedenteContatoCreateWithoutCedenteInput = {
    contato: ContatoCreateNestedOneWithoutCedentesInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteContatoCreateWithoutContatoInput = {
    cedente: CedenteCreateNestedOneWithoutContatosInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteContatoGroupBy = {
    __typename?: "CedenteContatoGroupBy";
    _avg?: Maybe<CedenteContatoAvgAggregate>;
    _count?: Maybe<CedenteContatoCountAggregate>;
    _max?: Maybe<CedenteContatoMaxAggregate>;
    _min?: Maybe<CedenteContatoMinAggregate>;
    _sum?: Maybe<CedenteContatoSumAggregate>;
    cedenteId: Scalars["Int"];
    contatoId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    inativo: Scalars["Boolean"];
    principal: Scalars["Boolean"];
    updatedAt: Scalars["DateTime"];
};

export type CedenteContatoListRelationFilter = {
    every?: InputMaybe<CedenteContatoWhereInput>;
    none?: InputMaybe<CedenteContatoWhereInput>;
    some?: InputMaybe<CedenteContatoWhereInput>;
};

export type CedenteContatoMaxAggregate = {
    __typename?: "CedenteContatoMaxAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    contatoId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    principal?: Maybe<Scalars["Boolean"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CedenteContatoMaxOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteContatoMinAggregate = {
    __typename?: "CedenteContatoMinAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    contatoId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    principal?: Maybe<Scalars["Boolean"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CedenteContatoMinOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteContatoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type CedenteContatoOrderByWithAggregationInput = {
    _avg?: InputMaybe<CedenteContatoAvgOrderByAggregateInput>;
    _count?: InputMaybe<CedenteContatoCountOrderByAggregateInput>;
    _max?: InputMaybe<CedenteContatoMaxOrderByAggregateInput>;
    _min?: InputMaybe<CedenteContatoMinOrderByAggregateInput>;
    _sum?: InputMaybe<CedenteContatoSumOrderByAggregateInput>;
    cedenteId?: InputMaybe<SortOrder>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteContatoOrderByWithRelationInput = {
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    contato?: InputMaybe<ContatoOrderByWithRelationInput>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export enum CedenteContatoScalarFieldEnum {
    CedenteId = "cedenteId",
    ContatoId = "contatoId",
    CreatedAt = "createdAt",
    Inativo = "inativo",
    Principal = "principal",
    UpdatedAt = "updatedAt",
}

export type CedenteContatoScalarWhereInput = {
    AND?: InputMaybe<Array<CedenteContatoScalarWhereInput>>;
    NOT?: InputMaybe<Array<CedenteContatoScalarWhereInput>>;
    OR?: InputMaybe<Array<CedenteContatoScalarWhereInput>>;
    cedenteId?: InputMaybe<IntFilter>;
    contatoId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    inativo?: InputMaybe<BoolFilter>;
    principal?: InputMaybe<BoolFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CedenteContatoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CedenteContatoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CedenteContatoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CedenteContatoScalarWhereWithAggregatesInput>>;
    cedenteId?: InputMaybe<IntWithAggregatesFilter>;
    contatoId?: InputMaybe<IntWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    principal?: InputMaybe<BoolWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type CedenteContatoSumAggregate = {
    __typename?: "CedenteContatoSumAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    contatoId?: Maybe<Scalars["Int"]>;
};

export type CedenteContatoSumOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    contatoId?: InputMaybe<SortOrder>;
};

export type CedenteContatoUpdateInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutContatosNestedInput>;
    contato?: InputMaybe<ContatoUpdateOneRequiredWithoutCedentesNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    principal?: InputMaybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteContatoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    principal?: InputMaybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteContatoUpdateManyWithWhereWithoutCedenteInput = {
    data: CedenteContatoUpdateManyMutationInput;
    where: CedenteContatoScalarWhereInput;
};

export type CedenteContatoUpdateManyWithWhereWithoutContatoInput = {
    data: CedenteContatoUpdateManyMutationInput;
    where: CedenteContatoScalarWhereInput;
};

export type CedenteContatoUpdateManyWithoutCedenteNestedInput = {
    connect?: InputMaybe<Array<CedenteContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteContatoCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<CedenteContatoCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<CedenteContatoCreateManyCedenteInputEnvelope>;
    delete?: InputMaybe<Array<CedenteContatoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteContatoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteContatoWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteContatoWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteContatoUpdateWithWhereUniqueWithoutCedenteInput>>;
    updateMany?: InputMaybe<Array<CedenteContatoUpdateManyWithWhereWithoutCedenteInput>>;
    upsert?: InputMaybe<Array<CedenteContatoUpsertWithWhereUniqueWithoutCedenteInput>>;
};

export type CedenteContatoUpdateManyWithoutContatoNestedInput = {
    connect?: InputMaybe<Array<CedenteContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteContatoCreateOrConnectWithoutContatoInput>>;
    create?: InputMaybe<Array<CedenteContatoCreateWithoutContatoInput>>;
    createMany?: InputMaybe<CedenteContatoCreateManyContatoInputEnvelope>;
    delete?: InputMaybe<Array<CedenteContatoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteContatoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteContatoWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteContatoWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteContatoUpdateWithWhereUniqueWithoutContatoInput>>;
    updateMany?: InputMaybe<Array<CedenteContatoUpdateManyWithWhereWithoutContatoInput>>;
    upsert?: InputMaybe<Array<CedenteContatoUpsertWithWhereUniqueWithoutContatoInput>>;
};

export type CedenteContatoUpdateWithWhereUniqueWithoutCedenteInput = {
    data: CedenteContatoUpdateWithoutCedenteInput;
    where: CedenteContatoWhereUniqueInput;
};

export type CedenteContatoUpdateWithWhereUniqueWithoutContatoInput = {
    data: CedenteContatoUpdateWithoutContatoInput;
    where: CedenteContatoWhereUniqueInput;
};

export type CedenteContatoUpdateWithoutCedenteInput = {
    contato?: InputMaybe<ContatoUpdateOneRequiredWithoutCedentesNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    principal?: InputMaybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteContatoUpdateWithoutContatoInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutContatosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    principal?: InputMaybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteContatoUpsertWithWhereUniqueWithoutCedenteInput = {
    create: CedenteContatoCreateWithoutCedenteInput;
    update: CedenteContatoUpdateWithoutCedenteInput;
    where: CedenteContatoWhereUniqueInput;
};

export type CedenteContatoUpsertWithWhereUniqueWithoutContatoInput = {
    create: CedenteContatoCreateWithoutContatoInput;
    update: CedenteContatoUpdateWithoutContatoInput;
    where: CedenteContatoWhereUniqueInput;
};

export type CedenteContatoWhereInput = {
    AND?: InputMaybe<Array<CedenteContatoWhereInput>>;
    NOT?: InputMaybe<Array<CedenteContatoWhereInput>>;
    OR?: InputMaybe<Array<CedenteContatoWhereInput>>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    contato?: InputMaybe<ContatoRelationFilter>;
    contatoId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    inativo?: InputMaybe<BoolFilter>;
    principal?: InputMaybe<BoolFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CedenteContatoWhereUniqueInput = {
    cedenteId_contatoId?: InputMaybe<CedenteContatoCedenteIdContatoIdCompoundUniqueInput>;
};

export type CedenteCount = {
    __typename?: "CedenteCount";
    AssignorStatusByMinibank: Scalars["Int"];
    ContaBancaria: Scalars["Int"];
    OperationsInfo: Scalars["Int"];
    RecebiveisInfo: Scalars["Int"];
    RecebiveisToAnticipate: Scalars["Int"];
    alteracoes: Scalars["Int"];
    anexos: Scalars["Int"];
    contatos: Scalars["Int"];
    histStatus: Scalars["Int"];
    historico: Scalars["Int"];
    minibancos: Scalars["Int"];
    recebiveis: Scalars["Int"];
    responsaveis: Scalars["Int"];
    statusOperacional: Scalars["Int"];
};

export type CedenteCountAggregate = {
    __typename?: "CedenteCountAggregate";
    _all: Scalars["Int"];
    ativo: Scalars["Int"];
    cadastroId: Scalars["Int"];
    cnpj: Scalars["Int"];
    codigoCnae: Scalars["Int"];
    contaBancId: Scalars["Int"];
    createdAt: Scalars["Int"];
    digitalSignature: Scalars["Int"];
    email: Scalars["Int"];
    enderecoId: Scalars["Int"];
    fone: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    naoAntecipa: Scalars["Int"];
    nomeFantasia: Scalars["Int"];
    origem: Scalars["Int"];
    pessoaFisica: Scalars["Int"];
    razaoSocial: Scalars["Int"];
    representativeId: Scalars["Int"];
    situation: Scalars["Int"];
    status: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type CedenteCountOrderByAggregateInput = {
    ativo?: InputMaybe<SortOrder>;
    cadastroId?: InputMaybe<SortOrder>;
    cnpj?: InputMaybe<SortOrder>;
    codigoCnae?: InputMaybe<SortOrder>;
    contaBancId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    digitalSignature?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    naoAntecipa?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    representativeId?: InputMaybe<SortOrder>;
    situation?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteCreateInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateManyCnaeInput = {
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastroId?: InputMaybe<Scalars["Int"]>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    enderecoId?: InputMaybe<Scalars["Int"]>;
    fone?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    representativeId?: InputMaybe<Scalars["Int"]>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateManyCnaeInputEnvelope = {
    data: Array<CedenteCreateManyCnaeInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteCreateManyEnderecoInput = {
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastroId?: InputMaybe<Scalars["Int"]>;
    cnpj: Scalars["String"];
    codigoCnae?: InputMaybe<Scalars["Int"]>;
    contaBancId?: InputMaybe<Scalars["Int"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    representativeId?: InputMaybe<Scalars["Int"]>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateManyEnderecoInputEnvelope = {
    data: Array<CedenteCreateManyEnderecoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteCreateManyInput = {
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastroId?: InputMaybe<Scalars["Int"]>;
    cnpj: Scalars["String"];
    codigoCnae?: InputMaybe<Scalars["Int"]>;
    contaBancId?: InputMaybe<Scalars["Int"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    enderecoId?: InputMaybe<Scalars["Int"]>;
    fone?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    representativeId?: InputMaybe<Scalars["Int"]>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateManyRepresentativeInput = {
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastroId?: InputMaybe<Scalars["Int"]>;
    cnpj: Scalars["String"];
    codigoCnae?: InputMaybe<Scalars["Int"]>;
    contaBancId?: InputMaybe<Scalars["Int"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    enderecoId?: InputMaybe<Scalars["Int"]>;
    fone?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateManyRepresentativeInputEnvelope = {
    data: Array<CedenteCreateManyRepresentativeInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CedenteCreateNestedManyWithoutCnaeInput = {
    connect?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCreateOrConnectWithoutCnaeInput>>;
    create?: InputMaybe<Array<CedenteCreateWithoutCnaeInput>>;
    createMany?: InputMaybe<CedenteCreateManyCnaeInputEnvelope>;
};

export type CedenteCreateNestedManyWithoutEnderecoInput = {
    connect?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCreateOrConnectWithoutEnderecoInput>>;
    create?: InputMaybe<Array<CedenteCreateWithoutEnderecoInput>>;
    createMany?: InputMaybe<CedenteCreateManyEnderecoInputEnvelope>;
};

export type CedenteCreateNestedManyWithoutMinibancosInput = {
    connect?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCreateOrConnectWithoutMinibancosInput>>;
    create?: InputMaybe<Array<CedenteCreateWithoutMinibancosInput>>;
};

export type CedenteCreateNestedManyWithoutRepresentativeInput = {
    connect?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCreateOrConnectWithoutRepresentativeInput>>;
    create?: InputMaybe<Array<CedenteCreateWithoutRepresentativeInput>>;
    createMany?: InputMaybe<CedenteCreateManyRepresentativeInputEnvelope>;
};

export type CedenteCreateNestedOneWithoutAlteracoesInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutAlteracoesInput>;
    create?: InputMaybe<CedenteCreateWithoutAlteracoesInput>;
};

export type CedenteCreateNestedOneWithoutAnexosInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutAnexosInput>;
    create?: InputMaybe<CedenteCreateWithoutAnexosInput>;
};

export type CedenteCreateNestedOneWithoutAssignorBankDataInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutAssignorBankDataInput>;
    create?: InputMaybe<CedenteCreateWithoutAssignorBankDataInput>;
};

export type CedenteCreateNestedOneWithoutAssignorStatusByMinibankInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutAssignorStatusByMinibankInput>;
    create?: InputMaybe<CedenteCreateWithoutAssignorStatusByMinibankInput>;
};

export type CedenteCreateNestedOneWithoutCadastroInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutCadastroInput>;
    create?: InputMaybe<CedenteCreateWithoutCadastroInput>;
};

export type CedenteCreateNestedOneWithoutCedentesInfoInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutCedentesInfoInput>;
    create?: InputMaybe<CedenteCreateWithoutCedentesInfoInput>;
};

export type CedenteCreateNestedOneWithoutContaBancariaInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutContaBancariaInput>;
    create?: InputMaybe<CedenteCreateWithoutContaBancariaInput>;
};

export type CedenteCreateNestedOneWithoutContatosInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutContatosInput>;
    create?: InputMaybe<CedenteCreateWithoutContatosInput>;
};

export type CedenteCreateNestedOneWithoutHistStatusInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutHistStatusInput>;
    create?: InputMaybe<CedenteCreateWithoutHistStatusInput>;
};

export type CedenteCreateNestedOneWithoutHistoricoInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<CedenteCreateWithoutHistoricoInput>;
};

export type CedenteCreateNestedOneWithoutOperationsInfoInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutOperationsInfoInput>;
    create?: InputMaybe<CedenteCreateWithoutOperationsInfoInput>;
};

export type CedenteCreateNestedOneWithoutPreCadastroCedenteInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutPreCadastroCedenteInput>;
    create?: InputMaybe<CedenteCreateWithoutPreCadastroCedenteInput>;
};

export type CedenteCreateNestedOneWithoutRecebiveisInfoInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutRecebiveisInfoInput>;
    create?: InputMaybe<CedenteCreateWithoutRecebiveisInfoInput>;
};

export type CedenteCreateNestedOneWithoutRecebiveisInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutRecebiveisInput>;
    create?: InputMaybe<CedenteCreateWithoutRecebiveisInput>;
};

export type CedenteCreateNestedOneWithoutRecebiveisToAnticipateInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutRecebiveisToAnticipateInput>;
    create?: InputMaybe<CedenteCreateWithoutRecebiveisToAnticipateInput>;
};

export type CedenteCreateNestedOneWithoutResponsaveisInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutResponsaveisInput>;
    create?: InputMaybe<CedenteCreateWithoutResponsaveisInput>;
};

export type CedenteCreateNestedOneWithoutStatusOperacionalInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutStatusOperacionalInput>;
    create?: InputMaybe<CedenteCreateWithoutStatusOperacionalInput>;
};

export type CedenteCreateOrConnectWithoutAlteracoesInput = {
    create: CedenteCreateWithoutAlteracoesInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutAnexosInput = {
    create: CedenteCreateWithoutAnexosInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutAssignorBankDataInput = {
    create: CedenteCreateWithoutAssignorBankDataInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutAssignorStatusByMinibankInput = {
    create: CedenteCreateWithoutAssignorStatusByMinibankInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutCadastroInput = {
    create: CedenteCreateWithoutCadastroInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutCedentesInfoInput = {
    create: CedenteCreateWithoutCedentesInfoInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutCnaeInput = {
    create: CedenteCreateWithoutCnaeInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutContaBancariaInput = {
    create: CedenteCreateWithoutContaBancariaInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutContatosInput = {
    create: CedenteCreateWithoutContatosInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutEnderecoInput = {
    create: CedenteCreateWithoutEnderecoInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutHistStatusInput = {
    create: CedenteCreateWithoutHistStatusInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutHistoricoInput = {
    create: CedenteCreateWithoutHistoricoInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutMinibancosInput = {
    create: CedenteCreateWithoutMinibancosInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutOperationsInfoInput = {
    create: CedenteCreateWithoutOperationsInfoInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutPreCadastroCedenteInput = {
    create: CedenteCreateWithoutPreCadastroCedenteInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutRecebiveisInfoInput = {
    create: CedenteCreateWithoutRecebiveisInfoInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutRecebiveisInput = {
    create: CedenteCreateWithoutRecebiveisInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutRecebiveisToAnticipateInput = {
    create: CedenteCreateWithoutRecebiveisToAnticipateInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutRepresentativeInput = {
    create: CedenteCreateWithoutRepresentativeInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutResponsaveisInput = {
    create: CedenteCreateWithoutResponsaveisInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateOrConnectWithoutStatusOperacionalInput = {
    create: CedenteCreateWithoutStatusOperacionalInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteCreateWithoutAlteracoesInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutAnexosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutAssignorBankDataInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutAssignorStatusByMinibankInput = {
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutCadastroInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutCedentesInfoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutCnaeInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutContaBancariaInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutContatosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutEnderecoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutHistStatusInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutHistoricoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutMinibancosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutOperationsInfoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutPreCadastroCedenteInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutRecebiveisInfoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutRecebiveisInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutRecebiveisToAnticipateInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutRepresentativeInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutResponsaveisInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteCreateWithoutStatusOperacionalInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutAssignorInput>;
    CedentesInfo?: InputMaybe<CedentesInfoCreateNestedOneWithoutCedenteInput>;
    ContaBancaria?: InputMaybe<ContaBancariaCreateNestedManyWithoutCedenteInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutCedenteInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteCreateNestedOneWithoutCedenteInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutCedenteInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput>;
    Representative?: InputMaybe<RepresentativeCreateNestedOneWithoutAssignorInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutCedenteInput>;
    anexos?: InputMaybe<CedenteAnexosCreateNestedManyWithoutCedenteInput>;
    assignorBankData?: InputMaybe<AssignorBankDataCreateNestedOneWithoutAssignorInput>;
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cadastro?: InputMaybe<CedenteCadastroCreateNestedOneWithoutCedenteInput>;
    cnae?: InputMaybe<CnaeClassesCreateNestedOneWithoutCedentesInput>;
    cnpj: Scalars["String"];
    contaBancId?: InputMaybe<Scalars["Int"]>;
    contatos?: InputMaybe<CedenteContatoCreateNestedManyWithoutCedenteInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    digitalSignature?: InputMaybe<DigitalSignature>;
    email?: InputMaybe<Scalars["String"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCedentesInput>;
    fone?: InputMaybe<Scalars["String"]>;
    histStatus?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutCedenteInput>;
    historico?: InputMaybe<CedenteAuditCreateNestedManyWithoutCedenteInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutCedentesInput>;
    naoAntecipa?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    origem?: InputMaybe<OrigemCedente>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutCedenteInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutCedenteInput>;
    situation?: InputMaybe<Scalars["Boolean"]>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CedenteGroupBy = {
    __typename?: "CedenteGroupBy";
    _avg?: Maybe<CedenteAvgAggregate>;
    _count?: Maybe<CedenteCountAggregate>;
    _max?: Maybe<CedenteMaxAggregate>;
    _min?: Maybe<CedenteMinAggregate>;
    _sum?: Maybe<CedenteSumAggregate>;
    ativo: Scalars["Boolean"];
    cadastroId?: Maybe<Scalars["Int"]>;
    cnpj: Scalars["String"];
    codigoCnae?: Maybe<Scalars["Int"]>;
    contaBancId?: Maybe<Scalars["Int"]>;
    createdAt: Scalars["DateTime"];
    digitalSignature?: Maybe<DigitalSignature>;
    email?: Maybe<Scalars["String"]>;
    enderecoId?: Maybe<Scalars["Int"]>;
    fone?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    naoAntecipa: Scalars["Boolean"];
    nomeFantasia?: Maybe<Scalars["String"]>;
    origem: OrigemCedente;
    pessoaFisica: Scalars["Boolean"];
    razaoSocial: Scalars["String"];
    representativeId?: Maybe<Scalars["Int"]>;
    situation: Scalars["Boolean"];
    status: CedenteStatus;
    updatedAt: Scalars["DateTime"];
};

export type CedenteListRelationFilter = {
    every?: InputMaybe<CedenteWhereInput>;
    none?: InputMaybe<CedenteWhereInput>;
    some?: InputMaybe<CedenteWhereInput>;
};

export type CedenteMaxAggregate = {
    __typename?: "CedenteMaxAggregate";
    ativo?: Maybe<Scalars["Boolean"]>;
    cadastroId?: Maybe<Scalars["Int"]>;
    cnpj?: Maybe<Scalars["String"]>;
    codigoCnae?: Maybe<Scalars["Int"]>;
    contaBancId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    digitalSignature?: Maybe<DigitalSignature>;
    email?: Maybe<Scalars["String"]>;
    enderecoId?: Maybe<Scalars["Int"]>;
    fone?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    naoAntecipa?: Maybe<Scalars["Boolean"]>;
    nomeFantasia?: Maybe<Scalars["String"]>;
    origem?: Maybe<OrigemCedente>;
    pessoaFisica?: Maybe<Scalars["Boolean"]>;
    razaoSocial?: Maybe<Scalars["String"]>;
    representativeId?: Maybe<Scalars["Int"]>;
    situation?: Maybe<Scalars["Boolean"]>;
    status?: Maybe<CedenteStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CedenteMaxOrderByAggregateInput = {
    ativo?: InputMaybe<SortOrder>;
    cadastroId?: InputMaybe<SortOrder>;
    cnpj?: InputMaybe<SortOrder>;
    codigoCnae?: InputMaybe<SortOrder>;
    contaBancId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    digitalSignature?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    naoAntecipa?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    representativeId?: InputMaybe<SortOrder>;
    situation?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteMinAggregate = {
    __typename?: "CedenteMinAggregate";
    ativo?: Maybe<Scalars["Boolean"]>;
    cadastroId?: Maybe<Scalars["Int"]>;
    cnpj?: Maybe<Scalars["String"]>;
    codigoCnae?: Maybe<Scalars["Int"]>;
    contaBancId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    digitalSignature?: Maybe<DigitalSignature>;
    email?: Maybe<Scalars["String"]>;
    enderecoId?: Maybe<Scalars["Int"]>;
    fone?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    naoAntecipa?: Maybe<Scalars["Boolean"]>;
    nomeFantasia?: Maybe<Scalars["String"]>;
    origem?: Maybe<OrigemCedente>;
    pessoaFisica?: Maybe<Scalars["Boolean"]>;
    razaoSocial?: Maybe<Scalars["String"]>;
    representativeId?: Maybe<Scalars["Int"]>;
    situation?: Maybe<Scalars["Boolean"]>;
    status?: Maybe<CedenteStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CedenteMinOrderByAggregateInput = {
    ativo?: InputMaybe<SortOrder>;
    cadastroId?: InputMaybe<SortOrder>;
    cnpj?: InputMaybe<SortOrder>;
    codigoCnae?: InputMaybe<SortOrder>;
    contaBancId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    digitalSignature?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    naoAntecipa?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    representativeId?: InputMaybe<SortOrder>;
    situation?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type CedenteOrderByWithAggregationInput = {
    _avg?: InputMaybe<CedenteAvgOrderByAggregateInput>;
    _count?: InputMaybe<CedenteCountOrderByAggregateInput>;
    _max?: InputMaybe<CedenteMaxOrderByAggregateInput>;
    _min?: InputMaybe<CedenteMinOrderByAggregateInput>;
    _sum?: InputMaybe<CedenteSumOrderByAggregateInput>;
    ativo?: InputMaybe<SortOrder>;
    cadastroId?: InputMaybe<SortOrder>;
    cnpj?: InputMaybe<SortOrder>;
    codigoCnae?: InputMaybe<SortOrder>;
    contaBancId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    digitalSignature?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    naoAntecipa?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    representativeId?: InputMaybe<SortOrder>;
    situation?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteOrderByWithRelationInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankOrderByRelationAggregateInput>;
    CedentesInfo?: InputMaybe<CedentesInfoOrderByWithRelationInput>;
    ContaBancaria?: InputMaybe<ContaBancariaOrderByRelationAggregateInput>;
    OperationsInfo?: InputMaybe<OperationsInfoOrderByRelationAggregateInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteOrderByWithRelationInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoOrderByRelationAggregateInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateOrderByRelationAggregateInput>;
    Representative?: InputMaybe<RepresentativeOrderByWithRelationInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoOrderByRelationAggregateInput>;
    anexos?: InputMaybe<CedenteAnexosOrderByRelationAggregateInput>;
    assignorBankData?: InputMaybe<AssignorBankDataOrderByWithRelationInput>;
    ativo?: InputMaybe<SortOrder>;
    cadastro?: InputMaybe<CedenteCadastroOrderByWithRelationInput>;
    cadastroId?: InputMaybe<SortOrder>;
    cnae?: InputMaybe<CnaeClassesOrderByWithRelationInput>;
    cnpj?: InputMaybe<SortOrder>;
    codigoCnae?: InputMaybe<SortOrder>;
    contaBancId?: InputMaybe<SortOrder>;
    contatos?: InputMaybe<CedenteContatoOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    digitalSignature?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    endereco?: InputMaybe<EnderecoOrderByWithRelationInput>;
    enderecoId?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    histStatus?: InputMaybe<StatusOperacionalAuditOrderByRelationAggregateInput>;
    historico?: InputMaybe<CedenteAuditOrderByRelationAggregateInput>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancos?: InputMaybe<MinibancoOrderByRelationAggregateInput>;
    naoAntecipa?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    recebiveis?: InputMaybe<RecebivelOrderByRelationAggregateInput>;
    representativeId?: InputMaybe<SortOrder>;
    responsaveis?: InputMaybe<ResponsavelCedenteOrderByRelationAggregateInput>;
    situation?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoOrderByRelationAggregateInput>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type CedenteRelationFilter = {
    is?: InputMaybe<CedenteWhereInput>;
    isNot?: InputMaybe<CedenteWhereInput>;
};

export enum CedenteScalarFieldEnum {
    Ativo = "ativo",
    CadastroId = "cadastroId",
    Cnpj = "cnpj",
    CodigoCnae = "codigoCnae",
    ContaBancId = "contaBancId",
    CreatedAt = "createdAt",
    DigitalSignature = "digitalSignature",
    Email = "email",
    EnderecoId = "enderecoId",
    Fone = "fone",
    Id = "id",
    Inativo = "inativo",
    NaoAntecipa = "naoAntecipa",
    NomeFantasia = "nomeFantasia",
    Origem = "origem",
    PessoaFisica = "pessoaFisica",
    RazaoSocial = "razaoSocial",
    RepresentativeId = "representativeId",
    Situation = "situation",
    Status = "status",
    UpdatedAt = "updatedAt",
}

export type CedenteScalarWhereInput = {
    AND?: InputMaybe<Array<CedenteScalarWhereInput>>;
    NOT?: InputMaybe<Array<CedenteScalarWhereInput>>;
    OR?: InputMaybe<Array<CedenteScalarWhereInput>>;
    ativo?: InputMaybe<BoolFilter>;
    cadastroId?: InputMaybe<IntNullableFilter>;
    cnpj?: InputMaybe<StringFilter>;
    codigoCnae?: InputMaybe<IntNullableFilter>;
    contaBancId?: InputMaybe<IntNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    digitalSignature?: InputMaybe<EnumDigitalSignatureNullableFilter>;
    email?: InputMaybe<StringNullableFilter>;
    enderecoId?: InputMaybe<IntNullableFilter>;
    fone?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    naoAntecipa?: InputMaybe<BoolFilter>;
    nomeFantasia?: InputMaybe<StringNullableFilter>;
    origem?: InputMaybe<EnumOrigemCedenteFilter>;
    pessoaFisica?: InputMaybe<BoolFilter>;
    razaoSocial?: InputMaybe<StringFilter>;
    representativeId?: InputMaybe<IntNullableFilter>;
    situation?: InputMaybe<BoolFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CedenteScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CedenteScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CedenteScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CedenteScalarWhereWithAggregatesInput>>;
    ativo?: InputMaybe<BoolWithAggregatesFilter>;
    cadastroId?: InputMaybe<IntNullableWithAggregatesFilter>;
    cnpj?: InputMaybe<StringWithAggregatesFilter>;
    codigoCnae?: InputMaybe<IntNullableWithAggregatesFilter>;
    contaBancId?: InputMaybe<IntNullableWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    digitalSignature?: InputMaybe<EnumDigitalSignatureNullableWithAggregatesFilter>;
    email?: InputMaybe<StringNullableWithAggregatesFilter>;
    enderecoId?: InputMaybe<IntNullableWithAggregatesFilter>;
    fone?: InputMaybe<StringNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    naoAntecipa?: InputMaybe<BoolWithAggregatesFilter>;
    nomeFantasia?: InputMaybe<StringNullableWithAggregatesFilter>;
    origem?: InputMaybe<EnumOrigemCedenteWithAggregatesFilter>;
    pessoaFisica?: InputMaybe<BoolWithAggregatesFilter>;
    razaoSocial?: InputMaybe<StringWithAggregatesFilter>;
    representativeId?: InputMaybe<IntNullableWithAggregatesFilter>;
    situation?: InputMaybe<BoolWithAggregatesFilter>;
    status?: InputMaybe<EnumCedenteStatusWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export enum CedenteStatus {
    Aprovado = "APROVADO",
    EmAnalise = "EM_ANALISE",
    Recusado = "RECUSADO",
}

export type CedenteSumAggregate = {
    __typename?: "CedenteSumAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    codigoCnae?: Maybe<Scalars["Int"]>;
    contaBancId?: Maybe<Scalars["Int"]>;
    enderecoId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    representativeId?: Maybe<Scalars["Int"]>;
};

export type CedenteSumOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    codigoCnae?: InputMaybe<SortOrder>;
    contaBancId?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    representativeId?: InputMaybe<SortOrder>;
};

export type CedenteUpdateInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateManyMutationInput = {
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateManyWithWhereWithoutCnaeInput = {
    data: CedenteUpdateManyMutationInput;
    where: CedenteScalarWhereInput;
};

export type CedenteUpdateManyWithWhereWithoutEnderecoInput = {
    data: CedenteUpdateManyMutationInput;
    where: CedenteScalarWhereInput;
};

export type CedenteUpdateManyWithWhereWithoutMinibancosInput = {
    data: CedenteUpdateManyMutationInput;
    where: CedenteScalarWhereInput;
};

export type CedenteUpdateManyWithWhereWithoutRepresentativeInput = {
    data: CedenteUpdateManyMutationInput;
    where: CedenteScalarWhereInput;
};

export type CedenteUpdateManyWithoutCnaeNestedInput = {
    connect?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCreateOrConnectWithoutCnaeInput>>;
    create?: InputMaybe<Array<CedenteCreateWithoutCnaeInput>>;
    createMany?: InputMaybe<CedenteCreateManyCnaeInputEnvelope>;
    delete?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteUpdateWithWhereUniqueWithoutCnaeInput>>;
    updateMany?: InputMaybe<Array<CedenteUpdateManyWithWhereWithoutCnaeInput>>;
    upsert?: InputMaybe<Array<CedenteUpsertWithWhereUniqueWithoutCnaeInput>>;
};

export type CedenteUpdateManyWithoutEnderecoNestedInput = {
    connect?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCreateOrConnectWithoutEnderecoInput>>;
    create?: InputMaybe<Array<CedenteCreateWithoutEnderecoInput>>;
    createMany?: InputMaybe<CedenteCreateManyEnderecoInputEnvelope>;
    delete?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteUpdateWithWhereUniqueWithoutEnderecoInput>>;
    updateMany?: InputMaybe<Array<CedenteUpdateManyWithWhereWithoutEnderecoInput>>;
    upsert?: InputMaybe<Array<CedenteUpsertWithWhereUniqueWithoutEnderecoInput>>;
};

export type CedenteUpdateManyWithoutMinibancosNestedInput = {
    connect?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCreateOrConnectWithoutMinibancosInput>>;
    create?: InputMaybe<Array<CedenteCreateWithoutMinibancosInput>>;
    delete?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteUpdateWithWhereUniqueWithoutMinibancosInput>>;
    updateMany?: InputMaybe<Array<CedenteUpdateManyWithWhereWithoutMinibancosInput>>;
    upsert?: InputMaybe<Array<CedenteUpsertWithWhereUniqueWithoutMinibancosInput>>;
};

export type CedenteUpdateManyWithoutRepresentativeNestedInput = {
    connect?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CedenteCreateOrConnectWithoutRepresentativeInput>>;
    create?: InputMaybe<Array<CedenteCreateWithoutRepresentativeInput>>;
    createMany?: InputMaybe<CedenteCreateManyRepresentativeInputEnvelope>;
    delete?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CedenteScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    set?: InputMaybe<Array<CedenteWhereUniqueInput>>;
    update?: InputMaybe<Array<CedenteUpdateWithWhereUniqueWithoutRepresentativeInput>>;
    updateMany?: InputMaybe<Array<CedenteUpdateManyWithWhereWithoutRepresentativeInput>>;
    upsert?: InputMaybe<Array<CedenteUpsertWithWhereUniqueWithoutRepresentativeInput>>;
};

export type CedenteUpdateOneRequiredWithoutAlteracoesNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutAlteracoesInput>;
    create?: InputMaybe<CedenteCreateWithoutAlteracoesInput>;
    update?: InputMaybe<CedenteUpdateWithoutAlteracoesInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutAlteracoesInput>;
};

export type CedenteUpdateOneRequiredWithoutAnexosNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutAnexosInput>;
    create?: InputMaybe<CedenteCreateWithoutAnexosInput>;
    update?: InputMaybe<CedenteUpdateWithoutAnexosInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutAnexosInput>;
};

export type CedenteUpdateOneRequiredWithoutAssignorBankDataNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutAssignorBankDataInput>;
    create?: InputMaybe<CedenteCreateWithoutAssignorBankDataInput>;
    update?: InputMaybe<CedenteUpdateWithoutAssignorBankDataInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutAssignorBankDataInput>;
};

export type CedenteUpdateOneRequiredWithoutAssignorStatusByMinibankNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutAssignorStatusByMinibankInput>;
    create?: InputMaybe<CedenteCreateWithoutAssignorStatusByMinibankInput>;
    update?: InputMaybe<CedenteUpdateWithoutAssignorStatusByMinibankInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutAssignorStatusByMinibankInput>;
};

export type CedenteUpdateOneRequiredWithoutCedentesInfoNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutCedentesInfoInput>;
    create?: InputMaybe<CedenteCreateWithoutCedentesInfoInput>;
    update?: InputMaybe<CedenteUpdateWithoutCedentesInfoInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutCedentesInfoInput>;
};

export type CedenteUpdateOneRequiredWithoutContatosNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutContatosInput>;
    create?: InputMaybe<CedenteCreateWithoutContatosInput>;
    update?: InputMaybe<CedenteUpdateWithoutContatosInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutContatosInput>;
};

export type CedenteUpdateOneRequiredWithoutHistStatusNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutHistStatusInput>;
    create?: InputMaybe<CedenteCreateWithoutHistStatusInput>;
    update?: InputMaybe<CedenteUpdateWithoutHistStatusInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutHistStatusInput>;
};

export type CedenteUpdateOneRequiredWithoutHistoricoNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<CedenteCreateWithoutHistoricoInput>;
    update?: InputMaybe<CedenteUpdateWithoutHistoricoInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutHistoricoInput>;
};

export type CedenteUpdateOneRequiredWithoutOperationsInfoNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutOperationsInfoInput>;
    create?: InputMaybe<CedenteCreateWithoutOperationsInfoInput>;
    update?: InputMaybe<CedenteUpdateWithoutOperationsInfoInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutOperationsInfoInput>;
};

export type CedenteUpdateOneRequiredWithoutPreCadastroCedenteNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutPreCadastroCedenteInput>;
    create?: InputMaybe<CedenteCreateWithoutPreCadastroCedenteInput>;
    update?: InputMaybe<CedenteUpdateWithoutPreCadastroCedenteInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutPreCadastroCedenteInput>;
};

export type CedenteUpdateOneRequiredWithoutRecebiveisInfoNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutRecebiveisInfoInput>;
    create?: InputMaybe<CedenteCreateWithoutRecebiveisInfoInput>;
    update?: InputMaybe<CedenteUpdateWithoutRecebiveisInfoInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutRecebiveisInfoInput>;
};

export type CedenteUpdateOneRequiredWithoutRecebiveisNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutRecebiveisInput>;
    create?: InputMaybe<CedenteCreateWithoutRecebiveisInput>;
    update?: InputMaybe<CedenteUpdateWithoutRecebiveisInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutRecebiveisInput>;
};

export type CedenteUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutRecebiveisToAnticipateInput>;
    create?: InputMaybe<CedenteCreateWithoutRecebiveisToAnticipateInput>;
    update?: InputMaybe<CedenteUpdateWithoutRecebiveisToAnticipateInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutRecebiveisToAnticipateInput>;
};

export type CedenteUpdateOneRequiredWithoutResponsaveisNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutResponsaveisInput>;
    create?: InputMaybe<CedenteCreateWithoutResponsaveisInput>;
    update?: InputMaybe<CedenteUpdateWithoutResponsaveisInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutResponsaveisInput>;
};

export type CedenteUpdateOneRequiredWithoutStatusOperacionalNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutStatusOperacionalInput>;
    create?: InputMaybe<CedenteCreateWithoutStatusOperacionalInput>;
    update?: InputMaybe<CedenteUpdateWithoutStatusOperacionalInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutStatusOperacionalInput>;
};

export type CedenteUpdateOneWithoutCadastroNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutCadastroInput>;
    create?: InputMaybe<CedenteCreateWithoutCadastroInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<CedenteUpdateWithoutCadastroInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutCadastroInput>;
};

export type CedenteUpdateOneWithoutContaBancariaNestedInput = {
    connect?: InputMaybe<CedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedenteCreateOrConnectWithoutContaBancariaInput>;
    create?: InputMaybe<CedenteCreateWithoutContaBancariaInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<CedenteUpdateWithoutContaBancariaInput>;
    upsert?: InputMaybe<CedenteUpsertWithoutContaBancariaInput>;
};

export type CedenteUpdateWithWhereUniqueWithoutCnaeInput = {
    data: CedenteUpdateWithoutCnaeInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteUpdateWithWhereUniqueWithoutEnderecoInput = {
    data: CedenteUpdateWithoutEnderecoInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteUpdateWithWhereUniqueWithoutMinibancosInput = {
    data: CedenteUpdateWithoutMinibancosInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteUpdateWithWhereUniqueWithoutRepresentativeInput = {
    data: CedenteUpdateWithoutRepresentativeInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteUpdateWithoutAlteracoesInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutAnexosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutAssignorBankDataInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutAssignorStatusByMinibankInput = {
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutCadastroInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutCedentesInfoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutCnaeInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutContaBancariaInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutContatosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutEnderecoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutHistStatusInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutHistoricoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutMinibancosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutOperationsInfoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutPreCadastroCedenteInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutRecebiveisInfoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutRecebiveisInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutRecebiveisToAnticipateInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutRepresentativeInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutResponsaveisInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpdateWithoutStatusOperacionalInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutAssignorNestedInput>;
    CedentesInfo?: InputMaybe<CedentesInfoUpdateOneWithoutCedenteNestedInput>;
    ContaBancaria?: InputMaybe<ContaBancariaUpdateManyWithoutCedenteNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutCedenteNestedInput>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteUpdateOneWithoutCedenteNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutCedenteNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput>;
    Representative?: InputMaybe<RepresentativeUpdateOneWithoutAssignorNestedInput>;
    alteracoes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutCedenteNestedInput>;
    anexos?: InputMaybe<CedenteAnexosUpdateManyWithoutCedenteNestedInput>;
    assignorBankData?: InputMaybe<AssignorBankDataUpdateOneWithoutAssignorNestedInput>;
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<CedenteCadastroUpdateOneWithoutCedenteNestedInput>;
    cnae?: InputMaybe<CnaeClassesUpdateOneWithoutCedentesNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    contaBancId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    contatos?: InputMaybe<CedenteContatoUpdateManyWithoutCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    digitalSignature?: InputMaybe<NullableEnumDigitalSignatureFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCedentesNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histStatus?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput>;
    historico?: InputMaybe<CedenteAuditUpdateManyWithoutCedenteNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutCedentesNestedInput>;
    naoAntecipa?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemCedenteFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutCedenteNestedInput>;
    responsaveis?: InputMaybe<ResponsavelCedenteUpdateManyWithoutCedenteNestedInput>;
    situation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CedenteUpsertWithWhereUniqueWithoutCnaeInput = {
    create: CedenteCreateWithoutCnaeInput;
    update: CedenteUpdateWithoutCnaeInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteUpsertWithWhereUniqueWithoutEnderecoInput = {
    create: CedenteCreateWithoutEnderecoInput;
    update: CedenteUpdateWithoutEnderecoInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteUpsertWithWhereUniqueWithoutMinibancosInput = {
    create: CedenteCreateWithoutMinibancosInput;
    update: CedenteUpdateWithoutMinibancosInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteUpsertWithWhereUniqueWithoutRepresentativeInput = {
    create: CedenteCreateWithoutRepresentativeInput;
    update: CedenteUpdateWithoutRepresentativeInput;
    where: CedenteWhereUniqueInput;
};

export type CedenteUpsertWithoutAlteracoesInput = {
    create: CedenteCreateWithoutAlteracoesInput;
    update: CedenteUpdateWithoutAlteracoesInput;
};

export type CedenteUpsertWithoutAnexosInput = {
    create: CedenteCreateWithoutAnexosInput;
    update: CedenteUpdateWithoutAnexosInput;
};

export type CedenteUpsertWithoutAssignorBankDataInput = {
    create: CedenteCreateWithoutAssignorBankDataInput;
    update: CedenteUpdateWithoutAssignorBankDataInput;
};

export type CedenteUpsertWithoutAssignorStatusByMinibankInput = {
    create: CedenteCreateWithoutAssignorStatusByMinibankInput;
    update: CedenteUpdateWithoutAssignorStatusByMinibankInput;
};

export type CedenteUpsertWithoutCadastroInput = {
    create: CedenteCreateWithoutCadastroInput;
    update: CedenteUpdateWithoutCadastroInput;
};

export type CedenteUpsertWithoutCedentesInfoInput = {
    create: CedenteCreateWithoutCedentesInfoInput;
    update: CedenteUpdateWithoutCedentesInfoInput;
};

export type CedenteUpsertWithoutContaBancariaInput = {
    create: CedenteCreateWithoutContaBancariaInput;
    update: CedenteUpdateWithoutContaBancariaInput;
};

export type CedenteUpsertWithoutContatosInput = {
    create: CedenteCreateWithoutContatosInput;
    update: CedenteUpdateWithoutContatosInput;
};

export type CedenteUpsertWithoutHistStatusInput = {
    create: CedenteCreateWithoutHistStatusInput;
    update: CedenteUpdateWithoutHistStatusInput;
};

export type CedenteUpsertWithoutHistoricoInput = {
    create: CedenteCreateWithoutHistoricoInput;
    update: CedenteUpdateWithoutHistoricoInput;
};

export type CedenteUpsertWithoutOperationsInfoInput = {
    create: CedenteCreateWithoutOperationsInfoInput;
    update: CedenteUpdateWithoutOperationsInfoInput;
};

export type CedenteUpsertWithoutPreCadastroCedenteInput = {
    create: CedenteCreateWithoutPreCadastroCedenteInput;
    update: CedenteUpdateWithoutPreCadastroCedenteInput;
};

export type CedenteUpsertWithoutRecebiveisInfoInput = {
    create: CedenteCreateWithoutRecebiveisInfoInput;
    update: CedenteUpdateWithoutRecebiveisInfoInput;
};

export type CedenteUpsertWithoutRecebiveisInput = {
    create: CedenteCreateWithoutRecebiveisInput;
    update: CedenteUpdateWithoutRecebiveisInput;
};

export type CedenteUpsertWithoutRecebiveisToAnticipateInput = {
    create: CedenteCreateWithoutRecebiveisToAnticipateInput;
    update: CedenteUpdateWithoutRecebiveisToAnticipateInput;
};

export type CedenteUpsertWithoutResponsaveisInput = {
    create: CedenteCreateWithoutResponsaveisInput;
    update: CedenteUpdateWithoutResponsaveisInput;
};

export type CedenteUpsertWithoutStatusOperacionalInput = {
    create: CedenteCreateWithoutStatusOperacionalInput;
    update: CedenteUpdateWithoutStatusOperacionalInput;
};

export type CedenteWhereInput = {
    AND?: InputMaybe<Array<CedenteWhereInput>>;
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankListRelationFilter>;
    CedentesInfo?: InputMaybe<CedentesInfoRelationFilter>;
    ContaBancaria?: InputMaybe<ContaBancariaListRelationFilter>;
    NOT?: InputMaybe<Array<CedenteWhereInput>>;
    OR?: InputMaybe<Array<CedenteWhereInput>>;
    OperationsInfo?: InputMaybe<OperationsInfoListRelationFilter>;
    PreCadastroCedente?: InputMaybe<PreCadastroCedenteRelationFilter>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoListRelationFilter>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateListRelationFilter>;
    Representative?: InputMaybe<RepresentativeRelationFilter>;
    alteracoes?: InputMaybe<CedenteAlteracaoListRelationFilter>;
    anexos?: InputMaybe<CedenteAnexosListRelationFilter>;
    assignorBankData?: InputMaybe<AssignorBankDataRelationFilter>;
    ativo?: InputMaybe<BoolFilter>;
    cadastro?: InputMaybe<CedenteCadastroRelationFilter>;
    cadastroId?: InputMaybe<IntNullableFilter>;
    cnae?: InputMaybe<CnaeClassesRelationFilter>;
    cnpj?: InputMaybe<StringFilter>;
    codigoCnae?: InputMaybe<IntNullableFilter>;
    contaBancId?: InputMaybe<IntNullableFilter>;
    contatos?: InputMaybe<CedenteContatoListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    digitalSignature?: InputMaybe<EnumDigitalSignatureNullableFilter>;
    email?: InputMaybe<StringNullableFilter>;
    endereco?: InputMaybe<EnderecoRelationFilter>;
    enderecoId?: InputMaybe<IntNullableFilter>;
    fone?: InputMaybe<StringNullableFilter>;
    histStatus?: InputMaybe<StatusOperacionalAuditListRelationFilter>;
    historico?: InputMaybe<CedenteAuditListRelationFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibancos?: InputMaybe<MinibancoListRelationFilter>;
    naoAntecipa?: InputMaybe<BoolFilter>;
    nomeFantasia?: InputMaybe<StringNullableFilter>;
    origem?: InputMaybe<EnumOrigemCedenteFilter>;
    pessoaFisica?: InputMaybe<BoolFilter>;
    razaoSocial?: InputMaybe<StringFilter>;
    recebiveis?: InputMaybe<RecebivelListRelationFilter>;
    representativeId?: InputMaybe<IntNullableFilter>;
    responsaveis?: InputMaybe<ResponsavelCedenteListRelationFilter>;
    situation?: InputMaybe<BoolFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoListRelationFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CedenteWhereUniqueInput = {
    cadastroId?: InputMaybe<Scalars["Int"]>;
    cnpj?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
};

/** @view */
export type CedentesInfo = {
    __typename?: "CedentesInfo";
    cedente: Cedente;
    cedenteId: Scalars["Int"];
    cnpj: Scalars["String"];
    razaoSocial: Scalars["String"];
    status: CedenteStatus;
    taxaMedia: Scalars["Decimal"];
    valorOperado: Scalars["Decimal"];
};

export type CedentesInfoAvgAggregate = {
    __typename?: "CedentesInfoAvgAggregate";
    cedenteId?: Maybe<Scalars["Float"]>;
    taxaMedia?: Maybe<Scalars["Decimal"]>;
    valorOperado?: Maybe<Scalars["Decimal"]>;
};

export type CedentesInfoAvgOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    taxaMedia?: InputMaybe<SortOrder>;
    valorOperado?: InputMaybe<SortOrder>;
};

export type CedentesInfoCountAggregate = {
    __typename?: "CedentesInfoCountAggregate";
    _all: Scalars["Int"];
    cedenteId: Scalars["Int"];
    cnpj: Scalars["Int"];
    razaoSocial: Scalars["Int"];
    status: Scalars["Int"];
    taxaMedia: Scalars["Int"];
    valorOperado: Scalars["Int"];
};

export type CedentesInfoCountOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    cnpj?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    taxaMedia?: InputMaybe<SortOrder>;
    valorOperado?: InputMaybe<SortOrder>;
};

export type CedentesInfoCreateInput = {
    cedente: CedenteCreateNestedOneWithoutCedentesInfoInput;
    cnpj: Scalars["String"];
    razaoSocial: Scalars["String"];
    status: CedenteStatus;
    taxaMedia: Scalars["Decimal"];
    valorOperado: Scalars["Decimal"];
};

export type CedentesInfoCreateManyInput = {
    cedenteId: Scalars["Int"];
    cnpj: Scalars["String"];
    razaoSocial: Scalars["String"];
    status: CedenteStatus;
    taxaMedia: Scalars["Decimal"];
    valorOperado: Scalars["Decimal"];
};

export type CedentesInfoCreateNestedOneWithoutCedenteInput = {
    connect?: InputMaybe<CedentesInfoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedentesInfoCreateOrConnectWithoutCedenteInput>;
    create?: InputMaybe<CedentesInfoCreateWithoutCedenteInput>;
};

export type CedentesInfoCreateOrConnectWithoutCedenteInput = {
    create: CedentesInfoCreateWithoutCedenteInput;
    where: CedentesInfoWhereUniqueInput;
};

export type CedentesInfoCreateWithoutCedenteInput = {
    cnpj: Scalars["String"];
    razaoSocial: Scalars["String"];
    status: CedenteStatus;
    taxaMedia: Scalars["Decimal"];
    valorOperado: Scalars["Decimal"];
};

export type CedentesInfoGroupBy = {
    __typename?: "CedentesInfoGroupBy";
    _avg?: Maybe<CedentesInfoAvgAggregate>;
    _count?: Maybe<CedentesInfoCountAggregate>;
    _max?: Maybe<CedentesInfoMaxAggregate>;
    _min?: Maybe<CedentesInfoMinAggregate>;
    _sum?: Maybe<CedentesInfoSumAggregate>;
    cedenteId: Scalars["Int"];
    cnpj: Scalars["String"];
    razaoSocial: Scalars["String"];
    status: CedenteStatus;
    taxaMedia: Scalars["Decimal"];
    valorOperado: Scalars["Decimal"];
};

export type CedentesInfoMaxAggregate = {
    __typename?: "CedentesInfoMaxAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    cnpj?: Maybe<Scalars["String"]>;
    razaoSocial?: Maybe<Scalars["String"]>;
    status?: Maybe<CedenteStatus>;
    taxaMedia?: Maybe<Scalars["Decimal"]>;
    valorOperado?: Maybe<Scalars["Decimal"]>;
};

export type CedentesInfoMaxOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    cnpj?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    taxaMedia?: InputMaybe<SortOrder>;
    valorOperado?: InputMaybe<SortOrder>;
};

export type CedentesInfoMinAggregate = {
    __typename?: "CedentesInfoMinAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    cnpj?: Maybe<Scalars["String"]>;
    razaoSocial?: Maybe<Scalars["String"]>;
    status?: Maybe<CedenteStatus>;
    taxaMedia?: Maybe<Scalars["Decimal"]>;
    valorOperado?: Maybe<Scalars["Decimal"]>;
};

export type CedentesInfoMinOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    cnpj?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    taxaMedia?: InputMaybe<SortOrder>;
    valorOperado?: InputMaybe<SortOrder>;
};

export type CedentesInfoOrderByWithAggregationInput = {
    _avg?: InputMaybe<CedentesInfoAvgOrderByAggregateInput>;
    _count?: InputMaybe<CedentesInfoCountOrderByAggregateInput>;
    _max?: InputMaybe<CedentesInfoMaxOrderByAggregateInput>;
    _min?: InputMaybe<CedentesInfoMinOrderByAggregateInput>;
    _sum?: InputMaybe<CedentesInfoSumOrderByAggregateInput>;
    cedenteId?: InputMaybe<SortOrder>;
    cnpj?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    taxaMedia?: InputMaybe<SortOrder>;
    valorOperado?: InputMaybe<SortOrder>;
};

export type CedentesInfoOrderByWithRelationInput = {
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    cnpj?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    taxaMedia?: InputMaybe<SortOrder>;
    valorOperado?: InputMaybe<SortOrder>;
};

export type CedentesInfoRelationFilter = {
    is?: InputMaybe<CedentesInfoWhereInput>;
    isNot?: InputMaybe<CedentesInfoWhereInput>;
};

export enum CedentesInfoScalarFieldEnum {
    CedenteId = "cedenteId",
    Cnpj = "cnpj",
    RazaoSocial = "razaoSocial",
    Status = "status",
    TaxaMedia = "taxaMedia",
    ValorOperado = "valorOperado",
}

export type CedentesInfoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CedentesInfoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CedentesInfoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CedentesInfoScalarWhereWithAggregatesInput>>;
    cedenteId?: InputMaybe<IntWithAggregatesFilter>;
    cnpj?: InputMaybe<StringWithAggregatesFilter>;
    razaoSocial?: InputMaybe<StringWithAggregatesFilter>;
    status?: InputMaybe<EnumCedenteStatusWithAggregatesFilter>;
    taxaMedia?: InputMaybe<DecimalWithAggregatesFilter>;
    valorOperado?: InputMaybe<DecimalWithAggregatesFilter>;
};

export type CedentesInfoSumAggregate = {
    __typename?: "CedentesInfoSumAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    taxaMedia?: Maybe<Scalars["Decimal"]>;
    valorOperado?: Maybe<Scalars["Decimal"]>;
};

export type CedentesInfoSumOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    taxaMedia?: InputMaybe<SortOrder>;
    valorOperado?: InputMaybe<SortOrder>;
};

export type CedentesInfoUpdateInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutCedentesInfoNestedInput>;
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    taxaMedia?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    valorOperado?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type CedentesInfoUpdateManyMutationInput = {
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    taxaMedia?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    valorOperado?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type CedentesInfoUpdateOneWithoutCedenteNestedInput = {
    connect?: InputMaybe<CedentesInfoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CedentesInfoCreateOrConnectWithoutCedenteInput>;
    create?: InputMaybe<CedentesInfoCreateWithoutCedenteInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<CedentesInfoUpdateWithoutCedenteInput>;
    upsert?: InputMaybe<CedentesInfoUpsertWithoutCedenteInput>;
};

export type CedentesInfoUpdateWithoutCedenteInput = {
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    taxaMedia?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    valorOperado?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type CedentesInfoUpsertWithoutCedenteInput = {
    create: CedentesInfoCreateWithoutCedenteInput;
    update: CedentesInfoUpdateWithoutCedenteInput;
};

export type CedentesInfoWhereInput = {
    AND?: InputMaybe<Array<CedentesInfoWhereInput>>;
    NOT?: InputMaybe<Array<CedentesInfoWhereInput>>;
    OR?: InputMaybe<Array<CedentesInfoWhereInput>>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    cnpj?: InputMaybe<StringFilter>;
    razaoSocial?: InputMaybe<StringFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    taxaMedia?: InputMaybe<DecimalFilter>;
    valorOperado?: InputMaybe<DecimalFilter>;
};

export type CedentesInfoWhereUniqueInput = {
    cedenteId?: InputMaybe<Scalars["Int"]>;
    cnpj?: InputMaybe<Scalars["String"]>;
};

export type CnaeClasses = {
    __typename?: "CnaeClasses";
    _count?: Maybe<CnaeClassesCount>;
    cedentes: Array<Cedente>;
    codigo: Scalars["Int"];
    codigoGrupo?: Maybe<Scalars["Int"]>;
    descricao: Scalars["String"];
    grupo?: Maybe<CnaeGrupos>;
    subclasses: Array<CnaeSubClasses>;
};

export type CnaeClassesCedentesArgs = {
    cursor?: InputMaybe<CedenteWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteWhereInput>;
};

export type CnaeClassesSubclassesArgs = {
    cursor?: InputMaybe<CnaeSubClassesWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeSubClassesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeSubClassesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeSubClassesWhereInput>;
};

export type CnaeClassesAvgAggregate = {
    __typename?: "CnaeClassesAvgAggregate";
    codigo?: Maybe<Scalars["Float"]>;
    codigoGrupo?: Maybe<Scalars["Float"]>;
};

export type CnaeClassesAvgOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoGrupo?: InputMaybe<SortOrder>;
};

export type CnaeClassesCount = {
    __typename?: "CnaeClassesCount";
    cedentes: Scalars["Int"];
    subclasses: Scalars["Int"];
};

export type CnaeClassesCountAggregate = {
    __typename?: "CnaeClassesCountAggregate";
    _all: Scalars["Int"];
    codigo: Scalars["Int"];
    codigoGrupo: Scalars["Int"];
    descricao: Scalars["Int"];
};

export type CnaeClassesCountOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoGrupo?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeClassesCreateInput = {
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutCnaeInput>;
    codigo: Scalars["Int"];
    descricao: Scalars["String"];
    grupo?: InputMaybe<CnaeGruposCreateNestedOneWithoutClassesInput>;
    subclasses?: InputMaybe<CnaeSubClassesCreateNestedManyWithoutClasseInput>;
};

export type CnaeClassesCreateManyGrupoInput = {
    codigo: Scalars["Int"];
    descricao: Scalars["String"];
};

export type CnaeClassesCreateManyGrupoInputEnvelope = {
    data: Array<CnaeClassesCreateManyGrupoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CnaeClassesCreateManyInput = {
    codigo: Scalars["Int"];
    codigoGrupo?: InputMaybe<Scalars["Int"]>;
    descricao: Scalars["String"];
};

export type CnaeClassesCreateNestedManyWithoutGrupoInput = {
    connect?: InputMaybe<Array<CnaeClassesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CnaeClassesCreateOrConnectWithoutGrupoInput>>;
    create?: InputMaybe<Array<CnaeClassesCreateWithoutGrupoInput>>;
    createMany?: InputMaybe<CnaeClassesCreateManyGrupoInputEnvelope>;
};

export type CnaeClassesCreateNestedOneWithoutCedentesInput = {
    connect?: InputMaybe<CnaeClassesWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CnaeClassesCreateOrConnectWithoutCedentesInput>;
    create?: InputMaybe<CnaeClassesCreateWithoutCedentesInput>;
};

export type CnaeClassesCreateNestedOneWithoutSubclassesInput = {
    connect?: InputMaybe<CnaeClassesWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CnaeClassesCreateOrConnectWithoutSubclassesInput>;
    create?: InputMaybe<CnaeClassesCreateWithoutSubclassesInput>;
};

export type CnaeClassesCreateOrConnectWithoutCedentesInput = {
    create: CnaeClassesCreateWithoutCedentesInput;
    where: CnaeClassesWhereUniqueInput;
};

export type CnaeClassesCreateOrConnectWithoutGrupoInput = {
    create: CnaeClassesCreateWithoutGrupoInput;
    where: CnaeClassesWhereUniqueInput;
};

export type CnaeClassesCreateOrConnectWithoutSubclassesInput = {
    create: CnaeClassesCreateWithoutSubclassesInput;
    where: CnaeClassesWhereUniqueInput;
};

export type CnaeClassesCreateWithoutCedentesInput = {
    codigo: Scalars["Int"];
    descricao: Scalars["String"];
    grupo?: InputMaybe<CnaeGruposCreateNestedOneWithoutClassesInput>;
    subclasses?: InputMaybe<CnaeSubClassesCreateNestedManyWithoutClasseInput>;
};

export type CnaeClassesCreateWithoutGrupoInput = {
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutCnaeInput>;
    codigo: Scalars["Int"];
    descricao: Scalars["String"];
    subclasses?: InputMaybe<CnaeSubClassesCreateNestedManyWithoutClasseInput>;
};

export type CnaeClassesCreateWithoutSubclassesInput = {
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutCnaeInput>;
    codigo: Scalars["Int"];
    descricao: Scalars["String"];
    grupo?: InputMaybe<CnaeGruposCreateNestedOneWithoutClassesInput>;
};

export type CnaeClassesGroupBy = {
    __typename?: "CnaeClassesGroupBy";
    _avg?: Maybe<CnaeClassesAvgAggregate>;
    _count?: Maybe<CnaeClassesCountAggregate>;
    _max?: Maybe<CnaeClassesMaxAggregate>;
    _min?: Maybe<CnaeClassesMinAggregate>;
    _sum?: Maybe<CnaeClassesSumAggregate>;
    codigo: Scalars["Int"];
    codigoGrupo?: Maybe<Scalars["Int"]>;
    descricao: Scalars["String"];
};

export type CnaeClassesListRelationFilter = {
    every?: InputMaybe<CnaeClassesWhereInput>;
    none?: InputMaybe<CnaeClassesWhereInput>;
    some?: InputMaybe<CnaeClassesWhereInput>;
};

export type CnaeClassesMaxAggregate = {
    __typename?: "CnaeClassesMaxAggregate";
    codigo?: Maybe<Scalars["Int"]>;
    codigoGrupo?: Maybe<Scalars["Int"]>;
    descricao?: Maybe<Scalars["String"]>;
};

export type CnaeClassesMaxOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoGrupo?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeClassesMinAggregate = {
    __typename?: "CnaeClassesMinAggregate";
    codigo?: Maybe<Scalars["Int"]>;
    codigoGrupo?: Maybe<Scalars["Int"]>;
    descricao?: Maybe<Scalars["String"]>;
};

export type CnaeClassesMinOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoGrupo?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeClassesOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type CnaeClassesOrderByWithAggregationInput = {
    _avg?: InputMaybe<CnaeClassesAvgOrderByAggregateInput>;
    _count?: InputMaybe<CnaeClassesCountOrderByAggregateInput>;
    _max?: InputMaybe<CnaeClassesMaxOrderByAggregateInput>;
    _min?: InputMaybe<CnaeClassesMinOrderByAggregateInput>;
    _sum?: InputMaybe<CnaeClassesSumOrderByAggregateInput>;
    codigo?: InputMaybe<SortOrder>;
    codigoGrupo?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeClassesOrderByWithRelationInput = {
    cedentes?: InputMaybe<CedenteOrderByRelationAggregateInput>;
    codigo?: InputMaybe<SortOrder>;
    codigoGrupo?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    grupo?: InputMaybe<CnaeGruposOrderByWithRelationInput>;
    subclasses?: InputMaybe<CnaeSubClassesOrderByRelationAggregateInput>;
};

export type CnaeClassesRelationFilter = {
    is?: InputMaybe<CnaeClassesWhereInput>;
    isNot?: InputMaybe<CnaeClassesWhereInput>;
};

export enum CnaeClassesScalarFieldEnum {
    Codigo = "codigo",
    CodigoGrupo = "codigoGrupo",
    Descricao = "descricao",
}

export type CnaeClassesScalarWhereInput = {
    AND?: InputMaybe<Array<CnaeClassesScalarWhereInput>>;
    NOT?: InputMaybe<Array<CnaeClassesScalarWhereInput>>;
    OR?: InputMaybe<Array<CnaeClassesScalarWhereInput>>;
    codigo?: InputMaybe<IntFilter>;
    codigoGrupo?: InputMaybe<IntNullableFilter>;
    descricao?: InputMaybe<StringFilter>;
};

export type CnaeClassesScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CnaeClassesScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CnaeClassesScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CnaeClassesScalarWhereWithAggregatesInput>>;
    codigo?: InputMaybe<IntWithAggregatesFilter>;
    codigoGrupo?: InputMaybe<IntNullableWithAggregatesFilter>;
    descricao?: InputMaybe<StringWithAggregatesFilter>;
};

export type CnaeClassesSumAggregate = {
    __typename?: "CnaeClassesSumAggregate";
    codigo?: Maybe<Scalars["Int"]>;
    codigoGrupo?: Maybe<Scalars["Int"]>;
};

export type CnaeClassesSumOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoGrupo?: InputMaybe<SortOrder>;
};

export type CnaeClassesUpdateInput = {
    cedentes?: InputMaybe<CedenteUpdateManyWithoutCnaeNestedInput>;
    codigo?: InputMaybe<IntFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    grupo?: InputMaybe<CnaeGruposUpdateOneWithoutClassesNestedInput>;
    subclasses?: InputMaybe<CnaeSubClassesUpdateManyWithoutClasseNestedInput>;
};

export type CnaeClassesUpdateManyMutationInput = {
    codigo?: InputMaybe<IntFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CnaeClassesUpdateManyWithWhereWithoutGrupoInput = {
    data: CnaeClassesUpdateManyMutationInput;
    where: CnaeClassesScalarWhereInput;
};

export type CnaeClassesUpdateManyWithoutGrupoNestedInput = {
    connect?: InputMaybe<Array<CnaeClassesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CnaeClassesCreateOrConnectWithoutGrupoInput>>;
    create?: InputMaybe<Array<CnaeClassesCreateWithoutGrupoInput>>;
    createMany?: InputMaybe<CnaeClassesCreateManyGrupoInputEnvelope>;
    delete?: InputMaybe<Array<CnaeClassesWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CnaeClassesScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CnaeClassesWhereUniqueInput>>;
    set?: InputMaybe<Array<CnaeClassesWhereUniqueInput>>;
    update?: InputMaybe<Array<CnaeClassesUpdateWithWhereUniqueWithoutGrupoInput>>;
    updateMany?: InputMaybe<Array<CnaeClassesUpdateManyWithWhereWithoutGrupoInput>>;
    upsert?: InputMaybe<Array<CnaeClassesUpsertWithWhereUniqueWithoutGrupoInput>>;
};

export type CnaeClassesUpdateOneRequiredWithoutSubclassesNestedInput = {
    connect?: InputMaybe<CnaeClassesWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CnaeClassesCreateOrConnectWithoutSubclassesInput>;
    create?: InputMaybe<CnaeClassesCreateWithoutSubclassesInput>;
    update?: InputMaybe<CnaeClassesUpdateWithoutSubclassesInput>;
    upsert?: InputMaybe<CnaeClassesUpsertWithoutSubclassesInput>;
};

export type CnaeClassesUpdateOneWithoutCedentesNestedInput = {
    connect?: InputMaybe<CnaeClassesWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CnaeClassesCreateOrConnectWithoutCedentesInput>;
    create?: InputMaybe<CnaeClassesCreateWithoutCedentesInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<CnaeClassesUpdateWithoutCedentesInput>;
    upsert?: InputMaybe<CnaeClassesUpsertWithoutCedentesInput>;
};

export type CnaeClassesUpdateWithWhereUniqueWithoutGrupoInput = {
    data: CnaeClassesUpdateWithoutGrupoInput;
    where: CnaeClassesWhereUniqueInput;
};

export type CnaeClassesUpdateWithoutCedentesInput = {
    codigo?: InputMaybe<IntFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    grupo?: InputMaybe<CnaeGruposUpdateOneWithoutClassesNestedInput>;
    subclasses?: InputMaybe<CnaeSubClassesUpdateManyWithoutClasseNestedInput>;
};

export type CnaeClassesUpdateWithoutGrupoInput = {
    cedentes?: InputMaybe<CedenteUpdateManyWithoutCnaeNestedInput>;
    codigo?: InputMaybe<IntFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    subclasses?: InputMaybe<CnaeSubClassesUpdateManyWithoutClasseNestedInput>;
};

export type CnaeClassesUpdateWithoutSubclassesInput = {
    cedentes?: InputMaybe<CedenteUpdateManyWithoutCnaeNestedInput>;
    codigo?: InputMaybe<IntFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    grupo?: InputMaybe<CnaeGruposUpdateOneWithoutClassesNestedInput>;
};

export type CnaeClassesUpsertWithWhereUniqueWithoutGrupoInput = {
    create: CnaeClassesCreateWithoutGrupoInput;
    update: CnaeClassesUpdateWithoutGrupoInput;
    where: CnaeClassesWhereUniqueInput;
};

export type CnaeClassesUpsertWithoutCedentesInput = {
    create: CnaeClassesCreateWithoutCedentesInput;
    update: CnaeClassesUpdateWithoutCedentesInput;
};

export type CnaeClassesUpsertWithoutSubclassesInput = {
    create: CnaeClassesCreateWithoutSubclassesInput;
    update: CnaeClassesUpdateWithoutSubclassesInput;
};

export type CnaeClassesWhereInput = {
    AND?: InputMaybe<Array<CnaeClassesWhereInput>>;
    NOT?: InputMaybe<Array<CnaeClassesWhereInput>>;
    OR?: InputMaybe<Array<CnaeClassesWhereInput>>;
    cedentes?: InputMaybe<CedenteListRelationFilter>;
    codigo?: InputMaybe<IntFilter>;
    codigoGrupo?: InputMaybe<IntNullableFilter>;
    descricao?: InputMaybe<StringFilter>;
    grupo?: InputMaybe<CnaeGruposRelationFilter>;
    subclasses?: InputMaybe<CnaeSubClassesListRelationFilter>;
};

export type CnaeClassesWhereUniqueInput = {
    codigo?: InputMaybe<Scalars["Int"]>;
};

export type CnaeDivisoes = {
    __typename?: "CnaeDivisoes";
    _count?: Maybe<CnaeDivisoesCount>;
    codigo: Scalars["Int"];
    codigoSecao: Scalars["String"];
    descricao: Scalars["String"];
    grupos: Array<CnaeGrupos>;
    secao: CnaeSecoes;
};

export type CnaeDivisoesGruposArgs = {
    cursor?: InputMaybe<CnaeGruposWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeGruposScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeGruposOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeGruposWhereInput>;
};

export type CnaeDivisoesAvgAggregate = {
    __typename?: "CnaeDivisoesAvgAggregate";
    codigo?: Maybe<Scalars["Float"]>;
};

export type CnaeDivisoesAvgOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
};

export type CnaeDivisoesCount = {
    __typename?: "CnaeDivisoesCount";
    grupos: Scalars["Int"];
};

export type CnaeDivisoesCountAggregate = {
    __typename?: "CnaeDivisoesCountAggregate";
    _all: Scalars["Int"];
    codigo: Scalars["Int"];
    codigoSecao: Scalars["Int"];
    descricao: Scalars["Int"];
};

export type CnaeDivisoesCountOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoSecao?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeDivisoesCreateInput = {
    codigo: Scalars["Int"];
    descricao: Scalars["String"];
    grupos?: InputMaybe<CnaeGruposCreateNestedManyWithoutDivisaoInput>;
    secao: CnaeSecoesCreateNestedOneWithoutDivisoesInput;
};

export type CnaeDivisoesCreateManyInput = {
    codigo: Scalars["Int"];
    codigoSecao: Scalars["String"];
    descricao: Scalars["String"];
};

export type CnaeDivisoesCreateManySecaoInput = {
    codigo: Scalars["Int"];
    descricao: Scalars["String"];
};

export type CnaeDivisoesCreateManySecaoInputEnvelope = {
    data: Array<CnaeDivisoesCreateManySecaoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CnaeDivisoesCreateNestedManyWithoutSecaoInput = {
    connect?: InputMaybe<Array<CnaeDivisoesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CnaeDivisoesCreateOrConnectWithoutSecaoInput>>;
    create?: InputMaybe<Array<CnaeDivisoesCreateWithoutSecaoInput>>;
    createMany?: InputMaybe<CnaeDivisoesCreateManySecaoInputEnvelope>;
};

export type CnaeDivisoesCreateNestedOneWithoutGruposInput = {
    connect?: InputMaybe<CnaeDivisoesWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CnaeDivisoesCreateOrConnectWithoutGruposInput>;
    create?: InputMaybe<CnaeDivisoesCreateWithoutGruposInput>;
};

export type CnaeDivisoesCreateOrConnectWithoutGruposInput = {
    create: CnaeDivisoesCreateWithoutGruposInput;
    where: CnaeDivisoesWhereUniqueInput;
};

export type CnaeDivisoesCreateOrConnectWithoutSecaoInput = {
    create: CnaeDivisoesCreateWithoutSecaoInput;
    where: CnaeDivisoesWhereUniqueInput;
};

export type CnaeDivisoesCreateWithoutGruposInput = {
    codigo: Scalars["Int"];
    descricao: Scalars["String"];
    secao: CnaeSecoesCreateNestedOneWithoutDivisoesInput;
};

export type CnaeDivisoesCreateWithoutSecaoInput = {
    codigo: Scalars["Int"];
    descricao: Scalars["String"];
    grupos?: InputMaybe<CnaeGruposCreateNestedManyWithoutDivisaoInput>;
};

export type CnaeDivisoesGroupBy = {
    __typename?: "CnaeDivisoesGroupBy";
    _avg?: Maybe<CnaeDivisoesAvgAggregate>;
    _count?: Maybe<CnaeDivisoesCountAggregate>;
    _max?: Maybe<CnaeDivisoesMaxAggregate>;
    _min?: Maybe<CnaeDivisoesMinAggregate>;
    _sum?: Maybe<CnaeDivisoesSumAggregate>;
    codigo: Scalars["Int"];
    codigoSecao: Scalars["String"];
    descricao: Scalars["String"];
};

export type CnaeDivisoesListRelationFilter = {
    every?: InputMaybe<CnaeDivisoesWhereInput>;
    none?: InputMaybe<CnaeDivisoesWhereInput>;
    some?: InputMaybe<CnaeDivisoesWhereInput>;
};

export type CnaeDivisoesMaxAggregate = {
    __typename?: "CnaeDivisoesMaxAggregate";
    codigo?: Maybe<Scalars["Int"]>;
    codigoSecao?: Maybe<Scalars["String"]>;
    descricao?: Maybe<Scalars["String"]>;
};

export type CnaeDivisoesMaxOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoSecao?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeDivisoesMinAggregate = {
    __typename?: "CnaeDivisoesMinAggregate";
    codigo?: Maybe<Scalars["Int"]>;
    codigoSecao?: Maybe<Scalars["String"]>;
    descricao?: Maybe<Scalars["String"]>;
};

export type CnaeDivisoesMinOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoSecao?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeDivisoesOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type CnaeDivisoesOrderByWithAggregationInput = {
    _avg?: InputMaybe<CnaeDivisoesAvgOrderByAggregateInput>;
    _count?: InputMaybe<CnaeDivisoesCountOrderByAggregateInput>;
    _max?: InputMaybe<CnaeDivisoesMaxOrderByAggregateInput>;
    _min?: InputMaybe<CnaeDivisoesMinOrderByAggregateInput>;
    _sum?: InputMaybe<CnaeDivisoesSumOrderByAggregateInput>;
    codigo?: InputMaybe<SortOrder>;
    codigoSecao?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeDivisoesOrderByWithRelationInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoSecao?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    grupos?: InputMaybe<CnaeGruposOrderByRelationAggregateInput>;
    secao?: InputMaybe<CnaeSecoesOrderByWithRelationInput>;
};

export type CnaeDivisoesRelationFilter = {
    is?: InputMaybe<CnaeDivisoesWhereInput>;
    isNot?: InputMaybe<CnaeDivisoesWhereInput>;
};

export enum CnaeDivisoesScalarFieldEnum {
    Codigo = "codigo",
    CodigoSecao = "codigoSecao",
    Descricao = "descricao",
}

export type CnaeDivisoesScalarWhereInput = {
    AND?: InputMaybe<Array<CnaeDivisoesScalarWhereInput>>;
    NOT?: InputMaybe<Array<CnaeDivisoesScalarWhereInput>>;
    OR?: InputMaybe<Array<CnaeDivisoesScalarWhereInput>>;
    codigo?: InputMaybe<IntFilter>;
    codigoSecao?: InputMaybe<StringFilter>;
    descricao?: InputMaybe<StringFilter>;
};

export type CnaeDivisoesScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CnaeDivisoesScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CnaeDivisoesScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CnaeDivisoesScalarWhereWithAggregatesInput>>;
    codigo?: InputMaybe<IntWithAggregatesFilter>;
    codigoSecao?: InputMaybe<StringWithAggregatesFilter>;
    descricao?: InputMaybe<StringWithAggregatesFilter>;
};

export type CnaeDivisoesSumAggregate = {
    __typename?: "CnaeDivisoesSumAggregate";
    codigo?: Maybe<Scalars["Int"]>;
};

export type CnaeDivisoesSumOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
};

export type CnaeDivisoesUpdateInput = {
    codigo?: InputMaybe<IntFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    grupos?: InputMaybe<CnaeGruposUpdateManyWithoutDivisaoNestedInput>;
    secao?: InputMaybe<CnaeSecoesUpdateOneRequiredWithoutDivisoesNestedInput>;
};

export type CnaeDivisoesUpdateManyMutationInput = {
    codigo?: InputMaybe<IntFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CnaeDivisoesUpdateManyWithWhereWithoutSecaoInput = {
    data: CnaeDivisoesUpdateManyMutationInput;
    where: CnaeDivisoesScalarWhereInput;
};

export type CnaeDivisoesUpdateManyWithoutSecaoNestedInput = {
    connect?: InputMaybe<Array<CnaeDivisoesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CnaeDivisoesCreateOrConnectWithoutSecaoInput>>;
    create?: InputMaybe<Array<CnaeDivisoesCreateWithoutSecaoInput>>;
    createMany?: InputMaybe<CnaeDivisoesCreateManySecaoInputEnvelope>;
    delete?: InputMaybe<Array<CnaeDivisoesWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CnaeDivisoesScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CnaeDivisoesWhereUniqueInput>>;
    set?: InputMaybe<Array<CnaeDivisoesWhereUniqueInput>>;
    update?: InputMaybe<Array<CnaeDivisoesUpdateWithWhereUniqueWithoutSecaoInput>>;
    updateMany?: InputMaybe<Array<CnaeDivisoesUpdateManyWithWhereWithoutSecaoInput>>;
    upsert?: InputMaybe<Array<CnaeDivisoesUpsertWithWhereUniqueWithoutSecaoInput>>;
};

export type CnaeDivisoesUpdateOneRequiredWithoutGruposNestedInput = {
    connect?: InputMaybe<CnaeDivisoesWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CnaeDivisoesCreateOrConnectWithoutGruposInput>;
    create?: InputMaybe<CnaeDivisoesCreateWithoutGruposInput>;
    update?: InputMaybe<CnaeDivisoesUpdateWithoutGruposInput>;
    upsert?: InputMaybe<CnaeDivisoesUpsertWithoutGruposInput>;
};

export type CnaeDivisoesUpdateWithWhereUniqueWithoutSecaoInput = {
    data: CnaeDivisoesUpdateWithoutSecaoInput;
    where: CnaeDivisoesWhereUniqueInput;
};

export type CnaeDivisoesUpdateWithoutGruposInput = {
    codigo?: InputMaybe<IntFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    secao?: InputMaybe<CnaeSecoesUpdateOneRequiredWithoutDivisoesNestedInput>;
};

export type CnaeDivisoesUpdateWithoutSecaoInput = {
    codigo?: InputMaybe<IntFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    grupos?: InputMaybe<CnaeGruposUpdateManyWithoutDivisaoNestedInput>;
};

export type CnaeDivisoesUpsertWithWhereUniqueWithoutSecaoInput = {
    create: CnaeDivisoesCreateWithoutSecaoInput;
    update: CnaeDivisoesUpdateWithoutSecaoInput;
    where: CnaeDivisoesWhereUniqueInput;
};

export type CnaeDivisoesUpsertWithoutGruposInput = {
    create: CnaeDivisoesCreateWithoutGruposInput;
    update: CnaeDivisoesUpdateWithoutGruposInput;
};

export type CnaeDivisoesWhereInput = {
    AND?: InputMaybe<Array<CnaeDivisoesWhereInput>>;
    NOT?: InputMaybe<Array<CnaeDivisoesWhereInput>>;
    OR?: InputMaybe<Array<CnaeDivisoesWhereInput>>;
    codigo?: InputMaybe<IntFilter>;
    codigoSecao?: InputMaybe<StringFilter>;
    descricao?: InputMaybe<StringFilter>;
    grupos?: InputMaybe<CnaeGruposListRelationFilter>;
    secao?: InputMaybe<CnaeSecoesRelationFilter>;
};

export type CnaeDivisoesWhereUniqueInput = {
    codigo?: InputMaybe<Scalars["Int"]>;
};

export type CnaeGrupos = {
    __typename?: "CnaeGrupos";
    _count?: Maybe<CnaeGruposCount>;
    classes: Array<CnaeClasses>;
    codigo: Scalars["Int"];
    codigoDivisao: Scalars["Int"];
    descricao: Scalars["String"];
    divisao: CnaeDivisoes;
};

export type CnaeGruposClassesArgs = {
    cursor?: InputMaybe<CnaeClassesWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeClassesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeClassesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeClassesWhereInput>;
};

export type CnaeGruposAvgAggregate = {
    __typename?: "CnaeGruposAvgAggregate";
    codigo?: Maybe<Scalars["Float"]>;
    codigoDivisao?: Maybe<Scalars["Float"]>;
};

export type CnaeGruposAvgOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoDivisao?: InputMaybe<SortOrder>;
};

export type CnaeGruposCount = {
    __typename?: "CnaeGruposCount";
    classes: Scalars["Int"];
};

export type CnaeGruposCountAggregate = {
    __typename?: "CnaeGruposCountAggregate";
    _all: Scalars["Int"];
    codigo: Scalars["Int"];
    codigoDivisao: Scalars["Int"];
    descricao: Scalars["Int"];
};

export type CnaeGruposCountOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoDivisao?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeGruposCreateInput = {
    classes?: InputMaybe<CnaeClassesCreateNestedManyWithoutGrupoInput>;
    codigo: Scalars["Int"];
    descricao: Scalars["String"];
    divisao: CnaeDivisoesCreateNestedOneWithoutGruposInput;
};

export type CnaeGruposCreateManyDivisaoInput = {
    codigo: Scalars["Int"];
    descricao: Scalars["String"];
};

export type CnaeGruposCreateManyDivisaoInputEnvelope = {
    data: Array<CnaeGruposCreateManyDivisaoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CnaeGruposCreateManyInput = {
    codigo: Scalars["Int"];
    codigoDivisao: Scalars["Int"];
    descricao: Scalars["String"];
};

export type CnaeGruposCreateNestedManyWithoutDivisaoInput = {
    connect?: InputMaybe<Array<CnaeGruposWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CnaeGruposCreateOrConnectWithoutDivisaoInput>>;
    create?: InputMaybe<Array<CnaeGruposCreateWithoutDivisaoInput>>;
    createMany?: InputMaybe<CnaeGruposCreateManyDivisaoInputEnvelope>;
};

export type CnaeGruposCreateNestedOneWithoutClassesInput = {
    connect?: InputMaybe<CnaeGruposWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CnaeGruposCreateOrConnectWithoutClassesInput>;
    create?: InputMaybe<CnaeGruposCreateWithoutClassesInput>;
};

export type CnaeGruposCreateOrConnectWithoutClassesInput = {
    create: CnaeGruposCreateWithoutClassesInput;
    where: CnaeGruposWhereUniqueInput;
};

export type CnaeGruposCreateOrConnectWithoutDivisaoInput = {
    create: CnaeGruposCreateWithoutDivisaoInput;
    where: CnaeGruposWhereUniqueInput;
};

export type CnaeGruposCreateWithoutClassesInput = {
    codigo: Scalars["Int"];
    descricao: Scalars["String"];
    divisao: CnaeDivisoesCreateNestedOneWithoutGruposInput;
};

export type CnaeGruposCreateWithoutDivisaoInput = {
    classes?: InputMaybe<CnaeClassesCreateNestedManyWithoutGrupoInput>;
    codigo: Scalars["Int"];
    descricao: Scalars["String"];
};

export type CnaeGruposGroupBy = {
    __typename?: "CnaeGruposGroupBy";
    _avg?: Maybe<CnaeGruposAvgAggregate>;
    _count?: Maybe<CnaeGruposCountAggregate>;
    _max?: Maybe<CnaeGruposMaxAggregate>;
    _min?: Maybe<CnaeGruposMinAggregate>;
    _sum?: Maybe<CnaeGruposSumAggregate>;
    codigo: Scalars["Int"];
    codigoDivisao: Scalars["Int"];
    descricao: Scalars["String"];
};

export type CnaeGruposListRelationFilter = {
    every?: InputMaybe<CnaeGruposWhereInput>;
    none?: InputMaybe<CnaeGruposWhereInput>;
    some?: InputMaybe<CnaeGruposWhereInput>;
};

export type CnaeGruposMaxAggregate = {
    __typename?: "CnaeGruposMaxAggregate";
    codigo?: Maybe<Scalars["Int"]>;
    codigoDivisao?: Maybe<Scalars["Int"]>;
    descricao?: Maybe<Scalars["String"]>;
};

export type CnaeGruposMaxOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoDivisao?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeGruposMinAggregate = {
    __typename?: "CnaeGruposMinAggregate";
    codigo?: Maybe<Scalars["Int"]>;
    codigoDivisao?: Maybe<Scalars["Int"]>;
    descricao?: Maybe<Scalars["String"]>;
};

export type CnaeGruposMinOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoDivisao?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeGruposOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type CnaeGruposOrderByWithAggregationInput = {
    _avg?: InputMaybe<CnaeGruposAvgOrderByAggregateInput>;
    _count?: InputMaybe<CnaeGruposCountOrderByAggregateInput>;
    _max?: InputMaybe<CnaeGruposMaxOrderByAggregateInput>;
    _min?: InputMaybe<CnaeGruposMinOrderByAggregateInput>;
    _sum?: InputMaybe<CnaeGruposSumOrderByAggregateInput>;
    codigo?: InputMaybe<SortOrder>;
    codigoDivisao?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeGruposOrderByWithRelationInput = {
    classes?: InputMaybe<CnaeClassesOrderByRelationAggregateInput>;
    codigo?: InputMaybe<SortOrder>;
    codigoDivisao?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    divisao?: InputMaybe<CnaeDivisoesOrderByWithRelationInput>;
};

export type CnaeGruposRelationFilter = {
    is?: InputMaybe<CnaeGruposWhereInput>;
    isNot?: InputMaybe<CnaeGruposWhereInput>;
};

export enum CnaeGruposScalarFieldEnum {
    Codigo = "codigo",
    CodigoDivisao = "codigoDivisao",
    Descricao = "descricao",
}

export type CnaeGruposScalarWhereInput = {
    AND?: InputMaybe<Array<CnaeGruposScalarWhereInput>>;
    NOT?: InputMaybe<Array<CnaeGruposScalarWhereInput>>;
    OR?: InputMaybe<Array<CnaeGruposScalarWhereInput>>;
    codigo?: InputMaybe<IntFilter>;
    codigoDivisao?: InputMaybe<IntFilter>;
    descricao?: InputMaybe<StringFilter>;
};

export type CnaeGruposScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CnaeGruposScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CnaeGruposScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CnaeGruposScalarWhereWithAggregatesInput>>;
    codigo?: InputMaybe<IntWithAggregatesFilter>;
    codigoDivisao?: InputMaybe<IntWithAggregatesFilter>;
    descricao?: InputMaybe<StringWithAggregatesFilter>;
};

export type CnaeGruposSumAggregate = {
    __typename?: "CnaeGruposSumAggregate";
    codigo?: Maybe<Scalars["Int"]>;
    codigoDivisao?: Maybe<Scalars["Int"]>;
};

export type CnaeGruposSumOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    codigoDivisao?: InputMaybe<SortOrder>;
};

export type CnaeGruposUpdateInput = {
    classes?: InputMaybe<CnaeClassesUpdateManyWithoutGrupoNestedInput>;
    codigo?: InputMaybe<IntFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    divisao?: InputMaybe<CnaeDivisoesUpdateOneRequiredWithoutGruposNestedInput>;
};

export type CnaeGruposUpdateManyMutationInput = {
    codigo?: InputMaybe<IntFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CnaeGruposUpdateManyWithWhereWithoutDivisaoInput = {
    data: CnaeGruposUpdateManyMutationInput;
    where: CnaeGruposScalarWhereInput;
};

export type CnaeGruposUpdateManyWithoutDivisaoNestedInput = {
    connect?: InputMaybe<Array<CnaeGruposWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CnaeGruposCreateOrConnectWithoutDivisaoInput>>;
    create?: InputMaybe<Array<CnaeGruposCreateWithoutDivisaoInput>>;
    createMany?: InputMaybe<CnaeGruposCreateManyDivisaoInputEnvelope>;
    delete?: InputMaybe<Array<CnaeGruposWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CnaeGruposScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CnaeGruposWhereUniqueInput>>;
    set?: InputMaybe<Array<CnaeGruposWhereUniqueInput>>;
    update?: InputMaybe<Array<CnaeGruposUpdateWithWhereUniqueWithoutDivisaoInput>>;
    updateMany?: InputMaybe<Array<CnaeGruposUpdateManyWithWhereWithoutDivisaoInput>>;
    upsert?: InputMaybe<Array<CnaeGruposUpsertWithWhereUniqueWithoutDivisaoInput>>;
};

export type CnaeGruposUpdateOneWithoutClassesNestedInput = {
    connect?: InputMaybe<CnaeGruposWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CnaeGruposCreateOrConnectWithoutClassesInput>;
    create?: InputMaybe<CnaeGruposCreateWithoutClassesInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<CnaeGruposUpdateWithoutClassesInput>;
    upsert?: InputMaybe<CnaeGruposUpsertWithoutClassesInput>;
};

export type CnaeGruposUpdateWithWhereUniqueWithoutDivisaoInput = {
    data: CnaeGruposUpdateWithoutDivisaoInput;
    where: CnaeGruposWhereUniqueInput;
};

export type CnaeGruposUpdateWithoutClassesInput = {
    codigo?: InputMaybe<IntFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    divisao?: InputMaybe<CnaeDivisoesUpdateOneRequiredWithoutGruposNestedInput>;
};

export type CnaeGruposUpdateWithoutDivisaoInput = {
    classes?: InputMaybe<CnaeClassesUpdateManyWithoutGrupoNestedInput>;
    codigo?: InputMaybe<IntFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CnaeGruposUpsertWithWhereUniqueWithoutDivisaoInput = {
    create: CnaeGruposCreateWithoutDivisaoInput;
    update: CnaeGruposUpdateWithoutDivisaoInput;
    where: CnaeGruposWhereUniqueInput;
};

export type CnaeGruposUpsertWithoutClassesInput = {
    create: CnaeGruposCreateWithoutClassesInput;
    update: CnaeGruposUpdateWithoutClassesInput;
};

export type CnaeGruposWhereInput = {
    AND?: InputMaybe<Array<CnaeGruposWhereInput>>;
    NOT?: InputMaybe<Array<CnaeGruposWhereInput>>;
    OR?: InputMaybe<Array<CnaeGruposWhereInput>>;
    classes?: InputMaybe<CnaeClassesListRelationFilter>;
    codigo?: InputMaybe<IntFilter>;
    codigoDivisao?: InputMaybe<IntFilter>;
    descricao?: InputMaybe<StringFilter>;
    divisao?: InputMaybe<CnaeDivisoesRelationFilter>;
};

export type CnaeGruposWhereUniqueInput = {
    codigo?: InputMaybe<Scalars["Int"]>;
};

export type CnaeSecoes = {
    __typename?: "CnaeSecoes";
    _count?: Maybe<CnaeSecoesCount>;
    codigo: Scalars["String"];
    descricao: Scalars["String"];
    divisoes: Array<CnaeDivisoes>;
};

export type CnaeSecoesDivisoesArgs = {
    cursor?: InputMaybe<CnaeDivisoesWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeDivisoesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeDivisoesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeDivisoesWhereInput>;
};

export type CnaeSecoesCount = {
    __typename?: "CnaeSecoesCount";
    divisoes: Scalars["Int"];
};

export type CnaeSecoesCountAggregate = {
    __typename?: "CnaeSecoesCountAggregate";
    _all: Scalars["Int"];
    codigo: Scalars["Int"];
    descricao: Scalars["Int"];
};

export type CnaeSecoesCountOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeSecoesCreateInput = {
    codigo: Scalars["String"];
    descricao: Scalars["String"];
    divisoes?: InputMaybe<CnaeDivisoesCreateNestedManyWithoutSecaoInput>;
};

export type CnaeSecoesCreateManyInput = {
    codigo: Scalars["String"];
    descricao: Scalars["String"];
};

export type CnaeSecoesCreateNestedOneWithoutDivisoesInput = {
    connect?: InputMaybe<CnaeSecoesWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CnaeSecoesCreateOrConnectWithoutDivisoesInput>;
    create?: InputMaybe<CnaeSecoesCreateWithoutDivisoesInput>;
};

export type CnaeSecoesCreateOrConnectWithoutDivisoesInput = {
    create: CnaeSecoesCreateWithoutDivisoesInput;
    where: CnaeSecoesWhereUniqueInput;
};

export type CnaeSecoesCreateWithoutDivisoesInput = {
    codigo: Scalars["String"];
    descricao: Scalars["String"];
};

export type CnaeSecoesGroupBy = {
    __typename?: "CnaeSecoesGroupBy";
    _count?: Maybe<CnaeSecoesCountAggregate>;
    _max?: Maybe<CnaeSecoesMaxAggregate>;
    _min?: Maybe<CnaeSecoesMinAggregate>;
    codigo: Scalars["String"];
    descricao: Scalars["String"];
};

export type CnaeSecoesMaxAggregate = {
    __typename?: "CnaeSecoesMaxAggregate";
    codigo?: Maybe<Scalars["String"]>;
    descricao?: Maybe<Scalars["String"]>;
};

export type CnaeSecoesMaxOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeSecoesMinAggregate = {
    __typename?: "CnaeSecoesMinAggregate";
    codigo?: Maybe<Scalars["String"]>;
    descricao?: Maybe<Scalars["String"]>;
};

export type CnaeSecoesMinOrderByAggregateInput = {
    codigo?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeSecoesOrderByWithAggregationInput = {
    _count?: InputMaybe<CnaeSecoesCountOrderByAggregateInput>;
    _max?: InputMaybe<CnaeSecoesMaxOrderByAggregateInput>;
    _min?: InputMaybe<CnaeSecoesMinOrderByAggregateInput>;
    codigo?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
};

export type CnaeSecoesOrderByWithRelationInput = {
    codigo?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    divisoes?: InputMaybe<CnaeDivisoesOrderByRelationAggregateInput>;
};

export type CnaeSecoesRelationFilter = {
    is?: InputMaybe<CnaeSecoesWhereInput>;
    isNot?: InputMaybe<CnaeSecoesWhereInput>;
};

export enum CnaeSecoesScalarFieldEnum {
    Codigo = "codigo",
    Descricao = "descricao",
}

export type CnaeSecoesScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CnaeSecoesScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CnaeSecoesScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CnaeSecoesScalarWhereWithAggregatesInput>>;
    codigo?: InputMaybe<StringWithAggregatesFilter>;
    descricao?: InputMaybe<StringWithAggregatesFilter>;
};

export type CnaeSecoesUpdateInput = {
    codigo?: InputMaybe<StringFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    divisoes?: InputMaybe<CnaeDivisoesUpdateManyWithoutSecaoNestedInput>;
};

export type CnaeSecoesUpdateManyMutationInput = {
    codigo?: InputMaybe<StringFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CnaeSecoesUpdateOneRequiredWithoutDivisoesNestedInput = {
    connect?: InputMaybe<CnaeSecoesWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CnaeSecoesCreateOrConnectWithoutDivisoesInput>;
    create?: InputMaybe<CnaeSecoesCreateWithoutDivisoesInput>;
    update?: InputMaybe<CnaeSecoesUpdateWithoutDivisoesInput>;
    upsert?: InputMaybe<CnaeSecoesUpsertWithoutDivisoesInput>;
};

export type CnaeSecoesUpdateWithoutDivisoesInput = {
    codigo?: InputMaybe<StringFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CnaeSecoesUpsertWithoutDivisoesInput = {
    create: CnaeSecoesCreateWithoutDivisoesInput;
    update: CnaeSecoesUpdateWithoutDivisoesInput;
};

export type CnaeSecoesWhereInput = {
    AND?: InputMaybe<Array<CnaeSecoesWhereInput>>;
    NOT?: InputMaybe<Array<CnaeSecoesWhereInput>>;
    OR?: InputMaybe<Array<CnaeSecoesWhereInput>>;
    codigo?: InputMaybe<StringFilter>;
    descricao?: InputMaybe<StringFilter>;
    divisoes?: InputMaybe<CnaeDivisoesListRelationFilter>;
};

export type CnaeSecoesWhereUniqueInput = {
    codigo?: InputMaybe<Scalars["String"]>;
};

export type CnaeSubClasses = {
    __typename?: "CnaeSubClasses";
    classe: CnaeClasses;
    codigoClasse: Scalars["Int"];
    descricao: Scalars["String"];
    id: Scalars["Int"];
};

export type CnaeSubClassesAvgAggregate = {
    __typename?: "CnaeSubClassesAvgAggregate";
    codigoClasse?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
};

export type CnaeSubClassesAvgOrderByAggregateInput = {
    codigoClasse?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type CnaeSubClassesCountAggregate = {
    __typename?: "CnaeSubClassesCountAggregate";
    _all: Scalars["Int"];
    codigoClasse: Scalars["Int"];
    descricao: Scalars["Int"];
    id: Scalars["Int"];
};

export type CnaeSubClassesCountOrderByAggregateInput = {
    codigoClasse?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type CnaeSubClassesCreateInput = {
    classe: CnaeClassesCreateNestedOneWithoutSubclassesInput;
    descricao: Scalars["String"];
};

export type CnaeSubClassesCreateManyClasseInput = {
    descricao: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
};

export type CnaeSubClassesCreateManyClasseInputEnvelope = {
    data: Array<CnaeSubClassesCreateManyClasseInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CnaeSubClassesCreateManyInput = {
    codigoClasse: Scalars["Int"];
    descricao: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
};

export type CnaeSubClassesCreateNestedManyWithoutClasseInput = {
    connect?: InputMaybe<Array<CnaeSubClassesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CnaeSubClassesCreateOrConnectWithoutClasseInput>>;
    create?: InputMaybe<Array<CnaeSubClassesCreateWithoutClasseInput>>;
    createMany?: InputMaybe<CnaeSubClassesCreateManyClasseInputEnvelope>;
};

export type CnaeSubClassesCreateOrConnectWithoutClasseInput = {
    create: CnaeSubClassesCreateWithoutClasseInput;
    where: CnaeSubClassesWhereUniqueInput;
};

export type CnaeSubClassesCreateWithoutClasseInput = {
    descricao: Scalars["String"];
};

export type CnaeSubClassesGroupBy = {
    __typename?: "CnaeSubClassesGroupBy";
    _avg?: Maybe<CnaeSubClassesAvgAggregate>;
    _count?: Maybe<CnaeSubClassesCountAggregate>;
    _max?: Maybe<CnaeSubClassesMaxAggregate>;
    _min?: Maybe<CnaeSubClassesMinAggregate>;
    _sum?: Maybe<CnaeSubClassesSumAggregate>;
    codigoClasse: Scalars["Int"];
    descricao: Scalars["String"];
    id: Scalars["Int"];
};

export type CnaeSubClassesListRelationFilter = {
    every?: InputMaybe<CnaeSubClassesWhereInput>;
    none?: InputMaybe<CnaeSubClassesWhereInput>;
    some?: InputMaybe<CnaeSubClassesWhereInput>;
};

export type CnaeSubClassesMaxAggregate = {
    __typename?: "CnaeSubClassesMaxAggregate";
    codigoClasse?: Maybe<Scalars["Int"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
};

export type CnaeSubClassesMaxOrderByAggregateInput = {
    codigoClasse?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type CnaeSubClassesMinAggregate = {
    __typename?: "CnaeSubClassesMinAggregate";
    codigoClasse?: Maybe<Scalars["Int"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
};

export type CnaeSubClassesMinOrderByAggregateInput = {
    codigoClasse?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type CnaeSubClassesOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type CnaeSubClassesOrderByWithAggregationInput = {
    _avg?: InputMaybe<CnaeSubClassesAvgOrderByAggregateInput>;
    _count?: InputMaybe<CnaeSubClassesCountOrderByAggregateInput>;
    _max?: InputMaybe<CnaeSubClassesMaxOrderByAggregateInput>;
    _min?: InputMaybe<CnaeSubClassesMinOrderByAggregateInput>;
    _sum?: InputMaybe<CnaeSubClassesSumOrderByAggregateInput>;
    codigoClasse?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type CnaeSubClassesOrderByWithRelationInput = {
    classe?: InputMaybe<CnaeClassesOrderByWithRelationInput>;
    codigoClasse?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export enum CnaeSubClassesScalarFieldEnum {
    CodigoClasse = "codigoClasse",
    Descricao = "descricao",
    Id = "id",
}

export type CnaeSubClassesScalarWhereInput = {
    AND?: InputMaybe<Array<CnaeSubClassesScalarWhereInput>>;
    NOT?: InputMaybe<Array<CnaeSubClassesScalarWhereInput>>;
    OR?: InputMaybe<Array<CnaeSubClassesScalarWhereInput>>;
    codigoClasse?: InputMaybe<IntFilter>;
    descricao?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
};

export type CnaeSubClassesScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<CnaeSubClassesScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<CnaeSubClassesScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<CnaeSubClassesScalarWhereWithAggregatesInput>>;
    codigoClasse?: InputMaybe<IntWithAggregatesFilter>;
    descricao?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
};

export type CnaeSubClassesSumAggregate = {
    __typename?: "CnaeSubClassesSumAggregate";
    codigoClasse?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
};

export type CnaeSubClassesSumOrderByAggregateInput = {
    codigoClasse?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type CnaeSubClassesUpdateInput = {
    classe?: InputMaybe<CnaeClassesUpdateOneRequiredWithoutSubclassesNestedInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CnaeSubClassesUpdateManyMutationInput = {
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CnaeSubClassesUpdateManyWithWhereWithoutClasseInput = {
    data: CnaeSubClassesUpdateManyMutationInput;
    where: CnaeSubClassesScalarWhereInput;
};

export type CnaeSubClassesUpdateManyWithoutClasseNestedInput = {
    connect?: InputMaybe<Array<CnaeSubClassesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<CnaeSubClassesCreateOrConnectWithoutClasseInput>>;
    create?: InputMaybe<Array<CnaeSubClassesCreateWithoutClasseInput>>;
    createMany?: InputMaybe<CnaeSubClassesCreateManyClasseInputEnvelope>;
    delete?: InputMaybe<Array<CnaeSubClassesWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<CnaeSubClassesScalarWhereInput>>;
    disconnect?: InputMaybe<Array<CnaeSubClassesWhereUniqueInput>>;
    set?: InputMaybe<Array<CnaeSubClassesWhereUniqueInput>>;
    update?: InputMaybe<Array<CnaeSubClassesUpdateWithWhereUniqueWithoutClasseInput>>;
    updateMany?: InputMaybe<Array<CnaeSubClassesUpdateManyWithWhereWithoutClasseInput>>;
    upsert?: InputMaybe<Array<CnaeSubClassesUpsertWithWhereUniqueWithoutClasseInput>>;
};

export type CnaeSubClassesUpdateWithWhereUniqueWithoutClasseInput = {
    data: CnaeSubClassesUpdateWithoutClasseInput;
    where: CnaeSubClassesWhereUniqueInput;
};

export type CnaeSubClassesUpdateWithoutClasseInput = {
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CnaeSubClassesUpsertWithWhereUniqueWithoutClasseInput = {
    create: CnaeSubClassesCreateWithoutClasseInput;
    update: CnaeSubClassesUpdateWithoutClasseInput;
    where: CnaeSubClassesWhereUniqueInput;
};

export type CnaeSubClassesWhereInput = {
    AND?: InputMaybe<Array<CnaeSubClassesWhereInput>>;
    NOT?: InputMaybe<Array<CnaeSubClassesWhereInput>>;
    OR?: InputMaybe<Array<CnaeSubClassesWhereInput>>;
    classe?: InputMaybe<CnaeClassesRelationFilter>;
    codigoClasse?: InputMaybe<IntFilter>;
    descricao?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
};

export type CnaeSubClassesWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type ComercialPartnerAudit = {
    __typename?: "ComercialPartnerAudit";
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    partner: ParceiroComercial;
    partnerId: Scalars["Int"];
    service: TipoServico;
    user?: Maybe<Usuario>;
    userAgent: Scalars["String"];
    userId?: Maybe<Scalars["Int"]>;
};

export type ComercialPartnerAuditAvgAggregate = {
    __typename?: "ComercialPartnerAuditAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    partnerId?: Maybe<Scalars["Float"]>;
    userId?: Maybe<Scalars["Float"]>;
};

export type ComercialPartnerAuditAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    partnerId?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type ComercialPartnerAuditCountAggregate = {
    __typename?: "ComercialPartnerAuditCountAggregate";
    _all: Scalars["Int"];
    action: Scalars["Int"];
    createdAt: Scalars["Int"];
    field: Scalars["Int"];
    id: Scalars["Int"];
    ipUsuario: Scalars["Int"];
    newValue: Scalars["Int"];
    oldValue: Scalars["Int"];
    partnerId: Scalars["Int"];
    service: Scalars["Int"];
    userAgent: Scalars["Int"];
    userId: Scalars["Int"];
};

export type ComercialPartnerAuditCountOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    partnerId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type ComercialPartnerAuditCreateInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    partner: ParceiroComercialCreateNestedOneWithoutAuditInput;
    service?: InputMaybe<TipoServico>;
    user?: InputMaybe<UsuarioCreateNestedOneWithoutComercialPartnerAuditInput>;
    userAgent: Scalars["String"];
};

export type ComercialPartnerAuditCreateManyInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    partnerId: Scalars["Int"];
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    userId?: InputMaybe<Scalars["Int"]>;
};

export type ComercialPartnerAuditCreateManyPartnerInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    userId?: InputMaybe<Scalars["Int"]>;
};

export type ComercialPartnerAuditCreateManyPartnerInputEnvelope = {
    data: Array<ComercialPartnerAuditCreateManyPartnerInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ComercialPartnerAuditCreateManyUserInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    partnerId: Scalars["Int"];
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type ComercialPartnerAuditCreateManyUserInputEnvelope = {
    data: Array<ComercialPartnerAuditCreateManyUserInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ComercialPartnerAuditCreateNestedManyWithoutPartnerInput = {
    connect?: InputMaybe<Array<ComercialPartnerAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ComercialPartnerAuditCreateOrConnectWithoutPartnerInput>>;
    create?: InputMaybe<Array<ComercialPartnerAuditCreateWithoutPartnerInput>>;
    createMany?: InputMaybe<ComercialPartnerAuditCreateManyPartnerInputEnvelope>;
};

export type ComercialPartnerAuditCreateNestedManyWithoutUserInput = {
    connect?: InputMaybe<Array<ComercialPartnerAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ComercialPartnerAuditCreateOrConnectWithoutUserInput>>;
    create?: InputMaybe<Array<ComercialPartnerAuditCreateWithoutUserInput>>;
    createMany?: InputMaybe<ComercialPartnerAuditCreateManyUserInputEnvelope>;
};

export type ComercialPartnerAuditCreateOrConnectWithoutPartnerInput = {
    create: ComercialPartnerAuditCreateWithoutPartnerInput;
    where: ComercialPartnerAuditWhereUniqueInput;
};

export type ComercialPartnerAuditCreateOrConnectWithoutUserInput = {
    create: ComercialPartnerAuditCreateWithoutUserInput;
    where: ComercialPartnerAuditWhereUniqueInput;
};

export type ComercialPartnerAuditCreateWithoutPartnerInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    user?: InputMaybe<UsuarioCreateNestedOneWithoutComercialPartnerAuditInput>;
    userAgent: Scalars["String"];
};

export type ComercialPartnerAuditCreateWithoutUserInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    partner: ParceiroComercialCreateNestedOneWithoutAuditInput;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type ComercialPartnerAuditGroupBy = {
    __typename?: "ComercialPartnerAuditGroupBy";
    _avg?: Maybe<ComercialPartnerAuditAvgAggregate>;
    _count?: Maybe<ComercialPartnerAuditCountAggregate>;
    _max?: Maybe<ComercialPartnerAuditMaxAggregate>;
    _min?: Maybe<ComercialPartnerAuditMinAggregate>;
    _sum?: Maybe<ComercialPartnerAuditSumAggregate>;
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    partnerId: Scalars["Int"];
    service: TipoServico;
    userAgent: Scalars["String"];
    userId?: Maybe<Scalars["Int"]>;
};

export type ComercialPartnerAuditListRelationFilter = {
    every?: InputMaybe<ComercialPartnerAuditWhereInput>;
    none?: InputMaybe<ComercialPartnerAuditWhereInput>;
    some?: InputMaybe<ComercialPartnerAuditWhereInput>;
};

export type ComercialPartnerAuditMaxAggregate = {
    __typename?: "ComercialPartnerAuditMaxAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    partnerId?: Maybe<Scalars["Int"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    userId?: Maybe<Scalars["Int"]>;
};

export type ComercialPartnerAuditMaxOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    partnerId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type ComercialPartnerAuditMinAggregate = {
    __typename?: "ComercialPartnerAuditMinAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    partnerId?: Maybe<Scalars["Int"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    userId?: Maybe<Scalars["Int"]>;
};

export type ComercialPartnerAuditMinOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    partnerId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type ComercialPartnerAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type ComercialPartnerAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<ComercialPartnerAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<ComercialPartnerAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<ComercialPartnerAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<ComercialPartnerAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<ComercialPartnerAuditSumOrderByAggregateInput>;
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    partnerId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type ComercialPartnerAuditOrderByWithRelationInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    partner?: InputMaybe<ParceiroComercialOrderByWithRelationInput>;
    partnerId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    user?: InputMaybe<UsuarioOrderByWithRelationInput>;
    userAgent?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export enum ComercialPartnerAuditScalarFieldEnum {
    Action = "action",
    CreatedAt = "createdAt",
    Field = "field",
    Id = "id",
    IpUsuario = "ipUsuario",
    NewValue = "newValue",
    OldValue = "oldValue",
    PartnerId = "partnerId",
    Service = "service",
    UserAgent = "userAgent",
    UserId = "userId",
}

export type ComercialPartnerAuditScalarWhereInput = {
    AND?: InputMaybe<Array<ComercialPartnerAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<ComercialPartnerAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<ComercialPartnerAuditScalarWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    partnerId?: InputMaybe<IntFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    userId?: InputMaybe<IntNullableFilter>;
};

export type ComercialPartnerAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<ComercialPartnerAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<ComercialPartnerAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<ComercialPartnerAuditScalarWhereWithAggregatesInput>>;
    action?: InputMaybe<EnumAuditAcoesWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    field?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ipUsuario?: InputMaybe<StringWithAggregatesFilter>;
    newValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    partnerId?: InputMaybe<IntWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    userId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type ComercialPartnerAuditSumAggregate = {
    __typename?: "ComercialPartnerAuditSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    partnerId?: Maybe<Scalars["Int"]>;
    userId?: Maybe<Scalars["Int"]>;
};

export type ComercialPartnerAuditSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    partnerId?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type ComercialPartnerAuditUpdateInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    partner?: InputMaybe<ParceiroComercialUpdateOneRequiredWithoutAuditNestedInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    user?: InputMaybe<UsuarioUpdateOneWithoutComercialPartnerAuditNestedInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ComercialPartnerAuditUpdateManyMutationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ComercialPartnerAuditUpdateManyWithWhereWithoutPartnerInput = {
    data: ComercialPartnerAuditUpdateManyMutationInput;
    where: ComercialPartnerAuditScalarWhereInput;
};

export type ComercialPartnerAuditUpdateManyWithWhereWithoutUserInput = {
    data: ComercialPartnerAuditUpdateManyMutationInput;
    where: ComercialPartnerAuditScalarWhereInput;
};

export type ComercialPartnerAuditUpdateManyWithoutPartnerNestedInput = {
    connect?: InputMaybe<Array<ComercialPartnerAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ComercialPartnerAuditCreateOrConnectWithoutPartnerInput>>;
    create?: InputMaybe<Array<ComercialPartnerAuditCreateWithoutPartnerInput>>;
    createMany?: InputMaybe<ComercialPartnerAuditCreateManyPartnerInputEnvelope>;
    delete?: InputMaybe<Array<ComercialPartnerAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ComercialPartnerAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ComercialPartnerAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<ComercialPartnerAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<ComercialPartnerAuditUpdateWithWhereUniqueWithoutPartnerInput>>;
    updateMany?: InputMaybe<Array<ComercialPartnerAuditUpdateManyWithWhereWithoutPartnerInput>>;
    upsert?: InputMaybe<Array<ComercialPartnerAuditUpsertWithWhereUniqueWithoutPartnerInput>>;
};

export type ComercialPartnerAuditUpdateManyWithoutUserNestedInput = {
    connect?: InputMaybe<Array<ComercialPartnerAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ComercialPartnerAuditCreateOrConnectWithoutUserInput>>;
    create?: InputMaybe<Array<ComercialPartnerAuditCreateWithoutUserInput>>;
    createMany?: InputMaybe<ComercialPartnerAuditCreateManyUserInputEnvelope>;
    delete?: InputMaybe<Array<ComercialPartnerAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ComercialPartnerAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ComercialPartnerAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<ComercialPartnerAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<ComercialPartnerAuditUpdateWithWhereUniqueWithoutUserInput>>;
    updateMany?: InputMaybe<Array<ComercialPartnerAuditUpdateManyWithWhereWithoutUserInput>>;
    upsert?: InputMaybe<Array<ComercialPartnerAuditUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ComercialPartnerAuditUpdateWithWhereUniqueWithoutPartnerInput = {
    data: ComercialPartnerAuditUpdateWithoutPartnerInput;
    where: ComercialPartnerAuditWhereUniqueInput;
};

export type ComercialPartnerAuditUpdateWithWhereUniqueWithoutUserInput = {
    data: ComercialPartnerAuditUpdateWithoutUserInput;
    where: ComercialPartnerAuditWhereUniqueInput;
};

export type ComercialPartnerAuditUpdateWithoutPartnerInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    user?: InputMaybe<UsuarioUpdateOneWithoutComercialPartnerAuditNestedInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ComercialPartnerAuditUpdateWithoutUserInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    partner?: InputMaybe<ParceiroComercialUpdateOneRequiredWithoutAuditNestedInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ComercialPartnerAuditUpsertWithWhereUniqueWithoutPartnerInput = {
    create: ComercialPartnerAuditCreateWithoutPartnerInput;
    update: ComercialPartnerAuditUpdateWithoutPartnerInput;
    where: ComercialPartnerAuditWhereUniqueInput;
};

export type ComercialPartnerAuditUpsertWithWhereUniqueWithoutUserInput = {
    create: ComercialPartnerAuditCreateWithoutUserInput;
    update: ComercialPartnerAuditUpdateWithoutUserInput;
    where: ComercialPartnerAuditWhereUniqueInput;
};

export type ComercialPartnerAuditWhereInput = {
    AND?: InputMaybe<Array<ComercialPartnerAuditWhereInput>>;
    NOT?: InputMaybe<Array<ComercialPartnerAuditWhereInput>>;
    OR?: InputMaybe<Array<ComercialPartnerAuditWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    partner?: InputMaybe<ParceiroComercialRelationFilter>;
    partnerId?: InputMaybe<IntFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    user?: InputMaybe<UsuarioRelationFilter>;
    userAgent?: InputMaybe<StringFilter>;
    userId?: InputMaybe<IntNullableFilter>;
};

export type ComercialPartnerAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type ComercialPartnerCommission = {
    __typename?: "ComercialPartnerCommission";
    commission: Scalars["Decimal"];
    createdAt: Scalars["DateTime"];
    lastsUntil: Scalars["DateTime"];
    minibank: Minibanco;
    minibankId: Scalars["Int"];
    partner: ParceiroComercial;
    partnerId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type ComercialPartnerCommissionAvgAggregate = {
    __typename?: "ComercialPartnerCommissionAvgAggregate";
    commission?: Maybe<Scalars["Decimal"]>;
    minibankId?: Maybe<Scalars["Float"]>;
    partnerId?: Maybe<Scalars["Float"]>;
};

export type ComercialPartnerCommissionAvgOrderByAggregateInput = {
    commission?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    partnerId?: InputMaybe<SortOrder>;
};

export type ComercialPartnerCommissionCountAggregate = {
    __typename?: "ComercialPartnerCommissionCountAggregate";
    _all: Scalars["Int"];
    commission: Scalars["Int"];
    createdAt: Scalars["Int"];
    lastsUntil: Scalars["Int"];
    minibankId: Scalars["Int"];
    partnerId: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type ComercialPartnerCommissionCountOrderByAggregateInput = {
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    lastsUntil?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    partnerId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ComercialPartnerCommissionCreateInput = {
    commission: Scalars["Decimal"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    lastsUntil: Scalars["DateTime"];
    minibank: MinibancoCreateNestedOneWithoutPartnerCommissionInput;
    partner: ParceiroComercialCreateNestedOneWithoutPartnerCommissionInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ComercialPartnerCommissionCreateManyInput = {
    commission: Scalars["Decimal"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    lastsUntil: Scalars["DateTime"];
    minibankId: Scalars["Int"];
    partnerId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ComercialPartnerCommissionCreateManyMinibankInput = {
    commission: Scalars["Decimal"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    lastsUntil: Scalars["DateTime"];
    partnerId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ComercialPartnerCommissionCreateManyMinibankInputEnvelope = {
    data: Array<ComercialPartnerCommissionCreateManyMinibankInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ComercialPartnerCommissionCreateManyPartnerInput = {
    commission: Scalars["Decimal"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    lastsUntil: Scalars["DateTime"];
    minibankId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ComercialPartnerCommissionCreateManyPartnerInputEnvelope = {
    data: Array<ComercialPartnerCommissionCreateManyPartnerInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput = {
    connect?: InputMaybe<Array<ComercialPartnerCommissionWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ComercialPartnerCommissionCreateOrConnectWithoutMinibankInput>>;
    create?: InputMaybe<Array<ComercialPartnerCommissionCreateWithoutMinibankInput>>;
    createMany?: InputMaybe<ComercialPartnerCommissionCreateManyMinibankInputEnvelope>;
};

export type ComercialPartnerCommissionCreateNestedManyWithoutPartnerInput = {
    connect?: InputMaybe<Array<ComercialPartnerCommissionWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ComercialPartnerCommissionCreateOrConnectWithoutPartnerInput>>;
    create?: InputMaybe<Array<ComercialPartnerCommissionCreateWithoutPartnerInput>>;
    createMany?: InputMaybe<ComercialPartnerCommissionCreateManyPartnerInputEnvelope>;
};

export type ComercialPartnerCommissionCreateOrConnectWithoutMinibankInput = {
    create: ComercialPartnerCommissionCreateWithoutMinibankInput;
    where: ComercialPartnerCommissionWhereUniqueInput;
};

export type ComercialPartnerCommissionCreateOrConnectWithoutPartnerInput = {
    create: ComercialPartnerCommissionCreateWithoutPartnerInput;
    where: ComercialPartnerCommissionWhereUniqueInput;
};

export type ComercialPartnerCommissionCreateWithoutMinibankInput = {
    commission: Scalars["Decimal"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    lastsUntil: Scalars["DateTime"];
    partner: ParceiroComercialCreateNestedOneWithoutPartnerCommissionInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ComercialPartnerCommissionCreateWithoutPartnerInput = {
    commission: Scalars["Decimal"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    lastsUntil: Scalars["DateTime"];
    minibank: MinibancoCreateNestedOneWithoutPartnerCommissionInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ComercialPartnerCommissionGroupBy = {
    __typename?: "ComercialPartnerCommissionGroupBy";
    _avg?: Maybe<ComercialPartnerCommissionAvgAggregate>;
    _count?: Maybe<ComercialPartnerCommissionCountAggregate>;
    _max?: Maybe<ComercialPartnerCommissionMaxAggregate>;
    _min?: Maybe<ComercialPartnerCommissionMinAggregate>;
    _sum?: Maybe<ComercialPartnerCommissionSumAggregate>;
    commission: Scalars["Decimal"];
    createdAt: Scalars["DateTime"];
    lastsUntil: Scalars["DateTime"];
    minibankId: Scalars["Int"];
    partnerId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type ComercialPartnerCommissionListRelationFilter = {
    every?: InputMaybe<ComercialPartnerCommissionWhereInput>;
    none?: InputMaybe<ComercialPartnerCommissionWhereInput>;
    some?: InputMaybe<ComercialPartnerCommissionWhereInput>;
};

export type ComercialPartnerCommissionMaxAggregate = {
    __typename?: "ComercialPartnerCommissionMaxAggregate";
    commission?: Maybe<Scalars["Decimal"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    lastsUntil?: Maybe<Scalars["DateTime"]>;
    minibankId?: Maybe<Scalars["Int"]>;
    partnerId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ComercialPartnerCommissionMaxOrderByAggregateInput = {
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    lastsUntil?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    partnerId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ComercialPartnerCommissionMinAggregate = {
    __typename?: "ComercialPartnerCommissionMinAggregate";
    commission?: Maybe<Scalars["Decimal"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    lastsUntil?: Maybe<Scalars["DateTime"]>;
    minibankId?: Maybe<Scalars["Int"]>;
    partnerId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ComercialPartnerCommissionMinOrderByAggregateInput = {
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    lastsUntil?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    partnerId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ComercialPartnerCommissionMinibankIdPartnerIdCompoundUniqueInput = {
    minibankId: Scalars["Int"];
    partnerId: Scalars["Int"];
};

export type ComercialPartnerCommissionOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type ComercialPartnerCommissionOrderByWithAggregationInput = {
    _avg?: InputMaybe<ComercialPartnerCommissionAvgOrderByAggregateInput>;
    _count?: InputMaybe<ComercialPartnerCommissionCountOrderByAggregateInput>;
    _max?: InputMaybe<ComercialPartnerCommissionMaxOrderByAggregateInput>;
    _min?: InputMaybe<ComercialPartnerCommissionMinOrderByAggregateInput>;
    _sum?: InputMaybe<ComercialPartnerCommissionSumOrderByAggregateInput>;
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    lastsUntil?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    partnerId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ComercialPartnerCommissionOrderByWithRelationInput = {
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    lastsUntil?: InputMaybe<SortOrder>;
    minibank?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibankId?: InputMaybe<SortOrder>;
    partner?: InputMaybe<ParceiroComercialOrderByWithRelationInput>;
    partnerId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export enum ComercialPartnerCommissionScalarFieldEnum {
    Commission = "commission",
    CreatedAt = "createdAt",
    LastsUntil = "lastsUntil",
    MinibankId = "minibankId",
    PartnerId = "partnerId",
    UpdatedAt = "updatedAt",
}

export type ComercialPartnerCommissionScalarWhereInput = {
    AND?: InputMaybe<Array<ComercialPartnerCommissionScalarWhereInput>>;
    NOT?: InputMaybe<Array<ComercialPartnerCommissionScalarWhereInput>>;
    OR?: InputMaybe<Array<ComercialPartnerCommissionScalarWhereInput>>;
    commission?: InputMaybe<DecimalFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    lastsUntil?: InputMaybe<DateTimeFilter>;
    minibankId?: InputMaybe<IntFilter>;
    partnerId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ComercialPartnerCommissionScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<ComercialPartnerCommissionScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<ComercialPartnerCommissionScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<ComercialPartnerCommissionScalarWhereWithAggregatesInput>>;
    commission?: InputMaybe<DecimalWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    lastsUntil?: InputMaybe<DateTimeWithAggregatesFilter>;
    minibankId?: InputMaybe<IntWithAggregatesFilter>;
    partnerId?: InputMaybe<IntWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ComercialPartnerCommissionSumAggregate = {
    __typename?: "ComercialPartnerCommissionSumAggregate";
    commission?: Maybe<Scalars["Decimal"]>;
    minibankId?: Maybe<Scalars["Int"]>;
    partnerId?: Maybe<Scalars["Int"]>;
};

export type ComercialPartnerCommissionSumOrderByAggregateInput = {
    commission?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    partnerId?: InputMaybe<SortOrder>;
};

export type ComercialPartnerCommissionUpdateInput = {
    commission?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    lastsUntil?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    minibank?: InputMaybe<MinibancoUpdateOneRequiredWithoutPartnerCommissionNestedInput>;
    partner?: InputMaybe<ParceiroComercialUpdateOneRequiredWithoutPartnerCommissionNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ComercialPartnerCommissionUpdateManyMutationInput = {
    commission?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    lastsUntil?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ComercialPartnerCommissionUpdateManyWithWhereWithoutMinibankInput = {
    data: ComercialPartnerCommissionUpdateManyMutationInput;
    where: ComercialPartnerCommissionScalarWhereInput;
};

export type ComercialPartnerCommissionUpdateManyWithWhereWithoutPartnerInput = {
    data: ComercialPartnerCommissionUpdateManyMutationInput;
    where: ComercialPartnerCommissionScalarWhereInput;
};

export type ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput = {
    connect?: InputMaybe<Array<ComercialPartnerCommissionWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ComercialPartnerCommissionCreateOrConnectWithoutMinibankInput>>;
    create?: InputMaybe<Array<ComercialPartnerCommissionCreateWithoutMinibankInput>>;
    createMany?: InputMaybe<ComercialPartnerCommissionCreateManyMinibankInputEnvelope>;
    delete?: InputMaybe<Array<ComercialPartnerCommissionWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ComercialPartnerCommissionScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ComercialPartnerCommissionWhereUniqueInput>>;
    set?: InputMaybe<Array<ComercialPartnerCommissionWhereUniqueInput>>;
    update?: InputMaybe<Array<ComercialPartnerCommissionUpdateWithWhereUniqueWithoutMinibankInput>>;
    updateMany?: InputMaybe<Array<ComercialPartnerCommissionUpdateManyWithWhereWithoutMinibankInput>>;
    upsert?: InputMaybe<Array<ComercialPartnerCommissionUpsertWithWhereUniqueWithoutMinibankInput>>;
};

export type ComercialPartnerCommissionUpdateManyWithoutPartnerNestedInput = {
    connect?: InputMaybe<Array<ComercialPartnerCommissionWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ComercialPartnerCommissionCreateOrConnectWithoutPartnerInput>>;
    create?: InputMaybe<Array<ComercialPartnerCommissionCreateWithoutPartnerInput>>;
    createMany?: InputMaybe<ComercialPartnerCommissionCreateManyPartnerInputEnvelope>;
    delete?: InputMaybe<Array<ComercialPartnerCommissionWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ComercialPartnerCommissionScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ComercialPartnerCommissionWhereUniqueInput>>;
    set?: InputMaybe<Array<ComercialPartnerCommissionWhereUniqueInput>>;
    update?: InputMaybe<Array<ComercialPartnerCommissionUpdateWithWhereUniqueWithoutPartnerInput>>;
    updateMany?: InputMaybe<Array<ComercialPartnerCommissionUpdateManyWithWhereWithoutPartnerInput>>;
    upsert?: InputMaybe<Array<ComercialPartnerCommissionUpsertWithWhereUniqueWithoutPartnerInput>>;
};

export type ComercialPartnerCommissionUpdateWithWhereUniqueWithoutMinibankInput = {
    data: ComercialPartnerCommissionUpdateWithoutMinibankInput;
    where: ComercialPartnerCommissionWhereUniqueInput;
};

export type ComercialPartnerCommissionUpdateWithWhereUniqueWithoutPartnerInput = {
    data: ComercialPartnerCommissionUpdateWithoutPartnerInput;
    where: ComercialPartnerCommissionWhereUniqueInput;
};

export type ComercialPartnerCommissionUpdateWithoutMinibankInput = {
    commission?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    lastsUntil?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    partner?: InputMaybe<ParceiroComercialUpdateOneRequiredWithoutPartnerCommissionNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ComercialPartnerCommissionUpdateWithoutPartnerInput = {
    commission?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    lastsUntil?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    minibank?: InputMaybe<MinibancoUpdateOneRequiredWithoutPartnerCommissionNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ComercialPartnerCommissionUpsertWithWhereUniqueWithoutMinibankInput = {
    create: ComercialPartnerCommissionCreateWithoutMinibankInput;
    update: ComercialPartnerCommissionUpdateWithoutMinibankInput;
    where: ComercialPartnerCommissionWhereUniqueInput;
};

export type ComercialPartnerCommissionUpsertWithWhereUniqueWithoutPartnerInput = {
    create: ComercialPartnerCommissionCreateWithoutPartnerInput;
    update: ComercialPartnerCommissionUpdateWithoutPartnerInput;
    where: ComercialPartnerCommissionWhereUniqueInput;
};

export type ComercialPartnerCommissionWhereInput = {
    AND?: InputMaybe<Array<ComercialPartnerCommissionWhereInput>>;
    NOT?: InputMaybe<Array<ComercialPartnerCommissionWhereInput>>;
    OR?: InputMaybe<Array<ComercialPartnerCommissionWhereInput>>;
    commission?: InputMaybe<DecimalFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    lastsUntil?: InputMaybe<DateTimeFilter>;
    minibank?: InputMaybe<MinibancoRelationFilter>;
    minibankId?: InputMaybe<IntFilter>;
    partner?: InputMaybe<ParceiroComercialRelationFilter>;
    partnerId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ComercialPartnerCommissionWhereUniqueInput = {
    minibankId_partnerId?: InputMaybe<ComercialPartnerCommissionMinibankIdPartnerIdCompoundUniqueInput>;
};

export type ContaBancaria = {
    __typename?: "ContaBancaria";
    agencia: Scalars["String"];
    cedente?: Maybe<Cedente>;
    cedenteId?: Maybe<Scalars["Int"]>;
    id: Scalars["Int"];
    numBanco: Scalars["String"];
    numConta: Scalars["String"];
};

export type ContaBancariaAvgAggregate = {
    __typename?: "ContaBancariaAvgAggregate";
    cedenteId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
};

export type ContaBancariaAvgOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type ContaBancariaContaCompoundUniqueInput = {
    agencia: Scalars["String"];
    numConta: Scalars["String"];
};

export type ContaBancariaCountAggregate = {
    __typename?: "ContaBancariaCountAggregate";
    _all: Scalars["Int"];
    agencia: Scalars["Int"];
    cedenteId: Scalars["Int"];
    id: Scalars["Int"];
    numBanco: Scalars["Int"];
    numConta: Scalars["Int"];
};

export type ContaBancariaCountOrderByAggregateInput = {
    agencia?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    numBanco?: InputMaybe<SortOrder>;
    numConta?: InputMaybe<SortOrder>;
};

export type ContaBancariaCreateInput = {
    agencia: Scalars["String"];
    cedente?: InputMaybe<CedenteCreateNestedOneWithoutContaBancariaInput>;
    numBanco: Scalars["String"];
    numConta: Scalars["String"];
};

export type ContaBancariaCreateManyCedenteInput = {
    agencia: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    numBanco: Scalars["String"];
    numConta: Scalars["String"];
};

export type ContaBancariaCreateManyCedenteInputEnvelope = {
    data: Array<ContaBancariaCreateManyCedenteInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ContaBancariaCreateManyInput = {
    agencia: Scalars["String"];
    cedenteId?: InputMaybe<Scalars["Int"]>;
    id?: InputMaybe<Scalars["Int"]>;
    numBanco: Scalars["String"];
    numConta: Scalars["String"];
};

export type ContaBancariaCreateNestedManyWithoutCedenteInput = {
    connect?: InputMaybe<Array<ContaBancariaWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ContaBancariaCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<ContaBancariaCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<ContaBancariaCreateManyCedenteInputEnvelope>;
};

export type ContaBancariaCreateOrConnectWithoutCedenteInput = {
    create: ContaBancariaCreateWithoutCedenteInput;
    where: ContaBancariaWhereUniqueInput;
};

export type ContaBancariaCreateWithoutCedenteInput = {
    agencia: Scalars["String"];
    numBanco: Scalars["String"];
    numConta: Scalars["String"];
};

export type ContaBancariaGroupBy = {
    __typename?: "ContaBancariaGroupBy";
    _avg?: Maybe<ContaBancariaAvgAggregate>;
    _count?: Maybe<ContaBancariaCountAggregate>;
    _max?: Maybe<ContaBancariaMaxAggregate>;
    _min?: Maybe<ContaBancariaMinAggregate>;
    _sum?: Maybe<ContaBancariaSumAggregate>;
    agencia: Scalars["String"];
    cedenteId?: Maybe<Scalars["Int"]>;
    id: Scalars["Int"];
    numBanco: Scalars["String"];
    numConta: Scalars["String"];
};

export type ContaBancariaListRelationFilter = {
    every?: InputMaybe<ContaBancariaWhereInput>;
    none?: InputMaybe<ContaBancariaWhereInput>;
    some?: InputMaybe<ContaBancariaWhereInput>;
};

export type ContaBancariaMaxAggregate = {
    __typename?: "ContaBancariaMaxAggregate";
    agencia?: Maybe<Scalars["String"]>;
    cedenteId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    numBanco?: Maybe<Scalars["String"]>;
    numConta?: Maybe<Scalars["String"]>;
};

export type ContaBancariaMaxOrderByAggregateInput = {
    agencia?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    numBanco?: InputMaybe<SortOrder>;
    numConta?: InputMaybe<SortOrder>;
};

export type ContaBancariaMinAggregate = {
    __typename?: "ContaBancariaMinAggregate";
    agencia?: Maybe<Scalars["String"]>;
    cedenteId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    numBanco?: Maybe<Scalars["String"]>;
    numConta?: Maybe<Scalars["String"]>;
};

export type ContaBancariaMinOrderByAggregateInput = {
    agencia?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    numBanco?: InputMaybe<SortOrder>;
    numConta?: InputMaybe<SortOrder>;
};

export type ContaBancariaOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type ContaBancariaOrderByWithAggregationInput = {
    _avg?: InputMaybe<ContaBancariaAvgOrderByAggregateInput>;
    _count?: InputMaybe<ContaBancariaCountOrderByAggregateInput>;
    _max?: InputMaybe<ContaBancariaMaxOrderByAggregateInput>;
    _min?: InputMaybe<ContaBancariaMinOrderByAggregateInput>;
    _sum?: InputMaybe<ContaBancariaSumOrderByAggregateInput>;
    agencia?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    numBanco?: InputMaybe<SortOrder>;
    numConta?: InputMaybe<SortOrder>;
};

export type ContaBancariaOrderByWithRelationInput = {
    agencia?: InputMaybe<SortOrder>;
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    numBanco?: InputMaybe<SortOrder>;
    numConta?: InputMaybe<SortOrder>;
};

export enum ContaBancariaScalarFieldEnum {
    Agencia = "agencia",
    CedenteId = "cedenteId",
    Id = "id",
    NumBanco = "numBanco",
    NumConta = "numConta",
}

export type ContaBancariaScalarWhereInput = {
    AND?: InputMaybe<Array<ContaBancariaScalarWhereInput>>;
    NOT?: InputMaybe<Array<ContaBancariaScalarWhereInput>>;
    OR?: InputMaybe<Array<ContaBancariaScalarWhereInput>>;
    agencia?: InputMaybe<StringFilter>;
    cedenteId?: InputMaybe<IntNullableFilter>;
    id?: InputMaybe<IntFilter>;
    numBanco?: InputMaybe<StringFilter>;
    numConta?: InputMaybe<StringFilter>;
};

export type ContaBancariaScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<ContaBancariaScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<ContaBancariaScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<ContaBancariaScalarWhereWithAggregatesInput>>;
    agencia?: InputMaybe<StringWithAggregatesFilter>;
    cedenteId?: InputMaybe<IntNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    numBanco?: InputMaybe<StringWithAggregatesFilter>;
    numConta?: InputMaybe<StringWithAggregatesFilter>;
};

export type ContaBancariaSumAggregate = {
    __typename?: "ContaBancariaSumAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
};

export type ContaBancariaSumOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type ContaBancariaUpdateInput = {
    agencia?: InputMaybe<StringFieldUpdateOperationsInput>;
    cedente?: InputMaybe<CedenteUpdateOneWithoutContaBancariaNestedInput>;
    numBanco?: InputMaybe<StringFieldUpdateOperationsInput>;
    numConta?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ContaBancariaUpdateManyMutationInput = {
    agencia?: InputMaybe<StringFieldUpdateOperationsInput>;
    numBanco?: InputMaybe<StringFieldUpdateOperationsInput>;
    numConta?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ContaBancariaUpdateManyWithWhereWithoutCedenteInput = {
    data: ContaBancariaUpdateManyMutationInput;
    where: ContaBancariaScalarWhereInput;
};

export type ContaBancariaUpdateManyWithoutCedenteNestedInput = {
    connect?: InputMaybe<Array<ContaBancariaWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ContaBancariaCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<ContaBancariaCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<ContaBancariaCreateManyCedenteInputEnvelope>;
    delete?: InputMaybe<Array<ContaBancariaWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ContaBancariaScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ContaBancariaWhereUniqueInput>>;
    set?: InputMaybe<Array<ContaBancariaWhereUniqueInput>>;
    update?: InputMaybe<Array<ContaBancariaUpdateWithWhereUniqueWithoutCedenteInput>>;
    updateMany?: InputMaybe<Array<ContaBancariaUpdateManyWithWhereWithoutCedenteInput>>;
    upsert?: InputMaybe<Array<ContaBancariaUpsertWithWhereUniqueWithoutCedenteInput>>;
};

export type ContaBancariaUpdateWithWhereUniqueWithoutCedenteInput = {
    data: ContaBancariaUpdateWithoutCedenteInput;
    where: ContaBancariaWhereUniqueInput;
};

export type ContaBancariaUpdateWithoutCedenteInput = {
    agencia?: InputMaybe<StringFieldUpdateOperationsInput>;
    numBanco?: InputMaybe<StringFieldUpdateOperationsInput>;
    numConta?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ContaBancariaUpsertWithWhereUniqueWithoutCedenteInput = {
    create: ContaBancariaCreateWithoutCedenteInput;
    update: ContaBancariaUpdateWithoutCedenteInput;
    where: ContaBancariaWhereUniqueInput;
};

export type ContaBancariaWhereInput = {
    AND?: InputMaybe<Array<ContaBancariaWhereInput>>;
    NOT?: InputMaybe<Array<ContaBancariaWhereInput>>;
    OR?: InputMaybe<Array<ContaBancariaWhereInput>>;
    agencia?: InputMaybe<StringFilter>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntNullableFilter>;
    id?: InputMaybe<IntFilter>;
    numBanco?: InputMaybe<StringFilter>;
    numConta?: InputMaybe<StringFilter>;
};

export type ContaBancariaWhereUniqueInput = {
    conta?: InputMaybe<ContaBancariaContaCompoundUniqueInput>;
    id?: InputMaybe<Scalars["Int"]>;
};

export type Contato = {
    __typename?: "Contato";
    _count?: Maybe<ContatoCount>;
    cargo?: Maybe<Scalars["String"]>;
    cedentes: Array<CedenteContato>;
    createdAt: Scalars["DateTime"];
    email?: Maybe<Scalars["String"]>;
    firstName?: Maybe<Scalars["String"]>;
    fone?: Maybe<Scalars["String"]>;
    historico: Array<ContatoAudit>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    inscrito: Scalars["Boolean"];
    lastName?: Maybe<Scalars["String"]>;
    minibancos: Array<MinibancoContato>;
    respId?: Maybe<Scalars["Int"]>;
    responsavel?: Maybe<Usuario>;
    sacados: Array<SacadoContato>;
    updatedAt: Scalars["DateTime"];
    verificado: Scalars["Boolean"];
};

export type ContatoCedentesArgs = {
    cursor?: InputMaybe<CedenteContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteContatoWhereInput>;
};

export type ContatoHistoricoArgs = {
    cursor?: InputMaybe<ContatoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<ContatoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ContatoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContatoAuditWhereInput>;
};

export type ContatoMinibancosArgs = {
    cursor?: InputMaybe<MinibancoContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoContatoWhereInput>;
};

export type ContatoSacadosArgs = {
    cursor?: InputMaybe<SacadoContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<SacadoContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<SacadoContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoContatoWhereInput>;
};

export type ContatoAudit = {
    __typename?: "ContatoAudit";
    action: AuditAcoes;
    contato: Contato;
    contatoId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuario?: Maybe<Usuario>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ContatoAuditAvgAggregate = {
    __typename?: "ContatoAuditAvgAggregate";
    contatoId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type ContatoAuditAvgOrderByAggregateInput = {
    contatoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ContatoAuditCountAggregate = {
    __typename?: "ContatoAuditCountAggregate";
    _all: Scalars["Int"];
    action: Scalars["Int"];
    contatoId: Scalars["Int"];
    createdAt: Scalars["Int"];
    field: Scalars["Int"];
    id: Scalars["Int"];
    ipUsuario: Scalars["Int"];
    newValue: Scalars["Int"];
    oldValue: Scalars["Int"];
    service: Scalars["Int"];
    userAgent: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type ContatoAuditCountOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ContatoAuditCreateInput = {
    action?: InputMaybe<AuditAcoes>;
    contato: ContatoCreateNestedOneWithoutHistoricoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutContatoAuditInput>;
};

export type ContatoAuditCreateManyContatoInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type ContatoAuditCreateManyContatoInputEnvelope = {
    data: Array<ContatoAuditCreateManyContatoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ContatoAuditCreateManyInput = {
    action?: InputMaybe<AuditAcoes>;
    contatoId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type ContatoAuditCreateManyUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    contatoId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type ContatoAuditCreateManyUsuarioInputEnvelope = {
    data: Array<ContatoAuditCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ContatoAuditCreateNestedManyWithoutContatoInput = {
    connect?: InputMaybe<Array<ContatoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ContatoAuditCreateOrConnectWithoutContatoInput>>;
    create?: InputMaybe<Array<ContatoAuditCreateWithoutContatoInput>>;
    createMany?: InputMaybe<ContatoAuditCreateManyContatoInputEnvelope>;
};

export type ContatoAuditCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<ContatoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ContatoAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<ContatoAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<ContatoAuditCreateManyUsuarioInputEnvelope>;
};

export type ContatoAuditCreateOrConnectWithoutContatoInput = {
    create: ContatoAuditCreateWithoutContatoInput;
    where: ContatoAuditWhereUniqueInput;
};

export type ContatoAuditCreateOrConnectWithoutUsuarioInput = {
    create: ContatoAuditCreateWithoutUsuarioInput;
    where: ContatoAuditWhereUniqueInput;
};

export type ContatoAuditCreateWithoutContatoInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutContatoAuditInput>;
};

export type ContatoAuditCreateWithoutUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    contato: ContatoCreateNestedOneWithoutHistoricoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type ContatoAuditGroupBy = {
    __typename?: "ContatoAuditGroupBy";
    _avg?: Maybe<ContatoAuditAvgAggregate>;
    _count?: Maybe<ContatoAuditCountAggregate>;
    _max?: Maybe<ContatoAuditMaxAggregate>;
    _min?: Maybe<ContatoAuditMinAggregate>;
    _sum?: Maybe<ContatoAuditSumAggregate>;
    action: AuditAcoes;
    contatoId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ContatoAuditListRelationFilter = {
    every?: InputMaybe<ContatoAuditWhereInput>;
    none?: InputMaybe<ContatoAuditWhereInput>;
    some?: InputMaybe<ContatoAuditWhereInput>;
};

export type ContatoAuditMaxAggregate = {
    __typename?: "ContatoAuditMaxAggregate";
    action?: Maybe<AuditAcoes>;
    contatoId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ContatoAuditMaxOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ContatoAuditMinAggregate = {
    __typename?: "ContatoAuditMinAggregate";
    action?: Maybe<AuditAcoes>;
    contatoId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ContatoAuditMinOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ContatoAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type ContatoAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<ContatoAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<ContatoAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<ContatoAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<ContatoAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<ContatoAuditSumOrderByAggregateInput>;
    action?: InputMaybe<SortOrder>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ContatoAuditOrderByWithRelationInput = {
    action?: InputMaybe<SortOrder>;
    contato?: InputMaybe<ContatoOrderByWithRelationInput>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum ContatoAuditScalarFieldEnum {
    Action = "action",
    ContatoId = "contatoId",
    CreatedAt = "createdAt",
    Field = "field",
    Id = "id",
    IpUsuario = "ipUsuario",
    NewValue = "newValue",
    OldValue = "oldValue",
    Service = "service",
    UserAgent = "userAgent",
    UsuarioId = "usuarioId",
}

export type ContatoAuditScalarWhereInput = {
    AND?: InputMaybe<Array<ContatoAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<ContatoAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<ContatoAuditScalarWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    contatoId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type ContatoAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<ContatoAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<ContatoAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<ContatoAuditScalarWhereWithAggregatesInput>>;
    action?: InputMaybe<EnumAuditAcoesWithAggregatesFilter>;
    contatoId?: InputMaybe<IntWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    field?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ipUsuario?: InputMaybe<StringWithAggregatesFilter>;
    newValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type ContatoAuditSumAggregate = {
    __typename?: "ContatoAuditSumAggregate";
    contatoId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ContatoAuditSumOrderByAggregateInput = {
    contatoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ContatoAuditUpdateInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    contato?: InputMaybe<ContatoUpdateOneRequiredWithoutHistoricoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutContatoAuditNestedInput>;
};

export type ContatoAuditUpdateManyMutationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ContatoAuditUpdateManyWithWhereWithoutContatoInput = {
    data: ContatoAuditUpdateManyMutationInput;
    where: ContatoAuditScalarWhereInput;
};

export type ContatoAuditUpdateManyWithWhereWithoutUsuarioInput = {
    data: ContatoAuditUpdateManyMutationInput;
    where: ContatoAuditScalarWhereInput;
};

export type ContatoAuditUpdateManyWithoutContatoNestedInput = {
    connect?: InputMaybe<Array<ContatoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ContatoAuditCreateOrConnectWithoutContatoInput>>;
    create?: InputMaybe<Array<ContatoAuditCreateWithoutContatoInput>>;
    createMany?: InputMaybe<ContatoAuditCreateManyContatoInputEnvelope>;
    delete?: InputMaybe<Array<ContatoAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ContatoAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ContatoAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<ContatoAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<ContatoAuditUpdateWithWhereUniqueWithoutContatoInput>>;
    updateMany?: InputMaybe<Array<ContatoAuditUpdateManyWithWhereWithoutContatoInput>>;
    upsert?: InputMaybe<Array<ContatoAuditUpsertWithWhereUniqueWithoutContatoInput>>;
};

export type ContatoAuditUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<ContatoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ContatoAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<ContatoAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<ContatoAuditCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<ContatoAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ContatoAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ContatoAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<ContatoAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<ContatoAuditUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<ContatoAuditUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<ContatoAuditUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type ContatoAuditUpdateWithWhereUniqueWithoutContatoInput = {
    data: ContatoAuditUpdateWithoutContatoInput;
    where: ContatoAuditWhereUniqueInput;
};

export type ContatoAuditUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: ContatoAuditUpdateWithoutUsuarioInput;
    where: ContatoAuditWhereUniqueInput;
};

export type ContatoAuditUpdateWithoutContatoInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutContatoAuditNestedInput>;
};

export type ContatoAuditUpdateWithoutUsuarioInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    contato?: InputMaybe<ContatoUpdateOneRequiredWithoutHistoricoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ContatoAuditUpsertWithWhereUniqueWithoutContatoInput = {
    create: ContatoAuditCreateWithoutContatoInput;
    update: ContatoAuditUpdateWithoutContatoInput;
    where: ContatoAuditWhereUniqueInput;
};

export type ContatoAuditUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: ContatoAuditCreateWithoutUsuarioInput;
    update: ContatoAuditUpdateWithoutUsuarioInput;
    where: ContatoAuditWhereUniqueInput;
};

export type ContatoAuditWhereInput = {
    AND?: InputMaybe<Array<ContatoAuditWhereInput>>;
    NOT?: InputMaybe<Array<ContatoAuditWhereInput>>;
    OR?: InputMaybe<Array<ContatoAuditWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    contato?: InputMaybe<ContatoRelationFilter>;
    contatoId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type ContatoAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type ContatoAvgAggregate = {
    __typename?: "ContatoAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    respId?: Maybe<Scalars["Float"]>;
};

export type ContatoAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    respId?: InputMaybe<SortOrder>;
};

export type ContatoCount = {
    __typename?: "ContatoCount";
    cedentes: Scalars["Int"];
    historico: Scalars["Int"];
    minibancos: Scalars["Int"];
    sacados: Scalars["Int"];
};

export type ContatoCountAggregate = {
    __typename?: "ContatoCountAggregate";
    _all: Scalars["Int"];
    cargo: Scalars["Int"];
    createdAt: Scalars["Int"];
    email: Scalars["Int"];
    firstName: Scalars["Int"];
    fone: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    inscrito: Scalars["Int"];
    lastName: Scalars["Int"];
    respId: Scalars["Int"];
    updatedAt: Scalars["Int"];
    verificado: Scalars["Int"];
};

export type ContatoCountOrderByAggregateInput = {
    cargo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    firstName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    inscrito?: InputMaybe<SortOrder>;
    lastName?: InputMaybe<SortOrder>;
    respId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    verificado?: InputMaybe<SortOrder>;
};

export type ContatoCreateInput = {
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<CedenteContatoCreateNestedManyWithoutContatoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email?: InputMaybe<Scalars["String"]>;
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<ContatoAuditCreateNestedManyWithoutContatoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    inscrito?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibancos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutContatoInput>;
    responsavel?: InputMaybe<UsuarioCreateNestedOneWithoutRespContatoInput>;
    sacados?: InputMaybe<SacadoContatoCreateNestedManyWithoutContatoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    verificado?: InputMaybe<Scalars["Boolean"]>;
};

export type ContatoCreateManyInput = {
    cargo?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email?: InputMaybe<Scalars["String"]>;
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    inscrito?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    respId?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    verificado?: InputMaybe<Scalars["Boolean"]>;
};

export type ContatoCreateManyResponsavelInput = {
    cargo?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email?: InputMaybe<Scalars["String"]>;
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    inscrito?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    verificado?: InputMaybe<Scalars["Boolean"]>;
};

export type ContatoCreateManyResponsavelInputEnvelope = {
    data: Array<ContatoCreateManyResponsavelInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ContatoCreateNestedManyWithoutResponsavelInput = {
    connect?: InputMaybe<Array<ContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ContatoCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<ContatoCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<ContatoCreateManyResponsavelInputEnvelope>;
};

export type ContatoCreateNestedOneWithoutCedentesInput = {
    connect?: InputMaybe<ContatoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ContatoCreateOrConnectWithoutCedentesInput>;
    create?: InputMaybe<ContatoCreateWithoutCedentesInput>;
};

export type ContatoCreateNestedOneWithoutHistoricoInput = {
    connect?: InputMaybe<ContatoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ContatoCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<ContatoCreateWithoutHistoricoInput>;
};

export type ContatoCreateNestedOneWithoutMinibancosInput = {
    connect?: InputMaybe<ContatoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ContatoCreateOrConnectWithoutMinibancosInput>;
    create?: InputMaybe<ContatoCreateWithoutMinibancosInput>;
};

export type ContatoCreateNestedOneWithoutSacadosInput = {
    connect?: InputMaybe<ContatoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ContatoCreateOrConnectWithoutSacadosInput>;
    create?: InputMaybe<ContatoCreateWithoutSacadosInput>;
};

export type ContatoCreateOrConnectWithoutCedentesInput = {
    create: ContatoCreateWithoutCedentesInput;
    where: ContatoWhereUniqueInput;
};

export type ContatoCreateOrConnectWithoutHistoricoInput = {
    create: ContatoCreateWithoutHistoricoInput;
    where: ContatoWhereUniqueInput;
};

export type ContatoCreateOrConnectWithoutMinibancosInput = {
    create: ContatoCreateWithoutMinibancosInput;
    where: ContatoWhereUniqueInput;
};

export type ContatoCreateOrConnectWithoutResponsavelInput = {
    create: ContatoCreateWithoutResponsavelInput;
    where: ContatoWhereUniqueInput;
};

export type ContatoCreateOrConnectWithoutSacadosInput = {
    create: ContatoCreateWithoutSacadosInput;
    where: ContatoWhereUniqueInput;
};

export type ContatoCreateWithoutCedentesInput = {
    cargo?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email?: InputMaybe<Scalars["String"]>;
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<ContatoAuditCreateNestedManyWithoutContatoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    inscrito?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibancos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutContatoInput>;
    responsavel?: InputMaybe<UsuarioCreateNestedOneWithoutRespContatoInput>;
    sacados?: InputMaybe<SacadoContatoCreateNestedManyWithoutContatoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    verificado?: InputMaybe<Scalars["Boolean"]>;
};

export type ContatoCreateWithoutHistoricoInput = {
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<CedenteContatoCreateNestedManyWithoutContatoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email?: InputMaybe<Scalars["String"]>;
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    inscrito?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibancos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutContatoInput>;
    responsavel?: InputMaybe<UsuarioCreateNestedOneWithoutRespContatoInput>;
    sacados?: InputMaybe<SacadoContatoCreateNestedManyWithoutContatoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    verificado?: InputMaybe<Scalars["Boolean"]>;
};

export type ContatoCreateWithoutMinibancosInput = {
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<CedenteContatoCreateNestedManyWithoutContatoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email?: InputMaybe<Scalars["String"]>;
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<ContatoAuditCreateNestedManyWithoutContatoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    inscrito?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    responsavel?: InputMaybe<UsuarioCreateNestedOneWithoutRespContatoInput>;
    sacados?: InputMaybe<SacadoContatoCreateNestedManyWithoutContatoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    verificado?: InputMaybe<Scalars["Boolean"]>;
};

export type ContatoCreateWithoutResponsavelInput = {
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<CedenteContatoCreateNestedManyWithoutContatoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email?: InputMaybe<Scalars["String"]>;
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<ContatoAuditCreateNestedManyWithoutContatoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    inscrito?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibancos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutContatoInput>;
    sacados?: InputMaybe<SacadoContatoCreateNestedManyWithoutContatoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    verificado?: InputMaybe<Scalars["Boolean"]>;
};

export type ContatoCreateWithoutSacadosInput = {
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<CedenteContatoCreateNestedManyWithoutContatoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email?: InputMaybe<Scalars["String"]>;
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<ContatoAuditCreateNestedManyWithoutContatoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    inscrito?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibancos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutContatoInput>;
    responsavel?: InputMaybe<UsuarioCreateNestedOneWithoutRespContatoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    verificado?: InputMaybe<Scalars["Boolean"]>;
};

export type ContatoGroupBy = {
    __typename?: "ContatoGroupBy";
    _avg?: Maybe<ContatoAvgAggregate>;
    _count?: Maybe<ContatoCountAggregate>;
    _max?: Maybe<ContatoMaxAggregate>;
    _min?: Maybe<ContatoMinAggregate>;
    _sum?: Maybe<ContatoSumAggregate>;
    cargo?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    email?: Maybe<Scalars["String"]>;
    firstName?: Maybe<Scalars["String"]>;
    fone?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    inscrito: Scalars["Boolean"];
    lastName?: Maybe<Scalars["String"]>;
    respId?: Maybe<Scalars["Int"]>;
    updatedAt: Scalars["DateTime"];
    verificado: Scalars["Boolean"];
};

export type ContatoListRelationFilter = {
    every?: InputMaybe<ContatoWhereInput>;
    none?: InputMaybe<ContatoWhereInput>;
    some?: InputMaybe<ContatoWhereInput>;
};

export type ContatoMaxAggregate = {
    __typename?: "ContatoMaxAggregate";
    cargo?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    email?: Maybe<Scalars["String"]>;
    firstName?: Maybe<Scalars["String"]>;
    fone?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    inscrito?: Maybe<Scalars["Boolean"]>;
    lastName?: Maybe<Scalars["String"]>;
    respId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    verificado?: Maybe<Scalars["Boolean"]>;
};

export type ContatoMaxOrderByAggregateInput = {
    cargo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    firstName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    inscrito?: InputMaybe<SortOrder>;
    lastName?: InputMaybe<SortOrder>;
    respId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    verificado?: InputMaybe<SortOrder>;
};

export type ContatoMinAggregate = {
    __typename?: "ContatoMinAggregate";
    cargo?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    email?: Maybe<Scalars["String"]>;
    firstName?: Maybe<Scalars["String"]>;
    fone?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    inscrito?: Maybe<Scalars["Boolean"]>;
    lastName?: Maybe<Scalars["String"]>;
    respId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    verificado?: Maybe<Scalars["Boolean"]>;
};

export type ContatoMinOrderByAggregateInput = {
    cargo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    firstName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    inscrito?: InputMaybe<SortOrder>;
    lastName?: InputMaybe<SortOrder>;
    respId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    verificado?: InputMaybe<SortOrder>;
};

export type ContatoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type ContatoOrderByWithAggregationInput = {
    _avg?: InputMaybe<ContatoAvgOrderByAggregateInput>;
    _count?: InputMaybe<ContatoCountOrderByAggregateInput>;
    _max?: InputMaybe<ContatoMaxOrderByAggregateInput>;
    _min?: InputMaybe<ContatoMinOrderByAggregateInput>;
    _sum?: InputMaybe<ContatoSumOrderByAggregateInput>;
    cargo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    firstName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    inscrito?: InputMaybe<SortOrder>;
    lastName?: InputMaybe<SortOrder>;
    respId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    verificado?: InputMaybe<SortOrder>;
};

export type ContatoOrderByWithRelationInput = {
    cargo?: InputMaybe<SortOrder>;
    cedentes?: InputMaybe<CedenteContatoOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    firstName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    historico?: InputMaybe<ContatoAuditOrderByRelationAggregateInput>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    inscrito?: InputMaybe<SortOrder>;
    lastName?: InputMaybe<SortOrder>;
    minibancos?: InputMaybe<MinibancoContatoOrderByRelationAggregateInput>;
    respId?: InputMaybe<SortOrder>;
    responsavel?: InputMaybe<UsuarioOrderByWithRelationInput>;
    sacados?: InputMaybe<SacadoContatoOrderByRelationAggregateInput>;
    updatedAt?: InputMaybe<SortOrder>;
    verificado?: InputMaybe<SortOrder>;
};

export type ContatoRelationFilter = {
    is?: InputMaybe<ContatoWhereInput>;
    isNot?: InputMaybe<ContatoWhereInput>;
};

export enum ContatoScalarFieldEnum {
    Cargo = "cargo",
    CreatedAt = "createdAt",
    Email = "email",
    FirstName = "firstName",
    Fone = "fone",
    Id = "id",
    Inativo = "inativo",
    Inscrito = "inscrito",
    LastName = "lastName",
    RespId = "respId",
    UpdatedAt = "updatedAt",
    Verificado = "verificado",
}

export type ContatoScalarWhereInput = {
    AND?: InputMaybe<Array<ContatoScalarWhereInput>>;
    NOT?: InputMaybe<Array<ContatoScalarWhereInput>>;
    OR?: InputMaybe<Array<ContatoScalarWhereInput>>;
    cargo?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    email?: InputMaybe<StringNullableFilter>;
    firstName?: InputMaybe<StringNullableFilter>;
    fone?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    inscrito?: InputMaybe<BoolFilter>;
    lastName?: InputMaybe<StringNullableFilter>;
    respId?: InputMaybe<IntNullableFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    verificado?: InputMaybe<BoolFilter>;
};

export type ContatoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<ContatoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<ContatoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<ContatoScalarWhereWithAggregatesInput>>;
    cargo?: InputMaybe<StringNullableWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    email?: InputMaybe<StringNullableWithAggregatesFilter>;
    firstName?: InputMaybe<StringNullableWithAggregatesFilter>;
    fone?: InputMaybe<StringNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    inscrito?: InputMaybe<BoolWithAggregatesFilter>;
    lastName?: InputMaybe<StringNullableWithAggregatesFilter>;
    respId?: InputMaybe<IntNullableWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    verificado?: InputMaybe<BoolWithAggregatesFilter>;
};

export type ContatoSumAggregate = {
    __typename?: "ContatoSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    respId?: Maybe<Scalars["Int"]>;
};

export type ContatoSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    respId?: InputMaybe<SortOrder>;
};

export type ContatoUpdateInput = {
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<CedenteContatoUpdateManyWithoutContatoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<ContatoAuditUpdateManyWithoutContatoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    inscrito?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoContatoUpdateManyWithoutContatoNestedInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneWithoutRespContatoNestedInput>;
    sacados?: InputMaybe<SacadoContatoUpdateManyWithoutContatoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    verificado?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ContatoUpdateManyMutationInput = {
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    inscrito?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    verificado?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ContatoUpdateManyWithWhereWithoutResponsavelInput = {
    data: ContatoUpdateManyMutationInput;
    where: ContatoScalarWhereInput;
};

export type ContatoUpdateManyWithoutResponsavelNestedInput = {
    connect?: InputMaybe<Array<ContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ContatoCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<ContatoCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<ContatoCreateManyResponsavelInputEnvelope>;
    delete?: InputMaybe<Array<ContatoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ContatoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ContatoWhereUniqueInput>>;
    set?: InputMaybe<Array<ContatoWhereUniqueInput>>;
    update?: InputMaybe<Array<ContatoUpdateWithWhereUniqueWithoutResponsavelInput>>;
    updateMany?: InputMaybe<Array<ContatoUpdateManyWithWhereWithoutResponsavelInput>>;
    upsert?: InputMaybe<Array<ContatoUpsertWithWhereUniqueWithoutResponsavelInput>>;
};

export type ContatoUpdateOneRequiredWithoutCedentesNestedInput = {
    connect?: InputMaybe<ContatoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ContatoCreateOrConnectWithoutCedentesInput>;
    create?: InputMaybe<ContatoCreateWithoutCedentesInput>;
    update?: InputMaybe<ContatoUpdateWithoutCedentesInput>;
    upsert?: InputMaybe<ContatoUpsertWithoutCedentesInput>;
};

export type ContatoUpdateOneRequiredWithoutHistoricoNestedInput = {
    connect?: InputMaybe<ContatoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ContatoCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<ContatoCreateWithoutHistoricoInput>;
    update?: InputMaybe<ContatoUpdateWithoutHistoricoInput>;
    upsert?: InputMaybe<ContatoUpsertWithoutHistoricoInput>;
};

export type ContatoUpdateOneRequiredWithoutMinibancosNestedInput = {
    connect?: InputMaybe<ContatoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ContatoCreateOrConnectWithoutMinibancosInput>;
    create?: InputMaybe<ContatoCreateWithoutMinibancosInput>;
    update?: InputMaybe<ContatoUpdateWithoutMinibancosInput>;
    upsert?: InputMaybe<ContatoUpsertWithoutMinibancosInput>;
};

export type ContatoUpdateOneRequiredWithoutSacadosNestedInput = {
    connect?: InputMaybe<ContatoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ContatoCreateOrConnectWithoutSacadosInput>;
    create?: InputMaybe<ContatoCreateWithoutSacadosInput>;
    update?: InputMaybe<ContatoUpdateWithoutSacadosInput>;
    upsert?: InputMaybe<ContatoUpsertWithoutSacadosInput>;
};

export type ContatoUpdateWithWhereUniqueWithoutResponsavelInput = {
    data: ContatoUpdateWithoutResponsavelInput;
    where: ContatoWhereUniqueInput;
};

export type ContatoUpdateWithoutCedentesInput = {
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<ContatoAuditUpdateManyWithoutContatoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    inscrito?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoContatoUpdateManyWithoutContatoNestedInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneWithoutRespContatoNestedInput>;
    sacados?: InputMaybe<SacadoContatoUpdateManyWithoutContatoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    verificado?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ContatoUpdateWithoutHistoricoInput = {
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<CedenteContatoUpdateManyWithoutContatoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    inscrito?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoContatoUpdateManyWithoutContatoNestedInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneWithoutRespContatoNestedInput>;
    sacados?: InputMaybe<SacadoContatoUpdateManyWithoutContatoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    verificado?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ContatoUpdateWithoutMinibancosInput = {
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<CedenteContatoUpdateManyWithoutContatoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<ContatoAuditUpdateManyWithoutContatoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    inscrito?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneWithoutRespContatoNestedInput>;
    sacados?: InputMaybe<SacadoContatoUpdateManyWithoutContatoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    verificado?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ContatoUpdateWithoutResponsavelInput = {
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<CedenteContatoUpdateManyWithoutContatoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<ContatoAuditUpdateManyWithoutContatoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    inscrito?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoContatoUpdateManyWithoutContatoNestedInput>;
    sacados?: InputMaybe<SacadoContatoUpdateManyWithoutContatoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    verificado?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ContatoUpdateWithoutSacadosInput = {
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<CedenteContatoUpdateManyWithoutContatoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<ContatoAuditUpdateManyWithoutContatoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    inscrito?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoContatoUpdateManyWithoutContatoNestedInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneWithoutRespContatoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    verificado?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ContatoUpsertWithWhereUniqueWithoutResponsavelInput = {
    create: ContatoCreateWithoutResponsavelInput;
    update: ContatoUpdateWithoutResponsavelInput;
    where: ContatoWhereUniqueInput;
};

export type ContatoUpsertWithoutCedentesInput = {
    create: ContatoCreateWithoutCedentesInput;
    update: ContatoUpdateWithoutCedentesInput;
};

export type ContatoUpsertWithoutHistoricoInput = {
    create: ContatoCreateWithoutHistoricoInput;
    update: ContatoUpdateWithoutHistoricoInput;
};

export type ContatoUpsertWithoutMinibancosInput = {
    create: ContatoCreateWithoutMinibancosInput;
    update: ContatoUpdateWithoutMinibancosInput;
};

export type ContatoUpsertWithoutSacadosInput = {
    create: ContatoCreateWithoutSacadosInput;
    update: ContatoUpdateWithoutSacadosInput;
};

export type ContatoWhereInput = {
    AND?: InputMaybe<Array<ContatoWhereInput>>;
    NOT?: InputMaybe<Array<ContatoWhereInput>>;
    OR?: InputMaybe<Array<ContatoWhereInput>>;
    cargo?: InputMaybe<StringNullableFilter>;
    cedentes?: InputMaybe<CedenteContatoListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    email?: InputMaybe<StringNullableFilter>;
    firstName?: InputMaybe<StringNullableFilter>;
    fone?: InputMaybe<StringNullableFilter>;
    historico?: InputMaybe<ContatoAuditListRelationFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    inscrito?: InputMaybe<BoolFilter>;
    lastName?: InputMaybe<StringNullableFilter>;
    minibancos?: InputMaybe<MinibancoContatoListRelationFilter>;
    respId?: InputMaybe<IntNullableFilter>;
    responsavel?: InputMaybe<UsuarioRelationFilter>;
    sacados?: InputMaybe<SacadoContatoListRelationFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    verificado?: InputMaybe<BoolFilter>;
};

export type ContatoWhereUniqueInput = {
    email?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
};

export enum CrmType {
    HubSpot = "HUB_SPOT",
}

export type DateTimeFieldUpdateOperationsInput = {
    set?: InputMaybe<Scalars["DateTime"]>;
};

export type DateTimeFilter = {
    equals?: InputMaybe<Scalars["DateTime"]>;
    gt?: InputMaybe<Scalars["DateTime"]>;
    gte?: InputMaybe<Scalars["DateTime"]>;
    in?: InputMaybe<Array<Scalars["DateTime"]>>;
    lt?: InputMaybe<Scalars["DateTime"]>;
    lte?: InputMaybe<Scalars["DateTime"]>;
    not?: InputMaybe<NestedDateTimeFilter>;
    notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type DateTimeNullableFilter = {
    equals?: InputMaybe<Scalars["DateTime"]>;
    gt?: InputMaybe<Scalars["DateTime"]>;
    gte?: InputMaybe<Scalars["DateTime"]>;
    in?: InputMaybe<Array<Scalars["DateTime"]>>;
    lt?: InputMaybe<Scalars["DateTime"]>;
    lte?: InputMaybe<Scalars["DateTime"]>;
    not?: InputMaybe<NestedDateTimeNullableFilter>;
    notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type DateTimeNullableWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedDateTimeNullableFilter>;
    _min?: InputMaybe<NestedDateTimeNullableFilter>;
    equals?: InputMaybe<Scalars["DateTime"]>;
    gt?: InputMaybe<Scalars["DateTime"]>;
    gte?: InputMaybe<Scalars["DateTime"]>;
    in?: InputMaybe<Array<Scalars["DateTime"]>>;
    lt?: InputMaybe<Scalars["DateTime"]>;
    lte?: InputMaybe<Scalars["DateTime"]>;
    not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type DateTimeWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedDateTimeFilter>;
    _min?: InputMaybe<NestedDateTimeFilter>;
    equals?: InputMaybe<Scalars["DateTime"]>;
    gt?: InputMaybe<Scalars["DateTime"]>;
    gte?: InputMaybe<Scalars["DateTime"]>;
    in?: InputMaybe<Array<Scalars["DateTime"]>>;
    lt?: InputMaybe<Scalars["DateTime"]>;
    lte?: InputMaybe<Scalars["DateTime"]>;
    not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type Debenturista = {
    __typename?: "Debenturista";
    _count?: Maybe<DebenturistaCount>;
    createdAt: Scalars["DateTime"];
    document: Scalars["String"];
    historico: Array<DebenturistaAudit>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    name: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type DebenturistaHistoricoArgs = {
    cursor?: InputMaybe<DebenturistaAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<DebenturistaAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<DebenturistaAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<DebenturistaAuditWhereInput>;
};

export type DebenturistaAudit = {
    __typename?: "DebenturistaAudit";
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    debenturista: Debenturista;
    debenturistaId: Scalars["Int"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuario?: Maybe<Usuario>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type DebenturistaAuditAvgAggregate = {
    __typename?: "DebenturistaAuditAvgAggregate";
    debenturistaId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type DebenturistaAuditAvgOrderByAggregateInput = {
    debenturistaId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type DebenturistaAuditCountAggregate = {
    __typename?: "DebenturistaAuditCountAggregate";
    _all: Scalars["Int"];
    action: Scalars["Int"];
    createdAt: Scalars["Int"];
    debenturistaId: Scalars["Int"];
    field: Scalars["Int"];
    id: Scalars["Int"];
    ipUsuario: Scalars["Int"];
    newValue: Scalars["Int"];
    oldValue: Scalars["Int"];
    service: Scalars["Int"];
    userAgent: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type DebenturistaAuditCountOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    debenturistaId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type DebenturistaAuditCreateInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturista: DebenturistaCreateNestedOneWithoutHistoricoInput;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutDebenturistaAuditInput>;
};

export type DebenturistaAuditCreateManyDebenturistaInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type DebenturistaAuditCreateManyDebenturistaInputEnvelope = {
    data: Array<DebenturistaAuditCreateManyDebenturistaInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type DebenturistaAuditCreateManyInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistaId: Scalars["Int"];
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type DebenturistaAuditCreateManyUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistaId: Scalars["Int"];
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type DebenturistaAuditCreateManyUsuarioInputEnvelope = {
    data: Array<DebenturistaAuditCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type DebenturistaAuditCreateNestedManyWithoutDebenturistaInput = {
    connect?: InputMaybe<Array<DebenturistaAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<DebenturistaAuditCreateOrConnectWithoutDebenturistaInput>>;
    create?: InputMaybe<Array<DebenturistaAuditCreateWithoutDebenturistaInput>>;
    createMany?: InputMaybe<DebenturistaAuditCreateManyDebenturistaInputEnvelope>;
};

export type DebenturistaAuditCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<DebenturistaAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<DebenturistaAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<DebenturistaAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<DebenturistaAuditCreateManyUsuarioInputEnvelope>;
};

export type DebenturistaAuditCreateOrConnectWithoutDebenturistaInput = {
    create: DebenturistaAuditCreateWithoutDebenturistaInput;
    where: DebenturistaAuditWhereUniqueInput;
};

export type DebenturistaAuditCreateOrConnectWithoutUsuarioInput = {
    create: DebenturistaAuditCreateWithoutUsuarioInput;
    where: DebenturistaAuditWhereUniqueInput;
};

export type DebenturistaAuditCreateWithoutDebenturistaInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutDebenturistaAuditInput>;
};

export type DebenturistaAuditCreateWithoutUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturista: DebenturistaCreateNestedOneWithoutHistoricoInput;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type DebenturistaAuditGroupBy = {
    __typename?: "DebenturistaAuditGroupBy";
    _avg?: Maybe<DebenturistaAuditAvgAggregate>;
    _count?: Maybe<DebenturistaAuditCountAggregate>;
    _max?: Maybe<DebenturistaAuditMaxAggregate>;
    _min?: Maybe<DebenturistaAuditMinAggregate>;
    _sum?: Maybe<DebenturistaAuditSumAggregate>;
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    debenturistaId: Scalars["Int"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type DebenturistaAuditListRelationFilter = {
    every?: InputMaybe<DebenturistaAuditWhereInput>;
    none?: InputMaybe<DebenturistaAuditWhereInput>;
    some?: InputMaybe<DebenturistaAuditWhereInput>;
};

export type DebenturistaAuditMaxAggregate = {
    __typename?: "DebenturistaAuditMaxAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    debenturistaId?: Maybe<Scalars["Int"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type DebenturistaAuditMaxOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    debenturistaId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type DebenturistaAuditMinAggregate = {
    __typename?: "DebenturistaAuditMinAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    debenturistaId?: Maybe<Scalars["Int"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type DebenturistaAuditMinOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    debenturistaId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type DebenturistaAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type DebenturistaAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<DebenturistaAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<DebenturistaAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<DebenturistaAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<DebenturistaAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<DebenturistaAuditSumOrderByAggregateInput>;
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    debenturistaId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type DebenturistaAuditOrderByWithRelationInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    debenturista?: InputMaybe<DebenturistaOrderByWithRelationInput>;
    debenturistaId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum DebenturistaAuditScalarFieldEnum {
    Action = "action",
    CreatedAt = "createdAt",
    DebenturistaId = "debenturistaId",
    Field = "field",
    Id = "id",
    IpUsuario = "ipUsuario",
    NewValue = "newValue",
    OldValue = "oldValue",
    Service = "service",
    UserAgent = "userAgent",
    UsuarioId = "usuarioId",
}

export type DebenturistaAuditScalarWhereInput = {
    AND?: InputMaybe<Array<DebenturistaAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<DebenturistaAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<DebenturistaAuditScalarWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    debenturistaId?: InputMaybe<IntFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type DebenturistaAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<DebenturistaAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<DebenturistaAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<DebenturistaAuditScalarWhereWithAggregatesInput>>;
    action?: InputMaybe<EnumAuditAcoesWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    debenturistaId?: InputMaybe<IntWithAggregatesFilter>;
    field?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ipUsuario?: InputMaybe<StringWithAggregatesFilter>;
    newValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type DebenturistaAuditSumAggregate = {
    __typename?: "DebenturistaAuditSumAggregate";
    debenturistaId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type DebenturistaAuditSumOrderByAggregateInput = {
    debenturistaId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type DebenturistaAuditUpdateInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturista?: InputMaybe<DebenturistaUpdateOneRequiredWithoutHistoricoNestedInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutDebenturistaAuditNestedInput>;
};

export type DebenturistaAuditUpdateManyMutationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DebenturistaAuditUpdateManyWithWhereWithoutDebenturistaInput = {
    data: DebenturistaAuditUpdateManyMutationInput;
    where: DebenturistaAuditScalarWhereInput;
};

export type DebenturistaAuditUpdateManyWithWhereWithoutUsuarioInput = {
    data: DebenturistaAuditUpdateManyMutationInput;
    where: DebenturistaAuditScalarWhereInput;
};

export type DebenturistaAuditUpdateManyWithoutDebenturistaNestedInput = {
    connect?: InputMaybe<Array<DebenturistaAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<DebenturistaAuditCreateOrConnectWithoutDebenturistaInput>>;
    create?: InputMaybe<Array<DebenturistaAuditCreateWithoutDebenturistaInput>>;
    createMany?: InputMaybe<DebenturistaAuditCreateManyDebenturistaInputEnvelope>;
    delete?: InputMaybe<Array<DebenturistaAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<DebenturistaAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<DebenturistaAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<DebenturistaAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<DebenturistaAuditUpdateWithWhereUniqueWithoutDebenturistaInput>>;
    updateMany?: InputMaybe<Array<DebenturistaAuditUpdateManyWithWhereWithoutDebenturistaInput>>;
    upsert?: InputMaybe<Array<DebenturistaAuditUpsertWithWhereUniqueWithoutDebenturistaInput>>;
};

export type DebenturistaAuditUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<DebenturistaAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<DebenturistaAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<DebenturistaAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<DebenturistaAuditCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<DebenturistaAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<DebenturistaAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<DebenturistaAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<DebenturistaAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<DebenturistaAuditUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<DebenturistaAuditUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<DebenturistaAuditUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type DebenturistaAuditUpdateWithWhereUniqueWithoutDebenturistaInput = {
    data: DebenturistaAuditUpdateWithoutDebenturistaInput;
    where: DebenturistaAuditWhereUniqueInput;
};

export type DebenturistaAuditUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: DebenturistaAuditUpdateWithoutUsuarioInput;
    where: DebenturistaAuditWhereUniqueInput;
};

export type DebenturistaAuditUpdateWithoutDebenturistaInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutDebenturistaAuditNestedInput>;
};

export type DebenturistaAuditUpdateWithoutUsuarioInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturista?: InputMaybe<DebenturistaUpdateOneRequiredWithoutHistoricoNestedInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DebenturistaAuditUpsertWithWhereUniqueWithoutDebenturistaInput = {
    create: DebenturistaAuditCreateWithoutDebenturistaInput;
    update: DebenturistaAuditUpdateWithoutDebenturistaInput;
    where: DebenturistaAuditWhereUniqueInput;
};

export type DebenturistaAuditUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: DebenturistaAuditCreateWithoutUsuarioInput;
    update: DebenturistaAuditUpdateWithoutUsuarioInput;
    where: DebenturistaAuditWhereUniqueInput;
};

export type DebenturistaAuditWhereInput = {
    AND?: InputMaybe<Array<DebenturistaAuditWhereInput>>;
    NOT?: InputMaybe<Array<DebenturistaAuditWhereInput>>;
    OR?: InputMaybe<Array<DebenturistaAuditWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    debenturista?: InputMaybe<DebenturistaRelationFilter>;
    debenturistaId?: InputMaybe<IntFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type DebenturistaAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type DebenturistaAvgAggregate = {
    __typename?: "DebenturistaAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
};

export type DebenturistaAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
};

export type DebenturistaCount = {
    __typename?: "DebenturistaCount";
    historico: Scalars["Int"];
};

export type DebenturistaCountAggregate = {
    __typename?: "DebenturistaCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    document: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    minibancoId: Scalars["Int"];
    name: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type DebenturistaCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type DebenturistaCreateInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    historico?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutDebenturistaInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutDebenturistasInput;
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type DebenturistaCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type DebenturistaCreateManyMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type DebenturistaCreateManyMinibancoInputEnvelope = {
    data: Array<DebenturistaCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type DebenturistaCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<DebenturistaWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<DebenturistaCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<DebenturistaCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<DebenturistaCreateManyMinibancoInputEnvelope>;
};

export type DebenturistaCreateNestedOneWithoutHistoricoInput = {
    connect?: InputMaybe<DebenturistaWhereUniqueInput>;
    connectOrCreate?: InputMaybe<DebenturistaCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<DebenturistaCreateWithoutHistoricoInput>;
};

export type DebenturistaCreateOrConnectWithoutHistoricoInput = {
    create: DebenturistaCreateWithoutHistoricoInput;
    where: DebenturistaWhereUniqueInput;
};

export type DebenturistaCreateOrConnectWithoutMinibancoInput = {
    create: DebenturistaCreateWithoutMinibancoInput;
    where: DebenturistaWhereUniqueInput;
};

export type DebenturistaCreateWithoutHistoricoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutDebenturistasInput;
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type DebenturistaCreateWithoutMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    historico?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutDebenturistaInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type DebenturistaGroupBy = {
    __typename?: "DebenturistaGroupBy";
    _avg?: Maybe<DebenturistaAvgAggregate>;
    _count?: Maybe<DebenturistaCountAggregate>;
    _max?: Maybe<DebenturistaMaxAggregate>;
    _min?: Maybe<DebenturistaMinAggregate>;
    _sum?: Maybe<DebenturistaSumAggregate>;
    createdAt: Scalars["DateTime"];
    document: Scalars["String"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibancoId: Scalars["Int"];
    name: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type DebenturistaListRelationFilter = {
    every?: InputMaybe<DebenturistaWhereInput>;
    none?: InputMaybe<DebenturistaWhereInput>;
    some?: InputMaybe<DebenturistaWhereInput>;
};

export type DebenturistaMaxAggregate = {
    __typename?: "DebenturistaMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    document?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    name?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DebenturistaMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type DebenturistaMinAggregate = {
    __typename?: "DebenturistaMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    document?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    name?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DebenturistaMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type DebenturistaOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type DebenturistaOrderByWithAggregationInput = {
    _avg?: InputMaybe<DebenturistaAvgOrderByAggregateInput>;
    _count?: InputMaybe<DebenturistaCountOrderByAggregateInput>;
    _max?: InputMaybe<DebenturistaMaxOrderByAggregateInput>;
    _min?: InputMaybe<DebenturistaMinOrderByAggregateInput>;
    _sum?: InputMaybe<DebenturistaSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type DebenturistaOrderByWithRelationInput = {
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    historico?: InputMaybe<DebenturistaAuditOrderByRelationAggregateInput>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type DebenturistaRelationFilter = {
    is?: InputMaybe<DebenturistaWhereInput>;
    isNot?: InputMaybe<DebenturistaWhereInput>;
};

export enum DebenturistaScalarFieldEnum {
    CreatedAt = "createdAt",
    Document = "document",
    Id = "id",
    Inativo = "inativo",
    MinibancoId = "minibancoId",
    Name = "name",
    UpdatedAt = "updatedAt",
}

export type DebenturistaScalarWhereInput = {
    AND?: InputMaybe<Array<DebenturistaScalarWhereInput>>;
    NOT?: InputMaybe<Array<DebenturistaScalarWhereInput>>;
    OR?: InputMaybe<Array<DebenturistaScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    document?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    name?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DebenturistaScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<DebenturistaScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<DebenturistaScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<DebenturistaScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    document?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    name?: InputMaybe<StringWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type DebenturistaSumAggregate = {
    __typename?: "DebenturistaSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
};

export type DebenturistaSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
};

export type DebenturistaUpdateInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    historico?: InputMaybe<DebenturistaAuditUpdateManyWithoutDebenturistaNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutDebenturistasNestedInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DebenturistaUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DebenturistaUpdateManyWithWhereWithoutMinibancoInput = {
    data: DebenturistaUpdateManyMutationInput;
    where: DebenturistaScalarWhereInput;
};

export type DebenturistaUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<DebenturistaWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<DebenturistaCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<DebenturistaCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<DebenturistaCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<DebenturistaWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<DebenturistaScalarWhereInput>>;
    disconnect?: InputMaybe<Array<DebenturistaWhereUniqueInput>>;
    set?: InputMaybe<Array<DebenturistaWhereUniqueInput>>;
    update?: InputMaybe<Array<DebenturistaUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<DebenturistaUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<DebenturistaUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type DebenturistaUpdateOneRequiredWithoutHistoricoNestedInput = {
    connect?: InputMaybe<DebenturistaWhereUniqueInput>;
    connectOrCreate?: InputMaybe<DebenturistaCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<DebenturistaCreateWithoutHistoricoInput>;
    update?: InputMaybe<DebenturistaUpdateWithoutHistoricoInput>;
    upsert?: InputMaybe<DebenturistaUpsertWithoutHistoricoInput>;
};

export type DebenturistaUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: DebenturistaUpdateWithoutMinibancoInput;
    where: DebenturistaWhereUniqueInput;
};

export type DebenturistaUpdateWithoutHistoricoInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutDebenturistasNestedInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DebenturistaUpdateWithoutMinibancoInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    historico?: InputMaybe<DebenturistaAuditUpdateManyWithoutDebenturistaNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DebenturistaUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: DebenturistaCreateWithoutMinibancoInput;
    update: DebenturistaUpdateWithoutMinibancoInput;
    where: DebenturistaWhereUniqueInput;
};

export type DebenturistaUpsertWithoutHistoricoInput = {
    create: DebenturistaCreateWithoutHistoricoInput;
    update: DebenturistaUpdateWithoutHistoricoInput;
};

export type DebenturistaWhereInput = {
    AND?: InputMaybe<Array<DebenturistaWhereInput>>;
    NOT?: InputMaybe<Array<DebenturistaWhereInput>>;
    OR?: InputMaybe<Array<DebenturistaWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    document?: InputMaybe<StringFilter>;
    historico?: InputMaybe<DebenturistaAuditListRelationFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    name?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DebenturistaWhereUniqueInput = {
    document?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
};

export type DecimalFieldUpdateOperationsInput = {
    decrement?: InputMaybe<Scalars["Decimal"]>;
    divide?: InputMaybe<Scalars["Decimal"]>;
    increment?: InputMaybe<Scalars["Decimal"]>;
    multiply?: InputMaybe<Scalars["Decimal"]>;
    set?: InputMaybe<Scalars["Decimal"]>;
};

export type DecimalFilter = {
    equals?: InputMaybe<Scalars["Decimal"]>;
    gt?: InputMaybe<Scalars["Decimal"]>;
    gte?: InputMaybe<Scalars["Decimal"]>;
    in?: InputMaybe<Array<Scalars["Decimal"]>>;
    lt?: InputMaybe<Scalars["Decimal"]>;
    lte?: InputMaybe<Scalars["Decimal"]>;
    not?: InputMaybe<NestedDecimalFilter>;
    notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type DecimalNullableFilter = {
    equals?: InputMaybe<Scalars["Decimal"]>;
    gt?: InputMaybe<Scalars["Decimal"]>;
    gte?: InputMaybe<Scalars["Decimal"]>;
    in?: InputMaybe<Array<Scalars["Decimal"]>>;
    lt?: InputMaybe<Scalars["Decimal"]>;
    lte?: InputMaybe<Scalars["Decimal"]>;
    not?: InputMaybe<NestedDecimalNullableFilter>;
    notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type DecimalNullableWithAggregatesFilter = {
    _avg?: InputMaybe<NestedDecimalNullableFilter>;
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedDecimalNullableFilter>;
    _min?: InputMaybe<NestedDecimalNullableFilter>;
    _sum?: InputMaybe<NestedDecimalNullableFilter>;
    equals?: InputMaybe<Scalars["Decimal"]>;
    gt?: InputMaybe<Scalars["Decimal"]>;
    gte?: InputMaybe<Scalars["Decimal"]>;
    in?: InputMaybe<Array<Scalars["Decimal"]>>;
    lt?: InputMaybe<Scalars["Decimal"]>;
    lte?: InputMaybe<Scalars["Decimal"]>;
    not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type DecimalWithAggregatesFilter = {
    _avg?: InputMaybe<NestedDecimalFilter>;
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedDecimalFilter>;
    _min?: InputMaybe<NestedDecimalFilter>;
    _sum?: InputMaybe<NestedDecimalFilter>;
    equals?: InputMaybe<Scalars["Decimal"]>;
    gt?: InputMaybe<Scalars["Decimal"]>;
    gte?: InputMaybe<Scalars["Decimal"]>;
    in?: InputMaybe<Array<Scalars["Decimal"]>>;
    lt?: InputMaybe<Scalars["Decimal"]>;
    lte?: InputMaybe<Scalars["Decimal"]>;
    not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export enum DigitalSignature {
    Eletronica = "ELETRONICA",
    ECnpj = "E_CNPJ",
    ECpf = "E_CPF",
}

export enum Erp {
    Gestor = "GESTOR",
    Qprof = "QPROF",
}

export type Empresa = {
    __typename?: "Empresa";
    _count?: Maybe<EmpresaCount>;
    cnpj: Scalars["String"];
    createdAt: Scalars["DateTime"];
    historico: Array<EmpresaAudit>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibancos: Array<Minibanco>;
    nomeFantasia?: Maybe<Scalars["String"]>;
    razaoSocial: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type EmpresaHistoricoArgs = {
    cursor?: InputMaybe<EmpresaAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<EmpresaAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EmpresaAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EmpresaAuditWhereInput>;
};

export type EmpresaMinibancosArgs = {
    cursor?: InputMaybe<MinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoWhereInput>;
};

export type EmpresaAudit = {
    __typename?: "EmpresaAudit";
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    empresa: Empresa;
    empresaId: Scalars["Int"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuario?: Maybe<Usuario>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EmpresaAuditAvgAggregate = {
    __typename?: "EmpresaAuditAvgAggregate";
    empresaId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type EmpresaAuditAvgOrderByAggregateInput = {
    empresaId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EmpresaAuditCountAggregate = {
    __typename?: "EmpresaAuditCountAggregate";
    _all: Scalars["Int"];
    action: Scalars["Int"];
    createdAt: Scalars["Int"];
    empresaId: Scalars["Int"];
    field: Scalars["Int"];
    id: Scalars["Int"];
    ipUsuario: Scalars["Int"];
    newValue: Scalars["Int"];
    oldValue: Scalars["Int"];
    service: Scalars["Int"];
    userAgent: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type EmpresaAuditCountOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    empresaId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EmpresaAuditCreateInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    empresa: EmpresaCreateNestedOneWithoutHistoricoInput;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistEmpresaInput>;
};

export type EmpresaAuditCreateManyEmpresaInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type EmpresaAuditCreateManyEmpresaInputEnvelope = {
    data: Array<EmpresaAuditCreateManyEmpresaInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EmpresaAuditCreateManyInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    empresaId: Scalars["Int"];
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type EmpresaAuditCreateManyUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    empresaId: Scalars["Int"];
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type EmpresaAuditCreateManyUsuarioInputEnvelope = {
    data: Array<EmpresaAuditCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EmpresaAuditCreateNestedManyWithoutEmpresaInput = {
    connect?: InputMaybe<Array<EmpresaAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EmpresaAuditCreateOrConnectWithoutEmpresaInput>>;
    create?: InputMaybe<Array<EmpresaAuditCreateWithoutEmpresaInput>>;
    createMany?: InputMaybe<EmpresaAuditCreateManyEmpresaInputEnvelope>;
};

export type EmpresaAuditCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<EmpresaAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EmpresaAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<EmpresaAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<EmpresaAuditCreateManyUsuarioInputEnvelope>;
};

export type EmpresaAuditCreateOrConnectWithoutEmpresaInput = {
    create: EmpresaAuditCreateWithoutEmpresaInput;
    where: EmpresaAuditWhereUniqueInput;
};

export type EmpresaAuditCreateOrConnectWithoutUsuarioInput = {
    create: EmpresaAuditCreateWithoutUsuarioInput;
    where: EmpresaAuditWhereUniqueInput;
};

export type EmpresaAuditCreateWithoutEmpresaInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistEmpresaInput>;
};

export type EmpresaAuditCreateWithoutUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    empresa: EmpresaCreateNestedOneWithoutHistoricoInput;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type EmpresaAuditGroupBy = {
    __typename?: "EmpresaAuditGroupBy";
    _avg?: Maybe<EmpresaAuditAvgAggregate>;
    _count?: Maybe<EmpresaAuditCountAggregate>;
    _max?: Maybe<EmpresaAuditMaxAggregate>;
    _min?: Maybe<EmpresaAuditMinAggregate>;
    _sum?: Maybe<EmpresaAuditSumAggregate>;
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    empresaId: Scalars["Int"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EmpresaAuditListRelationFilter = {
    every?: InputMaybe<EmpresaAuditWhereInput>;
    none?: InputMaybe<EmpresaAuditWhereInput>;
    some?: InputMaybe<EmpresaAuditWhereInput>;
};

export type EmpresaAuditMaxAggregate = {
    __typename?: "EmpresaAuditMaxAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    empresaId?: Maybe<Scalars["Int"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EmpresaAuditMaxOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    empresaId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EmpresaAuditMinAggregate = {
    __typename?: "EmpresaAuditMinAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    empresaId?: Maybe<Scalars["Int"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EmpresaAuditMinOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    empresaId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EmpresaAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type EmpresaAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<EmpresaAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<EmpresaAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<EmpresaAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<EmpresaAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<EmpresaAuditSumOrderByAggregateInput>;
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    empresaId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EmpresaAuditOrderByWithRelationInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    empresa?: InputMaybe<EmpresaOrderByWithRelationInput>;
    empresaId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum EmpresaAuditScalarFieldEnum {
    Action = "action",
    CreatedAt = "createdAt",
    EmpresaId = "empresaId",
    Field = "field",
    Id = "id",
    IpUsuario = "ipUsuario",
    NewValue = "newValue",
    OldValue = "oldValue",
    Service = "service",
    UserAgent = "userAgent",
    UsuarioId = "usuarioId",
}

export type EmpresaAuditScalarWhereInput = {
    AND?: InputMaybe<Array<EmpresaAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<EmpresaAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<EmpresaAuditScalarWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    empresaId?: InputMaybe<IntFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type EmpresaAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<EmpresaAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<EmpresaAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<EmpresaAuditScalarWhereWithAggregatesInput>>;
    action?: InputMaybe<EnumAuditAcoesWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    empresaId?: InputMaybe<IntWithAggregatesFilter>;
    field?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ipUsuario?: InputMaybe<StringWithAggregatesFilter>;
    newValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type EmpresaAuditSumAggregate = {
    __typename?: "EmpresaAuditSumAggregate";
    empresaId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EmpresaAuditSumOrderByAggregateInput = {
    empresaId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EmpresaAuditUpdateInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    empresa?: InputMaybe<EmpresaUpdateOneRequiredWithoutHistoricoNestedInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistEmpresaNestedInput>;
};

export type EmpresaAuditUpdateManyMutationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EmpresaAuditUpdateManyWithWhereWithoutEmpresaInput = {
    data: EmpresaAuditUpdateManyMutationInput;
    where: EmpresaAuditScalarWhereInput;
};

export type EmpresaAuditUpdateManyWithWhereWithoutUsuarioInput = {
    data: EmpresaAuditUpdateManyMutationInput;
    where: EmpresaAuditScalarWhereInput;
};

export type EmpresaAuditUpdateManyWithoutEmpresaNestedInput = {
    connect?: InputMaybe<Array<EmpresaAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EmpresaAuditCreateOrConnectWithoutEmpresaInput>>;
    create?: InputMaybe<Array<EmpresaAuditCreateWithoutEmpresaInput>>;
    createMany?: InputMaybe<EmpresaAuditCreateManyEmpresaInputEnvelope>;
    delete?: InputMaybe<Array<EmpresaAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EmpresaAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EmpresaAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<EmpresaAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<EmpresaAuditUpdateWithWhereUniqueWithoutEmpresaInput>>;
    updateMany?: InputMaybe<Array<EmpresaAuditUpdateManyWithWhereWithoutEmpresaInput>>;
    upsert?: InputMaybe<Array<EmpresaAuditUpsertWithWhereUniqueWithoutEmpresaInput>>;
};

export type EmpresaAuditUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<EmpresaAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EmpresaAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<EmpresaAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<EmpresaAuditCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<EmpresaAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EmpresaAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EmpresaAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<EmpresaAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<EmpresaAuditUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<EmpresaAuditUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<EmpresaAuditUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type EmpresaAuditUpdateWithWhereUniqueWithoutEmpresaInput = {
    data: EmpresaAuditUpdateWithoutEmpresaInput;
    where: EmpresaAuditWhereUniqueInput;
};

export type EmpresaAuditUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: EmpresaAuditUpdateWithoutUsuarioInput;
    where: EmpresaAuditWhereUniqueInput;
};

export type EmpresaAuditUpdateWithoutEmpresaInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistEmpresaNestedInput>;
};

export type EmpresaAuditUpdateWithoutUsuarioInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    empresa?: InputMaybe<EmpresaUpdateOneRequiredWithoutHistoricoNestedInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EmpresaAuditUpsertWithWhereUniqueWithoutEmpresaInput = {
    create: EmpresaAuditCreateWithoutEmpresaInput;
    update: EmpresaAuditUpdateWithoutEmpresaInput;
    where: EmpresaAuditWhereUniqueInput;
};

export type EmpresaAuditUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: EmpresaAuditCreateWithoutUsuarioInput;
    update: EmpresaAuditUpdateWithoutUsuarioInput;
    where: EmpresaAuditWhereUniqueInput;
};

export type EmpresaAuditWhereInput = {
    AND?: InputMaybe<Array<EmpresaAuditWhereInput>>;
    NOT?: InputMaybe<Array<EmpresaAuditWhereInput>>;
    OR?: InputMaybe<Array<EmpresaAuditWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    empresa?: InputMaybe<EmpresaRelationFilter>;
    empresaId?: InputMaybe<IntFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type EmpresaAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type EmpresaAvgAggregate = {
    __typename?: "EmpresaAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
};

export type EmpresaAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type EmpresaCount = {
    __typename?: "EmpresaCount";
    historico: Scalars["Int"];
    minibancos: Scalars["Int"];
};

export type EmpresaCountAggregate = {
    __typename?: "EmpresaCountAggregate";
    _all: Scalars["Int"];
    cnpj: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    nomeFantasia: Scalars["Int"];
    razaoSocial: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type EmpresaCountOrderByAggregateInput = {
    cnpj?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type EmpresaCreateInput = {
    cnpj: Scalars["String"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<EmpresaAuditCreateNestedManyWithoutEmpresaInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutEmpresasInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    razaoSocial: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type EmpresaCreateManyInput = {
    cnpj: Scalars["String"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    razaoSocial: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type EmpresaCreateNestedManyWithoutMinibancosInput = {
    connect?: InputMaybe<Array<EmpresaWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EmpresaCreateOrConnectWithoutMinibancosInput>>;
    create?: InputMaybe<Array<EmpresaCreateWithoutMinibancosInput>>;
};

export type EmpresaCreateNestedOneWithoutHistoricoInput = {
    connect?: InputMaybe<EmpresaWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EmpresaCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<EmpresaCreateWithoutHistoricoInput>;
};

export type EmpresaCreateOrConnectWithoutHistoricoInput = {
    create: EmpresaCreateWithoutHistoricoInput;
    where: EmpresaWhereUniqueInput;
};

export type EmpresaCreateOrConnectWithoutMinibancosInput = {
    create: EmpresaCreateWithoutMinibancosInput;
    where: EmpresaWhereUniqueInput;
};

export type EmpresaCreateWithoutHistoricoInput = {
    cnpj: Scalars["String"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutEmpresasInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    razaoSocial: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type EmpresaCreateWithoutMinibancosInput = {
    cnpj: Scalars["String"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<EmpresaAuditCreateNestedManyWithoutEmpresaInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    razaoSocial: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type EmpresaGroupBy = {
    __typename?: "EmpresaGroupBy";
    _avg?: Maybe<EmpresaAvgAggregate>;
    _count?: Maybe<EmpresaCountAggregate>;
    _max?: Maybe<EmpresaMaxAggregate>;
    _min?: Maybe<EmpresaMinAggregate>;
    _sum?: Maybe<EmpresaSumAggregate>;
    cnpj: Scalars["String"];
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    nomeFantasia?: Maybe<Scalars["String"]>;
    razaoSocial: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type EmpresaListRelationFilter = {
    every?: InputMaybe<EmpresaWhereInput>;
    none?: InputMaybe<EmpresaWhereInput>;
    some?: InputMaybe<EmpresaWhereInput>;
};

export type EmpresaMaxAggregate = {
    __typename?: "EmpresaMaxAggregate";
    cnpj?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    nomeFantasia?: Maybe<Scalars["String"]>;
    razaoSocial?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type EmpresaMaxOrderByAggregateInput = {
    cnpj?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type EmpresaMinAggregate = {
    __typename?: "EmpresaMinAggregate";
    cnpj?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    nomeFantasia?: Maybe<Scalars["String"]>;
    razaoSocial?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type EmpresaMinOrderByAggregateInput = {
    cnpj?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type EmpresaOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type EmpresaOrderByWithAggregationInput = {
    _avg?: InputMaybe<EmpresaAvgOrderByAggregateInput>;
    _count?: InputMaybe<EmpresaCountOrderByAggregateInput>;
    _max?: InputMaybe<EmpresaMaxOrderByAggregateInput>;
    _min?: InputMaybe<EmpresaMinOrderByAggregateInput>;
    _sum?: InputMaybe<EmpresaSumOrderByAggregateInput>;
    cnpj?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type EmpresaOrderByWithRelationInput = {
    cnpj?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    historico?: InputMaybe<EmpresaAuditOrderByRelationAggregateInput>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancos?: InputMaybe<MinibancoOrderByRelationAggregateInput>;
    nomeFantasia?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type EmpresaRelationFilter = {
    is?: InputMaybe<EmpresaWhereInput>;
    isNot?: InputMaybe<EmpresaWhereInput>;
};

export enum EmpresaScalarFieldEnum {
    Cnpj = "cnpj",
    CreatedAt = "createdAt",
    Id = "id",
    Inativo = "inativo",
    NomeFantasia = "nomeFantasia",
    RazaoSocial = "razaoSocial",
    UpdatedAt = "updatedAt",
}

export type EmpresaScalarWhereInput = {
    AND?: InputMaybe<Array<EmpresaScalarWhereInput>>;
    NOT?: InputMaybe<Array<EmpresaScalarWhereInput>>;
    OR?: InputMaybe<Array<EmpresaScalarWhereInput>>;
    cnpj?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    nomeFantasia?: InputMaybe<StringNullableFilter>;
    razaoSocial?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EmpresaScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<EmpresaScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<EmpresaScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<EmpresaScalarWhereWithAggregatesInput>>;
    cnpj?: InputMaybe<StringWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    nomeFantasia?: InputMaybe<StringNullableWithAggregatesFilter>;
    razaoSocial?: InputMaybe<StringWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type EmpresaSumAggregate = {
    __typename?: "EmpresaSumAggregate";
    id?: Maybe<Scalars["Int"]>;
};

export type EmpresaSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type EmpresaUpdateInput = {
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<EmpresaAuditUpdateManyWithoutEmpresaNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutEmpresasNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmpresaUpdateManyMutationInput = {
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmpresaUpdateManyWithWhereWithoutMinibancosInput = {
    data: EmpresaUpdateManyMutationInput;
    where: EmpresaScalarWhereInput;
};

export type EmpresaUpdateManyWithoutMinibancosNestedInput = {
    connect?: InputMaybe<Array<EmpresaWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EmpresaCreateOrConnectWithoutMinibancosInput>>;
    create?: InputMaybe<Array<EmpresaCreateWithoutMinibancosInput>>;
    delete?: InputMaybe<Array<EmpresaWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EmpresaScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EmpresaWhereUniqueInput>>;
    set?: InputMaybe<Array<EmpresaWhereUniqueInput>>;
    update?: InputMaybe<Array<EmpresaUpdateWithWhereUniqueWithoutMinibancosInput>>;
    updateMany?: InputMaybe<Array<EmpresaUpdateManyWithWhereWithoutMinibancosInput>>;
    upsert?: InputMaybe<Array<EmpresaUpsertWithWhereUniqueWithoutMinibancosInput>>;
};

export type EmpresaUpdateOneRequiredWithoutHistoricoNestedInput = {
    connect?: InputMaybe<EmpresaWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EmpresaCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<EmpresaCreateWithoutHistoricoInput>;
    update?: InputMaybe<EmpresaUpdateWithoutHistoricoInput>;
    upsert?: InputMaybe<EmpresaUpsertWithoutHistoricoInput>;
};

export type EmpresaUpdateWithWhereUniqueWithoutMinibancosInput = {
    data: EmpresaUpdateWithoutMinibancosInput;
    where: EmpresaWhereUniqueInput;
};

export type EmpresaUpdateWithoutHistoricoInput = {
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutEmpresasNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmpresaUpdateWithoutMinibancosInput = {
    cnpj?: InputMaybe<StringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<EmpresaAuditUpdateManyWithoutEmpresaNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmpresaUpsertWithWhereUniqueWithoutMinibancosInput = {
    create: EmpresaCreateWithoutMinibancosInput;
    update: EmpresaUpdateWithoutMinibancosInput;
    where: EmpresaWhereUniqueInput;
};

export type EmpresaUpsertWithoutHistoricoInput = {
    create: EmpresaCreateWithoutHistoricoInput;
    update: EmpresaUpdateWithoutHistoricoInput;
};

export type EmpresaWhereInput = {
    AND?: InputMaybe<Array<EmpresaWhereInput>>;
    NOT?: InputMaybe<Array<EmpresaWhereInput>>;
    OR?: InputMaybe<Array<EmpresaWhereInput>>;
    cnpj?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    historico?: InputMaybe<EmpresaAuditListRelationFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibancos?: InputMaybe<MinibancoListRelationFilter>;
    nomeFantasia?: InputMaybe<StringNullableFilter>;
    razaoSocial?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EmpresaWhereUniqueInput = {
    cnpj?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
};

export type Endereco = {
    __typename?: "Endereco";
    _count?: Maybe<EnderecoCount>;
    alteracoes: Array<EnderecoAlteracao>;
    bairro?: Maybe<Scalars["String"]>;
    cadastro?: Maybe<EnderecoCadastro>;
    cadastroId?: Maybe<Scalars["Int"]>;
    cedentes: Array<Cedente>;
    cep: Scalars["String"];
    cidade: Scalars["String"];
    complemento?: Maybe<Scalars["String"]>;
    historico: Array<EnderecoAudit>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    logradouro: Scalars["String"];
    numero?: Maybe<Scalars["Int"]>;
    sacados: Array<Sacado>;
    uf: Scalars["String"];
};

export type EnderecoAlteracoesArgs = {
    cursor?: InputMaybe<EnderecoAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoAlteracaoWhereInput>;
};

export type EnderecoCedentesArgs = {
    cursor?: InputMaybe<CedenteWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteWhereInput>;
};

export type EnderecoHistoricoArgs = {
    cursor?: InputMaybe<EnderecoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoAuditWhereInput>;
};

export type EnderecoSacadosArgs = {
    cursor?: InputMaybe<SacadoWhereUniqueInput>;
    distinct?: InputMaybe<Array<SacadoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<SacadoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoWhereInput>;
};

export type EnderecoAlteracao = {
    __typename?: "EnderecoAlteracao";
    _count?: Maybe<EnderecoAlteracaoCount>;
    campos: Array<EnderecoCamposAlteracao>;
    createdAt: Scalars["DateTime"];
    endereco: Endereco;
    enderecoId: Scalars["Int"];
    id: Scalars["Int"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    status: CedenteStatus;
    updatedAt: Scalars["DateTime"];
    usuario: Usuario;
    usuarioId: Scalars["Int"];
};

export type EnderecoAlteracaoCamposArgs = {
    cursor?: InputMaybe<EnderecoCamposAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoCamposAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoCamposAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCamposAlteracaoWhereInput>;
};

export type EnderecoAlteracaoAvgAggregate = {
    __typename?: "EnderecoAlteracaoAvgAggregate";
    enderecoId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type EnderecoAlteracaoAvgOrderByAggregateInput = {
    enderecoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoAlteracaoCount = {
    __typename?: "EnderecoAlteracaoCount";
    campos: Scalars["Int"];
};

export type EnderecoAlteracaoCountAggregate = {
    __typename?: "EnderecoAlteracaoCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    enderecoId: Scalars["Int"];
    id: Scalars["Int"];
    minibancoId: Scalars["Int"];
    status: Scalars["Int"];
    updatedAt: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type EnderecoAlteracaoCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoAlteracaoCreateInput = {
    campos?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutAlteracaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    endereco: EnderecoCreateNestedOneWithoutAlteracoesInput;
    minibanco: MinibancoCreateNestedOneWithoutAlteracaoCedentesEnderecoInput;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutAlteracaoEnderecoCedentesInput;
};

export type EnderecoAlteracaoCreateManyEnderecoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
};

export type EnderecoAlteracaoCreateManyEnderecoInputEnvelope = {
    data: Array<EnderecoAlteracaoCreateManyEnderecoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EnderecoAlteracaoCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    enderecoId: Scalars["Int"];
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
};

export type EnderecoAlteracaoCreateManyMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    enderecoId: Scalars["Int"];
    id?: InputMaybe<Scalars["Int"]>;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
};

export type EnderecoAlteracaoCreateManyMinibancoInputEnvelope = {
    data: Array<EnderecoAlteracaoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EnderecoAlteracaoCreateManyUsuarioInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    enderecoId: Scalars["Int"];
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type EnderecoAlteracaoCreateManyUsuarioInputEnvelope = {
    data: Array<EnderecoAlteracaoCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EnderecoAlteracaoCreateNestedManyWithoutEnderecoInput = {
    connect?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoAlteracaoCreateOrConnectWithoutEnderecoInput>>;
    create?: InputMaybe<Array<EnderecoAlteracaoCreateWithoutEnderecoInput>>;
    createMany?: InputMaybe<EnderecoAlteracaoCreateManyEnderecoInputEnvelope>;
};

export type EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoAlteracaoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<EnderecoAlteracaoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<EnderecoAlteracaoCreateManyMinibancoInputEnvelope>;
};

export type EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoAlteracaoCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<EnderecoAlteracaoCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<EnderecoAlteracaoCreateManyUsuarioInputEnvelope>;
};

export type EnderecoAlteracaoCreateNestedOneWithoutCamposInput = {
    connect?: InputMaybe<EnderecoAlteracaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoAlteracaoCreateOrConnectWithoutCamposInput>;
    create?: InputMaybe<EnderecoAlteracaoCreateWithoutCamposInput>;
};

export type EnderecoAlteracaoCreateOrConnectWithoutCamposInput = {
    create: EnderecoAlteracaoCreateWithoutCamposInput;
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type EnderecoAlteracaoCreateOrConnectWithoutEnderecoInput = {
    create: EnderecoAlteracaoCreateWithoutEnderecoInput;
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type EnderecoAlteracaoCreateOrConnectWithoutMinibancoInput = {
    create: EnderecoAlteracaoCreateWithoutMinibancoInput;
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type EnderecoAlteracaoCreateOrConnectWithoutUsuarioInput = {
    create: EnderecoAlteracaoCreateWithoutUsuarioInput;
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type EnderecoAlteracaoCreateWithoutCamposInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    endereco: EnderecoCreateNestedOneWithoutAlteracoesInput;
    minibanco: MinibancoCreateNestedOneWithoutAlteracaoCedentesEnderecoInput;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutAlteracaoEnderecoCedentesInput;
};

export type EnderecoAlteracaoCreateWithoutEnderecoInput = {
    campos?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutAlteracaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    minibanco: MinibancoCreateNestedOneWithoutAlteracaoCedentesEnderecoInput;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutAlteracaoEnderecoCedentesInput;
};

export type EnderecoAlteracaoCreateWithoutMinibancoInput = {
    campos?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutAlteracaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    endereco: EnderecoCreateNestedOneWithoutAlteracoesInput;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutAlteracaoEnderecoCedentesInput;
};

export type EnderecoAlteracaoCreateWithoutUsuarioInput = {
    campos?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutAlteracaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    endereco: EnderecoCreateNestedOneWithoutAlteracoesInput;
    minibanco: MinibancoCreateNestedOneWithoutAlteracaoCedentesEnderecoInput;
    status?: InputMaybe<CedenteStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type EnderecoAlteracaoGroupBy = {
    __typename?: "EnderecoAlteracaoGroupBy";
    _avg?: Maybe<EnderecoAlteracaoAvgAggregate>;
    _count?: Maybe<EnderecoAlteracaoCountAggregate>;
    _max?: Maybe<EnderecoAlteracaoMaxAggregate>;
    _min?: Maybe<EnderecoAlteracaoMinAggregate>;
    _sum?: Maybe<EnderecoAlteracaoSumAggregate>;
    createdAt: Scalars["DateTime"];
    enderecoId: Scalars["Int"];
    id: Scalars["Int"];
    minibancoId: Scalars["Int"];
    status: CedenteStatus;
    updatedAt: Scalars["DateTime"];
    usuarioId: Scalars["Int"];
};

export type EnderecoAlteracaoListRelationFilter = {
    every?: InputMaybe<EnderecoAlteracaoWhereInput>;
    none?: InputMaybe<EnderecoAlteracaoWhereInput>;
    some?: InputMaybe<EnderecoAlteracaoWhereInput>;
};

export type EnderecoAlteracaoMaxAggregate = {
    __typename?: "EnderecoAlteracaoMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    enderecoId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    status?: Maybe<CedenteStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EnderecoAlteracaoMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoAlteracaoMinAggregate = {
    __typename?: "EnderecoAlteracaoMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    enderecoId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    status?: Maybe<CedenteStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EnderecoAlteracaoMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoAlteracaoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type EnderecoAlteracaoOrderByWithAggregationInput = {
    _avg?: InputMaybe<EnderecoAlteracaoAvgOrderByAggregateInput>;
    _count?: InputMaybe<EnderecoAlteracaoCountOrderByAggregateInput>;
    _max?: InputMaybe<EnderecoAlteracaoMaxOrderByAggregateInput>;
    _min?: InputMaybe<EnderecoAlteracaoMinOrderByAggregateInput>;
    _sum?: InputMaybe<EnderecoAlteracaoSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoAlteracaoOrderByWithRelationInput = {
    campos?: InputMaybe<EnderecoCamposAlteracaoOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    endereco?: InputMaybe<EnderecoOrderByWithRelationInput>;
    enderecoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoAlteracaoRelationFilter = {
    is?: InputMaybe<EnderecoAlteracaoWhereInput>;
    isNot?: InputMaybe<EnderecoAlteracaoWhereInput>;
};

export enum EnderecoAlteracaoScalarFieldEnum {
    CreatedAt = "createdAt",
    EnderecoId = "enderecoId",
    Id = "id",
    MinibancoId = "minibancoId",
    Status = "status",
    UpdatedAt = "updatedAt",
    UsuarioId = "usuarioId",
}

export type EnderecoAlteracaoScalarWhereInput = {
    AND?: InputMaybe<Array<EnderecoAlteracaoScalarWhereInput>>;
    NOT?: InputMaybe<Array<EnderecoAlteracaoScalarWhereInput>>;
    OR?: InputMaybe<Array<EnderecoAlteracaoScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    enderecoId?: InputMaybe<IntFilter>;
    id?: InputMaybe<IntFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuarioId?: InputMaybe<IntFilter>;
};

export type EnderecoAlteracaoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<EnderecoAlteracaoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<EnderecoAlteracaoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<EnderecoAlteracaoScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    enderecoId?: InputMaybe<IntWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    status?: InputMaybe<EnumCedenteStatusWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntWithAggregatesFilter>;
};

export type EnderecoAlteracaoSumAggregate = {
    __typename?: "EnderecoAlteracaoSumAggregate";
    enderecoId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EnderecoAlteracaoSumOrderByAggregateInput = {
    enderecoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoAlteracaoUpdateInput = {
    campos?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutAlteracaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneRequiredWithoutAlteracoesNestedInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutAlteracaoCedentesEnderecoNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutAlteracaoEnderecoCedentesNestedInput>;
};

export type EnderecoAlteracaoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EnderecoAlteracaoUpdateManyWithWhereWithoutEnderecoInput = {
    data: EnderecoAlteracaoUpdateManyMutationInput;
    where: EnderecoAlteracaoScalarWhereInput;
};

export type EnderecoAlteracaoUpdateManyWithWhereWithoutMinibancoInput = {
    data: EnderecoAlteracaoUpdateManyMutationInput;
    where: EnderecoAlteracaoScalarWhereInput;
};

export type EnderecoAlteracaoUpdateManyWithWhereWithoutUsuarioInput = {
    data: EnderecoAlteracaoUpdateManyMutationInput;
    where: EnderecoAlteracaoScalarWhereInput;
};

export type EnderecoAlteracaoUpdateManyWithoutEnderecoNestedInput = {
    connect?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoAlteracaoCreateOrConnectWithoutEnderecoInput>>;
    create?: InputMaybe<Array<EnderecoAlteracaoCreateWithoutEnderecoInput>>;
    createMany?: InputMaybe<EnderecoAlteracaoCreateManyEnderecoInputEnvelope>;
    delete?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EnderecoAlteracaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    set?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    update?: InputMaybe<Array<EnderecoAlteracaoUpdateWithWhereUniqueWithoutEnderecoInput>>;
    updateMany?: InputMaybe<Array<EnderecoAlteracaoUpdateManyWithWhereWithoutEnderecoInput>>;
    upsert?: InputMaybe<Array<EnderecoAlteracaoUpsertWithWhereUniqueWithoutEnderecoInput>>;
};

export type EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoAlteracaoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<EnderecoAlteracaoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<EnderecoAlteracaoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EnderecoAlteracaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    set?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    update?: InputMaybe<Array<EnderecoAlteracaoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<EnderecoAlteracaoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<EnderecoAlteracaoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoAlteracaoCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<EnderecoAlteracaoCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<EnderecoAlteracaoCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EnderecoAlteracaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    set?: InputMaybe<Array<EnderecoAlteracaoWhereUniqueInput>>;
    update?: InputMaybe<Array<EnderecoAlteracaoUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<EnderecoAlteracaoUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<EnderecoAlteracaoUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type EnderecoAlteracaoUpdateOneRequiredWithoutCamposNestedInput = {
    connect?: InputMaybe<EnderecoAlteracaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoAlteracaoCreateOrConnectWithoutCamposInput>;
    create?: InputMaybe<EnderecoAlteracaoCreateWithoutCamposInput>;
    update?: InputMaybe<EnderecoAlteracaoUpdateWithoutCamposInput>;
    upsert?: InputMaybe<EnderecoAlteracaoUpsertWithoutCamposInput>;
};

export type EnderecoAlteracaoUpdateWithWhereUniqueWithoutEnderecoInput = {
    data: EnderecoAlteracaoUpdateWithoutEnderecoInput;
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type EnderecoAlteracaoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: EnderecoAlteracaoUpdateWithoutMinibancoInput;
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type EnderecoAlteracaoUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: EnderecoAlteracaoUpdateWithoutUsuarioInput;
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type EnderecoAlteracaoUpdateWithoutCamposInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneRequiredWithoutAlteracoesNestedInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutAlteracaoCedentesEnderecoNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutAlteracaoEnderecoCedentesNestedInput>;
};

export type EnderecoAlteracaoUpdateWithoutEnderecoInput = {
    campos?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutAlteracaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutAlteracaoCedentesEnderecoNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutAlteracaoEnderecoCedentesNestedInput>;
};

export type EnderecoAlteracaoUpdateWithoutMinibancoInput = {
    campos?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutAlteracaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneRequiredWithoutAlteracoesNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutAlteracaoEnderecoCedentesNestedInput>;
};

export type EnderecoAlteracaoUpdateWithoutUsuarioInput = {
    campos?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutAlteracaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneRequiredWithoutAlteracoesNestedInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutAlteracaoCedentesEnderecoNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EnderecoAlteracaoUpsertWithWhereUniqueWithoutEnderecoInput = {
    create: EnderecoAlteracaoCreateWithoutEnderecoInput;
    update: EnderecoAlteracaoUpdateWithoutEnderecoInput;
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type EnderecoAlteracaoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: EnderecoAlteracaoCreateWithoutMinibancoInput;
    update: EnderecoAlteracaoUpdateWithoutMinibancoInput;
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type EnderecoAlteracaoUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: EnderecoAlteracaoCreateWithoutUsuarioInput;
    update: EnderecoAlteracaoUpdateWithoutUsuarioInput;
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type EnderecoAlteracaoUpsertWithoutCamposInput = {
    create: EnderecoAlteracaoCreateWithoutCamposInput;
    update: EnderecoAlteracaoUpdateWithoutCamposInput;
};

export type EnderecoAlteracaoWhereInput = {
    AND?: InputMaybe<Array<EnderecoAlteracaoWhereInput>>;
    NOT?: InputMaybe<Array<EnderecoAlteracaoWhereInput>>;
    OR?: InputMaybe<Array<EnderecoAlteracaoWhereInput>>;
    campos?: InputMaybe<EnderecoCamposAlteracaoListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    endereco?: InputMaybe<EnderecoRelationFilter>;
    enderecoId?: InputMaybe<IntFilter>;
    id?: InputMaybe<IntFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntFilter>;
};

export type EnderecoAlteracaoWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type EnderecoAudit = {
    __typename?: "EnderecoAudit";
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    endereco: Endereco;
    enderecoId: Scalars["Int"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuario?: Maybe<Usuario>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EnderecoAuditAvgAggregate = {
    __typename?: "EnderecoAuditAvgAggregate";
    enderecoId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type EnderecoAuditAvgOrderByAggregateInput = {
    enderecoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoAuditCountAggregate = {
    __typename?: "EnderecoAuditCountAggregate";
    _all: Scalars["Int"];
    action: Scalars["Int"];
    createdAt: Scalars["Int"];
    enderecoId: Scalars["Int"];
    field: Scalars["Int"];
    id: Scalars["Int"];
    ipUsuario: Scalars["Int"];
    newValue: Scalars["Int"];
    oldValue: Scalars["Int"];
    service: Scalars["Int"];
    userAgent: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type EnderecoAuditCountOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoAuditCreateInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    endereco: EnderecoCreateNestedOneWithoutHistoricoInput;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistEnderecoInput>;
};

export type EnderecoAuditCreateManyEnderecoInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type EnderecoAuditCreateManyEnderecoInputEnvelope = {
    data: Array<EnderecoAuditCreateManyEnderecoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EnderecoAuditCreateManyInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    enderecoId: Scalars["Int"];
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type EnderecoAuditCreateManyUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    enderecoId: Scalars["Int"];
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type EnderecoAuditCreateManyUsuarioInputEnvelope = {
    data: Array<EnderecoAuditCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EnderecoAuditCreateNestedManyWithoutEnderecoInput = {
    connect?: InputMaybe<Array<EnderecoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoAuditCreateOrConnectWithoutEnderecoInput>>;
    create?: InputMaybe<Array<EnderecoAuditCreateWithoutEnderecoInput>>;
    createMany?: InputMaybe<EnderecoAuditCreateManyEnderecoInputEnvelope>;
};

export type EnderecoAuditCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<EnderecoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<EnderecoAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<EnderecoAuditCreateManyUsuarioInputEnvelope>;
};

export type EnderecoAuditCreateOrConnectWithoutEnderecoInput = {
    create: EnderecoAuditCreateWithoutEnderecoInput;
    where: EnderecoAuditWhereUniqueInput;
};

export type EnderecoAuditCreateOrConnectWithoutUsuarioInput = {
    create: EnderecoAuditCreateWithoutUsuarioInput;
    where: EnderecoAuditWhereUniqueInput;
};

export type EnderecoAuditCreateWithoutEnderecoInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistEnderecoInput>;
};

export type EnderecoAuditCreateWithoutUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    endereco: EnderecoCreateNestedOneWithoutHistoricoInput;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type EnderecoAuditGroupBy = {
    __typename?: "EnderecoAuditGroupBy";
    _avg?: Maybe<EnderecoAuditAvgAggregate>;
    _count?: Maybe<EnderecoAuditCountAggregate>;
    _max?: Maybe<EnderecoAuditMaxAggregate>;
    _min?: Maybe<EnderecoAuditMinAggregate>;
    _sum?: Maybe<EnderecoAuditSumAggregate>;
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    enderecoId: Scalars["Int"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EnderecoAuditListRelationFilter = {
    every?: InputMaybe<EnderecoAuditWhereInput>;
    none?: InputMaybe<EnderecoAuditWhereInput>;
    some?: InputMaybe<EnderecoAuditWhereInput>;
};

export type EnderecoAuditMaxAggregate = {
    __typename?: "EnderecoAuditMaxAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    enderecoId?: Maybe<Scalars["Int"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EnderecoAuditMaxOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoAuditMinAggregate = {
    __typename?: "EnderecoAuditMinAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    enderecoId?: Maybe<Scalars["Int"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EnderecoAuditMinOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type EnderecoAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<EnderecoAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<EnderecoAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<EnderecoAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<EnderecoAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<EnderecoAuditSumOrderByAggregateInput>;
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoAuditOrderByWithRelationInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    endereco?: InputMaybe<EnderecoOrderByWithRelationInput>;
    enderecoId?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum EnderecoAuditScalarFieldEnum {
    Action = "action",
    CreatedAt = "createdAt",
    EnderecoId = "enderecoId",
    Field = "field",
    Id = "id",
    IpUsuario = "ipUsuario",
    NewValue = "newValue",
    OldValue = "oldValue",
    Service = "service",
    UserAgent = "userAgent",
    UsuarioId = "usuarioId",
}

export type EnderecoAuditScalarWhereInput = {
    AND?: InputMaybe<Array<EnderecoAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<EnderecoAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<EnderecoAuditScalarWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    enderecoId?: InputMaybe<IntFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type EnderecoAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<EnderecoAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<EnderecoAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<EnderecoAuditScalarWhereWithAggregatesInput>>;
    action?: InputMaybe<EnumAuditAcoesWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    enderecoId?: InputMaybe<IntWithAggregatesFilter>;
    field?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ipUsuario?: InputMaybe<StringWithAggregatesFilter>;
    newValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type EnderecoAuditSumAggregate = {
    __typename?: "EnderecoAuditSumAggregate";
    enderecoId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EnderecoAuditSumOrderByAggregateInput = {
    enderecoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoAuditUpdateInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneRequiredWithoutHistoricoNestedInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistEnderecoNestedInput>;
};

export type EnderecoAuditUpdateManyMutationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoAuditUpdateManyWithWhereWithoutEnderecoInput = {
    data: EnderecoAuditUpdateManyMutationInput;
    where: EnderecoAuditScalarWhereInput;
};

export type EnderecoAuditUpdateManyWithWhereWithoutUsuarioInput = {
    data: EnderecoAuditUpdateManyMutationInput;
    where: EnderecoAuditScalarWhereInput;
};

export type EnderecoAuditUpdateManyWithoutEnderecoNestedInput = {
    connect?: InputMaybe<Array<EnderecoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoAuditCreateOrConnectWithoutEnderecoInput>>;
    create?: InputMaybe<Array<EnderecoAuditCreateWithoutEnderecoInput>>;
    createMany?: InputMaybe<EnderecoAuditCreateManyEnderecoInputEnvelope>;
    delete?: InputMaybe<Array<EnderecoAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EnderecoAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EnderecoAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<EnderecoAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<EnderecoAuditUpdateWithWhereUniqueWithoutEnderecoInput>>;
    updateMany?: InputMaybe<Array<EnderecoAuditUpdateManyWithWhereWithoutEnderecoInput>>;
    upsert?: InputMaybe<Array<EnderecoAuditUpsertWithWhereUniqueWithoutEnderecoInput>>;
};

export type EnderecoAuditUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<EnderecoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<EnderecoAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<EnderecoAuditCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<EnderecoAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EnderecoAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EnderecoAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<EnderecoAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<EnderecoAuditUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<EnderecoAuditUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<EnderecoAuditUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type EnderecoAuditUpdateWithWhereUniqueWithoutEnderecoInput = {
    data: EnderecoAuditUpdateWithoutEnderecoInput;
    where: EnderecoAuditWhereUniqueInput;
};

export type EnderecoAuditUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: EnderecoAuditUpdateWithoutUsuarioInput;
    where: EnderecoAuditWhereUniqueInput;
};

export type EnderecoAuditUpdateWithoutEnderecoInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistEnderecoNestedInput>;
};

export type EnderecoAuditUpdateWithoutUsuarioInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneRequiredWithoutHistoricoNestedInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoAuditUpsertWithWhereUniqueWithoutEnderecoInput = {
    create: EnderecoAuditCreateWithoutEnderecoInput;
    update: EnderecoAuditUpdateWithoutEnderecoInput;
    where: EnderecoAuditWhereUniqueInput;
};

export type EnderecoAuditUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: EnderecoAuditCreateWithoutUsuarioInput;
    update: EnderecoAuditUpdateWithoutUsuarioInput;
    where: EnderecoAuditWhereUniqueInput;
};

export type EnderecoAuditWhereInput = {
    AND?: InputMaybe<Array<EnderecoAuditWhereInput>>;
    NOT?: InputMaybe<Array<EnderecoAuditWhereInput>>;
    OR?: InputMaybe<Array<EnderecoAuditWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    endereco?: InputMaybe<EnderecoRelationFilter>;
    enderecoId?: InputMaybe<IntFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type EnderecoAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type EnderecoAvgAggregate = {
    __typename?: "EnderecoAvgAggregate";
    cadastroId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    numero?: Maybe<Scalars["Float"]>;
};

export type EnderecoAvgOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    numero?: InputMaybe<SortOrder>;
};

export type EnderecoCadastro = {
    __typename?: "EnderecoCadastro";
    _count?: Maybe<EnderecoCadastroCount>;
    campos: Array<EnderecoCamposCadastro>;
    createdAt: Scalars["DateTime"];
    endereco?: Maybe<Endereco>;
    id: Scalars["Int"];
    minibanco?: Maybe<Minibanco>;
    minibancoId?: Maybe<Scalars["Int"]>;
    origem: Origem;
    status: CadastroStatus;
    updatedAt: Scalars["DateTime"];
    usuario?: Maybe<Usuario>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EnderecoCadastroCamposArgs = {
    cursor?: InputMaybe<EnderecoCamposCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoCamposCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoCamposCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCamposCadastroWhereInput>;
};

export type EnderecoCadastroAvgAggregate = {
    __typename?: "EnderecoCadastroAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type EnderecoCadastroAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoCadastroCount = {
    __typename?: "EnderecoCadastroCount";
    campos: Scalars["Int"];
};

export type EnderecoCadastroCountAggregate = {
    __typename?: "EnderecoCadastroCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    minibancoId: Scalars["Int"];
    origem: Scalars["Int"];
    status: Scalars["Int"];
    updatedAt: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type EnderecoCadastroCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoCadastroCreateInput = {
    campos?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutCadastroInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCadastroInput>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutCadastroEnderecoInput>;
    origem?: InputMaybe<Origem>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutCadastroEnderecoInput>;
};

export type EnderecoCadastroCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId?: InputMaybe<Scalars["Int"]>;
    origem?: InputMaybe<Origem>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type EnderecoCadastroCreateManyMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    origem?: InputMaybe<Origem>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type EnderecoCadastroCreateManyMinibancoInputEnvelope = {
    data: Array<EnderecoCadastroCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EnderecoCadastroCreateManyUsuarioInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId?: InputMaybe<Scalars["Int"]>;
    origem?: InputMaybe<Origem>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type EnderecoCadastroCreateManyUsuarioInputEnvelope = {
    data: Array<EnderecoCadastroCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EnderecoCadastroCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<EnderecoCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoCadastroCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<EnderecoCadastroCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<EnderecoCadastroCreateManyMinibancoInputEnvelope>;
};

export type EnderecoCadastroCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<EnderecoCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoCadastroCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<EnderecoCadastroCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<EnderecoCadastroCreateManyUsuarioInputEnvelope>;
};

export type EnderecoCadastroCreateNestedOneWithoutCamposInput = {
    connect?: InputMaybe<EnderecoCadastroWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCadastroCreateOrConnectWithoutCamposInput>;
    create?: InputMaybe<EnderecoCadastroCreateWithoutCamposInput>;
};

export type EnderecoCadastroCreateNestedOneWithoutEnderecoInput = {
    connect?: InputMaybe<EnderecoCadastroWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCadastroCreateOrConnectWithoutEnderecoInput>;
    create?: InputMaybe<EnderecoCadastroCreateWithoutEnderecoInput>;
};

export type EnderecoCadastroCreateOrConnectWithoutCamposInput = {
    create: EnderecoCadastroCreateWithoutCamposInput;
    where: EnderecoCadastroWhereUniqueInput;
};

export type EnderecoCadastroCreateOrConnectWithoutEnderecoInput = {
    create: EnderecoCadastroCreateWithoutEnderecoInput;
    where: EnderecoCadastroWhereUniqueInput;
};

export type EnderecoCadastroCreateOrConnectWithoutMinibancoInput = {
    create: EnderecoCadastroCreateWithoutMinibancoInput;
    where: EnderecoCadastroWhereUniqueInput;
};

export type EnderecoCadastroCreateOrConnectWithoutUsuarioInput = {
    create: EnderecoCadastroCreateWithoutUsuarioInput;
    where: EnderecoCadastroWhereUniqueInput;
};

export type EnderecoCadastroCreateWithoutCamposInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCadastroInput>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutCadastroEnderecoInput>;
    origem?: InputMaybe<Origem>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutCadastroEnderecoInput>;
};

export type EnderecoCadastroCreateWithoutEnderecoInput = {
    campos?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutCadastroInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutCadastroEnderecoInput>;
    origem?: InputMaybe<Origem>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutCadastroEnderecoInput>;
};

export type EnderecoCadastroCreateWithoutMinibancoInput = {
    campos?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutCadastroInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCadastroInput>;
    origem?: InputMaybe<Origem>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutCadastroEnderecoInput>;
};

export type EnderecoCadastroCreateWithoutUsuarioInput = {
    campos?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutCadastroInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutCadastroInput>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutCadastroEnderecoInput>;
    origem?: InputMaybe<Origem>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type EnderecoCadastroGroupBy = {
    __typename?: "EnderecoCadastroGroupBy";
    _avg?: Maybe<EnderecoCadastroAvgAggregate>;
    _count?: Maybe<EnderecoCadastroCountAggregate>;
    _max?: Maybe<EnderecoCadastroMaxAggregate>;
    _min?: Maybe<EnderecoCadastroMinAggregate>;
    _sum?: Maybe<EnderecoCadastroSumAggregate>;
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    minibancoId?: Maybe<Scalars["Int"]>;
    origem: Origem;
    status: CadastroStatus;
    updatedAt: Scalars["DateTime"];
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EnderecoCadastroListRelationFilter = {
    every?: InputMaybe<EnderecoCadastroWhereInput>;
    none?: InputMaybe<EnderecoCadastroWhereInput>;
    some?: InputMaybe<EnderecoCadastroWhereInput>;
};

export type EnderecoCadastroMaxAggregate = {
    __typename?: "EnderecoCadastroMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    origem?: Maybe<Origem>;
    status?: Maybe<CadastroStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EnderecoCadastroMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoCadastroMinAggregate = {
    __typename?: "EnderecoCadastroMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    origem?: Maybe<Origem>;
    status?: Maybe<CadastroStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EnderecoCadastroMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoCadastroOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type EnderecoCadastroOrderByWithAggregationInput = {
    _avg?: InputMaybe<EnderecoCadastroAvgOrderByAggregateInput>;
    _count?: InputMaybe<EnderecoCadastroCountOrderByAggregateInput>;
    _max?: InputMaybe<EnderecoCadastroMaxOrderByAggregateInput>;
    _min?: InputMaybe<EnderecoCadastroMinOrderByAggregateInput>;
    _sum?: InputMaybe<EnderecoCadastroSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoCadastroOrderByWithRelationInput = {
    campos?: InputMaybe<EnderecoCamposCadastroOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    endereco?: InputMaybe<EnderecoOrderByWithRelationInput>;
    id?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoCadastroRelationFilter = {
    is?: InputMaybe<EnderecoCadastroWhereInput>;
    isNot?: InputMaybe<EnderecoCadastroWhereInput>;
};

export enum EnderecoCadastroScalarFieldEnum {
    CreatedAt = "createdAt",
    Id = "id",
    MinibancoId = "minibancoId",
    Origem = "origem",
    Status = "status",
    UpdatedAt = "updatedAt",
    UsuarioId = "usuarioId",
}

export type EnderecoCadastroScalarWhereInput = {
    AND?: InputMaybe<Array<EnderecoCadastroScalarWhereInput>>;
    NOT?: InputMaybe<Array<EnderecoCadastroScalarWhereInput>>;
    OR?: InputMaybe<Array<EnderecoCadastroScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    minibancoId?: InputMaybe<IntNullableFilter>;
    origem?: InputMaybe<EnumOrigemFilter>;
    status?: InputMaybe<EnumCadastroStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type EnderecoCadastroScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<EnderecoCadastroScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<EnderecoCadastroScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<EnderecoCadastroScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntNullableWithAggregatesFilter>;
    origem?: InputMaybe<EnumOrigemWithAggregatesFilter>;
    status?: InputMaybe<EnumCadastroStatusWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type EnderecoCadastroSumAggregate = {
    __typename?: "EnderecoCadastroSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type EnderecoCadastroSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type EnderecoCadastroUpdateInput = {
    campos?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutCadastroNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCadastroNestedInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutCadastroEnderecoNestedInput>;
    origem?: InputMaybe<EnumOrigemFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutCadastroEnderecoNestedInput>;
};

export type EnderecoCadastroUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumOrigemFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EnderecoCadastroUpdateManyWithWhereWithoutMinibancoInput = {
    data: EnderecoCadastroUpdateManyMutationInput;
    where: EnderecoCadastroScalarWhereInput;
};

export type EnderecoCadastroUpdateManyWithWhereWithoutUsuarioInput = {
    data: EnderecoCadastroUpdateManyMutationInput;
    where: EnderecoCadastroScalarWhereInput;
};

export type EnderecoCadastroUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<EnderecoCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoCadastroCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<EnderecoCadastroCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<EnderecoCadastroCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<EnderecoCadastroWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EnderecoCadastroScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EnderecoCadastroWhereUniqueInput>>;
    set?: InputMaybe<Array<EnderecoCadastroWhereUniqueInput>>;
    update?: InputMaybe<Array<EnderecoCadastroUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<EnderecoCadastroUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<EnderecoCadastroUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type EnderecoCadastroUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<EnderecoCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoCadastroCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<EnderecoCadastroCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<EnderecoCadastroCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<EnderecoCadastroWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EnderecoCadastroScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EnderecoCadastroWhereUniqueInput>>;
    set?: InputMaybe<Array<EnderecoCadastroWhereUniqueInput>>;
    update?: InputMaybe<Array<EnderecoCadastroUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<EnderecoCadastroUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<EnderecoCadastroUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type EnderecoCadastroUpdateOneRequiredWithoutCamposNestedInput = {
    connect?: InputMaybe<EnderecoCadastroWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCadastroCreateOrConnectWithoutCamposInput>;
    create?: InputMaybe<EnderecoCadastroCreateWithoutCamposInput>;
    update?: InputMaybe<EnderecoCadastroUpdateWithoutCamposInput>;
    upsert?: InputMaybe<EnderecoCadastroUpsertWithoutCamposInput>;
};

export type EnderecoCadastroUpdateOneWithoutEnderecoNestedInput = {
    connect?: InputMaybe<EnderecoCadastroWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCadastroCreateOrConnectWithoutEnderecoInput>;
    create?: InputMaybe<EnderecoCadastroCreateWithoutEnderecoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<EnderecoCadastroUpdateWithoutEnderecoInput>;
    upsert?: InputMaybe<EnderecoCadastroUpsertWithoutEnderecoInput>;
};

export type EnderecoCadastroUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: EnderecoCadastroUpdateWithoutMinibancoInput;
    where: EnderecoCadastroWhereUniqueInput;
};

export type EnderecoCadastroUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: EnderecoCadastroUpdateWithoutUsuarioInput;
    where: EnderecoCadastroWhereUniqueInput;
};

export type EnderecoCadastroUpdateWithoutCamposInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCadastroNestedInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutCadastroEnderecoNestedInput>;
    origem?: InputMaybe<EnumOrigemFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutCadastroEnderecoNestedInput>;
};

export type EnderecoCadastroUpdateWithoutEnderecoInput = {
    campos?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutCadastroNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutCadastroEnderecoNestedInput>;
    origem?: InputMaybe<EnumOrigemFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutCadastroEnderecoNestedInput>;
};

export type EnderecoCadastroUpdateWithoutMinibancoInput = {
    campos?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutCadastroNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCadastroNestedInput>;
    origem?: InputMaybe<EnumOrigemFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutCadastroEnderecoNestedInput>;
};

export type EnderecoCadastroUpdateWithoutUsuarioInput = {
    campos?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutCadastroNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutCadastroNestedInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutCadastroEnderecoNestedInput>;
    origem?: InputMaybe<EnumOrigemFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EnderecoCadastroUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: EnderecoCadastroCreateWithoutMinibancoInput;
    update: EnderecoCadastroUpdateWithoutMinibancoInput;
    where: EnderecoCadastroWhereUniqueInput;
};

export type EnderecoCadastroUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: EnderecoCadastroCreateWithoutUsuarioInput;
    update: EnderecoCadastroUpdateWithoutUsuarioInput;
    where: EnderecoCadastroWhereUniqueInput;
};

export type EnderecoCadastroUpsertWithoutCamposInput = {
    create: EnderecoCadastroCreateWithoutCamposInput;
    update: EnderecoCadastroUpdateWithoutCamposInput;
};

export type EnderecoCadastroUpsertWithoutEnderecoInput = {
    create: EnderecoCadastroCreateWithoutEnderecoInput;
    update: EnderecoCadastroUpdateWithoutEnderecoInput;
};

export type EnderecoCadastroWhereInput = {
    AND?: InputMaybe<Array<EnderecoCadastroWhereInput>>;
    NOT?: InputMaybe<Array<EnderecoCadastroWhereInput>>;
    OR?: InputMaybe<Array<EnderecoCadastroWhereInput>>;
    campos?: InputMaybe<EnderecoCamposCadastroListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    endereco?: InputMaybe<EnderecoRelationFilter>;
    id?: InputMaybe<IntFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntNullableFilter>;
    origem?: InputMaybe<EnumOrigemFilter>;
    status?: InputMaybe<EnumCadastroStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type EnderecoCadastroWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type EnderecoCamposAlteracao = {
    __typename?: "EnderecoCamposAlteracao";
    alteracao: EnderecoAlteracao;
    alteracaoId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao: Scalars["DateTime"];
    id: Scalars["Int"];
    responsavel: Usuario;
    responsavelId: Scalars["Int"];
    status: CedenteStatus;
    valor: Scalars["String"];
};

export type EnderecoCamposAlteracaoAvgAggregate = {
    __typename?: "EnderecoCamposAlteracaoAvgAggregate";
    alteracaoId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    responsavelId?: Maybe<Scalars["Float"]>;
};

export type EnderecoCamposAlteracaoAvgOrderByAggregateInput = {
    alteracaoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
};

export type EnderecoCamposAlteracaoCampoAlteracaoIdCompoundUniqueInput = {
    alteracaoId: Scalars["Int"];
    campo: Scalars["String"];
};

export type EnderecoCamposAlteracaoCountAggregate = {
    __typename?: "EnderecoCamposAlteracaoCountAggregate";
    _all: Scalars["Int"];
    alteracaoId: Scalars["Int"];
    campo: Scalars["Int"];
    dtVerificacao: Scalars["Int"];
    id: Scalars["Int"];
    responsavelId: Scalars["Int"];
    status: Scalars["Int"];
    valor: Scalars["Int"];
};

export type EnderecoCamposAlteracaoCountOrderByAggregateInput = {
    alteracaoId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type EnderecoCamposAlteracaoCreateInput = {
    alteracao: EnderecoAlteracaoCreateNestedOneWithoutCamposInput;
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    responsavel: UsuarioCreateNestedOneWithoutCamposAlteracaoEnderecoCedentesInput;
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type EnderecoCamposAlteracaoCreateManyAlteracaoInput = {
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    responsavelId: Scalars["Int"];
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type EnderecoCamposAlteracaoCreateManyAlteracaoInputEnvelope = {
    data: Array<EnderecoCamposAlteracaoCreateManyAlteracaoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EnderecoCamposAlteracaoCreateManyInput = {
    alteracaoId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    responsavelId: Scalars["Int"];
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type EnderecoCamposAlteracaoCreateManyResponsavelInput = {
    alteracaoId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type EnderecoCamposAlteracaoCreateManyResponsavelInputEnvelope = {
    data: Array<EnderecoCamposAlteracaoCreateManyResponsavelInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EnderecoCamposAlteracaoCreateNestedManyWithoutAlteracaoInput = {
    connect?: InputMaybe<Array<EnderecoCamposAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoCamposAlteracaoCreateOrConnectWithoutAlteracaoInput>>;
    create?: InputMaybe<Array<EnderecoCamposAlteracaoCreateWithoutAlteracaoInput>>;
    createMany?: InputMaybe<EnderecoCamposAlteracaoCreateManyAlteracaoInputEnvelope>;
};

export type EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput = {
    connect?: InputMaybe<Array<EnderecoCamposAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoCamposAlteracaoCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<EnderecoCamposAlteracaoCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<EnderecoCamposAlteracaoCreateManyResponsavelInputEnvelope>;
};

export type EnderecoCamposAlteracaoCreateOrConnectWithoutAlteracaoInput = {
    create: EnderecoCamposAlteracaoCreateWithoutAlteracaoInput;
    where: EnderecoCamposAlteracaoWhereUniqueInput;
};

export type EnderecoCamposAlteracaoCreateOrConnectWithoutResponsavelInput = {
    create: EnderecoCamposAlteracaoCreateWithoutResponsavelInput;
    where: EnderecoCamposAlteracaoWhereUniqueInput;
};

export type EnderecoCamposAlteracaoCreateWithoutAlteracaoInput = {
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    responsavel: UsuarioCreateNestedOneWithoutCamposAlteracaoEnderecoCedentesInput;
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type EnderecoCamposAlteracaoCreateWithoutResponsavelInput = {
    alteracao: EnderecoAlteracaoCreateNestedOneWithoutCamposInput;
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    status?: InputMaybe<CedenteStatus>;
    valor: Scalars["String"];
};

export type EnderecoCamposAlteracaoGroupBy = {
    __typename?: "EnderecoCamposAlteracaoGroupBy";
    _avg?: Maybe<EnderecoCamposAlteracaoAvgAggregate>;
    _count?: Maybe<EnderecoCamposAlteracaoCountAggregate>;
    _max?: Maybe<EnderecoCamposAlteracaoMaxAggregate>;
    _min?: Maybe<EnderecoCamposAlteracaoMinAggregate>;
    _sum?: Maybe<EnderecoCamposAlteracaoSumAggregate>;
    alteracaoId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao: Scalars["DateTime"];
    id: Scalars["Int"];
    responsavelId: Scalars["Int"];
    status: CedenteStatus;
    valor: Scalars["String"];
};

export type EnderecoCamposAlteracaoListRelationFilter = {
    every?: InputMaybe<EnderecoCamposAlteracaoWhereInput>;
    none?: InputMaybe<EnderecoCamposAlteracaoWhereInput>;
    some?: InputMaybe<EnderecoCamposAlteracaoWhereInput>;
};

export type EnderecoCamposAlteracaoMaxAggregate = {
    __typename?: "EnderecoCamposAlteracaoMaxAggregate";
    alteracaoId?: Maybe<Scalars["Int"]>;
    campo?: Maybe<Scalars["String"]>;
    dtVerificacao?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    responsavelId?: Maybe<Scalars["Int"]>;
    status?: Maybe<CedenteStatus>;
    valor?: Maybe<Scalars["String"]>;
};

export type EnderecoCamposAlteracaoMaxOrderByAggregateInput = {
    alteracaoId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type EnderecoCamposAlteracaoMinAggregate = {
    __typename?: "EnderecoCamposAlteracaoMinAggregate";
    alteracaoId?: Maybe<Scalars["Int"]>;
    campo?: Maybe<Scalars["String"]>;
    dtVerificacao?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    responsavelId?: Maybe<Scalars["Int"]>;
    status?: Maybe<CedenteStatus>;
    valor?: Maybe<Scalars["String"]>;
};

export type EnderecoCamposAlteracaoMinOrderByAggregateInput = {
    alteracaoId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type EnderecoCamposAlteracaoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type EnderecoCamposAlteracaoOrderByWithAggregationInput = {
    _avg?: InputMaybe<EnderecoCamposAlteracaoAvgOrderByAggregateInput>;
    _count?: InputMaybe<EnderecoCamposAlteracaoCountOrderByAggregateInput>;
    _max?: InputMaybe<EnderecoCamposAlteracaoMaxOrderByAggregateInput>;
    _min?: InputMaybe<EnderecoCamposAlteracaoMinOrderByAggregateInput>;
    _sum?: InputMaybe<EnderecoCamposAlteracaoSumOrderByAggregateInput>;
    alteracaoId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type EnderecoCamposAlteracaoOrderByWithRelationInput = {
    alteracao?: InputMaybe<EnderecoAlteracaoOrderByWithRelationInput>;
    alteracaoId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavel?: InputMaybe<UsuarioOrderByWithRelationInput>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export enum EnderecoCamposAlteracaoScalarFieldEnum {
    AlteracaoId = "alteracaoId",
    Campo = "campo",
    DtVerificacao = "dtVerificacao",
    Id = "id",
    ResponsavelId = "responsavelId",
    Status = "status",
    Valor = "valor",
}

export type EnderecoCamposAlteracaoScalarWhereInput = {
    AND?: InputMaybe<Array<EnderecoCamposAlteracaoScalarWhereInput>>;
    NOT?: InputMaybe<Array<EnderecoCamposAlteracaoScalarWhereInput>>;
    OR?: InputMaybe<Array<EnderecoCamposAlteracaoScalarWhereInput>>;
    alteracaoId?: InputMaybe<IntFilter>;
    campo?: InputMaybe<StringFilter>;
    dtVerificacao?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    responsavelId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    valor?: InputMaybe<StringFilter>;
};

export type EnderecoCamposAlteracaoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<EnderecoCamposAlteracaoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<EnderecoCamposAlteracaoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<EnderecoCamposAlteracaoScalarWhereWithAggregatesInput>>;
    alteracaoId?: InputMaybe<IntWithAggregatesFilter>;
    campo?: InputMaybe<StringWithAggregatesFilter>;
    dtVerificacao?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    responsavelId?: InputMaybe<IntWithAggregatesFilter>;
    status?: InputMaybe<EnumCedenteStatusWithAggregatesFilter>;
    valor?: InputMaybe<StringWithAggregatesFilter>;
};

export type EnderecoCamposAlteracaoSumAggregate = {
    __typename?: "EnderecoCamposAlteracaoSumAggregate";
    alteracaoId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    responsavelId?: Maybe<Scalars["Int"]>;
};

export type EnderecoCamposAlteracaoSumOrderByAggregateInput = {
    alteracaoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
};

export type EnderecoCamposAlteracaoUpdateInput = {
    alteracao?: InputMaybe<EnderecoAlteracaoUpdateOneRequiredWithoutCamposNestedInput>;
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutCamposAlteracaoEnderecoCedentesNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoCamposAlteracaoUpdateManyMutationInput = {
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoCamposAlteracaoUpdateManyWithWhereWithoutAlteracaoInput = {
    data: EnderecoCamposAlteracaoUpdateManyMutationInput;
    where: EnderecoCamposAlteracaoScalarWhereInput;
};

export type EnderecoCamposAlteracaoUpdateManyWithWhereWithoutResponsavelInput = {
    data: EnderecoCamposAlteracaoUpdateManyMutationInput;
    where: EnderecoCamposAlteracaoScalarWhereInput;
};

export type EnderecoCamposAlteracaoUpdateManyWithoutAlteracaoNestedInput = {
    connect?: InputMaybe<Array<EnderecoCamposAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoCamposAlteracaoCreateOrConnectWithoutAlteracaoInput>>;
    create?: InputMaybe<Array<EnderecoCamposAlteracaoCreateWithoutAlteracaoInput>>;
    createMany?: InputMaybe<EnderecoCamposAlteracaoCreateManyAlteracaoInputEnvelope>;
    delete?: InputMaybe<Array<EnderecoCamposAlteracaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EnderecoCamposAlteracaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EnderecoCamposAlteracaoWhereUniqueInput>>;
    set?: InputMaybe<Array<EnderecoCamposAlteracaoWhereUniqueInput>>;
    update?: InputMaybe<Array<EnderecoCamposAlteracaoUpdateWithWhereUniqueWithoutAlteracaoInput>>;
    updateMany?: InputMaybe<Array<EnderecoCamposAlteracaoUpdateManyWithWhereWithoutAlteracaoInput>>;
    upsert?: InputMaybe<Array<EnderecoCamposAlteracaoUpsertWithWhereUniqueWithoutAlteracaoInput>>;
};

export type EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput = {
    connect?: InputMaybe<Array<EnderecoCamposAlteracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoCamposAlteracaoCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<EnderecoCamposAlteracaoCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<EnderecoCamposAlteracaoCreateManyResponsavelInputEnvelope>;
    delete?: InputMaybe<Array<EnderecoCamposAlteracaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EnderecoCamposAlteracaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EnderecoCamposAlteracaoWhereUniqueInput>>;
    set?: InputMaybe<Array<EnderecoCamposAlteracaoWhereUniqueInput>>;
    update?: InputMaybe<Array<EnderecoCamposAlteracaoUpdateWithWhereUniqueWithoutResponsavelInput>>;
    updateMany?: InputMaybe<Array<EnderecoCamposAlteracaoUpdateManyWithWhereWithoutResponsavelInput>>;
    upsert?: InputMaybe<Array<EnderecoCamposAlteracaoUpsertWithWhereUniqueWithoutResponsavelInput>>;
};

export type EnderecoCamposAlteracaoUpdateWithWhereUniqueWithoutAlteracaoInput = {
    data: EnderecoCamposAlteracaoUpdateWithoutAlteracaoInput;
    where: EnderecoCamposAlteracaoWhereUniqueInput;
};

export type EnderecoCamposAlteracaoUpdateWithWhereUniqueWithoutResponsavelInput = {
    data: EnderecoCamposAlteracaoUpdateWithoutResponsavelInput;
    where: EnderecoCamposAlteracaoWhereUniqueInput;
};

export type EnderecoCamposAlteracaoUpdateWithoutAlteracaoInput = {
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutCamposAlteracaoEnderecoCedentesNestedInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoCamposAlteracaoUpdateWithoutResponsavelInput = {
    alteracao?: InputMaybe<EnderecoAlteracaoUpdateOneRequiredWithoutCamposNestedInput>;
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCedenteStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoCamposAlteracaoUpsertWithWhereUniqueWithoutAlteracaoInput = {
    create: EnderecoCamposAlteracaoCreateWithoutAlteracaoInput;
    update: EnderecoCamposAlteracaoUpdateWithoutAlteracaoInput;
    where: EnderecoCamposAlteracaoWhereUniqueInput;
};

export type EnderecoCamposAlteracaoUpsertWithWhereUniqueWithoutResponsavelInput = {
    create: EnderecoCamposAlteracaoCreateWithoutResponsavelInput;
    update: EnderecoCamposAlteracaoUpdateWithoutResponsavelInput;
    where: EnderecoCamposAlteracaoWhereUniqueInput;
};

export type EnderecoCamposAlteracaoWhereInput = {
    AND?: InputMaybe<Array<EnderecoCamposAlteracaoWhereInput>>;
    NOT?: InputMaybe<Array<EnderecoCamposAlteracaoWhereInput>>;
    OR?: InputMaybe<Array<EnderecoCamposAlteracaoWhereInput>>;
    alteracao?: InputMaybe<EnderecoAlteracaoRelationFilter>;
    alteracaoId?: InputMaybe<IntFilter>;
    campo?: InputMaybe<StringFilter>;
    dtVerificacao?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    responsavel?: InputMaybe<UsuarioRelationFilter>;
    responsavelId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumCedenteStatusFilter>;
    valor?: InputMaybe<StringFilter>;
};

export type EnderecoCamposAlteracaoWhereUniqueInput = {
    campo_alteracaoId?: InputMaybe<EnderecoCamposAlteracaoCampoAlteracaoIdCompoundUniqueInput>;
    id?: InputMaybe<Scalars["Int"]>;
};

export type EnderecoCamposCadastro = {
    __typename?: "EnderecoCamposCadastro";
    cadastro: EnderecoCadastro;
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao: Scalars["DateTime"];
    id: Scalars["Int"];
    responsavel: Usuario;
    responsavelId: Scalars["Int"];
    status: CadastroStatus;
    valor: Scalars["String"];
};

export type EnderecoCamposCadastroAvgAggregate = {
    __typename?: "EnderecoCamposCadastroAvgAggregate";
    cadastroId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    responsavelId?: Maybe<Scalars["Float"]>;
};

export type EnderecoCamposCadastroAvgOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
};

export type EnderecoCamposCadastroCampoCadastroIdCompoundUniqueInput = {
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
};

export type EnderecoCamposCadastroCountAggregate = {
    __typename?: "EnderecoCamposCadastroCountAggregate";
    _all: Scalars["Int"];
    cadastroId: Scalars["Int"];
    campo: Scalars["Int"];
    dtVerificacao: Scalars["Int"];
    id: Scalars["Int"];
    responsavelId: Scalars["Int"];
    status: Scalars["Int"];
    valor: Scalars["Int"];
};

export type EnderecoCamposCadastroCountOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type EnderecoCamposCadastroCreateInput = {
    cadastro: EnderecoCadastroCreateNestedOneWithoutCamposInput;
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    responsavel: UsuarioCreateNestedOneWithoutCamposCadastroEnderecoInput;
    status?: InputMaybe<CadastroStatus>;
    valor: Scalars["String"];
};

export type EnderecoCamposCadastroCreateManyCadastroInput = {
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    responsavelId: Scalars["Int"];
    status?: InputMaybe<CadastroStatus>;
    valor: Scalars["String"];
};

export type EnderecoCamposCadastroCreateManyCadastroInputEnvelope = {
    data: Array<EnderecoCamposCadastroCreateManyCadastroInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EnderecoCamposCadastroCreateManyInput = {
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    responsavelId: Scalars["Int"];
    status?: InputMaybe<CadastroStatus>;
    valor: Scalars["String"];
};

export type EnderecoCamposCadastroCreateManyResponsavelInput = {
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    status?: InputMaybe<CadastroStatus>;
    valor: Scalars["String"];
};

export type EnderecoCamposCadastroCreateManyResponsavelInputEnvelope = {
    data: Array<EnderecoCamposCadastroCreateManyResponsavelInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EnderecoCamposCadastroCreateNestedManyWithoutCadastroInput = {
    connect?: InputMaybe<Array<EnderecoCamposCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoCamposCadastroCreateOrConnectWithoutCadastroInput>>;
    create?: InputMaybe<Array<EnderecoCamposCadastroCreateWithoutCadastroInput>>;
    createMany?: InputMaybe<EnderecoCamposCadastroCreateManyCadastroInputEnvelope>;
};

export type EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput = {
    connect?: InputMaybe<Array<EnderecoCamposCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoCamposCadastroCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<EnderecoCamposCadastroCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<EnderecoCamposCadastroCreateManyResponsavelInputEnvelope>;
};

export type EnderecoCamposCadastroCreateOrConnectWithoutCadastroInput = {
    create: EnderecoCamposCadastroCreateWithoutCadastroInput;
    where: EnderecoCamposCadastroWhereUniqueInput;
};

export type EnderecoCamposCadastroCreateOrConnectWithoutResponsavelInput = {
    create: EnderecoCamposCadastroCreateWithoutResponsavelInput;
    where: EnderecoCamposCadastroWhereUniqueInput;
};

export type EnderecoCamposCadastroCreateWithoutCadastroInput = {
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    responsavel: UsuarioCreateNestedOneWithoutCamposCadastroEnderecoInput;
    status?: InputMaybe<CadastroStatus>;
    valor: Scalars["String"];
};

export type EnderecoCamposCadastroCreateWithoutResponsavelInput = {
    cadastro: EnderecoCadastroCreateNestedOneWithoutCamposInput;
    campo: Scalars["String"];
    dtVerificacao?: InputMaybe<Scalars["DateTime"]>;
    status?: InputMaybe<CadastroStatus>;
    valor: Scalars["String"];
};

export type EnderecoCamposCadastroGroupBy = {
    __typename?: "EnderecoCamposCadastroGroupBy";
    _avg?: Maybe<EnderecoCamposCadastroAvgAggregate>;
    _count?: Maybe<EnderecoCamposCadastroCountAggregate>;
    _max?: Maybe<EnderecoCamposCadastroMaxAggregate>;
    _min?: Maybe<EnderecoCamposCadastroMinAggregate>;
    _sum?: Maybe<EnderecoCamposCadastroSumAggregate>;
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    dtVerificacao: Scalars["DateTime"];
    id: Scalars["Int"];
    responsavelId: Scalars["Int"];
    status: CadastroStatus;
    valor: Scalars["String"];
};

export type EnderecoCamposCadastroListRelationFilter = {
    every?: InputMaybe<EnderecoCamposCadastroWhereInput>;
    none?: InputMaybe<EnderecoCamposCadastroWhereInput>;
    some?: InputMaybe<EnderecoCamposCadastroWhereInput>;
};

export type EnderecoCamposCadastroMaxAggregate = {
    __typename?: "EnderecoCamposCadastroMaxAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    campo?: Maybe<Scalars["String"]>;
    dtVerificacao?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    responsavelId?: Maybe<Scalars["Int"]>;
    status?: Maybe<CadastroStatus>;
    valor?: Maybe<Scalars["String"]>;
};

export type EnderecoCamposCadastroMaxOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type EnderecoCamposCadastroMinAggregate = {
    __typename?: "EnderecoCamposCadastroMinAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    campo?: Maybe<Scalars["String"]>;
    dtVerificacao?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    responsavelId?: Maybe<Scalars["Int"]>;
    status?: Maybe<CadastroStatus>;
    valor?: Maybe<Scalars["String"]>;
};

export type EnderecoCamposCadastroMinOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type EnderecoCamposCadastroOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type EnderecoCamposCadastroOrderByWithAggregationInput = {
    _avg?: InputMaybe<EnderecoCamposCadastroAvgOrderByAggregateInput>;
    _count?: InputMaybe<EnderecoCamposCadastroCountOrderByAggregateInput>;
    _max?: InputMaybe<EnderecoCamposCadastroMaxOrderByAggregateInput>;
    _min?: InputMaybe<EnderecoCamposCadastroMinOrderByAggregateInput>;
    _sum?: InputMaybe<EnderecoCamposCadastroSumOrderByAggregateInput>;
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type EnderecoCamposCadastroOrderByWithRelationInput = {
    cadastro?: InputMaybe<EnderecoCadastroOrderByWithRelationInput>;
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    dtVerificacao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavel?: InputMaybe<UsuarioOrderByWithRelationInput>;
    responsavelId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export enum EnderecoCamposCadastroScalarFieldEnum {
    CadastroId = "cadastroId",
    Campo = "campo",
    DtVerificacao = "dtVerificacao",
    Id = "id",
    ResponsavelId = "responsavelId",
    Status = "status",
    Valor = "valor",
}

export type EnderecoCamposCadastroScalarWhereInput = {
    AND?: InputMaybe<Array<EnderecoCamposCadastroScalarWhereInput>>;
    NOT?: InputMaybe<Array<EnderecoCamposCadastroScalarWhereInput>>;
    OR?: InputMaybe<Array<EnderecoCamposCadastroScalarWhereInput>>;
    cadastroId?: InputMaybe<IntFilter>;
    campo?: InputMaybe<StringFilter>;
    dtVerificacao?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    responsavelId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumCadastroStatusFilter>;
    valor?: InputMaybe<StringFilter>;
};

export type EnderecoCamposCadastroScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<EnderecoCamposCadastroScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<EnderecoCamposCadastroScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<EnderecoCamposCadastroScalarWhereWithAggregatesInput>>;
    cadastroId?: InputMaybe<IntWithAggregatesFilter>;
    campo?: InputMaybe<StringWithAggregatesFilter>;
    dtVerificacao?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    responsavelId?: InputMaybe<IntWithAggregatesFilter>;
    status?: InputMaybe<EnumCadastroStatusWithAggregatesFilter>;
    valor?: InputMaybe<StringWithAggregatesFilter>;
};

export type EnderecoCamposCadastroSumAggregate = {
    __typename?: "EnderecoCamposCadastroSumAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    responsavelId?: Maybe<Scalars["Int"]>;
};

export type EnderecoCamposCadastroSumOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
};

export type EnderecoCamposCadastroUpdateInput = {
    cadastro?: InputMaybe<EnderecoCadastroUpdateOneRequiredWithoutCamposNestedInput>;
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutCamposCadastroEnderecoNestedInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoCamposCadastroUpdateManyMutationInput = {
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoCamposCadastroUpdateManyWithWhereWithoutCadastroInput = {
    data: EnderecoCamposCadastroUpdateManyMutationInput;
    where: EnderecoCamposCadastroScalarWhereInput;
};

export type EnderecoCamposCadastroUpdateManyWithWhereWithoutResponsavelInput = {
    data: EnderecoCamposCadastroUpdateManyMutationInput;
    where: EnderecoCamposCadastroScalarWhereInput;
};

export type EnderecoCamposCadastroUpdateManyWithoutCadastroNestedInput = {
    connect?: InputMaybe<Array<EnderecoCamposCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoCamposCadastroCreateOrConnectWithoutCadastroInput>>;
    create?: InputMaybe<Array<EnderecoCamposCadastroCreateWithoutCadastroInput>>;
    createMany?: InputMaybe<EnderecoCamposCadastroCreateManyCadastroInputEnvelope>;
    delete?: InputMaybe<Array<EnderecoCamposCadastroWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EnderecoCamposCadastroScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EnderecoCamposCadastroWhereUniqueInput>>;
    set?: InputMaybe<Array<EnderecoCamposCadastroWhereUniqueInput>>;
    update?: InputMaybe<Array<EnderecoCamposCadastroUpdateWithWhereUniqueWithoutCadastroInput>>;
    updateMany?: InputMaybe<Array<EnderecoCamposCadastroUpdateManyWithWhereWithoutCadastroInput>>;
    upsert?: InputMaybe<Array<EnderecoCamposCadastroUpsertWithWhereUniqueWithoutCadastroInput>>;
};

export type EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput = {
    connect?: InputMaybe<Array<EnderecoCamposCadastroWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<EnderecoCamposCadastroCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<EnderecoCamposCadastroCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<EnderecoCamposCadastroCreateManyResponsavelInputEnvelope>;
    delete?: InputMaybe<Array<EnderecoCamposCadastroWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<EnderecoCamposCadastroScalarWhereInput>>;
    disconnect?: InputMaybe<Array<EnderecoCamposCadastroWhereUniqueInput>>;
    set?: InputMaybe<Array<EnderecoCamposCadastroWhereUniqueInput>>;
    update?: InputMaybe<Array<EnderecoCamposCadastroUpdateWithWhereUniqueWithoutResponsavelInput>>;
    updateMany?: InputMaybe<Array<EnderecoCamposCadastroUpdateManyWithWhereWithoutResponsavelInput>>;
    upsert?: InputMaybe<Array<EnderecoCamposCadastroUpsertWithWhereUniqueWithoutResponsavelInput>>;
};

export type EnderecoCamposCadastroUpdateWithWhereUniqueWithoutCadastroInput = {
    data: EnderecoCamposCadastroUpdateWithoutCadastroInput;
    where: EnderecoCamposCadastroWhereUniqueInput;
};

export type EnderecoCamposCadastroUpdateWithWhereUniqueWithoutResponsavelInput = {
    data: EnderecoCamposCadastroUpdateWithoutResponsavelInput;
    where: EnderecoCamposCadastroWhereUniqueInput;
};

export type EnderecoCamposCadastroUpdateWithoutCadastroInput = {
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutCamposCadastroEnderecoNestedInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoCamposCadastroUpdateWithoutResponsavelInput = {
    cadastro?: InputMaybe<EnderecoCadastroUpdateOneRequiredWithoutCamposNestedInput>;
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtVerificacao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoCamposCadastroUpsertWithWhereUniqueWithoutCadastroInput = {
    create: EnderecoCamposCadastroCreateWithoutCadastroInput;
    update: EnderecoCamposCadastroUpdateWithoutCadastroInput;
    where: EnderecoCamposCadastroWhereUniqueInput;
};

export type EnderecoCamposCadastroUpsertWithWhereUniqueWithoutResponsavelInput = {
    create: EnderecoCamposCadastroCreateWithoutResponsavelInput;
    update: EnderecoCamposCadastroUpdateWithoutResponsavelInput;
    where: EnderecoCamposCadastroWhereUniqueInput;
};

export type EnderecoCamposCadastroWhereInput = {
    AND?: InputMaybe<Array<EnderecoCamposCadastroWhereInput>>;
    NOT?: InputMaybe<Array<EnderecoCamposCadastroWhereInput>>;
    OR?: InputMaybe<Array<EnderecoCamposCadastroWhereInput>>;
    cadastro?: InputMaybe<EnderecoCadastroRelationFilter>;
    cadastroId?: InputMaybe<IntFilter>;
    campo?: InputMaybe<StringFilter>;
    dtVerificacao?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    responsavel?: InputMaybe<UsuarioRelationFilter>;
    responsavelId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumCadastroStatusFilter>;
    valor?: InputMaybe<StringFilter>;
};

export type EnderecoCamposCadastroWhereUniqueInput = {
    campo_cadastroId?: InputMaybe<EnderecoCamposCadastroCampoCadastroIdCompoundUniqueInput>;
    id?: InputMaybe<Scalars["Int"]>;
};

export type EnderecoCount = {
    __typename?: "EnderecoCount";
    alteracoes: Scalars["Int"];
    cedentes: Scalars["Int"];
    historico: Scalars["Int"];
    sacados: Scalars["Int"];
};

export type EnderecoCountAggregate = {
    __typename?: "EnderecoCountAggregate";
    _all: Scalars["Int"];
    bairro: Scalars["Int"];
    cadastroId: Scalars["Int"];
    cep: Scalars["Int"];
    cidade: Scalars["Int"];
    complemento: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    logradouro: Scalars["Int"];
    numero: Scalars["Int"];
    uf: Scalars["Int"];
};

export type EnderecoCountOrderByAggregateInput = {
    bairro?: InputMaybe<SortOrder>;
    cadastroId?: InputMaybe<SortOrder>;
    cep?: InputMaybe<SortOrder>;
    cidade?: InputMaybe<SortOrder>;
    complemento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    logradouro?: InputMaybe<SortOrder>;
    numero?: InputMaybe<SortOrder>;
    uf?: InputMaybe<SortOrder>;
};

export type EnderecoCreateInput = {
    alteracoes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutEnderecoInput>;
    bairro?: InputMaybe<Scalars["String"]>;
    cadastro?: InputMaybe<EnderecoCadastroCreateNestedOneWithoutEnderecoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutEnderecoInput>;
    cep: Scalars["String"];
    cidade: Scalars["String"];
    complemento?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<EnderecoAuditCreateNestedManyWithoutEnderecoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    logradouro: Scalars["String"];
    numero?: InputMaybe<Scalars["Int"]>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutEnderecoInput>;
    uf: Scalars["String"];
};

export type EnderecoCreateManyInput = {
    bairro?: InputMaybe<Scalars["String"]>;
    cadastroId?: InputMaybe<Scalars["Int"]>;
    cep: Scalars["String"];
    cidade: Scalars["String"];
    complemento?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    logradouro: Scalars["String"];
    numero?: InputMaybe<Scalars["Int"]>;
    uf: Scalars["String"];
};

export type EnderecoCreateNestedOneWithoutAlteracoesInput = {
    connect?: InputMaybe<EnderecoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCreateOrConnectWithoutAlteracoesInput>;
    create?: InputMaybe<EnderecoCreateWithoutAlteracoesInput>;
};

export type EnderecoCreateNestedOneWithoutCadastroInput = {
    connect?: InputMaybe<EnderecoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCreateOrConnectWithoutCadastroInput>;
    create?: InputMaybe<EnderecoCreateWithoutCadastroInput>;
};

export type EnderecoCreateNestedOneWithoutCedentesInput = {
    connect?: InputMaybe<EnderecoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCreateOrConnectWithoutCedentesInput>;
    create?: InputMaybe<EnderecoCreateWithoutCedentesInput>;
};

export type EnderecoCreateNestedOneWithoutHistoricoInput = {
    connect?: InputMaybe<EnderecoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<EnderecoCreateWithoutHistoricoInput>;
};

export type EnderecoCreateNestedOneWithoutSacadosInput = {
    connect?: InputMaybe<EnderecoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCreateOrConnectWithoutSacadosInput>;
    create?: InputMaybe<EnderecoCreateWithoutSacadosInput>;
};

export type EnderecoCreateOrConnectWithoutAlteracoesInput = {
    create: EnderecoCreateWithoutAlteracoesInput;
    where: EnderecoWhereUniqueInput;
};

export type EnderecoCreateOrConnectWithoutCadastroInput = {
    create: EnderecoCreateWithoutCadastroInput;
    where: EnderecoWhereUniqueInput;
};

export type EnderecoCreateOrConnectWithoutCedentesInput = {
    create: EnderecoCreateWithoutCedentesInput;
    where: EnderecoWhereUniqueInput;
};

export type EnderecoCreateOrConnectWithoutHistoricoInput = {
    create: EnderecoCreateWithoutHistoricoInput;
    where: EnderecoWhereUniqueInput;
};

export type EnderecoCreateOrConnectWithoutSacadosInput = {
    create: EnderecoCreateWithoutSacadosInput;
    where: EnderecoWhereUniqueInput;
};

export type EnderecoCreateWithoutAlteracoesInput = {
    bairro?: InputMaybe<Scalars["String"]>;
    cadastro?: InputMaybe<EnderecoCadastroCreateNestedOneWithoutEnderecoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutEnderecoInput>;
    cep: Scalars["String"];
    cidade: Scalars["String"];
    complemento?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<EnderecoAuditCreateNestedManyWithoutEnderecoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    logradouro: Scalars["String"];
    numero?: InputMaybe<Scalars["Int"]>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutEnderecoInput>;
    uf: Scalars["String"];
};

export type EnderecoCreateWithoutCadastroInput = {
    alteracoes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutEnderecoInput>;
    bairro?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutEnderecoInput>;
    cep: Scalars["String"];
    cidade: Scalars["String"];
    complemento?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<EnderecoAuditCreateNestedManyWithoutEnderecoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    logradouro: Scalars["String"];
    numero?: InputMaybe<Scalars["Int"]>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutEnderecoInput>;
    uf: Scalars["String"];
};

export type EnderecoCreateWithoutCedentesInput = {
    alteracoes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutEnderecoInput>;
    bairro?: InputMaybe<Scalars["String"]>;
    cadastro?: InputMaybe<EnderecoCadastroCreateNestedOneWithoutEnderecoInput>;
    cep: Scalars["String"];
    cidade: Scalars["String"];
    complemento?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<EnderecoAuditCreateNestedManyWithoutEnderecoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    logradouro: Scalars["String"];
    numero?: InputMaybe<Scalars["Int"]>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutEnderecoInput>;
    uf: Scalars["String"];
};

export type EnderecoCreateWithoutHistoricoInput = {
    alteracoes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutEnderecoInput>;
    bairro?: InputMaybe<Scalars["String"]>;
    cadastro?: InputMaybe<EnderecoCadastroCreateNestedOneWithoutEnderecoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutEnderecoInput>;
    cep: Scalars["String"];
    cidade: Scalars["String"];
    complemento?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    logradouro: Scalars["String"];
    numero?: InputMaybe<Scalars["Int"]>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutEnderecoInput>;
    uf: Scalars["String"];
};

export type EnderecoCreateWithoutSacadosInput = {
    alteracoes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutEnderecoInput>;
    bairro?: InputMaybe<Scalars["String"]>;
    cadastro?: InputMaybe<EnderecoCadastroCreateNestedOneWithoutEnderecoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutEnderecoInput>;
    cep: Scalars["String"];
    cidade: Scalars["String"];
    complemento?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<EnderecoAuditCreateNestedManyWithoutEnderecoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    logradouro: Scalars["String"];
    numero?: InputMaybe<Scalars["Int"]>;
    uf: Scalars["String"];
};

export type EnderecoGroupBy = {
    __typename?: "EnderecoGroupBy";
    _avg?: Maybe<EnderecoAvgAggregate>;
    _count?: Maybe<EnderecoCountAggregate>;
    _max?: Maybe<EnderecoMaxAggregate>;
    _min?: Maybe<EnderecoMinAggregate>;
    _sum?: Maybe<EnderecoSumAggregate>;
    bairro?: Maybe<Scalars["String"]>;
    cadastroId?: Maybe<Scalars["Int"]>;
    cep: Scalars["String"];
    cidade: Scalars["String"];
    complemento?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    logradouro: Scalars["String"];
    numero?: Maybe<Scalars["Int"]>;
    uf: Scalars["String"];
};

export type EnderecoLogradouroNumeroCepCompoundUniqueInput = {
    cep: Scalars["String"];
    logradouro: Scalars["String"];
    numero: Scalars["Int"];
};

export type EnderecoMaxAggregate = {
    __typename?: "EnderecoMaxAggregate";
    bairro?: Maybe<Scalars["String"]>;
    cadastroId?: Maybe<Scalars["Int"]>;
    cep?: Maybe<Scalars["String"]>;
    cidade?: Maybe<Scalars["String"]>;
    complemento?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    logradouro?: Maybe<Scalars["String"]>;
    numero?: Maybe<Scalars["Int"]>;
    uf?: Maybe<Scalars["String"]>;
};

export type EnderecoMaxOrderByAggregateInput = {
    bairro?: InputMaybe<SortOrder>;
    cadastroId?: InputMaybe<SortOrder>;
    cep?: InputMaybe<SortOrder>;
    cidade?: InputMaybe<SortOrder>;
    complemento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    logradouro?: InputMaybe<SortOrder>;
    numero?: InputMaybe<SortOrder>;
    uf?: InputMaybe<SortOrder>;
};

export type EnderecoMinAggregate = {
    __typename?: "EnderecoMinAggregate";
    bairro?: Maybe<Scalars["String"]>;
    cadastroId?: Maybe<Scalars["Int"]>;
    cep?: Maybe<Scalars["String"]>;
    cidade?: Maybe<Scalars["String"]>;
    complemento?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    logradouro?: Maybe<Scalars["String"]>;
    numero?: Maybe<Scalars["Int"]>;
    uf?: Maybe<Scalars["String"]>;
};

export type EnderecoMinOrderByAggregateInput = {
    bairro?: InputMaybe<SortOrder>;
    cadastroId?: InputMaybe<SortOrder>;
    cep?: InputMaybe<SortOrder>;
    cidade?: InputMaybe<SortOrder>;
    complemento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    logradouro?: InputMaybe<SortOrder>;
    numero?: InputMaybe<SortOrder>;
    uf?: InputMaybe<SortOrder>;
};

export type EnderecoOrderByWithAggregationInput = {
    _avg?: InputMaybe<EnderecoAvgOrderByAggregateInput>;
    _count?: InputMaybe<EnderecoCountOrderByAggregateInput>;
    _max?: InputMaybe<EnderecoMaxOrderByAggregateInput>;
    _min?: InputMaybe<EnderecoMinOrderByAggregateInput>;
    _sum?: InputMaybe<EnderecoSumOrderByAggregateInput>;
    bairro?: InputMaybe<SortOrder>;
    cadastroId?: InputMaybe<SortOrder>;
    cep?: InputMaybe<SortOrder>;
    cidade?: InputMaybe<SortOrder>;
    complemento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    logradouro?: InputMaybe<SortOrder>;
    numero?: InputMaybe<SortOrder>;
    uf?: InputMaybe<SortOrder>;
};

export type EnderecoOrderByWithRelationInput = {
    alteracoes?: InputMaybe<EnderecoAlteracaoOrderByRelationAggregateInput>;
    bairro?: InputMaybe<SortOrder>;
    cadastro?: InputMaybe<EnderecoCadastroOrderByWithRelationInput>;
    cadastroId?: InputMaybe<SortOrder>;
    cedentes?: InputMaybe<CedenteOrderByRelationAggregateInput>;
    cep?: InputMaybe<SortOrder>;
    cidade?: InputMaybe<SortOrder>;
    complemento?: InputMaybe<SortOrder>;
    historico?: InputMaybe<EnderecoAuditOrderByRelationAggregateInput>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    logradouro?: InputMaybe<SortOrder>;
    numero?: InputMaybe<SortOrder>;
    sacados?: InputMaybe<SacadoOrderByRelationAggregateInput>;
    uf?: InputMaybe<SortOrder>;
};

export type EnderecoRelationFilter = {
    is?: InputMaybe<EnderecoWhereInput>;
    isNot?: InputMaybe<EnderecoWhereInput>;
};

export enum EnderecoScalarFieldEnum {
    Bairro = "bairro",
    CadastroId = "cadastroId",
    Cep = "cep",
    Cidade = "cidade",
    Complemento = "complemento",
    Id = "id",
    Inativo = "inativo",
    Logradouro = "logradouro",
    Numero = "numero",
    Uf = "uf",
}

export type EnderecoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<EnderecoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<EnderecoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<EnderecoScalarWhereWithAggregatesInput>>;
    bairro?: InputMaybe<StringNullableWithAggregatesFilter>;
    cadastroId?: InputMaybe<IntNullableWithAggregatesFilter>;
    cep?: InputMaybe<StringWithAggregatesFilter>;
    cidade?: InputMaybe<StringWithAggregatesFilter>;
    complemento?: InputMaybe<StringNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    logradouro?: InputMaybe<StringWithAggregatesFilter>;
    numero?: InputMaybe<IntNullableWithAggregatesFilter>;
    uf?: InputMaybe<StringWithAggregatesFilter>;
};

export type EnderecoSumAggregate = {
    __typename?: "EnderecoSumAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    numero?: Maybe<Scalars["Int"]>;
};

export type EnderecoSumOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    numero?: InputMaybe<SortOrder>;
};

export type EnderecoUpdateInput = {
    alteracoes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutEnderecoNestedInput>;
    bairro?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<EnderecoCadastroUpdateOneWithoutEnderecoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutEnderecoNestedInput>;
    cep?: InputMaybe<StringFieldUpdateOperationsInput>;
    cidade?: InputMaybe<StringFieldUpdateOperationsInput>;
    complemento?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<EnderecoAuditUpdateManyWithoutEnderecoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    logradouro?: InputMaybe<StringFieldUpdateOperationsInput>;
    numero?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutEnderecoNestedInput>;
    uf?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoUpdateManyMutationInput = {
    bairro?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cep?: InputMaybe<StringFieldUpdateOperationsInput>;
    cidade?: InputMaybe<StringFieldUpdateOperationsInput>;
    complemento?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    logradouro?: InputMaybe<StringFieldUpdateOperationsInput>;
    numero?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    uf?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoUpdateOneRequiredWithoutAlteracoesNestedInput = {
    connect?: InputMaybe<EnderecoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCreateOrConnectWithoutAlteracoesInput>;
    create?: InputMaybe<EnderecoCreateWithoutAlteracoesInput>;
    update?: InputMaybe<EnderecoUpdateWithoutAlteracoesInput>;
    upsert?: InputMaybe<EnderecoUpsertWithoutAlteracoesInput>;
};

export type EnderecoUpdateOneRequiredWithoutHistoricoNestedInput = {
    connect?: InputMaybe<EnderecoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<EnderecoCreateWithoutHistoricoInput>;
    update?: InputMaybe<EnderecoUpdateWithoutHistoricoInput>;
    upsert?: InputMaybe<EnderecoUpsertWithoutHistoricoInput>;
};

export type EnderecoUpdateOneWithoutCadastroNestedInput = {
    connect?: InputMaybe<EnderecoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCreateOrConnectWithoutCadastroInput>;
    create?: InputMaybe<EnderecoCreateWithoutCadastroInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<EnderecoUpdateWithoutCadastroInput>;
    upsert?: InputMaybe<EnderecoUpsertWithoutCadastroInput>;
};

export type EnderecoUpdateOneWithoutCedentesNestedInput = {
    connect?: InputMaybe<EnderecoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCreateOrConnectWithoutCedentesInput>;
    create?: InputMaybe<EnderecoCreateWithoutCedentesInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<EnderecoUpdateWithoutCedentesInput>;
    upsert?: InputMaybe<EnderecoUpsertWithoutCedentesInput>;
};

export type EnderecoUpdateOneWithoutSacadosNestedInput = {
    connect?: InputMaybe<EnderecoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EnderecoCreateOrConnectWithoutSacadosInput>;
    create?: InputMaybe<EnderecoCreateWithoutSacadosInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<EnderecoUpdateWithoutSacadosInput>;
    upsert?: InputMaybe<EnderecoUpsertWithoutSacadosInput>;
};

export type EnderecoUpdateWithoutAlteracoesInput = {
    bairro?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<EnderecoCadastroUpdateOneWithoutEnderecoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutEnderecoNestedInput>;
    cep?: InputMaybe<StringFieldUpdateOperationsInput>;
    cidade?: InputMaybe<StringFieldUpdateOperationsInput>;
    complemento?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<EnderecoAuditUpdateManyWithoutEnderecoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    logradouro?: InputMaybe<StringFieldUpdateOperationsInput>;
    numero?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutEnderecoNestedInput>;
    uf?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoUpdateWithoutCadastroInput = {
    alteracoes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutEnderecoNestedInput>;
    bairro?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutEnderecoNestedInput>;
    cep?: InputMaybe<StringFieldUpdateOperationsInput>;
    cidade?: InputMaybe<StringFieldUpdateOperationsInput>;
    complemento?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<EnderecoAuditUpdateManyWithoutEnderecoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    logradouro?: InputMaybe<StringFieldUpdateOperationsInput>;
    numero?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutEnderecoNestedInput>;
    uf?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoUpdateWithoutCedentesInput = {
    alteracoes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutEnderecoNestedInput>;
    bairro?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<EnderecoCadastroUpdateOneWithoutEnderecoNestedInput>;
    cep?: InputMaybe<StringFieldUpdateOperationsInput>;
    cidade?: InputMaybe<StringFieldUpdateOperationsInput>;
    complemento?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<EnderecoAuditUpdateManyWithoutEnderecoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    logradouro?: InputMaybe<StringFieldUpdateOperationsInput>;
    numero?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutEnderecoNestedInput>;
    uf?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoUpdateWithoutHistoricoInput = {
    alteracoes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutEnderecoNestedInput>;
    bairro?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<EnderecoCadastroUpdateOneWithoutEnderecoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutEnderecoNestedInput>;
    cep?: InputMaybe<StringFieldUpdateOperationsInput>;
    cidade?: InputMaybe<StringFieldUpdateOperationsInput>;
    complemento?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    logradouro?: InputMaybe<StringFieldUpdateOperationsInput>;
    numero?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutEnderecoNestedInput>;
    uf?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoUpdateWithoutSacadosInput = {
    alteracoes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutEnderecoNestedInput>;
    bairro?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cadastro?: InputMaybe<EnderecoCadastroUpdateOneWithoutEnderecoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutEnderecoNestedInput>;
    cep?: InputMaybe<StringFieldUpdateOperationsInput>;
    cidade?: InputMaybe<StringFieldUpdateOperationsInput>;
    complemento?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<EnderecoAuditUpdateManyWithoutEnderecoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    logradouro?: InputMaybe<StringFieldUpdateOperationsInput>;
    numero?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    uf?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EnderecoUpsertWithoutAlteracoesInput = {
    create: EnderecoCreateWithoutAlteracoesInput;
    update: EnderecoUpdateWithoutAlteracoesInput;
};

export type EnderecoUpsertWithoutCadastroInput = {
    create: EnderecoCreateWithoutCadastroInput;
    update: EnderecoUpdateWithoutCadastroInput;
};

export type EnderecoUpsertWithoutCedentesInput = {
    create: EnderecoCreateWithoutCedentesInput;
    update: EnderecoUpdateWithoutCedentesInput;
};

export type EnderecoUpsertWithoutHistoricoInput = {
    create: EnderecoCreateWithoutHistoricoInput;
    update: EnderecoUpdateWithoutHistoricoInput;
};

export type EnderecoUpsertWithoutSacadosInput = {
    create: EnderecoCreateWithoutSacadosInput;
    update: EnderecoUpdateWithoutSacadosInput;
};

export type EnderecoWhereInput = {
    AND?: InputMaybe<Array<EnderecoWhereInput>>;
    NOT?: InputMaybe<Array<EnderecoWhereInput>>;
    OR?: InputMaybe<Array<EnderecoWhereInput>>;
    alteracoes?: InputMaybe<EnderecoAlteracaoListRelationFilter>;
    bairro?: InputMaybe<StringNullableFilter>;
    cadastro?: InputMaybe<EnderecoCadastroRelationFilter>;
    cadastroId?: InputMaybe<IntNullableFilter>;
    cedentes?: InputMaybe<CedenteListRelationFilter>;
    cep?: InputMaybe<StringFilter>;
    cidade?: InputMaybe<StringFilter>;
    complemento?: InputMaybe<StringNullableFilter>;
    historico?: InputMaybe<EnderecoAuditListRelationFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    logradouro?: InputMaybe<StringFilter>;
    numero?: InputMaybe<IntNullableFilter>;
    sacados?: InputMaybe<SacadoListRelationFilter>;
    uf?: InputMaybe<StringFilter>;
};

export type EnderecoWhereUniqueInput = {
    cadastroId?: InputMaybe<Scalars["Int"]>;
    id?: InputMaybe<Scalars["Int"]>;
    logradouro_numero_cep?: InputMaybe<EnderecoLogradouroNumeroCepCompoundUniqueInput>;
};

export enum Entidade {
    AgenteComercial = "AGENTE_COMERCIAL",
    Arquivo = "ARQUIVO",
    Auditoria = "AUDITORIA",
    Cedente = "CEDENTE",
    Cheque = "CHEQUE",
    Contato = "CONTATO",
    ContaBancaria = "CONTA_BANCARIA",
    Dashboard = "DASHBOARD",
    Debenturista = "DEBENTURISTA",
    Empresa = "EMPRESA",
    Gerente = "GERENTE",
    Historico = "HISTORICO",
    Minibanco = "MINIBANCO",
    Nfe = "NFE",
    Occupation = "OCCUPATION",
    Operacao = "OPERACAO",
    OperacaoAnexos = "OPERACAO_ANEXOS",
    Parceiro = "PARCEIRO",
    ParcelaNfe = "PARCELA_NFE",
    Permissao = "PERMISSAO",
    Recebivel = "RECEBIVEL",
    Sacado = "SACADO",
    Usuario = "USUARIO",
}

export type EnumAuditAcoesFieldUpdateOperationsInput = {
    set?: InputMaybe<AuditAcoes>;
};

export type EnumAuditAcoesFilter = {
    equals?: InputMaybe<AuditAcoes>;
    in?: InputMaybe<Array<AuditAcoes>>;
    not?: InputMaybe<NestedEnumAuditAcoesFilter>;
    notIn?: InputMaybe<Array<AuditAcoes>>;
};

export type EnumAuditAcoesWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumAuditAcoesFilter>;
    _min?: InputMaybe<NestedEnumAuditAcoesFilter>;
    equals?: InputMaybe<AuditAcoes>;
    in?: InputMaybe<Array<AuditAcoes>>;
    not?: InputMaybe<NestedEnumAuditAcoesWithAggregatesFilter>;
    notIn?: InputMaybe<Array<AuditAcoes>>;
};

export type EnumCadastroStatusFieldUpdateOperationsInput = {
    set?: InputMaybe<CadastroStatus>;
};

export type EnumCadastroStatusFilter = {
    equals?: InputMaybe<CadastroStatus>;
    in?: InputMaybe<Array<CadastroStatus>>;
    not?: InputMaybe<NestedEnumCadastroStatusFilter>;
    notIn?: InputMaybe<Array<CadastroStatus>>;
};

export type EnumCadastroStatusWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumCadastroStatusFilter>;
    _min?: InputMaybe<NestedEnumCadastroStatusFilter>;
    equals?: InputMaybe<CadastroStatus>;
    in?: InputMaybe<Array<CadastroStatus>>;
    not?: InputMaybe<NestedEnumCadastroStatusWithAggregatesFilter>;
    notIn?: InputMaybe<Array<CadastroStatus>>;
};

export type EnumCedenteStatusFieldUpdateOperationsInput = {
    set?: InputMaybe<CedenteStatus>;
};

export type EnumCedenteStatusFilter = {
    equals?: InputMaybe<CedenteStatus>;
    in?: InputMaybe<Array<CedenteStatus>>;
    not?: InputMaybe<NestedEnumCedenteStatusFilter>;
    notIn?: InputMaybe<Array<CedenteStatus>>;
};

export type EnumCedenteStatusWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumCedenteStatusFilter>;
    _min?: InputMaybe<NestedEnumCedenteStatusFilter>;
    equals?: InputMaybe<CedenteStatus>;
    in?: InputMaybe<Array<CedenteStatus>>;
    not?: InputMaybe<NestedEnumCedenteStatusWithAggregatesFilter>;
    notIn?: InputMaybe<Array<CedenteStatus>>;
};

export type EnumCrmTypeFieldUpdateOperationsInput = {
    set?: InputMaybe<CrmType>;
};

export type EnumCrmTypeFilter = {
    equals?: InputMaybe<CrmType>;
    in?: InputMaybe<Array<CrmType>>;
    not?: InputMaybe<NestedEnumCrmTypeFilter>;
    notIn?: InputMaybe<Array<CrmType>>;
};

export type EnumCrmTypeWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumCrmTypeFilter>;
    _min?: InputMaybe<NestedEnumCrmTypeFilter>;
    equals?: InputMaybe<CrmType>;
    in?: InputMaybe<Array<CrmType>>;
    not?: InputMaybe<NestedEnumCrmTypeWithAggregatesFilter>;
    notIn?: InputMaybe<Array<CrmType>>;
};

export type EnumDigitalSignatureNullableFilter = {
    equals?: InputMaybe<DigitalSignature>;
    in?: InputMaybe<Array<DigitalSignature>>;
    not?: InputMaybe<NestedEnumDigitalSignatureNullableFilter>;
    notIn?: InputMaybe<Array<DigitalSignature>>;
};

export type EnumDigitalSignatureNullableWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedEnumDigitalSignatureNullableFilter>;
    _min?: InputMaybe<NestedEnumDigitalSignatureNullableFilter>;
    equals?: InputMaybe<DigitalSignature>;
    in?: InputMaybe<Array<DigitalSignature>>;
    not?: InputMaybe<NestedEnumDigitalSignatureNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<DigitalSignature>>;
};

export type EnumErpFieldUpdateOperationsInput = {
    set?: InputMaybe<Erp>;
};

export type EnumErpFilter = {
    equals?: InputMaybe<Erp>;
    in?: InputMaybe<Array<Erp>>;
    not?: InputMaybe<NestedEnumErpFilter>;
    notIn?: InputMaybe<Array<Erp>>;
};

export type EnumErpWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumErpFilter>;
    _min?: InputMaybe<NestedEnumErpFilter>;
    equals?: InputMaybe<Erp>;
    in?: InputMaybe<Array<Erp>>;
    not?: InputMaybe<NestedEnumErpWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Erp>>;
};

export type EnumEntidadeFieldUpdateOperationsInput = {
    set?: InputMaybe<Entidade>;
};

export type EnumEntidadeFilter = {
    equals?: InputMaybe<Entidade>;
    in?: InputMaybe<Array<Entidade>>;
    not?: InputMaybe<NestedEnumEntidadeFilter>;
    notIn?: InputMaybe<Array<Entidade>>;
};

export type EnumEntidadeWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumEntidadeFilter>;
    _min?: InputMaybe<NestedEnumEntidadeFilter>;
    equals?: InputMaybe<Entidade>;
    in?: InputMaybe<Array<Entidade>>;
    not?: InputMaybe<NestedEnumEntidadeWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Entidade>>;
};

export type EnumNivelAcessoFieldUpdateOperationsInput = {
    set?: InputMaybe<NivelAcesso>;
};

export type EnumNivelAcessoFilter = {
    equals?: InputMaybe<NivelAcesso>;
    in?: InputMaybe<Array<NivelAcesso>>;
    not?: InputMaybe<NestedEnumNivelAcessoFilter>;
    notIn?: InputMaybe<Array<NivelAcesso>>;
};

export type EnumNivelAcessoWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumNivelAcessoFilter>;
    _min?: InputMaybe<NestedEnumNivelAcessoFilter>;
    equals?: InputMaybe<NivelAcesso>;
    in?: InputMaybe<Array<NivelAcesso>>;
    not?: InputMaybe<NestedEnumNivelAcessoWithAggregatesFilter>;
    notIn?: InputMaybe<Array<NivelAcesso>>;
};

export type EnumOrigemCedenteFieldUpdateOperationsInput = {
    set?: InputMaybe<OrigemCedente>;
};

export type EnumOrigemCedenteFilter = {
    equals?: InputMaybe<OrigemCedente>;
    in?: InputMaybe<Array<OrigemCedente>>;
    not?: InputMaybe<NestedEnumOrigemCedenteFilter>;
    notIn?: InputMaybe<Array<OrigemCedente>>;
};

export type EnumOrigemCedenteWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumOrigemCedenteFilter>;
    _min?: InputMaybe<NestedEnumOrigemCedenteFilter>;
    equals?: InputMaybe<OrigemCedente>;
    in?: InputMaybe<Array<OrigemCedente>>;
    not?: InputMaybe<NestedEnumOrigemCedenteWithAggregatesFilter>;
    notIn?: InputMaybe<Array<OrigemCedente>>;
};

export type EnumOrigemFieldUpdateOperationsInput = {
    set?: InputMaybe<Origem>;
};

export type EnumOrigemFilter = {
    equals?: InputMaybe<Origem>;
    in?: InputMaybe<Array<Origem>>;
    not?: InputMaybe<NestedEnumOrigemFilter>;
    notIn?: InputMaybe<Array<Origem>>;
};

export type EnumOrigemWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumOrigemFilter>;
    _min?: InputMaybe<NestedEnumOrigemFilter>;
    equals?: InputMaybe<Origem>;
    in?: InputMaybe<Array<Origem>>;
    not?: InputMaybe<NestedEnumOrigemWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Origem>>;
};

export type EnumPayableTypeFieldUpdateOperationsInput = {
    set?: InputMaybe<PayableType>;
};

export type EnumPayableTypeFilter = {
    equals?: InputMaybe<PayableType>;
    in?: InputMaybe<Array<PayableType>>;
    not?: InputMaybe<NestedEnumPayableTypeFilter>;
    notIn?: InputMaybe<Array<PayableType>>;
};

export type EnumPayableTypeNullableFilter = {
    equals?: InputMaybe<PayableType>;
    in?: InputMaybe<Array<PayableType>>;
    not?: InputMaybe<NestedEnumPayableTypeNullableFilter>;
    notIn?: InputMaybe<Array<PayableType>>;
};

export type EnumPayableTypeNullableWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedEnumPayableTypeNullableFilter>;
    _min?: InputMaybe<NestedEnumPayableTypeNullableFilter>;
    equals?: InputMaybe<PayableType>;
    in?: InputMaybe<Array<PayableType>>;
    not?: InputMaybe<NestedEnumPayableTypeNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<PayableType>>;
};

export type EnumPayableTypeWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumPayableTypeFilter>;
    _min?: InputMaybe<NestedEnumPayableTypeFilter>;
    equals?: InputMaybe<PayableType>;
    in?: InputMaybe<Array<PayableType>>;
    not?: InputMaybe<NestedEnumPayableTypeWithAggregatesFilter>;
    notIn?: InputMaybe<Array<PayableType>>;
};

export type EnumPixKeyTypeNullableFilter = {
    equals?: InputMaybe<PixKeyType>;
    in?: InputMaybe<Array<PixKeyType>>;
    not?: InputMaybe<NestedEnumPixKeyTypeNullableFilter>;
    notIn?: InputMaybe<Array<PixKeyType>>;
};

export type EnumPixKeyTypeNullableWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedEnumPixKeyTypeNullableFilter>;
    _min?: InputMaybe<NestedEnumPixKeyTypeNullableFilter>;
    equals?: InputMaybe<PixKeyType>;
    in?: InputMaybe<Array<PixKeyType>>;
    not?: InputMaybe<NestedEnumPixKeyTypeNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<PixKeyType>>;
};

export type EnumRecebivelOrigemFieldUpdateOperationsInput = {
    set?: InputMaybe<RecebivelOrigem>;
};

export type EnumRecebivelOrigemFilter = {
    equals?: InputMaybe<RecebivelOrigem>;
    in?: InputMaybe<Array<RecebivelOrigem>>;
    not?: InputMaybe<NestedEnumRecebivelOrigemFilter>;
    notIn?: InputMaybe<Array<RecebivelOrigem>>;
};

export type EnumRecebivelOrigemWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumRecebivelOrigemFilter>;
    _min?: InputMaybe<NestedEnumRecebivelOrigemFilter>;
    equals?: InputMaybe<RecebivelOrigem>;
    in?: InputMaybe<Array<RecebivelOrigem>>;
    not?: InputMaybe<NestedEnumRecebivelOrigemWithAggregatesFilter>;
    notIn?: InputMaybe<Array<RecebivelOrigem>>;
};

export type EnumStatusOperacionalFieldUpdateOperationsInput = {
    set?: InputMaybe<StatusOperacional>;
};

export type EnumStatusOperacionalFilter = {
    equals?: InputMaybe<StatusOperacional>;
    in?: InputMaybe<Array<StatusOperacional>>;
    not?: InputMaybe<NestedEnumStatusOperacionalFilter>;
    notIn?: InputMaybe<Array<StatusOperacional>>;
};

export type EnumStatusOperacionalWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumStatusOperacionalFilter>;
    _min?: InputMaybe<NestedEnumStatusOperacionalFilter>;
    equals?: InputMaybe<StatusOperacional>;
    in?: InputMaybe<Array<StatusOperacional>>;
    not?: InputMaybe<NestedEnumStatusOperacionalWithAggregatesFilter>;
    notIn?: InputMaybe<Array<StatusOperacional>>;
};

export type EnumStatusOperationalAssignorByMinibankFieldUpdateOperationsInput = {
    set?: InputMaybe<StatusOperationalAssignorByMinibank>;
};

export type EnumStatusOperationalAssignorByMinibankFilter = {
    equals?: InputMaybe<StatusOperationalAssignorByMinibank>;
    in?: InputMaybe<Array<StatusOperationalAssignorByMinibank>>;
    not?: InputMaybe<NestedEnumStatusOperationalAssignorByMinibankFilter>;
    notIn?: InputMaybe<Array<StatusOperationalAssignorByMinibank>>;
};

export type EnumStatusOperationalAssignorByMinibankWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumStatusOperationalAssignorByMinibankFilter>;
    _min?: InputMaybe<NestedEnumStatusOperationalAssignorByMinibankFilter>;
    equals?: InputMaybe<StatusOperationalAssignorByMinibank>;
    in?: InputMaybe<Array<StatusOperationalAssignorByMinibank>>;
    not?: InputMaybe<NestedEnumStatusOperationalAssignorByMinibankWithAggregatesFilter>;
    notIn?: InputMaybe<Array<StatusOperationalAssignorByMinibank>>;
};

export type EnumStatusRecebivelFieldUpdateOperationsInput = {
    set?: InputMaybe<StatusRecebivel>;
};

export type EnumStatusRecebivelFilter = {
    equals?: InputMaybe<StatusRecebivel>;
    in?: InputMaybe<Array<StatusRecebivel>>;
    not?: InputMaybe<NestedEnumStatusRecebivelFilter>;
    notIn?: InputMaybe<Array<StatusRecebivel>>;
};

export type EnumStatusRecebivelWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumStatusRecebivelFilter>;
    _min?: InputMaybe<NestedEnumStatusRecebivelFilter>;
    equals?: InputMaybe<StatusRecebivel>;
    in?: InputMaybe<Array<StatusRecebivel>>;
    not?: InputMaybe<NestedEnumStatusRecebivelWithAggregatesFilter>;
    notIn?: InputMaybe<Array<StatusRecebivel>>;
};

export type EnumTipoMinibancoFieldUpdateOperationsInput = {
    set?: InputMaybe<TipoMinibanco>;
};

export type EnumTipoMinibancoFilter = {
    equals?: InputMaybe<TipoMinibanco>;
    in?: InputMaybe<Array<TipoMinibanco>>;
    not?: InputMaybe<NestedEnumTipoMinibancoFilter>;
    notIn?: InputMaybe<Array<TipoMinibanco>>;
};

export type EnumTipoMinibancoWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumTipoMinibancoFilter>;
    _min?: InputMaybe<NestedEnumTipoMinibancoFilter>;
    equals?: InputMaybe<TipoMinibanco>;
    in?: InputMaybe<Array<TipoMinibanco>>;
    not?: InputMaybe<NestedEnumTipoMinibancoWithAggregatesFilter>;
    notIn?: InputMaybe<Array<TipoMinibanco>>;
};

export type EnumTipoServicoFieldUpdateOperationsInput = {
    set?: InputMaybe<TipoServico>;
};

export type EnumTipoServicoFilter = {
    equals?: InputMaybe<TipoServico>;
    in?: InputMaybe<Array<TipoServico>>;
    not?: InputMaybe<NestedEnumTipoServicoFilter>;
    notIn?: InputMaybe<Array<TipoServico>>;
};

export type EnumTipoServicoNullableFilter = {
    equals?: InputMaybe<TipoServico>;
    in?: InputMaybe<Array<TipoServico>>;
    not?: InputMaybe<NestedEnumTipoServicoNullableFilter>;
    notIn?: InputMaybe<Array<TipoServico>>;
};

export type EnumTipoServicoNullableWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedEnumTipoServicoNullableFilter>;
    _min?: InputMaybe<NestedEnumTipoServicoNullableFilter>;
    equals?: InputMaybe<TipoServico>;
    in?: InputMaybe<Array<TipoServico>>;
    not?: InputMaybe<NestedEnumTipoServicoNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<TipoServico>>;
};

export type EnumTipoServicoWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumTipoServicoFilter>;
    _min?: InputMaybe<NestedEnumTipoServicoFilter>;
    equals?: InputMaybe<TipoServico>;
    in?: InputMaybe<Array<TipoServico>>;
    not?: InputMaybe<NestedEnumTipoServicoWithAggregatesFilter>;
    notIn?: InputMaybe<Array<TipoServico>>;
};

export type EnumTypeOfAnalysisFieldUpdateOperationsInput = {
    set?: InputMaybe<TypeOfAnalysis>;
};

export type EnumTypeOfAnalysisFilter = {
    equals?: InputMaybe<TypeOfAnalysis>;
    in?: InputMaybe<Array<TypeOfAnalysis>>;
    not?: InputMaybe<NestedEnumTypeOfAnalysisFilter>;
    notIn?: InputMaybe<Array<TypeOfAnalysis>>;
};

export type EnumTypeOfAnalysisWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumTypeOfAnalysisFilter>;
    _min?: InputMaybe<NestedEnumTypeOfAnalysisFilter>;
    equals?: InputMaybe<TypeOfAnalysis>;
    in?: InputMaybe<Array<TypeOfAnalysis>>;
    not?: InputMaybe<NestedEnumTypeOfAnalysisWithAggregatesFilter>;
    notIn?: InputMaybe<Array<TypeOfAnalysis>>;
};

export type Event = {
    __typename?: "Event";
    OperationUpdates: Array<OperationUpdates>;
    PayableUpdates: Array<PayableUpdates>;
    _count?: Maybe<EventCount>;
    active: Scalars["Boolean"];
    createdAt: Scalars["DateTime"];
    description?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    name: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type EventOperationUpdatesArgs = {
    cursor?: InputMaybe<OperationUpdatesWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationUpdatesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationUpdatesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationUpdatesWhereInput>;
};

export type EventPayableUpdatesArgs = {
    cursor?: InputMaybe<PayableUpdatesWhereUniqueInput>;
    distinct?: InputMaybe<Array<PayableUpdatesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<PayableUpdatesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PayableUpdatesWhereInput>;
};

export type EventAvgAggregate = {
    __typename?: "EventAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
};

export type EventAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type EventCount = {
    __typename?: "EventCount";
    OperationUpdates: Scalars["Int"];
    PayableUpdates: Scalars["Int"];
};

export type EventCountAggregate = {
    __typename?: "EventCountAggregate";
    _all: Scalars["Int"];
    active: Scalars["Int"];
    createdAt: Scalars["Int"];
    description: Scalars["Int"];
    id: Scalars["Int"];
    name: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type EventCountOrderByAggregateInput = {
    active?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type EventCreateInput = {
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutEventInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutEventInput>;
    active?: InputMaybe<Scalars["Boolean"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    description?: InputMaybe<Scalars["String"]>;
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type EventCreateManyInput = {
    active?: InputMaybe<Scalars["Boolean"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    description?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type EventCreateNestedOneWithoutOperationUpdatesInput = {
    connect?: InputMaybe<EventWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutOperationUpdatesInput>;
    create?: InputMaybe<EventCreateWithoutOperationUpdatesInput>;
};

export type EventCreateNestedOneWithoutPayableUpdatesInput = {
    connect?: InputMaybe<EventWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutPayableUpdatesInput>;
    create?: InputMaybe<EventCreateWithoutPayableUpdatesInput>;
};

export type EventCreateOrConnectWithoutOperationUpdatesInput = {
    create: EventCreateWithoutOperationUpdatesInput;
    where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutPayableUpdatesInput = {
    create: EventCreateWithoutPayableUpdatesInput;
    where: EventWhereUniqueInput;
};

export type EventCreateWithoutOperationUpdatesInput = {
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutEventInput>;
    active?: InputMaybe<Scalars["Boolean"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    description?: InputMaybe<Scalars["String"]>;
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type EventCreateWithoutPayableUpdatesInput = {
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutEventInput>;
    active?: InputMaybe<Scalars["Boolean"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    description?: InputMaybe<Scalars["String"]>;
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type EventGroupBy = {
    __typename?: "EventGroupBy";
    _avg?: Maybe<EventAvgAggregate>;
    _count?: Maybe<EventCountAggregate>;
    _max?: Maybe<EventMaxAggregate>;
    _min?: Maybe<EventMinAggregate>;
    _sum?: Maybe<EventSumAggregate>;
    active: Scalars["Boolean"];
    createdAt: Scalars["DateTime"];
    description?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    name: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type EventMaxAggregate = {
    __typename?: "EventMaxAggregate";
    active?: Maybe<Scalars["Boolean"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    name?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type EventMaxOrderByAggregateInput = {
    active?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type EventMinAggregate = {
    __typename?: "EventMinAggregate";
    active?: Maybe<Scalars["Boolean"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    name?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type EventMinOrderByAggregateInput = {
    active?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type EventOrderByWithAggregationInput = {
    _avg?: InputMaybe<EventAvgOrderByAggregateInput>;
    _count?: InputMaybe<EventCountOrderByAggregateInput>;
    _max?: InputMaybe<EventMaxOrderByAggregateInput>;
    _min?: InputMaybe<EventMinOrderByAggregateInput>;
    _sum?: InputMaybe<EventSumOrderByAggregateInput>;
    active?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type EventOrderByWithRelationInput = {
    OperationUpdates?: InputMaybe<OperationUpdatesOrderByRelationAggregateInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesOrderByRelationAggregateInput>;
    active?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type EventRelationFilter = {
    is?: InputMaybe<EventWhereInput>;
    isNot?: InputMaybe<EventWhereInput>;
};

export enum EventScalarFieldEnum {
    Active = "active",
    CreatedAt = "createdAt",
    Description = "description",
    Id = "id",
    Name = "name",
    UpdatedAt = "updatedAt",
}

export type EventScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<EventScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<EventScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<EventScalarWhereWithAggregatesInput>>;
    active?: InputMaybe<BoolWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    description?: InputMaybe<StringNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    name?: InputMaybe<StringWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type EventSumAggregate = {
    __typename?: "EventSumAggregate";
    id?: Maybe<Scalars["Int"]>;
};

export type EventSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type EventUpdateInput = {
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutEventNestedInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutEventNestedInput>;
    active?: InputMaybe<BoolFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventUpdateManyMutationInput = {
    active?: InputMaybe<BoolFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventUpdateOneRequiredWithoutOperationUpdatesNestedInput = {
    connect?: InputMaybe<EventWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutOperationUpdatesInput>;
    create?: InputMaybe<EventCreateWithoutOperationUpdatesInput>;
    update?: InputMaybe<EventUpdateWithoutOperationUpdatesInput>;
    upsert?: InputMaybe<EventUpsertWithoutOperationUpdatesInput>;
};

export type EventUpdateOneRequiredWithoutPayableUpdatesNestedInput = {
    connect?: InputMaybe<EventWhereUniqueInput>;
    connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutPayableUpdatesInput>;
    create?: InputMaybe<EventCreateWithoutPayableUpdatesInput>;
    update?: InputMaybe<EventUpdateWithoutPayableUpdatesInput>;
    upsert?: InputMaybe<EventUpsertWithoutPayableUpdatesInput>;
};

export type EventUpdateWithoutOperationUpdatesInput = {
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutEventNestedInput>;
    active?: InputMaybe<BoolFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutPayableUpdatesInput = {
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutEventNestedInput>;
    active?: InputMaybe<BoolFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventUpsertWithoutOperationUpdatesInput = {
    create: EventCreateWithoutOperationUpdatesInput;
    update: EventUpdateWithoutOperationUpdatesInput;
};

export type EventUpsertWithoutPayableUpdatesInput = {
    create: EventCreateWithoutPayableUpdatesInput;
    update: EventUpdateWithoutPayableUpdatesInput;
};

export type EventWhereInput = {
    AND?: InputMaybe<Array<EventWhereInput>>;
    NOT?: InputMaybe<Array<EventWhereInput>>;
    OR?: InputMaybe<Array<EventWhereInput>>;
    OperationUpdates?: InputMaybe<OperationUpdatesListRelationFilter>;
    PayableUpdates?: InputMaybe<PayableUpdatesListRelationFilter>;
    active?: InputMaybe<BoolFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    description?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    name?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EventWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
    name?: InputMaybe<Scalars["String"]>;
};

export type ExtratoIntegracao = {
    __typename?: "ExtratoIntegracao";
    chave: Scalars["Int"];
    extrato: MinibancoExtrato;
    extratoId: Scalars["Int"];
    isDebenture: Scalars["Boolean"];
};

export type ExtratoIntegracaoAvgAggregate = {
    __typename?: "ExtratoIntegracaoAvgAggregate";
    chave?: Maybe<Scalars["Float"]>;
    extratoId?: Maybe<Scalars["Float"]>;
};

export type ExtratoIntegracaoAvgOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    extratoId?: InputMaybe<SortOrder>;
};

export type ExtratoIntegracaoChaveIsDebentureCompoundUniqueInput = {
    chave: Scalars["Int"];
    isDebenture: Scalars["Boolean"];
};

export type ExtratoIntegracaoCountAggregate = {
    __typename?: "ExtratoIntegracaoCountAggregate";
    _all: Scalars["Int"];
    chave: Scalars["Int"];
    extratoId: Scalars["Int"];
    isDebenture: Scalars["Int"];
};

export type ExtratoIntegracaoCountOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    extratoId?: InputMaybe<SortOrder>;
    isDebenture?: InputMaybe<SortOrder>;
};

export type ExtratoIntegracaoCreateInput = {
    chave: Scalars["Int"];
    extrato: MinibancoExtratoCreateNestedOneWithoutChaveIntegracaoInput;
    isDebenture?: InputMaybe<Scalars["Boolean"]>;
};

export type ExtratoIntegracaoCreateManyInput = {
    chave: Scalars["Int"];
    extratoId: Scalars["Int"];
    isDebenture?: InputMaybe<Scalars["Boolean"]>;
};

export type ExtratoIntegracaoCreateNestedOneWithoutExtratoInput = {
    connect?: InputMaybe<ExtratoIntegracaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ExtratoIntegracaoCreateOrConnectWithoutExtratoInput>;
    create?: InputMaybe<ExtratoIntegracaoCreateWithoutExtratoInput>;
};

export type ExtratoIntegracaoCreateOrConnectWithoutExtratoInput = {
    create: ExtratoIntegracaoCreateWithoutExtratoInput;
    where: ExtratoIntegracaoWhereUniqueInput;
};

export type ExtratoIntegracaoCreateWithoutExtratoInput = {
    chave: Scalars["Int"];
    isDebenture?: InputMaybe<Scalars["Boolean"]>;
};

export type ExtratoIntegracaoGroupBy = {
    __typename?: "ExtratoIntegracaoGroupBy";
    _avg?: Maybe<ExtratoIntegracaoAvgAggregate>;
    _count?: Maybe<ExtratoIntegracaoCountAggregate>;
    _max?: Maybe<ExtratoIntegracaoMaxAggregate>;
    _min?: Maybe<ExtratoIntegracaoMinAggregate>;
    _sum?: Maybe<ExtratoIntegracaoSumAggregate>;
    chave: Scalars["Int"];
    extratoId: Scalars["Int"];
    isDebenture: Scalars["Boolean"];
};

export type ExtratoIntegracaoMaxAggregate = {
    __typename?: "ExtratoIntegracaoMaxAggregate";
    chave?: Maybe<Scalars["Int"]>;
    extratoId?: Maybe<Scalars["Int"]>;
    isDebenture?: Maybe<Scalars["Boolean"]>;
};

export type ExtratoIntegracaoMaxOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    extratoId?: InputMaybe<SortOrder>;
    isDebenture?: InputMaybe<SortOrder>;
};

export type ExtratoIntegracaoMinAggregate = {
    __typename?: "ExtratoIntegracaoMinAggregate";
    chave?: Maybe<Scalars["Int"]>;
    extratoId?: Maybe<Scalars["Int"]>;
    isDebenture?: Maybe<Scalars["Boolean"]>;
};

export type ExtratoIntegracaoMinOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    extratoId?: InputMaybe<SortOrder>;
    isDebenture?: InputMaybe<SortOrder>;
};

export type ExtratoIntegracaoOrderByWithAggregationInput = {
    _avg?: InputMaybe<ExtratoIntegracaoAvgOrderByAggregateInput>;
    _count?: InputMaybe<ExtratoIntegracaoCountOrderByAggregateInput>;
    _max?: InputMaybe<ExtratoIntegracaoMaxOrderByAggregateInput>;
    _min?: InputMaybe<ExtratoIntegracaoMinOrderByAggregateInput>;
    _sum?: InputMaybe<ExtratoIntegracaoSumOrderByAggregateInput>;
    chave?: InputMaybe<SortOrder>;
    extratoId?: InputMaybe<SortOrder>;
    isDebenture?: InputMaybe<SortOrder>;
};

export type ExtratoIntegracaoOrderByWithRelationInput = {
    chave?: InputMaybe<SortOrder>;
    extrato?: InputMaybe<MinibancoExtratoOrderByWithRelationInput>;
    extratoId?: InputMaybe<SortOrder>;
    isDebenture?: InputMaybe<SortOrder>;
};

export type ExtratoIntegracaoRelationFilter = {
    is?: InputMaybe<ExtratoIntegracaoWhereInput>;
    isNot?: InputMaybe<ExtratoIntegracaoWhereInput>;
};

export enum ExtratoIntegracaoScalarFieldEnum {
    Chave = "chave",
    ExtratoId = "extratoId",
    IsDebenture = "isDebenture",
}

export type ExtratoIntegracaoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<ExtratoIntegracaoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<ExtratoIntegracaoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<ExtratoIntegracaoScalarWhereWithAggregatesInput>>;
    chave?: InputMaybe<IntWithAggregatesFilter>;
    extratoId?: InputMaybe<IntWithAggregatesFilter>;
    isDebenture?: InputMaybe<BoolWithAggregatesFilter>;
};

export type ExtratoIntegracaoSumAggregate = {
    __typename?: "ExtratoIntegracaoSumAggregate";
    chave?: Maybe<Scalars["Int"]>;
    extratoId?: Maybe<Scalars["Int"]>;
};

export type ExtratoIntegracaoSumOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    extratoId?: InputMaybe<SortOrder>;
};

export type ExtratoIntegracaoUpdateInput = {
    chave?: InputMaybe<IntFieldUpdateOperationsInput>;
    extrato?: InputMaybe<MinibancoExtratoUpdateOneRequiredWithoutChaveIntegracaoNestedInput>;
    isDebenture?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ExtratoIntegracaoUpdateManyMutationInput = {
    chave?: InputMaybe<IntFieldUpdateOperationsInput>;
    isDebenture?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ExtratoIntegracaoUpdateOneWithoutExtratoNestedInput = {
    connect?: InputMaybe<ExtratoIntegracaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ExtratoIntegracaoCreateOrConnectWithoutExtratoInput>;
    create?: InputMaybe<ExtratoIntegracaoCreateWithoutExtratoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<ExtratoIntegracaoUpdateWithoutExtratoInput>;
    upsert?: InputMaybe<ExtratoIntegracaoUpsertWithoutExtratoInput>;
};

export type ExtratoIntegracaoUpdateWithoutExtratoInput = {
    chave?: InputMaybe<IntFieldUpdateOperationsInput>;
    isDebenture?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type ExtratoIntegracaoUpsertWithoutExtratoInput = {
    create: ExtratoIntegracaoCreateWithoutExtratoInput;
    update: ExtratoIntegracaoUpdateWithoutExtratoInput;
};

export type ExtratoIntegracaoWhereInput = {
    AND?: InputMaybe<Array<ExtratoIntegracaoWhereInput>>;
    NOT?: InputMaybe<Array<ExtratoIntegracaoWhereInput>>;
    OR?: InputMaybe<Array<ExtratoIntegracaoWhereInput>>;
    chave?: InputMaybe<IntFilter>;
    extrato?: InputMaybe<MinibancoExtratoRelationFilter>;
    extratoId?: InputMaybe<IntFilter>;
    isDebenture?: InputMaybe<BoolFilter>;
};

export type ExtratoIntegracaoWhereUniqueInput = {
    chave_isDebenture?: InputMaybe<ExtratoIntegracaoChaveIsDebentureCompoundUniqueInput>;
    extratoId?: InputMaybe<Scalars["Int"]>;
};

export type GrupoNotificacao = {
    __typename?: "GrupoNotificacao";
    _count?: Maybe<GrupoNotificacaoCount>;
    createdAt: Scalars["DateTime"];
    descricao?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
    plataforma: TipoServico;
    responsavel: Usuario;
    responsavelId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
    usuarios: Array<Usuario>;
};

export type GrupoNotificacaoUsuariosArgs = {
    cursor?: InputMaybe<UsuarioWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioWhereInput>;
};

export type GrupoNotificacaoAvgAggregate = {
    __typename?: "GrupoNotificacaoAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    responsavelId?: Maybe<Scalars["Float"]>;
};

export type GrupoNotificacaoAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
};

export type GrupoNotificacaoCount = {
    __typename?: "GrupoNotificacaoCount";
    usuarios: Scalars["Int"];
};

export type GrupoNotificacaoCountAggregate = {
    __typename?: "GrupoNotificacaoCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    descricao: Scalars["Int"];
    id: Scalars["Int"];
    minibancoId: Scalars["Int"];
    nome: Scalars["Int"];
    plataforma: Scalars["Int"];
    responsavelId: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type GrupoNotificacaoCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    plataforma?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type GrupoNotificacaoCreateInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    minibanco: MinibancoCreateNestedOneWithoutGrupoNotificacaoInput;
    nome: Scalars["String"];
    plataforma: TipoServico;
    responsavel: UsuarioCreateNestedOneWithoutRespGrupoNotificacaoInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutNotificacoesInput>;
};

export type GrupoNotificacaoCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
    plataforma: TipoServico;
    responsavelId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type GrupoNotificacaoCreateManyMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    nome: Scalars["String"];
    plataforma: TipoServico;
    responsavelId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type GrupoNotificacaoCreateManyMinibancoInputEnvelope = {
    data: Array<GrupoNotificacaoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type GrupoNotificacaoCreateManyResponsavelInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
    plataforma: TipoServico;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type GrupoNotificacaoCreateManyResponsavelInputEnvelope = {
    data: Array<GrupoNotificacaoCreateManyResponsavelInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type GrupoNotificacaoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<GrupoNotificacaoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<GrupoNotificacaoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<GrupoNotificacaoCreateManyMinibancoInputEnvelope>;
};

export type GrupoNotificacaoCreateNestedManyWithoutResponsavelInput = {
    connect?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<GrupoNotificacaoCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<GrupoNotificacaoCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<GrupoNotificacaoCreateManyResponsavelInputEnvelope>;
};

export type GrupoNotificacaoCreateNestedManyWithoutUsuariosInput = {
    connect?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<GrupoNotificacaoCreateOrConnectWithoutUsuariosInput>>;
    create?: InputMaybe<Array<GrupoNotificacaoCreateWithoutUsuariosInput>>;
};

export type GrupoNotificacaoCreateOrConnectWithoutMinibancoInput = {
    create: GrupoNotificacaoCreateWithoutMinibancoInput;
    where: GrupoNotificacaoWhereUniqueInput;
};

export type GrupoNotificacaoCreateOrConnectWithoutResponsavelInput = {
    create: GrupoNotificacaoCreateWithoutResponsavelInput;
    where: GrupoNotificacaoWhereUniqueInput;
};

export type GrupoNotificacaoCreateOrConnectWithoutUsuariosInput = {
    create: GrupoNotificacaoCreateWithoutUsuariosInput;
    where: GrupoNotificacaoWhereUniqueInput;
};

export type GrupoNotificacaoCreateWithoutMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    nome: Scalars["String"];
    plataforma: TipoServico;
    responsavel: UsuarioCreateNestedOneWithoutRespGrupoNotificacaoInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutNotificacoesInput>;
};

export type GrupoNotificacaoCreateWithoutResponsavelInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    minibanco: MinibancoCreateNestedOneWithoutGrupoNotificacaoInput;
    nome: Scalars["String"];
    plataforma: TipoServico;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutNotificacoesInput>;
};

export type GrupoNotificacaoCreateWithoutUsuariosInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    minibanco: MinibancoCreateNestedOneWithoutGrupoNotificacaoInput;
    nome: Scalars["String"];
    plataforma: TipoServico;
    responsavel: UsuarioCreateNestedOneWithoutRespGrupoNotificacaoInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type GrupoNotificacaoGroupBy = {
    __typename?: "GrupoNotificacaoGroupBy";
    _avg?: Maybe<GrupoNotificacaoAvgAggregate>;
    _count?: Maybe<GrupoNotificacaoCountAggregate>;
    _max?: Maybe<GrupoNotificacaoMaxAggregate>;
    _min?: Maybe<GrupoNotificacaoMinAggregate>;
    _sum?: Maybe<GrupoNotificacaoSumAggregate>;
    createdAt: Scalars["DateTime"];
    descricao?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
    plataforma: TipoServico;
    responsavelId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type GrupoNotificacaoListRelationFilter = {
    every?: InputMaybe<GrupoNotificacaoWhereInput>;
    none?: InputMaybe<GrupoNotificacaoWhereInput>;
    some?: InputMaybe<GrupoNotificacaoWhereInput>;
};

export type GrupoNotificacaoMaxAggregate = {
    __typename?: "GrupoNotificacaoMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    nome?: Maybe<Scalars["String"]>;
    plataforma?: Maybe<TipoServico>;
    responsavelId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type GrupoNotificacaoMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    plataforma?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type GrupoNotificacaoMinAggregate = {
    __typename?: "GrupoNotificacaoMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    nome?: Maybe<Scalars["String"]>;
    plataforma?: Maybe<TipoServico>;
    responsavelId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type GrupoNotificacaoMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    plataforma?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type GrupoNotificacaoNomeMinibancoIdCompoundUniqueInput = {
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
};

export type GrupoNotificacaoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type GrupoNotificacaoOrderByWithAggregationInput = {
    _avg?: InputMaybe<GrupoNotificacaoAvgOrderByAggregateInput>;
    _count?: InputMaybe<GrupoNotificacaoCountOrderByAggregateInput>;
    _max?: InputMaybe<GrupoNotificacaoMaxOrderByAggregateInput>;
    _min?: InputMaybe<GrupoNotificacaoMinOrderByAggregateInput>;
    _sum?: InputMaybe<GrupoNotificacaoSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    plataforma?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type GrupoNotificacaoOrderByWithRelationInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    plataforma?: InputMaybe<SortOrder>;
    responsavel?: InputMaybe<UsuarioOrderByWithRelationInput>;
    responsavelId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarios?: InputMaybe<UsuarioOrderByRelationAggregateInput>;
};

export enum GrupoNotificacaoScalarFieldEnum {
    CreatedAt = "createdAt",
    Descricao = "descricao",
    Id = "id",
    MinibancoId = "minibancoId",
    Nome = "nome",
    Plataforma = "plataforma",
    ResponsavelId = "responsavelId",
    UpdatedAt = "updatedAt",
}

export type GrupoNotificacaoScalarWhereInput = {
    AND?: InputMaybe<Array<GrupoNotificacaoScalarWhereInput>>;
    NOT?: InputMaybe<Array<GrupoNotificacaoScalarWhereInput>>;
    OR?: InputMaybe<Array<GrupoNotificacaoScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    descricao?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    nome?: InputMaybe<StringFilter>;
    plataforma?: InputMaybe<EnumTipoServicoFilter>;
    responsavelId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GrupoNotificacaoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<GrupoNotificacaoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<GrupoNotificacaoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<GrupoNotificacaoScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    descricao?: InputMaybe<StringNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    nome?: InputMaybe<StringWithAggregatesFilter>;
    plataforma?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    responsavelId?: InputMaybe<IntWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type GrupoNotificacaoSumAggregate = {
    __typename?: "GrupoNotificacaoSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    responsavelId?: Maybe<Scalars["Int"]>;
};

export type GrupoNotificacaoSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    responsavelId?: InputMaybe<SortOrder>;
};

export type GrupoNotificacaoUpdateInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutGrupoNotificacaoNestedInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    plataforma?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutRespGrupoNotificacaoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutNotificacoesNestedInput>;
};

export type GrupoNotificacaoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    plataforma?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GrupoNotificacaoUpdateManyWithWhereWithoutMinibancoInput = {
    data: GrupoNotificacaoUpdateManyMutationInput;
    where: GrupoNotificacaoScalarWhereInput;
};

export type GrupoNotificacaoUpdateManyWithWhereWithoutResponsavelInput = {
    data: GrupoNotificacaoUpdateManyMutationInput;
    where: GrupoNotificacaoScalarWhereInput;
};

export type GrupoNotificacaoUpdateManyWithWhereWithoutUsuariosInput = {
    data: GrupoNotificacaoUpdateManyMutationInput;
    where: GrupoNotificacaoScalarWhereInput;
};

export type GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<GrupoNotificacaoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<GrupoNotificacaoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<GrupoNotificacaoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<GrupoNotificacaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    set?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    update?: InputMaybe<Array<GrupoNotificacaoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<GrupoNotificacaoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<GrupoNotificacaoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput = {
    connect?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<GrupoNotificacaoCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<GrupoNotificacaoCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<GrupoNotificacaoCreateManyResponsavelInputEnvelope>;
    delete?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<GrupoNotificacaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    set?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    update?: InputMaybe<Array<GrupoNotificacaoUpdateWithWhereUniqueWithoutResponsavelInput>>;
    updateMany?: InputMaybe<Array<GrupoNotificacaoUpdateManyWithWhereWithoutResponsavelInput>>;
    upsert?: InputMaybe<Array<GrupoNotificacaoUpsertWithWhereUniqueWithoutResponsavelInput>>;
};

export type GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput = {
    connect?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<GrupoNotificacaoCreateOrConnectWithoutUsuariosInput>>;
    create?: InputMaybe<Array<GrupoNotificacaoCreateWithoutUsuariosInput>>;
    delete?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<GrupoNotificacaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    set?: InputMaybe<Array<GrupoNotificacaoWhereUniqueInput>>;
    update?: InputMaybe<Array<GrupoNotificacaoUpdateWithWhereUniqueWithoutUsuariosInput>>;
    updateMany?: InputMaybe<Array<GrupoNotificacaoUpdateManyWithWhereWithoutUsuariosInput>>;
    upsert?: InputMaybe<Array<GrupoNotificacaoUpsertWithWhereUniqueWithoutUsuariosInput>>;
};

export type GrupoNotificacaoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: GrupoNotificacaoUpdateWithoutMinibancoInput;
    where: GrupoNotificacaoWhereUniqueInput;
};

export type GrupoNotificacaoUpdateWithWhereUniqueWithoutResponsavelInput = {
    data: GrupoNotificacaoUpdateWithoutResponsavelInput;
    where: GrupoNotificacaoWhereUniqueInput;
};

export type GrupoNotificacaoUpdateWithWhereUniqueWithoutUsuariosInput = {
    data: GrupoNotificacaoUpdateWithoutUsuariosInput;
    where: GrupoNotificacaoWhereUniqueInput;
};

export type GrupoNotificacaoUpdateWithoutMinibancoInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    plataforma?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutRespGrupoNotificacaoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutNotificacoesNestedInput>;
};

export type GrupoNotificacaoUpdateWithoutResponsavelInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutGrupoNotificacaoNestedInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    plataforma?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutNotificacoesNestedInput>;
};

export type GrupoNotificacaoUpdateWithoutUsuariosInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutGrupoNotificacaoNestedInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    plataforma?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutRespGrupoNotificacaoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GrupoNotificacaoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: GrupoNotificacaoCreateWithoutMinibancoInput;
    update: GrupoNotificacaoUpdateWithoutMinibancoInput;
    where: GrupoNotificacaoWhereUniqueInput;
};

export type GrupoNotificacaoUpsertWithWhereUniqueWithoutResponsavelInput = {
    create: GrupoNotificacaoCreateWithoutResponsavelInput;
    update: GrupoNotificacaoUpdateWithoutResponsavelInput;
    where: GrupoNotificacaoWhereUniqueInput;
};

export type GrupoNotificacaoUpsertWithWhereUniqueWithoutUsuariosInput = {
    create: GrupoNotificacaoCreateWithoutUsuariosInput;
    update: GrupoNotificacaoUpdateWithoutUsuariosInput;
    where: GrupoNotificacaoWhereUniqueInput;
};

export type GrupoNotificacaoWhereInput = {
    AND?: InputMaybe<Array<GrupoNotificacaoWhereInput>>;
    NOT?: InputMaybe<Array<GrupoNotificacaoWhereInput>>;
    OR?: InputMaybe<Array<GrupoNotificacaoWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    descricao?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    nome?: InputMaybe<StringFilter>;
    plataforma?: InputMaybe<EnumTipoServicoFilter>;
    responsavel?: InputMaybe<UsuarioRelationFilter>;
    responsavelId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuarios?: InputMaybe<UsuarioListRelationFilter>;
};

export type GrupoNotificacaoWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
    nome_minibancoId?: InputMaybe<GrupoNotificacaoNomeMinibancoIdCompoundUniqueInput>;
};

export type GruposPermissao = {
    __typename?: "GruposPermissao";
    _count?: Maybe<GruposPermissaoCount>;
    createdAt: Scalars["DateTime"];
    descricao?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
    permissoesGrupo: Array<PermissoesGrupos>;
    plataforma?: Maybe<TipoServico>;
    respId: Scalars["Int"];
    responsavel: Usuario;
    updatedAt: Scalars["DateTime"];
    usuarios: Array<Usuario>;
};

export type GruposPermissaoPermissoesGrupoArgs = {
    cursor?: InputMaybe<PermissoesGruposWhereUniqueInput>;
    distinct?: InputMaybe<Array<PermissoesGruposScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<PermissoesGruposOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PermissoesGruposWhereInput>;
};

export type GruposPermissaoUsuariosArgs = {
    cursor?: InputMaybe<UsuarioWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioWhereInput>;
};

export type GruposPermissaoAvgAggregate = {
    __typename?: "GruposPermissaoAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    respId?: Maybe<Scalars["Float"]>;
};

export type GruposPermissaoAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    respId?: InputMaybe<SortOrder>;
};

export type GruposPermissaoCount = {
    __typename?: "GruposPermissaoCount";
    permissoesGrupo: Scalars["Int"];
    usuarios: Scalars["Int"];
};

export type GruposPermissaoCountAggregate = {
    __typename?: "GruposPermissaoCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    descricao: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    minibancoId: Scalars["Int"];
    nome: Scalars["Int"];
    plataforma: Scalars["Int"];
    respId: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type GruposPermissaoCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    plataforma?: InputMaybe<SortOrder>;
    respId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type GruposPermissaoCreateInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutGruposPermissaoInput;
    nome: Scalars["String"];
    permissoesGrupo?: InputMaybe<PermissoesGruposCreateNestedManyWithoutGrupoInput>;
    plataforma?: InputMaybe<TipoServico>;
    responsavel: UsuarioCreateNestedOneWithoutRespGrupoPermissaoInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutPermissoesInput>;
};

export type GruposPermissaoCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
    plataforma?: InputMaybe<TipoServico>;
    respId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type GruposPermissaoCreateManyMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nome: Scalars["String"];
    plataforma?: InputMaybe<TipoServico>;
    respId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type GruposPermissaoCreateManyMinibancoInputEnvelope = {
    data: Array<GruposPermissaoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type GruposPermissaoCreateManyResponsavelInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
    plataforma?: InputMaybe<TipoServico>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type GruposPermissaoCreateManyResponsavelInputEnvelope = {
    data: Array<GruposPermissaoCreateManyResponsavelInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type GruposPermissaoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<GruposPermissaoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<GruposPermissaoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<GruposPermissaoCreateManyMinibancoInputEnvelope>;
};

export type GruposPermissaoCreateNestedManyWithoutResponsavelInput = {
    connect?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<GruposPermissaoCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<GruposPermissaoCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<GruposPermissaoCreateManyResponsavelInputEnvelope>;
};

export type GruposPermissaoCreateNestedManyWithoutUsuariosInput = {
    connect?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<GruposPermissaoCreateOrConnectWithoutUsuariosInput>>;
    create?: InputMaybe<Array<GruposPermissaoCreateWithoutUsuariosInput>>;
};

export type GruposPermissaoCreateNestedOneWithoutPermissoesGrupoInput = {
    connect?: InputMaybe<GruposPermissaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<GruposPermissaoCreateOrConnectWithoutPermissoesGrupoInput>;
    create?: InputMaybe<GruposPermissaoCreateWithoutPermissoesGrupoInput>;
};

export type GruposPermissaoCreateOrConnectWithoutMinibancoInput = {
    create: GruposPermissaoCreateWithoutMinibancoInput;
    where: GruposPermissaoWhereUniqueInput;
};

export type GruposPermissaoCreateOrConnectWithoutPermissoesGrupoInput = {
    create: GruposPermissaoCreateWithoutPermissoesGrupoInput;
    where: GruposPermissaoWhereUniqueInput;
};

export type GruposPermissaoCreateOrConnectWithoutResponsavelInput = {
    create: GruposPermissaoCreateWithoutResponsavelInput;
    where: GruposPermissaoWhereUniqueInput;
};

export type GruposPermissaoCreateOrConnectWithoutUsuariosInput = {
    create: GruposPermissaoCreateWithoutUsuariosInput;
    where: GruposPermissaoWhereUniqueInput;
};

export type GruposPermissaoCreateWithoutMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nome: Scalars["String"];
    permissoesGrupo?: InputMaybe<PermissoesGruposCreateNestedManyWithoutGrupoInput>;
    plataforma?: InputMaybe<TipoServico>;
    responsavel: UsuarioCreateNestedOneWithoutRespGrupoPermissaoInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutPermissoesInput>;
};

export type GruposPermissaoCreateWithoutPermissoesGrupoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutGruposPermissaoInput;
    nome: Scalars["String"];
    plataforma?: InputMaybe<TipoServico>;
    responsavel: UsuarioCreateNestedOneWithoutRespGrupoPermissaoInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutPermissoesInput>;
};

export type GruposPermissaoCreateWithoutResponsavelInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutGruposPermissaoInput;
    nome: Scalars["String"];
    permissoesGrupo?: InputMaybe<PermissoesGruposCreateNestedManyWithoutGrupoInput>;
    plataforma?: InputMaybe<TipoServico>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutPermissoesInput>;
};

export type GruposPermissaoCreateWithoutUsuariosInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutGruposPermissaoInput;
    nome: Scalars["String"];
    permissoesGrupo?: InputMaybe<PermissoesGruposCreateNestedManyWithoutGrupoInput>;
    plataforma?: InputMaybe<TipoServico>;
    responsavel: UsuarioCreateNestedOneWithoutRespGrupoPermissaoInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type GruposPermissaoGroupBy = {
    __typename?: "GruposPermissaoGroupBy";
    _avg?: Maybe<GruposPermissaoAvgAggregate>;
    _count?: Maybe<GruposPermissaoCountAggregate>;
    _max?: Maybe<GruposPermissaoMaxAggregate>;
    _min?: Maybe<GruposPermissaoMinAggregate>;
    _sum?: Maybe<GruposPermissaoSumAggregate>;
    createdAt: Scalars["DateTime"];
    descricao?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
    plataforma?: Maybe<TipoServico>;
    respId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type GruposPermissaoListRelationFilter = {
    every?: InputMaybe<GruposPermissaoWhereInput>;
    none?: InputMaybe<GruposPermissaoWhereInput>;
    some?: InputMaybe<GruposPermissaoWhereInput>;
};

export type GruposPermissaoMaxAggregate = {
    __typename?: "GruposPermissaoMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    nome?: Maybe<Scalars["String"]>;
    plataforma?: Maybe<TipoServico>;
    respId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type GruposPermissaoMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    plataforma?: InputMaybe<SortOrder>;
    respId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type GruposPermissaoMinAggregate = {
    __typename?: "GruposPermissaoMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    nome?: Maybe<Scalars["String"]>;
    plataforma?: Maybe<TipoServico>;
    respId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type GruposPermissaoMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    plataforma?: InputMaybe<SortOrder>;
    respId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type GruposPermissaoNomeMinibancoIdCompoundUniqueInput = {
    minibancoId: Scalars["Int"];
    nome: Scalars["String"];
};

export type GruposPermissaoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type GruposPermissaoOrderByWithAggregationInput = {
    _avg?: InputMaybe<GruposPermissaoAvgOrderByAggregateInput>;
    _count?: InputMaybe<GruposPermissaoCountOrderByAggregateInput>;
    _max?: InputMaybe<GruposPermissaoMaxOrderByAggregateInput>;
    _min?: InputMaybe<GruposPermissaoMinOrderByAggregateInput>;
    _sum?: InputMaybe<GruposPermissaoSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    plataforma?: InputMaybe<SortOrder>;
    respId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type GruposPermissaoOrderByWithRelationInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    permissoesGrupo?: InputMaybe<PermissoesGruposOrderByRelationAggregateInput>;
    plataforma?: InputMaybe<SortOrder>;
    respId?: InputMaybe<SortOrder>;
    responsavel?: InputMaybe<UsuarioOrderByWithRelationInput>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarios?: InputMaybe<UsuarioOrderByRelationAggregateInput>;
};

export type GruposPermissaoRelationFilter = {
    is?: InputMaybe<GruposPermissaoWhereInput>;
    isNot?: InputMaybe<GruposPermissaoWhereInput>;
};

export enum GruposPermissaoScalarFieldEnum {
    CreatedAt = "createdAt",
    Descricao = "descricao",
    Id = "id",
    Inativo = "inativo",
    MinibancoId = "minibancoId",
    Nome = "nome",
    Plataforma = "plataforma",
    RespId = "respId",
    UpdatedAt = "updatedAt",
}

export type GruposPermissaoScalarWhereInput = {
    AND?: InputMaybe<Array<GruposPermissaoScalarWhereInput>>;
    NOT?: InputMaybe<Array<GruposPermissaoScalarWhereInput>>;
    OR?: InputMaybe<Array<GruposPermissaoScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    descricao?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    nome?: InputMaybe<StringFilter>;
    plataforma?: InputMaybe<EnumTipoServicoNullableFilter>;
    respId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GruposPermissaoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<GruposPermissaoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<GruposPermissaoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<GruposPermissaoScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    descricao?: InputMaybe<StringNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    nome?: InputMaybe<StringWithAggregatesFilter>;
    plataforma?: InputMaybe<EnumTipoServicoNullableWithAggregatesFilter>;
    respId?: InputMaybe<IntWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type GruposPermissaoSumAggregate = {
    __typename?: "GruposPermissaoSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    respId?: Maybe<Scalars["Int"]>;
};

export type GruposPermissaoSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    respId?: InputMaybe<SortOrder>;
};

export type GruposPermissaoUpdateInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutGruposPermissaoNestedInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    permissoesGrupo?: InputMaybe<PermissoesGruposUpdateManyWithoutGrupoNestedInput>;
    plataforma?: InputMaybe<NullableEnumTipoServicoFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutRespGrupoPermissaoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutPermissoesNestedInput>;
};

export type GruposPermissaoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    plataforma?: InputMaybe<NullableEnumTipoServicoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GruposPermissaoUpdateManyWithWhereWithoutMinibancoInput = {
    data: GruposPermissaoUpdateManyMutationInput;
    where: GruposPermissaoScalarWhereInput;
};

export type GruposPermissaoUpdateManyWithWhereWithoutResponsavelInput = {
    data: GruposPermissaoUpdateManyMutationInput;
    where: GruposPermissaoScalarWhereInput;
};

export type GruposPermissaoUpdateManyWithWhereWithoutUsuariosInput = {
    data: GruposPermissaoUpdateManyMutationInput;
    where: GruposPermissaoScalarWhereInput;
};

export type GruposPermissaoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<GruposPermissaoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<GruposPermissaoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<GruposPermissaoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<GruposPermissaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    set?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    update?: InputMaybe<Array<GruposPermissaoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<GruposPermissaoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<GruposPermissaoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type GruposPermissaoUpdateManyWithoutResponsavelNestedInput = {
    connect?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<GruposPermissaoCreateOrConnectWithoutResponsavelInput>>;
    create?: InputMaybe<Array<GruposPermissaoCreateWithoutResponsavelInput>>;
    createMany?: InputMaybe<GruposPermissaoCreateManyResponsavelInputEnvelope>;
    delete?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<GruposPermissaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    set?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    update?: InputMaybe<Array<GruposPermissaoUpdateWithWhereUniqueWithoutResponsavelInput>>;
    updateMany?: InputMaybe<Array<GruposPermissaoUpdateManyWithWhereWithoutResponsavelInput>>;
    upsert?: InputMaybe<Array<GruposPermissaoUpsertWithWhereUniqueWithoutResponsavelInput>>;
};

export type GruposPermissaoUpdateManyWithoutUsuariosNestedInput = {
    connect?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<GruposPermissaoCreateOrConnectWithoutUsuariosInput>>;
    create?: InputMaybe<Array<GruposPermissaoCreateWithoutUsuariosInput>>;
    delete?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<GruposPermissaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    set?: InputMaybe<Array<GruposPermissaoWhereUniqueInput>>;
    update?: InputMaybe<Array<GruposPermissaoUpdateWithWhereUniqueWithoutUsuariosInput>>;
    updateMany?: InputMaybe<Array<GruposPermissaoUpdateManyWithWhereWithoutUsuariosInput>>;
    upsert?: InputMaybe<Array<GruposPermissaoUpsertWithWhereUniqueWithoutUsuariosInput>>;
};

export type GruposPermissaoUpdateOneRequiredWithoutPermissoesGrupoNestedInput = {
    connect?: InputMaybe<GruposPermissaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<GruposPermissaoCreateOrConnectWithoutPermissoesGrupoInput>;
    create?: InputMaybe<GruposPermissaoCreateWithoutPermissoesGrupoInput>;
    update?: InputMaybe<GruposPermissaoUpdateWithoutPermissoesGrupoInput>;
    upsert?: InputMaybe<GruposPermissaoUpsertWithoutPermissoesGrupoInput>;
};

export type GruposPermissaoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: GruposPermissaoUpdateWithoutMinibancoInput;
    where: GruposPermissaoWhereUniqueInput;
};

export type GruposPermissaoUpdateWithWhereUniqueWithoutResponsavelInput = {
    data: GruposPermissaoUpdateWithoutResponsavelInput;
    where: GruposPermissaoWhereUniqueInput;
};

export type GruposPermissaoUpdateWithWhereUniqueWithoutUsuariosInput = {
    data: GruposPermissaoUpdateWithoutUsuariosInput;
    where: GruposPermissaoWhereUniqueInput;
};

export type GruposPermissaoUpdateWithoutMinibancoInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    permissoesGrupo?: InputMaybe<PermissoesGruposUpdateManyWithoutGrupoNestedInput>;
    plataforma?: InputMaybe<NullableEnumTipoServicoFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutRespGrupoPermissaoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutPermissoesNestedInput>;
};

export type GruposPermissaoUpdateWithoutPermissoesGrupoInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutGruposPermissaoNestedInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    plataforma?: InputMaybe<NullableEnumTipoServicoFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutRespGrupoPermissaoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutPermissoesNestedInput>;
};

export type GruposPermissaoUpdateWithoutResponsavelInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutGruposPermissaoNestedInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    permissoesGrupo?: InputMaybe<PermissoesGruposUpdateManyWithoutGrupoNestedInput>;
    plataforma?: InputMaybe<NullableEnumTipoServicoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutPermissoesNestedInput>;
};

export type GruposPermissaoUpdateWithoutUsuariosInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutGruposPermissaoNestedInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    permissoesGrupo?: InputMaybe<PermissoesGruposUpdateManyWithoutGrupoNestedInput>;
    plataforma?: InputMaybe<NullableEnumTipoServicoFieldUpdateOperationsInput>;
    responsavel?: InputMaybe<UsuarioUpdateOneRequiredWithoutRespGrupoPermissaoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GruposPermissaoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: GruposPermissaoCreateWithoutMinibancoInput;
    update: GruposPermissaoUpdateWithoutMinibancoInput;
    where: GruposPermissaoWhereUniqueInput;
};

export type GruposPermissaoUpsertWithWhereUniqueWithoutResponsavelInput = {
    create: GruposPermissaoCreateWithoutResponsavelInput;
    update: GruposPermissaoUpdateWithoutResponsavelInput;
    where: GruposPermissaoWhereUniqueInput;
};

export type GruposPermissaoUpsertWithWhereUniqueWithoutUsuariosInput = {
    create: GruposPermissaoCreateWithoutUsuariosInput;
    update: GruposPermissaoUpdateWithoutUsuariosInput;
    where: GruposPermissaoWhereUniqueInput;
};

export type GruposPermissaoUpsertWithoutPermissoesGrupoInput = {
    create: GruposPermissaoCreateWithoutPermissoesGrupoInput;
    update: GruposPermissaoUpdateWithoutPermissoesGrupoInput;
};

export type GruposPermissaoWhereInput = {
    AND?: InputMaybe<Array<GruposPermissaoWhereInput>>;
    NOT?: InputMaybe<Array<GruposPermissaoWhereInput>>;
    OR?: InputMaybe<Array<GruposPermissaoWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    descricao?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    nome?: InputMaybe<StringFilter>;
    permissoesGrupo?: InputMaybe<PermissoesGruposListRelationFilter>;
    plataforma?: InputMaybe<EnumTipoServicoNullableFilter>;
    respId?: InputMaybe<IntFilter>;
    responsavel?: InputMaybe<UsuarioRelationFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuarios?: InputMaybe<UsuarioListRelationFilter>;
};

export type GruposPermissaoWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
    nome_minibancoId?: InputMaybe<GruposPermissaoNomeMinibancoIdCompoundUniqueInput>;
};

export type IFindOneOperationDto = {
    __typename?: "IFindOneOperationDto";
    cedente?: Maybe<Assignor>;
    cedenteId?: Maybe<Scalars["Float"]>;
    deduction: Scalars["Float"];
    expenses: Scalars["Float"];
    modalidade?: Maybe<Modality>;
    modalidadeId?: Maybe<Scalars["Float"]>;
    netProfit: Scalars["Float"];
    operacao?: Maybe<Operation>;
    operacaoId?: Maybe<Scalars["Float"]>;
    riscoZero: Scalars["Boolean"];
    taxa: Scalars["Float"];
    valor: Scalars["Float"];
};

export type IntFieldUpdateOperationsInput = {
    decrement?: InputMaybe<Scalars["Int"]>;
    divide?: InputMaybe<Scalars["Int"]>;
    increment?: InputMaybe<Scalars["Int"]>;
    multiply?: InputMaybe<Scalars["Int"]>;
    set?: InputMaybe<Scalars["Int"]>;
};

export type IntFilter = {
    equals?: InputMaybe<Scalars["Int"]>;
    gt?: InputMaybe<Scalars["Int"]>;
    gte?: InputMaybe<Scalars["Int"]>;
    in?: InputMaybe<Array<Scalars["Int"]>>;
    lt?: InputMaybe<Scalars["Int"]>;
    lte?: InputMaybe<Scalars["Int"]>;
    not?: InputMaybe<NestedIntFilter>;
    notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type IntNullableFilter = {
    equals?: InputMaybe<Scalars["Int"]>;
    gt?: InputMaybe<Scalars["Int"]>;
    gte?: InputMaybe<Scalars["Int"]>;
    in?: InputMaybe<Array<Scalars["Int"]>>;
    lt?: InputMaybe<Scalars["Int"]>;
    lte?: InputMaybe<Scalars["Int"]>;
    not?: InputMaybe<NestedIntNullableFilter>;
    notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type IntNullableWithAggregatesFilter = {
    _avg?: InputMaybe<NestedFloatNullableFilter>;
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedIntNullableFilter>;
    _min?: InputMaybe<NestedIntNullableFilter>;
    _sum?: InputMaybe<NestedIntNullableFilter>;
    equals?: InputMaybe<Scalars["Int"]>;
    gt?: InputMaybe<Scalars["Int"]>;
    gte?: InputMaybe<Scalars["Int"]>;
    in?: InputMaybe<Array<Scalars["Int"]>>;
    lt?: InputMaybe<Scalars["Int"]>;
    lte?: InputMaybe<Scalars["Int"]>;
    not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type IntWithAggregatesFilter = {
    _avg?: InputMaybe<NestedFloatFilter>;
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedIntFilter>;
    _min?: InputMaybe<NestedIntFilter>;
    _sum?: InputMaybe<NestedIntFilter>;
    equals?: InputMaybe<Scalars["Int"]>;
    gt?: InputMaybe<Scalars["Int"]>;
    gte?: InputMaybe<Scalars["Int"]>;
    in?: InputMaybe<Array<Scalars["Int"]>>;
    lt?: InputMaybe<Scalars["Int"]>;
    lte?: InputMaybe<Scalars["Int"]>;
    not?: InputMaybe<NestedIntWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type JsonFilter = {
    array_contains?: InputMaybe<Scalars["JSON"]>;
    array_ends_with?: InputMaybe<Scalars["JSON"]>;
    array_starts_with?: InputMaybe<Scalars["JSON"]>;
    equals?: InputMaybe<Scalars["JSON"]>;
    gt?: InputMaybe<Scalars["JSON"]>;
    gte?: InputMaybe<Scalars["JSON"]>;
    lt?: InputMaybe<Scalars["JSON"]>;
    lte?: InputMaybe<Scalars["JSON"]>;
    not?: InputMaybe<Scalars["JSON"]>;
    path?: InputMaybe<Array<Scalars["String"]>>;
    string_contains?: InputMaybe<Scalars["String"]>;
    string_ends_with?: InputMaybe<Scalars["String"]>;
    string_starts_with?: InputMaybe<Scalars["String"]>;
};

export type JsonNullableFilter = {
    array_contains?: InputMaybe<Scalars["JSON"]>;
    array_ends_with?: InputMaybe<Scalars["JSON"]>;
    array_starts_with?: InputMaybe<Scalars["JSON"]>;
    equals?: InputMaybe<Scalars["JSON"]>;
    gt?: InputMaybe<Scalars["JSON"]>;
    gte?: InputMaybe<Scalars["JSON"]>;
    lt?: InputMaybe<Scalars["JSON"]>;
    lte?: InputMaybe<Scalars["JSON"]>;
    not?: InputMaybe<Scalars["JSON"]>;
    path?: InputMaybe<Array<Scalars["String"]>>;
    string_contains?: InputMaybe<Scalars["String"]>;
    string_ends_with?: InputMaybe<Scalars["String"]>;
    string_starts_with?: InputMaybe<Scalars["String"]>;
};

export type JsonNullableWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedJsonNullableFilter>;
    _min?: InputMaybe<NestedJsonNullableFilter>;
    array_contains?: InputMaybe<Scalars["JSON"]>;
    array_ends_with?: InputMaybe<Scalars["JSON"]>;
    array_starts_with?: InputMaybe<Scalars["JSON"]>;
    equals?: InputMaybe<Scalars["JSON"]>;
    gt?: InputMaybe<Scalars["JSON"]>;
    gte?: InputMaybe<Scalars["JSON"]>;
    lt?: InputMaybe<Scalars["JSON"]>;
    lte?: InputMaybe<Scalars["JSON"]>;
    not?: InputMaybe<Scalars["JSON"]>;
    path?: InputMaybe<Array<Scalars["String"]>>;
    string_contains?: InputMaybe<Scalars["String"]>;
    string_ends_with?: InputMaybe<Scalars["String"]>;
    string_starts_with?: InputMaybe<Scalars["String"]>;
};

export type JsonWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedJsonFilter>;
    _min?: InputMaybe<NestedJsonFilter>;
    array_contains?: InputMaybe<Scalars["JSON"]>;
    array_ends_with?: InputMaybe<Scalars["JSON"]>;
    array_starts_with?: InputMaybe<Scalars["JSON"]>;
    equals?: InputMaybe<Scalars["JSON"]>;
    gt?: InputMaybe<Scalars["JSON"]>;
    gte?: InputMaybe<Scalars["JSON"]>;
    lt?: InputMaybe<Scalars["JSON"]>;
    lte?: InputMaybe<Scalars["JSON"]>;
    not?: InputMaybe<Scalars["JSON"]>;
    path?: InputMaybe<Array<Scalars["String"]>>;
    string_contains?: InputMaybe<Scalars["String"]>;
    string_ends_with?: InputMaybe<Scalars["String"]>;
    string_starts_with?: InputMaybe<Scalars["String"]>;
};

export type Minibanco = {
    __typename?: "Minibanco";
    AssignorStatusByMinibank: Array<AssignorStatusByMinibank>;
    GrupoNotificacao: Array<GrupoNotificacao>;
    GruposPermissao: Array<GruposPermissao>;
    OperacaoIntegracao: Array<OperacaoIntegracao>;
    OperationsInfo: Array<OperationsInfo>;
    RecebiveisInfo: Array<RecebiveisInfo>;
    RecebiveisToAnticipate: Array<RecebiveisToAnticipate>;
    RecebivelIntegracao: Array<RecebivelIntegracao>;
    _count?: Maybe<MinibancoCount>;
    alteracaoCedentes: Array<CedenteAlteracao>;
    alteracaoCedentesEndereco: Array<EnderecoAlteracao>;
    arquivos: Array<Arquivo>;
    cadastroCedentes: Array<CedenteCadastro>;
    cadastroEndereco: Array<EnderecoCadastro>;
    cedentes: Array<Cedente>;
    contatos: Array<MinibancoContato>;
    createdAt: Scalars["DateTime"];
    debenturistas: Array<Debenturista>;
    document: Scalars["String"];
    empresas: Array<Empresa>;
    historico: Array<MinibancoAudit>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    keyAccount?: Maybe<Usuario>;
    keyAccountId?: Maybe<Scalars["Int"]>;
    movimentacoes: Array<MinibancoExtrato>;
    nomeFantasia?: Maybe<Scalars["String"]>;
    operacoes: Array<Operacao>;
    operacoesOrigem: Array<Operacao>;
    parceiroComercial?: Maybe<ParceiroComercial>;
    parceiroId?: Maybe<Scalars["Int"]>;
    partnerCommission: Array<ComercialPartnerCommission>;
    pessoaFisica: Scalars["Boolean"];
    razaoSocial: Scalars["String"];
    recebiveis: Array<Recebivel>;
    sacados: Array<Sacado>;
    statusOperacionalCedente: Array<StatusOperacionalCedenteMinibanco>;
    takeRateMinibanco: Array<TakeRateMinibanco>;
    taxasMinibanco: Array<TaxasMinibanco>;
    tipo: TipoMinibanco;
    updatedAt: Scalars["DateTime"];
    usuarios: Array<Usuario>;
};

export type MinibancoAssignorStatusByMinibankArgs = {
    cursor?: InputMaybe<AssignorStatusByMinibankWhereUniqueInput>;
    distinct?: InputMaybe<Array<AssignorStatusByMinibankScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AssignorStatusByMinibankOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorStatusByMinibankWhereInput>;
};

export type MinibancoGrupoNotificacaoArgs = {
    cursor?: InputMaybe<GrupoNotificacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<GrupoNotificacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<GrupoNotificacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GrupoNotificacaoWhereInput>;
};

export type MinibancoGruposPermissaoArgs = {
    cursor?: InputMaybe<GruposPermissaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<GruposPermissaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<GruposPermissaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GruposPermissaoWhereInput>;
};

export type MinibancoOperacaoIntegracaoArgs = {
    cursor?: InputMaybe<OperacaoIntegracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoIntegracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoIntegracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoIntegracaoWhereInput>;
};

export type MinibancoOperationsInfoArgs = {
    cursor?: InputMaybe<OperationsInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationsInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationsInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationsInfoWhereInput>;
};

export type MinibancoRecebiveisInfoArgs = {
    cursor?: InputMaybe<RecebiveisInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebiveisInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebiveisInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisInfoWhereInput>;
};

export type MinibancoRecebiveisToAnticipateArgs = {
    cursor?: InputMaybe<RecebiveisToAnticipateWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebiveisToAnticipateScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebiveisToAnticipateOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisToAnticipateWhereInput>;
};

export type MinibancoRecebivelIntegracaoArgs = {
    cursor?: InputMaybe<RecebivelIntegracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelIntegracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelIntegracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelIntegracaoWhereInput>;
};

export type MinibancoAlteracaoCedentesArgs = {
    cursor?: InputMaybe<CedenteAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAlteracaoWhereInput>;
};

export type MinibancoAlteracaoCedentesEnderecoArgs = {
    cursor?: InputMaybe<EnderecoAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoAlteracaoWhereInput>;
};

export type MinibancoArquivosArgs = {
    cursor?: InputMaybe<ArquivoWhereUniqueInput>;
    distinct?: InputMaybe<Array<ArquivoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ArquivoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoWhereInput>;
};

export type MinibancoCadastroCedentesArgs = {
    cursor?: InputMaybe<CedenteCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCadastroWhereInput>;
};

export type MinibancoCadastroEnderecoArgs = {
    cursor?: InputMaybe<EnderecoCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCadastroWhereInput>;
};

export type MinibancoCedentesArgs = {
    cursor?: InputMaybe<CedenteWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteWhereInput>;
};

export type MinibancoContatosArgs = {
    cursor?: InputMaybe<MinibancoContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoContatoWhereInput>;
};

export type MinibancoDebenturistasArgs = {
    cursor?: InputMaybe<DebenturistaWhereUniqueInput>;
    distinct?: InputMaybe<Array<DebenturistaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<DebenturistaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<DebenturistaWhereInput>;
};

export type MinibancoEmpresasArgs = {
    cursor?: InputMaybe<EmpresaWhereUniqueInput>;
    distinct?: InputMaybe<Array<EmpresaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EmpresaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EmpresaWhereInput>;
};

export type MinibancoHistoricoArgs = {
    cursor?: InputMaybe<MinibancoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoAuditWhereInput>;
};

export type MinibancoMovimentacoesArgs = {
    cursor?: InputMaybe<MinibancoExtratoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoExtratoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoExtratoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoExtratoWhereInput>;
};

export type MinibancoOperacoesArgs = {
    cursor?: InputMaybe<OperacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoWhereInput>;
};

export type MinibancoOperacoesOrigemArgs = {
    cursor?: InputMaybe<OperacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoWhereInput>;
};

export type MinibancoPartnerCommissionArgs = {
    cursor?: InputMaybe<ComercialPartnerCommissionWhereUniqueInput>;
    distinct?: InputMaybe<Array<ComercialPartnerCommissionScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ComercialPartnerCommissionOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ComercialPartnerCommissionWhereInput>;
};

export type MinibancoRecebiveisArgs = {
    cursor?: InputMaybe<RecebivelWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelWhereInput>;
};

export type MinibancoSacadosArgs = {
    cursor?: InputMaybe<SacadoWhereUniqueInput>;
    distinct?: InputMaybe<Array<SacadoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<SacadoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoWhereInput>;
};

export type MinibancoStatusOperacionalCedenteArgs = {
    cursor?: InputMaybe<StatusOperacionalCedenteMinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<StatusOperacionalCedenteMinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<StatusOperacionalCedenteMinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<StatusOperacionalCedenteMinibancoWhereInput>;
};

export type MinibancoTakeRateMinibancoArgs = {
    cursor?: InputMaybe<TakeRateMinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<TakeRateMinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<TakeRateMinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TakeRateMinibancoWhereInput>;
};

export type MinibancoTaxasMinibancoArgs = {
    cursor?: InputMaybe<TaxasMinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<TaxasMinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<TaxasMinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TaxasMinibancoWhereInput>;
};

export type MinibancoUsuariosArgs = {
    cursor?: InputMaybe<UsuarioWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioWhereInput>;
};

export type MinibancoAudit = {
    __typename?: "MinibancoAudit";
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuario?: Maybe<Usuario>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type MinibancoAuditAvgAggregate = {
    __typename?: "MinibancoAuditAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type MinibancoAuditAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type MinibancoAuditCountAggregate = {
    __typename?: "MinibancoAuditCountAggregate";
    _all: Scalars["Int"];
    action: Scalars["Int"];
    createdAt: Scalars["Int"];
    field: Scalars["Int"];
    id: Scalars["Int"];
    ipUsuario: Scalars["Int"];
    minibancoId: Scalars["Int"];
    newValue: Scalars["Int"];
    oldValue: Scalars["Int"];
    service: Scalars["Int"];
    userAgent: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type MinibancoAuditCountOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type MinibancoAuditCreateInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    minibanco: MinibancoCreateNestedOneWithoutHistoricoInput;
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistMinibancoInput>;
};

export type MinibancoAuditCreateManyInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    minibancoId: Scalars["Int"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type MinibancoAuditCreateManyMinibancoInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type MinibancoAuditCreateManyMinibancoInputEnvelope = {
    data: Array<MinibancoAuditCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MinibancoAuditCreateManyUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    minibancoId: Scalars["Int"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type MinibancoAuditCreateManyUsuarioInputEnvelope = {
    data: Array<MinibancoAuditCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MinibancoAuditCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<MinibancoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoAuditCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<MinibancoAuditCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<MinibancoAuditCreateManyMinibancoInputEnvelope>;
};

export type MinibancoAuditCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<MinibancoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<MinibancoAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<MinibancoAuditCreateManyUsuarioInputEnvelope>;
};

export type MinibancoAuditCreateOrConnectWithoutMinibancoInput = {
    create: MinibancoAuditCreateWithoutMinibancoInput;
    where: MinibancoAuditWhereUniqueInput;
};

export type MinibancoAuditCreateOrConnectWithoutUsuarioInput = {
    create: MinibancoAuditCreateWithoutUsuarioInput;
    where: MinibancoAuditWhereUniqueInput;
};

export type MinibancoAuditCreateWithoutMinibancoInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistMinibancoInput>;
};

export type MinibancoAuditCreateWithoutUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    minibanco: MinibancoCreateNestedOneWithoutHistoricoInput;
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type MinibancoAuditGroupBy = {
    __typename?: "MinibancoAuditGroupBy";
    _avg?: Maybe<MinibancoAuditAvgAggregate>;
    _count?: Maybe<MinibancoAuditCountAggregate>;
    _max?: Maybe<MinibancoAuditMaxAggregate>;
    _min?: Maybe<MinibancoAuditMinAggregate>;
    _sum?: Maybe<MinibancoAuditSumAggregate>;
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    minibancoId: Scalars["Int"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type MinibancoAuditListRelationFilter = {
    every?: InputMaybe<MinibancoAuditWhereInput>;
    none?: InputMaybe<MinibancoAuditWhereInput>;
    some?: InputMaybe<MinibancoAuditWhereInput>;
};

export type MinibancoAuditMaxAggregate = {
    __typename?: "MinibancoAuditMaxAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type MinibancoAuditMaxOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type MinibancoAuditMinAggregate = {
    __typename?: "MinibancoAuditMinAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type MinibancoAuditMinOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type MinibancoAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type MinibancoAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<MinibancoAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<MinibancoAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<MinibancoAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<MinibancoAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<MinibancoAuditSumOrderByAggregateInput>;
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type MinibancoAuditOrderByWithRelationInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum MinibancoAuditScalarFieldEnum {
    Action = "action",
    CreatedAt = "createdAt",
    Field = "field",
    Id = "id",
    IpUsuario = "ipUsuario",
    MinibancoId = "minibancoId",
    NewValue = "newValue",
    OldValue = "oldValue",
    Service = "service",
    UserAgent = "userAgent",
    UsuarioId = "usuarioId",
}

export type MinibancoAuditScalarWhereInput = {
    AND?: InputMaybe<Array<MinibancoAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<MinibancoAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<MinibancoAuditScalarWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type MinibancoAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<MinibancoAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<MinibancoAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<MinibancoAuditScalarWhereWithAggregatesInput>>;
    action?: InputMaybe<EnumAuditAcoesWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    field?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ipUsuario?: InputMaybe<StringWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    newValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type MinibancoAuditSumAggregate = {
    __typename?: "MinibancoAuditSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type MinibancoAuditSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type MinibancoAuditUpdateInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutHistoricoNestedInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistMinibancoNestedInput>;
};

export type MinibancoAuditUpdateManyMutationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MinibancoAuditUpdateManyWithWhereWithoutMinibancoInput = {
    data: MinibancoAuditUpdateManyMutationInput;
    where: MinibancoAuditScalarWhereInput;
};

export type MinibancoAuditUpdateManyWithWhereWithoutUsuarioInput = {
    data: MinibancoAuditUpdateManyMutationInput;
    where: MinibancoAuditScalarWhereInput;
};

export type MinibancoAuditUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<MinibancoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoAuditCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<MinibancoAuditCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<MinibancoAuditCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<MinibancoAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<MinibancoAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<MinibancoAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<MinibancoAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<MinibancoAuditUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<MinibancoAuditUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<MinibancoAuditUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type MinibancoAuditUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<MinibancoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<MinibancoAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<MinibancoAuditCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<MinibancoAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<MinibancoAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<MinibancoAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<MinibancoAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<MinibancoAuditUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<MinibancoAuditUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<MinibancoAuditUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type MinibancoAuditUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: MinibancoAuditUpdateWithoutMinibancoInput;
    where: MinibancoAuditWhereUniqueInput;
};

export type MinibancoAuditUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: MinibancoAuditUpdateWithoutUsuarioInput;
    where: MinibancoAuditWhereUniqueInput;
};

export type MinibancoAuditUpdateWithoutMinibancoInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistMinibancoNestedInput>;
};

export type MinibancoAuditUpdateWithoutUsuarioInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutHistoricoNestedInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MinibancoAuditUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: MinibancoAuditCreateWithoutMinibancoInput;
    update: MinibancoAuditUpdateWithoutMinibancoInput;
    where: MinibancoAuditWhereUniqueInput;
};

export type MinibancoAuditUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: MinibancoAuditCreateWithoutUsuarioInput;
    update: MinibancoAuditUpdateWithoutUsuarioInput;
    where: MinibancoAuditWhereUniqueInput;
};

export type MinibancoAuditWhereInput = {
    AND?: InputMaybe<Array<MinibancoAuditWhereInput>>;
    NOT?: InputMaybe<Array<MinibancoAuditWhereInput>>;
    OR?: InputMaybe<Array<MinibancoAuditWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type MinibancoAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type MinibancoAvgAggregate = {
    __typename?: "MinibancoAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    keyAccountId?: Maybe<Scalars["Float"]>;
    parceiroId?: Maybe<Scalars["Float"]>;
};

export type MinibancoAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    parceiroId?: InputMaybe<SortOrder>;
};

export type MinibancoContato = {
    __typename?: "MinibancoContato";
    contato: Contato;
    contatoId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    inativo: Scalars["Boolean"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    principal: Scalars["Boolean"];
    updatedAt: Scalars["DateTime"];
};

export type MinibancoContatoAvgAggregate = {
    __typename?: "MinibancoContatoAvgAggregate";
    contatoId?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
};

export type MinibancoContatoAvgOrderByAggregateInput = {
    contatoId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
};

export type MinibancoContatoCountAggregate = {
    __typename?: "MinibancoContatoCountAggregate";
    _all: Scalars["Int"];
    contatoId: Scalars["Int"];
    createdAt: Scalars["Int"];
    inativo: Scalars["Int"];
    minibancoId: Scalars["Int"];
    principal: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type MinibancoContatoCountOrderByAggregateInput = {
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type MinibancoContatoCreateInput = {
    contato: ContatoCreateNestedOneWithoutMinibancosInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutContatosInput;
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MinibancoContatoCreateManyContatoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MinibancoContatoCreateManyContatoInputEnvelope = {
    data: Array<MinibancoContatoCreateManyContatoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MinibancoContatoCreateManyInput = {
    contatoId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MinibancoContatoCreateManyMinibancoInput = {
    contatoId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MinibancoContatoCreateManyMinibancoInputEnvelope = {
    data: Array<MinibancoContatoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MinibancoContatoCreateNestedManyWithoutContatoInput = {
    connect?: InputMaybe<Array<MinibancoContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoContatoCreateOrConnectWithoutContatoInput>>;
    create?: InputMaybe<Array<MinibancoContatoCreateWithoutContatoInput>>;
    createMany?: InputMaybe<MinibancoContatoCreateManyContatoInputEnvelope>;
};

export type MinibancoContatoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<MinibancoContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoContatoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<MinibancoContatoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<MinibancoContatoCreateManyMinibancoInputEnvelope>;
};

export type MinibancoContatoCreateOrConnectWithoutContatoInput = {
    create: MinibancoContatoCreateWithoutContatoInput;
    where: MinibancoContatoWhereUniqueInput;
};

export type MinibancoContatoCreateOrConnectWithoutMinibancoInput = {
    create: MinibancoContatoCreateWithoutMinibancoInput;
    where: MinibancoContatoWhereUniqueInput;
};

export type MinibancoContatoCreateWithoutContatoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutContatosInput;
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MinibancoContatoCreateWithoutMinibancoInput = {
    contato: ContatoCreateNestedOneWithoutMinibancosInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MinibancoContatoGroupBy = {
    __typename?: "MinibancoContatoGroupBy";
    _avg?: Maybe<MinibancoContatoAvgAggregate>;
    _count?: Maybe<MinibancoContatoCountAggregate>;
    _max?: Maybe<MinibancoContatoMaxAggregate>;
    _min?: Maybe<MinibancoContatoMinAggregate>;
    _sum?: Maybe<MinibancoContatoSumAggregate>;
    contatoId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    inativo: Scalars["Boolean"];
    minibancoId: Scalars["Int"];
    principal: Scalars["Boolean"];
    updatedAt: Scalars["DateTime"];
};

export type MinibancoContatoListRelationFilter = {
    every?: InputMaybe<MinibancoContatoWhereInput>;
    none?: InputMaybe<MinibancoContatoWhereInput>;
    some?: InputMaybe<MinibancoContatoWhereInput>;
};

export type MinibancoContatoMaxAggregate = {
    __typename?: "MinibancoContatoMaxAggregate";
    contatoId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    principal?: Maybe<Scalars["Boolean"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MinibancoContatoMaxOrderByAggregateInput = {
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type MinibancoContatoMinAggregate = {
    __typename?: "MinibancoContatoMinAggregate";
    contatoId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    principal?: Maybe<Scalars["Boolean"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MinibancoContatoMinOrderByAggregateInput = {
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type MinibancoContatoMinibancoIdContatoIdCompoundUniqueInput = {
    contatoId: Scalars["Int"];
    minibancoId: Scalars["Int"];
};

export type MinibancoContatoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type MinibancoContatoOrderByWithAggregationInput = {
    _avg?: InputMaybe<MinibancoContatoAvgOrderByAggregateInput>;
    _count?: InputMaybe<MinibancoContatoCountOrderByAggregateInput>;
    _max?: InputMaybe<MinibancoContatoMaxOrderByAggregateInput>;
    _min?: InputMaybe<MinibancoContatoMinOrderByAggregateInput>;
    _sum?: InputMaybe<MinibancoContatoSumOrderByAggregateInput>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type MinibancoContatoOrderByWithRelationInput = {
    contato?: InputMaybe<ContatoOrderByWithRelationInput>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export enum MinibancoContatoScalarFieldEnum {
    ContatoId = "contatoId",
    CreatedAt = "createdAt",
    Inativo = "inativo",
    MinibancoId = "minibancoId",
    Principal = "principal",
    UpdatedAt = "updatedAt",
}

export type MinibancoContatoScalarWhereInput = {
    AND?: InputMaybe<Array<MinibancoContatoScalarWhereInput>>;
    NOT?: InputMaybe<Array<MinibancoContatoScalarWhereInput>>;
    OR?: InputMaybe<Array<MinibancoContatoScalarWhereInput>>;
    contatoId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    principal?: InputMaybe<BoolFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MinibancoContatoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<MinibancoContatoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<MinibancoContatoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<MinibancoContatoScalarWhereWithAggregatesInput>>;
    contatoId?: InputMaybe<IntWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    principal?: InputMaybe<BoolWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type MinibancoContatoSumAggregate = {
    __typename?: "MinibancoContatoSumAggregate";
    contatoId?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
};

export type MinibancoContatoSumOrderByAggregateInput = {
    contatoId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
};

export type MinibancoContatoUpdateInput = {
    contato?: InputMaybe<ContatoUpdateOneRequiredWithoutMinibancosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutContatosNestedInput>;
    principal?: InputMaybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MinibancoContatoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    principal?: InputMaybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MinibancoContatoUpdateManyWithWhereWithoutContatoInput = {
    data: MinibancoContatoUpdateManyMutationInput;
    where: MinibancoContatoScalarWhereInput;
};

export type MinibancoContatoUpdateManyWithWhereWithoutMinibancoInput = {
    data: MinibancoContatoUpdateManyMutationInput;
    where: MinibancoContatoScalarWhereInput;
};

export type MinibancoContatoUpdateManyWithoutContatoNestedInput = {
    connect?: InputMaybe<Array<MinibancoContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoContatoCreateOrConnectWithoutContatoInput>>;
    create?: InputMaybe<Array<MinibancoContatoCreateWithoutContatoInput>>;
    createMany?: InputMaybe<MinibancoContatoCreateManyContatoInputEnvelope>;
    delete?: InputMaybe<Array<MinibancoContatoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<MinibancoContatoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<MinibancoContatoWhereUniqueInput>>;
    set?: InputMaybe<Array<MinibancoContatoWhereUniqueInput>>;
    update?: InputMaybe<Array<MinibancoContatoUpdateWithWhereUniqueWithoutContatoInput>>;
    updateMany?: InputMaybe<Array<MinibancoContatoUpdateManyWithWhereWithoutContatoInput>>;
    upsert?: InputMaybe<Array<MinibancoContatoUpsertWithWhereUniqueWithoutContatoInput>>;
};

export type MinibancoContatoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<MinibancoContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoContatoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<MinibancoContatoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<MinibancoContatoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<MinibancoContatoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<MinibancoContatoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<MinibancoContatoWhereUniqueInput>>;
    set?: InputMaybe<Array<MinibancoContatoWhereUniqueInput>>;
    update?: InputMaybe<Array<MinibancoContatoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<MinibancoContatoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<MinibancoContatoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type MinibancoContatoUpdateWithWhereUniqueWithoutContatoInput = {
    data: MinibancoContatoUpdateWithoutContatoInput;
    where: MinibancoContatoWhereUniqueInput;
};

export type MinibancoContatoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: MinibancoContatoUpdateWithoutMinibancoInput;
    where: MinibancoContatoWhereUniqueInput;
};

export type MinibancoContatoUpdateWithoutContatoInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutContatosNestedInput>;
    principal?: InputMaybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MinibancoContatoUpdateWithoutMinibancoInput = {
    contato?: InputMaybe<ContatoUpdateOneRequiredWithoutMinibancosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    principal?: InputMaybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MinibancoContatoUpsertWithWhereUniqueWithoutContatoInput = {
    create: MinibancoContatoCreateWithoutContatoInput;
    update: MinibancoContatoUpdateWithoutContatoInput;
    where: MinibancoContatoWhereUniqueInput;
};

export type MinibancoContatoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: MinibancoContatoCreateWithoutMinibancoInput;
    update: MinibancoContatoUpdateWithoutMinibancoInput;
    where: MinibancoContatoWhereUniqueInput;
};

export type MinibancoContatoWhereInput = {
    AND?: InputMaybe<Array<MinibancoContatoWhereInput>>;
    NOT?: InputMaybe<Array<MinibancoContatoWhereInput>>;
    OR?: InputMaybe<Array<MinibancoContatoWhereInput>>;
    contato?: InputMaybe<ContatoRelationFilter>;
    contatoId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    principal?: InputMaybe<BoolFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MinibancoContatoWhereUniqueInput = {
    minibancoId_contatoId?: InputMaybe<MinibancoContatoMinibancoIdContatoIdCompoundUniqueInput>;
};

export type MinibancoCount = {
    __typename?: "MinibancoCount";
    AssignorStatusByMinibank: Scalars["Int"];
    GrupoNotificacao: Scalars["Int"];
    GruposPermissao: Scalars["Int"];
    OperacaoIntegracao: Scalars["Int"];
    OperationsInfo: Scalars["Int"];
    RecebiveisInfo: Scalars["Int"];
    RecebiveisToAnticipate: Scalars["Int"];
    RecebivelIntegracao: Scalars["Int"];
    alteracaoCedentes: Scalars["Int"];
    alteracaoCedentesEndereco: Scalars["Int"];
    arquivos: Scalars["Int"];
    cadastroCedentes: Scalars["Int"];
    cadastroEndereco: Scalars["Int"];
    cedentes: Scalars["Int"];
    contatos: Scalars["Int"];
    debenturistas: Scalars["Int"];
    empresas: Scalars["Int"];
    historico: Scalars["Int"];
    movimentacoes: Scalars["Int"];
    operacoes: Scalars["Int"];
    operacoesOrigem: Scalars["Int"];
    partnerCommission: Scalars["Int"];
    recebiveis: Scalars["Int"];
    sacados: Scalars["Int"];
    statusOperacionalCedente: Scalars["Int"];
    takeRateMinibanco: Scalars["Int"];
    taxasMinibanco: Scalars["Int"];
    usuarios: Scalars["Int"];
};

export type MinibancoCountAggregate = {
    __typename?: "MinibancoCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    document: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    keyAccountId: Scalars["Int"];
    nomeFantasia: Scalars["Int"];
    parceiroId: Scalars["Int"];
    pessoaFisica: Scalars["Int"];
    razaoSocial: Scalars["Int"];
    tipo: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type MinibancoCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    parceiroId?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    tipo?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type MinibancoCreateInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccountId?: InputMaybe<Scalars["Int"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    parceiroId?: InputMaybe<Scalars["Int"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MinibancoCreateManyKeyAccountInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    parceiroId?: InputMaybe<Scalars["Int"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MinibancoCreateManyKeyAccountInputEnvelope = {
    data: Array<MinibancoCreateManyKeyAccountInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MinibancoCreateManyParceiroComercialInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccountId?: InputMaybe<Scalars["Int"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MinibancoCreateManyParceiroComercialInputEnvelope = {
    data: Array<MinibancoCreateManyParceiroComercialInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MinibancoCreateNestedManyWithoutCedentesInput = {
    connect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoCreateOrConnectWithoutCedentesInput>>;
    create?: InputMaybe<Array<MinibancoCreateWithoutCedentesInput>>;
};

export type MinibancoCreateNestedManyWithoutEmpresasInput = {
    connect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoCreateOrConnectWithoutEmpresasInput>>;
    create?: InputMaybe<Array<MinibancoCreateWithoutEmpresasInput>>;
};

export type MinibancoCreateNestedManyWithoutKeyAccountInput = {
    connect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoCreateOrConnectWithoutKeyAccountInput>>;
    create?: InputMaybe<Array<MinibancoCreateWithoutKeyAccountInput>>;
    createMany?: InputMaybe<MinibancoCreateManyKeyAccountInputEnvelope>;
};

export type MinibancoCreateNestedManyWithoutParceiroComercialInput = {
    connect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoCreateOrConnectWithoutParceiroComercialInput>>;
    create?: InputMaybe<Array<MinibancoCreateWithoutParceiroComercialInput>>;
    createMany?: InputMaybe<MinibancoCreateManyParceiroComercialInputEnvelope>;
};

export type MinibancoCreateNestedManyWithoutSacadosInput = {
    connect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoCreateOrConnectWithoutSacadosInput>>;
    create?: InputMaybe<Array<MinibancoCreateWithoutSacadosInput>>;
};

export type MinibancoCreateNestedOneWithoutAlteracaoCedentesEnderecoInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutAlteracaoCedentesEnderecoInput>;
    create?: InputMaybe<MinibancoCreateWithoutAlteracaoCedentesEnderecoInput>;
};

export type MinibancoCreateNestedOneWithoutAlteracaoCedentesInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutAlteracaoCedentesInput>;
    create?: InputMaybe<MinibancoCreateWithoutAlteracaoCedentesInput>;
};

export type MinibancoCreateNestedOneWithoutArquivosInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutArquivosInput>;
    create?: InputMaybe<MinibancoCreateWithoutArquivosInput>;
};

export type MinibancoCreateNestedOneWithoutAssignorStatusByMinibankInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutAssignorStatusByMinibankInput>;
    create?: InputMaybe<MinibancoCreateWithoutAssignorStatusByMinibankInput>;
};

export type MinibancoCreateNestedOneWithoutCadastroCedentesInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutCadastroCedentesInput>;
    create?: InputMaybe<MinibancoCreateWithoutCadastroCedentesInput>;
};

export type MinibancoCreateNestedOneWithoutCadastroEnderecoInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutCadastroEnderecoInput>;
    create?: InputMaybe<MinibancoCreateWithoutCadastroEnderecoInput>;
};

export type MinibancoCreateNestedOneWithoutContatosInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutContatosInput>;
    create?: InputMaybe<MinibancoCreateWithoutContatosInput>;
};

export type MinibancoCreateNestedOneWithoutDebenturistasInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutDebenturistasInput>;
    create?: InputMaybe<MinibancoCreateWithoutDebenturistasInput>;
};

export type MinibancoCreateNestedOneWithoutGrupoNotificacaoInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutGrupoNotificacaoInput>;
    create?: InputMaybe<MinibancoCreateWithoutGrupoNotificacaoInput>;
};

export type MinibancoCreateNestedOneWithoutGruposPermissaoInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutGruposPermissaoInput>;
    create?: InputMaybe<MinibancoCreateWithoutGruposPermissaoInput>;
};

export type MinibancoCreateNestedOneWithoutHistoricoInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<MinibancoCreateWithoutHistoricoInput>;
};

export type MinibancoCreateNestedOneWithoutMovimentacoesInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutMovimentacoesInput>;
    create?: InputMaybe<MinibancoCreateWithoutMovimentacoesInput>;
};

export type MinibancoCreateNestedOneWithoutOperacaoIntegracaoInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutOperacaoIntegracaoInput>;
    create?: InputMaybe<MinibancoCreateWithoutOperacaoIntegracaoInput>;
};

export type MinibancoCreateNestedOneWithoutOperacoesInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutOperacoesInput>;
    create?: InputMaybe<MinibancoCreateWithoutOperacoesInput>;
};

export type MinibancoCreateNestedOneWithoutOperacoesOrigemInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutOperacoesOrigemInput>;
    create?: InputMaybe<MinibancoCreateWithoutOperacoesOrigemInput>;
};

export type MinibancoCreateNestedOneWithoutOperationsInfoInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutOperationsInfoInput>;
    create?: InputMaybe<MinibancoCreateWithoutOperationsInfoInput>;
};

export type MinibancoCreateNestedOneWithoutPartnerCommissionInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutPartnerCommissionInput>;
    create?: InputMaybe<MinibancoCreateWithoutPartnerCommissionInput>;
};

export type MinibancoCreateNestedOneWithoutRecebiveisInfoInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutRecebiveisInfoInput>;
    create?: InputMaybe<MinibancoCreateWithoutRecebiveisInfoInput>;
};

export type MinibancoCreateNestedOneWithoutRecebiveisInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutRecebiveisInput>;
    create?: InputMaybe<MinibancoCreateWithoutRecebiveisInput>;
};

export type MinibancoCreateNestedOneWithoutRecebiveisToAnticipateInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutRecebiveisToAnticipateInput>;
    create?: InputMaybe<MinibancoCreateWithoutRecebiveisToAnticipateInput>;
};

export type MinibancoCreateNestedOneWithoutRecebivelIntegracaoInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutRecebivelIntegracaoInput>;
    create?: InputMaybe<MinibancoCreateWithoutRecebivelIntegracaoInput>;
};

export type MinibancoCreateNestedOneWithoutStatusOperacionalCedenteInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutStatusOperacionalCedenteInput>;
    create?: InputMaybe<MinibancoCreateWithoutStatusOperacionalCedenteInput>;
};

export type MinibancoCreateNestedOneWithoutTakeRateMinibancoInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutTakeRateMinibancoInput>;
    create?: InputMaybe<MinibancoCreateWithoutTakeRateMinibancoInput>;
};

export type MinibancoCreateNestedOneWithoutTaxasMinibancoInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutTaxasMinibancoInput>;
    create?: InputMaybe<MinibancoCreateWithoutTaxasMinibancoInput>;
};

export type MinibancoCreateNestedOneWithoutUsuariosInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutUsuariosInput>;
    create?: InputMaybe<MinibancoCreateWithoutUsuariosInput>;
};

export type MinibancoCreateOrConnectWithoutAlteracaoCedentesEnderecoInput = {
    create: MinibancoCreateWithoutAlteracaoCedentesEnderecoInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutAlteracaoCedentesInput = {
    create: MinibancoCreateWithoutAlteracaoCedentesInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutArquivosInput = {
    create: MinibancoCreateWithoutArquivosInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutAssignorStatusByMinibankInput = {
    create: MinibancoCreateWithoutAssignorStatusByMinibankInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutCadastroCedentesInput = {
    create: MinibancoCreateWithoutCadastroCedentesInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutCadastroEnderecoInput = {
    create: MinibancoCreateWithoutCadastroEnderecoInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutCedentesInput = {
    create: MinibancoCreateWithoutCedentesInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutContatosInput = {
    create: MinibancoCreateWithoutContatosInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutDebenturistasInput = {
    create: MinibancoCreateWithoutDebenturistasInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutEmpresasInput = {
    create: MinibancoCreateWithoutEmpresasInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutGrupoNotificacaoInput = {
    create: MinibancoCreateWithoutGrupoNotificacaoInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutGruposPermissaoInput = {
    create: MinibancoCreateWithoutGruposPermissaoInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutHistoricoInput = {
    create: MinibancoCreateWithoutHistoricoInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutKeyAccountInput = {
    create: MinibancoCreateWithoutKeyAccountInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutMovimentacoesInput = {
    create: MinibancoCreateWithoutMovimentacoesInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutOperacaoIntegracaoInput = {
    create: MinibancoCreateWithoutOperacaoIntegracaoInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutOperacoesInput = {
    create: MinibancoCreateWithoutOperacoesInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutOperacoesOrigemInput = {
    create: MinibancoCreateWithoutOperacoesOrigemInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutOperationsInfoInput = {
    create: MinibancoCreateWithoutOperationsInfoInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutParceiroComercialInput = {
    create: MinibancoCreateWithoutParceiroComercialInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutPartnerCommissionInput = {
    create: MinibancoCreateWithoutPartnerCommissionInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutRecebiveisInfoInput = {
    create: MinibancoCreateWithoutRecebiveisInfoInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutRecebiveisInput = {
    create: MinibancoCreateWithoutRecebiveisInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutRecebiveisToAnticipateInput = {
    create: MinibancoCreateWithoutRecebiveisToAnticipateInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutRecebivelIntegracaoInput = {
    create: MinibancoCreateWithoutRecebivelIntegracaoInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutSacadosInput = {
    create: MinibancoCreateWithoutSacadosInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutStatusOperacionalCedenteInput = {
    create: MinibancoCreateWithoutStatusOperacionalCedenteInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutTakeRateMinibancoInput = {
    create: MinibancoCreateWithoutTakeRateMinibancoInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutTaxasMinibancoInput = {
    create: MinibancoCreateWithoutTaxasMinibancoInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateOrConnectWithoutUsuariosInput = {
    create: MinibancoCreateWithoutUsuariosInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoCreateWithoutAlteracaoCedentesEnderecoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutAlteracaoCedentesInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutArquivosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutAssignorStatusByMinibankInput = {
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutCadastroCedentesInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutCadastroEnderecoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutCedentesInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutContatosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutDebenturistasInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutEmpresasInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutGrupoNotificacaoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutGruposPermissaoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutHistoricoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutKeyAccountInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutMovimentacoesInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutOperacaoIntegracaoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutOperacoesInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutOperacoesOrigemInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutOperationsInfoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutParceiroComercialInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutPartnerCommissionInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutRecebiveisInfoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutRecebiveisInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutRecebiveisToAnticipateInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutRecebivelIntegracaoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutSacadosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutStatusOperacionalCedenteInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutTakeRateMinibancoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutTaxasMinibancoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarios?: InputMaybe<UsuarioCreateNestedManyWithoutMinibancoInput>;
};

export type MinibancoCreateWithoutUsuariosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankCreateNestedManyWithoutMinibankInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutMinibancoInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutMinibancoInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutMinibancoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedManyWithoutMinibankInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutMinibancoInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutMinibancoInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutMinibancoInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutMinibancoInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutMinibancoInput>;
    cedentes?: InputMaybe<CedenteCreateNestedManyWithoutMinibancosInput>;
    contatos?: InputMaybe<MinibancoContatoCreateNestedManyWithoutMinibancoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    debenturistas?: InputMaybe<DebenturistaCreateNestedManyWithoutMinibancoInput>;
    document: Scalars["String"];
    empresas?: InputMaybe<EmpresaCreateNestedManyWithoutMinibancosInput>;
    historico?: InputMaybe<MinibancoAuditCreateNestedManyWithoutMinibancoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    keyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutMinibanksWalletInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutMinibancoInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutMinibancoInput>;
    operacoesOrigem?: InputMaybe<OperacaoCreateNestedManyWithoutOrigemOperacaoInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialCreateNestedOneWithoutMinibancosInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutMinibankInput>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutMinibancoInput>;
    sacados?: InputMaybe<SacadoCreateNestedManyWithoutMinibancosInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoCreateNestedManyWithoutMinibancoInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoCreateNestedManyWithoutMinibancoInput>;
    tipo: TipoMinibanco;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type MinibancoExtrato = {
    __typename?: "MinibancoExtrato";
    chaveIntegracao?: Maybe<ExtratoIntegracao>;
    createdAt: Scalars["DateTime"];
    data: Scalars["DateTime"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    tipoId: Scalars["Int"];
    tipoMov: TipoMovimentacao;
    updatedAt: Scalars["DateTime"];
    valor: Scalars["Decimal"];
};

export type MinibancoExtratoAvgAggregate = {
    __typename?: "MinibancoExtratoAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    tipoId?: Maybe<Scalars["Float"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type MinibancoExtratoAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    tipoId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type MinibancoExtratoCountAggregate = {
    __typename?: "MinibancoExtratoCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    data: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    minibancoId: Scalars["Int"];
    tipoId: Scalars["Int"];
    updatedAt: Scalars["Int"];
    valor: Scalars["Int"];
};

export type MinibancoExtratoCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    data?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    tipoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type MinibancoExtratoCreateInput = {
    chaveIntegracao?: InputMaybe<ExtratoIntegracaoCreateNestedOneWithoutExtratoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    data: Scalars["DateTime"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutMovimentacoesInput;
    tipoMov: TipoMovimentacaoCreateNestedOneWithoutOcorrenciasInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valor: Scalars["Decimal"];
};

export type MinibancoExtratoCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    data: Scalars["DateTime"];
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    tipoId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valor: Scalars["Decimal"];
};

export type MinibancoExtratoCreateManyMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    data: Scalars["DateTime"];
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    tipoId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valor: Scalars["Decimal"];
};

export type MinibancoExtratoCreateManyMinibancoInputEnvelope = {
    data: Array<MinibancoExtratoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MinibancoExtratoCreateManyTipoMovInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    data: Scalars["DateTime"];
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valor: Scalars["Decimal"];
};

export type MinibancoExtratoCreateManyTipoMovInputEnvelope = {
    data: Array<MinibancoExtratoCreateManyTipoMovInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MinibancoExtratoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<MinibancoExtratoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoExtratoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<MinibancoExtratoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<MinibancoExtratoCreateManyMinibancoInputEnvelope>;
};

export type MinibancoExtratoCreateNestedManyWithoutTipoMovInput = {
    connect?: InputMaybe<Array<MinibancoExtratoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoExtratoCreateOrConnectWithoutTipoMovInput>>;
    create?: InputMaybe<Array<MinibancoExtratoCreateWithoutTipoMovInput>>;
    createMany?: InputMaybe<MinibancoExtratoCreateManyTipoMovInputEnvelope>;
};

export type MinibancoExtratoCreateNestedOneWithoutChaveIntegracaoInput = {
    connect?: InputMaybe<MinibancoExtratoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoExtratoCreateOrConnectWithoutChaveIntegracaoInput>;
    create?: InputMaybe<MinibancoExtratoCreateWithoutChaveIntegracaoInput>;
};

export type MinibancoExtratoCreateOrConnectWithoutChaveIntegracaoInput = {
    create: MinibancoExtratoCreateWithoutChaveIntegracaoInput;
    where: MinibancoExtratoWhereUniqueInput;
};

export type MinibancoExtratoCreateOrConnectWithoutMinibancoInput = {
    create: MinibancoExtratoCreateWithoutMinibancoInput;
    where: MinibancoExtratoWhereUniqueInput;
};

export type MinibancoExtratoCreateOrConnectWithoutTipoMovInput = {
    create: MinibancoExtratoCreateWithoutTipoMovInput;
    where: MinibancoExtratoWhereUniqueInput;
};

export type MinibancoExtratoCreateWithoutChaveIntegracaoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    data: Scalars["DateTime"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutMovimentacoesInput;
    tipoMov: TipoMovimentacaoCreateNestedOneWithoutOcorrenciasInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valor: Scalars["Decimal"];
};

export type MinibancoExtratoCreateWithoutMinibancoInput = {
    chaveIntegracao?: InputMaybe<ExtratoIntegracaoCreateNestedOneWithoutExtratoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    data: Scalars["DateTime"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    tipoMov: TipoMovimentacaoCreateNestedOneWithoutOcorrenciasInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valor: Scalars["Decimal"];
};

export type MinibancoExtratoCreateWithoutTipoMovInput = {
    chaveIntegracao?: InputMaybe<ExtratoIntegracaoCreateNestedOneWithoutExtratoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    data: Scalars["DateTime"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutMovimentacoesInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valor: Scalars["Decimal"];
};

export type MinibancoExtratoGroupBy = {
    __typename?: "MinibancoExtratoGroupBy";
    _avg?: Maybe<MinibancoExtratoAvgAggregate>;
    _count?: Maybe<MinibancoExtratoCountAggregate>;
    _max?: Maybe<MinibancoExtratoMaxAggregate>;
    _min?: Maybe<MinibancoExtratoMinAggregate>;
    _sum?: Maybe<MinibancoExtratoSumAggregate>;
    createdAt: Scalars["DateTime"];
    data: Scalars["DateTime"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibancoId: Scalars["Int"];
    tipoId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
    valor: Scalars["Decimal"];
};

export type MinibancoExtratoListRelationFilter = {
    every?: InputMaybe<MinibancoExtratoWhereInput>;
    none?: InputMaybe<MinibancoExtratoWhereInput>;
    some?: InputMaybe<MinibancoExtratoWhereInput>;
};

export type MinibancoExtratoMaxAggregate = {
    __typename?: "MinibancoExtratoMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    data?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    tipoId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type MinibancoExtratoMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    data?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    tipoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type MinibancoExtratoMinAggregate = {
    __typename?: "MinibancoExtratoMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    data?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    tipoId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type MinibancoExtratoMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    data?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    tipoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type MinibancoExtratoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type MinibancoExtratoOrderByWithAggregationInput = {
    _avg?: InputMaybe<MinibancoExtratoAvgOrderByAggregateInput>;
    _count?: InputMaybe<MinibancoExtratoCountOrderByAggregateInput>;
    _max?: InputMaybe<MinibancoExtratoMaxOrderByAggregateInput>;
    _min?: InputMaybe<MinibancoExtratoMinOrderByAggregateInput>;
    _sum?: InputMaybe<MinibancoExtratoSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    data?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    tipoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type MinibancoExtratoOrderByWithRelationInput = {
    chaveIntegracao?: InputMaybe<ExtratoIntegracaoOrderByWithRelationInput>;
    createdAt?: InputMaybe<SortOrder>;
    data?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    tipoId?: InputMaybe<SortOrder>;
    tipoMov?: InputMaybe<TipoMovimentacaoOrderByWithRelationInput>;
    updatedAt?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type MinibancoExtratoRelationFilter = {
    is?: InputMaybe<MinibancoExtratoWhereInput>;
    isNot?: InputMaybe<MinibancoExtratoWhereInput>;
};

export enum MinibancoExtratoScalarFieldEnum {
    CreatedAt = "createdAt",
    Data = "data",
    Id = "id",
    Inativo = "inativo",
    MinibancoId = "minibancoId",
    TipoId = "tipoId",
    UpdatedAt = "updatedAt",
    Valor = "valor",
}

export type MinibancoExtratoScalarWhereInput = {
    AND?: InputMaybe<Array<MinibancoExtratoScalarWhereInput>>;
    NOT?: InputMaybe<Array<MinibancoExtratoScalarWhereInput>>;
    OR?: InputMaybe<Array<MinibancoExtratoScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    data?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    tipoId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    valor?: InputMaybe<DecimalFilter>;
};

export type MinibancoExtratoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<MinibancoExtratoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<MinibancoExtratoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<MinibancoExtratoScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    data?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    tipoId?: InputMaybe<IntWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    valor?: InputMaybe<DecimalWithAggregatesFilter>;
};

export type MinibancoExtratoSumAggregate = {
    __typename?: "MinibancoExtratoSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    tipoId?: Maybe<Scalars["Int"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type MinibancoExtratoSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    tipoId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type MinibancoExtratoUpdateInput = {
    chaveIntegracao?: InputMaybe<ExtratoIntegracaoUpdateOneWithoutExtratoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    data?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutMovimentacoesNestedInput>;
    tipoMov?: InputMaybe<TipoMovimentacaoUpdateOneRequiredWithoutOcorrenciasNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type MinibancoExtratoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    data?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type MinibancoExtratoUpdateManyWithWhereWithoutMinibancoInput = {
    data: MinibancoExtratoUpdateManyMutationInput;
    where: MinibancoExtratoScalarWhereInput;
};

export type MinibancoExtratoUpdateManyWithWhereWithoutTipoMovInput = {
    data: MinibancoExtratoUpdateManyMutationInput;
    where: MinibancoExtratoScalarWhereInput;
};

export type MinibancoExtratoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<MinibancoExtratoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoExtratoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<MinibancoExtratoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<MinibancoExtratoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<MinibancoExtratoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<MinibancoExtratoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<MinibancoExtratoWhereUniqueInput>>;
    set?: InputMaybe<Array<MinibancoExtratoWhereUniqueInput>>;
    update?: InputMaybe<Array<MinibancoExtratoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<MinibancoExtratoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<MinibancoExtratoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type MinibancoExtratoUpdateManyWithoutTipoMovNestedInput = {
    connect?: InputMaybe<Array<MinibancoExtratoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoExtratoCreateOrConnectWithoutTipoMovInput>>;
    create?: InputMaybe<Array<MinibancoExtratoCreateWithoutTipoMovInput>>;
    createMany?: InputMaybe<MinibancoExtratoCreateManyTipoMovInputEnvelope>;
    delete?: InputMaybe<Array<MinibancoExtratoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<MinibancoExtratoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<MinibancoExtratoWhereUniqueInput>>;
    set?: InputMaybe<Array<MinibancoExtratoWhereUniqueInput>>;
    update?: InputMaybe<Array<MinibancoExtratoUpdateWithWhereUniqueWithoutTipoMovInput>>;
    updateMany?: InputMaybe<Array<MinibancoExtratoUpdateManyWithWhereWithoutTipoMovInput>>;
    upsert?: InputMaybe<Array<MinibancoExtratoUpsertWithWhereUniqueWithoutTipoMovInput>>;
};

export type MinibancoExtratoUpdateOneRequiredWithoutChaveIntegracaoNestedInput = {
    connect?: InputMaybe<MinibancoExtratoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoExtratoCreateOrConnectWithoutChaveIntegracaoInput>;
    create?: InputMaybe<MinibancoExtratoCreateWithoutChaveIntegracaoInput>;
    update?: InputMaybe<MinibancoExtratoUpdateWithoutChaveIntegracaoInput>;
    upsert?: InputMaybe<MinibancoExtratoUpsertWithoutChaveIntegracaoInput>;
};

export type MinibancoExtratoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: MinibancoExtratoUpdateWithoutMinibancoInput;
    where: MinibancoExtratoWhereUniqueInput;
};

export type MinibancoExtratoUpdateWithWhereUniqueWithoutTipoMovInput = {
    data: MinibancoExtratoUpdateWithoutTipoMovInput;
    where: MinibancoExtratoWhereUniqueInput;
};

export type MinibancoExtratoUpdateWithoutChaveIntegracaoInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    data?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutMovimentacoesNestedInput>;
    tipoMov?: InputMaybe<TipoMovimentacaoUpdateOneRequiredWithoutOcorrenciasNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type MinibancoExtratoUpdateWithoutMinibancoInput = {
    chaveIntegracao?: InputMaybe<ExtratoIntegracaoUpdateOneWithoutExtratoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    data?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    tipoMov?: InputMaybe<TipoMovimentacaoUpdateOneRequiredWithoutOcorrenciasNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type MinibancoExtratoUpdateWithoutTipoMovInput = {
    chaveIntegracao?: InputMaybe<ExtratoIntegracaoUpdateOneWithoutExtratoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    data?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutMovimentacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type MinibancoExtratoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: MinibancoExtratoCreateWithoutMinibancoInput;
    update: MinibancoExtratoUpdateWithoutMinibancoInput;
    where: MinibancoExtratoWhereUniqueInput;
};

export type MinibancoExtratoUpsertWithWhereUniqueWithoutTipoMovInput = {
    create: MinibancoExtratoCreateWithoutTipoMovInput;
    update: MinibancoExtratoUpdateWithoutTipoMovInput;
    where: MinibancoExtratoWhereUniqueInput;
};

export type MinibancoExtratoUpsertWithoutChaveIntegracaoInput = {
    create: MinibancoExtratoCreateWithoutChaveIntegracaoInput;
    update: MinibancoExtratoUpdateWithoutChaveIntegracaoInput;
};

export type MinibancoExtratoWhereInput = {
    AND?: InputMaybe<Array<MinibancoExtratoWhereInput>>;
    NOT?: InputMaybe<Array<MinibancoExtratoWhereInput>>;
    OR?: InputMaybe<Array<MinibancoExtratoWhereInput>>;
    chaveIntegracao?: InputMaybe<ExtratoIntegracaoRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    data?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    tipoId?: InputMaybe<IntFilter>;
    tipoMov?: InputMaybe<TipoMovimentacaoRelationFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    valor?: InputMaybe<DecimalFilter>;
};

export type MinibancoExtratoWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type MinibancoGroupBy = {
    __typename?: "MinibancoGroupBy";
    _avg?: Maybe<MinibancoAvgAggregate>;
    _count?: Maybe<MinibancoCountAggregate>;
    _max?: Maybe<MinibancoMaxAggregate>;
    _min?: Maybe<MinibancoMinAggregate>;
    _sum?: Maybe<MinibancoSumAggregate>;
    createdAt: Scalars["DateTime"];
    document: Scalars["String"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    keyAccountId?: Maybe<Scalars["Int"]>;
    nomeFantasia?: Maybe<Scalars["String"]>;
    parceiroId?: Maybe<Scalars["Int"]>;
    pessoaFisica: Scalars["Boolean"];
    razaoSocial: Scalars["String"];
    tipo: TipoMinibanco;
    updatedAt: Scalars["DateTime"];
};

export type MinibancoListRelationFilter = {
    every?: InputMaybe<MinibancoWhereInput>;
    none?: InputMaybe<MinibancoWhereInput>;
    some?: InputMaybe<MinibancoWhereInput>;
};

export type MinibancoMaxAggregate = {
    __typename?: "MinibancoMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    document?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    keyAccountId?: Maybe<Scalars["Int"]>;
    nomeFantasia?: Maybe<Scalars["String"]>;
    parceiroId?: Maybe<Scalars["Int"]>;
    pessoaFisica?: Maybe<Scalars["Boolean"]>;
    razaoSocial?: Maybe<Scalars["String"]>;
    tipo?: Maybe<TipoMinibanco>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MinibancoMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    parceiroId?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    tipo?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type MinibancoMinAggregate = {
    __typename?: "MinibancoMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    document?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    keyAccountId?: Maybe<Scalars["Int"]>;
    nomeFantasia?: Maybe<Scalars["String"]>;
    parceiroId?: Maybe<Scalars["Int"]>;
    pessoaFisica?: Maybe<Scalars["Boolean"]>;
    razaoSocial?: Maybe<Scalars["String"]>;
    tipo?: Maybe<TipoMinibanco>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MinibancoMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    parceiroId?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    tipo?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type MinibancoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type MinibancoOrderByWithAggregationInput = {
    _avg?: InputMaybe<MinibancoAvgOrderByAggregateInput>;
    _count?: InputMaybe<MinibancoCountOrderByAggregateInput>;
    _max?: InputMaybe<MinibancoMaxOrderByAggregateInput>;
    _min?: InputMaybe<MinibancoMinOrderByAggregateInput>;
    _sum?: InputMaybe<MinibancoSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    parceiroId?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    tipo?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type MinibancoOrderByWithRelationInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankOrderByRelationAggregateInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoOrderByRelationAggregateInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoOrderByRelationAggregateInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoOrderByRelationAggregateInput>;
    OperationsInfo?: InputMaybe<OperationsInfoOrderByRelationAggregateInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoOrderByRelationAggregateInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateOrderByRelationAggregateInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoOrderByRelationAggregateInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoOrderByRelationAggregateInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoOrderByRelationAggregateInput>;
    arquivos?: InputMaybe<ArquivoOrderByRelationAggregateInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroOrderByRelationAggregateInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroOrderByRelationAggregateInput>;
    cedentes?: InputMaybe<CedenteOrderByRelationAggregateInput>;
    contatos?: InputMaybe<MinibancoContatoOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    debenturistas?: InputMaybe<DebenturistaOrderByRelationAggregateInput>;
    document?: InputMaybe<SortOrder>;
    empresas?: InputMaybe<EmpresaOrderByRelationAggregateInput>;
    historico?: InputMaybe<MinibancoAuditOrderByRelationAggregateInput>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    keyAccount?: InputMaybe<UsuarioOrderByWithRelationInput>;
    keyAccountId?: InputMaybe<SortOrder>;
    movimentacoes?: InputMaybe<MinibancoExtratoOrderByRelationAggregateInput>;
    nomeFantasia?: InputMaybe<SortOrder>;
    operacoes?: InputMaybe<OperacaoOrderByRelationAggregateInput>;
    operacoesOrigem?: InputMaybe<OperacaoOrderByRelationAggregateInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialOrderByWithRelationInput>;
    parceiroId?: InputMaybe<SortOrder>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionOrderByRelationAggregateInput>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    recebiveis?: InputMaybe<RecebivelOrderByRelationAggregateInput>;
    sacados?: InputMaybe<SacadoOrderByRelationAggregateInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoOrderByRelationAggregateInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoOrderByRelationAggregateInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoOrderByRelationAggregateInput>;
    tipo?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarios?: InputMaybe<UsuarioOrderByRelationAggregateInput>;
};

export type MinibancoRelationFilter = {
    is?: InputMaybe<MinibancoWhereInput>;
    isNot?: InputMaybe<MinibancoWhereInput>;
};

export enum MinibancoScalarFieldEnum {
    CreatedAt = "createdAt",
    Document = "document",
    Id = "id",
    Inativo = "inativo",
    KeyAccountId = "keyAccountId",
    NomeFantasia = "nomeFantasia",
    ParceiroId = "parceiroId",
    PessoaFisica = "pessoaFisica",
    RazaoSocial = "razaoSocial",
    Tipo = "tipo",
    UpdatedAt = "updatedAt",
}

export type MinibancoScalarWhereInput = {
    AND?: InputMaybe<Array<MinibancoScalarWhereInput>>;
    NOT?: InputMaybe<Array<MinibancoScalarWhereInput>>;
    OR?: InputMaybe<Array<MinibancoScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    document?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    keyAccountId?: InputMaybe<IntNullableFilter>;
    nomeFantasia?: InputMaybe<StringNullableFilter>;
    parceiroId?: InputMaybe<IntNullableFilter>;
    pessoaFisica?: InputMaybe<BoolFilter>;
    razaoSocial?: InputMaybe<StringFilter>;
    tipo?: InputMaybe<EnumTipoMinibancoFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MinibancoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<MinibancoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<MinibancoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<MinibancoScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    document?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    keyAccountId?: InputMaybe<IntNullableWithAggregatesFilter>;
    nomeFantasia?: InputMaybe<StringNullableWithAggregatesFilter>;
    parceiroId?: InputMaybe<IntNullableWithAggregatesFilter>;
    pessoaFisica?: InputMaybe<BoolWithAggregatesFilter>;
    razaoSocial?: InputMaybe<StringWithAggregatesFilter>;
    tipo?: InputMaybe<EnumTipoMinibancoWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type MinibancoSumAggregate = {
    __typename?: "MinibancoSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    keyAccountId?: Maybe<Scalars["Int"]>;
    parceiroId?: Maybe<Scalars["Int"]>;
};

export type MinibancoSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    parceiroId?: InputMaybe<SortOrder>;
};

export type MinibancoUpdateInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MinibancoUpdateManyWithWhereWithoutCedentesInput = {
    data: MinibancoUpdateManyMutationInput;
    where: MinibancoScalarWhereInput;
};

export type MinibancoUpdateManyWithWhereWithoutEmpresasInput = {
    data: MinibancoUpdateManyMutationInput;
    where: MinibancoScalarWhereInput;
};

export type MinibancoUpdateManyWithWhereWithoutKeyAccountInput = {
    data: MinibancoUpdateManyMutationInput;
    where: MinibancoScalarWhereInput;
};

export type MinibancoUpdateManyWithWhereWithoutParceiroComercialInput = {
    data: MinibancoUpdateManyMutationInput;
    where: MinibancoScalarWhereInput;
};

export type MinibancoUpdateManyWithWhereWithoutSacadosInput = {
    data: MinibancoUpdateManyMutationInput;
    where: MinibancoScalarWhereInput;
};

export type MinibancoUpdateManyWithoutCedentesNestedInput = {
    connect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoCreateOrConnectWithoutCedentesInput>>;
    create?: InputMaybe<Array<MinibancoCreateWithoutCedentesInput>>;
    delete?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<MinibancoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    set?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    update?: InputMaybe<Array<MinibancoUpdateWithWhereUniqueWithoutCedentesInput>>;
    updateMany?: InputMaybe<Array<MinibancoUpdateManyWithWhereWithoutCedentesInput>>;
    upsert?: InputMaybe<Array<MinibancoUpsertWithWhereUniqueWithoutCedentesInput>>;
};

export type MinibancoUpdateManyWithoutEmpresasNestedInput = {
    connect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoCreateOrConnectWithoutEmpresasInput>>;
    create?: InputMaybe<Array<MinibancoCreateWithoutEmpresasInput>>;
    delete?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<MinibancoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    set?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    update?: InputMaybe<Array<MinibancoUpdateWithWhereUniqueWithoutEmpresasInput>>;
    updateMany?: InputMaybe<Array<MinibancoUpdateManyWithWhereWithoutEmpresasInput>>;
    upsert?: InputMaybe<Array<MinibancoUpsertWithWhereUniqueWithoutEmpresasInput>>;
};

export type MinibancoUpdateManyWithoutKeyAccountNestedInput = {
    connect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoCreateOrConnectWithoutKeyAccountInput>>;
    create?: InputMaybe<Array<MinibancoCreateWithoutKeyAccountInput>>;
    createMany?: InputMaybe<MinibancoCreateManyKeyAccountInputEnvelope>;
    delete?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<MinibancoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    set?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    update?: InputMaybe<Array<MinibancoUpdateWithWhereUniqueWithoutKeyAccountInput>>;
    updateMany?: InputMaybe<Array<MinibancoUpdateManyWithWhereWithoutKeyAccountInput>>;
    upsert?: InputMaybe<Array<MinibancoUpsertWithWhereUniqueWithoutKeyAccountInput>>;
};

export type MinibancoUpdateManyWithoutParceiroComercialNestedInput = {
    connect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoCreateOrConnectWithoutParceiroComercialInput>>;
    create?: InputMaybe<Array<MinibancoCreateWithoutParceiroComercialInput>>;
    createMany?: InputMaybe<MinibancoCreateManyParceiroComercialInputEnvelope>;
    delete?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<MinibancoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    set?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    update?: InputMaybe<Array<MinibancoUpdateWithWhereUniqueWithoutParceiroComercialInput>>;
    updateMany?: InputMaybe<Array<MinibancoUpdateManyWithWhereWithoutParceiroComercialInput>>;
    upsert?: InputMaybe<Array<MinibancoUpsertWithWhereUniqueWithoutParceiroComercialInput>>;
};

export type MinibancoUpdateManyWithoutSacadosNestedInput = {
    connect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<MinibancoCreateOrConnectWithoutSacadosInput>>;
    create?: InputMaybe<Array<MinibancoCreateWithoutSacadosInput>>;
    delete?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<MinibancoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    set?: InputMaybe<Array<MinibancoWhereUniqueInput>>;
    update?: InputMaybe<Array<MinibancoUpdateWithWhereUniqueWithoutSacadosInput>>;
    updateMany?: InputMaybe<Array<MinibancoUpdateManyWithWhereWithoutSacadosInput>>;
    upsert?: InputMaybe<Array<MinibancoUpsertWithWhereUniqueWithoutSacadosInput>>;
};

export type MinibancoUpdateOneRequiredWithoutAlteracaoCedentesEnderecoNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutAlteracaoCedentesEnderecoInput>;
    create?: InputMaybe<MinibancoCreateWithoutAlteracaoCedentesEnderecoInput>;
    update?: InputMaybe<MinibancoUpdateWithoutAlteracaoCedentesEnderecoInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutAlteracaoCedentesEnderecoInput>;
};

export type MinibancoUpdateOneRequiredWithoutAlteracaoCedentesNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutAlteracaoCedentesInput>;
    create?: InputMaybe<MinibancoCreateWithoutAlteracaoCedentesInput>;
    update?: InputMaybe<MinibancoUpdateWithoutAlteracaoCedentesInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutAlteracaoCedentesInput>;
};

export type MinibancoUpdateOneRequiredWithoutArquivosNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutArquivosInput>;
    create?: InputMaybe<MinibancoCreateWithoutArquivosInput>;
    update?: InputMaybe<MinibancoUpdateWithoutArquivosInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutArquivosInput>;
};

export type MinibancoUpdateOneRequiredWithoutAssignorStatusByMinibankNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutAssignorStatusByMinibankInput>;
    create?: InputMaybe<MinibancoCreateWithoutAssignorStatusByMinibankInput>;
    update?: InputMaybe<MinibancoUpdateWithoutAssignorStatusByMinibankInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutAssignorStatusByMinibankInput>;
};

export type MinibancoUpdateOneRequiredWithoutContatosNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutContatosInput>;
    create?: InputMaybe<MinibancoCreateWithoutContatosInput>;
    update?: InputMaybe<MinibancoUpdateWithoutContatosInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutContatosInput>;
};

export type MinibancoUpdateOneRequiredWithoutDebenturistasNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutDebenturistasInput>;
    create?: InputMaybe<MinibancoCreateWithoutDebenturistasInput>;
    update?: InputMaybe<MinibancoUpdateWithoutDebenturistasInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutDebenturistasInput>;
};

export type MinibancoUpdateOneRequiredWithoutGrupoNotificacaoNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutGrupoNotificacaoInput>;
    create?: InputMaybe<MinibancoCreateWithoutGrupoNotificacaoInput>;
    update?: InputMaybe<MinibancoUpdateWithoutGrupoNotificacaoInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutGrupoNotificacaoInput>;
};

export type MinibancoUpdateOneRequiredWithoutGruposPermissaoNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutGruposPermissaoInput>;
    create?: InputMaybe<MinibancoCreateWithoutGruposPermissaoInput>;
    update?: InputMaybe<MinibancoUpdateWithoutGruposPermissaoInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutGruposPermissaoInput>;
};

export type MinibancoUpdateOneRequiredWithoutHistoricoNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<MinibancoCreateWithoutHistoricoInput>;
    update?: InputMaybe<MinibancoUpdateWithoutHistoricoInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutHistoricoInput>;
};

export type MinibancoUpdateOneRequiredWithoutMovimentacoesNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutMovimentacoesInput>;
    create?: InputMaybe<MinibancoCreateWithoutMovimentacoesInput>;
    update?: InputMaybe<MinibancoUpdateWithoutMovimentacoesInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutMovimentacoesInput>;
};

export type MinibancoUpdateOneRequiredWithoutOperacaoIntegracaoNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutOperacaoIntegracaoInput>;
    create?: InputMaybe<MinibancoCreateWithoutOperacaoIntegracaoInput>;
    update?: InputMaybe<MinibancoUpdateWithoutOperacaoIntegracaoInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutOperacaoIntegracaoInput>;
};

export type MinibancoUpdateOneRequiredWithoutOperacoesNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutOperacoesInput>;
    create?: InputMaybe<MinibancoCreateWithoutOperacoesInput>;
    update?: InputMaybe<MinibancoUpdateWithoutOperacoesInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutOperacoesInput>;
};

export type MinibancoUpdateOneRequiredWithoutOperationsInfoNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutOperationsInfoInput>;
    create?: InputMaybe<MinibancoCreateWithoutOperationsInfoInput>;
    update?: InputMaybe<MinibancoUpdateWithoutOperationsInfoInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutOperationsInfoInput>;
};

export type MinibancoUpdateOneRequiredWithoutPartnerCommissionNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutPartnerCommissionInput>;
    create?: InputMaybe<MinibancoCreateWithoutPartnerCommissionInput>;
    update?: InputMaybe<MinibancoUpdateWithoutPartnerCommissionInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutPartnerCommissionInput>;
};

export type MinibancoUpdateOneRequiredWithoutRecebiveisInfoNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutRecebiveisInfoInput>;
    create?: InputMaybe<MinibancoCreateWithoutRecebiveisInfoInput>;
    update?: InputMaybe<MinibancoUpdateWithoutRecebiveisInfoInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutRecebiveisInfoInput>;
};

export type MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutRecebiveisInput>;
    create?: InputMaybe<MinibancoCreateWithoutRecebiveisInput>;
    update?: InputMaybe<MinibancoUpdateWithoutRecebiveisInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutRecebiveisInput>;
};

export type MinibancoUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutRecebiveisToAnticipateInput>;
    create?: InputMaybe<MinibancoCreateWithoutRecebiveisToAnticipateInput>;
    update?: InputMaybe<MinibancoUpdateWithoutRecebiveisToAnticipateInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutRecebiveisToAnticipateInput>;
};

export type MinibancoUpdateOneRequiredWithoutStatusOperacionalCedenteNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutStatusOperacionalCedenteInput>;
    create?: InputMaybe<MinibancoCreateWithoutStatusOperacionalCedenteInput>;
    update?: InputMaybe<MinibancoUpdateWithoutStatusOperacionalCedenteInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutStatusOperacionalCedenteInput>;
};

export type MinibancoUpdateOneRequiredWithoutTakeRateMinibancoNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutTakeRateMinibancoInput>;
    create?: InputMaybe<MinibancoCreateWithoutTakeRateMinibancoInput>;
    update?: InputMaybe<MinibancoUpdateWithoutTakeRateMinibancoInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutTakeRateMinibancoInput>;
};

export type MinibancoUpdateOneRequiredWithoutTaxasMinibancoNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutTaxasMinibancoInput>;
    create?: InputMaybe<MinibancoCreateWithoutTaxasMinibancoInput>;
    update?: InputMaybe<MinibancoUpdateWithoutTaxasMinibancoInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutTaxasMinibancoInput>;
};

export type MinibancoUpdateOneWithoutCadastroCedentesNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutCadastroCedentesInput>;
    create?: InputMaybe<MinibancoCreateWithoutCadastroCedentesInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<MinibancoUpdateWithoutCadastroCedentesInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutCadastroCedentesInput>;
};

export type MinibancoUpdateOneWithoutCadastroEnderecoNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutCadastroEnderecoInput>;
    create?: InputMaybe<MinibancoCreateWithoutCadastroEnderecoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<MinibancoUpdateWithoutCadastroEnderecoInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutCadastroEnderecoInput>;
};

export type MinibancoUpdateOneWithoutOperacoesOrigemNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutOperacoesOrigemInput>;
    create?: InputMaybe<MinibancoCreateWithoutOperacoesOrigemInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<MinibancoUpdateWithoutOperacoesOrigemInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutOperacoesOrigemInput>;
};

export type MinibancoUpdateOneWithoutRecebivelIntegracaoNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutRecebivelIntegracaoInput>;
    create?: InputMaybe<MinibancoCreateWithoutRecebivelIntegracaoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<MinibancoUpdateWithoutRecebivelIntegracaoInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutRecebivelIntegracaoInput>;
};

export type MinibancoUpdateOneWithoutUsuariosNestedInput = {
    connect?: InputMaybe<MinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<MinibancoCreateOrConnectWithoutUsuariosInput>;
    create?: InputMaybe<MinibancoCreateWithoutUsuariosInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<MinibancoUpdateWithoutUsuariosInput>;
    upsert?: InputMaybe<MinibancoUpsertWithoutUsuariosInput>;
};

export type MinibancoUpdateWithWhereUniqueWithoutCedentesInput = {
    data: MinibancoUpdateWithoutCedentesInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoUpdateWithWhereUniqueWithoutEmpresasInput = {
    data: MinibancoUpdateWithoutEmpresasInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoUpdateWithWhereUniqueWithoutKeyAccountInput = {
    data: MinibancoUpdateWithoutKeyAccountInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoUpdateWithWhereUniqueWithoutParceiroComercialInput = {
    data: MinibancoUpdateWithoutParceiroComercialInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoUpdateWithWhereUniqueWithoutSacadosInput = {
    data: MinibancoUpdateWithoutSacadosInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoUpdateWithoutAlteracaoCedentesEnderecoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutAlteracaoCedentesInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutArquivosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutAssignorStatusByMinibankInput = {
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutCadastroCedentesInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutCadastroEnderecoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutCedentesInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutContatosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutDebenturistasInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutEmpresasInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutGrupoNotificacaoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutGruposPermissaoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutHistoricoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutKeyAccountInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutMovimentacoesInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutOperacaoIntegracaoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutOperacoesInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutOperacoesOrigemInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutOperationsInfoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutParceiroComercialInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutPartnerCommissionInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutRecebiveisInfoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutRecebiveisInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutRecebiveisToAnticipateInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutRecebivelIntegracaoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutSacadosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutStatusOperacionalCedenteInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutTakeRateMinibancoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutTaxasMinibancoInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarios?: InputMaybe<UsuarioUpdateManyWithoutMinibancoNestedInput>;
};

export type MinibancoUpdateWithoutUsuariosInput = {
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankUpdateManyWithoutMinibankNestedInput>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutMinibancoNestedInput>;
    GruposPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutMinibancoNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutMinibancoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutMinibancoNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutMinibancoNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutMinibancoNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutMinibancoNestedInput>;
    cedentes?: InputMaybe<CedenteUpdateManyWithoutMinibancosNestedInput>;
    contatos?: InputMaybe<MinibancoContatoUpdateManyWithoutMinibancoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    debenturistas?: InputMaybe<DebenturistaUpdateManyWithoutMinibancoNestedInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    empresas?: InputMaybe<EmpresaUpdateManyWithoutMinibancosNestedInput>;
    historico?: InputMaybe<MinibancoAuditUpdateManyWithoutMinibancoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    keyAccount?: InputMaybe<UsuarioUpdateOneWithoutMinibanksWalletNestedInput>;
    movimentacoes?: InputMaybe<MinibancoExtratoUpdateManyWithoutMinibancoNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutMinibancoNestedInput>;
    operacoesOrigem?: InputMaybe<OperacaoUpdateManyWithoutOrigemOperacaoNestedInput>;
    parceiroComercial?: InputMaybe<ParceiroComercialUpdateOneWithoutMinibancosNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutMinibankNestedInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutMinibancoNestedInput>;
    sacados?: InputMaybe<SacadoUpdateManyWithoutMinibancosNestedInput>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoUpdateManyWithoutMinibancoNestedInput>;
    tipo?: InputMaybe<EnumTipoMinibancoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MinibancoUpsertWithWhereUniqueWithoutCedentesInput = {
    create: MinibancoCreateWithoutCedentesInput;
    update: MinibancoUpdateWithoutCedentesInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoUpsertWithWhereUniqueWithoutEmpresasInput = {
    create: MinibancoCreateWithoutEmpresasInput;
    update: MinibancoUpdateWithoutEmpresasInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoUpsertWithWhereUniqueWithoutKeyAccountInput = {
    create: MinibancoCreateWithoutKeyAccountInput;
    update: MinibancoUpdateWithoutKeyAccountInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoUpsertWithWhereUniqueWithoutParceiroComercialInput = {
    create: MinibancoCreateWithoutParceiroComercialInput;
    update: MinibancoUpdateWithoutParceiroComercialInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoUpsertWithWhereUniqueWithoutSacadosInput = {
    create: MinibancoCreateWithoutSacadosInput;
    update: MinibancoUpdateWithoutSacadosInput;
    where: MinibancoWhereUniqueInput;
};

export type MinibancoUpsertWithoutAlteracaoCedentesEnderecoInput = {
    create: MinibancoCreateWithoutAlteracaoCedentesEnderecoInput;
    update: MinibancoUpdateWithoutAlteracaoCedentesEnderecoInput;
};

export type MinibancoUpsertWithoutAlteracaoCedentesInput = {
    create: MinibancoCreateWithoutAlteracaoCedentesInput;
    update: MinibancoUpdateWithoutAlteracaoCedentesInput;
};

export type MinibancoUpsertWithoutArquivosInput = {
    create: MinibancoCreateWithoutArquivosInput;
    update: MinibancoUpdateWithoutArquivosInput;
};

export type MinibancoUpsertWithoutAssignorStatusByMinibankInput = {
    create: MinibancoCreateWithoutAssignorStatusByMinibankInput;
    update: MinibancoUpdateWithoutAssignorStatusByMinibankInput;
};

export type MinibancoUpsertWithoutCadastroCedentesInput = {
    create: MinibancoCreateWithoutCadastroCedentesInput;
    update: MinibancoUpdateWithoutCadastroCedentesInput;
};

export type MinibancoUpsertWithoutCadastroEnderecoInput = {
    create: MinibancoCreateWithoutCadastroEnderecoInput;
    update: MinibancoUpdateWithoutCadastroEnderecoInput;
};

export type MinibancoUpsertWithoutContatosInput = {
    create: MinibancoCreateWithoutContatosInput;
    update: MinibancoUpdateWithoutContatosInput;
};

export type MinibancoUpsertWithoutDebenturistasInput = {
    create: MinibancoCreateWithoutDebenturistasInput;
    update: MinibancoUpdateWithoutDebenturistasInput;
};

export type MinibancoUpsertWithoutGrupoNotificacaoInput = {
    create: MinibancoCreateWithoutGrupoNotificacaoInput;
    update: MinibancoUpdateWithoutGrupoNotificacaoInput;
};

export type MinibancoUpsertWithoutGruposPermissaoInput = {
    create: MinibancoCreateWithoutGruposPermissaoInput;
    update: MinibancoUpdateWithoutGruposPermissaoInput;
};

export type MinibancoUpsertWithoutHistoricoInput = {
    create: MinibancoCreateWithoutHistoricoInput;
    update: MinibancoUpdateWithoutHistoricoInput;
};

export type MinibancoUpsertWithoutMovimentacoesInput = {
    create: MinibancoCreateWithoutMovimentacoesInput;
    update: MinibancoUpdateWithoutMovimentacoesInput;
};

export type MinibancoUpsertWithoutOperacaoIntegracaoInput = {
    create: MinibancoCreateWithoutOperacaoIntegracaoInput;
    update: MinibancoUpdateWithoutOperacaoIntegracaoInput;
};

export type MinibancoUpsertWithoutOperacoesInput = {
    create: MinibancoCreateWithoutOperacoesInput;
    update: MinibancoUpdateWithoutOperacoesInput;
};

export type MinibancoUpsertWithoutOperacoesOrigemInput = {
    create: MinibancoCreateWithoutOperacoesOrigemInput;
    update: MinibancoUpdateWithoutOperacoesOrigemInput;
};

export type MinibancoUpsertWithoutOperationsInfoInput = {
    create: MinibancoCreateWithoutOperationsInfoInput;
    update: MinibancoUpdateWithoutOperationsInfoInput;
};

export type MinibancoUpsertWithoutPartnerCommissionInput = {
    create: MinibancoCreateWithoutPartnerCommissionInput;
    update: MinibancoUpdateWithoutPartnerCommissionInput;
};

export type MinibancoUpsertWithoutRecebiveisInfoInput = {
    create: MinibancoCreateWithoutRecebiveisInfoInput;
    update: MinibancoUpdateWithoutRecebiveisInfoInput;
};

export type MinibancoUpsertWithoutRecebiveisInput = {
    create: MinibancoCreateWithoutRecebiveisInput;
    update: MinibancoUpdateWithoutRecebiveisInput;
};

export type MinibancoUpsertWithoutRecebiveisToAnticipateInput = {
    create: MinibancoCreateWithoutRecebiveisToAnticipateInput;
    update: MinibancoUpdateWithoutRecebiveisToAnticipateInput;
};

export type MinibancoUpsertWithoutRecebivelIntegracaoInput = {
    create: MinibancoCreateWithoutRecebivelIntegracaoInput;
    update: MinibancoUpdateWithoutRecebivelIntegracaoInput;
};

export type MinibancoUpsertWithoutStatusOperacionalCedenteInput = {
    create: MinibancoCreateWithoutStatusOperacionalCedenteInput;
    update: MinibancoUpdateWithoutStatusOperacionalCedenteInput;
};

export type MinibancoUpsertWithoutTakeRateMinibancoInput = {
    create: MinibancoCreateWithoutTakeRateMinibancoInput;
    update: MinibancoUpdateWithoutTakeRateMinibancoInput;
};

export type MinibancoUpsertWithoutTaxasMinibancoInput = {
    create: MinibancoCreateWithoutTaxasMinibancoInput;
    update: MinibancoUpdateWithoutTaxasMinibancoInput;
};

export type MinibancoUpsertWithoutUsuariosInput = {
    create: MinibancoCreateWithoutUsuariosInput;
    update: MinibancoUpdateWithoutUsuariosInput;
};

export type MinibancoWhereInput = {
    AND?: InputMaybe<Array<MinibancoWhereInput>>;
    AssignorStatusByMinibank?: InputMaybe<AssignorStatusByMinibankListRelationFilter>;
    GrupoNotificacao?: InputMaybe<GrupoNotificacaoListRelationFilter>;
    GruposPermissao?: InputMaybe<GruposPermissaoListRelationFilter>;
    NOT?: InputMaybe<Array<MinibancoWhereInput>>;
    OR?: InputMaybe<Array<MinibancoWhereInput>>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoListRelationFilter>;
    OperationsInfo?: InputMaybe<OperationsInfoListRelationFilter>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoListRelationFilter>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateListRelationFilter>;
    RecebivelIntegracao?: InputMaybe<RecebivelIntegracaoListRelationFilter>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoListRelationFilter>;
    alteracaoCedentesEndereco?: InputMaybe<EnderecoAlteracaoListRelationFilter>;
    arquivos?: InputMaybe<ArquivoListRelationFilter>;
    cadastroCedentes?: InputMaybe<CedenteCadastroListRelationFilter>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroListRelationFilter>;
    cedentes?: InputMaybe<CedenteListRelationFilter>;
    contatos?: InputMaybe<MinibancoContatoListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    debenturistas?: InputMaybe<DebenturistaListRelationFilter>;
    document?: InputMaybe<StringFilter>;
    empresas?: InputMaybe<EmpresaListRelationFilter>;
    historico?: InputMaybe<MinibancoAuditListRelationFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    keyAccount?: InputMaybe<UsuarioRelationFilter>;
    keyAccountId?: InputMaybe<IntNullableFilter>;
    movimentacoes?: InputMaybe<MinibancoExtratoListRelationFilter>;
    nomeFantasia?: InputMaybe<StringNullableFilter>;
    operacoes?: InputMaybe<OperacaoListRelationFilter>;
    operacoesOrigem?: InputMaybe<OperacaoListRelationFilter>;
    parceiroComercial?: InputMaybe<ParceiroComercialRelationFilter>;
    parceiroId?: InputMaybe<IntNullableFilter>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionListRelationFilter>;
    pessoaFisica?: InputMaybe<BoolFilter>;
    razaoSocial?: InputMaybe<StringFilter>;
    recebiveis?: InputMaybe<RecebivelListRelationFilter>;
    sacados?: InputMaybe<SacadoListRelationFilter>;
    statusOperacionalCedente?: InputMaybe<StatusOperacionalCedenteMinibancoListRelationFilter>;
    takeRateMinibanco?: InputMaybe<TakeRateMinibancoListRelationFilter>;
    taxasMinibanco?: InputMaybe<TaxasMinibancoListRelationFilter>;
    tipo?: InputMaybe<EnumTipoMinibancoFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuarios?: InputMaybe<UsuarioListRelationFilter>;
};

export type MinibancoWhereUniqueInput = {
    document?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
};

export type MinibankIntegration = {
    __typename?: "MinibankIntegration";
    minibankId: Scalars["Int"];
    source: Erp;
    sourceId?: Maybe<Scalars["Int"]>;
};

export type MinibankIntegrationAvgAggregate = {
    __typename?: "MinibankIntegrationAvgAggregate";
    minibankId?: Maybe<Scalars["Float"]>;
    sourceId?: Maybe<Scalars["Float"]>;
};

export type MinibankIntegrationAvgOrderByAggregateInput = {
    minibankId?: InputMaybe<SortOrder>;
    sourceId?: InputMaybe<SortOrder>;
};

export type MinibankIntegrationCountAggregate = {
    __typename?: "MinibankIntegrationCountAggregate";
    _all: Scalars["Int"];
    minibankId: Scalars["Int"];
    source: Scalars["Int"];
    sourceId: Scalars["Int"];
};

export type MinibankIntegrationCountOrderByAggregateInput = {
    minibankId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
    sourceId?: InputMaybe<SortOrder>;
};

export type MinibankIntegrationCreateInput = {
    minibankId: Scalars["Int"];
    source?: InputMaybe<Erp>;
    sourceId?: InputMaybe<Scalars["Int"]>;
};

export type MinibankIntegrationCreateManyInput = {
    minibankId: Scalars["Int"];
    source?: InputMaybe<Erp>;
    sourceId?: InputMaybe<Scalars["Int"]>;
};

export type MinibankIntegrationGroupBy = {
    __typename?: "MinibankIntegrationGroupBy";
    _avg?: Maybe<MinibankIntegrationAvgAggregate>;
    _count?: Maybe<MinibankIntegrationCountAggregate>;
    _max?: Maybe<MinibankIntegrationMaxAggregate>;
    _min?: Maybe<MinibankIntegrationMinAggregate>;
    _sum?: Maybe<MinibankIntegrationSumAggregate>;
    minibankId: Scalars["Int"];
    source: Erp;
    sourceId?: Maybe<Scalars["Int"]>;
};

export type MinibankIntegrationMaxAggregate = {
    __typename?: "MinibankIntegrationMaxAggregate";
    minibankId?: Maybe<Scalars["Int"]>;
    source?: Maybe<Erp>;
    sourceId?: Maybe<Scalars["Int"]>;
};

export type MinibankIntegrationMaxOrderByAggregateInput = {
    minibankId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
    sourceId?: InputMaybe<SortOrder>;
};

export type MinibankIntegrationMinAggregate = {
    __typename?: "MinibankIntegrationMinAggregate";
    minibankId?: Maybe<Scalars["Int"]>;
    source?: Maybe<Erp>;
    sourceId?: Maybe<Scalars["Int"]>;
};

export type MinibankIntegrationMinOrderByAggregateInput = {
    minibankId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
    sourceId?: InputMaybe<SortOrder>;
};

export type MinibankIntegrationOrderByWithAggregationInput = {
    _avg?: InputMaybe<MinibankIntegrationAvgOrderByAggregateInput>;
    _count?: InputMaybe<MinibankIntegrationCountOrderByAggregateInput>;
    _max?: InputMaybe<MinibankIntegrationMaxOrderByAggregateInput>;
    _min?: InputMaybe<MinibankIntegrationMinOrderByAggregateInput>;
    _sum?: InputMaybe<MinibankIntegrationSumOrderByAggregateInput>;
    minibankId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
    sourceId?: InputMaybe<SortOrder>;
};

export type MinibankIntegrationOrderByWithRelationInput = {
    minibankId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
    sourceId?: InputMaybe<SortOrder>;
};

export enum MinibankIntegrationScalarFieldEnum {
    MinibankId = "minibankId",
    Source = "source",
    SourceId = "sourceId",
}

export type MinibankIntegrationScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<MinibankIntegrationScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<MinibankIntegrationScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<MinibankIntegrationScalarWhereWithAggregatesInput>>;
    minibankId?: InputMaybe<IntWithAggregatesFilter>;
    source?: InputMaybe<EnumErpWithAggregatesFilter>;
    sourceId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type MinibankIntegrationSumAggregate = {
    __typename?: "MinibankIntegrationSumAggregate";
    minibankId?: Maybe<Scalars["Int"]>;
    sourceId?: Maybe<Scalars["Int"]>;
};

export type MinibankIntegrationSumOrderByAggregateInput = {
    minibankId?: InputMaybe<SortOrder>;
    sourceId?: InputMaybe<SortOrder>;
};

export type MinibankIntegrationUpdateInput = {
    minibankId?: InputMaybe<IntFieldUpdateOperationsInput>;
    source?: InputMaybe<EnumErpFieldUpdateOperationsInput>;
    sourceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type MinibankIntegrationUpdateManyMutationInput = {
    minibankId?: InputMaybe<IntFieldUpdateOperationsInput>;
    source?: InputMaybe<EnumErpFieldUpdateOperationsInput>;
    sourceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
};

export type MinibankIntegrationWhereInput = {
    AND?: InputMaybe<Array<MinibankIntegrationWhereInput>>;
    NOT?: InputMaybe<Array<MinibankIntegrationWhereInput>>;
    OR?: InputMaybe<Array<MinibankIntegrationWhereInput>>;
    minibankId?: InputMaybe<IntFilter>;
    source?: InputMaybe<EnumErpFilter>;
    sourceId?: InputMaybe<IntNullableFilter>;
};

export type MinibankIntegrationWhereUniqueInput = {
    minibankId?: InputMaybe<Scalars["Int"]>;
    sourceId?: InputMaybe<Scalars["Int"]>;
};

export type Mutation = {
    __typename?: "Mutation";
    createManyAcceptedTerms: AffectedRowsOutput;
    createManyArquivo: AffectedRowsOutput;
    createManyArquivoAudit: AffectedRowsOutput;
    createManyAssignorBankData: AffectedRowsOutput;
    createManyAssignorCRM: AffectedRowsOutput;
    createManyAssignorDocumentType: AffectedRowsOutput;
    createManyAssignorStatusByMinibank: AffectedRowsOutput;
    createManyBanks: AffectedRowsOutput;
    createManyCTe: AffectedRowsOutput;
    createManyCedente: AffectedRowsOutput;
    createManyCedenteAlteracao: AffectedRowsOutput;
    createManyCedenteAnexos: AffectedRowsOutput;
    createManyCedenteAudit: AffectedRowsOutput;
    createManyCedenteCadastro: AffectedRowsOutput;
    createManyCedenteCamposAlteracao: AffectedRowsOutput;
    createManyCedenteCamposCadastro: AffectedRowsOutput;
    createManyCedenteContato: AffectedRowsOutput;
    createManyCedentesInfo: AffectedRowsOutput;
    createManyCnaeClasses: AffectedRowsOutput;
    createManyCnaeDivisoes: AffectedRowsOutput;
    createManyCnaeGrupos: AffectedRowsOutput;
    createManyCnaeSecoes: AffectedRowsOutput;
    createManyCnaeSubClasses: AffectedRowsOutput;
    createManyComercialPartnerAudit: AffectedRowsOutput;
    createManyComercialPartnerCommission: AffectedRowsOutput;
    createManyContaBancaria: AffectedRowsOutput;
    createManyContato: AffectedRowsOutput;
    createManyContatoAudit: AffectedRowsOutput;
    createManyDebenturista: AffectedRowsOutput;
    createManyDebenturistaAudit: AffectedRowsOutput;
    createManyEmpresa: AffectedRowsOutput;
    createManyEmpresaAudit: AffectedRowsOutput;
    createManyEndereco: AffectedRowsOutput;
    createManyEnderecoAlteracao: AffectedRowsOutput;
    createManyEnderecoAudit: AffectedRowsOutput;
    createManyEnderecoCadastro: AffectedRowsOutput;
    createManyEnderecoCamposAlteracao: AffectedRowsOutput;
    createManyEnderecoCamposCadastro: AffectedRowsOutput;
    createManyEvent: AffectedRowsOutput;
    createManyExtratoIntegracao: AffectedRowsOutput;
    createManyGrupoNotificacao: AffectedRowsOutput;
    createManyGruposPermissao: AffectedRowsOutput;
    createManyMinibanco: AffectedRowsOutput;
    createManyMinibancoAudit: AffectedRowsOutput;
    createManyMinibancoContato: AffectedRowsOutput;
    createManyMinibancoExtrato: AffectedRowsOutput;
    createManyMinibankIntegration: AffectedRowsOutput;
    createManyNFe: AffectedRowsOutput;
    createManyNFeParcela: AffectedRowsOutput;
    createManyNivelPermissao: AffectedRowsOutput;
    createManyOccupation: AffectedRowsOutput;
    createManyOccupationAudit: AffectedRowsOutput;
    createManyOperacao: AffectedRowsOutput;
    createManyOperacaoAnexos: AffectedRowsOutput;
    createManyOperacaoAudit: AffectedRowsOutput;
    createManyOperacaoChecklist: AffectedRowsOutput;
    createManyOperacaoIntegracao: AffectedRowsOutput;
    createManyOperacaoModalidade: AffectedRowsOutput;
    createManyOperacaoRascunho: AffectedRowsOutput;
    createManyOperacaoStatus: AffectedRowsOutput;
    createManyOperacaoSugerida: AffectedRowsOutput;
    createManyOperacaoTipo: AffectedRowsOutput;
    createManyOperacoesRascunhoView: AffectedRowsOutput;
    createManyOperationCommision: AffectedRowsOutput;
    createManyOperationFees: AffectedRowsOutput;
    createManyOperationUpdates: AffectedRowsOutput;
    createManyOperationsInfo: AffectedRowsOutput;
    createManyParceiroComercial: AffectedRowsOutput;
    createManyPayableUpdates: AffectedRowsOutput;
    createManyPermissoesGrupos: AffectedRowsOutput;
    createManyPreCadastroCedente: AffectedRowsOutput;
    createManyRecebiveisInfo: AffectedRowsOutput;
    createManyRecebiveisToAnticipate: AffectedRowsOutput;
    createManyRecebivel: AffectedRowsOutput;
    createManyRecebivelAudit: AffectedRowsOutput;
    createManyRecebivelIntegracao: AffectedRowsOutput;
    createManyRecebivelOperado: AffectedRowsOutput;
    createManyRecebivelPagarContas: AffectedRowsOutput;
    createManyRepresentative: AffectedRowsOutput;
    createManyResponsavelCedente: AffectedRowsOutput;
    createManySacado: AffectedRowsOutput;
    createManySacadoAudit: AffectedRowsOutput;
    createManySacadoContato: AffectedRowsOutput;
    createManyStatusOperacionalAudit: AffectedRowsOutput;
    createManyStatusOperacionalCedenteMinibanco: AffectedRowsOutput;
    createManyTakeRateMinibanco: AffectedRowsOutput;
    createManyTaxasMinibanco: AffectedRowsOutput;
    createManyTermsAndConditions: AffectedRowsOutput;
    createManyTipoMovimentacao: AffectedRowsOutput;
    createManyUsuario: AffectedRowsOutput;
    createManyUsuarioAudit: AffectedRowsOutput;
    createManyUsuarioCadastro: AffectedRowsOutput;
    createManyUsuarioCadastroCampos: AffectedRowsOutput;
    createOneAcceptedTerms: AcceptedTerms;
    createOneArquivo: Arquivo;
    createOneArquivoAudit: ArquivoAudit;
    createOneAssignorBankData: AssignorBankData;
    createOneAssignorCRM: AssignorCrm;
    createOneAssignorDocumentType: AssignorDocumentType;
    createOneAssignorStatusByMinibank: AssignorStatusByMinibank;
    createOneBanks: Banks;
    createOneCTe: CTe;
    createOneCedente: Cedente;
    createOneCedenteAlteracao: CedenteAlteracao;
    createOneCedenteAnexos: CedenteAnexos;
    createOneCedenteAudit: CedenteAudit;
    createOneCedenteCadastro: CedenteCadastro;
    createOneCedenteCamposAlteracao: CedenteCamposAlteracao;
    createOneCedenteCamposCadastro: CedenteCamposCadastro;
    createOneCedenteContato: CedenteContato;
    createOneCedentesInfo: CedentesInfo;
    createOneCnaeClasses: CnaeClasses;
    createOneCnaeDivisoes: CnaeDivisoes;
    createOneCnaeGrupos: CnaeGrupos;
    createOneCnaeSecoes: CnaeSecoes;
    createOneCnaeSubClasses: CnaeSubClasses;
    createOneComercialPartnerAudit: ComercialPartnerAudit;
    createOneComercialPartnerCommission: ComercialPartnerCommission;
    createOneContaBancaria: ContaBancaria;
    createOneContato: Contato;
    createOneContatoAudit: ContatoAudit;
    createOneDebenturista: Debenturista;
    createOneDebenturistaAudit: DebenturistaAudit;
    createOneEmpresa: Empresa;
    createOneEmpresaAudit: EmpresaAudit;
    createOneEndereco: Endereco;
    createOneEnderecoAlteracao: EnderecoAlteracao;
    createOneEnderecoAudit: EnderecoAudit;
    createOneEnderecoCadastro: EnderecoCadastro;
    createOneEnderecoCamposAlteracao: EnderecoCamposAlteracao;
    createOneEnderecoCamposCadastro: EnderecoCamposCadastro;
    createOneEvent: Event;
    createOneExtratoIntegracao: ExtratoIntegracao;
    createOneGrupoNotificacao: GrupoNotificacao;
    createOneGruposPermissao: GruposPermissao;
    createOneMinibanco: Minibanco;
    createOneMinibancoAudit: MinibancoAudit;
    createOneMinibancoContato: MinibancoContato;
    createOneMinibancoExtrato: MinibancoExtrato;
    createOneMinibankIntegration: MinibankIntegration;
    createOneNFe: NFe;
    createOneNFeParcela: NFeParcela;
    createOneNivelPermissao: NivelPermissao;
    createOneOccupation: Occupation;
    createOneOccupationAudit: OccupationAudit;
    createOneOperacao: Operacao;
    createOneOperacaoAnexos: OperacaoAnexos;
    createOneOperacaoAudit: OperacaoAudit;
    createOneOperacaoChecklist: OperacaoChecklist;
    createOneOperacaoIntegracao: OperacaoIntegracao;
    createOneOperacaoModalidade: OperacaoModalidade;
    createOneOperacaoRascunho: OperacaoRascunho;
    createOneOperacaoStatus: OperacaoStatus;
    createOneOperacaoSugerida: OperacaoSugerida;
    createOneOperacaoTipo: OperacaoTipo;
    createOneOperacoesRascunhoView: OperacoesRascunhoView;
    createOneOperationCommision: OperationCommision;
    createOneOperationFees: OperationFees;
    createOneOperationUpdates: OperationUpdates;
    createOneOperationsInfo: OperationsInfo;
    createOneParceiroComercial: ParceiroComercial;
    createOnePayableUpdates: PayableUpdates;
    createOnePermissoesGrupos: PermissoesGrupos;
    createOnePreCadastroCedente: PreCadastroCedente;
    createOneRecebiveisInfo: RecebiveisInfo;
    createOneRecebiveisToAnticipate: RecebiveisToAnticipate;
    createOneRecebivel: Recebivel;
    createOneRecebivelAudit: RecebivelAudit;
    createOneRecebivelIntegracao: RecebivelIntegracao;
    createOneRecebivelOperado: RecebivelOperado;
    createOneRecebivelPagarContas: RecebivelPagarContas;
    createOneRepresentative: Representative;
    createOneResponsavelCedente: ResponsavelCedente;
    createOneSacado: Sacado;
    createOneSacadoAudit: SacadoAudit;
    createOneSacadoContato: SacadoContato;
    createOneStatusOperacionalAudit: StatusOperacionalAudit;
    createOneStatusOperacionalCedenteMinibanco: StatusOperacionalCedenteMinibanco;
    createOneTakeRateMinibanco: TakeRateMinibanco;
    createOneTaxasMinibanco: TaxasMinibanco;
    createOneTermsAndConditions: TermsAndConditions;
    createOneTipoMovimentacao: TipoMovimentacao;
    createOneUsuario: Usuario;
    createOneUsuarioAudit: UsuarioAudit;
    createOneUsuarioCadastro: UsuarioCadastro;
    createOneUsuarioCadastroCampos: UsuarioCadastroCampos;
    deleteFileFromOperation: Scalars["Boolean"];
    deleteManyAcceptedTerms: AffectedRowsOutput;
    deleteManyArquivo: AffectedRowsOutput;
    deleteManyArquivoAudit: AffectedRowsOutput;
    deleteManyAssignorBankData: AffectedRowsOutput;
    deleteManyAssignorCRM: AffectedRowsOutput;
    deleteManyAssignorDocumentType: AffectedRowsOutput;
    deleteManyAssignorStatusByMinibank: AffectedRowsOutput;
    deleteManyBanks: AffectedRowsOutput;
    deleteManyCTe: AffectedRowsOutput;
    deleteManyCedente: AffectedRowsOutput;
    deleteManyCedenteAlteracao: AffectedRowsOutput;
    deleteManyCedenteAnexos: AffectedRowsOutput;
    deleteManyCedenteAudit: AffectedRowsOutput;
    deleteManyCedenteCadastro: AffectedRowsOutput;
    deleteManyCedenteCamposAlteracao: AffectedRowsOutput;
    deleteManyCedenteCamposCadastro: AffectedRowsOutput;
    deleteManyCedenteContato: AffectedRowsOutput;
    deleteManyCedentesInfo: AffectedRowsOutput;
    deleteManyCnaeClasses: AffectedRowsOutput;
    deleteManyCnaeDivisoes: AffectedRowsOutput;
    deleteManyCnaeGrupos: AffectedRowsOutput;
    deleteManyCnaeSecoes: AffectedRowsOutput;
    deleteManyCnaeSubClasses: AffectedRowsOutput;
    deleteManyComercialPartnerAudit: AffectedRowsOutput;
    deleteManyComercialPartnerCommission: AffectedRowsOutput;
    deleteManyContaBancaria: AffectedRowsOutput;
    deleteManyContato: AffectedRowsOutput;
    deleteManyContatoAudit: AffectedRowsOutput;
    deleteManyDebenturista: AffectedRowsOutput;
    deleteManyDebenturistaAudit: AffectedRowsOutput;
    deleteManyEmpresa: AffectedRowsOutput;
    deleteManyEmpresaAudit: AffectedRowsOutput;
    deleteManyEndereco: AffectedRowsOutput;
    deleteManyEnderecoAlteracao: AffectedRowsOutput;
    deleteManyEnderecoAudit: AffectedRowsOutput;
    deleteManyEnderecoCadastro: AffectedRowsOutput;
    deleteManyEnderecoCamposAlteracao: AffectedRowsOutput;
    deleteManyEnderecoCamposCadastro: AffectedRowsOutput;
    deleteManyEvent: AffectedRowsOutput;
    deleteManyExtratoIntegracao: AffectedRowsOutput;
    deleteManyGrupoNotificacao: AffectedRowsOutput;
    deleteManyGruposPermissao: AffectedRowsOutput;
    deleteManyMinibanco: AffectedRowsOutput;
    deleteManyMinibancoAudit: AffectedRowsOutput;
    deleteManyMinibancoContato: AffectedRowsOutput;
    deleteManyMinibancoExtrato: AffectedRowsOutput;
    deleteManyMinibankIntegration: AffectedRowsOutput;
    deleteManyNFe: AffectedRowsOutput;
    deleteManyNFeParcela: AffectedRowsOutput;
    deleteManyNivelPermissao: AffectedRowsOutput;
    deleteManyOccupation: AffectedRowsOutput;
    deleteManyOccupationAudit: AffectedRowsOutput;
    deleteManyOperacao: AffectedRowsOutput;
    deleteManyOperacaoAnexos: AffectedRowsOutput;
    deleteManyOperacaoAudit: AffectedRowsOutput;
    deleteManyOperacaoChecklist: AffectedRowsOutput;
    deleteManyOperacaoIntegracao: AffectedRowsOutput;
    deleteManyOperacaoModalidade: AffectedRowsOutput;
    deleteManyOperacaoRascunho: AffectedRowsOutput;
    deleteManyOperacaoStatus: AffectedRowsOutput;
    deleteManyOperacaoSugerida: AffectedRowsOutput;
    deleteManyOperacaoTipo: AffectedRowsOutput;
    deleteManyOperacoesRascunhoView: AffectedRowsOutput;
    deleteManyOperationCommision: AffectedRowsOutput;
    deleteManyOperationFees: AffectedRowsOutput;
    deleteManyOperationUpdates: AffectedRowsOutput;
    deleteManyOperationsInfo: AffectedRowsOutput;
    deleteManyParceiroComercial: AffectedRowsOutput;
    deleteManyPayableUpdates: AffectedRowsOutput;
    deleteManyPermissoesGrupos: AffectedRowsOutput;
    deleteManyPreCadastroCedente: AffectedRowsOutput;
    deleteManyRecebiveisInfo: AffectedRowsOutput;
    deleteManyRecebiveisToAnticipate: AffectedRowsOutput;
    deleteManyRecebivel: AffectedRowsOutput;
    deleteManyRecebivelAudit: AffectedRowsOutput;
    deleteManyRecebivelIntegracao: AffectedRowsOutput;
    deleteManyRecebivelOperado: AffectedRowsOutput;
    deleteManyRecebivelPagarContas: AffectedRowsOutput;
    deleteManyRepresentative: AffectedRowsOutput;
    deleteManyResponsavelCedente: AffectedRowsOutput;
    deleteManySacado: AffectedRowsOutput;
    deleteManySacadoAudit: AffectedRowsOutput;
    deleteManySacadoContato: AffectedRowsOutput;
    deleteManyStatusOperacionalAudit: AffectedRowsOutput;
    deleteManyStatusOperacionalCedenteMinibanco: AffectedRowsOutput;
    deleteManyTakeRateMinibanco: AffectedRowsOutput;
    deleteManyTaxasMinibanco: AffectedRowsOutput;
    deleteManyTermsAndConditions: AffectedRowsOutput;
    deleteManyTipoMovimentacao: AffectedRowsOutput;
    deleteManyUsuario: AffectedRowsOutput;
    deleteManyUsuarioAudit: AffectedRowsOutput;
    deleteManyUsuarioCadastro: AffectedRowsOutput;
    deleteManyUsuarioCadastroCampos: AffectedRowsOutput;
    deleteOneAcceptedTerms?: Maybe<AcceptedTerms>;
    deleteOneArquivo?: Maybe<Arquivo>;
    deleteOneArquivoAudit?: Maybe<ArquivoAudit>;
    deleteOneAssignorBankData?: Maybe<AssignorBankData>;
    deleteOneAssignorCRM?: Maybe<AssignorCrm>;
    deleteOneAssignorDocumentType?: Maybe<AssignorDocumentType>;
    deleteOneAssignorStatusByMinibank?: Maybe<AssignorStatusByMinibank>;
    deleteOneBanks?: Maybe<Banks>;
    deleteOneCTe?: Maybe<CTe>;
    deleteOneCedente?: Maybe<Cedente>;
    deleteOneCedenteAlteracao?: Maybe<CedenteAlteracao>;
    deleteOneCedenteAnexos?: Maybe<CedenteAnexos>;
    deleteOneCedenteAudit?: Maybe<CedenteAudit>;
    deleteOneCedenteCadastro?: Maybe<CedenteCadastro>;
    deleteOneCedenteCamposAlteracao?: Maybe<CedenteCamposAlteracao>;
    deleteOneCedenteCamposCadastro?: Maybe<CedenteCamposCadastro>;
    deleteOneCedenteContato?: Maybe<CedenteContato>;
    deleteOneCedentesInfo?: Maybe<CedentesInfo>;
    deleteOneCnaeClasses?: Maybe<CnaeClasses>;
    deleteOneCnaeDivisoes?: Maybe<CnaeDivisoes>;
    deleteOneCnaeGrupos?: Maybe<CnaeGrupos>;
    deleteOneCnaeSecoes?: Maybe<CnaeSecoes>;
    deleteOneCnaeSubClasses?: Maybe<CnaeSubClasses>;
    deleteOneComercialPartnerAudit?: Maybe<ComercialPartnerAudit>;
    deleteOneComercialPartnerCommission?: Maybe<ComercialPartnerCommission>;
    deleteOneContaBancaria?: Maybe<ContaBancaria>;
    deleteOneContato?: Maybe<Contato>;
    deleteOneContatoAudit?: Maybe<ContatoAudit>;
    deleteOneDebenturista?: Maybe<Debenturista>;
    deleteOneDebenturistaAudit?: Maybe<DebenturistaAudit>;
    deleteOneEmpresa?: Maybe<Empresa>;
    deleteOneEmpresaAudit?: Maybe<EmpresaAudit>;
    deleteOneEndereco?: Maybe<Endereco>;
    deleteOneEnderecoAlteracao?: Maybe<EnderecoAlteracao>;
    deleteOneEnderecoAudit?: Maybe<EnderecoAudit>;
    deleteOneEnderecoCadastro?: Maybe<EnderecoCadastro>;
    deleteOneEnderecoCamposAlteracao?: Maybe<EnderecoCamposAlteracao>;
    deleteOneEnderecoCamposCadastro?: Maybe<EnderecoCamposCadastro>;
    deleteOneEvent?: Maybe<Event>;
    deleteOneExtratoIntegracao?: Maybe<ExtratoIntegracao>;
    deleteOneGrupoNotificacao?: Maybe<GrupoNotificacao>;
    deleteOneGruposPermissao?: Maybe<GruposPermissao>;
    deleteOneMinibanco?: Maybe<Minibanco>;
    deleteOneMinibancoAudit?: Maybe<MinibancoAudit>;
    deleteOneMinibancoContato?: Maybe<MinibancoContato>;
    deleteOneMinibancoExtrato?: Maybe<MinibancoExtrato>;
    deleteOneMinibankIntegration?: Maybe<MinibankIntegration>;
    deleteOneNFe?: Maybe<NFe>;
    deleteOneNFeParcela?: Maybe<NFeParcela>;
    deleteOneNivelPermissao?: Maybe<NivelPermissao>;
    deleteOneOccupation?: Maybe<Occupation>;
    deleteOneOccupationAudit?: Maybe<OccupationAudit>;
    deleteOneOperacao?: Maybe<Operacao>;
    deleteOneOperacaoAnexos?: Maybe<OperacaoAnexos>;
    deleteOneOperacaoAudit?: Maybe<OperacaoAudit>;
    deleteOneOperacaoChecklist?: Maybe<OperacaoChecklist>;
    deleteOneOperacaoIntegracao?: Maybe<OperacaoIntegracao>;
    deleteOneOperacaoModalidade?: Maybe<OperacaoModalidade>;
    deleteOneOperacaoRascunho?: Maybe<OperacaoRascunho>;
    deleteOneOperacaoStatus?: Maybe<OperacaoStatus>;
    deleteOneOperacaoSugerida?: Maybe<OperacaoSugerida>;
    deleteOneOperacaoTipo?: Maybe<OperacaoTipo>;
    deleteOneOperacoesRascunhoView?: Maybe<OperacoesRascunhoView>;
    deleteOneOperationCommision?: Maybe<OperationCommision>;
    deleteOneOperationFees?: Maybe<OperationFees>;
    deleteOneOperationUpdates?: Maybe<OperationUpdates>;
    deleteOneOperationsInfo?: Maybe<OperationsInfo>;
    deleteOneParceiroComercial?: Maybe<ParceiroComercial>;
    deleteOnePayableUpdates?: Maybe<PayableUpdates>;
    deleteOnePermissoesGrupos?: Maybe<PermissoesGrupos>;
    deleteOnePreCadastroCedente?: Maybe<PreCadastroCedente>;
    deleteOneRecebiveisInfo?: Maybe<RecebiveisInfo>;
    deleteOneRecebiveisToAnticipate?: Maybe<RecebiveisToAnticipate>;
    deleteOneRecebivel?: Maybe<Recebivel>;
    deleteOneRecebivelAudit?: Maybe<RecebivelAudit>;
    deleteOneRecebivelIntegracao?: Maybe<RecebivelIntegracao>;
    deleteOneRecebivelOperado?: Maybe<RecebivelOperado>;
    deleteOneRecebivelPagarContas?: Maybe<RecebivelPagarContas>;
    deleteOneRepresentative?: Maybe<Representative>;
    deleteOneResponsavelCedente?: Maybe<ResponsavelCedente>;
    deleteOneSacado?: Maybe<Sacado>;
    deleteOneSacadoAudit?: Maybe<SacadoAudit>;
    deleteOneSacadoContato?: Maybe<SacadoContato>;
    deleteOneStatusOperacionalAudit?: Maybe<StatusOperacionalAudit>;
    deleteOneStatusOperacionalCedenteMinibanco?: Maybe<StatusOperacionalCedenteMinibanco>;
    deleteOneTakeRateMinibanco?: Maybe<TakeRateMinibanco>;
    deleteOneTaxasMinibanco?: Maybe<TaxasMinibanco>;
    deleteOneTermsAndConditions?: Maybe<TermsAndConditions>;
    deleteOneTipoMovimentacao?: Maybe<TipoMovimentacao>;
    deleteOneUsuario?: Maybe<Usuario>;
    deleteOneUsuarioAudit?: Maybe<UsuarioAudit>;
    deleteOneUsuarioCadastro?: Maybe<UsuarioCadastro>;
    deleteOneUsuarioCadastroCampos?: Maybe<UsuarioCadastroCampos>;
    updateManyAcceptedTerms: AffectedRowsOutput;
    updateManyArquivo: AffectedRowsOutput;
    updateManyArquivoAudit: AffectedRowsOutput;
    updateManyAssignorBankData: AffectedRowsOutput;
    updateManyAssignorCRM: AffectedRowsOutput;
    updateManyAssignorDocumentType: AffectedRowsOutput;
    updateManyAssignorStatusByMinibank: AffectedRowsOutput;
    updateManyBanks: AffectedRowsOutput;
    updateManyCTe: AffectedRowsOutput;
    updateManyCedente: AffectedRowsOutput;
    updateManyCedenteAlteracao: AffectedRowsOutput;
    updateManyCedenteAnexos: AffectedRowsOutput;
    updateManyCedenteAudit: AffectedRowsOutput;
    updateManyCedenteCadastro: AffectedRowsOutput;
    updateManyCedenteCamposAlteracao: AffectedRowsOutput;
    updateManyCedenteCamposCadastro: AffectedRowsOutput;
    updateManyCedenteContato: AffectedRowsOutput;
    updateManyCedentesInfo: AffectedRowsOutput;
    updateManyCnaeClasses: AffectedRowsOutput;
    updateManyCnaeDivisoes: AffectedRowsOutput;
    updateManyCnaeGrupos: AffectedRowsOutput;
    updateManyCnaeSecoes: AffectedRowsOutput;
    updateManyCnaeSubClasses: AffectedRowsOutput;
    updateManyComercialPartnerAudit: AffectedRowsOutput;
    updateManyComercialPartnerCommission: AffectedRowsOutput;
    updateManyContaBancaria: AffectedRowsOutput;
    updateManyContato: AffectedRowsOutput;
    updateManyContatoAudit: AffectedRowsOutput;
    updateManyDebenturista: AffectedRowsOutput;
    updateManyDebenturistaAudit: AffectedRowsOutput;
    updateManyEmpresa: AffectedRowsOutput;
    updateManyEmpresaAudit: AffectedRowsOutput;
    updateManyEndereco: AffectedRowsOutput;
    updateManyEnderecoAlteracao: AffectedRowsOutput;
    updateManyEnderecoAudit: AffectedRowsOutput;
    updateManyEnderecoCadastro: AffectedRowsOutput;
    updateManyEnderecoCamposAlteracao: AffectedRowsOutput;
    updateManyEnderecoCamposCadastro: AffectedRowsOutput;
    updateManyEvent: AffectedRowsOutput;
    updateManyExtratoIntegracao: AffectedRowsOutput;
    updateManyGrupoNotificacao: AffectedRowsOutput;
    updateManyGruposPermissao: AffectedRowsOutput;
    updateManyMinibanco: AffectedRowsOutput;
    updateManyMinibancoAudit: AffectedRowsOutput;
    updateManyMinibancoContato: AffectedRowsOutput;
    updateManyMinibancoExtrato: AffectedRowsOutput;
    updateManyMinibankIntegration: AffectedRowsOutput;
    updateManyNFe: AffectedRowsOutput;
    updateManyNFeParcela: AffectedRowsOutput;
    updateManyNivelPermissao: AffectedRowsOutput;
    updateManyOccupation: AffectedRowsOutput;
    updateManyOccupationAudit: AffectedRowsOutput;
    updateManyOperacao: AffectedRowsOutput;
    updateManyOperacaoAnexos: AffectedRowsOutput;
    updateManyOperacaoAudit: AffectedRowsOutput;
    updateManyOperacaoChecklist: AffectedRowsOutput;
    updateManyOperacaoIntegracao: AffectedRowsOutput;
    updateManyOperacaoModalidade: AffectedRowsOutput;
    updateManyOperacaoRascunho: AffectedRowsOutput;
    updateManyOperacaoStatus: AffectedRowsOutput;
    updateManyOperacaoSugerida: AffectedRowsOutput;
    updateManyOperacaoTipo: AffectedRowsOutput;
    updateManyOperacoesRascunhoView: AffectedRowsOutput;
    updateManyOperationCommision: AffectedRowsOutput;
    updateManyOperationFees: AffectedRowsOutput;
    updateManyOperationUpdates: AffectedRowsOutput;
    updateManyOperationsInfo: AffectedRowsOutput;
    updateManyParceiroComercial: AffectedRowsOutput;
    updateManyPayableUpdates: AffectedRowsOutput;
    updateManyPermissoesGrupos: AffectedRowsOutput;
    updateManyPreCadastroCedente: AffectedRowsOutput;
    updateManyRecebiveisInfo: AffectedRowsOutput;
    updateManyRecebiveisToAnticipate: AffectedRowsOutput;
    updateManyRecebivel: AffectedRowsOutput;
    updateManyRecebivelAudit: AffectedRowsOutput;
    updateManyRecebivelIntegracao: AffectedRowsOutput;
    updateManyRecebivelOperado: AffectedRowsOutput;
    updateManyRecebivelPagarContas: AffectedRowsOutput;
    updateManyRepresentative: AffectedRowsOutput;
    updateManyResponsavelCedente: AffectedRowsOutput;
    updateManySacado: AffectedRowsOutput;
    updateManySacadoAudit: AffectedRowsOutput;
    updateManySacadoContato: AffectedRowsOutput;
    updateManyStatusOperacionalAudit: AffectedRowsOutput;
    updateManyStatusOperacionalCedenteMinibanco: AffectedRowsOutput;
    updateManyTakeRateMinibanco: AffectedRowsOutput;
    updateManyTaxasMinibanco: AffectedRowsOutput;
    updateManyTermsAndConditions: AffectedRowsOutput;
    updateManyTipoMovimentacao: AffectedRowsOutput;
    updateManyUsuario: AffectedRowsOutput;
    updateManyUsuarioAudit: AffectedRowsOutput;
    updateManyUsuarioCadastro: AffectedRowsOutput;
    updateManyUsuarioCadastroCampos: AffectedRowsOutput;
    updateObsFromAttachment: Scalars["Boolean"];
    updateOneAcceptedTerms?: Maybe<AcceptedTerms>;
    updateOneArquivo?: Maybe<Arquivo>;
    updateOneArquivoAudit?: Maybe<ArquivoAudit>;
    updateOneAssignorBankData?: Maybe<AssignorBankData>;
    updateOneAssignorCRM?: Maybe<AssignorCrm>;
    updateOneAssignorDocumentType?: Maybe<AssignorDocumentType>;
    updateOneAssignorStatusByMinibank?: Maybe<AssignorStatusByMinibank>;
    updateOneBanks?: Maybe<Banks>;
    updateOneCTe?: Maybe<CTe>;
    updateOneCedente?: Maybe<Cedente>;
    updateOneCedenteAlteracao?: Maybe<CedenteAlteracao>;
    updateOneCedenteAnexos?: Maybe<CedenteAnexos>;
    updateOneCedenteAudit?: Maybe<CedenteAudit>;
    updateOneCedenteCadastro?: Maybe<CedenteCadastro>;
    updateOneCedenteCamposAlteracao?: Maybe<CedenteCamposAlteracao>;
    updateOneCedenteCamposCadastro?: Maybe<CedenteCamposCadastro>;
    updateOneCedenteContato?: Maybe<CedenteContato>;
    updateOneCedentesInfo?: Maybe<CedentesInfo>;
    updateOneCnaeClasses?: Maybe<CnaeClasses>;
    updateOneCnaeDivisoes?: Maybe<CnaeDivisoes>;
    updateOneCnaeGrupos?: Maybe<CnaeGrupos>;
    updateOneCnaeSecoes?: Maybe<CnaeSecoes>;
    updateOneCnaeSubClasses?: Maybe<CnaeSubClasses>;
    updateOneComercialPartnerAudit?: Maybe<ComercialPartnerAudit>;
    updateOneComercialPartnerCommission?: Maybe<ComercialPartnerCommission>;
    updateOneContaBancaria?: Maybe<ContaBancaria>;
    updateOneContato?: Maybe<Contato>;
    updateOneContatoAudit?: Maybe<ContatoAudit>;
    updateOneDebenturista?: Maybe<Debenturista>;
    updateOneDebenturistaAudit?: Maybe<DebenturistaAudit>;
    updateOneEmpresa?: Maybe<Empresa>;
    updateOneEmpresaAudit?: Maybe<EmpresaAudit>;
    updateOneEndereco?: Maybe<Endereco>;
    updateOneEnderecoAlteracao?: Maybe<EnderecoAlteracao>;
    updateOneEnderecoAudit?: Maybe<EnderecoAudit>;
    updateOneEnderecoCadastro?: Maybe<EnderecoCadastro>;
    updateOneEnderecoCamposAlteracao?: Maybe<EnderecoCamposAlteracao>;
    updateOneEnderecoCamposCadastro?: Maybe<EnderecoCamposCadastro>;
    updateOneEvent?: Maybe<Event>;
    updateOneExtratoIntegracao?: Maybe<ExtratoIntegracao>;
    updateOneGrupoNotificacao?: Maybe<GrupoNotificacao>;
    updateOneGruposPermissao?: Maybe<GruposPermissao>;
    updateOneMinibanco?: Maybe<Minibanco>;
    updateOneMinibancoAudit?: Maybe<MinibancoAudit>;
    updateOneMinibancoContato?: Maybe<MinibancoContato>;
    updateOneMinibancoExtrato?: Maybe<MinibancoExtrato>;
    updateOneMinibankIntegration?: Maybe<MinibankIntegration>;
    updateOneNFe?: Maybe<NFe>;
    updateOneNFeParcela?: Maybe<NFeParcela>;
    updateOneNivelPermissao?: Maybe<NivelPermissao>;
    updateOneOccupation?: Maybe<Occupation>;
    updateOneOccupationAudit?: Maybe<OccupationAudit>;
    updateOneOperacao?: Maybe<Operacao>;
    updateOneOperacaoAnexos?: Maybe<OperacaoAnexos>;
    updateOneOperacaoAudit?: Maybe<OperacaoAudit>;
    updateOneOperacaoChecklist?: Maybe<OperacaoChecklist>;
    updateOneOperacaoIntegracao?: Maybe<OperacaoIntegracao>;
    updateOneOperacaoModalidade?: Maybe<OperacaoModalidade>;
    updateOneOperacaoRascunho?: Maybe<OperacaoRascunho>;
    updateOneOperacaoStatus?: Maybe<OperacaoStatus>;
    updateOneOperacaoSugerida?: Maybe<OperacaoSugerida>;
    updateOneOperacaoTipo?: Maybe<OperacaoTipo>;
    updateOneOperacoesRascunhoView?: Maybe<OperacoesRascunhoView>;
    updateOneOperationCommision?: Maybe<OperationCommision>;
    updateOneOperationFees?: Maybe<OperationFees>;
    updateOneOperationUpdates?: Maybe<OperationUpdates>;
    updateOneOperationsInfo?: Maybe<OperationsInfo>;
    updateOneParceiroComercial?: Maybe<ParceiroComercial>;
    updateOnePayableUpdates?: Maybe<PayableUpdates>;
    updateOnePermissoesGrupos?: Maybe<PermissoesGrupos>;
    updateOnePreCadastroCedente?: Maybe<PreCadastroCedente>;
    updateOneRecebiveisInfo?: Maybe<RecebiveisInfo>;
    updateOneRecebiveisToAnticipate?: Maybe<RecebiveisToAnticipate>;
    updateOneRecebivel?: Maybe<Recebivel>;
    updateOneRecebivelAudit?: Maybe<RecebivelAudit>;
    updateOneRecebivelIntegracao?: Maybe<RecebivelIntegracao>;
    updateOneRecebivelOperado?: Maybe<RecebivelOperado>;
    updateOneRecebivelPagarContas?: Maybe<RecebivelPagarContas>;
    updateOneRepresentative?: Maybe<Representative>;
    updateOneResponsavelCedente?: Maybe<ResponsavelCedente>;
    updateOneSacado?: Maybe<Sacado>;
    updateOneSacadoAudit?: Maybe<SacadoAudit>;
    updateOneSacadoContato?: Maybe<SacadoContato>;
    updateOneStatusOperacionalAudit?: Maybe<StatusOperacionalAudit>;
    updateOneStatusOperacionalCedenteMinibanco?: Maybe<StatusOperacionalCedenteMinibanco>;
    updateOneTakeRateMinibanco?: Maybe<TakeRateMinibanco>;
    updateOneTaxasMinibanco?: Maybe<TaxasMinibanco>;
    updateOneTermsAndConditions?: Maybe<TermsAndConditions>;
    updateOneTipoMovimentacao?: Maybe<TipoMovimentacao>;
    updateOneUsuario?: Maybe<Usuario>;
    updateOneUsuarioAudit?: Maybe<UsuarioAudit>;
    updateOneUsuarioCadastro?: Maybe<UsuarioCadastro>;
    updateOneUsuarioCadastroCampos?: Maybe<UsuarioCadastroCampos>;
    upsertOneAcceptedTerms: AcceptedTerms;
    upsertOneArquivo: Arquivo;
    upsertOneArquivoAudit: ArquivoAudit;
    upsertOneAssignorBankData: AssignorBankData;
    upsertOneAssignorCRM: AssignorCrm;
    upsertOneAssignorDocumentType: AssignorDocumentType;
    upsertOneAssignorStatusByMinibank: AssignorStatusByMinibank;
    upsertOneBanks: Banks;
    upsertOneCTe: CTe;
    upsertOneCedente: Cedente;
    upsertOneCedenteAlteracao: CedenteAlteracao;
    upsertOneCedenteAnexos: CedenteAnexos;
    upsertOneCedenteAudit: CedenteAudit;
    upsertOneCedenteCadastro: CedenteCadastro;
    upsertOneCedenteCamposAlteracao: CedenteCamposAlteracao;
    upsertOneCedenteCamposCadastro: CedenteCamposCadastro;
    upsertOneCedenteContato: CedenteContato;
    upsertOneCedentesInfo: CedentesInfo;
    upsertOneCnaeClasses: CnaeClasses;
    upsertOneCnaeDivisoes: CnaeDivisoes;
    upsertOneCnaeGrupos: CnaeGrupos;
    upsertOneCnaeSecoes: CnaeSecoes;
    upsertOneCnaeSubClasses: CnaeSubClasses;
    upsertOneComercialPartnerAudit: ComercialPartnerAudit;
    upsertOneComercialPartnerCommission: ComercialPartnerCommission;
    upsertOneContaBancaria: ContaBancaria;
    upsertOneContato: Contato;
    upsertOneContatoAudit: ContatoAudit;
    upsertOneDebenturista: Debenturista;
    upsertOneDebenturistaAudit: DebenturistaAudit;
    upsertOneEmpresa: Empresa;
    upsertOneEmpresaAudit: EmpresaAudit;
    upsertOneEndereco: Endereco;
    upsertOneEnderecoAlteracao: EnderecoAlteracao;
    upsertOneEnderecoAudit: EnderecoAudit;
    upsertOneEnderecoCadastro: EnderecoCadastro;
    upsertOneEnderecoCamposAlteracao: EnderecoCamposAlteracao;
    upsertOneEnderecoCamposCadastro: EnderecoCamposCadastro;
    upsertOneEvent: Event;
    upsertOneExtratoIntegracao: ExtratoIntegracao;
    upsertOneGrupoNotificacao: GrupoNotificacao;
    upsertOneGruposPermissao: GruposPermissao;
    upsertOneMinibanco: Minibanco;
    upsertOneMinibancoAudit: MinibancoAudit;
    upsertOneMinibancoContato: MinibancoContato;
    upsertOneMinibancoExtrato: MinibancoExtrato;
    upsertOneMinibankIntegration: MinibankIntegration;
    upsertOneNFe: NFe;
    upsertOneNFeParcela: NFeParcela;
    upsertOneNivelPermissao: NivelPermissao;
    upsertOneOccupation: Occupation;
    upsertOneOccupationAudit: OccupationAudit;
    upsertOneOperacao: Operacao;
    upsertOneOperacaoAnexos: OperacaoAnexos;
    upsertOneOperacaoAudit: OperacaoAudit;
    upsertOneOperacaoChecklist: OperacaoChecklist;
    upsertOneOperacaoIntegracao: OperacaoIntegracao;
    upsertOneOperacaoModalidade: OperacaoModalidade;
    upsertOneOperacaoRascunho: OperacaoRascunho;
    upsertOneOperacaoStatus: OperacaoStatus;
    upsertOneOperacaoSugerida: OperacaoSugerida;
    upsertOneOperacaoTipo: OperacaoTipo;
    upsertOneOperacoesRascunhoView: OperacoesRascunhoView;
    upsertOneOperationCommision: OperationCommision;
    upsertOneOperationFees: OperationFees;
    upsertOneOperationUpdates: OperationUpdates;
    upsertOneOperationsInfo: OperationsInfo;
    upsertOneParceiroComercial: ParceiroComercial;
    upsertOnePayableUpdates: PayableUpdates;
    upsertOnePermissoesGrupos: PermissoesGrupos;
    upsertOnePreCadastroCedente: PreCadastroCedente;
    upsertOneRecebiveisInfo: RecebiveisInfo;
    upsertOneRecebiveisToAnticipate: RecebiveisToAnticipate;
    upsertOneRecebivel: Recebivel;
    upsertOneRecebivelAudit: RecebivelAudit;
    upsertOneRecebivelIntegracao: RecebivelIntegracao;
    upsertOneRecebivelOperado: RecebivelOperado;
    upsertOneRecebivelPagarContas: RecebivelPagarContas;
    upsertOneRepresentative: Representative;
    upsertOneResponsavelCedente: ResponsavelCedente;
    upsertOneSacado: Sacado;
    upsertOneSacadoAudit: SacadoAudit;
    upsertOneSacadoContato: SacadoContato;
    upsertOneStatusOperacionalAudit: StatusOperacionalAudit;
    upsertOneStatusOperacionalCedenteMinibanco: StatusOperacionalCedenteMinibanco;
    upsertOneTakeRateMinibanco: TakeRateMinibanco;
    upsertOneTaxasMinibanco: TaxasMinibanco;
    upsertOneTermsAndConditions: TermsAndConditions;
    upsertOneTipoMovimentacao: TipoMovimentacao;
    upsertOneUsuario: Usuario;
    upsertOneUsuarioAudit: UsuarioAudit;
    upsertOneUsuarioCadastro: UsuarioCadastro;
    upsertOneUsuarioCadastroCampos: UsuarioCadastroCampos;
};

export type MutationCreateManyAcceptedTermsArgs = {
    data: Array<AcceptedTermsCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyArquivoArgs = {
    data: Array<ArquivoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyArquivoAuditArgs = {
    data: Array<ArquivoAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyAssignorBankDataArgs = {
    data: Array<AssignorBankDataCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyAssignorCrmArgs = {
    data: Array<AssignorCrmCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyAssignorDocumentTypeArgs = {
    data: Array<AssignorDocumentTypeCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyAssignorStatusByMinibankArgs = {
    data: Array<AssignorStatusByMinibankCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyBanksArgs = {
    data: Array<BanksCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCTeArgs = {
    data: Array<CTeCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCedenteArgs = {
    data: Array<CedenteCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCedenteAlteracaoArgs = {
    data: Array<CedenteAlteracaoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCedenteAnexosArgs = {
    data: Array<CedenteAnexosCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCedenteAuditArgs = {
    data: Array<CedenteAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCedenteCadastroArgs = {
    data: Array<CedenteCadastroCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCedenteCamposAlteracaoArgs = {
    data: Array<CedenteCamposAlteracaoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCedenteCamposCadastroArgs = {
    data: Array<CedenteCamposCadastroCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCedenteContatoArgs = {
    data: Array<CedenteContatoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCedentesInfoArgs = {
    data: Array<CedentesInfoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCnaeClassesArgs = {
    data: Array<CnaeClassesCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCnaeDivisoesArgs = {
    data: Array<CnaeDivisoesCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCnaeGruposArgs = {
    data: Array<CnaeGruposCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCnaeSecoesArgs = {
    data: Array<CnaeSecoesCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyCnaeSubClassesArgs = {
    data: Array<CnaeSubClassesCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyComercialPartnerAuditArgs = {
    data: Array<ComercialPartnerAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyComercialPartnerCommissionArgs = {
    data: Array<ComercialPartnerCommissionCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyContaBancariaArgs = {
    data: Array<ContaBancariaCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyContatoArgs = {
    data: Array<ContatoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyContatoAuditArgs = {
    data: Array<ContatoAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyDebenturistaArgs = {
    data: Array<DebenturistaCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyDebenturistaAuditArgs = {
    data: Array<DebenturistaAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyEmpresaArgs = {
    data: Array<EmpresaCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyEmpresaAuditArgs = {
    data: Array<EmpresaAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyEnderecoArgs = {
    data: Array<EnderecoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyEnderecoAlteracaoArgs = {
    data: Array<EnderecoAlteracaoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyEnderecoAuditArgs = {
    data: Array<EnderecoAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyEnderecoCadastroArgs = {
    data: Array<EnderecoCadastroCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyEnderecoCamposAlteracaoArgs = {
    data: Array<EnderecoCamposAlteracaoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyEnderecoCamposCadastroArgs = {
    data: Array<EnderecoCamposCadastroCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyEventArgs = {
    data: Array<EventCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyExtratoIntegracaoArgs = {
    data: Array<ExtratoIntegracaoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyGrupoNotificacaoArgs = {
    data: Array<GrupoNotificacaoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyGruposPermissaoArgs = {
    data: Array<GruposPermissaoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyMinibancoArgs = {
    data: Array<MinibancoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyMinibancoAuditArgs = {
    data: Array<MinibancoAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyMinibancoContatoArgs = {
    data: Array<MinibancoContatoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyMinibancoExtratoArgs = {
    data: Array<MinibancoExtratoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyMinibankIntegrationArgs = {
    data: Array<MinibankIntegrationCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyNFeArgs = {
    data: Array<NFeCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyNFeParcelaArgs = {
    data: Array<NFeParcelaCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyNivelPermissaoArgs = {
    data: Array<NivelPermissaoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOccupationArgs = {
    data: Array<OccupationCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOccupationAuditArgs = {
    data: Array<OccupationAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperacaoArgs = {
    data: Array<OperacaoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperacaoAnexosArgs = {
    data: Array<OperacaoAnexosCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperacaoAuditArgs = {
    data: Array<OperacaoAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperacaoChecklistArgs = {
    data: Array<OperacaoChecklistCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperacaoIntegracaoArgs = {
    data: Array<OperacaoIntegracaoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperacaoModalidadeArgs = {
    data: Array<OperacaoModalidadeCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperacaoRascunhoArgs = {
    data: Array<OperacaoRascunhoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperacaoStatusArgs = {
    data: Array<OperacaoStatusCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperacaoSugeridaArgs = {
    data: Array<OperacaoSugeridaCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperacaoTipoArgs = {
    data: Array<OperacaoTipoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperacoesRascunhoViewArgs = {
    data: Array<OperacoesRascunhoViewCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperationCommisionArgs = {
    data: Array<OperationCommisionCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperationFeesArgs = {
    data: Array<OperationFeesCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperationUpdatesArgs = {
    data: Array<OperationUpdatesCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyOperationsInfoArgs = {
    data: Array<OperationsInfoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyParceiroComercialArgs = {
    data: Array<ParceiroComercialCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyPayableUpdatesArgs = {
    data: Array<PayableUpdatesCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyPermissoesGruposArgs = {
    data: Array<PermissoesGruposCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyPreCadastroCedenteArgs = {
    data: Array<PreCadastroCedenteCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyRecebiveisInfoArgs = {
    data: Array<RecebiveisInfoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyRecebiveisToAnticipateArgs = {
    data: Array<RecebiveisToAnticipateCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyRecebivelArgs = {
    data: Array<RecebivelCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyRecebivelAuditArgs = {
    data: Array<RecebivelAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyRecebivelIntegracaoArgs = {
    data: Array<RecebivelIntegracaoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyRecebivelOperadoArgs = {
    data: Array<RecebivelOperadoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyRecebivelPagarContasArgs = {
    data: Array<RecebivelPagarContasCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyRepresentativeArgs = {
    data: Array<RepresentativeCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyResponsavelCedenteArgs = {
    data: Array<ResponsavelCedenteCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManySacadoArgs = {
    data: Array<SacadoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManySacadoAuditArgs = {
    data: Array<SacadoAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManySacadoContatoArgs = {
    data: Array<SacadoContatoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyStatusOperacionalAuditArgs = {
    data: Array<StatusOperacionalAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyStatusOperacionalCedenteMinibancoArgs = {
    data: Array<StatusOperacionalCedenteMinibancoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyTakeRateMinibancoArgs = {
    data: Array<TakeRateMinibancoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyTaxasMinibancoArgs = {
    data: Array<TaxasMinibancoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyTermsAndConditionsArgs = {
    data: Array<TermsAndConditionsCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyTipoMovimentacaoArgs = {
    data: Array<TipoMovimentacaoCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyUsuarioArgs = {
    data: Array<UsuarioCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyUsuarioAuditArgs = {
    data: Array<UsuarioAuditCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyUsuarioCadastroArgs = {
    data: Array<UsuarioCadastroCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateManyUsuarioCadastroCamposArgs = {
    data: Array<UsuarioCadastroCamposCreateManyInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationCreateOneAcceptedTermsArgs = {
    data: AcceptedTermsCreateInput;
};

export type MutationCreateOneArquivoArgs = {
    data: ArquivoCreateInput;
};

export type MutationCreateOneArquivoAuditArgs = {
    data: ArquivoAuditCreateInput;
};

export type MutationCreateOneAssignorBankDataArgs = {
    data: AssignorBankDataCreateInput;
};

export type MutationCreateOneAssignorCrmArgs = {
    data: AssignorCrmCreateInput;
};

export type MutationCreateOneAssignorDocumentTypeArgs = {
    data: AssignorDocumentTypeCreateInput;
};

export type MutationCreateOneAssignorStatusByMinibankArgs = {
    data: AssignorStatusByMinibankCreateInput;
};

export type MutationCreateOneBanksArgs = {
    data: BanksCreateInput;
};

export type MutationCreateOneCTeArgs = {
    data: CTeCreateInput;
};

export type MutationCreateOneCedenteArgs = {
    data: CedenteCreateInput;
};

export type MutationCreateOneCedenteAlteracaoArgs = {
    data: CedenteAlteracaoCreateInput;
};

export type MutationCreateOneCedenteAnexosArgs = {
    data: CedenteAnexosCreateInput;
};

export type MutationCreateOneCedenteAuditArgs = {
    data: CedenteAuditCreateInput;
};

export type MutationCreateOneCedenteCadastroArgs = {
    data: CedenteCadastroCreateInput;
};

export type MutationCreateOneCedenteCamposAlteracaoArgs = {
    data: CedenteCamposAlteracaoCreateInput;
};

export type MutationCreateOneCedenteCamposCadastroArgs = {
    data: CedenteCamposCadastroCreateInput;
};

export type MutationCreateOneCedenteContatoArgs = {
    data: CedenteContatoCreateInput;
};

export type MutationCreateOneCedentesInfoArgs = {
    data: CedentesInfoCreateInput;
};

export type MutationCreateOneCnaeClassesArgs = {
    data: CnaeClassesCreateInput;
};

export type MutationCreateOneCnaeDivisoesArgs = {
    data: CnaeDivisoesCreateInput;
};

export type MutationCreateOneCnaeGruposArgs = {
    data: CnaeGruposCreateInput;
};

export type MutationCreateOneCnaeSecoesArgs = {
    data: CnaeSecoesCreateInput;
};

export type MutationCreateOneCnaeSubClassesArgs = {
    data: CnaeSubClassesCreateInput;
};

export type MutationCreateOneComercialPartnerAuditArgs = {
    data: ComercialPartnerAuditCreateInput;
};

export type MutationCreateOneComercialPartnerCommissionArgs = {
    data: ComercialPartnerCommissionCreateInput;
};

export type MutationCreateOneContaBancariaArgs = {
    data: ContaBancariaCreateInput;
};

export type MutationCreateOneContatoArgs = {
    data: ContatoCreateInput;
};

export type MutationCreateOneContatoAuditArgs = {
    data: ContatoAuditCreateInput;
};

export type MutationCreateOneDebenturistaArgs = {
    data: DebenturistaCreateInput;
};

export type MutationCreateOneDebenturistaAuditArgs = {
    data: DebenturistaAuditCreateInput;
};

export type MutationCreateOneEmpresaArgs = {
    data: EmpresaCreateInput;
};

export type MutationCreateOneEmpresaAuditArgs = {
    data: EmpresaAuditCreateInput;
};

export type MutationCreateOneEnderecoArgs = {
    data: EnderecoCreateInput;
};

export type MutationCreateOneEnderecoAlteracaoArgs = {
    data: EnderecoAlteracaoCreateInput;
};

export type MutationCreateOneEnderecoAuditArgs = {
    data: EnderecoAuditCreateInput;
};

export type MutationCreateOneEnderecoCadastroArgs = {
    data: EnderecoCadastroCreateInput;
};

export type MutationCreateOneEnderecoCamposAlteracaoArgs = {
    data: EnderecoCamposAlteracaoCreateInput;
};

export type MutationCreateOneEnderecoCamposCadastroArgs = {
    data: EnderecoCamposCadastroCreateInput;
};

export type MutationCreateOneEventArgs = {
    data: EventCreateInput;
};

export type MutationCreateOneExtratoIntegracaoArgs = {
    data: ExtratoIntegracaoCreateInput;
};

export type MutationCreateOneGrupoNotificacaoArgs = {
    data: GrupoNotificacaoCreateInput;
};

export type MutationCreateOneGruposPermissaoArgs = {
    data: GruposPermissaoCreateInput;
};

export type MutationCreateOneMinibancoArgs = {
    data: MinibancoCreateInput;
};

export type MutationCreateOneMinibancoAuditArgs = {
    data: MinibancoAuditCreateInput;
};

export type MutationCreateOneMinibancoContatoArgs = {
    data: MinibancoContatoCreateInput;
};

export type MutationCreateOneMinibancoExtratoArgs = {
    data: MinibancoExtratoCreateInput;
};

export type MutationCreateOneMinibankIntegrationArgs = {
    data: MinibankIntegrationCreateInput;
};

export type MutationCreateOneNFeArgs = {
    data: NFeCreateInput;
};

export type MutationCreateOneNFeParcelaArgs = {
    data: NFeParcelaCreateInput;
};

export type MutationCreateOneNivelPermissaoArgs = {
    data: NivelPermissaoCreateInput;
};

export type MutationCreateOneOccupationArgs = {
    data: OccupationCreateInput;
};

export type MutationCreateOneOccupationAuditArgs = {
    data: OccupationAuditCreateInput;
};

export type MutationCreateOneOperacaoArgs = {
    data: OperacaoCreateInput;
};

export type MutationCreateOneOperacaoAnexosArgs = {
    data: OperacaoAnexosCreateInput;
};

export type MutationCreateOneOperacaoAuditArgs = {
    data: OperacaoAuditCreateInput;
};

export type MutationCreateOneOperacaoChecklistArgs = {
    data: OperacaoChecklistCreateInput;
};

export type MutationCreateOneOperacaoIntegracaoArgs = {
    data: OperacaoIntegracaoCreateInput;
};

export type MutationCreateOneOperacaoModalidadeArgs = {
    data: OperacaoModalidadeCreateInput;
};

export type MutationCreateOneOperacaoRascunhoArgs = {
    data: OperacaoRascunhoCreateInput;
};

export type MutationCreateOneOperacaoStatusArgs = {
    data: OperacaoStatusCreateInput;
};

export type MutationCreateOneOperacaoSugeridaArgs = {
    data: OperacaoSugeridaCreateInput;
};

export type MutationCreateOneOperacaoTipoArgs = {
    data: OperacaoTipoCreateInput;
};

export type MutationCreateOneOperacoesRascunhoViewArgs = {
    data: OperacoesRascunhoViewCreateInput;
};

export type MutationCreateOneOperationCommisionArgs = {
    data: OperationCommisionCreateInput;
};

export type MutationCreateOneOperationFeesArgs = {
    data: OperationFeesCreateInput;
};

export type MutationCreateOneOperationUpdatesArgs = {
    data: OperationUpdatesCreateInput;
};

export type MutationCreateOneOperationsInfoArgs = {
    data: OperationsInfoCreateInput;
};

export type MutationCreateOneParceiroComercialArgs = {
    data: ParceiroComercialCreateInput;
};

export type MutationCreateOnePayableUpdatesArgs = {
    data: PayableUpdatesCreateInput;
};

export type MutationCreateOnePermissoesGruposArgs = {
    data: PermissoesGruposCreateInput;
};

export type MutationCreateOnePreCadastroCedenteArgs = {
    data: PreCadastroCedenteCreateInput;
};

export type MutationCreateOneRecebiveisInfoArgs = {
    data: RecebiveisInfoCreateInput;
};

export type MutationCreateOneRecebiveisToAnticipateArgs = {
    data: RecebiveisToAnticipateCreateInput;
};

export type MutationCreateOneRecebivelArgs = {
    data: RecebivelCreateInput;
};

export type MutationCreateOneRecebivelAuditArgs = {
    data: RecebivelAuditCreateInput;
};

export type MutationCreateOneRecebivelIntegracaoArgs = {
    data: RecebivelIntegracaoCreateInput;
};

export type MutationCreateOneRecebivelOperadoArgs = {
    data: RecebivelOperadoCreateInput;
};

export type MutationCreateOneRecebivelPagarContasArgs = {
    data: RecebivelPagarContasCreateInput;
};

export type MutationCreateOneRepresentativeArgs = {
    data: RepresentativeCreateInput;
};

export type MutationCreateOneResponsavelCedenteArgs = {
    data: ResponsavelCedenteCreateInput;
};

export type MutationCreateOneSacadoArgs = {
    data: SacadoCreateInput;
};

export type MutationCreateOneSacadoAuditArgs = {
    data: SacadoAuditCreateInput;
};

export type MutationCreateOneSacadoContatoArgs = {
    data: SacadoContatoCreateInput;
};

export type MutationCreateOneStatusOperacionalAuditArgs = {
    data: StatusOperacionalAuditCreateInput;
};

export type MutationCreateOneStatusOperacionalCedenteMinibancoArgs = {
    data: StatusOperacionalCedenteMinibancoCreateInput;
};

export type MutationCreateOneTakeRateMinibancoArgs = {
    data: TakeRateMinibancoCreateInput;
};

export type MutationCreateOneTaxasMinibancoArgs = {
    data: TaxasMinibancoCreateInput;
};

export type MutationCreateOneTermsAndConditionsArgs = {
    data: TermsAndConditionsCreateInput;
};

export type MutationCreateOneTipoMovimentacaoArgs = {
    data: TipoMovimentacaoCreateInput;
};

export type MutationCreateOneUsuarioArgs = {
    data: UsuarioCreateInput;
};

export type MutationCreateOneUsuarioAuditArgs = {
    data: UsuarioAuditCreateInput;
};

export type MutationCreateOneUsuarioCadastroArgs = {
    data: UsuarioCadastroCreateInput;
};

export type MutationCreateOneUsuarioCadastroCamposArgs = {
    data: UsuarioCadastroCamposCreateInput;
};

export type MutationDeleteFileFromOperationArgs = {
    id: Scalars["Float"];
};

export type MutationDeleteManyAcceptedTermsArgs = {
    where?: InputMaybe<AcceptedTermsWhereInput>;
};

export type MutationDeleteManyArquivoArgs = {
    where?: InputMaybe<ArquivoWhereInput>;
};

export type MutationDeleteManyArquivoAuditArgs = {
    where?: InputMaybe<ArquivoAuditWhereInput>;
};

export type MutationDeleteManyAssignorBankDataArgs = {
    where?: InputMaybe<AssignorBankDataWhereInput>;
};

export type MutationDeleteManyAssignorCrmArgs = {
    where?: InputMaybe<AssignorCrmWhereInput>;
};

export type MutationDeleteManyAssignorDocumentTypeArgs = {
    where?: InputMaybe<AssignorDocumentTypeWhereInput>;
};

export type MutationDeleteManyAssignorStatusByMinibankArgs = {
    where?: InputMaybe<AssignorStatusByMinibankWhereInput>;
};

export type MutationDeleteManyBanksArgs = {
    where?: InputMaybe<BanksWhereInput>;
};

export type MutationDeleteManyCTeArgs = {
    where?: InputMaybe<CTeWhereInput>;
};

export type MutationDeleteManyCedenteArgs = {
    where?: InputMaybe<CedenteWhereInput>;
};

export type MutationDeleteManyCedenteAlteracaoArgs = {
    where?: InputMaybe<CedenteAlteracaoWhereInput>;
};

export type MutationDeleteManyCedenteAnexosArgs = {
    where?: InputMaybe<CedenteAnexosWhereInput>;
};

export type MutationDeleteManyCedenteAuditArgs = {
    where?: InputMaybe<CedenteAuditWhereInput>;
};

export type MutationDeleteManyCedenteCadastroArgs = {
    where?: InputMaybe<CedenteCadastroWhereInput>;
};

export type MutationDeleteManyCedenteCamposAlteracaoArgs = {
    where?: InputMaybe<CedenteCamposAlteracaoWhereInput>;
};

export type MutationDeleteManyCedenteCamposCadastroArgs = {
    where?: InputMaybe<CedenteCamposCadastroWhereInput>;
};

export type MutationDeleteManyCedenteContatoArgs = {
    where?: InputMaybe<CedenteContatoWhereInput>;
};

export type MutationDeleteManyCedentesInfoArgs = {
    where?: InputMaybe<CedentesInfoWhereInput>;
};

export type MutationDeleteManyCnaeClassesArgs = {
    where?: InputMaybe<CnaeClassesWhereInput>;
};

export type MutationDeleteManyCnaeDivisoesArgs = {
    where?: InputMaybe<CnaeDivisoesWhereInput>;
};

export type MutationDeleteManyCnaeGruposArgs = {
    where?: InputMaybe<CnaeGruposWhereInput>;
};

export type MutationDeleteManyCnaeSecoesArgs = {
    where?: InputMaybe<CnaeSecoesWhereInput>;
};

export type MutationDeleteManyCnaeSubClassesArgs = {
    where?: InputMaybe<CnaeSubClassesWhereInput>;
};

export type MutationDeleteManyComercialPartnerAuditArgs = {
    where?: InputMaybe<ComercialPartnerAuditWhereInput>;
};

export type MutationDeleteManyComercialPartnerCommissionArgs = {
    where?: InputMaybe<ComercialPartnerCommissionWhereInput>;
};

export type MutationDeleteManyContaBancariaArgs = {
    where?: InputMaybe<ContaBancariaWhereInput>;
};

export type MutationDeleteManyContatoArgs = {
    where?: InputMaybe<ContatoWhereInput>;
};

export type MutationDeleteManyContatoAuditArgs = {
    where?: InputMaybe<ContatoAuditWhereInput>;
};

export type MutationDeleteManyDebenturistaArgs = {
    where?: InputMaybe<DebenturistaWhereInput>;
};

export type MutationDeleteManyDebenturistaAuditArgs = {
    where?: InputMaybe<DebenturistaAuditWhereInput>;
};

export type MutationDeleteManyEmpresaArgs = {
    where?: InputMaybe<EmpresaWhereInput>;
};

export type MutationDeleteManyEmpresaAuditArgs = {
    where?: InputMaybe<EmpresaAuditWhereInput>;
};

export type MutationDeleteManyEnderecoArgs = {
    where?: InputMaybe<EnderecoWhereInput>;
};

export type MutationDeleteManyEnderecoAlteracaoArgs = {
    where?: InputMaybe<EnderecoAlteracaoWhereInput>;
};

export type MutationDeleteManyEnderecoAuditArgs = {
    where?: InputMaybe<EnderecoAuditWhereInput>;
};

export type MutationDeleteManyEnderecoCadastroArgs = {
    where?: InputMaybe<EnderecoCadastroWhereInput>;
};

export type MutationDeleteManyEnderecoCamposAlteracaoArgs = {
    where?: InputMaybe<EnderecoCamposAlteracaoWhereInput>;
};

export type MutationDeleteManyEnderecoCamposCadastroArgs = {
    where?: InputMaybe<EnderecoCamposCadastroWhereInput>;
};

export type MutationDeleteManyEventArgs = {
    where?: InputMaybe<EventWhereInput>;
};

export type MutationDeleteManyExtratoIntegracaoArgs = {
    where?: InputMaybe<ExtratoIntegracaoWhereInput>;
};

export type MutationDeleteManyGrupoNotificacaoArgs = {
    where?: InputMaybe<GrupoNotificacaoWhereInput>;
};

export type MutationDeleteManyGruposPermissaoArgs = {
    where?: InputMaybe<GruposPermissaoWhereInput>;
};

export type MutationDeleteManyMinibancoArgs = {
    where?: InputMaybe<MinibancoWhereInput>;
};

export type MutationDeleteManyMinibancoAuditArgs = {
    where?: InputMaybe<MinibancoAuditWhereInput>;
};

export type MutationDeleteManyMinibancoContatoArgs = {
    where?: InputMaybe<MinibancoContatoWhereInput>;
};

export type MutationDeleteManyMinibancoExtratoArgs = {
    where?: InputMaybe<MinibancoExtratoWhereInput>;
};

export type MutationDeleteManyMinibankIntegrationArgs = {
    where?: InputMaybe<MinibankIntegrationWhereInput>;
};

export type MutationDeleteManyNFeArgs = {
    where?: InputMaybe<NFeWhereInput>;
};

export type MutationDeleteManyNFeParcelaArgs = {
    where?: InputMaybe<NFeParcelaWhereInput>;
};

export type MutationDeleteManyNivelPermissaoArgs = {
    where?: InputMaybe<NivelPermissaoWhereInput>;
};

export type MutationDeleteManyOccupationArgs = {
    where?: InputMaybe<OccupationWhereInput>;
};

export type MutationDeleteManyOccupationAuditArgs = {
    where?: InputMaybe<OccupationAuditWhereInput>;
};

export type MutationDeleteManyOperacaoArgs = {
    where?: InputMaybe<OperacaoWhereInput>;
};

export type MutationDeleteManyOperacaoAnexosArgs = {
    where?: InputMaybe<OperacaoAnexosWhereInput>;
};

export type MutationDeleteManyOperacaoAuditArgs = {
    where?: InputMaybe<OperacaoAuditWhereInput>;
};

export type MutationDeleteManyOperacaoChecklistArgs = {
    where?: InputMaybe<OperacaoChecklistWhereInput>;
};

export type MutationDeleteManyOperacaoIntegracaoArgs = {
    where?: InputMaybe<OperacaoIntegracaoWhereInput>;
};

export type MutationDeleteManyOperacaoModalidadeArgs = {
    where?: InputMaybe<OperacaoModalidadeWhereInput>;
};

export type MutationDeleteManyOperacaoRascunhoArgs = {
    where?: InputMaybe<OperacaoRascunhoWhereInput>;
};

export type MutationDeleteManyOperacaoStatusArgs = {
    where?: InputMaybe<OperacaoStatusWhereInput>;
};

export type MutationDeleteManyOperacaoSugeridaArgs = {
    where?: InputMaybe<OperacaoSugeridaWhereInput>;
};

export type MutationDeleteManyOperacaoTipoArgs = {
    where?: InputMaybe<OperacaoTipoWhereInput>;
};

export type MutationDeleteManyOperacoesRascunhoViewArgs = {
    where?: InputMaybe<OperacoesRascunhoViewWhereInput>;
};

export type MutationDeleteManyOperationCommisionArgs = {
    where?: InputMaybe<OperationCommisionWhereInput>;
};

export type MutationDeleteManyOperationFeesArgs = {
    where?: InputMaybe<OperationFeesWhereInput>;
};

export type MutationDeleteManyOperationUpdatesArgs = {
    where?: InputMaybe<OperationUpdatesWhereInput>;
};

export type MutationDeleteManyOperationsInfoArgs = {
    where?: InputMaybe<OperationsInfoWhereInput>;
};

export type MutationDeleteManyParceiroComercialArgs = {
    where?: InputMaybe<ParceiroComercialWhereInput>;
};

export type MutationDeleteManyPayableUpdatesArgs = {
    where?: InputMaybe<PayableUpdatesWhereInput>;
};

export type MutationDeleteManyPermissoesGruposArgs = {
    where?: InputMaybe<PermissoesGruposWhereInput>;
};

export type MutationDeleteManyPreCadastroCedenteArgs = {
    where?: InputMaybe<PreCadastroCedenteWhereInput>;
};

export type MutationDeleteManyRecebiveisInfoArgs = {
    where?: InputMaybe<RecebiveisInfoWhereInput>;
};

export type MutationDeleteManyRecebiveisToAnticipateArgs = {
    where?: InputMaybe<RecebiveisToAnticipateWhereInput>;
};

export type MutationDeleteManyRecebivelArgs = {
    where?: InputMaybe<RecebivelWhereInput>;
};

export type MutationDeleteManyRecebivelAuditArgs = {
    where?: InputMaybe<RecebivelAuditWhereInput>;
};

export type MutationDeleteManyRecebivelIntegracaoArgs = {
    where?: InputMaybe<RecebivelIntegracaoWhereInput>;
};

export type MutationDeleteManyRecebivelOperadoArgs = {
    where?: InputMaybe<RecebivelOperadoWhereInput>;
};

export type MutationDeleteManyRecebivelPagarContasArgs = {
    where?: InputMaybe<RecebivelPagarContasWhereInput>;
};

export type MutationDeleteManyRepresentativeArgs = {
    where?: InputMaybe<RepresentativeWhereInput>;
};

export type MutationDeleteManyResponsavelCedenteArgs = {
    where?: InputMaybe<ResponsavelCedenteWhereInput>;
};

export type MutationDeleteManySacadoArgs = {
    where?: InputMaybe<SacadoWhereInput>;
};

export type MutationDeleteManySacadoAuditArgs = {
    where?: InputMaybe<SacadoAuditWhereInput>;
};

export type MutationDeleteManySacadoContatoArgs = {
    where?: InputMaybe<SacadoContatoWhereInput>;
};

export type MutationDeleteManyStatusOperacionalAuditArgs = {
    where?: InputMaybe<StatusOperacionalAuditWhereInput>;
};

export type MutationDeleteManyStatusOperacionalCedenteMinibancoArgs = {
    where?: InputMaybe<StatusOperacionalCedenteMinibancoWhereInput>;
};

export type MutationDeleteManyTakeRateMinibancoArgs = {
    where?: InputMaybe<TakeRateMinibancoWhereInput>;
};

export type MutationDeleteManyTaxasMinibancoArgs = {
    where?: InputMaybe<TaxasMinibancoWhereInput>;
};

export type MutationDeleteManyTermsAndConditionsArgs = {
    where?: InputMaybe<TermsAndConditionsWhereInput>;
};

export type MutationDeleteManyTipoMovimentacaoArgs = {
    where?: InputMaybe<TipoMovimentacaoWhereInput>;
};

export type MutationDeleteManyUsuarioArgs = {
    where?: InputMaybe<UsuarioWhereInput>;
};

export type MutationDeleteManyUsuarioAuditArgs = {
    where?: InputMaybe<UsuarioAuditWhereInput>;
};

export type MutationDeleteManyUsuarioCadastroArgs = {
    where?: InputMaybe<UsuarioCadastroWhereInput>;
};

export type MutationDeleteManyUsuarioCadastroCamposArgs = {
    where?: InputMaybe<UsuarioCadastroCamposWhereInput>;
};

export type MutationDeleteOneAcceptedTermsArgs = {
    where: AcceptedTermsWhereUniqueInput;
};

export type MutationDeleteOneArquivoArgs = {
    where: ArquivoWhereUniqueInput;
};

export type MutationDeleteOneArquivoAuditArgs = {
    where: ArquivoAuditWhereUniqueInput;
};

export type MutationDeleteOneAssignorBankDataArgs = {
    where: AssignorBankDataWhereUniqueInput;
};

export type MutationDeleteOneAssignorCrmArgs = {
    where: AssignorCrmWhereUniqueInput;
};

export type MutationDeleteOneAssignorDocumentTypeArgs = {
    where: AssignorDocumentTypeWhereUniqueInput;
};

export type MutationDeleteOneAssignorStatusByMinibankArgs = {
    where: AssignorStatusByMinibankWhereUniqueInput;
};

export type MutationDeleteOneBanksArgs = {
    where: BanksWhereUniqueInput;
};

export type MutationDeleteOneCTeArgs = {
    where: CTeWhereUniqueInput;
};

export type MutationDeleteOneCedenteArgs = {
    where: CedenteWhereUniqueInput;
};

export type MutationDeleteOneCedenteAlteracaoArgs = {
    where: CedenteAlteracaoWhereUniqueInput;
};

export type MutationDeleteOneCedenteAnexosArgs = {
    where: CedenteAnexosWhereUniqueInput;
};

export type MutationDeleteOneCedenteAuditArgs = {
    where: CedenteAuditWhereUniqueInput;
};

export type MutationDeleteOneCedenteCadastroArgs = {
    where: CedenteCadastroWhereUniqueInput;
};

export type MutationDeleteOneCedenteCamposAlteracaoArgs = {
    where: CedenteCamposAlteracaoWhereUniqueInput;
};

export type MutationDeleteOneCedenteCamposCadastroArgs = {
    where: CedenteCamposCadastroWhereUniqueInput;
};

export type MutationDeleteOneCedenteContatoArgs = {
    where: CedenteContatoWhereUniqueInput;
};

export type MutationDeleteOneCedentesInfoArgs = {
    where: CedentesInfoWhereUniqueInput;
};

export type MutationDeleteOneCnaeClassesArgs = {
    where: CnaeClassesWhereUniqueInput;
};

export type MutationDeleteOneCnaeDivisoesArgs = {
    where: CnaeDivisoesWhereUniqueInput;
};

export type MutationDeleteOneCnaeGruposArgs = {
    where: CnaeGruposWhereUniqueInput;
};

export type MutationDeleteOneCnaeSecoesArgs = {
    where: CnaeSecoesWhereUniqueInput;
};

export type MutationDeleteOneCnaeSubClassesArgs = {
    where: CnaeSubClassesWhereUniqueInput;
};

export type MutationDeleteOneComercialPartnerAuditArgs = {
    where: ComercialPartnerAuditWhereUniqueInput;
};

export type MutationDeleteOneComercialPartnerCommissionArgs = {
    where: ComercialPartnerCommissionWhereUniqueInput;
};

export type MutationDeleteOneContaBancariaArgs = {
    where: ContaBancariaWhereUniqueInput;
};

export type MutationDeleteOneContatoArgs = {
    where: ContatoWhereUniqueInput;
};

export type MutationDeleteOneContatoAuditArgs = {
    where: ContatoAuditWhereUniqueInput;
};

export type MutationDeleteOneDebenturistaArgs = {
    where: DebenturistaWhereUniqueInput;
};

export type MutationDeleteOneDebenturistaAuditArgs = {
    where: DebenturistaAuditWhereUniqueInput;
};

export type MutationDeleteOneEmpresaArgs = {
    where: EmpresaWhereUniqueInput;
};

export type MutationDeleteOneEmpresaAuditArgs = {
    where: EmpresaAuditWhereUniqueInput;
};

export type MutationDeleteOneEnderecoArgs = {
    where: EnderecoWhereUniqueInput;
};

export type MutationDeleteOneEnderecoAlteracaoArgs = {
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type MutationDeleteOneEnderecoAuditArgs = {
    where: EnderecoAuditWhereUniqueInput;
};

export type MutationDeleteOneEnderecoCadastroArgs = {
    where: EnderecoCadastroWhereUniqueInput;
};

export type MutationDeleteOneEnderecoCamposAlteracaoArgs = {
    where: EnderecoCamposAlteracaoWhereUniqueInput;
};

export type MutationDeleteOneEnderecoCamposCadastroArgs = {
    where: EnderecoCamposCadastroWhereUniqueInput;
};

export type MutationDeleteOneEventArgs = {
    where: EventWhereUniqueInput;
};

export type MutationDeleteOneExtratoIntegracaoArgs = {
    where: ExtratoIntegracaoWhereUniqueInput;
};

export type MutationDeleteOneGrupoNotificacaoArgs = {
    where: GrupoNotificacaoWhereUniqueInput;
};

export type MutationDeleteOneGruposPermissaoArgs = {
    where: GruposPermissaoWhereUniqueInput;
};

export type MutationDeleteOneMinibancoArgs = {
    where: MinibancoWhereUniqueInput;
};

export type MutationDeleteOneMinibancoAuditArgs = {
    where: MinibancoAuditWhereUniqueInput;
};

export type MutationDeleteOneMinibancoContatoArgs = {
    where: MinibancoContatoWhereUniqueInput;
};

export type MutationDeleteOneMinibancoExtratoArgs = {
    where: MinibancoExtratoWhereUniqueInput;
};

export type MutationDeleteOneMinibankIntegrationArgs = {
    where: MinibankIntegrationWhereUniqueInput;
};

export type MutationDeleteOneNFeArgs = {
    where: NFeWhereUniqueInput;
};

export type MutationDeleteOneNFeParcelaArgs = {
    where: NFeParcelaWhereUniqueInput;
};

export type MutationDeleteOneNivelPermissaoArgs = {
    where: NivelPermissaoWhereUniqueInput;
};

export type MutationDeleteOneOccupationArgs = {
    where: OccupationWhereUniqueInput;
};

export type MutationDeleteOneOccupationAuditArgs = {
    where: OccupationAuditWhereUniqueInput;
};

export type MutationDeleteOneOperacaoArgs = {
    where: OperacaoWhereUniqueInput;
};

export type MutationDeleteOneOperacaoAnexosArgs = {
    where: OperacaoAnexosWhereUniqueInput;
};

export type MutationDeleteOneOperacaoAuditArgs = {
    where: OperacaoAuditWhereUniqueInput;
};

export type MutationDeleteOneOperacaoChecklistArgs = {
    where: OperacaoChecklistWhereUniqueInput;
};

export type MutationDeleteOneOperacaoIntegracaoArgs = {
    where: OperacaoIntegracaoWhereUniqueInput;
};

export type MutationDeleteOneOperacaoModalidadeArgs = {
    where: OperacaoModalidadeWhereUniqueInput;
};

export type MutationDeleteOneOperacaoRascunhoArgs = {
    where: OperacaoRascunhoWhereUniqueInput;
};

export type MutationDeleteOneOperacaoStatusArgs = {
    where: OperacaoStatusWhereUniqueInput;
};

export type MutationDeleteOneOperacaoSugeridaArgs = {
    where: OperacaoSugeridaWhereUniqueInput;
};

export type MutationDeleteOneOperacaoTipoArgs = {
    where: OperacaoTipoWhereUniqueInput;
};

export type MutationDeleteOneOperacoesRascunhoViewArgs = {
    where: OperacoesRascunhoViewWhereUniqueInput;
};

export type MutationDeleteOneOperationCommisionArgs = {
    where: OperationCommisionWhereUniqueInput;
};

export type MutationDeleteOneOperationFeesArgs = {
    where: OperationFeesWhereUniqueInput;
};

export type MutationDeleteOneOperationUpdatesArgs = {
    where: OperationUpdatesWhereUniqueInput;
};

export type MutationDeleteOneOperationsInfoArgs = {
    where: OperationsInfoWhereUniqueInput;
};

export type MutationDeleteOneParceiroComercialArgs = {
    where: ParceiroComercialWhereUniqueInput;
};

export type MutationDeleteOnePayableUpdatesArgs = {
    where: PayableUpdatesWhereUniqueInput;
};

export type MutationDeleteOnePermissoesGruposArgs = {
    where: PermissoesGruposWhereUniqueInput;
};

export type MutationDeleteOnePreCadastroCedenteArgs = {
    where: PreCadastroCedenteWhereUniqueInput;
};

export type MutationDeleteOneRecebiveisInfoArgs = {
    where: RecebiveisInfoWhereUniqueInput;
};

export type MutationDeleteOneRecebiveisToAnticipateArgs = {
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type MutationDeleteOneRecebivelArgs = {
    where: RecebivelWhereUniqueInput;
};

export type MutationDeleteOneRecebivelAuditArgs = {
    where: RecebivelAuditWhereUniqueInput;
};

export type MutationDeleteOneRecebivelIntegracaoArgs = {
    where: RecebivelIntegracaoWhereUniqueInput;
};

export type MutationDeleteOneRecebivelOperadoArgs = {
    where: RecebivelOperadoWhereUniqueInput;
};

export type MutationDeleteOneRecebivelPagarContasArgs = {
    where: RecebivelPagarContasWhereUniqueInput;
};

export type MutationDeleteOneRepresentativeArgs = {
    where: RepresentativeWhereUniqueInput;
};

export type MutationDeleteOneResponsavelCedenteArgs = {
    where: ResponsavelCedenteWhereUniqueInput;
};

export type MutationDeleteOneSacadoArgs = {
    where: SacadoWhereUniqueInput;
};

export type MutationDeleteOneSacadoAuditArgs = {
    where: SacadoAuditWhereUniqueInput;
};

export type MutationDeleteOneSacadoContatoArgs = {
    where: SacadoContatoWhereUniqueInput;
};

export type MutationDeleteOneStatusOperacionalAuditArgs = {
    where: StatusOperacionalAuditWhereUniqueInput;
};

export type MutationDeleteOneStatusOperacionalCedenteMinibancoArgs = {
    where: StatusOperacionalCedenteMinibancoWhereUniqueInput;
};

export type MutationDeleteOneTakeRateMinibancoArgs = {
    where: TakeRateMinibancoWhereUniqueInput;
};

export type MutationDeleteOneTaxasMinibancoArgs = {
    where: TaxasMinibancoWhereUniqueInput;
};

export type MutationDeleteOneTermsAndConditionsArgs = {
    where: TermsAndConditionsWhereUniqueInput;
};

export type MutationDeleteOneTipoMovimentacaoArgs = {
    where: TipoMovimentacaoWhereUniqueInput;
};

export type MutationDeleteOneUsuarioArgs = {
    where: UsuarioWhereUniqueInput;
};

export type MutationDeleteOneUsuarioAuditArgs = {
    where: UsuarioAuditWhereUniqueInput;
};

export type MutationDeleteOneUsuarioCadastroArgs = {
    where: UsuarioCadastroWhereUniqueInput;
};

export type MutationDeleteOneUsuarioCadastroCamposArgs = {
    where: UsuarioCadastroCamposWhereUniqueInput;
};

export type MutationUpdateManyAcceptedTermsArgs = {
    data: AcceptedTermsUpdateManyMutationInput;
    where?: InputMaybe<AcceptedTermsWhereInput>;
};

export type MutationUpdateManyArquivoArgs = {
    data: ArquivoUpdateManyMutationInput;
    where?: InputMaybe<ArquivoWhereInput>;
};

export type MutationUpdateManyArquivoAuditArgs = {
    data: ArquivoAuditUpdateManyMutationInput;
    where?: InputMaybe<ArquivoAuditWhereInput>;
};

export type MutationUpdateManyAssignorBankDataArgs = {
    data: AssignorBankDataUpdateManyMutationInput;
    where?: InputMaybe<AssignorBankDataWhereInput>;
};

export type MutationUpdateManyAssignorCrmArgs = {
    data: AssignorCrmUpdateManyMutationInput;
    where?: InputMaybe<AssignorCrmWhereInput>;
};

export type MutationUpdateManyAssignorDocumentTypeArgs = {
    data: AssignorDocumentTypeUpdateManyMutationInput;
    where?: InputMaybe<AssignorDocumentTypeWhereInput>;
};

export type MutationUpdateManyAssignorStatusByMinibankArgs = {
    data: AssignorStatusByMinibankUpdateManyMutationInput;
    where?: InputMaybe<AssignorStatusByMinibankWhereInput>;
};

export type MutationUpdateManyBanksArgs = {
    data: BanksUpdateManyMutationInput;
    where?: InputMaybe<BanksWhereInput>;
};

export type MutationUpdateManyCTeArgs = {
    data: CTeUpdateManyMutationInput;
    where?: InputMaybe<CTeWhereInput>;
};

export type MutationUpdateManyCedenteArgs = {
    data: CedenteUpdateManyMutationInput;
    where?: InputMaybe<CedenteWhereInput>;
};

export type MutationUpdateManyCedenteAlteracaoArgs = {
    data: CedenteAlteracaoUpdateManyMutationInput;
    where?: InputMaybe<CedenteAlteracaoWhereInput>;
};

export type MutationUpdateManyCedenteAnexosArgs = {
    data: CedenteAnexosUpdateManyMutationInput;
    where?: InputMaybe<CedenteAnexosWhereInput>;
};

export type MutationUpdateManyCedenteAuditArgs = {
    data: CedenteAuditUpdateManyMutationInput;
    where?: InputMaybe<CedenteAuditWhereInput>;
};

export type MutationUpdateManyCedenteCadastroArgs = {
    data: CedenteCadastroUpdateManyMutationInput;
    where?: InputMaybe<CedenteCadastroWhereInput>;
};

export type MutationUpdateManyCedenteCamposAlteracaoArgs = {
    data: CedenteCamposAlteracaoUpdateManyMutationInput;
    where?: InputMaybe<CedenteCamposAlteracaoWhereInput>;
};

export type MutationUpdateManyCedenteCamposCadastroArgs = {
    data: CedenteCamposCadastroUpdateManyMutationInput;
    where?: InputMaybe<CedenteCamposCadastroWhereInput>;
};

export type MutationUpdateManyCedenteContatoArgs = {
    data: CedenteContatoUpdateManyMutationInput;
    where?: InputMaybe<CedenteContatoWhereInput>;
};

export type MutationUpdateManyCedentesInfoArgs = {
    data: CedentesInfoUpdateManyMutationInput;
    where?: InputMaybe<CedentesInfoWhereInput>;
};

export type MutationUpdateManyCnaeClassesArgs = {
    data: CnaeClassesUpdateManyMutationInput;
    where?: InputMaybe<CnaeClassesWhereInput>;
};

export type MutationUpdateManyCnaeDivisoesArgs = {
    data: CnaeDivisoesUpdateManyMutationInput;
    where?: InputMaybe<CnaeDivisoesWhereInput>;
};

export type MutationUpdateManyCnaeGruposArgs = {
    data: CnaeGruposUpdateManyMutationInput;
    where?: InputMaybe<CnaeGruposWhereInput>;
};

export type MutationUpdateManyCnaeSecoesArgs = {
    data: CnaeSecoesUpdateManyMutationInput;
    where?: InputMaybe<CnaeSecoesWhereInput>;
};

export type MutationUpdateManyCnaeSubClassesArgs = {
    data: CnaeSubClassesUpdateManyMutationInput;
    where?: InputMaybe<CnaeSubClassesWhereInput>;
};

export type MutationUpdateManyComercialPartnerAuditArgs = {
    data: ComercialPartnerAuditUpdateManyMutationInput;
    where?: InputMaybe<ComercialPartnerAuditWhereInput>;
};

export type MutationUpdateManyComercialPartnerCommissionArgs = {
    data: ComercialPartnerCommissionUpdateManyMutationInput;
    where?: InputMaybe<ComercialPartnerCommissionWhereInput>;
};

export type MutationUpdateManyContaBancariaArgs = {
    data: ContaBancariaUpdateManyMutationInput;
    where?: InputMaybe<ContaBancariaWhereInput>;
};

export type MutationUpdateManyContatoArgs = {
    data: ContatoUpdateManyMutationInput;
    where?: InputMaybe<ContatoWhereInput>;
};

export type MutationUpdateManyContatoAuditArgs = {
    data: ContatoAuditUpdateManyMutationInput;
    where?: InputMaybe<ContatoAuditWhereInput>;
};

export type MutationUpdateManyDebenturistaArgs = {
    data: DebenturistaUpdateManyMutationInput;
    where?: InputMaybe<DebenturistaWhereInput>;
};

export type MutationUpdateManyDebenturistaAuditArgs = {
    data: DebenturistaAuditUpdateManyMutationInput;
    where?: InputMaybe<DebenturistaAuditWhereInput>;
};

export type MutationUpdateManyEmpresaArgs = {
    data: EmpresaUpdateManyMutationInput;
    where?: InputMaybe<EmpresaWhereInput>;
};

export type MutationUpdateManyEmpresaAuditArgs = {
    data: EmpresaAuditUpdateManyMutationInput;
    where?: InputMaybe<EmpresaAuditWhereInput>;
};

export type MutationUpdateManyEnderecoArgs = {
    data: EnderecoUpdateManyMutationInput;
    where?: InputMaybe<EnderecoWhereInput>;
};

export type MutationUpdateManyEnderecoAlteracaoArgs = {
    data: EnderecoAlteracaoUpdateManyMutationInput;
    where?: InputMaybe<EnderecoAlteracaoWhereInput>;
};

export type MutationUpdateManyEnderecoAuditArgs = {
    data: EnderecoAuditUpdateManyMutationInput;
    where?: InputMaybe<EnderecoAuditWhereInput>;
};

export type MutationUpdateManyEnderecoCadastroArgs = {
    data: EnderecoCadastroUpdateManyMutationInput;
    where?: InputMaybe<EnderecoCadastroWhereInput>;
};

export type MutationUpdateManyEnderecoCamposAlteracaoArgs = {
    data: EnderecoCamposAlteracaoUpdateManyMutationInput;
    where?: InputMaybe<EnderecoCamposAlteracaoWhereInput>;
};

export type MutationUpdateManyEnderecoCamposCadastroArgs = {
    data: EnderecoCamposCadastroUpdateManyMutationInput;
    where?: InputMaybe<EnderecoCamposCadastroWhereInput>;
};

export type MutationUpdateManyEventArgs = {
    data: EventUpdateManyMutationInput;
    where?: InputMaybe<EventWhereInput>;
};

export type MutationUpdateManyExtratoIntegracaoArgs = {
    data: ExtratoIntegracaoUpdateManyMutationInput;
    where?: InputMaybe<ExtratoIntegracaoWhereInput>;
};

export type MutationUpdateManyGrupoNotificacaoArgs = {
    data: GrupoNotificacaoUpdateManyMutationInput;
    where?: InputMaybe<GrupoNotificacaoWhereInput>;
};

export type MutationUpdateManyGruposPermissaoArgs = {
    data: GruposPermissaoUpdateManyMutationInput;
    where?: InputMaybe<GruposPermissaoWhereInput>;
};

export type MutationUpdateManyMinibancoArgs = {
    data: MinibancoUpdateManyMutationInput;
    where?: InputMaybe<MinibancoWhereInput>;
};

export type MutationUpdateManyMinibancoAuditArgs = {
    data: MinibancoAuditUpdateManyMutationInput;
    where?: InputMaybe<MinibancoAuditWhereInput>;
};

export type MutationUpdateManyMinibancoContatoArgs = {
    data: MinibancoContatoUpdateManyMutationInput;
    where?: InputMaybe<MinibancoContatoWhereInput>;
};

export type MutationUpdateManyMinibancoExtratoArgs = {
    data: MinibancoExtratoUpdateManyMutationInput;
    where?: InputMaybe<MinibancoExtratoWhereInput>;
};

export type MutationUpdateManyMinibankIntegrationArgs = {
    data: MinibankIntegrationUpdateManyMutationInput;
    where?: InputMaybe<MinibankIntegrationWhereInput>;
};

export type MutationUpdateManyNFeArgs = {
    data: NFeUpdateManyMutationInput;
    where?: InputMaybe<NFeWhereInput>;
};

export type MutationUpdateManyNFeParcelaArgs = {
    data: NFeParcelaUpdateManyMutationInput;
    where?: InputMaybe<NFeParcelaWhereInput>;
};

export type MutationUpdateManyNivelPermissaoArgs = {
    data: NivelPermissaoUpdateManyMutationInput;
    where?: InputMaybe<NivelPermissaoWhereInput>;
};

export type MutationUpdateManyOccupationArgs = {
    data: OccupationUpdateManyMutationInput;
    where?: InputMaybe<OccupationWhereInput>;
};

export type MutationUpdateManyOccupationAuditArgs = {
    data: OccupationAuditUpdateManyMutationInput;
    where?: InputMaybe<OccupationAuditWhereInput>;
};

export type MutationUpdateManyOperacaoArgs = {
    data: OperacaoUpdateManyMutationInput;
    where?: InputMaybe<OperacaoWhereInput>;
};

export type MutationUpdateManyOperacaoAnexosArgs = {
    data: OperacaoAnexosUpdateManyMutationInput;
    where?: InputMaybe<OperacaoAnexosWhereInput>;
};

export type MutationUpdateManyOperacaoAuditArgs = {
    data: OperacaoAuditUpdateManyMutationInput;
    where?: InputMaybe<OperacaoAuditWhereInput>;
};

export type MutationUpdateManyOperacaoChecklistArgs = {
    data: OperacaoChecklistUpdateManyMutationInput;
    where?: InputMaybe<OperacaoChecklistWhereInput>;
};

export type MutationUpdateManyOperacaoIntegracaoArgs = {
    data: OperacaoIntegracaoUpdateManyMutationInput;
    where?: InputMaybe<OperacaoIntegracaoWhereInput>;
};

export type MutationUpdateManyOperacaoModalidadeArgs = {
    data: OperacaoModalidadeUpdateManyMutationInput;
    where?: InputMaybe<OperacaoModalidadeWhereInput>;
};

export type MutationUpdateManyOperacaoRascunhoArgs = {
    data: OperacaoRascunhoUpdateManyMutationInput;
    where?: InputMaybe<OperacaoRascunhoWhereInput>;
};

export type MutationUpdateManyOperacaoStatusArgs = {
    data: OperacaoStatusUpdateManyMutationInput;
    where?: InputMaybe<OperacaoStatusWhereInput>;
};

export type MutationUpdateManyOperacaoSugeridaArgs = {
    data: OperacaoSugeridaUpdateManyMutationInput;
    where?: InputMaybe<OperacaoSugeridaWhereInput>;
};

export type MutationUpdateManyOperacaoTipoArgs = {
    data: OperacaoTipoUpdateManyMutationInput;
    where?: InputMaybe<OperacaoTipoWhereInput>;
};

export type MutationUpdateManyOperacoesRascunhoViewArgs = {
    data: OperacoesRascunhoViewUpdateManyMutationInput;
    where?: InputMaybe<OperacoesRascunhoViewWhereInput>;
};

export type MutationUpdateManyOperationCommisionArgs = {
    data: OperationCommisionUpdateManyMutationInput;
    where?: InputMaybe<OperationCommisionWhereInput>;
};

export type MutationUpdateManyOperationFeesArgs = {
    data: OperationFeesUpdateManyMutationInput;
    where?: InputMaybe<OperationFeesWhereInput>;
};

export type MutationUpdateManyOperationUpdatesArgs = {
    data: OperationUpdatesUpdateManyMutationInput;
    where?: InputMaybe<OperationUpdatesWhereInput>;
};

export type MutationUpdateManyOperationsInfoArgs = {
    data: OperationsInfoUpdateManyMutationInput;
    where?: InputMaybe<OperationsInfoWhereInput>;
};

export type MutationUpdateManyParceiroComercialArgs = {
    data: ParceiroComercialUpdateManyMutationInput;
    where?: InputMaybe<ParceiroComercialWhereInput>;
};

export type MutationUpdateManyPayableUpdatesArgs = {
    data: PayableUpdatesUpdateManyMutationInput;
    where?: InputMaybe<PayableUpdatesWhereInput>;
};

export type MutationUpdateManyPermissoesGruposArgs = {
    data: PermissoesGruposUpdateManyMutationInput;
    where?: InputMaybe<PermissoesGruposWhereInput>;
};

export type MutationUpdateManyPreCadastroCedenteArgs = {
    data: PreCadastroCedenteUpdateManyMutationInput;
    where?: InputMaybe<PreCadastroCedenteWhereInput>;
};

export type MutationUpdateManyRecebiveisInfoArgs = {
    data: RecebiveisInfoUpdateManyMutationInput;
    where?: InputMaybe<RecebiveisInfoWhereInput>;
};

export type MutationUpdateManyRecebiveisToAnticipateArgs = {
    data: RecebiveisToAnticipateUpdateManyMutationInput;
    where?: InputMaybe<RecebiveisToAnticipateWhereInput>;
};

export type MutationUpdateManyRecebivelArgs = {
    data: RecebivelUpdateManyMutationInput;
    where?: InputMaybe<RecebivelWhereInput>;
};

export type MutationUpdateManyRecebivelAuditArgs = {
    data: RecebivelAuditUpdateManyMutationInput;
    where?: InputMaybe<RecebivelAuditWhereInput>;
};

export type MutationUpdateManyRecebivelIntegracaoArgs = {
    data: RecebivelIntegracaoUpdateManyMutationInput;
    where?: InputMaybe<RecebivelIntegracaoWhereInput>;
};

export type MutationUpdateManyRecebivelOperadoArgs = {
    data: RecebivelOperadoUpdateManyMutationInput;
    where?: InputMaybe<RecebivelOperadoWhereInput>;
};

export type MutationUpdateManyRecebivelPagarContasArgs = {
    data: RecebivelPagarContasUpdateManyMutationInput;
    where?: InputMaybe<RecebivelPagarContasWhereInput>;
};

export type MutationUpdateManyRepresentativeArgs = {
    data: RepresentativeUpdateManyMutationInput;
    where?: InputMaybe<RepresentativeWhereInput>;
};

export type MutationUpdateManyResponsavelCedenteArgs = {
    data: ResponsavelCedenteUpdateManyMutationInput;
    where?: InputMaybe<ResponsavelCedenteWhereInput>;
};

export type MutationUpdateManySacadoArgs = {
    data: SacadoUpdateManyMutationInput;
    where?: InputMaybe<SacadoWhereInput>;
};

export type MutationUpdateManySacadoAuditArgs = {
    data: SacadoAuditUpdateManyMutationInput;
    where?: InputMaybe<SacadoAuditWhereInput>;
};

export type MutationUpdateManySacadoContatoArgs = {
    data: SacadoContatoUpdateManyMutationInput;
    where?: InputMaybe<SacadoContatoWhereInput>;
};

export type MutationUpdateManyStatusOperacionalAuditArgs = {
    data: StatusOperacionalAuditUpdateManyMutationInput;
    where?: InputMaybe<StatusOperacionalAuditWhereInput>;
};

export type MutationUpdateManyStatusOperacionalCedenteMinibancoArgs = {
    data: StatusOperacionalCedenteMinibancoUpdateManyMutationInput;
    where?: InputMaybe<StatusOperacionalCedenteMinibancoWhereInput>;
};

export type MutationUpdateManyTakeRateMinibancoArgs = {
    data: TakeRateMinibancoUpdateManyMutationInput;
    where?: InputMaybe<TakeRateMinibancoWhereInput>;
};

export type MutationUpdateManyTaxasMinibancoArgs = {
    data: TaxasMinibancoUpdateManyMutationInput;
    where?: InputMaybe<TaxasMinibancoWhereInput>;
};

export type MutationUpdateManyTermsAndConditionsArgs = {
    data: TermsAndConditionsUpdateManyMutationInput;
    where?: InputMaybe<TermsAndConditionsWhereInput>;
};

export type MutationUpdateManyTipoMovimentacaoArgs = {
    data: TipoMovimentacaoUpdateManyMutationInput;
    where?: InputMaybe<TipoMovimentacaoWhereInput>;
};

export type MutationUpdateManyUsuarioArgs = {
    data: UsuarioUpdateManyMutationInput;
    where?: InputMaybe<UsuarioWhereInput>;
};

export type MutationUpdateManyUsuarioAuditArgs = {
    data: UsuarioAuditUpdateManyMutationInput;
    where?: InputMaybe<UsuarioAuditWhereInput>;
};

export type MutationUpdateManyUsuarioCadastroArgs = {
    data: UsuarioCadastroUpdateManyMutationInput;
    where?: InputMaybe<UsuarioCadastroWhereInput>;
};

export type MutationUpdateManyUsuarioCadastroCamposArgs = {
    data: UsuarioCadastroCamposUpdateManyMutationInput;
    where?: InputMaybe<UsuarioCadastroCamposWhereInput>;
};

export type MutationUpdateObsFromAttachmentArgs = {
    id: Scalars["Float"];
    obs: Scalars["String"];
};

export type MutationUpdateOneAcceptedTermsArgs = {
    data: AcceptedTermsUpdateInput;
    where: AcceptedTermsWhereUniqueInput;
};

export type MutationUpdateOneArquivoArgs = {
    data: ArquivoUpdateInput;
    where: ArquivoWhereUniqueInput;
};

export type MutationUpdateOneArquivoAuditArgs = {
    data: ArquivoAuditUpdateInput;
    where: ArquivoAuditWhereUniqueInput;
};

export type MutationUpdateOneAssignorBankDataArgs = {
    data: AssignorBankDataUpdateInput;
    where: AssignorBankDataWhereUniqueInput;
};

export type MutationUpdateOneAssignorCrmArgs = {
    data: AssignorCrmUpdateInput;
    where: AssignorCrmWhereUniqueInput;
};

export type MutationUpdateOneAssignorDocumentTypeArgs = {
    data: AssignorDocumentTypeUpdateInput;
    where: AssignorDocumentTypeWhereUniqueInput;
};

export type MutationUpdateOneAssignorStatusByMinibankArgs = {
    data: AssignorStatusByMinibankUpdateInput;
    where: AssignorStatusByMinibankWhereUniqueInput;
};

export type MutationUpdateOneBanksArgs = {
    data: BanksUpdateInput;
    where: BanksWhereUniqueInput;
};

export type MutationUpdateOneCTeArgs = {
    data: CTeUpdateInput;
    where: CTeWhereUniqueInput;
};

export type MutationUpdateOneCedenteArgs = {
    data: CedenteUpdateInput;
    where: CedenteWhereUniqueInput;
};

export type MutationUpdateOneCedenteAlteracaoArgs = {
    data: CedenteAlteracaoUpdateInput;
    where: CedenteAlteracaoWhereUniqueInput;
};

export type MutationUpdateOneCedenteAnexosArgs = {
    data: CedenteAnexosUpdateInput;
    where: CedenteAnexosWhereUniqueInput;
};

export type MutationUpdateOneCedenteAuditArgs = {
    data: CedenteAuditUpdateInput;
    where: CedenteAuditWhereUniqueInput;
};

export type MutationUpdateOneCedenteCadastroArgs = {
    data: CedenteCadastroUpdateInput;
    where: CedenteCadastroWhereUniqueInput;
};

export type MutationUpdateOneCedenteCamposAlteracaoArgs = {
    data: CedenteCamposAlteracaoUpdateInput;
    where: CedenteCamposAlteracaoWhereUniqueInput;
};

export type MutationUpdateOneCedenteCamposCadastroArgs = {
    data: CedenteCamposCadastroUpdateInput;
    where: CedenteCamposCadastroWhereUniqueInput;
};

export type MutationUpdateOneCedenteContatoArgs = {
    data: CedenteContatoUpdateInput;
    where: CedenteContatoWhereUniqueInput;
};

export type MutationUpdateOneCedentesInfoArgs = {
    data: CedentesInfoUpdateInput;
    where: CedentesInfoWhereUniqueInput;
};

export type MutationUpdateOneCnaeClassesArgs = {
    data: CnaeClassesUpdateInput;
    where: CnaeClassesWhereUniqueInput;
};

export type MutationUpdateOneCnaeDivisoesArgs = {
    data: CnaeDivisoesUpdateInput;
    where: CnaeDivisoesWhereUniqueInput;
};

export type MutationUpdateOneCnaeGruposArgs = {
    data: CnaeGruposUpdateInput;
    where: CnaeGruposWhereUniqueInput;
};

export type MutationUpdateOneCnaeSecoesArgs = {
    data: CnaeSecoesUpdateInput;
    where: CnaeSecoesWhereUniqueInput;
};

export type MutationUpdateOneCnaeSubClassesArgs = {
    data: CnaeSubClassesUpdateInput;
    where: CnaeSubClassesWhereUniqueInput;
};

export type MutationUpdateOneComercialPartnerAuditArgs = {
    data: ComercialPartnerAuditUpdateInput;
    where: ComercialPartnerAuditWhereUniqueInput;
};

export type MutationUpdateOneComercialPartnerCommissionArgs = {
    data: ComercialPartnerCommissionUpdateInput;
    where: ComercialPartnerCommissionWhereUniqueInput;
};

export type MutationUpdateOneContaBancariaArgs = {
    data: ContaBancariaUpdateInput;
    where: ContaBancariaWhereUniqueInput;
};

export type MutationUpdateOneContatoArgs = {
    data: ContatoUpdateInput;
    where: ContatoWhereUniqueInput;
};

export type MutationUpdateOneContatoAuditArgs = {
    data: ContatoAuditUpdateInput;
    where: ContatoAuditWhereUniqueInput;
};

export type MutationUpdateOneDebenturistaArgs = {
    data: DebenturistaUpdateInput;
    where: DebenturistaWhereUniqueInput;
};

export type MutationUpdateOneDebenturistaAuditArgs = {
    data: DebenturistaAuditUpdateInput;
    where: DebenturistaAuditWhereUniqueInput;
};

export type MutationUpdateOneEmpresaArgs = {
    data: EmpresaUpdateInput;
    where: EmpresaWhereUniqueInput;
};

export type MutationUpdateOneEmpresaAuditArgs = {
    data: EmpresaAuditUpdateInput;
    where: EmpresaAuditWhereUniqueInput;
};

export type MutationUpdateOneEnderecoArgs = {
    data: EnderecoUpdateInput;
    where: EnderecoWhereUniqueInput;
};

export type MutationUpdateOneEnderecoAlteracaoArgs = {
    data: EnderecoAlteracaoUpdateInput;
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type MutationUpdateOneEnderecoAuditArgs = {
    data: EnderecoAuditUpdateInput;
    where: EnderecoAuditWhereUniqueInput;
};

export type MutationUpdateOneEnderecoCadastroArgs = {
    data: EnderecoCadastroUpdateInput;
    where: EnderecoCadastroWhereUniqueInput;
};

export type MutationUpdateOneEnderecoCamposAlteracaoArgs = {
    data: EnderecoCamposAlteracaoUpdateInput;
    where: EnderecoCamposAlteracaoWhereUniqueInput;
};

export type MutationUpdateOneEnderecoCamposCadastroArgs = {
    data: EnderecoCamposCadastroUpdateInput;
    where: EnderecoCamposCadastroWhereUniqueInput;
};

export type MutationUpdateOneEventArgs = {
    data: EventUpdateInput;
    where: EventWhereUniqueInput;
};

export type MutationUpdateOneExtratoIntegracaoArgs = {
    data: ExtratoIntegracaoUpdateInput;
    where: ExtratoIntegracaoWhereUniqueInput;
};

export type MutationUpdateOneGrupoNotificacaoArgs = {
    data: GrupoNotificacaoUpdateInput;
    where: GrupoNotificacaoWhereUniqueInput;
};

export type MutationUpdateOneGruposPermissaoArgs = {
    data: GruposPermissaoUpdateInput;
    where: GruposPermissaoWhereUniqueInput;
};

export type MutationUpdateOneMinibancoArgs = {
    data: MinibancoUpdateInput;
    where: MinibancoWhereUniqueInput;
};

export type MutationUpdateOneMinibancoAuditArgs = {
    data: MinibancoAuditUpdateInput;
    where: MinibancoAuditWhereUniqueInput;
};

export type MutationUpdateOneMinibancoContatoArgs = {
    data: MinibancoContatoUpdateInput;
    where: MinibancoContatoWhereUniqueInput;
};

export type MutationUpdateOneMinibancoExtratoArgs = {
    data: MinibancoExtratoUpdateInput;
    where: MinibancoExtratoWhereUniqueInput;
};

export type MutationUpdateOneMinibankIntegrationArgs = {
    data: MinibankIntegrationUpdateInput;
    where: MinibankIntegrationWhereUniqueInput;
};

export type MutationUpdateOneNFeArgs = {
    data: NFeUpdateInput;
    where: NFeWhereUniqueInput;
};

export type MutationUpdateOneNFeParcelaArgs = {
    data: NFeParcelaUpdateInput;
    where: NFeParcelaWhereUniqueInput;
};

export type MutationUpdateOneNivelPermissaoArgs = {
    data: NivelPermissaoUpdateInput;
    where: NivelPermissaoWhereUniqueInput;
};

export type MutationUpdateOneOccupationArgs = {
    data: OccupationUpdateInput;
    where: OccupationWhereUniqueInput;
};

export type MutationUpdateOneOccupationAuditArgs = {
    data: OccupationAuditUpdateInput;
    where: OccupationAuditWhereUniqueInput;
};

export type MutationUpdateOneOperacaoArgs = {
    data: OperacaoUpdateInput;
    where: OperacaoWhereUniqueInput;
};

export type MutationUpdateOneOperacaoAnexosArgs = {
    data: OperacaoAnexosUpdateInput;
    where: OperacaoAnexosWhereUniqueInput;
};

export type MutationUpdateOneOperacaoAuditArgs = {
    data: OperacaoAuditUpdateInput;
    where: OperacaoAuditWhereUniqueInput;
};

export type MutationUpdateOneOperacaoChecklistArgs = {
    data: OperacaoChecklistUpdateInput;
    where: OperacaoChecklistWhereUniqueInput;
};

export type MutationUpdateOneOperacaoIntegracaoArgs = {
    data: OperacaoIntegracaoUpdateInput;
    where: OperacaoIntegracaoWhereUniqueInput;
};

export type MutationUpdateOneOperacaoModalidadeArgs = {
    data: OperacaoModalidadeUpdateInput;
    where: OperacaoModalidadeWhereUniqueInput;
};

export type MutationUpdateOneOperacaoRascunhoArgs = {
    data: OperacaoRascunhoUpdateInput;
    where: OperacaoRascunhoWhereUniqueInput;
};

export type MutationUpdateOneOperacaoStatusArgs = {
    data: OperacaoStatusUpdateInput;
    where: OperacaoStatusWhereUniqueInput;
};

export type MutationUpdateOneOperacaoSugeridaArgs = {
    data: OperacaoSugeridaUpdateInput;
    where: OperacaoSugeridaWhereUniqueInput;
};

export type MutationUpdateOneOperacaoTipoArgs = {
    data: OperacaoTipoUpdateInput;
    where: OperacaoTipoWhereUniqueInput;
};

export type MutationUpdateOneOperacoesRascunhoViewArgs = {
    data: OperacoesRascunhoViewUpdateInput;
    where: OperacoesRascunhoViewWhereUniqueInput;
};

export type MutationUpdateOneOperationCommisionArgs = {
    data: OperationCommisionUpdateInput;
    where: OperationCommisionWhereUniqueInput;
};

export type MutationUpdateOneOperationFeesArgs = {
    data: OperationFeesUpdateInput;
    where: OperationFeesWhereUniqueInput;
};

export type MutationUpdateOneOperationUpdatesArgs = {
    data: OperationUpdatesUpdateInput;
    where: OperationUpdatesWhereUniqueInput;
};

export type MutationUpdateOneOperationsInfoArgs = {
    data: OperationsInfoUpdateInput;
    where: OperationsInfoWhereUniqueInput;
};

export type MutationUpdateOneParceiroComercialArgs = {
    data: ParceiroComercialUpdateInput;
    where: ParceiroComercialWhereUniqueInput;
};

export type MutationUpdateOnePayableUpdatesArgs = {
    data: PayableUpdatesUpdateInput;
    where: PayableUpdatesWhereUniqueInput;
};

export type MutationUpdateOnePermissoesGruposArgs = {
    data: PermissoesGruposUpdateInput;
    where: PermissoesGruposWhereUniqueInput;
};

export type MutationUpdateOnePreCadastroCedenteArgs = {
    data: PreCadastroCedenteUpdateInput;
    where: PreCadastroCedenteWhereUniqueInput;
};

export type MutationUpdateOneRecebiveisInfoArgs = {
    data: RecebiveisInfoUpdateInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type MutationUpdateOneRecebiveisToAnticipateArgs = {
    data: RecebiveisToAnticipateUpdateInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type MutationUpdateOneRecebivelArgs = {
    data: RecebivelUpdateInput;
    where: RecebivelWhereUniqueInput;
};

export type MutationUpdateOneRecebivelAuditArgs = {
    data: RecebivelAuditUpdateInput;
    where: RecebivelAuditWhereUniqueInput;
};

export type MutationUpdateOneRecebivelIntegracaoArgs = {
    data: RecebivelIntegracaoUpdateInput;
    where: RecebivelIntegracaoWhereUniqueInput;
};

export type MutationUpdateOneRecebivelOperadoArgs = {
    data: RecebivelOperadoUpdateInput;
    where: RecebivelOperadoWhereUniqueInput;
};

export type MutationUpdateOneRecebivelPagarContasArgs = {
    data: RecebivelPagarContasUpdateInput;
    where: RecebivelPagarContasWhereUniqueInput;
};

export type MutationUpdateOneRepresentativeArgs = {
    data: RepresentativeUpdateInput;
    where: RepresentativeWhereUniqueInput;
};

export type MutationUpdateOneResponsavelCedenteArgs = {
    data: ResponsavelCedenteUpdateInput;
    where: ResponsavelCedenteWhereUniqueInput;
};

export type MutationUpdateOneSacadoArgs = {
    data: SacadoUpdateInput;
    where: SacadoWhereUniqueInput;
};

export type MutationUpdateOneSacadoAuditArgs = {
    data: SacadoAuditUpdateInput;
    where: SacadoAuditWhereUniqueInput;
};

export type MutationUpdateOneSacadoContatoArgs = {
    data: SacadoContatoUpdateInput;
    where: SacadoContatoWhereUniqueInput;
};

export type MutationUpdateOneStatusOperacionalAuditArgs = {
    data: StatusOperacionalAuditUpdateInput;
    where: StatusOperacionalAuditWhereUniqueInput;
};

export type MutationUpdateOneStatusOperacionalCedenteMinibancoArgs = {
    data: StatusOperacionalCedenteMinibancoUpdateInput;
    where: StatusOperacionalCedenteMinibancoWhereUniqueInput;
};

export type MutationUpdateOneTakeRateMinibancoArgs = {
    data: TakeRateMinibancoUpdateInput;
    where: TakeRateMinibancoWhereUniqueInput;
};

export type MutationUpdateOneTaxasMinibancoArgs = {
    data: TaxasMinibancoUpdateInput;
    where: TaxasMinibancoWhereUniqueInput;
};

export type MutationUpdateOneTermsAndConditionsArgs = {
    data: TermsAndConditionsUpdateInput;
    where: TermsAndConditionsWhereUniqueInput;
};

export type MutationUpdateOneTipoMovimentacaoArgs = {
    data: TipoMovimentacaoUpdateInput;
    where: TipoMovimentacaoWhereUniqueInput;
};

export type MutationUpdateOneUsuarioArgs = {
    data: UsuarioUpdateInput;
    where: UsuarioWhereUniqueInput;
};

export type MutationUpdateOneUsuarioAuditArgs = {
    data: UsuarioAuditUpdateInput;
    where: UsuarioAuditWhereUniqueInput;
};

export type MutationUpdateOneUsuarioCadastroArgs = {
    data: UsuarioCadastroUpdateInput;
    where: UsuarioCadastroWhereUniqueInput;
};

export type MutationUpdateOneUsuarioCadastroCamposArgs = {
    data: UsuarioCadastroCamposUpdateInput;
    where: UsuarioCadastroCamposWhereUniqueInput;
};

export type MutationUpsertOneAcceptedTermsArgs = {
    create: AcceptedTermsCreateInput;
    update: AcceptedTermsUpdateInput;
    where: AcceptedTermsWhereUniqueInput;
};

export type MutationUpsertOneArquivoArgs = {
    create: ArquivoCreateInput;
    update: ArquivoUpdateInput;
    where: ArquivoWhereUniqueInput;
};

export type MutationUpsertOneArquivoAuditArgs = {
    create: ArquivoAuditCreateInput;
    update: ArquivoAuditUpdateInput;
    where: ArquivoAuditWhereUniqueInput;
};

export type MutationUpsertOneAssignorBankDataArgs = {
    create: AssignorBankDataCreateInput;
    update: AssignorBankDataUpdateInput;
    where: AssignorBankDataWhereUniqueInput;
};

export type MutationUpsertOneAssignorCrmArgs = {
    create: AssignorCrmCreateInput;
    update: AssignorCrmUpdateInput;
    where: AssignorCrmWhereUniqueInput;
};

export type MutationUpsertOneAssignorDocumentTypeArgs = {
    create: AssignorDocumentTypeCreateInput;
    update: AssignorDocumentTypeUpdateInput;
    where: AssignorDocumentTypeWhereUniqueInput;
};

export type MutationUpsertOneAssignorStatusByMinibankArgs = {
    create: AssignorStatusByMinibankCreateInput;
    update: AssignorStatusByMinibankUpdateInput;
    where: AssignorStatusByMinibankWhereUniqueInput;
};

export type MutationUpsertOneBanksArgs = {
    create: BanksCreateInput;
    update: BanksUpdateInput;
    where: BanksWhereUniqueInput;
};

export type MutationUpsertOneCTeArgs = {
    create: CTeCreateInput;
    update: CTeUpdateInput;
    where: CTeWhereUniqueInput;
};

export type MutationUpsertOneCedenteArgs = {
    create: CedenteCreateInput;
    update: CedenteUpdateInput;
    where: CedenteWhereUniqueInput;
};

export type MutationUpsertOneCedenteAlteracaoArgs = {
    create: CedenteAlteracaoCreateInput;
    update: CedenteAlteracaoUpdateInput;
    where: CedenteAlteracaoWhereUniqueInput;
};

export type MutationUpsertOneCedenteAnexosArgs = {
    create: CedenteAnexosCreateInput;
    update: CedenteAnexosUpdateInput;
    where: CedenteAnexosWhereUniqueInput;
};

export type MutationUpsertOneCedenteAuditArgs = {
    create: CedenteAuditCreateInput;
    update: CedenteAuditUpdateInput;
    where: CedenteAuditWhereUniqueInput;
};

export type MutationUpsertOneCedenteCadastroArgs = {
    create: CedenteCadastroCreateInput;
    update: CedenteCadastroUpdateInput;
    where: CedenteCadastroWhereUniqueInput;
};

export type MutationUpsertOneCedenteCamposAlteracaoArgs = {
    create: CedenteCamposAlteracaoCreateInput;
    update: CedenteCamposAlteracaoUpdateInput;
    where: CedenteCamposAlteracaoWhereUniqueInput;
};

export type MutationUpsertOneCedenteCamposCadastroArgs = {
    create: CedenteCamposCadastroCreateInput;
    update: CedenteCamposCadastroUpdateInput;
    where: CedenteCamposCadastroWhereUniqueInput;
};

export type MutationUpsertOneCedenteContatoArgs = {
    create: CedenteContatoCreateInput;
    update: CedenteContatoUpdateInput;
    where: CedenteContatoWhereUniqueInput;
};

export type MutationUpsertOneCedentesInfoArgs = {
    create: CedentesInfoCreateInput;
    update: CedentesInfoUpdateInput;
    where: CedentesInfoWhereUniqueInput;
};

export type MutationUpsertOneCnaeClassesArgs = {
    create: CnaeClassesCreateInput;
    update: CnaeClassesUpdateInput;
    where: CnaeClassesWhereUniqueInput;
};

export type MutationUpsertOneCnaeDivisoesArgs = {
    create: CnaeDivisoesCreateInput;
    update: CnaeDivisoesUpdateInput;
    where: CnaeDivisoesWhereUniqueInput;
};

export type MutationUpsertOneCnaeGruposArgs = {
    create: CnaeGruposCreateInput;
    update: CnaeGruposUpdateInput;
    where: CnaeGruposWhereUniqueInput;
};

export type MutationUpsertOneCnaeSecoesArgs = {
    create: CnaeSecoesCreateInput;
    update: CnaeSecoesUpdateInput;
    where: CnaeSecoesWhereUniqueInput;
};

export type MutationUpsertOneCnaeSubClassesArgs = {
    create: CnaeSubClassesCreateInput;
    update: CnaeSubClassesUpdateInput;
    where: CnaeSubClassesWhereUniqueInput;
};

export type MutationUpsertOneComercialPartnerAuditArgs = {
    create: ComercialPartnerAuditCreateInput;
    update: ComercialPartnerAuditUpdateInput;
    where: ComercialPartnerAuditWhereUniqueInput;
};

export type MutationUpsertOneComercialPartnerCommissionArgs = {
    create: ComercialPartnerCommissionCreateInput;
    update: ComercialPartnerCommissionUpdateInput;
    where: ComercialPartnerCommissionWhereUniqueInput;
};

export type MutationUpsertOneContaBancariaArgs = {
    create: ContaBancariaCreateInput;
    update: ContaBancariaUpdateInput;
    where: ContaBancariaWhereUniqueInput;
};

export type MutationUpsertOneContatoArgs = {
    create: ContatoCreateInput;
    update: ContatoUpdateInput;
    where: ContatoWhereUniqueInput;
};

export type MutationUpsertOneContatoAuditArgs = {
    create: ContatoAuditCreateInput;
    update: ContatoAuditUpdateInput;
    where: ContatoAuditWhereUniqueInput;
};

export type MutationUpsertOneDebenturistaArgs = {
    create: DebenturistaCreateInput;
    update: DebenturistaUpdateInput;
    where: DebenturistaWhereUniqueInput;
};

export type MutationUpsertOneDebenturistaAuditArgs = {
    create: DebenturistaAuditCreateInput;
    update: DebenturistaAuditUpdateInput;
    where: DebenturistaAuditWhereUniqueInput;
};

export type MutationUpsertOneEmpresaArgs = {
    create: EmpresaCreateInput;
    update: EmpresaUpdateInput;
    where: EmpresaWhereUniqueInput;
};

export type MutationUpsertOneEmpresaAuditArgs = {
    create: EmpresaAuditCreateInput;
    update: EmpresaAuditUpdateInput;
    where: EmpresaAuditWhereUniqueInput;
};

export type MutationUpsertOneEnderecoArgs = {
    create: EnderecoCreateInput;
    update: EnderecoUpdateInput;
    where: EnderecoWhereUniqueInput;
};

export type MutationUpsertOneEnderecoAlteracaoArgs = {
    create: EnderecoAlteracaoCreateInput;
    update: EnderecoAlteracaoUpdateInput;
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type MutationUpsertOneEnderecoAuditArgs = {
    create: EnderecoAuditCreateInput;
    update: EnderecoAuditUpdateInput;
    where: EnderecoAuditWhereUniqueInput;
};

export type MutationUpsertOneEnderecoCadastroArgs = {
    create: EnderecoCadastroCreateInput;
    update: EnderecoCadastroUpdateInput;
    where: EnderecoCadastroWhereUniqueInput;
};

export type MutationUpsertOneEnderecoCamposAlteracaoArgs = {
    create: EnderecoCamposAlteracaoCreateInput;
    update: EnderecoCamposAlteracaoUpdateInput;
    where: EnderecoCamposAlteracaoWhereUniqueInput;
};

export type MutationUpsertOneEnderecoCamposCadastroArgs = {
    create: EnderecoCamposCadastroCreateInput;
    update: EnderecoCamposCadastroUpdateInput;
    where: EnderecoCamposCadastroWhereUniqueInput;
};

export type MutationUpsertOneEventArgs = {
    create: EventCreateInput;
    update: EventUpdateInput;
    where: EventWhereUniqueInput;
};

export type MutationUpsertOneExtratoIntegracaoArgs = {
    create: ExtratoIntegracaoCreateInput;
    update: ExtratoIntegracaoUpdateInput;
    where: ExtratoIntegracaoWhereUniqueInput;
};

export type MutationUpsertOneGrupoNotificacaoArgs = {
    create: GrupoNotificacaoCreateInput;
    update: GrupoNotificacaoUpdateInput;
    where: GrupoNotificacaoWhereUniqueInput;
};

export type MutationUpsertOneGruposPermissaoArgs = {
    create: GruposPermissaoCreateInput;
    update: GruposPermissaoUpdateInput;
    where: GruposPermissaoWhereUniqueInput;
};

export type MutationUpsertOneMinibancoArgs = {
    create: MinibancoCreateInput;
    update: MinibancoUpdateInput;
    where: MinibancoWhereUniqueInput;
};

export type MutationUpsertOneMinibancoAuditArgs = {
    create: MinibancoAuditCreateInput;
    update: MinibancoAuditUpdateInput;
    where: MinibancoAuditWhereUniqueInput;
};

export type MutationUpsertOneMinibancoContatoArgs = {
    create: MinibancoContatoCreateInput;
    update: MinibancoContatoUpdateInput;
    where: MinibancoContatoWhereUniqueInput;
};

export type MutationUpsertOneMinibancoExtratoArgs = {
    create: MinibancoExtratoCreateInput;
    update: MinibancoExtratoUpdateInput;
    where: MinibancoExtratoWhereUniqueInput;
};

export type MutationUpsertOneMinibankIntegrationArgs = {
    create: MinibankIntegrationCreateInput;
    update: MinibankIntegrationUpdateInput;
    where: MinibankIntegrationWhereUniqueInput;
};

export type MutationUpsertOneNFeArgs = {
    create: NFeCreateInput;
    update: NFeUpdateInput;
    where: NFeWhereUniqueInput;
};

export type MutationUpsertOneNFeParcelaArgs = {
    create: NFeParcelaCreateInput;
    update: NFeParcelaUpdateInput;
    where: NFeParcelaWhereUniqueInput;
};

export type MutationUpsertOneNivelPermissaoArgs = {
    create: NivelPermissaoCreateInput;
    update: NivelPermissaoUpdateInput;
    where: NivelPermissaoWhereUniqueInput;
};

export type MutationUpsertOneOccupationArgs = {
    create: OccupationCreateInput;
    update: OccupationUpdateInput;
    where: OccupationWhereUniqueInput;
};

export type MutationUpsertOneOccupationAuditArgs = {
    create: OccupationAuditCreateInput;
    update: OccupationAuditUpdateInput;
    where: OccupationAuditWhereUniqueInput;
};

export type MutationUpsertOneOperacaoArgs = {
    create: OperacaoCreateInput;
    update: OperacaoUpdateInput;
    where: OperacaoWhereUniqueInput;
};

export type MutationUpsertOneOperacaoAnexosArgs = {
    create: OperacaoAnexosCreateInput;
    update: OperacaoAnexosUpdateInput;
    where: OperacaoAnexosWhereUniqueInput;
};

export type MutationUpsertOneOperacaoAuditArgs = {
    create: OperacaoAuditCreateInput;
    update: OperacaoAuditUpdateInput;
    where: OperacaoAuditWhereUniqueInput;
};

export type MutationUpsertOneOperacaoChecklistArgs = {
    create: OperacaoChecklistCreateInput;
    update: OperacaoChecklistUpdateInput;
    where: OperacaoChecklistWhereUniqueInput;
};

export type MutationUpsertOneOperacaoIntegracaoArgs = {
    create: OperacaoIntegracaoCreateInput;
    update: OperacaoIntegracaoUpdateInput;
    where: OperacaoIntegracaoWhereUniqueInput;
};

export type MutationUpsertOneOperacaoModalidadeArgs = {
    create: OperacaoModalidadeCreateInput;
    update: OperacaoModalidadeUpdateInput;
    where: OperacaoModalidadeWhereUniqueInput;
};

export type MutationUpsertOneOperacaoRascunhoArgs = {
    create: OperacaoRascunhoCreateInput;
    update: OperacaoRascunhoUpdateInput;
    where: OperacaoRascunhoWhereUniqueInput;
};

export type MutationUpsertOneOperacaoStatusArgs = {
    create: OperacaoStatusCreateInput;
    update: OperacaoStatusUpdateInput;
    where: OperacaoStatusWhereUniqueInput;
};

export type MutationUpsertOneOperacaoSugeridaArgs = {
    create: OperacaoSugeridaCreateInput;
    update: OperacaoSugeridaUpdateInput;
    where: OperacaoSugeridaWhereUniqueInput;
};

export type MutationUpsertOneOperacaoTipoArgs = {
    create: OperacaoTipoCreateInput;
    update: OperacaoTipoUpdateInput;
    where: OperacaoTipoWhereUniqueInput;
};

export type MutationUpsertOneOperacoesRascunhoViewArgs = {
    create: OperacoesRascunhoViewCreateInput;
    update: OperacoesRascunhoViewUpdateInput;
    where: OperacoesRascunhoViewWhereUniqueInput;
};

export type MutationUpsertOneOperationCommisionArgs = {
    create: OperationCommisionCreateInput;
    update: OperationCommisionUpdateInput;
    where: OperationCommisionWhereUniqueInput;
};

export type MutationUpsertOneOperationFeesArgs = {
    create: OperationFeesCreateInput;
    update: OperationFeesUpdateInput;
    where: OperationFeesWhereUniqueInput;
};

export type MutationUpsertOneOperationUpdatesArgs = {
    create: OperationUpdatesCreateInput;
    update: OperationUpdatesUpdateInput;
    where: OperationUpdatesWhereUniqueInput;
};

export type MutationUpsertOneOperationsInfoArgs = {
    create: OperationsInfoCreateInput;
    update: OperationsInfoUpdateInput;
    where: OperationsInfoWhereUniqueInput;
};

export type MutationUpsertOneParceiroComercialArgs = {
    create: ParceiroComercialCreateInput;
    update: ParceiroComercialUpdateInput;
    where: ParceiroComercialWhereUniqueInput;
};

export type MutationUpsertOnePayableUpdatesArgs = {
    create: PayableUpdatesCreateInput;
    update: PayableUpdatesUpdateInput;
    where: PayableUpdatesWhereUniqueInput;
};

export type MutationUpsertOnePermissoesGruposArgs = {
    create: PermissoesGruposCreateInput;
    update: PermissoesGruposUpdateInput;
    where: PermissoesGruposWhereUniqueInput;
};

export type MutationUpsertOnePreCadastroCedenteArgs = {
    create: PreCadastroCedenteCreateInput;
    update: PreCadastroCedenteUpdateInput;
    where: PreCadastroCedenteWhereUniqueInput;
};

export type MutationUpsertOneRecebiveisInfoArgs = {
    create: RecebiveisInfoCreateInput;
    update: RecebiveisInfoUpdateInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type MutationUpsertOneRecebiveisToAnticipateArgs = {
    create: RecebiveisToAnticipateCreateInput;
    update: RecebiveisToAnticipateUpdateInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type MutationUpsertOneRecebivelArgs = {
    create: RecebivelCreateInput;
    update: RecebivelUpdateInput;
    where: RecebivelWhereUniqueInput;
};

export type MutationUpsertOneRecebivelAuditArgs = {
    create: RecebivelAuditCreateInput;
    update: RecebivelAuditUpdateInput;
    where: RecebivelAuditWhereUniqueInput;
};

export type MutationUpsertOneRecebivelIntegracaoArgs = {
    create: RecebivelIntegracaoCreateInput;
    update: RecebivelIntegracaoUpdateInput;
    where: RecebivelIntegracaoWhereUniqueInput;
};

export type MutationUpsertOneRecebivelOperadoArgs = {
    create: RecebivelOperadoCreateInput;
    update: RecebivelOperadoUpdateInput;
    where: RecebivelOperadoWhereUniqueInput;
};

export type MutationUpsertOneRecebivelPagarContasArgs = {
    create: RecebivelPagarContasCreateInput;
    update: RecebivelPagarContasUpdateInput;
    where: RecebivelPagarContasWhereUniqueInput;
};

export type MutationUpsertOneRepresentativeArgs = {
    create: RepresentativeCreateInput;
    update: RepresentativeUpdateInput;
    where: RepresentativeWhereUniqueInput;
};

export type MutationUpsertOneResponsavelCedenteArgs = {
    create: ResponsavelCedenteCreateInput;
    update: ResponsavelCedenteUpdateInput;
    where: ResponsavelCedenteWhereUniqueInput;
};

export type MutationUpsertOneSacadoArgs = {
    create: SacadoCreateInput;
    update: SacadoUpdateInput;
    where: SacadoWhereUniqueInput;
};

export type MutationUpsertOneSacadoAuditArgs = {
    create: SacadoAuditCreateInput;
    update: SacadoAuditUpdateInput;
    where: SacadoAuditWhereUniqueInput;
};

export type MutationUpsertOneSacadoContatoArgs = {
    create: SacadoContatoCreateInput;
    update: SacadoContatoUpdateInput;
    where: SacadoContatoWhereUniqueInput;
};

export type MutationUpsertOneStatusOperacionalAuditArgs = {
    create: StatusOperacionalAuditCreateInput;
    update: StatusOperacionalAuditUpdateInput;
    where: StatusOperacionalAuditWhereUniqueInput;
};

export type MutationUpsertOneStatusOperacionalCedenteMinibancoArgs = {
    create: StatusOperacionalCedenteMinibancoCreateInput;
    update: StatusOperacionalCedenteMinibancoUpdateInput;
    where: StatusOperacionalCedenteMinibancoWhereUniqueInput;
};

export type MutationUpsertOneTakeRateMinibancoArgs = {
    create: TakeRateMinibancoCreateInput;
    update: TakeRateMinibancoUpdateInput;
    where: TakeRateMinibancoWhereUniqueInput;
};

export type MutationUpsertOneTaxasMinibancoArgs = {
    create: TaxasMinibancoCreateInput;
    update: TaxasMinibancoUpdateInput;
    where: TaxasMinibancoWhereUniqueInput;
};

export type MutationUpsertOneTermsAndConditionsArgs = {
    create: TermsAndConditionsCreateInput;
    update: TermsAndConditionsUpdateInput;
    where: TermsAndConditionsWhereUniqueInput;
};

export type MutationUpsertOneTipoMovimentacaoArgs = {
    create: TipoMovimentacaoCreateInput;
    update: TipoMovimentacaoUpdateInput;
    where: TipoMovimentacaoWhereUniqueInput;
};

export type MutationUpsertOneUsuarioArgs = {
    create: UsuarioCreateInput;
    update: UsuarioUpdateInput;
    where: UsuarioWhereUniqueInput;
};

export type MutationUpsertOneUsuarioAuditArgs = {
    create: UsuarioAuditCreateInput;
    update: UsuarioAuditUpdateInput;
    where: UsuarioAuditWhereUniqueInput;
};

export type MutationUpsertOneUsuarioCadastroArgs = {
    create: UsuarioCadastroCreateInput;
    update: UsuarioCadastroUpdateInput;
    where: UsuarioCadastroWhereUniqueInput;
};

export type MutationUpsertOneUsuarioCadastroCamposArgs = {
    create: UsuarioCadastroCamposCreateInput;
    update: UsuarioCadastroCamposUpdateInput;
    where: UsuarioCadastroCamposWhereUniqueInput;
};

export type NFe = {
    __typename?: "NFe";
    RecebiveisInfo: Array<RecebiveisInfo>;
    RecebiveisToAnticipate: Array<RecebiveisToAnticipate>;
    _count?: Maybe<NFeCount>;
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    idExterno?: Maybe<Scalars["String"]>;
    inativo: Scalars["Boolean"];
    nfeKey: Scalars["String"];
    parcelas: Array<NFeParcela>;
    updatedAt: Scalars["DateTime"];
};

export type NFeRecebiveisInfoArgs = {
    cursor?: InputMaybe<RecebiveisInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebiveisInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebiveisInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisInfoWhereInput>;
};

export type NFeRecebiveisToAnticipateArgs = {
    cursor?: InputMaybe<RecebiveisToAnticipateWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebiveisToAnticipateScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebiveisToAnticipateOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisToAnticipateWhereInput>;
};

export type NFeParcelasArgs = {
    cursor?: InputMaybe<NFeParcelaWhereUniqueInput>;
    distinct?: InputMaybe<Array<NFeParcelaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<NFeParcelaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NFeParcelaWhereInput>;
};

export type NFeAvgAggregate = {
    __typename?: "NFeAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
};

export type NFeAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type NFeCount = {
    __typename?: "NFeCount";
    RecebiveisInfo: Scalars["Int"];
    RecebiveisToAnticipate: Scalars["Int"];
    parcelas: Scalars["Int"];
};

export type NFeCountAggregate = {
    __typename?: "NFeCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    idExterno: Scalars["Int"];
    inativo: Scalars["Int"];
    nfeKey: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type NFeCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    idExterno?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nfeKey?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type NFeCreateInput = {
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutNfeInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutNfeInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    idExterno?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nfeKey: Scalars["String"];
    parcelas?: InputMaybe<NFeParcelaCreateNestedManyWithoutNfeInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type NFeCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    idExterno?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nfeKey: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type NFeCreateNestedOneWithoutParcelasInput = {
    connect?: InputMaybe<NFeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<NFeCreateOrConnectWithoutParcelasInput>;
    create?: InputMaybe<NFeCreateWithoutParcelasInput>;
};

export type NFeCreateNestedOneWithoutRecebiveisInfoInput = {
    connect?: InputMaybe<NFeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<NFeCreateOrConnectWithoutRecebiveisInfoInput>;
    create?: InputMaybe<NFeCreateWithoutRecebiveisInfoInput>;
};

export type NFeCreateNestedOneWithoutRecebiveisToAnticipateInput = {
    connect?: InputMaybe<NFeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<NFeCreateOrConnectWithoutRecebiveisToAnticipateInput>;
    create?: InputMaybe<NFeCreateWithoutRecebiveisToAnticipateInput>;
};

export type NFeCreateOrConnectWithoutParcelasInput = {
    create: NFeCreateWithoutParcelasInput;
    where: NFeWhereUniqueInput;
};

export type NFeCreateOrConnectWithoutRecebiveisInfoInput = {
    create: NFeCreateWithoutRecebiveisInfoInput;
    where: NFeWhereUniqueInput;
};

export type NFeCreateOrConnectWithoutRecebiveisToAnticipateInput = {
    create: NFeCreateWithoutRecebiveisToAnticipateInput;
    where: NFeWhereUniqueInput;
};

export type NFeCreateWithoutParcelasInput = {
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutNfeInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutNfeInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    idExterno?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nfeKey: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type NFeCreateWithoutRecebiveisInfoInput = {
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutNfeInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    idExterno?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nfeKey: Scalars["String"];
    parcelas?: InputMaybe<NFeParcelaCreateNestedManyWithoutNfeInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type NFeCreateWithoutRecebiveisToAnticipateInput = {
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutNfeInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    idExterno?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nfeKey: Scalars["String"];
    parcelas?: InputMaybe<NFeParcelaCreateNestedManyWithoutNfeInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type NFeGroupBy = {
    __typename?: "NFeGroupBy";
    _avg?: Maybe<NFeAvgAggregate>;
    _count?: Maybe<NFeCountAggregate>;
    _max?: Maybe<NFeMaxAggregate>;
    _min?: Maybe<NFeMinAggregate>;
    _sum?: Maybe<NFeSumAggregate>;
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    idExterno?: Maybe<Scalars["String"]>;
    inativo: Scalars["Boolean"];
    nfeKey: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type NFeMaxAggregate = {
    __typename?: "NFeMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    idExterno?: Maybe<Scalars["String"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    nfeKey?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type NFeMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    idExterno?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nfeKey?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type NFeMinAggregate = {
    __typename?: "NFeMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    idExterno?: Maybe<Scalars["String"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    nfeKey?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type NFeMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    idExterno?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nfeKey?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type NFeOrderByWithAggregationInput = {
    _avg?: InputMaybe<NFeAvgOrderByAggregateInput>;
    _count?: InputMaybe<NFeCountOrderByAggregateInput>;
    _max?: InputMaybe<NFeMaxOrderByAggregateInput>;
    _min?: InputMaybe<NFeMinOrderByAggregateInput>;
    _sum?: InputMaybe<NFeSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    idExterno?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nfeKey?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type NFeOrderByWithRelationInput = {
    RecebiveisInfo?: InputMaybe<RecebiveisInfoOrderByRelationAggregateInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    idExterno?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nfeKey?: InputMaybe<SortOrder>;
    parcelas?: InputMaybe<NFeParcelaOrderByRelationAggregateInput>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type NFeParcela = {
    __typename?: "NFeParcela";
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    nfe: NFe;
    nfeId: Scalars["Int"];
    recebivel: Recebivel;
    recebivelId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type NFeParcelaAvgAggregate = {
    __typename?: "NFeParcelaAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    nfeId?: Maybe<Scalars["Float"]>;
    recebivelId?: Maybe<Scalars["Float"]>;
};

export type NFeParcelaAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type NFeParcelaCountAggregate = {
    __typename?: "NFeParcelaCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    nfeId: Scalars["Int"];
    recebivelId: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type NFeParcelaCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type NFeParcelaCreateInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nfe: NFeCreateNestedOneWithoutParcelasInput;
    recebivel: RecebivelCreateNestedOneWithoutParcelaNFeInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type NFeParcelaCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nfeId: Scalars["Int"];
    recebivelId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type NFeParcelaCreateManyNfeInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    recebivelId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type NFeParcelaCreateManyNfeInputEnvelope = {
    data: Array<NFeParcelaCreateManyNfeInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type NFeParcelaCreateNestedManyWithoutNfeInput = {
    connect?: InputMaybe<Array<NFeParcelaWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<NFeParcelaCreateOrConnectWithoutNfeInput>>;
    create?: InputMaybe<Array<NFeParcelaCreateWithoutNfeInput>>;
    createMany?: InputMaybe<NFeParcelaCreateManyNfeInputEnvelope>;
};

export type NFeParcelaCreateNestedOneWithoutRecebivelInput = {
    connect?: InputMaybe<NFeParcelaWhereUniqueInput>;
    connectOrCreate?: InputMaybe<NFeParcelaCreateOrConnectWithoutRecebivelInput>;
    create?: InputMaybe<NFeParcelaCreateWithoutRecebivelInput>;
};

export type NFeParcelaCreateOrConnectWithoutNfeInput = {
    create: NFeParcelaCreateWithoutNfeInput;
    where: NFeParcelaWhereUniqueInput;
};

export type NFeParcelaCreateOrConnectWithoutRecebivelInput = {
    create: NFeParcelaCreateWithoutRecebivelInput;
    where: NFeParcelaWhereUniqueInput;
};

export type NFeParcelaCreateWithoutNfeInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    recebivel: RecebivelCreateNestedOneWithoutParcelaNFeInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type NFeParcelaCreateWithoutRecebivelInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nfe: NFeCreateNestedOneWithoutParcelasInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type NFeParcelaGroupBy = {
    __typename?: "NFeParcelaGroupBy";
    _avg?: Maybe<NFeParcelaAvgAggregate>;
    _count?: Maybe<NFeParcelaCountAggregate>;
    _max?: Maybe<NFeParcelaMaxAggregate>;
    _min?: Maybe<NFeParcelaMinAggregate>;
    _sum?: Maybe<NFeParcelaSumAggregate>;
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    nfeId: Scalars["Int"];
    recebivelId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type NFeParcelaListRelationFilter = {
    every?: InputMaybe<NFeParcelaWhereInput>;
    none?: InputMaybe<NFeParcelaWhereInput>;
    some?: InputMaybe<NFeParcelaWhereInput>;
};

export type NFeParcelaMaxAggregate = {
    __typename?: "NFeParcelaMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    nfeId?: Maybe<Scalars["Int"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type NFeParcelaMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type NFeParcelaMinAggregate = {
    __typename?: "NFeParcelaMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    nfeId?: Maybe<Scalars["Int"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type NFeParcelaMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type NFeParcelaOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type NFeParcelaOrderByWithAggregationInput = {
    _avg?: InputMaybe<NFeParcelaAvgOrderByAggregateInput>;
    _count?: InputMaybe<NFeParcelaCountOrderByAggregateInput>;
    _max?: InputMaybe<NFeParcelaMaxOrderByAggregateInput>;
    _min?: InputMaybe<NFeParcelaMinOrderByAggregateInput>;
    _sum?: InputMaybe<NFeParcelaSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type NFeParcelaOrderByWithRelationInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nfe?: InputMaybe<NFeOrderByWithRelationInput>;
    nfeId?: InputMaybe<SortOrder>;
    recebivel?: InputMaybe<RecebivelOrderByWithRelationInput>;
    recebivelId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type NFeParcelaRelationFilter = {
    is?: InputMaybe<NFeParcelaWhereInput>;
    isNot?: InputMaybe<NFeParcelaWhereInput>;
};

export enum NFeParcelaScalarFieldEnum {
    CreatedAt = "createdAt",
    Id = "id",
    Inativo = "inativo",
    NfeId = "nfeId",
    RecebivelId = "recebivelId",
    UpdatedAt = "updatedAt",
}

export type NFeParcelaScalarWhereInput = {
    AND?: InputMaybe<Array<NFeParcelaScalarWhereInput>>;
    NOT?: InputMaybe<Array<NFeParcelaScalarWhereInput>>;
    OR?: InputMaybe<Array<NFeParcelaScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    nfeId?: InputMaybe<IntFilter>;
    recebivelId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type NFeParcelaScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<NFeParcelaScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<NFeParcelaScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<NFeParcelaScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    nfeId?: InputMaybe<IntWithAggregatesFilter>;
    recebivelId?: InputMaybe<IntWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type NFeParcelaSumAggregate = {
    __typename?: "NFeParcelaSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    nfeId?: Maybe<Scalars["Int"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
};

export type NFeParcelaSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type NFeParcelaUpdateInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nfe?: InputMaybe<NFeUpdateOneRequiredWithoutParcelasNestedInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneRequiredWithoutParcelaNFeNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NFeParcelaUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NFeParcelaUpdateManyWithWhereWithoutNfeInput = {
    data: NFeParcelaUpdateManyMutationInput;
    where: NFeParcelaScalarWhereInput;
};

export type NFeParcelaUpdateManyWithoutNfeNestedInput = {
    connect?: InputMaybe<Array<NFeParcelaWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<NFeParcelaCreateOrConnectWithoutNfeInput>>;
    create?: InputMaybe<Array<NFeParcelaCreateWithoutNfeInput>>;
    createMany?: InputMaybe<NFeParcelaCreateManyNfeInputEnvelope>;
    delete?: InputMaybe<Array<NFeParcelaWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<NFeParcelaScalarWhereInput>>;
    disconnect?: InputMaybe<Array<NFeParcelaWhereUniqueInput>>;
    set?: InputMaybe<Array<NFeParcelaWhereUniqueInput>>;
    update?: InputMaybe<Array<NFeParcelaUpdateWithWhereUniqueWithoutNfeInput>>;
    updateMany?: InputMaybe<Array<NFeParcelaUpdateManyWithWhereWithoutNfeInput>>;
    upsert?: InputMaybe<Array<NFeParcelaUpsertWithWhereUniqueWithoutNfeInput>>;
};

export type NFeParcelaUpdateOneWithoutRecebivelNestedInput = {
    connect?: InputMaybe<NFeParcelaWhereUniqueInput>;
    connectOrCreate?: InputMaybe<NFeParcelaCreateOrConnectWithoutRecebivelInput>;
    create?: InputMaybe<NFeParcelaCreateWithoutRecebivelInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<NFeParcelaUpdateWithoutRecebivelInput>;
    upsert?: InputMaybe<NFeParcelaUpsertWithoutRecebivelInput>;
};

export type NFeParcelaUpdateWithWhereUniqueWithoutNfeInput = {
    data: NFeParcelaUpdateWithoutNfeInput;
    where: NFeParcelaWhereUniqueInput;
};

export type NFeParcelaUpdateWithoutNfeInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneRequiredWithoutParcelaNFeNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NFeParcelaUpdateWithoutRecebivelInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nfe?: InputMaybe<NFeUpdateOneRequiredWithoutParcelasNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NFeParcelaUpsertWithWhereUniqueWithoutNfeInput = {
    create: NFeParcelaCreateWithoutNfeInput;
    update: NFeParcelaUpdateWithoutNfeInput;
    where: NFeParcelaWhereUniqueInput;
};

export type NFeParcelaUpsertWithoutRecebivelInput = {
    create: NFeParcelaCreateWithoutRecebivelInput;
    update: NFeParcelaUpdateWithoutRecebivelInput;
};

export type NFeParcelaWhereInput = {
    AND?: InputMaybe<Array<NFeParcelaWhereInput>>;
    NOT?: InputMaybe<Array<NFeParcelaWhereInput>>;
    OR?: InputMaybe<Array<NFeParcelaWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    nfe?: InputMaybe<NFeRelationFilter>;
    nfeId?: InputMaybe<IntFilter>;
    recebivel?: InputMaybe<RecebivelRelationFilter>;
    recebivelId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type NFeParcelaWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
    recebivelId?: InputMaybe<Scalars["Int"]>;
};

export type NFeRelationFilter = {
    is?: InputMaybe<NFeWhereInput>;
    isNot?: InputMaybe<NFeWhereInput>;
};

export enum NFeScalarFieldEnum {
    CreatedAt = "createdAt",
    Id = "id",
    IdExterno = "idExterno",
    Inativo = "inativo",
    NfeKey = "nfeKey",
    UpdatedAt = "updatedAt",
}

export type NFeScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<NFeScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<NFeScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<NFeScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    idExterno?: InputMaybe<StringNullableWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    nfeKey?: InputMaybe<StringWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type NFeSumAggregate = {
    __typename?: "NFeSumAggregate";
    id?: Maybe<Scalars["Int"]>;
};

export type NFeSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type NFeUpdateInput = {
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutNfeNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutNfeNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    idExterno?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nfeKey?: InputMaybe<StringFieldUpdateOperationsInput>;
    parcelas?: InputMaybe<NFeParcelaUpdateManyWithoutNfeNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NFeUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    idExterno?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nfeKey?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NFeUpdateOneRequiredWithoutParcelasNestedInput = {
    connect?: InputMaybe<NFeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<NFeCreateOrConnectWithoutParcelasInput>;
    create?: InputMaybe<NFeCreateWithoutParcelasInput>;
    update?: InputMaybe<NFeUpdateWithoutParcelasInput>;
    upsert?: InputMaybe<NFeUpsertWithoutParcelasInput>;
};

export type NFeUpdateOneWithoutRecebiveisInfoNestedInput = {
    connect?: InputMaybe<NFeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<NFeCreateOrConnectWithoutRecebiveisInfoInput>;
    create?: InputMaybe<NFeCreateWithoutRecebiveisInfoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<NFeUpdateWithoutRecebiveisInfoInput>;
    upsert?: InputMaybe<NFeUpsertWithoutRecebiveisInfoInput>;
};

export type NFeUpdateOneWithoutRecebiveisToAnticipateNestedInput = {
    connect?: InputMaybe<NFeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<NFeCreateOrConnectWithoutRecebiveisToAnticipateInput>;
    create?: InputMaybe<NFeCreateWithoutRecebiveisToAnticipateInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<NFeUpdateWithoutRecebiveisToAnticipateInput>;
    upsert?: InputMaybe<NFeUpsertWithoutRecebiveisToAnticipateInput>;
};

export type NFeUpdateWithoutParcelasInput = {
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutNfeNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutNfeNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    idExterno?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nfeKey?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NFeUpdateWithoutRecebiveisInfoInput = {
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutNfeNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    idExterno?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nfeKey?: InputMaybe<StringFieldUpdateOperationsInput>;
    parcelas?: InputMaybe<NFeParcelaUpdateManyWithoutNfeNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NFeUpdateWithoutRecebiveisToAnticipateInput = {
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutNfeNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    idExterno?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nfeKey?: InputMaybe<StringFieldUpdateOperationsInput>;
    parcelas?: InputMaybe<NFeParcelaUpdateManyWithoutNfeNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NFeUpsertWithoutParcelasInput = {
    create: NFeCreateWithoutParcelasInput;
    update: NFeUpdateWithoutParcelasInput;
};

export type NFeUpsertWithoutRecebiveisInfoInput = {
    create: NFeCreateWithoutRecebiveisInfoInput;
    update: NFeUpdateWithoutRecebiveisInfoInput;
};

export type NFeUpsertWithoutRecebiveisToAnticipateInput = {
    create: NFeCreateWithoutRecebiveisToAnticipateInput;
    update: NFeUpdateWithoutRecebiveisToAnticipateInput;
};

export type NFeWhereInput = {
    AND?: InputMaybe<Array<NFeWhereInput>>;
    NOT?: InputMaybe<Array<NFeWhereInput>>;
    OR?: InputMaybe<Array<NFeWhereInput>>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoListRelationFilter>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    idExterno?: InputMaybe<StringNullableFilter>;
    inativo?: InputMaybe<BoolFilter>;
    nfeKey?: InputMaybe<StringFilter>;
    parcelas?: InputMaybe<NFeParcelaListRelationFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type NFeWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
    idExterno?: InputMaybe<Scalars["String"]>;
    nfeKey?: InputMaybe<Scalars["String"]>;
};

export type NestedBoolFilter = {
    equals?: InputMaybe<Scalars["Boolean"]>;
    not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedBoolFilter>;
    _min?: InputMaybe<NestedBoolFilter>;
    equals?: InputMaybe<Scalars["Boolean"]>;
    not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
    equals?: InputMaybe<Scalars["DateTime"]>;
    gt?: InputMaybe<Scalars["DateTime"]>;
    gte?: InputMaybe<Scalars["DateTime"]>;
    in?: InputMaybe<Array<Scalars["DateTime"]>>;
    lt?: InputMaybe<Scalars["DateTime"]>;
    lte?: InputMaybe<Scalars["DateTime"]>;
    not?: InputMaybe<NestedDateTimeFilter>;
    notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type NestedDateTimeNullableFilter = {
    equals?: InputMaybe<Scalars["DateTime"]>;
    gt?: InputMaybe<Scalars["DateTime"]>;
    gte?: InputMaybe<Scalars["DateTime"]>;
    in?: InputMaybe<Array<Scalars["DateTime"]>>;
    lt?: InputMaybe<Scalars["DateTime"]>;
    lte?: InputMaybe<Scalars["DateTime"]>;
    not?: InputMaybe<NestedDateTimeNullableFilter>;
    notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedDateTimeNullableFilter>;
    _min?: InputMaybe<NestedDateTimeNullableFilter>;
    equals?: InputMaybe<Scalars["DateTime"]>;
    gt?: InputMaybe<Scalars["DateTime"]>;
    gte?: InputMaybe<Scalars["DateTime"]>;
    in?: InputMaybe<Array<Scalars["DateTime"]>>;
    lt?: InputMaybe<Scalars["DateTime"]>;
    lte?: InputMaybe<Scalars["DateTime"]>;
    not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type NestedDateTimeWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedDateTimeFilter>;
    _min?: InputMaybe<NestedDateTimeFilter>;
    equals?: InputMaybe<Scalars["DateTime"]>;
    gt?: InputMaybe<Scalars["DateTime"]>;
    gte?: InputMaybe<Scalars["DateTime"]>;
    in?: InputMaybe<Array<Scalars["DateTime"]>>;
    lt?: InputMaybe<Scalars["DateTime"]>;
    lte?: InputMaybe<Scalars["DateTime"]>;
    not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type NestedDecimalFilter = {
    equals?: InputMaybe<Scalars["Decimal"]>;
    gt?: InputMaybe<Scalars["Decimal"]>;
    gte?: InputMaybe<Scalars["Decimal"]>;
    in?: InputMaybe<Array<Scalars["Decimal"]>>;
    lt?: InputMaybe<Scalars["Decimal"]>;
    lte?: InputMaybe<Scalars["Decimal"]>;
    not?: InputMaybe<NestedDecimalFilter>;
    notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type NestedDecimalNullableFilter = {
    equals?: InputMaybe<Scalars["Decimal"]>;
    gt?: InputMaybe<Scalars["Decimal"]>;
    gte?: InputMaybe<Scalars["Decimal"]>;
    in?: InputMaybe<Array<Scalars["Decimal"]>>;
    lt?: InputMaybe<Scalars["Decimal"]>;
    lte?: InputMaybe<Scalars["Decimal"]>;
    not?: InputMaybe<NestedDecimalNullableFilter>;
    notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type NestedDecimalNullableWithAggregatesFilter = {
    _avg?: InputMaybe<NestedDecimalNullableFilter>;
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedDecimalNullableFilter>;
    _min?: InputMaybe<NestedDecimalNullableFilter>;
    _sum?: InputMaybe<NestedDecimalNullableFilter>;
    equals?: InputMaybe<Scalars["Decimal"]>;
    gt?: InputMaybe<Scalars["Decimal"]>;
    gte?: InputMaybe<Scalars["Decimal"]>;
    in?: InputMaybe<Array<Scalars["Decimal"]>>;
    lt?: InputMaybe<Scalars["Decimal"]>;
    lte?: InputMaybe<Scalars["Decimal"]>;
    not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type NestedDecimalWithAggregatesFilter = {
    _avg?: InputMaybe<NestedDecimalFilter>;
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedDecimalFilter>;
    _min?: InputMaybe<NestedDecimalFilter>;
    _sum?: InputMaybe<NestedDecimalFilter>;
    equals?: InputMaybe<Scalars["Decimal"]>;
    gt?: InputMaybe<Scalars["Decimal"]>;
    gte?: InputMaybe<Scalars["Decimal"]>;
    in?: InputMaybe<Array<Scalars["Decimal"]>>;
    lt?: InputMaybe<Scalars["Decimal"]>;
    lte?: InputMaybe<Scalars["Decimal"]>;
    not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["Decimal"]>>;
};

export type NestedEnumAuditAcoesFilter = {
    equals?: InputMaybe<AuditAcoes>;
    in?: InputMaybe<Array<AuditAcoes>>;
    not?: InputMaybe<NestedEnumAuditAcoesFilter>;
    notIn?: InputMaybe<Array<AuditAcoes>>;
};

export type NestedEnumAuditAcoesWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumAuditAcoesFilter>;
    _min?: InputMaybe<NestedEnumAuditAcoesFilter>;
    equals?: InputMaybe<AuditAcoes>;
    in?: InputMaybe<Array<AuditAcoes>>;
    not?: InputMaybe<NestedEnumAuditAcoesWithAggregatesFilter>;
    notIn?: InputMaybe<Array<AuditAcoes>>;
};

export type NestedEnumCadastroStatusFilter = {
    equals?: InputMaybe<CadastroStatus>;
    in?: InputMaybe<Array<CadastroStatus>>;
    not?: InputMaybe<NestedEnumCadastroStatusFilter>;
    notIn?: InputMaybe<Array<CadastroStatus>>;
};

export type NestedEnumCadastroStatusWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumCadastroStatusFilter>;
    _min?: InputMaybe<NestedEnumCadastroStatusFilter>;
    equals?: InputMaybe<CadastroStatus>;
    in?: InputMaybe<Array<CadastroStatus>>;
    not?: InputMaybe<NestedEnumCadastroStatusWithAggregatesFilter>;
    notIn?: InputMaybe<Array<CadastroStatus>>;
};

export type NestedEnumCedenteStatusFilter = {
    equals?: InputMaybe<CedenteStatus>;
    in?: InputMaybe<Array<CedenteStatus>>;
    not?: InputMaybe<NestedEnumCedenteStatusFilter>;
    notIn?: InputMaybe<Array<CedenteStatus>>;
};

export type NestedEnumCedenteStatusWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumCedenteStatusFilter>;
    _min?: InputMaybe<NestedEnumCedenteStatusFilter>;
    equals?: InputMaybe<CedenteStatus>;
    in?: InputMaybe<Array<CedenteStatus>>;
    not?: InputMaybe<NestedEnumCedenteStatusWithAggregatesFilter>;
    notIn?: InputMaybe<Array<CedenteStatus>>;
};

export type NestedEnumCrmTypeFilter = {
    equals?: InputMaybe<CrmType>;
    in?: InputMaybe<Array<CrmType>>;
    not?: InputMaybe<NestedEnumCrmTypeFilter>;
    notIn?: InputMaybe<Array<CrmType>>;
};

export type NestedEnumCrmTypeWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumCrmTypeFilter>;
    _min?: InputMaybe<NestedEnumCrmTypeFilter>;
    equals?: InputMaybe<CrmType>;
    in?: InputMaybe<Array<CrmType>>;
    not?: InputMaybe<NestedEnumCrmTypeWithAggregatesFilter>;
    notIn?: InputMaybe<Array<CrmType>>;
};

export type NestedEnumDigitalSignatureNullableFilter = {
    equals?: InputMaybe<DigitalSignature>;
    in?: InputMaybe<Array<DigitalSignature>>;
    not?: InputMaybe<NestedEnumDigitalSignatureNullableFilter>;
    notIn?: InputMaybe<Array<DigitalSignature>>;
};

export type NestedEnumDigitalSignatureNullableWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedEnumDigitalSignatureNullableFilter>;
    _min?: InputMaybe<NestedEnumDigitalSignatureNullableFilter>;
    equals?: InputMaybe<DigitalSignature>;
    in?: InputMaybe<Array<DigitalSignature>>;
    not?: InputMaybe<NestedEnumDigitalSignatureNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<DigitalSignature>>;
};

export type NestedEnumErpFilter = {
    equals?: InputMaybe<Erp>;
    in?: InputMaybe<Array<Erp>>;
    not?: InputMaybe<NestedEnumErpFilter>;
    notIn?: InputMaybe<Array<Erp>>;
};

export type NestedEnumErpWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumErpFilter>;
    _min?: InputMaybe<NestedEnumErpFilter>;
    equals?: InputMaybe<Erp>;
    in?: InputMaybe<Array<Erp>>;
    not?: InputMaybe<NestedEnumErpWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Erp>>;
};

export type NestedEnumEntidadeFilter = {
    equals?: InputMaybe<Entidade>;
    in?: InputMaybe<Array<Entidade>>;
    not?: InputMaybe<NestedEnumEntidadeFilter>;
    notIn?: InputMaybe<Array<Entidade>>;
};

export type NestedEnumEntidadeWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumEntidadeFilter>;
    _min?: InputMaybe<NestedEnumEntidadeFilter>;
    equals?: InputMaybe<Entidade>;
    in?: InputMaybe<Array<Entidade>>;
    not?: InputMaybe<NestedEnumEntidadeWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Entidade>>;
};

export type NestedEnumNivelAcessoFilter = {
    equals?: InputMaybe<NivelAcesso>;
    in?: InputMaybe<Array<NivelAcesso>>;
    not?: InputMaybe<NestedEnumNivelAcessoFilter>;
    notIn?: InputMaybe<Array<NivelAcesso>>;
};

export type NestedEnumNivelAcessoWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumNivelAcessoFilter>;
    _min?: InputMaybe<NestedEnumNivelAcessoFilter>;
    equals?: InputMaybe<NivelAcesso>;
    in?: InputMaybe<Array<NivelAcesso>>;
    not?: InputMaybe<NestedEnumNivelAcessoWithAggregatesFilter>;
    notIn?: InputMaybe<Array<NivelAcesso>>;
};

export type NestedEnumOrigemCedenteFilter = {
    equals?: InputMaybe<OrigemCedente>;
    in?: InputMaybe<Array<OrigemCedente>>;
    not?: InputMaybe<NestedEnumOrigemCedenteFilter>;
    notIn?: InputMaybe<Array<OrigemCedente>>;
};

export type NestedEnumOrigemCedenteWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumOrigemCedenteFilter>;
    _min?: InputMaybe<NestedEnumOrigemCedenteFilter>;
    equals?: InputMaybe<OrigemCedente>;
    in?: InputMaybe<Array<OrigemCedente>>;
    not?: InputMaybe<NestedEnumOrigemCedenteWithAggregatesFilter>;
    notIn?: InputMaybe<Array<OrigemCedente>>;
};

export type NestedEnumOrigemFilter = {
    equals?: InputMaybe<Origem>;
    in?: InputMaybe<Array<Origem>>;
    not?: InputMaybe<NestedEnumOrigemFilter>;
    notIn?: InputMaybe<Array<Origem>>;
};

export type NestedEnumOrigemWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumOrigemFilter>;
    _min?: InputMaybe<NestedEnumOrigemFilter>;
    equals?: InputMaybe<Origem>;
    in?: InputMaybe<Array<Origem>>;
    not?: InputMaybe<NestedEnumOrigemWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Origem>>;
};

export type NestedEnumPayableTypeFilter = {
    equals?: InputMaybe<PayableType>;
    in?: InputMaybe<Array<PayableType>>;
    not?: InputMaybe<NestedEnumPayableTypeFilter>;
    notIn?: InputMaybe<Array<PayableType>>;
};

export type NestedEnumPayableTypeNullableFilter = {
    equals?: InputMaybe<PayableType>;
    in?: InputMaybe<Array<PayableType>>;
    not?: InputMaybe<NestedEnumPayableTypeNullableFilter>;
    notIn?: InputMaybe<Array<PayableType>>;
};

export type NestedEnumPayableTypeNullableWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedEnumPayableTypeNullableFilter>;
    _min?: InputMaybe<NestedEnumPayableTypeNullableFilter>;
    equals?: InputMaybe<PayableType>;
    in?: InputMaybe<Array<PayableType>>;
    not?: InputMaybe<NestedEnumPayableTypeNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<PayableType>>;
};

export type NestedEnumPayableTypeWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumPayableTypeFilter>;
    _min?: InputMaybe<NestedEnumPayableTypeFilter>;
    equals?: InputMaybe<PayableType>;
    in?: InputMaybe<Array<PayableType>>;
    not?: InputMaybe<NestedEnumPayableTypeWithAggregatesFilter>;
    notIn?: InputMaybe<Array<PayableType>>;
};

export type NestedEnumPixKeyTypeNullableFilter = {
    equals?: InputMaybe<PixKeyType>;
    in?: InputMaybe<Array<PixKeyType>>;
    not?: InputMaybe<NestedEnumPixKeyTypeNullableFilter>;
    notIn?: InputMaybe<Array<PixKeyType>>;
};

export type NestedEnumPixKeyTypeNullableWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedEnumPixKeyTypeNullableFilter>;
    _min?: InputMaybe<NestedEnumPixKeyTypeNullableFilter>;
    equals?: InputMaybe<PixKeyType>;
    in?: InputMaybe<Array<PixKeyType>>;
    not?: InputMaybe<NestedEnumPixKeyTypeNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<PixKeyType>>;
};

export type NestedEnumRecebivelOrigemFilter = {
    equals?: InputMaybe<RecebivelOrigem>;
    in?: InputMaybe<Array<RecebivelOrigem>>;
    not?: InputMaybe<NestedEnumRecebivelOrigemFilter>;
    notIn?: InputMaybe<Array<RecebivelOrigem>>;
};

export type NestedEnumRecebivelOrigemWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumRecebivelOrigemFilter>;
    _min?: InputMaybe<NestedEnumRecebivelOrigemFilter>;
    equals?: InputMaybe<RecebivelOrigem>;
    in?: InputMaybe<Array<RecebivelOrigem>>;
    not?: InputMaybe<NestedEnumRecebivelOrigemWithAggregatesFilter>;
    notIn?: InputMaybe<Array<RecebivelOrigem>>;
};

export type NestedEnumStatusOperacionalFilter = {
    equals?: InputMaybe<StatusOperacional>;
    in?: InputMaybe<Array<StatusOperacional>>;
    not?: InputMaybe<NestedEnumStatusOperacionalFilter>;
    notIn?: InputMaybe<Array<StatusOperacional>>;
};

export type NestedEnumStatusOperacionalWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumStatusOperacionalFilter>;
    _min?: InputMaybe<NestedEnumStatusOperacionalFilter>;
    equals?: InputMaybe<StatusOperacional>;
    in?: InputMaybe<Array<StatusOperacional>>;
    not?: InputMaybe<NestedEnumStatusOperacionalWithAggregatesFilter>;
    notIn?: InputMaybe<Array<StatusOperacional>>;
};

export type NestedEnumStatusOperationalAssignorByMinibankFilter = {
    equals?: InputMaybe<StatusOperationalAssignorByMinibank>;
    in?: InputMaybe<Array<StatusOperationalAssignorByMinibank>>;
    not?: InputMaybe<NestedEnumStatusOperationalAssignorByMinibankFilter>;
    notIn?: InputMaybe<Array<StatusOperationalAssignorByMinibank>>;
};

export type NestedEnumStatusOperationalAssignorByMinibankWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumStatusOperationalAssignorByMinibankFilter>;
    _min?: InputMaybe<NestedEnumStatusOperationalAssignorByMinibankFilter>;
    equals?: InputMaybe<StatusOperationalAssignorByMinibank>;
    in?: InputMaybe<Array<StatusOperationalAssignorByMinibank>>;
    not?: InputMaybe<NestedEnumStatusOperationalAssignorByMinibankWithAggregatesFilter>;
    notIn?: InputMaybe<Array<StatusOperationalAssignorByMinibank>>;
};

export type NestedEnumStatusRecebivelFilter = {
    equals?: InputMaybe<StatusRecebivel>;
    in?: InputMaybe<Array<StatusRecebivel>>;
    not?: InputMaybe<NestedEnumStatusRecebivelFilter>;
    notIn?: InputMaybe<Array<StatusRecebivel>>;
};

export type NestedEnumStatusRecebivelWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumStatusRecebivelFilter>;
    _min?: InputMaybe<NestedEnumStatusRecebivelFilter>;
    equals?: InputMaybe<StatusRecebivel>;
    in?: InputMaybe<Array<StatusRecebivel>>;
    not?: InputMaybe<NestedEnumStatusRecebivelWithAggregatesFilter>;
    notIn?: InputMaybe<Array<StatusRecebivel>>;
};

export type NestedEnumTipoMinibancoFilter = {
    equals?: InputMaybe<TipoMinibanco>;
    in?: InputMaybe<Array<TipoMinibanco>>;
    not?: InputMaybe<NestedEnumTipoMinibancoFilter>;
    notIn?: InputMaybe<Array<TipoMinibanco>>;
};

export type NestedEnumTipoMinibancoWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumTipoMinibancoFilter>;
    _min?: InputMaybe<NestedEnumTipoMinibancoFilter>;
    equals?: InputMaybe<TipoMinibanco>;
    in?: InputMaybe<Array<TipoMinibanco>>;
    not?: InputMaybe<NestedEnumTipoMinibancoWithAggregatesFilter>;
    notIn?: InputMaybe<Array<TipoMinibanco>>;
};

export type NestedEnumTipoServicoFilter = {
    equals?: InputMaybe<TipoServico>;
    in?: InputMaybe<Array<TipoServico>>;
    not?: InputMaybe<NestedEnumTipoServicoFilter>;
    notIn?: InputMaybe<Array<TipoServico>>;
};

export type NestedEnumTipoServicoNullableFilter = {
    equals?: InputMaybe<TipoServico>;
    in?: InputMaybe<Array<TipoServico>>;
    not?: InputMaybe<NestedEnumTipoServicoNullableFilter>;
    notIn?: InputMaybe<Array<TipoServico>>;
};

export type NestedEnumTipoServicoNullableWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedEnumTipoServicoNullableFilter>;
    _min?: InputMaybe<NestedEnumTipoServicoNullableFilter>;
    equals?: InputMaybe<TipoServico>;
    in?: InputMaybe<Array<TipoServico>>;
    not?: InputMaybe<NestedEnumTipoServicoNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<TipoServico>>;
};

export type NestedEnumTipoServicoWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumTipoServicoFilter>;
    _min?: InputMaybe<NestedEnumTipoServicoFilter>;
    equals?: InputMaybe<TipoServico>;
    in?: InputMaybe<Array<TipoServico>>;
    not?: InputMaybe<NestedEnumTipoServicoWithAggregatesFilter>;
    notIn?: InputMaybe<Array<TipoServico>>;
};

export type NestedEnumTypeOfAnalysisFilter = {
    equals?: InputMaybe<TypeOfAnalysis>;
    in?: InputMaybe<Array<TypeOfAnalysis>>;
    not?: InputMaybe<NestedEnumTypeOfAnalysisFilter>;
    notIn?: InputMaybe<Array<TypeOfAnalysis>>;
};

export type NestedEnumTypeOfAnalysisWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedEnumTypeOfAnalysisFilter>;
    _min?: InputMaybe<NestedEnumTypeOfAnalysisFilter>;
    equals?: InputMaybe<TypeOfAnalysis>;
    in?: InputMaybe<Array<TypeOfAnalysis>>;
    not?: InputMaybe<NestedEnumTypeOfAnalysisWithAggregatesFilter>;
    notIn?: InputMaybe<Array<TypeOfAnalysis>>;
};

export type NestedFloatFilter = {
    equals?: InputMaybe<Scalars["Float"]>;
    gt?: InputMaybe<Scalars["Float"]>;
    gte?: InputMaybe<Scalars["Float"]>;
    in?: InputMaybe<Array<Scalars["Float"]>>;
    lt?: InputMaybe<Scalars["Float"]>;
    lte?: InputMaybe<Scalars["Float"]>;
    not?: InputMaybe<NestedFloatFilter>;
    notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type NestedFloatNullableFilter = {
    equals?: InputMaybe<Scalars["Float"]>;
    gt?: InputMaybe<Scalars["Float"]>;
    gte?: InputMaybe<Scalars["Float"]>;
    in?: InputMaybe<Array<Scalars["Float"]>>;
    lt?: InputMaybe<Scalars["Float"]>;
    lte?: InputMaybe<Scalars["Float"]>;
    not?: InputMaybe<NestedFloatNullableFilter>;
    notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type NestedIntFilter = {
    equals?: InputMaybe<Scalars["Int"]>;
    gt?: InputMaybe<Scalars["Int"]>;
    gte?: InputMaybe<Scalars["Int"]>;
    in?: InputMaybe<Array<Scalars["Int"]>>;
    lt?: InputMaybe<Scalars["Int"]>;
    lte?: InputMaybe<Scalars["Int"]>;
    not?: InputMaybe<NestedIntFilter>;
    notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type NestedIntNullableFilter = {
    equals?: InputMaybe<Scalars["Int"]>;
    gt?: InputMaybe<Scalars["Int"]>;
    gte?: InputMaybe<Scalars["Int"]>;
    in?: InputMaybe<Array<Scalars["Int"]>>;
    lt?: InputMaybe<Scalars["Int"]>;
    lte?: InputMaybe<Scalars["Int"]>;
    not?: InputMaybe<NestedIntNullableFilter>;
    notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type NestedIntNullableWithAggregatesFilter = {
    _avg?: InputMaybe<NestedFloatNullableFilter>;
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedIntNullableFilter>;
    _min?: InputMaybe<NestedIntNullableFilter>;
    _sum?: InputMaybe<NestedIntNullableFilter>;
    equals?: InputMaybe<Scalars["Int"]>;
    gt?: InputMaybe<Scalars["Int"]>;
    gte?: InputMaybe<Scalars["Int"]>;
    in?: InputMaybe<Array<Scalars["Int"]>>;
    lt?: InputMaybe<Scalars["Int"]>;
    lte?: InputMaybe<Scalars["Int"]>;
    not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type NestedIntWithAggregatesFilter = {
    _avg?: InputMaybe<NestedFloatFilter>;
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedIntFilter>;
    _min?: InputMaybe<NestedIntFilter>;
    _sum?: InputMaybe<NestedIntFilter>;
    equals?: InputMaybe<Scalars["Int"]>;
    gt?: InputMaybe<Scalars["Int"]>;
    gte?: InputMaybe<Scalars["Int"]>;
    in?: InputMaybe<Array<Scalars["Int"]>>;
    lt?: InputMaybe<Scalars["Int"]>;
    lte?: InputMaybe<Scalars["Int"]>;
    not?: InputMaybe<NestedIntWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type NestedJsonFilter = {
    array_contains?: InputMaybe<Scalars["JSON"]>;
    array_ends_with?: InputMaybe<Scalars["JSON"]>;
    array_starts_with?: InputMaybe<Scalars["JSON"]>;
    equals?: InputMaybe<Scalars["JSON"]>;
    gt?: InputMaybe<Scalars["JSON"]>;
    gte?: InputMaybe<Scalars["JSON"]>;
    lt?: InputMaybe<Scalars["JSON"]>;
    lte?: InputMaybe<Scalars["JSON"]>;
    not?: InputMaybe<Scalars["JSON"]>;
    path?: InputMaybe<Array<Scalars["String"]>>;
    string_contains?: InputMaybe<Scalars["String"]>;
    string_ends_with?: InputMaybe<Scalars["String"]>;
    string_starts_with?: InputMaybe<Scalars["String"]>;
};

export type NestedJsonNullableFilter = {
    array_contains?: InputMaybe<Scalars["JSON"]>;
    array_ends_with?: InputMaybe<Scalars["JSON"]>;
    array_starts_with?: InputMaybe<Scalars["JSON"]>;
    equals?: InputMaybe<Scalars["JSON"]>;
    gt?: InputMaybe<Scalars["JSON"]>;
    gte?: InputMaybe<Scalars["JSON"]>;
    lt?: InputMaybe<Scalars["JSON"]>;
    lte?: InputMaybe<Scalars["JSON"]>;
    not?: InputMaybe<Scalars["JSON"]>;
    path?: InputMaybe<Array<Scalars["String"]>>;
    string_contains?: InputMaybe<Scalars["String"]>;
    string_ends_with?: InputMaybe<Scalars["String"]>;
    string_starts_with?: InputMaybe<Scalars["String"]>;
};

export type NestedStringFilter = {
    contains?: InputMaybe<Scalars["String"]>;
    endsWith?: InputMaybe<Scalars["String"]>;
    equals?: InputMaybe<Scalars["String"]>;
    gt?: InputMaybe<Scalars["String"]>;
    gte?: InputMaybe<Scalars["String"]>;
    in?: InputMaybe<Array<Scalars["String"]>>;
    lt?: InputMaybe<Scalars["String"]>;
    lte?: InputMaybe<Scalars["String"]>;
    not?: InputMaybe<NestedStringFilter>;
    notIn?: InputMaybe<Array<Scalars["String"]>>;
    startsWith?: InputMaybe<Scalars["String"]>;
};

export type NestedStringNullableFilter = {
    contains?: InputMaybe<Scalars["String"]>;
    endsWith?: InputMaybe<Scalars["String"]>;
    equals?: InputMaybe<Scalars["String"]>;
    gt?: InputMaybe<Scalars["String"]>;
    gte?: InputMaybe<Scalars["String"]>;
    in?: InputMaybe<Array<Scalars["String"]>>;
    lt?: InputMaybe<Scalars["String"]>;
    lte?: InputMaybe<Scalars["String"]>;
    not?: InputMaybe<NestedStringNullableFilter>;
    notIn?: InputMaybe<Array<Scalars["String"]>>;
    startsWith?: InputMaybe<Scalars["String"]>;
};

export type NestedStringNullableWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedStringNullableFilter>;
    _min?: InputMaybe<NestedStringNullableFilter>;
    contains?: InputMaybe<Scalars["String"]>;
    endsWith?: InputMaybe<Scalars["String"]>;
    equals?: InputMaybe<Scalars["String"]>;
    gt?: InputMaybe<Scalars["String"]>;
    gte?: InputMaybe<Scalars["String"]>;
    in?: InputMaybe<Array<Scalars["String"]>>;
    lt?: InputMaybe<Scalars["String"]>;
    lte?: InputMaybe<Scalars["String"]>;
    not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["String"]>>;
    startsWith?: InputMaybe<Scalars["String"]>;
};

export type NestedStringWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedStringFilter>;
    _min?: InputMaybe<NestedStringFilter>;
    contains?: InputMaybe<Scalars["String"]>;
    endsWith?: InputMaybe<Scalars["String"]>;
    equals?: InputMaybe<Scalars["String"]>;
    gt?: InputMaybe<Scalars["String"]>;
    gte?: InputMaybe<Scalars["String"]>;
    in?: InputMaybe<Array<Scalars["String"]>>;
    lt?: InputMaybe<Scalars["String"]>;
    lte?: InputMaybe<Scalars["String"]>;
    not?: InputMaybe<NestedStringWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["String"]>>;
    startsWith?: InputMaybe<Scalars["String"]>;
};

export enum NivelAcesso {
    Create = "CREATE",
    Delete = "DELETE",
    Manage = "MANAGE",
    Read = "READ",
    Update = "UPDATE",
}

export type NivelPermissao = {
    __typename?: "NivelPermissao";
    entidade: Entidade;
    nivel: NivelAcesso;
    usuario: Usuario;
    usuarioId: Scalars["Int"];
};

export type NivelPermissaoAvgAggregate = {
    __typename?: "NivelPermissaoAvgAggregate";
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type NivelPermissaoAvgOrderByAggregateInput = {
    usuarioId?: InputMaybe<SortOrder>;
};

export type NivelPermissaoCountAggregate = {
    __typename?: "NivelPermissaoCountAggregate";
    _all: Scalars["Int"];
    entidade: Scalars["Int"];
    nivel: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type NivelPermissaoCountOrderByAggregateInput = {
    entidade?: InputMaybe<SortOrder>;
    nivel?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type NivelPermissaoCreateInput = {
    entidade: Entidade;
    nivel: NivelAcesso;
    usuario: UsuarioCreateNestedOneWithoutIndPermissoesInput;
};

export type NivelPermissaoCreateManyInput = {
    entidade: Entidade;
    nivel: NivelAcesso;
    usuarioId: Scalars["Int"];
};

export type NivelPermissaoCreateManyUsuarioInput = {
    entidade: Entidade;
    nivel: NivelAcesso;
};

export type NivelPermissaoCreateManyUsuarioInputEnvelope = {
    data: Array<NivelPermissaoCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type NivelPermissaoCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<NivelPermissaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<NivelPermissaoCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<NivelPermissaoCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<NivelPermissaoCreateManyUsuarioInputEnvelope>;
};

export type NivelPermissaoCreateOrConnectWithoutUsuarioInput = {
    create: NivelPermissaoCreateWithoutUsuarioInput;
    where: NivelPermissaoWhereUniqueInput;
};

export type NivelPermissaoCreateWithoutUsuarioInput = {
    entidade: Entidade;
    nivel: NivelAcesso;
};

export type NivelPermissaoGroupBy = {
    __typename?: "NivelPermissaoGroupBy";
    _avg?: Maybe<NivelPermissaoAvgAggregate>;
    _count?: Maybe<NivelPermissaoCountAggregate>;
    _max?: Maybe<NivelPermissaoMaxAggregate>;
    _min?: Maybe<NivelPermissaoMinAggregate>;
    _sum?: Maybe<NivelPermissaoSumAggregate>;
    entidade: Entidade;
    nivel: NivelAcesso;
    usuarioId: Scalars["Int"];
};

export type NivelPermissaoListRelationFilter = {
    every?: InputMaybe<NivelPermissaoWhereInput>;
    none?: InputMaybe<NivelPermissaoWhereInput>;
    some?: InputMaybe<NivelPermissaoWhereInput>;
};

export type NivelPermissaoMaxAggregate = {
    __typename?: "NivelPermissaoMaxAggregate";
    entidade?: Maybe<Entidade>;
    nivel?: Maybe<NivelAcesso>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type NivelPermissaoMaxOrderByAggregateInput = {
    entidade?: InputMaybe<SortOrder>;
    nivel?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type NivelPermissaoMinAggregate = {
    __typename?: "NivelPermissaoMinAggregate";
    entidade?: Maybe<Entidade>;
    nivel?: Maybe<NivelAcesso>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type NivelPermissaoMinOrderByAggregateInput = {
    entidade?: InputMaybe<SortOrder>;
    nivel?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type NivelPermissaoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type NivelPermissaoOrderByWithAggregationInput = {
    _avg?: InputMaybe<NivelPermissaoAvgOrderByAggregateInput>;
    _count?: InputMaybe<NivelPermissaoCountOrderByAggregateInput>;
    _max?: InputMaybe<NivelPermissaoMaxOrderByAggregateInput>;
    _min?: InputMaybe<NivelPermissaoMinOrderByAggregateInput>;
    _sum?: InputMaybe<NivelPermissaoSumOrderByAggregateInput>;
    entidade?: InputMaybe<SortOrder>;
    nivel?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type NivelPermissaoOrderByWithRelationInput = {
    entidade?: InputMaybe<SortOrder>;
    nivel?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum NivelPermissaoScalarFieldEnum {
    Entidade = "entidade",
    Nivel = "nivel",
    UsuarioId = "usuarioId",
}

export type NivelPermissaoScalarWhereInput = {
    AND?: InputMaybe<Array<NivelPermissaoScalarWhereInput>>;
    NOT?: InputMaybe<Array<NivelPermissaoScalarWhereInput>>;
    OR?: InputMaybe<Array<NivelPermissaoScalarWhereInput>>;
    entidade?: InputMaybe<EnumEntidadeFilter>;
    nivel?: InputMaybe<EnumNivelAcessoFilter>;
    usuarioId?: InputMaybe<IntFilter>;
};

export type NivelPermissaoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<NivelPermissaoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<NivelPermissaoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<NivelPermissaoScalarWhereWithAggregatesInput>>;
    entidade?: InputMaybe<EnumEntidadeWithAggregatesFilter>;
    nivel?: InputMaybe<EnumNivelAcessoWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntWithAggregatesFilter>;
};

export type NivelPermissaoSumAggregate = {
    __typename?: "NivelPermissaoSumAggregate";
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type NivelPermissaoSumOrderByAggregateInput = {
    usuarioId?: InputMaybe<SortOrder>;
};

export type NivelPermissaoUpdateInput = {
    entidade?: InputMaybe<EnumEntidadeFieldUpdateOperationsInput>;
    nivel?: InputMaybe<EnumNivelAcessoFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutIndPermissoesNestedInput>;
};

export type NivelPermissaoUpdateManyMutationInput = {
    entidade?: InputMaybe<EnumEntidadeFieldUpdateOperationsInput>;
    nivel?: InputMaybe<EnumNivelAcessoFieldUpdateOperationsInput>;
};

export type NivelPermissaoUpdateManyWithWhereWithoutUsuarioInput = {
    data: NivelPermissaoUpdateManyMutationInput;
    where: NivelPermissaoScalarWhereInput;
};

export type NivelPermissaoUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<NivelPermissaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<NivelPermissaoCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<NivelPermissaoCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<NivelPermissaoCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<NivelPermissaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<NivelPermissaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<NivelPermissaoWhereUniqueInput>>;
    set?: InputMaybe<Array<NivelPermissaoWhereUniqueInput>>;
    update?: InputMaybe<Array<NivelPermissaoUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<NivelPermissaoUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<NivelPermissaoUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type NivelPermissaoUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: NivelPermissaoUpdateWithoutUsuarioInput;
    where: NivelPermissaoWhereUniqueInput;
};

export type NivelPermissaoUpdateWithoutUsuarioInput = {
    entidade?: InputMaybe<EnumEntidadeFieldUpdateOperationsInput>;
    nivel?: InputMaybe<EnumNivelAcessoFieldUpdateOperationsInput>;
};

export type NivelPermissaoUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: NivelPermissaoCreateWithoutUsuarioInput;
    update: NivelPermissaoUpdateWithoutUsuarioInput;
    where: NivelPermissaoWhereUniqueInput;
};

export type NivelPermissaoUsuarioIdEntidadeNivelCompoundUniqueInput = {
    entidade: Entidade;
    nivel: NivelAcesso;
    usuarioId: Scalars["Int"];
};

export type NivelPermissaoWhereInput = {
    AND?: InputMaybe<Array<NivelPermissaoWhereInput>>;
    NOT?: InputMaybe<Array<NivelPermissaoWhereInput>>;
    OR?: InputMaybe<Array<NivelPermissaoWhereInput>>;
    entidade?: InputMaybe<EnumEntidadeFilter>;
    nivel?: InputMaybe<EnumNivelAcessoFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntFilter>;
};

export type NivelPermissaoWhereUniqueInput = {
    usuarioId_entidade_nivel?: InputMaybe<NivelPermissaoUsuarioIdEntidadeNivelCompoundUniqueInput>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
    set?: InputMaybe<Scalars["DateTime"]>;
};

export type NullableDecimalFieldUpdateOperationsInput = {
    decrement?: InputMaybe<Scalars["Decimal"]>;
    divide?: InputMaybe<Scalars["Decimal"]>;
    increment?: InputMaybe<Scalars["Decimal"]>;
    multiply?: InputMaybe<Scalars["Decimal"]>;
    set?: InputMaybe<Scalars["Decimal"]>;
};

export type NullableEnumDigitalSignatureFieldUpdateOperationsInput = {
    set?: InputMaybe<DigitalSignature>;
};

export type NullableEnumPayableTypeFieldUpdateOperationsInput = {
    set?: InputMaybe<PayableType>;
};

export type NullableEnumPixKeyTypeFieldUpdateOperationsInput = {
    set?: InputMaybe<PixKeyType>;
};

export type NullableEnumTipoServicoFieldUpdateOperationsInput = {
    set?: InputMaybe<TipoServico>;
};

export type NullableIntFieldUpdateOperationsInput = {
    decrement?: InputMaybe<Scalars["Int"]>;
    divide?: InputMaybe<Scalars["Int"]>;
    increment?: InputMaybe<Scalars["Int"]>;
    multiply?: InputMaybe<Scalars["Int"]>;
    set?: InputMaybe<Scalars["Int"]>;
};

export type NullableStringFieldUpdateOperationsInput = {
    set?: InputMaybe<Scalars["String"]>;
};

export type Occupation = {
    __typename?: "Occupation";
    OccupationAudit: Array<OccupationAudit>;
    OperationCommision?: Maybe<OperationCommision>;
    _count?: Maybe<OccupationCount>;
    abbreviation?: Maybe<Scalars["String"]>;
    commission?: Maybe<Scalars["Decimal"]>;
    createdAt: Scalars["DateTime"];
    description?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    inactive: Scalars["Boolean"];
    name: Scalars["String"];
    updatedAt: Scalars["DateTime"];
    users: Array<Usuario>;
};

export type OccupationOccupationAuditArgs = {
    cursor?: InputMaybe<OccupationAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<OccupationAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OccupationAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OccupationAuditWhereInput>;
};

export type OccupationUsersArgs = {
    cursor?: InputMaybe<UsuarioWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioWhereInput>;
};

export type OccupationAudit = {
    __typename?: "OccupationAudit";
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    newValue?: Maybe<Scalars["String"]>;
    occupation?: Maybe<Occupation>;
    occupationId?: Maybe<Scalars["Int"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    user?: Maybe<Usuario>;
    userAgent: Scalars["String"];
    userIP: Scalars["String"];
    userId?: Maybe<Scalars["Int"]>;
};

export type OccupationAuditAvgAggregate = {
    __typename?: "OccupationAuditAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    occupationId?: Maybe<Scalars["Float"]>;
    userId?: Maybe<Scalars["Float"]>;
};

export type OccupationAuditAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    occupationId?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type OccupationAuditCountAggregate = {
    __typename?: "OccupationAuditCountAggregate";
    _all: Scalars["Int"];
    action: Scalars["Int"];
    createdAt: Scalars["Int"];
    field: Scalars["Int"];
    id: Scalars["Int"];
    newValue: Scalars["Int"];
    occupationId: Scalars["Int"];
    oldValue: Scalars["Int"];
    service: Scalars["Int"];
    userAgent: Scalars["Int"];
    userIP: Scalars["Int"];
    userId: Scalars["Int"];
};

export type OccupationAuditCountOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    occupationId?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    userIP?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type OccupationAuditCreateInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    occupation?: InputMaybe<OccupationCreateNestedOneWithoutOccupationAuditInput>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    user?: InputMaybe<UsuarioCreateNestedOneWithoutOccupationAuditInput>;
    userAgent: Scalars["String"];
    userIP: Scalars["String"];
};

export type OccupationAuditCreateManyInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    newValue?: InputMaybe<Scalars["String"]>;
    occupationId?: InputMaybe<Scalars["Int"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    userIP: Scalars["String"];
    userId?: InputMaybe<Scalars["Int"]>;
};

export type OccupationAuditCreateManyOccupationInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    userIP: Scalars["String"];
    userId?: InputMaybe<Scalars["Int"]>;
};

export type OccupationAuditCreateManyOccupationInputEnvelope = {
    data: Array<OccupationAuditCreateManyOccupationInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OccupationAuditCreateManyUserInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    newValue?: InputMaybe<Scalars["String"]>;
    occupationId?: InputMaybe<Scalars["Int"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    userIP: Scalars["String"];
};

export type OccupationAuditCreateManyUserInputEnvelope = {
    data: Array<OccupationAuditCreateManyUserInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OccupationAuditCreateNestedManyWithoutOccupationInput = {
    connect?: InputMaybe<Array<OccupationAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OccupationAuditCreateOrConnectWithoutOccupationInput>>;
    create?: InputMaybe<Array<OccupationAuditCreateWithoutOccupationInput>>;
    createMany?: InputMaybe<OccupationAuditCreateManyOccupationInputEnvelope>;
};

export type OccupationAuditCreateNestedManyWithoutUserInput = {
    connect?: InputMaybe<Array<OccupationAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OccupationAuditCreateOrConnectWithoutUserInput>>;
    create?: InputMaybe<Array<OccupationAuditCreateWithoutUserInput>>;
    createMany?: InputMaybe<OccupationAuditCreateManyUserInputEnvelope>;
};

export type OccupationAuditCreateOrConnectWithoutOccupationInput = {
    create: OccupationAuditCreateWithoutOccupationInput;
    where: OccupationAuditWhereUniqueInput;
};

export type OccupationAuditCreateOrConnectWithoutUserInput = {
    create: OccupationAuditCreateWithoutUserInput;
    where: OccupationAuditWhereUniqueInput;
};

export type OccupationAuditCreateWithoutOccupationInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    user?: InputMaybe<UsuarioCreateNestedOneWithoutOccupationAuditInput>;
    userAgent: Scalars["String"];
    userIP: Scalars["String"];
};

export type OccupationAuditCreateWithoutUserInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    occupation?: InputMaybe<OccupationCreateNestedOneWithoutOccupationAuditInput>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    userIP: Scalars["String"];
};

export type OccupationAuditGroupBy = {
    __typename?: "OccupationAuditGroupBy";
    _avg?: Maybe<OccupationAuditAvgAggregate>;
    _count?: Maybe<OccupationAuditCountAggregate>;
    _max?: Maybe<OccupationAuditMaxAggregate>;
    _min?: Maybe<OccupationAuditMinAggregate>;
    _sum?: Maybe<OccupationAuditSumAggregate>;
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    newValue?: Maybe<Scalars["String"]>;
    occupationId?: Maybe<Scalars["Int"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    userIP: Scalars["String"];
    userId?: Maybe<Scalars["Int"]>;
};

export type OccupationAuditListRelationFilter = {
    every?: InputMaybe<OccupationAuditWhereInput>;
    none?: InputMaybe<OccupationAuditWhereInput>;
    some?: InputMaybe<OccupationAuditWhereInput>;
};

export type OccupationAuditMaxAggregate = {
    __typename?: "OccupationAuditMaxAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    newValue?: Maybe<Scalars["String"]>;
    occupationId?: Maybe<Scalars["Int"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    userIP?: Maybe<Scalars["String"]>;
    userId?: Maybe<Scalars["Int"]>;
};

export type OccupationAuditMaxOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    occupationId?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    userIP?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type OccupationAuditMinAggregate = {
    __typename?: "OccupationAuditMinAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    newValue?: Maybe<Scalars["String"]>;
    occupationId?: Maybe<Scalars["Int"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    userIP?: Maybe<Scalars["String"]>;
    userId?: Maybe<Scalars["Int"]>;
};

export type OccupationAuditMinOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    occupationId?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    userIP?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type OccupationAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type OccupationAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<OccupationAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<OccupationAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<OccupationAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<OccupationAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<OccupationAuditSumOrderByAggregateInput>;
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    occupationId?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    userIP?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type OccupationAuditOrderByWithRelationInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    occupation?: InputMaybe<OccupationOrderByWithRelationInput>;
    occupationId?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    user?: InputMaybe<UsuarioOrderByWithRelationInput>;
    userAgent?: InputMaybe<SortOrder>;
    userIP?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export enum OccupationAuditScalarFieldEnum {
    Action = "action",
    CreatedAt = "createdAt",
    Field = "field",
    Id = "id",
    NewValue = "newValue",
    OccupationId = "occupationId",
    OldValue = "oldValue",
    Service = "service",
    UserAgent = "userAgent",
    UserIp = "userIP",
    UserId = "userId",
}

export type OccupationAuditScalarWhereInput = {
    AND?: InputMaybe<Array<OccupationAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<OccupationAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<OccupationAuditScalarWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    occupationId?: InputMaybe<IntNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    userIP?: InputMaybe<StringFilter>;
    userId?: InputMaybe<IntNullableFilter>;
};

export type OccupationAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OccupationAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OccupationAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OccupationAuditScalarWhereWithAggregatesInput>>;
    action?: InputMaybe<EnumAuditAcoesWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    field?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    newValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    occupationId?: InputMaybe<IntNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    userIP?: InputMaybe<StringWithAggregatesFilter>;
    userId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type OccupationAuditSumAggregate = {
    __typename?: "OccupationAuditSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    occupationId?: Maybe<Scalars["Int"]>;
    userId?: Maybe<Scalars["Int"]>;
};

export type OccupationAuditSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    occupationId?: InputMaybe<SortOrder>;
    userId?: InputMaybe<SortOrder>;
};

export type OccupationAuditUpdateInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    occupation?: InputMaybe<OccupationUpdateOneWithoutOccupationAuditNestedInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    user?: InputMaybe<UsuarioUpdateOneWithoutOccupationAuditNestedInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    userIP?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OccupationAuditUpdateManyMutationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    userIP?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OccupationAuditUpdateManyWithWhereWithoutOccupationInput = {
    data: OccupationAuditUpdateManyMutationInput;
    where: OccupationAuditScalarWhereInput;
};

export type OccupationAuditUpdateManyWithWhereWithoutUserInput = {
    data: OccupationAuditUpdateManyMutationInput;
    where: OccupationAuditScalarWhereInput;
};

export type OccupationAuditUpdateManyWithoutOccupationNestedInput = {
    connect?: InputMaybe<Array<OccupationAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OccupationAuditCreateOrConnectWithoutOccupationInput>>;
    create?: InputMaybe<Array<OccupationAuditCreateWithoutOccupationInput>>;
    createMany?: InputMaybe<OccupationAuditCreateManyOccupationInputEnvelope>;
    delete?: InputMaybe<Array<OccupationAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OccupationAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OccupationAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<OccupationAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<OccupationAuditUpdateWithWhereUniqueWithoutOccupationInput>>;
    updateMany?: InputMaybe<Array<OccupationAuditUpdateManyWithWhereWithoutOccupationInput>>;
    upsert?: InputMaybe<Array<OccupationAuditUpsertWithWhereUniqueWithoutOccupationInput>>;
};

export type OccupationAuditUpdateManyWithoutUserNestedInput = {
    connect?: InputMaybe<Array<OccupationAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OccupationAuditCreateOrConnectWithoutUserInput>>;
    create?: InputMaybe<Array<OccupationAuditCreateWithoutUserInput>>;
    createMany?: InputMaybe<OccupationAuditCreateManyUserInputEnvelope>;
    delete?: InputMaybe<Array<OccupationAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OccupationAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OccupationAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<OccupationAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<OccupationAuditUpdateWithWhereUniqueWithoutUserInput>>;
    updateMany?: InputMaybe<Array<OccupationAuditUpdateManyWithWhereWithoutUserInput>>;
    upsert?: InputMaybe<Array<OccupationAuditUpsertWithWhereUniqueWithoutUserInput>>;
};

export type OccupationAuditUpdateWithWhereUniqueWithoutOccupationInput = {
    data: OccupationAuditUpdateWithoutOccupationInput;
    where: OccupationAuditWhereUniqueInput;
};

export type OccupationAuditUpdateWithWhereUniqueWithoutUserInput = {
    data: OccupationAuditUpdateWithoutUserInput;
    where: OccupationAuditWhereUniqueInput;
};

export type OccupationAuditUpdateWithoutOccupationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    user?: InputMaybe<UsuarioUpdateOneWithoutOccupationAuditNestedInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    userIP?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OccupationAuditUpdateWithoutUserInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    occupation?: InputMaybe<OccupationUpdateOneWithoutOccupationAuditNestedInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    userIP?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OccupationAuditUpsertWithWhereUniqueWithoutOccupationInput = {
    create: OccupationAuditCreateWithoutOccupationInput;
    update: OccupationAuditUpdateWithoutOccupationInput;
    where: OccupationAuditWhereUniqueInput;
};

export type OccupationAuditUpsertWithWhereUniqueWithoutUserInput = {
    create: OccupationAuditCreateWithoutUserInput;
    update: OccupationAuditUpdateWithoutUserInput;
    where: OccupationAuditWhereUniqueInput;
};

export type OccupationAuditWhereInput = {
    AND?: InputMaybe<Array<OccupationAuditWhereInput>>;
    NOT?: InputMaybe<Array<OccupationAuditWhereInput>>;
    OR?: InputMaybe<Array<OccupationAuditWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    occupation?: InputMaybe<OccupationRelationFilter>;
    occupationId?: InputMaybe<IntNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    user?: InputMaybe<UsuarioRelationFilter>;
    userAgent?: InputMaybe<StringFilter>;
    userIP?: InputMaybe<StringFilter>;
    userId?: InputMaybe<IntNullableFilter>;
};

export type OccupationAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type OccupationAvgAggregate = {
    __typename?: "OccupationAvgAggregate";
    commission?: Maybe<Scalars["Decimal"]>;
    id?: Maybe<Scalars["Float"]>;
};

export type OccupationAvgOrderByAggregateInput = {
    commission?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type OccupationCount = {
    __typename?: "OccupationCount";
    OccupationAudit: Scalars["Int"];
    users: Scalars["Int"];
};

export type OccupationCountAggregate = {
    __typename?: "OccupationCountAggregate";
    _all: Scalars["Int"];
    abbreviation: Scalars["Int"];
    commission: Scalars["Int"];
    createdAt: Scalars["Int"];
    description: Scalars["Int"];
    id: Scalars["Int"];
    inactive: Scalars["Int"];
    name: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type OccupationCountOrderByAggregateInput = {
    abbreviation?: InputMaybe<SortOrder>;
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inactive?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OccupationCreateInput = {
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutOccupationInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOccupationInput>;
    abbreviation?: InputMaybe<Scalars["String"]>;
    commission?: InputMaybe<Scalars["Decimal"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    description?: InputMaybe<Scalars["String"]>;
    inactive?: InputMaybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    users?: InputMaybe<UsuarioCreateNestedManyWithoutOccupationsInput>;
};

export type OccupationCreateManyInput = {
    abbreviation?: InputMaybe<Scalars["String"]>;
    commission?: InputMaybe<Scalars["Decimal"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    description?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inactive?: InputMaybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OccupationCreateNestedManyWithoutUsersInput = {
    connect?: InputMaybe<Array<OccupationWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OccupationCreateOrConnectWithoutUsersInput>>;
    create?: InputMaybe<Array<OccupationCreateWithoutUsersInput>>;
};

export type OccupationCreateNestedOneWithoutOccupationAuditInput = {
    connect?: InputMaybe<OccupationWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OccupationCreateOrConnectWithoutOccupationAuditInput>;
    create?: InputMaybe<OccupationCreateWithoutOccupationAuditInput>;
};

export type OccupationCreateNestedOneWithoutOperationCommisionInput = {
    connect?: InputMaybe<OccupationWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OccupationCreateOrConnectWithoutOperationCommisionInput>;
    create?: InputMaybe<OccupationCreateWithoutOperationCommisionInput>;
};

export type OccupationCreateOrConnectWithoutOccupationAuditInput = {
    create: OccupationCreateWithoutOccupationAuditInput;
    where: OccupationWhereUniqueInput;
};

export type OccupationCreateOrConnectWithoutOperationCommisionInput = {
    create: OccupationCreateWithoutOperationCommisionInput;
    where: OccupationWhereUniqueInput;
};

export type OccupationCreateOrConnectWithoutUsersInput = {
    create: OccupationCreateWithoutUsersInput;
    where: OccupationWhereUniqueInput;
};

export type OccupationCreateWithoutOccupationAuditInput = {
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOccupationInput>;
    abbreviation?: InputMaybe<Scalars["String"]>;
    commission?: InputMaybe<Scalars["Decimal"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    description?: InputMaybe<Scalars["String"]>;
    inactive?: InputMaybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    users?: InputMaybe<UsuarioCreateNestedManyWithoutOccupationsInput>;
};

export type OccupationCreateWithoutOperationCommisionInput = {
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutOccupationInput>;
    abbreviation?: InputMaybe<Scalars["String"]>;
    commission?: InputMaybe<Scalars["Decimal"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    description?: InputMaybe<Scalars["String"]>;
    inactive?: InputMaybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    users?: InputMaybe<UsuarioCreateNestedManyWithoutOccupationsInput>;
};

export type OccupationCreateWithoutUsersInput = {
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutOccupationInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOccupationInput>;
    abbreviation?: InputMaybe<Scalars["String"]>;
    commission?: InputMaybe<Scalars["Decimal"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    description?: InputMaybe<Scalars["String"]>;
    inactive?: InputMaybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OccupationGroupBy = {
    __typename?: "OccupationGroupBy";
    _avg?: Maybe<OccupationAvgAggregate>;
    _count?: Maybe<OccupationCountAggregate>;
    _max?: Maybe<OccupationMaxAggregate>;
    _min?: Maybe<OccupationMinAggregate>;
    _sum?: Maybe<OccupationSumAggregate>;
    abbreviation?: Maybe<Scalars["String"]>;
    commission?: Maybe<Scalars["Decimal"]>;
    createdAt: Scalars["DateTime"];
    description?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    inactive: Scalars["Boolean"];
    name: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type OccupationListRelationFilter = {
    every?: InputMaybe<OccupationWhereInput>;
    none?: InputMaybe<OccupationWhereInput>;
    some?: InputMaybe<OccupationWhereInput>;
};

export type OccupationMaxAggregate = {
    __typename?: "OccupationMaxAggregate";
    abbreviation?: Maybe<Scalars["String"]>;
    commission?: Maybe<Scalars["Decimal"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inactive?: Maybe<Scalars["Boolean"]>;
    name?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OccupationMaxOrderByAggregateInput = {
    abbreviation?: InputMaybe<SortOrder>;
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inactive?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OccupationMinAggregate = {
    __typename?: "OccupationMinAggregate";
    abbreviation?: Maybe<Scalars["String"]>;
    commission?: Maybe<Scalars["Decimal"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    description?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inactive?: Maybe<Scalars["Boolean"]>;
    name?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OccupationMinOrderByAggregateInput = {
    abbreviation?: InputMaybe<SortOrder>;
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inactive?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OccupationOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type OccupationOrderByWithAggregationInput = {
    _avg?: InputMaybe<OccupationAvgOrderByAggregateInput>;
    _count?: InputMaybe<OccupationCountOrderByAggregateInput>;
    _max?: InputMaybe<OccupationMaxOrderByAggregateInput>;
    _min?: InputMaybe<OccupationMinOrderByAggregateInput>;
    _sum?: InputMaybe<OccupationSumOrderByAggregateInput>;
    abbreviation?: InputMaybe<SortOrder>;
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inactive?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OccupationOrderByWithRelationInput = {
    OccupationAudit?: InputMaybe<OccupationAuditOrderByRelationAggregateInput>;
    OperationCommision?: InputMaybe<OperationCommisionOrderByWithRelationInput>;
    abbreviation?: InputMaybe<SortOrder>;
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    description?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inactive?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    users?: InputMaybe<UsuarioOrderByRelationAggregateInput>;
};

export type OccupationRelationFilter = {
    is?: InputMaybe<OccupationWhereInput>;
    isNot?: InputMaybe<OccupationWhereInput>;
};

export enum OccupationScalarFieldEnum {
    Abbreviation = "abbreviation",
    Commission = "commission",
    CreatedAt = "createdAt",
    Description = "description",
    Id = "id",
    Inactive = "inactive",
    Name = "name",
    UpdatedAt = "updatedAt",
}

export type OccupationScalarWhereInput = {
    AND?: InputMaybe<Array<OccupationScalarWhereInput>>;
    NOT?: InputMaybe<Array<OccupationScalarWhereInput>>;
    OR?: InputMaybe<Array<OccupationScalarWhereInput>>;
    abbreviation?: InputMaybe<StringNullableFilter>;
    commission?: InputMaybe<DecimalNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    description?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    inactive?: InputMaybe<BoolFilter>;
    name?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OccupationScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OccupationScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OccupationScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OccupationScalarWhereWithAggregatesInput>>;
    abbreviation?: InputMaybe<StringNullableWithAggregatesFilter>;
    commission?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    description?: InputMaybe<StringNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inactive?: InputMaybe<BoolWithAggregatesFilter>;
    name?: InputMaybe<StringWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type OccupationSumAggregate = {
    __typename?: "OccupationSumAggregate";
    commission?: Maybe<Scalars["Decimal"]>;
    id?: Maybe<Scalars["Int"]>;
};

export type OccupationSumOrderByAggregateInput = {
    commission?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type OccupationUpdateInput = {
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutOccupationNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOccupationNestedInput>;
    abbreviation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    commission?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inactive?: InputMaybe<BoolFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    users?: InputMaybe<UsuarioUpdateManyWithoutOccupationsNestedInput>;
};

export type OccupationUpdateManyMutationInput = {
    abbreviation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    commission?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inactive?: InputMaybe<BoolFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OccupationUpdateManyWithWhereWithoutUsersInput = {
    data: OccupationUpdateManyMutationInput;
    where: OccupationScalarWhereInput;
};

export type OccupationUpdateManyWithoutUsersNestedInput = {
    connect?: InputMaybe<Array<OccupationWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OccupationCreateOrConnectWithoutUsersInput>>;
    create?: InputMaybe<Array<OccupationCreateWithoutUsersInput>>;
    delete?: InputMaybe<Array<OccupationWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OccupationScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OccupationWhereUniqueInput>>;
    set?: InputMaybe<Array<OccupationWhereUniqueInput>>;
    update?: InputMaybe<Array<OccupationUpdateWithWhereUniqueWithoutUsersInput>>;
    updateMany?: InputMaybe<Array<OccupationUpdateManyWithWhereWithoutUsersInput>>;
    upsert?: InputMaybe<Array<OccupationUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type OccupationUpdateOneRequiredWithoutOperationCommisionNestedInput = {
    connect?: InputMaybe<OccupationWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OccupationCreateOrConnectWithoutOperationCommisionInput>;
    create?: InputMaybe<OccupationCreateWithoutOperationCommisionInput>;
    update?: InputMaybe<OccupationUpdateWithoutOperationCommisionInput>;
    upsert?: InputMaybe<OccupationUpsertWithoutOperationCommisionInput>;
};

export type OccupationUpdateOneWithoutOccupationAuditNestedInput = {
    connect?: InputMaybe<OccupationWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OccupationCreateOrConnectWithoutOccupationAuditInput>;
    create?: InputMaybe<OccupationCreateWithoutOccupationAuditInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<OccupationUpdateWithoutOccupationAuditInput>;
    upsert?: InputMaybe<OccupationUpsertWithoutOccupationAuditInput>;
};

export type OccupationUpdateWithWhereUniqueWithoutUsersInput = {
    data: OccupationUpdateWithoutUsersInput;
    where: OccupationWhereUniqueInput;
};

export type OccupationUpdateWithoutOccupationAuditInput = {
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOccupationNestedInput>;
    abbreviation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    commission?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inactive?: InputMaybe<BoolFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    users?: InputMaybe<UsuarioUpdateManyWithoutOccupationsNestedInput>;
};

export type OccupationUpdateWithoutOperationCommisionInput = {
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutOccupationNestedInput>;
    abbreviation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    commission?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inactive?: InputMaybe<BoolFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    users?: InputMaybe<UsuarioUpdateManyWithoutOccupationsNestedInput>;
};

export type OccupationUpdateWithoutUsersInput = {
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutOccupationNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOccupationNestedInput>;
    abbreviation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    commission?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inactive?: InputMaybe<BoolFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OccupationUpsertWithWhereUniqueWithoutUsersInput = {
    create: OccupationCreateWithoutUsersInput;
    update: OccupationUpdateWithoutUsersInput;
    where: OccupationWhereUniqueInput;
};

export type OccupationUpsertWithoutOccupationAuditInput = {
    create: OccupationCreateWithoutOccupationAuditInput;
    update: OccupationUpdateWithoutOccupationAuditInput;
};

export type OccupationUpsertWithoutOperationCommisionInput = {
    create: OccupationCreateWithoutOperationCommisionInput;
    update: OccupationUpdateWithoutOperationCommisionInput;
};

export type OccupationWhereInput = {
    AND?: InputMaybe<Array<OccupationWhereInput>>;
    NOT?: InputMaybe<Array<OccupationWhereInput>>;
    OR?: InputMaybe<Array<OccupationWhereInput>>;
    OccupationAudit?: InputMaybe<OccupationAuditListRelationFilter>;
    OperationCommision?: InputMaybe<OperationCommisionRelationFilter>;
    abbreviation?: InputMaybe<StringNullableFilter>;
    commission?: InputMaybe<DecimalNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    description?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    inactive?: InputMaybe<BoolFilter>;
    name?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    users?: InputMaybe<UsuarioListRelationFilter>;
};

export type OccupationWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
    name?: InputMaybe<Scalars["String"]>;
};

export type Operacao = {
    __typename?: "Operacao";
    KeyAccount?: Maybe<Usuario>;
    OperacaoIntegracao?: Maybe<OperacaoIntegracao>;
    OperationCommision?: Maybe<OperationCommision>;
    OperationFees?: Maybe<OperationFees>;
    OperationUpdates: Array<OperationUpdates>;
    OperationsInfo?: Maybe<OperationsInfo>;
    Salesman?: Maybe<Usuario>;
    _count?: Maybe<OperacaoCount>;
    anexos: Array<OperacaoAnexos>;
    createdAt: Scalars["DateTime"];
    descricao?: Maybe<Scalars["String"]>;
    desembolso?: Maybe<Scalars["Decimal"]>;
    historico: Array<OperacaoAudit>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    iof?: Maybe<Scalars["Decimal"]>;
    keyAccountId?: Maybe<Scalars["Int"]>;
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    modalidade: OperacaoModalidade;
    modalidadeId: Scalars["Int"];
    operacaoChecklist?: Maybe<OperacaoChecklist>;
    origemOperacao?: Maybe<Minibanco>;
    origemOperacaoId?: Maybe<Scalars["Int"]>;
    recebiveis: Array<Recebivel>;
    repurchasedAmount?: Maybe<Scalars["Decimal"]>;
    salesmanId?: Maybe<Scalars["Int"]>;
    shouldAnalyzePayerCredit: Scalars["Boolean"];
    shouldCheckPayable: Scalars["Boolean"];
    status: OperacaoStatus;
    statusId: Scalars["Int"];
    sugerida?: Maybe<OperacaoSugerida>;
    taxa: Scalars["Decimal"];
    tipo: OperacaoTipo;
    tipoId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type OperacaoOperationUpdatesArgs = {
    cursor?: InputMaybe<OperationUpdatesWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationUpdatesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationUpdatesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationUpdatesWhereInput>;
};

export type OperacaoAnexosArgs = {
    cursor?: InputMaybe<OperacaoAnexosWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoAnexosScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoAnexosOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoAnexosWhereInput>;
};

export type OperacaoHistoricoArgs = {
    cursor?: InputMaybe<OperacaoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoAuditWhereInput>;
};

export type OperacaoRecebiveisArgs = {
    cursor?: InputMaybe<RecebivelWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelWhereInput>;
};

export type OperacaoAnexos = {
    __typename?: "OperacaoAnexos";
    _count?: Maybe<OperacaoAnexosCount>;
    arquivos: Array<Arquivo>;
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    observacao?: Maybe<Scalars["String"]>;
    operacao: Operacao;
    operacaoId: Scalars["Int"];
    operacaoStatus: OperacaoStatus;
    statusId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type OperacaoAnexosArquivosArgs = {
    cursor?: InputMaybe<ArquivoWhereUniqueInput>;
    distinct?: InputMaybe<Array<ArquivoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ArquivoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoWhereInput>;
};

export type OperacaoAnexosAvgAggregate = {
    __typename?: "OperacaoAnexosAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    operacaoId?: Maybe<Scalars["Float"]>;
    statusId?: Maybe<Scalars["Float"]>;
};

export type OperacaoAnexosAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
};

export type OperacaoAnexosCount = {
    __typename?: "OperacaoAnexosCount";
    arquivos: Scalars["Int"];
};

export type OperacaoAnexosCountAggregate = {
    __typename?: "OperacaoAnexosCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    observacao: Scalars["Int"];
    operacaoId: Scalars["Int"];
    statusId: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type OperacaoAnexosCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoAnexosCreateInput = {
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutOperacoesInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    operacao: OperacaoCreateNestedOneWithoutAnexosInput;
    operacaoStatus: OperacaoStatusCreateNestedOneWithoutAnexosInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoAnexosCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    operacaoId: Scalars["Int"];
    statusId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoAnexosCreateManyOperacaoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    statusId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoAnexosCreateManyOperacaoInputEnvelope = {
    data: Array<OperacaoAnexosCreateManyOperacaoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperacaoAnexosCreateManyOperacaoStatusInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    operacaoId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoAnexosCreateManyOperacaoStatusInputEnvelope = {
    data: Array<OperacaoAnexosCreateManyOperacaoStatusInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperacaoAnexosCreateNestedManyWithoutArquivosInput = {
    connect?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoAnexosCreateOrConnectWithoutArquivosInput>>;
    create?: InputMaybe<Array<OperacaoAnexosCreateWithoutArquivosInput>>;
};

export type OperacaoAnexosCreateNestedManyWithoutOperacaoInput = {
    connect?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoAnexosCreateOrConnectWithoutOperacaoInput>>;
    create?: InputMaybe<Array<OperacaoAnexosCreateWithoutOperacaoInput>>;
    createMany?: InputMaybe<OperacaoAnexosCreateManyOperacaoInputEnvelope>;
};

export type OperacaoAnexosCreateNestedManyWithoutOperacaoStatusInput = {
    connect?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoAnexosCreateOrConnectWithoutOperacaoStatusInput>>;
    create?: InputMaybe<Array<OperacaoAnexosCreateWithoutOperacaoStatusInput>>;
    createMany?: InputMaybe<OperacaoAnexosCreateManyOperacaoStatusInputEnvelope>;
};

export type OperacaoAnexosCreateOrConnectWithoutArquivosInput = {
    create: OperacaoAnexosCreateWithoutArquivosInput;
    where: OperacaoAnexosWhereUniqueInput;
};

export type OperacaoAnexosCreateOrConnectWithoutOperacaoInput = {
    create: OperacaoAnexosCreateWithoutOperacaoInput;
    where: OperacaoAnexosWhereUniqueInput;
};

export type OperacaoAnexosCreateOrConnectWithoutOperacaoStatusInput = {
    create: OperacaoAnexosCreateWithoutOperacaoStatusInput;
    where: OperacaoAnexosWhereUniqueInput;
};

export type OperacaoAnexosCreateWithoutArquivosInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    operacao: OperacaoCreateNestedOneWithoutAnexosInput;
    operacaoStatus: OperacaoStatusCreateNestedOneWithoutAnexosInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoAnexosCreateWithoutOperacaoInput = {
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutOperacoesInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    operacaoStatus: OperacaoStatusCreateNestedOneWithoutAnexosInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoAnexosCreateWithoutOperacaoStatusInput = {
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutOperacoesInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    operacao: OperacaoCreateNestedOneWithoutAnexosInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoAnexosGroupBy = {
    __typename?: "OperacaoAnexosGroupBy";
    _avg?: Maybe<OperacaoAnexosAvgAggregate>;
    _count?: Maybe<OperacaoAnexosCountAggregate>;
    _max?: Maybe<OperacaoAnexosMaxAggregate>;
    _min?: Maybe<OperacaoAnexosMinAggregate>;
    _sum?: Maybe<OperacaoAnexosSumAggregate>;
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    observacao?: Maybe<Scalars["String"]>;
    operacaoId: Scalars["Int"];
    statusId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type OperacaoAnexosListRelationFilter = {
    every?: InputMaybe<OperacaoAnexosWhereInput>;
    none?: InputMaybe<OperacaoAnexosWhereInput>;
    some?: InputMaybe<OperacaoAnexosWhereInput>;
};

export type OperacaoAnexosMaxAggregate = {
    __typename?: "OperacaoAnexosMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    observacao?: Maybe<Scalars["String"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    statusId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperacaoAnexosMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoAnexosMinAggregate = {
    __typename?: "OperacaoAnexosMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    observacao?: Maybe<Scalars["String"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    statusId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperacaoAnexosMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoAnexosOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type OperacaoAnexosOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperacaoAnexosAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperacaoAnexosCountOrderByAggregateInput>;
    _max?: InputMaybe<OperacaoAnexosMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperacaoAnexosMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperacaoAnexosSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoAnexosOrderByWithRelationInput = {
    arquivos?: InputMaybe<ArquivoOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    operacao?: InputMaybe<OperacaoOrderByWithRelationInput>;
    operacaoId?: InputMaybe<SortOrder>;
    operacaoStatus?: InputMaybe<OperacaoStatusOrderByWithRelationInput>;
    statusId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export enum OperacaoAnexosScalarFieldEnum {
    CreatedAt = "createdAt",
    Id = "id",
    Observacao = "observacao",
    OperacaoId = "operacaoId",
    StatusId = "statusId",
    UpdatedAt = "updatedAt",
}

export type OperacaoAnexosScalarWhereInput = {
    AND?: InputMaybe<Array<OperacaoAnexosScalarWhereInput>>;
    NOT?: InputMaybe<Array<OperacaoAnexosScalarWhereInput>>;
    OR?: InputMaybe<Array<OperacaoAnexosScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    observacao?: InputMaybe<StringNullableFilter>;
    operacaoId?: InputMaybe<IntFilter>;
    statusId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OperacaoAnexosScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperacaoAnexosScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperacaoAnexosScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperacaoAnexosScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    observacao?: InputMaybe<StringNullableWithAggregatesFilter>;
    operacaoId?: InputMaybe<IntWithAggregatesFilter>;
    statusId?: InputMaybe<IntWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type OperacaoAnexosSumAggregate = {
    __typename?: "OperacaoAnexosSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    statusId?: Maybe<Scalars["Int"]>;
};

export type OperacaoAnexosSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
};

export type OperacaoAnexosUpdateInput = {
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutOperacoesNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutAnexosNestedInput>;
    operacaoStatus?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutAnexosNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoAnexosUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoAnexosUpdateManyWithWhereWithoutArquivosInput = {
    data: OperacaoAnexosUpdateManyMutationInput;
    where: OperacaoAnexosScalarWhereInput;
};

export type OperacaoAnexosUpdateManyWithWhereWithoutOperacaoInput = {
    data: OperacaoAnexosUpdateManyMutationInput;
    where: OperacaoAnexosScalarWhereInput;
};

export type OperacaoAnexosUpdateManyWithWhereWithoutOperacaoStatusInput = {
    data: OperacaoAnexosUpdateManyMutationInput;
    where: OperacaoAnexosScalarWhereInput;
};

export type OperacaoAnexosUpdateManyWithoutArquivosNestedInput = {
    connect?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoAnexosCreateOrConnectWithoutArquivosInput>>;
    create?: InputMaybe<Array<OperacaoAnexosCreateWithoutArquivosInput>>;
    delete?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperacaoAnexosScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    set?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    update?: InputMaybe<Array<OperacaoAnexosUpdateWithWhereUniqueWithoutArquivosInput>>;
    updateMany?: InputMaybe<Array<OperacaoAnexosUpdateManyWithWhereWithoutArquivosInput>>;
    upsert?: InputMaybe<Array<OperacaoAnexosUpsertWithWhereUniqueWithoutArquivosInput>>;
};

export type OperacaoAnexosUpdateManyWithoutOperacaoNestedInput = {
    connect?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoAnexosCreateOrConnectWithoutOperacaoInput>>;
    create?: InputMaybe<Array<OperacaoAnexosCreateWithoutOperacaoInput>>;
    createMany?: InputMaybe<OperacaoAnexosCreateManyOperacaoInputEnvelope>;
    delete?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperacaoAnexosScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    set?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    update?: InputMaybe<Array<OperacaoAnexosUpdateWithWhereUniqueWithoutOperacaoInput>>;
    updateMany?: InputMaybe<Array<OperacaoAnexosUpdateManyWithWhereWithoutOperacaoInput>>;
    upsert?: InputMaybe<Array<OperacaoAnexosUpsertWithWhereUniqueWithoutOperacaoInput>>;
};

export type OperacaoAnexosUpdateManyWithoutOperacaoStatusNestedInput = {
    connect?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoAnexosCreateOrConnectWithoutOperacaoStatusInput>>;
    create?: InputMaybe<Array<OperacaoAnexosCreateWithoutOperacaoStatusInput>>;
    createMany?: InputMaybe<OperacaoAnexosCreateManyOperacaoStatusInputEnvelope>;
    delete?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperacaoAnexosScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    set?: InputMaybe<Array<OperacaoAnexosWhereUniqueInput>>;
    update?: InputMaybe<Array<OperacaoAnexosUpdateWithWhereUniqueWithoutOperacaoStatusInput>>;
    updateMany?: InputMaybe<Array<OperacaoAnexosUpdateManyWithWhereWithoutOperacaoStatusInput>>;
    upsert?: InputMaybe<Array<OperacaoAnexosUpsertWithWhereUniqueWithoutOperacaoStatusInput>>;
};

export type OperacaoAnexosUpdateWithWhereUniqueWithoutArquivosInput = {
    data: OperacaoAnexosUpdateWithoutArquivosInput;
    where: OperacaoAnexosWhereUniqueInput;
};

export type OperacaoAnexosUpdateWithWhereUniqueWithoutOperacaoInput = {
    data: OperacaoAnexosUpdateWithoutOperacaoInput;
    where: OperacaoAnexosWhereUniqueInput;
};

export type OperacaoAnexosUpdateWithWhereUniqueWithoutOperacaoStatusInput = {
    data: OperacaoAnexosUpdateWithoutOperacaoStatusInput;
    where: OperacaoAnexosWhereUniqueInput;
};

export type OperacaoAnexosUpdateWithoutArquivosInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutAnexosNestedInput>;
    operacaoStatus?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutAnexosNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoAnexosUpdateWithoutOperacaoInput = {
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutOperacoesNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacaoStatus?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutAnexosNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoAnexosUpdateWithoutOperacaoStatusInput = {
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutOperacoesNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutAnexosNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoAnexosUpsertWithWhereUniqueWithoutArquivosInput = {
    create: OperacaoAnexosCreateWithoutArquivosInput;
    update: OperacaoAnexosUpdateWithoutArquivosInput;
    where: OperacaoAnexosWhereUniqueInput;
};

export type OperacaoAnexosUpsertWithWhereUniqueWithoutOperacaoInput = {
    create: OperacaoAnexosCreateWithoutOperacaoInput;
    update: OperacaoAnexosUpdateWithoutOperacaoInput;
    where: OperacaoAnexosWhereUniqueInput;
};

export type OperacaoAnexosUpsertWithWhereUniqueWithoutOperacaoStatusInput = {
    create: OperacaoAnexosCreateWithoutOperacaoStatusInput;
    update: OperacaoAnexosUpdateWithoutOperacaoStatusInput;
    where: OperacaoAnexosWhereUniqueInput;
};

export type OperacaoAnexosWhereInput = {
    AND?: InputMaybe<Array<OperacaoAnexosWhereInput>>;
    NOT?: InputMaybe<Array<OperacaoAnexosWhereInput>>;
    OR?: InputMaybe<Array<OperacaoAnexosWhereInput>>;
    arquivos?: InputMaybe<ArquivoListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    observacao?: InputMaybe<StringNullableFilter>;
    operacao?: InputMaybe<OperacaoRelationFilter>;
    operacaoId?: InputMaybe<IntFilter>;
    operacaoStatus?: InputMaybe<OperacaoStatusRelationFilter>;
    statusId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OperacaoAnexosWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type OperacaoAudit = {
    __typename?: "OperacaoAudit";
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    operacao: Operacao;
    operacaoId: Scalars["Int"];
    service: TipoServico;
    userAgent: Scalars["String"];
    usuario?: Maybe<Usuario>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type OperacaoAuditAvgAggregate = {
    __typename?: "OperacaoAuditAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    operacaoId?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type OperacaoAuditAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacaoAuditCountAggregate = {
    __typename?: "OperacaoAuditCountAggregate";
    _all: Scalars["Int"];
    action: Scalars["Int"];
    createdAt: Scalars["Int"];
    field: Scalars["Int"];
    id: Scalars["Int"];
    ipUsuario: Scalars["Int"];
    newValue: Scalars["Int"];
    oldValue: Scalars["Int"];
    operacaoId: Scalars["Int"];
    service: Scalars["Int"];
    userAgent: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type OperacaoAuditCountOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacaoAuditCreateInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    operacao: OperacaoCreateNestedOneWithoutHistoricoInput;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistOperacaoInput>;
};

export type OperacaoAuditCreateManyInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    operacaoId: Scalars["Int"];
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type OperacaoAuditCreateManyOperacaoInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type OperacaoAuditCreateManyOperacaoInputEnvelope = {
    data: Array<OperacaoAuditCreateManyOperacaoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperacaoAuditCreateManyUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    operacaoId: Scalars["Int"];
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type OperacaoAuditCreateManyUsuarioInputEnvelope = {
    data: Array<OperacaoAuditCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperacaoAuditCreateNestedManyWithoutOperacaoInput = {
    connect?: InputMaybe<Array<OperacaoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoAuditCreateOrConnectWithoutOperacaoInput>>;
    create?: InputMaybe<Array<OperacaoAuditCreateWithoutOperacaoInput>>;
    createMany?: InputMaybe<OperacaoAuditCreateManyOperacaoInputEnvelope>;
};

export type OperacaoAuditCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<OperacaoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<OperacaoAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<OperacaoAuditCreateManyUsuarioInputEnvelope>;
};

export type OperacaoAuditCreateOrConnectWithoutOperacaoInput = {
    create: OperacaoAuditCreateWithoutOperacaoInput;
    where: OperacaoAuditWhereUniqueInput;
};

export type OperacaoAuditCreateOrConnectWithoutUsuarioInput = {
    create: OperacaoAuditCreateWithoutUsuarioInput;
    where: OperacaoAuditWhereUniqueInput;
};

export type OperacaoAuditCreateWithoutOperacaoInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistOperacaoInput>;
};

export type OperacaoAuditCreateWithoutUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    operacao: OperacaoCreateNestedOneWithoutHistoricoInput;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type OperacaoAuditGroupBy = {
    __typename?: "OperacaoAuditGroupBy";
    _avg?: Maybe<OperacaoAuditAvgAggregate>;
    _count?: Maybe<OperacaoAuditCountAggregate>;
    _max?: Maybe<OperacaoAuditMaxAggregate>;
    _min?: Maybe<OperacaoAuditMinAggregate>;
    _sum?: Maybe<OperacaoAuditSumAggregate>;
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    operacaoId: Scalars["Int"];
    service: TipoServico;
    userAgent: Scalars["String"];
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type OperacaoAuditListRelationFilter = {
    every?: InputMaybe<OperacaoAuditWhereInput>;
    none?: InputMaybe<OperacaoAuditWhereInput>;
    some?: InputMaybe<OperacaoAuditWhereInput>;
};

export type OperacaoAuditMaxAggregate = {
    __typename?: "OperacaoAuditMaxAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type OperacaoAuditMaxOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacaoAuditMinAggregate = {
    __typename?: "OperacaoAuditMinAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type OperacaoAuditMinOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacaoAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type OperacaoAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperacaoAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperacaoAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<OperacaoAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperacaoAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperacaoAuditSumOrderByAggregateInput>;
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacaoAuditOrderByWithRelationInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    operacao?: InputMaybe<OperacaoOrderByWithRelationInput>;
    operacaoId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum OperacaoAuditScalarFieldEnum {
    Action = "action",
    CreatedAt = "createdAt",
    Field = "field",
    Id = "id",
    IpUsuario = "ipUsuario",
    NewValue = "newValue",
    OldValue = "oldValue",
    OperacaoId = "operacaoId",
    Service = "service",
    UserAgent = "userAgent",
    UsuarioId = "usuarioId",
}

export type OperacaoAuditScalarWhereInput = {
    AND?: InputMaybe<Array<OperacaoAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<OperacaoAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<OperacaoAuditScalarWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    operacaoId?: InputMaybe<IntFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type OperacaoAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperacaoAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperacaoAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperacaoAuditScalarWhereWithAggregatesInput>>;
    action?: InputMaybe<EnumAuditAcoesWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    field?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ipUsuario?: InputMaybe<StringWithAggregatesFilter>;
    newValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    operacaoId?: InputMaybe<IntWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type OperacaoAuditSumAggregate = {
    __typename?: "OperacaoAuditSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type OperacaoAuditSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacaoAuditUpdateInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutHistoricoNestedInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistOperacaoNestedInput>;
};

export type OperacaoAuditUpdateManyMutationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OperacaoAuditUpdateManyWithWhereWithoutOperacaoInput = {
    data: OperacaoAuditUpdateManyMutationInput;
    where: OperacaoAuditScalarWhereInput;
};

export type OperacaoAuditUpdateManyWithWhereWithoutUsuarioInput = {
    data: OperacaoAuditUpdateManyMutationInput;
    where: OperacaoAuditScalarWhereInput;
};

export type OperacaoAuditUpdateManyWithoutOperacaoNestedInput = {
    connect?: InputMaybe<Array<OperacaoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoAuditCreateOrConnectWithoutOperacaoInput>>;
    create?: InputMaybe<Array<OperacaoAuditCreateWithoutOperacaoInput>>;
    createMany?: InputMaybe<OperacaoAuditCreateManyOperacaoInputEnvelope>;
    delete?: InputMaybe<Array<OperacaoAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperacaoAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperacaoAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<OperacaoAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<OperacaoAuditUpdateWithWhereUniqueWithoutOperacaoInput>>;
    updateMany?: InputMaybe<Array<OperacaoAuditUpdateManyWithWhereWithoutOperacaoInput>>;
    upsert?: InputMaybe<Array<OperacaoAuditUpsertWithWhereUniqueWithoutOperacaoInput>>;
};

export type OperacaoAuditUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<OperacaoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<OperacaoAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<OperacaoAuditCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<OperacaoAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperacaoAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperacaoAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<OperacaoAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<OperacaoAuditUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<OperacaoAuditUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<OperacaoAuditUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type OperacaoAuditUpdateWithWhereUniqueWithoutOperacaoInput = {
    data: OperacaoAuditUpdateWithoutOperacaoInput;
    where: OperacaoAuditWhereUniqueInput;
};

export type OperacaoAuditUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: OperacaoAuditUpdateWithoutUsuarioInput;
    where: OperacaoAuditWhereUniqueInput;
};

export type OperacaoAuditUpdateWithoutOperacaoInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistOperacaoNestedInput>;
};

export type OperacaoAuditUpdateWithoutUsuarioInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutHistoricoNestedInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OperacaoAuditUpsertWithWhereUniqueWithoutOperacaoInput = {
    create: OperacaoAuditCreateWithoutOperacaoInput;
    update: OperacaoAuditUpdateWithoutOperacaoInput;
    where: OperacaoAuditWhereUniqueInput;
};

export type OperacaoAuditUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: OperacaoAuditCreateWithoutUsuarioInput;
    update: OperacaoAuditUpdateWithoutUsuarioInput;
    where: OperacaoAuditWhereUniqueInput;
};

export type OperacaoAuditWhereInput = {
    AND?: InputMaybe<Array<OperacaoAuditWhereInput>>;
    NOT?: InputMaybe<Array<OperacaoAuditWhereInput>>;
    OR?: InputMaybe<Array<OperacaoAuditWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    operacao?: InputMaybe<OperacaoRelationFilter>;
    operacaoId?: InputMaybe<IntFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type OperacaoAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type OperacaoAvgAggregate = {
    __typename?: "OperacaoAvgAggregate";
    desembolso?: Maybe<Scalars["Decimal"]>;
    id?: Maybe<Scalars["Float"]>;
    iof?: Maybe<Scalars["Decimal"]>;
    keyAccountId?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    modalidadeId?: Maybe<Scalars["Float"]>;
    origemOperacaoId?: Maybe<Scalars["Float"]>;
    repurchasedAmount?: Maybe<Scalars["Decimal"]>;
    salesmanId?: Maybe<Scalars["Float"]>;
    statusId?: Maybe<Scalars["Float"]>;
    taxa?: Maybe<Scalars["Decimal"]>;
    tipoId?: Maybe<Scalars["Float"]>;
};

export type OperacaoAvgOrderByAggregateInput = {
    desembolso?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidadeId?: InputMaybe<SortOrder>;
    origemOperacaoId?: InputMaybe<SortOrder>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    salesmanId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    taxa?: InputMaybe<SortOrder>;
    tipoId?: InputMaybe<SortOrder>;
};

export type OperacaoChecklist = {
    __typename?: "OperacaoChecklist";
    checklist?: Maybe<Scalars["JSON"]>;
    firstOperation: Scalars["Boolean"];
    operacao: Operacao;
    operacaoId: Scalars["Int"];
};

export type OperacaoChecklistAvgAggregate = {
    __typename?: "OperacaoChecklistAvgAggregate";
    operacaoId?: Maybe<Scalars["Float"]>;
};

export type OperacaoChecklistAvgOrderByAggregateInput = {
    operacaoId?: InputMaybe<SortOrder>;
};

export type OperacaoChecklistCountAggregate = {
    __typename?: "OperacaoChecklistCountAggregate";
    _all: Scalars["Int"];
    checklist: Scalars["Int"];
    firstOperation: Scalars["Int"];
    operacaoId: Scalars["Int"];
};

export type OperacaoChecklistCountOrderByAggregateInput = {
    checklist?: InputMaybe<SortOrder>;
    firstOperation?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
};

export type OperacaoChecklistCreateInput = {
    checklist?: InputMaybe<Scalars["JSON"]>;
    firstOperation?: InputMaybe<Scalars["Boolean"]>;
    operacao: OperacaoCreateNestedOneWithoutOperacaoChecklistInput;
};

export type OperacaoChecklistCreateManyInput = {
    checklist?: InputMaybe<Scalars["JSON"]>;
    firstOperation?: InputMaybe<Scalars["Boolean"]>;
    operacaoId: Scalars["Int"];
};

export type OperacaoChecklistCreateNestedOneWithoutOperacaoInput = {
    connect?: InputMaybe<OperacaoChecklistWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoChecklistCreateOrConnectWithoutOperacaoInput>;
    create?: InputMaybe<OperacaoChecklistCreateWithoutOperacaoInput>;
};

export type OperacaoChecklistCreateOrConnectWithoutOperacaoInput = {
    create: OperacaoChecklistCreateWithoutOperacaoInput;
    where: OperacaoChecklistWhereUniqueInput;
};

export type OperacaoChecklistCreateWithoutOperacaoInput = {
    checklist?: InputMaybe<Scalars["JSON"]>;
    firstOperation?: InputMaybe<Scalars["Boolean"]>;
};

export type OperacaoChecklistGroupBy = {
    __typename?: "OperacaoChecklistGroupBy";
    _avg?: Maybe<OperacaoChecklistAvgAggregate>;
    _count?: Maybe<OperacaoChecklistCountAggregate>;
    _max?: Maybe<OperacaoChecklistMaxAggregate>;
    _min?: Maybe<OperacaoChecklistMinAggregate>;
    _sum?: Maybe<OperacaoChecklistSumAggregate>;
    checklist?: Maybe<Scalars["JSON"]>;
    firstOperation: Scalars["Boolean"];
    operacaoId: Scalars["Int"];
};

export type OperacaoChecklistMaxAggregate = {
    __typename?: "OperacaoChecklistMaxAggregate";
    firstOperation?: Maybe<Scalars["Boolean"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
};

export type OperacaoChecklistMaxOrderByAggregateInput = {
    firstOperation?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
};

export type OperacaoChecklistMinAggregate = {
    __typename?: "OperacaoChecklistMinAggregate";
    firstOperation?: Maybe<Scalars["Boolean"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
};

export type OperacaoChecklistMinOrderByAggregateInput = {
    firstOperation?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
};

export type OperacaoChecklistOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperacaoChecklistAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperacaoChecklistCountOrderByAggregateInput>;
    _max?: InputMaybe<OperacaoChecklistMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperacaoChecklistMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperacaoChecklistSumOrderByAggregateInput>;
    checklist?: InputMaybe<SortOrder>;
    firstOperation?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
};

export type OperacaoChecklistOrderByWithRelationInput = {
    checklist?: InputMaybe<SortOrder>;
    firstOperation?: InputMaybe<SortOrder>;
    operacao?: InputMaybe<OperacaoOrderByWithRelationInput>;
    operacaoId?: InputMaybe<SortOrder>;
};

export type OperacaoChecklistRelationFilter = {
    is?: InputMaybe<OperacaoChecklistWhereInput>;
    isNot?: InputMaybe<OperacaoChecklistWhereInput>;
};

export enum OperacaoChecklistScalarFieldEnum {
    Checklist = "checklist",
    FirstOperation = "firstOperation",
    OperacaoId = "operacaoId",
}

export type OperacaoChecklistScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperacaoChecklistScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperacaoChecklistScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperacaoChecklistScalarWhereWithAggregatesInput>>;
    checklist?: InputMaybe<JsonNullableWithAggregatesFilter>;
    firstOperation?: InputMaybe<BoolWithAggregatesFilter>;
    operacaoId?: InputMaybe<IntWithAggregatesFilter>;
};

export type OperacaoChecklistSumAggregate = {
    __typename?: "OperacaoChecklistSumAggregate";
    operacaoId?: Maybe<Scalars["Int"]>;
};

export type OperacaoChecklistSumOrderByAggregateInput = {
    operacaoId?: InputMaybe<SortOrder>;
};

export type OperacaoChecklistUpdateInput = {
    checklist?: InputMaybe<Scalars["JSON"]>;
    firstOperation?: InputMaybe<BoolFieldUpdateOperationsInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperacaoChecklistNestedInput>;
};

export type OperacaoChecklistUpdateManyMutationInput = {
    checklist?: InputMaybe<Scalars["JSON"]>;
    firstOperation?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type OperacaoChecklistUpdateOneWithoutOperacaoNestedInput = {
    connect?: InputMaybe<OperacaoChecklistWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoChecklistCreateOrConnectWithoutOperacaoInput>;
    create?: InputMaybe<OperacaoChecklistCreateWithoutOperacaoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<OperacaoChecklistUpdateWithoutOperacaoInput>;
    upsert?: InputMaybe<OperacaoChecklistUpsertWithoutOperacaoInput>;
};

export type OperacaoChecklistUpdateWithoutOperacaoInput = {
    checklist?: InputMaybe<Scalars["JSON"]>;
    firstOperation?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type OperacaoChecklistUpsertWithoutOperacaoInput = {
    create: OperacaoChecklistCreateWithoutOperacaoInput;
    update: OperacaoChecklistUpdateWithoutOperacaoInput;
};

export type OperacaoChecklistWhereInput = {
    AND?: InputMaybe<Array<OperacaoChecklistWhereInput>>;
    NOT?: InputMaybe<Array<OperacaoChecklistWhereInput>>;
    OR?: InputMaybe<Array<OperacaoChecklistWhereInput>>;
    checklist?: InputMaybe<JsonNullableFilter>;
    firstOperation?: InputMaybe<BoolFilter>;
    operacao?: InputMaybe<OperacaoRelationFilter>;
    operacaoId?: InputMaybe<IntFilter>;
};

export type OperacaoChecklistWhereUniqueInput = {
    operacaoId?: InputMaybe<Scalars["Int"]>;
};

export type OperacaoCount = {
    __typename?: "OperacaoCount";
    OperationUpdates: Scalars["Int"];
    anexos: Scalars["Int"];
    historico: Scalars["Int"];
    recebiveis: Scalars["Int"];
};

export type OperacaoCountAggregate = {
    __typename?: "OperacaoCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    descricao: Scalars["Int"];
    desembolso: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    iof: Scalars["Int"];
    keyAccountId: Scalars["Int"];
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    origemOperacaoId: Scalars["Int"];
    repurchasedAmount: Scalars["Int"];
    salesmanId: Scalars["Int"];
    shouldAnalyzePayerCredit: Scalars["Int"];
    shouldCheckPayable: Scalars["Int"];
    statusId: Scalars["Int"];
    taxa: Scalars["Int"];
    tipoId: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type OperacaoCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    desembolso?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidadeId?: InputMaybe<SortOrder>;
    origemOperacaoId?: InputMaybe<SortOrder>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    salesmanId?: InputMaybe<SortOrder>;
    shouldAnalyzePayerCredit?: InputMaybe<SortOrder>;
    shouldCheckPayable?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    taxa?: InputMaybe<SortOrder>;
    tipoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoCreateInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    keyAccountId?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    origemOperacaoId?: InputMaybe<Scalars["Int"]>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    salesmanId?: InputMaybe<Scalars["Int"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    tipoId?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateManyKeyAccountInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    origemOperacaoId?: InputMaybe<Scalars["Int"]>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    salesmanId?: InputMaybe<Scalars["Int"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    tipoId?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateManyKeyAccountInputEnvelope = {
    data: Array<OperacaoCreateManyKeyAccountInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperacaoCreateManyMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    keyAccountId?: InputMaybe<Scalars["Int"]>;
    modalidadeId: Scalars["Int"];
    origemOperacaoId?: InputMaybe<Scalars["Int"]>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    salesmanId?: InputMaybe<Scalars["Int"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    tipoId?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateManyMinibancoInputEnvelope = {
    data: Array<OperacaoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperacaoCreateManyModalidadeInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    keyAccountId?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    origemOperacaoId?: InputMaybe<Scalars["Int"]>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    salesmanId?: InputMaybe<Scalars["Int"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    tipoId?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateManyModalidadeInputEnvelope = {
    data: Array<OperacaoCreateManyModalidadeInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperacaoCreateManyOrigemOperacaoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    keyAccountId?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    salesmanId?: InputMaybe<Scalars["Int"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    tipoId?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateManyOrigemOperacaoInputEnvelope = {
    data: Array<OperacaoCreateManyOrigemOperacaoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperacaoCreateManySalesmanInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    keyAccountId?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    origemOperacaoId?: InputMaybe<Scalars["Int"]>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    tipoId?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateManySalesmanInputEnvelope = {
    data: Array<OperacaoCreateManySalesmanInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperacaoCreateManyStatusInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    keyAccountId?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    origemOperacaoId?: InputMaybe<Scalars["Int"]>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    salesmanId?: InputMaybe<Scalars["Int"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    taxa: Scalars["Decimal"];
    tipoId?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateManyStatusInputEnvelope = {
    data: Array<OperacaoCreateManyStatusInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperacaoCreateManyTipoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    keyAccountId?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    origemOperacaoId?: InputMaybe<Scalars["Int"]>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    salesmanId?: InputMaybe<Scalars["Int"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateManyTipoInputEnvelope = {
    data: Array<OperacaoCreateManyTipoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperacaoCreateNestedManyWithoutKeyAccountInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutKeyAccountInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutKeyAccountInput>>;
    createMany?: InputMaybe<OperacaoCreateManyKeyAccountInputEnvelope>;
};

export type OperacaoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<OperacaoCreateManyMinibancoInputEnvelope>;
};

export type OperacaoCreateNestedManyWithoutModalidadeInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutModalidadeInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutModalidadeInput>>;
    createMany?: InputMaybe<OperacaoCreateManyModalidadeInputEnvelope>;
};

export type OperacaoCreateNestedManyWithoutOrigemOperacaoInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutOrigemOperacaoInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutOrigemOperacaoInput>>;
    createMany?: InputMaybe<OperacaoCreateManyOrigemOperacaoInputEnvelope>;
};

export type OperacaoCreateNestedManyWithoutSalesmanInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutSalesmanInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutSalesmanInput>>;
    createMany?: InputMaybe<OperacaoCreateManySalesmanInputEnvelope>;
};

export type OperacaoCreateNestedManyWithoutStatusInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutStatusInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutStatusInput>>;
    createMany?: InputMaybe<OperacaoCreateManyStatusInputEnvelope>;
};

export type OperacaoCreateNestedManyWithoutTipoInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutTipoInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutTipoInput>>;
    createMany?: InputMaybe<OperacaoCreateManyTipoInputEnvelope>;
};

export type OperacaoCreateNestedOneWithoutAnexosInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutAnexosInput>;
    create?: InputMaybe<OperacaoCreateWithoutAnexosInput>;
};

export type OperacaoCreateNestedOneWithoutHistoricoInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<OperacaoCreateWithoutHistoricoInput>;
};

export type OperacaoCreateNestedOneWithoutOperacaoChecklistInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutOperacaoChecklistInput>;
    create?: InputMaybe<OperacaoCreateWithoutOperacaoChecklistInput>;
};

export type OperacaoCreateNestedOneWithoutOperacaoIntegracaoInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutOperacaoIntegracaoInput>;
    create?: InputMaybe<OperacaoCreateWithoutOperacaoIntegracaoInput>;
};

export type OperacaoCreateNestedOneWithoutOperationCommisionInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutOperationCommisionInput>;
    create?: InputMaybe<OperacaoCreateWithoutOperationCommisionInput>;
};

export type OperacaoCreateNestedOneWithoutOperationFeesInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutOperationFeesInput>;
    create?: InputMaybe<OperacaoCreateWithoutOperationFeesInput>;
};

export type OperacaoCreateNestedOneWithoutOperationUpdatesInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutOperationUpdatesInput>;
    create?: InputMaybe<OperacaoCreateWithoutOperationUpdatesInput>;
};

export type OperacaoCreateNestedOneWithoutOperationsInfoInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutOperationsInfoInput>;
    create?: InputMaybe<OperacaoCreateWithoutOperationsInfoInput>;
};

export type OperacaoCreateNestedOneWithoutRecebiveisInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutRecebiveisInput>;
    create?: InputMaybe<OperacaoCreateWithoutRecebiveisInput>;
};

export type OperacaoCreateNestedOneWithoutSugeridaInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutSugeridaInput>;
    create?: InputMaybe<OperacaoCreateWithoutSugeridaInput>;
};

export type OperacaoCreateOrConnectWithoutAnexosInput = {
    create: OperacaoCreateWithoutAnexosInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutHistoricoInput = {
    create: OperacaoCreateWithoutHistoricoInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutKeyAccountInput = {
    create: OperacaoCreateWithoutKeyAccountInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutMinibancoInput = {
    create: OperacaoCreateWithoutMinibancoInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutModalidadeInput = {
    create: OperacaoCreateWithoutModalidadeInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutOperacaoChecklistInput = {
    create: OperacaoCreateWithoutOperacaoChecklistInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutOperacaoIntegracaoInput = {
    create: OperacaoCreateWithoutOperacaoIntegracaoInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutOperationCommisionInput = {
    create: OperacaoCreateWithoutOperationCommisionInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutOperationFeesInput = {
    create: OperacaoCreateWithoutOperationFeesInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutOperationUpdatesInput = {
    create: OperacaoCreateWithoutOperationUpdatesInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutOperationsInfoInput = {
    create: OperacaoCreateWithoutOperationsInfoInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutOrigemOperacaoInput = {
    create: OperacaoCreateWithoutOrigemOperacaoInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutRecebiveisInput = {
    create: OperacaoCreateWithoutRecebiveisInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutSalesmanInput = {
    create: OperacaoCreateWithoutSalesmanInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutStatusInput = {
    create: OperacaoCreateWithoutStatusInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutSugeridaInput = {
    create: OperacaoCreateWithoutSugeridaInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateOrConnectWithoutTipoInput = {
    create: OperacaoCreateWithoutTipoInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoCreateWithoutAnexosInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutHistoricoInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutKeyAccountInput = {
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutMinibancoInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutModalidadeInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutOperacaoChecklistInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutOperacaoIntegracaoInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutOperationCommisionInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutOperationFeesInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutOperationUpdatesInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutOperationsInfoInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutOrigemOperacaoInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutRecebiveisInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutSalesmanInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutStatusInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutSugeridaInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    taxa: Scalars["Decimal"];
    tipo?: InputMaybe<OperacaoTipoCreateNestedOneWithoutOperacoesInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoCreateWithoutTipoInput = {
    KeyAccount?: InputMaybe<UsuarioCreateNestedOneWithoutKeyAccountInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput>;
    OperationCommision?: InputMaybe<OperationCommisionCreateNestedOneWithoutOperacaoInput>;
    OperationFees?: InputMaybe<OperationFeesCreateNestedOneWithoutOperationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesCreateNestedManyWithoutOperationInput>;
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedOneWithoutOperacaoInput>;
    Salesman?: InputMaybe<UsuarioCreateNestedOneWithoutSalesmanInput>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    desembolso?: InputMaybe<Scalars["Decimal"]>;
    historico?: InputMaybe<OperacaoAuditCreateNestedManyWithoutOperacaoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutOperacoesInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperacoesInput;
    operacaoChecklist?: InputMaybe<OperacaoChecklistCreateNestedOneWithoutOperacaoInput>;
    origemOperacao?: InputMaybe<MinibancoCreateNestedOneWithoutOperacoesOrigemInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutOperacaoInput>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    shouldAnalyzePayerCredit?: InputMaybe<Scalars["Boolean"]>;
    shouldCheckPayable?: InputMaybe<Scalars["Boolean"]>;
    status: OperacaoStatusCreateNestedOneWithoutOperacoesInput;
    sugerida?: InputMaybe<OperacaoSugeridaCreateNestedOneWithoutOperacaoInput>;
    taxa: Scalars["Decimal"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoGroupBy = {
    __typename?: "OperacaoGroupBy";
    _avg?: Maybe<OperacaoAvgAggregate>;
    _count?: Maybe<OperacaoCountAggregate>;
    _max?: Maybe<OperacaoMaxAggregate>;
    _min?: Maybe<OperacaoMinAggregate>;
    _sum?: Maybe<OperacaoSumAggregate>;
    createdAt: Scalars["DateTime"];
    descricao?: Maybe<Scalars["String"]>;
    desembolso?: Maybe<Scalars["Decimal"]>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    iof?: Maybe<Scalars["Decimal"]>;
    keyAccountId?: Maybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    origemOperacaoId?: Maybe<Scalars["Int"]>;
    repurchasedAmount?: Maybe<Scalars["Decimal"]>;
    salesmanId?: Maybe<Scalars["Int"]>;
    shouldAnalyzePayerCredit: Scalars["Boolean"];
    shouldCheckPayable: Scalars["Boolean"];
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    tipoId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type OperacaoIntegracao = {
    __typename?: "OperacaoIntegracao";
    chave: Scalars["Int"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    operacao: Operacao;
    operacaoId: Scalars["Int"];
    source: Erp;
};

export type OperacaoIntegracaoAvgAggregate = {
    __typename?: "OperacaoIntegracaoAvgAggregate";
    chave?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    operacaoId?: Maybe<Scalars["Float"]>;
};

export type OperacaoIntegracaoAvgOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
};

export type OperacaoIntegracaoCountAggregate = {
    __typename?: "OperacaoIntegracaoCountAggregate";
    _all: Scalars["Int"];
    chave: Scalars["Int"];
    minibancoId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    source: Scalars["Int"];
};

export type OperacaoIntegracaoCountOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
};

export type OperacaoIntegracaoCreateInput = {
    chave: Scalars["Int"];
    minibanco: MinibancoCreateNestedOneWithoutOperacaoIntegracaoInput;
    operacao: OperacaoCreateNestedOneWithoutOperacaoIntegracaoInput;
    source?: InputMaybe<Erp>;
};

export type OperacaoIntegracaoCreateManyInput = {
    chave: Scalars["Int"];
    minibancoId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    source?: InputMaybe<Erp>;
};

export type OperacaoIntegracaoCreateManyMinibancoInput = {
    chave: Scalars["Int"];
    operacaoId: Scalars["Int"];
    source?: InputMaybe<Erp>;
};

export type OperacaoIntegracaoCreateManyMinibancoInputEnvelope = {
    data: Array<OperacaoIntegracaoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperacaoIntegracaoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<OperacaoIntegracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoIntegracaoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<OperacaoIntegracaoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<OperacaoIntegracaoCreateManyMinibancoInputEnvelope>;
};

export type OperacaoIntegracaoCreateNestedOneWithoutOperacaoInput = {
    connect?: InputMaybe<OperacaoIntegracaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoIntegracaoCreateOrConnectWithoutOperacaoInput>;
    create?: InputMaybe<OperacaoIntegracaoCreateWithoutOperacaoInput>;
};

export type OperacaoIntegracaoCreateOrConnectWithoutMinibancoInput = {
    create: OperacaoIntegracaoCreateWithoutMinibancoInput;
    where: OperacaoIntegracaoWhereUniqueInput;
};

export type OperacaoIntegracaoCreateOrConnectWithoutOperacaoInput = {
    create: OperacaoIntegracaoCreateWithoutOperacaoInput;
    where: OperacaoIntegracaoWhereUniqueInput;
};

export type OperacaoIntegracaoCreateWithoutMinibancoInput = {
    chave: Scalars["Int"];
    operacao: OperacaoCreateNestedOneWithoutOperacaoIntegracaoInput;
    source?: InputMaybe<Erp>;
};

export type OperacaoIntegracaoCreateWithoutOperacaoInput = {
    chave: Scalars["Int"];
    minibanco: MinibancoCreateNestedOneWithoutOperacaoIntegracaoInput;
    source?: InputMaybe<Erp>;
};

export type OperacaoIntegracaoGroupBy = {
    __typename?: "OperacaoIntegracaoGroupBy";
    _avg?: Maybe<OperacaoIntegracaoAvgAggregate>;
    _count?: Maybe<OperacaoIntegracaoCountAggregate>;
    _max?: Maybe<OperacaoIntegracaoMaxAggregate>;
    _min?: Maybe<OperacaoIntegracaoMinAggregate>;
    _sum?: Maybe<OperacaoIntegracaoSumAggregate>;
    chave: Scalars["Int"];
    minibancoId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    source: Erp;
};

export type OperacaoIntegracaoListRelationFilter = {
    every?: InputMaybe<OperacaoIntegracaoWhereInput>;
    none?: InputMaybe<OperacaoIntegracaoWhereInput>;
    some?: InputMaybe<OperacaoIntegracaoWhereInput>;
};

export type OperacaoIntegracaoMaxAggregate = {
    __typename?: "OperacaoIntegracaoMaxAggregate";
    chave?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    source?: Maybe<Erp>;
};

export type OperacaoIntegracaoMaxOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
};

export type OperacaoIntegracaoMinAggregate = {
    __typename?: "OperacaoIntegracaoMinAggregate";
    chave?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    source?: Maybe<Erp>;
};

export type OperacaoIntegracaoMinOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
};

export type OperacaoIntegracaoOperacaoChaveCompoundUniqueInput = {
    chave: Scalars["Int"];
    minibancoId: Scalars["Int"];
    source: Erp;
};

export type OperacaoIntegracaoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type OperacaoIntegracaoOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperacaoIntegracaoAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperacaoIntegracaoCountOrderByAggregateInput>;
    _max?: InputMaybe<OperacaoIntegracaoMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperacaoIntegracaoMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperacaoIntegracaoSumOrderByAggregateInput>;
    chave?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
};

export type OperacaoIntegracaoOrderByWithRelationInput = {
    chave?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    operacao?: InputMaybe<OperacaoOrderByWithRelationInput>;
    operacaoId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
};

export type OperacaoIntegracaoRelationFilter = {
    is?: InputMaybe<OperacaoIntegracaoWhereInput>;
    isNot?: InputMaybe<OperacaoIntegracaoWhereInput>;
};

export enum OperacaoIntegracaoScalarFieldEnum {
    Chave = "chave",
    MinibancoId = "minibancoId",
    OperacaoId = "operacaoId",
    Source = "source",
}

export type OperacaoIntegracaoScalarWhereInput = {
    AND?: InputMaybe<Array<OperacaoIntegracaoScalarWhereInput>>;
    NOT?: InputMaybe<Array<OperacaoIntegracaoScalarWhereInput>>;
    OR?: InputMaybe<Array<OperacaoIntegracaoScalarWhereInput>>;
    chave?: InputMaybe<IntFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    operacaoId?: InputMaybe<IntFilter>;
    source?: InputMaybe<EnumErpFilter>;
};

export type OperacaoIntegracaoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperacaoIntegracaoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperacaoIntegracaoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperacaoIntegracaoScalarWhereWithAggregatesInput>>;
    chave?: InputMaybe<IntWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    operacaoId?: InputMaybe<IntWithAggregatesFilter>;
    source?: InputMaybe<EnumErpWithAggregatesFilter>;
};

export type OperacaoIntegracaoSumAggregate = {
    __typename?: "OperacaoIntegracaoSumAggregate";
    chave?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
};

export type OperacaoIntegracaoSumOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
};

export type OperacaoIntegracaoUpdateInput = {
    chave?: InputMaybe<IntFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacaoIntegracaoNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperacaoIntegracaoNestedInput>;
    source?: InputMaybe<EnumErpFieldUpdateOperationsInput>;
};

export type OperacaoIntegracaoUpdateManyMutationInput = {
    chave?: InputMaybe<IntFieldUpdateOperationsInput>;
    source?: InputMaybe<EnumErpFieldUpdateOperationsInput>;
};

export type OperacaoIntegracaoUpdateManyWithWhereWithoutMinibancoInput = {
    data: OperacaoIntegracaoUpdateManyMutationInput;
    where: OperacaoIntegracaoScalarWhereInput;
};

export type OperacaoIntegracaoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<OperacaoIntegracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoIntegracaoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<OperacaoIntegracaoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<OperacaoIntegracaoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<OperacaoIntegracaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperacaoIntegracaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperacaoIntegracaoWhereUniqueInput>>;
    set?: InputMaybe<Array<OperacaoIntegracaoWhereUniqueInput>>;
    update?: InputMaybe<Array<OperacaoIntegracaoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<OperacaoIntegracaoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<OperacaoIntegracaoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput = {
    connect?: InputMaybe<OperacaoIntegracaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoIntegracaoCreateOrConnectWithoutOperacaoInput>;
    create?: InputMaybe<OperacaoIntegracaoCreateWithoutOperacaoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<OperacaoIntegracaoUpdateWithoutOperacaoInput>;
    upsert?: InputMaybe<OperacaoIntegracaoUpsertWithoutOperacaoInput>;
};

export type OperacaoIntegracaoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: OperacaoIntegracaoUpdateWithoutMinibancoInput;
    where: OperacaoIntegracaoWhereUniqueInput;
};

export type OperacaoIntegracaoUpdateWithoutMinibancoInput = {
    chave?: InputMaybe<IntFieldUpdateOperationsInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperacaoIntegracaoNestedInput>;
    source?: InputMaybe<EnumErpFieldUpdateOperationsInput>;
};

export type OperacaoIntegracaoUpdateWithoutOperacaoInput = {
    chave?: InputMaybe<IntFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacaoIntegracaoNestedInput>;
    source?: InputMaybe<EnumErpFieldUpdateOperationsInput>;
};

export type OperacaoIntegracaoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: OperacaoIntegracaoCreateWithoutMinibancoInput;
    update: OperacaoIntegracaoUpdateWithoutMinibancoInput;
    where: OperacaoIntegracaoWhereUniqueInput;
};

export type OperacaoIntegracaoUpsertWithoutOperacaoInput = {
    create: OperacaoIntegracaoCreateWithoutOperacaoInput;
    update: OperacaoIntegracaoUpdateWithoutOperacaoInput;
};

export type OperacaoIntegracaoWhereInput = {
    AND?: InputMaybe<Array<OperacaoIntegracaoWhereInput>>;
    NOT?: InputMaybe<Array<OperacaoIntegracaoWhereInput>>;
    OR?: InputMaybe<Array<OperacaoIntegracaoWhereInput>>;
    chave?: InputMaybe<IntFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    operacao?: InputMaybe<OperacaoRelationFilter>;
    operacaoId?: InputMaybe<IntFilter>;
    source?: InputMaybe<EnumErpFilter>;
};

export type OperacaoIntegracaoWhereUniqueInput = {
    operacaoChave?: InputMaybe<OperacaoIntegracaoOperacaoChaveCompoundUniqueInput>;
    operacaoId?: InputMaybe<Scalars["Int"]>;
};

export type OperacaoListRelationFilter = {
    every?: InputMaybe<OperacaoWhereInput>;
    none?: InputMaybe<OperacaoWhereInput>;
    some?: InputMaybe<OperacaoWhereInput>;
};

export type OperacaoMaxAggregate = {
    __typename?: "OperacaoMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    desembolso?: Maybe<Scalars["Decimal"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    iof?: Maybe<Scalars["Decimal"]>;
    keyAccountId?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    modalidadeId?: Maybe<Scalars["Int"]>;
    origemOperacaoId?: Maybe<Scalars["Int"]>;
    repurchasedAmount?: Maybe<Scalars["Decimal"]>;
    salesmanId?: Maybe<Scalars["Int"]>;
    shouldAnalyzePayerCredit?: Maybe<Scalars["Boolean"]>;
    shouldCheckPayable?: Maybe<Scalars["Boolean"]>;
    statusId?: Maybe<Scalars["Int"]>;
    taxa?: Maybe<Scalars["Decimal"]>;
    tipoId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperacaoMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    desembolso?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidadeId?: InputMaybe<SortOrder>;
    origemOperacaoId?: InputMaybe<SortOrder>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    salesmanId?: InputMaybe<SortOrder>;
    shouldAnalyzePayerCredit?: InputMaybe<SortOrder>;
    shouldCheckPayable?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    taxa?: InputMaybe<SortOrder>;
    tipoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoMinAggregate = {
    __typename?: "OperacaoMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    desembolso?: Maybe<Scalars["Decimal"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    iof?: Maybe<Scalars["Decimal"]>;
    keyAccountId?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    modalidadeId?: Maybe<Scalars["Int"]>;
    origemOperacaoId?: Maybe<Scalars["Int"]>;
    repurchasedAmount?: Maybe<Scalars["Decimal"]>;
    salesmanId?: Maybe<Scalars["Int"]>;
    shouldAnalyzePayerCredit?: Maybe<Scalars["Boolean"]>;
    shouldCheckPayable?: Maybe<Scalars["Boolean"]>;
    statusId?: Maybe<Scalars["Int"]>;
    taxa?: Maybe<Scalars["Decimal"]>;
    tipoId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperacaoMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    desembolso?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidadeId?: InputMaybe<SortOrder>;
    origemOperacaoId?: InputMaybe<SortOrder>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    salesmanId?: InputMaybe<SortOrder>;
    shouldAnalyzePayerCredit?: InputMaybe<SortOrder>;
    shouldCheckPayable?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    taxa?: InputMaybe<SortOrder>;
    tipoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoModalidade = {
    __typename?: "OperacaoModalidade";
    OperationsInfo: Array<OperationsInfo>;
    _count?: Maybe<OperacaoModalidadeCount>;
    abreviacao?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    descricao: Scalars["String"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    operacoes: Array<Operacao>;
    riscoZero: Scalars["Boolean"];
    sigla: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type OperacaoModalidadeOperationsInfoArgs = {
    cursor?: InputMaybe<OperationsInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationsInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationsInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationsInfoWhereInput>;
};

export type OperacaoModalidadeOperacoesArgs = {
    cursor?: InputMaybe<OperacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoWhereInput>;
};

export type OperacaoModalidadeAvgAggregate = {
    __typename?: "OperacaoModalidadeAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
};

export type OperacaoModalidadeAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type OperacaoModalidadeCount = {
    __typename?: "OperacaoModalidadeCount";
    OperationsInfo: Scalars["Int"];
    operacoes: Scalars["Int"];
};

export type OperacaoModalidadeCountAggregate = {
    __typename?: "OperacaoModalidadeCountAggregate";
    _all: Scalars["Int"];
    abreviacao: Scalars["Int"];
    createdAt: Scalars["Int"];
    descricao: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    riscoZero: Scalars["Int"];
    sigla: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type OperacaoModalidadeCountOrderByAggregateInput = {
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    riscoZero?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoModalidadeCreateInput = {
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutModalidadeInput>;
    abreviacao?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao: Scalars["String"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutModalidadeInput>;
    riscoZero?: InputMaybe<Scalars["Boolean"]>;
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoModalidadeCreateManyInput = {
    abreviacao?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    riscoZero?: InputMaybe<Scalars["Boolean"]>;
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoModalidadeCreateNestedOneWithoutOperacoesInput = {
    connect?: InputMaybe<OperacaoModalidadeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoModalidadeCreateOrConnectWithoutOperacoesInput>;
    create?: InputMaybe<OperacaoModalidadeCreateWithoutOperacoesInput>;
};

export type OperacaoModalidadeCreateNestedOneWithoutOperationsInfoInput = {
    connect?: InputMaybe<OperacaoModalidadeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoModalidadeCreateOrConnectWithoutOperationsInfoInput>;
    create?: InputMaybe<OperacaoModalidadeCreateWithoutOperationsInfoInput>;
};

export type OperacaoModalidadeCreateOrConnectWithoutOperacoesInput = {
    create: OperacaoModalidadeCreateWithoutOperacoesInput;
    where: OperacaoModalidadeWhereUniqueInput;
};

export type OperacaoModalidadeCreateOrConnectWithoutOperationsInfoInput = {
    create: OperacaoModalidadeCreateWithoutOperationsInfoInput;
    where: OperacaoModalidadeWhereUniqueInput;
};

export type OperacaoModalidadeCreateWithoutOperacoesInput = {
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutModalidadeInput>;
    abreviacao?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao: Scalars["String"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    riscoZero?: InputMaybe<Scalars["Boolean"]>;
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoModalidadeCreateWithoutOperationsInfoInput = {
    abreviacao?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao: Scalars["String"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutModalidadeInput>;
    riscoZero?: InputMaybe<Scalars["Boolean"]>;
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoModalidadeGroupBy = {
    __typename?: "OperacaoModalidadeGroupBy";
    _avg?: Maybe<OperacaoModalidadeAvgAggregate>;
    _count?: Maybe<OperacaoModalidadeCountAggregate>;
    _max?: Maybe<OperacaoModalidadeMaxAggregate>;
    _min?: Maybe<OperacaoModalidadeMinAggregate>;
    _sum?: Maybe<OperacaoModalidadeSumAggregate>;
    abreviacao?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    descricao: Scalars["String"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    riscoZero: Scalars["Boolean"];
    sigla: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type OperacaoModalidadeMaxAggregate = {
    __typename?: "OperacaoModalidadeMaxAggregate";
    abreviacao?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    riscoZero?: Maybe<Scalars["Boolean"]>;
    sigla?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperacaoModalidadeMaxOrderByAggregateInput = {
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    riscoZero?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoModalidadeMinAggregate = {
    __typename?: "OperacaoModalidadeMinAggregate";
    abreviacao?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    riscoZero?: Maybe<Scalars["Boolean"]>;
    sigla?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperacaoModalidadeMinOrderByAggregateInput = {
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    riscoZero?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoModalidadeOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperacaoModalidadeAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperacaoModalidadeCountOrderByAggregateInput>;
    _max?: InputMaybe<OperacaoModalidadeMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperacaoModalidadeMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperacaoModalidadeSumOrderByAggregateInput>;
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    riscoZero?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoModalidadeOrderByWithRelationInput = {
    OperationsInfo?: InputMaybe<OperationsInfoOrderByRelationAggregateInput>;
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    operacoes?: InputMaybe<OperacaoOrderByRelationAggregateInput>;
    riscoZero?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoModalidadeRelationFilter = {
    is?: InputMaybe<OperacaoModalidadeWhereInput>;
    isNot?: InputMaybe<OperacaoModalidadeWhereInput>;
};

export enum OperacaoModalidadeScalarFieldEnum {
    Abreviacao = "abreviacao",
    CreatedAt = "createdAt",
    Descricao = "descricao",
    Id = "id",
    Inativo = "inativo",
    RiscoZero = "riscoZero",
    Sigla = "sigla",
    UpdatedAt = "updatedAt",
}

export type OperacaoModalidadeScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperacaoModalidadeScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperacaoModalidadeScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperacaoModalidadeScalarWhereWithAggregatesInput>>;
    abreviacao?: InputMaybe<StringNullableWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    descricao?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    riscoZero?: InputMaybe<BoolWithAggregatesFilter>;
    sigla?: InputMaybe<StringWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type OperacaoModalidadeSumAggregate = {
    __typename?: "OperacaoModalidadeSumAggregate";
    id?: Maybe<Scalars["Int"]>;
};

export type OperacaoModalidadeSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type OperacaoModalidadeUpdateInput = {
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutModalidadeNestedInput>;
    abreviacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutModalidadeNestedInput>;
    riscoZero?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoModalidadeUpdateManyMutationInput = {
    abreviacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    riscoZero?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput = {
    connect?: InputMaybe<OperacaoModalidadeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoModalidadeCreateOrConnectWithoutOperacoesInput>;
    create?: InputMaybe<OperacaoModalidadeCreateWithoutOperacoesInput>;
    update?: InputMaybe<OperacaoModalidadeUpdateWithoutOperacoesInput>;
    upsert?: InputMaybe<OperacaoModalidadeUpsertWithoutOperacoesInput>;
};

export type OperacaoModalidadeUpdateOneRequiredWithoutOperationsInfoNestedInput = {
    connect?: InputMaybe<OperacaoModalidadeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoModalidadeCreateOrConnectWithoutOperationsInfoInput>;
    create?: InputMaybe<OperacaoModalidadeCreateWithoutOperationsInfoInput>;
    update?: InputMaybe<OperacaoModalidadeUpdateWithoutOperationsInfoInput>;
    upsert?: InputMaybe<OperacaoModalidadeUpsertWithoutOperationsInfoInput>;
};

export type OperacaoModalidadeUpdateWithoutOperacoesInput = {
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutModalidadeNestedInput>;
    abreviacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    riscoZero?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoModalidadeUpdateWithoutOperationsInfoInput = {
    abreviacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutModalidadeNestedInput>;
    riscoZero?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoModalidadeUpsertWithoutOperacoesInput = {
    create: OperacaoModalidadeCreateWithoutOperacoesInput;
    update: OperacaoModalidadeUpdateWithoutOperacoesInput;
};

export type OperacaoModalidadeUpsertWithoutOperationsInfoInput = {
    create: OperacaoModalidadeCreateWithoutOperationsInfoInput;
    update: OperacaoModalidadeUpdateWithoutOperationsInfoInput;
};

export type OperacaoModalidadeWhereInput = {
    AND?: InputMaybe<Array<OperacaoModalidadeWhereInput>>;
    NOT?: InputMaybe<Array<OperacaoModalidadeWhereInput>>;
    OR?: InputMaybe<Array<OperacaoModalidadeWhereInput>>;
    OperationsInfo?: InputMaybe<OperationsInfoListRelationFilter>;
    abreviacao?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    descricao?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    operacoes?: InputMaybe<OperacaoListRelationFilter>;
    riscoZero?: InputMaybe<BoolFilter>;
    sigla?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OperacaoModalidadeWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
    sigla?: InputMaybe<Scalars["String"]>;
};

export type OperacaoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type OperacaoOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperacaoAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperacaoCountOrderByAggregateInput>;
    _max?: InputMaybe<OperacaoMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperacaoMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperacaoSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    desembolso?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidadeId?: InputMaybe<SortOrder>;
    origemOperacaoId?: InputMaybe<SortOrder>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    salesmanId?: InputMaybe<SortOrder>;
    shouldAnalyzePayerCredit?: InputMaybe<SortOrder>;
    shouldCheckPayable?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    taxa?: InputMaybe<SortOrder>;
    tipoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoOrderByWithRelationInput = {
    KeyAccount?: InputMaybe<UsuarioOrderByWithRelationInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoOrderByWithRelationInput>;
    OperationCommision?: InputMaybe<OperationCommisionOrderByWithRelationInput>;
    OperationFees?: InputMaybe<OperationFeesOrderByWithRelationInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesOrderByRelationAggregateInput>;
    OperationsInfo?: InputMaybe<OperationsInfoOrderByWithRelationInput>;
    Salesman?: InputMaybe<UsuarioOrderByWithRelationInput>;
    anexos?: InputMaybe<OperacaoAnexosOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    desembolso?: InputMaybe<SortOrder>;
    historico?: InputMaybe<OperacaoAuditOrderByRelationAggregateInput>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidade?: InputMaybe<OperacaoModalidadeOrderByWithRelationInput>;
    modalidadeId?: InputMaybe<SortOrder>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistOrderByWithRelationInput>;
    origemOperacao?: InputMaybe<MinibancoOrderByWithRelationInput>;
    origemOperacaoId?: InputMaybe<SortOrder>;
    recebiveis?: InputMaybe<RecebivelOrderByRelationAggregateInput>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    salesmanId?: InputMaybe<SortOrder>;
    shouldAnalyzePayerCredit?: InputMaybe<SortOrder>;
    shouldCheckPayable?: InputMaybe<SortOrder>;
    status?: InputMaybe<OperacaoStatusOrderByWithRelationInput>;
    statusId?: InputMaybe<SortOrder>;
    sugerida?: InputMaybe<OperacaoSugeridaOrderByWithRelationInput>;
    taxa?: InputMaybe<SortOrder>;
    tipo?: InputMaybe<OperacaoTipoOrderByWithRelationInput>;
    tipoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoRascunho = {
    __typename?: "OperacaoRascunho";
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    usuarioId: Scalars["Int"];
    valor: Scalars["String"];
};

export type OperacaoRascunhoAvgAggregate = {
    __typename?: "OperacaoRascunhoAvgAggregate";
    cadastroId?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type OperacaoRascunhoAvgOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacaoRascunhoCadastroIdCampoCompoundUniqueInput = {
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
};

export type OperacaoRascunhoCountAggregate = {
    __typename?: "OperacaoRascunhoCountAggregate";
    _all: Scalars["Int"];
    cadastroId: Scalars["Int"];
    campo: Scalars["Int"];
    createdAt: Scalars["Int"];
    updatedAt: Scalars["Int"];
    usuarioId: Scalars["Int"];
    valor: Scalars["Int"];
};

export type OperacaoRascunhoCountOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type OperacaoRascunhoCreateInput = {
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
    valor: Scalars["String"];
};

export type OperacaoRascunhoCreateManyInput = {
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
    valor: Scalars["String"];
};

export type OperacaoRascunhoGroupBy = {
    __typename?: "OperacaoRascunhoGroupBy";
    _avg?: Maybe<OperacaoRascunhoAvgAggregate>;
    _count?: Maybe<OperacaoRascunhoCountAggregate>;
    _max?: Maybe<OperacaoRascunhoMaxAggregate>;
    _min?: Maybe<OperacaoRascunhoMinAggregate>;
    _sum?: Maybe<OperacaoRascunhoSumAggregate>;
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    usuarioId: Scalars["Int"];
    valor: Scalars["String"];
};

export type OperacaoRascunhoMaxAggregate = {
    __typename?: "OperacaoRascunhoMaxAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    campo?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
    valor?: Maybe<Scalars["String"]>;
};

export type OperacaoRascunhoMaxOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type OperacaoRascunhoMinAggregate = {
    __typename?: "OperacaoRascunhoMinAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    campo?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
    valor?: Maybe<Scalars["String"]>;
};

export type OperacaoRascunhoMinOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type OperacaoRascunhoOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperacaoRascunhoAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperacaoRascunhoCountOrderByAggregateInput>;
    _max?: InputMaybe<OperacaoRascunhoMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperacaoRascunhoMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperacaoRascunhoSumOrderByAggregateInput>;
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type OperacaoRascunhoOrderByWithRelationInput = {
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export enum OperacaoRascunhoScalarFieldEnum {
    CadastroId = "cadastroId",
    Campo = "campo",
    CreatedAt = "createdAt",
    UpdatedAt = "updatedAt",
    UsuarioId = "usuarioId",
    Valor = "valor",
}

export type OperacaoRascunhoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperacaoRascunhoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperacaoRascunhoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperacaoRascunhoScalarWhereWithAggregatesInput>>;
    cadastroId?: InputMaybe<IntWithAggregatesFilter>;
    campo?: InputMaybe<StringWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntWithAggregatesFilter>;
    valor?: InputMaybe<StringWithAggregatesFilter>;
};

export type OperacaoRascunhoSumAggregate = {
    __typename?: "OperacaoRascunhoSumAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type OperacaoRascunhoSumOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacaoRascunhoUpdateInput = {
    cadastroId?: InputMaybe<IntFieldUpdateOperationsInput>;
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarioId?: InputMaybe<IntFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OperacaoRascunhoUpdateManyMutationInput = {
    cadastroId?: InputMaybe<IntFieldUpdateOperationsInput>;
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarioId?: InputMaybe<IntFieldUpdateOperationsInput>;
    valor?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OperacaoRascunhoWhereInput = {
    AND?: InputMaybe<Array<OperacaoRascunhoWhereInput>>;
    NOT?: InputMaybe<Array<OperacaoRascunhoWhereInput>>;
    OR?: InputMaybe<Array<OperacaoRascunhoWhereInput>>;
    cadastroId?: InputMaybe<IntFilter>;
    campo?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuarioId?: InputMaybe<IntFilter>;
    valor?: InputMaybe<StringFilter>;
};

export type OperacaoRascunhoWhereUniqueInput = {
    cadastroId_campo?: InputMaybe<OperacaoRascunhoCadastroIdCampoCompoundUniqueInput>;
};

export type OperacaoRelationFilter = {
    is?: InputMaybe<OperacaoWhereInput>;
    isNot?: InputMaybe<OperacaoWhereInput>;
};

export enum OperacaoScalarFieldEnum {
    CreatedAt = "createdAt",
    Descricao = "descricao",
    Desembolso = "desembolso",
    Id = "id",
    Inativo = "inativo",
    Iof = "iof",
    KeyAccountId = "keyAccountId",
    MinibancoId = "minibancoId",
    ModalidadeId = "modalidadeId",
    OrigemOperacaoId = "origemOperacaoId",
    RepurchasedAmount = "repurchasedAmount",
    SalesmanId = "salesmanId",
    ShouldAnalyzePayerCredit = "shouldAnalyzePayerCredit",
    ShouldCheckPayable = "shouldCheckPayable",
    StatusId = "statusId",
    Taxa = "taxa",
    TipoId = "tipoId",
    UpdatedAt = "updatedAt",
}

export type OperacaoScalarWhereInput = {
    AND?: InputMaybe<Array<OperacaoScalarWhereInput>>;
    NOT?: InputMaybe<Array<OperacaoScalarWhereInput>>;
    OR?: InputMaybe<Array<OperacaoScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    descricao?: InputMaybe<StringNullableFilter>;
    desembolso?: InputMaybe<DecimalNullableFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    iof?: InputMaybe<DecimalNullableFilter>;
    keyAccountId?: InputMaybe<IntNullableFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    modalidadeId?: InputMaybe<IntFilter>;
    origemOperacaoId?: InputMaybe<IntNullableFilter>;
    repurchasedAmount?: InputMaybe<DecimalNullableFilter>;
    salesmanId?: InputMaybe<IntNullableFilter>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFilter>;
    shouldCheckPayable?: InputMaybe<BoolFilter>;
    statusId?: InputMaybe<IntFilter>;
    taxa?: InputMaybe<DecimalFilter>;
    tipoId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OperacaoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperacaoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperacaoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperacaoScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    descricao?: InputMaybe<StringNullableWithAggregatesFilter>;
    desembolso?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    iof?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    keyAccountId?: InputMaybe<IntNullableWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    modalidadeId?: InputMaybe<IntWithAggregatesFilter>;
    origemOperacaoId?: InputMaybe<IntNullableWithAggregatesFilter>;
    repurchasedAmount?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    salesmanId?: InputMaybe<IntNullableWithAggregatesFilter>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolWithAggregatesFilter>;
    shouldCheckPayable?: InputMaybe<BoolWithAggregatesFilter>;
    statusId?: InputMaybe<IntWithAggregatesFilter>;
    taxa?: InputMaybe<DecimalWithAggregatesFilter>;
    tipoId?: InputMaybe<IntWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type OperacaoStatus = {
    __typename?: "OperacaoStatus";
    OperationsInfo: Array<OperationsInfo>;
    _count?: Maybe<OperacaoStatusCount>;
    abreviacao?: Maybe<Scalars["String"]>;
    anexos: Array<OperacaoAnexos>;
    createdAt: Scalars["DateTime"];
    descricao: Scalars["String"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    operacoes: Array<Operacao>;
    sigla: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type OperacaoStatusOperationsInfoArgs = {
    cursor?: InputMaybe<OperationsInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationsInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationsInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationsInfoWhereInput>;
};

export type OperacaoStatusAnexosArgs = {
    cursor?: InputMaybe<OperacaoAnexosWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoAnexosScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoAnexosOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoAnexosWhereInput>;
};

export type OperacaoStatusOperacoesArgs = {
    cursor?: InputMaybe<OperacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoWhereInput>;
};

export type OperacaoStatusAvgAggregate = {
    __typename?: "OperacaoStatusAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
};

export type OperacaoStatusAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type OperacaoStatusCount = {
    __typename?: "OperacaoStatusCount";
    OperationsInfo: Scalars["Int"];
    anexos: Scalars["Int"];
    operacoes: Scalars["Int"];
};

export type OperacaoStatusCountAggregate = {
    __typename?: "OperacaoStatusCountAggregate";
    _all: Scalars["Int"];
    abreviacao: Scalars["Int"];
    createdAt: Scalars["Int"];
    descricao: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    sigla: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type OperacaoStatusCountOrderByAggregateInput = {
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoStatusCreateInput = {
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutStatusInput>;
    abreviacao?: InputMaybe<Scalars["String"]>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoStatusInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao: Scalars["String"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutStatusInput>;
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoStatusCreateManyInput = {
    abreviacao?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoStatusCreateNestedOneWithoutAnexosInput = {
    connect?: InputMaybe<OperacaoStatusWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoStatusCreateOrConnectWithoutAnexosInput>;
    create?: InputMaybe<OperacaoStatusCreateWithoutAnexosInput>;
};

export type OperacaoStatusCreateNestedOneWithoutOperacoesInput = {
    connect?: InputMaybe<OperacaoStatusWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoStatusCreateOrConnectWithoutOperacoesInput>;
    create?: InputMaybe<OperacaoStatusCreateWithoutOperacoesInput>;
};

export type OperacaoStatusCreateNestedOneWithoutOperationsInfoInput = {
    connect?: InputMaybe<OperacaoStatusWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoStatusCreateOrConnectWithoutOperationsInfoInput>;
    create?: InputMaybe<OperacaoStatusCreateWithoutOperationsInfoInput>;
};

export type OperacaoStatusCreateOrConnectWithoutAnexosInput = {
    create: OperacaoStatusCreateWithoutAnexosInput;
    where: OperacaoStatusWhereUniqueInput;
};

export type OperacaoStatusCreateOrConnectWithoutOperacoesInput = {
    create: OperacaoStatusCreateWithoutOperacoesInput;
    where: OperacaoStatusWhereUniqueInput;
};

export type OperacaoStatusCreateOrConnectWithoutOperationsInfoInput = {
    create: OperacaoStatusCreateWithoutOperationsInfoInput;
    where: OperacaoStatusWhereUniqueInput;
};

export type OperacaoStatusCreateWithoutAnexosInput = {
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutStatusInput>;
    abreviacao?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao: Scalars["String"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutStatusInput>;
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoStatusCreateWithoutOperacoesInput = {
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutStatusInput>;
    abreviacao?: InputMaybe<Scalars["String"]>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoStatusInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao: Scalars["String"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoStatusCreateWithoutOperationsInfoInput = {
    abreviacao?: InputMaybe<Scalars["String"]>;
    anexos?: InputMaybe<OperacaoAnexosCreateNestedManyWithoutOperacaoStatusInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao: Scalars["String"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutStatusInput>;
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoStatusGroupBy = {
    __typename?: "OperacaoStatusGroupBy";
    _avg?: Maybe<OperacaoStatusAvgAggregate>;
    _count?: Maybe<OperacaoStatusCountAggregate>;
    _max?: Maybe<OperacaoStatusMaxAggregate>;
    _min?: Maybe<OperacaoStatusMinAggregate>;
    _sum?: Maybe<OperacaoStatusSumAggregate>;
    abreviacao?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    descricao: Scalars["String"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    sigla: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type OperacaoStatusMaxAggregate = {
    __typename?: "OperacaoStatusMaxAggregate";
    abreviacao?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    sigla?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperacaoStatusMaxOrderByAggregateInput = {
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoStatusMinAggregate = {
    __typename?: "OperacaoStatusMinAggregate";
    abreviacao?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    sigla?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperacaoStatusMinOrderByAggregateInput = {
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoStatusOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperacaoStatusAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperacaoStatusCountOrderByAggregateInput>;
    _max?: InputMaybe<OperacaoStatusMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperacaoStatusMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperacaoStatusSumOrderByAggregateInput>;
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoStatusOrderByWithRelationInput = {
    OperationsInfo?: InputMaybe<OperationsInfoOrderByRelationAggregateInput>;
    abreviacao?: InputMaybe<SortOrder>;
    anexos?: InputMaybe<OperacaoAnexosOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    operacoes?: InputMaybe<OperacaoOrderByRelationAggregateInput>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoStatusRelationFilter = {
    is?: InputMaybe<OperacaoStatusWhereInput>;
    isNot?: InputMaybe<OperacaoStatusWhereInput>;
};

export enum OperacaoStatusScalarFieldEnum {
    Abreviacao = "abreviacao",
    CreatedAt = "createdAt",
    Descricao = "descricao",
    Id = "id",
    Inativo = "inativo",
    Sigla = "sigla",
    UpdatedAt = "updatedAt",
}

export type OperacaoStatusScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperacaoStatusScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperacaoStatusScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperacaoStatusScalarWhereWithAggregatesInput>>;
    abreviacao?: InputMaybe<StringNullableWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    descricao?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    sigla?: InputMaybe<StringWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type OperacaoStatusSumAggregate = {
    __typename?: "OperacaoStatusSumAggregate";
    id?: Maybe<Scalars["Int"]>;
};

export type OperacaoStatusSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type OperacaoStatusUpdateInput = {
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutStatusNestedInput>;
    abreviacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoStatusNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutStatusNestedInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoStatusUpdateManyMutationInput = {
    abreviacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoStatusUpdateOneRequiredWithoutAnexosNestedInput = {
    connect?: InputMaybe<OperacaoStatusWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoStatusCreateOrConnectWithoutAnexosInput>;
    create?: InputMaybe<OperacaoStatusCreateWithoutAnexosInput>;
    update?: InputMaybe<OperacaoStatusUpdateWithoutAnexosInput>;
    upsert?: InputMaybe<OperacaoStatusUpsertWithoutAnexosInput>;
};

export type OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput = {
    connect?: InputMaybe<OperacaoStatusWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoStatusCreateOrConnectWithoutOperacoesInput>;
    create?: InputMaybe<OperacaoStatusCreateWithoutOperacoesInput>;
    update?: InputMaybe<OperacaoStatusUpdateWithoutOperacoesInput>;
    upsert?: InputMaybe<OperacaoStatusUpsertWithoutOperacoesInput>;
};

export type OperacaoStatusUpdateOneRequiredWithoutOperationsInfoNestedInput = {
    connect?: InputMaybe<OperacaoStatusWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoStatusCreateOrConnectWithoutOperationsInfoInput>;
    create?: InputMaybe<OperacaoStatusCreateWithoutOperationsInfoInput>;
    update?: InputMaybe<OperacaoStatusUpdateWithoutOperationsInfoInput>;
    upsert?: InputMaybe<OperacaoStatusUpsertWithoutOperationsInfoInput>;
};

export type OperacaoStatusUpdateWithoutAnexosInput = {
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutStatusNestedInput>;
    abreviacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutStatusNestedInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoStatusUpdateWithoutOperacoesInput = {
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutStatusNestedInput>;
    abreviacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoStatusNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoStatusUpdateWithoutOperationsInfoInput = {
    abreviacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoStatusNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutStatusNestedInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoStatusUpsertWithoutAnexosInput = {
    create: OperacaoStatusCreateWithoutAnexosInput;
    update: OperacaoStatusUpdateWithoutAnexosInput;
};

export type OperacaoStatusUpsertWithoutOperacoesInput = {
    create: OperacaoStatusCreateWithoutOperacoesInput;
    update: OperacaoStatusUpdateWithoutOperacoesInput;
};

export type OperacaoStatusUpsertWithoutOperationsInfoInput = {
    create: OperacaoStatusCreateWithoutOperationsInfoInput;
    update: OperacaoStatusUpdateWithoutOperationsInfoInput;
};

export type OperacaoStatusWhereInput = {
    AND?: InputMaybe<Array<OperacaoStatusWhereInput>>;
    NOT?: InputMaybe<Array<OperacaoStatusWhereInput>>;
    OR?: InputMaybe<Array<OperacaoStatusWhereInput>>;
    OperationsInfo?: InputMaybe<OperationsInfoListRelationFilter>;
    abreviacao?: InputMaybe<StringNullableFilter>;
    anexos?: InputMaybe<OperacaoAnexosListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    descricao?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    operacoes?: InputMaybe<OperacaoListRelationFilter>;
    sigla?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OperacaoStatusWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
    sigla?: InputMaybe<Scalars["String"]>;
};

export type OperacaoSugerida = {
    __typename?: "OperacaoSugerida";
    createdAt: Scalars["DateTime"];
    operacao: Operacao;
    operacaoId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type OperacaoSugeridaAvgAggregate = {
    __typename?: "OperacaoSugeridaAvgAggregate";
    operacaoId?: Maybe<Scalars["Float"]>;
};

export type OperacaoSugeridaAvgOrderByAggregateInput = {
    operacaoId?: InputMaybe<SortOrder>;
};

export type OperacaoSugeridaCountAggregate = {
    __typename?: "OperacaoSugeridaCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    operacaoId: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type OperacaoSugeridaCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoSugeridaCreateInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    operacao: OperacaoCreateNestedOneWithoutSugeridaInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoSugeridaCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    operacaoId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoSugeridaCreateNestedOneWithoutOperacaoInput = {
    connect?: InputMaybe<OperacaoSugeridaWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoSugeridaCreateOrConnectWithoutOperacaoInput>;
    create?: InputMaybe<OperacaoSugeridaCreateWithoutOperacaoInput>;
};

export type OperacaoSugeridaCreateOrConnectWithoutOperacaoInput = {
    create: OperacaoSugeridaCreateWithoutOperacaoInput;
    where: OperacaoSugeridaWhereUniqueInput;
};

export type OperacaoSugeridaCreateWithoutOperacaoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoSugeridaGroupBy = {
    __typename?: "OperacaoSugeridaGroupBy";
    _avg?: Maybe<OperacaoSugeridaAvgAggregate>;
    _count?: Maybe<OperacaoSugeridaCountAggregate>;
    _max?: Maybe<OperacaoSugeridaMaxAggregate>;
    _min?: Maybe<OperacaoSugeridaMinAggregate>;
    _sum?: Maybe<OperacaoSugeridaSumAggregate>;
    createdAt: Scalars["DateTime"];
    operacaoId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type OperacaoSugeridaMaxAggregate = {
    __typename?: "OperacaoSugeridaMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperacaoSugeridaMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoSugeridaMinAggregate = {
    __typename?: "OperacaoSugeridaMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperacaoSugeridaMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoSugeridaOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperacaoSugeridaAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperacaoSugeridaCountOrderByAggregateInput>;
    _max?: InputMaybe<OperacaoSugeridaMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperacaoSugeridaMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperacaoSugeridaSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoSugeridaOrderByWithRelationInput = {
    createdAt?: InputMaybe<SortOrder>;
    operacao?: InputMaybe<OperacaoOrderByWithRelationInput>;
    operacaoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoSugeridaRelationFilter = {
    is?: InputMaybe<OperacaoSugeridaWhereInput>;
    isNot?: InputMaybe<OperacaoSugeridaWhereInput>;
};

export enum OperacaoSugeridaScalarFieldEnum {
    CreatedAt = "createdAt",
    OperacaoId = "operacaoId",
    UpdatedAt = "updatedAt",
}

export type OperacaoSugeridaScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperacaoSugeridaScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperacaoSugeridaScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperacaoSugeridaScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    operacaoId?: InputMaybe<IntWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type OperacaoSugeridaSumAggregate = {
    __typename?: "OperacaoSugeridaSumAggregate";
    operacaoId?: Maybe<Scalars["Int"]>;
};

export type OperacaoSugeridaSumOrderByAggregateInput = {
    operacaoId?: InputMaybe<SortOrder>;
};

export type OperacaoSugeridaUpdateInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutSugeridaNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoSugeridaUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput = {
    connect?: InputMaybe<OperacaoSugeridaWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoSugeridaCreateOrConnectWithoutOperacaoInput>;
    create?: InputMaybe<OperacaoSugeridaCreateWithoutOperacaoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<OperacaoSugeridaUpdateWithoutOperacaoInput>;
    upsert?: InputMaybe<OperacaoSugeridaUpsertWithoutOperacaoInput>;
};

export type OperacaoSugeridaUpdateWithoutOperacaoInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoSugeridaUpsertWithoutOperacaoInput = {
    create: OperacaoSugeridaCreateWithoutOperacaoInput;
    update: OperacaoSugeridaUpdateWithoutOperacaoInput;
};

export type OperacaoSugeridaWhereInput = {
    AND?: InputMaybe<Array<OperacaoSugeridaWhereInput>>;
    NOT?: InputMaybe<Array<OperacaoSugeridaWhereInput>>;
    OR?: InputMaybe<Array<OperacaoSugeridaWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    operacao?: InputMaybe<OperacaoRelationFilter>;
    operacaoId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OperacaoSugeridaWhereUniqueInput = {
    operacaoId?: InputMaybe<Scalars["Int"]>;
};

export type OperacaoSumAggregate = {
    __typename?: "OperacaoSumAggregate";
    desembolso?: Maybe<Scalars["Decimal"]>;
    id?: Maybe<Scalars["Int"]>;
    iof?: Maybe<Scalars["Decimal"]>;
    keyAccountId?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    modalidadeId?: Maybe<Scalars["Int"]>;
    origemOperacaoId?: Maybe<Scalars["Int"]>;
    repurchasedAmount?: Maybe<Scalars["Decimal"]>;
    salesmanId?: Maybe<Scalars["Int"]>;
    statusId?: Maybe<Scalars["Int"]>;
    taxa?: Maybe<Scalars["Decimal"]>;
    tipoId?: Maybe<Scalars["Int"]>;
};

export type OperacaoSumOrderByAggregateInput = {
    desembolso?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidadeId?: InputMaybe<SortOrder>;
    origemOperacaoId?: InputMaybe<SortOrder>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    salesmanId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    taxa?: InputMaybe<SortOrder>;
    tipoId?: InputMaybe<SortOrder>;
};

export type OperacaoTipo = {
    __typename?: "OperacaoTipo";
    _count?: Maybe<OperacaoTipoCount>;
    abreviacao?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    descricao: Scalars["String"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    operacoes: Array<Operacao>;
    sigla: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type OperacaoTipoOperacoesArgs = {
    cursor?: InputMaybe<OperacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoWhereInput>;
};

export type OperacaoTipoAvgAggregate = {
    __typename?: "OperacaoTipoAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
};

export type OperacaoTipoAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type OperacaoTipoCount = {
    __typename?: "OperacaoTipoCount";
    operacoes: Scalars["Int"];
};

export type OperacaoTipoCountAggregate = {
    __typename?: "OperacaoTipoCountAggregate";
    _all: Scalars["Int"];
    abreviacao: Scalars["Int"];
    createdAt: Scalars["Int"];
    descricao: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    sigla: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type OperacaoTipoCountOrderByAggregateInput = {
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoTipoCreateInput = {
    abreviacao?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao: Scalars["String"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    operacoes?: InputMaybe<OperacaoCreateNestedManyWithoutTipoInput>;
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoTipoCreateManyInput = {
    abreviacao?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoTipoCreateNestedOneWithoutOperacoesInput = {
    connect?: InputMaybe<OperacaoTipoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoTipoCreateOrConnectWithoutOperacoesInput>;
    create?: InputMaybe<OperacaoTipoCreateWithoutOperacoesInput>;
};

export type OperacaoTipoCreateOrConnectWithoutOperacoesInput = {
    create: OperacaoTipoCreateWithoutOperacoesInput;
    where: OperacaoTipoWhereUniqueInput;
};

export type OperacaoTipoCreateWithoutOperacoesInput = {
    abreviacao?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao: Scalars["String"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperacaoTipoGroupBy = {
    __typename?: "OperacaoTipoGroupBy";
    _avg?: Maybe<OperacaoTipoAvgAggregate>;
    _count?: Maybe<OperacaoTipoCountAggregate>;
    _max?: Maybe<OperacaoTipoMaxAggregate>;
    _min?: Maybe<OperacaoTipoMinAggregate>;
    _sum?: Maybe<OperacaoTipoSumAggregate>;
    abreviacao?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    descricao: Scalars["String"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    sigla: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type OperacaoTipoMaxAggregate = {
    __typename?: "OperacaoTipoMaxAggregate";
    abreviacao?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    sigla?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperacaoTipoMaxOrderByAggregateInput = {
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoTipoMinAggregate = {
    __typename?: "OperacaoTipoMinAggregate";
    abreviacao?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    sigla?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperacaoTipoMinOrderByAggregateInput = {
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoTipoOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperacaoTipoAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperacaoTipoCountOrderByAggregateInput>;
    _max?: InputMaybe<OperacaoTipoMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperacaoTipoMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperacaoTipoSumOrderByAggregateInput>;
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoTipoOrderByWithRelationInput = {
    abreviacao?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    operacoes?: InputMaybe<OperacaoOrderByRelationAggregateInput>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperacaoTipoRelationFilter = {
    is?: InputMaybe<OperacaoTipoWhereInput>;
    isNot?: InputMaybe<OperacaoTipoWhereInput>;
};

export enum OperacaoTipoScalarFieldEnum {
    Abreviacao = "abreviacao",
    CreatedAt = "createdAt",
    Descricao = "descricao",
    Id = "id",
    Inativo = "inativo",
    Sigla = "sigla",
    UpdatedAt = "updatedAt",
}

export type OperacaoTipoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperacaoTipoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperacaoTipoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperacaoTipoScalarWhereWithAggregatesInput>>;
    abreviacao?: InputMaybe<StringNullableWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    descricao?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    sigla?: InputMaybe<StringWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type OperacaoTipoSumAggregate = {
    __typename?: "OperacaoTipoSumAggregate";
    id?: Maybe<Scalars["Int"]>;
};

export type OperacaoTipoSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type OperacaoTipoUpdateInput = {
    abreviacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    operacoes?: InputMaybe<OperacaoUpdateManyWithoutTipoNestedInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoTipoUpdateManyMutationInput = {
    abreviacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput = {
    connect?: InputMaybe<OperacaoTipoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoTipoCreateOrConnectWithoutOperacoesInput>;
    create?: InputMaybe<OperacaoTipoCreateWithoutOperacoesInput>;
    update?: InputMaybe<OperacaoTipoUpdateWithoutOperacoesInput>;
    upsert?: InputMaybe<OperacaoTipoUpsertWithoutOperacoesInput>;
};

export type OperacaoTipoUpdateWithoutOperacoesInput = {
    abreviacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<StringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoTipoUpsertWithoutOperacoesInput = {
    create: OperacaoTipoCreateWithoutOperacoesInput;
    update: OperacaoTipoUpdateWithoutOperacoesInput;
};

export type OperacaoTipoWhereInput = {
    AND?: InputMaybe<Array<OperacaoTipoWhereInput>>;
    NOT?: InputMaybe<Array<OperacaoTipoWhereInput>>;
    OR?: InputMaybe<Array<OperacaoTipoWhereInput>>;
    abreviacao?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    descricao?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    operacoes?: InputMaybe<OperacaoListRelationFilter>;
    sigla?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OperacaoTipoWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
    sigla?: InputMaybe<Scalars["String"]>;
};

export type OperacaoUpdateInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateManyWithWhereWithoutKeyAccountInput = {
    data: OperacaoUpdateManyMutationInput;
    where: OperacaoScalarWhereInput;
};

export type OperacaoUpdateManyWithWhereWithoutMinibancoInput = {
    data: OperacaoUpdateManyMutationInput;
    where: OperacaoScalarWhereInput;
};

export type OperacaoUpdateManyWithWhereWithoutModalidadeInput = {
    data: OperacaoUpdateManyMutationInput;
    where: OperacaoScalarWhereInput;
};

export type OperacaoUpdateManyWithWhereWithoutOrigemOperacaoInput = {
    data: OperacaoUpdateManyMutationInput;
    where: OperacaoScalarWhereInput;
};

export type OperacaoUpdateManyWithWhereWithoutSalesmanInput = {
    data: OperacaoUpdateManyMutationInput;
    where: OperacaoScalarWhereInput;
};

export type OperacaoUpdateManyWithWhereWithoutStatusInput = {
    data: OperacaoUpdateManyMutationInput;
    where: OperacaoScalarWhereInput;
};

export type OperacaoUpdateManyWithWhereWithoutTipoInput = {
    data: OperacaoUpdateManyMutationInput;
    where: OperacaoScalarWhereInput;
};

export type OperacaoUpdateManyWithoutKeyAccountNestedInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutKeyAccountInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutKeyAccountInput>>;
    createMany?: InputMaybe<OperacaoCreateManyKeyAccountInputEnvelope>;
    delete?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperacaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    set?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    update?: InputMaybe<Array<OperacaoUpdateWithWhereUniqueWithoutKeyAccountInput>>;
    updateMany?: InputMaybe<Array<OperacaoUpdateManyWithWhereWithoutKeyAccountInput>>;
    upsert?: InputMaybe<Array<OperacaoUpsertWithWhereUniqueWithoutKeyAccountInput>>;
};

export type OperacaoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<OperacaoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperacaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    set?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    update?: InputMaybe<Array<OperacaoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<OperacaoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<OperacaoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type OperacaoUpdateManyWithoutModalidadeNestedInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutModalidadeInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutModalidadeInput>>;
    createMany?: InputMaybe<OperacaoCreateManyModalidadeInputEnvelope>;
    delete?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperacaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    set?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    update?: InputMaybe<Array<OperacaoUpdateWithWhereUniqueWithoutModalidadeInput>>;
    updateMany?: InputMaybe<Array<OperacaoUpdateManyWithWhereWithoutModalidadeInput>>;
    upsert?: InputMaybe<Array<OperacaoUpsertWithWhereUniqueWithoutModalidadeInput>>;
};

export type OperacaoUpdateManyWithoutOrigemOperacaoNestedInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutOrigemOperacaoInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutOrigemOperacaoInput>>;
    createMany?: InputMaybe<OperacaoCreateManyOrigemOperacaoInputEnvelope>;
    delete?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperacaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    set?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    update?: InputMaybe<Array<OperacaoUpdateWithWhereUniqueWithoutOrigemOperacaoInput>>;
    updateMany?: InputMaybe<Array<OperacaoUpdateManyWithWhereWithoutOrigemOperacaoInput>>;
    upsert?: InputMaybe<Array<OperacaoUpsertWithWhereUniqueWithoutOrigemOperacaoInput>>;
};

export type OperacaoUpdateManyWithoutSalesmanNestedInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutSalesmanInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutSalesmanInput>>;
    createMany?: InputMaybe<OperacaoCreateManySalesmanInputEnvelope>;
    delete?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperacaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    set?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    update?: InputMaybe<Array<OperacaoUpdateWithWhereUniqueWithoutSalesmanInput>>;
    updateMany?: InputMaybe<Array<OperacaoUpdateManyWithWhereWithoutSalesmanInput>>;
    upsert?: InputMaybe<Array<OperacaoUpsertWithWhereUniqueWithoutSalesmanInput>>;
};

export type OperacaoUpdateManyWithoutStatusNestedInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutStatusInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutStatusInput>>;
    createMany?: InputMaybe<OperacaoCreateManyStatusInputEnvelope>;
    delete?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperacaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    set?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    update?: InputMaybe<Array<OperacaoUpdateWithWhereUniqueWithoutStatusInput>>;
    updateMany?: InputMaybe<Array<OperacaoUpdateManyWithWhereWithoutStatusInput>>;
    upsert?: InputMaybe<Array<OperacaoUpsertWithWhereUniqueWithoutStatusInput>>;
};

export type OperacaoUpdateManyWithoutTipoNestedInput = {
    connect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperacaoCreateOrConnectWithoutTipoInput>>;
    create?: InputMaybe<Array<OperacaoCreateWithoutTipoInput>>;
    createMany?: InputMaybe<OperacaoCreateManyTipoInputEnvelope>;
    delete?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperacaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    set?: InputMaybe<Array<OperacaoWhereUniqueInput>>;
    update?: InputMaybe<Array<OperacaoUpdateWithWhereUniqueWithoutTipoInput>>;
    updateMany?: InputMaybe<Array<OperacaoUpdateManyWithWhereWithoutTipoInput>>;
    upsert?: InputMaybe<Array<OperacaoUpsertWithWhereUniqueWithoutTipoInput>>;
};

export type OperacaoUpdateOneRequiredWithoutAnexosNestedInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutAnexosInput>;
    create?: InputMaybe<OperacaoCreateWithoutAnexosInput>;
    update?: InputMaybe<OperacaoUpdateWithoutAnexosInput>;
    upsert?: InputMaybe<OperacaoUpsertWithoutAnexosInput>;
};

export type OperacaoUpdateOneRequiredWithoutHistoricoNestedInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<OperacaoCreateWithoutHistoricoInput>;
    update?: InputMaybe<OperacaoUpdateWithoutHistoricoInput>;
    upsert?: InputMaybe<OperacaoUpsertWithoutHistoricoInput>;
};

export type OperacaoUpdateOneRequiredWithoutOperacaoChecklistNestedInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutOperacaoChecklistInput>;
    create?: InputMaybe<OperacaoCreateWithoutOperacaoChecklistInput>;
    update?: InputMaybe<OperacaoUpdateWithoutOperacaoChecklistInput>;
    upsert?: InputMaybe<OperacaoUpsertWithoutOperacaoChecklistInput>;
};

export type OperacaoUpdateOneRequiredWithoutOperacaoIntegracaoNestedInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutOperacaoIntegracaoInput>;
    create?: InputMaybe<OperacaoCreateWithoutOperacaoIntegracaoInput>;
    update?: InputMaybe<OperacaoUpdateWithoutOperacaoIntegracaoInput>;
    upsert?: InputMaybe<OperacaoUpsertWithoutOperacaoIntegracaoInput>;
};

export type OperacaoUpdateOneRequiredWithoutOperationCommisionNestedInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutOperationCommisionInput>;
    create?: InputMaybe<OperacaoCreateWithoutOperationCommisionInput>;
    update?: InputMaybe<OperacaoUpdateWithoutOperationCommisionInput>;
    upsert?: InputMaybe<OperacaoUpsertWithoutOperationCommisionInput>;
};

export type OperacaoUpdateOneRequiredWithoutOperationFeesNestedInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutOperationFeesInput>;
    create?: InputMaybe<OperacaoCreateWithoutOperationFeesInput>;
    update?: InputMaybe<OperacaoUpdateWithoutOperationFeesInput>;
    upsert?: InputMaybe<OperacaoUpsertWithoutOperationFeesInput>;
};

export type OperacaoUpdateOneRequiredWithoutOperationUpdatesNestedInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutOperationUpdatesInput>;
    create?: InputMaybe<OperacaoCreateWithoutOperationUpdatesInput>;
    update?: InputMaybe<OperacaoUpdateWithoutOperationUpdatesInput>;
    upsert?: InputMaybe<OperacaoUpsertWithoutOperationUpdatesInput>;
};

export type OperacaoUpdateOneRequiredWithoutOperationsInfoNestedInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutOperationsInfoInput>;
    create?: InputMaybe<OperacaoCreateWithoutOperationsInfoInput>;
    update?: InputMaybe<OperacaoUpdateWithoutOperationsInfoInput>;
    upsert?: InputMaybe<OperacaoUpsertWithoutOperationsInfoInput>;
};

export type OperacaoUpdateOneRequiredWithoutSugeridaNestedInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutSugeridaInput>;
    create?: InputMaybe<OperacaoCreateWithoutSugeridaInput>;
    update?: InputMaybe<OperacaoUpdateWithoutSugeridaInput>;
    upsert?: InputMaybe<OperacaoUpsertWithoutSugeridaInput>;
};

export type OperacaoUpdateOneWithoutRecebiveisNestedInput = {
    connect?: InputMaybe<OperacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperacaoCreateOrConnectWithoutRecebiveisInput>;
    create?: InputMaybe<OperacaoCreateWithoutRecebiveisInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<OperacaoUpdateWithoutRecebiveisInput>;
    upsert?: InputMaybe<OperacaoUpsertWithoutRecebiveisInput>;
};

export type OperacaoUpdateWithWhereUniqueWithoutKeyAccountInput = {
    data: OperacaoUpdateWithoutKeyAccountInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: OperacaoUpdateWithoutMinibancoInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpdateWithWhereUniqueWithoutModalidadeInput = {
    data: OperacaoUpdateWithoutModalidadeInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpdateWithWhereUniqueWithoutOrigemOperacaoInput = {
    data: OperacaoUpdateWithoutOrigemOperacaoInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpdateWithWhereUniqueWithoutSalesmanInput = {
    data: OperacaoUpdateWithoutSalesmanInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpdateWithWhereUniqueWithoutStatusInput = {
    data: OperacaoUpdateWithoutStatusInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpdateWithWhereUniqueWithoutTipoInput = {
    data: OperacaoUpdateWithoutTipoInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpdateWithoutAnexosInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutHistoricoInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutKeyAccountInput = {
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutMinibancoInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutModalidadeInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutOperacaoChecklistInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutOperacaoIntegracaoInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutOperationCommisionInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutOperationFeesInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutOperationUpdatesInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutOperationsInfoInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutOrigemOperacaoInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutRecebiveisInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutSalesmanInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutStatusInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutSugeridaInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    tipo?: InputMaybe<OperacaoTipoUpdateOneRequiredWithoutOperacoesNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpdateWithoutTipoInput = {
    KeyAccount?: InputMaybe<UsuarioUpdateOneWithoutKeyAccountNestedInput>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoUpdateOneWithoutOperacaoNestedInput>;
    OperationCommision?: InputMaybe<OperationCommisionUpdateOneWithoutOperacaoNestedInput>;
    OperationFees?: InputMaybe<OperationFeesUpdateOneWithoutOperationNestedInput>;
    OperationUpdates?: InputMaybe<OperationUpdatesUpdateManyWithoutOperationNestedInput>;
    OperationsInfo?: InputMaybe<OperationsInfoUpdateOneWithoutOperacaoNestedInput>;
    Salesman?: InputMaybe<UsuarioUpdateOneWithoutSalesmanNestedInput>;
    anexos?: InputMaybe<OperacaoAnexosUpdateManyWithoutOperacaoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    desembolso?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    historico?: InputMaybe<OperacaoAuditUpdateManyWithoutOperacaoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperacoesNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperacoesNestedInput>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistUpdateOneWithoutOperacaoNestedInput>;
    origemOperacao?: InputMaybe<MinibancoUpdateOneWithoutOperacoesOrigemNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutOperacaoNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFieldUpdateOperationsInput>;
    shouldCheckPayable?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperacoesNestedInput>;
    sugerida?: InputMaybe<OperacaoSugeridaUpdateOneWithoutOperacaoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperacaoUpsertWithWhereUniqueWithoutKeyAccountInput = {
    create: OperacaoCreateWithoutKeyAccountInput;
    update: OperacaoUpdateWithoutKeyAccountInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: OperacaoCreateWithoutMinibancoInput;
    update: OperacaoUpdateWithoutMinibancoInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpsertWithWhereUniqueWithoutModalidadeInput = {
    create: OperacaoCreateWithoutModalidadeInput;
    update: OperacaoUpdateWithoutModalidadeInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpsertWithWhereUniqueWithoutOrigemOperacaoInput = {
    create: OperacaoCreateWithoutOrigemOperacaoInput;
    update: OperacaoUpdateWithoutOrigemOperacaoInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpsertWithWhereUniqueWithoutSalesmanInput = {
    create: OperacaoCreateWithoutSalesmanInput;
    update: OperacaoUpdateWithoutSalesmanInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpsertWithWhereUniqueWithoutStatusInput = {
    create: OperacaoCreateWithoutStatusInput;
    update: OperacaoUpdateWithoutStatusInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpsertWithWhereUniqueWithoutTipoInput = {
    create: OperacaoCreateWithoutTipoInput;
    update: OperacaoUpdateWithoutTipoInput;
    where: OperacaoWhereUniqueInput;
};

export type OperacaoUpsertWithoutAnexosInput = {
    create: OperacaoCreateWithoutAnexosInput;
    update: OperacaoUpdateWithoutAnexosInput;
};

export type OperacaoUpsertWithoutHistoricoInput = {
    create: OperacaoCreateWithoutHistoricoInput;
    update: OperacaoUpdateWithoutHistoricoInput;
};

export type OperacaoUpsertWithoutOperacaoChecklistInput = {
    create: OperacaoCreateWithoutOperacaoChecklistInput;
    update: OperacaoUpdateWithoutOperacaoChecklistInput;
};

export type OperacaoUpsertWithoutOperacaoIntegracaoInput = {
    create: OperacaoCreateWithoutOperacaoIntegracaoInput;
    update: OperacaoUpdateWithoutOperacaoIntegracaoInput;
};

export type OperacaoUpsertWithoutOperationCommisionInput = {
    create: OperacaoCreateWithoutOperationCommisionInput;
    update: OperacaoUpdateWithoutOperationCommisionInput;
};

export type OperacaoUpsertWithoutOperationFeesInput = {
    create: OperacaoCreateWithoutOperationFeesInput;
    update: OperacaoUpdateWithoutOperationFeesInput;
};

export type OperacaoUpsertWithoutOperationUpdatesInput = {
    create: OperacaoCreateWithoutOperationUpdatesInput;
    update: OperacaoUpdateWithoutOperationUpdatesInput;
};

export type OperacaoUpsertWithoutOperationsInfoInput = {
    create: OperacaoCreateWithoutOperationsInfoInput;
    update: OperacaoUpdateWithoutOperationsInfoInput;
};

export type OperacaoUpsertWithoutRecebiveisInput = {
    create: OperacaoCreateWithoutRecebiveisInput;
    update: OperacaoUpdateWithoutRecebiveisInput;
};

export type OperacaoUpsertWithoutSugeridaInput = {
    create: OperacaoCreateWithoutSugeridaInput;
    update: OperacaoUpdateWithoutSugeridaInput;
};

export type OperacaoWhereInput = {
    AND?: InputMaybe<Array<OperacaoWhereInput>>;
    KeyAccount?: InputMaybe<UsuarioRelationFilter>;
    NOT?: InputMaybe<Array<OperacaoWhereInput>>;
    OR?: InputMaybe<Array<OperacaoWhereInput>>;
    OperacaoIntegracao?: InputMaybe<OperacaoIntegracaoRelationFilter>;
    OperationCommision?: InputMaybe<OperationCommisionRelationFilter>;
    OperationFees?: InputMaybe<OperationFeesRelationFilter>;
    OperationUpdates?: InputMaybe<OperationUpdatesListRelationFilter>;
    OperationsInfo?: InputMaybe<OperationsInfoRelationFilter>;
    Salesman?: InputMaybe<UsuarioRelationFilter>;
    anexos?: InputMaybe<OperacaoAnexosListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    descricao?: InputMaybe<StringNullableFilter>;
    desembolso?: InputMaybe<DecimalNullableFilter>;
    historico?: InputMaybe<OperacaoAuditListRelationFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    iof?: InputMaybe<DecimalNullableFilter>;
    keyAccountId?: InputMaybe<IntNullableFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    modalidade?: InputMaybe<OperacaoModalidadeRelationFilter>;
    modalidadeId?: InputMaybe<IntFilter>;
    operacaoChecklist?: InputMaybe<OperacaoChecklistRelationFilter>;
    origemOperacao?: InputMaybe<MinibancoRelationFilter>;
    origemOperacaoId?: InputMaybe<IntNullableFilter>;
    recebiveis?: InputMaybe<RecebivelListRelationFilter>;
    repurchasedAmount?: InputMaybe<DecimalNullableFilter>;
    salesmanId?: InputMaybe<IntNullableFilter>;
    shouldAnalyzePayerCredit?: InputMaybe<BoolFilter>;
    shouldCheckPayable?: InputMaybe<BoolFilter>;
    status?: InputMaybe<OperacaoStatusRelationFilter>;
    statusId?: InputMaybe<IntFilter>;
    sugerida?: InputMaybe<OperacaoSugeridaRelationFilter>;
    taxa?: InputMaybe<DecimalFilter>;
    tipo?: InputMaybe<OperacaoTipoRelationFilter>;
    tipoId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OperacaoWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

/** @view */
export type OperacoesRascunhoView = {
    __typename?: "OperacoesRascunhoView";
    createdAt: Scalars["DateTime"];
    form: Scalars["JSON"];
    id: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
    usuarioId: Scalars["Int"];
};

export type OperacoesRascunhoViewAvgAggregate = {
    __typename?: "OperacoesRascunhoViewAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type OperacoesRascunhoViewAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacoesRascunhoViewCountAggregate = {
    __typename?: "OperacoesRascunhoViewCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    form: Scalars["Int"];
    id: Scalars["Int"];
    updatedAt: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type OperacoesRascunhoViewCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    form?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacoesRascunhoViewCreateInput = {
    createdAt: Scalars["DateTime"];
    form: Scalars["JSON"];
    id: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
    usuarioId: Scalars["Int"];
};

export type OperacoesRascunhoViewCreateManyInput = {
    createdAt: Scalars["DateTime"];
    form: Scalars["JSON"];
    id: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
    usuarioId: Scalars["Int"];
};

export type OperacoesRascunhoViewGroupBy = {
    __typename?: "OperacoesRascunhoViewGroupBy";
    _avg?: Maybe<OperacoesRascunhoViewAvgAggregate>;
    _count?: Maybe<OperacoesRascunhoViewCountAggregate>;
    _max?: Maybe<OperacoesRascunhoViewMaxAggregate>;
    _min?: Maybe<OperacoesRascunhoViewMinAggregate>;
    _sum?: Maybe<OperacoesRascunhoViewSumAggregate>;
    createdAt: Scalars["DateTime"];
    form: Scalars["JSON"];
    id: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
    usuarioId: Scalars["Int"];
};

export type OperacoesRascunhoViewMaxAggregate = {
    __typename?: "OperacoesRascunhoViewMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type OperacoesRascunhoViewMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacoesRascunhoViewMinAggregate = {
    __typename?: "OperacoesRascunhoViewMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type OperacoesRascunhoViewMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacoesRascunhoViewOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperacoesRascunhoViewAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperacoesRascunhoViewCountOrderByAggregateInput>;
    _max?: InputMaybe<OperacoesRascunhoViewMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperacoesRascunhoViewMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperacoesRascunhoViewSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    form?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacoesRascunhoViewOrderByWithRelationInput = {
    createdAt?: InputMaybe<SortOrder>;
    form?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum OperacoesRascunhoViewScalarFieldEnum {
    CreatedAt = "createdAt",
    Form = "form",
    Id = "id",
    UpdatedAt = "updatedAt",
    UsuarioId = "usuarioId",
}

export type OperacoesRascunhoViewScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperacoesRascunhoViewScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperacoesRascunhoViewScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperacoesRascunhoViewScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    form?: InputMaybe<JsonWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntWithAggregatesFilter>;
};

export type OperacoesRascunhoViewSumAggregate = {
    __typename?: "OperacoesRascunhoViewSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type OperacoesRascunhoViewSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type OperacoesRascunhoViewUpdateInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    form?: InputMaybe<Scalars["JSON"]>;
    id?: InputMaybe<IntFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarioId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type OperacoesRascunhoViewUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    form?: InputMaybe<Scalars["JSON"]>;
    id?: InputMaybe<IntFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuarioId?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type OperacoesRascunhoViewWhereInput = {
    AND?: InputMaybe<Array<OperacoesRascunhoViewWhereInput>>;
    NOT?: InputMaybe<Array<OperacoesRascunhoViewWhereInput>>;
    OR?: InputMaybe<Array<OperacoesRascunhoViewWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    form?: InputMaybe<JsonFilter>;
    id?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuarioId?: InputMaybe<IntFilter>;
};

export type OperacoesRascunhoViewWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type OperationCommision = {
    __typename?: "OperationCommision";
    commission: Scalars["Decimal"];
    createdAt: Scalars["DateTime"];
    occupation: Occupation;
    occupationId: Scalars["Int"];
    operacao: Operacao;
    operacaoId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type OperationCommisionAvgAggregate = {
    __typename?: "OperationCommisionAvgAggregate";
    commission?: Maybe<Scalars["Decimal"]>;
    occupationId?: Maybe<Scalars["Float"]>;
    operacaoId?: Maybe<Scalars["Float"]>;
};

export type OperationCommisionAvgOrderByAggregateInput = {
    commission?: InputMaybe<SortOrder>;
    occupationId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
};

export type OperationCommisionCountAggregate = {
    __typename?: "OperationCommisionCountAggregate";
    _all: Scalars["Int"];
    commission: Scalars["Int"];
    createdAt: Scalars["Int"];
    occupationId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type OperationCommisionCountOrderByAggregateInput = {
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    occupationId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperationCommisionCreateInput = {
    commission: Scalars["Decimal"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    occupation: OccupationCreateNestedOneWithoutOperationCommisionInput;
    operacao: OperacaoCreateNestedOneWithoutOperationCommisionInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperationCommisionCreateManyInput = {
    commission: Scalars["Decimal"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    occupationId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperationCommisionCreateNestedOneWithoutOccupationInput = {
    connect?: InputMaybe<OperationCommisionWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperationCommisionCreateOrConnectWithoutOccupationInput>;
    create?: InputMaybe<OperationCommisionCreateWithoutOccupationInput>;
};

export type OperationCommisionCreateNestedOneWithoutOperacaoInput = {
    connect?: InputMaybe<OperationCommisionWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperationCommisionCreateOrConnectWithoutOperacaoInput>;
    create?: InputMaybe<OperationCommisionCreateWithoutOperacaoInput>;
};

export type OperationCommisionCreateOrConnectWithoutOccupationInput = {
    create: OperationCommisionCreateWithoutOccupationInput;
    where: OperationCommisionWhereUniqueInput;
};

export type OperationCommisionCreateOrConnectWithoutOperacaoInput = {
    create: OperationCommisionCreateWithoutOperacaoInput;
    where: OperationCommisionWhereUniqueInput;
};

export type OperationCommisionCreateWithoutOccupationInput = {
    commission: Scalars["Decimal"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    operacao: OperacaoCreateNestedOneWithoutOperationCommisionInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperationCommisionCreateWithoutOperacaoInput = {
    commission: Scalars["Decimal"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    occupation: OccupationCreateNestedOneWithoutOperationCommisionInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperationCommisionGroupBy = {
    __typename?: "OperationCommisionGroupBy";
    _avg?: Maybe<OperationCommisionAvgAggregate>;
    _count?: Maybe<OperationCommisionCountAggregate>;
    _max?: Maybe<OperationCommisionMaxAggregate>;
    _min?: Maybe<OperationCommisionMinAggregate>;
    _sum?: Maybe<OperationCommisionSumAggregate>;
    commission: Scalars["Decimal"];
    createdAt: Scalars["DateTime"];
    occupationId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type OperationCommisionMaxAggregate = {
    __typename?: "OperationCommisionMaxAggregate";
    commission?: Maybe<Scalars["Decimal"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    occupationId?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperationCommisionMaxOrderByAggregateInput = {
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    occupationId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperationCommisionMinAggregate = {
    __typename?: "OperationCommisionMinAggregate";
    commission?: Maybe<Scalars["Decimal"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    occupationId?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperationCommisionMinOrderByAggregateInput = {
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    occupationId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperationCommisionOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperationCommisionAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperationCommisionCountOrderByAggregateInput>;
    _max?: InputMaybe<OperationCommisionMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperationCommisionMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperationCommisionSumOrderByAggregateInput>;
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    occupationId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperationCommisionOrderByWithRelationInput = {
    commission?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    occupation?: InputMaybe<OccupationOrderByWithRelationInput>;
    occupationId?: InputMaybe<SortOrder>;
    operacao?: InputMaybe<OperacaoOrderByWithRelationInput>;
    operacaoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperationCommisionRelationFilter = {
    is?: InputMaybe<OperationCommisionWhereInput>;
    isNot?: InputMaybe<OperationCommisionWhereInput>;
};

export enum OperationCommisionScalarFieldEnum {
    Commission = "commission",
    CreatedAt = "createdAt",
    OccupationId = "occupationId",
    OperacaoId = "operacaoId",
    UpdatedAt = "updatedAt",
}

export type OperationCommisionScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperationCommisionScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperationCommisionScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperationCommisionScalarWhereWithAggregatesInput>>;
    commission?: InputMaybe<DecimalWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    occupationId?: InputMaybe<IntWithAggregatesFilter>;
    operacaoId?: InputMaybe<IntWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type OperationCommisionSumAggregate = {
    __typename?: "OperationCommisionSumAggregate";
    commission?: Maybe<Scalars["Decimal"]>;
    occupationId?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
};

export type OperationCommisionSumOrderByAggregateInput = {
    commission?: InputMaybe<SortOrder>;
    occupationId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
};

export type OperationCommisionUpdateInput = {
    commission?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    occupation?: InputMaybe<OccupationUpdateOneRequiredWithoutOperationCommisionNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperationCommisionNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationCommisionUpdateManyMutationInput = {
    commission?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationCommisionUpdateOneWithoutOccupationNestedInput = {
    connect?: InputMaybe<OperationCommisionWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperationCommisionCreateOrConnectWithoutOccupationInput>;
    create?: InputMaybe<OperationCommisionCreateWithoutOccupationInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<OperationCommisionUpdateWithoutOccupationInput>;
    upsert?: InputMaybe<OperationCommisionUpsertWithoutOccupationInput>;
};

export type OperationCommisionUpdateOneWithoutOperacaoNestedInput = {
    connect?: InputMaybe<OperationCommisionWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperationCommisionCreateOrConnectWithoutOperacaoInput>;
    create?: InputMaybe<OperationCommisionCreateWithoutOperacaoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<OperationCommisionUpdateWithoutOperacaoInput>;
    upsert?: InputMaybe<OperationCommisionUpsertWithoutOperacaoInput>;
};

export type OperationCommisionUpdateWithoutOccupationInput = {
    commission?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperationCommisionNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationCommisionUpdateWithoutOperacaoInput = {
    commission?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    occupation?: InputMaybe<OccupationUpdateOneRequiredWithoutOperationCommisionNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationCommisionUpsertWithoutOccupationInput = {
    create: OperationCommisionCreateWithoutOccupationInput;
    update: OperationCommisionUpdateWithoutOccupationInput;
};

export type OperationCommisionUpsertWithoutOperacaoInput = {
    create: OperationCommisionCreateWithoutOperacaoInput;
    update: OperationCommisionUpdateWithoutOperacaoInput;
};

export type OperationCommisionWhereInput = {
    AND?: InputMaybe<Array<OperationCommisionWhereInput>>;
    NOT?: InputMaybe<Array<OperationCommisionWhereInput>>;
    OR?: InputMaybe<Array<OperationCommisionWhereInput>>;
    commission?: InputMaybe<DecimalFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    occupation?: InputMaybe<OccupationRelationFilter>;
    occupationId?: InputMaybe<IntFilter>;
    operacao?: InputMaybe<OperacaoRelationFilter>;
    operacaoId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OperationCommisionWhereUniqueInput = {
    occupationId?: InputMaybe<Scalars["Int"]>;
    operacaoId?: InputMaybe<Scalars["Int"]>;
};

export type OperationFees = {
    __typename?: "OperationFees";
    checkCustody?: Maybe<Scalars["Decimal"]>;
    creditAnalysis: Scalars["Decimal"];
    digitalSignatureCost?: Maybe<Scalars["Decimal"]>;
    invoices: Scalars["Decimal"];
    operation: Operacao;
    operationId: Scalars["Int"];
    pix?: Maybe<Scalars["Decimal"]>;
    tac: Scalars["Decimal"];
    ted: Scalars["Decimal"];
};

export type OperationFeesAvgAggregate = {
    __typename?: "OperationFeesAvgAggregate";
    checkCustody?: Maybe<Scalars["Decimal"]>;
    creditAnalysis?: Maybe<Scalars["Decimal"]>;
    digitalSignatureCost?: Maybe<Scalars["Decimal"]>;
    invoices?: Maybe<Scalars["Decimal"]>;
    operationId?: Maybe<Scalars["Float"]>;
    pix?: Maybe<Scalars["Decimal"]>;
    tac?: Maybe<Scalars["Decimal"]>;
    ted?: Maybe<Scalars["Decimal"]>;
};

export type OperationFeesAvgOrderByAggregateInput = {
    checkCustody?: InputMaybe<SortOrder>;
    creditAnalysis?: InputMaybe<SortOrder>;
    digitalSignatureCost?: InputMaybe<SortOrder>;
    invoices?: InputMaybe<SortOrder>;
    operationId?: InputMaybe<SortOrder>;
    pix?: InputMaybe<SortOrder>;
    tac?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export type OperationFeesCountAggregate = {
    __typename?: "OperationFeesCountAggregate";
    _all: Scalars["Int"];
    checkCustody: Scalars["Int"];
    creditAnalysis: Scalars["Int"];
    digitalSignatureCost: Scalars["Int"];
    invoices: Scalars["Int"];
    operationId: Scalars["Int"];
    pix: Scalars["Int"];
    tac: Scalars["Int"];
    ted: Scalars["Int"];
};

export type OperationFeesCountOrderByAggregateInput = {
    checkCustody?: InputMaybe<SortOrder>;
    creditAnalysis?: InputMaybe<SortOrder>;
    digitalSignatureCost?: InputMaybe<SortOrder>;
    invoices?: InputMaybe<SortOrder>;
    operationId?: InputMaybe<SortOrder>;
    pix?: InputMaybe<SortOrder>;
    tac?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export type OperationFeesCreateInput = {
    checkCustody?: InputMaybe<Scalars["Decimal"]>;
    creditAnalysis: Scalars["Decimal"];
    digitalSignatureCost?: InputMaybe<Scalars["Decimal"]>;
    invoices: Scalars["Decimal"];
    operation: OperacaoCreateNestedOneWithoutOperationFeesInput;
    pix?: InputMaybe<Scalars["Decimal"]>;
    tac: Scalars["Decimal"];
    ted: Scalars["Decimal"];
};

export type OperationFeesCreateManyInput = {
    checkCustody?: InputMaybe<Scalars["Decimal"]>;
    creditAnalysis: Scalars["Decimal"];
    digitalSignatureCost?: InputMaybe<Scalars["Decimal"]>;
    invoices: Scalars["Decimal"];
    operationId: Scalars["Int"];
    pix?: InputMaybe<Scalars["Decimal"]>;
    tac: Scalars["Decimal"];
    ted: Scalars["Decimal"];
};

export type OperationFeesCreateNestedOneWithoutOperationInput = {
    connect?: InputMaybe<OperationFeesWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperationFeesCreateOrConnectWithoutOperationInput>;
    create?: InputMaybe<OperationFeesCreateWithoutOperationInput>;
};

export type OperationFeesCreateOrConnectWithoutOperationInput = {
    create: OperationFeesCreateWithoutOperationInput;
    where: OperationFeesWhereUniqueInput;
};

export type OperationFeesCreateWithoutOperationInput = {
    checkCustody?: InputMaybe<Scalars["Decimal"]>;
    creditAnalysis: Scalars["Decimal"];
    digitalSignatureCost?: InputMaybe<Scalars["Decimal"]>;
    invoices: Scalars["Decimal"];
    pix?: InputMaybe<Scalars["Decimal"]>;
    tac: Scalars["Decimal"];
    ted: Scalars["Decimal"];
};

export type OperationFeesGroupBy = {
    __typename?: "OperationFeesGroupBy";
    _avg?: Maybe<OperationFeesAvgAggregate>;
    _count?: Maybe<OperationFeesCountAggregate>;
    _max?: Maybe<OperationFeesMaxAggregate>;
    _min?: Maybe<OperationFeesMinAggregate>;
    _sum?: Maybe<OperationFeesSumAggregate>;
    checkCustody?: Maybe<Scalars["Decimal"]>;
    creditAnalysis: Scalars["Decimal"];
    digitalSignatureCost?: Maybe<Scalars["Decimal"]>;
    invoices: Scalars["Decimal"];
    operationId: Scalars["Int"];
    pix?: Maybe<Scalars["Decimal"]>;
    tac: Scalars["Decimal"];
    ted: Scalars["Decimal"];
};

export type OperationFeesMaxAggregate = {
    __typename?: "OperationFeesMaxAggregate";
    checkCustody?: Maybe<Scalars["Decimal"]>;
    creditAnalysis?: Maybe<Scalars["Decimal"]>;
    digitalSignatureCost?: Maybe<Scalars["Decimal"]>;
    invoices?: Maybe<Scalars["Decimal"]>;
    operationId?: Maybe<Scalars["Int"]>;
    pix?: Maybe<Scalars["Decimal"]>;
    tac?: Maybe<Scalars["Decimal"]>;
    ted?: Maybe<Scalars["Decimal"]>;
};

export type OperationFeesMaxOrderByAggregateInput = {
    checkCustody?: InputMaybe<SortOrder>;
    creditAnalysis?: InputMaybe<SortOrder>;
    digitalSignatureCost?: InputMaybe<SortOrder>;
    invoices?: InputMaybe<SortOrder>;
    operationId?: InputMaybe<SortOrder>;
    pix?: InputMaybe<SortOrder>;
    tac?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export type OperationFeesMinAggregate = {
    __typename?: "OperationFeesMinAggregate";
    checkCustody?: Maybe<Scalars["Decimal"]>;
    creditAnalysis?: Maybe<Scalars["Decimal"]>;
    digitalSignatureCost?: Maybe<Scalars["Decimal"]>;
    invoices?: Maybe<Scalars["Decimal"]>;
    operationId?: Maybe<Scalars["Int"]>;
    pix?: Maybe<Scalars["Decimal"]>;
    tac?: Maybe<Scalars["Decimal"]>;
    ted?: Maybe<Scalars["Decimal"]>;
};

export type OperationFeesMinOrderByAggregateInput = {
    checkCustody?: InputMaybe<SortOrder>;
    creditAnalysis?: InputMaybe<SortOrder>;
    digitalSignatureCost?: InputMaybe<SortOrder>;
    invoices?: InputMaybe<SortOrder>;
    operationId?: InputMaybe<SortOrder>;
    pix?: InputMaybe<SortOrder>;
    tac?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export type OperationFeesOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperationFeesAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperationFeesCountOrderByAggregateInput>;
    _max?: InputMaybe<OperationFeesMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperationFeesMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperationFeesSumOrderByAggregateInput>;
    checkCustody?: InputMaybe<SortOrder>;
    creditAnalysis?: InputMaybe<SortOrder>;
    digitalSignatureCost?: InputMaybe<SortOrder>;
    invoices?: InputMaybe<SortOrder>;
    operationId?: InputMaybe<SortOrder>;
    pix?: InputMaybe<SortOrder>;
    tac?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export type OperationFeesOrderByWithRelationInput = {
    checkCustody?: InputMaybe<SortOrder>;
    creditAnalysis?: InputMaybe<SortOrder>;
    digitalSignatureCost?: InputMaybe<SortOrder>;
    invoices?: InputMaybe<SortOrder>;
    operation?: InputMaybe<OperacaoOrderByWithRelationInput>;
    operationId?: InputMaybe<SortOrder>;
    pix?: InputMaybe<SortOrder>;
    tac?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export type OperationFeesRelationFilter = {
    is?: InputMaybe<OperationFeesWhereInput>;
    isNot?: InputMaybe<OperationFeesWhereInput>;
};

export enum OperationFeesScalarFieldEnum {
    CheckCustody = "checkCustody",
    CreditAnalysis = "creditAnalysis",
    DigitalSignatureCost = "digitalSignatureCost",
    Invoices = "invoices",
    OperationId = "operationId",
    Pix = "pix",
    Tac = "tac",
    Ted = "ted",
}

export type OperationFeesScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperationFeesScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperationFeesScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperationFeesScalarWhereWithAggregatesInput>>;
    checkCustody?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    creditAnalysis?: InputMaybe<DecimalWithAggregatesFilter>;
    digitalSignatureCost?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    invoices?: InputMaybe<DecimalWithAggregatesFilter>;
    operationId?: InputMaybe<IntWithAggregatesFilter>;
    pix?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    tac?: InputMaybe<DecimalWithAggregatesFilter>;
    ted?: InputMaybe<DecimalWithAggregatesFilter>;
};

export type OperationFeesSumAggregate = {
    __typename?: "OperationFeesSumAggregate";
    checkCustody?: Maybe<Scalars["Decimal"]>;
    creditAnalysis?: Maybe<Scalars["Decimal"]>;
    digitalSignatureCost?: Maybe<Scalars["Decimal"]>;
    invoices?: Maybe<Scalars["Decimal"]>;
    operationId?: Maybe<Scalars["Int"]>;
    pix?: Maybe<Scalars["Decimal"]>;
    tac?: Maybe<Scalars["Decimal"]>;
    ted?: Maybe<Scalars["Decimal"]>;
};

export type OperationFeesSumOrderByAggregateInput = {
    checkCustody?: InputMaybe<SortOrder>;
    creditAnalysis?: InputMaybe<SortOrder>;
    digitalSignatureCost?: InputMaybe<SortOrder>;
    invoices?: InputMaybe<SortOrder>;
    operationId?: InputMaybe<SortOrder>;
    pix?: InputMaybe<SortOrder>;
    tac?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export type OperationFeesUpdateInput = {
    checkCustody?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    creditAnalysis?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    digitalSignatureCost?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    invoices?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    operation?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperationFeesNestedInput>;
    pix?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    tac?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    ted?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type OperationFeesUpdateManyMutationInput = {
    checkCustody?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    creditAnalysis?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    digitalSignatureCost?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    invoices?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    pix?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    tac?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    ted?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type OperationFeesUpdateOneWithoutOperationNestedInput = {
    connect?: InputMaybe<OperationFeesWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperationFeesCreateOrConnectWithoutOperationInput>;
    create?: InputMaybe<OperationFeesCreateWithoutOperationInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<OperationFeesUpdateWithoutOperationInput>;
    upsert?: InputMaybe<OperationFeesUpsertWithoutOperationInput>;
};

export type OperationFeesUpdateWithoutOperationInput = {
    checkCustody?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    creditAnalysis?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    digitalSignatureCost?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    invoices?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    pix?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    tac?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    ted?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type OperationFeesUpsertWithoutOperationInput = {
    create: OperationFeesCreateWithoutOperationInput;
    update: OperationFeesUpdateWithoutOperationInput;
};

export type OperationFeesWhereInput = {
    AND?: InputMaybe<Array<OperationFeesWhereInput>>;
    NOT?: InputMaybe<Array<OperationFeesWhereInput>>;
    OR?: InputMaybe<Array<OperationFeesWhereInput>>;
    checkCustody?: InputMaybe<DecimalNullableFilter>;
    creditAnalysis?: InputMaybe<DecimalFilter>;
    digitalSignatureCost?: InputMaybe<DecimalNullableFilter>;
    invoices?: InputMaybe<DecimalFilter>;
    operation?: InputMaybe<OperacaoRelationFilter>;
    operationId?: InputMaybe<IntFilter>;
    pix?: InputMaybe<DecimalNullableFilter>;
    tac?: InputMaybe<DecimalFilter>;
    ted?: InputMaybe<DecimalFilter>;
};

export type OperationFeesWhereUniqueInput = {
    operationId?: InputMaybe<Scalars["Int"]>;
};

export type OperationUpdates = {
    __typename?: "OperationUpdates";
    createdAt: Scalars["DateTime"];
    disbursement?: Maybe<Scalars["Decimal"]>;
    event: Event;
    eventId: Scalars["Int"];
    foreignKey: Scalars["Int"];
    id: Scalars["Int"];
    iof?: Maybe<Scalars["Decimal"]>;
    isActive: Scalars["Boolean"];
    operation: Operacao;
    operationId: Scalars["Int"];
    repurchasedAmount?: Maybe<Scalars["Decimal"]>;
    tax: Scalars["Decimal"];
    updatedAt: Scalars["DateTime"];
};

export type OperationUpdatesAvgAggregate = {
    __typename?: "OperationUpdatesAvgAggregate";
    disbursement?: Maybe<Scalars["Decimal"]>;
    eventId?: Maybe<Scalars["Float"]>;
    foreignKey?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    iof?: Maybe<Scalars["Decimal"]>;
    operationId?: Maybe<Scalars["Float"]>;
    repurchasedAmount?: Maybe<Scalars["Decimal"]>;
    tax?: Maybe<Scalars["Decimal"]>;
};

export type OperationUpdatesAvgOrderByAggregateInput = {
    disbursement?: InputMaybe<SortOrder>;
    eventId?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    operationId?: InputMaybe<SortOrder>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    tax?: InputMaybe<SortOrder>;
};

export type OperationUpdatesCountAggregate = {
    __typename?: "OperationUpdatesCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    disbursement: Scalars["Int"];
    eventId: Scalars["Int"];
    foreignKey: Scalars["Int"];
    id: Scalars["Int"];
    iof: Scalars["Int"];
    isActive: Scalars["Int"];
    operationId: Scalars["Int"];
    repurchasedAmount: Scalars["Int"];
    tax: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type OperationUpdatesCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    disbursement?: InputMaybe<SortOrder>;
    eventId?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    isActive?: InputMaybe<SortOrder>;
    operationId?: InputMaybe<SortOrder>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    tax?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperationUpdatesCreateInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    disbursement?: InputMaybe<Scalars["Decimal"]>;
    event: EventCreateNestedOneWithoutOperationUpdatesInput;
    foreignKey: Scalars["Int"];
    iof?: InputMaybe<Scalars["Decimal"]>;
    isActive?: InputMaybe<Scalars["Boolean"]>;
    operation: OperacaoCreateNestedOneWithoutOperationUpdatesInput;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    tax: Scalars["Decimal"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperationUpdatesCreateManyEventInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    disbursement?: InputMaybe<Scalars["Decimal"]>;
    foreignKey: Scalars["Int"];
    id?: InputMaybe<Scalars["Int"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    isActive?: InputMaybe<Scalars["Boolean"]>;
    operationId: Scalars["Int"];
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    tax: Scalars["Decimal"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperationUpdatesCreateManyEventInputEnvelope = {
    data: Array<OperationUpdatesCreateManyEventInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperationUpdatesCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    disbursement?: InputMaybe<Scalars["Decimal"]>;
    eventId: Scalars["Int"];
    foreignKey: Scalars["Int"];
    id?: InputMaybe<Scalars["Int"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    isActive?: InputMaybe<Scalars["Boolean"]>;
    operationId: Scalars["Int"];
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    tax: Scalars["Decimal"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperationUpdatesCreateManyOperationInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    disbursement?: InputMaybe<Scalars["Decimal"]>;
    eventId: Scalars["Int"];
    foreignKey: Scalars["Int"];
    id?: InputMaybe<Scalars["Int"]>;
    iof?: InputMaybe<Scalars["Decimal"]>;
    isActive?: InputMaybe<Scalars["Boolean"]>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    tax: Scalars["Decimal"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperationUpdatesCreateManyOperationInputEnvelope = {
    data: Array<OperationUpdatesCreateManyOperationInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperationUpdatesCreateNestedManyWithoutEventInput = {
    connect?: InputMaybe<Array<OperationUpdatesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationUpdatesCreateOrConnectWithoutEventInput>>;
    create?: InputMaybe<Array<OperationUpdatesCreateWithoutEventInput>>;
    createMany?: InputMaybe<OperationUpdatesCreateManyEventInputEnvelope>;
};

export type OperationUpdatesCreateNestedManyWithoutOperationInput = {
    connect?: InputMaybe<Array<OperationUpdatesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationUpdatesCreateOrConnectWithoutOperationInput>>;
    create?: InputMaybe<Array<OperationUpdatesCreateWithoutOperationInput>>;
    createMany?: InputMaybe<OperationUpdatesCreateManyOperationInputEnvelope>;
};

export type OperationUpdatesCreateOrConnectWithoutEventInput = {
    create: OperationUpdatesCreateWithoutEventInput;
    where: OperationUpdatesWhereUniqueInput;
};

export type OperationUpdatesCreateOrConnectWithoutOperationInput = {
    create: OperationUpdatesCreateWithoutOperationInput;
    where: OperationUpdatesWhereUniqueInput;
};

export type OperationUpdatesCreateWithoutEventInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    disbursement?: InputMaybe<Scalars["Decimal"]>;
    foreignKey: Scalars["Int"];
    iof?: InputMaybe<Scalars["Decimal"]>;
    isActive?: InputMaybe<Scalars["Boolean"]>;
    operation: OperacaoCreateNestedOneWithoutOperationUpdatesInput;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    tax: Scalars["Decimal"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperationUpdatesCreateWithoutOperationInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    disbursement?: InputMaybe<Scalars["Decimal"]>;
    event: EventCreateNestedOneWithoutOperationUpdatesInput;
    foreignKey: Scalars["Int"];
    iof?: InputMaybe<Scalars["Decimal"]>;
    isActive?: InputMaybe<Scalars["Boolean"]>;
    repurchasedAmount?: InputMaybe<Scalars["Decimal"]>;
    tax: Scalars["Decimal"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type OperationUpdatesGroupBy = {
    __typename?: "OperationUpdatesGroupBy";
    _avg?: Maybe<OperationUpdatesAvgAggregate>;
    _count?: Maybe<OperationUpdatesCountAggregate>;
    _max?: Maybe<OperationUpdatesMaxAggregate>;
    _min?: Maybe<OperationUpdatesMinAggregate>;
    _sum?: Maybe<OperationUpdatesSumAggregate>;
    createdAt: Scalars["DateTime"];
    disbursement?: Maybe<Scalars["Decimal"]>;
    eventId: Scalars["Int"];
    foreignKey: Scalars["Int"];
    id: Scalars["Int"];
    iof?: Maybe<Scalars["Decimal"]>;
    isActive: Scalars["Boolean"];
    operationId: Scalars["Int"];
    repurchasedAmount?: Maybe<Scalars["Decimal"]>;
    tax: Scalars["Decimal"];
    updatedAt: Scalars["DateTime"];
};

export type OperationUpdatesListRelationFilter = {
    every?: InputMaybe<OperationUpdatesWhereInput>;
    none?: InputMaybe<OperationUpdatesWhereInput>;
    some?: InputMaybe<OperationUpdatesWhereInput>;
};

export type OperationUpdatesMaxAggregate = {
    __typename?: "OperationUpdatesMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    disbursement?: Maybe<Scalars["Decimal"]>;
    eventId?: Maybe<Scalars["Int"]>;
    foreignKey?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    iof?: Maybe<Scalars["Decimal"]>;
    isActive?: Maybe<Scalars["Boolean"]>;
    operationId?: Maybe<Scalars["Int"]>;
    repurchasedAmount?: Maybe<Scalars["Decimal"]>;
    tax?: Maybe<Scalars["Decimal"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperationUpdatesMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    disbursement?: InputMaybe<SortOrder>;
    eventId?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    isActive?: InputMaybe<SortOrder>;
    operationId?: InputMaybe<SortOrder>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    tax?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperationUpdatesMinAggregate = {
    __typename?: "OperationUpdatesMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    disbursement?: Maybe<Scalars["Decimal"]>;
    eventId?: Maybe<Scalars["Int"]>;
    foreignKey?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    iof?: Maybe<Scalars["Decimal"]>;
    isActive?: Maybe<Scalars["Boolean"]>;
    operationId?: Maybe<Scalars["Int"]>;
    repurchasedAmount?: Maybe<Scalars["Decimal"]>;
    tax?: Maybe<Scalars["Decimal"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OperationUpdatesMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    disbursement?: InputMaybe<SortOrder>;
    eventId?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    isActive?: InputMaybe<SortOrder>;
    operationId?: InputMaybe<SortOrder>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    tax?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperationUpdatesOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type OperationUpdatesOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperationUpdatesAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperationUpdatesCountOrderByAggregateInput>;
    _max?: InputMaybe<OperationUpdatesMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperationUpdatesMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperationUpdatesSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    disbursement?: InputMaybe<SortOrder>;
    eventId?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    isActive?: InputMaybe<SortOrder>;
    operationId?: InputMaybe<SortOrder>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    tax?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type OperationUpdatesOrderByWithRelationInput = {
    createdAt?: InputMaybe<SortOrder>;
    disbursement?: InputMaybe<SortOrder>;
    event?: InputMaybe<EventOrderByWithRelationInput>;
    eventId?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    isActive?: InputMaybe<SortOrder>;
    operation?: InputMaybe<OperacaoOrderByWithRelationInput>;
    operationId?: InputMaybe<SortOrder>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    tax?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export enum OperationUpdatesScalarFieldEnum {
    CreatedAt = "createdAt",
    Disbursement = "disbursement",
    EventId = "eventId",
    ForeignKey = "foreignKey",
    Id = "id",
    Iof = "iof",
    IsActive = "isActive",
    OperationId = "operationId",
    RepurchasedAmount = "repurchasedAmount",
    Tax = "tax",
    UpdatedAt = "updatedAt",
}

export type OperationUpdatesScalarWhereInput = {
    AND?: InputMaybe<Array<OperationUpdatesScalarWhereInput>>;
    NOT?: InputMaybe<Array<OperationUpdatesScalarWhereInput>>;
    OR?: InputMaybe<Array<OperationUpdatesScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    disbursement?: InputMaybe<DecimalNullableFilter>;
    eventId?: InputMaybe<IntFilter>;
    foreignKey?: InputMaybe<IntFilter>;
    id?: InputMaybe<IntFilter>;
    iof?: InputMaybe<DecimalNullableFilter>;
    isActive?: InputMaybe<BoolFilter>;
    operationId?: InputMaybe<IntFilter>;
    repurchasedAmount?: InputMaybe<DecimalNullableFilter>;
    tax?: InputMaybe<DecimalFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OperationUpdatesScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperationUpdatesScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperationUpdatesScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperationUpdatesScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    disbursement?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    eventId?: InputMaybe<IntWithAggregatesFilter>;
    foreignKey?: InputMaybe<IntWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    iof?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    isActive?: InputMaybe<BoolWithAggregatesFilter>;
    operationId?: InputMaybe<IntWithAggregatesFilter>;
    repurchasedAmount?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    tax?: InputMaybe<DecimalWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type OperationUpdatesSumAggregate = {
    __typename?: "OperationUpdatesSumAggregate";
    disbursement?: Maybe<Scalars["Decimal"]>;
    eventId?: Maybe<Scalars["Int"]>;
    foreignKey?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    iof?: Maybe<Scalars["Decimal"]>;
    operationId?: Maybe<Scalars["Int"]>;
    repurchasedAmount?: Maybe<Scalars["Decimal"]>;
    tax?: Maybe<Scalars["Decimal"]>;
};

export type OperationUpdatesSumOrderByAggregateInput = {
    disbursement?: InputMaybe<SortOrder>;
    eventId?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    iof?: InputMaybe<SortOrder>;
    operationId?: InputMaybe<SortOrder>;
    repurchasedAmount?: InputMaybe<SortOrder>;
    tax?: InputMaybe<SortOrder>;
};

export type OperationUpdatesUpdateInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    disbursement?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    event?: InputMaybe<EventUpdateOneRequiredWithoutOperationUpdatesNestedInput>;
    foreignKey?: InputMaybe<IntFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
    operation?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperationUpdatesNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    tax?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationUpdatesUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    disbursement?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    foreignKey?: InputMaybe<IntFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    tax?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationUpdatesUpdateManyWithWhereWithoutEventInput = {
    data: OperationUpdatesUpdateManyMutationInput;
    where: OperationUpdatesScalarWhereInput;
};

export type OperationUpdatesUpdateManyWithWhereWithoutOperationInput = {
    data: OperationUpdatesUpdateManyMutationInput;
    where: OperationUpdatesScalarWhereInput;
};

export type OperationUpdatesUpdateManyWithoutEventNestedInput = {
    connect?: InputMaybe<Array<OperationUpdatesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationUpdatesCreateOrConnectWithoutEventInput>>;
    create?: InputMaybe<Array<OperationUpdatesCreateWithoutEventInput>>;
    createMany?: InputMaybe<OperationUpdatesCreateManyEventInputEnvelope>;
    delete?: InputMaybe<Array<OperationUpdatesWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperationUpdatesScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperationUpdatesWhereUniqueInput>>;
    set?: InputMaybe<Array<OperationUpdatesWhereUniqueInput>>;
    update?: InputMaybe<Array<OperationUpdatesUpdateWithWhereUniqueWithoutEventInput>>;
    updateMany?: InputMaybe<Array<OperationUpdatesUpdateManyWithWhereWithoutEventInput>>;
    upsert?: InputMaybe<Array<OperationUpdatesUpsertWithWhereUniqueWithoutEventInput>>;
};

export type OperationUpdatesUpdateManyWithoutOperationNestedInput = {
    connect?: InputMaybe<Array<OperationUpdatesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationUpdatesCreateOrConnectWithoutOperationInput>>;
    create?: InputMaybe<Array<OperationUpdatesCreateWithoutOperationInput>>;
    createMany?: InputMaybe<OperationUpdatesCreateManyOperationInputEnvelope>;
    delete?: InputMaybe<Array<OperationUpdatesWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperationUpdatesScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperationUpdatesWhereUniqueInput>>;
    set?: InputMaybe<Array<OperationUpdatesWhereUniqueInput>>;
    update?: InputMaybe<Array<OperationUpdatesUpdateWithWhereUniqueWithoutOperationInput>>;
    updateMany?: InputMaybe<Array<OperationUpdatesUpdateManyWithWhereWithoutOperationInput>>;
    upsert?: InputMaybe<Array<OperationUpdatesUpsertWithWhereUniqueWithoutOperationInput>>;
};

export type OperationUpdatesUpdateWithWhereUniqueWithoutEventInput = {
    data: OperationUpdatesUpdateWithoutEventInput;
    where: OperationUpdatesWhereUniqueInput;
};

export type OperationUpdatesUpdateWithWhereUniqueWithoutOperationInput = {
    data: OperationUpdatesUpdateWithoutOperationInput;
    where: OperationUpdatesWhereUniqueInput;
};

export type OperationUpdatesUpdateWithoutEventInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    disbursement?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    foreignKey?: InputMaybe<IntFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
    operation?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperationUpdatesNestedInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    tax?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationUpdatesUpdateWithoutOperationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    disbursement?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    event?: InputMaybe<EventUpdateOneRequiredWithoutOperationUpdatesNestedInput>;
    foreignKey?: InputMaybe<IntFieldUpdateOperationsInput>;
    iof?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
    repurchasedAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    tax?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationUpdatesUpsertWithWhereUniqueWithoutEventInput = {
    create: OperationUpdatesCreateWithoutEventInput;
    update: OperationUpdatesUpdateWithoutEventInput;
    where: OperationUpdatesWhereUniqueInput;
};

export type OperationUpdatesUpsertWithWhereUniqueWithoutOperationInput = {
    create: OperationUpdatesCreateWithoutOperationInput;
    update: OperationUpdatesUpdateWithoutOperationInput;
    where: OperationUpdatesWhereUniqueInput;
};

export type OperationUpdatesWhereInput = {
    AND?: InputMaybe<Array<OperationUpdatesWhereInput>>;
    NOT?: InputMaybe<Array<OperationUpdatesWhereInput>>;
    OR?: InputMaybe<Array<OperationUpdatesWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    disbursement?: InputMaybe<DecimalNullableFilter>;
    event?: InputMaybe<EventRelationFilter>;
    eventId?: InputMaybe<IntFilter>;
    foreignKey?: InputMaybe<IntFilter>;
    id?: InputMaybe<IntFilter>;
    iof?: InputMaybe<DecimalNullableFilter>;
    isActive?: InputMaybe<BoolFilter>;
    operation?: InputMaybe<OperacaoRelationFilter>;
    operationId?: InputMaybe<IntFilter>;
    repurchasedAmount?: InputMaybe<DecimalNullableFilter>;
    tax?: InputMaybe<DecimalFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OperationUpdatesWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

/** @view */
export type OperationsInfo = {
    __typename?: "OperationsInfo";
    cedente: Cedente;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    modalidade: OperacaoModalidade;
    modalidadeId: Scalars["Int"];
    operacao: Operacao;
    operacaoId: Scalars["Int"];
    riscoZero: Scalars["Boolean"];
    sacado: Sacado;
    sacadoId: Scalars["Int"];
    status: OperacaoStatus;
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoAvgAggregate = {
    __typename?: "OperationsInfoAvgAggregate";
    cedenteId?: Maybe<Scalars["Float"]>;
    keyAccountId?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    modalidadeId?: Maybe<Scalars["Float"]>;
    operacaoId?: Maybe<Scalars["Float"]>;
    sacadoId?: Maybe<Scalars["Float"]>;
    statusId?: Maybe<Scalars["Float"]>;
    taxa?: Maybe<Scalars["Decimal"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type OperationsInfoAvgOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidadeId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    taxa?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type OperationsInfoCountAggregate = {
    __typename?: "OperationsInfoCountAggregate";
    _all: Scalars["Int"];
    cedenteId: Scalars["Int"];
    createdAt: Scalars["Int"];
    dtVencimento: Scalars["Int"];
    keyAccountId: Scalars["Int"];
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    riscoZero: Scalars["Int"];
    sacadoId: Scalars["Int"];
    statusId: Scalars["Int"];
    taxa: Scalars["Int"];
    valor: Scalars["Int"];
};

export type OperationsInfoCountOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidadeId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    riscoZero?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    taxa?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type OperationsInfoCreateInput = {
    cedente: CedenteCreateNestedOneWithoutOperationsInfoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    minibanco: MinibancoCreateNestedOneWithoutOperationsInfoInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperationsInfoInput;
    operacao: OperacaoCreateNestedOneWithoutOperationsInfoInput;
    riscoZero: Scalars["Boolean"];
    sacado: SacadoCreateNestedOneWithoutOperationsInfoInput;
    status: OperacaoStatusCreateNestedOneWithoutOperationsInfoInput;
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoCreateManyCedenteInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    riscoZero: Scalars["Boolean"];
    sacadoId: Scalars["Int"];
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoCreateManyCedenteInputEnvelope = {
    data: Array<OperationsInfoCreateManyCedenteInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperationsInfoCreateManyInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    riscoZero: Scalars["Boolean"];
    sacadoId: Scalars["Int"];
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoCreateManyMinibancoInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    riscoZero: Scalars["Boolean"];
    sacadoId: Scalars["Int"];
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoCreateManyMinibancoInputEnvelope = {
    data: Array<OperationsInfoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperationsInfoCreateManyModalidadeInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    minibancoId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    riscoZero: Scalars["Boolean"];
    sacadoId: Scalars["Int"];
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoCreateManyModalidadeInputEnvelope = {
    data: Array<OperationsInfoCreateManyModalidadeInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperationsInfoCreateManySacadoInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    riscoZero: Scalars["Boolean"];
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoCreateManySacadoInputEnvelope = {
    data: Array<OperationsInfoCreateManySacadoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperationsInfoCreateManyStatusInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    riscoZero: Scalars["Boolean"];
    sacadoId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoCreateManyStatusInputEnvelope = {
    data: Array<OperationsInfoCreateManyStatusInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type OperationsInfoCreateNestedManyWithoutCedenteInput = {
    connect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationsInfoCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<OperationsInfoCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<OperationsInfoCreateManyCedenteInputEnvelope>;
};

export type OperationsInfoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationsInfoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<OperationsInfoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<OperationsInfoCreateManyMinibancoInputEnvelope>;
};

export type OperationsInfoCreateNestedManyWithoutModalidadeInput = {
    connect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationsInfoCreateOrConnectWithoutModalidadeInput>>;
    create?: InputMaybe<Array<OperationsInfoCreateWithoutModalidadeInput>>;
    createMany?: InputMaybe<OperationsInfoCreateManyModalidadeInputEnvelope>;
};

export type OperationsInfoCreateNestedManyWithoutSacadoInput = {
    connect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationsInfoCreateOrConnectWithoutSacadoInput>>;
    create?: InputMaybe<Array<OperationsInfoCreateWithoutSacadoInput>>;
    createMany?: InputMaybe<OperationsInfoCreateManySacadoInputEnvelope>;
};

export type OperationsInfoCreateNestedManyWithoutStatusInput = {
    connect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationsInfoCreateOrConnectWithoutStatusInput>>;
    create?: InputMaybe<Array<OperationsInfoCreateWithoutStatusInput>>;
    createMany?: InputMaybe<OperationsInfoCreateManyStatusInputEnvelope>;
};

export type OperationsInfoCreateNestedOneWithoutOperacaoInput = {
    connect?: InputMaybe<OperationsInfoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperationsInfoCreateOrConnectWithoutOperacaoInput>;
    create?: InputMaybe<OperationsInfoCreateWithoutOperacaoInput>;
};

export type OperationsInfoCreateOrConnectWithoutCedenteInput = {
    create: OperationsInfoCreateWithoutCedenteInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoCreateOrConnectWithoutMinibancoInput = {
    create: OperationsInfoCreateWithoutMinibancoInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoCreateOrConnectWithoutModalidadeInput = {
    create: OperationsInfoCreateWithoutModalidadeInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoCreateOrConnectWithoutOperacaoInput = {
    create: OperationsInfoCreateWithoutOperacaoInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoCreateOrConnectWithoutSacadoInput = {
    create: OperationsInfoCreateWithoutSacadoInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoCreateOrConnectWithoutStatusInput = {
    create: OperationsInfoCreateWithoutStatusInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoCreateWithoutCedenteInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    minibanco: MinibancoCreateNestedOneWithoutOperationsInfoInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperationsInfoInput;
    operacao: OperacaoCreateNestedOneWithoutOperationsInfoInput;
    riscoZero: Scalars["Boolean"];
    sacado: SacadoCreateNestedOneWithoutOperationsInfoInput;
    status: OperacaoStatusCreateNestedOneWithoutOperationsInfoInput;
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoCreateWithoutMinibancoInput = {
    cedente: CedenteCreateNestedOneWithoutOperationsInfoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperationsInfoInput;
    operacao: OperacaoCreateNestedOneWithoutOperationsInfoInput;
    riscoZero: Scalars["Boolean"];
    sacado: SacadoCreateNestedOneWithoutOperationsInfoInput;
    status: OperacaoStatusCreateNestedOneWithoutOperationsInfoInput;
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoCreateWithoutModalidadeInput = {
    cedente: CedenteCreateNestedOneWithoutOperationsInfoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    minibanco: MinibancoCreateNestedOneWithoutOperationsInfoInput;
    operacao: OperacaoCreateNestedOneWithoutOperationsInfoInput;
    riscoZero: Scalars["Boolean"];
    sacado: SacadoCreateNestedOneWithoutOperationsInfoInput;
    status: OperacaoStatusCreateNestedOneWithoutOperationsInfoInput;
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoCreateWithoutOperacaoInput = {
    cedente: CedenteCreateNestedOneWithoutOperationsInfoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    minibanco: MinibancoCreateNestedOneWithoutOperationsInfoInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperationsInfoInput;
    riscoZero: Scalars["Boolean"];
    sacado: SacadoCreateNestedOneWithoutOperationsInfoInput;
    status: OperacaoStatusCreateNestedOneWithoutOperationsInfoInput;
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoCreateWithoutSacadoInput = {
    cedente: CedenteCreateNestedOneWithoutOperationsInfoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    minibanco: MinibancoCreateNestedOneWithoutOperationsInfoInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperationsInfoInput;
    operacao: OperacaoCreateNestedOneWithoutOperationsInfoInput;
    riscoZero: Scalars["Boolean"];
    status: OperacaoStatusCreateNestedOneWithoutOperationsInfoInput;
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoCreateWithoutStatusInput = {
    cedente: CedenteCreateNestedOneWithoutOperationsInfoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    minibanco: MinibancoCreateNestedOneWithoutOperationsInfoInput;
    modalidade: OperacaoModalidadeCreateNestedOneWithoutOperationsInfoInput;
    operacao: OperacaoCreateNestedOneWithoutOperationsInfoInput;
    riscoZero: Scalars["Boolean"];
    sacado: SacadoCreateNestedOneWithoutOperationsInfoInput;
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoGroupBy = {
    __typename?: "OperationsInfoGroupBy";
    _avg?: Maybe<OperationsInfoAvgAggregate>;
    _count?: Maybe<OperationsInfoCountAggregate>;
    _max?: Maybe<OperationsInfoMaxAggregate>;
    _min?: Maybe<OperationsInfoMinAggregate>;
    _sum?: Maybe<OperationsInfoSumAggregate>;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    dtVencimento: Scalars["DateTime"];
    keyAccountId: Scalars["Int"];
    minibancoId: Scalars["Int"];
    modalidadeId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    riscoZero: Scalars["Boolean"];
    sacadoId: Scalars["Int"];
    statusId: Scalars["Int"];
    taxa: Scalars["Decimal"];
    valor: Scalars["Decimal"];
};

export type OperationsInfoListRelationFilter = {
    every?: InputMaybe<OperationsInfoWhereInput>;
    none?: InputMaybe<OperationsInfoWhereInput>;
    some?: InputMaybe<OperationsInfoWhereInput>;
};

export type OperationsInfoMaxAggregate = {
    __typename?: "OperationsInfoMaxAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    keyAccountId?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    modalidadeId?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    riscoZero?: Maybe<Scalars["Boolean"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
    statusId?: Maybe<Scalars["Int"]>;
    taxa?: Maybe<Scalars["Decimal"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type OperationsInfoMaxOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidadeId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    riscoZero?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    taxa?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type OperationsInfoMinAggregate = {
    __typename?: "OperationsInfoMinAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    keyAccountId?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    modalidadeId?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    riscoZero?: Maybe<Scalars["Boolean"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
    statusId?: Maybe<Scalars["Int"]>;
    taxa?: Maybe<Scalars["Decimal"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type OperationsInfoMinOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidadeId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    riscoZero?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    taxa?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type OperationsInfoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type OperationsInfoOrderByWithAggregationInput = {
    _avg?: InputMaybe<OperationsInfoAvgOrderByAggregateInput>;
    _count?: InputMaybe<OperationsInfoCountOrderByAggregateInput>;
    _max?: InputMaybe<OperationsInfoMaxOrderByAggregateInput>;
    _min?: InputMaybe<OperationsInfoMinOrderByAggregateInput>;
    _sum?: InputMaybe<OperationsInfoSumOrderByAggregateInput>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidadeId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    riscoZero?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    taxa?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type OperationsInfoOrderByWithRelationInput = {
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidade?: InputMaybe<OperacaoModalidadeOrderByWithRelationInput>;
    modalidadeId?: InputMaybe<SortOrder>;
    operacao?: InputMaybe<OperacaoOrderByWithRelationInput>;
    operacaoId?: InputMaybe<SortOrder>;
    riscoZero?: InputMaybe<SortOrder>;
    sacado?: InputMaybe<SacadoOrderByWithRelationInput>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<OperacaoStatusOrderByWithRelationInput>;
    statusId?: InputMaybe<SortOrder>;
    taxa?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type OperationsInfoRelationFilter = {
    is?: InputMaybe<OperationsInfoWhereInput>;
    isNot?: InputMaybe<OperationsInfoWhereInput>;
};

export enum OperationsInfoScalarFieldEnum {
    CedenteId = "cedenteId",
    CreatedAt = "createdAt",
    DtVencimento = "dtVencimento",
    KeyAccountId = "keyAccountId",
    MinibancoId = "minibancoId",
    ModalidadeId = "modalidadeId",
    OperacaoId = "operacaoId",
    RiscoZero = "riscoZero",
    SacadoId = "sacadoId",
    StatusId = "statusId",
    Taxa = "taxa",
    Valor = "valor",
}

export type OperationsInfoScalarWhereInput = {
    AND?: InputMaybe<Array<OperationsInfoScalarWhereInput>>;
    NOT?: InputMaybe<Array<OperationsInfoScalarWhereInput>>;
    OR?: InputMaybe<Array<OperationsInfoScalarWhereInput>>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    dtVencimento?: InputMaybe<DateTimeFilter>;
    keyAccountId?: InputMaybe<IntFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    modalidadeId?: InputMaybe<IntFilter>;
    operacaoId?: InputMaybe<IntFilter>;
    riscoZero?: InputMaybe<BoolFilter>;
    sacadoId?: InputMaybe<IntFilter>;
    statusId?: InputMaybe<IntFilter>;
    taxa?: InputMaybe<DecimalFilter>;
    valor?: InputMaybe<DecimalFilter>;
};

export type OperationsInfoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<OperationsInfoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<OperationsInfoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<OperationsInfoScalarWhereWithAggregatesInput>>;
    cedenteId?: InputMaybe<IntWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    dtVencimento?: InputMaybe<DateTimeWithAggregatesFilter>;
    keyAccountId?: InputMaybe<IntWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    modalidadeId?: InputMaybe<IntWithAggregatesFilter>;
    operacaoId?: InputMaybe<IntWithAggregatesFilter>;
    riscoZero?: InputMaybe<BoolWithAggregatesFilter>;
    sacadoId?: InputMaybe<IntWithAggregatesFilter>;
    statusId?: InputMaybe<IntWithAggregatesFilter>;
    taxa?: InputMaybe<DecimalWithAggregatesFilter>;
    valor?: InputMaybe<DecimalWithAggregatesFilter>;
};

export type OperationsInfoSumAggregate = {
    __typename?: "OperationsInfoSumAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    keyAccountId?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    modalidadeId?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
    statusId?: Maybe<Scalars["Int"]>;
    taxa?: Maybe<Scalars["Decimal"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type OperationsInfoSumOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    keyAccountId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    modalidadeId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    taxa?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type OperationsInfoUpdateInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    keyAccountId?: InputMaybe<IntFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    riscoZero?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type OperationsInfoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    keyAccountId?: InputMaybe<IntFieldUpdateOperationsInput>;
    riscoZero?: InputMaybe<BoolFieldUpdateOperationsInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type OperationsInfoUpdateManyWithWhereWithoutCedenteInput = {
    data: OperationsInfoUpdateManyMutationInput;
    where: OperationsInfoScalarWhereInput;
};

export type OperationsInfoUpdateManyWithWhereWithoutMinibancoInput = {
    data: OperationsInfoUpdateManyMutationInput;
    where: OperationsInfoScalarWhereInput;
};

export type OperationsInfoUpdateManyWithWhereWithoutModalidadeInput = {
    data: OperationsInfoUpdateManyMutationInput;
    where: OperationsInfoScalarWhereInput;
};

export type OperationsInfoUpdateManyWithWhereWithoutSacadoInput = {
    data: OperationsInfoUpdateManyMutationInput;
    where: OperationsInfoScalarWhereInput;
};

export type OperationsInfoUpdateManyWithWhereWithoutStatusInput = {
    data: OperationsInfoUpdateManyMutationInput;
    where: OperationsInfoScalarWhereInput;
};

export type OperationsInfoUpdateManyWithoutCedenteNestedInput = {
    connect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationsInfoCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<OperationsInfoCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<OperationsInfoCreateManyCedenteInputEnvelope>;
    delete?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperationsInfoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    set?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    update?: InputMaybe<Array<OperationsInfoUpdateWithWhereUniqueWithoutCedenteInput>>;
    updateMany?: InputMaybe<Array<OperationsInfoUpdateManyWithWhereWithoutCedenteInput>>;
    upsert?: InputMaybe<Array<OperationsInfoUpsertWithWhereUniqueWithoutCedenteInput>>;
};

export type OperationsInfoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationsInfoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<OperationsInfoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<OperationsInfoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperationsInfoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    set?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    update?: InputMaybe<Array<OperationsInfoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<OperationsInfoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<OperationsInfoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type OperationsInfoUpdateManyWithoutModalidadeNestedInput = {
    connect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationsInfoCreateOrConnectWithoutModalidadeInput>>;
    create?: InputMaybe<Array<OperationsInfoCreateWithoutModalidadeInput>>;
    createMany?: InputMaybe<OperationsInfoCreateManyModalidadeInputEnvelope>;
    delete?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperationsInfoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    set?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    update?: InputMaybe<Array<OperationsInfoUpdateWithWhereUniqueWithoutModalidadeInput>>;
    updateMany?: InputMaybe<Array<OperationsInfoUpdateManyWithWhereWithoutModalidadeInput>>;
    upsert?: InputMaybe<Array<OperationsInfoUpsertWithWhereUniqueWithoutModalidadeInput>>;
};

export type OperationsInfoUpdateManyWithoutSacadoNestedInput = {
    connect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationsInfoCreateOrConnectWithoutSacadoInput>>;
    create?: InputMaybe<Array<OperationsInfoCreateWithoutSacadoInput>>;
    createMany?: InputMaybe<OperationsInfoCreateManySacadoInputEnvelope>;
    delete?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperationsInfoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    set?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    update?: InputMaybe<Array<OperationsInfoUpdateWithWhereUniqueWithoutSacadoInput>>;
    updateMany?: InputMaybe<Array<OperationsInfoUpdateManyWithWhereWithoutSacadoInput>>;
    upsert?: InputMaybe<Array<OperationsInfoUpsertWithWhereUniqueWithoutSacadoInput>>;
};

export type OperationsInfoUpdateManyWithoutStatusNestedInput = {
    connect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<OperationsInfoCreateOrConnectWithoutStatusInput>>;
    create?: InputMaybe<Array<OperationsInfoCreateWithoutStatusInput>>;
    createMany?: InputMaybe<OperationsInfoCreateManyStatusInputEnvelope>;
    delete?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<OperationsInfoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    set?: InputMaybe<Array<OperationsInfoWhereUniqueInput>>;
    update?: InputMaybe<Array<OperationsInfoUpdateWithWhereUniqueWithoutStatusInput>>;
    updateMany?: InputMaybe<Array<OperationsInfoUpdateManyWithWhereWithoutStatusInput>>;
    upsert?: InputMaybe<Array<OperationsInfoUpsertWithWhereUniqueWithoutStatusInput>>;
};

export type OperationsInfoUpdateOneWithoutOperacaoNestedInput = {
    connect?: InputMaybe<OperationsInfoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<OperationsInfoCreateOrConnectWithoutOperacaoInput>;
    create?: InputMaybe<OperationsInfoCreateWithoutOperacaoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<OperationsInfoUpdateWithoutOperacaoInput>;
    upsert?: InputMaybe<OperationsInfoUpsertWithoutOperacaoInput>;
};

export type OperationsInfoUpdateWithWhereUniqueWithoutCedenteInput = {
    data: OperationsInfoUpdateWithoutCedenteInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: OperationsInfoUpdateWithoutMinibancoInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoUpdateWithWhereUniqueWithoutModalidadeInput = {
    data: OperationsInfoUpdateWithoutModalidadeInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoUpdateWithWhereUniqueWithoutSacadoInput = {
    data: OperationsInfoUpdateWithoutSacadoInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoUpdateWithWhereUniqueWithoutStatusInput = {
    data: OperationsInfoUpdateWithoutStatusInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoUpdateWithoutCedenteInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    keyAccountId?: InputMaybe<IntFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    riscoZero?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type OperationsInfoUpdateWithoutMinibancoInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    keyAccountId?: InputMaybe<IntFieldUpdateOperationsInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    riscoZero?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type OperationsInfoUpdateWithoutModalidadeInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    keyAccountId?: InputMaybe<IntFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    riscoZero?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type OperationsInfoUpdateWithoutOperacaoInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    keyAccountId?: InputMaybe<IntFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    riscoZero?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type OperationsInfoUpdateWithoutSacadoInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    keyAccountId?: InputMaybe<IntFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    riscoZero?: InputMaybe<BoolFieldUpdateOperationsInput>;
    status?: InputMaybe<OperacaoStatusUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type OperationsInfoUpdateWithoutStatusInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    keyAccountId?: InputMaybe<IntFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    modalidade?: InputMaybe<OperacaoModalidadeUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    riscoZero?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutOperationsInfoNestedInput>;
    taxa?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type OperationsInfoUpsertWithWhereUniqueWithoutCedenteInput = {
    create: OperationsInfoCreateWithoutCedenteInput;
    update: OperationsInfoUpdateWithoutCedenteInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: OperationsInfoCreateWithoutMinibancoInput;
    update: OperationsInfoUpdateWithoutMinibancoInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoUpsertWithWhereUniqueWithoutModalidadeInput = {
    create: OperationsInfoCreateWithoutModalidadeInput;
    update: OperationsInfoUpdateWithoutModalidadeInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoUpsertWithWhereUniqueWithoutSacadoInput = {
    create: OperationsInfoCreateWithoutSacadoInput;
    update: OperationsInfoUpdateWithoutSacadoInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoUpsertWithWhereUniqueWithoutStatusInput = {
    create: OperationsInfoCreateWithoutStatusInput;
    update: OperationsInfoUpdateWithoutStatusInput;
    where: OperationsInfoWhereUniqueInput;
};

export type OperationsInfoUpsertWithoutOperacaoInput = {
    create: OperationsInfoCreateWithoutOperacaoInput;
    update: OperationsInfoUpdateWithoutOperacaoInput;
};

export type OperationsInfoWhereInput = {
    AND?: InputMaybe<Array<OperationsInfoWhereInput>>;
    NOT?: InputMaybe<Array<OperationsInfoWhereInput>>;
    OR?: InputMaybe<Array<OperationsInfoWhereInput>>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    dtVencimento?: InputMaybe<DateTimeFilter>;
    keyAccountId?: InputMaybe<IntFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    modalidade?: InputMaybe<OperacaoModalidadeRelationFilter>;
    modalidadeId?: InputMaybe<IntFilter>;
    operacao?: InputMaybe<OperacaoRelationFilter>;
    operacaoId?: InputMaybe<IntFilter>;
    riscoZero?: InputMaybe<BoolFilter>;
    sacado?: InputMaybe<SacadoRelationFilter>;
    sacadoId?: InputMaybe<IntFilter>;
    status?: InputMaybe<OperacaoStatusRelationFilter>;
    statusId?: InputMaybe<IntFilter>;
    taxa?: InputMaybe<DecimalFilter>;
    valor?: InputMaybe<DecimalFilter>;
};

export type OperationsInfoWhereUniqueInput = {
    operacaoId?: InputMaybe<Scalars["Int"]>;
};

export enum Origem {
    Backoffice = "BACKOFFICE",
    CadastroNfe = "CADASTRO_NFE",
    PlataformaCedente = "PLATAFORMA_CEDENTE",
}

export enum OrigemCedente {
    Backoffice = "BACKOFFICE",
    CadastroNfe = "CADASTRO_NFE",
    Licenciado = "LICENCIADO",
    PlataformaCedente = "PLATAFORMA_CEDENTE",
}

export type ParceiroComercial = {
    __typename?: "ParceiroComercial";
    _count?: Maybe<ParceiroComercialCount>;
    audit: Array<ComercialPartnerAudit>;
    corporateName?: Maybe<Scalars["String"]>;
    cpf?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    document: Scalars["String"];
    dtNascimento?: Maybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    fantasyName?: Maybe<Scalars["String"]>;
    fone?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibancos: Array<Minibanco>;
    partnerCommission: Array<ComercialPartnerCommission>;
    updatedAt: Scalars["DateTime"];
};

export type ParceiroComercialAuditArgs = {
    cursor?: InputMaybe<ComercialPartnerAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<ComercialPartnerAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ComercialPartnerAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ComercialPartnerAuditWhereInput>;
};

export type ParceiroComercialMinibancosArgs = {
    cursor?: InputMaybe<MinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoWhereInput>;
};

export type ParceiroComercialPartnerCommissionArgs = {
    cursor?: InputMaybe<ComercialPartnerCommissionWhereUniqueInput>;
    distinct?: InputMaybe<Array<ComercialPartnerCommissionScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ComercialPartnerCommissionOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ComercialPartnerCommissionWhereInput>;
};

export type ParceiroComercialAvgAggregate = {
    __typename?: "ParceiroComercialAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
};

export type ParceiroComercialAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type ParceiroComercialCount = {
    __typename?: "ParceiroComercialCount";
    audit: Scalars["Int"];
    minibancos: Scalars["Int"];
    partnerCommission: Scalars["Int"];
};

export type ParceiroComercialCountAggregate = {
    __typename?: "ParceiroComercialCountAggregate";
    _all: Scalars["Int"];
    corporateName: Scalars["Int"];
    cpf: Scalars["Int"];
    createdAt: Scalars["Int"];
    document: Scalars["Int"];
    dtNascimento: Scalars["Int"];
    email: Scalars["Int"];
    fantasyName: Scalars["Int"];
    fone: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type ParceiroComercialCountOrderByAggregateInput = {
    corporateName?: InputMaybe<SortOrder>;
    cpf?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    dtNascimento?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    fantasyName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ParceiroComercialCreateInput = {
    audit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutPartnerInput>;
    corporateName?: InputMaybe<Scalars["String"]>;
    cpf?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    dtNascimento?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    fantasyName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutParceiroComercialInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutPartnerInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ParceiroComercialCreateManyInput = {
    corporateName?: InputMaybe<Scalars["String"]>;
    cpf?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    dtNascimento?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    fantasyName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ParceiroComercialCreateNestedOneWithoutAuditInput = {
    connect?: InputMaybe<ParceiroComercialWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ParceiroComercialCreateOrConnectWithoutAuditInput>;
    create?: InputMaybe<ParceiroComercialCreateWithoutAuditInput>;
};

export type ParceiroComercialCreateNestedOneWithoutMinibancosInput = {
    connect?: InputMaybe<ParceiroComercialWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ParceiroComercialCreateOrConnectWithoutMinibancosInput>;
    create?: InputMaybe<ParceiroComercialCreateWithoutMinibancosInput>;
};

export type ParceiroComercialCreateNestedOneWithoutPartnerCommissionInput = {
    connect?: InputMaybe<ParceiroComercialWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ParceiroComercialCreateOrConnectWithoutPartnerCommissionInput>;
    create?: InputMaybe<ParceiroComercialCreateWithoutPartnerCommissionInput>;
};

export type ParceiroComercialCreateOrConnectWithoutAuditInput = {
    create: ParceiroComercialCreateWithoutAuditInput;
    where: ParceiroComercialWhereUniqueInput;
};

export type ParceiroComercialCreateOrConnectWithoutMinibancosInput = {
    create: ParceiroComercialCreateWithoutMinibancosInput;
    where: ParceiroComercialWhereUniqueInput;
};

export type ParceiroComercialCreateOrConnectWithoutPartnerCommissionInput = {
    create: ParceiroComercialCreateWithoutPartnerCommissionInput;
    where: ParceiroComercialWhereUniqueInput;
};

export type ParceiroComercialCreateWithoutAuditInput = {
    corporateName?: InputMaybe<Scalars["String"]>;
    cpf?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    dtNascimento?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    fantasyName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutParceiroComercialInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutPartnerInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ParceiroComercialCreateWithoutMinibancosInput = {
    audit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutPartnerInput>;
    corporateName?: InputMaybe<Scalars["String"]>;
    cpf?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    dtNascimento?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    fantasyName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionCreateNestedManyWithoutPartnerInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ParceiroComercialCreateWithoutPartnerCommissionInput = {
    audit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutPartnerInput>;
    corporateName?: InputMaybe<Scalars["String"]>;
    cpf?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    dtNascimento?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    fantasyName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutParceiroComercialInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ParceiroComercialGroupBy = {
    __typename?: "ParceiroComercialGroupBy";
    _avg?: Maybe<ParceiroComercialAvgAggregate>;
    _count?: Maybe<ParceiroComercialCountAggregate>;
    _max?: Maybe<ParceiroComercialMaxAggregate>;
    _min?: Maybe<ParceiroComercialMinAggregate>;
    _sum?: Maybe<ParceiroComercialSumAggregate>;
    corporateName?: Maybe<Scalars["String"]>;
    cpf?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    document: Scalars["String"];
    dtNascimento?: Maybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    fantasyName?: Maybe<Scalars["String"]>;
    fone?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    updatedAt: Scalars["DateTime"];
};

export type ParceiroComercialMaxAggregate = {
    __typename?: "ParceiroComercialMaxAggregate";
    corporateName?: Maybe<Scalars["String"]>;
    cpf?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    document?: Maybe<Scalars["String"]>;
    dtNascimento?: Maybe<Scalars["DateTime"]>;
    email?: Maybe<Scalars["String"]>;
    fantasyName?: Maybe<Scalars["String"]>;
    fone?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ParceiroComercialMaxOrderByAggregateInput = {
    corporateName?: InputMaybe<SortOrder>;
    cpf?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    dtNascimento?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    fantasyName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ParceiroComercialMinAggregate = {
    __typename?: "ParceiroComercialMinAggregate";
    corporateName?: Maybe<Scalars["String"]>;
    cpf?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    document?: Maybe<Scalars["String"]>;
    dtNascimento?: Maybe<Scalars["DateTime"]>;
    email?: Maybe<Scalars["String"]>;
    fantasyName?: Maybe<Scalars["String"]>;
    fone?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ParceiroComercialMinOrderByAggregateInput = {
    corporateName?: InputMaybe<SortOrder>;
    cpf?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    dtNascimento?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    fantasyName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ParceiroComercialOrderByWithAggregationInput = {
    _avg?: InputMaybe<ParceiroComercialAvgOrderByAggregateInput>;
    _count?: InputMaybe<ParceiroComercialCountOrderByAggregateInput>;
    _max?: InputMaybe<ParceiroComercialMaxOrderByAggregateInput>;
    _min?: InputMaybe<ParceiroComercialMinOrderByAggregateInput>;
    _sum?: InputMaybe<ParceiroComercialSumOrderByAggregateInput>;
    corporateName?: InputMaybe<SortOrder>;
    cpf?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    dtNascimento?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    fantasyName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ParceiroComercialOrderByWithRelationInput = {
    audit?: InputMaybe<ComercialPartnerAuditOrderByRelationAggregateInput>;
    corporateName?: InputMaybe<SortOrder>;
    cpf?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    dtNascimento?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    fantasyName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancos?: InputMaybe<MinibancoOrderByRelationAggregateInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionOrderByRelationAggregateInput>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type ParceiroComercialRelationFilter = {
    is?: InputMaybe<ParceiroComercialWhereInput>;
    isNot?: InputMaybe<ParceiroComercialWhereInput>;
};

export enum ParceiroComercialScalarFieldEnum {
    CorporateName = "corporateName",
    Cpf = "cpf",
    CreatedAt = "createdAt",
    Document = "document",
    DtNascimento = "dtNascimento",
    Email = "email",
    FantasyName = "fantasyName",
    Fone = "fone",
    Id = "id",
    Inativo = "inativo",
    UpdatedAt = "updatedAt",
}

export type ParceiroComercialScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<ParceiroComercialScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<ParceiroComercialScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<ParceiroComercialScalarWhereWithAggregatesInput>>;
    corporateName?: InputMaybe<StringNullableWithAggregatesFilter>;
    cpf?: InputMaybe<StringNullableWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    document?: InputMaybe<StringWithAggregatesFilter>;
    dtNascimento?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
    email?: InputMaybe<StringWithAggregatesFilter>;
    fantasyName?: InputMaybe<StringNullableWithAggregatesFilter>;
    fone?: InputMaybe<StringNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type ParceiroComercialSumAggregate = {
    __typename?: "ParceiroComercialSumAggregate";
    id?: Maybe<Scalars["Int"]>;
};

export type ParceiroComercialSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type ParceiroComercialUpdateInput = {
    audit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutPartnerNestedInput>;
    corporateName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cpf?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtNascimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    fantasyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutParceiroComercialNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutPartnerNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ParceiroComercialUpdateManyMutationInput = {
    corporateName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cpf?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtNascimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    fantasyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ParceiroComercialUpdateOneRequiredWithoutAuditNestedInput = {
    connect?: InputMaybe<ParceiroComercialWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ParceiroComercialCreateOrConnectWithoutAuditInput>;
    create?: InputMaybe<ParceiroComercialCreateWithoutAuditInput>;
    update?: InputMaybe<ParceiroComercialUpdateWithoutAuditInput>;
    upsert?: InputMaybe<ParceiroComercialUpsertWithoutAuditInput>;
};

export type ParceiroComercialUpdateOneRequiredWithoutPartnerCommissionNestedInput = {
    connect?: InputMaybe<ParceiroComercialWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ParceiroComercialCreateOrConnectWithoutPartnerCommissionInput>;
    create?: InputMaybe<ParceiroComercialCreateWithoutPartnerCommissionInput>;
    update?: InputMaybe<ParceiroComercialUpdateWithoutPartnerCommissionInput>;
    upsert?: InputMaybe<ParceiroComercialUpsertWithoutPartnerCommissionInput>;
};

export type ParceiroComercialUpdateOneWithoutMinibancosNestedInput = {
    connect?: InputMaybe<ParceiroComercialWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ParceiroComercialCreateOrConnectWithoutMinibancosInput>;
    create?: InputMaybe<ParceiroComercialCreateWithoutMinibancosInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<ParceiroComercialUpdateWithoutMinibancosInput>;
    upsert?: InputMaybe<ParceiroComercialUpsertWithoutMinibancosInput>;
};

export type ParceiroComercialUpdateWithoutAuditInput = {
    corporateName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cpf?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtNascimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    fantasyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutParceiroComercialNestedInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutPartnerNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ParceiroComercialUpdateWithoutMinibancosInput = {
    audit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutPartnerNestedInput>;
    corporateName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cpf?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtNascimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    fantasyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionUpdateManyWithoutPartnerNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ParceiroComercialUpdateWithoutPartnerCommissionInput = {
    audit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutPartnerNestedInput>;
    corporateName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cpf?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtNascimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    fantasyName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutParceiroComercialNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ParceiroComercialUpsertWithoutAuditInput = {
    create: ParceiroComercialCreateWithoutAuditInput;
    update: ParceiroComercialUpdateWithoutAuditInput;
};

export type ParceiroComercialUpsertWithoutMinibancosInput = {
    create: ParceiroComercialCreateWithoutMinibancosInput;
    update: ParceiroComercialUpdateWithoutMinibancosInput;
};

export type ParceiroComercialUpsertWithoutPartnerCommissionInput = {
    create: ParceiroComercialCreateWithoutPartnerCommissionInput;
    update: ParceiroComercialUpdateWithoutPartnerCommissionInput;
};

export type ParceiroComercialWhereInput = {
    AND?: InputMaybe<Array<ParceiroComercialWhereInput>>;
    NOT?: InputMaybe<Array<ParceiroComercialWhereInput>>;
    OR?: InputMaybe<Array<ParceiroComercialWhereInput>>;
    audit?: InputMaybe<ComercialPartnerAuditListRelationFilter>;
    corporateName?: InputMaybe<StringNullableFilter>;
    cpf?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    document?: InputMaybe<StringFilter>;
    dtNascimento?: InputMaybe<DateTimeNullableFilter>;
    email?: InputMaybe<StringFilter>;
    fantasyName?: InputMaybe<StringNullableFilter>;
    fone?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibancos?: InputMaybe<MinibancoListRelationFilter>;
    partnerCommission?: InputMaybe<ComercialPartnerCommissionListRelationFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ParceiroComercialWhereUniqueInput = {
    document?: InputMaybe<Scalars["String"]>;
    email?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
};

export enum PayableType {
    Cheque = "CHEQUE",
    Duplicata = "DUPLICATA",
    NotaPromissoria = "NOTA_PROMISSORIA",
}

export type PayableUpdates = {
    __typename?: "PayableUpdates";
    createdAt: Scalars["DateTime"];
    deduction: Scalars["Decimal"];
    event: Event;
    eventId: Scalars["Int"];
    expenses: Scalars["Decimal"];
    expiredAt?: Maybe<Scalars["DateTime"]>;
    extensionAmount?: Maybe<Scalars["Decimal"]>;
    extensionDate?: Maybe<Scalars["DateTime"]>;
    fine?: Maybe<Scalars["Decimal"]>;
    foreignKey: Scalars["Int"];
    id: Scalars["Int"];
    isActive: Scalars["Boolean"];
    paidAmount?: Maybe<Scalars["Decimal"]>;
    payable: Recebivel;
    payableId: Scalars["Int"];
    paymentAmount: Scalars["Decimal"];
    paymentDate?: Maybe<Scalars["DateTime"]>;
    status: StatusRecebivel;
    updatedAt: Scalars["DateTime"];
};

export type PayableUpdatesAvgAggregate = {
    __typename?: "PayableUpdatesAvgAggregate";
    deduction?: Maybe<Scalars["Decimal"]>;
    eventId?: Maybe<Scalars["Float"]>;
    expenses?: Maybe<Scalars["Decimal"]>;
    extensionAmount?: Maybe<Scalars["Decimal"]>;
    fine?: Maybe<Scalars["Decimal"]>;
    foreignKey?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    paidAmount?: Maybe<Scalars["Decimal"]>;
    payableId?: Maybe<Scalars["Float"]>;
    paymentAmount?: Maybe<Scalars["Decimal"]>;
};

export type PayableUpdatesAvgOrderByAggregateInput = {
    deduction?: InputMaybe<SortOrder>;
    eventId?: InputMaybe<SortOrder>;
    expenses?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    fine?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    paidAmount?: InputMaybe<SortOrder>;
    payableId?: InputMaybe<SortOrder>;
    paymentAmount?: InputMaybe<SortOrder>;
};

export type PayableUpdatesCountAggregate = {
    __typename?: "PayableUpdatesCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    deduction: Scalars["Int"];
    eventId: Scalars["Int"];
    expenses: Scalars["Int"];
    expiredAt: Scalars["Int"];
    extensionAmount: Scalars["Int"];
    extensionDate: Scalars["Int"];
    fine: Scalars["Int"];
    foreignKey: Scalars["Int"];
    id: Scalars["Int"];
    isActive: Scalars["Int"];
    paidAmount: Scalars["Int"];
    payableId: Scalars["Int"];
    paymentAmount: Scalars["Int"];
    paymentDate: Scalars["Int"];
    status: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type PayableUpdatesCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    deduction?: InputMaybe<SortOrder>;
    eventId?: InputMaybe<SortOrder>;
    expenses?: InputMaybe<SortOrder>;
    expiredAt?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    extensionDate?: InputMaybe<SortOrder>;
    fine?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    isActive?: InputMaybe<SortOrder>;
    paidAmount?: InputMaybe<SortOrder>;
    payableId?: InputMaybe<SortOrder>;
    paymentAmount?: InputMaybe<SortOrder>;
    paymentDate?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PayableUpdatesCreateInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    deduction: Scalars["Decimal"];
    event: EventCreateNestedOneWithoutPayableUpdatesInput;
    expenses: Scalars["Decimal"];
    expiredAt?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    fine?: InputMaybe<Scalars["Decimal"]>;
    foreignKey: Scalars["Int"];
    isActive?: InputMaybe<Scalars["Boolean"]>;
    paidAmount?: InputMaybe<Scalars["Decimal"]>;
    payable: RecebivelCreateNestedOneWithoutPayableUpdatesInput;
    paymentAmount: Scalars["Decimal"];
    paymentDate?: InputMaybe<Scalars["DateTime"]>;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PayableUpdatesCreateManyEventInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    deduction: Scalars["Decimal"];
    expenses: Scalars["Decimal"];
    expiredAt?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    fine?: InputMaybe<Scalars["Decimal"]>;
    foreignKey: Scalars["Int"];
    id?: InputMaybe<Scalars["Int"]>;
    isActive?: InputMaybe<Scalars["Boolean"]>;
    paidAmount?: InputMaybe<Scalars["Decimal"]>;
    payableId: Scalars["Int"];
    paymentAmount: Scalars["Decimal"];
    paymentDate?: InputMaybe<Scalars["DateTime"]>;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PayableUpdatesCreateManyEventInputEnvelope = {
    data: Array<PayableUpdatesCreateManyEventInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type PayableUpdatesCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    deduction: Scalars["Decimal"];
    eventId: Scalars["Int"];
    expenses: Scalars["Decimal"];
    expiredAt?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    fine?: InputMaybe<Scalars["Decimal"]>;
    foreignKey: Scalars["Int"];
    id?: InputMaybe<Scalars["Int"]>;
    isActive?: InputMaybe<Scalars["Boolean"]>;
    paidAmount?: InputMaybe<Scalars["Decimal"]>;
    payableId: Scalars["Int"];
    paymentAmount: Scalars["Decimal"];
    paymentDate?: InputMaybe<Scalars["DateTime"]>;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PayableUpdatesCreateManyPayableInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    deduction: Scalars["Decimal"];
    eventId: Scalars["Int"];
    expenses: Scalars["Decimal"];
    expiredAt?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    fine?: InputMaybe<Scalars["Decimal"]>;
    foreignKey: Scalars["Int"];
    id?: InputMaybe<Scalars["Int"]>;
    isActive?: InputMaybe<Scalars["Boolean"]>;
    paidAmount?: InputMaybe<Scalars["Decimal"]>;
    paymentAmount: Scalars["Decimal"];
    paymentDate?: InputMaybe<Scalars["DateTime"]>;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PayableUpdatesCreateManyPayableInputEnvelope = {
    data: Array<PayableUpdatesCreateManyPayableInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type PayableUpdatesCreateNestedManyWithoutEventInput = {
    connect?: InputMaybe<Array<PayableUpdatesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<PayableUpdatesCreateOrConnectWithoutEventInput>>;
    create?: InputMaybe<Array<PayableUpdatesCreateWithoutEventInput>>;
    createMany?: InputMaybe<PayableUpdatesCreateManyEventInputEnvelope>;
};

export type PayableUpdatesCreateNestedManyWithoutPayableInput = {
    connect?: InputMaybe<Array<PayableUpdatesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<PayableUpdatesCreateOrConnectWithoutPayableInput>>;
    create?: InputMaybe<Array<PayableUpdatesCreateWithoutPayableInput>>;
    createMany?: InputMaybe<PayableUpdatesCreateManyPayableInputEnvelope>;
};

export type PayableUpdatesCreateOrConnectWithoutEventInput = {
    create: PayableUpdatesCreateWithoutEventInput;
    where: PayableUpdatesWhereUniqueInput;
};

export type PayableUpdatesCreateOrConnectWithoutPayableInput = {
    create: PayableUpdatesCreateWithoutPayableInput;
    where: PayableUpdatesWhereUniqueInput;
};

export type PayableUpdatesCreateWithoutEventInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    deduction: Scalars["Decimal"];
    expenses: Scalars["Decimal"];
    expiredAt?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    fine?: InputMaybe<Scalars["Decimal"]>;
    foreignKey: Scalars["Int"];
    isActive?: InputMaybe<Scalars["Boolean"]>;
    paidAmount?: InputMaybe<Scalars["Decimal"]>;
    payable: RecebivelCreateNestedOneWithoutPayableUpdatesInput;
    paymentAmount: Scalars["Decimal"];
    paymentDate?: InputMaybe<Scalars["DateTime"]>;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PayableUpdatesCreateWithoutPayableInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    deduction: Scalars["Decimal"];
    event: EventCreateNestedOneWithoutPayableUpdatesInput;
    expenses: Scalars["Decimal"];
    expiredAt?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    fine?: InputMaybe<Scalars["Decimal"]>;
    foreignKey: Scalars["Int"];
    isActive?: InputMaybe<Scalars["Boolean"]>;
    paidAmount?: InputMaybe<Scalars["Decimal"]>;
    paymentAmount: Scalars["Decimal"];
    paymentDate?: InputMaybe<Scalars["DateTime"]>;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PayableUpdatesGroupBy = {
    __typename?: "PayableUpdatesGroupBy";
    _avg?: Maybe<PayableUpdatesAvgAggregate>;
    _count?: Maybe<PayableUpdatesCountAggregate>;
    _max?: Maybe<PayableUpdatesMaxAggregate>;
    _min?: Maybe<PayableUpdatesMinAggregate>;
    _sum?: Maybe<PayableUpdatesSumAggregate>;
    createdAt: Scalars["DateTime"];
    deduction: Scalars["Decimal"];
    eventId: Scalars["Int"];
    expenses: Scalars["Decimal"];
    expiredAt?: Maybe<Scalars["DateTime"]>;
    extensionAmount?: Maybe<Scalars["Decimal"]>;
    extensionDate?: Maybe<Scalars["DateTime"]>;
    fine?: Maybe<Scalars["Decimal"]>;
    foreignKey: Scalars["Int"];
    id: Scalars["Int"];
    isActive: Scalars["Boolean"];
    paidAmount?: Maybe<Scalars["Decimal"]>;
    payableId: Scalars["Int"];
    paymentAmount: Scalars["Decimal"];
    paymentDate?: Maybe<Scalars["DateTime"]>;
    status: StatusRecebivel;
    updatedAt: Scalars["DateTime"];
};

export type PayableUpdatesListRelationFilter = {
    every?: InputMaybe<PayableUpdatesWhereInput>;
    none?: InputMaybe<PayableUpdatesWhereInput>;
    some?: InputMaybe<PayableUpdatesWhereInput>;
};

export type PayableUpdatesMaxAggregate = {
    __typename?: "PayableUpdatesMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    deduction?: Maybe<Scalars["Decimal"]>;
    eventId?: Maybe<Scalars["Int"]>;
    expenses?: Maybe<Scalars["Decimal"]>;
    expiredAt?: Maybe<Scalars["DateTime"]>;
    extensionAmount?: Maybe<Scalars["Decimal"]>;
    extensionDate?: Maybe<Scalars["DateTime"]>;
    fine?: Maybe<Scalars["Decimal"]>;
    foreignKey?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    isActive?: Maybe<Scalars["Boolean"]>;
    paidAmount?: Maybe<Scalars["Decimal"]>;
    payableId?: Maybe<Scalars["Int"]>;
    paymentAmount?: Maybe<Scalars["Decimal"]>;
    paymentDate?: Maybe<Scalars["DateTime"]>;
    status?: Maybe<StatusRecebivel>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type PayableUpdatesMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    deduction?: InputMaybe<SortOrder>;
    eventId?: InputMaybe<SortOrder>;
    expenses?: InputMaybe<SortOrder>;
    expiredAt?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    extensionDate?: InputMaybe<SortOrder>;
    fine?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    isActive?: InputMaybe<SortOrder>;
    paidAmount?: InputMaybe<SortOrder>;
    payableId?: InputMaybe<SortOrder>;
    paymentAmount?: InputMaybe<SortOrder>;
    paymentDate?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PayableUpdatesMinAggregate = {
    __typename?: "PayableUpdatesMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    deduction?: Maybe<Scalars["Decimal"]>;
    eventId?: Maybe<Scalars["Int"]>;
    expenses?: Maybe<Scalars["Decimal"]>;
    expiredAt?: Maybe<Scalars["DateTime"]>;
    extensionAmount?: Maybe<Scalars["Decimal"]>;
    extensionDate?: Maybe<Scalars["DateTime"]>;
    fine?: Maybe<Scalars["Decimal"]>;
    foreignKey?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    isActive?: Maybe<Scalars["Boolean"]>;
    paidAmount?: Maybe<Scalars["Decimal"]>;
    payableId?: Maybe<Scalars["Int"]>;
    paymentAmount?: Maybe<Scalars["Decimal"]>;
    paymentDate?: Maybe<Scalars["DateTime"]>;
    status?: Maybe<StatusRecebivel>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type PayableUpdatesMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    deduction?: InputMaybe<SortOrder>;
    eventId?: InputMaybe<SortOrder>;
    expenses?: InputMaybe<SortOrder>;
    expiredAt?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    extensionDate?: InputMaybe<SortOrder>;
    fine?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    isActive?: InputMaybe<SortOrder>;
    paidAmount?: InputMaybe<SortOrder>;
    payableId?: InputMaybe<SortOrder>;
    paymentAmount?: InputMaybe<SortOrder>;
    paymentDate?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PayableUpdatesOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type PayableUpdatesOrderByWithAggregationInput = {
    _avg?: InputMaybe<PayableUpdatesAvgOrderByAggregateInput>;
    _count?: InputMaybe<PayableUpdatesCountOrderByAggregateInput>;
    _max?: InputMaybe<PayableUpdatesMaxOrderByAggregateInput>;
    _min?: InputMaybe<PayableUpdatesMinOrderByAggregateInput>;
    _sum?: InputMaybe<PayableUpdatesSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    deduction?: InputMaybe<SortOrder>;
    eventId?: InputMaybe<SortOrder>;
    expenses?: InputMaybe<SortOrder>;
    expiredAt?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    extensionDate?: InputMaybe<SortOrder>;
    fine?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    isActive?: InputMaybe<SortOrder>;
    paidAmount?: InputMaybe<SortOrder>;
    payableId?: InputMaybe<SortOrder>;
    paymentAmount?: InputMaybe<SortOrder>;
    paymentDate?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PayableUpdatesOrderByWithRelationInput = {
    createdAt?: InputMaybe<SortOrder>;
    deduction?: InputMaybe<SortOrder>;
    event?: InputMaybe<EventOrderByWithRelationInput>;
    eventId?: InputMaybe<SortOrder>;
    expenses?: InputMaybe<SortOrder>;
    expiredAt?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    extensionDate?: InputMaybe<SortOrder>;
    fine?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    isActive?: InputMaybe<SortOrder>;
    paidAmount?: InputMaybe<SortOrder>;
    payable?: InputMaybe<RecebivelOrderByWithRelationInput>;
    payableId?: InputMaybe<SortOrder>;
    paymentAmount?: InputMaybe<SortOrder>;
    paymentDate?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export enum PayableUpdatesScalarFieldEnum {
    CreatedAt = "createdAt",
    Deduction = "deduction",
    EventId = "eventId",
    Expenses = "expenses",
    ExpiredAt = "expiredAt",
    ExtensionAmount = "extensionAmount",
    ExtensionDate = "extensionDate",
    Fine = "fine",
    ForeignKey = "foreignKey",
    Id = "id",
    IsActive = "isActive",
    PaidAmount = "paidAmount",
    PayableId = "payableId",
    PaymentAmount = "paymentAmount",
    PaymentDate = "paymentDate",
    Status = "status",
    UpdatedAt = "updatedAt",
}

export type PayableUpdatesScalarWhereInput = {
    AND?: InputMaybe<Array<PayableUpdatesScalarWhereInput>>;
    NOT?: InputMaybe<Array<PayableUpdatesScalarWhereInput>>;
    OR?: InputMaybe<Array<PayableUpdatesScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    deduction?: InputMaybe<DecimalFilter>;
    eventId?: InputMaybe<IntFilter>;
    expenses?: InputMaybe<DecimalFilter>;
    expiredAt?: InputMaybe<DateTimeNullableFilter>;
    extensionAmount?: InputMaybe<DecimalNullableFilter>;
    extensionDate?: InputMaybe<DateTimeNullableFilter>;
    fine?: InputMaybe<DecimalNullableFilter>;
    foreignKey?: InputMaybe<IntFilter>;
    id?: InputMaybe<IntFilter>;
    isActive?: InputMaybe<BoolFilter>;
    paidAmount?: InputMaybe<DecimalNullableFilter>;
    payableId?: InputMaybe<IntFilter>;
    paymentAmount?: InputMaybe<DecimalFilter>;
    paymentDate?: InputMaybe<DateTimeNullableFilter>;
    status?: InputMaybe<EnumStatusRecebivelFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PayableUpdatesScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<PayableUpdatesScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<PayableUpdatesScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<PayableUpdatesScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    deduction?: InputMaybe<DecimalWithAggregatesFilter>;
    eventId?: InputMaybe<IntWithAggregatesFilter>;
    expenses?: InputMaybe<DecimalWithAggregatesFilter>;
    expiredAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
    extensionAmount?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    extensionDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
    fine?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    foreignKey?: InputMaybe<IntWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    isActive?: InputMaybe<BoolWithAggregatesFilter>;
    paidAmount?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    payableId?: InputMaybe<IntWithAggregatesFilter>;
    paymentAmount?: InputMaybe<DecimalWithAggregatesFilter>;
    paymentDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
    status?: InputMaybe<EnumStatusRecebivelWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PayableUpdatesSumAggregate = {
    __typename?: "PayableUpdatesSumAggregate";
    deduction?: Maybe<Scalars["Decimal"]>;
    eventId?: Maybe<Scalars["Int"]>;
    expenses?: Maybe<Scalars["Decimal"]>;
    extensionAmount?: Maybe<Scalars["Decimal"]>;
    fine?: Maybe<Scalars["Decimal"]>;
    foreignKey?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    paidAmount?: Maybe<Scalars["Decimal"]>;
    payableId?: Maybe<Scalars["Int"]>;
    paymentAmount?: Maybe<Scalars["Decimal"]>;
};

export type PayableUpdatesSumOrderByAggregateInput = {
    deduction?: InputMaybe<SortOrder>;
    eventId?: InputMaybe<SortOrder>;
    expenses?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    fine?: InputMaybe<SortOrder>;
    foreignKey?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    paidAmount?: InputMaybe<SortOrder>;
    payableId?: InputMaybe<SortOrder>;
    paymentAmount?: InputMaybe<SortOrder>;
};

export type PayableUpdatesUpdateInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    deduction?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    event?: InputMaybe<EventUpdateOneRequiredWithoutPayableUpdatesNestedInput>;
    expenses?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    expiredAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    fine?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    foreignKey?: InputMaybe<IntFieldUpdateOperationsInput>;
    isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
    paidAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    payable?: InputMaybe<RecebivelUpdateOneRequiredWithoutPayableUpdatesNestedInput>;
    paymentAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    paymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PayableUpdatesUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    deduction?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    expenses?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    expiredAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    fine?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    foreignKey?: InputMaybe<IntFieldUpdateOperationsInput>;
    isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
    paidAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    paymentAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    paymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PayableUpdatesUpdateManyWithWhereWithoutEventInput = {
    data: PayableUpdatesUpdateManyMutationInput;
    where: PayableUpdatesScalarWhereInput;
};

export type PayableUpdatesUpdateManyWithWhereWithoutPayableInput = {
    data: PayableUpdatesUpdateManyMutationInput;
    where: PayableUpdatesScalarWhereInput;
};

export type PayableUpdatesUpdateManyWithoutEventNestedInput = {
    connect?: InputMaybe<Array<PayableUpdatesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<PayableUpdatesCreateOrConnectWithoutEventInput>>;
    create?: InputMaybe<Array<PayableUpdatesCreateWithoutEventInput>>;
    createMany?: InputMaybe<PayableUpdatesCreateManyEventInputEnvelope>;
    delete?: InputMaybe<Array<PayableUpdatesWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<PayableUpdatesScalarWhereInput>>;
    disconnect?: InputMaybe<Array<PayableUpdatesWhereUniqueInput>>;
    set?: InputMaybe<Array<PayableUpdatesWhereUniqueInput>>;
    update?: InputMaybe<Array<PayableUpdatesUpdateWithWhereUniqueWithoutEventInput>>;
    updateMany?: InputMaybe<Array<PayableUpdatesUpdateManyWithWhereWithoutEventInput>>;
    upsert?: InputMaybe<Array<PayableUpdatesUpsertWithWhereUniqueWithoutEventInput>>;
};

export type PayableUpdatesUpdateManyWithoutPayableNestedInput = {
    connect?: InputMaybe<Array<PayableUpdatesWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<PayableUpdatesCreateOrConnectWithoutPayableInput>>;
    create?: InputMaybe<Array<PayableUpdatesCreateWithoutPayableInput>>;
    createMany?: InputMaybe<PayableUpdatesCreateManyPayableInputEnvelope>;
    delete?: InputMaybe<Array<PayableUpdatesWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<PayableUpdatesScalarWhereInput>>;
    disconnect?: InputMaybe<Array<PayableUpdatesWhereUniqueInput>>;
    set?: InputMaybe<Array<PayableUpdatesWhereUniqueInput>>;
    update?: InputMaybe<Array<PayableUpdatesUpdateWithWhereUniqueWithoutPayableInput>>;
    updateMany?: InputMaybe<Array<PayableUpdatesUpdateManyWithWhereWithoutPayableInput>>;
    upsert?: InputMaybe<Array<PayableUpdatesUpsertWithWhereUniqueWithoutPayableInput>>;
};

export type PayableUpdatesUpdateWithWhereUniqueWithoutEventInput = {
    data: PayableUpdatesUpdateWithoutEventInput;
    where: PayableUpdatesWhereUniqueInput;
};

export type PayableUpdatesUpdateWithWhereUniqueWithoutPayableInput = {
    data: PayableUpdatesUpdateWithoutPayableInput;
    where: PayableUpdatesWhereUniqueInput;
};

export type PayableUpdatesUpdateWithoutEventInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    deduction?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    expenses?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    expiredAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    fine?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    foreignKey?: InputMaybe<IntFieldUpdateOperationsInput>;
    isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
    paidAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    payable?: InputMaybe<RecebivelUpdateOneRequiredWithoutPayableUpdatesNestedInput>;
    paymentAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    paymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PayableUpdatesUpdateWithoutPayableInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    deduction?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    event?: InputMaybe<EventUpdateOneRequiredWithoutPayableUpdatesNestedInput>;
    expenses?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    expiredAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    fine?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    foreignKey?: InputMaybe<IntFieldUpdateOperationsInput>;
    isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
    paidAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    paymentAmount?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    paymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PayableUpdatesUpsertWithWhereUniqueWithoutEventInput = {
    create: PayableUpdatesCreateWithoutEventInput;
    update: PayableUpdatesUpdateWithoutEventInput;
    where: PayableUpdatesWhereUniqueInput;
};

export type PayableUpdatesUpsertWithWhereUniqueWithoutPayableInput = {
    create: PayableUpdatesCreateWithoutPayableInput;
    update: PayableUpdatesUpdateWithoutPayableInput;
    where: PayableUpdatesWhereUniqueInput;
};

export type PayableUpdatesWhereInput = {
    AND?: InputMaybe<Array<PayableUpdatesWhereInput>>;
    NOT?: InputMaybe<Array<PayableUpdatesWhereInput>>;
    OR?: InputMaybe<Array<PayableUpdatesWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    deduction?: InputMaybe<DecimalFilter>;
    event?: InputMaybe<EventRelationFilter>;
    eventId?: InputMaybe<IntFilter>;
    expenses?: InputMaybe<DecimalFilter>;
    expiredAt?: InputMaybe<DateTimeNullableFilter>;
    extensionAmount?: InputMaybe<DecimalNullableFilter>;
    extensionDate?: InputMaybe<DateTimeNullableFilter>;
    fine?: InputMaybe<DecimalNullableFilter>;
    foreignKey?: InputMaybe<IntFilter>;
    id?: InputMaybe<IntFilter>;
    isActive?: InputMaybe<BoolFilter>;
    paidAmount?: InputMaybe<DecimalNullableFilter>;
    payable?: InputMaybe<RecebivelRelationFilter>;
    payableId?: InputMaybe<IntFilter>;
    paymentAmount?: InputMaybe<DecimalFilter>;
    paymentDate?: InputMaybe<DateTimeNullableFilter>;
    status?: InputMaybe<EnumStatusRecebivelFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PayableUpdatesWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type PermissoesGrupos = {
    __typename?: "PermissoesGrupos";
    createdAt: Scalars["DateTime"];
    entidade: Entidade;
    grupo: GruposPermissao;
    grupoId: Scalars["Int"];
    inativo: Scalars["Boolean"];
    nivelPermissao: NivelAcesso;
    updatedAt: Scalars["DateTime"];
};

export type PermissoesGruposAvgAggregate = {
    __typename?: "PermissoesGruposAvgAggregate";
    grupoId?: Maybe<Scalars["Float"]>;
};

export type PermissoesGruposAvgOrderByAggregateInput = {
    grupoId?: InputMaybe<SortOrder>;
};

export type PermissoesGruposCountAggregate = {
    __typename?: "PermissoesGruposCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    entidade: Scalars["Int"];
    grupoId: Scalars["Int"];
    inativo: Scalars["Int"];
    nivelPermissao: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type PermissoesGruposCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    entidade?: InputMaybe<SortOrder>;
    grupoId?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nivelPermissao?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PermissoesGruposCreateInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    entidade: Entidade;
    grupo: GruposPermissaoCreateNestedOneWithoutPermissoesGrupoInput;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nivelPermissao: NivelAcesso;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PermissoesGruposCreateManyGrupoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    entidade: Entidade;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nivelPermissao: NivelAcesso;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PermissoesGruposCreateManyGrupoInputEnvelope = {
    data: Array<PermissoesGruposCreateManyGrupoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type PermissoesGruposCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    entidade: Entidade;
    grupoId: Scalars["Int"];
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nivelPermissao: NivelAcesso;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PermissoesGruposCreateNestedManyWithoutGrupoInput = {
    connect?: InputMaybe<Array<PermissoesGruposWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<PermissoesGruposCreateOrConnectWithoutGrupoInput>>;
    create?: InputMaybe<Array<PermissoesGruposCreateWithoutGrupoInput>>;
    createMany?: InputMaybe<PermissoesGruposCreateManyGrupoInputEnvelope>;
};

export type PermissoesGruposCreateOrConnectWithoutGrupoInput = {
    create: PermissoesGruposCreateWithoutGrupoInput;
    where: PermissoesGruposWhereUniqueInput;
};

export type PermissoesGruposCreateWithoutGrupoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    entidade: Entidade;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nivelPermissao: NivelAcesso;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PermissoesGruposGroupBy = {
    __typename?: "PermissoesGruposGroupBy";
    _avg?: Maybe<PermissoesGruposAvgAggregate>;
    _count?: Maybe<PermissoesGruposCountAggregate>;
    _max?: Maybe<PermissoesGruposMaxAggregate>;
    _min?: Maybe<PermissoesGruposMinAggregate>;
    _sum?: Maybe<PermissoesGruposSumAggregate>;
    createdAt: Scalars["DateTime"];
    entidade: Entidade;
    grupoId: Scalars["Int"];
    inativo: Scalars["Boolean"];
    nivelPermissao: NivelAcesso;
    updatedAt: Scalars["DateTime"];
};

export type PermissoesGruposGrupoIdEntidadeNivelPermissaoCompoundUniqueInput = {
    entidade: Entidade;
    grupoId: Scalars["Int"];
    nivelPermissao: NivelAcesso;
};

export type PermissoesGruposListRelationFilter = {
    every?: InputMaybe<PermissoesGruposWhereInput>;
    none?: InputMaybe<PermissoesGruposWhereInput>;
    some?: InputMaybe<PermissoesGruposWhereInput>;
};

export type PermissoesGruposMaxAggregate = {
    __typename?: "PermissoesGruposMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    entidade?: Maybe<Entidade>;
    grupoId?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    nivelPermissao?: Maybe<NivelAcesso>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type PermissoesGruposMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    entidade?: InputMaybe<SortOrder>;
    grupoId?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nivelPermissao?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PermissoesGruposMinAggregate = {
    __typename?: "PermissoesGruposMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    entidade?: Maybe<Entidade>;
    grupoId?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    nivelPermissao?: Maybe<NivelAcesso>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type PermissoesGruposMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    entidade?: InputMaybe<SortOrder>;
    grupoId?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nivelPermissao?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PermissoesGruposOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type PermissoesGruposOrderByWithAggregationInput = {
    _avg?: InputMaybe<PermissoesGruposAvgOrderByAggregateInput>;
    _count?: InputMaybe<PermissoesGruposCountOrderByAggregateInput>;
    _max?: InputMaybe<PermissoesGruposMaxOrderByAggregateInput>;
    _min?: InputMaybe<PermissoesGruposMinOrderByAggregateInput>;
    _sum?: InputMaybe<PermissoesGruposSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    entidade?: InputMaybe<SortOrder>;
    grupoId?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nivelPermissao?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PermissoesGruposOrderByWithRelationInput = {
    createdAt?: InputMaybe<SortOrder>;
    entidade?: InputMaybe<SortOrder>;
    grupo?: InputMaybe<GruposPermissaoOrderByWithRelationInput>;
    grupoId?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nivelPermissao?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export enum PermissoesGruposScalarFieldEnum {
    CreatedAt = "createdAt",
    Entidade = "entidade",
    GrupoId = "grupoId",
    Inativo = "inativo",
    NivelPermissao = "nivelPermissao",
    UpdatedAt = "updatedAt",
}

export type PermissoesGruposScalarWhereInput = {
    AND?: InputMaybe<Array<PermissoesGruposScalarWhereInput>>;
    NOT?: InputMaybe<Array<PermissoesGruposScalarWhereInput>>;
    OR?: InputMaybe<Array<PermissoesGruposScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    entidade?: InputMaybe<EnumEntidadeFilter>;
    grupoId?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    nivelPermissao?: InputMaybe<EnumNivelAcessoFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PermissoesGruposScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<PermissoesGruposScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<PermissoesGruposScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<PermissoesGruposScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    entidade?: InputMaybe<EnumEntidadeWithAggregatesFilter>;
    grupoId?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    nivelPermissao?: InputMaybe<EnumNivelAcessoWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PermissoesGruposSumAggregate = {
    __typename?: "PermissoesGruposSumAggregate";
    grupoId?: Maybe<Scalars["Int"]>;
};

export type PermissoesGruposSumOrderByAggregateInput = {
    grupoId?: InputMaybe<SortOrder>;
};

export type PermissoesGruposUpdateInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    entidade?: InputMaybe<EnumEntidadeFieldUpdateOperationsInput>;
    grupo?: InputMaybe<GruposPermissaoUpdateOneRequiredWithoutPermissoesGrupoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nivelPermissao?: InputMaybe<EnumNivelAcessoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PermissoesGruposUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    entidade?: InputMaybe<EnumEntidadeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nivelPermissao?: InputMaybe<EnumNivelAcessoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PermissoesGruposUpdateManyWithWhereWithoutGrupoInput = {
    data: PermissoesGruposUpdateManyMutationInput;
    where: PermissoesGruposScalarWhereInput;
};

export type PermissoesGruposUpdateManyWithoutGrupoNestedInput = {
    connect?: InputMaybe<Array<PermissoesGruposWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<PermissoesGruposCreateOrConnectWithoutGrupoInput>>;
    create?: InputMaybe<Array<PermissoesGruposCreateWithoutGrupoInput>>;
    createMany?: InputMaybe<PermissoesGruposCreateManyGrupoInputEnvelope>;
    delete?: InputMaybe<Array<PermissoesGruposWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<PermissoesGruposScalarWhereInput>>;
    disconnect?: InputMaybe<Array<PermissoesGruposWhereUniqueInput>>;
    set?: InputMaybe<Array<PermissoesGruposWhereUniqueInput>>;
    update?: InputMaybe<Array<PermissoesGruposUpdateWithWhereUniqueWithoutGrupoInput>>;
    updateMany?: InputMaybe<Array<PermissoesGruposUpdateManyWithWhereWithoutGrupoInput>>;
    upsert?: InputMaybe<Array<PermissoesGruposUpsertWithWhereUniqueWithoutGrupoInput>>;
};

export type PermissoesGruposUpdateWithWhereUniqueWithoutGrupoInput = {
    data: PermissoesGruposUpdateWithoutGrupoInput;
    where: PermissoesGruposWhereUniqueInput;
};

export type PermissoesGruposUpdateWithoutGrupoInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    entidade?: InputMaybe<EnumEntidadeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nivelPermissao?: InputMaybe<EnumNivelAcessoFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PermissoesGruposUpsertWithWhereUniqueWithoutGrupoInput = {
    create: PermissoesGruposCreateWithoutGrupoInput;
    update: PermissoesGruposUpdateWithoutGrupoInput;
    where: PermissoesGruposWhereUniqueInput;
};

export type PermissoesGruposWhereInput = {
    AND?: InputMaybe<Array<PermissoesGruposWhereInput>>;
    NOT?: InputMaybe<Array<PermissoesGruposWhereInput>>;
    OR?: InputMaybe<Array<PermissoesGruposWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    entidade?: InputMaybe<EnumEntidadeFilter>;
    grupo?: InputMaybe<GruposPermissaoRelationFilter>;
    grupoId?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    nivelPermissao?: InputMaybe<EnumNivelAcessoFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PermissoesGruposWhereUniqueInput = {
    grupoId_entidade_nivelPermissao?: InputMaybe<PermissoesGruposGrupoIdEntidadeNivelPermissaoCompoundUniqueInput>;
};

export enum PixKeyType {
    Cnpj = "CNPJ",
    Cpf = "CPF",
    Email = "EMAIL",
    Phone = "PHONE",
    RandomKey = "RANDOM_KEY",
}

export type PreCadastroCedente = {
    __typename?: "PreCadastroCedente";
    ativo: Scalars["Boolean"];
    cedente: Cedente;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    origem: TipoServico;
    referencia: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type PreCadastroCedenteAvgAggregate = {
    __typename?: "PreCadastroCedenteAvgAggregate";
    cedenteId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
};

export type PreCadastroCedenteAvgOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type PreCadastroCedenteCountAggregate = {
    __typename?: "PreCadastroCedenteCountAggregate";
    _all: Scalars["Int"];
    ativo: Scalars["Int"];
    cedenteId: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    origem: Scalars["Int"];
    referencia: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type PreCadastroCedenteCountOrderByAggregateInput = {
    ativo?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    referencia?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PreCadastroCedenteCreateInput = {
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cedente: CedenteCreateNestedOneWithoutPreCadastroCedenteInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    origem?: InputMaybe<TipoServico>;
    referencia: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PreCadastroCedenteCreateManyInput = {
    ativo?: InputMaybe<Scalars["Boolean"]>;
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    origem?: InputMaybe<TipoServico>;
    referencia: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PreCadastroCedenteCreateNestedOneWithoutCedenteInput = {
    connect?: InputMaybe<PreCadastroCedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<PreCadastroCedenteCreateOrConnectWithoutCedenteInput>;
    create?: InputMaybe<PreCadastroCedenteCreateWithoutCedenteInput>;
};

export type PreCadastroCedenteCreateOrConnectWithoutCedenteInput = {
    create: PreCadastroCedenteCreateWithoutCedenteInput;
    where: PreCadastroCedenteWhereUniqueInput;
};

export type PreCadastroCedenteCreateWithoutCedenteInput = {
    ativo?: InputMaybe<Scalars["Boolean"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    origem?: InputMaybe<TipoServico>;
    referencia: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PreCadastroCedenteGroupBy = {
    __typename?: "PreCadastroCedenteGroupBy";
    _avg?: Maybe<PreCadastroCedenteAvgAggregate>;
    _count?: Maybe<PreCadastroCedenteCountAggregate>;
    _max?: Maybe<PreCadastroCedenteMaxAggregate>;
    _min?: Maybe<PreCadastroCedenteMinAggregate>;
    _sum?: Maybe<PreCadastroCedenteSumAggregate>;
    ativo: Scalars["Boolean"];
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    origem: TipoServico;
    referencia: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type PreCadastroCedenteMaxAggregate = {
    __typename?: "PreCadastroCedenteMaxAggregate";
    ativo?: Maybe<Scalars["Boolean"]>;
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    origem?: Maybe<TipoServico>;
    referencia?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type PreCadastroCedenteMaxOrderByAggregateInput = {
    ativo?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    referencia?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PreCadastroCedenteMinAggregate = {
    __typename?: "PreCadastroCedenteMinAggregate";
    ativo?: Maybe<Scalars["Boolean"]>;
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    origem?: Maybe<TipoServico>;
    referencia?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type PreCadastroCedenteMinOrderByAggregateInput = {
    ativo?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    referencia?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PreCadastroCedenteOrderByWithAggregationInput = {
    _avg?: InputMaybe<PreCadastroCedenteAvgOrderByAggregateInput>;
    _count?: InputMaybe<PreCadastroCedenteCountOrderByAggregateInput>;
    _max?: InputMaybe<PreCadastroCedenteMaxOrderByAggregateInput>;
    _min?: InputMaybe<PreCadastroCedenteMinOrderByAggregateInput>;
    _sum?: InputMaybe<PreCadastroCedenteSumOrderByAggregateInput>;
    ativo?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    referencia?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PreCadastroCedenteOrderByWithRelationInput = {
    ativo?: InputMaybe<SortOrder>;
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    referencia?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type PreCadastroCedenteRelationFilter = {
    is?: InputMaybe<PreCadastroCedenteWhereInput>;
    isNot?: InputMaybe<PreCadastroCedenteWhereInput>;
};

export enum PreCadastroCedenteScalarFieldEnum {
    Ativo = "ativo",
    CedenteId = "cedenteId",
    CreatedAt = "createdAt",
    Id = "id",
    Origem = "origem",
    Referencia = "referencia",
    UpdatedAt = "updatedAt",
}

export type PreCadastroCedenteScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<PreCadastroCedenteScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<PreCadastroCedenteScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<PreCadastroCedenteScalarWhereWithAggregatesInput>>;
    ativo?: InputMaybe<BoolWithAggregatesFilter>;
    cedenteId?: InputMaybe<IntWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    origem?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    referencia?: InputMaybe<StringWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PreCadastroCedenteSumAggregate = {
    __typename?: "PreCadastroCedenteSumAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
};

export type PreCadastroCedenteSumOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type PreCadastroCedenteUpdateInput = {
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutPreCadastroCedenteNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    referencia?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PreCadastroCedenteUpdateManyMutationInput = {
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    referencia?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PreCadastroCedenteUpdateOneWithoutCedenteNestedInput = {
    connect?: InputMaybe<PreCadastroCedenteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<PreCadastroCedenteCreateOrConnectWithoutCedenteInput>;
    create?: InputMaybe<PreCadastroCedenteCreateWithoutCedenteInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<PreCadastroCedenteUpdateWithoutCedenteInput>;
    upsert?: InputMaybe<PreCadastroCedenteUpsertWithoutCedenteInput>;
};

export type PreCadastroCedenteUpdateWithoutCedenteInput = {
    ativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    referencia?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PreCadastroCedenteUpsertWithoutCedenteInput = {
    create: PreCadastroCedenteCreateWithoutCedenteInput;
    update: PreCadastroCedenteUpdateWithoutCedenteInput;
};

export type PreCadastroCedenteWhereInput = {
    AND?: InputMaybe<Array<PreCadastroCedenteWhereInput>>;
    NOT?: InputMaybe<Array<PreCadastroCedenteWhereInput>>;
    OR?: InputMaybe<Array<PreCadastroCedenteWhereInput>>;
    ativo?: InputMaybe<BoolFilter>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    origem?: InputMaybe<EnumTipoServicoFilter>;
    referencia?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PreCadastroCedenteWhereUniqueInput = {
    cedenteId?: InputMaybe<Scalars["Int"]>;
    id?: InputMaybe<Scalars["Int"]>;
    referencia?: InputMaybe<Scalars["String"]>;
};

export type Query = {
    __typename?: "Query";
    aggregateAcceptedTerms: AggregateAcceptedTerms;
    aggregateArquivo: AggregateArquivo;
    aggregateArquivoAudit: AggregateArquivoAudit;
    aggregateAssignorBankData: AggregateAssignorBankData;
    aggregateAssignorCRM: AggregateAssignorCrm;
    aggregateAssignorDocumentType: AggregateAssignorDocumentType;
    aggregateAssignorStatusByMinibank: AggregateAssignorStatusByMinibank;
    aggregateBanks: AggregateBanks;
    aggregateCTe: AggregateCTe;
    aggregateCedente: AggregateCedente;
    aggregateCedenteAlteracao: AggregateCedenteAlteracao;
    aggregateCedenteAnexos: AggregateCedenteAnexos;
    aggregateCedenteAudit: AggregateCedenteAudit;
    aggregateCedenteCadastro: AggregateCedenteCadastro;
    aggregateCedenteCamposAlteracao: AggregateCedenteCamposAlteracao;
    aggregateCedenteCamposCadastro: AggregateCedenteCamposCadastro;
    aggregateCedenteContato: AggregateCedenteContato;
    aggregateCedentesInfo: AggregateCedentesInfo;
    aggregateCnaeClasses: AggregateCnaeClasses;
    aggregateCnaeDivisoes: AggregateCnaeDivisoes;
    aggregateCnaeGrupos: AggregateCnaeGrupos;
    aggregateCnaeSecoes: AggregateCnaeSecoes;
    aggregateCnaeSubClasses: AggregateCnaeSubClasses;
    aggregateComercialPartnerAudit: AggregateComercialPartnerAudit;
    aggregateComercialPartnerCommission: AggregateComercialPartnerCommission;
    aggregateContaBancaria: AggregateContaBancaria;
    aggregateContato: AggregateContato;
    aggregateContatoAudit: AggregateContatoAudit;
    aggregateDebenturista: AggregateDebenturista;
    aggregateDebenturistaAudit: AggregateDebenturistaAudit;
    aggregateEmpresa: AggregateEmpresa;
    aggregateEmpresaAudit: AggregateEmpresaAudit;
    aggregateEndereco: AggregateEndereco;
    aggregateEnderecoAlteracao: AggregateEnderecoAlteracao;
    aggregateEnderecoAudit: AggregateEnderecoAudit;
    aggregateEnderecoCadastro: AggregateEnderecoCadastro;
    aggregateEnderecoCamposAlteracao: AggregateEnderecoCamposAlteracao;
    aggregateEnderecoCamposCadastro: AggregateEnderecoCamposCadastro;
    aggregateEvent: AggregateEvent;
    aggregateExtratoIntegracao: AggregateExtratoIntegracao;
    aggregateGrupoNotificacao: AggregateGrupoNotificacao;
    aggregateGruposPermissao: AggregateGruposPermissao;
    aggregateMinibanco: AggregateMinibanco;
    aggregateMinibancoAudit: AggregateMinibancoAudit;
    aggregateMinibancoContato: AggregateMinibancoContato;
    aggregateMinibancoExtrato: AggregateMinibancoExtrato;
    aggregateMinibankIntegration: AggregateMinibankIntegration;
    aggregateNFe: AggregateNFe;
    aggregateNFeParcela: AggregateNFeParcela;
    aggregateNivelPermissao: AggregateNivelPermissao;
    aggregateOccupation: AggregateOccupation;
    aggregateOccupationAudit: AggregateOccupationAudit;
    aggregateOperacao: AggregateOperacao;
    aggregateOperacaoAnexos: AggregateOperacaoAnexos;
    aggregateOperacaoAudit: AggregateOperacaoAudit;
    aggregateOperacaoChecklist: AggregateOperacaoChecklist;
    aggregateOperacaoIntegracao: AggregateOperacaoIntegracao;
    aggregateOperacaoModalidade: AggregateOperacaoModalidade;
    aggregateOperacaoRascunho: AggregateOperacaoRascunho;
    aggregateOperacaoStatus: AggregateOperacaoStatus;
    aggregateOperacaoSugerida: AggregateOperacaoSugerida;
    aggregateOperacaoTipo: AggregateOperacaoTipo;
    aggregateOperacoesRascunhoView: AggregateOperacoesRascunhoView;
    aggregateOperationCommision: AggregateOperationCommision;
    aggregateOperationFees: AggregateOperationFees;
    aggregateOperationUpdates: AggregateOperationUpdates;
    aggregateOperationsInfo: AggregateOperationsInfo;
    aggregateParceiroComercial: AggregateParceiroComercial;
    aggregatePayableUpdates: AggregatePayableUpdates;
    aggregatePermissoesGrupos: AggregatePermissoesGrupos;
    aggregatePreCadastroCedente: AggregatePreCadastroCedente;
    aggregateRecebiveisInfo: AggregateRecebiveisInfo;
    aggregateRecebiveisToAnticipate: AggregateRecebiveisToAnticipate;
    aggregateRecebivel: AggregateRecebivel;
    aggregateRecebivelAudit: AggregateRecebivelAudit;
    aggregateRecebivelIntegracao: AggregateRecebivelIntegracao;
    aggregateRecebivelOperado: AggregateRecebivelOperado;
    aggregateRecebivelPagarContas: AggregateRecebivelPagarContas;
    aggregateRepresentative: AggregateRepresentative;
    aggregateResponsavelCedente: AggregateResponsavelCedente;
    aggregateSacado: AggregateSacado;
    aggregateSacadoAudit: AggregateSacadoAudit;
    aggregateSacadoContato: AggregateSacadoContato;
    aggregateStatusOperacionalAudit: AggregateStatusOperacionalAudit;
    aggregateStatusOperacionalCedenteMinibanco: AggregateStatusOperacionalCedenteMinibanco;
    aggregateTakeRateMinibanco: AggregateTakeRateMinibanco;
    aggregateTaxasMinibanco: AggregateTaxasMinibanco;
    aggregateTermsAndConditions: AggregateTermsAndConditions;
    aggregateTipoMovimentacao: AggregateTipoMovimentacao;
    aggregateUsuario: AggregateUsuario;
    aggregateUsuarioAudit: AggregateUsuarioAudit;
    aggregateUsuarioCadastro: AggregateUsuarioCadastro;
    aggregateUsuarioCadastroCampos: AggregateUsuarioCadastroCampos;
    arquivo?: Maybe<Arquivo>;
    arquivoAudit?: Maybe<ArquivoAudit>;
    arquivoAudits: Array<ArquivoAudit>;
    arquivos: Array<Arquivo>;
    assignorCRM?: Maybe<AssignorCrm>;
    assignorCRMS: Array<AssignorCrm>;
    assignorDocumentType?: Maybe<AssignorDocumentType>;
    assignorDocumentTypes: Array<AssignorDocumentType>;
    assignorStatusByMinibank?: Maybe<AssignorStatusByMinibank>;
    assignorStatusByMinibanks: Array<AssignorStatusByMinibank>;
    cTe?: Maybe<CTe>;
    cTes: Array<CTe>;
    cedente?: Maybe<Cedente>;
    cedenteAlteracao?: Maybe<CedenteAlteracao>;
    cedenteAlteracaos: Array<CedenteAlteracao>;
    cedenteAudit?: Maybe<CedenteAudit>;
    cedenteAudits: Array<CedenteAudit>;
    cedenteCadastro?: Maybe<CedenteCadastro>;
    cedenteCadastros: Array<CedenteCadastro>;
    cedenteCamposAlteracao?: Maybe<CedenteCamposAlteracao>;
    cedenteCamposAlteracaos: Array<CedenteCamposAlteracao>;
    cedenteCamposCadastro?: Maybe<CedenteCamposCadastro>;
    cedenteCamposCadastros: Array<CedenteCamposCadastro>;
    cedenteContato?: Maybe<CedenteContato>;
    cedenteContatoes: Array<CedenteContato>;
    cedentes: Array<Cedente>;
    cedentesInfo?: Maybe<CedentesInfo>;
    cedentesInfos: Array<CedentesInfo>;
    comercialPartnerAudit?: Maybe<ComercialPartnerAudit>;
    comercialPartnerAudits: Array<ComercialPartnerAudit>;
    comercialPartnerCommission?: Maybe<ComercialPartnerCommission>;
    comercialPartnerCommissions: Array<ComercialPartnerCommission>;
    contaBancaria?: Maybe<ContaBancaria>;
    contaBancarias: Array<ContaBancaria>;
    contato?: Maybe<Contato>;
    contatoAudit?: Maybe<ContatoAudit>;
    contatoAudits: Array<ContatoAudit>;
    contatoes: Array<Contato>;
    debenturista?: Maybe<Debenturista>;
    debenturistaAudit?: Maybe<DebenturistaAudit>;
    debenturistaAudits: Array<DebenturistaAudit>;
    debenturistas: Array<Debenturista>;
    empresa?: Maybe<Empresa>;
    empresaAudit?: Maybe<EmpresaAudit>;
    empresaAudits: Array<EmpresaAudit>;
    empresas: Array<Empresa>;
    endereco?: Maybe<Endereco>;
    enderecoAlteracao?: Maybe<EnderecoAlteracao>;
    enderecoAlteracaos: Array<EnderecoAlteracao>;
    enderecoAudit?: Maybe<EnderecoAudit>;
    enderecoAudits: Array<EnderecoAudit>;
    enderecoCadastro?: Maybe<EnderecoCadastro>;
    enderecoCadastros: Array<EnderecoCadastro>;
    enderecoCamposAlteracao?: Maybe<EnderecoCamposAlteracao>;
    enderecoCamposAlteracaos: Array<EnderecoCamposAlteracao>;
    enderecoCamposCadastro?: Maybe<EnderecoCamposCadastro>;
    enderecoCamposCadastros: Array<EnderecoCamposCadastro>;
    enderecos: Array<Endereco>;
    event?: Maybe<Event>;
    events: Array<Event>;
    extratoIntegracao?: Maybe<ExtratoIntegracao>;
    extratoIntegracaos: Array<ExtratoIntegracao>;
    findFirstAcceptedTerms?: Maybe<AcceptedTerms>;
    findFirstArquivo?: Maybe<Arquivo>;
    findFirstArquivoAudit?: Maybe<ArquivoAudit>;
    findFirstAssignorBankData?: Maybe<AssignorBankData>;
    findFirstAssignorCRM?: Maybe<AssignorCrm>;
    findFirstAssignorDocumentType?: Maybe<AssignorDocumentType>;
    findFirstAssignorStatusByMinibank?: Maybe<AssignorStatusByMinibank>;
    findFirstBanks?: Maybe<Banks>;
    findFirstCTe?: Maybe<CTe>;
    findFirstCedente?: Maybe<Cedente>;
    findFirstCedenteAlteracao?: Maybe<CedenteAlteracao>;
    findFirstCedenteAnexos?: Maybe<CedenteAnexos>;
    findFirstCedenteAudit?: Maybe<CedenteAudit>;
    findFirstCedenteCadastro?: Maybe<CedenteCadastro>;
    findFirstCedenteCamposAlteracao?: Maybe<CedenteCamposAlteracao>;
    findFirstCedenteCamposCadastro?: Maybe<CedenteCamposCadastro>;
    findFirstCedenteContato?: Maybe<CedenteContato>;
    findFirstCedentesInfo?: Maybe<CedentesInfo>;
    findFirstCnaeClasses?: Maybe<CnaeClasses>;
    findFirstCnaeDivisoes?: Maybe<CnaeDivisoes>;
    findFirstCnaeGrupos?: Maybe<CnaeGrupos>;
    findFirstCnaeSecoes?: Maybe<CnaeSecoes>;
    findFirstCnaeSubClasses?: Maybe<CnaeSubClasses>;
    findFirstComercialPartnerAudit?: Maybe<ComercialPartnerAudit>;
    findFirstComercialPartnerCommission?: Maybe<ComercialPartnerCommission>;
    findFirstContaBancaria?: Maybe<ContaBancaria>;
    findFirstContato?: Maybe<Contato>;
    findFirstContatoAudit?: Maybe<ContatoAudit>;
    findFirstDebenturista?: Maybe<Debenturista>;
    findFirstDebenturistaAudit?: Maybe<DebenturistaAudit>;
    findFirstEmpresa?: Maybe<Empresa>;
    findFirstEmpresaAudit?: Maybe<EmpresaAudit>;
    findFirstEndereco?: Maybe<Endereco>;
    findFirstEnderecoAlteracao?: Maybe<EnderecoAlteracao>;
    findFirstEnderecoAudit?: Maybe<EnderecoAudit>;
    findFirstEnderecoCadastro?: Maybe<EnderecoCadastro>;
    findFirstEnderecoCamposAlteracao?: Maybe<EnderecoCamposAlteracao>;
    findFirstEnderecoCamposCadastro?: Maybe<EnderecoCamposCadastro>;
    findFirstEvent?: Maybe<Event>;
    findFirstExtratoIntegracao?: Maybe<ExtratoIntegracao>;
    findFirstGrupoNotificacao?: Maybe<GrupoNotificacao>;
    findFirstGruposPermissao?: Maybe<GruposPermissao>;
    findFirstMinibanco?: Maybe<Minibanco>;
    findFirstMinibancoAudit?: Maybe<MinibancoAudit>;
    findFirstMinibancoContato?: Maybe<MinibancoContato>;
    findFirstMinibancoExtrato?: Maybe<MinibancoExtrato>;
    findFirstMinibankIntegration?: Maybe<MinibankIntegration>;
    findFirstNFe?: Maybe<NFe>;
    findFirstNFeParcela?: Maybe<NFeParcela>;
    findFirstNivelPermissao?: Maybe<NivelPermissao>;
    findFirstOccupation?: Maybe<Occupation>;
    findFirstOccupationAudit?: Maybe<OccupationAudit>;
    findFirstOperacao?: Maybe<Operacao>;
    findFirstOperacaoAnexos?: Maybe<OperacaoAnexos>;
    findFirstOperacaoAudit?: Maybe<OperacaoAudit>;
    findFirstOperacaoChecklist?: Maybe<OperacaoChecklist>;
    findFirstOperacaoIntegracao?: Maybe<OperacaoIntegracao>;
    findFirstOperacaoModalidade?: Maybe<OperacaoModalidade>;
    findFirstOperacaoRascunho?: Maybe<OperacaoRascunho>;
    findFirstOperacaoStatus?: Maybe<OperacaoStatus>;
    findFirstOperacaoSugerida?: Maybe<OperacaoSugerida>;
    findFirstOperacaoTipo?: Maybe<OperacaoTipo>;
    findFirstOperacoesRascunhoView?: Maybe<OperacoesRascunhoView>;
    findFirstOperationCommision?: Maybe<OperationCommision>;
    findFirstOperationFees?: Maybe<OperationFees>;
    findFirstOperationUpdates?: Maybe<OperationUpdates>;
    findFirstOperationsInfo?: Maybe<OperationsInfo>;
    findFirstParceiroComercial?: Maybe<ParceiroComercial>;
    findFirstPayableUpdates?: Maybe<PayableUpdates>;
    findFirstPermissoesGrupos?: Maybe<PermissoesGrupos>;
    findFirstPreCadastroCedente?: Maybe<PreCadastroCedente>;
    findFirstRecebiveisInfo?: Maybe<RecebiveisInfo>;
    findFirstRecebiveisToAnticipate?: Maybe<RecebiveisToAnticipate>;
    findFirstRecebivel?: Maybe<Recebivel>;
    findFirstRecebivelAudit?: Maybe<RecebivelAudit>;
    findFirstRecebivelIntegracao?: Maybe<RecebivelIntegracao>;
    findFirstRecebivelOperado?: Maybe<RecebivelOperado>;
    findFirstRecebivelPagarContas?: Maybe<RecebivelPagarContas>;
    findFirstRepresentative?: Maybe<Representative>;
    findFirstResponsavelCedente?: Maybe<ResponsavelCedente>;
    findFirstSacado?: Maybe<Sacado>;
    findFirstSacadoAudit?: Maybe<SacadoAudit>;
    findFirstSacadoContato?: Maybe<SacadoContato>;
    findFirstStatusOperacionalAudit?: Maybe<StatusOperacionalAudit>;
    findFirstStatusOperacionalCedenteMinibanco?: Maybe<StatusOperacionalCedenteMinibanco>;
    findFirstTakeRateMinibanco?: Maybe<TakeRateMinibanco>;
    findFirstTaxasMinibanco?: Maybe<TaxasMinibanco>;
    findFirstTermsAndConditions?: Maybe<TermsAndConditions>;
    findFirstTipoMovimentacao?: Maybe<TipoMovimentacao>;
    findFirstUsuario?: Maybe<Usuario>;
    findFirstUsuarioAudit?: Maybe<UsuarioAudit>;
    findFirstUsuarioCadastro?: Maybe<UsuarioCadastro>;
    findFirstUsuarioCadastroCampos?: Maybe<UsuarioCadastroCampos>;
    findManyAcceptedTerms: Array<AcceptedTerms>;
    findManyAssignorBankData: Array<AssignorBankData>;
    findManyBanks: Array<Banks>;
    findManyCedenteAnexos: Array<CedenteAnexos>;
    findManyCnaeClasses: Array<CnaeClasses>;
    findManyCnaeDivisoes: Array<CnaeDivisoes>;
    findManyCnaeGrupos: Array<CnaeGrupos>;
    findManyCnaeSecoes: Array<CnaeSecoes>;
    findManyCnaeSubClasses: Array<CnaeSubClasses>;
    findManyOperacaoAnexos: Array<OperacaoAnexos>;
    findManyOperationFees: Array<OperationFees>;
    findManyOperationUpdates: Array<OperationUpdates>;
    findManyPayableUpdates: Array<PayableUpdates>;
    findManyPermissoesGrupos: Array<PermissoesGrupos>;
    findManyRecebivelPagarContas: Array<RecebivelPagarContas>;
    findManyTermsAndConditions: Array<TermsAndConditions>;
    findManyUsuarioCadastroCampos: Array<UsuarioCadastroCampos>;
    findOneOperation: IFindOneOperationDto;
    findUniqueAcceptedTerms?: Maybe<AcceptedTerms>;
    findUniqueAssignorBankData?: Maybe<AssignorBankData>;
    findUniqueBanks?: Maybe<Banks>;
    findUniqueCedenteAnexos?: Maybe<CedenteAnexos>;
    findUniqueCnaeClasses?: Maybe<CnaeClasses>;
    findUniqueCnaeDivisoes?: Maybe<CnaeDivisoes>;
    findUniqueCnaeGrupos?: Maybe<CnaeGrupos>;
    findUniqueCnaeSecoes?: Maybe<CnaeSecoes>;
    findUniqueCnaeSubClasses?: Maybe<CnaeSubClasses>;
    findUniqueOperacaoAnexos?: Maybe<OperacaoAnexos>;
    findUniqueOperationFees?: Maybe<OperationFees>;
    findUniqueOperationUpdates?: Maybe<OperationUpdates>;
    findUniquePayableUpdates?: Maybe<PayableUpdates>;
    findUniquePermissoesGrupos?: Maybe<PermissoesGrupos>;
    findUniqueRecebivelPagarContas?: Maybe<RecebivelPagarContas>;
    findUniqueTermsAndConditions?: Maybe<TermsAndConditions>;
    findUniqueUsuarioCadastroCampos?: Maybe<UsuarioCadastroCampos>;
    groupByAcceptedTerms: Array<AcceptedTermsGroupBy>;
    groupByArquivo: Array<ArquivoGroupBy>;
    groupByArquivoAudit: Array<ArquivoAuditGroupBy>;
    groupByAssignorBankData: Array<AssignorBankDataGroupBy>;
    groupByAssignorCRM: Array<AssignorCrmGroupBy>;
    groupByAssignorDocumentType: Array<AssignorDocumentTypeGroupBy>;
    groupByAssignorStatusByMinibank: Array<AssignorStatusByMinibankGroupBy>;
    groupByBanks: Array<BanksGroupBy>;
    groupByCTe: Array<CTeGroupBy>;
    groupByCedente: Array<CedenteGroupBy>;
    groupByCedenteAlteracao: Array<CedenteAlteracaoGroupBy>;
    groupByCedenteAnexos: Array<CedenteAnexosGroupBy>;
    groupByCedenteAudit: Array<CedenteAuditGroupBy>;
    groupByCedenteCadastro: Array<CedenteCadastroGroupBy>;
    groupByCedenteCamposAlteracao: Array<CedenteCamposAlteracaoGroupBy>;
    groupByCedenteCamposCadastro: Array<CedenteCamposCadastroGroupBy>;
    groupByCedenteContato: Array<CedenteContatoGroupBy>;
    groupByCedentesInfo: Array<CedentesInfoGroupBy>;
    groupByCnaeClasses: Array<CnaeClassesGroupBy>;
    groupByCnaeDivisoes: Array<CnaeDivisoesGroupBy>;
    groupByCnaeGrupos: Array<CnaeGruposGroupBy>;
    groupByCnaeSecoes: Array<CnaeSecoesGroupBy>;
    groupByCnaeSubClasses: Array<CnaeSubClassesGroupBy>;
    groupByComercialPartnerAudit: Array<ComercialPartnerAuditGroupBy>;
    groupByComercialPartnerCommission: Array<ComercialPartnerCommissionGroupBy>;
    groupByContaBancaria: Array<ContaBancariaGroupBy>;
    groupByContato: Array<ContatoGroupBy>;
    groupByContatoAudit: Array<ContatoAuditGroupBy>;
    groupByDebenturista: Array<DebenturistaGroupBy>;
    groupByDebenturistaAudit: Array<DebenturistaAuditGroupBy>;
    groupByEmpresa: Array<EmpresaGroupBy>;
    groupByEmpresaAudit: Array<EmpresaAuditGroupBy>;
    groupByEndereco: Array<EnderecoGroupBy>;
    groupByEnderecoAlteracao: Array<EnderecoAlteracaoGroupBy>;
    groupByEnderecoAudit: Array<EnderecoAuditGroupBy>;
    groupByEnderecoCadastro: Array<EnderecoCadastroGroupBy>;
    groupByEnderecoCamposAlteracao: Array<EnderecoCamposAlteracaoGroupBy>;
    groupByEnderecoCamposCadastro: Array<EnderecoCamposCadastroGroupBy>;
    groupByEvent: Array<EventGroupBy>;
    groupByExtratoIntegracao: Array<ExtratoIntegracaoGroupBy>;
    groupByGrupoNotificacao: Array<GrupoNotificacaoGroupBy>;
    groupByGruposPermissao: Array<GruposPermissaoGroupBy>;
    groupByMinibanco: Array<MinibancoGroupBy>;
    groupByMinibancoAudit: Array<MinibancoAuditGroupBy>;
    groupByMinibancoContato: Array<MinibancoContatoGroupBy>;
    groupByMinibancoExtrato: Array<MinibancoExtratoGroupBy>;
    groupByMinibankIntegration: Array<MinibankIntegrationGroupBy>;
    groupByNFe: Array<NFeGroupBy>;
    groupByNFeParcela: Array<NFeParcelaGroupBy>;
    groupByNivelPermissao: Array<NivelPermissaoGroupBy>;
    groupByOccupation: Array<OccupationGroupBy>;
    groupByOccupationAudit: Array<OccupationAuditGroupBy>;
    groupByOperacao: Array<OperacaoGroupBy>;
    groupByOperacaoAnexos: Array<OperacaoAnexosGroupBy>;
    groupByOperacaoAudit: Array<OperacaoAuditGroupBy>;
    groupByOperacaoChecklist: Array<OperacaoChecklistGroupBy>;
    groupByOperacaoIntegracao: Array<OperacaoIntegracaoGroupBy>;
    groupByOperacaoModalidade: Array<OperacaoModalidadeGroupBy>;
    groupByOperacaoRascunho: Array<OperacaoRascunhoGroupBy>;
    groupByOperacaoStatus: Array<OperacaoStatusGroupBy>;
    groupByOperacaoSugerida: Array<OperacaoSugeridaGroupBy>;
    groupByOperacaoTipo: Array<OperacaoTipoGroupBy>;
    groupByOperacoesRascunhoView: Array<OperacoesRascunhoViewGroupBy>;
    groupByOperationCommision: Array<OperationCommisionGroupBy>;
    groupByOperationFees: Array<OperationFeesGroupBy>;
    groupByOperationUpdates: Array<OperationUpdatesGroupBy>;
    groupByOperationsInfo: Array<OperationsInfoGroupBy>;
    groupByParceiroComercial: Array<ParceiroComercialGroupBy>;
    groupByPayableUpdates: Array<PayableUpdatesGroupBy>;
    groupByPermissoesGrupos: Array<PermissoesGruposGroupBy>;
    groupByPreCadastroCedente: Array<PreCadastroCedenteGroupBy>;
    groupByRecebiveisInfo: Array<RecebiveisInfoGroupBy>;
    groupByRecebiveisToAnticipate: Array<RecebiveisToAnticipateGroupBy>;
    groupByRecebivel: Array<RecebivelGroupBy>;
    groupByRecebivelAudit: Array<RecebivelAuditGroupBy>;
    groupByRecebivelIntegracao: Array<RecebivelIntegracaoGroupBy>;
    groupByRecebivelOperado: Array<RecebivelOperadoGroupBy>;
    groupByRecebivelPagarContas: Array<RecebivelPagarContasGroupBy>;
    groupByRepresentative: Array<RepresentativeGroupBy>;
    groupByResponsavelCedente: Array<ResponsavelCedenteGroupBy>;
    groupBySacado: Array<SacadoGroupBy>;
    groupBySacadoAudit: Array<SacadoAuditGroupBy>;
    groupBySacadoContato: Array<SacadoContatoGroupBy>;
    groupByStatusOperacionalAudit: Array<StatusOperacionalAuditGroupBy>;
    groupByStatusOperacionalCedenteMinibanco: Array<StatusOperacionalCedenteMinibancoGroupBy>;
    groupByTakeRateMinibanco: Array<TakeRateMinibancoGroupBy>;
    groupByTaxasMinibanco: Array<TaxasMinibancoGroupBy>;
    groupByTermsAndConditions: Array<TermsAndConditionsGroupBy>;
    groupByTipoMovimentacao: Array<TipoMovimentacaoGroupBy>;
    groupByUsuario: Array<UsuarioGroupBy>;
    groupByUsuarioAudit: Array<UsuarioAuditGroupBy>;
    groupByUsuarioCadastro: Array<UsuarioCadastroGroupBy>;
    groupByUsuarioCadastroCampos: Array<UsuarioCadastroCamposGroupBy>;
    grupoNotificacao?: Maybe<GrupoNotificacao>;
    grupoNotificacaos: Array<GrupoNotificacao>;
    gruposPermissao?: Maybe<GruposPermissao>;
    gruposPermissaos: Array<GruposPermissao>;
    minibanco?: Maybe<Minibanco>;
    minibancoAudit?: Maybe<MinibancoAudit>;
    minibancoAudits: Array<MinibancoAudit>;
    minibancoContato?: Maybe<MinibancoContato>;
    minibancoContatoes: Array<MinibancoContato>;
    minibancoExtrato?: Maybe<MinibancoExtrato>;
    minibancoExtratoes: Array<MinibancoExtrato>;
    minibancos: Array<Minibanco>;
    minibankIntegration?: Maybe<MinibankIntegration>;
    minibankIntegrations: Array<MinibankIntegration>;
    nFe?: Maybe<NFe>;
    nFeParcela?: Maybe<NFeParcela>;
    nFeParcelas: Array<NFeParcela>;
    nFes: Array<NFe>;
    nivelPermissao?: Maybe<NivelPermissao>;
    nivelPermissaos: Array<NivelPermissao>;
    occupation?: Maybe<Occupation>;
    occupationAudit?: Maybe<OccupationAudit>;
    occupationAudits: Array<OccupationAudit>;
    occupations: Array<Occupation>;
    operacao?: Maybe<Operacao>;
    operacaoAudit?: Maybe<OperacaoAudit>;
    operacaoAudits: Array<OperacaoAudit>;
    operacaoChecklist?: Maybe<OperacaoChecklist>;
    operacaoChecklists: Array<OperacaoChecklist>;
    operacaoIntegracao?: Maybe<OperacaoIntegracao>;
    operacaoIntegracaos: Array<OperacaoIntegracao>;
    operacaoModalidade?: Maybe<OperacaoModalidade>;
    operacaoModalidades: Array<OperacaoModalidade>;
    operacaoRascunho?: Maybe<OperacaoRascunho>;
    operacaoRascunhos: Array<OperacaoRascunho>;
    operacaoStatus?: Maybe<OperacaoStatus>;
    operacaoStatuses: Array<OperacaoStatus>;
    operacaoSugerida?: Maybe<OperacaoSugerida>;
    operacaoSugeridas: Array<OperacaoSugerida>;
    operacaoTipo?: Maybe<OperacaoTipo>;
    operacaoTipos: Array<OperacaoTipo>;
    operacaos: Array<Operacao>;
    operacoesRascunhoView?: Maybe<OperacoesRascunhoView>;
    operacoesRascunhoViews: Array<OperacoesRascunhoView>;
    operationCommision?: Maybe<OperationCommision>;
    operationCommisions: Array<OperationCommision>;
    operationsInfo?: Maybe<OperationsInfo>;
    operationsInfos: Array<OperationsInfo>;
    parceiroComercial?: Maybe<ParceiroComercial>;
    parceiroComercials: Array<ParceiroComercial>;
    preCadastroCedente?: Maybe<PreCadastroCedente>;
    preCadastroCedentes: Array<PreCadastroCedente>;
    recebiveisInfo?: Maybe<RecebiveisInfo>;
    recebiveisInfos: Array<RecebiveisInfo>;
    recebiveisToAnticipate?: Maybe<RecebiveisToAnticipate>;
    recebiveisToAnticipates: Array<RecebiveisToAnticipate>;
    recebivel?: Maybe<Recebivel>;
    recebivelAudit?: Maybe<RecebivelAudit>;
    recebivelAudits: Array<RecebivelAudit>;
    recebivelIntegracao?: Maybe<RecebivelIntegracao>;
    recebivelIntegracaos: Array<RecebivelIntegracao>;
    recebivelOperado?: Maybe<RecebivelOperado>;
    recebivelOperados: Array<RecebivelOperado>;
    recebivels: Array<Recebivel>;
    representative?: Maybe<Representative>;
    representatives: Array<Representative>;
    responsavelCedente?: Maybe<ResponsavelCedente>;
    responsavelCedentes: Array<ResponsavelCedente>;
    sacado?: Maybe<Sacado>;
    sacadoAudit?: Maybe<SacadoAudit>;
    sacadoAudits: Array<SacadoAudit>;
    sacadoContato?: Maybe<SacadoContato>;
    sacadoContatoes: Array<SacadoContato>;
    sacados: Array<Sacado>;
    statusOperacionalAudit?: Maybe<StatusOperacionalAudit>;
    statusOperacionalAudits: Array<StatusOperacionalAudit>;
    statusOperacionalCedenteMinibanco?: Maybe<StatusOperacionalCedenteMinibanco>;
    statusOperacionalCedenteMinibancos: Array<StatusOperacionalCedenteMinibanco>;
    takeRateMinibanco?: Maybe<TakeRateMinibanco>;
    takeRateMinibancos: Array<TakeRateMinibanco>;
    taxasMinibanco?: Maybe<TaxasMinibanco>;
    taxasMinibancos: Array<TaxasMinibanco>;
    tipoMovimentacao?: Maybe<TipoMovimentacao>;
    tipoMovimentacaos: Array<TipoMovimentacao>;
    usuario?: Maybe<Usuario>;
    usuarioAudit?: Maybe<UsuarioAudit>;
    usuarioAudits: Array<UsuarioAudit>;
    usuarioCadastro?: Maybe<UsuarioCadastro>;
    usuarioCadastros: Array<UsuarioCadastro>;
    usuarios: Array<Usuario>;
};

export type QueryAggregateAcceptedTermsArgs = {
    cursor?: InputMaybe<AcceptedTermsWhereUniqueInput>;
    orderBy?: InputMaybe<Array<AcceptedTermsOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AcceptedTermsWhereInput>;
};

export type QueryAggregateArquivoArgs = {
    cursor?: InputMaybe<ArquivoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ArquivoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoWhereInput>;
};

export type QueryAggregateArquivoAuditArgs = {
    cursor?: InputMaybe<ArquivoAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ArquivoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoAuditWhereInput>;
};

export type QueryAggregateAssignorBankDataArgs = {
    cursor?: InputMaybe<AssignorBankDataWhereUniqueInput>;
    orderBy?: InputMaybe<Array<AssignorBankDataOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorBankDataWhereInput>;
};

export type QueryAggregateAssignorCrmArgs = {
    cursor?: InputMaybe<AssignorCrmWhereUniqueInput>;
    orderBy?: InputMaybe<Array<AssignorCrmOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorCrmWhereInput>;
};

export type QueryAggregateAssignorDocumentTypeArgs = {
    cursor?: InputMaybe<AssignorDocumentTypeWhereUniqueInput>;
    orderBy?: InputMaybe<Array<AssignorDocumentTypeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorDocumentTypeWhereInput>;
};

export type QueryAggregateAssignorStatusByMinibankArgs = {
    cursor?: InputMaybe<AssignorStatusByMinibankWhereUniqueInput>;
    orderBy?: InputMaybe<Array<AssignorStatusByMinibankOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorStatusByMinibankWhereInput>;
};

export type QueryAggregateBanksArgs = {
    cursor?: InputMaybe<BanksWhereUniqueInput>;
    orderBy?: InputMaybe<Array<BanksOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<BanksWhereInput>;
};

export type QueryAggregateCTeArgs = {
    cursor?: InputMaybe<CTeWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CTeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CTeWhereInput>;
};

export type QueryAggregateCedenteArgs = {
    cursor?: InputMaybe<CedenteWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteWhereInput>;
};

export type QueryAggregateCedenteAlteracaoArgs = {
    cursor?: InputMaybe<CedenteAlteracaoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CedenteAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAlteracaoWhereInput>;
};

export type QueryAggregateCedenteAnexosArgs = {
    cursor?: InputMaybe<CedenteAnexosWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CedenteAnexosOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAnexosWhereInput>;
};

export type QueryAggregateCedenteAuditArgs = {
    cursor?: InputMaybe<CedenteAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CedenteAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAuditWhereInput>;
};

export type QueryAggregateCedenteCadastroArgs = {
    cursor?: InputMaybe<CedenteCadastroWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CedenteCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCadastroWhereInput>;
};

export type QueryAggregateCedenteCamposAlteracaoArgs = {
    cursor?: InputMaybe<CedenteCamposAlteracaoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CedenteCamposAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCamposAlteracaoWhereInput>;
};

export type QueryAggregateCedenteCamposCadastroArgs = {
    cursor?: InputMaybe<CedenteCamposCadastroWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CedenteCamposCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCamposCadastroWhereInput>;
};

export type QueryAggregateCedenteContatoArgs = {
    cursor?: InputMaybe<CedenteContatoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CedenteContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteContatoWhereInput>;
};

export type QueryAggregateCedentesInfoArgs = {
    cursor?: InputMaybe<CedentesInfoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CedentesInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedentesInfoWhereInput>;
};

export type QueryAggregateCnaeClassesArgs = {
    cursor?: InputMaybe<CnaeClassesWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CnaeClassesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeClassesWhereInput>;
};

export type QueryAggregateCnaeDivisoesArgs = {
    cursor?: InputMaybe<CnaeDivisoesWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CnaeDivisoesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeDivisoesWhereInput>;
};

export type QueryAggregateCnaeGruposArgs = {
    cursor?: InputMaybe<CnaeGruposWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CnaeGruposOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeGruposWhereInput>;
};

export type QueryAggregateCnaeSecoesArgs = {
    cursor?: InputMaybe<CnaeSecoesWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CnaeSecoesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeSecoesWhereInput>;
};

export type QueryAggregateCnaeSubClassesArgs = {
    cursor?: InputMaybe<CnaeSubClassesWhereUniqueInput>;
    orderBy?: InputMaybe<Array<CnaeSubClassesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeSubClassesWhereInput>;
};

export type QueryAggregateComercialPartnerAuditArgs = {
    cursor?: InputMaybe<ComercialPartnerAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ComercialPartnerAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ComercialPartnerAuditWhereInput>;
};

export type QueryAggregateComercialPartnerCommissionArgs = {
    cursor?: InputMaybe<ComercialPartnerCommissionWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ComercialPartnerCommissionOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ComercialPartnerCommissionWhereInput>;
};

export type QueryAggregateContaBancariaArgs = {
    cursor?: InputMaybe<ContaBancariaWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ContaBancariaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContaBancariaWhereInput>;
};

export type QueryAggregateContatoArgs = {
    cursor?: InputMaybe<ContatoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContatoWhereInput>;
};

export type QueryAggregateContatoAuditArgs = {
    cursor?: InputMaybe<ContatoAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ContatoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContatoAuditWhereInput>;
};

export type QueryAggregateDebenturistaArgs = {
    cursor?: InputMaybe<DebenturistaWhereUniqueInput>;
    orderBy?: InputMaybe<Array<DebenturistaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<DebenturistaWhereInput>;
};

export type QueryAggregateDebenturistaAuditArgs = {
    cursor?: InputMaybe<DebenturistaAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<DebenturistaAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<DebenturistaAuditWhereInput>;
};

export type QueryAggregateEmpresaArgs = {
    cursor?: InputMaybe<EmpresaWhereUniqueInput>;
    orderBy?: InputMaybe<Array<EmpresaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EmpresaWhereInput>;
};

export type QueryAggregateEmpresaAuditArgs = {
    cursor?: InputMaybe<EmpresaAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<EmpresaAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EmpresaAuditWhereInput>;
};

export type QueryAggregateEnderecoArgs = {
    cursor?: InputMaybe<EnderecoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<EnderecoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoWhereInput>;
};

export type QueryAggregateEnderecoAlteracaoArgs = {
    cursor?: InputMaybe<EnderecoAlteracaoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<EnderecoAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoAlteracaoWhereInput>;
};

export type QueryAggregateEnderecoAuditArgs = {
    cursor?: InputMaybe<EnderecoAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<EnderecoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoAuditWhereInput>;
};

export type QueryAggregateEnderecoCadastroArgs = {
    cursor?: InputMaybe<EnderecoCadastroWhereUniqueInput>;
    orderBy?: InputMaybe<Array<EnderecoCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCadastroWhereInput>;
};

export type QueryAggregateEnderecoCamposAlteracaoArgs = {
    cursor?: InputMaybe<EnderecoCamposAlteracaoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<EnderecoCamposAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCamposAlteracaoWhereInput>;
};

export type QueryAggregateEnderecoCamposCadastroArgs = {
    cursor?: InputMaybe<EnderecoCamposCadastroWhereUniqueInput>;
    orderBy?: InputMaybe<Array<EnderecoCamposCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCamposCadastroWhereInput>;
};

export type QueryAggregateEventArgs = {
    cursor?: InputMaybe<EventWhereUniqueInput>;
    orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EventWhereInput>;
};

export type QueryAggregateExtratoIntegracaoArgs = {
    cursor?: InputMaybe<ExtratoIntegracaoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ExtratoIntegracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ExtratoIntegracaoWhereInput>;
};

export type QueryAggregateGrupoNotificacaoArgs = {
    cursor?: InputMaybe<GrupoNotificacaoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<GrupoNotificacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GrupoNotificacaoWhereInput>;
};

export type QueryAggregateGruposPermissaoArgs = {
    cursor?: InputMaybe<GruposPermissaoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<GruposPermissaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GruposPermissaoWhereInput>;
};

export type QueryAggregateMinibancoArgs = {
    cursor?: InputMaybe<MinibancoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<MinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoWhereInput>;
};

export type QueryAggregateMinibancoAuditArgs = {
    cursor?: InputMaybe<MinibancoAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<MinibancoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoAuditWhereInput>;
};

export type QueryAggregateMinibancoContatoArgs = {
    cursor?: InputMaybe<MinibancoContatoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<MinibancoContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoContatoWhereInput>;
};

export type QueryAggregateMinibancoExtratoArgs = {
    cursor?: InputMaybe<MinibancoExtratoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<MinibancoExtratoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoExtratoWhereInput>;
};

export type QueryAggregateMinibankIntegrationArgs = {
    cursor?: InputMaybe<MinibankIntegrationWhereUniqueInput>;
    orderBy?: InputMaybe<Array<MinibankIntegrationOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibankIntegrationWhereInput>;
};

export type QueryAggregateNFeArgs = {
    cursor?: InputMaybe<NFeWhereUniqueInput>;
    orderBy?: InputMaybe<Array<NFeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NFeWhereInput>;
};

export type QueryAggregateNFeParcelaArgs = {
    cursor?: InputMaybe<NFeParcelaWhereUniqueInput>;
    orderBy?: InputMaybe<Array<NFeParcelaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NFeParcelaWhereInput>;
};

export type QueryAggregateNivelPermissaoArgs = {
    cursor?: InputMaybe<NivelPermissaoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<NivelPermissaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NivelPermissaoWhereInput>;
};

export type QueryAggregateOccupationArgs = {
    cursor?: InputMaybe<OccupationWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OccupationOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OccupationWhereInput>;
};

export type QueryAggregateOccupationAuditArgs = {
    cursor?: InputMaybe<OccupationAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OccupationAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OccupationAuditWhereInput>;
};

export type QueryAggregateOperacaoArgs = {
    cursor?: InputMaybe<OperacaoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoWhereInput>;
};

export type QueryAggregateOperacaoAnexosArgs = {
    cursor?: InputMaybe<OperacaoAnexosWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperacaoAnexosOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoAnexosWhereInput>;
};

export type QueryAggregateOperacaoAuditArgs = {
    cursor?: InputMaybe<OperacaoAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperacaoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoAuditWhereInput>;
};

export type QueryAggregateOperacaoChecklistArgs = {
    cursor?: InputMaybe<OperacaoChecklistWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperacaoChecklistOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoChecklistWhereInput>;
};

export type QueryAggregateOperacaoIntegracaoArgs = {
    cursor?: InputMaybe<OperacaoIntegracaoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperacaoIntegracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoIntegracaoWhereInput>;
};

export type QueryAggregateOperacaoModalidadeArgs = {
    cursor?: InputMaybe<OperacaoModalidadeWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperacaoModalidadeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoModalidadeWhereInput>;
};

export type QueryAggregateOperacaoRascunhoArgs = {
    cursor?: InputMaybe<OperacaoRascunhoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperacaoRascunhoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoRascunhoWhereInput>;
};

export type QueryAggregateOperacaoStatusArgs = {
    cursor?: InputMaybe<OperacaoStatusWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperacaoStatusOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoStatusWhereInput>;
};

export type QueryAggregateOperacaoSugeridaArgs = {
    cursor?: InputMaybe<OperacaoSugeridaWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperacaoSugeridaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoSugeridaWhereInput>;
};

export type QueryAggregateOperacaoTipoArgs = {
    cursor?: InputMaybe<OperacaoTipoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperacaoTipoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoTipoWhereInput>;
};

export type QueryAggregateOperacoesRascunhoViewArgs = {
    cursor?: InputMaybe<OperacoesRascunhoViewWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperacoesRascunhoViewOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacoesRascunhoViewWhereInput>;
};

export type QueryAggregateOperationCommisionArgs = {
    cursor?: InputMaybe<OperationCommisionWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperationCommisionOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationCommisionWhereInput>;
};

export type QueryAggregateOperationFeesArgs = {
    cursor?: InputMaybe<OperationFeesWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperationFeesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationFeesWhereInput>;
};

export type QueryAggregateOperationUpdatesArgs = {
    cursor?: InputMaybe<OperationUpdatesWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperationUpdatesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationUpdatesWhereInput>;
};

export type QueryAggregateOperationsInfoArgs = {
    cursor?: InputMaybe<OperationsInfoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<OperationsInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationsInfoWhereInput>;
};

export type QueryAggregateParceiroComercialArgs = {
    cursor?: InputMaybe<ParceiroComercialWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ParceiroComercialOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ParceiroComercialWhereInput>;
};

export type QueryAggregatePayableUpdatesArgs = {
    cursor?: InputMaybe<PayableUpdatesWhereUniqueInput>;
    orderBy?: InputMaybe<Array<PayableUpdatesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PayableUpdatesWhereInput>;
};

export type QueryAggregatePermissoesGruposArgs = {
    cursor?: InputMaybe<PermissoesGruposWhereUniqueInput>;
    orderBy?: InputMaybe<Array<PermissoesGruposOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PermissoesGruposWhereInput>;
};

export type QueryAggregatePreCadastroCedenteArgs = {
    cursor?: InputMaybe<PreCadastroCedenteWhereUniqueInput>;
    orderBy?: InputMaybe<Array<PreCadastroCedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PreCadastroCedenteWhereInput>;
};

export type QueryAggregateRecebiveisInfoArgs = {
    cursor?: InputMaybe<RecebiveisInfoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<RecebiveisInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisInfoWhereInput>;
};

export type QueryAggregateRecebiveisToAnticipateArgs = {
    cursor?: InputMaybe<RecebiveisToAnticipateWhereUniqueInput>;
    orderBy?: InputMaybe<Array<RecebiveisToAnticipateOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisToAnticipateWhereInput>;
};

export type QueryAggregateRecebivelArgs = {
    cursor?: InputMaybe<RecebivelWhereUniqueInput>;
    orderBy?: InputMaybe<Array<RecebivelOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelWhereInput>;
};

export type QueryAggregateRecebivelAuditArgs = {
    cursor?: InputMaybe<RecebivelAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<RecebivelAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelAuditWhereInput>;
};

export type QueryAggregateRecebivelIntegracaoArgs = {
    cursor?: InputMaybe<RecebivelIntegracaoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<RecebivelIntegracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelIntegracaoWhereInput>;
};

export type QueryAggregateRecebivelOperadoArgs = {
    cursor?: InputMaybe<RecebivelOperadoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<RecebivelOperadoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelOperadoWhereInput>;
};

export type QueryAggregateRecebivelPagarContasArgs = {
    cursor?: InputMaybe<RecebivelPagarContasWhereUniqueInput>;
    orderBy?: InputMaybe<Array<RecebivelPagarContasOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelPagarContasWhereInput>;
};

export type QueryAggregateRepresentativeArgs = {
    cursor?: InputMaybe<RepresentativeWhereUniqueInput>;
    orderBy?: InputMaybe<Array<RepresentativeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RepresentativeWhereInput>;
};

export type QueryAggregateResponsavelCedenteArgs = {
    cursor?: InputMaybe<ResponsavelCedenteWhereUniqueInput>;
    orderBy?: InputMaybe<Array<ResponsavelCedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ResponsavelCedenteWhereInput>;
};

export type QueryAggregateSacadoArgs = {
    cursor?: InputMaybe<SacadoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<SacadoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoWhereInput>;
};

export type QueryAggregateSacadoAuditArgs = {
    cursor?: InputMaybe<SacadoAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<SacadoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoAuditWhereInput>;
};

export type QueryAggregateSacadoContatoArgs = {
    cursor?: InputMaybe<SacadoContatoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<SacadoContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoContatoWhereInput>;
};

export type QueryAggregateStatusOperacionalAuditArgs = {
    cursor?: InputMaybe<StatusOperacionalAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<StatusOperacionalAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<StatusOperacionalAuditWhereInput>;
};

export type QueryAggregateStatusOperacionalCedenteMinibancoArgs = {
    cursor?: InputMaybe<StatusOperacionalCedenteMinibancoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<StatusOperacionalCedenteMinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<StatusOperacionalCedenteMinibancoWhereInput>;
};

export type QueryAggregateTakeRateMinibancoArgs = {
    cursor?: InputMaybe<TakeRateMinibancoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<TakeRateMinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TakeRateMinibancoWhereInput>;
};

export type QueryAggregateTaxasMinibancoArgs = {
    cursor?: InputMaybe<TaxasMinibancoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<TaxasMinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TaxasMinibancoWhereInput>;
};

export type QueryAggregateTermsAndConditionsArgs = {
    cursor?: InputMaybe<TermsAndConditionsWhereUniqueInput>;
    orderBy?: InputMaybe<Array<TermsAndConditionsOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TermsAndConditionsWhereInput>;
};

export type QueryAggregateTipoMovimentacaoArgs = {
    cursor?: InputMaybe<TipoMovimentacaoWhereUniqueInput>;
    orderBy?: InputMaybe<Array<TipoMovimentacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TipoMovimentacaoWhereInput>;
};

export type QueryAggregateUsuarioArgs = {
    cursor?: InputMaybe<UsuarioWhereUniqueInput>;
    orderBy?: InputMaybe<Array<UsuarioOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioWhereInput>;
};

export type QueryAggregateUsuarioAuditArgs = {
    cursor?: InputMaybe<UsuarioAuditWhereUniqueInput>;
    orderBy?: InputMaybe<Array<UsuarioAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioAuditWhereInput>;
};

export type QueryAggregateUsuarioCadastroArgs = {
    cursor?: InputMaybe<UsuarioCadastroWhereUniqueInput>;
    orderBy?: InputMaybe<Array<UsuarioCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioCadastroWhereInput>;
};

export type QueryAggregateUsuarioCadastroCamposArgs = {
    cursor?: InputMaybe<UsuarioCadastroCamposWhereUniqueInput>;
    orderBy?: InputMaybe<Array<UsuarioCadastroCamposOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioCadastroCamposWhereInput>;
};

export type QueryArquivoArgs = {
    where: ArquivoWhereUniqueInput;
};

export type QueryArquivoAuditArgs = {
    where: ArquivoAuditWhereUniqueInput;
};

export type QueryArquivoAuditsArgs = {
    cursor?: InputMaybe<ArquivoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<ArquivoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ArquivoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoAuditWhereInput>;
};

export type QueryArquivosArgs = {
    cursor?: InputMaybe<ArquivoWhereUniqueInput>;
    distinct?: InputMaybe<Array<ArquivoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ArquivoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoWhereInput>;
};

export type QueryAssignorCrmArgs = {
    where: AssignorCrmWhereUniqueInput;
};

export type QueryAssignorCrmsArgs = {
    cursor?: InputMaybe<AssignorCrmWhereUniqueInput>;
    distinct?: InputMaybe<Array<AssignorCrmScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AssignorCrmOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorCrmWhereInput>;
};

export type QueryAssignorDocumentTypeArgs = {
    where: AssignorDocumentTypeWhereUniqueInput;
};

export type QueryAssignorDocumentTypesArgs = {
    cursor?: InputMaybe<AssignorDocumentTypeWhereUniqueInput>;
    distinct?: InputMaybe<Array<AssignorDocumentTypeScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AssignorDocumentTypeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorDocumentTypeWhereInput>;
};

export type QueryAssignorStatusByMinibankArgs = {
    where: AssignorStatusByMinibankWhereUniqueInput;
};

export type QueryAssignorStatusByMinibanksArgs = {
    cursor?: InputMaybe<AssignorStatusByMinibankWhereUniqueInput>;
    distinct?: InputMaybe<Array<AssignorStatusByMinibankScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AssignorStatusByMinibankOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorStatusByMinibankWhereInput>;
};

export type QueryCTeArgs = {
    where: CTeWhereUniqueInput;
};

export type QueryCTesArgs = {
    cursor?: InputMaybe<CTeWhereUniqueInput>;
    distinct?: InputMaybe<Array<CTeScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CTeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CTeWhereInput>;
};

export type QueryCedenteArgs = {
    where: CedenteWhereUniqueInput;
};

export type QueryCedenteAlteracaoArgs = {
    where: CedenteAlteracaoWhereUniqueInput;
};

export type QueryCedenteAlteracaosArgs = {
    cursor?: InputMaybe<CedenteAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAlteracaoWhereInput>;
};

export type QueryCedenteAuditArgs = {
    where: CedenteAuditWhereUniqueInput;
};

export type QueryCedenteAuditsArgs = {
    cursor?: InputMaybe<CedenteAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAuditWhereInput>;
};

export type QueryCedenteCadastroArgs = {
    where: CedenteCadastroWhereUniqueInput;
};

export type QueryCedenteCadastrosArgs = {
    cursor?: InputMaybe<CedenteCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCadastroWhereInput>;
};

export type QueryCedenteCamposAlteracaoArgs = {
    where: CedenteCamposAlteracaoWhereUniqueInput;
};

export type QueryCedenteCamposAlteracaosArgs = {
    cursor?: InputMaybe<CedenteCamposAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteCamposAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteCamposAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCamposAlteracaoWhereInput>;
};

export type QueryCedenteCamposCadastroArgs = {
    where: CedenteCamposCadastroWhereUniqueInput;
};

export type QueryCedenteCamposCadastrosArgs = {
    cursor?: InputMaybe<CedenteCamposCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteCamposCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteCamposCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCamposCadastroWhereInput>;
};

export type QueryCedenteContatoArgs = {
    where: CedenteContatoWhereUniqueInput;
};

export type QueryCedenteContatoesArgs = {
    cursor?: InputMaybe<CedenteContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteContatoWhereInput>;
};

export type QueryCedentesArgs = {
    cursor?: InputMaybe<CedenteWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteWhereInput>;
};

export type QueryCedentesInfoArgs = {
    where: CedentesInfoWhereUniqueInput;
};

export type QueryCedentesInfosArgs = {
    cursor?: InputMaybe<CedentesInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedentesInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedentesInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedentesInfoWhereInput>;
};

export type QueryComercialPartnerAuditArgs = {
    where: ComercialPartnerAuditWhereUniqueInput;
};

export type QueryComercialPartnerAuditsArgs = {
    cursor?: InputMaybe<ComercialPartnerAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<ComercialPartnerAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ComercialPartnerAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ComercialPartnerAuditWhereInput>;
};

export type QueryComercialPartnerCommissionArgs = {
    where: ComercialPartnerCommissionWhereUniqueInput;
};

export type QueryComercialPartnerCommissionsArgs = {
    cursor?: InputMaybe<ComercialPartnerCommissionWhereUniqueInput>;
    distinct?: InputMaybe<Array<ComercialPartnerCommissionScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ComercialPartnerCommissionOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ComercialPartnerCommissionWhereInput>;
};

export type QueryContaBancariaArgs = {
    where: ContaBancariaWhereUniqueInput;
};

export type QueryContaBancariasArgs = {
    cursor?: InputMaybe<ContaBancariaWhereUniqueInput>;
    distinct?: InputMaybe<Array<ContaBancariaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ContaBancariaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContaBancariaWhereInput>;
};

export type QueryContatoArgs = {
    where: ContatoWhereUniqueInput;
};

export type QueryContatoAuditArgs = {
    where: ContatoAuditWhereUniqueInput;
};

export type QueryContatoAuditsArgs = {
    cursor?: InputMaybe<ContatoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<ContatoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ContatoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContatoAuditWhereInput>;
};

export type QueryContatoesArgs = {
    cursor?: InputMaybe<ContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<ContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContatoWhereInput>;
};

export type QueryDebenturistaArgs = {
    where: DebenturistaWhereUniqueInput;
};

export type QueryDebenturistaAuditArgs = {
    where: DebenturistaAuditWhereUniqueInput;
};

export type QueryDebenturistaAuditsArgs = {
    cursor?: InputMaybe<DebenturistaAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<DebenturistaAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<DebenturistaAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<DebenturistaAuditWhereInput>;
};

export type QueryDebenturistasArgs = {
    cursor?: InputMaybe<DebenturistaWhereUniqueInput>;
    distinct?: InputMaybe<Array<DebenturistaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<DebenturistaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<DebenturistaWhereInput>;
};

export type QueryEmpresaArgs = {
    where: EmpresaWhereUniqueInput;
};

export type QueryEmpresaAuditArgs = {
    where: EmpresaAuditWhereUniqueInput;
};

export type QueryEmpresaAuditsArgs = {
    cursor?: InputMaybe<EmpresaAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<EmpresaAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EmpresaAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EmpresaAuditWhereInput>;
};

export type QueryEmpresasArgs = {
    cursor?: InputMaybe<EmpresaWhereUniqueInput>;
    distinct?: InputMaybe<Array<EmpresaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EmpresaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EmpresaWhereInput>;
};

export type QueryEnderecoArgs = {
    where: EnderecoWhereUniqueInput;
};

export type QueryEnderecoAlteracaoArgs = {
    where: EnderecoAlteracaoWhereUniqueInput;
};

export type QueryEnderecoAlteracaosArgs = {
    cursor?: InputMaybe<EnderecoAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoAlteracaoWhereInput>;
};

export type QueryEnderecoAuditArgs = {
    where: EnderecoAuditWhereUniqueInput;
};

export type QueryEnderecoAuditsArgs = {
    cursor?: InputMaybe<EnderecoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoAuditWhereInput>;
};

export type QueryEnderecoCadastroArgs = {
    where: EnderecoCadastroWhereUniqueInput;
};

export type QueryEnderecoCadastrosArgs = {
    cursor?: InputMaybe<EnderecoCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCadastroWhereInput>;
};

export type QueryEnderecoCamposAlteracaoArgs = {
    where: EnderecoCamposAlteracaoWhereUniqueInput;
};

export type QueryEnderecoCamposAlteracaosArgs = {
    cursor?: InputMaybe<EnderecoCamposAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoCamposAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoCamposAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCamposAlteracaoWhereInput>;
};

export type QueryEnderecoCamposCadastroArgs = {
    where: EnderecoCamposCadastroWhereUniqueInput;
};

export type QueryEnderecoCamposCadastrosArgs = {
    cursor?: InputMaybe<EnderecoCamposCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoCamposCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoCamposCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCamposCadastroWhereInput>;
};

export type QueryEnderecosArgs = {
    cursor?: InputMaybe<EnderecoWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoWhereInput>;
};

export type QueryEventArgs = {
    where: EventWhereUniqueInput;
};

export type QueryEventsArgs = {
    cursor?: InputMaybe<EventWhereUniqueInput>;
    distinct?: InputMaybe<Array<EventScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EventWhereInput>;
};

export type QueryExtratoIntegracaoArgs = {
    where: ExtratoIntegracaoWhereUniqueInput;
};

export type QueryExtratoIntegracaosArgs = {
    cursor?: InputMaybe<ExtratoIntegracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<ExtratoIntegracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ExtratoIntegracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ExtratoIntegracaoWhereInput>;
};

export type QueryFindFirstAcceptedTermsArgs = {
    cursor?: InputMaybe<AcceptedTermsWhereUniqueInput>;
    distinct?: InputMaybe<Array<AcceptedTermsScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AcceptedTermsOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AcceptedTermsWhereInput>;
};

export type QueryFindFirstArquivoArgs = {
    cursor?: InputMaybe<ArquivoWhereUniqueInput>;
    distinct?: InputMaybe<Array<ArquivoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ArquivoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoWhereInput>;
};

export type QueryFindFirstArquivoAuditArgs = {
    cursor?: InputMaybe<ArquivoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<ArquivoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ArquivoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoAuditWhereInput>;
};

export type QueryFindFirstAssignorBankDataArgs = {
    cursor?: InputMaybe<AssignorBankDataWhereUniqueInput>;
    distinct?: InputMaybe<Array<AssignorBankDataScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AssignorBankDataOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorBankDataWhereInput>;
};

export type QueryFindFirstAssignorCrmArgs = {
    cursor?: InputMaybe<AssignorCrmWhereUniqueInput>;
    distinct?: InputMaybe<Array<AssignorCrmScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AssignorCrmOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorCrmWhereInput>;
};

export type QueryFindFirstAssignorDocumentTypeArgs = {
    cursor?: InputMaybe<AssignorDocumentTypeWhereUniqueInput>;
    distinct?: InputMaybe<Array<AssignorDocumentTypeScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AssignorDocumentTypeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorDocumentTypeWhereInput>;
};

export type QueryFindFirstAssignorStatusByMinibankArgs = {
    cursor?: InputMaybe<AssignorStatusByMinibankWhereUniqueInput>;
    distinct?: InputMaybe<Array<AssignorStatusByMinibankScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AssignorStatusByMinibankOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorStatusByMinibankWhereInput>;
};

export type QueryFindFirstBanksArgs = {
    cursor?: InputMaybe<BanksWhereUniqueInput>;
    distinct?: InputMaybe<Array<BanksScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<BanksOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<BanksWhereInput>;
};

export type QueryFindFirstCTeArgs = {
    cursor?: InputMaybe<CTeWhereUniqueInput>;
    distinct?: InputMaybe<Array<CTeScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CTeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CTeWhereInput>;
};

export type QueryFindFirstCedenteArgs = {
    cursor?: InputMaybe<CedenteWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteWhereInput>;
};

export type QueryFindFirstCedenteAlteracaoArgs = {
    cursor?: InputMaybe<CedenteAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAlteracaoWhereInput>;
};

export type QueryFindFirstCedenteAnexosArgs = {
    cursor?: InputMaybe<CedenteAnexosWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAnexosScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAnexosOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAnexosWhereInput>;
};

export type QueryFindFirstCedenteAuditArgs = {
    cursor?: InputMaybe<CedenteAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAuditWhereInput>;
};

export type QueryFindFirstCedenteCadastroArgs = {
    cursor?: InputMaybe<CedenteCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCadastroWhereInput>;
};

export type QueryFindFirstCedenteCamposAlteracaoArgs = {
    cursor?: InputMaybe<CedenteCamposAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteCamposAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteCamposAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCamposAlteracaoWhereInput>;
};

export type QueryFindFirstCedenteCamposCadastroArgs = {
    cursor?: InputMaybe<CedenteCamposCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteCamposCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteCamposCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCamposCadastroWhereInput>;
};

export type QueryFindFirstCedenteContatoArgs = {
    cursor?: InputMaybe<CedenteContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteContatoWhereInput>;
};

export type QueryFindFirstCedentesInfoArgs = {
    cursor?: InputMaybe<CedentesInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedentesInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedentesInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedentesInfoWhereInput>;
};

export type QueryFindFirstCnaeClassesArgs = {
    cursor?: InputMaybe<CnaeClassesWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeClassesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeClassesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeClassesWhereInput>;
};

export type QueryFindFirstCnaeDivisoesArgs = {
    cursor?: InputMaybe<CnaeDivisoesWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeDivisoesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeDivisoesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeDivisoesWhereInput>;
};

export type QueryFindFirstCnaeGruposArgs = {
    cursor?: InputMaybe<CnaeGruposWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeGruposScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeGruposOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeGruposWhereInput>;
};

export type QueryFindFirstCnaeSecoesArgs = {
    cursor?: InputMaybe<CnaeSecoesWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeSecoesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeSecoesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeSecoesWhereInput>;
};

export type QueryFindFirstCnaeSubClassesArgs = {
    cursor?: InputMaybe<CnaeSubClassesWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeSubClassesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeSubClassesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeSubClassesWhereInput>;
};

export type QueryFindFirstComercialPartnerAuditArgs = {
    cursor?: InputMaybe<ComercialPartnerAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<ComercialPartnerAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ComercialPartnerAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ComercialPartnerAuditWhereInput>;
};

export type QueryFindFirstComercialPartnerCommissionArgs = {
    cursor?: InputMaybe<ComercialPartnerCommissionWhereUniqueInput>;
    distinct?: InputMaybe<Array<ComercialPartnerCommissionScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ComercialPartnerCommissionOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ComercialPartnerCommissionWhereInput>;
};

export type QueryFindFirstContaBancariaArgs = {
    cursor?: InputMaybe<ContaBancariaWhereUniqueInput>;
    distinct?: InputMaybe<Array<ContaBancariaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ContaBancariaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContaBancariaWhereInput>;
};

export type QueryFindFirstContatoArgs = {
    cursor?: InputMaybe<ContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<ContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContatoWhereInput>;
};

export type QueryFindFirstContatoAuditArgs = {
    cursor?: InputMaybe<ContatoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<ContatoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ContatoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContatoAuditWhereInput>;
};

export type QueryFindFirstDebenturistaArgs = {
    cursor?: InputMaybe<DebenturistaWhereUniqueInput>;
    distinct?: InputMaybe<Array<DebenturistaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<DebenturistaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<DebenturistaWhereInput>;
};

export type QueryFindFirstDebenturistaAuditArgs = {
    cursor?: InputMaybe<DebenturistaAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<DebenturistaAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<DebenturistaAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<DebenturistaAuditWhereInput>;
};

export type QueryFindFirstEmpresaArgs = {
    cursor?: InputMaybe<EmpresaWhereUniqueInput>;
    distinct?: InputMaybe<Array<EmpresaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EmpresaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EmpresaWhereInput>;
};

export type QueryFindFirstEmpresaAuditArgs = {
    cursor?: InputMaybe<EmpresaAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<EmpresaAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EmpresaAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EmpresaAuditWhereInput>;
};

export type QueryFindFirstEnderecoArgs = {
    cursor?: InputMaybe<EnderecoWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoWhereInput>;
};

export type QueryFindFirstEnderecoAlteracaoArgs = {
    cursor?: InputMaybe<EnderecoAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoAlteracaoWhereInput>;
};

export type QueryFindFirstEnderecoAuditArgs = {
    cursor?: InputMaybe<EnderecoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoAuditWhereInput>;
};

export type QueryFindFirstEnderecoCadastroArgs = {
    cursor?: InputMaybe<EnderecoCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCadastroWhereInput>;
};

export type QueryFindFirstEnderecoCamposAlteracaoArgs = {
    cursor?: InputMaybe<EnderecoCamposAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoCamposAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoCamposAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCamposAlteracaoWhereInput>;
};

export type QueryFindFirstEnderecoCamposCadastroArgs = {
    cursor?: InputMaybe<EnderecoCamposCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoCamposCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoCamposCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCamposCadastroWhereInput>;
};

export type QueryFindFirstEventArgs = {
    cursor?: InputMaybe<EventWhereUniqueInput>;
    distinct?: InputMaybe<Array<EventScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EventWhereInput>;
};

export type QueryFindFirstExtratoIntegracaoArgs = {
    cursor?: InputMaybe<ExtratoIntegracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<ExtratoIntegracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ExtratoIntegracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ExtratoIntegracaoWhereInput>;
};

export type QueryFindFirstGrupoNotificacaoArgs = {
    cursor?: InputMaybe<GrupoNotificacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<GrupoNotificacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<GrupoNotificacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GrupoNotificacaoWhereInput>;
};

export type QueryFindFirstGruposPermissaoArgs = {
    cursor?: InputMaybe<GruposPermissaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<GruposPermissaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<GruposPermissaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GruposPermissaoWhereInput>;
};

export type QueryFindFirstMinibancoArgs = {
    cursor?: InputMaybe<MinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoWhereInput>;
};

export type QueryFindFirstMinibancoAuditArgs = {
    cursor?: InputMaybe<MinibancoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoAuditWhereInput>;
};

export type QueryFindFirstMinibancoContatoArgs = {
    cursor?: InputMaybe<MinibancoContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoContatoWhereInput>;
};

export type QueryFindFirstMinibancoExtratoArgs = {
    cursor?: InputMaybe<MinibancoExtratoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoExtratoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoExtratoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoExtratoWhereInput>;
};

export type QueryFindFirstMinibankIntegrationArgs = {
    cursor?: InputMaybe<MinibankIntegrationWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibankIntegrationScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibankIntegrationOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibankIntegrationWhereInput>;
};

export type QueryFindFirstNFeArgs = {
    cursor?: InputMaybe<NFeWhereUniqueInput>;
    distinct?: InputMaybe<Array<NFeScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<NFeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NFeWhereInput>;
};

export type QueryFindFirstNFeParcelaArgs = {
    cursor?: InputMaybe<NFeParcelaWhereUniqueInput>;
    distinct?: InputMaybe<Array<NFeParcelaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<NFeParcelaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NFeParcelaWhereInput>;
};

export type QueryFindFirstNivelPermissaoArgs = {
    cursor?: InputMaybe<NivelPermissaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<NivelPermissaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<NivelPermissaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NivelPermissaoWhereInput>;
};

export type QueryFindFirstOccupationArgs = {
    cursor?: InputMaybe<OccupationWhereUniqueInput>;
    distinct?: InputMaybe<Array<OccupationScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OccupationOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OccupationWhereInput>;
};

export type QueryFindFirstOccupationAuditArgs = {
    cursor?: InputMaybe<OccupationAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<OccupationAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OccupationAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OccupationAuditWhereInput>;
};

export type QueryFindFirstOperacaoArgs = {
    cursor?: InputMaybe<OperacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoWhereInput>;
};

export type QueryFindFirstOperacaoAnexosArgs = {
    cursor?: InputMaybe<OperacaoAnexosWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoAnexosScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoAnexosOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoAnexosWhereInput>;
};

export type QueryFindFirstOperacaoAuditArgs = {
    cursor?: InputMaybe<OperacaoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoAuditWhereInput>;
};

export type QueryFindFirstOperacaoChecklistArgs = {
    cursor?: InputMaybe<OperacaoChecklistWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoChecklistScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoChecklistOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoChecklistWhereInput>;
};

export type QueryFindFirstOperacaoIntegracaoArgs = {
    cursor?: InputMaybe<OperacaoIntegracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoIntegracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoIntegracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoIntegracaoWhereInput>;
};

export type QueryFindFirstOperacaoModalidadeArgs = {
    cursor?: InputMaybe<OperacaoModalidadeWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoModalidadeScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoModalidadeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoModalidadeWhereInput>;
};

export type QueryFindFirstOperacaoRascunhoArgs = {
    cursor?: InputMaybe<OperacaoRascunhoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoRascunhoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoRascunhoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoRascunhoWhereInput>;
};

export type QueryFindFirstOperacaoStatusArgs = {
    cursor?: InputMaybe<OperacaoStatusWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoStatusScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoStatusOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoStatusWhereInput>;
};

export type QueryFindFirstOperacaoSugeridaArgs = {
    cursor?: InputMaybe<OperacaoSugeridaWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoSugeridaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoSugeridaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoSugeridaWhereInput>;
};

export type QueryFindFirstOperacaoTipoArgs = {
    cursor?: InputMaybe<OperacaoTipoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoTipoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoTipoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoTipoWhereInput>;
};

export type QueryFindFirstOperacoesRascunhoViewArgs = {
    cursor?: InputMaybe<OperacoesRascunhoViewWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacoesRascunhoViewScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacoesRascunhoViewOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacoesRascunhoViewWhereInput>;
};

export type QueryFindFirstOperationCommisionArgs = {
    cursor?: InputMaybe<OperationCommisionWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationCommisionScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationCommisionOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationCommisionWhereInput>;
};

export type QueryFindFirstOperationFeesArgs = {
    cursor?: InputMaybe<OperationFeesWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationFeesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationFeesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationFeesWhereInput>;
};

export type QueryFindFirstOperationUpdatesArgs = {
    cursor?: InputMaybe<OperationUpdatesWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationUpdatesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationUpdatesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationUpdatesWhereInput>;
};

export type QueryFindFirstOperationsInfoArgs = {
    cursor?: InputMaybe<OperationsInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationsInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationsInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationsInfoWhereInput>;
};

export type QueryFindFirstParceiroComercialArgs = {
    cursor?: InputMaybe<ParceiroComercialWhereUniqueInput>;
    distinct?: InputMaybe<Array<ParceiroComercialScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ParceiroComercialOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ParceiroComercialWhereInput>;
};

export type QueryFindFirstPayableUpdatesArgs = {
    cursor?: InputMaybe<PayableUpdatesWhereUniqueInput>;
    distinct?: InputMaybe<Array<PayableUpdatesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<PayableUpdatesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PayableUpdatesWhereInput>;
};

export type QueryFindFirstPermissoesGruposArgs = {
    cursor?: InputMaybe<PermissoesGruposWhereUniqueInput>;
    distinct?: InputMaybe<Array<PermissoesGruposScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<PermissoesGruposOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PermissoesGruposWhereInput>;
};

export type QueryFindFirstPreCadastroCedenteArgs = {
    cursor?: InputMaybe<PreCadastroCedenteWhereUniqueInput>;
    distinct?: InputMaybe<Array<PreCadastroCedenteScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<PreCadastroCedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PreCadastroCedenteWhereInput>;
};

export type QueryFindFirstRecebiveisInfoArgs = {
    cursor?: InputMaybe<RecebiveisInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebiveisInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebiveisInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisInfoWhereInput>;
};

export type QueryFindFirstRecebiveisToAnticipateArgs = {
    cursor?: InputMaybe<RecebiveisToAnticipateWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebiveisToAnticipateScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebiveisToAnticipateOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisToAnticipateWhereInput>;
};

export type QueryFindFirstRecebivelArgs = {
    cursor?: InputMaybe<RecebivelWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelWhereInput>;
};

export type QueryFindFirstRecebivelAuditArgs = {
    cursor?: InputMaybe<RecebivelAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelAuditWhereInput>;
};

export type QueryFindFirstRecebivelIntegracaoArgs = {
    cursor?: InputMaybe<RecebivelIntegracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelIntegracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelIntegracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelIntegracaoWhereInput>;
};

export type QueryFindFirstRecebivelOperadoArgs = {
    cursor?: InputMaybe<RecebivelOperadoWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelOperadoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelOperadoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelOperadoWhereInput>;
};

export type QueryFindFirstRecebivelPagarContasArgs = {
    cursor?: InputMaybe<RecebivelPagarContasWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelPagarContasScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelPagarContasOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelPagarContasWhereInput>;
};

export type QueryFindFirstRepresentativeArgs = {
    cursor?: InputMaybe<RepresentativeWhereUniqueInput>;
    distinct?: InputMaybe<Array<RepresentativeScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RepresentativeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RepresentativeWhereInput>;
};

export type QueryFindFirstResponsavelCedenteArgs = {
    cursor?: InputMaybe<ResponsavelCedenteWhereUniqueInput>;
    distinct?: InputMaybe<Array<ResponsavelCedenteScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ResponsavelCedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ResponsavelCedenteWhereInput>;
};

export type QueryFindFirstSacadoArgs = {
    cursor?: InputMaybe<SacadoWhereUniqueInput>;
    distinct?: InputMaybe<Array<SacadoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<SacadoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoWhereInput>;
};

export type QueryFindFirstSacadoAuditArgs = {
    cursor?: InputMaybe<SacadoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<SacadoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<SacadoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoAuditWhereInput>;
};

export type QueryFindFirstSacadoContatoArgs = {
    cursor?: InputMaybe<SacadoContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<SacadoContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<SacadoContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoContatoWhereInput>;
};

export type QueryFindFirstStatusOperacionalAuditArgs = {
    cursor?: InputMaybe<StatusOperacionalAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<StatusOperacionalAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<StatusOperacionalAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<StatusOperacionalAuditWhereInput>;
};

export type QueryFindFirstStatusOperacionalCedenteMinibancoArgs = {
    cursor?: InputMaybe<StatusOperacionalCedenteMinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<StatusOperacionalCedenteMinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<StatusOperacionalCedenteMinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<StatusOperacionalCedenteMinibancoWhereInput>;
};

export type QueryFindFirstTakeRateMinibancoArgs = {
    cursor?: InputMaybe<TakeRateMinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<TakeRateMinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<TakeRateMinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TakeRateMinibancoWhereInput>;
};

export type QueryFindFirstTaxasMinibancoArgs = {
    cursor?: InputMaybe<TaxasMinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<TaxasMinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<TaxasMinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TaxasMinibancoWhereInput>;
};

export type QueryFindFirstTermsAndConditionsArgs = {
    cursor?: InputMaybe<TermsAndConditionsWhereUniqueInput>;
    distinct?: InputMaybe<Array<TermsAndConditionsScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<TermsAndConditionsOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TermsAndConditionsWhereInput>;
};

export type QueryFindFirstTipoMovimentacaoArgs = {
    cursor?: InputMaybe<TipoMovimentacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<TipoMovimentacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<TipoMovimentacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TipoMovimentacaoWhereInput>;
};

export type QueryFindFirstUsuarioArgs = {
    cursor?: InputMaybe<UsuarioWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioWhereInput>;
};

export type QueryFindFirstUsuarioAuditArgs = {
    cursor?: InputMaybe<UsuarioAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioAuditWhereInput>;
};

export type QueryFindFirstUsuarioCadastroArgs = {
    cursor?: InputMaybe<UsuarioCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioCadastroWhereInput>;
};

export type QueryFindFirstUsuarioCadastroCamposArgs = {
    cursor?: InputMaybe<UsuarioCadastroCamposWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioCadastroCamposScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioCadastroCamposOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioCadastroCamposWhereInput>;
};

export type QueryFindManyAcceptedTermsArgs = {
    cursor?: InputMaybe<AcceptedTermsWhereUniqueInput>;
    distinct?: InputMaybe<Array<AcceptedTermsScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AcceptedTermsOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AcceptedTermsWhereInput>;
};

export type QueryFindManyAssignorBankDataArgs = {
    cursor?: InputMaybe<AssignorBankDataWhereUniqueInput>;
    distinct?: InputMaybe<Array<AssignorBankDataScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AssignorBankDataOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorBankDataWhereInput>;
};

export type QueryFindManyBanksArgs = {
    cursor?: InputMaybe<BanksWhereUniqueInput>;
    distinct?: InputMaybe<Array<BanksScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<BanksOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<BanksWhereInput>;
};

export type QueryFindManyCedenteAnexosArgs = {
    cursor?: InputMaybe<CedenteAnexosWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAnexosScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAnexosOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAnexosWhereInput>;
};

export type QueryFindManyCnaeClassesArgs = {
    cursor?: InputMaybe<CnaeClassesWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeClassesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeClassesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeClassesWhereInput>;
};

export type QueryFindManyCnaeDivisoesArgs = {
    cursor?: InputMaybe<CnaeDivisoesWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeDivisoesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeDivisoesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeDivisoesWhereInput>;
};

export type QueryFindManyCnaeGruposArgs = {
    cursor?: InputMaybe<CnaeGruposWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeGruposScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeGruposOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeGruposWhereInput>;
};

export type QueryFindManyCnaeSecoesArgs = {
    cursor?: InputMaybe<CnaeSecoesWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeSecoesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeSecoesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeSecoesWhereInput>;
};

export type QueryFindManyCnaeSubClassesArgs = {
    cursor?: InputMaybe<CnaeSubClassesWhereUniqueInput>;
    distinct?: InputMaybe<Array<CnaeSubClassesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CnaeSubClassesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeSubClassesWhereInput>;
};

export type QueryFindManyOperacaoAnexosArgs = {
    cursor?: InputMaybe<OperacaoAnexosWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoAnexosScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoAnexosOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoAnexosWhereInput>;
};

export type QueryFindManyOperationFeesArgs = {
    cursor?: InputMaybe<OperationFeesWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationFeesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationFeesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationFeesWhereInput>;
};

export type QueryFindManyOperationUpdatesArgs = {
    cursor?: InputMaybe<OperationUpdatesWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationUpdatesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationUpdatesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationUpdatesWhereInput>;
};

export type QueryFindManyPayableUpdatesArgs = {
    cursor?: InputMaybe<PayableUpdatesWhereUniqueInput>;
    distinct?: InputMaybe<Array<PayableUpdatesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<PayableUpdatesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PayableUpdatesWhereInput>;
};

export type QueryFindManyPermissoesGruposArgs = {
    cursor?: InputMaybe<PermissoesGruposWhereUniqueInput>;
    distinct?: InputMaybe<Array<PermissoesGruposScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<PermissoesGruposOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PermissoesGruposWhereInput>;
};

export type QueryFindManyRecebivelPagarContasArgs = {
    cursor?: InputMaybe<RecebivelPagarContasWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelPagarContasScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelPagarContasOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelPagarContasWhereInput>;
};

export type QueryFindManyTermsAndConditionsArgs = {
    cursor?: InputMaybe<TermsAndConditionsWhereUniqueInput>;
    distinct?: InputMaybe<Array<TermsAndConditionsScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<TermsAndConditionsOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TermsAndConditionsWhereInput>;
};

export type QueryFindManyUsuarioCadastroCamposArgs = {
    cursor?: InputMaybe<UsuarioCadastroCamposWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioCadastroCamposScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioCadastroCamposOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioCadastroCamposWhereInput>;
};

export type QueryFindOneOperationArgs = {
    id: Scalars["Float"];
};

export type QueryFindUniqueAcceptedTermsArgs = {
    where: AcceptedTermsWhereUniqueInput;
};

export type QueryFindUniqueAssignorBankDataArgs = {
    where: AssignorBankDataWhereUniqueInput;
};

export type QueryFindUniqueBanksArgs = {
    where: BanksWhereUniqueInput;
};

export type QueryFindUniqueCedenteAnexosArgs = {
    where: CedenteAnexosWhereUniqueInput;
};

export type QueryFindUniqueCnaeClassesArgs = {
    where: CnaeClassesWhereUniqueInput;
};

export type QueryFindUniqueCnaeDivisoesArgs = {
    where: CnaeDivisoesWhereUniqueInput;
};

export type QueryFindUniqueCnaeGruposArgs = {
    where: CnaeGruposWhereUniqueInput;
};

export type QueryFindUniqueCnaeSecoesArgs = {
    where: CnaeSecoesWhereUniqueInput;
};

export type QueryFindUniqueCnaeSubClassesArgs = {
    where: CnaeSubClassesWhereUniqueInput;
};

export type QueryFindUniqueOperacaoAnexosArgs = {
    where: OperacaoAnexosWhereUniqueInput;
};

export type QueryFindUniqueOperationFeesArgs = {
    where: OperationFeesWhereUniqueInput;
};

export type QueryFindUniqueOperationUpdatesArgs = {
    where: OperationUpdatesWhereUniqueInput;
};

export type QueryFindUniquePayableUpdatesArgs = {
    where: PayableUpdatesWhereUniqueInput;
};

export type QueryFindUniquePermissoesGruposArgs = {
    where: PermissoesGruposWhereUniqueInput;
};

export type QueryFindUniqueRecebivelPagarContasArgs = {
    where: RecebivelPagarContasWhereUniqueInput;
};

export type QueryFindUniqueTermsAndConditionsArgs = {
    where: TermsAndConditionsWhereUniqueInput;
};

export type QueryFindUniqueUsuarioCadastroCamposArgs = {
    where: UsuarioCadastroCamposWhereUniqueInput;
};

export type QueryGroupByAcceptedTermsArgs = {
    by: Array<AcceptedTermsScalarFieldEnum>;
    having?: InputMaybe<AcceptedTermsScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<AcceptedTermsOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AcceptedTermsWhereInput>;
};

export type QueryGroupByArquivoArgs = {
    by: Array<ArquivoScalarFieldEnum>;
    having?: InputMaybe<ArquivoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<ArquivoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoWhereInput>;
};

export type QueryGroupByArquivoAuditArgs = {
    by: Array<ArquivoAuditScalarFieldEnum>;
    having?: InputMaybe<ArquivoAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<ArquivoAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoAuditWhereInput>;
};

export type QueryGroupByAssignorBankDataArgs = {
    by: Array<AssignorBankDataScalarFieldEnum>;
    having?: InputMaybe<AssignorBankDataScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<AssignorBankDataOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorBankDataWhereInput>;
};

export type QueryGroupByAssignorCrmArgs = {
    by: Array<AssignorCrmScalarFieldEnum>;
    having?: InputMaybe<AssignorCrmScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<AssignorCrmOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorCrmWhereInput>;
};

export type QueryGroupByAssignorDocumentTypeArgs = {
    by: Array<AssignorDocumentTypeScalarFieldEnum>;
    having?: InputMaybe<AssignorDocumentTypeScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<AssignorDocumentTypeOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorDocumentTypeWhereInput>;
};

export type QueryGroupByAssignorStatusByMinibankArgs = {
    by: Array<AssignorStatusByMinibankScalarFieldEnum>;
    having?: InputMaybe<AssignorStatusByMinibankScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<AssignorStatusByMinibankOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AssignorStatusByMinibankWhereInput>;
};

export type QueryGroupByBanksArgs = {
    by: Array<BanksScalarFieldEnum>;
    having?: InputMaybe<BanksScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<BanksOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<BanksWhereInput>;
};

export type QueryGroupByCTeArgs = {
    by: Array<CTeScalarFieldEnum>;
    having?: InputMaybe<CTeScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CTeOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CTeWhereInput>;
};

export type QueryGroupByCedenteArgs = {
    by: Array<CedenteScalarFieldEnum>;
    having?: InputMaybe<CedenteScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CedenteOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteWhereInput>;
};

export type QueryGroupByCedenteAlteracaoArgs = {
    by: Array<CedenteAlteracaoScalarFieldEnum>;
    having?: InputMaybe<CedenteAlteracaoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CedenteAlteracaoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAlteracaoWhereInput>;
};

export type QueryGroupByCedenteAnexosArgs = {
    by: Array<CedenteAnexosScalarFieldEnum>;
    having?: InputMaybe<CedenteAnexosScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CedenteAnexosOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAnexosWhereInput>;
};

export type QueryGroupByCedenteAuditArgs = {
    by: Array<CedenteAuditScalarFieldEnum>;
    having?: InputMaybe<CedenteAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CedenteAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAuditWhereInput>;
};

export type QueryGroupByCedenteCadastroArgs = {
    by: Array<CedenteCadastroScalarFieldEnum>;
    having?: InputMaybe<CedenteCadastroScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CedenteCadastroOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCadastroWhereInput>;
};

export type QueryGroupByCedenteCamposAlteracaoArgs = {
    by: Array<CedenteCamposAlteracaoScalarFieldEnum>;
    having?: InputMaybe<CedenteCamposAlteracaoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CedenteCamposAlteracaoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCamposAlteracaoWhereInput>;
};

export type QueryGroupByCedenteCamposCadastroArgs = {
    by: Array<CedenteCamposCadastroScalarFieldEnum>;
    having?: InputMaybe<CedenteCamposCadastroScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CedenteCamposCadastroOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCamposCadastroWhereInput>;
};

export type QueryGroupByCedenteContatoArgs = {
    by: Array<CedenteContatoScalarFieldEnum>;
    having?: InputMaybe<CedenteContatoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CedenteContatoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteContatoWhereInput>;
};

export type QueryGroupByCedentesInfoArgs = {
    by: Array<CedentesInfoScalarFieldEnum>;
    having?: InputMaybe<CedentesInfoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CedentesInfoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedentesInfoWhereInput>;
};

export type QueryGroupByCnaeClassesArgs = {
    by: Array<CnaeClassesScalarFieldEnum>;
    having?: InputMaybe<CnaeClassesScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CnaeClassesOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeClassesWhereInput>;
};

export type QueryGroupByCnaeDivisoesArgs = {
    by: Array<CnaeDivisoesScalarFieldEnum>;
    having?: InputMaybe<CnaeDivisoesScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CnaeDivisoesOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeDivisoesWhereInput>;
};

export type QueryGroupByCnaeGruposArgs = {
    by: Array<CnaeGruposScalarFieldEnum>;
    having?: InputMaybe<CnaeGruposScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CnaeGruposOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeGruposWhereInput>;
};

export type QueryGroupByCnaeSecoesArgs = {
    by: Array<CnaeSecoesScalarFieldEnum>;
    having?: InputMaybe<CnaeSecoesScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CnaeSecoesOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeSecoesWhereInput>;
};

export type QueryGroupByCnaeSubClassesArgs = {
    by: Array<CnaeSubClassesScalarFieldEnum>;
    having?: InputMaybe<CnaeSubClassesScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<CnaeSubClassesOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CnaeSubClassesWhereInput>;
};

export type QueryGroupByComercialPartnerAuditArgs = {
    by: Array<ComercialPartnerAuditScalarFieldEnum>;
    having?: InputMaybe<ComercialPartnerAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<ComercialPartnerAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ComercialPartnerAuditWhereInput>;
};

export type QueryGroupByComercialPartnerCommissionArgs = {
    by: Array<ComercialPartnerCommissionScalarFieldEnum>;
    having?: InputMaybe<ComercialPartnerCommissionScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<ComercialPartnerCommissionOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ComercialPartnerCommissionWhereInput>;
};

export type QueryGroupByContaBancariaArgs = {
    by: Array<ContaBancariaScalarFieldEnum>;
    having?: InputMaybe<ContaBancariaScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<ContaBancariaOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContaBancariaWhereInput>;
};

export type QueryGroupByContatoArgs = {
    by: Array<ContatoScalarFieldEnum>;
    having?: InputMaybe<ContatoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<ContatoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContatoWhereInput>;
};

export type QueryGroupByContatoAuditArgs = {
    by: Array<ContatoAuditScalarFieldEnum>;
    having?: InputMaybe<ContatoAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<ContatoAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContatoAuditWhereInput>;
};

export type QueryGroupByDebenturistaArgs = {
    by: Array<DebenturistaScalarFieldEnum>;
    having?: InputMaybe<DebenturistaScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<DebenturistaOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<DebenturistaWhereInput>;
};

export type QueryGroupByDebenturistaAuditArgs = {
    by: Array<DebenturistaAuditScalarFieldEnum>;
    having?: InputMaybe<DebenturistaAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<DebenturistaAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<DebenturistaAuditWhereInput>;
};

export type QueryGroupByEmpresaArgs = {
    by: Array<EmpresaScalarFieldEnum>;
    having?: InputMaybe<EmpresaScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<EmpresaOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EmpresaWhereInput>;
};

export type QueryGroupByEmpresaAuditArgs = {
    by: Array<EmpresaAuditScalarFieldEnum>;
    having?: InputMaybe<EmpresaAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<EmpresaAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EmpresaAuditWhereInput>;
};

export type QueryGroupByEnderecoArgs = {
    by: Array<EnderecoScalarFieldEnum>;
    having?: InputMaybe<EnderecoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<EnderecoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoWhereInput>;
};

export type QueryGroupByEnderecoAlteracaoArgs = {
    by: Array<EnderecoAlteracaoScalarFieldEnum>;
    having?: InputMaybe<EnderecoAlteracaoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<EnderecoAlteracaoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoAlteracaoWhereInput>;
};

export type QueryGroupByEnderecoAuditArgs = {
    by: Array<EnderecoAuditScalarFieldEnum>;
    having?: InputMaybe<EnderecoAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<EnderecoAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoAuditWhereInput>;
};

export type QueryGroupByEnderecoCadastroArgs = {
    by: Array<EnderecoCadastroScalarFieldEnum>;
    having?: InputMaybe<EnderecoCadastroScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<EnderecoCadastroOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCadastroWhereInput>;
};

export type QueryGroupByEnderecoCamposAlteracaoArgs = {
    by: Array<EnderecoCamposAlteracaoScalarFieldEnum>;
    having?: InputMaybe<EnderecoCamposAlteracaoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<EnderecoCamposAlteracaoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCamposAlteracaoWhereInput>;
};

export type QueryGroupByEnderecoCamposCadastroArgs = {
    by: Array<EnderecoCamposCadastroScalarFieldEnum>;
    having?: InputMaybe<EnderecoCamposCadastroScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<EnderecoCamposCadastroOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCamposCadastroWhereInput>;
};

export type QueryGroupByEventArgs = {
    by: Array<EventScalarFieldEnum>;
    having?: InputMaybe<EventScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<EventOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EventWhereInput>;
};

export type QueryGroupByExtratoIntegracaoArgs = {
    by: Array<ExtratoIntegracaoScalarFieldEnum>;
    having?: InputMaybe<ExtratoIntegracaoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<ExtratoIntegracaoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ExtratoIntegracaoWhereInput>;
};

export type QueryGroupByGrupoNotificacaoArgs = {
    by: Array<GrupoNotificacaoScalarFieldEnum>;
    having?: InputMaybe<GrupoNotificacaoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<GrupoNotificacaoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GrupoNotificacaoWhereInput>;
};

export type QueryGroupByGruposPermissaoArgs = {
    by: Array<GruposPermissaoScalarFieldEnum>;
    having?: InputMaybe<GruposPermissaoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<GruposPermissaoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GruposPermissaoWhereInput>;
};

export type QueryGroupByMinibancoArgs = {
    by: Array<MinibancoScalarFieldEnum>;
    having?: InputMaybe<MinibancoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<MinibancoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoWhereInput>;
};

export type QueryGroupByMinibancoAuditArgs = {
    by: Array<MinibancoAuditScalarFieldEnum>;
    having?: InputMaybe<MinibancoAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<MinibancoAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoAuditWhereInput>;
};

export type QueryGroupByMinibancoContatoArgs = {
    by: Array<MinibancoContatoScalarFieldEnum>;
    having?: InputMaybe<MinibancoContatoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<MinibancoContatoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoContatoWhereInput>;
};

export type QueryGroupByMinibancoExtratoArgs = {
    by: Array<MinibancoExtratoScalarFieldEnum>;
    having?: InputMaybe<MinibancoExtratoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<MinibancoExtratoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoExtratoWhereInput>;
};

export type QueryGroupByMinibankIntegrationArgs = {
    by: Array<MinibankIntegrationScalarFieldEnum>;
    having?: InputMaybe<MinibankIntegrationScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<MinibankIntegrationOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibankIntegrationWhereInput>;
};

export type QueryGroupByNFeArgs = {
    by: Array<NFeScalarFieldEnum>;
    having?: InputMaybe<NFeScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<NFeOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NFeWhereInput>;
};

export type QueryGroupByNFeParcelaArgs = {
    by: Array<NFeParcelaScalarFieldEnum>;
    having?: InputMaybe<NFeParcelaScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<NFeParcelaOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NFeParcelaWhereInput>;
};

export type QueryGroupByNivelPermissaoArgs = {
    by: Array<NivelPermissaoScalarFieldEnum>;
    having?: InputMaybe<NivelPermissaoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<NivelPermissaoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NivelPermissaoWhereInput>;
};

export type QueryGroupByOccupationArgs = {
    by: Array<OccupationScalarFieldEnum>;
    having?: InputMaybe<OccupationScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OccupationOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OccupationWhereInput>;
};

export type QueryGroupByOccupationAuditArgs = {
    by: Array<OccupationAuditScalarFieldEnum>;
    having?: InputMaybe<OccupationAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OccupationAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OccupationAuditWhereInput>;
};

export type QueryGroupByOperacaoArgs = {
    by: Array<OperacaoScalarFieldEnum>;
    having?: InputMaybe<OperacaoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperacaoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoWhereInput>;
};

export type QueryGroupByOperacaoAnexosArgs = {
    by: Array<OperacaoAnexosScalarFieldEnum>;
    having?: InputMaybe<OperacaoAnexosScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperacaoAnexosOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoAnexosWhereInput>;
};

export type QueryGroupByOperacaoAuditArgs = {
    by: Array<OperacaoAuditScalarFieldEnum>;
    having?: InputMaybe<OperacaoAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperacaoAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoAuditWhereInput>;
};

export type QueryGroupByOperacaoChecklistArgs = {
    by: Array<OperacaoChecklistScalarFieldEnum>;
    having?: InputMaybe<OperacaoChecklistScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperacaoChecklistOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoChecklistWhereInput>;
};

export type QueryGroupByOperacaoIntegracaoArgs = {
    by: Array<OperacaoIntegracaoScalarFieldEnum>;
    having?: InputMaybe<OperacaoIntegracaoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperacaoIntegracaoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoIntegracaoWhereInput>;
};

export type QueryGroupByOperacaoModalidadeArgs = {
    by: Array<OperacaoModalidadeScalarFieldEnum>;
    having?: InputMaybe<OperacaoModalidadeScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperacaoModalidadeOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoModalidadeWhereInput>;
};

export type QueryGroupByOperacaoRascunhoArgs = {
    by: Array<OperacaoRascunhoScalarFieldEnum>;
    having?: InputMaybe<OperacaoRascunhoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperacaoRascunhoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoRascunhoWhereInput>;
};

export type QueryGroupByOperacaoStatusArgs = {
    by: Array<OperacaoStatusScalarFieldEnum>;
    having?: InputMaybe<OperacaoStatusScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperacaoStatusOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoStatusWhereInput>;
};

export type QueryGroupByOperacaoSugeridaArgs = {
    by: Array<OperacaoSugeridaScalarFieldEnum>;
    having?: InputMaybe<OperacaoSugeridaScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperacaoSugeridaOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoSugeridaWhereInput>;
};

export type QueryGroupByOperacaoTipoArgs = {
    by: Array<OperacaoTipoScalarFieldEnum>;
    having?: InputMaybe<OperacaoTipoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperacaoTipoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoTipoWhereInput>;
};

export type QueryGroupByOperacoesRascunhoViewArgs = {
    by: Array<OperacoesRascunhoViewScalarFieldEnum>;
    having?: InputMaybe<OperacoesRascunhoViewScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperacoesRascunhoViewOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacoesRascunhoViewWhereInput>;
};

export type QueryGroupByOperationCommisionArgs = {
    by: Array<OperationCommisionScalarFieldEnum>;
    having?: InputMaybe<OperationCommisionScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperationCommisionOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationCommisionWhereInput>;
};

export type QueryGroupByOperationFeesArgs = {
    by: Array<OperationFeesScalarFieldEnum>;
    having?: InputMaybe<OperationFeesScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperationFeesOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationFeesWhereInput>;
};

export type QueryGroupByOperationUpdatesArgs = {
    by: Array<OperationUpdatesScalarFieldEnum>;
    having?: InputMaybe<OperationUpdatesScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperationUpdatesOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationUpdatesWhereInput>;
};

export type QueryGroupByOperationsInfoArgs = {
    by: Array<OperationsInfoScalarFieldEnum>;
    having?: InputMaybe<OperationsInfoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<OperationsInfoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationsInfoWhereInput>;
};

export type QueryGroupByParceiroComercialArgs = {
    by: Array<ParceiroComercialScalarFieldEnum>;
    having?: InputMaybe<ParceiroComercialScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<ParceiroComercialOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ParceiroComercialWhereInput>;
};

export type QueryGroupByPayableUpdatesArgs = {
    by: Array<PayableUpdatesScalarFieldEnum>;
    having?: InputMaybe<PayableUpdatesScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<PayableUpdatesOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PayableUpdatesWhereInput>;
};

export type QueryGroupByPermissoesGruposArgs = {
    by: Array<PermissoesGruposScalarFieldEnum>;
    having?: InputMaybe<PermissoesGruposScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<PermissoesGruposOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PermissoesGruposWhereInput>;
};

export type QueryGroupByPreCadastroCedenteArgs = {
    by: Array<PreCadastroCedenteScalarFieldEnum>;
    having?: InputMaybe<PreCadastroCedenteScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<PreCadastroCedenteOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PreCadastroCedenteWhereInput>;
};

export type QueryGroupByRecebiveisInfoArgs = {
    by: Array<RecebiveisInfoScalarFieldEnum>;
    having?: InputMaybe<RecebiveisInfoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<RecebiveisInfoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisInfoWhereInput>;
};

export type QueryGroupByRecebiveisToAnticipateArgs = {
    by: Array<RecebiveisToAnticipateScalarFieldEnum>;
    having?: InputMaybe<RecebiveisToAnticipateScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<RecebiveisToAnticipateOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisToAnticipateWhereInput>;
};

export type QueryGroupByRecebivelArgs = {
    by: Array<RecebivelScalarFieldEnum>;
    having?: InputMaybe<RecebivelScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<RecebivelOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelWhereInput>;
};

export type QueryGroupByRecebivelAuditArgs = {
    by: Array<RecebivelAuditScalarFieldEnum>;
    having?: InputMaybe<RecebivelAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<RecebivelAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelAuditWhereInput>;
};

export type QueryGroupByRecebivelIntegracaoArgs = {
    by: Array<RecebivelIntegracaoScalarFieldEnum>;
    having?: InputMaybe<RecebivelIntegracaoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<RecebivelIntegracaoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelIntegracaoWhereInput>;
};

export type QueryGroupByRecebivelOperadoArgs = {
    by: Array<RecebivelOperadoScalarFieldEnum>;
    having?: InputMaybe<RecebivelOperadoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<RecebivelOperadoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelOperadoWhereInput>;
};

export type QueryGroupByRecebivelPagarContasArgs = {
    by: Array<RecebivelPagarContasScalarFieldEnum>;
    having?: InputMaybe<RecebivelPagarContasScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<RecebivelPagarContasOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelPagarContasWhereInput>;
};

export type QueryGroupByRepresentativeArgs = {
    by: Array<RepresentativeScalarFieldEnum>;
    having?: InputMaybe<RepresentativeScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<RepresentativeOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RepresentativeWhereInput>;
};

export type QueryGroupByResponsavelCedenteArgs = {
    by: Array<ResponsavelCedenteScalarFieldEnum>;
    having?: InputMaybe<ResponsavelCedenteScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<ResponsavelCedenteOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ResponsavelCedenteWhereInput>;
};

export type QueryGroupBySacadoArgs = {
    by: Array<SacadoScalarFieldEnum>;
    having?: InputMaybe<SacadoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<SacadoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoWhereInput>;
};

export type QueryGroupBySacadoAuditArgs = {
    by: Array<SacadoAuditScalarFieldEnum>;
    having?: InputMaybe<SacadoAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<SacadoAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoAuditWhereInput>;
};

export type QueryGroupBySacadoContatoArgs = {
    by: Array<SacadoContatoScalarFieldEnum>;
    having?: InputMaybe<SacadoContatoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<SacadoContatoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoContatoWhereInput>;
};

export type QueryGroupByStatusOperacionalAuditArgs = {
    by: Array<StatusOperacionalAuditScalarFieldEnum>;
    having?: InputMaybe<StatusOperacionalAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<StatusOperacionalAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<StatusOperacionalAuditWhereInput>;
};

export type QueryGroupByStatusOperacionalCedenteMinibancoArgs = {
    by: Array<StatusOperacionalCedenteMinibancoScalarFieldEnum>;
    having?: InputMaybe<StatusOperacionalCedenteMinibancoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<StatusOperacionalCedenteMinibancoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<StatusOperacionalCedenteMinibancoWhereInput>;
};

export type QueryGroupByTakeRateMinibancoArgs = {
    by: Array<TakeRateMinibancoScalarFieldEnum>;
    having?: InputMaybe<TakeRateMinibancoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<TakeRateMinibancoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TakeRateMinibancoWhereInput>;
};

export type QueryGroupByTaxasMinibancoArgs = {
    by: Array<TaxasMinibancoScalarFieldEnum>;
    having?: InputMaybe<TaxasMinibancoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<TaxasMinibancoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TaxasMinibancoWhereInput>;
};

export type QueryGroupByTermsAndConditionsArgs = {
    by: Array<TermsAndConditionsScalarFieldEnum>;
    having?: InputMaybe<TermsAndConditionsScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<TermsAndConditionsOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TermsAndConditionsWhereInput>;
};

export type QueryGroupByTipoMovimentacaoArgs = {
    by: Array<TipoMovimentacaoScalarFieldEnum>;
    having?: InputMaybe<TipoMovimentacaoScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<TipoMovimentacaoOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TipoMovimentacaoWhereInput>;
};

export type QueryGroupByUsuarioArgs = {
    by: Array<UsuarioScalarFieldEnum>;
    having?: InputMaybe<UsuarioScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<UsuarioOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioWhereInput>;
};

export type QueryGroupByUsuarioAuditArgs = {
    by: Array<UsuarioAuditScalarFieldEnum>;
    having?: InputMaybe<UsuarioAuditScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<UsuarioAuditOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioAuditWhereInput>;
};

export type QueryGroupByUsuarioCadastroArgs = {
    by: Array<UsuarioCadastroScalarFieldEnum>;
    having?: InputMaybe<UsuarioCadastroScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<UsuarioCadastroOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioCadastroWhereInput>;
};

export type QueryGroupByUsuarioCadastroCamposArgs = {
    by: Array<UsuarioCadastroCamposScalarFieldEnum>;
    having?: InputMaybe<UsuarioCadastroCamposScalarWhereWithAggregatesInput>;
    orderBy?: InputMaybe<Array<UsuarioCadastroCamposOrderByWithAggregationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioCadastroCamposWhereInput>;
};

export type QueryGrupoNotificacaoArgs = {
    where: GrupoNotificacaoWhereUniqueInput;
};

export type QueryGrupoNotificacaosArgs = {
    cursor?: InputMaybe<GrupoNotificacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<GrupoNotificacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<GrupoNotificacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GrupoNotificacaoWhereInput>;
};

export type QueryGruposPermissaoArgs = {
    where: GruposPermissaoWhereUniqueInput;
};

export type QueryGruposPermissaosArgs = {
    cursor?: InputMaybe<GruposPermissaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<GruposPermissaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<GruposPermissaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GruposPermissaoWhereInput>;
};

export type QueryMinibancoArgs = {
    where: MinibancoWhereUniqueInput;
};

export type QueryMinibancoAuditArgs = {
    where: MinibancoAuditWhereUniqueInput;
};

export type QueryMinibancoAuditsArgs = {
    cursor?: InputMaybe<MinibancoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoAuditWhereInput>;
};

export type QueryMinibancoContatoArgs = {
    where: MinibancoContatoWhereUniqueInput;
};

export type QueryMinibancoContatoesArgs = {
    cursor?: InputMaybe<MinibancoContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoContatoWhereInput>;
};

export type QueryMinibancoExtratoArgs = {
    where: MinibancoExtratoWhereUniqueInput;
};

export type QueryMinibancoExtratoesArgs = {
    cursor?: InputMaybe<MinibancoExtratoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoExtratoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoExtratoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoExtratoWhereInput>;
};

export type QueryMinibancosArgs = {
    cursor?: InputMaybe<MinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoWhereInput>;
};

export type QueryMinibankIntegrationArgs = {
    where: MinibankIntegrationWhereUniqueInput;
};

export type QueryMinibankIntegrationsArgs = {
    cursor?: InputMaybe<MinibankIntegrationWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibankIntegrationScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibankIntegrationOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibankIntegrationWhereInput>;
};

export type QueryNFeArgs = {
    where: NFeWhereUniqueInput;
};

export type QueryNFeParcelaArgs = {
    where: NFeParcelaWhereUniqueInput;
};

export type QueryNFeParcelasArgs = {
    cursor?: InputMaybe<NFeParcelaWhereUniqueInput>;
    distinct?: InputMaybe<Array<NFeParcelaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<NFeParcelaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NFeParcelaWhereInput>;
};

export type QueryNFesArgs = {
    cursor?: InputMaybe<NFeWhereUniqueInput>;
    distinct?: InputMaybe<Array<NFeScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<NFeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NFeWhereInput>;
};

export type QueryNivelPermissaoArgs = {
    where: NivelPermissaoWhereUniqueInput;
};

export type QueryNivelPermissaosArgs = {
    cursor?: InputMaybe<NivelPermissaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<NivelPermissaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<NivelPermissaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NivelPermissaoWhereInput>;
};

export type QueryOccupationArgs = {
    where: OccupationWhereUniqueInput;
};

export type QueryOccupationAuditArgs = {
    where: OccupationAuditWhereUniqueInput;
};

export type QueryOccupationAuditsArgs = {
    cursor?: InputMaybe<OccupationAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<OccupationAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OccupationAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OccupationAuditWhereInput>;
};

export type QueryOccupationsArgs = {
    cursor?: InputMaybe<OccupationWhereUniqueInput>;
    distinct?: InputMaybe<Array<OccupationScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OccupationOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OccupationWhereInput>;
};

export type QueryOperacaoArgs = {
    where: OperacaoWhereUniqueInput;
};

export type QueryOperacaoAuditArgs = {
    where: OperacaoAuditWhereUniqueInput;
};

export type QueryOperacaoAuditsArgs = {
    cursor?: InputMaybe<OperacaoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoAuditWhereInput>;
};

export type QueryOperacaoChecklistArgs = {
    where: OperacaoChecklistWhereUniqueInput;
};

export type QueryOperacaoChecklistsArgs = {
    cursor?: InputMaybe<OperacaoChecklistWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoChecklistScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoChecklistOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoChecklistWhereInput>;
};

export type QueryOperacaoIntegracaoArgs = {
    where: OperacaoIntegracaoWhereUniqueInput;
};

export type QueryOperacaoIntegracaosArgs = {
    cursor?: InputMaybe<OperacaoIntegracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoIntegracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoIntegracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoIntegracaoWhereInput>;
};

export type QueryOperacaoModalidadeArgs = {
    where: OperacaoModalidadeWhereUniqueInput;
};

export type QueryOperacaoModalidadesArgs = {
    cursor?: InputMaybe<OperacaoModalidadeWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoModalidadeScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoModalidadeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoModalidadeWhereInput>;
};

export type QueryOperacaoRascunhoArgs = {
    where: OperacaoRascunhoWhereUniqueInput;
};

export type QueryOperacaoRascunhosArgs = {
    cursor?: InputMaybe<OperacaoRascunhoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoRascunhoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoRascunhoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoRascunhoWhereInput>;
};

export type QueryOperacaoStatusArgs = {
    where: OperacaoStatusWhereUniqueInput;
};

export type QueryOperacaoStatusesArgs = {
    cursor?: InputMaybe<OperacaoStatusWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoStatusScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoStatusOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoStatusWhereInput>;
};

export type QueryOperacaoSugeridaArgs = {
    where: OperacaoSugeridaWhereUniqueInput;
};

export type QueryOperacaoSugeridasArgs = {
    cursor?: InputMaybe<OperacaoSugeridaWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoSugeridaScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoSugeridaOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoSugeridaWhereInput>;
};

export type QueryOperacaoTipoArgs = {
    where: OperacaoTipoWhereUniqueInput;
};

export type QueryOperacaoTiposArgs = {
    cursor?: InputMaybe<OperacaoTipoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoTipoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoTipoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoTipoWhereInput>;
};

export type QueryOperacaosArgs = {
    cursor?: InputMaybe<OperacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoWhereInput>;
};

export type QueryOperacoesRascunhoViewArgs = {
    where: OperacoesRascunhoViewWhereUniqueInput;
};

export type QueryOperacoesRascunhoViewsArgs = {
    cursor?: InputMaybe<OperacoesRascunhoViewWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacoesRascunhoViewScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacoesRascunhoViewOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacoesRascunhoViewWhereInput>;
};

export type QueryOperationCommisionArgs = {
    where: OperationCommisionWhereUniqueInput;
};

export type QueryOperationCommisionsArgs = {
    cursor?: InputMaybe<OperationCommisionWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationCommisionScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationCommisionOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationCommisionWhereInput>;
};

export type QueryOperationsInfoArgs = {
    where: OperationsInfoWhereUniqueInput;
};

export type QueryOperationsInfosArgs = {
    cursor?: InputMaybe<OperationsInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationsInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationsInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationsInfoWhereInput>;
};

export type QueryParceiroComercialArgs = {
    where: ParceiroComercialWhereUniqueInput;
};

export type QueryParceiroComercialsArgs = {
    cursor?: InputMaybe<ParceiroComercialWhereUniqueInput>;
    distinct?: InputMaybe<Array<ParceiroComercialScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ParceiroComercialOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ParceiroComercialWhereInput>;
};

export type QueryPreCadastroCedenteArgs = {
    where: PreCadastroCedenteWhereUniqueInput;
};

export type QueryPreCadastroCedentesArgs = {
    cursor?: InputMaybe<PreCadastroCedenteWhereUniqueInput>;
    distinct?: InputMaybe<Array<PreCadastroCedenteScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<PreCadastroCedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PreCadastroCedenteWhereInput>;
};

export type QueryRecebiveisInfoArgs = {
    where: RecebiveisInfoWhereUniqueInput;
};

export type QueryRecebiveisInfosArgs = {
    cursor?: InputMaybe<RecebiveisInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebiveisInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebiveisInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisInfoWhereInput>;
};

export type QueryRecebiveisToAnticipateArgs = {
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type QueryRecebiveisToAnticipatesArgs = {
    cursor?: InputMaybe<RecebiveisToAnticipateWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebiveisToAnticipateScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebiveisToAnticipateOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisToAnticipateWhereInput>;
};

export type QueryRecebivelArgs = {
    where: RecebivelWhereUniqueInput;
};

export type QueryRecebivelAuditArgs = {
    where: RecebivelAuditWhereUniqueInput;
};

export type QueryRecebivelAuditsArgs = {
    cursor?: InputMaybe<RecebivelAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelAuditWhereInput>;
};

export type QueryRecebivelIntegracaoArgs = {
    where: RecebivelIntegracaoWhereUniqueInput;
};

export type QueryRecebivelIntegracaosArgs = {
    cursor?: InputMaybe<RecebivelIntegracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelIntegracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelIntegracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelIntegracaoWhereInput>;
};

export type QueryRecebivelOperadoArgs = {
    where: RecebivelOperadoWhereUniqueInput;
};

export type QueryRecebivelOperadosArgs = {
    cursor?: InputMaybe<RecebivelOperadoWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelOperadoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelOperadoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelOperadoWhereInput>;
};

export type QueryRecebivelsArgs = {
    cursor?: InputMaybe<RecebivelWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelWhereInput>;
};

export type QueryRepresentativeArgs = {
    where: RepresentativeWhereUniqueInput;
};

export type QueryRepresentativesArgs = {
    cursor?: InputMaybe<RepresentativeWhereUniqueInput>;
    distinct?: InputMaybe<Array<RepresentativeScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RepresentativeOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RepresentativeWhereInput>;
};

export type QueryResponsavelCedenteArgs = {
    where: ResponsavelCedenteWhereUniqueInput;
};

export type QueryResponsavelCedentesArgs = {
    cursor?: InputMaybe<ResponsavelCedenteWhereUniqueInput>;
    distinct?: InputMaybe<Array<ResponsavelCedenteScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ResponsavelCedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ResponsavelCedenteWhereInput>;
};

export type QuerySacadoArgs = {
    where: SacadoWhereUniqueInput;
};

export type QuerySacadoAuditArgs = {
    where: SacadoAuditWhereUniqueInput;
};

export type QuerySacadoAuditsArgs = {
    cursor?: InputMaybe<SacadoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<SacadoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<SacadoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoAuditWhereInput>;
};

export type QuerySacadoContatoArgs = {
    where: SacadoContatoWhereUniqueInput;
};

export type QuerySacadoContatoesArgs = {
    cursor?: InputMaybe<SacadoContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<SacadoContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<SacadoContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoContatoWhereInput>;
};

export type QuerySacadosArgs = {
    cursor?: InputMaybe<SacadoWhereUniqueInput>;
    distinct?: InputMaybe<Array<SacadoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<SacadoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoWhereInput>;
};

export type QueryStatusOperacionalAuditArgs = {
    where: StatusOperacionalAuditWhereUniqueInput;
};

export type QueryStatusOperacionalAuditsArgs = {
    cursor?: InputMaybe<StatusOperacionalAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<StatusOperacionalAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<StatusOperacionalAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<StatusOperacionalAuditWhereInput>;
};

export type QueryStatusOperacionalCedenteMinibancoArgs = {
    where: StatusOperacionalCedenteMinibancoWhereUniqueInput;
};

export type QueryStatusOperacionalCedenteMinibancosArgs = {
    cursor?: InputMaybe<StatusOperacionalCedenteMinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<StatusOperacionalCedenteMinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<StatusOperacionalCedenteMinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<StatusOperacionalCedenteMinibancoWhereInput>;
};

export type QueryTakeRateMinibancoArgs = {
    where: TakeRateMinibancoWhereUniqueInput;
};

export type QueryTakeRateMinibancosArgs = {
    cursor?: InputMaybe<TakeRateMinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<TakeRateMinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<TakeRateMinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TakeRateMinibancoWhereInput>;
};

export type QueryTaxasMinibancoArgs = {
    where: TaxasMinibancoWhereUniqueInput;
};

export type QueryTaxasMinibancosArgs = {
    cursor?: InputMaybe<TaxasMinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<TaxasMinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<TaxasMinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TaxasMinibancoWhereInput>;
};

export type QueryTipoMovimentacaoArgs = {
    where: TipoMovimentacaoWhereUniqueInput;
};

export type QueryTipoMovimentacaosArgs = {
    cursor?: InputMaybe<TipoMovimentacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<TipoMovimentacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<TipoMovimentacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<TipoMovimentacaoWhereInput>;
};

export type QueryUsuarioArgs = {
    where: UsuarioWhereUniqueInput;
};

export type QueryUsuarioAuditArgs = {
    where: UsuarioAuditWhereUniqueInput;
};

export type QueryUsuarioAuditsArgs = {
    cursor?: InputMaybe<UsuarioAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioAuditWhereInput>;
};

export type QueryUsuarioCadastroArgs = {
    where: UsuarioCadastroWhereUniqueInput;
};

export type QueryUsuarioCadastrosArgs = {
    cursor?: InputMaybe<UsuarioCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioCadastroWhereInput>;
};

export type QueryUsuariosArgs = {
    cursor?: InputMaybe<UsuarioWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioWhereInput>;
};

export enum QueryMode {
    Default = "default",
    Insensitive = "insensitive",
}

/** @view */
export type RecebiveisInfo = {
    __typename?: "RecebiveisInfo";
    cedente: Cedente;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    nfe?: Maybe<NFe>;
    nfeId?: Maybe<Scalars["Int"]>;
    prazo?: Maybe<Scalars["Int"]>;
    sacado: Sacado;
    sacadoId: Scalars["Int"];
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisInfoAvgAggregate = {
    __typename?: "RecebiveisInfoAvgAggregate";
    cedenteId?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    nfeId?: Maybe<Scalars["Float"]>;
    prazo?: Maybe<Scalars["Float"]>;
    sacadoId?: Maybe<Scalars["Float"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type RecebiveisInfoAvgOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    prazo?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebiveisInfoCountAggregate = {
    __typename?: "RecebiveisInfoCountAggregate";
    _all: Scalars["Int"];
    cedenteId: Scalars["Int"];
    createdAt: Scalars["Int"];
    dtEmissao: Scalars["Int"];
    dtVencimento: Scalars["Int"];
    id: Scalars["Int"];
    minibancoId: Scalars["Int"];
    nfeId: Scalars["Int"];
    prazo: Scalars["Int"];
    sacadoId: Scalars["Int"];
    status: Scalars["Int"];
    valor: Scalars["Int"];
};

export type RecebiveisInfoCountOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    prazo?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebiveisInfoCreateInput = {
    cedente: CedenteCreateNestedOneWithoutRecebiveisInfoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInfoInput;
    nfe?: InputMaybe<NFeCreateNestedOneWithoutRecebiveisInfoInput>;
    prazo?: InputMaybe<Scalars["Int"]>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInfoInput;
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisInfoCreateManyCedenteInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    minibancoId: Scalars["Int"];
    nfeId?: InputMaybe<Scalars["Int"]>;
    prazo?: InputMaybe<Scalars["Int"]>;
    sacadoId: Scalars["Int"];
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisInfoCreateManyCedenteInputEnvelope = {
    data: Array<RecebiveisInfoCreateManyCedenteInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebiveisInfoCreateManyInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    minibancoId: Scalars["Int"];
    nfeId?: InputMaybe<Scalars["Int"]>;
    prazo?: InputMaybe<Scalars["Int"]>;
    sacadoId: Scalars["Int"];
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisInfoCreateManyMinibancoInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    nfeId?: InputMaybe<Scalars["Int"]>;
    prazo?: InputMaybe<Scalars["Int"]>;
    sacadoId: Scalars["Int"];
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisInfoCreateManyMinibancoInputEnvelope = {
    data: Array<RecebiveisInfoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebiveisInfoCreateManyNfeInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    minibancoId: Scalars["Int"];
    prazo?: InputMaybe<Scalars["Int"]>;
    sacadoId: Scalars["Int"];
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisInfoCreateManyNfeInputEnvelope = {
    data: Array<RecebiveisInfoCreateManyNfeInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebiveisInfoCreateManySacadoInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    minibancoId: Scalars["Int"];
    nfeId?: InputMaybe<Scalars["Int"]>;
    prazo?: InputMaybe<Scalars["Int"]>;
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisInfoCreateManySacadoInputEnvelope = {
    data: Array<RecebiveisInfoCreateManySacadoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebiveisInfoCreateNestedManyWithoutCedenteInput = {
    connect?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisInfoCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<RecebiveisInfoCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<RecebiveisInfoCreateManyCedenteInputEnvelope>;
};

export type RecebiveisInfoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisInfoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<RecebiveisInfoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<RecebiveisInfoCreateManyMinibancoInputEnvelope>;
};

export type RecebiveisInfoCreateNestedManyWithoutNfeInput = {
    connect?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisInfoCreateOrConnectWithoutNfeInput>>;
    create?: InputMaybe<Array<RecebiveisInfoCreateWithoutNfeInput>>;
    createMany?: InputMaybe<RecebiveisInfoCreateManyNfeInputEnvelope>;
};

export type RecebiveisInfoCreateNestedManyWithoutSacadoInput = {
    connect?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisInfoCreateOrConnectWithoutSacadoInput>>;
    create?: InputMaybe<Array<RecebiveisInfoCreateWithoutSacadoInput>>;
    createMany?: InputMaybe<RecebiveisInfoCreateManySacadoInputEnvelope>;
};

export type RecebiveisInfoCreateOrConnectWithoutCedenteInput = {
    create: RecebiveisInfoCreateWithoutCedenteInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type RecebiveisInfoCreateOrConnectWithoutMinibancoInput = {
    create: RecebiveisInfoCreateWithoutMinibancoInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type RecebiveisInfoCreateOrConnectWithoutNfeInput = {
    create: RecebiveisInfoCreateWithoutNfeInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type RecebiveisInfoCreateOrConnectWithoutSacadoInput = {
    create: RecebiveisInfoCreateWithoutSacadoInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type RecebiveisInfoCreateWithoutCedenteInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInfoInput;
    nfe?: InputMaybe<NFeCreateNestedOneWithoutRecebiveisInfoInput>;
    prazo?: InputMaybe<Scalars["Int"]>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInfoInput;
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisInfoCreateWithoutMinibancoInput = {
    cedente: CedenteCreateNestedOneWithoutRecebiveisInfoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    nfe?: InputMaybe<NFeCreateNestedOneWithoutRecebiveisInfoInput>;
    prazo?: InputMaybe<Scalars["Int"]>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInfoInput;
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisInfoCreateWithoutNfeInput = {
    cedente: CedenteCreateNestedOneWithoutRecebiveisInfoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInfoInput;
    prazo?: InputMaybe<Scalars["Int"]>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInfoInput;
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisInfoCreateWithoutSacadoInput = {
    cedente: CedenteCreateNestedOneWithoutRecebiveisInfoInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInfoInput;
    nfe?: InputMaybe<NFeCreateNestedOneWithoutRecebiveisInfoInput>;
    prazo?: InputMaybe<Scalars["Int"]>;
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisInfoGroupBy = {
    __typename?: "RecebiveisInfoGroupBy";
    _avg?: Maybe<RecebiveisInfoAvgAggregate>;
    _count?: Maybe<RecebiveisInfoCountAggregate>;
    _max?: Maybe<RecebiveisInfoMaxAggregate>;
    _min?: Maybe<RecebiveisInfoMinAggregate>;
    _sum?: Maybe<RecebiveisInfoSumAggregate>;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    minibancoId: Scalars["Int"];
    nfeId?: Maybe<Scalars["Int"]>;
    prazo?: Maybe<Scalars["Int"]>;
    sacadoId: Scalars["Int"];
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisInfoListRelationFilter = {
    every?: InputMaybe<RecebiveisInfoWhereInput>;
    none?: InputMaybe<RecebiveisInfoWhereInput>;
    some?: InputMaybe<RecebiveisInfoWhereInput>;
};

export type RecebiveisInfoMaxAggregate = {
    __typename?: "RecebiveisInfoMaxAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    dtEmissao?: Maybe<Scalars["DateTime"]>;
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["String"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    nfeId?: Maybe<Scalars["Int"]>;
    prazo?: Maybe<Scalars["Int"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
    status?: Maybe<Scalars["String"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type RecebiveisInfoMaxOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    prazo?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebiveisInfoMinAggregate = {
    __typename?: "RecebiveisInfoMinAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    dtEmissao?: Maybe<Scalars["DateTime"]>;
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["String"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    nfeId?: Maybe<Scalars["Int"]>;
    prazo?: Maybe<Scalars["Int"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
    status?: Maybe<Scalars["String"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type RecebiveisInfoMinOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    prazo?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebiveisInfoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type RecebiveisInfoOrderByWithAggregationInput = {
    _avg?: InputMaybe<RecebiveisInfoAvgOrderByAggregateInput>;
    _count?: InputMaybe<RecebiveisInfoCountOrderByAggregateInput>;
    _max?: InputMaybe<RecebiveisInfoMaxOrderByAggregateInput>;
    _min?: InputMaybe<RecebiveisInfoMinOrderByAggregateInput>;
    _sum?: InputMaybe<RecebiveisInfoSumOrderByAggregateInput>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    prazo?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebiveisInfoOrderByWithRelationInput = {
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    nfe?: InputMaybe<NFeOrderByWithRelationInput>;
    nfeId?: InputMaybe<SortOrder>;
    prazo?: InputMaybe<SortOrder>;
    sacado?: InputMaybe<SacadoOrderByWithRelationInput>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export enum RecebiveisInfoScalarFieldEnum {
    CedenteId = "cedenteId",
    CreatedAt = "createdAt",
    DtEmissao = "dtEmissao",
    DtVencimento = "dtVencimento",
    Id = "id",
    MinibancoId = "minibancoId",
    NfeId = "nfeId",
    Prazo = "prazo",
    SacadoId = "sacadoId",
    Status = "status",
    Valor = "valor",
}

export type RecebiveisInfoScalarWhereInput = {
    AND?: InputMaybe<Array<RecebiveisInfoScalarWhereInput>>;
    NOT?: InputMaybe<Array<RecebiveisInfoScalarWhereInput>>;
    OR?: InputMaybe<Array<RecebiveisInfoScalarWhereInput>>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    dtEmissao?: InputMaybe<DateTimeFilter>;
    dtVencimento?: InputMaybe<DateTimeNullableFilter>;
    id?: InputMaybe<StringFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    nfeId?: InputMaybe<IntNullableFilter>;
    prazo?: InputMaybe<IntNullableFilter>;
    sacadoId?: InputMaybe<IntFilter>;
    status?: InputMaybe<StringFilter>;
    valor?: InputMaybe<DecimalFilter>;
};

export type RecebiveisInfoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<RecebiveisInfoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<RecebiveisInfoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<RecebiveisInfoScalarWhereWithAggregatesInput>>;
    cedenteId?: InputMaybe<IntWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    dtEmissao?: InputMaybe<DateTimeWithAggregatesFilter>;
    dtVencimento?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
    id?: InputMaybe<StringWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    nfeId?: InputMaybe<IntNullableWithAggregatesFilter>;
    prazo?: InputMaybe<IntNullableWithAggregatesFilter>;
    sacadoId?: InputMaybe<IntWithAggregatesFilter>;
    status?: InputMaybe<StringWithAggregatesFilter>;
    valor?: InputMaybe<DecimalWithAggregatesFilter>;
};

export type RecebiveisInfoSumAggregate = {
    __typename?: "RecebiveisInfoSumAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    nfeId?: Maybe<Scalars["Int"]>;
    prazo?: Maybe<Scalars["Int"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type RecebiveisInfoSumOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    prazo?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebiveisInfoUpdateInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisInfoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: InputMaybe<StringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisInfoNestedInput>;
    nfe?: InputMaybe<NFeUpdateOneWithoutRecebiveisInfoNestedInput>;
    prazo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisInfoNestedInput>;
    status?: InputMaybe<StringFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebiveisInfoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: InputMaybe<StringFieldUpdateOperationsInput>;
    prazo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    status?: InputMaybe<StringFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebiveisInfoUpdateManyWithWhereWithoutCedenteInput = {
    data: RecebiveisInfoUpdateManyMutationInput;
    where: RecebiveisInfoScalarWhereInput;
};

export type RecebiveisInfoUpdateManyWithWhereWithoutMinibancoInput = {
    data: RecebiveisInfoUpdateManyMutationInput;
    where: RecebiveisInfoScalarWhereInput;
};

export type RecebiveisInfoUpdateManyWithWhereWithoutNfeInput = {
    data: RecebiveisInfoUpdateManyMutationInput;
    where: RecebiveisInfoScalarWhereInput;
};

export type RecebiveisInfoUpdateManyWithWhereWithoutSacadoInput = {
    data: RecebiveisInfoUpdateManyMutationInput;
    where: RecebiveisInfoScalarWhereInput;
};

export type RecebiveisInfoUpdateManyWithoutCedenteNestedInput = {
    connect?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisInfoCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<RecebiveisInfoCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<RecebiveisInfoCreateManyCedenteInputEnvelope>;
    delete?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebiveisInfoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebiveisInfoUpdateWithWhereUniqueWithoutCedenteInput>>;
    updateMany?: InputMaybe<Array<RecebiveisInfoUpdateManyWithWhereWithoutCedenteInput>>;
    upsert?: InputMaybe<Array<RecebiveisInfoUpsertWithWhereUniqueWithoutCedenteInput>>;
};

export type RecebiveisInfoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisInfoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<RecebiveisInfoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<RecebiveisInfoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebiveisInfoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebiveisInfoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<RecebiveisInfoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<RecebiveisInfoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type RecebiveisInfoUpdateManyWithoutNfeNestedInput = {
    connect?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisInfoCreateOrConnectWithoutNfeInput>>;
    create?: InputMaybe<Array<RecebiveisInfoCreateWithoutNfeInput>>;
    createMany?: InputMaybe<RecebiveisInfoCreateManyNfeInputEnvelope>;
    delete?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebiveisInfoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebiveisInfoUpdateWithWhereUniqueWithoutNfeInput>>;
    updateMany?: InputMaybe<Array<RecebiveisInfoUpdateManyWithWhereWithoutNfeInput>>;
    upsert?: InputMaybe<Array<RecebiveisInfoUpsertWithWhereUniqueWithoutNfeInput>>;
};

export type RecebiveisInfoUpdateManyWithoutSacadoNestedInput = {
    connect?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisInfoCreateOrConnectWithoutSacadoInput>>;
    create?: InputMaybe<Array<RecebiveisInfoCreateWithoutSacadoInput>>;
    createMany?: InputMaybe<RecebiveisInfoCreateManySacadoInputEnvelope>;
    delete?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebiveisInfoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebiveisInfoWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebiveisInfoUpdateWithWhereUniqueWithoutSacadoInput>>;
    updateMany?: InputMaybe<Array<RecebiveisInfoUpdateManyWithWhereWithoutSacadoInput>>;
    upsert?: InputMaybe<Array<RecebiveisInfoUpsertWithWhereUniqueWithoutSacadoInput>>;
};

export type RecebiveisInfoUpdateWithWhereUniqueWithoutCedenteInput = {
    data: RecebiveisInfoUpdateWithoutCedenteInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type RecebiveisInfoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: RecebiveisInfoUpdateWithoutMinibancoInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type RecebiveisInfoUpdateWithWhereUniqueWithoutNfeInput = {
    data: RecebiveisInfoUpdateWithoutNfeInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type RecebiveisInfoUpdateWithWhereUniqueWithoutSacadoInput = {
    data: RecebiveisInfoUpdateWithoutSacadoInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type RecebiveisInfoUpdateWithoutCedenteInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: InputMaybe<StringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisInfoNestedInput>;
    nfe?: InputMaybe<NFeUpdateOneWithoutRecebiveisInfoNestedInput>;
    prazo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisInfoNestedInput>;
    status?: InputMaybe<StringFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebiveisInfoUpdateWithoutMinibancoInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisInfoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: InputMaybe<StringFieldUpdateOperationsInput>;
    nfe?: InputMaybe<NFeUpdateOneWithoutRecebiveisInfoNestedInput>;
    prazo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisInfoNestedInput>;
    status?: InputMaybe<StringFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebiveisInfoUpdateWithoutNfeInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisInfoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: InputMaybe<StringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisInfoNestedInput>;
    prazo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisInfoNestedInput>;
    status?: InputMaybe<StringFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebiveisInfoUpdateWithoutSacadoInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisInfoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: InputMaybe<StringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisInfoNestedInput>;
    nfe?: InputMaybe<NFeUpdateOneWithoutRecebiveisInfoNestedInput>;
    prazo?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
    status?: InputMaybe<StringFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebiveisInfoUpsertWithWhereUniqueWithoutCedenteInput = {
    create: RecebiveisInfoCreateWithoutCedenteInput;
    update: RecebiveisInfoUpdateWithoutCedenteInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type RecebiveisInfoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: RecebiveisInfoCreateWithoutMinibancoInput;
    update: RecebiveisInfoUpdateWithoutMinibancoInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type RecebiveisInfoUpsertWithWhereUniqueWithoutNfeInput = {
    create: RecebiveisInfoCreateWithoutNfeInput;
    update: RecebiveisInfoUpdateWithoutNfeInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type RecebiveisInfoUpsertWithWhereUniqueWithoutSacadoInput = {
    create: RecebiveisInfoCreateWithoutSacadoInput;
    update: RecebiveisInfoUpdateWithoutSacadoInput;
    where: RecebiveisInfoWhereUniqueInput;
};

export type RecebiveisInfoWhereInput = {
    AND?: InputMaybe<Array<RecebiveisInfoWhereInput>>;
    NOT?: InputMaybe<Array<RecebiveisInfoWhereInput>>;
    OR?: InputMaybe<Array<RecebiveisInfoWhereInput>>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    dtEmissao?: InputMaybe<DateTimeFilter>;
    dtVencimento?: InputMaybe<DateTimeNullableFilter>;
    id?: InputMaybe<StringFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    nfe?: InputMaybe<NFeRelationFilter>;
    nfeId?: InputMaybe<IntNullableFilter>;
    prazo?: InputMaybe<IntNullableFilter>;
    sacado?: InputMaybe<SacadoRelationFilter>;
    sacadoId?: InputMaybe<IntFilter>;
    status?: InputMaybe<StringFilter>;
    valor?: InputMaybe<DecimalFilter>;
};

export type RecebiveisInfoWhereUniqueInput = {
    id?: InputMaybe<Scalars["String"]>;
};

/** @view */
export type RecebiveisToAnticipate = {
    __typename?: "RecebiveisToAnticipate";
    cedente: Cedente;
    cedenteId: Scalars["Int"];
    destinatario: Scalars["String"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    isNfe: Scalars["Boolean"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    nfParcelas?: Maybe<Scalars["JSON"]>;
    nfe?: Maybe<NFe>;
    nfeId?: Maybe<Scalars["Int"]>;
    payableType: PayableType;
    sacado: Sacado;
    sacadoId: Scalars["Int"];
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisToAnticipateAvgAggregate = {
    __typename?: "RecebiveisToAnticipateAvgAggregate";
    cedenteId?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    nfeId?: Maybe<Scalars["Float"]>;
    sacadoId?: Maybe<Scalars["Float"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type RecebiveisToAnticipateAvgOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebiveisToAnticipateCountAggregate = {
    __typename?: "RecebiveisToAnticipateCountAggregate";
    _all: Scalars["Int"];
    cedenteId: Scalars["Int"];
    destinatario: Scalars["Int"];
    dtEmissao: Scalars["Int"];
    dtVencimento: Scalars["Int"];
    id: Scalars["Int"];
    isNfe: Scalars["Int"];
    minibancoId: Scalars["Int"];
    nfParcelas: Scalars["Int"];
    nfeId: Scalars["Int"];
    payableType: Scalars["Int"];
    sacadoId: Scalars["Int"];
    status: Scalars["Int"];
    valor: Scalars["Int"];
};

export type RecebiveisToAnticipateCountOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    destinatario?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    isNfe?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nfParcelas?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    payableType?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebiveisToAnticipateCreateInput = {
    cedente: CedenteCreateNestedOneWithoutRecebiveisToAnticipateInput;
    destinatario: Scalars["String"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    isNfe: Scalars["Boolean"];
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisToAnticipateInput;
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    nfe?: InputMaybe<NFeCreateNestedOneWithoutRecebiveisToAnticipateInput>;
    payableType: PayableType;
    sacado: SacadoCreateNestedOneWithoutRecebiveisToAnticipateInput;
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisToAnticipateCreateManyCedenteInput = {
    destinatario: Scalars["String"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    isNfe: Scalars["Boolean"];
    minibancoId: Scalars["Int"];
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    nfeId?: InputMaybe<Scalars["Int"]>;
    payableType: PayableType;
    sacadoId: Scalars["Int"];
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisToAnticipateCreateManyCedenteInputEnvelope = {
    data: Array<RecebiveisToAnticipateCreateManyCedenteInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebiveisToAnticipateCreateManyInput = {
    cedenteId: Scalars["Int"];
    destinatario: Scalars["String"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    isNfe: Scalars["Boolean"];
    minibancoId: Scalars["Int"];
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    nfeId?: InputMaybe<Scalars["Int"]>;
    payableType: PayableType;
    sacadoId: Scalars["Int"];
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisToAnticipateCreateManyMinibancoInput = {
    cedenteId: Scalars["Int"];
    destinatario: Scalars["String"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    isNfe: Scalars["Boolean"];
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    nfeId?: InputMaybe<Scalars["Int"]>;
    payableType: PayableType;
    sacadoId: Scalars["Int"];
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisToAnticipateCreateManyMinibancoInputEnvelope = {
    data: Array<RecebiveisToAnticipateCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebiveisToAnticipateCreateManyNfeInput = {
    cedenteId: Scalars["Int"];
    destinatario: Scalars["String"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    isNfe: Scalars["Boolean"];
    minibancoId: Scalars["Int"];
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    payableType: PayableType;
    sacadoId: Scalars["Int"];
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisToAnticipateCreateManyNfeInputEnvelope = {
    data: Array<RecebiveisToAnticipateCreateManyNfeInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebiveisToAnticipateCreateManySacadoInput = {
    cedenteId: Scalars["Int"];
    destinatario: Scalars["String"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    isNfe: Scalars["Boolean"];
    minibancoId: Scalars["Int"];
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    nfeId?: InputMaybe<Scalars["Int"]>;
    payableType: PayableType;
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisToAnticipateCreateManySacadoInputEnvelope = {
    data: Array<RecebiveisToAnticipateCreateManySacadoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebiveisToAnticipateCreateNestedManyWithoutCedenteInput = {
    connect?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisToAnticipateCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<RecebiveisToAnticipateCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<RecebiveisToAnticipateCreateManyCedenteInputEnvelope>;
};

export type RecebiveisToAnticipateCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisToAnticipateCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<RecebiveisToAnticipateCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<RecebiveisToAnticipateCreateManyMinibancoInputEnvelope>;
};

export type RecebiveisToAnticipateCreateNestedManyWithoutNfeInput = {
    connect?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisToAnticipateCreateOrConnectWithoutNfeInput>>;
    create?: InputMaybe<Array<RecebiveisToAnticipateCreateWithoutNfeInput>>;
    createMany?: InputMaybe<RecebiveisToAnticipateCreateManyNfeInputEnvelope>;
};

export type RecebiveisToAnticipateCreateNestedManyWithoutSacadoInput = {
    connect?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisToAnticipateCreateOrConnectWithoutSacadoInput>>;
    create?: InputMaybe<Array<RecebiveisToAnticipateCreateWithoutSacadoInput>>;
    createMany?: InputMaybe<RecebiveisToAnticipateCreateManySacadoInputEnvelope>;
};

export type RecebiveisToAnticipateCreateOrConnectWithoutCedenteInput = {
    create: RecebiveisToAnticipateCreateWithoutCedenteInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type RecebiveisToAnticipateCreateOrConnectWithoutMinibancoInput = {
    create: RecebiveisToAnticipateCreateWithoutMinibancoInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type RecebiveisToAnticipateCreateOrConnectWithoutNfeInput = {
    create: RecebiveisToAnticipateCreateWithoutNfeInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type RecebiveisToAnticipateCreateOrConnectWithoutSacadoInput = {
    create: RecebiveisToAnticipateCreateWithoutSacadoInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type RecebiveisToAnticipateCreateWithoutCedenteInput = {
    destinatario: Scalars["String"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    isNfe: Scalars["Boolean"];
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisToAnticipateInput;
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    nfe?: InputMaybe<NFeCreateNestedOneWithoutRecebiveisToAnticipateInput>;
    payableType: PayableType;
    sacado: SacadoCreateNestedOneWithoutRecebiveisToAnticipateInput;
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisToAnticipateCreateWithoutMinibancoInput = {
    cedente: CedenteCreateNestedOneWithoutRecebiveisToAnticipateInput;
    destinatario: Scalars["String"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    isNfe: Scalars["Boolean"];
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    nfe?: InputMaybe<NFeCreateNestedOneWithoutRecebiveisToAnticipateInput>;
    payableType: PayableType;
    sacado: SacadoCreateNestedOneWithoutRecebiveisToAnticipateInput;
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisToAnticipateCreateWithoutNfeInput = {
    cedente: CedenteCreateNestedOneWithoutRecebiveisToAnticipateInput;
    destinatario: Scalars["String"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    isNfe: Scalars["Boolean"];
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisToAnticipateInput;
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    payableType: PayableType;
    sacado: SacadoCreateNestedOneWithoutRecebiveisToAnticipateInput;
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisToAnticipateCreateWithoutSacadoInput = {
    cedente: CedenteCreateNestedOneWithoutRecebiveisToAnticipateInput;
    destinatario: Scalars["String"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    isNfe: Scalars["Boolean"];
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisToAnticipateInput;
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    nfe?: InputMaybe<NFeCreateNestedOneWithoutRecebiveisToAnticipateInput>;
    payableType: PayableType;
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisToAnticipateGroupBy = {
    __typename?: "RecebiveisToAnticipateGroupBy";
    _avg?: Maybe<RecebiveisToAnticipateAvgAggregate>;
    _count?: Maybe<RecebiveisToAnticipateCountAggregate>;
    _max?: Maybe<RecebiveisToAnticipateMaxAggregate>;
    _min?: Maybe<RecebiveisToAnticipateMinAggregate>;
    _sum?: Maybe<RecebiveisToAnticipateSumAggregate>;
    cedenteId: Scalars["Int"];
    destinatario: Scalars["String"];
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    id: Scalars["String"];
    isNfe: Scalars["Boolean"];
    minibancoId: Scalars["Int"];
    nfParcelas?: Maybe<Scalars["JSON"]>;
    nfeId?: Maybe<Scalars["Int"]>;
    payableType: PayableType;
    sacadoId: Scalars["Int"];
    status: Scalars["String"];
    valor: Scalars["Decimal"];
};

export type RecebiveisToAnticipateListRelationFilter = {
    every?: InputMaybe<RecebiveisToAnticipateWhereInput>;
    none?: InputMaybe<RecebiveisToAnticipateWhereInput>;
    some?: InputMaybe<RecebiveisToAnticipateWhereInput>;
};

export type RecebiveisToAnticipateMaxAggregate = {
    __typename?: "RecebiveisToAnticipateMaxAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    destinatario?: Maybe<Scalars["String"]>;
    dtEmissao?: Maybe<Scalars["DateTime"]>;
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["String"]>;
    isNfe?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    nfeId?: Maybe<Scalars["Int"]>;
    payableType?: Maybe<PayableType>;
    sacadoId?: Maybe<Scalars["Int"]>;
    status?: Maybe<Scalars["String"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type RecebiveisToAnticipateMaxOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    destinatario?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    isNfe?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    payableType?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebiveisToAnticipateMinAggregate = {
    __typename?: "RecebiveisToAnticipateMinAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    destinatario?: Maybe<Scalars["String"]>;
    dtEmissao?: Maybe<Scalars["DateTime"]>;
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["String"]>;
    isNfe?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    nfeId?: Maybe<Scalars["Int"]>;
    payableType?: Maybe<PayableType>;
    sacadoId?: Maybe<Scalars["Int"]>;
    status?: Maybe<Scalars["String"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type RecebiveisToAnticipateMinOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    destinatario?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    isNfe?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    payableType?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebiveisToAnticipateOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type RecebiveisToAnticipateOrderByWithAggregationInput = {
    _avg?: InputMaybe<RecebiveisToAnticipateAvgOrderByAggregateInput>;
    _count?: InputMaybe<RecebiveisToAnticipateCountOrderByAggregateInput>;
    _max?: InputMaybe<RecebiveisToAnticipateMaxOrderByAggregateInput>;
    _min?: InputMaybe<RecebiveisToAnticipateMinOrderByAggregateInput>;
    _sum?: InputMaybe<RecebiveisToAnticipateSumOrderByAggregateInput>;
    cedenteId?: InputMaybe<SortOrder>;
    destinatario?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    isNfe?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nfParcelas?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    payableType?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebiveisToAnticipateOrderByWithRelationInput = {
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    destinatario?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    isNfe?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    nfParcelas?: InputMaybe<SortOrder>;
    nfe?: InputMaybe<NFeOrderByWithRelationInput>;
    nfeId?: InputMaybe<SortOrder>;
    payableType?: InputMaybe<SortOrder>;
    sacado?: InputMaybe<SacadoOrderByWithRelationInput>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export enum RecebiveisToAnticipateScalarFieldEnum {
    CedenteId = "cedenteId",
    Destinatario = "destinatario",
    DtEmissao = "dtEmissao",
    DtVencimento = "dtVencimento",
    Id = "id",
    IsNfe = "isNfe",
    MinibancoId = "minibancoId",
    NfParcelas = "nfParcelas",
    NfeId = "nfeId",
    PayableType = "payableType",
    SacadoId = "sacadoId",
    Status = "status",
    Valor = "valor",
}

export type RecebiveisToAnticipateScalarWhereInput = {
    AND?: InputMaybe<Array<RecebiveisToAnticipateScalarWhereInput>>;
    NOT?: InputMaybe<Array<RecebiveisToAnticipateScalarWhereInput>>;
    OR?: InputMaybe<Array<RecebiveisToAnticipateScalarWhereInput>>;
    cedenteId?: InputMaybe<IntFilter>;
    destinatario?: InputMaybe<StringFilter>;
    dtEmissao?: InputMaybe<DateTimeFilter>;
    dtVencimento?: InputMaybe<DateTimeNullableFilter>;
    id?: InputMaybe<StringFilter>;
    isNfe?: InputMaybe<BoolFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    nfParcelas?: InputMaybe<JsonNullableFilter>;
    nfeId?: InputMaybe<IntNullableFilter>;
    payableType?: InputMaybe<EnumPayableTypeFilter>;
    sacadoId?: InputMaybe<IntFilter>;
    status?: InputMaybe<StringFilter>;
    valor?: InputMaybe<DecimalFilter>;
};

export type RecebiveisToAnticipateScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<RecebiveisToAnticipateScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<RecebiveisToAnticipateScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<RecebiveisToAnticipateScalarWhereWithAggregatesInput>>;
    cedenteId?: InputMaybe<IntWithAggregatesFilter>;
    destinatario?: InputMaybe<StringWithAggregatesFilter>;
    dtEmissao?: InputMaybe<DateTimeWithAggregatesFilter>;
    dtVencimento?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
    id?: InputMaybe<StringWithAggregatesFilter>;
    isNfe?: InputMaybe<BoolWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    nfParcelas?: InputMaybe<JsonNullableWithAggregatesFilter>;
    nfeId?: InputMaybe<IntNullableWithAggregatesFilter>;
    payableType?: InputMaybe<EnumPayableTypeWithAggregatesFilter>;
    sacadoId?: InputMaybe<IntWithAggregatesFilter>;
    status?: InputMaybe<StringWithAggregatesFilter>;
    valor?: InputMaybe<DecimalWithAggregatesFilter>;
};

export type RecebiveisToAnticipateSumAggregate = {
    __typename?: "RecebiveisToAnticipateSumAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    nfeId?: Maybe<Scalars["Int"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type RecebiveisToAnticipateSumOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    nfeId?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebiveisToAnticipateUpdateInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput>;
    destinatario?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: InputMaybe<StringFieldUpdateOperationsInput>;
    isNfe?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput>;
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    nfe?: InputMaybe<NFeUpdateOneWithoutRecebiveisToAnticipateNestedInput>;
    payableType?: InputMaybe<EnumPayableTypeFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput>;
    status?: InputMaybe<StringFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebiveisToAnticipateUpdateManyMutationInput = {
    destinatario?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: InputMaybe<StringFieldUpdateOperationsInput>;
    isNfe?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    payableType?: InputMaybe<EnumPayableTypeFieldUpdateOperationsInput>;
    status?: InputMaybe<StringFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebiveisToAnticipateUpdateManyWithWhereWithoutCedenteInput = {
    data: RecebiveisToAnticipateUpdateManyMutationInput;
    where: RecebiveisToAnticipateScalarWhereInput;
};

export type RecebiveisToAnticipateUpdateManyWithWhereWithoutMinibancoInput = {
    data: RecebiveisToAnticipateUpdateManyMutationInput;
    where: RecebiveisToAnticipateScalarWhereInput;
};

export type RecebiveisToAnticipateUpdateManyWithWhereWithoutNfeInput = {
    data: RecebiveisToAnticipateUpdateManyMutationInput;
    where: RecebiveisToAnticipateScalarWhereInput;
};

export type RecebiveisToAnticipateUpdateManyWithWhereWithoutSacadoInput = {
    data: RecebiveisToAnticipateUpdateManyMutationInput;
    where: RecebiveisToAnticipateScalarWhereInput;
};

export type RecebiveisToAnticipateUpdateManyWithoutCedenteNestedInput = {
    connect?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisToAnticipateCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<RecebiveisToAnticipateCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<RecebiveisToAnticipateCreateManyCedenteInputEnvelope>;
    delete?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebiveisToAnticipateScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebiveisToAnticipateUpdateWithWhereUniqueWithoutCedenteInput>>;
    updateMany?: InputMaybe<Array<RecebiveisToAnticipateUpdateManyWithWhereWithoutCedenteInput>>;
    upsert?: InputMaybe<Array<RecebiveisToAnticipateUpsertWithWhereUniqueWithoutCedenteInput>>;
};

export type RecebiveisToAnticipateUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisToAnticipateCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<RecebiveisToAnticipateCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<RecebiveisToAnticipateCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebiveisToAnticipateScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebiveisToAnticipateUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<RecebiveisToAnticipateUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<RecebiveisToAnticipateUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type RecebiveisToAnticipateUpdateManyWithoutNfeNestedInput = {
    connect?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisToAnticipateCreateOrConnectWithoutNfeInput>>;
    create?: InputMaybe<Array<RecebiveisToAnticipateCreateWithoutNfeInput>>;
    createMany?: InputMaybe<RecebiveisToAnticipateCreateManyNfeInputEnvelope>;
    delete?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebiveisToAnticipateScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebiveisToAnticipateUpdateWithWhereUniqueWithoutNfeInput>>;
    updateMany?: InputMaybe<Array<RecebiveisToAnticipateUpdateManyWithWhereWithoutNfeInput>>;
    upsert?: InputMaybe<Array<RecebiveisToAnticipateUpsertWithWhereUniqueWithoutNfeInput>>;
};

export type RecebiveisToAnticipateUpdateManyWithoutSacadoNestedInput = {
    connect?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebiveisToAnticipateCreateOrConnectWithoutSacadoInput>>;
    create?: InputMaybe<Array<RecebiveisToAnticipateCreateWithoutSacadoInput>>;
    createMany?: InputMaybe<RecebiveisToAnticipateCreateManySacadoInputEnvelope>;
    delete?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebiveisToAnticipateScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebiveisToAnticipateWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebiveisToAnticipateUpdateWithWhereUniqueWithoutSacadoInput>>;
    updateMany?: InputMaybe<Array<RecebiveisToAnticipateUpdateManyWithWhereWithoutSacadoInput>>;
    upsert?: InputMaybe<Array<RecebiveisToAnticipateUpsertWithWhereUniqueWithoutSacadoInput>>;
};

export type RecebiveisToAnticipateUpdateWithWhereUniqueWithoutCedenteInput = {
    data: RecebiveisToAnticipateUpdateWithoutCedenteInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type RecebiveisToAnticipateUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: RecebiveisToAnticipateUpdateWithoutMinibancoInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type RecebiveisToAnticipateUpdateWithWhereUniqueWithoutNfeInput = {
    data: RecebiveisToAnticipateUpdateWithoutNfeInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type RecebiveisToAnticipateUpdateWithWhereUniqueWithoutSacadoInput = {
    data: RecebiveisToAnticipateUpdateWithoutSacadoInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type RecebiveisToAnticipateUpdateWithoutCedenteInput = {
    destinatario?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: InputMaybe<StringFieldUpdateOperationsInput>;
    isNfe?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput>;
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    nfe?: InputMaybe<NFeUpdateOneWithoutRecebiveisToAnticipateNestedInput>;
    payableType?: InputMaybe<EnumPayableTypeFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput>;
    status?: InputMaybe<StringFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebiveisToAnticipateUpdateWithoutMinibancoInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput>;
    destinatario?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: InputMaybe<StringFieldUpdateOperationsInput>;
    isNfe?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    nfe?: InputMaybe<NFeUpdateOneWithoutRecebiveisToAnticipateNestedInput>;
    payableType?: InputMaybe<EnumPayableTypeFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput>;
    status?: InputMaybe<StringFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebiveisToAnticipateUpdateWithoutNfeInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput>;
    destinatario?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: InputMaybe<StringFieldUpdateOperationsInput>;
    isNfe?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput>;
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    payableType?: InputMaybe<EnumPayableTypeFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput>;
    status?: InputMaybe<StringFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebiveisToAnticipateUpdateWithoutSacadoInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput>;
    destinatario?: InputMaybe<StringFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    id?: InputMaybe<StringFieldUpdateOperationsInput>;
    isNfe?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput>;
    nfParcelas?: InputMaybe<Scalars["JSON"]>;
    nfe?: InputMaybe<NFeUpdateOneWithoutRecebiveisToAnticipateNestedInput>;
    payableType?: InputMaybe<EnumPayableTypeFieldUpdateOperationsInput>;
    status?: InputMaybe<StringFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebiveisToAnticipateUpsertWithWhereUniqueWithoutCedenteInput = {
    create: RecebiveisToAnticipateCreateWithoutCedenteInput;
    update: RecebiveisToAnticipateUpdateWithoutCedenteInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type RecebiveisToAnticipateUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: RecebiveisToAnticipateCreateWithoutMinibancoInput;
    update: RecebiveisToAnticipateUpdateWithoutMinibancoInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type RecebiveisToAnticipateUpsertWithWhereUniqueWithoutNfeInput = {
    create: RecebiveisToAnticipateCreateWithoutNfeInput;
    update: RecebiveisToAnticipateUpdateWithoutNfeInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type RecebiveisToAnticipateUpsertWithWhereUniqueWithoutSacadoInput = {
    create: RecebiveisToAnticipateCreateWithoutSacadoInput;
    update: RecebiveisToAnticipateUpdateWithoutSacadoInput;
    where: RecebiveisToAnticipateWhereUniqueInput;
};

export type RecebiveisToAnticipateWhereInput = {
    AND?: InputMaybe<Array<RecebiveisToAnticipateWhereInput>>;
    NOT?: InputMaybe<Array<RecebiveisToAnticipateWhereInput>>;
    OR?: InputMaybe<Array<RecebiveisToAnticipateWhereInput>>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    destinatario?: InputMaybe<StringFilter>;
    dtEmissao?: InputMaybe<DateTimeFilter>;
    dtVencimento?: InputMaybe<DateTimeNullableFilter>;
    id?: InputMaybe<StringFilter>;
    isNfe?: InputMaybe<BoolFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    nfParcelas?: InputMaybe<JsonNullableFilter>;
    nfe?: InputMaybe<NFeRelationFilter>;
    nfeId?: InputMaybe<IntNullableFilter>;
    payableType?: InputMaybe<EnumPayableTypeFilter>;
    sacado?: InputMaybe<SacadoRelationFilter>;
    sacadoId?: InputMaybe<IntFilter>;
    status?: InputMaybe<StringFilter>;
    valor?: InputMaybe<DecimalFilter>;
};

export type RecebiveisToAnticipateWhereUniqueInput = {
    id?: InputMaybe<Scalars["String"]>;
};

export type Recebivel = {
    __typename?: "Recebivel";
    CTe?: Maybe<CTe>;
    PayableUpdates: Array<PayableUpdates>;
    _count?: Maybe<RecebivelCount>;
    arquivo?: Maybe<Arquivo>;
    arquivoId?: Maybe<Scalars["Int"]>;
    cedente: Cedente;
    cedenteId: Scalars["Int"];
    chaveIntegracao?: Maybe<RecebivelIntegracao>;
    chavePagarContas?: Maybe<RecebivelPagarContas>;
    cmc7?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    dtBaixa?: Maybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    extensionAmount?: Maybe<Scalars["Decimal"]>;
    extensionDate?: Maybe<Scalars["DateTime"]>;
    historico: Array<RecebivelAudit>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    operacao?: Maybe<Operacao>;
    operacaoId?: Maybe<Scalars["Int"]>;
    origem: RecebivelOrigem;
    parcelaNFe?: Maybe<NFeParcela>;
    payableNumber?: Maybe<Scalars["String"]>;
    payableType?: Maybe<PayableType>;
    recebivelOperado?: Maybe<RecebivelOperado>;
    sacado: Sacado;
    sacadoId: Scalars["Int"];
    status: StatusRecebivel;
    updatedAt: Scalars["DateTime"];
    usuario: Usuario;
    usuarioId: Scalars["Int"];
    valor: Scalars["Decimal"];
};

export type RecebivelPayableUpdatesArgs = {
    cursor?: InputMaybe<PayableUpdatesWhereUniqueInput>;
    distinct?: InputMaybe<Array<PayableUpdatesScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<PayableUpdatesOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<PayableUpdatesWhereInput>;
};

export type RecebivelHistoricoArgs = {
    cursor?: InputMaybe<RecebivelAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelAuditWhereInput>;
};

export type RecebivelAudit = {
    __typename?: "RecebivelAudit";
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    recebivel: Recebivel;
    recebivelId: Scalars["Int"];
    service: TipoServico;
    userAgent: Scalars["String"];
    usuario?: Maybe<Usuario>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type RecebivelAuditAvgAggregate = {
    __typename?: "RecebivelAuditAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    recebivelId?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type RecebivelAuditAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type RecebivelAuditCountAggregate = {
    __typename?: "RecebivelAuditCountAggregate";
    _all: Scalars["Int"];
    action: Scalars["Int"];
    createdAt: Scalars["Int"];
    field: Scalars["Int"];
    id: Scalars["Int"];
    ipUsuario: Scalars["Int"];
    newValue: Scalars["Int"];
    oldValue: Scalars["Int"];
    recebivelId: Scalars["Int"];
    service: Scalars["Int"];
    userAgent: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type RecebivelAuditCountOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type RecebivelAuditCreateInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    recebivel: RecebivelCreateNestedOneWithoutHistoricoInput;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistRecebivelInput>;
};

export type RecebivelAuditCreateManyInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    recebivelId: Scalars["Int"];
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type RecebivelAuditCreateManyRecebivelInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type RecebivelAuditCreateManyRecebivelInputEnvelope = {
    data: Array<RecebivelAuditCreateManyRecebivelInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebivelAuditCreateManyUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    recebivelId: Scalars["Int"];
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type RecebivelAuditCreateManyUsuarioInputEnvelope = {
    data: Array<RecebivelAuditCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebivelAuditCreateNestedManyWithoutRecebivelInput = {
    connect?: InputMaybe<Array<RecebivelAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelAuditCreateOrConnectWithoutRecebivelInput>>;
    create?: InputMaybe<Array<RecebivelAuditCreateWithoutRecebivelInput>>;
    createMany?: InputMaybe<RecebivelAuditCreateManyRecebivelInputEnvelope>;
};

export type RecebivelAuditCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<RecebivelAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<RecebivelAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<RecebivelAuditCreateManyUsuarioInputEnvelope>;
};

export type RecebivelAuditCreateOrConnectWithoutRecebivelInput = {
    create: RecebivelAuditCreateWithoutRecebivelInput;
    where: RecebivelAuditWhereUniqueInput;
};

export type RecebivelAuditCreateOrConnectWithoutUsuarioInput = {
    create: RecebivelAuditCreateWithoutUsuarioInput;
    where: RecebivelAuditWhereUniqueInput;
};

export type RecebivelAuditCreateWithoutRecebivelInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistRecebivelInput>;
};

export type RecebivelAuditCreateWithoutUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    recebivel: RecebivelCreateNestedOneWithoutHistoricoInput;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type RecebivelAuditGroupBy = {
    __typename?: "RecebivelAuditGroupBy";
    _avg?: Maybe<RecebivelAuditAvgAggregate>;
    _count?: Maybe<RecebivelAuditCountAggregate>;
    _max?: Maybe<RecebivelAuditMaxAggregate>;
    _min?: Maybe<RecebivelAuditMinAggregate>;
    _sum?: Maybe<RecebivelAuditSumAggregate>;
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    recebivelId: Scalars["Int"];
    service: TipoServico;
    userAgent: Scalars["String"];
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type RecebivelAuditListRelationFilter = {
    every?: InputMaybe<RecebivelAuditWhereInput>;
    none?: InputMaybe<RecebivelAuditWhereInput>;
    some?: InputMaybe<RecebivelAuditWhereInput>;
};

export type RecebivelAuditMaxAggregate = {
    __typename?: "RecebivelAuditMaxAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type RecebivelAuditMaxOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type RecebivelAuditMinAggregate = {
    __typename?: "RecebivelAuditMinAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type RecebivelAuditMinOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type RecebivelAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type RecebivelAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<RecebivelAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<RecebivelAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<RecebivelAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<RecebivelAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<RecebivelAuditSumOrderByAggregateInput>;
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type RecebivelAuditOrderByWithRelationInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    recebivel?: InputMaybe<RecebivelOrderByWithRelationInput>;
    recebivelId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum RecebivelAuditScalarFieldEnum {
    Action = "action",
    CreatedAt = "createdAt",
    Field = "field",
    Id = "id",
    IpUsuario = "ipUsuario",
    NewValue = "newValue",
    OldValue = "oldValue",
    RecebivelId = "recebivelId",
    Service = "service",
    UserAgent = "userAgent",
    UsuarioId = "usuarioId",
}

export type RecebivelAuditScalarWhereInput = {
    AND?: InputMaybe<Array<RecebivelAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<RecebivelAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<RecebivelAuditScalarWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    recebivelId?: InputMaybe<IntFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type RecebivelAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<RecebivelAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<RecebivelAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<RecebivelAuditScalarWhereWithAggregatesInput>>;
    action?: InputMaybe<EnumAuditAcoesWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    field?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ipUsuario?: InputMaybe<StringWithAggregatesFilter>;
    newValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    recebivelId?: InputMaybe<IntWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type RecebivelAuditSumAggregate = {
    __typename?: "RecebivelAuditSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type RecebivelAuditSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type RecebivelAuditUpdateInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneRequiredWithoutHistoricoNestedInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistRecebivelNestedInput>;
};

export type RecebivelAuditUpdateManyMutationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RecebivelAuditUpdateManyWithWhereWithoutRecebivelInput = {
    data: RecebivelAuditUpdateManyMutationInput;
    where: RecebivelAuditScalarWhereInput;
};

export type RecebivelAuditUpdateManyWithWhereWithoutUsuarioInput = {
    data: RecebivelAuditUpdateManyMutationInput;
    where: RecebivelAuditScalarWhereInput;
};

export type RecebivelAuditUpdateManyWithoutRecebivelNestedInput = {
    connect?: InputMaybe<Array<RecebivelAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelAuditCreateOrConnectWithoutRecebivelInput>>;
    create?: InputMaybe<Array<RecebivelAuditCreateWithoutRecebivelInput>>;
    createMany?: InputMaybe<RecebivelAuditCreateManyRecebivelInputEnvelope>;
    delete?: InputMaybe<Array<RecebivelAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebivelAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebivelAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebivelAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebivelAuditUpdateWithWhereUniqueWithoutRecebivelInput>>;
    updateMany?: InputMaybe<Array<RecebivelAuditUpdateManyWithWhereWithoutRecebivelInput>>;
    upsert?: InputMaybe<Array<RecebivelAuditUpsertWithWhereUniqueWithoutRecebivelInput>>;
};

export type RecebivelAuditUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<RecebivelAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<RecebivelAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<RecebivelAuditCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<RecebivelAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebivelAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebivelAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebivelAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebivelAuditUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<RecebivelAuditUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<RecebivelAuditUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type RecebivelAuditUpdateWithWhereUniqueWithoutRecebivelInput = {
    data: RecebivelAuditUpdateWithoutRecebivelInput;
    where: RecebivelAuditWhereUniqueInput;
};

export type RecebivelAuditUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: RecebivelAuditUpdateWithoutUsuarioInput;
    where: RecebivelAuditWhereUniqueInput;
};

export type RecebivelAuditUpdateWithoutRecebivelInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistRecebivelNestedInput>;
};

export type RecebivelAuditUpdateWithoutUsuarioInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneRequiredWithoutHistoricoNestedInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RecebivelAuditUpsertWithWhereUniqueWithoutRecebivelInput = {
    create: RecebivelAuditCreateWithoutRecebivelInput;
    update: RecebivelAuditUpdateWithoutRecebivelInput;
    where: RecebivelAuditWhereUniqueInput;
};

export type RecebivelAuditUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: RecebivelAuditCreateWithoutUsuarioInput;
    update: RecebivelAuditUpdateWithoutUsuarioInput;
    where: RecebivelAuditWhereUniqueInput;
};

export type RecebivelAuditWhereInput = {
    AND?: InputMaybe<Array<RecebivelAuditWhereInput>>;
    NOT?: InputMaybe<Array<RecebivelAuditWhereInput>>;
    OR?: InputMaybe<Array<RecebivelAuditWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    recebivel?: InputMaybe<RecebivelRelationFilter>;
    recebivelId?: InputMaybe<IntFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type RecebivelAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type RecebivelAvgAggregate = {
    __typename?: "RecebivelAvgAggregate";
    arquivoId?: Maybe<Scalars["Float"]>;
    cedenteId?: Maybe<Scalars["Float"]>;
    extensionAmount?: Maybe<Scalars["Decimal"]>;
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    operacaoId?: Maybe<Scalars["Float"]>;
    sacadoId?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type RecebivelAvgOrderByAggregateInput = {
    arquivoId?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebivelCount = {
    __typename?: "RecebivelCount";
    PayableUpdates: Scalars["Int"];
    historico: Scalars["Int"];
};

export type RecebivelCountAggregate = {
    __typename?: "RecebivelCountAggregate";
    _all: Scalars["Int"];
    arquivoId: Scalars["Int"];
    cedenteId: Scalars["Int"];
    cmc7: Scalars["Int"];
    createdAt: Scalars["Int"];
    dtBaixa: Scalars["Int"];
    dtEmissao: Scalars["Int"];
    dtVencimento: Scalars["Int"];
    extensionAmount: Scalars["Int"];
    extensionDate: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    minibancoId: Scalars["Int"];
    operacaoId: Scalars["Int"];
    origem: Scalars["Int"];
    payableNumber: Scalars["Int"];
    payableType: Scalars["Int"];
    sacadoId: Scalars["Int"];
    status: Scalars["Int"];
    updatedAt: Scalars["Int"];
    usuarioId: Scalars["Int"];
    valor: Scalars["Int"];
};

export type RecebivelCountOrderByAggregateInput = {
    arquivoId?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    cmc7?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtBaixa?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    extensionDate?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    payableNumber?: InputMaybe<SortOrder>;
    payableType?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebivelCreateInput = {
    CTe?: InputMaybe<CTeCreateNestedOneWithoutRecebivelInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutPayableInput>;
    arquivo?: InputMaybe<ArquivoCreateNestedOneWithoutRecebivelInput>;
    cedente: CedenteCreateNestedOneWithoutRecebiveisInput;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<RecebivelAuditCreateNestedManyWithoutRecebivelInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInput;
    operacao?: InputMaybe<OperacaoCreateNestedOneWithoutRecebiveisInput>;
    origem?: InputMaybe<RecebivelOrigem>;
    parcelaNFe?: InputMaybe<NFeParcelaCreateNestedOneWithoutRecebivelInput>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    recebivelOperado?: InputMaybe<RecebivelOperadoCreateNestedOneWithoutRecebivelInput>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInput;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutRecebiveisInput;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateManyCedenteInput = {
    arquivoId?: InputMaybe<Scalars["Int"]>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    operacaoId?: InputMaybe<Scalars["Int"]>;
    origem?: InputMaybe<RecebivelOrigem>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    sacadoId: Scalars["Int"];
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
    valor: Scalars["Decimal"];
};

export type RecebivelCreateManyCedenteInputEnvelope = {
    data: Array<RecebivelCreateManyCedenteInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebivelCreateManyInput = {
    arquivoId?: InputMaybe<Scalars["Int"]>;
    cedenteId: Scalars["Int"];
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    operacaoId?: InputMaybe<Scalars["Int"]>;
    origem?: InputMaybe<RecebivelOrigem>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    sacadoId: Scalars["Int"];
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
    valor: Scalars["Decimal"];
};

export type RecebivelCreateManyMinibancoInput = {
    arquivoId?: InputMaybe<Scalars["Int"]>;
    cedenteId: Scalars["Int"];
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    operacaoId?: InputMaybe<Scalars["Int"]>;
    origem?: InputMaybe<RecebivelOrigem>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    sacadoId: Scalars["Int"];
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
    valor: Scalars["Decimal"];
};

export type RecebivelCreateManyMinibancoInputEnvelope = {
    data: Array<RecebivelCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebivelCreateManyOperacaoInput = {
    arquivoId?: InputMaybe<Scalars["Int"]>;
    cedenteId: Scalars["Int"];
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    origem?: InputMaybe<RecebivelOrigem>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    sacadoId: Scalars["Int"];
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
    valor: Scalars["Decimal"];
};

export type RecebivelCreateManyOperacaoInputEnvelope = {
    data: Array<RecebivelCreateManyOperacaoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebivelCreateManySacadoInput = {
    arquivoId?: InputMaybe<Scalars["Int"]>;
    cedenteId: Scalars["Int"];
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    operacaoId?: InputMaybe<Scalars["Int"]>;
    origem?: InputMaybe<RecebivelOrigem>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
    valor: Scalars["Decimal"];
};

export type RecebivelCreateManySacadoInputEnvelope = {
    data: Array<RecebivelCreateManySacadoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebivelCreateManyUsuarioInput = {
    arquivoId?: InputMaybe<Scalars["Int"]>;
    cedenteId: Scalars["Int"];
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    operacaoId?: InputMaybe<Scalars["Int"]>;
    origem?: InputMaybe<RecebivelOrigem>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    sacadoId: Scalars["Int"];
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateManyUsuarioInputEnvelope = {
    data: Array<RecebivelCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebivelCreateNestedManyWithoutCedenteInput = {
    connect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<RecebivelCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<RecebivelCreateManyCedenteInputEnvelope>;
};

export type RecebivelCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<RecebivelCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<RecebivelCreateManyMinibancoInputEnvelope>;
};

export type RecebivelCreateNestedManyWithoutOperacaoInput = {
    connect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelCreateOrConnectWithoutOperacaoInput>>;
    create?: InputMaybe<Array<RecebivelCreateWithoutOperacaoInput>>;
    createMany?: InputMaybe<RecebivelCreateManyOperacaoInputEnvelope>;
};

export type RecebivelCreateNestedManyWithoutSacadoInput = {
    connect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelCreateOrConnectWithoutSacadoInput>>;
    create?: InputMaybe<Array<RecebivelCreateWithoutSacadoInput>>;
    createMany?: InputMaybe<RecebivelCreateManySacadoInputEnvelope>;
};

export type RecebivelCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<RecebivelCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<RecebivelCreateManyUsuarioInputEnvelope>;
};

export type RecebivelCreateNestedOneWithoutArquivoInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutArquivoInput>;
    create?: InputMaybe<RecebivelCreateWithoutArquivoInput>;
};

export type RecebivelCreateNestedOneWithoutCTeInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutCTeInput>;
    create?: InputMaybe<RecebivelCreateWithoutCTeInput>;
};

export type RecebivelCreateNestedOneWithoutChaveIntegracaoInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutChaveIntegracaoInput>;
    create?: InputMaybe<RecebivelCreateWithoutChaveIntegracaoInput>;
};

export type RecebivelCreateNestedOneWithoutChavePagarContasInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutChavePagarContasInput>;
    create?: InputMaybe<RecebivelCreateWithoutChavePagarContasInput>;
};

export type RecebivelCreateNestedOneWithoutHistoricoInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<RecebivelCreateWithoutHistoricoInput>;
};

export type RecebivelCreateNestedOneWithoutParcelaNFeInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutParcelaNFeInput>;
    create?: InputMaybe<RecebivelCreateWithoutParcelaNFeInput>;
};

export type RecebivelCreateNestedOneWithoutPayableUpdatesInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutPayableUpdatesInput>;
    create?: InputMaybe<RecebivelCreateWithoutPayableUpdatesInput>;
};

export type RecebivelCreateNestedOneWithoutRecebivelOperadoInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutRecebivelOperadoInput>;
    create?: InputMaybe<RecebivelCreateWithoutRecebivelOperadoInput>;
};

export type RecebivelCreateOrConnectWithoutArquivoInput = {
    create: RecebivelCreateWithoutArquivoInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelCreateOrConnectWithoutCTeInput = {
    create: RecebivelCreateWithoutCTeInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelCreateOrConnectWithoutCedenteInput = {
    create: RecebivelCreateWithoutCedenteInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelCreateOrConnectWithoutChaveIntegracaoInput = {
    create: RecebivelCreateWithoutChaveIntegracaoInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelCreateOrConnectWithoutChavePagarContasInput = {
    create: RecebivelCreateWithoutChavePagarContasInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelCreateOrConnectWithoutHistoricoInput = {
    create: RecebivelCreateWithoutHistoricoInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelCreateOrConnectWithoutMinibancoInput = {
    create: RecebivelCreateWithoutMinibancoInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelCreateOrConnectWithoutOperacaoInput = {
    create: RecebivelCreateWithoutOperacaoInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelCreateOrConnectWithoutParcelaNFeInput = {
    create: RecebivelCreateWithoutParcelaNFeInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelCreateOrConnectWithoutPayableUpdatesInput = {
    create: RecebivelCreateWithoutPayableUpdatesInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelCreateOrConnectWithoutRecebivelOperadoInput = {
    create: RecebivelCreateWithoutRecebivelOperadoInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelCreateOrConnectWithoutSacadoInput = {
    create: RecebivelCreateWithoutSacadoInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelCreateOrConnectWithoutUsuarioInput = {
    create: RecebivelCreateWithoutUsuarioInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelCreateWithoutArquivoInput = {
    CTe?: InputMaybe<CTeCreateNestedOneWithoutRecebivelInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutPayableInput>;
    cedente: CedenteCreateNestedOneWithoutRecebiveisInput;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<RecebivelAuditCreateNestedManyWithoutRecebivelInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInput;
    operacao?: InputMaybe<OperacaoCreateNestedOneWithoutRecebiveisInput>;
    origem?: InputMaybe<RecebivelOrigem>;
    parcelaNFe?: InputMaybe<NFeParcelaCreateNestedOneWithoutRecebivelInput>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    recebivelOperado?: InputMaybe<RecebivelOperadoCreateNestedOneWithoutRecebivelInput>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInput;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutRecebiveisInput;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateWithoutCTeInput = {
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutPayableInput>;
    arquivo?: InputMaybe<ArquivoCreateNestedOneWithoutRecebivelInput>;
    cedente: CedenteCreateNestedOneWithoutRecebiveisInput;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<RecebivelAuditCreateNestedManyWithoutRecebivelInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInput;
    operacao?: InputMaybe<OperacaoCreateNestedOneWithoutRecebiveisInput>;
    origem?: InputMaybe<RecebivelOrigem>;
    parcelaNFe?: InputMaybe<NFeParcelaCreateNestedOneWithoutRecebivelInput>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    recebivelOperado?: InputMaybe<RecebivelOperadoCreateNestedOneWithoutRecebivelInput>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInput;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutRecebiveisInput;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateWithoutCedenteInput = {
    CTe?: InputMaybe<CTeCreateNestedOneWithoutRecebivelInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutPayableInput>;
    arquivo?: InputMaybe<ArquivoCreateNestedOneWithoutRecebivelInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<RecebivelAuditCreateNestedManyWithoutRecebivelInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInput;
    operacao?: InputMaybe<OperacaoCreateNestedOneWithoutRecebiveisInput>;
    origem?: InputMaybe<RecebivelOrigem>;
    parcelaNFe?: InputMaybe<NFeParcelaCreateNestedOneWithoutRecebivelInput>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    recebivelOperado?: InputMaybe<RecebivelOperadoCreateNestedOneWithoutRecebivelInput>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInput;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutRecebiveisInput;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateWithoutChaveIntegracaoInput = {
    CTe?: InputMaybe<CTeCreateNestedOneWithoutRecebivelInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutPayableInput>;
    arquivo?: InputMaybe<ArquivoCreateNestedOneWithoutRecebivelInput>;
    cedente: CedenteCreateNestedOneWithoutRecebiveisInput;
    chavePagarContas?: InputMaybe<RecebivelPagarContasCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<RecebivelAuditCreateNestedManyWithoutRecebivelInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInput;
    operacao?: InputMaybe<OperacaoCreateNestedOneWithoutRecebiveisInput>;
    origem?: InputMaybe<RecebivelOrigem>;
    parcelaNFe?: InputMaybe<NFeParcelaCreateNestedOneWithoutRecebivelInput>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    recebivelOperado?: InputMaybe<RecebivelOperadoCreateNestedOneWithoutRecebivelInput>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInput;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutRecebiveisInput;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateWithoutChavePagarContasInput = {
    CTe?: InputMaybe<CTeCreateNestedOneWithoutRecebivelInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutPayableInput>;
    arquivo?: InputMaybe<ArquivoCreateNestedOneWithoutRecebivelInput>;
    cedente: CedenteCreateNestedOneWithoutRecebiveisInput;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<RecebivelAuditCreateNestedManyWithoutRecebivelInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInput;
    operacao?: InputMaybe<OperacaoCreateNestedOneWithoutRecebiveisInput>;
    origem?: InputMaybe<RecebivelOrigem>;
    parcelaNFe?: InputMaybe<NFeParcelaCreateNestedOneWithoutRecebivelInput>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    recebivelOperado?: InputMaybe<RecebivelOperadoCreateNestedOneWithoutRecebivelInput>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInput;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutRecebiveisInput;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateWithoutHistoricoInput = {
    CTe?: InputMaybe<CTeCreateNestedOneWithoutRecebivelInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutPayableInput>;
    arquivo?: InputMaybe<ArquivoCreateNestedOneWithoutRecebivelInput>;
    cedente: CedenteCreateNestedOneWithoutRecebiveisInput;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInput;
    operacao?: InputMaybe<OperacaoCreateNestedOneWithoutRecebiveisInput>;
    origem?: InputMaybe<RecebivelOrigem>;
    parcelaNFe?: InputMaybe<NFeParcelaCreateNestedOneWithoutRecebivelInput>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    recebivelOperado?: InputMaybe<RecebivelOperadoCreateNestedOneWithoutRecebivelInput>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInput;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutRecebiveisInput;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateWithoutMinibancoInput = {
    CTe?: InputMaybe<CTeCreateNestedOneWithoutRecebivelInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutPayableInput>;
    arquivo?: InputMaybe<ArquivoCreateNestedOneWithoutRecebivelInput>;
    cedente: CedenteCreateNestedOneWithoutRecebiveisInput;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<RecebivelAuditCreateNestedManyWithoutRecebivelInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    operacao?: InputMaybe<OperacaoCreateNestedOneWithoutRecebiveisInput>;
    origem?: InputMaybe<RecebivelOrigem>;
    parcelaNFe?: InputMaybe<NFeParcelaCreateNestedOneWithoutRecebivelInput>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    recebivelOperado?: InputMaybe<RecebivelOperadoCreateNestedOneWithoutRecebivelInput>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInput;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutRecebiveisInput;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateWithoutOperacaoInput = {
    CTe?: InputMaybe<CTeCreateNestedOneWithoutRecebivelInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutPayableInput>;
    arquivo?: InputMaybe<ArquivoCreateNestedOneWithoutRecebivelInput>;
    cedente: CedenteCreateNestedOneWithoutRecebiveisInput;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<RecebivelAuditCreateNestedManyWithoutRecebivelInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInput;
    origem?: InputMaybe<RecebivelOrigem>;
    parcelaNFe?: InputMaybe<NFeParcelaCreateNestedOneWithoutRecebivelInput>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    recebivelOperado?: InputMaybe<RecebivelOperadoCreateNestedOneWithoutRecebivelInput>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInput;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutRecebiveisInput;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateWithoutParcelaNFeInput = {
    CTe?: InputMaybe<CTeCreateNestedOneWithoutRecebivelInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutPayableInput>;
    arquivo?: InputMaybe<ArquivoCreateNestedOneWithoutRecebivelInput>;
    cedente: CedenteCreateNestedOneWithoutRecebiveisInput;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<RecebivelAuditCreateNestedManyWithoutRecebivelInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInput;
    operacao?: InputMaybe<OperacaoCreateNestedOneWithoutRecebiveisInput>;
    origem?: InputMaybe<RecebivelOrigem>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    recebivelOperado?: InputMaybe<RecebivelOperadoCreateNestedOneWithoutRecebivelInput>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInput;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutRecebiveisInput;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateWithoutPayableUpdatesInput = {
    CTe?: InputMaybe<CTeCreateNestedOneWithoutRecebivelInput>;
    arquivo?: InputMaybe<ArquivoCreateNestedOneWithoutRecebivelInput>;
    cedente: CedenteCreateNestedOneWithoutRecebiveisInput;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<RecebivelAuditCreateNestedManyWithoutRecebivelInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInput;
    operacao?: InputMaybe<OperacaoCreateNestedOneWithoutRecebiveisInput>;
    origem?: InputMaybe<RecebivelOrigem>;
    parcelaNFe?: InputMaybe<NFeParcelaCreateNestedOneWithoutRecebivelInput>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    recebivelOperado?: InputMaybe<RecebivelOperadoCreateNestedOneWithoutRecebivelInput>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInput;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutRecebiveisInput;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateWithoutRecebivelOperadoInput = {
    CTe?: InputMaybe<CTeCreateNestedOneWithoutRecebivelInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutPayableInput>;
    arquivo?: InputMaybe<ArquivoCreateNestedOneWithoutRecebivelInput>;
    cedente: CedenteCreateNestedOneWithoutRecebiveisInput;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<RecebivelAuditCreateNestedManyWithoutRecebivelInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInput;
    operacao?: InputMaybe<OperacaoCreateNestedOneWithoutRecebiveisInput>;
    origem?: InputMaybe<RecebivelOrigem>;
    parcelaNFe?: InputMaybe<NFeParcelaCreateNestedOneWithoutRecebivelInput>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInput;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutRecebiveisInput;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateWithoutSacadoInput = {
    CTe?: InputMaybe<CTeCreateNestedOneWithoutRecebivelInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutPayableInput>;
    arquivo?: InputMaybe<ArquivoCreateNestedOneWithoutRecebivelInput>;
    cedente: CedenteCreateNestedOneWithoutRecebiveisInput;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<RecebivelAuditCreateNestedManyWithoutRecebivelInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInput;
    operacao?: InputMaybe<OperacaoCreateNestedOneWithoutRecebiveisInput>;
    origem?: InputMaybe<RecebivelOrigem>;
    parcelaNFe?: InputMaybe<NFeParcelaCreateNestedOneWithoutRecebivelInput>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    recebivelOperado?: InputMaybe<RecebivelOperadoCreateNestedOneWithoutRecebivelInput>;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutRecebiveisInput;
    valor: Scalars["Decimal"];
};

export type RecebivelCreateWithoutUsuarioInput = {
    CTe?: InputMaybe<CTeCreateNestedOneWithoutRecebivelInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesCreateNestedManyWithoutPayableInput>;
    arquivo?: InputMaybe<ArquivoCreateNestedOneWithoutRecebivelInput>;
    cedente: CedenteCreateNestedOneWithoutRecebiveisInput;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasCreateNestedOneWithoutRecebivelInput>;
    cmc7?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    dtBaixa?: InputMaybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: InputMaybe<Scalars["DateTime"]>;
    extensionAmount?: InputMaybe<Scalars["Decimal"]>;
    extensionDate?: InputMaybe<Scalars["DateTime"]>;
    historico?: InputMaybe<RecebivelAuditCreateNestedManyWithoutRecebivelInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutRecebiveisInput;
    operacao?: InputMaybe<OperacaoCreateNestedOneWithoutRecebiveisInput>;
    origem?: InputMaybe<RecebivelOrigem>;
    parcelaNFe?: InputMaybe<NFeParcelaCreateNestedOneWithoutRecebivelInput>;
    payableNumber?: InputMaybe<Scalars["String"]>;
    payableType?: InputMaybe<PayableType>;
    recebivelOperado?: InputMaybe<RecebivelOperadoCreateNestedOneWithoutRecebivelInput>;
    sacado: SacadoCreateNestedOneWithoutRecebiveisInput;
    status?: InputMaybe<StatusRecebivel>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valor: Scalars["Decimal"];
};

export type RecebivelGroupBy = {
    __typename?: "RecebivelGroupBy";
    _avg?: Maybe<RecebivelAvgAggregate>;
    _count?: Maybe<RecebivelCountAggregate>;
    _max?: Maybe<RecebivelMaxAggregate>;
    _min?: Maybe<RecebivelMinAggregate>;
    _sum?: Maybe<RecebivelSumAggregate>;
    arquivoId?: Maybe<Scalars["Int"]>;
    cedenteId: Scalars["Int"];
    cmc7?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    dtBaixa?: Maybe<Scalars["DateTime"]>;
    dtEmissao: Scalars["DateTime"];
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    extensionAmount?: Maybe<Scalars["Decimal"]>;
    extensionDate?: Maybe<Scalars["DateTime"]>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibancoId: Scalars["Int"];
    operacaoId?: Maybe<Scalars["Int"]>;
    origem: RecebivelOrigem;
    payableNumber?: Maybe<Scalars["String"]>;
    payableType?: Maybe<PayableType>;
    sacadoId: Scalars["Int"];
    status: StatusRecebivel;
    updatedAt: Scalars["DateTime"];
    usuarioId: Scalars["Int"];
    valor: Scalars["Decimal"];
};

export type RecebivelIntegracao = {
    __typename?: "RecebivelIntegracao";
    chave: Scalars["Int"];
    minibank?: Maybe<Minibanco>;
    minibankId?: Maybe<Scalars["Int"]>;
    recebivel: Recebivel;
    recebivelId: Scalars["Int"];
    source: Erp;
};

export type RecebivelIntegracaoAvgAggregate = {
    __typename?: "RecebivelIntegracaoAvgAggregate";
    chave?: Maybe<Scalars["Float"]>;
    minibankId?: Maybe<Scalars["Float"]>;
    recebivelId?: Maybe<Scalars["Float"]>;
};

export type RecebivelIntegracaoAvgOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type RecebivelIntegracaoChaveSourceMinibankIdCompoundUniqueInput = {
    chave: Scalars["Int"];
    minibankId: Scalars["Int"];
    source: Erp;
};

export type RecebivelIntegracaoCountAggregate = {
    __typename?: "RecebivelIntegracaoCountAggregate";
    _all: Scalars["Int"];
    chave: Scalars["Int"];
    minibankId: Scalars["Int"];
    recebivelId: Scalars["Int"];
    source: Scalars["Int"];
};

export type RecebivelIntegracaoCountOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
};

export type RecebivelIntegracaoCreateInput = {
    chave: Scalars["Int"];
    minibank?: InputMaybe<MinibancoCreateNestedOneWithoutRecebivelIntegracaoInput>;
    recebivel: RecebivelCreateNestedOneWithoutChaveIntegracaoInput;
    source?: InputMaybe<Erp>;
};

export type RecebivelIntegracaoCreateManyInput = {
    chave: Scalars["Int"];
    minibankId?: InputMaybe<Scalars["Int"]>;
    recebivelId: Scalars["Int"];
    source?: InputMaybe<Erp>;
};

export type RecebivelIntegracaoCreateManyMinibankInput = {
    chave: Scalars["Int"];
    recebivelId: Scalars["Int"];
    source?: InputMaybe<Erp>;
};

export type RecebivelIntegracaoCreateManyMinibankInputEnvelope = {
    data: Array<RecebivelIntegracaoCreateManyMinibankInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type RecebivelIntegracaoCreateNestedManyWithoutMinibankInput = {
    connect?: InputMaybe<Array<RecebivelIntegracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelIntegracaoCreateOrConnectWithoutMinibankInput>>;
    create?: InputMaybe<Array<RecebivelIntegracaoCreateWithoutMinibankInput>>;
    createMany?: InputMaybe<RecebivelIntegracaoCreateManyMinibankInputEnvelope>;
};

export type RecebivelIntegracaoCreateNestedOneWithoutRecebivelInput = {
    connect?: InputMaybe<RecebivelIntegracaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelIntegracaoCreateOrConnectWithoutRecebivelInput>;
    create?: InputMaybe<RecebivelIntegracaoCreateWithoutRecebivelInput>;
};

export type RecebivelIntegracaoCreateOrConnectWithoutMinibankInput = {
    create: RecebivelIntegracaoCreateWithoutMinibankInput;
    where: RecebivelIntegracaoWhereUniqueInput;
};

export type RecebivelIntegracaoCreateOrConnectWithoutRecebivelInput = {
    create: RecebivelIntegracaoCreateWithoutRecebivelInput;
    where: RecebivelIntegracaoWhereUniqueInput;
};

export type RecebivelIntegracaoCreateWithoutMinibankInput = {
    chave: Scalars["Int"];
    recebivel: RecebivelCreateNestedOneWithoutChaveIntegracaoInput;
    source?: InputMaybe<Erp>;
};

export type RecebivelIntegracaoCreateWithoutRecebivelInput = {
    chave: Scalars["Int"];
    minibank?: InputMaybe<MinibancoCreateNestedOneWithoutRecebivelIntegracaoInput>;
    source?: InputMaybe<Erp>;
};

export type RecebivelIntegracaoGroupBy = {
    __typename?: "RecebivelIntegracaoGroupBy";
    _avg?: Maybe<RecebivelIntegracaoAvgAggregate>;
    _count?: Maybe<RecebivelIntegracaoCountAggregate>;
    _max?: Maybe<RecebivelIntegracaoMaxAggregate>;
    _min?: Maybe<RecebivelIntegracaoMinAggregate>;
    _sum?: Maybe<RecebivelIntegracaoSumAggregate>;
    chave: Scalars["Int"];
    minibankId?: Maybe<Scalars["Int"]>;
    recebivelId: Scalars["Int"];
    source: Erp;
};

export type RecebivelIntegracaoListRelationFilter = {
    every?: InputMaybe<RecebivelIntegracaoWhereInput>;
    none?: InputMaybe<RecebivelIntegracaoWhereInput>;
    some?: InputMaybe<RecebivelIntegracaoWhereInput>;
};

export type RecebivelIntegracaoMaxAggregate = {
    __typename?: "RecebivelIntegracaoMaxAggregate";
    chave?: Maybe<Scalars["Int"]>;
    minibankId?: Maybe<Scalars["Int"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
    source?: Maybe<Erp>;
};

export type RecebivelIntegracaoMaxOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
};

export type RecebivelIntegracaoMinAggregate = {
    __typename?: "RecebivelIntegracaoMinAggregate";
    chave?: Maybe<Scalars["Int"]>;
    minibankId?: Maybe<Scalars["Int"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
    source?: Maybe<Erp>;
};

export type RecebivelIntegracaoMinOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
};

export type RecebivelIntegracaoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type RecebivelIntegracaoOrderByWithAggregationInput = {
    _avg?: InputMaybe<RecebivelIntegracaoAvgOrderByAggregateInput>;
    _count?: InputMaybe<RecebivelIntegracaoCountOrderByAggregateInput>;
    _max?: InputMaybe<RecebivelIntegracaoMaxOrderByAggregateInput>;
    _min?: InputMaybe<RecebivelIntegracaoMinOrderByAggregateInput>;
    _sum?: InputMaybe<RecebivelIntegracaoSumOrderByAggregateInput>;
    chave?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
};

export type RecebivelIntegracaoOrderByWithRelationInput = {
    chave?: InputMaybe<SortOrder>;
    minibank?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibankId?: InputMaybe<SortOrder>;
    recebivel?: InputMaybe<RecebivelOrderByWithRelationInput>;
    recebivelId?: InputMaybe<SortOrder>;
    source?: InputMaybe<SortOrder>;
};

export type RecebivelIntegracaoRelationFilter = {
    is?: InputMaybe<RecebivelIntegracaoWhereInput>;
    isNot?: InputMaybe<RecebivelIntegracaoWhereInput>;
};

export enum RecebivelIntegracaoScalarFieldEnum {
    Chave = "chave",
    MinibankId = "minibankId",
    RecebivelId = "recebivelId",
    Source = "source",
}

export type RecebivelIntegracaoScalarWhereInput = {
    AND?: InputMaybe<Array<RecebivelIntegracaoScalarWhereInput>>;
    NOT?: InputMaybe<Array<RecebivelIntegracaoScalarWhereInput>>;
    OR?: InputMaybe<Array<RecebivelIntegracaoScalarWhereInput>>;
    chave?: InputMaybe<IntFilter>;
    minibankId?: InputMaybe<IntNullableFilter>;
    recebivelId?: InputMaybe<IntFilter>;
    source?: InputMaybe<EnumErpFilter>;
};

export type RecebivelIntegracaoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<RecebivelIntegracaoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<RecebivelIntegracaoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<RecebivelIntegracaoScalarWhereWithAggregatesInput>>;
    chave?: InputMaybe<IntWithAggregatesFilter>;
    minibankId?: InputMaybe<IntNullableWithAggregatesFilter>;
    recebivelId?: InputMaybe<IntWithAggregatesFilter>;
    source?: InputMaybe<EnumErpWithAggregatesFilter>;
};

export type RecebivelIntegracaoSumAggregate = {
    __typename?: "RecebivelIntegracaoSumAggregate";
    chave?: Maybe<Scalars["Int"]>;
    minibankId?: Maybe<Scalars["Int"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
};

export type RecebivelIntegracaoSumOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    minibankId?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type RecebivelIntegracaoUpdateInput = {
    chave?: InputMaybe<IntFieldUpdateOperationsInput>;
    minibank?: InputMaybe<MinibancoUpdateOneWithoutRecebivelIntegracaoNestedInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneRequiredWithoutChaveIntegracaoNestedInput>;
    source?: InputMaybe<EnumErpFieldUpdateOperationsInput>;
};

export type RecebivelIntegracaoUpdateManyMutationInput = {
    chave?: InputMaybe<IntFieldUpdateOperationsInput>;
    source?: InputMaybe<EnumErpFieldUpdateOperationsInput>;
};

export type RecebivelIntegracaoUpdateManyWithWhereWithoutMinibankInput = {
    data: RecebivelIntegracaoUpdateManyMutationInput;
    where: RecebivelIntegracaoScalarWhereInput;
};

export type RecebivelIntegracaoUpdateManyWithoutMinibankNestedInput = {
    connect?: InputMaybe<Array<RecebivelIntegracaoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelIntegracaoCreateOrConnectWithoutMinibankInput>>;
    create?: InputMaybe<Array<RecebivelIntegracaoCreateWithoutMinibankInput>>;
    createMany?: InputMaybe<RecebivelIntegracaoCreateManyMinibankInputEnvelope>;
    delete?: InputMaybe<Array<RecebivelIntegracaoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebivelIntegracaoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebivelIntegracaoWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebivelIntegracaoWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebivelIntegracaoUpdateWithWhereUniqueWithoutMinibankInput>>;
    updateMany?: InputMaybe<Array<RecebivelIntegracaoUpdateManyWithWhereWithoutMinibankInput>>;
    upsert?: InputMaybe<Array<RecebivelIntegracaoUpsertWithWhereUniqueWithoutMinibankInput>>;
};

export type RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput = {
    connect?: InputMaybe<RecebivelIntegracaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelIntegracaoCreateOrConnectWithoutRecebivelInput>;
    create?: InputMaybe<RecebivelIntegracaoCreateWithoutRecebivelInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<RecebivelIntegracaoUpdateWithoutRecebivelInput>;
    upsert?: InputMaybe<RecebivelIntegracaoUpsertWithoutRecebivelInput>;
};

export type RecebivelIntegracaoUpdateWithWhereUniqueWithoutMinibankInput = {
    data: RecebivelIntegracaoUpdateWithoutMinibankInput;
    where: RecebivelIntegracaoWhereUniqueInput;
};

export type RecebivelIntegracaoUpdateWithoutMinibankInput = {
    chave?: InputMaybe<IntFieldUpdateOperationsInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneRequiredWithoutChaveIntegracaoNestedInput>;
    source?: InputMaybe<EnumErpFieldUpdateOperationsInput>;
};

export type RecebivelIntegracaoUpdateWithoutRecebivelInput = {
    chave?: InputMaybe<IntFieldUpdateOperationsInput>;
    minibank?: InputMaybe<MinibancoUpdateOneWithoutRecebivelIntegracaoNestedInput>;
    source?: InputMaybe<EnumErpFieldUpdateOperationsInput>;
};

export type RecebivelIntegracaoUpsertWithWhereUniqueWithoutMinibankInput = {
    create: RecebivelIntegracaoCreateWithoutMinibankInput;
    update: RecebivelIntegracaoUpdateWithoutMinibankInput;
    where: RecebivelIntegracaoWhereUniqueInput;
};

export type RecebivelIntegracaoUpsertWithoutRecebivelInput = {
    create: RecebivelIntegracaoCreateWithoutRecebivelInput;
    update: RecebivelIntegracaoUpdateWithoutRecebivelInput;
};

export type RecebivelIntegracaoWhereInput = {
    AND?: InputMaybe<Array<RecebivelIntegracaoWhereInput>>;
    NOT?: InputMaybe<Array<RecebivelIntegracaoWhereInput>>;
    OR?: InputMaybe<Array<RecebivelIntegracaoWhereInput>>;
    chave?: InputMaybe<IntFilter>;
    minibank?: InputMaybe<MinibancoRelationFilter>;
    minibankId?: InputMaybe<IntNullableFilter>;
    recebivel?: InputMaybe<RecebivelRelationFilter>;
    recebivelId?: InputMaybe<IntFilter>;
    source?: InputMaybe<EnumErpFilter>;
};

export type RecebivelIntegracaoWhereUniqueInput = {
    chave_source_minibankId?: InputMaybe<RecebivelIntegracaoChaveSourceMinibankIdCompoundUniqueInput>;
    recebivelId?: InputMaybe<Scalars["Int"]>;
};

export type RecebivelListRelationFilter = {
    every?: InputMaybe<RecebivelWhereInput>;
    none?: InputMaybe<RecebivelWhereInput>;
    some?: InputMaybe<RecebivelWhereInput>;
};

export type RecebivelMaxAggregate = {
    __typename?: "RecebivelMaxAggregate";
    arquivoId?: Maybe<Scalars["Int"]>;
    cedenteId?: Maybe<Scalars["Int"]>;
    cmc7?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    dtBaixa?: Maybe<Scalars["DateTime"]>;
    dtEmissao?: Maybe<Scalars["DateTime"]>;
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    extensionAmount?: Maybe<Scalars["Decimal"]>;
    extensionDate?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    origem?: Maybe<RecebivelOrigem>;
    payableNumber?: Maybe<Scalars["String"]>;
    payableType?: Maybe<PayableType>;
    sacadoId?: Maybe<Scalars["Int"]>;
    status?: Maybe<StatusRecebivel>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type RecebivelMaxOrderByAggregateInput = {
    arquivoId?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    cmc7?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtBaixa?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    extensionDate?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    payableNumber?: InputMaybe<SortOrder>;
    payableType?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebivelMinAggregate = {
    __typename?: "RecebivelMinAggregate";
    arquivoId?: Maybe<Scalars["Int"]>;
    cedenteId?: Maybe<Scalars["Int"]>;
    cmc7?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    dtBaixa?: Maybe<Scalars["DateTime"]>;
    dtEmissao?: Maybe<Scalars["DateTime"]>;
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    extensionAmount?: Maybe<Scalars["Decimal"]>;
    extensionDate?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    origem?: Maybe<RecebivelOrigem>;
    payableNumber?: Maybe<Scalars["String"]>;
    payableType?: Maybe<PayableType>;
    sacadoId?: Maybe<Scalars["Int"]>;
    status?: Maybe<StatusRecebivel>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type RecebivelMinOrderByAggregateInput = {
    arquivoId?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    cmc7?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtBaixa?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    extensionDate?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    payableNumber?: InputMaybe<SortOrder>;
    payableType?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebivelOperado = {
    __typename?: "RecebivelOperado";
    desagio: Scalars["Decimal"];
    despesas: Scalars["Decimal"];
    multa?: Maybe<Scalars["Decimal"]>;
    recebivel: Recebivel;
    recebivelId: Scalars["Int"];
    valorPago?: Maybe<Scalars["Decimal"]>;
};

export type RecebivelOperadoAvgAggregate = {
    __typename?: "RecebivelOperadoAvgAggregate";
    desagio?: Maybe<Scalars["Decimal"]>;
    despesas?: Maybe<Scalars["Decimal"]>;
    multa?: Maybe<Scalars["Decimal"]>;
    recebivelId?: Maybe<Scalars["Float"]>;
    valorPago?: Maybe<Scalars["Decimal"]>;
};

export type RecebivelOperadoAvgOrderByAggregateInput = {
    desagio?: InputMaybe<SortOrder>;
    despesas?: InputMaybe<SortOrder>;
    multa?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    valorPago?: InputMaybe<SortOrder>;
};

export type RecebivelOperadoCountAggregate = {
    __typename?: "RecebivelOperadoCountAggregate";
    _all: Scalars["Int"];
    desagio: Scalars["Int"];
    despesas: Scalars["Int"];
    multa: Scalars["Int"];
    recebivelId: Scalars["Int"];
    valorPago: Scalars["Int"];
};

export type RecebivelOperadoCountOrderByAggregateInput = {
    desagio?: InputMaybe<SortOrder>;
    despesas?: InputMaybe<SortOrder>;
    multa?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    valorPago?: InputMaybe<SortOrder>;
};

export type RecebivelOperadoCreateInput = {
    desagio: Scalars["Decimal"];
    despesas: Scalars["Decimal"];
    multa?: InputMaybe<Scalars["Decimal"]>;
    recebivel: RecebivelCreateNestedOneWithoutRecebivelOperadoInput;
    valorPago?: InputMaybe<Scalars["Decimal"]>;
};

export type RecebivelOperadoCreateManyInput = {
    desagio: Scalars["Decimal"];
    despesas: Scalars["Decimal"];
    multa?: InputMaybe<Scalars["Decimal"]>;
    recebivelId: Scalars["Int"];
    valorPago?: InputMaybe<Scalars["Decimal"]>;
};

export type RecebivelOperadoCreateNestedOneWithoutRecebivelInput = {
    connect?: InputMaybe<RecebivelOperadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelOperadoCreateOrConnectWithoutRecebivelInput>;
    create?: InputMaybe<RecebivelOperadoCreateWithoutRecebivelInput>;
};

export type RecebivelOperadoCreateOrConnectWithoutRecebivelInput = {
    create: RecebivelOperadoCreateWithoutRecebivelInput;
    where: RecebivelOperadoWhereUniqueInput;
};

export type RecebivelOperadoCreateWithoutRecebivelInput = {
    desagio: Scalars["Decimal"];
    despesas: Scalars["Decimal"];
    multa?: InputMaybe<Scalars["Decimal"]>;
    valorPago?: InputMaybe<Scalars["Decimal"]>;
};

export type RecebivelOperadoGroupBy = {
    __typename?: "RecebivelOperadoGroupBy";
    _avg?: Maybe<RecebivelOperadoAvgAggregate>;
    _count?: Maybe<RecebivelOperadoCountAggregate>;
    _max?: Maybe<RecebivelOperadoMaxAggregate>;
    _min?: Maybe<RecebivelOperadoMinAggregate>;
    _sum?: Maybe<RecebivelOperadoSumAggregate>;
    desagio: Scalars["Decimal"];
    despesas: Scalars["Decimal"];
    multa?: Maybe<Scalars["Decimal"]>;
    recebivelId: Scalars["Int"];
    valorPago?: Maybe<Scalars["Decimal"]>;
};

export type RecebivelOperadoMaxAggregate = {
    __typename?: "RecebivelOperadoMaxAggregate";
    desagio?: Maybe<Scalars["Decimal"]>;
    despesas?: Maybe<Scalars["Decimal"]>;
    multa?: Maybe<Scalars["Decimal"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
    valorPago?: Maybe<Scalars["Decimal"]>;
};

export type RecebivelOperadoMaxOrderByAggregateInput = {
    desagio?: InputMaybe<SortOrder>;
    despesas?: InputMaybe<SortOrder>;
    multa?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    valorPago?: InputMaybe<SortOrder>;
};

export type RecebivelOperadoMinAggregate = {
    __typename?: "RecebivelOperadoMinAggregate";
    desagio?: Maybe<Scalars["Decimal"]>;
    despesas?: Maybe<Scalars["Decimal"]>;
    multa?: Maybe<Scalars["Decimal"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
    valorPago?: Maybe<Scalars["Decimal"]>;
};

export type RecebivelOperadoMinOrderByAggregateInput = {
    desagio?: InputMaybe<SortOrder>;
    despesas?: InputMaybe<SortOrder>;
    multa?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    valorPago?: InputMaybe<SortOrder>;
};

export type RecebivelOperadoOrderByWithAggregationInput = {
    _avg?: InputMaybe<RecebivelOperadoAvgOrderByAggregateInput>;
    _count?: InputMaybe<RecebivelOperadoCountOrderByAggregateInput>;
    _max?: InputMaybe<RecebivelOperadoMaxOrderByAggregateInput>;
    _min?: InputMaybe<RecebivelOperadoMinOrderByAggregateInput>;
    _sum?: InputMaybe<RecebivelOperadoSumOrderByAggregateInput>;
    desagio?: InputMaybe<SortOrder>;
    despesas?: InputMaybe<SortOrder>;
    multa?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    valorPago?: InputMaybe<SortOrder>;
};

export type RecebivelOperadoOrderByWithRelationInput = {
    desagio?: InputMaybe<SortOrder>;
    despesas?: InputMaybe<SortOrder>;
    multa?: InputMaybe<SortOrder>;
    recebivel?: InputMaybe<RecebivelOrderByWithRelationInput>;
    recebivelId?: InputMaybe<SortOrder>;
    valorPago?: InputMaybe<SortOrder>;
};

export type RecebivelOperadoRelationFilter = {
    is?: InputMaybe<RecebivelOperadoWhereInput>;
    isNot?: InputMaybe<RecebivelOperadoWhereInput>;
};

export enum RecebivelOperadoScalarFieldEnum {
    Desagio = "desagio",
    Despesas = "despesas",
    Multa = "multa",
    RecebivelId = "recebivelId",
    ValorPago = "valorPago",
}

export type RecebivelOperadoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<RecebivelOperadoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<RecebivelOperadoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<RecebivelOperadoScalarWhereWithAggregatesInput>>;
    desagio?: InputMaybe<DecimalWithAggregatesFilter>;
    despesas?: InputMaybe<DecimalWithAggregatesFilter>;
    multa?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    recebivelId?: InputMaybe<IntWithAggregatesFilter>;
    valorPago?: InputMaybe<DecimalNullableWithAggregatesFilter>;
};

export type RecebivelOperadoSumAggregate = {
    __typename?: "RecebivelOperadoSumAggregate";
    desagio?: Maybe<Scalars["Decimal"]>;
    despesas?: Maybe<Scalars["Decimal"]>;
    multa?: Maybe<Scalars["Decimal"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
    valorPago?: Maybe<Scalars["Decimal"]>;
};

export type RecebivelOperadoSumOrderByAggregateInput = {
    desagio?: InputMaybe<SortOrder>;
    despesas?: InputMaybe<SortOrder>;
    multa?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
    valorPago?: InputMaybe<SortOrder>;
};

export type RecebivelOperadoUpdateInput = {
    desagio?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    despesas?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    multa?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneRequiredWithoutRecebivelOperadoNestedInput>;
    valorPago?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type RecebivelOperadoUpdateManyMutationInput = {
    desagio?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    despesas?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    multa?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    valorPago?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type RecebivelOperadoUpdateOneWithoutRecebivelNestedInput = {
    connect?: InputMaybe<RecebivelOperadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelOperadoCreateOrConnectWithoutRecebivelInput>;
    create?: InputMaybe<RecebivelOperadoCreateWithoutRecebivelInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<RecebivelOperadoUpdateWithoutRecebivelInput>;
    upsert?: InputMaybe<RecebivelOperadoUpsertWithoutRecebivelInput>;
};

export type RecebivelOperadoUpdateWithoutRecebivelInput = {
    desagio?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    despesas?: InputMaybe<DecimalFieldUpdateOperationsInput>;
    multa?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    valorPago?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type RecebivelOperadoUpsertWithoutRecebivelInput = {
    create: RecebivelOperadoCreateWithoutRecebivelInput;
    update: RecebivelOperadoUpdateWithoutRecebivelInput;
};

export type RecebivelOperadoWhereInput = {
    AND?: InputMaybe<Array<RecebivelOperadoWhereInput>>;
    NOT?: InputMaybe<Array<RecebivelOperadoWhereInput>>;
    OR?: InputMaybe<Array<RecebivelOperadoWhereInput>>;
    desagio?: InputMaybe<DecimalFilter>;
    despesas?: InputMaybe<DecimalFilter>;
    multa?: InputMaybe<DecimalNullableFilter>;
    recebivel?: InputMaybe<RecebivelRelationFilter>;
    recebivelId?: InputMaybe<IntFilter>;
    valorPago?: InputMaybe<DecimalNullableFilter>;
};

export type RecebivelOperadoWhereUniqueInput = {
    recebivelId?: InputMaybe<Scalars["Int"]>;
};

export type RecebivelOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type RecebivelOrderByWithAggregationInput = {
    _avg?: InputMaybe<RecebivelAvgOrderByAggregateInput>;
    _count?: InputMaybe<RecebivelCountOrderByAggregateInput>;
    _max?: InputMaybe<RecebivelMaxOrderByAggregateInput>;
    _min?: InputMaybe<RecebivelMinOrderByAggregateInput>;
    _sum?: InputMaybe<RecebivelSumOrderByAggregateInput>;
    arquivoId?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    cmc7?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtBaixa?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    extensionDate?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    payableNumber?: InputMaybe<SortOrder>;
    payableType?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebivelOrderByWithRelationInput = {
    CTe?: InputMaybe<CTeOrderByWithRelationInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesOrderByRelationAggregateInput>;
    arquivo?: InputMaybe<ArquivoOrderByWithRelationInput>;
    arquivoId?: InputMaybe<SortOrder>;
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoOrderByWithRelationInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasOrderByWithRelationInput>;
    cmc7?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    dtBaixa?: InputMaybe<SortOrder>;
    dtEmissao?: InputMaybe<SortOrder>;
    dtVencimento?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    extensionDate?: InputMaybe<SortOrder>;
    historico?: InputMaybe<RecebivelAuditOrderByRelationAggregateInput>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    operacao?: InputMaybe<OperacaoOrderByWithRelationInput>;
    operacaoId?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    parcelaNFe?: InputMaybe<NFeParcelaOrderByWithRelationInput>;
    payableNumber?: InputMaybe<SortOrder>;
    payableType?: InputMaybe<SortOrder>;
    recebivelOperado?: InputMaybe<RecebivelOperadoOrderByWithRelationInput>;
    sacado?: InputMaybe<SacadoOrderByWithRelationInput>;
    sacadoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export enum RecebivelOrigem {
    Cheque = "CHEQUE",
    Cte = "CTE",
    Default = "DEFAULT",
    Nfe = "NFE",
    UploadCedente = "UPLOAD_CEDENTE",
    UploadPlanilha = "UPLOAD_PLANILHA",
}

export type RecebivelPagarContas = {
    __typename?: "RecebivelPagarContas";
    chave?: Maybe<Scalars["String"]>;
    minibancoId: Scalars["Int"];
    numeroTitulo: Scalars["Int"];
    recebivel: Recebivel;
    recebivelId: Scalars["Int"];
};

export type RecebivelPagarContasAvgAggregate = {
    __typename?: "RecebivelPagarContasAvgAggregate";
    minibancoId?: Maybe<Scalars["Float"]>;
    numeroTitulo?: Maybe<Scalars["Float"]>;
    recebivelId?: Maybe<Scalars["Float"]>;
};

export type RecebivelPagarContasAvgOrderByAggregateInput = {
    minibancoId?: InputMaybe<SortOrder>;
    numeroTitulo?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type RecebivelPagarContasCountAggregate = {
    __typename?: "RecebivelPagarContasCountAggregate";
    _all: Scalars["Int"];
    chave: Scalars["Int"];
    minibancoId: Scalars["Int"];
    numeroTitulo: Scalars["Int"];
    recebivelId: Scalars["Int"];
};

export type RecebivelPagarContasCountOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    numeroTitulo?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type RecebivelPagarContasCreateInput = {
    chave?: InputMaybe<Scalars["String"]>;
    minibancoId: Scalars["Int"];
    numeroTitulo: Scalars["Int"];
    recebivel: RecebivelCreateNestedOneWithoutChavePagarContasInput;
};

export type RecebivelPagarContasCreateManyInput = {
    chave?: InputMaybe<Scalars["String"]>;
    minibancoId: Scalars["Int"];
    numeroTitulo: Scalars["Int"];
    recebivelId: Scalars["Int"];
};

export type RecebivelPagarContasCreateNestedOneWithoutRecebivelInput = {
    connect?: InputMaybe<RecebivelPagarContasWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelPagarContasCreateOrConnectWithoutRecebivelInput>;
    create?: InputMaybe<RecebivelPagarContasCreateWithoutRecebivelInput>;
};

export type RecebivelPagarContasCreateOrConnectWithoutRecebivelInput = {
    create: RecebivelPagarContasCreateWithoutRecebivelInput;
    where: RecebivelPagarContasWhereUniqueInput;
};

export type RecebivelPagarContasCreateWithoutRecebivelInput = {
    chave?: InputMaybe<Scalars["String"]>;
    minibancoId: Scalars["Int"];
    numeroTitulo: Scalars["Int"];
};

export type RecebivelPagarContasGroupBy = {
    __typename?: "RecebivelPagarContasGroupBy";
    _avg?: Maybe<RecebivelPagarContasAvgAggregate>;
    _count?: Maybe<RecebivelPagarContasCountAggregate>;
    _max?: Maybe<RecebivelPagarContasMaxAggregate>;
    _min?: Maybe<RecebivelPagarContasMinAggregate>;
    _sum?: Maybe<RecebivelPagarContasSumAggregate>;
    chave?: Maybe<Scalars["String"]>;
    minibancoId: Scalars["Int"];
    numeroTitulo: Scalars["Int"];
    recebivelId: Scalars["Int"];
};

export type RecebivelPagarContasMaxAggregate = {
    __typename?: "RecebivelPagarContasMaxAggregate";
    chave?: Maybe<Scalars["String"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    numeroTitulo?: Maybe<Scalars["Int"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
};

export type RecebivelPagarContasMaxOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    numeroTitulo?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type RecebivelPagarContasMinAggregate = {
    __typename?: "RecebivelPagarContasMinAggregate";
    chave?: Maybe<Scalars["String"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    numeroTitulo?: Maybe<Scalars["Int"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
};

export type RecebivelPagarContasMinOrderByAggregateInput = {
    chave?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    numeroTitulo?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type RecebivelPagarContasNumeroTituloMinibancoIdCompoundUniqueInput = {
    minibancoId: Scalars["Int"];
    numeroTitulo: Scalars["Int"];
};

export type RecebivelPagarContasOrderByWithAggregationInput = {
    _avg?: InputMaybe<RecebivelPagarContasAvgOrderByAggregateInput>;
    _count?: InputMaybe<RecebivelPagarContasCountOrderByAggregateInput>;
    _max?: InputMaybe<RecebivelPagarContasMaxOrderByAggregateInput>;
    _min?: InputMaybe<RecebivelPagarContasMinOrderByAggregateInput>;
    _sum?: InputMaybe<RecebivelPagarContasSumOrderByAggregateInput>;
    chave?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    numeroTitulo?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type RecebivelPagarContasOrderByWithRelationInput = {
    chave?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    numeroTitulo?: InputMaybe<SortOrder>;
    recebivel?: InputMaybe<RecebivelOrderByWithRelationInput>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type RecebivelPagarContasRelationFilter = {
    is?: InputMaybe<RecebivelPagarContasWhereInput>;
    isNot?: InputMaybe<RecebivelPagarContasWhereInput>;
};

export enum RecebivelPagarContasScalarFieldEnum {
    Chave = "chave",
    MinibancoId = "minibancoId",
    NumeroTitulo = "numeroTitulo",
    RecebivelId = "recebivelId",
}

export type RecebivelPagarContasScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<RecebivelPagarContasScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<RecebivelPagarContasScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<RecebivelPagarContasScalarWhereWithAggregatesInput>>;
    chave?: InputMaybe<StringNullableWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    numeroTitulo?: InputMaybe<IntWithAggregatesFilter>;
    recebivelId?: InputMaybe<IntWithAggregatesFilter>;
};

export type RecebivelPagarContasSumAggregate = {
    __typename?: "RecebivelPagarContasSumAggregate";
    minibancoId?: Maybe<Scalars["Int"]>;
    numeroTitulo?: Maybe<Scalars["Int"]>;
    recebivelId?: Maybe<Scalars["Int"]>;
};

export type RecebivelPagarContasSumOrderByAggregateInput = {
    minibancoId?: InputMaybe<SortOrder>;
    numeroTitulo?: InputMaybe<SortOrder>;
    recebivelId?: InputMaybe<SortOrder>;
};

export type RecebivelPagarContasUpdateInput = {
    chave?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibancoId?: InputMaybe<IntFieldUpdateOperationsInput>;
    numeroTitulo?: InputMaybe<IntFieldUpdateOperationsInput>;
    recebivel?: InputMaybe<RecebivelUpdateOneRequiredWithoutChavePagarContasNestedInput>;
};

export type RecebivelPagarContasUpdateManyMutationInput = {
    chave?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibancoId?: InputMaybe<IntFieldUpdateOperationsInput>;
    numeroTitulo?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput = {
    connect?: InputMaybe<RecebivelPagarContasWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelPagarContasCreateOrConnectWithoutRecebivelInput>;
    create?: InputMaybe<RecebivelPagarContasCreateWithoutRecebivelInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<RecebivelPagarContasUpdateWithoutRecebivelInput>;
    upsert?: InputMaybe<RecebivelPagarContasUpsertWithoutRecebivelInput>;
};

export type RecebivelPagarContasUpdateWithoutRecebivelInput = {
    chave?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibancoId?: InputMaybe<IntFieldUpdateOperationsInput>;
    numeroTitulo?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type RecebivelPagarContasUpsertWithoutRecebivelInput = {
    create: RecebivelPagarContasCreateWithoutRecebivelInput;
    update: RecebivelPagarContasUpdateWithoutRecebivelInput;
};

export type RecebivelPagarContasWhereInput = {
    AND?: InputMaybe<Array<RecebivelPagarContasWhereInput>>;
    NOT?: InputMaybe<Array<RecebivelPagarContasWhereInput>>;
    OR?: InputMaybe<Array<RecebivelPagarContasWhereInput>>;
    chave?: InputMaybe<StringNullableFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    numeroTitulo?: InputMaybe<IntFilter>;
    recebivel?: InputMaybe<RecebivelRelationFilter>;
    recebivelId?: InputMaybe<IntFilter>;
};

export type RecebivelPagarContasWhereUniqueInput = {
    numeroTitulo_minibancoId?: InputMaybe<RecebivelPagarContasNumeroTituloMinibancoIdCompoundUniqueInput>;
    recebivelId?: InputMaybe<Scalars["Int"]>;
};

export type RecebivelRelationFilter = {
    is?: InputMaybe<RecebivelWhereInput>;
    isNot?: InputMaybe<RecebivelWhereInput>;
};

export enum RecebivelScalarFieldEnum {
    ArquivoId = "arquivoId",
    CedenteId = "cedenteId",
    Cmc7 = "cmc7",
    CreatedAt = "createdAt",
    DtBaixa = "dtBaixa",
    DtEmissao = "dtEmissao",
    DtVencimento = "dtVencimento",
    ExtensionAmount = "extensionAmount",
    ExtensionDate = "extensionDate",
    Id = "id",
    Inativo = "inativo",
    MinibancoId = "minibancoId",
    OperacaoId = "operacaoId",
    Origem = "origem",
    PayableNumber = "payableNumber",
    PayableType = "payableType",
    SacadoId = "sacadoId",
    Status = "status",
    UpdatedAt = "updatedAt",
    UsuarioId = "usuarioId",
    Valor = "valor",
}

export type RecebivelScalarWhereInput = {
    AND?: InputMaybe<Array<RecebivelScalarWhereInput>>;
    NOT?: InputMaybe<Array<RecebivelScalarWhereInput>>;
    OR?: InputMaybe<Array<RecebivelScalarWhereInput>>;
    arquivoId?: InputMaybe<IntNullableFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    cmc7?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    dtBaixa?: InputMaybe<DateTimeNullableFilter>;
    dtEmissao?: InputMaybe<DateTimeFilter>;
    dtVencimento?: InputMaybe<DateTimeNullableFilter>;
    extensionAmount?: InputMaybe<DecimalNullableFilter>;
    extensionDate?: InputMaybe<DateTimeNullableFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    operacaoId?: InputMaybe<IntNullableFilter>;
    origem?: InputMaybe<EnumRecebivelOrigemFilter>;
    payableNumber?: InputMaybe<StringNullableFilter>;
    payableType?: InputMaybe<EnumPayableTypeNullableFilter>;
    sacadoId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumStatusRecebivelFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuarioId?: InputMaybe<IntFilter>;
    valor?: InputMaybe<DecimalFilter>;
};

export type RecebivelScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<RecebivelScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<RecebivelScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<RecebivelScalarWhereWithAggregatesInput>>;
    arquivoId?: InputMaybe<IntNullableWithAggregatesFilter>;
    cedenteId?: InputMaybe<IntWithAggregatesFilter>;
    cmc7?: InputMaybe<StringNullableWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    dtBaixa?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
    dtEmissao?: InputMaybe<DateTimeWithAggregatesFilter>;
    dtVencimento?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
    extensionAmount?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    extensionDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    operacaoId?: InputMaybe<IntNullableWithAggregatesFilter>;
    origem?: InputMaybe<EnumRecebivelOrigemWithAggregatesFilter>;
    payableNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
    payableType?: InputMaybe<EnumPayableTypeNullableWithAggregatesFilter>;
    sacadoId?: InputMaybe<IntWithAggregatesFilter>;
    status?: InputMaybe<EnumStatusRecebivelWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntWithAggregatesFilter>;
    valor?: InputMaybe<DecimalWithAggregatesFilter>;
};

export type RecebivelSumAggregate = {
    __typename?: "RecebivelSumAggregate";
    arquivoId?: Maybe<Scalars["Int"]>;
    cedenteId?: Maybe<Scalars["Int"]>;
    extensionAmount?: Maybe<Scalars["Decimal"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    operacaoId?: Maybe<Scalars["Int"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
    valor?: Maybe<Scalars["Decimal"]>;
};

export type RecebivelSumOrderByAggregateInput = {
    arquivoId?: InputMaybe<SortOrder>;
    cedenteId?: InputMaybe<SortOrder>;
    extensionAmount?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    operacaoId?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type RecebivelUpdateInput = {
    CTe?: InputMaybe<CTeUpdateOneWithoutRecebivelNestedInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutPayableNestedInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneWithoutRecebivelNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisNestedInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<RecebivelAuditUpdateManyWithoutRecebivelNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    parcelaNFe?: InputMaybe<NFeParcelaUpdateOneWithoutRecebivelNestedInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    recebivelOperado?: InputMaybe<RecebivelOperadoUpdateOneWithoutRecebivelNestedInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateManyMutationInput = {
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateManyWithWhereWithoutCedenteInput = {
    data: RecebivelUpdateManyMutationInput;
    where: RecebivelScalarWhereInput;
};

export type RecebivelUpdateManyWithWhereWithoutMinibancoInput = {
    data: RecebivelUpdateManyMutationInput;
    where: RecebivelScalarWhereInput;
};

export type RecebivelUpdateManyWithWhereWithoutOperacaoInput = {
    data: RecebivelUpdateManyMutationInput;
    where: RecebivelScalarWhereInput;
};

export type RecebivelUpdateManyWithWhereWithoutSacadoInput = {
    data: RecebivelUpdateManyMutationInput;
    where: RecebivelScalarWhereInput;
};

export type RecebivelUpdateManyWithWhereWithoutUsuarioInput = {
    data: RecebivelUpdateManyMutationInput;
    where: RecebivelScalarWhereInput;
};

export type RecebivelUpdateManyWithoutCedenteNestedInput = {
    connect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<RecebivelCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<RecebivelCreateManyCedenteInputEnvelope>;
    delete?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebivelScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebivelUpdateWithWhereUniqueWithoutCedenteInput>>;
    updateMany?: InputMaybe<Array<RecebivelUpdateManyWithWhereWithoutCedenteInput>>;
    upsert?: InputMaybe<Array<RecebivelUpsertWithWhereUniqueWithoutCedenteInput>>;
};

export type RecebivelUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<RecebivelCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<RecebivelCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebivelScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebivelUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<RecebivelUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<RecebivelUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type RecebivelUpdateManyWithoutOperacaoNestedInput = {
    connect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelCreateOrConnectWithoutOperacaoInput>>;
    create?: InputMaybe<Array<RecebivelCreateWithoutOperacaoInput>>;
    createMany?: InputMaybe<RecebivelCreateManyOperacaoInputEnvelope>;
    delete?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebivelScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebivelUpdateWithWhereUniqueWithoutOperacaoInput>>;
    updateMany?: InputMaybe<Array<RecebivelUpdateManyWithWhereWithoutOperacaoInput>>;
    upsert?: InputMaybe<Array<RecebivelUpsertWithWhereUniqueWithoutOperacaoInput>>;
};

export type RecebivelUpdateManyWithoutSacadoNestedInput = {
    connect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelCreateOrConnectWithoutSacadoInput>>;
    create?: InputMaybe<Array<RecebivelCreateWithoutSacadoInput>>;
    createMany?: InputMaybe<RecebivelCreateManySacadoInputEnvelope>;
    delete?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebivelScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebivelUpdateWithWhereUniqueWithoutSacadoInput>>;
    updateMany?: InputMaybe<Array<RecebivelUpdateManyWithWhereWithoutSacadoInput>>;
    upsert?: InputMaybe<Array<RecebivelUpsertWithWhereUniqueWithoutSacadoInput>>;
};

export type RecebivelUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<RecebivelCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<RecebivelCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<RecebivelCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RecebivelScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    set?: InputMaybe<Array<RecebivelWhereUniqueInput>>;
    update?: InputMaybe<Array<RecebivelUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<RecebivelUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<RecebivelUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type RecebivelUpdateOneRequiredWithoutCTeNestedInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutCTeInput>;
    create?: InputMaybe<RecebivelCreateWithoutCTeInput>;
    update?: InputMaybe<RecebivelUpdateWithoutCTeInput>;
    upsert?: InputMaybe<RecebivelUpsertWithoutCTeInput>;
};

export type RecebivelUpdateOneRequiredWithoutChaveIntegracaoNestedInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutChaveIntegracaoInput>;
    create?: InputMaybe<RecebivelCreateWithoutChaveIntegracaoInput>;
    update?: InputMaybe<RecebivelUpdateWithoutChaveIntegracaoInput>;
    upsert?: InputMaybe<RecebivelUpsertWithoutChaveIntegracaoInput>;
};

export type RecebivelUpdateOneRequiredWithoutChavePagarContasNestedInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutChavePagarContasInput>;
    create?: InputMaybe<RecebivelCreateWithoutChavePagarContasInput>;
    update?: InputMaybe<RecebivelUpdateWithoutChavePagarContasInput>;
    upsert?: InputMaybe<RecebivelUpsertWithoutChavePagarContasInput>;
};

export type RecebivelUpdateOneRequiredWithoutHistoricoNestedInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<RecebivelCreateWithoutHistoricoInput>;
    update?: InputMaybe<RecebivelUpdateWithoutHistoricoInput>;
    upsert?: InputMaybe<RecebivelUpsertWithoutHistoricoInput>;
};

export type RecebivelUpdateOneRequiredWithoutParcelaNFeNestedInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutParcelaNFeInput>;
    create?: InputMaybe<RecebivelCreateWithoutParcelaNFeInput>;
    update?: InputMaybe<RecebivelUpdateWithoutParcelaNFeInput>;
    upsert?: InputMaybe<RecebivelUpsertWithoutParcelaNFeInput>;
};

export type RecebivelUpdateOneRequiredWithoutPayableUpdatesNestedInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutPayableUpdatesInput>;
    create?: InputMaybe<RecebivelCreateWithoutPayableUpdatesInput>;
    update?: InputMaybe<RecebivelUpdateWithoutPayableUpdatesInput>;
    upsert?: InputMaybe<RecebivelUpsertWithoutPayableUpdatesInput>;
};

export type RecebivelUpdateOneRequiredWithoutRecebivelOperadoNestedInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutRecebivelOperadoInput>;
    create?: InputMaybe<RecebivelCreateWithoutRecebivelOperadoInput>;
    update?: InputMaybe<RecebivelUpdateWithoutRecebivelOperadoInput>;
    upsert?: InputMaybe<RecebivelUpsertWithoutRecebivelOperadoInput>;
};

export type RecebivelUpdateOneWithoutArquivoNestedInput = {
    connect?: InputMaybe<RecebivelWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RecebivelCreateOrConnectWithoutArquivoInput>;
    create?: InputMaybe<RecebivelCreateWithoutArquivoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<RecebivelUpdateWithoutArquivoInput>;
    upsert?: InputMaybe<RecebivelUpsertWithoutArquivoInput>;
};

export type RecebivelUpdateWithWhereUniqueWithoutCedenteInput = {
    data: RecebivelUpdateWithoutCedenteInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: RecebivelUpdateWithoutMinibancoInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelUpdateWithWhereUniqueWithoutOperacaoInput = {
    data: RecebivelUpdateWithoutOperacaoInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelUpdateWithWhereUniqueWithoutSacadoInput = {
    data: RecebivelUpdateWithoutSacadoInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: RecebivelUpdateWithoutUsuarioInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelUpdateWithoutArquivoInput = {
    CTe?: InputMaybe<CTeUpdateOneWithoutRecebivelNestedInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutPayableNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisNestedInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<RecebivelAuditUpdateManyWithoutRecebivelNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    parcelaNFe?: InputMaybe<NFeParcelaUpdateOneWithoutRecebivelNestedInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    recebivelOperado?: InputMaybe<RecebivelOperadoUpdateOneWithoutRecebivelNestedInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateWithoutCTeInput = {
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutPayableNestedInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneWithoutRecebivelNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisNestedInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<RecebivelAuditUpdateManyWithoutRecebivelNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    parcelaNFe?: InputMaybe<NFeParcelaUpdateOneWithoutRecebivelNestedInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    recebivelOperado?: InputMaybe<RecebivelOperadoUpdateOneWithoutRecebivelNestedInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateWithoutCedenteInput = {
    CTe?: InputMaybe<CTeUpdateOneWithoutRecebivelNestedInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutPayableNestedInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneWithoutRecebivelNestedInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<RecebivelAuditUpdateManyWithoutRecebivelNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    parcelaNFe?: InputMaybe<NFeParcelaUpdateOneWithoutRecebivelNestedInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    recebivelOperado?: InputMaybe<RecebivelOperadoUpdateOneWithoutRecebivelNestedInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateWithoutChaveIntegracaoInput = {
    CTe?: InputMaybe<CTeUpdateOneWithoutRecebivelNestedInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutPayableNestedInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneWithoutRecebivelNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisNestedInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<RecebivelAuditUpdateManyWithoutRecebivelNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    parcelaNFe?: InputMaybe<NFeParcelaUpdateOneWithoutRecebivelNestedInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    recebivelOperado?: InputMaybe<RecebivelOperadoUpdateOneWithoutRecebivelNestedInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateWithoutChavePagarContasInput = {
    CTe?: InputMaybe<CTeUpdateOneWithoutRecebivelNestedInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutPayableNestedInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneWithoutRecebivelNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisNestedInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<RecebivelAuditUpdateManyWithoutRecebivelNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    parcelaNFe?: InputMaybe<NFeParcelaUpdateOneWithoutRecebivelNestedInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    recebivelOperado?: InputMaybe<RecebivelOperadoUpdateOneWithoutRecebivelNestedInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateWithoutHistoricoInput = {
    CTe?: InputMaybe<CTeUpdateOneWithoutRecebivelNestedInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutPayableNestedInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneWithoutRecebivelNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisNestedInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    parcelaNFe?: InputMaybe<NFeParcelaUpdateOneWithoutRecebivelNestedInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    recebivelOperado?: InputMaybe<RecebivelOperadoUpdateOneWithoutRecebivelNestedInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateWithoutMinibancoInput = {
    CTe?: InputMaybe<CTeUpdateOneWithoutRecebivelNestedInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutPayableNestedInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneWithoutRecebivelNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisNestedInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<RecebivelAuditUpdateManyWithoutRecebivelNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    operacao?: InputMaybe<OperacaoUpdateOneWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    parcelaNFe?: InputMaybe<NFeParcelaUpdateOneWithoutRecebivelNestedInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    recebivelOperado?: InputMaybe<RecebivelOperadoUpdateOneWithoutRecebivelNestedInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateWithoutOperacaoInput = {
    CTe?: InputMaybe<CTeUpdateOneWithoutRecebivelNestedInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutPayableNestedInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneWithoutRecebivelNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisNestedInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<RecebivelAuditUpdateManyWithoutRecebivelNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    parcelaNFe?: InputMaybe<NFeParcelaUpdateOneWithoutRecebivelNestedInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    recebivelOperado?: InputMaybe<RecebivelOperadoUpdateOneWithoutRecebivelNestedInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateWithoutParcelaNFeInput = {
    CTe?: InputMaybe<CTeUpdateOneWithoutRecebivelNestedInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutPayableNestedInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneWithoutRecebivelNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisNestedInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<RecebivelAuditUpdateManyWithoutRecebivelNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    recebivelOperado?: InputMaybe<RecebivelOperadoUpdateOneWithoutRecebivelNestedInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateWithoutPayableUpdatesInput = {
    CTe?: InputMaybe<CTeUpdateOneWithoutRecebivelNestedInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneWithoutRecebivelNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisNestedInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<RecebivelAuditUpdateManyWithoutRecebivelNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    parcelaNFe?: InputMaybe<NFeParcelaUpdateOneWithoutRecebivelNestedInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    recebivelOperado?: InputMaybe<RecebivelOperadoUpdateOneWithoutRecebivelNestedInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateWithoutRecebivelOperadoInput = {
    CTe?: InputMaybe<CTeUpdateOneWithoutRecebivelNestedInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutPayableNestedInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneWithoutRecebivelNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisNestedInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<RecebivelAuditUpdateManyWithoutRecebivelNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    parcelaNFe?: InputMaybe<NFeParcelaUpdateOneWithoutRecebivelNestedInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateWithoutSacadoInput = {
    CTe?: InputMaybe<CTeUpdateOneWithoutRecebivelNestedInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutPayableNestedInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneWithoutRecebivelNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisNestedInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<RecebivelAuditUpdateManyWithoutRecebivelNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    parcelaNFe?: InputMaybe<NFeParcelaUpdateOneWithoutRecebivelNestedInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    recebivelOperado?: InputMaybe<RecebivelOperadoUpdateOneWithoutRecebivelNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpdateWithoutUsuarioInput = {
    CTe?: InputMaybe<CTeUpdateOneWithoutRecebivelNestedInput>;
    PayableUpdates?: InputMaybe<PayableUpdatesUpdateManyWithoutPayableNestedInput>;
    arquivo?: InputMaybe<ArquivoUpdateOneWithoutRecebivelNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutRecebiveisNestedInput>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoUpdateOneWithoutRecebivelNestedInput>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasUpdateOneWithoutRecebivelNestedInput>;
    cmc7?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtBaixa?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    dtEmissao?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    dtVencimento?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    extensionAmount?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    extensionDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
    historico?: InputMaybe<RecebivelAuditUpdateManyWithoutRecebivelNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    operacao?: InputMaybe<OperacaoUpdateOneWithoutRecebiveisNestedInput>;
    origem?: InputMaybe<EnumRecebivelOrigemFieldUpdateOperationsInput>;
    parcelaNFe?: InputMaybe<NFeParcelaUpdateOneWithoutRecebivelNestedInput>;
    payableNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    payableType?: InputMaybe<NullableEnumPayableTypeFieldUpdateOperationsInput>;
    recebivelOperado?: InputMaybe<RecebivelOperadoUpdateOneWithoutRecebivelNestedInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutRecebiveisNestedInput>;
    status?: InputMaybe<EnumStatusRecebivelFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    valor?: InputMaybe<DecimalFieldUpdateOperationsInput>;
};

export type RecebivelUpsertWithWhereUniqueWithoutCedenteInput = {
    create: RecebivelCreateWithoutCedenteInput;
    update: RecebivelUpdateWithoutCedenteInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: RecebivelCreateWithoutMinibancoInput;
    update: RecebivelUpdateWithoutMinibancoInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelUpsertWithWhereUniqueWithoutOperacaoInput = {
    create: RecebivelCreateWithoutOperacaoInput;
    update: RecebivelUpdateWithoutOperacaoInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelUpsertWithWhereUniqueWithoutSacadoInput = {
    create: RecebivelCreateWithoutSacadoInput;
    update: RecebivelUpdateWithoutSacadoInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: RecebivelCreateWithoutUsuarioInput;
    update: RecebivelUpdateWithoutUsuarioInput;
    where: RecebivelWhereUniqueInput;
};

export type RecebivelUpsertWithoutArquivoInput = {
    create: RecebivelCreateWithoutArquivoInput;
    update: RecebivelUpdateWithoutArquivoInput;
};

export type RecebivelUpsertWithoutCTeInput = {
    create: RecebivelCreateWithoutCTeInput;
    update: RecebivelUpdateWithoutCTeInput;
};

export type RecebivelUpsertWithoutChaveIntegracaoInput = {
    create: RecebivelCreateWithoutChaveIntegracaoInput;
    update: RecebivelUpdateWithoutChaveIntegracaoInput;
};

export type RecebivelUpsertWithoutChavePagarContasInput = {
    create: RecebivelCreateWithoutChavePagarContasInput;
    update: RecebivelUpdateWithoutChavePagarContasInput;
};

export type RecebivelUpsertWithoutHistoricoInput = {
    create: RecebivelCreateWithoutHistoricoInput;
    update: RecebivelUpdateWithoutHistoricoInput;
};

export type RecebivelUpsertWithoutParcelaNFeInput = {
    create: RecebivelCreateWithoutParcelaNFeInput;
    update: RecebivelUpdateWithoutParcelaNFeInput;
};

export type RecebivelUpsertWithoutPayableUpdatesInput = {
    create: RecebivelCreateWithoutPayableUpdatesInput;
    update: RecebivelUpdateWithoutPayableUpdatesInput;
};

export type RecebivelUpsertWithoutRecebivelOperadoInput = {
    create: RecebivelCreateWithoutRecebivelOperadoInput;
    update: RecebivelUpdateWithoutRecebivelOperadoInput;
};

export type RecebivelWhereInput = {
    AND?: InputMaybe<Array<RecebivelWhereInput>>;
    CTe?: InputMaybe<CTeRelationFilter>;
    NOT?: InputMaybe<Array<RecebivelWhereInput>>;
    OR?: InputMaybe<Array<RecebivelWhereInput>>;
    PayableUpdates?: InputMaybe<PayableUpdatesListRelationFilter>;
    arquivo?: InputMaybe<ArquivoRelationFilter>;
    arquivoId?: InputMaybe<IntNullableFilter>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    chaveIntegracao?: InputMaybe<RecebivelIntegracaoRelationFilter>;
    chavePagarContas?: InputMaybe<RecebivelPagarContasRelationFilter>;
    cmc7?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    dtBaixa?: InputMaybe<DateTimeNullableFilter>;
    dtEmissao?: InputMaybe<DateTimeFilter>;
    dtVencimento?: InputMaybe<DateTimeNullableFilter>;
    extensionAmount?: InputMaybe<DecimalNullableFilter>;
    extensionDate?: InputMaybe<DateTimeNullableFilter>;
    historico?: InputMaybe<RecebivelAuditListRelationFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    operacao?: InputMaybe<OperacaoRelationFilter>;
    operacaoId?: InputMaybe<IntNullableFilter>;
    origem?: InputMaybe<EnumRecebivelOrigemFilter>;
    parcelaNFe?: InputMaybe<NFeParcelaRelationFilter>;
    payableNumber?: InputMaybe<StringNullableFilter>;
    payableType?: InputMaybe<EnumPayableTypeNullableFilter>;
    recebivelOperado?: InputMaybe<RecebivelOperadoRelationFilter>;
    sacado?: InputMaybe<SacadoRelationFilter>;
    sacadoId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumStatusRecebivelFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntFilter>;
    valor?: InputMaybe<DecimalFilter>;
};

export type RecebivelWhereUniqueInput = {
    arquivoId?: InputMaybe<Scalars["Int"]>;
    id?: InputMaybe<Scalars["Int"]>;
};

export type Representative = {
    __typename?: "Representative";
    Assignor: Array<Cedente>;
    _count?: Maybe<RepresentativeCount>;
    companyEntryDate: Scalars["DateTime"];
    document: Scalars["String"];
    id: Scalars["Int"];
    name: Scalars["String"];
    status: Scalars["Boolean"];
};

export type RepresentativeAssignorArgs = {
    cursor?: InputMaybe<CedenteWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteWhereInput>;
};

export type RepresentativeAvgAggregate = {
    __typename?: "RepresentativeAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
};

export type RepresentativeAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type RepresentativeCount = {
    __typename?: "RepresentativeCount";
    Assignor: Scalars["Int"];
};

export type RepresentativeCountAggregate = {
    __typename?: "RepresentativeCountAggregate";
    _all: Scalars["Int"];
    companyEntryDate: Scalars["Int"];
    document: Scalars["Int"];
    id: Scalars["Int"];
    name: Scalars["Int"];
    status: Scalars["Int"];
};

export type RepresentativeCountOrderByAggregateInput = {
    companyEntryDate?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
};

export type RepresentativeCreateInput = {
    Assignor?: InputMaybe<CedenteCreateNestedManyWithoutRepresentativeInput>;
    companyEntryDate: Scalars["DateTime"];
    document: Scalars["String"];
    name: Scalars["String"];
    status: Scalars["Boolean"];
};

export type RepresentativeCreateManyInput = {
    companyEntryDate: Scalars["DateTime"];
    document: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    name: Scalars["String"];
    status: Scalars["Boolean"];
};

export type RepresentativeCreateNestedOneWithoutAssignorInput = {
    connect?: InputMaybe<RepresentativeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RepresentativeCreateOrConnectWithoutAssignorInput>;
    create?: InputMaybe<RepresentativeCreateWithoutAssignorInput>;
};

export type RepresentativeCreateOrConnectWithoutAssignorInput = {
    create: RepresentativeCreateWithoutAssignorInput;
    where: RepresentativeWhereUniqueInput;
};

export type RepresentativeCreateWithoutAssignorInput = {
    companyEntryDate: Scalars["DateTime"];
    document: Scalars["String"];
    name: Scalars["String"];
    status: Scalars["Boolean"];
};

export type RepresentativeGroupBy = {
    __typename?: "RepresentativeGroupBy";
    _avg?: Maybe<RepresentativeAvgAggregate>;
    _count?: Maybe<RepresentativeCountAggregate>;
    _max?: Maybe<RepresentativeMaxAggregate>;
    _min?: Maybe<RepresentativeMinAggregate>;
    _sum?: Maybe<RepresentativeSumAggregate>;
    companyEntryDate: Scalars["DateTime"];
    document: Scalars["String"];
    id: Scalars["Int"];
    name: Scalars["String"];
    status: Scalars["Boolean"];
};

export type RepresentativeMaxAggregate = {
    __typename?: "RepresentativeMaxAggregate";
    companyEntryDate?: Maybe<Scalars["DateTime"]>;
    document?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    name?: Maybe<Scalars["String"]>;
    status?: Maybe<Scalars["Boolean"]>;
};

export type RepresentativeMaxOrderByAggregateInput = {
    companyEntryDate?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
};

export type RepresentativeMinAggregate = {
    __typename?: "RepresentativeMinAggregate";
    companyEntryDate?: Maybe<Scalars["DateTime"]>;
    document?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    name?: Maybe<Scalars["String"]>;
    status?: Maybe<Scalars["Boolean"]>;
};

export type RepresentativeMinOrderByAggregateInput = {
    companyEntryDate?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
};

export type RepresentativeOrderByWithAggregationInput = {
    _avg?: InputMaybe<RepresentativeAvgOrderByAggregateInput>;
    _count?: InputMaybe<RepresentativeCountOrderByAggregateInput>;
    _max?: InputMaybe<RepresentativeMaxOrderByAggregateInput>;
    _min?: InputMaybe<RepresentativeMinOrderByAggregateInput>;
    _sum?: InputMaybe<RepresentativeSumOrderByAggregateInput>;
    companyEntryDate?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
};

export type RepresentativeOrderByWithRelationInput = {
    Assignor?: InputMaybe<CedenteOrderByRelationAggregateInput>;
    companyEntryDate?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    name?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
};

export type RepresentativeRelationFilter = {
    is?: InputMaybe<RepresentativeWhereInput>;
    isNot?: InputMaybe<RepresentativeWhereInput>;
};

export enum RepresentativeScalarFieldEnum {
    CompanyEntryDate = "companyEntryDate",
    Document = "document",
    Id = "id",
    Name = "name",
    Status = "status",
}

export type RepresentativeScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<RepresentativeScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<RepresentativeScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<RepresentativeScalarWhereWithAggregatesInput>>;
    companyEntryDate?: InputMaybe<DateTimeWithAggregatesFilter>;
    document?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    name?: InputMaybe<StringWithAggregatesFilter>;
    status?: InputMaybe<BoolWithAggregatesFilter>;
};

export type RepresentativeSumAggregate = {
    __typename?: "RepresentativeSumAggregate";
    id?: Maybe<Scalars["Int"]>;
};

export type RepresentativeSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type RepresentativeUpdateInput = {
    Assignor?: InputMaybe<CedenteUpdateManyWithoutRepresentativeNestedInput>;
    companyEntryDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    status?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type RepresentativeUpdateManyMutationInput = {
    companyEntryDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    status?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type RepresentativeUpdateOneWithoutAssignorNestedInput = {
    connect?: InputMaybe<RepresentativeWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RepresentativeCreateOrConnectWithoutAssignorInput>;
    create?: InputMaybe<RepresentativeCreateWithoutAssignorInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<RepresentativeUpdateWithoutAssignorInput>;
    upsert?: InputMaybe<RepresentativeUpsertWithoutAssignorInput>;
};

export type RepresentativeUpdateWithoutAssignorInput = {
    companyEntryDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    name?: InputMaybe<StringFieldUpdateOperationsInput>;
    status?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type RepresentativeUpsertWithoutAssignorInput = {
    create: RepresentativeCreateWithoutAssignorInput;
    update: RepresentativeUpdateWithoutAssignorInput;
};

export type RepresentativeWhereInput = {
    AND?: InputMaybe<Array<RepresentativeWhereInput>>;
    Assignor?: InputMaybe<CedenteListRelationFilter>;
    NOT?: InputMaybe<Array<RepresentativeWhereInput>>;
    OR?: InputMaybe<Array<RepresentativeWhereInput>>;
    companyEntryDate?: InputMaybe<DateTimeFilter>;
    document?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    name?: InputMaybe<StringFilter>;
    status?: InputMaybe<BoolFilter>;
};

export type RepresentativeWhereUniqueInput = {
    document?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
};

export type ResponsavelCedente = {
    __typename?: "ResponsavelCedente";
    cedente: Cedente;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    observacao?: Maybe<Scalars["String"]>;
    status: CadastroStatus;
    updatedAt: Scalars["DateTime"];
    usuario: Usuario;
    usuarioId: Scalars["Int"];
};

export type ResponsavelCedenteAvgAggregate = {
    __typename?: "ResponsavelCedenteAvgAggregate";
    cedenteId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type ResponsavelCedenteAvgOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ResponsavelCedenteCountAggregate = {
    __typename?: "ResponsavelCedenteCountAggregate";
    _all: Scalars["Int"];
    cedenteId: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    observacao: Scalars["Int"];
    status: Scalars["Int"];
    updatedAt: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type ResponsavelCedenteCountOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ResponsavelCedenteCreateInput = {
    cedente: CedenteCreateNestedOneWithoutResponsaveisInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutCedentesInput;
};

export type ResponsavelCedenteCreateManyCedenteInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
};

export type ResponsavelCedenteCreateManyCedenteInputEnvelope = {
    data: Array<ResponsavelCedenteCreateManyCedenteInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResponsavelCedenteCreateManyInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuarioId: Scalars["Int"];
};

export type ResponsavelCedenteCreateManyUsuarioInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ResponsavelCedenteCreateManyUsuarioInputEnvelope = {
    data: Array<ResponsavelCedenteCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type ResponsavelCedenteCreateNestedManyWithoutCedenteInput = {
    connect?: InputMaybe<Array<ResponsavelCedenteWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ResponsavelCedenteCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<ResponsavelCedenteCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<ResponsavelCedenteCreateManyCedenteInputEnvelope>;
};

export type ResponsavelCedenteCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<ResponsavelCedenteWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ResponsavelCedenteCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<ResponsavelCedenteCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<ResponsavelCedenteCreateManyUsuarioInputEnvelope>;
};

export type ResponsavelCedenteCreateOrConnectWithoutCedenteInput = {
    create: ResponsavelCedenteCreateWithoutCedenteInput;
    where: ResponsavelCedenteWhereUniqueInput;
};

export type ResponsavelCedenteCreateOrConnectWithoutUsuarioInput = {
    create: ResponsavelCedenteCreateWithoutUsuarioInput;
    where: ResponsavelCedenteWhereUniqueInput;
};

export type ResponsavelCedenteCreateWithoutCedenteInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario: UsuarioCreateNestedOneWithoutCedentesInput;
};

export type ResponsavelCedenteCreateWithoutUsuarioInput = {
    cedente: CedenteCreateNestedOneWithoutResponsaveisInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    observacao?: InputMaybe<Scalars["String"]>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ResponsavelCedenteGroupBy = {
    __typename?: "ResponsavelCedenteGroupBy";
    _avg?: Maybe<ResponsavelCedenteAvgAggregate>;
    _count?: Maybe<ResponsavelCedenteCountAggregate>;
    _max?: Maybe<ResponsavelCedenteMaxAggregate>;
    _min?: Maybe<ResponsavelCedenteMinAggregate>;
    _sum?: Maybe<ResponsavelCedenteSumAggregate>;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    observacao?: Maybe<Scalars["String"]>;
    status: CadastroStatus;
    updatedAt: Scalars["DateTime"];
    usuarioId: Scalars["Int"];
};

export type ResponsavelCedenteListRelationFilter = {
    every?: InputMaybe<ResponsavelCedenteWhereInput>;
    none?: InputMaybe<ResponsavelCedenteWhereInput>;
    some?: InputMaybe<ResponsavelCedenteWhereInput>;
};

export type ResponsavelCedenteMaxAggregate = {
    __typename?: "ResponsavelCedenteMaxAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    observacao?: Maybe<Scalars["String"]>;
    status?: Maybe<CadastroStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ResponsavelCedenteMaxOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ResponsavelCedenteMinAggregate = {
    __typename?: "ResponsavelCedenteMinAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    observacao?: Maybe<Scalars["String"]>;
    status?: Maybe<CadastroStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ResponsavelCedenteMinOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ResponsavelCedenteOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type ResponsavelCedenteOrderByWithAggregationInput = {
    _avg?: InputMaybe<ResponsavelCedenteAvgOrderByAggregateInput>;
    _count?: InputMaybe<ResponsavelCedenteCountOrderByAggregateInput>;
    _max?: InputMaybe<ResponsavelCedenteMaxOrderByAggregateInput>;
    _min?: InputMaybe<ResponsavelCedenteMinOrderByAggregateInput>;
    _sum?: InputMaybe<ResponsavelCedenteSumOrderByAggregateInput>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ResponsavelCedenteOrderByWithRelationInput = {
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum ResponsavelCedenteScalarFieldEnum {
    CedenteId = "cedenteId",
    CreatedAt = "createdAt",
    Id = "id",
    Observacao = "observacao",
    Status = "status",
    UpdatedAt = "updatedAt",
    UsuarioId = "usuarioId",
}

export type ResponsavelCedenteScalarWhereInput = {
    AND?: InputMaybe<Array<ResponsavelCedenteScalarWhereInput>>;
    NOT?: InputMaybe<Array<ResponsavelCedenteScalarWhereInput>>;
    OR?: InputMaybe<Array<ResponsavelCedenteScalarWhereInput>>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    observacao?: InputMaybe<StringNullableFilter>;
    status?: InputMaybe<EnumCadastroStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuarioId?: InputMaybe<IntFilter>;
};

export type ResponsavelCedenteScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<ResponsavelCedenteScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<ResponsavelCedenteScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<ResponsavelCedenteScalarWhereWithAggregatesInput>>;
    cedenteId?: InputMaybe<IntWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    observacao?: InputMaybe<StringNullableWithAggregatesFilter>;
    status?: InputMaybe<EnumCadastroStatusWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntWithAggregatesFilter>;
};

export type ResponsavelCedenteSumAggregate = {
    __typename?: "ResponsavelCedenteSumAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type ResponsavelCedenteSumOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type ResponsavelCedenteUpdateInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutResponsaveisNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutCedentesNestedInput>;
};

export type ResponsavelCedenteUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ResponsavelCedenteUpdateManyWithWhereWithoutCedenteInput = {
    data: ResponsavelCedenteUpdateManyMutationInput;
    where: ResponsavelCedenteScalarWhereInput;
};

export type ResponsavelCedenteUpdateManyWithWhereWithoutUsuarioInput = {
    data: ResponsavelCedenteUpdateManyMutationInput;
    where: ResponsavelCedenteScalarWhereInput;
};

export type ResponsavelCedenteUpdateManyWithoutCedenteNestedInput = {
    connect?: InputMaybe<Array<ResponsavelCedenteWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ResponsavelCedenteCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<ResponsavelCedenteCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<ResponsavelCedenteCreateManyCedenteInputEnvelope>;
    delete?: InputMaybe<Array<ResponsavelCedenteWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ResponsavelCedenteScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ResponsavelCedenteWhereUniqueInput>>;
    set?: InputMaybe<Array<ResponsavelCedenteWhereUniqueInput>>;
    update?: InputMaybe<Array<ResponsavelCedenteUpdateWithWhereUniqueWithoutCedenteInput>>;
    updateMany?: InputMaybe<Array<ResponsavelCedenteUpdateManyWithWhereWithoutCedenteInput>>;
    upsert?: InputMaybe<Array<ResponsavelCedenteUpsertWithWhereUniqueWithoutCedenteInput>>;
};

export type ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<ResponsavelCedenteWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<ResponsavelCedenteCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<ResponsavelCedenteCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<ResponsavelCedenteCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<ResponsavelCedenteWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ResponsavelCedenteScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ResponsavelCedenteWhereUniqueInput>>;
    set?: InputMaybe<Array<ResponsavelCedenteWhereUniqueInput>>;
    update?: InputMaybe<Array<ResponsavelCedenteUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<ResponsavelCedenteUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<ResponsavelCedenteUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type ResponsavelCedenteUpdateWithWhereUniqueWithoutCedenteInput = {
    data: ResponsavelCedenteUpdateWithoutCedenteInput;
    where: ResponsavelCedenteWhereUniqueInput;
};

export type ResponsavelCedenteUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: ResponsavelCedenteUpdateWithoutUsuarioInput;
    where: ResponsavelCedenteWhereUniqueInput;
};

export type ResponsavelCedenteUpdateWithoutCedenteInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutCedentesNestedInput>;
};

export type ResponsavelCedenteUpdateWithoutUsuarioInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutResponsaveisNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ResponsavelCedenteUpsertWithWhereUniqueWithoutCedenteInput = {
    create: ResponsavelCedenteCreateWithoutCedenteInput;
    update: ResponsavelCedenteUpdateWithoutCedenteInput;
    where: ResponsavelCedenteWhereUniqueInput;
};

export type ResponsavelCedenteUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: ResponsavelCedenteCreateWithoutUsuarioInput;
    update: ResponsavelCedenteUpdateWithoutUsuarioInput;
    where: ResponsavelCedenteWhereUniqueInput;
};

export type ResponsavelCedenteVinculoCompoundUniqueInput = {
    cedenteId: Scalars["Int"];
    status: CadastroStatus;
    usuarioId: Scalars["Int"];
};

export type ResponsavelCedenteWhereInput = {
    AND?: InputMaybe<Array<ResponsavelCedenteWhereInput>>;
    NOT?: InputMaybe<Array<ResponsavelCedenteWhereInput>>;
    OR?: InputMaybe<Array<ResponsavelCedenteWhereInput>>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    observacao?: InputMaybe<StringNullableFilter>;
    status?: InputMaybe<EnumCadastroStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntFilter>;
};

export type ResponsavelCedenteWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
    vinculo?: InputMaybe<ResponsavelCedenteVinculoCompoundUniqueInput>;
};

export type Sacado = {
    __typename?: "Sacado";
    OperationsInfo: Array<OperationsInfo>;
    RecebiveisInfo: Array<RecebiveisInfo>;
    RecebiveisToAnticipate: Array<RecebiveisToAnticipate>;
    _count?: Maybe<SacadoCount>;
    contatos: Array<SacadoContato>;
    createdAt: Scalars["DateTime"];
    document: Scalars["String"];
    endereco?: Maybe<Endereco>;
    enderecoId?: Maybe<Scalars["Int"]>;
    fone?: Maybe<Scalars["String"]>;
    historico: Array<SacadoAudit>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibancos: Array<Minibanco>;
    nomeFantasia?: Maybe<Scalars["String"]>;
    pessoaFisica: Scalars["Boolean"];
    razaoSocial: Scalars["String"];
    recebiveis: Array<Recebivel>;
    updatedAt: Scalars["DateTime"];
};

export type SacadoOperationsInfoArgs = {
    cursor?: InputMaybe<OperationsInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperationsInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperationsInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperationsInfoWhereInput>;
};

export type SacadoRecebiveisInfoArgs = {
    cursor?: InputMaybe<RecebiveisInfoWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebiveisInfoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebiveisInfoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisInfoWhereInput>;
};

export type SacadoRecebiveisToAnticipateArgs = {
    cursor?: InputMaybe<RecebiveisToAnticipateWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebiveisToAnticipateScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebiveisToAnticipateOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebiveisToAnticipateWhereInput>;
};

export type SacadoContatosArgs = {
    cursor?: InputMaybe<SacadoContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<SacadoContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<SacadoContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoContatoWhereInput>;
};

export type SacadoHistoricoArgs = {
    cursor?: InputMaybe<SacadoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<SacadoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<SacadoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoAuditWhereInput>;
};

export type SacadoMinibancosArgs = {
    cursor?: InputMaybe<MinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoWhereInput>;
};

export type SacadoRecebiveisArgs = {
    cursor?: InputMaybe<RecebivelWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelWhereInput>;
};

export type SacadoAudit = {
    __typename?: "SacadoAudit";
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    sacado: Sacado;
    sacadoId: Scalars["Int"];
    service: TipoServico;
    userAgent: Scalars["String"];
    usuario?: Maybe<Usuario>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type SacadoAuditAvgAggregate = {
    __typename?: "SacadoAuditAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    sacadoId?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type SacadoAuditAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type SacadoAuditCountAggregate = {
    __typename?: "SacadoAuditCountAggregate";
    _all: Scalars["Int"];
    action: Scalars["Int"];
    createdAt: Scalars["Int"];
    field: Scalars["Int"];
    id: Scalars["Int"];
    ipUsuario: Scalars["Int"];
    newValue: Scalars["Int"];
    oldValue: Scalars["Int"];
    sacadoId: Scalars["Int"];
    service: Scalars["Int"];
    userAgent: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type SacadoAuditCountOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type SacadoAuditCreateInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    sacado: SacadoCreateNestedOneWithoutHistoricoInput;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistSacadoInput>;
};

export type SacadoAuditCreateManyInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    sacadoId: Scalars["Int"];
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type SacadoAuditCreateManySacadoInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type SacadoAuditCreateManySacadoInputEnvelope = {
    data: Array<SacadoAuditCreateManySacadoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type SacadoAuditCreateManyUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    sacadoId: Scalars["Int"];
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type SacadoAuditCreateManyUsuarioInputEnvelope = {
    data: Array<SacadoAuditCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type SacadoAuditCreateNestedManyWithoutSacadoInput = {
    connect?: InputMaybe<Array<SacadoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<SacadoAuditCreateOrConnectWithoutSacadoInput>>;
    create?: InputMaybe<Array<SacadoAuditCreateWithoutSacadoInput>>;
    createMany?: InputMaybe<SacadoAuditCreateManySacadoInputEnvelope>;
};

export type SacadoAuditCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<SacadoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<SacadoAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<SacadoAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<SacadoAuditCreateManyUsuarioInputEnvelope>;
};

export type SacadoAuditCreateOrConnectWithoutSacadoInput = {
    create: SacadoAuditCreateWithoutSacadoInput;
    where: SacadoAuditWhereUniqueInput;
};

export type SacadoAuditCreateOrConnectWithoutUsuarioInput = {
    create: SacadoAuditCreateWithoutUsuarioInput;
    where: SacadoAuditWhereUniqueInput;
};

export type SacadoAuditCreateWithoutSacadoInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistSacadoInput>;
};

export type SacadoAuditCreateWithoutUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    sacado: SacadoCreateNestedOneWithoutHistoricoInput;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
};

export type SacadoAuditGroupBy = {
    __typename?: "SacadoAuditGroupBy";
    _avg?: Maybe<SacadoAuditAvgAggregate>;
    _count?: Maybe<SacadoAuditCountAggregate>;
    _max?: Maybe<SacadoAuditMaxAggregate>;
    _min?: Maybe<SacadoAuditMinAggregate>;
    _sum?: Maybe<SacadoAuditSumAggregate>;
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    sacadoId: Scalars["Int"];
    service: TipoServico;
    userAgent: Scalars["String"];
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type SacadoAuditListRelationFilter = {
    every?: InputMaybe<SacadoAuditWhereInput>;
    none?: InputMaybe<SacadoAuditWhereInput>;
    some?: InputMaybe<SacadoAuditWhereInput>;
};

export type SacadoAuditMaxAggregate = {
    __typename?: "SacadoAuditMaxAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type SacadoAuditMaxOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type SacadoAuditMinAggregate = {
    __typename?: "SacadoAuditMinAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type SacadoAuditMinOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type SacadoAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type SacadoAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<SacadoAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<SacadoAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<SacadoAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<SacadoAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<SacadoAuditSumOrderByAggregateInput>;
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type SacadoAuditOrderByWithRelationInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    sacado?: InputMaybe<SacadoOrderByWithRelationInput>;
    sacadoId?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum SacadoAuditScalarFieldEnum {
    Action = "action",
    CreatedAt = "createdAt",
    Field = "field",
    Id = "id",
    IpUsuario = "ipUsuario",
    NewValue = "newValue",
    OldValue = "oldValue",
    SacadoId = "sacadoId",
    Service = "service",
    UserAgent = "userAgent",
    UsuarioId = "usuarioId",
}

export type SacadoAuditScalarWhereInput = {
    AND?: InputMaybe<Array<SacadoAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<SacadoAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<SacadoAuditScalarWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    sacadoId?: InputMaybe<IntFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type SacadoAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<SacadoAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<SacadoAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<SacadoAuditScalarWhereWithAggregatesInput>>;
    action?: InputMaybe<EnumAuditAcoesWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    field?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ipUsuario?: InputMaybe<StringWithAggregatesFilter>;
    newValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    sacadoId?: InputMaybe<IntWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type SacadoAuditSumAggregate = {
    __typename?: "SacadoAuditSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type SacadoAuditSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type SacadoAuditUpdateInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutHistoricoNestedInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistSacadoNestedInput>;
};

export type SacadoAuditUpdateManyMutationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SacadoAuditUpdateManyWithWhereWithoutSacadoInput = {
    data: SacadoAuditUpdateManyMutationInput;
    where: SacadoAuditScalarWhereInput;
};

export type SacadoAuditUpdateManyWithWhereWithoutUsuarioInput = {
    data: SacadoAuditUpdateManyMutationInput;
    where: SacadoAuditScalarWhereInput;
};

export type SacadoAuditUpdateManyWithoutSacadoNestedInput = {
    connect?: InputMaybe<Array<SacadoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<SacadoAuditCreateOrConnectWithoutSacadoInput>>;
    create?: InputMaybe<Array<SacadoAuditCreateWithoutSacadoInput>>;
    createMany?: InputMaybe<SacadoAuditCreateManySacadoInputEnvelope>;
    delete?: InputMaybe<Array<SacadoAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<SacadoAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<SacadoAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<SacadoAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<SacadoAuditUpdateWithWhereUniqueWithoutSacadoInput>>;
    updateMany?: InputMaybe<Array<SacadoAuditUpdateManyWithWhereWithoutSacadoInput>>;
    upsert?: InputMaybe<Array<SacadoAuditUpsertWithWhereUniqueWithoutSacadoInput>>;
};

export type SacadoAuditUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<SacadoAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<SacadoAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<SacadoAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<SacadoAuditCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<SacadoAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<SacadoAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<SacadoAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<SacadoAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<SacadoAuditUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<SacadoAuditUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<SacadoAuditUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type SacadoAuditUpdateWithWhereUniqueWithoutSacadoInput = {
    data: SacadoAuditUpdateWithoutSacadoInput;
    where: SacadoAuditWhereUniqueInput;
};

export type SacadoAuditUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: SacadoAuditUpdateWithoutUsuarioInput;
    where: SacadoAuditWhereUniqueInput;
};

export type SacadoAuditUpdateWithoutSacadoInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistSacadoNestedInput>;
};

export type SacadoAuditUpdateWithoutUsuarioInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutHistoricoNestedInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SacadoAuditUpsertWithWhereUniqueWithoutSacadoInput = {
    create: SacadoAuditCreateWithoutSacadoInput;
    update: SacadoAuditUpdateWithoutSacadoInput;
    where: SacadoAuditWhereUniqueInput;
};

export type SacadoAuditUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: SacadoAuditCreateWithoutUsuarioInput;
    update: SacadoAuditUpdateWithoutUsuarioInput;
    where: SacadoAuditWhereUniqueInput;
};

export type SacadoAuditWhereInput = {
    AND?: InputMaybe<Array<SacadoAuditWhereInput>>;
    NOT?: InputMaybe<Array<SacadoAuditWhereInput>>;
    OR?: InputMaybe<Array<SacadoAuditWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    sacado?: InputMaybe<SacadoRelationFilter>;
    sacadoId?: InputMaybe<IntFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
};

export type SacadoAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type SacadoAvgAggregate = {
    __typename?: "SacadoAvgAggregate";
    enderecoId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
};

export type SacadoAvgOrderByAggregateInput = {
    enderecoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type SacadoContato = {
    __typename?: "SacadoContato";
    contato: Contato;
    contatoId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    inativo: Scalars["Boolean"];
    principal: Scalars["Boolean"];
    sacado: Sacado;
    sacadoId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type SacadoContatoAvgAggregate = {
    __typename?: "SacadoContatoAvgAggregate";
    contatoId?: Maybe<Scalars["Float"]>;
    sacadoId?: Maybe<Scalars["Float"]>;
};

export type SacadoContatoAvgOrderByAggregateInput = {
    contatoId?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
};

export type SacadoContatoCountAggregate = {
    __typename?: "SacadoContatoCountAggregate";
    _all: Scalars["Int"];
    contatoId: Scalars["Int"];
    createdAt: Scalars["Int"];
    inativo: Scalars["Int"];
    principal: Scalars["Int"];
    sacadoId: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type SacadoContatoCountOrderByAggregateInput = {
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type SacadoContatoCreateInput = {
    contato: ContatoCreateNestedOneWithoutSacadosInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    sacado: SacadoCreateNestedOneWithoutContatosInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoContatoCreateManyContatoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    sacadoId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoContatoCreateManyContatoInputEnvelope = {
    data: Array<SacadoContatoCreateManyContatoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type SacadoContatoCreateManyInput = {
    contatoId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    sacadoId: Scalars["Int"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoContatoCreateManySacadoInput = {
    contatoId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoContatoCreateManySacadoInputEnvelope = {
    data: Array<SacadoContatoCreateManySacadoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type SacadoContatoCreateNestedManyWithoutContatoInput = {
    connect?: InputMaybe<Array<SacadoContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<SacadoContatoCreateOrConnectWithoutContatoInput>>;
    create?: InputMaybe<Array<SacadoContatoCreateWithoutContatoInput>>;
    createMany?: InputMaybe<SacadoContatoCreateManyContatoInputEnvelope>;
};

export type SacadoContatoCreateNestedManyWithoutSacadoInput = {
    connect?: InputMaybe<Array<SacadoContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<SacadoContatoCreateOrConnectWithoutSacadoInput>>;
    create?: InputMaybe<Array<SacadoContatoCreateWithoutSacadoInput>>;
    createMany?: InputMaybe<SacadoContatoCreateManySacadoInputEnvelope>;
};

export type SacadoContatoCreateOrConnectWithoutContatoInput = {
    create: SacadoContatoCreateWithoutContatoInput;
    where: SacadoContatoWhereUniqueInput;
};

export type SacadoContatoCreateOrConnectWithoutSacadoInput = {
    create: SacadoContatoCreateWithoutSacadoInput;
    where: SacadoContatoWhereUniqueInput;
};

export type SacadoContatoCreateWithoutContatoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    sacado: SacadoCreateNestedOneWithoutContatosInput;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoContatoCreateWithoutSacadoInput = {
    contato: ContatoCreateNestedOneWithoutSacadosInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    principal?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoContatoGroupBy = {
    __typename?: "SacadoContatoGroupBy";
    _avg?: Maybe<SacadoContatoAvgAggregate>;
    _count?: Maybe<SacadoContatoCountAggregate>;
    _max?: Maybe<SacadoContatoMaxAggregate>;
    _min?: Maybe<SacadoContatoMinAggregate>;
    _sum?: Maybe<SacadoContatoSumAggregate>;
    contatoId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    inativo: Scalars["Boolean"];
    principal: Scalars["Boolean"];
    sacadoId: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
};

export type SacadoContatoListRelationFilter = {
    every?: InputMaybe<SacadoContatoWhereInput>;
    none?: InputMaybe<SacadoContatoWhereInput>;
    some?: InputMaybe<SacadoContatoWhereInput>;
};

export type SacadoContatoMaxAggregate = {
    __typename?: "SacadoContatoMaxAggregate";
    contatoId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    principal?: Maybe<Scalars["Boolean"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SacadoContatoMaxOrderByAggregateInput = {
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type SacadoContatoMinAggregate = {
    __typename?: "SacadoContatoMinAggregate";
    contatoId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    principal?: Maybe<Scalars["Boolean"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SacadoContatoMinOrderByAggregateInput = {
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type SacadoContatoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type SacadoContatoOrderByWithAggregationInput = {
    _avg?: InputMaybe<SacadoContatoAvgOrderByAggregateInput>;
    _count?: InputMaybe<SacadoContatoCountOrderByAggregateInput>;
    _max?: InputMaybe<SacadoContatoMaxOrderByAggregateInput>;
    _min?: InputMaybe<SacadoContatoMinOrderByAggregateInput>;
    _sum?: InputMaybe<SacadoContatoSumOrderByAggregateInput>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type SacadoContatoOrderByWithRelationInput = {
    contato?: InputMaybe<ContatoOrderByWithRelationInput>;
    contatoId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    principal?: InputMaybe<SortOrder>;
    sacado?: InputMaybe<SacadoOrderByWithRelationInput>;
    sacadoId?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type SacadoContatoSacadoIdContatoIdCompoundUniqueInput = {
    contatoId: Scalars["Int"];
    sacadoId: Scalars["Int"];
};

export enum SacadoContatoScalarFieldEnum {
    ContatoId = "contatoId",
    CreatedAt = "createdAt",
    Inativo = "inativo",
    Principal = "principal",
    SacadoId = "sacadoId",
    UpdatedAt = "updatedAt",
}

export type SacadoContatoScalarWhereInput = {
    AND?: InputMaybe<Array<SacadoContatoScalarWhereInput>>;
    NOT?: InputMaybe<Array<SacadoContatoScalarWhereInput>>;
    OR?: InputMaybe<Array<SacadoContatoScalarWhereInput>>;
    contatoId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    inativo?: InputMaybe<BoolFilter>;
    principal?: InputMaybe<BoolFilter>;
    sacadoId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SacadoContatoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<SacadoContatoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<SacadoContatoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<SacadoContatoScalarWhereWithAggregatesInput>>;
    contatoId?: InputMaybe<IntWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    principal?: InputMaybe<BoolWithAggregatesFilter>;
    sacadoId?: InputMaybe<IntWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type SacadoContatoSumAggregate = {
    __typename?: "SacadoContatoSumAggregate";
    contatoId?: Maybe<Scalars["Int"]>;
    sacadoId?: Maybe<Scalars["Int"]>;
};

export type SacadoContatoSumOrderByAggregateInput = {
    contatoId?: InputMaybe<SortOrder>;
    sacadoId?: InputMaybe<SortOrder>;
};

export type SacadoContatoUpdateInput = {
    contato?: InputMaybe<ContatoUpdateOneRequiredWithoutSacadosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    principal?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutContatosNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoContatoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    principal?: InputMaybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoContatoUpdateManyWithWhereWithoutContatoInput = {
    data: SacadoContatoUpdateManyMutationInput;
    where: SacadoContatoScalarWhereInput;
};

export type SacadoContatoUpdateManyWithWhereWithoutSacadoInput = {
    data: SacadoContatoUpdateManyMutationInput;
    where: SacadoContatoScalarWhereInput;
};

export type SacadoContatoUpdateManyWithoutContatoNestedInput = {
    connect?: InputMaybe<Array<SacadoContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<SacadoContatoCreateOrConnectWithoutContatoInput>>;
    create?: InputMaybe<Array<SacadoContatoCreateWithoutContatoInput>>;
    createMany?: InputMaybe<SacadoContatoCreateManyContatoInputEnvelope>;
    delete?: InputMaybe<Array<SacadoContatoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<SacadoContatoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<SacadoContatoWhereUniqueInput>>;
    set?: InputMaybe<Array<SacadoContatoWhereUniqueInput>>;
    update?: InputMaybe<Array<SacadoContatoUpdateWithWhereUniqueWithoutContatoInput>>;
    updateMany?: InputMaybe<Array<SacadoContatoUpdateManyWithWhereWithoutContatoInput>>;
    upsert?: InputMaybe<Array<SacadoContatoUpsertWithWhereUniqueWithoutContatoInput>>;
};

export type SacadoContatoUpdateManyWithoutSacadoNestedInput = {
    connect?: InputMaybe<Array<SacadoContatoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<SacadoContatoCreateOrConnectWithoutSacadoInput>>;
    create?: InputMaybe<Array<SacadoContatoCreateWithoutSacadoInput>>;
    createMany?: InputMaybe<SacadoContatoCreateManySacadoInputEnvelope>;
    delete?: InputMaybe<Array<SacadoContatoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<SacadoContatoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<SacadoContatoWhereUniqueInput>>;
    set?: InputMaybe<Array<SacadoContatoWhereUniqueInput>>;
    update?: InputMaybe<Array<SacadoContatoUpdateWithWhereUniqueWithoutSacadoInput>>;
    updateMany?: InputMaybe<Array<SacadoContatoUpdateManyWithWhereWithoutSacadoInput>>;
    upsert?: InputMaybe<Array<SacadoContatoUpsertWithWhereUniqueWithoutSacadoInput>>;
};

export type SacadoContatoUpdateWithWhereUniqueWithoutContatoInput = {
    data: SacadoContatoUpdateWithoutContatoInput;
    where: SacadoContatoWhereUniqueInput;
};

export type SacadoContatoUpdateWithWhereUniqueWithoutSacadoInput = {
    data: SacadoContatoUpdateWithoutSacadoInput;
    where: SacadoContatoWhereUniqueInput;
};

export type SacadoContatoUpdateWithoutContatoInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    principal?: InputMaybe<BoolFieldUpdateOperationsInput>;
    sacado?: InputMaybe<SacadoUpdateOneRequiredWithoutContatosNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoContatoUpdateWithoutSacadoInput = {
    contato?: InputMaybe<ContatoUpdateOneRequiredWithoutSacadosNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    principal?: InputMaybe<BoolFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoContatoUpsertWithWhereUniqueWithoutContatoInput = {
    create: SacadoContatoCreateWithoutContatoInput;
    update: SacadoContatoUpdateWithoutContatoInput;
    where: SacadoContatoWhereUniqueInput;
};

export type SacadoContatoUpsertWithWhereUniqueWithoutSacadoInput = {
    create: SacadoContatoCreateWithoutSacadoInput;
    update: SacadoContatoUpdateWithoutSacadoInput;
    where: SacadoContatoWhereUniqueInput;
};

export type SacadoContatoWhereInput = {
    AND?: InputMaybe<Array<SacadoContatoWhereInput>>;
    NOT?: InputMaybe<Array<SacadoContatoWhereInput>>;
    OR?: InputMaybe<Array<SacadoContatoWhereInput>>;
    contato?: InputMaybe<ContatoRelationFilter>;
    contatoId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    inativo?: InputMaybe<BoolFilter>;
    principal?: InputMaybe<BoolFilter>;
    sacado?: InputMaybe<SacadoRelationFilter>;
    sacadoId?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SacadoContatoWhereUniqueInput = {
    sacadoId_contatoId?: InputMaybe<SacadoContatoSacadoIdContatoIdCompoundUniqueInput>;
};

export type SacadoCount = {
    __typename?: "SacadoCount";
    OperationsInfo: Scalars["Int"];
    RecebiveisInfo: Scalars["Int"];
    RecebiveisToAnticipate: Scalars["Int"];
    contatos: Scalars["Int"];
    historico: Scalars["Int"];
    minibancos: Scalars["Int"];
    recebiveis: Scalars["Int"];
};

export type SacadoCountAggregate = {
    __typename?: "SacadoCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    document: Scalars["Int"];
    enderecoId: Scalars["Int"];
    fone: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    nomeFantasia: Scalars["Int"];
    pessoaFisica: Scalars["Int"];
    razaoSocial: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type SacadoCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type SacadoCreateInput = {
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutSacadoInput>;
    contatos?: InputMaybe<SacadoContatoCreateNestedManyWithoutSacadoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutSacadosInput>;
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<SacadoAuditCreateNestedManyWithoutSacadoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutSacadosInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutSacadoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoCreateManyEnderecoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    fone?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoCreateManyEnderecoInputEnvelope = {
    data: Array<SacadoCreateManyEnderecoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type SacadoCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    enderecoId?: InputMaybe<Scalars["Int"]>;
    fone?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoCreateNestedManyWithoutEnderecoInput = {
    connect?: InputMaybe<Array<SacadoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<SacadoCreateOrConnectWithoutEnderecoInput>>;
    create?: InputMaybe<Array<SacadoCreateWithoutEnderecoInput>>;
    createMany?: InputMaybe<SacadoCreateManyEnderecoInputEnvelope>;
};

export type SacadoCreateNestedManyWithoutMinibancosInput = {
    connect?: InputMaybe<Array<SacadoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<SacadoCreateOrConnectWithoutMinibancosInput>>;
    create?: InputMaybe<Array<SacadoCreateWithoutMinibancosInput>>;
};

export type SacadoCreateNestedOneWithoutContatosInput = {
    connect?: InputMaybe<SacadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SacadoCreateOrConnectWithoutContatosInput>;
    create?: InputMaybe<SacadoCreateWithoutContatosInput>;
};

export type SacadoCreateNestedOneWithoutHistoricoInput = {
    connect?: InputMaybe<SacadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SacadoCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<SacadoCreateWithoutHistoricoInput>;
};

export type SacadoCreateNestedOneWithoutOperationsInfoInput = {
    connect?: InputMaybe<SacadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SacadoCreateOrConnectWithoutOperationsInfoInput>;
    create?: InputMaybe<SacadoCreateWithoutOperationsInfoInput>;
};

export type SacadoCreateNestedOneWithoutRecebiveisInfoInput = {
    connect?: InputMaybe<SacadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SacadoCreateOrConnectWithoutRecebiveisInfoInput>;
    create?: InputMaybe<SacadoCreateWithoutRecebiveisInfoInput>;
};

export type SacadoCreateNestedOneWithoutRecebiveisInput = {
    connect?: InputMaybe<SacadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SacadoCreateOrConnectWithoutRecebiveisInput>;
    create?: InputMaybe<SacadoCreateWithoutRecebiveisInput>;
};

export type SacadoCreateNestedOneWithoutRecebiveisToAnticipateInput = {
    connect?: InputMaybe<SacadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SacadoCreateOrConnectWithoutRecebiveisToAnticipateInput>;
    create?: InputMaybe<SacadoCreateWithoutRecebiveisToAnticipateInput>;
};

export type SacadoCreateOrConnectWithoutContatosInput = {
    create: SacadoCreateWithoutContatosInput;
    where: SacadoWhereUniqueInput;
};

export type SacadoCreateOrConnectWithoutEnderecoInput = {
    create: SacadoCreateWithoutEnderecoInput;
    where: SacadoWhereUniqueInput;
};

export type SacadoCreateOrConnectWithoutHistoricoInput = {
    create: SacadoCreateWithoutHistoricoInput;
    where: SacadoWhereUniqueInput;
};

export type SacadoCreateOrConnectWithoutMinibancosInput = {
    create: SacadoCreateWithoutMinibancosInput;
    where: SacadoWhereUniqueInput;
};

export type SacadoCreateOrConnectWithoutOperationsInfoInput = {
    create: SacadoCreateWithoutOperationsInfoInput;
    where: SacadoWhereUniqueInput;
};

export type SacadoCreateOrConnectWithoutRecebiveisInfoInput = {
    create: SacadoCreateWithoutRecebiveisInfoInput;
    where: SacadoWhereUniqueInput;
};

export type SacadoCreateOrConnectWithoutRecebiveisInput = {
    create: SacadoCreateWithoutRecebiveisInput;
    where: SacadoWhereUniqueInput;
};

export type SacadoCreateOrConnectWithoutRecebiveisToAnticipateInput = {
    create: SacadoCreateWithoutRecebiveisToAnticipateInput;
    where: SacadoWhereUniqueInput;
};

export type SacadoCreateWithoutContatosInput = {
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutSacadoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutSacadosInput>;
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<SacadoAuditCreateNestedManyWithoutSacadoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutSacadosInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutSacadoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoCreateWithoutEnderecoInput = {
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutSacadoInput>;
    contatos?: InputMaybe<SacadoContatoCreateNestedManyWithoutSacadoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<SacadoAuditCreateNestedManyWithoutSacadoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutSacadosInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutSacadoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoCreateWithoutHistoricoInput = {
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutSacadoInput>;
    contatos?: InputMaybe<SacadoContatoCreateNestedManyWithoutSacadoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutSacadosInput>;
    fone?: InputMaybe<Scalars["String"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutSacadosInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutSacadoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoCreateWithoutMinibancosInput = {
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutSacadoInput>;
    contatos?: InputMaybe<SacadoContatoCreateNestedManyWithoutSacadoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutSacadosInput>;
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<SacadoAuditCreateNestedManyWithoutSacadoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutSacadoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoCreateWithoutOperationsInfoInput = {
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutSacadoInput>;
    contatos?: InputMaybe<SacadoContatoCreateNestedManyWithoutSacadoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutSacadosInput>;
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<SacadoAuditCreateNestedManyWithoutSacadoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutSacadosInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutSacadoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoCreateWithoutRecebiveisInfoInput = {
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutSacadoInput>;
    contatos?: InputMaybe<SacadoContatoCreateNestedManyWithoutSacadoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutSacadosInput>;
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<SacadoAuditCreateNestedManyWithoutSacadoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutSacadosInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutSacadoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoCreateWithoutRecebiveisInput = {
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateCreateNestedManyWithoutSacadoInput>;
    contatos?: InputMaybe<SacadoContatoCreateNestedManyWithoutSacadoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutSacadosInput>;
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<SacadoAuditCreateNestedManyWithoutSacadoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutSacadosInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoCreateWithoutRecebiveisToAnticipateInput = {
    OperationsInfo?: InputMaybe<OperationsInfoCreateNestedManyWithoutSacadoInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoCreateNestedManyWithoutSacadoInput>;
    contatos?: InputMaybe<SacadoContatoCreateNestedManyWithoutSacadoInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    document: Scalars["String"];
    endereco?: InputMaybe<EnderecoCreateNestedOneWithoutSacadosInput>;
    fone?: InputMaybe<Scalars["String"]>;
    historico?: InputMaybe<SacadoAuditCreateNestedManyWithoutSacadoInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancos?: InputMaybe<MinibancoCreateNestedManyWithoutSacadosInput>;
    nomeFantasia?: InputMaybe<Scalars["String"]>;
    pessoaFisica?: InputMaybe<Scalars["Boolean"]>;
    razaoSocial: Scalars["String"];
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutSacadoInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type SacadoGroupBy = {
    __typename?: "SacadoGroupBy";
    _avg?: Maybe<SacadoAvgAggregate>;
    _count?: Maybe<SacadoCountAggregate>;
    _max?: Maybe<SacadoMaxAggregate>;
    _min?: Maybe<SacadoMinAggregate>;
    _sum?: Maybe<SacadoSumAggregate>;
    createdAt: Scalars["DateTime"];
    document: Scalars["String"];
    enderecoId?: Maybe<Scalars["Int"]>;
    fone?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    nomeFantasia?: Maybe<Scalars["String"]>;
    pessoaFisica: Scalars["Boolean"];
    razaoSocial: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type SacadoListRelationFilter = {
    every?: InputMaybe<SacadoWhereInput>;
    none?: InputMaybe<SacadoWhereInput>;
    some?: InputMaybe<SacadoWhereInput>;
};

export type SacadoMaxAggregate = {
    __typename?: "SacadoMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    document?: Maybe<Scalars["String"]>;
    enderecoId?: Maybe<Scalars["Int"]>;
    fone?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    nomeFantasia?: Maybe<Scalars["String"]>;
    pessoaFisica?: Maybe<Scalars["Boolean"]>;
    razaoSocial?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SacadoMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type SacadoMinAggregate = {
    __typename?: "SacadoMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    document?: Maybe<Scalars["String"]>;
    enderecoId?: Maybe<Scalars["Int"]>;
    fone?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    nomeFantasia?: Maybe<Scalars["String"]>;
    pessoaFisica?: Maybe<Scalars["Boolean"]>;
    razaoSocial?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SacadoMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type SacadoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type SacadoOrderByWithAggregationInput = {
    _avg?: InputMaybe<SacadoAvgOrderByAggregateInput>;
    _count?: InputMaybe<SacadoCountOrderByAggregateInput>;
    _max?: InputMaybe<SacadoMaxOrderByAggregateInput>;
    _min?: InputMaybe<SacadoMinOrderByAggregateInput>;
    _sum?: InputMaybe<SacadoSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    enderecoId?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    nomeFantasia?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type SacadoOrderByWithRelationInput = {
    OperationsInfo?: InputMaybe<OperationsInfoOrderByRelationAggregateInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoOrderByRelationAggregateInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateOrderByRelationAggregateInput>;
    contatos?: InputMaybe<SacadoContatoOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    document?: InputMaybe<SortOrder>;
    endereco?: InputMaybe<EnderecoOrderByWithRelationInput>;
    enderecoId?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    historico?: InputMaybe<SacadoAuditOrderByRelationAggregateInput>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancos?: InputMaybe<MinibancoOrderByRelationAggregateInput>;
    nomeFantasia?: InputMaybe<SortOrder>;
    pessoaFisica?: InputMaybe<SortOrder>;
    razaoSocial?: InputMaybe<SortOrder>;
    recebiveis?: InputMaybe<RecebivelOrderByRelationAggregateInput>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type SacadoRelationFilter = {
    is?: InputMaybe<SacadoWhereInput>;
    isNot?: InputMaybe<SacadoWhereInput>;
};

export enum SacadoScalarFieldEnum {
    CreatedAt = "createdAt",
    Document = "document",
    EnderecoId = "enderecoId",
    Fone = "fone",
    Id = "id",
    Inativo = "inativo",
    NomeFantasia = "nomeFantasia",
    PessoaFisica = "pessoaFisica",
    RazaoSocial = "razaoSocial",
    UpdatedAt = "updatedAt",
}

export type SacadoScalarWhereInput = {
    AND?: InputMaybe<Array<SacadoScalarWhereInput>>;
    NOT?: InputMaybe<Array<SacadoScalarWhereInput>>;
    OR?: InputMaybe<Array<SacadoScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    document?: InputMaybe<StringFilter>;
    enderecoId?: InputMaybe<IntNullableFilter>;
    fone?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    nomeFantasia?: InputMaybe<StringNullableFilter>;
    pessoaFisica?: InputMaybe<BoolFilter>;
    razaoSocial?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SacadoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<SacadoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<SacadoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<SacadoScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    document?: InputMaybe<StringWithAggregatesFilter>;
    enderecoId?: InputMaybe<IntNullableWithAggregatesFilter>;
    fone?: InputMaybe<StringNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    nomeFantasia?: InputMaybe<StringNullableWithAggregatesFilter>;
    pessoaFisica?: InputMaybe<BoolWithAggregatesFilter>;
    razaoSocial?: InputMaybe<StringWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type SacadoSumAggregate = {
    __typename?: "SacadoSumAggregate";
    enderecoId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
};

export type SacadoSumOrderByAggregateInput = {
    enderecoId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type SacadoUpdateInput = {
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutSacadoNestedInput>;
    contatos?: InputMaybe<SacadoContatoUpdateManyWithoutSacadoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutSacadosNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<SacadoAuditUpdateManyWithoutSacadoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutSacadosNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutSacadoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoUpdateManyWithWhereWithoutEnderecoInput = {
    data: SacadoUpdateManyMutationInput;
    where: SacadoScalarWhereInput;
};

export type SacadoUpdateManyWithWhereWithoutMinibancosInput = {
    data: SacadoUpdateManyMutationInput;
    where: SacadoScalarWhereInput;
};

export type SacadoUpdateManyWithoutEnderecoNestedInput = {
    connect?: InputMaybe<Array<SacadoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<SacadoCreateOrConnectWithoutEnderecoInput>>;
    create?: InputMaybe<Array<SacadoCreateWithoutEnderecoInput>>;
    createMany?: InputMaybe<SacadoCreateManyEnderecoInputEnvelope>;
    delete?: InputMaybe<Array<SacadoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<SacadoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<SacadoWhereUniqueInput>>;
    set?: InputMaybe<Array<SacadoWhereUniqueInput>>;
    update?: InputMaybe<Array<SacadoUpdateWithWhereUniqueWithoutEnderecoInput>>;
    updateMany?: InputMaybe<Array<SacadoUpdateManyWithWhereWithoutEnderecoInput>>;
    upsert?: InputMaybe<Array<SacadoUpsertWithWhereUniqueWithoutEnderecoInput>>;
};

export type SacadoUpdateManyWithoutMinibancosNestedInput = {
    connect?: InputMaybe<Array<SacadoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<SacadoCreateOrConnectWithoutMinibancosInput>>;
    create?: InputMaybe<Array<SacadoCreateWithoutMinibancosInput>>;
    delete?: InputMaybe<Array<SacadoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<SacadoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<SacadoWhereUniqueInput>>;
    set?: InputMaybe<Array<SacadoWhereUniqueInput>>;
    update?: InputMaybe<Array<SacadoUpdateWithWhereUniqueWithoutMinibancosInput>>;
    updateMany?: InputMaybe<Array<SacadoUpdateManyWithWhereWithoutMinibancosInput>>;
    upsert?: InputMaybe<Array<SacadoUpsertWithWhereUniqueWithoutMinibancosInput>>;
};

export type SacadoUpdateOneRequiredWithoutContatosNestedInput = {
    connect?: InputMaybe<SacadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SacadoCreateOrConnectWithoutContatosInput>;
    create?: InputMaybe<SacadoCreateWithoutContatosInput>;
    update?: InputMaybe<SacadoUpdateWithoutContatosInput>;
    upsert?: InputMaybe<SacadoUpsertWithoutContatosInput>;
};

export type SacadoUpdateOneRequiredWithoutHistoricoNestedInput = {
    connect?: InputMaybe<SacadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SacadoCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<SacadoCreateWithoutHistoricoInput>;
    update?: InputMaybe<SacadoUpdateWithoutHistoricoInput>;
    upsert?: InputMaybe<SacadoUpsertWithoutHistoricoInput>;
};

export type SacadoUpdateOneRequiredWithoutOperationsInfoNestedInput = {
    connect?: InputMaybe<SacadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SacadoCreateOrConnectWithoutOperationsInfoInput>;
    create?: InputMaybe<SacadoCreateWithoutOperationsInfoInput>;
    update?: InputMaybe<SacadoUpdateWithoutOperationsInfoInput>;
    upsert?: InputMaybe<SacadoUpsertWithoutOperationsInfoInput>;
};

export type SacadoUpdateOneRequiredWithoutRecebiveisInfoNestedInput = {
    connect?: InputMaybe<SacadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SacadoCreateOrConnectWithoutRecebiveisInfoInput>;
    create?: InputMaybe<SacadoCreateWithoutRecebiveisInfoInput>;
    update?: InputMaybe<SacadoUpdateWithoutRecebiveisInfoInput>;
    upsert?: InputMaybe<SacadoUpsertWithoutRecebiveisInfoInput>;
};

export type SacadoUpdateOneRequiredWithoutRecebiveisNestedInput = {
    connect?: InputMaybe<SacadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SacadoCreateOrConnectWithoutRecebiveisInput>;
    create?: InputMaybe<SacadoCreateWithoutRecebiveisInput>;
    update?: InputMaybe<SacadoUpdateWithoutRecebiveisInput>;
    upsert?: InputMaybe<SacadoUpsertWithoutRecebiveisInput>;
};

export type SacadoUpdateOneRequiredWithoutRecebiveisToAnticipateNestedInput = {
    connect?: InputMaybe<SacadoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SacadoCreateOrConnectWithoutRecebiveisToAnticipateInput>;
    create?: InputMaybe<SacadoCreateWithoutRecebiveisToAnticipateInput>;
    update?: InputMaybe<SacadoUpdateWithoutRecebiveisToAnticipateInput>;
    upsert?: InputMaybe<SacadoUpsertWithoutRecebiveisToAnticipateInput>;
};

export type SacadoUpdateWithWhereUniqueWithoutEnderecoInput = {
    data: SacadoUpdateWithoutEnderecoInput;
    where: SacadoWhereUniqueInput;
};

export type SacadoUpdateWithWhereUniqueWithoutMinibancosInput = {
    data: SacadoUpdateWithoutMinibancosInput;
    where: SacadoWhereUniqueInput;
};

export type SacadoUpdateWithoutContatosInput = {
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutSacadoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutSacadosNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<SacadoAuditUpdateManyWithoutSacadoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutSacadosNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutSacadoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoUpdateWithoutEnderecoInput = {
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutSacadoNestedInput>;
    contatos?: InputMaybe<SacadoContatoUpdateManyWithoutSacadoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<SacadoAuditUpdateManyWithoutSacadoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutSacadosNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutSacadoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoUpdateWithoutHistoricoInput = {
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutSacadoNestedInput>;
    contatos?: InputMaybe<SacadoContatoUpdateManyWithoutSacadoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutSacadosNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutSacadosNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutSacadoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoUpdateWithoutMinibancosInput = {
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutSacadoNestedInput>;
    contatos?: InputMaybe<SacadoContatoUpdateManyWithoutSacadoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutSacadosNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<SacadoAuditUpdateManyWithoutSacadoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutSacadoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoUpdateWithoutOperationsInfoInput = {
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutSacadoNestedInput>;
    contatos?: InputMaybe<SacadoContatoUpdateManyWithoutSacadoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutSacadosNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<SacadoAuditUpdateManyWithoutSacadoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutSacadosNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutSacadoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoUpdateWithoutRecebiveisInfoInput = {
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutSacadoNestedInput>;
    contatos?: InputMaybe<SacadoContatoUpdateManyWithoutSacadoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutSacadosNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<SacadoAuditUpdateManyWithoutSacadoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutSacadosNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutSacadoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoUpdateWithoutRecebiveisInput = {
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateUpdateManyWithoutSacadoNestedInput>;
    contatos?: InputMaybe<SacadoContatoUpdateManyWithoutSacadoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutSacadosNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<SacadoAuditUpdateManyWithoutSacadoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutSacadosNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoUpdateWithoutRecebiveisToAnticipateInput = {
    OperationsInfo?: InputMaybe<OperationsInfoUpdateManyWithoutSacadoNestedInput>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoUpdateManyWithoutSacadoNestedInput>;
    contatos?: InputMaybe<SacadoContatoUpdateManyWithoutSacadoNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    document?: InputMaybe<StringFieldUpdateOperationsInput>;
    endereco?: InputMaybe<EnderecoUpdateOneWithoutSacadosNestedInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    historico?: InputMaybe<SacadoAuditUpdateManyWithoutSacadoNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibancos?: InputMaybe<MinibancoUpdateManyWithoutSacadosNestedInput>;
    nomeFantasia?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    pessoaFisica?: InputMaybe<BoolFieldUpdateOperationsInput>;
    razaoSocial?: InputMaybe<StringFieldUpdateOperationsInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutSacadoNestedInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SacadoUpsertWithWhereUniqueWithoutEnderecoInput = {
    create: SacadoCreateWithoutEnderecoInput;
    update: SacadoUpdateWithoutEnderecoInput;
    where: SacadoWhereUniqueInput;
};

export type SacadoUpsertWithWhereUniqueWithoutMinibancosInput = {
    create: SacadoCreateWithoutMinibancosInput;
    update: SacadoUpdateWithoutMinibancosInput;
    where: SacadoWhereUniqueInput;
};

export type SacadoUpsertWithoutContatosInput = {
    create: SacadoCreateWithoutContatosInput;
    update: SacadoUpdateWithoutContatosInput;
};

export type SacadoUpsertWithoutHistoricoInput = {
    create: SacadoCreateWithoutHistoricoInput;
    update: SacadoUpdateWithoutHistoricoInput;
};

export type SacadoUpsertWithoutOperationsInfoInput = {
    create: SacadoCreateWithoutOperationsInfoInput;
    update: SacadoUpdateWithoutOperationsInfoInput;
};

export type SacadoUpsertWithoutRecebiveisInfoInput = {
    create: SacadoCreateWithoutRecebiveisInfoInput;
    update: SacadoUpdateWithoutRecebiveisInfoInput;
};

export type SacadoUpsertWithoutRecebiveisInput = {
    create: SacadoCreateWithoutRecebiveisInput;
    update: SacadoUpdateWithoutRecebiveisInput;
};

export type SacadoUpsertWithoutRecebiveisToAnticipateInput = {
    create: SacadoCreateWithoutRecebiveisToAnticipateInput;
    update: SacadoUpdateWithoutRecebiveisToAnticipateInput;
};

export type SacadoWhereInput = {
    AND?: InputMaybe<Array<SacadoWhereInput>>;
    NOT?: InputMaybe<Array<SacadoWhereInput>>;
    OR?: InputMaybe<Array<SacadoWhereInput>>;
    OperationsInfo?: InputMaybe<OperationsInfoListRelationFilter>;
    RecebiveisInfo?: InputMaybe<RecebiveisInfoListRelationFilter>;
    RecebiveisToAnticipate?: InputMaybe<RecebiveisToAnticipateListRelationFilter>;
    contatos?: InputMaybe<SacadoContatoListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    document?: InputMaybe<StringFilter>;
    endereco?: InputMaybe<EnderecoRelationFilter>;
    enderecoId?: InputMaybe<IntNullableFilter>;
    fone?: InputMaybe<StringNullableFilter>;
    historico?: InputMaybe<SacadoAuditListRelationFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibancos?: InputMaybe<MinibancoListRelationFilter>;
    nomeFantasia?: InputMaybe<StringNullableFilter>;
    pessoaFisica?: InputMaybe<BoolFilter>;
    razaoSocial?: InputMaybe<StringFilter>;
    recebiveis?: InputMaybe<RecebivelListRelationFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SacadoWhereUniqueInput = {
    document?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
};

export enum SortOrder {
    Asc = "asc",
    Desc = "desc",
}

export enum StatusOperacional {
    AguardandoAprovacao = "AGUARDANDO_APROVACAO",
    AguardandoPagamento = "AGUARDANDO_PAGAMENTO",
    AnaliseCredito = "ANALISE_CREDITO",
    Aprovado = "APROVADO",
    EmAnalise = "EM_ANALISE",
    Recusado = "RECUSADO",
}

export type StatusOperacionalAudit = {
    __typename?: "StatusOperacionalAudit";
    cedente: Cedente;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue: StatusOperacional;
    observacao?: Maybe<Scalars["String"]>;
    oldValue: StatusOperacional;
    service: TipoServico;
    statusId: Scalars["Int"];
    statusOperacional: StatusOperacionalCedenteMinibanco;
    userAgent: Scalars["String"];
    usuario: Usuario;
    usuarioId: Scalars["Int"];
};

export type StatusOperacionalAuditAvgAggregate = {
    __typename?: "StatusOperacionalAuditAvgAggregate";
    cedenteId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    statusId?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
};

export type StatusOperacionalAuditAvgOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type StatusOperacionalAuditCountAggregate = {
    __typename?: "StatusOperacionalAuditCountAggregate";
    _all: Scalars["Int"];
    cedenteId: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    ipUsuario: Scalars["Int"];
    newValue: Scalars["Int"];
    observacao: Scalars["Int"];
    oldValue: Scalars["Int"];
    service: Scalars["Int"];
    statusId: Scalars["Int"];
    userAgent: Scalars["Int"];
    usuarioId: Scalars["Int"];
};

export type StatusOperacionalAuditCountOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type StatusOperacionalAuditCreateInput = {
    cedente: CedenteCreateNestedOneWithoutHistStatusInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    ipUsuario: Scalars["String"];
    newValue: StatusOperacional;
    observacao?: InputMaybe<Scalars["String"]>;
    oldValue: StatusOperacional;
    service?: InputMaybe<TipoServico>;
    statusOperacional: StatusOperacionalCedenteMinibancoCreateNestedOneWithoutStatusOperacionalAuditInput;
    userAgent: Scalars["String"];
    usuario: UsuarioCreateNestedOneWithoutHistStatusOperacionalInput;
};

export type StatusOperacionalAuditCreateManyCedenteInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue: StatusOperacional;
    observacao?: InputMaybe<Scalars["String"]>;
    oldValue: StatusOperacional;
    service?: InputMaybe<TipoServico>;
    statusId: Scalars["Int"];
    userAgent: Scalars["String"];
    usuarioId: Scalars["Int"];
};

export type StatusOperacionalAuditCreateManyCedenteInputEnvelope = {
    data: Array<StatusOperacionalAuditCreateManyCedenteInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type StatusOperacionalAuditCreateManyInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue: StatusOperacional;
    observacao?: InputMaybe<Scalars["String"]>;
    oldValue: StatusOperacional;
    service?: InputMaybe<TipoServico>;
    statusId: Scalars["Int"];
    userAgent: Scalars["String"];
    usuarioId: Scalars["Int"];
};

export type StatusOperacionalAuditCreateManyStatusOperacionalInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue: StatusOperacional;
    observacao?: InputMaybe<Scalars["String"]>;
    oldValue: StatusOperacional;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId: Scalars["Int"];
};

export type StatusOperacionalAuditCreateManyStatusOperacionalInputEnvelope = {
    data: Array<StatusOperacionalAuditCreateManyStatusOperacionalInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type StatusOperacionalAuditCreateManyUsuarioInput = {
    cedenteId: Scalars["Int"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue: StatusOperacional;
    observacao?: InputMaybe<Scalars["String"]>;
    oldValue: StatusOperacional;
    service?: InputMaybe<TipoServico>;
    statusId: Scalars["Int"];
    userAgent: Scalars["String"];
};

export type StatusOperacionalAuditCreateManyUsuarioInputEnvelope = {
    data: Array<StatusOperacionalAuditCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type StatusOperacionalAuditCreateNestedManyWithoutCedenteInput = {
    connect?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<StatusOperacionalAuditCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<StatusOperacionalAuditCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<StatusOperacionalAuditCreateManyCedenteInputEnvelope>;
};

export type StatusOperacionalAuditCreateNestedManyWithoutStatusOperacionalInput = {
    connect?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<StatusOperacionalAuditCreateOrConnectWithoutStatusOperacionalInput>>;
    create?: InputMaybe<Array<StatusOperacionalAuditCreateWithoutStatusOperacionalInput>>;
    createMany?: InputMaybe<StatusOperacionalAuditCreateManyStatusOperacionalInputEnvelope>;
};

export type StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<StatusOperacionalAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<StatusOperacionalAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<StatusOperacionalAuditCreateManyUsuarioInputEnvelope>;
};

export type StatusOperacionalAuditCreateOrConnectWithoutCedenteInput = {
    create: StatusOperacionalAuditCreateWithoutCedenteInput;
    where: StatusOperacionalAuditWhereUniqueInput;
};

export type StatusOperacionalAuditCreateOrConnectWithoutStatusOperacionalInput = {
    create: StatusOperacionalAuditCreateWithoutStatusOperacionalInput;
    where: StatusOperacionalAuditWhereUniqueInput;
};

export type StatusOperacionalAuditCreateOrConnectWithoutUsuarioInput = {
    create: StatusOperacionalAuditCreateWithoutUsuarioInput;
    where: StatusOperacionalAuditWhereUniqueInput;
};

export type StatusOperacionalAuditCreateWithoutCedenteInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    ipUsuario: Scalars["String"];
    newValue: StatusOperacional;
    observacao?: InputMaybe<Scalars["String"]>;
    oldValue: StatusOperacional;
    service?: InputMaybe<TipoServico>;
    statusOperacional: StatusOperacionalCedenteMinibancoCreateNestedOneWithoutStatusOperacionalAuditInput;
    userAgent: Scalars["String"];
    usuario: UsuarioCreateNestedOneWithoutHistStatusOperacionalInput;
};

export type StatusOperacionalAuditCreateWithoutStatusOperacionalInput = {
    cedente: CedenteCreateNestedOneWithoutHistStatusInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    ipUsuario: Scalars["String"];
    newValue: StatusOperacional;
    observacao?: InputMaybe<Scalars["String"]>;
    oldValue: StatusOperacional;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario: UsuarioCreateNestedOneWithoutHistStatusOperacionalInput;
};

export type StatusOperacionalAuditCreateWithoutUsuarioInput = {
    cedente: CedenteCreateNestedOneWithoutHistStatusInput;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    ipUsuario: Scalars["String"];
    newValue: StatusOperacional;
    observacao?: InputMaybe<Scalars["String"]>;
    oldValue: StatusOperacional;
    service?: InputMaybe<TipoServico>;
    statusOperacional: StatusOperacionalCedenteMinibancoCreateNestedOneWithoutStatusOperacionalAuditInput;
    userAgent: Scalars["String"];
};

export type StatusOperacionalAuditGroupBy = {
    __typename?: "StatusOperacionalAuditGroupBy";
    _avg?: Maybe<StatusOperacionalAuditAvgAggregate>;
    _count?: Maybe<StatusOperacionalAuditCountAggregate>;
    _max?: Maybe<StatusOperacionalAuditMaxAggregate>;
    _min?: Maybe<StatusOperacionalAuditMinAggregate>;
    _sum?: Maybe<StatusOperacionalAuditSumAggregate>;
    cedenteId: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue: StatusOperacional;
    observacao?: Maybe<Scalars["String"]>;
    oldValue: StatusOperacional;
    service: TipoServico;
    statusId: Scalars["Int"];
    userAgent: Scalars["String"];
    usuarioId: Scalars["Int"];
};

export type StatusOperacionalAuditListRelationFilter = {
    every?: InputMaybe<StatusOperacionalAuditWhereInput>;
    none?: InputMaybe<StatusOperacionalAuditWhereInput>;
    some?: InputMaybe<StatusOperacionalAuditWhereInput>;
};

export type StatusOperacionalAuditMaxAggregate = {
    __typename?: "StatusOperacionalAuditMaxAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<StatusOperacional>;
    observacao?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<StatusOperacional>;
    service?: Maybe<TipoServico>;
    statusId?: Maybe<Scalars["Int"]>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type StatusOperacionalAuditMaxOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type StatusOperacionalAuditMinAggregate = {
    __typename?: "StatusOperacionalAuditMinAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<StatusOperacional>;
    observacao?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<StatusOperacional>;
    service?: Maybe<TipoServico>;
    statusId?: Maybe<Scalars["Int"]>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type StatusOperacionalAuditMinOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type StatusOperacionalAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type StatusOperacionalAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<StatusOperacionalAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<StatusOperacionalAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<StatusOperacionalAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<StatusOperacionalAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<StatusOperacionalAuditSumOrderByAggregateInput>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type StatusOperacionalAuditOrderByWithRelationInput = {
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    observacao?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoOrderByWithRelationInput>;
    userAgent?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
};

export enum StatusOperacionalAuditScalarFieldEnum {
    CedenteId = "cedenteId",
    CreatedAt = "createdAt",
    Id = "id",
    IpUsuario = "ipUsuario",
    NewValue = "newValue",
    Observacao = "observacao",
    OldValue = "oldValue",
    Service = "service",
    StatusId = "statusId",
    UserAgent = "userAgent",
    UsuarioId = "usuarioId",
}

export type StatusOperacionalAuditScalarWhereInput = {
    AND?: InputMaybe<Array<StatusOperacionalAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<StatusOperacionalAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<StatusOperacionalAuditScalarWhereInput>>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<EnumStatusOperacionalFilter>;
    observacao?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<EnumStatusOperacionalFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    statusId?: InputMaybe<IntFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuarioId?: InputMaybe<IntFilter>;
};

export type StatusOperacionalAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<StatusOperacionalAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<StatusOperacionalAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<StatusOperacionalAuditScalarWhereWithAggregatesInput>>;
    cedenteId?: InputMaybe<IntWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ipUsuario?: InputMaybe<StringWithAggregatesFilter>;
    newValue?: InputMaybe<EnumStatusOperacionalWithAggregatesFilter>;
    observacao?: InputMaybe<StringNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<EnumStatusOperacionalWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    statusId?: InputMaybe<IntWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntWithAggregatesFilter>;
};

export type StatusOperacionalAuditSumAggregate = {
    __typename?: "StatusOperacionalAuditSumAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    statusId?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
};

export type StatusOperacionalAuditSumOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    statusId?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
};

export type StatusOperacionalAuditUpdateInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutHistStatusNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateOneRequiredWithoutStatusOperacionalAuditNestedInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutHistStatusOperacionalNestedInput>;
};

export type StatusOperacionalAuditUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StatusOperacionalAuditUpdateManyWithWhereWithoutCedenteInput = {
    data: StatusOperacionalAuditUpdateManyMutationInput;
    where: StatusOperacionalAuditScalarWhereInput;
};

export type StatusOperacionalAuditUpdateManyWithWhereWithoutStatusOperacionalInput = {
    data: StatusOperacionalAuditUpdateManyMutationInput;
    where: StatusOperacionalAuditScalarWhereInput;
};

export type StatusOperacionalAuditUpdateManyWithWhereWithoutUsuarioInput = {
    data: StatusOperacionalAuditUpdateManyMutationInput;
    where: StatusOperacionalAuditScalarWhereInput;
};

export type StatusOperacionalAuditUpdateManyWithoutCedenteNestedInput = {
    connect?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<StatusOperacionalAuditCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<StatusOperacionalAuditCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<StatusOperacionalAuditCreateManyCedenteInputEnvelope>;
    delete?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<StatusOperacionalAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<StatusOperacionalAuditUpdateWithWhereUniqueWithoutCedenteInput>>;
    updateMany?: InputMaybe<Array<StatusOperacionalAuditUpdateManyWithWhereWithoutCedenteInput>>;
    upsert?: InputMaybe<Array<StatusOperacionalAuditUpsertWithWhereUniqueWithoutCedenteInput>>;
};

export type StatusOperacionalAuditUpdateManyWithoutStatusOperacionalNestedInput = {
    connect?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<StatusOperacionalAuditCreateOrConnectWithoutStatusOperacionalInput>>;
    create?: InputMaybe<Array<StatusOperacionalAuditCreateWithoutStatusOperacionalInput>>;
    createMany?: InputMaybe<StatusOperacionalAuditCreateManyStatusOperacionalInputEnvelope>;
    delete?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<StatusOperacionalAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<StatusOperacionalAuditUpdateWithWhereUniqueWithoutStatusOperacionalInput>>;
    updateMany?: InputMaybe<Array<StatusOperacionalAuditUpdateManyWithWhereWithoutStatusOperacionalInput>>;
    upsert?: InputMaybe<Array<StatusOperacionalAuditUpsertWithWhereUniqueWithoutStatusOperacionalInput>>;
};

export type StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<StatusOperacionalAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<StatusOperacionalAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<StatusOperacionalAuditCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<StatusOperacionalAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<StatusOperacionalAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<StatusOperacionalAuditUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<StatusOperacionalAuditUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<StatusOperacionalAuditUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type StatusOperacionalAuditUpdateWithWhereUniqueWithoutCedenteInput = {
    data: StatusOperacionalAuditUpdateWithoutCedenteInput;
    where: StatusOperacionalAuditWhereUniqueInput;
};

export type StatusOperacionalAuditUpdateWithWhereUniqueWithoutStatusOperacionalInput = {
    data: StatusOperacionalAuditUpdateWithoutStatusOperacionalInput;
    where: StatusOperacionalAuditWhereUniqueInput;
};

export type StatusOperacionalAuditUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: StatusOperacionalAuditUpdateWithoutUsuarioInput;
    where: StatusOperacionalAuditWhereUniqueInput;
};

export type StatusOperacionalAuditUpdateWithoutCedenteInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateOneRequiredWithoutStatusOperacionalAuditNestedInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutHistStatusOperacionalNestedInput>;
};

export type StatusOperacionalAuditUpdateWithoutStatusOperacionalInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutHistStatusNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneRequiredWithoutHistStatusOperacionalNestedInput>;
};

export type StatusOperacionalAuditUpdateWithoutUsuarioInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutHistStatusNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
    observacao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateOneRequiredWithoutStatusOperacionalAuditNestedInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StatusOperacionalAuditUpsertWithWhereUniqueWithoutCedenteInput = {
    create: StatusOperacionalAuditCreateWithoutCedenteInput;
    update: StatusOperacionalAuditUpdateWithoutCedenteInput;
    where: StatusOperacionalAuditWhereUniqueInput;
};

export type StatusOperacionalAuditUpsertWithWhereUniqueWithoutStatusOperacionalInput = {
    create: StatusOperacionalAuditCreateWithoutStatusOperacionalInput;
    update: StatusOperacionalAuditUpdateWithoutStatusOperacionalInput;
    where: StatusOperacionalAuditWhereUniqueInput;
};

export type StatusOperacionalAuditUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: StatusOperacionalAuditCreateWithoutUsuarioInput;
    update: StatusOperacionalAuditUpdateWithoutUsuarioInput;
    where: StatusOperacionalAuditWhereUniqueInput;
};

export type StatusOperacionalAuditWhereInput = {
    AND?: InputMaybe<Array<StatusOperacionalAuditWhereInput>>;
    NOT?: InputMaybe<Array<StatusOperacionalAuditWhereInput>>;
    OR?: InputMaybe<Array<StatusOperacionalAuditWhereInput>>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<EnumStatusOperacionalFilter>;
    observacao?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<EnumStatusOperacionalFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    statusId?: InputMaybe<IntFilter>;
    statusOperacional?: InputMaybe<StatusOperacionalCedenteMinibancoRelationFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntFilter>;
};

export type StatusOperacionalAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type StatusOperacionalCedenteMinibanco = {
    __typename?: "StatusOperacionalCedenteMinibanco";
    StatusOperacionalAudit: Array<StatusOperacionalAudit>;
    _count?: Maybe<StatusOperacionalCedenteMinibancoCount>;
    cedente: Cedente;
    cedenteId: Scalars["Int"];
    id: Scalars["Int"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    status: StatusOperacional;
};

export type StatusOperacionalCedenteMinibancoStatusOperacionalAuditArgs = {
    cursor?: InputMaybe<StatusOperacionalAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<StatusOperacionalAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<StatusOperacionalAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<StatusOperacionalAuditWhereInput>;
};

export type StatusOperacionalCedenteMinibancoAvgAggregate = {
    __typename?: "StatusOperacionalCedenteMinibancoAvgAggregate";
    cedenteId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
};

export type StatusOperacionalCedenteMinibancoAvgOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
};

export type StatusOperacionalCedenteMinibancoCedenteIdMinibancoIdCompoundUniqueInput = {
    cedenteId: Scalars["Int"];
    minibancoId: Scalars["Int"];
};

export type StatusOperacionalCedenteMinibancoCount = {
    __typename?: "StatusOperacionalCedenteMinibancoCount";
    StatusOperacionalAudit: Scalars["Int"];
};

export type StatusOperacionalCedenteMinibancoCountAggregate = {
    __typename?: "StatusOperacionalCedenteMinibancoCountAggregate";
    _all: Scalars["Int"];
    cedenteId: Scalars["Int"];
    id: Scalars["Int"];
    minibancoId: Scalars["Int"];
    status: Scalars["Int"];
};

export type StatusOperacionalCedenteMinibancoCountOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
};

export type StatusOperacionalCedenteMinibancoCreateInput = {
    StatusOperacionalAudit?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutStatusOperacionalInput>;
    cedente: CedenteCreateNestedOneWithoutStatusOperacionalInput;
    minibanco: MinibancoCreateNestedOneWithoutStatusOperacionalCedenteInput;
    status?: InputMaybe<StatusOperacional>;
};

export type StatusOperacionalCedenteMinibancoCreateManyCedenteInput = {
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    status?: InputMaybe<StatusOperacional>;
};

export type StatusOperacionalCedenteMinibancoCreateManyCedenteInputEnvelope = {
    data: Array<StatusOperacionalCedenteMinibancoCreateManyCedenteInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type StatusOperacionalCedenteMinibancoCreateManyInput = {
    cedenteId: Scalars["Int"];
    id?: InputMaybe<Scalars["Int"]>;
    minibancoId: Scalars["Int"];
    status?: InputMaybe<StatusOperacional>;
};

export type StatusOperacionalCedenteMinibancoCreateManyMinibancoInput = {
    cedenteId: Scalars["Int"];
    id?: InputMaybe<Scalars["Int"]>;
    status?: InputMaybe<StatusOperacional>;
};

export type StatusOperacionalCedenteMinibancoCreateManyMinibancoInputEnvelope = {
    data: Array<StatusOperacionalCedenteMinibancoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type StatusOperacionalCedenteMinibancoCreateNestedManyWithoutCedenteInput = {
    connect?: InputMaybe<Array<StatusOperacionalCedenteMinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<StatusOperacionalCedenteMinibancoCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<StatusOperacionalCedenteMinibancoCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<StatusOperacionalCedenteMinibancoCreateManyCedenteInputEnvelope>;
};

export type StatusOperacionalCedenteMinibancoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<StatusOperacionalCedenteMinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<StatusOperacionalCedenteMinibancoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<StatusOperacionalCedenteMinibancoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<StatusOperacionalCedenteMinibancoCreateManyMinibancoInputEnvelope>;
};

export type StatusOperacionalCedenteMinibancoCreateNestedOneWithoutStatusOperacionalAuditInput = {
    connect?: InputMaybe<StatusOperacionalCedenteMinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<StatusOperacionalCedenteMinibancoCreateOrConnectWithoutStatusOperacionalAuditInput>;
    create?: InputMaybe<StatusOperacionalCedenteMinibancoCreateWithoutStatusOperacionalAuditInput>;
};

export type StatusOperacionalCedenteMinibancoCreateOrConnectWithoutCedenteInput = {
    create: StatusOperacionalCedenteMinibancoCreateWithoutCedenteInput;
    where: StatusOperacionalCedenteMinibancoWhereUniqueInput;
};

export type StatusOperacionalCedenteMinibancoCreateOrConnectWithoutMinibancoInput = {
    create: StatusOperacionalCedenteMinibancoCreateWithoutMinibancoInput;
    where: StatusOperacionalCedenteMinibancoWhereUniqueInput;
};

export type StatusOperacionalCedenteMinibancoCreateOrConnectWithoutStatusOperacionalAuditInput = {
    create: StatusOperacionalCedenteMinibancoCreateWithoutStatusOperacionalAuditInput;
    where: StatusOperacionalCedenteMinibancoWhereUniqueInput;
};

export type StatusOperacionalCedenteMinibancoCreateWithoutCedenteInput = {
    StatusOperacionalAudit?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutStatusOperacionalInput>;
    minibanco: MinibancoCreateNestedOneWithoutStatusOperacionalCedenteInput;
    status?: InputMaybe<StatusOperacional>;
};

export type StatusOperacionalCedenteMinibancoCreateWithoutMinibancoInput = {
    StatusOperacionalAudit?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutStatusOperacionalInput>;
    cedente: CedenteCreateNestedOneWithoutStatusOperacionalInput;
    status?: InputMaybe<StatusOperacional>;
};

export type StatusOperacionalCedenteMinibancoCreateWithoutStatusOperacionalAuditInput = {
    cedente: CedenteCreateNestedOneWithoutStatusOperacionalInput;
    minibanco: MinibancoCreateNestedOneWithoutStatusOperacionalCedenteInput;
    status?: InputMaybe<StatusOperacional>;
};

export type StatusOperacionalCedenteMinibancoGroupBy = {
    __typename?: "StatusOperacionalCedenteMinibancoGroupBy";
    _avg?: Maybe<StatusOperacionalCedenteMinibancoAvgAggregate>;
    _count?: Maybe<StatusOperacionalCedenteMinibancoCountAggregate>;
    _max?: Maybe<StatusOperacionalCedenteMinibancoMaxAggregate>;
    _min?: Maybe<StatusOperacionalCedenteMinibancoMinAggregate>;
    _sum?: Maybe<StatusOperacionalCedenteMinibancoSumAggregate>;
    cedenteId: Scalars["Int"];
    id: Scalars["Int"];
    minibancoId: Scalars["Int"];
    status: StatusOperacional;
};

export type StatusOperacionalCedenteMinibancoListRelationFilter = {
    every?: InputMaybe<StatusOperacionalCedenteMinibancoWhereInput>;
    none?: InputMaybe<StatusOperacionalCedenteMinibancoWhereInput>;
    some?: InputMaybe<StatusOperacionalCedenteMinibancoWhereInput>;
};

export type StatusOperacionalCedenteMinibancoMaxAggregate = {
    __typename?: "StatusOperacionalCedenteMinibancoMaxAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    status?: Maybe<StatusOperacional>;
};

export type StatusOperacionalCedenteMinibancoMaxOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
};

export type StatusOperacionalCedenteMinibancoMinAggregate = {
    __typename?: "StatusOperacionalCedenteMinibancoMinAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    status?: Maybe<StatusOperacional>;
};

export type StatusOperacionalCedenteMinibancoMinOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
};

export type StatusOperacionalCedenteMinibancoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type StatusOperacionalCedenteMinibancoOrderByWithAggregationInput = {
    _avg?: InputMaybe<StatusOperacionalCedenteMinibancoAvgOrderByAggregateInput>;
    _count?: InputMaybe<StatusOperacionalCedenteMinibancoCountOrderByAggregateInput>;
    _max?: InputMaybe<StatusOperacionalCedenteMinibancoMaxOrderByAggregateInput>;
    _min?: InputMaybe<StatusOperacionalCedenteMinibancoMinOrderByAggregateInput>;
    _sum?: InputMaybe<StatusOperacionalCedenteMinibancoSumOrderByAggregateInput>;
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
};

export type StatusOperacionalCedenteMinibancoOrderByWithRelationInput = {
    StatusOperacionalAudit?: InputMaybe<StatusOperacionalAuditOrderByRelationAggregateInput>;
    cedente?: InputMaybe<CedenteOrderByWithRelationInput>;
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
};

export type StatusOperacionalCedenteMinibancoRelationFilter = {
    is?: InputMaybe<StatusOperacionalCedenteMinibancoWhereInput>;
    isNot?: InputMaybe<StatusOperacionalCedenteMinibancoWhereInput>;
};

export enum StatusOperacionalCedenteMinibancoScalarFieldEnum {
    CedenteId = "cedenteId",
    Id = "id",
    MinibancoId = "minibancoId",
    Status = "status",
}

export type StatusOperacionalCedenteMinibancoScalarWhereInput = {
    AND?: InputMaybe<Array<StatusOperacionalCedenteMinibancoScalarWhereInput>>;
    NOT?: InputMaybe<Array<StatusOperacionalCedenteMinibancoScalarWhereInput>>;
    OR?: InputMaybe<Array<StatusOperacionalCedenteMinibancoScalarWhereInput>>;
    cedenteId?: InputMaybe<IntFilter>;
    id?: InputMaybe<IntFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumStatusOperacionalFilter>;
};

export type StatusOperacionalCedenteMinibancoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<StatusOperacionalCedenteMinibancoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<StatusOperacionalCedenteMinibancoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<StatusOperacionalCedenteMinibancoScalarWhereWithAggregatesInput>>;
    cedenteId?: InputMaybe<IntWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    status?: InputMaybe<EnumStatusOperacionalWithAggregatesFilter>;
};

export type StatusOperacionalCedenteMinibancoSumAggregate = {
    __typename?: "StatusOperacionalCedenteMinibancoSumAggregate";
    cedenteId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
};

export type StatusOperacionalCedenteMinibancoSumOrderByAggregateInput = {
    cedenteId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
};

export type StatusOperacionalCedenteMinibancoUpdateInput = {
    StatusOperacionalAudit?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutStatusOperacionalNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutStatusOperacionalNestedInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutStatusOperacionalCedenteNestedInput>;
    status?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
};

export type StatusOperacionalCedenteMinibancoUpdateManyMutationInput = {
    status?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
};

export type StatusOperacionalCedenteMinibancoUpdateManyWithWhereWithoutCedenteInput = {
    data: StatusOperacionalCedenteMinibancoUpdateManyMutationInput;
    where: StatusOperacionalCedenteMinibancoScalarWhereInput;
};

export type StatusOperacionalCedenteMinibancoUpdateManyWithWhereWithoutMinibancoInput = {
    data: StatusOperacionalCedenteMinibancoUpdateManyMutationInput;
    where: StatusOperacionalCedenteMinibancoScalarWhereInput;
};

export type StatusOperacionalCedenteMinibancoUpdateManyWithoutCedenteNestedInput = {
    connect?: InputMaybe<Array<StatusOperacionalCedenteMinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<StatusOperacionalCedenteMinibancoCreateOrConnectWithoutCedenteInput>>;
    create?: InputMaybe<Array<StatusOperacionalCedenteMinibancoCreateWithoutCedenteInput>>;
    createMany?: InputMaybe<StatusOperacionalCedenteMinibancoCreateManyCedenteInputEnvelope>;
    delete?: InputMaybe<Array<StatusOperacionalCedenteMinibancoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<StatusOperacionalCedenteMinibancoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<StatusOperacionalCedenteMinibancoWhereUniqueInput>>;
    set?: InputMaybe<Array<StatusOperacionalCedenteMinibancoWhereUniqueInput>>;
    update?: InputMaybe<Array<StatusOperacionalCedenteMinibancoUpdateWithWhereUniqueWithoutCedenteInput>>;
    updateMany?: InputMaybe<Array<StatusOperacionalCedenteMinibancoUpdateManyWithWhereWithoutCedenteInput>>;
    upsert?: InputMaybe<Array<StatusOperacionalCedenteMinibancoUpsertWithWhereUniqueWithoutCedenteInput>>;
};

export type StatusOperacionalCedenteMinibancoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<StatusOperacionalCedenteMinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<StatusOperacionalCedenteMinibancoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<StatusOperacionalCedenteMinibancoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<StatusOperacionalCedenteMinibancoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<StatusOperacionalCedenteMinibancoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<StatusOperacionalCedenteMinibancoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<StatusOperacionalCedenteMinibancoWhereUniqueInput>>;
    set?: InputMaybe<Array<StatusOperacionalCedenteMinibancoWhereUniqueInput>>;
    update?: InputMaybe<Array<StatusOperacionalCedenteMinibancoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<StatusOperacionalCedenteMinibancoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<StatusOperacionalCedenteMinibancoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type StatusOperacionalCedenteMinibancoUpdateOneRequiredWithoutStatusOperacionalAuditNestedInput = {
    connect?: InputMaybe<StatusOperacionalCedenteMinibancoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<StatusOperacionalCedenteMinibancoCreateOrConnectWithoutStatusOperacionalAuditInput>;
    create?: InputMaybe<StatusOperacionalCedenteMinibancoCreateWithoutStatusOperacionalAuditInput>;
    update?: InputMaybe<StatusOperacionalCedenteMinibancoUpdateWithoutStatusOperacionalAuditInput>;
    upsert?: InputMaybe<StatusOperacionalCedenteMinibancoUpsertWithoutStatusOperacionalAuditInput>;
};

export type StatusOperacionalCedenteMinibancoUpdateWithWhereUniqueWithoutCedenteInput = {
    data: StatusOperacionalCedenteMinibancoUpdateWithoutCedenteInput;
    where: StatusOperacionalCedenteMinibancoWhereUniqueInput;
};

export type StatusOperacionalCedenteMinibancoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: StatusOperacionalCedenteMinibancoUpdateWithoutMinibancoInput;
    where: StatusOperacionalCedenteMinibancoWhereUniqueInput;
};

export type StatusOperacionalCedenteMinibancoUpdateWithoutCedenteInput = {
    StatusOperacionalAudit?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutStatusOperacionalNestedInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutStatusOperacionalCedenteNestedInput>;
    status?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
};

export type StatusOperacionalCedenteMinibancoUpdateWithoutMinibancoInput = {
    StatusOperacionalAudit?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutStatusOperacionalNestedInput>;
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutStatusOperacionalNestedInput>;
    status?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
};

export type StatusOperacionalCedenteMinibancoUpdateWithoutStatusOperacionalAuditInput = {
    cedente?: InputMaybe<CedenteUpdateOneRequiredWithoutStatusOperacionalNestedInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutStatusOperacionalCedenteNestedInput>;
    status?: InputMaybe<EnumStatusOperacionalFieldUpdateOperationsInput>;
};

export type StatusOperacionalCedenteMinibancoUpsertWithWhereUniqueWithoutCedenteInput = {
    create: StatusOperacionalCedenteMinibancoCreateWithoutCedenteInput;
    update: StatusOperacionalCedenteMinibancoUpdateWithoutCedenteInput;
    where: StatusOperacionalCedenteMinibancoWhereUniqueInput;
};

export type StatusOperacionalCedenteMinibancoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: StatusOperacionalCedenteMinibancoCreateWithoutMinibancoInput;
    update: StatusOperacionalCedenteMinibancoUpdateWithoutMinibancoInput;
    where: StatusOperacionalCedenteMinibancoWhereUniqueInput;
};

export type StatusOperacionalCedenteMinibancoUpsertWithoutStatusOperacionalAuditInput = {
    create: StatusOperacionalCedenteMinibancoCreateWithoutStatusOperacionalAuditInput;
    update: StatusOperacionalCedenteMinibancoUpdateWithoutStatusOperacionalAuditInput;
};

export type StatusOperacionalCedenteMinibancoWhereInput = {
    AND?: InputMaybe<Array<StatusOperacionalCedenteMinibancoWhereInput>>;
    NOT?: InputMaybe<Array<StatusOperacionalCedenteMinibancoWhereInput>>;
    OR?: InputMaybe<Array<StatusOperacionalCedenteMinibancoWhereInput>>;
    StatusOperacionalAudit?: InputMaybe<StatusOperacionalAuditListRelationFilter>;
    cedente?: InputMaybe<CedenteRelationFilter>;
    cedenteId?: InputMaybe<IntFilter>;
    id?: InputMaybe<IntFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    status?: InputMaybe<EnumStatusOperacionalFilter>;
};

export type StatusOperacionalCedenteMinibancoWhereUniqueInput = {
    cedenteId_minibancoId?: InputMaybe<StatusOperacionalCedenteMinibancoCedenteIdMinibancoIdCompoundUniqueInput>;
    id?: InputMaybe<Scalars["Int"]>;
};

export enum StatusOperationalAssignorByMinibank {
    AguardandoAprovacao = "AGUARDANDO_APROVACAO",
    AnaliseDeCredito = "ANALISE_DE_CREDITO",
    AnaliseSolicitada = "ANALISE_SOLICITADA",
    Aprovado = "APROVADO",
    Reprovado = "REPROVADO",
}

export enum StatusRecebivel {
    Aberto = "ABERTO",
    Baixado = "BAIXADO",
    Vencido = "VENCIDO",
}

export type StringFieldUpdateOperationsInput = {
    set?: InputMaybe<Scalars["String"]>;
};

export type StringFilter = {
    contains?: InputMaybe<Scalars["String"]>;
    endsWith?: InputMaybe<Scalars["String"]>;
    equals?: InputMaybe<Scalars["String"]>;
    gt?: InputMaybe<Scalars["String"]>;
    gte?: InputMaybe<Scalars["String"]>;
    in?: InputMaybe<Array<Scalars["String"]>>;
    lt?: InputMaybe<Scalars["String"]>;
    lte?: InputMaybe<Scalars["String"]>;
    mode?: InputMaybe<QueryMode>;
    not?: InputMaybe<NestedStringFilter>;
    notIn?: InputMaybe<Array<Scalars["String"]>>;
    startsWith?: InputMaybe<Scalars["String"]>;
};

export type StringNullableFilter = {
    contains?: InputMaybe<Scalars["String"]>;
    endsWith?: InputMaybe<Scalars["String"]>;
    equals?: InputMaybe<Scalars["String"]>;
    gt?: InputMaybe<Scalars["String"]>;
    gte?: InputMaybe<Scalars["String"]>;
    in?: InputMaybe<Array<Scalars["String"]>>;
    lt?: InputMaybe<Scalars["String"]>;
    lte?: InputMaybe<Scalars["String"]>;
    mode?: InputMaybe<QueryMode>;
    not?: InputMaybe<NestedStringNullableFilter>;
    notIn?: InputMaybe<Array<Scalars["String"]>>;
    startsWith?: InputMaybe<Scalars["String"]>;
};

export type StringNullableWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntNullableFilter>;
    _max?: InputMaybe<NestedStringNullableFilter>;
    _min?: InputMaybe<NestedStringNullableFilter>;
    contains?: InputMaybe<Scalars["String"]>;
    endsWith?: InputMaybe<Scalars["String"]>;
    equals?: InputMaybe<Scalars["String"]>;
    gt?: InputMaybe<Scalars["String"]>;
    gte?: InputMaybe<Scalars["String"]>;
    in?: InputMaybe<Array<Scalars["String"]>>;
    lt?: InputMaybe<Scalars["String"]>;
    lte?: InputMaybe<Scalars["String"]>;
    mode?: InputMaybe<QueryMode>;
    not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["String"]>>;
    startsWith?: InputMaybe<Scalars["String"]>;
};

export type StringWithAggregatesFilter = {
    _count?: InputMaybe<NestedIntFilter>;
    _max?: InputMaybe<NestedStringFilter>;
    _min?: InputMaybe<NestedStringFilter>;
    contains?: InputMaybe<Scalars["String"]>;
    endsWith?: InputMaybe<Scalars["String"]>;
    equals?: InputMaybe<Scalars["String"]>;
    gt?: InputMaybe<Scalars["String"]>;
    gte?: InputMaybe<Scalars["String"]>;
    in?: InputMaybe<Array<Scalars["String"]>>;
    lt?: InputMaybe<Scalars["String"]>;
    lte?: InputMaybe<Scalars["String"]>;
    mode?: InputMaybe<QueryMode>;
    not?: InputMaybe<NestedStringWithAggregatesFilter>;
    notIn?: InputMaybe<Array<Scalars["String"]>>;
    startsWith?: InputMaybe<Scalars["String"]>;
};

export type TakeRateMinibanco = {
    __typename?: "TakeRateMinibanco";
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    porcentagem?: Maybe<Scalars["Decimal"]>;
    updatedAt: Scalars["DateTime"];
    valorFinal?: Maybe<Scalars["Decimal"]>;
    valorInicial?: Maybe<Scalars["Decimal"]>;
};

export type TakeRateMinibancoAvgAggregate = {
    __typename?: "TakeRateMinibancoAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    porcentagem?: Maybe<Scalars["Decimal"]>;
    valorFinal?: Maybe<Scalars["Decimal"]>;
    valorInicial?: Maybe<Scalars["Decimal"]>;
};

export type TakeRateMinibancoAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    porcentagem?: InputMaybe<SortOrder>;
    valorFinal?: InputMaybe<SortOrder>;
    valorInicial?: InputMaybe<SortOrder>;
};

export type TakeRateMinibancoCountAggregate = {
    __typename?: "TakeRateMinibancoCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    minibancoId: Scalars["Int"];
    porcentagem: Scalars["Int"];
    updatedAt: Scalars["Int"];
    valorFinal: Scalars["Int"];
    valorInicial: Scalars["Int"];
};

export type TakeRateMinibancoCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    porcentagem?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valorFinal?: InputMaybe<SortOrder>;
    valorInicial?: InputMaybe<SortOrder>;
};

export type TakeRateMinibancoCreateInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibanco: MinibancoCreateNestedOneWithoutTakeRateMinibancoInput;
    porcentagem?: InputMaybe<Scalars["Decimal"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valorFinal?: InputMaybe<Scalars["Decimal"]>;
    valorInicial?: InputMaybe<Scalars["Decimal"]>;
};

export type TakeRateMinibancoCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    minibancoId: Scalars["Int"];
    porcentagem?: InputMaybe<Scalars["Decimal"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valorFinal?: InputMaybe<Scalars["Decimal"]>;
    valorInicial?: InputMaybe<Scalars["Decimal"]>;
};

export type TakeRateMinibancoCreateManyMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    porcentagem?: InputMaybe<Scalars["Decimal"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valorFinal?: InputMaybe<Scalars["Decimal"]>;
    valorInicial?: InputMaybe<Scalars["Decimal"]>;
};

export type TakeRateMinibancoCreateManyMinibancoInputEnvelope = {
    data: Array<TakeRateMinibancoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TakeRateMinibancoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<TakeRateMinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<TakeRateMinibancoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<TakeRateMinibancoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<TakeRateMinibancoCreateManyMinibancoInputEnvelope>;
};

export type TakeRateMinibancoCreateOrConnectWithoutMinibancoInput = {
    create: TakeRateMinibancoCreateWithoutMinibancoInput;
    where: TakeRateMinibancoWhereUniqueInput;
};

export type TakeRateMinibancoCreateWithoutMinibancoInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    porcentagem?: InputMaybe<Scalars["Decimal"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valorFinal?: InputMaybe<Scalars["Decimal"]>;
    valorInicial?: InputMaybe<Scalars["Decimal"]>;
};

export type TakeRateMinibancoGroupBy = {
    __typename?: "TakeRateMinibancoGroupBy";
    _avg?: Maybe<TakeRateMinibancoAvgAggregate>;
    _count?: Maybe<TakeRateMinibancoCountAggregate>;
    _max?: Maybe<TakeRateMinibancoMaxAggregate>;
    _min?: Maybe<TakeRateMinibancoMinAggregate>;
    _sum?: Maybe<TakeRateMinibancoSumAggregate>;
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    minibancoId: Scalars["Int"];
    porcentagem?: Maybe<Scalars["Decimal"]>;
    updatedAt: Scalars["DateTime"];
    valorFinal?: Maybe<Scalars["Decimal"]>;
    valorInicial?: Maybe<Scalars["Decimal"]>;
};

export type TakeRateMinibancoListRelationFilter = {
    every?: InputMaybe<TakeRateMinibancoWhereInput>;
    none?: InputMaybe<TakeRateMinibancoWhereInput>;
    some?: InputMaybe<TakeRateMinibancoWhereInput>;
};

export type TakeRateMinibancoMaxAggregate = {
    __typename?: "TakeRateMinibancoMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    porcentagem?: Maybe<Scalars["Decimal"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    valorFinal?: Maybe<Scalars["Decimal"]>;
    valorInicial?: Maybe<Scalars["Decimal"]>;
};

export type TakeRateMinibancoMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    porcentagem?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valorFinal?: InputMaybe<SortOrder>;
    valorInicial?: InputMaybe<SortOrder>;
};

export type TakeRateMinibancoMinAggregate = {
    __typename?: "TakeRateMinibancoMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    porcentagem?: Maybe<Scalars["Decimal"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    valorFinal?: Maybe<Scalars["Decimal"]>;
    valorInicial?: Maybe<Scalars["Decimal"]>;
};

export type TakeRateMinibancoMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    porcentagem?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valorFinal?: InputMaybe<SortOrder>;
    valorInicial?: InputMaybe<SortOrder>;
};

export type TakeRateMinibancoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type TakeRateMinibancoOrderByWithAggregationInput = {
    _avg?: InputMaybe<TakeRateMinibancoAvgOrderByAggregateInput>;
    _count?: InputMaybe<TakeRateMinibancoCountOrderByAggregateInput>;
    _max?: InputMaybe<TakeRateMinibancoMaxOrderByAggregateInput>;
    _min?: InputMaybe<TakeRateMinibancoMinOrderByAggregateInput>;
    _sum?: InputMaybe<TakeRateMinibancoSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    porcentagem?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valorFinal?: InputMaybe<SortOrder>;
    valorInicial?: InputMaybe<SortOrder>;
};

export type TakeRateMinibancoOrderByWithRelationInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    porcentagem?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valorFinal?: InputMaybe<SortOrder>;
    valorInicial?: InputMaybe<SortOrder>;
};

export enum TakeRateMinibancoScalarFieldEnum {
    CreatedAt = "createdAt",
    Id = "id",
    Inativo = "inativo",
    MinibancoId = "minibancoId",
    Porcentagem = "porcentagem",
    UpdatedAt = "updatedAt",
    ValorFinal = "valorFinal",
    ValorInicial = "valorInicial",
}

export type TakeRateMinibancoScalarWhereInput = {
    AND?: InputMaybe<Array<TakeRateMinibancoScalarWhereInput>>;
    NOT?: InputMaybe<Array<TakeRateMinibancoScalarWhereInput>>;
    OR?: InputMaybe<Array<TakeRateMinibancoScalarWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    porcentagem?: InputMaybe<DecimalNullableFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    valorFinal?: InputMaybe<DecimalNullableFilter>;
    valorInicial?: InputMaybe<DecimalNullableFilter>;
};

export type TakeRateMinibancoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<TakeRateMinibancoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<TakeRateMinibancoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<TakeRateMinibancoScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    porcentagem?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    valorFinal?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    valorInicial?: InputMaybe<DecimalNullableWithAggregatesFilter>;
};

export type TakeRateMinibancoSumAggregate = {
    __typename?: "TakeRateMinibancoSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    porcentagem?: Maybe<Scalars["Decimal"]>;
    valorFinal?: Maybe<Scalars["Decimal"]>;
    valorInicial?: Maybe<Scalars["Decimal"]>;
};

export type TakeRateMinibancoSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    porcentagem?: InputMaybe<SortOrder>;
    valorFinal?: InputMaybe<SortOrder>;
    valorInicial?: InputMaybe<SortOrder>;
};

export type TakeRateMinibancoUpdateInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutTakeRateMinibancoNestedInput>;
    porcentagem?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    valorFinal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    valorInicial?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type TakeRateMinibancoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    porcentagem?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    valorFinal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    valorInicial?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type TakeRateMinibancoUpdateManyWithWhereWithoutMinibancoInput = {
    data: TakeRateMinibancoUpdateManyMutationInput;
    where: TakeRateMinibancoScalarWhereInput;
};

export type TakeRateMinibancoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<TakeRateMinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<TakeRateMinibancoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<TakeRateMinibancoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<TakeRateMinibancoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<TakeRateMinibancoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<TakeRateMinibancoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<TakeRateMinibancoWhereUniqueInput>>;
    set?: InputMaybe<Array<TakeRateMinibancoWhereUniqueInput>>;
    update?: InputMaybe<Array<TakeRateMinibancoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<TakeRateMinibancoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<TakeRateMinibancoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type TakeRateMinibancoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: TakeRateMinibancoUpdateWithoutMinibancoInput;
    where: TakeRateMinibancoWhereUniqueInput;
};

export type TakeRateMinibancoUpdateWithoutMinibancoInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    porcentagem?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    valorFinal?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    valorInicial?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type TakeRateMinibancoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: TakeRateMinibancoCreateWithoutMinibancoInput;
    update: TakeRateMinibancoUpdateWithoutMinibancoInput;
    where: TakeRateMinibancoWhereUniqueInput;
};

export type TakeRateMinibancoWhereInput = {
    AND?: InputMaybe<Array<TakeRateMinibancoWhereInput>>;
    NOT?: InputMaybe<Array<TakeRateMinibancoWhereInput>>;
    OR?: InputMaybe<Array<TakeRateMinibancoWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    porcentagem?: InputMaybe<DecimalNullableFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    valorFinal?: InputMaybe<DecimalNullableFilter>;
    valorInicial?: InputMaybe<DecimalNullableFilter>;
};

export type TakeRateMinibancoWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type TaxasMinibanco = {
    __typename?: "TaxasMinibanco";
    analiseCredito?: Maybe<Scalars["Decimal"]>;
    boleto?: Maybe<Scalars["Decimal"]>;
    minibanco: Minibanco;
    minibancoId: Scalars["Int"];
    tacTrintaMais?: Maybe<Scalars["Decimal"]>;
    tacTrintaMenos?: Maybe<Scalars["Decimal"]>;
    taxaMinima?: Maybe<Scalars["Decimal"]>;
    ted?: Maybe<Scalars["Decimal"]>;
};

export type TaxasMinibancoAvgAggregate = {
    __typename?: "TaxasMinibancoAvgAggregate";
    analiseCredito?: Maybe<Scalars["Decimal"]>;
    boleto?: Maybe<Scalars["Decimal"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
    tacTrintaMais?: Maybe<Scalars["Decimal"]>;
    tacTrintaMenos?: Maybe<Scalars["Decimal"]>;
    taxaMinima?: Maybe<Scalars["Decimal"]>;
    ted?: Maybe<Scalars["Decimal"]>;
};

export type TaxasMinibancoAvgOrderByAggregateInput = {
    analiseCredito?: InputMaybe<SortOrder>;
    boleto?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    tacTrintaMais?: InputMaybe<SortOrder>;
    tacTrintaMenos?: InputMaybe<SortOrder>;
    taxaMinima?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export type TaxasMinibancoCountAggregate = {
    __typename?: "TaxasMinibancoCountAggregate";
    _all: Scalars["Int"];
    analiseCredito: Scalars["Int"];
    boleto: Scalars["Int"];
    minibancoId: Scalars["Int"];
    tacTrintaMais: Scalars["Int"];
    tacTrintaMenos: Scalars["Int"];
    taxaMinima: Scalars["Int"];
    ted: Scalars["Int"];
};

export type TaxasMinibancoCountOrderByAggregateInput = {
    analiseCredito?: InputMaybe<SortOrder>;
    boleto?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    tacTrintaMais?: InputMaybe<SortOrder>;
    tacTrintaMenos?: InputMaybe<SortOrder>;
    taxaMinima?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export type TaxasMinibancoCreateInput = {
    analiseCredito?: InputMaybe<Scalars["Decimal"]>;
    boleto?: InputMaybe<Scalars["Decimal"]>;
    minibanco: MinibancoCreateNestedOneWithoutTaxasMinibancoInput;
    tacTrintaMais?: InputMaybe<Scalars["Decimal"]>;
    tacTrintaMenos?: InputMaybe<Scalars["Decimal"]>;
    taxaMinima?: InputMaybe<Scalars["Decimal"]>;
    ted?: InputMaybe<Scalars["Decimal"]>;
};

export type TaxasMinibancoCreateManyInput = {
    analiseCredito?: InputMaybe<Scalars["Decimal"]>;
    boleto?: InputMaybe<Scalars["Decimal"]>;
    minibancoId: Scalars["Int"];
    tacTrintaMais?: InputMaybe<Scalars["Decimal"]>;
    tacTrintaMenos?: InputMaybe<Scalars["Decimal"]>;
    taxaMinima?: InputMaybe<Scalars["Decimal"]>;
    ted?: InputMaybe<Scalars["Decimal"]>;
};

export type TaxasMinibancoCreateManyMinibancoInput = {
    analiseCredito?: InputMaybe<Scalars["Decimal"]>;
    boleto?: InputMaybe<Scalars["Decimal"]>;
    tacTrintaMais?: InputMaybe<Scalars["Decimal"]>;
    tacTrintaMenos?: InputMaybe<Scalars["Decimal"]>;
    taxaMinima?: InputMaybe<Scalars["Decimal"]>;
    ted?: InputMaybe<Scalars["Decimal"]>;
};

export type TaxasMinibancoCreateManyMinibancoInputEnvelope = {
    data: Array<TaxasMinibancoCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type TaxasMinibancoCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<TaxasMinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<TaxasMinibancoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<TaxasMinibancoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<TaxasMinibancoCreateManyMinibancoInputEnvelope>;
};

export type TaxasMinibancoCreateOrConnectWithoutMinibancoInput = {
    create: TaxasMinibancoCreateWithoutMinibancoInput;
    where: TaxasMinibancoWhereUniqueInput;
};

export type TaxasMinibancoCreateWithoutMinibancoInput = {
    analiseCredito?: InputMaybe<Scalars["Decimal"]>;
    boleto?: InputMaybe<Scalars["Decimal"]>;
    tacTrintaMais?: InputMaybe<Scalars["Decimal"]>;
    tacTrintaMenos?: InputMaybe<Scalars["Decimal"]>;
    taxaMinima?: InputMaybe<Scalars["Decimal"]>;
    ted?: InputMaybe<Scalars["Decimal"]>;
};

export type TaxasMinibancoGroupBy = {
    __typename?: "TaxasMinibancoGroupBy";
    _avg?: Maybe<TaxasMinibancoAvgAggregate>;
    _count?: Maybe<TaxasMinibancoCountAggregate>;
    _max?: Maybe<TaxasMinibancoMaxAggregate>;
    _min?: Maybe<TaxasMinibancoMinAggregate>;
    _sum?: Maybe<TaxasMinibancoSumAggregate>;
    analiseCredito?: Maybe<Scalars["Decimal"]>;
    boleto?: Maybe<Scalars["Decimal"]>;
    minibancoId: Scalars["Int"];
    tacTrintaMais?: Maybe<Scalars["Decimal"]>;
    tacTrintaMenos?: Maybe<Scalars["Decimal"]>;
    taxaMinima?: Maybe<Scalars["Decimal"]>;
    ted?: Maybe<Scalars["Decimal"]>;
};

export type TaxasMinibancoListRelationFilter = {
    every?: InputMaybe<TaxasMinibancoWhereInput>;
    none?: InputMaybe<TaxasMinibancoWhereInput>;
    some?: InputMaybe<TaxasMinibancoWhereInput>;
};

export type TaxasMinibancoMaxAggregate = {
    __typename?: "TaxasMinibancoMaxAggregate";
    analiseCredito?: Maybe<Scalars["Decimal"]>;
    boleto?: Maybe<Scalars["Decimal"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    tacTrintaMais?: Maybe<Scalars["Decimal"]>;
    tacTrintaMenos?: Maybe<Scalars["Decimal"]>;
    taxaMinima?: Maybe<Scalars["Decimal"]>;
    ted?: Maybe<Scalars["Decimal"]>;
};

export type TaxasMinibancoMaxOrderByAggregateInput = {
    analiseCredito?: InputMaybe<SortOrder>;
    boleto?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    tacTrintaMais?: InputMaybe<SortOrder>;
    tacTrintaMenos?: InputMaybe<SortOrder>;
    taxaMinima?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export type TaxasMinibancoMinAggregate = {
    __typename?: "TaxasMinibancoMinAggregate";
    analiseCredito?: Maybe<Scalars["Decimal"]>;
    boleto?: Maybe<Scalars["Decimal"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    tacTrintaMais?: Maybe<Scalars["Decimal"]>;
    tacTrintaMenos?: Maybe<Scalars["Decimal"]>;
    taxaMinima?: Maybe<Scalars["Decimal"]>;
    ted?: Maybe<Scalars["Decimal"]>;
};

export type TaxasMinibancoMinOrderByAggregateInput = {
    analiseCredito?: InputMaybe<SortOrder>;
    boleto?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    tacTrintaMais?: InputMaybe<SortOrder>;
    tacTrintaMenos?: InputMaybe<SortOrder>;
    taxaMinima?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export type TaxasMinibancoOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type TaxasMinibancoOrderByWithAggregationInput = {
    _avg?: InputMaybe<TaxasMinibancoAvgOrderByAggregateInput>;
    _count?: InputMaybe<TaxasMinibancoCountOrderByAggregateInput>;
    _max?: InputMaybe<TaxasMinibancoMaxOrderByAggregateInput>;
    _min?: InputMaybe<TaxasMinibancoMinOrderByAggregateInput>;
    _sum?: InputMaybe<TaxasMinibancoSumOrderByAggregateInput>;
    analiseCredito?: InputMaybe<SortOrder>;
    boleto?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    tacTrintaMais?: InputMaybe<SortOrder>;
    tacTrintaMenos?: InputMaybe<SortOrder>;
    taxaMinima?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export type TaxasMinibancoOrderByWithRelationInput = {
    analiseCredito?: InputMaybe<SortOrder>;
    boleto?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    tacTrintaMais?: InputMaybe<SortOrder>;
    tacTrintaMenos?: InputMaybe<SortOrder>;
    taxaMinima?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export enum TaxasMinibancoScalarFieldEnum {
    AnaliseCredito = "analiseCredito",
    Boleto = "boleto",
    MinibancoId = "minibancoId",
    TacTrintaMais = "tacTrintaMais",
    TacTrintaMenos = "tacTrintaMenos",
    TaxaMinima = "taxaMinima",
    Ted = "ted",
}

export type TaxasMinibancoScalarWhereInput = {
    AND?: InputMaybe<Array<TaxasMinibancoScalarWhereInput>>;
    NOT?: InputMaybe<Array<TaxasMinibancoScalarWhereInput>>;
    OR?: InputMaybe<Array<TaxasMinibancoScalarWhereInput>>;
    analiseCredito?: InputMaybe<DecimalNullableFilter>;
    boleto?: InputMaybe<DecimalNullableFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    tacTrintaMais?: InputMaybe<DecimalNullableFilter>;
    tacTrintaMenos?: InputMaybe<DecimalNullableFilter>;
    taxaMinima?: InputMaybe<DecimalNullableFilter>;
    ted?: InputMaybe<DecimalNullableFilter>;
};

export type TaxasMinibancoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<TaxasMinibancoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<TaxasMinibancoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<TaxasMinibancoScalarWhereWithAggregatesInput>>;
    analiseCredito?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    boleto?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntWithAggregatesFilter>;
    tacTrintaMais?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    tacTrintaMenos?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    taxaMinima?: InputMaybe<DecimalNullableWithAggregatesFilter>;
    ted?: InputMaybe<DecimalNullableWithAggregatesFilter>;
};

export type TaxasMinibancoSumAggregate = {
    __typename?: "TaxasMinibancoSumAggregate";
    analiseCredito?: Maybe<Scalars["Decimal"]>;
    boleto?: Maybe<Scalars["Decimal"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    tacTrintaMais?: Maybe<Scalars["Decimal"]>;
    tacTrintaMenos?: Maybe<Scalars["Decimal"]>;
    taxaMinima?: Maybe<Scalars["Decimal"]>;
    ted?: Maybe<Scalars["Decimal"]>;
};

export type TaxasMinibancoSumOrderByAggregateInput = {
    analiseCredito?: InputMaybe<SortOrder>;
    boleto?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    tacTrintaMais?: InputMaybe<SortOrder>;
    tacTrintaMenos?: InputMaybe<SortOrder>;
    taxaMinima?: InputMaybe<SortOrder>;
    ted?: InputMaybe<SortOrder>;
};

export type TaxasMinibancoUpdateInput = {
    analiseCredito?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    boleto?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneRequiredWithoutTaxasMinibancoNestedInput>;
    tacTrintaMais?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    tacTrintaMenos?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    taxaMinima?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    ted?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type TaxasMinibancoUpdateManyMutationInput = {
    analiseCredito?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    boleto?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    tacTrintaMais?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    tacTrintaMenos?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    taxaMinima?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    ted?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type TaxasMinibancoUpdateManyWithWhereWithoutMinibancoInput = {
    data: TaxasMinibancoUpdateManyMutationInput;
    where: TaxasMinibancoScalarWhereInput;
};

export type TaxasMinibancoUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<TaxasMinibancoWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<TaxasMinibancoCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<TaxasMinibancoCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<TaxasMinibancoCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<TaxasMinibancoWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<TaxasMinibancoScalarWhereInput>>;
    disconnect?: InputMaybe<Array<TaxasMinibancoWhereUniqueInput>>;
    set?: InputMaybe<Array<TaxasMinibancoWhereUniqueInput>>;
    update?: InputMaybe<Array<TaxasMinibancoUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<TaxasMinibancoUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<TaxasMinibancoUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type TaxasMinibancoUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: TaxasMinibancoUpdateWithoutMinibancoInput;
    where: TaxasMinibancoWhereUniqueInput;
};

export type TaxasMinibancoUpdateWithoutMinibancoInput = {
    analiseCredito?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    boleto?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    tacTrintaMais?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    tacTrintaMenos?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    taxaMinima?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
    ted?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type TaxasMinibancoUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: TaxasMinibancoCreateWithoutMinibancoInput;
    update: TaxasMinibancoUpdateWithoutMinibancoInput;
    where: TaxasMinibancoWhereUniqueInput;
};

export type TaxasMinibancoWhereInput = {
    AND?: InputMaybe<Array<TaxasMinibancoWhereInput>>;
    NOT?: InputMaybe<Array<TaxasMinibancoWhereInput>>;
    OR?: InputMaybe<Array<TaxasMinibancoWhereInput>>;
    analiseCredito?: InputMaybe<DecimalNullableFilter>;
    boleto?: InputMaybe<DecimalNullableFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntFilter>;
    tacTrintaMais?: InputMaybe<DecimalNullableFilter>;
    tacTrintaMenos?: InputMaybe<DecimalNullableFilter>;
    taxaMinima?: InputMaybe<DecimalNullableFilter>;
    ted?: InputMaybe<DecimalNullableFilter>;
};

export type TaxasMinibancoWhereUniqueInput = {
    minibancoId?: InputMaybe<Scalars["Int"]>;
};

export type TermsAndConditions = {
    __typename?: "TermsAndConditions";
    _count?: Maybe<TermsAndConditionsCount>;
    acceptedTerms: Array<AcceptedTerms>;
    documentUrl: Scalars["String"];
    version: Scalars["Int"];
};

export type TermsAndConditionsAcceptedTermsArgs = {
    cursor?: InputMaybe<AcceptedTermsWhereUniqueInput>;
    distinct?: InputMaybe<Array<AcceptedTermsScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AcceptedTermsOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AcceptedTermsWhereInput>;
};

export type TermsAndConditionsAvgAggregate = {
    __typename?: "TermsAndConditionsAvgAggregate";
    version?: Maybe<Scalars["Float"]>;
};

export type TermsAndConditionsAvgOrderByAggregateInput = {
    version?: InputMaybe<SortOrder>;
};

export type TermsAndConditionsCount = {
    __typename?: "TermsAndConditionsCount";
    acceptedTerms: Scalars["Int"];
};

export type TermsAndConditionsCountAggregate = {
    __typename?: "TermsAndConditionsCountAggregate";
    _all: Scalars["Int"];
    documentUrl: Scalars["Int"];
    version: Scalars["Int"];
};

export type TermsAndConditionsCountOrderByAggregateInput = {
    documentUrl?: InputMaybe<SortOrder>;
    version?: InputMaybe<SortOrder>;
};

export type TermsAndConditionsCreateInput = {
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutVersionInput>;
    documentUrl: Scalars["String"];
};

export type TermsAndConditionsCreateManyInput = {
    documentUrl: Scalars["String"];
    version?: InputMaybe<Scalars["Int"]>;
};

export type TermsAndConditionsCreateNestedOneWithoutAcceptedTermsInput = {
    connect?: InputMaybe<TermsAndConditionsWhereUniqueInput>;
    connectOrCreate?: InputMaybe<TermsAndConditionsCreateOrConnectWithoutAcceptedTermsInput>;
    create?: InputMaybe<TermsAndConditionsCreateWithoutAcceptedTermsInput>;
};

export type TermsAndConditionsCreateOrConnectWithoutAcceptedTermsInput = {
    create: TermsAndConditionsCreateWithoutAcceptedTermsInput;
    where: TermsAndConditionsWhereUniqueInput;
};

export type TermsAndConditionsCreateWithoutAcceptedTermsInput = {
    documentUrl: Scalars["String"];
};

export type TermsAndConditionsGroupBy = {
    __typename?: "TermsAndConditionsGroupBy";
    _avg?: Maybe<TermsAndConditionsAvgAggregate>;
    _count?: Maybe<TermsAndConditionsCountAggregate>;
    _max?: Maybe<TermsAndConditionsMaxAggregate>;
    _min?: Maybe<TermsAndConditionsMinAggregate>;
    _sum?: Maybe<TermsAndConditionsSumAggregate>;
    documentUrl: Scalars["String"];
    version: Scalars["Int"];
};

export type TermsAndConditionsMaxAggregate = {
    __typename?: "TermsAndConditionsMaxAggregate";
    documentUrl?: Maybe<Scalars["String"]>;
    version?: Maybe<Scalars["Int"]>;
};

export type TermsAndConditionsMaxOrderByAggregateInput = {
    documentUrl?: InputMaybe<SortOrder>;
    version?: InputMaybe<SortOrder>;
};

export type TermsAndConditionsMinAggregate = {
    __typename?: "TermsAndConditionsMinAggregate";
    documentUrl?: Maybe<Scalars["String"]>;
    version?: Maybe<Scalars["Int"]>;
};

export type TermsAndConditionsMinOrderByAggregateInput = {
    documentUrl?: InputMaybe<SortOrder>;
    version?: InputMaybe<SortOrder>;
};

export type TermsAndConditionsOrderByWithAggregationInput = {
    _avg?: InputMaybe<TermsAndConditionsAvgOrderByAggregateInput>;
    _count?: InputMaybe<TermsAndConditionsCountOrderByAggregateInput>;
    _max?: InputMaybe<TermsAndConditionsMaxOrderByAggregateInput>;
    _min?: InputMaybe<TermsAndConditionsMinOrderByAggregateInput>;
    _sum?: InputMaybe<TermsAndConditionsSumOrderByAggregateInput>;
    documentUrl?: InputMaybe<SortOrder>;
    version?: InputMaybe<SortOrder>;
};

export type TermsAndConditionsOrderByWithRelationInput = {
    acceptedTerms?: InputMaybe<AcceptedTermsOrderByRelationAggregateInput>;
    documentUrl?: InputMaybe<SortOrder>;
    version?: InputMaybe<SortOrder>;
};

export type TermsAndConditionsRelationFilter = {
    is?: InputMaybe<TermsAndConditionsWhereInput>;
    isNot?: InputMaybe<TermsAndConditionsWhereInput>;
};

export enum TermsAndConditionsScalarFieldEnum {
    DocumentUrl = "documentUrl",
    Version = "version",
}

export type TermsAndConditionsScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<TermsAndConditionsScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<TermsAndConditionsScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<TermsAndConditionsScalarWhereWithAggregatesInput>>;
    documentUrl?: InputMaybe<StringWithAggregatesFilter>;
    version?: InputMaybe<IntWithAggregatesFilter>;
};

export type TermsAndConditionsSumAggregate = {
    __typename?: "TermsAndConditionsSumAggregate";
    version?: Maybe<Scalars["Int"]>;
};

export type TermsAndConditionsSumOrderByAggregateInput = {
    version?: InputMaybe<SortOrder>;
};

export type TermsAndConditionsUpdateInput = {
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutVersionNestedInput>;
    documentUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TermsAndConditionsUpdateManyMutationInput = {
    documentUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TermsAndConditionsUpdateOneRequiredWithoutAcceptedTermsNestedInput = {
    connect?: InputMaybe<TermsAndConditionsWhereUniqueInput>;
    connectOrCreate?: InputMaybe<TermsAndConditionsCreateOrConnectWithoutAcceptedTermsInput>;
    create?: InputMaybe<TermsAndConditionsCreateWithoutAcceptedTermsInput>;
    update?: InputMaybe<TermsAndConditionsUpdateWithoutAcceptedTermsInput>;
    upsert?: InputMaybe<TermsAndConditionsUpsertWithoutAcceptedTermsInput>;
};

export type TermsAndConditionsUpdateWithoutAcceptedTermsInput = {
    documentUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TermsAndConditionsUpsertWithoutAcceptedTermsInput = {
    create: TermsAndConditionsCreateWithoutAcceptedTermsInput;
    update: TermsAndConditionsUpdateWithoutAcceptedTermsInput;
};

export type TermsAndConditionsWhereInput = {
    AND?: InputMaybe<Array<TermsAndConditionsWhereInput>>;
    NOT?: InputMaybe<Array<TermsAndConditionsWhereInput>>;
    OR?: InputMaybe<Array<TermsAndConditionsWhereInput>>;
    acceptedTerms?: InputMaybe<AcceptedTermsListRelationFilter>;
    documentUrl?: InputMaybe<StringFilter>;
    version?: InputMaybe<IntFilter>;
};

export type TermsAndConditionsWhereUniqueInput = {
    version?: InputMaybe<Scalars["Int"]>;
};

export enum TipoMinibanco {
    Debenture = "DEBENTURE",
    Esc = "ESC",
    Lite = "LITE",
    Sec = "SEC",
}

export type TipoMovimentacao = {
    __typename?: "TipoMovimentacao";
    _count?: Maybe<TipoMovimentacaoCount>;
    createdAt: Scalars["DateTime"];
    descricao?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    nome: Scalars["String"];
    ocorrencias: Array<MinibancoExtrato>;
    sigla: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type TipoMovimentacaoOcorrenciasArgs = {
    cursor?: InputMaybe<MinibancoExtratoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoExtratoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoExtratoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoExtratoWhereInput>;
};

export type TipoMovimentacaoAvgAggregate = {
    __typename?: "TipoMovimentacaoAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
};

export type TipoMovimentacaoAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type TipoMovimentacaoCount = {
    __typename?: "TipoMovimentacaoCount";
    ocorrencias: Scalars["Int"];
};

export type TipoMovimentacaoCountAggregate = {
    __typename?: "TipoMovimentacaoCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    descricao: Scalars["Int"];
    id: Scalars["Int"];
    nome: Scalars["Int"];
    sigla: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type TipoMovimentacaoCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type TipoMovimentacaoCreateInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    nome: Scalars["String"];
    ocorrencias?: InputMaybe<MinibancoExtratoCreateNestedManyWithoutTipoMovInput>;
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type TipoMovimentacaoCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    nome: Scalars["String"];
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type TipoMovimentacaoCreateNestedOneWithoutOcorrenciasInput = {
    connect?: InputMaybe<TipoMovimentacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<TipoMovimentacaoCreateOrConnectWithoutOcorrenciasInput>;
    create?: InputMaybe<TipoMovimentacaoCreateWithoutOcorrenciasInput>;
};

export type TipoMovimentacaoCreateOrConnectWithoutOcorrenciasInput = {
    create: TipoMovimentacaoCreateWithoutOcorrenciasInput;
    where: TipoMovimentacaoWhereUniqueInput;
};

export type TipoMovimentacaoCreateWithoutOcorrenciasInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    descricao?: InputMaybe<Scalars["String"]>;
    nome: Scalars["String"];
    sigla: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type TipoMovimentacaoGroupBy = {
    __typename?: "TipoMovimentacaoGroupBy";
    _avg?: Maybe<TipoMovimentacaoAvgAggregate>;
    _count?: Maybe<TipoMovimentacaoCountAggregate>;
    _max?: Maybe<TipoMovimentacaoMaxAggregate>;
    _min?: Maybe<TipoMovimentacaoMinAggregate>;
    _sum?: Maybe<TipoMovimentacaoSumAggregate>;
    createdAt: Scalars["DateTime"];
    descricao?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    nome: Scalars["String"];
    sigla: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type TipoMovimentacaoMaxAggregate = {
    __typename?: "TipoMovimentacaoMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    nome?: Maybe<Scalars["String"]>;
    sigla?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TipoMovimentacaoMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type TipoMovimentacaoMinAggregate = {
    __typename?: "TipoMovimentacaoMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    descricao?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    nome?: Maybe<Scalars["String"]>;
    sigla?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TipoMovimentacaoMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type TipoMovimentacaoOrderByWithAggregationInput = {
    _avg?: InputMaybe<TipoMovimentacaoAvgOrderByAggregateInput>;
    _count?: InputMaybe<TipoMovimentacaoCountOrderByAggregateInput>;
    _max?: InputMaybe<TipoMovimentacaoMaxOrderByAggregateInput>;
    _min?: InputMaybe<TipoMovimentacaoMinOrderByAggregateInput>;
    _sum?: InputMaybe<TipoMovimentacaoSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type TipoMovimentacaoOrderByWithRelationInput = {
    createdAt?: InputMaybe<SortOrder>;
    descricao?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    nome?: InputMaybe<SortOrder>;
    ocorrencias?: InputMaybe<MinibancoExtratoOrderByRelationAggregateInput>;
    sigla?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type TipoMovimentacaoRelationFilter = {
    is?: InputMaybe<TipoMovimentacaoWhereInput>;
    isNot?: InputMaybe<TipoMovimentacaoWhereInput>;
};

export enum TipoMovimentacaoScalarFieldEnum {
    CreatedAt = "createdAt",
    Descricao = "descricao",
    Id = "id",
    Nome = "nome",
    Sigla = "sigla",
    UpdatedAt = "updatedAt",
}

export type TipoMovimentacaoScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<TipoMovimentacaoScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<TipoMovimentacaoScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<TipoMovimentacaoScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    descricao?: InputMaybe<StringNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    nome?: InputMaybe<StringWithAggregatesFilter>;
    sigla?: InputMaybe<StringWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type TipoMovimentacaoSumAggregate = {
    __typename?: "TipoMovimentacaoSumAggregate";
    id?: Maybe<Scalars["Int"]>;
};

export type TipoMovimentacaoSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type TipoMovimentacaoUpdateInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    ocorrencias?: InputMaybe<MinibancoExtratoUpdateManyWithoutTipoMovNestedInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TipoMovimentacaoUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TipoMovimentacaoUpdateOneRequiredWithoutOcorrenciasNestedInput = {
    connect?: InputMaybe<TipoMovimentacaoWhereUniqueInput>;
    connectOrCreate?: InputMaybe<TipoMovimentacaoCreateOrConnectWithoutOcorrenciasInput>;
    create?: InputMaybe<TipoMovimentacaoCreateWithoutOcorrenciasInput>;
    update?: InputMaybe<TipoMovimentacaoUpdateWithoutOcorrenciasInput>;
    upsert?: InputMaybe<TipoMovimentacaoUpsertWithoutOcorrenciasInput>;
};

export type TipoMovimentacaoUpdateWithoutOcorrenciasInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    descricao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    nome?: InputMaybe<StringFieldUpdateOperationsInput>;
    sigla?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TipoMovimentacaoUpsertWithoutOcorrenciasInput = {
    create: TipoMovimentacaoCreateWithoutOcorrenciasInput;
    update: TipoMovimentacaoUpdateWithoutOcorrenciasInput;
};

export type TipoMovimentacaoWhereInput = {
    AND?: InputMaybe<Array<TipoMovimentacaoWhereInput>>;
    NOT?: InputMaybe<Array<TipoMovimentacaoWhereInput>>;
    OR?: InputMaybe<Array<TipoMovimentacaoWhereInput>>;
    createdAt?: InputMaybe<DateTimeFilter>;
    descricao?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    nome?: InputMaybe<StringFilter>;
    ocorrencias?: InputMaybe<MinibancoExtratoListRelationFilter>;
    sigla?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TipoMovimentacaoWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
    sigla?: InputMaybe<Scalars["String"]>;
};

export enum TipoServico {
    FastapiNfe = "FASTAPI_NFE",
    NestApi = "NEST_API",
    NifiGestor = "NIFI_GESTOR",
    PlataformaCedente = "PLATAFORMA_CEDENTE",
}

export enum TypeOfAnalysis {
    AnaliseCompleta = "ANALISE_COMPLETA",
    AnaliseSimples = "ANALISE_SIMPLES",
    RiscoZero = "RISCO_ZERO",
}

export type Usuario = {
    __typename?: "Usuario";
    ComercialPartnerAudit: Array<ComercialPartnerAudit>;
    ContatoAudit: Array<ContatoAudit>;
    DebenturistaAudit: Array<DebenturistaAudit>;
    KeyAccount: Array<Operacao>;
    OccupationAudit: Array<OccupationAudit>;
    Salesman: Array<Operacao>;
    _count?: Maybe<UsuarioCount>;
    acceptedTerms: Array<AcceptedTerms>;
    alteracaoCedentes: Array<CedenteAlteracao>;
    alteracaoEnderecoCedentes: Array<EnderecoAlteracao>;
    arquivos: Array<Arquivo>;
    cadastro?: Maybe<UsuarioCadastro>;
    cadastroCedentes: Array<CedenteCadastro>;
    cadastroEndereco: Array<EnderecoCadastro>;
    cadastroId?: Maybe<Scalars["Int"]>;
    camposAlteracaoCedentes: Array<CedenteCamposAlteracao>;
    camposAlteracaoEnderecoCedentes: Array<EnderecoCamposAlteracao>;
    camposCadastroCedentes: Array<CedenteCamposCadastro>;
    camposCadastroEndereco: Array<EnderecoCamposCadastro>;
    cargo?: Maybe<Scalars["String"]>;
    cedentes: Array<ResponsavelCedente>;
    createdAt: Scalars["DateTime"];
    email: Scalars["String"];
    firstName?: Maybe<Scalars["String"]>;
    fone?: Maybe<Scalars["String"]>;
    histArquivo: Array<ArquivoAudit>;
    histCedente: Array<CedenteAudit>;
    histEmpresa: Array<EmpresaAudit>;
    histEndereco: Array<EnderecoAudit>;
    histMinibanco: Array<MinibancoAudit>;
    histOperacao: Array<OperacaoAudit>;
    histRecebivel: Array<RecebivelAudit>;
    histSacado: Array<SacadoAudit>;
    histStatusOperacional: Array<StatusOperacionalAudit>;
    histUsuario: Array<UsuarioAudit>;
    historico: Array<UsuarioAudit>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    indPermissoes: Array<NivelPermissao>;
    isAdmin: Scalars["Boolean"];
    lastName?: Maybe<Scalars["String"]>;
    minibanco?: Maybe<Minibanco>;
    minibancoId?: Maybe<Scalars["Int"]>;
    minibanksWallet: Array<Minibanco>;
    notificacoes: Array<GrupoNotificacao>;
    occupations: Array<Occupation>;
    permissoes: Array<GruposPermissao>;
    recebiveis: Array<Recebivel>;
    respContato: Array<Contato>;
    respGrupoNotificacao: Array<GrupoNotificacao>;
    respGrupoPermissao: Array<GruposPermissao>;
    updatedAt: Scalars["DateTime"];
};

export type UsuarioComercialPartnerAuditArgs = {
    cursor?: InputMaybe<ComercialPartnerAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<ComercialPartnerAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ComercialPartnerAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ComercialPartnerAuditWhereInput>;
};

export type UsuarioContatoAuditArgs = {
    cursor?: InputMaybe<ContatoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<ContatoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ContatoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContatoAuditWhereInput>;
};

export type UsuarioDebenturistaAuditArgs = {
    cursor?: InputMaybe<DebenturistaAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<DebenturistaAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<DebenturistaAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<DebenturistaAuditWhereInput>;
};

export type UsuarioKeyAccountArgs = {
    cursor?: InputMaybe<OperacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoWhereInput>;
};

export type UsuarioOccupationAuditArgs = {
    cursor?: InputMaybe<OccupationAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<OccupationAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OccupationAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OccupationAuditWhereInput>;
};

export type UsuarioSalesmanArgs = {
    cursor?: InputMaybe<OperacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoWhereInput>;
};

export type UsuarioAcceptedTermsArgs = {
    cursor?: InputMaybe<AcceptedTermsWhereUniqueInput>;
    distinct?: InputMaybe<Array<AcceptedTermsScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<AcceptedTermsOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<AcceptedTermsWhereInput>;
};

export type UsuarioAlteracaoCedentesArgs = {
    cursor?: InputMaybe<CedenteAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAlteracaoWhereInput>;
};

export type UsuarioAlteracaoEnderecoCedentesArgs = {
    cursor?: InputMaybe<EnderecoAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoAlteracaoWhereInput>;
};

export type UsuarioArquivosArgs = {
    cursor?: InputMaybe<ArquivoWhereUniqueInput>;
    distinct?: InputMaybe<Array<ArquivoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ArquivoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoWhereInput>;
};

export type UsuarioCadastroCedentesArgs = {
    cursor?: InputMaybe<CedenteCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCadastroWhereInput>;
};

export type UsuarioCadastroEnderecoArgs = {
    cursor?: InputMaybe<EnderecoCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCadastroWhereInput>;
};

export type UsuarioCamposAlteracaoCedentesArgs = {
    cursor?: InputMaybe<CedenteCamposAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteCamposAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteCamposAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCamposAlteracaoWhereInput>;
};

export type UsuarioCamposAlteracaoEnderecoCedentesArgs = {
    cursor?: InputMaybe<EnderecoCamposAlteracaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoCamposAlteracaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoCamposAlteracaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCamposAlteracaoWhereInput>;
};

export type UsuarioCamposCadastroCedentesArgs = {
    cursor?: InputMaybe<CedenteCamposCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteCamposCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteCamposCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteCamposCadastroWhereInput>;
};

export type UsuarioCamposCadastroEnderecoArgs = {
    cursor?: InputMaybe<EnderecoCamposCadastroWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoCamposCadastroScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoCamposCadastroOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoCamposCadastroWhereInput>;
};

export type UsuarioCedentesArgs = {
    cursor?: InputMaybe<ResponsavelCedenteWhereUniqueInput>;
    distinct?: InputMaybe<Array<ResponsavelCedenteScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ResponsavelCedenteOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ResponsavelCedenteWhereInput>;
};

export type UsuarioHistArquivoArgs = {
    cursor?: InputMaybe<ArquivoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<ArquivoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ArquivoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ArquivoAuditWhereInput>;
};

export type UsuarioHistCedenteArgs = {
    cursor?: InputMaybe<CedenteAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<CedenteAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<CedenteAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<CedenteAuditWhereInput>;
};

export type UsuarioHistEmpresaArgs = {
    cursor?: InputMaybe<EmpresaAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<EmpresaAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EmpresaAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EmpresaAuditWhereInput>;
};

export type UsuarioHistEnderecoArgs = {
    cursor?: InputMaybe<EnderecoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<EnderecoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<EnderecoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<EnderecoAuditWhereInput>;
};

export type UsuarioHistMinibancoArgs = {
    cursor?: InputMaybe<MinibancoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoAuditWhereInput>;
};

export type UsuarioHistOperacaoArgs = {
    cursor?: InputMaybe<OperacaoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<OperacaoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OperacaoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OperacaoAuditWhereInput>;
};

export type UsuarioHistRecebivelArgs = {
    cursor?: InputMaybe<RecebivelAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelAuditWhereInput>;
};

export type UsuarioHistSacadoArgs = {
    cursor?: InputMaybe<SacadoAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<SacadoAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<SacadoAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<SacadoAuditWhereInput>;
};

export type UsuarioHistStatusOperacionalArgs = {
    cursor?: InputMaybe<StatusOperacionalAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<StatusOperacionalAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<StatusOperacionalAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<StatusOperacionalAuditWhereInput>;
};

export type UsuarioHistUsuarioArgs = {
    cursor?: InputMaybe<UsuarioAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioAuditWhereInput>;
};

export type UsuarioHistoricoArgs = {
    cursor?: InputMaybe<UsuarioAuditWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioAuditScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioAuditOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioAuditWhereInput>;
};

export type UsuarioIndPermissoesArgs = {
    cursor?: InputMaybe<NivelPermissaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<NivelPermissaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<NivelPermissaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<NivelPermissaoWhereInput>;
};

export type UsuarioMinibanksWalletArgs = {
    cursor?: InputMaybe<MinibancoWhereUniqueInput>;
    distinct?: InputMaybe<Array<MinibancoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<MinibancoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<MinibancoWhereInput>;
};

export type UsuarioNotificacoesArgs = {
    cursor?: InputMaybe<GrupoNotificacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<GrupoNotificacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<GrupoNotificacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GrupoNotificacaoWhereInput>;
};

export type UsuarioOccupationsArgs = {
    cursor?: InputMaybe<OccupationWhereUniqueInput>;
    distinct?: InputMaybe<Array<OccupationScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<OccupationOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<OccupationWhereInput>;
};

export type UsuarioPermissoesArgs = {
    cursor?: InputMaybe<GruposPermissaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<GruposPermissaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<GruposPermissaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GruposPermissaoWhereInput>;
};

export type UsuarioRecebiveisArgs = {
    cursor?: InputMaybe<RecebivelWhereUniqueInput>;
    distinct?: InputMaybe<Array<RecebivelScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<RecebivelOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<RecebivelWhereInput>;
};

export type UsuarioRespContatoArgs = {
    cursor?: InputMaybe<ContatoWhereUniqueInput>;
    distinct?: InputMaybe<Array<ContatoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<ContatoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<ContatoWhereInput>;
};

export type UsuarioRespGrupoNotificacaoArgs = {
    cursor?: InputMaybe<GrupoNotificacaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<GrupoNotificacaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<GrupoNotificacaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GrupoNotificacaoWhereInput>;
};

export type UsuarioRespGrupoPermissaoArgs = {
    cursor?: InputMaybe<GruposPermissaoWhereUniqueInput>;
    distinct?: InputMaybe<Array<GruposPermissaoScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<GruposPermissaoOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<GruposPermissaoWhereInput>;
};

export type UsuarioAudit = {
    __typename?: "UsuarioAudit";
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuario?: Maybe<Usuario>;
    usuarioId?: Maybe<Scalars["Int"]>;
    usuarioMod?: Maybe<Usuario>;
    usuarioModId?: Maybe<Scalars["Int"]>;
};

export type UsuarioAuditAvgAggregate = {
    __typename?: "UsuarioAuditAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
    usuarioId?: Maybe<Scalars["Float"]>;
    usuarioModId?: Maybe<Scalars["Float"]>;
};

export type UsuarioAuditAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    usuarioModId?: InputMaybe<SortOrder>;
};

export type UsuarioAuditCountAggregate = {
    __typename?: "UsuarioAuditCountAggregate";
    _all: Scalars["Int"];
    action: Scalars["Int"];
    createdAt: Scalars["Int"];
    field: Scalars["Int"];
    id: Scalars["Int"];
    ipUsuario: Scalars["Int"];
    newValue: Scalars["Int"];
    oldValue: Scalars["Int"];
    service: Scalars["Int"];
    userAgent: Scalars["Int"];
    usuarioId: Scalars["Int"];
    usuarioModId: Scalars["Int"];
};

export type UsuarioAuditCountOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    usuarioModId?: InputMaybe<SortOrder>;
};

export type UsuarioAuditCreateInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistUsuarioInput>;
    usuarioMod?: InputMaybe<UsuarioCreateNestedOneWithoutHistoricoInput>;
};

export type UsuarioAuditCreateManyInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
    usuarioModId?: InputMaybe<Scalars["Int"]>;
};

export type UsuarioAuditCreateManyUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioModId?: InputMaybe<Scalars["Int"]>;
};

export type UsuarioAuditCreateManyUsuarioInputEnvelope = {
    data: Array<UsuarioAuditCreateManyUsuarioInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type UsuarioAuditCreateManyUsuarioModInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    id?: InputMaybe<Scalars["Int"]>;
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioId?: InputMaybe<Scalars["Int"]>;
};

export type UsuarioAuditCreateManyUsuarioModInputEnvelope = {
    data: Array<UsuarioAuditCreateManyUsuarioModInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type UsuarioAuditCreateNestedManyWithoutUsuarioInput = {
    connect?: InputMaybe<Array<UsuarioAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<UsuarioAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<UsuarioAuditCreateManyUsuarioInputEnvelope>;
};

export type UsuarioAuditCreateNestedManyWithoutUsuarioModInput = {
    connect?: InputMaybe<Array<UsuarioAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioAuditCreateOrConnectWithoutUsuarioModInput>>;
    create?: InputMaybe<Array<UsuarioAuditCreateWithoutUsuarioModInput>>;
    createMany?: InputMaybe<UsuarioAuditCreateManyUsuarioModInputEnvelope>;
};

export type UsuarioAuditCreateOrConnectWithoutUsuarioInput = {
    create: UsuarioAuditCreateWithoutUsuarioInput;
    where: UsuarioAuditWhereUniqueInput;
};

export type UsuarioAuditCreateOrConnectWithoutUsuarioModInput = {
    create: UsuarioAuditCreateWithoutUsuarioModInput;
    where: UsuarioAuditWhereUniqueInput;
};

export type UsuarioAuditCreateWithoutUsuarioInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuarioMod?: InputMaybe<UsuarioCreateNestedOneWithoutHistoricoInput>;
};

export type UsuarioAuditCreateWithoutUsuarioModInput = {
    action?: InputMaybe<AuditAcoes>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    field: Scalars["String"];
    ipUsuario: Scalars["String"];
    newValue?: InputMaybe<Scalars["String"]>;
    oldValue?: InputMaybe<Scalars["String"]>;
    service?: InputMaybe<TipoServico>;
    userAgent: Scalars["String"];
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutHistUsuarioInput>;
};

export type UsuarioAuditGroupBy = {
    __typename?: "UsuarioAuditGroupBy";
    _avg?: Maybe<UsuarioAuditAvgAggregate>;
    _count?: Maybe<UsuarioAuditCountAggregate>;
    _max?: Maybe<UsuarioAuditMaxAggregate>;
    _min?: Maybe<UsuarioAuditMinAggregate>;
    _sum?: Maybe<UsuarioAuditSumAggregate>;
    action: AuditAcoes;
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    id: Scalars["Int"];
    ipUsuario: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service: TipoServico;
    userAgent: Scalars["String"];
    usuarioId?: Maybe<Scalars["Int"]>;
    usuarioModId?: Maybe<Scalars["Int"]>;
};

export type UsuarioAuditListRelationFilter = {
    every?: InputMaybe<UsuarioAuditWhereInput>;
    none?: InputMaybe<UsuarioAuditWhereInput>;
    some?: InputMaybe<UsuarioAuditWhereInput>;
};

export type UsuarioAuditMaxAggregate = {
    __typename?: "UsuarioAuditMaxAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
    usuarioModId?: Maybe<Scalars["Int"]>;
};

export type UsuarioAuditMaxOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    usuarioModId?: InputMaybe<SortOrder>;
};

export type UsuarioAuditMinAggregate = {
    __typename?: "UsuarioAuditMinAggregate";
    action?: Maybe<AuditAcoes>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    field?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    ipUsuario?: Maybe<Scalars["String"]>;
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    service?: Maybe<TipoServico>;
    userAgent?: Maybe<Scalars["String"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
    usuarioModId?: Maybe<Scalars["Int"]>;
};

export type UsuarioAuditMinOrderByAggregateInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    usuarioModId?: InputMaybe<SortOrder>;
};

export type UsuarioAuditOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type UsuarioAuditOrderByWithAggregationInput = {
    _avg?: InputMaybe<UsuarioAuditAvgOrderByAggregateInput>;
    _count?: InputMaybe<UsuarioAuditCountOrderByAggregateInput>;
    _max?: InputMaybe<UsuarioAuditMaxOrderByAggregateInput>;
    _min?: InputMaybe<UsuarioAuditMinOrderByAggregateInput>;
    _sum?: InputMaybe<UsuarioAuditSumOrderByAggregateInput>;
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    usuarioModId?: InputMaybe<SortOrder>;
};

export type UsuarioAuditOrderByWithRelationInput = {
    action?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    field?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    ipUsuario?: InputMaybe<SortOrder>;
    newValue?: InputMaybe<SortOrder>;
    oldValue?: InputMaybe<SortOrder>;
    service?: InputMaybe<SortOrder>;
    userAgent?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioId?: InputMaybe<SortOrder>;
    usuarioMod?: InputMaybe<UsuarioOrderByWithRelationInput>;
    usuarioModId?: InputMaybe<SortOrder>;
};

export enum UsuarioAuditScalarFieldEnum {
    Action = "action",
    CreatedAt = "createdAt",
    Field = "field",
    Id = "id",
    IpUsuario = "ipUsuario",
    NewValue = "newValue",
    OldValue = "oldValue",
    Service = "service",
    UserAgent = "userAgent",
    UsuarioId = "usuarioId",
    UsuarioModId = "usuarioModId",
}

export type UsuarioAuditScalarWhereInput = {
    AND?: InputMaybe<Array<UsuarioAuditScalarWhereInput>>;
    NOT?: InputMaybe<Array<UsuarioAuditScalarWhereInput>>;
    OR?: InputMaybe<Array<UsuarioAuditScalarWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
    usuarioModId?: InputMaybe<IntNullableFilter>;
};

export type UsuarioAuditScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<UsuarioAuditScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<UsuarioAuditScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<UsuarioAuditScalarWhereWithAggregatesInput>>;
    action?: InputMaybe<EnumAuditAcoesWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    field?: InputMaybe<StringWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    ipUsuario?: InputMaybe<StringWithAggregatesFilter>;
    newValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    oldValue?: InputMaybe<StringNullableWithAggregatesFilter>;
    service?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    userAgent?: InputMaybe<StringWithAggregatesFilter>;
    usuarioId?: InputMaybe<IntNullableWithAggregatesFilter>;
    usuarioModId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type UsuarioAuditSumAggregate = {
    __typename?: "UsuarioAuditSumAggregate";
    id?: Maybe<Scalars["Int"]>;
    usuarioId?: Maybe<Scalars["Int"]>;
    usuarioModId?: Maybe<Scalars["Int"]>;
};

export type UsuarioAuditSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
    usuarioId?: InputMaybe<SortOrder>;
    usuarioModId?: InputMaybe<SortOrder>;
};

export type UsuarioAuditUpdateInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistUsuarioNestedInput>;
    usuarioMod?: InputMaybe<UsuarioUpdateOneWithoutHistoricoNestedInput>;
};

export type UsuarioAuditUpdateManyMutationInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UsuarioAuditUpdateManyWithWhereWithoutUsuarioInput = {
    data: UsuarioAuditUpdateManyMutationInput;
    where: UsuarioAuditScalarWhereInput;
};

export type UsuarioAuditUpdateManyWithWhereWithoutUsuarioModInput = {
    data: UsuarioAuditUpdateManyMutationInput;
    where: UsuarioAuditScalarWhereInput;
};

export type UsuarioAuditUpdateManyWithoutUsuarioModNestedInput = {
    connect?: InputMaybe<Array<UsuarioAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioAuditCreateOrConnectWithoutUsuarioModInput>>;
    create?: InputMaybe<Array<UsuarioAuditCreateWithoutUsuarioModInput>>;
    createMany?: InputMaybe<UsuarioAuditCreateManyUsuarioModInputEnvelope>;
    delete?: InputMaybe<Array<UsuarioAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<UsuarioAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<UsuarioAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<UsuarioAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<UsuarioAuditUpdateWithWhereUniqueWithoutUsuarioModInput>>;
    updateMany?: InputMaybe<Array<UsuarioAuditUpdateManyWithWhereWithoutUsuarioModInput>>;
    upsert?: InputMaybe<Array<UsuarioAuditUpsertWithWhereUniqueWithoutUsuarioModInput>>;
};

export type UsuarioAuditUpdateManyWithoutUsuarioNestedInput = {
    connect?: InputMaybe<Array<UsuarioAuditWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioAuditCreateOrConnectWithoutUsuarioInput>>;
    create?: InputMaybe<Array<UsuarioAuditCreateWithoutUsuarioInput>>;
    createMany?: InputMaybe<UsuarioAuditCreateManyUsuarioInputEnvelope>;
    delete?: InputMaybe<Array<UsuarioAuditWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<UsuarioAuditScalarWhereInput>>;
    disconnect?: InputMaybe<Array<UsuarioAuditWhereUniqueInput>>;
    set?: InputMaybe<Array<UsuarioAuditWhereUniqueInput>>;
    update?: InputMaybe<Array<UsuarioAuditUpdateWithWhereUniqueWithoutUsuarioInput>>;
    updateMany?: InputMaybe<Array<UsuarioAuditUpdateManyWithWhereWithoutUsuarioInput>>;
    upsert?: InputMaybe<Array<UsuarioAuditUpsertWithWhereUniqueWithoutUsuarioInput>>;
};

export type UsuarioAuditUpdateWithWhereUniqueWithoutUsuarioInput = {
    data: UsuarioAuditUpdateWithoutUsuarioInput;
    where: UsuarioAuditWhereUniqueInput;
};

export type UsuarioAuditUpdateWithWhereUniqueWithoutUsuarioModInput = {
    data: UsuarioAuditUpdateWithoutUsuarioModInput;
    where: UsuarioAuditWhereUniqueInput;
};

export type UsuarioAuditUpdateWithoutUsuarioInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuarioMod?: InputMaybe<UsuarioUpdateOneWithoutHistoricoNestedInput>;
};

export type UsuarioAuditUpdateWithoutUsuarioModInput = {
    action?: InputMaybe<EnumAuditAcoesFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    field?: InputMaybe<StringFieldUpdateOperationsInput>;
    ipUsuario?: InputMaybe<StringFieldUpdateOperationsInput>;
    newValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    oldValue?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    service?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    userAgent?: InputMaybe<StringFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutHistUsuarioNestedInput>;
};

export type UsuarioAuditUpsertWithWhereUniqueWithoutUsuarioInput = {
    create: UsuarioAuditCreateWithoutUsuarioInput;
    update: UsuarioAuditUpdateWithoutUsuarioInput;
    where: UsuarioAuditWhereUniqueInput;
};

export type UsuarioAuditUpsertWithWhereUniqueWithoutUsuarioModInput = {
    create: UsuarioAuditCreateWithoutUsuarioModInput;
    update: UsuarioAuditUpdateWithoutUsuarioModInput;
    where: UsuarioAuditWhereUniqueInput;
};

export type UsuarioAuditWhereInput = {
    AND?: InputMaybe<Array<UsuarioAuditWhereInput>>;
    NOT?: InputMaybe<Array<UsuarioAuditWhereInput>>;
    OR?: InputMaybe<Array<UsuarioAuditWhereInput>>;
    action?: InputMaybe<EnumAuditAcoesFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    field?: InputMaybe<StringFilter>;
    id?: InputMaybe<IntFilter>;
    ipUsuario?: InputMaybe<StringFilter>;
    newValue?: InputMaybe<StringNullableFilter>;
    oldValue?: InputMaybe<StringNullableFilter>;
    service?: InputMaybe<EnumTipoServicoFilter>;
    userAgent?: InputMaybe<StringFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
    usuarioId?: InputMaybe<IntNullableFilter>;
    usuarioMod?: InputMaybe<UsuarioRelationFilter>;
    usuarioModId?: InputMaybe<IntNullableFilter>;
};

export type UsuarioAuditWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type UsuarioAvgAggregate = {
    __typename?: "UsuarioAvgAggregate";
    cadastroId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
    minibancoId?: Maybe<Scalars["Float"]>;
};

export type UsuarioAvgOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
};

export type UsuarioCadastro = {
    __typename?: "UsuarioCadastro";
    _count?: Maybe<UsuarioCadastroCount>;
    campos: Array<UsuarioCadastroCampos>;
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    origem: TipoServico;
    status: CadastroStatus;
    updatedAt: Scalars["DateTime"];
    usuario?: Maybe<Usuario>;
};

export type UsuarioCadastroCamposArgs = {
    cursor?: InputMaybe<UsuarioCadastroCamposWhereUniqueInput>;
    distinct?: InputMaybe<Array<UsuarioCadastroCamposScalarFieldEnum>>;
    orderBy?: InputMaybe<Array<UsuarioCadastroCamposOrderByWithRelationInput>>;
    skip?: InputMaybe<Scalars["Int"]>;
    take?: InputMaybe<Scalars["Int"]>;
    where?: InputMaybe<UsuarioCadastroCamposWhereInput>;
};

export type UsuarioCadastroAvgAggregate = {
    __typename?: "UsuarioCadastroAvgAggregate";
    id?: Maybe<Scalars["Float"]>;
};

export type UsuarioCadastroAvgOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type UsuarioCadastroCampos = {
    __typename?: "UsuarioCadastroCampos";
    cadastro: UsuarioCadastro;
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
    valor?: Maybe<Scalars["String"]>;
};

export type UsuarioCadastroCamposAvgAggregate = {
    __typename?: "UsuarioCadastroCamposAvgAggregate";
    cadastroId?: Maybe<Scalars["Float"]>;
    id?: Maybe<Scalars["Float"]>;
};

export type UsuarioCadastroCamposAvgOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type UsuarioCadastroCamposCountAggregate = {
    __typename?: "UsuarioCadastroCamposCountAggregate";
    _all: Scalars["Int"];
    cadastroId: Scalars["Int"];
    campo: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    updatedAt: Scalars["Int"];
    valor: Scalars["Int"];
};

export type UsuarioCadastroCamposCountOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type UsuarioCadastroCamposCreateInput = {
    cadastro: UsuarioCadastroCreateNestedOneWithoutCamposInput;
    campo: Scalars["String"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valor?: InputMaybe<Scalars["String"]>;
};

export type UsuarioCadastroCamposCreateManyCadastroInput = {
    campo: Scalars["String"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valor?: InputMaybe<Scalars["String"]>;
};

export type UsuarioCadastroCamposCreateManyCadastroInputEnvelope = {
    data: Array<UsuarioCadastroCamposCreateManyCadastroInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type UsuarioCadastroCamposCreateManyInput = {
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valor?: InputMaybe<Scalars["String"]>;
};

export type UsuarioCadastroCamposCreateNestedManyWithoutCadastroInput = {
    connect?: InputMaybe<Array<UsuarioCadastroCamposWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioCadastroCamposCreateOrConnectWithoutCadastroInput>>;
    create?: InputMaybe<Array<UsuarioCadastroCamposCreateWithoutCadastroInput>>;
    createMany?: InputMaybe<UsuarioCadastroCamposCreateManyCadastroInputEnvelope>;
};

export type UsuarioCadastroCamposCreateOrConnectWithoutCadastroInput = {
    create: UsuarioCadastroCamposCreateWithoutCadastroInput;
    where: UsuarioCadastroCamposWhereUniqueInput;
};

export type UsuarioCadastroCamposCreateWithoutCadastroInput = {
    campo: Scalars["String"];
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    valor?: InputMaybe<Scalars["String"]>;
};

export type UsuarioCadastroCamposGroupBy = {
    __typename?: "UsuarioCadastroCamposGroupBy";
    _avg?: Maybe<UsuarioCadastroCamposAvgAggregate>;
    _count?: Maybe<UsuarioCadastroCamposCountAggregate>;
    _max?: Maybe<UsuarioCadastroCamposMaxAggregate>;
    _min?: Maybe<UsuarioCadastroCamposMinAggregate>;
    _sum?: Maybe<UsuarioCadastroCamposSumAggregate>;
    cadastroId: Scalars["Int"];
    campo: Scalars["String"];
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
    valor?: Maybe<Scalars["String"]>;
};

export type UsuarioCadastroCamposListRelationFilter = {
    every?: InputMaybe<UsuarioCadastroCamposWhereInput>;
    none?: InputMaybe<UsuarioCadastroCamposWhereInput>;
    some?: InputMaybe<UsuarioCadastroCamposWhereInput>;
};

export type UsuarioCadastroCamposMaxAggregate = {
    __typename?: "UsuarioCadastroCamposMaxAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    campo?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    valor?: Maybe<Scalars["String"]>;
};

export type UsuarioCadastroCamposMaxOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type UsuarioCadastroCamposMinAggregate = {
    __typename?: "UsuarioCadastroCamposMinAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    campo?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    valor?: Maybe<Scalars["String"]>;
};

export type UsuarioCadastroCamposMinOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type UsuarioCadastroCamposOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type UsuarioCadastroCamposOrderByWithAggregationInput = {
    _avg?: InputMaybe<UsuarioCadastroCamposAvgOrderByAggregateInput>;
    _count?: InputMaybe<UsuarioCadastroCamposCountOrderByAggregateInput>;
    _max?: InputMaybe<UsuarioCadastroCamposMaxOrderByAggregateInput>;
    _min?: InputMaybe<UsuarioCadastroCamposMinOrderByAggregateInput>;
    _sum?: InputMaybe<UsuarioCadastroCamposSumOrderByAggregateInput>;
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export type UsuarioCadastroCamposOrderByWithRelationInput = {
    cadastro?: InputMaybe<UsuarioCadastroOrderByWithRelationInput>;
    cadastroId?: InputMaybe<SortOrder>;
    campo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    valor?: InputMaybe<SortOrder>;
};

export enum UsuarioCadastroCamposScalarFieldEnum {
    CadastroId = "cadastroId",
    Campo = "campo",
    CreatedAt = "createdAt",
    Id = "id",
    UpdatedAt = "updatedAt",
    Valor = "valor",
}

export type UsuarioCadastroCamposScalarWhereInput = {
    AND?: InputMaybe<Array<UsuarioCadastroCamposScalarWhereInput>>;
    NOT?: InputMaybe<Array<UsuarioCadastroCamposScalarWhereInput>>;
    OR?: InputMaybe<Array<UsuarioCadastroCamposScalarWhereInput>>;
    cadastroId?: InputMaybe<IntFilter>;
    campo?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    valor?: InputMaybe<StringNullableFilter>;
};

export type UsuarioCadastroCamposScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<UsuarioCadastroCamposScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<UsuarioCadastroCamposScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<UsuarioCadastroCamposScalarWhereWithAggregatesInput>>;
    cadastroId?: InputMaybe<IntWithAggregatesFilter>;
    campo?: InputMaybe<StringWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    valor?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type UsuarioCadastroCamposSumAggregate = {
    __typename?: "UsuarioCadastroCamposSumAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
};

export type UsuarioCadastroCamposSumOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
};

export type UsuarioCadastroCamposUpdateInput = {
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneRequiredWithoutCamposNestedInput>;
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    valor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UsuarioCadastroCamposUpdateManyMutationInput = {
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    valor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UsuarioCadastroCamposUpdateManyWithWhereWithoutCadastroInput = {
    data: UsuarioCadastroCamposUpdateManyMutationInput;
    where: UsuarioCadastroCamposScalarWhereInput;
};

export type UsuarioCadastroCamposUpdateManyWithoutCadastroNestedInput = {
    connect?: InputMaybe<Array<UsuarioCadastroCamposWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioCadastroCamposCreateOrConnectWithoutCadastroInput>>;
    create?: InputMaybe<Array<UsuarioCadastroCamposCreateWithoutCadastroInput>>;
    createMany?: InputMaybe<UsuarioCadastroCamposCreateManyCadastroInputEnvelope>;
    delete?: InputMaybe<Array<UsuarioCadastroCamposWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<UsuarioCadastroCamposScalarWhereInput>>;
    disconnect?: InputMaybe<Array<UsuarioCadastroCamposWhereUniqueInput>>;
    set?: InputMaybe<Array<UsuarioCadastroCamposWhereUniqueInput>>;
    update?: InputMaybe<Array<UsuarioCadastroCamposUpdateWithWhereUniqueWithoutCadastroInput>>;
    updateMany?: InputMaybe<Array<UsuarioCadastroCamposUpdateManyWithWhereWithoutCadastroInput>>;
    upsert?: InputMaybe<Array<UsuarioCadastroCamposUpsertWithWhereUniqueWithoutCadastroInput>>;
};

export type UsuarioCadastroCamposUpdateWithWhereUniqueWithoutCadastroInput = {
    data: UsuarioCadastroCamposUpdateWithoutCadastroInput;
    where: UsuarioCadastroCamposWhereUniqueInput;
};

export type UsuarioCadastroCamposUpdateWithoutCadastroInput = {
    campo?: InputMaybe<StringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    valor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UsuarioCadastroCamposUpsertWithWhereUniqueWithoutCadastroInput = {
    create: UsuarioCadastroCamposCreateWithoutCadastroInput;
    update: UsuarioCadastroCamposUpdateWithoutCadastroInput;
    where: UsuarioCadastroCamposWhereUniqueInput;
};

export type UsuarioCadastroCamposWhereInput = {
    AND?: InputMaybe<Array<UsuarioCadastroCamposWhereInput>>;
    NOT?: InputMaybe<Array<UsuarioCadastroCamposWhereInput>>;
    OR?: InputMaybe<Array<UsuarioCadastroCamposWhereInput>>;
    cadastro?: InputMaybe<UsuarioCadastroRelationFilter>;
    cadastroId?: InputMaybe<IntFilter>;
    campo?: InputMaybe<StringFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    valor?: InputMaybe<StringNullableFilter>;
};

export type UsuarioCadastroCamposWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type UsuarioCadastroCount = {
    __typename?: "UsuarioCadastroCount";
    campos: Scalars["Int"];
};

export type UsuarioCadastroCountAggregate = {
    __typename?: "UsuarioCadastroCountAggregate";
    _all: Scalars["Int"];
    createdAt: Scalars["Int"];
    id: Scalars["Int"];
    origem: Scalars["Int"];
    status: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type UsuarioCadastroCountOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type UsuarioCadastroCreateInput = {
    campos?: InputMaybe<UsuarioCadastroCamposCreateNestedManyWithoutCadastroInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    origem?: InputMaybe<TipoServico>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutCadastroInput>;
};

export type UsuarioCadastroCreateManyInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    id?: InputMaybe<Scalars["Int"]>;
    origem?: InputMaybe<TipoServico>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCadastroCreateNestedOneWithoutCamposInput = {
    connect?: InputMaybe<UsuarioCadastroWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCadastroCreateOrConnectWithoutCamposInput>;
    create?: InputMaybe<UsuarioCadastroCreateWithoutCamposInput>;
};

export type UsuarioCadastroCreateNestedOneWithoutUsuarioInput = {
    connect?: InputMaybe<UsuarioCadastroWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCadastroCreateOrConnectWithoutUsuarioInput>;
    create?: InputMaybe<UsuarioCadastroCreateWithoutUsuarioInput>;
};

export type UsuarioCadastroCreateOrConnectWithoutCamposInput = {
    create: UsuarioCadastroCreateWithoutCamposInput;
    where: UsuarioCadastroWhereUniqueInput;
};

export type UsuarioCadastroCreateOrConnectWithoutUsuarioInput = {
    create: UsuarioCadastroCreateWithoutUsuarioInput;
    where: UsuarioCadastroWhereUniqueInput;
};

export type UsuarioCadastroCreateWithoutCamposInput = {
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    origem?: InputMaybe<TipoServico>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
    usuario?: InputMaybe<UsuarioCreateNestedOneWithoutCadastroInput>;
};

export type UsuarioCadastroCreateWithoutUsuarioInput = {
    campos?: InputMaybe<UsuarioCadastroCamposCreateNestedManyWithoutCadastroInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    origem?: InputMaybe<TipoServico>;
    status?: InputMaybe<CadastroStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCadastroGroupBy = {
    __typename?: "UsuarioCadastroGroupBy";
    _avg?: Maybe<UsuarioCadastroAvgAggregate>;
    _count?: Maybe<UsuarioCadastroCountAggregate>;
    _max?: Maybe<UsuarioCadastroMaxAggregate>;
    _min?: Maybe<UsuarioCadastroMinAggregate>;
    _sum?: Maybe<UsuarioCadastroSumAggregate>;
    createdAt: Scalars["DateTime"];
    id: Scalars["Int"];
    origem: TipoServico;
    status: CadastroStatus;
    updatedAt: Scalars["DateTime"];
};

export type UsuarioCadastroMaxAggregate = {
    __typename?: "UsuarioCadastroMaxAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    origem?: Maybe<TipoServico>;
    status?: Maybe<CadastroStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type UsuarioCadastroMaxOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type UsuarioCadastroMinAggregate = {
    __typename?: "UsuarioCadastroMinAggregate";
    createdAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["Int"]>;
    origem?: Maybe<TipoServico>;
    status?: Maybe<CadastroStatus>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type UsuarioCadastroMinOrderByAggregateInput = {
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type UsuarioCadastroOrderByWithAggregationInput = {
    _avg?: InputMaybe<UsuarioCadastroAvgOrderByAggregateInput>;
    _count?: InputMaybe<UsuarioCadastroCountOrderByAggregateInput>;
    _max?: InputMaybe<UsuarioCadastroMaxOrderByAggregateInput>;
    _min?: InputMaybe<UsuarioCadastroMinOrderByAggregateInput>;
    _sum?: InputMaybe<UsuarioCadastroSumOrderByAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type UsuarioCadastroOrderByWithRelationInput = {
    campos?: InputMaybe<UsuarioCadastroCamposOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    origem?: InputMaybe<SortOrder>;
    status?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
    usuario?: InputMaybe<UsuarioOrderByWithRelationInput>;
};

export type UsuarioCadastroRelationFilter = {
    is?: InputMaybe<UsuarioCadastroWhereInput>;
    isNot?: InputMaybe<UsuarioCadastroWhereInput>;
};

export enum UsuarioCadastroScalarFieldEnum {
    CreatedAt = "createdAt",
    Id = "id",
    Origem = "origem",
    Status = "status",
    UpdatedAt = "updatedAt",
}

export type UsuarioCadastroScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<UsuarioCadastroScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<UsuarioCadastroScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<UsuarioCadastroScalarWhereWithAggregatesInput>>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    origem?: InputMaybe<EnumTipoServicoWithAggregatesFilter>;
    status?: InputMaybe<EnumCadastroStatusWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type UsuarioCadastroSumAggregate = {
    __typename?: "UsuarioCadastroSumAggregate";
    id?: Maybe<Scalars["Int"]>;
};

export type UsuarioCadastroSumOrderByAggregateInput = {
    id?: InputMaybe<SortOrder>;
};

export type UsuarioCadastroUpdateInput = {
    campos?: InputMaybe<UsuarioCadastroCamposUpdateManyWithoutCadastroNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutCadastroNestedInput>;
};

export type UsuarioCadastroUpdateManyMutationInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioCadastroUpdateOneRequiredWithoutCamposNestedInput = {
    connect?: InputMaybe<UsuarioCadastroWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCadastroCreateOrConnectWithoutCamposInput>;
    create?: InputMaybe<UsuarioCadastroCreateWithoutCamposInput>;
    update?: InputMaybe<UsuarioCadastroUpdateWithoutCamposInput>;
    upsert?: InputMaybe<UsuarioCadastroUpsertWithoutCamposInput>;
};

export type UsuarioCadastroUpdateOneWithoutUsuarioNestedInput = {
    connect?: InputMaybe<UsuarioCadastroWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCadastroCreateOrConnectWithoutUsuarioInput>;
    create?: InputMaybe<UsuarioCadastroCreateWithoutUsuarioInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioCadastroUpdateWithoutUsuarioInput>;
    upsert?: InputMaybe<UsuarioCadastroUpsertWithoutUsuarioInput>;
};

export type UsuarioCadastroUpdateWithoutCamposInput = {
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    usuario?: InputMaybe<UsuarioUpdateOneWithoutCadastroNestedInput>;
};

export type UsuarioCadastroUpdateWithoutUsuarioInput = {
    campos?: InputMaybe<UsuarioCadastroCamposUpdateManyWithoutCadastroNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    origem?: InputMaybe<EnumTipoServicoFieldUpdateOperationsInput>;
    status?: InputMaybe<EnumCadastroStatusFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioCadastroUpsertWithoutCamposInput = {
    create: UsuarioCadastroCreateWithoutCamposInput;
    update: UsuarioCadastroUpdateWithoutCamposInput;
};

export type UsuarioCadastroUpsertWithoutUsuarioInput = {
    create: UsuarioCadastroCreateWithoutUsuarioInput;
    update: UsuarioCadastroUpdateWithoutUsuarioInput;
};

export type UsuarioCadastroWhereInput = {
    AND?: InputMaybe<Array<UsuarioCadastroWhereInput>>;
    NOT?: InputMaybe<Array<UsuarioCadastroWhereInput>>;
    OR?: InputMaybe<Array<UsuarioCadastroWhereInput>>;
    campos?: InputMaybe<UsuarioCadastroCamposListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    id?: InputMaybe<IntFilter>;
    origem?: InputMaybe<EnumTipoServicoFilter>;
    status?: InputMaybe<EnumCadastroStatusFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
    usuario?: InputMaybe<UsuarioRelationFilter>;
};

export type UsuarioCadastroWhereUniqueInput = {
    id?: InputMaybe<Scalars["Int"]>;
};

export type UsuarioCount = {
    __typename?: "UsuarioCount";
    ComercialPartnerAudit: Scalars["Int"];
    ContatoAudit: Scalars["Int"];
    DebenturistaAudit: Scalars["Int"];
    KeyAccount: Scalars["Int"];
    OccupationAudit: Scalars["Int"];
    Salesman: Scalars["Int"];
    acceptedTerms: Scalars["Int"];
    alteracaoCedentes: Scalars["Int"];
    alteracaoEnderecoCedentes: Scalars["Int"];
    arquivos: Scalars["Int"];
    cadastroCedentes: Scalars["Int"];
    cadastroEndereco: Scalars["Int"];
    camposAlteracaoCedentes: Scalars["Int"];
    camposAlteracaoEnderecoCedentes: Scalars["Int"];
    camposCadastroCedentes: Scalars["Int"];
    camposCadastroEndereco: Scalars["Int"];
    cedentes: Scalars["Int"];
    histArquivo: Scalars["Int"];
    histCedente: Scalars["Int"];
    histEmpresa: Scalars["Int"];
    histEndereco: Scalars["Int"];
    histMinibanco: Scalars["Int"];
    histOperacao: Scalars["Int"];
    histRecebivel: Scalars["Int"];
    histSacado: Scalars["Int"];
    histStatusOperacional: Scalars["Int"];
    histUsuario: Scalars["Int"];
    historico: Scalars["Int"];
    indPermissoes: Scalars["Int"];
    minibanksWallet: Scalars["Int"];
    notificacoes: Scalars["Int"];
    occupations: Scalars["Int"];
    permissoes: Scalars["Int"];
    recebiveis: Scalars["Int"];
    respContato: Scalars["Int"];
    respGrupoNotificacao: Scalars["Int"];
    respGrupoPermissao: Scalars["Int"];
};

export type UsuarioCountAggregate = {
    __typename?: "UsuarioCountAggregate";
    _all: Scalars["Int"];
    cadastroId: Scalars["Int"];
    cargo: Scalars["Int"];
    createdAt: Scalars["Int"];
    email: Scalars["Int"];
    firstName: Scalars["Int"];
    fone: Scalars["Int"];
    id: Scalars["Int"];
    inativo: Scalars["Int"];
    isAdmin: Scalars["Int"];
    lastName: Scalars["Int"];
    minibancoId: Scalars["Int"];
    senha: Scalars["Int"];
    updatedAt: Scalars["Int"];
};

export type UsuarioCountOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    cargo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    firstName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    isAdmin?: InputMaybe<SortOrder>;
    lastName?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    senha?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type UsuarioCreateInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateManyInput = {
    cadastroId?: InputMaybe<Scalars["Int"]>;
    cargo?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibancoId?: InputMaybe<Scalars["Int"]>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateManyMinibancoInput = {
    cadastroId?: InputMaybe<Scalars["Int"]>;
    cargo?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateManyMinibancoInputEnvelope = {
    data: Array<UsuarioCreateManyMinibancoInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type UsuarioCreateNestedManyWithoutMinibancoInput = {
    connect?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<UsuarioCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<UsuarioCreateManyMinibancoInputEnvelope>;
};

export type UsuarioCreateNestedManyWithoutNotificacoesInput = {
    connect?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioCreateOrConnectWithoutNotificacoesInput>>;
    create?: InputMaybe<Array<UsuarioCreateWithoutNotificacoesInput>>;
};

export type UsuarioCreateNestedManyWithoutOccupationsInput = {
    connect?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioCreateOrConnectWithoutOccupationsInput>>;
    create?: InputMaybe<Array<UsuarioCreateWithoutOccupationsInput>>;
};

export type UsuarioCreateNestedManyWithoutPermissoesInput = {
    connect?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioCreateOrConnectWithoutPermissoesInput>>;
    create?: InputMaybe<Array<UsuarioCreateWithoutPermissoesInput>>;
};

export type UsuarioCreateNestedOneWithoutAcceptedTermsInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutAcceptedTermsInput>;
    create?: InputMaybe<UsuarioCreateWithoutAcceptedTermsInput>;
};

export type UsuarioCreateNestedOneWithoutAlteracaoCedentesInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutAlteracaoCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutAlteracaoCedentesInput>;
};

export type UsuarioCreateNestedOneWithoutAlteracaoEnderecoCedentesInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutAlteracaoEnderecoCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutAlteracaoEnderecoCedentesInput>;
};

export type UsuarioCreateNestedOneWithoutArquivosInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutArquivosInput>;
    create?: InputMaybe<UsuarioCreateWithoutArquivosInput>;
};

export type UsuarioCreateNestedOneWithoutCadastroCedentesInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCadastroCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutCadastroCedentesInput>;
};

export type UsuarioCreateNestedOneWithoutCadastroEnderecoInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCadastroEnderecoInput>;
    create?: InputMaybe<UsuarioCreateWithoutCadastroEnderecoInput>;
};

export type UsuarioCreateNestedOneWithoutCadastroInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCadastroInput>;
    create?: InputMaybe<UsuarioCreateWithoutCadastroInput>;
};

export type UsuarioCreateNestedOneWithoutCamposAlteracaoCedentesInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCamposAlteracaoCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutCamposAlteracaoCedentesInput>;
};

export type UsuarioCreateNestedOneWithoutCamposAlteracaoEnderecoCedentesInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCamposAlteracaoEnderecoCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutCamposAlteracaoEnderecoCedentesInput>;
};

export type UsuarioCreateNestedOneWithoutCamposCadastroCedentesInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCamposCadastroCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutCamposCadastroCedentesInput>;
};

export type UsuarioCreateNestedOneWithoutCamposCadastroEnderecoInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCamposCadastroEnderecoInput>;
    create?: InputMaybe<UsuarioCreateWithoutCamposCadastroEnderecoInput>;
};

export type UsuarioCreateNestedOneWithoutCedentesInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutCedentesInput>;
};

export type UsuarioCreateNestedOneWithoutComercialPartnerAuditInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutComercialPartnerAuditInput>;
    create?: InputMaybe<UsuarioCreateWithoutComercialPartnerAuditInput>;
};

export type UsuarioCreateNestedOneWithoutContatoAuditInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutContatoAuditInput>;
    create?: InputMaybe<UsuarioCreateWithoutContatoAuditInput>;
};

export type UsuarioCreateNestedOneWithoutDebenturistaAuditInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutDebenturistaAuditInput>;
    create?: InputMaybe<UsuarioCreateWithoutDebenturistaAuditInput>;
};

export type UsuarioCreateNestedOneWithoutHistArquivoInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistArquivoInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistArquivoInput>;
};

export type UsuarioCreateNestedOneWithoutHistCedenteInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistCedenteInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistCedenteInput>;
};

export type UsuarioCreateNestedOneWithoutHistEmpresaInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistEmpresaInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistEmpresaInput>;
};

export type UsuarioCreateNestedOneWithoutHistEnderecoInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistEnderecoInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistEnderecoInput>;
};

export type UsuarioCreateNestedOneWithoutHistMinibancoInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistMinibancoInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistMinibancoInput>;
};

export type UsuarioCreateNestedOneWithoutHistOperacaoInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistOperacaoInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistOperacaoInput>;
};

export type UsuarioCreateNestedOneWithoutHistRecebivelInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistRecebivelInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistRecebivelInput>;
};

export type UsuarioCreateNestedOneWithoutHistSacadoInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistSacadoInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistSacadoInput>;
};

export type UsuarioCreateNestedOneWithoutHistStatusOperacionalInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistStatusOperacionalInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistStatusOperacionalInput>;
};

export type UsuarioCreateNestedOneWithoutHistUsuarioInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistUsuarioInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistUsuarioInput>;
};

export type UsuarioCreateNestedOneWithoutHistoricoInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistoricoInput>;
};

export type UsuarioCreateNestedOneWithoutIndPermissoesInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutIndPermissoesInput>;
    create?: InputMaybe<UsuarioCreateWithoutIndPermissoesInput>;
};

export type UsuarioCreateNestedOneWithoutKeyAccountInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutKeyAccountInput>;
    create?: InputMaybe<UsuarioCreateWithoutKeyAccountInput>;
};

export type UsuarioCreateNestedOneWithoutMinibanksWalletInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutMinibanksWalletInput>;
    create?: InputMaybe<UsuarioCreateWithoutMinibanksWalletInput>;
};

export type UsuarioCreateNestedOneWithoutOccupationAuditInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutOccupationAuditInput>;
    create?: InputMaybe<UsuarioCreateWithoutOccupationAuditInput>;
};

export type UsuarioCreateNestedOneWithoutRecebiveisInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutRecebiveisInput>;
    create?: InputMaybe<UsuarioCreateWithoutRecebiveisInput>;
};

export type UsuarioCreateNestedOneWithoutRespContatoInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutRespContatoInput>;
    create?: InputMaybe<UsuarioCreateWithoutRespContatoInput>;
};

export type UsuarioCreateNestedOneWithoutRespGrupoNotificacaoInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutRespGrupoNotificacaoInput>;
    create?: InputMaybe<UsuarioCreateWithoutRespGrupoNotificacaoInput>;
};

export type UsuarioCreateNestedOneWithoutRespGrupoPermissaoInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutRespGrupoPermissaoInput>;
    create?: InputMaybe<UsuarioCreateWithoutRespGrupoPermissaoInput>;
};

export type UsuarioCreateNestedOneWithoutSalesmanInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutSalesmanInput>;
    create?: InputMaybe<UsuarioCreateWithoutSalesmanInput>;
};

export type UsuarioCreateOrConnectWithoutAcceptedTermsInput = {
    create: UsuarioCreateWithoutAcceptedTermsInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutAlteracaoCedentesInput = {
    create: UsuarioCreateWithoutAlteracaoCedentesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutAlteracaoEnderecoCedentesInput = {
    create: UsuarioCreateWithoutAlteracaoEnderecoCedentesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutArquivosInput = {
    create: UsuarioCreateWithoutArquivosInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutCadastroCedentesInput = {
    create: UsuarioCreateWithoutCadastroCedentesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutCadastroEnderecoInput = {
    create: UsuarioCreateWithoutCadastroEnderecoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutCadastroInput = {
    create: UsuarioCreateWithoutCadastroInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutCamposAlteracaoCedentesInput = {
    create: UsuarioCreateWithoutCamposAlteracaoCedentesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutCamposAlteracaoEnderecoCedentesInput = {
    create: UsuarioCreateWithoutCamposAlteracaoEnderecoCedentesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutCamposCadastroCedentesInput = {
    create: UsuarioCreateWithoutCamposCadastroCedentesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutCamposCadastroEnderecoInput = {
    create: UsuarioCreateWithoutCamposCadastroEnderecoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutCedentesInput = {
    create: UsuarioCreateWithoutCedentesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutComercialPartnerAuditInput = {
    create: UsuarioCreateWithoutComercialPartnerAuditInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutContatoAuditInput = {
    create: UsuarioCreateWithoutContatoAuditInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutDebenturistaAuditInput = {
    create: UsuarioCreateWithoutDebenturistaAuditInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutHistArquivoInput = {
    create: UsuarioCreateWithoutHistArquivoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutHistCedenteInput = {
    create: UsuarioCreateWithoutHistCedenteInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutHistEmpresaInput = {
    create: UsuarioCreateWithoutHistEmpresaInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutHistEnderecoInput = {
    create: UsuarioCreateWithoutHistEnderecoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutHistMinibancoInput = {
    create: UsuarioCreateWithoutHistMinibancoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutHistOperacaoInput = {
    create: UsuarioCreateWithoutHistOperacaoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutHistRecebivelInput = {
    create: UsuarioCreateWithoutHistRecebivelInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutHistSacadoInput = {
    create: UsuarioCreateWithoutHistSacadoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutHistStatusOperacionalInput = {
    create: UsuarioCreateWithoutHistStatusOperacionalInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutHistUsuarioInput = {
    create: UsuarioCreateWithoutHistUsuarioInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutHistoricoInput = {
    create: UsuarioCreateWithoutHistoricoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutIndPermissoesInput = {
    create: UsuarioCreateWithoutIndPermissoesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutKeyAccountInput = {
    create: UsuarioCreateWithoutKeyAccountInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutMinibancoInput = {
    create: UsuarioCreateWithoutMinibancoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutMinibanksWalletInput = {
    create: UsuarioCreateWithoutMinibanksWalletInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutNotificacoesInput = {
    create: UsuarioCreateWithoutNotificacoesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutOccupationAuditInput = {
    create: UsuarioCreateWithoutOccupationAuditInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutOccupationsInput = {
    create: UsuarioCreateWithoutOccupationsInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutPermissoesInput = {
    create: UsuarioCreateWithoutPermissoesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutRecebiveisInput = {
    create: UsuarioCreateWithoutRecebiveisInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutRespContatoInput = {
    create: UsuarioCreateWithoutRespContatoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutRespGrupoNotificacaoInput = {
    create: UsuarioCreateWithoutRespGrupoNotificacaoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutRespGrupoPermissaoInput = {
    create: UsuarioCreateWithoutRespGrupoPermissaoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateOrConnectWithoutSalesmanInput = {
    create: UsuarioCreateWithoutSalesmanInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioCreateWithoutAcceptedTermsInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutAlteracaoCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutAlteracaoEnderecoCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutArquivosInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutCadastroCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutCadastroEnderecoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutCadastroInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutCamposAlteracaoCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutCamposAlteracaoEnderecoCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutCamposCadastroCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutCamposCadastroEnderecoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutComercialPartnerAuditInput = {
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutContatoAuditInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutDebenturistaAuditInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutHistArquivoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutHistCedenteInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutHistEmpresaInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutHistEnderecoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutHistMinibancoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutHistOperacaoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutHistRecebivelInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutHistSacadoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutHistStatusOperacionalInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutHistUsuarioInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutHistoricoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutIndPermissoesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutKeyAccountInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutMinibancoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutMinibanksWalletInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutNotificacoesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutOccupationAuditInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutOccupationsInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutPermissoesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutRecebiveisInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutRespContatoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutRespGrupoNotificacaoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutRespGrupoPermissaoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    Salesman?: InputMaybe<OperacaoCreateNestedManyWithoutSalesmanInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioCreateWithoutSalesmanInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditCreateNestedManyWithoutUserInput>;
    ContatoAudit?: InputMaybe<ContatoAuditCreateNestedManyWithoutUsuarioInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditCreateNestedManyWithoutUsuarioInput>;
    KeyAccount?: InputMaybe<OperacaoCreateNestedManyWithoutKeyAccountInput>;
    OccupationAudit?: InputMaybe<OccupationAuditCreateNestedManyWithoutUserInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsCreateNestedManyWithoutUserInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoCreateNestedManyWithoutUsuarioInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoCreateNestedManyWithoutUsuarioInput>;
    arquivos?: InputMaybe<ArquivoCreateNestedManyWithoutUsuarioInput>;
    cadastro?: InputMaybe<UsuarioCadastroCreateNestedOneWithoutUsuarioInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroCreateNestedManyWithoutUsuarioInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroCreateNestedManyWithoutUsuarioInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoCreateNestedManyWithoutResponsavelInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroCreateNestedManyWithoutResponsavelInput>;
    cargo?: InputMaybe<Scalars["String"]>;
    cedentes?: InputMaybe<ResponsavelCedenteCreateNestedManyWithoutUsuarioInput>;
    createdAt?: InputMaybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    firstName?: InputMaybe<Scalars["String"]>;
    fone?: InputMaybe<Scalars["String"]>;
    histArquivo?: InputMaybe<ArquivoAuditCreateNestedManyWithoutUsuarioInput>;
    histCedente?: InputMaybe<CedenteAuditCreateNestedManyWithoutUsuarioInput>;
    histEmpresa?: InputMaybe<EmpresaAuditCreateNestedManyWithoutUsuarioInput>;
    histEndereco?: InputMaybe<EnderecoAuditCreateNestedManyWithoutUsuarioInput>;
    histMinibanco?: InputMaybe<MinibancoAuditCreateNestedManyWithoutUsuarioInput>;
    histOperacao?: InputMaybe<OperacaoAuditCreateNestedManyWithoutUsuarioInput>;
    histRecebivel?: InputMaybe<RecebivelAuditCreateNestedManyWithoutUsuarioInput>;
    histSacado?: InputMaybe<SacadoAuditCreateNestedManyWithoutUsuarioInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditCreateNestedManyWithoutUsuarioInput>;
    histUsuario?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioInput>;
    historico?: InputMaybe<UsuarioAuditCreateNestedManyWithoutUsuarioModInput>;
    inativo?: InputMaybe<Scalars["Boolean"]>;
    indPermissoes?: InputMaybe<NivelPermissaoCreateNestedManyWithoutUsuarioInput>;
    isAdmin?: InputMaybe<Scalars["Boolean"]>;
    lastName?: InputMaybe<Scalars["String"]>;
    minibanco?: InputMaybe<MinibancoCreateNestedOneWithoutUsuariosInput>;
    minibanksWallet?: InputMaybe<MinibancoCreateNestedManyWithoutKeyAccountInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutUsuariosInput>;
    occupations?: InputMaybe<OccupationCreateNestedManyWithoutUsersInput>;
    permissoes?: InputMaybe<GruposPermissaoCreateNestedManyWithoutUsuariosInput>;
    recebiveis?: InputMaybe<RecebivelCreateNestedManyWithoutUsuarioInput>;
    respContato?: InputMaybe<ContatoCreateNestedManyWithoutResponsavelInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoCreateNestedManyWithoutResponsavelInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoCreateNestedManyWithoutResponsavelInput>;
    senha: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UsuarioGroupBy = {
    __typename?: "UsuarioGroupBy";
    _avg?: Maybe<UsuarioAvgAggregate>;
    _count?: Maybe<UsuarioCountAggregate>;
    _max?: Maybe<UsuarioMaxAggregate>;
    _min?: Maybe<UsuarioMinAggregate>;
    _sum?: Maybe<UsuarioSumAggregate>;
    cadastroId?: Maybe<Scalars["Int"]>;
    cargo?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    email: Scalars["String"];
    firstName?: Maybe<Scalars["String"]>;
    fone?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    inativo: Scalars["Boolean"];
    isAdmin: Scalars["Boolean"];
    lastName?: Maybe<Scalars["String"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    senha: Scalars["String"];
    updatedAt: Scalars["DateTime"];
};

export type UsuarioListRelationFilter = {
    every?: InputMaybe<UsuarioWhereInput>;
    none?: InputMaybe<UsuarioWhereInput>;
    some?: InputMaybe<UsuarioWhereInput>;
};

export type UsuarioMaxAggregate = {
    __typename?: "UsuarioMaxAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    cargo?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    email?: Maybe<Scalars["String"]>;
    firstName?: Maybe<Scalars["String"]>;
    fone?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    isAdmin?: Maybe<Scalars["Boolean"]>;
    lastName?: Maybe<Scalars["String"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    senha?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type UsuarioMaxOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    cargo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    firstName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    isAdmin?: InputMaybe<SortOrder>;
    lastName?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    senha?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type UsuarioMinAggregate = {
    __typename?: "UsuarioMinAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    cargo?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    email?: Maybe<Scalars["String"]>;
    firstName?: Maybe<Scalars["String"]>;
    fone?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["Int"]>;
    inativo?: Maybe<Scalars["Boolean"]>;
    isAdmin?: Maybe<Scalars["Boolean"]>;
    lastName?: Maybe<Scalars["String"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
    senha?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type UsuarioMinOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    cargo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    firstName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    isAdmin?: InputMaybe<SortOrder>;
    lastName?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    senha?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type UsuarioOrderByRelationAggregateInput = {
    _count?: InputMaybe<SortOrder>;
};

export type UsuarioOrderByWithAggregationInput = {
    _avg?: InputMaybe<UsuarioAvgOrderByAggregateInput>;
    _count?: InputMaybe<UsuarioCountOrderByAggregateInput>;
    _max?: InputMaybe<UsuarioMaxOrderByAggregateInput>;
    _min?: InputMaybe<UsuarioMinOrderByAggregateInput>;
    _sum?: InputMaybe<UsuarioSumOrderByAggregateInput>;
    cadastroId?: InputMaybe<SortOrder>;
    cargo?: InputMaybe<SortOrder>;
    createdAt?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    firstName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    isAdmin?: InputMaybe<SortOrder>;
    lastName?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
    senha?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type UsuarioOrderByWithRelationInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditOrderByRelationAggregateInput>;
    ContatoAudit?: InputMaybe<ContatoAuditOrderByRelationAggregateInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditOrderByRelationAggregateInput>;
    KeyAccount?: InputMaybe<OperacaoOrderByRelationAggregateInput>;
    OccupationAudit?: InputMaybe<OccupationAuditOrderByRelationAggregateInput>;
    Salesman?: InputMaybe<OperacaoOrderByRelationAggregateInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsOrderByRelationAggregateInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoOrderByRelationAggregateInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoOrderByRelationAggregateInput>;
    arquivos?: InputMaybe<ArquivoOrderByRelationAggregateInput>;
    cadastro?: InputMaybe<UsuarioCadastroOrderByWithRelationInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroOrderByRelationAggregateInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroOrderByRelationAggregateInput>;
    cadastroId?: InputMaybe<SortOrder>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoOrderByRelationAggregateInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoOrderByRelationAggregateInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroOrderByRelationAggregateInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroOrderByRelationAggregateInput>;
    cargo?: InputMaybe<SortOrder>;
    cedentes?: InputMaybe<ResponsavelCedenteOrderByRelationAggregateInput>;
    createdAt?: InputMaybe<SortOrder>;
    email?: InputMaybe<SortOrder>;
    firstName?: InputMaybe<SortOrder>;
    fone?: InputMaybe<SortOrder>;
    histArquivo?: InputMaybe<ArquivoAuditOrderByRelationAggregateInput>;
    histCedente?: InputMaybe<CedenteAuditOrderByRelationAggregateInput>;
    histEmpresa?: InputMaybe<EmpresaAuditOrderByRelationAggregateInput>;
    histEndereco?: InputMaybe<EnderecoAuditOrderByRelationAggregateInput>;
    histMinibanco?: InputMaybe<MinibancoAuditOrderByRelationAggregateInput>;
    histOperacao?: InputMaybe<OperacaoAuditOrderByRelationAggregateInput>;
    histRecebivel?: InputMaybe<RecebivelAuditOrderByRelationAggregateInput>;
    histSacado?: InputMaybe<SacadoAuditOrderByRelationAggregateInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditOrderByRelationAggregateInput>;
    histUsuario?: InputMaybe<UsuarioAuditOrderByRelationAggregateInput>;
    historico?: InputMaybe<UsuarioAuditOrderByRelationAggregateInput>;
    id?: InputMaybe<SortOrder>;
    inativo?: InputMaybe<SortOrder>;
    indPermissoes?: InputMaybe<NivelPermissaoOrderByRelationAggregateInput>;
    isAdmin?: InputMaybe<SortOrder>;
    lastName?: InputMaybe<SortOrder>;
    minibanco?: InputMaybe<MinibancoOrderByWithRelationInput>;
    minibancoId?: InputMaybe<SortOrder>;
    minibanksWallet?: InputMaybe<MinibancoOrderByRelationAggregateInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoOrderByRelationAggregateInput>;
    occupations?: InputMaybe<OccupationOrderByRelationAggregateInput>;
    permissoes?: InputMaybe<GruposPermissaoOrderByRelationAggregateInput>;
    recebiveis?: InputMaybe<RecebivelOrderByRelationAggregateInput>;
    respContato?: InputMaybe<ContatoOrderByRelationAggregateInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoOrderByRelationAggregateInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoOrderByRelationAggregateInput>;
    senha?: InputMaybe<SortOrder>;
    updatedAt?: InputMaybe<SortOrder>;
};

export type UsuarioRelationFilter = {
    is?: InputMaybe<UsuarioWhereInput>;
    isNot?: InputMaybe<UsuarioWhereInput>;
};

export enum UsuarioScalarFieldEnum {
    CadastroId = "cadastroId",
    Cargo = "cargo",
    CreatedAt = "createdAt",
    Email = "email",
    FirstName = "firstName",
    Fone = "fone",
    Id = "id",
    Inativo = "inativo",
    IsAdmin = "isAdmin",
    LastName = "lastName",
    MinibancoId = "minibancoId",
    Senha = "senha",
    UpdatedAt = "updatedAt",
}

export type UsuarioScalarWhereInput = {
    AND?: InputMaybe<Array<UsuarioScalarWhereInput>>;
    NOT?: InputMaybe<Array<UsuarioScalarWhereInput>>;
    OR?: InputMaybe<Array<UsuarioScalarWhereInput>>;
    cadastroId?: InputMaybe<IntNullableFilter>;
    cargo?: InputMaybe<StringNullableFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    email?: InputMaybe<StringFilter>;
    firstName?: InputMaybe<StringNullableFilter>;
    fone?: InputMaybe<StringNullableFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    isAdmin?: InputMaybe<BoolFilter>;
    lastName?: InputMaybe<StringNullableFilter>;
    minibancoId?: InputMaybe<IntNullableFilter>;
    senha?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UsuarioScalarWhereWithAggregatesInput = {
    AND?: InputMaybe<Array<UsuarioScalarWhereWithAggregatesInput>>;
    NOT?: InputMaybe<Array<UsuarioScalarWhereWithAggregatesInput>>;
    OR?: InputMaybe<Array<UsuarioScalarWhereWithAggregatesInput>>;
    cadastroId?: InputMaybe<IntNullableWithAggregatesFilter>;
    cargo?: InputMaybe<StringNullableWithAggregatesFilter>;
    createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
    email?: InputMaybe<StringWithAggregatesFilter>;
    firstName?: InputMaybe<StringNullableWithAggregatesFilter>;
    fone?: InputMaybe<StringNullableWithAggregatesFilter>;
    id?: InputMaybe<IntWithAggregatesFilter>;
    inativo?: InputMaybe<BoolWithAggregatesFilter>;
    isAdmin?: InputMaybe<BoolWithAggregatesFilter>;
    lastName?: InputMaybe<StringNullableWithAggregatesFilter>;
    minibancoId?: InputMaybe<IntNullableWithAggregatesFilter>;
    senha?: InputMaybe<StringWithAggregatesFilter>;
    updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type UsuarioSumAggregate = {
    __typename?: "UsuarioSumAggregate";
    cadastroId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["Int"]>;
    minibancoId?: Maybe<Scalars["Int"]>;
};

export type UsuarioSumOrderByAggregateInput = {
    cadastroId?: InputMaybe<SortOrder>;
    id?: InputMaybe<SortOrder>;
    minibancoId?: InputMaybe<SortOrder>;
};

export type UsuarioUpdateInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateManyMutationInput = {
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateManyWithWhereWithoutMinibancoInput = {
    data: UsuarioUpdateManyMutationInput;
    where: UsuarioScalarWhereInput;
};

export type UsuarioUpdateManyWithWhereWithoutNotificacoesInput = {
    data: UsuarioUpdateManyMutationInput;
    where: UsuarioScalarWhereInput;
};

export type UsuarioUpdateManyWithWhereWithoutOccupationsInput = {
    data: UsuarioUpdateManyMutationInput;
    where: UsuarioScalarWhereInput;
};

export type UsuarioUpdateManyWithWhereWithoutPermissoesInput = {
    data: UsuarioUpdateManyMutationInput;
    where: UsuarioScalarWhereInput;
};

export type UsuarioUpdateManyWithoutMinibancoNestedInput = {
    connect?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioCreateOrConnectWithoutMinibancoInput>>;
    create?: InputMaybe<Array<UsuarioCreateWithoutMinibancoInput>>;
    createMany?: InputMaybe<UsuarioCreateManyMinibancoInputEnvelope>;
    delete?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<UsuarioScalarWhereInput>>;
    disconnect?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    set?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    update?: InputMaybe<Array<UsuarioUpdateWithWhereUniqueWithoutMinibancoInput>>;
    updateMany?: InputMaybe<Array<UsuarioUpdateManyWithWhereWithoutMinibancoInput>>;
    upsert?: InputMaybe<Array<UsuarioUpsertWithWhereUniqueWithoutMinibancoInput>>;
};

export type UsuarioUpdateManyWithoutNotificacoesNestedInput = {
    connect?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioCreateOrConnectWithoutNotificacoesInput>>;
    create?: InputMaybe<Array<UsuarioCreateWithoutNotificacoesInput>>;
    delete?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<UsuarioScalarWhereInput>>;
    disconnect?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    set?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    update?: InputMaybe<Array<UsuarioUpdateWithWhereUniqueWithoutNotificacoesInput>>;
    updateMany?: InputMaybe<Array<UsuarioUpdateManyWithWhereWithoutNotificacoesInput>>;
    upsert?: InputMaybe<Array<UsuarioUpsertWithWhereUniqueWithoutNotificacoesInput>>;
};

export type UsuarioUpdateManyWithoutOccupationsNestedInput = {
    connect?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioCreateOrConnectWithoutOccupationsInput>>;
    create?: InputMaybe<Array<UsuarioCreateWithoutOccupationsInput>>;
    delete?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<UsuarioScalarWhereInput>>;
    disconnect?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    set?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    update?: InputMaybe<Array<UsuarioUpdateWithWhereUniqueWithoutOccupationsInput>>;
    updateMany?: InputMaybe<Array<UsuarioUpdateManyWithWhereWithoutOccupationsInput>>;
    upsert?: InputMaybe<Array<UsuarioUpsertWithWhereUniqueWithoutOccupationsInput>>;
};

export type UsuarioUpdateManyWithoutPermissoesNestedInput = {
    connect?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<Array<UsuarioCreateOrConnectWithoutPermissoesInput>>;
    create?: InputMaybe<Array<UsuarioCreateWithoutPermissoesInput>>;
    delete?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<UsuarioScalarWhereInput>>;
    disconnect?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    set?: InputMaybe<Array<UsuarioWhereUniqueInput>>;
    update?: InputMaybe<Array<UsuarioUpdateWithWhereUniqueWithoutPermissoesInput>>;
    updateMany?: InputMaybe<Array<UsuarioUpdateManyWithWhereWithoutPermissoesInput>>;
    upsert?: InputMaybe<Array<UsuarioUpsertWithWhereUniqueWithoutPermissoesInput>>;
};

export type UsuarioUpdateOneRequiredWithoutAcceptedTermsNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutAcceptedTermsInput>;
    create?: InputMaybe<UsuarioCreateWithoutAcceptedTermsInput>;
    update?: InputMaybe<UsuarioUpdateWithoutAcceptedTermsInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutAcceptedTermsInput>;
};

export type UsuarioUpdateOneRequiredWithoutAlteracaoCedentesNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutAlteracaoCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutAlteracaoCedentesInput>;
    update?: InputMaybe<UsuarioUpdateWithoutAlteracaoCedentesInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutAlteracaoCedentesInput>;
};

export type UsuarioUpdateOneRequiredWithoutAlteracaoEnderecoCedentesNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutAlteracaoEnderecoCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutAlteracaoEnderecoCedentesInput>;
    update?: InputMaybe<UsuarioUpdateWithoutAlteracaoEnderecoCedentesInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutAlteracaoEnderecoCedentesInput>;
};

export type UsuarioUpdateOneRequiredWithoutArquivosNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutArquivosInput>;
    create?: InputMaybe<UsuarioCreateWithoutArquivosInput>;
    update?: InputMaybe<UsuarioUpdateWithoutArquivosInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutArquivosInput>;
};

export type UsuarioUpdateOneRequiredWithoutCamposAlteracaoCedentesNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCamposAlteracaoCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutCamposAlteracaoCedentesInput>;
    update?: InputMaybe<UsuarioUpdateWithoutCamposAlteracaoCedentesInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutCamposAlteracaoCedentesInput>;
};

export type UsuarioUpdateOneRequiredWithoutCamposAlteracaoEnderecoCedentesNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCamposAlteracaoEnderecoCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutCamposAlteracaoEnderecoCedentesInput>;
    update?: InputMaybe<UsuarioUpdateWithoutCamposAlteracaoEnderecoCedentesInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutCamposAlteracaoEnderecoCedentesInput>;
};

export type UsuarioUpdateOneRequiredWithoutCamposCadastroCedentesNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCamposCadastroCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutCamposCadastroCedentesInput>;
    update?: InputMaybe<UsuarioUpdateWithoutCamposCadastroCedentesInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutCamposCadastroCedentesInput>;
};

export type UsuarioUpdateOneRequiredWithoutCamposCadastroEnderecoNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCamposCadastroEnderecoInput>;
    create?: InputMaybe<UsuarioCreateWithoutCamposCadastroEnderecoInput>;
    update?: InputMaybe<UsuarioUpdateWithoutCamposCadastroEnderecoInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutCamposCadastroEnderecoInput>;
};

export type UsuarioUpdateOneRequiredWithoutCedentesNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutCedentesInput>;
    update?: InputMaybe<UsuarioUpdateWithoutCedentesInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutCedentesInput>;
};

export type UsuarioUpdateOneRequiredWithoutHistStatusOperacionalNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistStatusOperacionalInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistStatusOperacionalInput>;
    update?: InputMaybe<UsuarioUpdateWithoutHistStatusOperacionalInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutHistStatusOperacionalInput>;
};

export type UsuarioUpdateOneRequiredWithoutIndPermissoesNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutIndPermissoesInput>;
    create?: InputMaybe<UsuarioCreateWithoutIndPermissoesInput>;
    update?: InputMaybe<UsuarioUpdateWithoutIndPermissoesInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutIndPermissoesInput>;
};

export type UsuarioUpdateOneRequiredWithoutRecebiveisNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutRecebiveisInput>;
    create?: InputMaybe<UsuarioCreateWithoutRecebiveisInput>;
    update?: InputMaybe<UsuarioUpdateWithoutRecebiveisInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutRecebiveisInput>;
};

export type UsuarioUpdateOneRequiredWithoutRespGrupoNotificacaoNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutRespGrupoNotificacaoInput>;
    create?: InputMaybe<UsuarioCreateWithoutRespGrupoNotificacaoInput>;
    update?: InputMaybe<UsuarioUpdateWithoutRespGrupoNotificacaoInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutRespGrupoNotificacaoInput>;
};

export type UsuarioUpdateOneRequiredWithoutRespGrupoPermissaoNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutRespGrupoPermissaoInput>;
    create?: InputMaybe<UsuarioCreateWithoutRespGrupoPermissaoInput>;
    update?: InputMaybe<UsuarioUpdateWithoutRespGrupoPermissaoInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutRespGrupoPermissaoInput>;
};

export type UsuarioUpdateOneWithoutCadastroCedentesNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCadastroCedentesInput>;
    create?: InputMaybe<UsuarioCreateWithoutCadastroCedentesInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutCadastroCedentesInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutCadastroCedentesInput>;
};

export type UsuarioUpdateOneWithoutCadastroEnderecoNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCadastroEnderecoInput>;
    create?: InputMaybe<UsuarioCreateWithoutCadastroEnderecoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutCadastroEnderecoInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutCadastroEnderecoInput>;
};

export type UsuarioUpdateOneWithoutCadastroNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutCadastroInput>;
    create?: InputMaybe<UsuarioCreateWithoutCadastroInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutCadastroInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutCadastroInput>;
};

export type UsuarioUpdateOneWithoutComercialPartnerAuditNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutComercialPartnerAuditInput>;
    create?: InputMaybe<UsuarioCreateWithoutComercialPartnerAuditInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutComercialPartnerAuditInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutComercialPartnerAuditInput>;
};

export type UsuarioUpdateOneWithoutContatoAuditNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutContatoAuditInput>;
    create?: InputMaybe<UsuarioCreateWithoutContatoAuditInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutContatoAuditInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutContatoAuditInput>;
};

export type UsuarioUpdateOneWithoutDebenturistaAuditNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutDebenturistaAuditInput>;
    create?: InputMaybe<UsuarioCreateWithoutDebenturistaAuditInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutDebenturistaAuditInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutDebenturistaAuditInput>;
};

export type UsuarioUpdateOneWithoutHistArquivoNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistArquivoInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistArquivoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutHistArquivoInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutHistArquivoInput>;
};

export type UsuarioUpdateOneWithoutHistCedenteNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistCedenteInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistCedenteInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutHistCedenteInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutHistCedenteInput>;
};

export type UsuarioUpdateOneWithoutHistEmpresaNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistEmpresaInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistEmpresaInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutHistEmpresaInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutHistEmpresaInput>;
};

export type UsuarioUpdateOneWithoutHistEnderecoNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistEnderecoInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistEnderecoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutHistEnderecoInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutHistEnderecoInput>;
};

export type UsuarioUpdateOneWithoutHistMinibancoNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistMinibancoInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistMinibancoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutHistMinibancoInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutHistMinibancoInput>;
};

export type UsuarioUpdateOneWithoutHistOperacaoNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistOperacaoInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistOperacaoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutHistOperacaoInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutHistOperacaoInput>;
};

export type UsuarioUpdateOneWithoutHistRecebivelNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistRecebivelInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistRecebivelInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutHistRecebivelInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutHistRecebivelInput>;
};

export type UsuarioUpdateOneWithoutHistSacadoNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistSacadoInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistSacadoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutHistSacadoInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutHistSacadoInput>;
};

export type UsuarioUpdateOneWithoutHistUsuarioNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistUsuarioInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistUsuarioInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutHistUsuarioInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutHistUsuarioInput>;
};

export type UsuarioUpdateOneWithoutHistoricoNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutHistoricoInput>;
    create?: InputMaybe<UsuarioCreateWithoutHistoricoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutHistoricoInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutHistoricoInput>;
};

export type UsuarioUpdateOneWithoutKeyAccountNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutKeyAccountInput>;
    create?: InputMaybe<UsuarioCreateWithoutKeyAccountInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutKeyAccountInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutKeyAccountInput>;
};

export type UsuarioUpdateOneWithoutMinibanksWalletNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutMinibanksWalletInput>;
    create?: InputMaybe<UsuarioCreateWithoutMinibanksWalletInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutMinibanksWalletInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutMinibanksWalletInput>;
};

export type UsuarioUpdateOneWithoutOccupationAuditNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutOccupationAuditInput>;
    create?: InputMaybe<UsuarioCreateWithoutOccupationAuditInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutOccupationAuditInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutOccupationAuditInput>;
};

export type UsuarioUpdateOneWithoutRespContatoNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutRespContatoInput>;
    create?: InputMaybe<UsuarioCreateWithoutRespContatoInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutRespContatoInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutRespContatoInput>;
};

export type UsuarioUpdateOneWithoutSalesmanNestedInput = {
    connect?: InputMaybe<UsuarioWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UsuarioCreateOrConnectWithoutSalesmanInput>;
    create?: InputMaybe<UsuarioCreateWithoutSalesmanInput>;
    delete?: InputMaybe<Scalars["Boolean"]>;
    disconnect?: InputMaybe<Scalars["Boolean"]>;
    update?: InputMaybe<UsuarioUpdateWithoutSalesmanInput>;
    upsert?: InputMaybe<UsuarioUpsertWithoutSalesmanInput>;
};

export type UsuarioUpdateWithWhereUniqueWithoutMinibancoInput = {
    data: UsuarioUpdateWithoutMinibancoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioUpdateWithWhereUniqueWithoutNotificacoesInput = {
    data: UsuarioUpdateWithoutNotificacoesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioUpdateWithWhereUniqueWithoutOccupationsInput = {
    data: UsuarioUpdateWithoutOccupationsInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioUpdateWithWhereUniqueWithoutPermissoesInput = {
    data: UsuarioUpdateWithoutPermissoesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioUpdateWithoutAcceptedTermsInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutAlteracaoCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutAlteracaoEnderecoCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutArquivosInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutCadastroCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutCadastroEnderecoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutCadastroInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutCamposAlteracaoCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutCamposAlteracaoEnderecoCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutCamposCadastroCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutCamposCadastroEnderecoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutCedentesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutComercialPartnerAuditInput = {
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutContatoAuditInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutDebenturistaAuditInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutHistArquivoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutHistCedenteInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutHistEmpresaInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutHistEnderecoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutHistMinibancoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutHistOperacaoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutHistRecebivelInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutHistSacadoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutHistStatusOperacionalInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutHistUsuarioInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutHistoricoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutIndPermissoesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutKeyAccountInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutMinibancoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutMinibanksWalletInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutNotificacoesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutOccupationAuditInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutOccupationsInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutPermissoesInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutRecebiveisInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutRespContatoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutRespGrupoNotificacaoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutRespGrupoPermissaoInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    Salesman?: InputMaybe<OperacaoUpdateManyWithoutSalesmanNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpdateWithoutSalesmanInput = {
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditUpdateManyWithoutUserNestedInput>;
    ContatoAudit?: InputMaybe<ContatoAuditUpdateManyWithoutUsuarioNestedInput>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditUpdateManyWithoutUsuarioNestedInput>;
    KeyAccount?: InputMaybe<OperacaoUpdateManyWithoutKeyAccountNestedInput>;
    OccupationAudit?: InputMaybe<OccupationAuditUpdateManyWithoutUserNestedInput>;
    acceptedTerms?: InputMaybe<AcceptedTermsUpdateManyWithoutUserNestedInput>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoUpdateManyWithoutUsuarioNestedInput>;
    arquivos?: InputMaybe<ArquivoUpdateManyWithoutUsuarioNestedInput>;
    cadastro?: InputMaybe<UsuarioCadastroUpdateOneWithoutUsuarioNestedInput>;
    cadastroCedentes?: InputMaybe<CedenteCadastroUpdateManyWithoutUsuarioNestedInput>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroUpdateManyWithoutUsuarioNestedInput>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroUpdateManyWithoutResponsavelNestedInput>;
    cargo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    cedentes?: InputMaybe<ResponsavelCedenteUpdateManyWithoutUsuarioNestedInput>;
    createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
    email?: InputMaybe<StringFieldUpdateOperationsInput>;
    firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    fone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    histArquivo?: InputMaybe<ArquivoAuditUpdateManyWithoutUsuarioNestedInput>;
    histCedente?: InputMaybe<CedenteAuditUpdateManyWithoutUsuarioNestedInput>;
    histEmpresa?: InputMaybe<EmpresaAuditUpdateManyWithoutUsuarioNestedInput>;
    histEndereco?: InputMaybe<EnderecoAuditUpdateManyWithoutUsuarioNestedInput>;
    histMinibanco?: InputMaybe<MinibancoAuditUpdateManyWithoutUsuarioNestedInput>;
    histOperacao?: InputMaybe<OperacaoAuditUpdateManyWithoutUsuarioNestedInput>;
    histRecebivel?: InputMaybe<RecebivelAuditUpdateManyWithoutUsuarioNestedInput>;
    histSacado?: InputMaybe<SacadoAuditUpdateManyWithoutUsuarioNestedInput>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditUpdateManyWithoutUsuarioNestedInput>;
    histUsuario?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioNestedInput>;
    historico?: InputMaybe<UsuarioAuditUpdateManyWithoutUsuarioModNestedInput>;
    inativo?: InputMaybe<BoolFieldUpdateOperationsInput>;
    indPermissoes?: InputMaybe<NivelPermissaoUpdateManyWithoutUsuarioNestedInput>;
    isAdmin?: InputMaybe<BoolFieldUpdateOperationsInput>;
    lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
    minibanco?: InputMaybe<MinibancoUpdateOneWithoutUsuariosNestedInput>;
    minibanksWallet?: InputMaybe<MinibancoUpdateManyWithoutKeyAccountNestedInput>;
    notificacoes?: InputMaybe<GrupoNotificacaoUpdateManyWithoutUsuariosNestedInput>;
    occupations?: InputMaybe<OccupationUpdateManyWithoutUsersNestedInput>;
    permissoes?: InputMaybe<GruposPermissaoUpdateManyWithoutUsuariosNestedInput>;
    recebiveis?: InputMaybe<RecebivelUpdateManyWithoutUsuarioNestedInput>;
    respContato?: InputMaybe<ContatoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoUpdateManyWithoutResponsavelNestedInput>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoUpdateManyWithoutResponsavelNestedInput>;
    senha?: InputMaybe<StringFieldUpdateOperationsInput>;
    updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UsuarioUpsertWithWhereUniqueWithoutMinibancoInput = {
    create: UsuarioCreateWithoutMinibancoInput;
    update: UsuarioUpdateWithoutMinibancoInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioUpsertWithWhereUniqueWithoutNotificacoesInput = {
    create: UsuarioCreateWithoutNotificacoesInput;
    update: UsuarioUpdateWithoutNotificacoesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioUpsertWithWhereUniqueWithoutOccupationsInput = {
    create: UsuarioCreateWithoutOccupationsInput;
    update: UsuarioUpdateWithoutOccupationsInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioUpsertWithWhereUniqueWithoutPermissoesInput = {
    create: UsuarioCreateWithoutPermissoesInput;
    update: UsuarioUpdateWithoutPermissoesInput;
    where: UsuarioWhereUniqueInput;
};

export type UsuarioUpsertWithoutAcceptedTermsInput = {
    create: UsuarioCreateWithoutAcceptedTermsInput;
    update: UsuarioUpdateWithoutAcceptedTermsInput;
};

export type UsuarioUpsertWithoutAlteracaoCedentesInput = {
    create: UsuarioCreateWithoutAlteracaoCedentesInput;
    update: UsuarioUpdateWithoutAlteracaoCedentesInput;
};

export type UsuarioUpsertWithoutAlteracaoEnderecoCedentesInput = {
    create: UsuarioCreateWithoutAlteracaoEnderecoCedentesInput;
    update: UsuarioUpdateWithoutAlteracaoEnderecoCedentesInput;
};

export type UsuarioUpsertWithoutArquivosInput = {
    create: UsuarioCreateWithoutArquivosInput;
    update: UsuarioUpdateWithoutArquivosInput;
};

export type UsuarioUpsertWithoutCadastroCedentesInput = {
    create: UsuarioCreateWithoutCadastroCedentesInput;
    update: UsuarioUpdateWithoutCadastroCedentesInput;
};

export type UsuarioUpsertWithoutCadastroEnderecoInput = {
    create: UsuarioCreateWithoutCadastroEnderecoInput;
    update: UsuarioUpdateWithoutCadastroEnderecoInput;
};

export type UsuarioUpsertWithoutCadastroInput = {
    create: UsuarioCreateWithoutCadastroInput;
    update: UsuarioUpdateWithoutCadastroInput;
};

export type UsuarioUpsertWithoutCamposAlteracaoCedentesInput = {
    create: UsuarioCreateWithoutCamposAlteracaoCedentesInput;
    update: UsuarioUpdateWithoutCamposAlteracaoCedentesInput;
};

export type UsuarioUpsertWithoutCamposAlteracaoEnderecoCedentesInput = {
    create: UsuarioCreateWithoutCamposAlteracaoEnderecoCedentesInput;
    update: UsuarioUpdateWithoutCamposAlteracaoEnderecoCedentesInput;
};

export type UsuarioUpsertWithoutCamposCadastroCedentesInput = {
    create: UsuarioCreateWithoutCamposCadastroCedentesInput;
    update: UsuarioUpdateWithoutCamposCadastroCedentesInput;
};

export type UsuarioUpsertWithoutCamposCadastroEnderecoInput = {
    create: UsuarioCreateWithoutCamposCadastroEnderecoInput;
    update: UsuarioUpdateWithoutCamposCadastroEnderecoInput;
};

export type UsuarioUpsertWithoutCedentesInput = {
    create: UsuarioCreateWithoutCedentesInput;
    update: UsuarioUpdateWithoutCedentesInput;
};

export type UsuarioUpsertWithoutComercialPartnerAuditInput = {
    create: UsuarioCreateWithoutComercialPartnerAuditInput;
    update: UsuarioUpdateWithoutComercialPartnerAuditInput;
};

export type UsuarioUpsertWithoutContatoAuditInput = {
    create: UsuarioCreateWithoutContatoAuditInput;
    update: UsuarioUpdateWithoutContatoAuditInput;
};

export type UsuarioUpsertWithoutDebenturistaAuditInput = {
    create: UsuarioCreateWithoutDebenturistaAuditInput;
    update: UsuarioUpdateWithoutDebenturistaAuditInput;
};

export type UsuarioUpsertWithoutHistArquivoInput = {
    create: UsuarioCreateWithoutHistArquivoInput;
    update: UsuarioUpdateWithoutHistArquivoInput;
};

export type UsuarioUpsertWithoutHistCedenteInput = {
    create: UsuarioCreateWithoutHistCedenteInput;
    update: UsuarioUpdateWithoutHistCedenteInput;
};

export type UsuarioUpsertWithoutHistEmpresaInput = {
    create: UsuarioCreateWithoutHistEmpresaInput;
    update: UsuarioUpdateWithoutHistEmpresaInput;
};

export type UsuarioUpsertWithoutHistEnderecoInput = {
    create: UsuarioCreateWithoutHistEnderecoInput;
    update: UsuarioUpdateWithoutHistEnderecoInput;
};

export type UsuarioUpsertWithoutHistMinibancoInput = {
    create: UsuarioCreateWithoutHistMinibancoInput;
    update: UsuarioUpdateWithoutHistMinibancoInput;
};

export type UsuarioUpsertWithoutHistOperacaoInput = {
    create: UsuarioCreateWithoutHistOperacaoInput;
    update: UsuarioUpdateWithoutHistOperacaoInput;
};

export type UsuarioUpsertWithoutHistRecebivelInput = {
    create: UsuarioCreateWithoutHistRecebivelInput;
    update: UsuarioUpdateWithoutHistRecebivelInput;
};

export type UsuarioUpsertWithoutHistSacadoInput = {
    create: UsuarioCreateWithoutHistSacadoInput;
    update: UsuarioUpdateWithoutHistSacadoInput;
};

export type UsuarioUpsertWithoutHistStatusOperacionalInput = {
    create: UsuarioCreateWithoutHistStatusOperacionalInput;
    update: UsuarioUpdateWithoutHistStatusOperacionalInput;
};

export type UsuarioUpsertWithoutHistUsuarioInput = {
    create: UsuarioCreateWithoutHistUsuarioInput;
    update: UsuarioUpdateWithoutHistUsuarioInput;
};

export type UsuarioUpsertWithoutHistoricoInput = {
    create: UsuarioCreateWithoutHistoricoInput;
    update: UsuarioUpdateWithoutHistoricoInput;
};

export type UsuarioUpsertWithoutIndPermissoesInput = {
    create: UsuarioCreateWithoutIndPermissoesInput;
    update: UsuarioUpdateWithoutIndPermissoesInput;
};

export type UsuarioUpsertWithoutKeyAccountInput = {
    create: UsuarioCreateWithoutKeyAccountInput;
    update: UsuarioUpdateWithoutKeyAccountInput;
};

export type UsuarioUpsertWithoutMinibanksWalletInput = {
    create: UsuarioCreateWithoutMinibanksWalletInput;
    update: UsuarioUpdateWithoutMinibanksWalletInput;
};

export type UsuarioUpsertWithoutOccupationAuditInput = {
    create: UsuarioCreateWithoutOccupationAuditInput;
    update: UsuarioUpdateWithoutOccupationAuditInput;
};

export type UsuarioUpsertWithoutRecebiveisInput = {
    create: UsuarioCreateWithoutRecebiveisInput;
    update: UsuarioUpdateWithoutRecebiveisInput;
};

export type UsuarioUpsertWithoutRespContatoInput = {
    create: UsuarioCreateWithoutRespContatoInput;
    update: UsuarioUpdateWithoutRespContatoInput;
};

export type UsuarioUpsertWithoutRespGrupoNotificacaoInput = {
    create: UsuarioCreateWithoutRespGrupoNotificacaoInput;
    update: UsuarioUpdateWithoutRespGrupoNotificacaoInput;
};

export type UsuarioUpsertWithoutRespGrupoPermissaoInput = {
    create: UsuarioCreateWithoutRespGrupoPermissaoInput;
    update: UsuarioUpdateWithoutRespGrupoPermissaoInput;
};

export type UsuarioUpsertWithoutSalesmanInput = {
    create: UsuarioCreateWithoutSalesmanInput;
    update: UsuarioUpdateWithoutSalesmanInput;
};

export type UsuarioWhereInput = {
    AND?: InputMaybe<Array<UsuarioWhereInput>>;
    ComercialPartnerAudit?: InputMaybe<ComercialPartnerAuditListRelationFilter>;
    ContatoAudit?: InputMaybe<ContatoAuditListRelationFilter>;
    DebenturistaAudit?: InputMaybe<DebenturistaAuditListRelationFilter>;
    KeyAccount?: InputMaybe<OperacaoListRelationFilter>;
    NOT?: InputMaybe<Array<UsuarioWhereInput>>;
    OR?: InputMaybe<Array<UsuarioWhereInput>>;
    OccupationAudit?: InputMaybe<OccupationAuditListRelationFilter>;
    Salesman?: InputMaybe<OperacaoListRelationFilter>;
    acceptedTerms?: InputMaybe<AcceptedTermsListRelationFilter>;
    alteracaoCedentes?: InputMaybe<CedenteAlteracaoListRelationFilter>;
    alteracaoEnderecoCedentes?: InputMaybe<EnderecoAlteracaoListRelationFilter>;
    arquivos?: InputMaybe<ArquivoListRelationFilter>;
    cadastro?: InputMaybe<UsuarioCadastroRelationFilter>;
    cadastroCedentes?: InputMaybe<CedenteCadastroListRelationFilter>;
    cadastroEndereco?: InputMaybe<EnderecoCadastroListRelationFilter>;
    cadastroId?: InputMaybe<IntNullableFilter>;
    camposAlteracaoCedentes?: InputMaybe<CedenteCamposAlteracaoListRelationFilter>;
    camposAlteracaoEnderecoCedentes?: InputMaybe<EnderecoCamposAlteracaoListRelationFilter>;
    camposCadastroCedentes?: InputMaybe<CedenteCamposCadastroListRelationFilter>;
    camposCadastroEndereco?: InputMaybe<EnderecoCamposCadastroListRelationFilter>;
    cargo?: InputMaybe<StringNullableFilter>;
    cedentes?: InputMaybe<ResponsavelCedenteListRelationFilter>;
    createdAt?: InputMaybe<DateTimeFilter>;
    email?: InputMaybe<StringFilter>;
    firstName?: InputMaybe<StringNullableFilter>;
    fone?: InputMaybe<StringNullableFilter>;
    histArquivo?: InputMaybe<ArquivoAuditListRelationFilter>;
    histCedente?: InputMaybe<CedenteAuditListRelationFilter>;
    histEmpresa?: InputMaybe<EmpresaAuditListRelationFilter>;
    histEndereco?: InputMaybe<EnderecoAuditListRelationFilter>;
    histMinibanco?: InputMaybe<MinibancoAuditListRelationFilter>;
    histOperacao?: InputMaybe<OperacaoAuditListRelationFilter>;
    histRecebivel?: InputMaybe<RecebivelAuditListRelationFilter>;
    histSacado?: InputMaybe<SacadoAuditListRelationFilter>;
    histStatusOperacional?: InputMaybe<StatusOperacionalAuditListRelationFilter>;
    histUsuario?: InputMaybe<UsuarioAuditListRelationFilter>;
    historico?: InputMaybe<UsuarioAuditListRelationFilter>;
    id?: InputMaybe<IntFilter>;
    inativo?: InputMaybe<BoolFilter>;
    indPermissoes?: InputMaybe<NivelPermissaoListRelationFilter>;
    isAdmin?: InputMaybe<BoolFilter>;
    lastName?: InputMaybe<StringNullableFilter>;
    minibanco?: InputMaybe<MinibancoRelationFilter>;
    minibancoId?: InputMaybe<IntNullableFilter>;
    minibanksWallet?: InputMaybe<MinibancoListRelationFilter>;
    notificacoes?: InputMaybe<GrupoNotificacaoListRelationFilter>;
    occupations?: InputMaybe<OccupationListRelationFilter>;
    permissoes?: InputMaybe<GruposPermissaoListRelationFilter>;
    recebiveis?: InputMaybe<RecebivelListRelationFilter>;
    respContato?: InputMaybe<ContatoListRelationFilter>;
    respGrupoNotificacao?: InputMaybe<GrupoNotificacaoListRelationFilter>;
    respGrupoPermissao?: InputMaybe<GruposPermissaoListRelationFilter>;
    senha?: InputMaybe<StringFilter>;
    updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UsuarioWhereUniqueInput = {
    cadastroId?: InputMaybe<Scalars["Int"]>;
    email?: InputMaybe<Scalars["String"]>;
    id?: InputMaybe<Scalars["Int"]>;
};

export type Assignor = {
    __typename?: "assignor";
    cnpj: Scalars["String"];
    razaoSocial: Scalars["String"];
};

export type Attachment = {
    __typename?: "attachment";
    arquivos?: Maybe<Array<File>>;
    createdAt: Scalars["DateTime"];
    id: Scalars["Float"];
    observacao?: Maybe<Scalars["String"]>;
};

export type File = {
    __typename?: "file";
    id: Scalars["Float"];
    inativo: Scalars["Boolean"];
    nome: Scalars["String"];
    url: Scalars["String"];
};

export type Historic = {
    __typename?: "historic";
    action: Scalars["String"];
    createdAt: Scalars["DateTime"];
    field: Scalars["String"];
    newValue?: Maybe<Scalars["String"]>;
    oldValue?: Maybe<Scalars["String"]>;
    usuario?: Maybe<User>;
};

export type MiniBank = {
    __typename?: "miniBank";
    tipo: Scalars["String"];
};

export type Modality = {
    __typename?: "modality";
    descricao: Scalars["String"];
};

export type Operation = {
    __typename?: "operation";
    anexos?: Maybe<Array<Attachment>>;
    createdAt: Scalars["DateTime"];
    descricao?: Maybe<Scalars["String"]>;
    desembolso?: Maybe<Scalars["Float"]>;
    historico?: Maybe<Array<Historic>>;
    id: Scalars["Float"];
    minibanco?: Maybe<MiniBank>;
    minibancoId: Scalars["Float"];
    recebiveis?: Maybe<Array<Payables>>;
    status?: Maybe<Statuses>;
    statusId: Scalars["Float"];
    tipo?: Maybe<OperationType>;
    tipoId: Scalars["Float"];
};

export type OperationType = {
    __typename?: "operationType";
    descricao: Scalars["String"];
    id: Scalars["Float"];
};

export type Payables = {
    __typename?: "payables";
    dtVencimento?: Maybe<Scalars["DateTime"]>;
    sacado?: Maybe<Payer>;
    sacadoId: Scalars["Float"];
    valor: Scalars["Float"];
};

export type Payer = {
    __typename?: "payer";
    document: Scalars["String"];
    razaoSocial: Scalars["String"];
};

export type Statuses = {
    __typename?: "statuses";
    descricao: Scalars["String"];
    sigla: Scalars["String"];
};

export type User = {
    __typename?: "user";
    firstName?: Maybe<Scalars["String"]>;
    lastName?: Maybe<Scalars["String"]>;
};

export type DeleteFileFromOperationMutationVariables = Exact<{
    id: Scalars["Float"];
}>;

export type DeleteFileFromOperationMutation = { __typename?: "Mutation"; deleteFileFromOperation: boolean };

export type UpdateObsFromAttachmentMutationVariables = Exact<{
    id: Scalars["Float"];
    obs: Scalars["String"];
}>;

export type UpdateObsFromAttachmentMutation = { __typename?: "Mutation"; updateObsFromAttachment: boolean };

export type FindOneOperationQueryVariables = Exact<{
    id: Scalars["Float"];
}>;

export type FindOneOperationQuery = {
    __typename?: "Query";
    findOneOperation: {
        __typename?: "IFindOneOperationDto";
        operacao?: {
            __typename?: "operation";
            anexos?: Array<{
                __typename?: "attachment";
                createdAt: any;
                id: number;
                observacao?: string | null;
                arquivos?: Array<{
                    __typename?: "file";
                    id: number;
                    nome: string;
                    url: string;
                    inativo: boolean;
                }> | null;
            }> | null;
            historico?: Array<{
                __typename?: "historic";
                action: string;
                field: string;
                oldValue?: string | null;
                newValue?: string | null;
                createdAt: any;
                usuario?: { __typename?: "user"; firstName?: string | null; lastName?: string | null } | null;
            }> | null;
        } | null;
    };
};

export const DeleteFileFromOperationDocument = gql`
    mutation deleteFileFromOperation($id: Float!) {
        deleteFileFromOperation(id: $id)
    }
`;

@Injectable({
    providedIn: "root",
})
export class DeleteFileFromOperationGQL extends Apollo.Mutation<
    DeleteFileFromOperationMutation,
    DeleteFileFromOperationMutationVariables
> {
    document = DeleteFileFromOperationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateObsFromAttachmentDocument = gql`
    mutation updateObsFromAttachment($id: Float!, $obs: String!) {
        updateObsFromAttachment(id: $id, obs: $obs)
    }
`;

@Injectable({
    providedIn: "root",
})
export class UpdateObsFromAttachmentGQL extends Apollo.Mutation<
    UpdateObsFromAttachmentMutation,
    UpdateObsFromAttachmentMutationVariables
> {
    document = UpdateObsFromAttachmentDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const FindOneOperationDocument = gql`
    query findOneOperation($id: Float!) {
        findOneOperation(id: $id) {
            operacao {
                anexos {
                    createdAt
                    id
                    observacao
                    arquivos {
                        id
                        nome
                        url
                        inativo
                    }
                }
                historico {
                    action
                    field
                    oldValue
                    newValue
                    createdAt
                    usuario {
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

@Injectable({
    providedIn: "root",
})
export class FindOneOperationGQL extends Apollo.Query<FindOneOperationQuery, FindOneOperationQueryVariables> {
    document = FindOneOperationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
