<div class="pb-3">
    <h1 class="font-bold text-3xl">Filtrar por</h1>
    <p>Utilize os filtros para a listagem de titulos disponíveis para Recompra.</p>

    <form class="flex flex-col gap-2 mt-4" [formGroup]="filterForm">
        <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Número do título</mat-label>
            <input matInput placeholder="Número do título" name="payableNumber" formControlName="payableNumber" />
        </mat-form-field>

        <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Nome do sacado</mat-label>
            <input matInput placeholder="Nome do sacado" name="payerName" formControlName="payerName" />
        </mat-form-field>

        <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Tipo de recebível</mat-label>
            <mat-select formControlName="payableType" placeholder="Selecione o tipo de recebível">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let type of payableTypes" [value]="type.value">
                    {{ type.name | titlecase }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>

    <mat-dialog-actions>
        <div class="flex gap-2 items-center justify-end w-full mt-2">
            <Button (click)="close()" label="Fechar" type="outline" color="primary"></Button>
            <Button
                (click)="applyFilters()"
                type="solid"
                color="primary"
                label="Aplicar filtros"
                [disabled]="loading"
            ></Button>
        </div>
    </mat-dialog-actions>
</div>
