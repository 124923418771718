<div class="flex justify-between align center px-8 md:p-0 py-5 md:py-6">
    <h1 class="block text-xl md:text-3xl font-semibold md:font-bold">Resumo da Operação</h1>
    <mat-icon
        [ngClass]="{ 'md:hidden': expanded }"
        [svgIcon]="expanded ? 'heroicons_outline:chevron-up' : 'heroicons_outline:chevron-down'"
        (click)="expandHideEmitter.emit()"
    ></mat-icon>
</div>
<div
    id="expandableOperationInfo"
    class="grid gap-3 bg-gray-100 md:bg-transparent md:border-solid md:border-2 md:border-neutral-100 md:rounded-sm"
    [ngClass]="expanded ? 'expanded' : 'overflow-hidden'"
>
    <div class="w-full bg-default rounded-t-sm flex flex-col gap-5 px-6 pt-6 pb-4">
        <div class="flex flex-col gap-y-2">
            <h1 class="text-2xl font-bold leading-6 text-neutral-900">Desconto dos títulos de crédito</h1>
            <Paragraph
                [level]="'P2'"
                variant="neutral"
                isBold="false"
                isItalic="false"
                isTruncate="false"
                [text]="riskName"
            ></Paragraph>
        </div>

        <div>
            <div class="flex flex-row gap-y-4 gap-x-2 items-center truncate">
                <span class="text-base font-normal leading-5 text-gray-400">Minibanco:</span>
                <Paragraph
                    [level]="'P2'"
                    variant="primary"
                    isBold="true"
                    isItalic="false"
                    isTruncate="false"
                    isUppercase="true"
                    [text]="minibankName"
                ></Paragraph>
            </div>

            <div class="flex flex-row gap-y-4 gap-x-2 items-center">
                <span class="text-base font-normal leading-5 text-gray-400">Cedente:</span>
                <Paragraph
                    [level]="'P2'"
                    variant="primary"
                    isBold="true"
                    isItalic="false"
                    isTruncate="false"
                    isUppercase="true"
                    [text]="assignorName"
                ></Paragraph>
            </div>
        </div>
    </div>
    <div class="flex flex-col px-6 gap-y-4">
        <div class="flex flex-row items-center justify-between w-full">
            <span class="text-gray-800 font-bold">Valor de face</span>
            <span class="text-gray-800">{{ operationData.amount | currency : "BRL" : "symbol" : "1.2-2" }}</span>
        </div>
        <div class="flex flex-row items-center justify-between w-full">
            <span class="text-gray-800 font-bold">Vencimento</span>
            <span class="text-gray-800">{{ operationData.dueDate | date : "dd/MM/yyyy" : "UTC" }}</span>
        </div>
        <div class="flex flex-row items-center justify-between w-full">
            <span class="text-gray-800 font-bold">Taxa ao mês</span>
            <span class="text-gray-800">{{ operationData.interestRate | percent : "1.2-2" }}</span>
        </div>
        <div class="flex flex-row items-center justify-between w-full">
            <span class="text-gray-800 font-bold">Deságio da operação</span>
            <span class="text-gray-800">{{ operationData.deduction | currency : "BRL" : "symbol" : "1.2-2" }}</span>
        </div>
        <div class="flex flex-row items-center justify-between w-full">
            <span class="text-gray-800 font-bold">Outras despesas</span>
            <span class="text-gray-800">{{ operationData.expenses | currency : "BRL" : "symbol" : "1.2-2" }}</span>
        </div>
        <div class="flex flex-row items-center justify-between w-full">
            <span class="text-gray-800 font-bold">Valor total de recompra</span>
            <span class="text-gray-800">{{
                operationData.totalRepurchase || 0 | currency : "BRL" : "symbol" : "1.2-2"
            }}</span>
        </div>
        <hr class="my-0" />
        <div class="flex flex-row items-center justify-between w-full">
            <span class="text-gray-800 font-bold">Desembolso total</span>
            <span class="text-gray-800">{{
                operationData.totalDisbursement | currency : "BRL" : "symbol" : "1.2-2"
            }}</span>
        </div>
        <div class="flex flex-row items-center justify-between w-full" *ngIf="operationData.hasFunding">
            <span class="text-gray-800 font-bold">Desembolso do Funding Bankme</span>
            <span class="text-gray-800">{{
                operationData.fundingDisbursement | currency : "BRL" : "symbol" : "1.2-2"
            }}</span>
        </div>
        <hr class="my-0" *ngIf="operationData.hasFunding" />
        <div class="flex flex-row items-center justify-between w-full" *ngIf="operationData.hasFunding">
            <span class="text-gray-800 text-lg">Desembolso da conta do minibanco</span>
            <span class="text-gray-800 text-lg">{{
                operationData.minibankDisbursement | currency : "BRL" : "symbol" : "1.2-2"
            }}</span>
        </div>
        <div class="flex flex-row items-center justify-between w-full" *ngIf="operationData.hasFunding">
            <span class="text-gray-800 text-lg">Deságio líquido</span>
            <span class="text-gray-800 text-lg">{{
                operationData.netDeduction | currency : "BRL" : "symbol" : "1.2-2"
            }}</span>
        </div>
        <div
            *ngIf="operationData.profitability !== 0 && operationData.hasFunding"
            [class]="
                operationData.profitability > 0
                    ? 'w-full rounded-lg py-2 bg-primary-100 text-primary-500 font-bold text-center'
                    : 'w-full rounded-lg py-2 bg-red-100 text-red-500 font-bold text-center'
            "
        >
            Rentabilidade da operação de {{ operationData.profitability | percent : "1.2-2" }}
        </div>
    </div>

    <div class="overflow-x-auto whitespace-nowrap px-6 py-2">
        <PayableList [hasTitle]="false" [payables]="payables"></PayableList>
    </div>
</div>
<hr class="md:hidden border border-neutral-200 border-b-0 m-0" />

<div
    (click)="openDialog()"
    class="rounded-3xl w-full bg-blue-500 text-white py-6 px-5 my-5"
    *ngIf="operationData.hasFunding"
>
    <div class="pb-3 flex items-center justify-between">
        <p class="font-bold text-2xl">Saiba mais</p>
        <mat-icon
            [svgIcon]="'heroicons_outline:arrow-right'"
            class="text-white rounded-full p-1 border border-white"
        ></mat-icon>
    </div>
    <p>Entenda mais sobre rentabilidade, parametrizações de uma operação e os detalhes do Funding Bankme.</p>
</div>
