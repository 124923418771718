import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { AuthService } from "app/core/auth/auth.service";

@Directive({
    selector: "[claim]",
})
export class RoleDirective implements OnInit {
    @Input() claim: Array<string>;

    constructor(private _elem: ElementRef<HTMLElement>, private _authService: AuthService) {}

    ngOnInit() {
        this.applyPermission();
    }

    applyPermission() {
        if (this.claim) {
            const hasDefined = this._authService.showItem(this.claim);

            if (!hasDefined) {
                this._elem.nativeElement.remove();
                this._elem.nativeElement.style.display = "none";
            }
        }
    }
}
