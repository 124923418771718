import { Injectable } from "@angular/core";
import { ApiService } from "app/core/api/api.service";
import { OperacoesService } from "app/modules/admin/v1/operacoes/operacoes.service";
import { Operacao } from "app/modules/admin/v1/operacoes/operacoes.types";
import { Cedente } from "app/modules/admin/v1/cedente/interfaces/cedente.types";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class NotificationsInboxService {
    private _operationsToApprove = new BehaviorSubject<Operacao[]>([]);
    private _newCustomers = new BehaviorSubject<Cedente[]>([]);
    private _creditsToApprove = new BehaviorSubject<Cedente[]>([]);

    set operationsToApprove(val: Operacao[]) {
        this._operationsToApprove.next(val);
    }

    get operationsToApprove$(): Observable<Operacao[]> {
        return this._operationsToApprove.asObservable();
    }

    set newCostumersToApprove(val: Cedente[]) {
        this._newCustomers.next(val);
    }

    get newCostumersToApprove$(): Observable<Cedente[]> {
        return this._newCustomers.asObservable();
    }

    set creditsToApprove(val: Cedente[]) {
        this._creditsToApprove.next(val);
    }

    get creditsToApprove$(): Observable<Cedente[]> {
        return this._creditsToApprove.asObservable();
    }

    constructor(private operationService: OperacoesService, private _apiService: ApiService) {}

    listOperationsToApprove() {
        let operationStatusId;

        this.operationService.getOperacoesStatus().subscribe((data) => {
            operationStatusId = data.filter((status) => status.sigla === "SUGERIDO-CED")[0]?.id;
        });

        return this._apiService.get("crm", "/operacao/aprove").subscribe((response: Operacao[]) => {
            this.operationsToApprove = response.filter((operations) => operations.statusId === operationStatusId);
        });
    }
}
