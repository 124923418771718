import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FileUrl } from "app/shared/constants/files-urls.constant";
import { PayableType } from "app/shared/constants/operation.enum";

@Component({
    selector: "ChooseUploadFileModal",
    templateUrl: "./choose-upload-file-modal.component.html",
    styleUrls: ["./choose-upload-file-modal.component.scss"],
})
export class ChooseUploadFileModalComponent {
    public payableType: PayableType;

    @Output() xmlEmitter = new EventEmitter();
    @Output() spreadsheetEmitter = new EventEmitter();
    @Output() imgEmitter = new EventEmitter();
    @Output() closeEmitter = new EventEmitter();

    constructor(@Inject(MAT_DIALOG_DATA) public data: { payableType: PayableType }) {
        this.payableType = data.payableType;
    }

    selectImg() {
        this.imgEmitter.emit();
    }

    selectXML() {
        this.xmlEmitter.emit();
    }

    selectSpreadsheet() {
        this.spreadsheetEmitter.emit();
    }

    downloadSpreadsheet() {
        const link = document.createElement("a");
        link.download = "modelo.xlsx";
        link.href = this.payableType === "CHEQUE" ? FileUrl.CHECK_XLSX_TEMPLATE : FileUrl.PAYABLE_XLSX_TEMPLATE;

        link.click();
    }

    close() {
        this.closeEmitter.emit();
    }
}
