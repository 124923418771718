import { NgModule } from "@angular/core";
import { CheckboxCardComponent } from "./checkbox-card.component";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    imports: [CommonModule, MatIconModule],
    declarations: [CheckboxCardComponent],
    exports: [CheckboxCardComponent],
})
export class CheckboxCardModule {}
