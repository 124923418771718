import { Directive, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { AuthService } from "app/core/auth/auth.service";
import { MinibankTypes } from "app/core/user/user.model";

@Directive({
    selector: "[isStarter]",
})
export class IsStarterDirective implements OnInit {
    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly authService: AuthService,
        private readonly renderer: Renderer2,
    ) {}

    ngOnInit() {
        this.validate();
    }

    private validate(): void {
        if (this.authService.isStarter) {
            this.renderer.removeChild(this.elementRef.nativeElement.parentNode, this.elementRef.nativeElement);
        }
    }
}
