import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { InMemoryCache, ApolloLink } from "@apollo/client/core";
import { HttpLink } from "apollo-angular/http";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { environment } from "environments/environment";

function getToken() {
    const token = localStorage.getItem("access_token");

    if (token === null) {
        return {};
    }

    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
}

export function createApollo(httpLink: HttpLink) {
    const token = getToken();
    const auth = setContext(() => token);

    const errorHandler = onError(({ forward, operation, graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach((error) => {
                if (error.message === "jwt expired") {
                    localStorage.clear();
                    location.href = "/sign-in";
                }
            });
        }
        if (networkError) {
            if (networkError.message === "jwt expired") {
                localStorage.clear();
                location.href = "/sign-in";
            }
        }

        return forward(operation);
    });

    const link = ApolloLink.from([
        errorHandler,
        auth,
        httpLink.create({ uri: `${environment.vars.apis.crm}/graphql` }),
    ]);
    const cache = new InMemoryCache();

    return {
        link,
        cache,
    };
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule {}
