import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Operacao } from "../../../modules/admin/v1/operacoes/operacoes.types";
import { NotificationsInboxService } from "../notifications-inbox/notifications-inbox.service";

@Component({
    selector: "app-aguardando-aprovacao-modal",
    templateUrl: "./aguardando-aprovacao-modal.component.html",
    styleUrls: ["./aguardando-aprovacao-modal.component.scss"],
})
export class AguardandoAprovacaoModalComponent implements OnInit {
    constructor(
        private _notificationService: NotificationsInboxService,
        private dialogRef: MatDialogRef<AguardandoAprovacaoModalComponent>,
    ) {}
    operacoesToAprovar: Operacao[];

    ngOnInit() {
        this._notificationService.operationsToApprove$.subscribe((operacoes) => {
            this.operacoesToAprovar = operacoes.slice(0, 3);
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
