import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";

import { NgxDropzoneModule } from "ngx-dropzone";
import { SharedModule } from "../../../shared/shared.module";

import { UploadModalComponent } from "./upload-modal.component";
import { UploadModalService } from "./upload-modal.service";
import { FileValidatorModule } from "app/shared/services/file-validator/file-validator.module";

@NgModule({
    declarations: [UploadModalComponent],
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        NgxDropzoneModule,
        SharedModule,
        FileValidatorModule,
    ],
    exports: [UploadModalComponent],
    providers: [UploadModalService],
})
export class UploadModalModule {}
