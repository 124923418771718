import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import ptBr from "@angular/common/locales/pt";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExtraOptions, PreloadAllModules, RouterModule } from "@angular/router";
import { FuseModule } from "@fuse";
import { FuseConfigModule } from "@fuse/services/config";
import { AppComponent } from "app/app.component";
import { appRoutes } from "app/app.routing";
import { appConfig } from "app/core/config/app.config";
import { CoreModule } from "app/core/core.module";
import { LayoutModule } from "app/layout/layout.module";
import { NgxHotjarModule, NgxHotjarRouterModule } from "ngx-hotjar";
import { FuseConfirmationModule } from "../@fuse/services/confirmation";
import { environment } from "../environments/environment";
import { AguardandoAprovacaoModalModule } from "./components/v1/aguardando-aprovacao-modal/aguardando-aprovacao-modal.module";
import { TitulosLoadingSnackBarModule } from "./components/v1/titulos-loading-snack-bar/titulos-loading-snack-bar.module";
import { DateLocaleModule } from "./configs/date-locale.module";
import { AuthService } from "./core/auth/auth.service";
import { GraphQLModule } from "./utils/graphql/graphql.module";
import { CookieService } from "ngx-cookie-service";
import { CreateOperationModule } from "./modules/admin/v2/operation/create/create-operation.module";
import * as amplitude from "@amplitude/analytics-browser";
import { HasPermission } from "./utils/auth/auth";

amplitude.track("View app-banqueiro-module");
registerLocaleData(ptBr);

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: "enabled",
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: "legacy",
    onSameUrlNavigation: "reload",
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        FuseModule,
        CoreModule,
        LayoutModule,
        BrowserModule,
        DateLocaleModule,
        MatSnackBarModule,
        NgxHotjarRouterModule,
        FuseConfirmationModule,
        BrowserAnimationsModule,
        TitulosLoadingSnackBarModule,
        AguardandoAprovacaoModalModule,
        FuseConfigModule.forRoot(appConfig),
        NgxHotjarModule.forRoot(environment.vars.hj),
        RouterModule.forRoot(appRoutes, routerConfig),
        GraphQLModule,
        HttpClientModule,
        MatIconModule,
        CreateOperationModule,
    ],
    bootstrap: [AppComponent],
    providers: [AuthService, CookieService, HasPermission],
})
export class AppModule {}
