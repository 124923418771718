<mat-dialog-content class="mat-typograph">
    <div class="w-full h-full px-5">
        <form class="w-full">
            <span class="flex justify-start gap-3 items-center">
                <mat-icon class="inline-block icon-size-7" style="color: inherit">upload_file</mat-icon>
                <h2 class="font-bold text-3xl leading-tight">Importar recebíveis</h2>
            </span>
            <div class="w-full flex flex-col items-center justify-center">
                <div
                    *ngIf="loading"
                    class="flex flex-col justify-center items-center gap-10 text-blue-700 font-bold text-lg my-20"
                >
                    <mat-progress-spinner [diameter]="100" [mode]="'indeterminate'"></mat-progress-spinner>
                    <span>Aguarde enquando os recebíveis são validados e processados</span>
                </div>
                <ngx-dropzone
                    *ngIf="!loading"
                    (change)="onSelect($event)"
                    class="w-full lg:h-80 my-4 drag-n-drop dark:bg-gray-600 justify-center bg-primary-50"
                    style="border: 1px dashed lightgrey; overflow: hidden"
                    [maxFileSize]="maxFileSize"
                    [accept]="
                        fileType === 'XML'
                            ? 'application/xml, text/xml'
                            : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/x-xls, application/vnd.ms-excel'
                    "
                    [multiple]="true"
                    [expandable]="true"
                    [disabled]="dropFileDisabled"
                >
                    <ngx-dropzone-label class="px-4 flex flex-col items-center justify-center gap-y-2">
                        <p class="text-black font-bold text-xl mb-0">
                            {{
                                fileType === "XML"
                                    ? "Arraste e solte ou selecione os arquivos"
                                    : "Arraste e solte ou selecione o arquivo"
                            }}
                        </p>
                        <p class="text-secondary text-sm">
                            {{
                                fileType === "XML"
                                    ? "São aceitos apenas arquivos XML"
                                    : "É aceito somente um arquivo do tipo XLS/XLSX (planilha)"
                            }}
                        </p>
                    </ngx-dropzone-label>

                    <ngx-dropzone-preview
                        class="xml-card"
                        *ngFor="let f of files"
                        [removable]="!dropFileDisabled"
                        (removed)="onRemove(f)"
                    >
                        <ngx-dropzone-label style="word-break: break-word !important">
                            {{ f.name }}
                            <mat-icon
                                class="absolute top-1 left-1 opacity-50"
                                svgIcon="heroicons_outline:document-text"
                            ></mat-icon>
                        </ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="w-full flex md:flex-row flex-col items-center justify-between">
        <div class="w-full flex flex-row items-center justify-between md:justify-end">
            <button mat-button mat-dialog-close>Cancelar</button>
            <button
                [disabled]="loading"
                mat-button
                class="bg-primary text-white"
                cdkFocusInitial
                (click)="sendPayables()"
            >
                <span *ngIf="!loading">Enviar</span>
                <mat-progress-spinner *ngIf="loading" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
        </div>
    </div>
</mat-dialog-actions>
