import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "Button",
    templateUrl: "./button.component.html",
    styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
    public buttonColor = "";
    public buttonStyle = "";

    private colors = {
        primary: "blue-800",
        accent: "gray-800",
        warn: "red-700",
        light: "gray-500",
        neutral: "blue-500",
    };

    private typeStyle = {
        solid: "bg-$color border-$color border-2 text-white",
        outline: "border-$color border-2 bg-transparent text-$color",
        transparent: "bg-transparent text-$color",
        icon: "bg-transparent text-$color hover:bg-gray-200",
        neutral: "bg-white text-$color",
    };

    // appearance
    @Input() matIcon?: string;
    @Input() heroIcon?: string;
    @Input() label?: string;
    @Input() color: "primary" | "accent" | "warn" | "light" | "neutral";
    @Input() type: "solid" | "outline" | "icon" | "transparent" | "neutral";
    @Input() hideText: boolean = true;
    @Input() customClass = "";

    // behavior
    @Input() disabled = false;
    @Input() navigateToUrl?: string;
    @Output() clickEvent = new EventEmitter();

    public paddingStyle = {};

    constructor(private readonly router: Router) {}

    ngOnInit() {
        this.buttonColor = this.colors[this.color];
        const buttonStyle = this.typeStyle[this.type].replaceAll("$color", this.buttonColor);
        this.buttonStyle = buttonStyle + " " + this.customClass;
    }

    clickAction() {
        if (this.disabled) return;
        if (this.navigateToUrl) {
            this.router.navigate([this.navigateToUrl]);
        }
        this.clickEvent.emit();
    }

    secondaryClickAction(event: MouseEvent) {
        if (this.disabled) return;
        const MIDDLE_CLICK = 2;
        if (event.which === MIDDLE_CLICK && !this.navigateToUrl) {
            return this.clickAction();
        }
        if (this.navigateToUrl) this.router.navigate([]).then(() => window.open(this.navigateToUrl, "_blank"));
    }
}
