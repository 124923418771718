<form class="grid grid-cols-1 gap-2">
    <div
        class="border shadow-sm rounded-md p-4 cursor-pointer space-y-1 transition-all duration-300"
        *ngFor="let paymentCard of paymentCards"
        (click)="selectCard(paymentCard)"
        [ngClass]="{ 'bg-blue-600 text-white shadow-md': paymentCard === selectedPaymentCard }"
    >
        <h3 class="font-bold text-lg tracking-wide">{{ paymentCard.accountHolder || "Não informado" }}</h3>
        <div class="flex gap-2">
            <p>
                CPF/CNPJ: <span class="font-semibold">{{ paymentCard.document || "Não informado" }}</span>
            </p>
            <p>
                Instituição bancária: <span class="font-semibold">{{ paymentCard.bank }}</span>
            </p>
        </div>

        <div class="flex gap-2">
            <p>
                Agência: <span class="font-semibold">{{ paymentCard.agency }} - {{ paymentCard.agencyDigit }}</span>
            </p>
            <p>
                Conta: <span class="font-semibold">{{ paymentCard.account }} - {{ paymentCard.accountDigit }}</span>
            </p>
        </div>

        <div class="flex gap-2">
            <p>
                Tipo de chave: <span class="font-semibold">{{ paymentCard.pixKeyType }}</span>
            </p>
            <p>
                Chave Pix: <span class="font-semibold">{{ paymentCard.pix }}</span>
            </p>
        </div>
    </div>

    <p class="text-gray-800 text-sm" *ngIf="!paymentCards.length">Não há contas bancárias cadastradas</p>
</form>
