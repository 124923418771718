import { RolesInterface } from "app/modules/admin/v1/usuarios/perfil/perfil.types";

export enum MinibankTypes {
    "ESC" = "ESC",
    "SEC" = "SEC",
    "DEBENTURE" = "DEBENTURE",
    "STARTER" = "STARTER",
}

export interface Minibanco {
    id: number;
    cnpj: string;
    razaoSocial: string;
    nomeFantasia?: string;
    tipo: MinibankTypes;
    tipoNome?: string;
}
interface Empresa {
    id: number;
    cnpj: string;
    razaoSocial: string;
    nomeFantasia?: string;
}
export interface UserSchema {
    avatar?: any;
    id: number;
    email: string;
    firstName?: string;
    lastName?: string;
    isAdmin: boolean;
}

export interface User {
    companyId?: string;
    user: UserSchema;
    minibanco?: Minibanco;
    empresa?: Empresa;
    iat: number;
    exp: number;
    permissions: Array<string>;
    roles: Array<string>;
}
