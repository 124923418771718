import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UnoperatedPayablesFilter } from "app/modules/admin/v2/operation/create/components/repurchase-payables-modal/interfaces/unoperated-payables";
import { RepurchasePayableService } from "app/modules/admin/v2/operation/create/service/repurchase-payable.service";

@Component({
    selector: "RepurchaseFilterModal",
    templateUrl: "./repurchase-filter-modal.component.html",
})
export class RepurchaseFilterModalComponent implements OnInit {
    public payableTypes = [
        {
            value: "CHEQUE",
            name: "Cheque",
        },
        {
            value: "DUPLICATA",
            name: "Duplicata",
        },
        {
            value: "NOTA_PROMISSORIA",
            name: "Nota Promissória",
        },
    ];
    public loading = false;
    public inputs: UnoperatedPayablesFilter = {
        payableNumber: "",
        payerName: "",
        payableType: "",
    };
    public filterForm: FormGroup;

    @Output() filterEmitter = new EventEmitter<UnoperatedPayablesFilter>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: UnoperatedPayablesFilter,
        private readonly dialogRef: MatDialogRef<RepurchaseFilterModalComponent>,
        private readonly formBuilder: FormBuilder,
        private readonly repurchasePayableService: RepurchasePayableService,
    ) {}

    ngOnInit(): void {
        this.filterForm = this.formBuilder.group({
            payableNumber: [this.data.payableNumber],
            payerName: [this.data.payerName],
            payableType: [this.data.payableType],
        });
    }

    async applyFilters() {
        const isEmpty = Object.values(this.filterForm.value).every((value) => value === "");
        if (isEmpty) {
            return;
        }
        await this.handleFilterChange();
        this.filterEmitter.emit(this.filterForm.value);
        this.close();
    }

    close() {
        this.dialogRef.close();
    }

    async resetFilters() {
        this.filterForm.reset();
        await this.handleFilterChange();
        this.filterEmitter.emit(this.filterForm.value);
    }

    async handleFilterChange() {
        this.loading = true;
        const assignorId = this.repurchasePayableService.getAssignorId;
        const response = await this.repurchasePayableService.listUnoperatedPayables(assignorId, {
            ...this.filterForm.value,
            page: 1,
            take: 10,
        });
        this.repurchasePayableService.setUnoperatedPayables = response;
        this.loading = false;
        return response;
    }
}
