import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApiService } from "app/core/api/api.service";
import { ErrorMessage } from "app/shared/constants/messages.constant";
import { IRevenueServiceResponse } from "app/shared/interfaces/revernue-service-response.interface";
import { cnpj, cpf } from "cpf-cnpj-validator";

@Injectable({ providedIn: "root" })
export class RevenueService {
    constructor(private readonly http: ApiService, private readonly snack: MatSnackBar) {}

    async getInformationFromDocument(document: string): Promise<IRevenueServiceResponse> {
        const isDocumentValid = cpf.isValid(document) || cnpj.isValid(document);
        if (!isDocumentValid) return;

        try {
            const res = await this.http.post("bff", "/revenue-service", { document }).toPromise();
            if (!res.data) return null;
            if (!res.data.companyName) res.data.companyName = res.data.name;
            if (res.data?.representative?.companyEntryDate) {
                const { companyEntryDate } = res.data.representative;
                res.data.representative.companyEntryDate = companyEntryDate.split("-").reverse().join("-");
            }

            return res.data;
        } catch {
            this.snack.open(ErrorMessage.SERVER_CONNECTION_ERROR, "x", {
                duration: 3000,
                panelClass: ["snack-bar-error"],
            });
        }
    }
}
