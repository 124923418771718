import { MatPaginatorIntl } from "@angular/material/paginator";

const brRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} de ${length}`;
};

export const getBRPaginatorIntl = () => {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = "Items por página:";
    paginatorIntl.nextPageLabel = "Próxima página";
    paginatorIntl.previousPageLabel = "Voltar página";
    paginatorIntl.firstPageLabel = "Primeira página";
    paginatorIntl.lastPageLabel = "Última página";
    paginatorIntl.getRangeLabel = brRangeLabel;

    return paginatorIntl;
};
