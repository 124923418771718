import { PixKeyTypeEnum } from "../constants/pix-key-type.enum";

export interface IPixKeyType {
    name: string;
    value: string;
}

export const pixKeyTypes: Array<IPixKeyType> = [
    {
        name: "CPF",
        value: PixKeyTypeEnum.CPF,
    },
    {
        name: "CNPJ",
        value: PixKeyTypeEnum.CNPJ,
    },
    {
        name: "Email",
        value: PixKeyTypeEnum.EMAIL,
    },
    {
        name: "Celular",
        value: PixKeyTypeEnum.PHONE,
    },
    {
        name: "Chave aleatória",
        value: PixKeyTypeEnum.RANDOM_KEY,
    },
];

export interface IBank {
    codeBank: string;
    shortName: string;
    longName: string;
}

export interface IBankData {
    bank: string;
    account: number;
    accountDigit: number;
    agency: number;
    agencyDigit: number;
    pix: string;
    pixKeyType: string;
}
