import { Component, EventEmitter, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IRevenueServiceResponse } from "app/shared/interfaces/revernue-service-response.interface";

@Component({
    selector: "revenue-data-confirmation-modal",
    templateUrl: "./revenue-data-confirmation.component.html",
})
export class RevenueDataModalComponent {
    public info: IRevenueServiceResponse;

    public confirmEmitter = new EventEmitter();
    public cancelEmitter = new EventEmitter();

    constructor(@Inject(MAT_DIALOG_DATA) private data: IRevenueServiceResponse) {
        this.info = data;
    }

    confirm() {
        this.confirmEmitter.emit();
    }

    cancel() {
        this.cancelEmitter.emit();
    }
}
