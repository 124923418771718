import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "app-confirm-close-modal",
    templateUrl: "./confirm-type-payable-modal.html",
    styleUrls: ["./confirm-type-payable-modal.scss"],
})
export class ConfirmTypePayableModalComponent {
    constructor(public dialogRef: MatDialogRef<ConfirmTypePayableModalComponent>) {}

    close(value: string) {
        this.dialogRef.close(value);
    }
}
