import { Injectable } from "@angular/core";
import { ApiService } from "app/core/api/api.service";
import { CreatePayableInput } from "./interfaces/create-payable-input.interface";

@Injectable()
export class UploadBankCheckModalService {
    constructor(private apiService: ApiService) {}

    async uploadCMC7(files: File): Promise<{ cmc7: string }> {
        try {
            const { body } = await this.apiService
                .makeFileRequest("bff", "/file/ocr/upload", [files], "file")
                .toPromise<any>();
            return body as { cmc7: string };
        } catch {
            throw new Error("Ocorreu um erro ao comunicar com o servidor.");
        }
    }

    createPayable(data: CreatePayableInput) {
        return this.apiService.post("crm", "/recebivel", data);
    }
}
