import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "NewValueModal",
    templateUrl: "./new-value-modal.component.html",
})
export class NewValueModalComponent implements OnInit {
    private fb: FormBuilder;
    public valueForm: FormGroup;

    constructor(formBuilder: FormBuilder) {
        this.fb = formBuilder;
    }

    @Output() cancelEmitter = new EventEmitter();
    @Output() confirmEmitter = new EventEmitter();

    ngOnInit() {
        this.valueForm = this.fb.group({
            value: [0, [Validators.required, Validators.min(0.01)]],
        });
    }

    updateValue() {
        if (!this.valueForm.valid) return;
        const value = this.valueForm.controls.value.value;

        this.confirmEmitter.emit(value);
    }
}
