<!-- eslint-disable prettier/prettier -->
<ng-container *ngIf="!isExpandable; else expandableMenu">
    <!-- Button -->
    <button mat-icon-button [matMenuTriggerFor]="userActions">
        <span class="relative">
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'" class="w-8 h-8 text-blue-700"></mat-icon>
        </span>
    </button>

    <!-- Menu -->
    <mat-menu [xPosition]="'before'" #userActions="matMenu">
        <button mat-menu-item>
            <span class="flex flex-col leading-none">
                <span>{{
                    user.minibanco?.nomeFantasia ? user.minibanco?.nomeFantasia : user.minibanco?.razaoSocial
                }}</span>
                <span class="mt-1.5 text-md font-medium">{{ user?.user?.email }}</span>
            </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item routerLinkActive="router-link-active" (click)="redirectToAccount()">
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>Minha conta</span>
        </button>

        <button mat-menu-item [claim]="['usuarios:read']" (click)="redirectToAccounts()">
            <mat-icon svgIcon="heroicons_outline:user-add"></mat-icon>
            <span>Usuários</span>
        </button>

        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="signOut()">
            <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
            <span>Sair</span>
        </button>
    </mat-menu>
</ng-container>

<ng-template #expandableMenu>
    <div class="pb-4">
        <div class="flex gap-2 justify-between cursor-pointer" (click)="isExpanded = !isExpanded">
            <div class="flex gap-2 items-center">
                <mat-icon [svgIcon]="'heroicons_outline:user-circle'" class="text-blue-600 icon-size-5"></mat-icon>
                <span class="text-gray-800 text-lg">Perfil</span>
            </div>
            <mat-icon
                class="text-blue-600 icon-size-5"
                [svgIcon]="isExpanded ? 'heroicons_outline:chevron-up' : 'heroicons_outline:chevron-down'"
            ></mat-icon>
        </div>
        <div
            id="expandableMenu"
            class="flex flex-col justify-start mt-2 ml-5 gap-2 overflow-hidden"
            [ngClass]="isExpanded ? 'expanded' : ''"
        >
            <Button
                routerLinkActive="router-link-active"
                [routerLink]="['/conta']"
                class="flex justify-start p-2 menu-item-hover hover:text-black rounded"
            >
                <span
                    class="text-gray-700 text-lg"
                    [ngClass]="router.url === '/conta' ? 'text-blue-700 font-bold' : ''"
                >
                    Minha conta
                </span>
            </Button>
            <button
                routerLinkActive="router-link-active"
                [routerLink]="['/usuarios']"
                class="flex justify-start p-2 menu-item-hover hover:text-black rounded"
                [claim]="['usuarios:read']"
            >
                <span
                    class="text-gray-700 text-lg"
                    [ngClass]="router.url === '/usuarios' ? 'text-blue-700 font-bold' : ''"
                >
                    Usuários
                </span>
            </button>

            <button (click)="signOut()" class="text-red-600 flex items-center gap-2 p-2 hover:bg-red-100">
                <mat-icon [svgIcon]="'heroicons_outline:logout'" class="icon-size-5 text-red-600"></mat-icon>
                <span class="font-bold text-lg">Sair</span>
            </button>
        </div>
    </div>
</ng-template>
