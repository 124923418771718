export enum FeesEnum {
    FEE_PER_MONTH = "feePerMonth",
    TAC = "tac",
    TED = "ted",
    DIGITAL_SIGNATURE_COST = "digitalSignatureCost",
    CREDIT_ANALYSIS = "creditAnalysis",
    PIX = "pix",
    INVOICES = "invoices",
    CHECK_CUSTODY = "checkCustody",
}
