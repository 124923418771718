import { Injectable } from "@angular/core";
import { FetchService } from "app/core/api/fetch.service";

@Injectable({ providedIn: "root" })
export class NewCTEDueDateService {
    constructor(private readonly fetch: FetchService) {}

    updatePayableDueDate({ date, payableId }: { payableId: number; date: Date | string }) {
        return this.fetch.put("crm", `/recebivel/${payableId}`, {
            dtVencimento: date,
        });
    }
}
