/* eslint-disable @typescript-eslint/no-unsafe-call */
import { combineLatest, Observable, of, Subject } from "rxjs";
import { takeUntil, startWith, debounceTime, switchMap, map, tap } from "rxjs/operators";
import { fuseAnimations } from "@fuse/animations";
import { IFormBuilder, IFormGroup, IFormArray } from "@rxweb/types";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Cedente } from "app/modules/admin/v1/cedente/interfaces/cedente.types";
import { UserService } from "app/core/user/user.service";
import { ClientesService } from "app/modules/admin/v1/cedente/cedente.service";
import { Payable, Sacado } from "app/modules/admin/v1/operacoes/operacoes.types";
import { CreatePayablesModalService } from "./create-payables-modal.service";
import { EnvioNotasModalService } from "../xml-modal/envio-notas-modal.service";
import { CreatePayerModalComponent } from "app/modules/admin/v1/payer/payer-create/payer-create-modal/payer-create-modal.component";
import { IPayablesFormArray } from "./interfaces";
import moment from "moment";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { validateCmc7 } from "app/utils/cmc7-validator";

@Component({
    selector: "app-create-payables-modal",
    templateUrl: "./create-payables-modal.component.html",
    animations: fuseAnimations,
})
export class CreatePayablesModalComponent implements OnInit, OnDestroy {
    private unsubscribeAll = new Subject();

    total = 0;
    loading = false;
    disabled = false;
    dataSource = [];
    assignor: Partial<Cedente>;
    minDate = new Date();
    createPayableForm: IFormGroup<any>;
    installments: IFormArray<any>;
    formBuilder: IFormBuilder;
    animation: boolean = true;
    payables = [];
    payers = [];
    filteredPayers$: Observable<any>;
    payableType: string;
    hasCmc7 = false;
    installmentForm: FormGroup;
    selectedPayer;

    constructor(
        public dialog: MatDialog,
        formBuilder: FormBuilder,
        private snack: MatSnackBar,
        private userService: UserService,
        private cedenteService: ClientesService,
        private sendNFsModalService: EnvioNotasModalService,
        private createPayablesModalService: CreatePayablesModalService,
        @Inject(MAT_DIALOG_DATA) public data: { assignor: Cedente; payableType: string },
    ) {
        this.formBuilder = formBuilder;
    }

    ngOnInit() {
        this.assignor = this.data.assignor;
        this.payableType = this.data.payableType;
        this.hasCmc7 = this.payableType === "CHEQUE";

        this.sendNFsModalService.getPayables$.subscribe((payables) => (this.payables = payables));

        this.sendNFsModalService.getUpdatePayables$.subscribe((id) => {
            this.addSelectedPayableId(false, { id });
        });

        this.createPayableForm = this.formBuilder.group<{
            minibanco: string;
            cedente: string;
            sacado: Sacado;
            parcelas: IPayablesFormArray[];
        }>({
            minibanco: [{ value: this.userService.user$.minibanco.razaoSocial, disabled: true }, Validators.required],
            cedente: [{ value: this.assignor.razaoSocial, disabled: true }, Validators.required],
            sacado: [null, Validators.required],
            parcelas: this.formBuilder.array<IPayablesFormArray>([]),
        });

        this.sumInstallments();
        this.watchPayerAutocomplete();
        this.addInstallment();
    }

    addSelectedPayableId(event, parcela: Partial<Payable>) {
        if (event) {
            this.sendNFsModalService.setPayables = [...this.payables, parcela];

            return;
        }

        this.sendNFsModalService.setPayables = this.payables.filter((payables) => payables.id !== parcela.id);
    }

    get installmentsFormArray() {
        return this.createPayableForm.get("parcelas") as FormArray;
    }

    watchPayerAutocomplete() {
        this.cedenteService.sacados$.subscribe((res) => {
            this.payers = res;
        });

        this.filteredPayers$ = this.createPayableForm.get("sacado").valueChanges.pipe(
            takeUntil(this.unsubscribeAll),
            startWith(""),
            debounceTime(300),
            switchMap((value) => {
                if (value !== "") {
                    return this.cedenteService
                        .searchSacados(this.cleanValueString(value).toLowerCase())
                        .pipe(map((result) => result.data));
                } else {
                    return of(this.payers);
                }
            }),
        );
    }

    cleanValueString(value: number | string): string {
        return String(value).replaceAll(/[-./]/g, "");
    }

    sumInstallments() {
        this.createPayableForm
            .get("parcelas")
            .valueChanges.pipe(takeUntil(this.unsubscribeAll))
            .subscribe((installments: { valorDaParcela: string; vencimento: Date }[]) => {
                this.total = installments.reduce((acc, curr) => Number(acc) + Number(curr.valorDaParcela), 0);
            });
    }

    deleteInstallment(index: number) {
        this.installmentsFormArray.removeAt(index);
    }

    addInstallment() {
        this.installmentForm = this.formBuilder.group({
            valorDaParcela: ["", [Validators.required, Validators.min(0.01), Validators.max(1_000_000_000)]],
            vencimento: ["", Validators.required],
            payableNumber: ["", Validators.required],
            cmc7: ["", this.hasCmc7 ? [Validators.required, validateCmc7] : null],
        });

        this.installmentsFormArray.push(this.installmentForm);
    }

    displayName(value): string {
        return value && value.razaoSocial;
    }

    createPayable() {
        this.loading = true;

        if (this.createPayableForm.invalid) {
            this.loading = false;

            this.snack.open("Preencha todos os campos corretamente !", "X", {
                duration: 3000,
            });

            return;
        }

        const recebs = this.installmentsFormArray.value.map((recebivel: any) => {
            const { sacado } = this.createPayableForm.value;
            const { valorDaParcela, vencimento, payableNumber, cmc7 }: IPayablesFormArray = recebivel;

            const data = {
                cedenteId: this.assignor.id,
                sacadoId: sacado.id,
                minibancoId: this.userService.user$.minibanco.id,
                dtVencimento: vencimento,
                dtEmissao: new Date(),
                payableType: this.data.payableType,
                valor: parseFloat(valorDaParcela),
                payable_number: payableNumber,
                cmc7,
            };

            return this.createPayablesModalService.createRecebivel(data).pipe(tap((res) => res));
        });

        combineLatest(recebs)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(
                (res) => {
                    this.loading = false;

                    const partialPayableInfo: any = res.map((payable: Payable) => {
                        const {
                            sacado: { razaoSocial, document },
                            valor,
                            dtVencimento,
                            id,
                        } = payable;

                        return {
                            id,
                            sacado: { razaoSocial, document },
                            valor,
                            dtVencimento: moment(dtVencimento).add(1, "days").format("DD/MM/YYYY"),
                        };
                    });

                    this.sendNFsModalService.setPayables = [...this.payables, ...partialPayableInfo];

                    this.snack.open(
                        `${recebs.length > 1 ? "Recebíveis criados" : "Recebível criado"} com sucesso !`,
                        "X",
                        { panelClass: "snack-bar-success", duration: 5000 },
                    );

                    this.dialog.closeAll();
                },
                (err) => {
                    this.loading = false;

                    this.snack.open(err.error.message, "Ok", {
                        panelClass: ["mat-toolbar", "mat-warn"],
                        duration: 3500,
                    });
                },
            );
    }

    newPayer() {
        const addPayerDialog = this.dialog.open(CreatePayerModalComponent, {
            width: "80vw",
            height: "80vh",
            id: "dialog-new-payer",
        });

        addPayerDialog.componentInstance.cancelEmitter.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
            addPayerDialog.close();
        });

        addPayerDialog.componentInstance.afterSaveEmitter.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
            addPayerDialog.close();
        });

        addPayerDialog.componentInstance.createdPayerEmitter
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((createdPayer) => {
                this.createPayableForm.controls.sacado.setValue(createdPayer);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    onPayerSelected(event: MatAutocompleteSelectedEvent) {
        const selectedPayer = event.option.value;

        this.createPayableForm.controls.sacado.setValue(selectedPayer);
    }

    onBlurPayerInput() {
        const selectedPayer = this.createPayableForm.controls.sacado.value;

        if (typeof selectedPayer === "string") {
            this.createPayableForm.controls.sacado.setValue(null);
        }
    }
}
