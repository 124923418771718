import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NewValueModalComponent } from "./new-value-modal.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NgxCurrencyModule } from "ngx-currency";
import { StandardButtonModule } from "../../Button/button.module";
import { NgxMaskModule } from "ngx-mask";
import { MatInputModule } from "@angular/material/input";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        NgxCurrencyModule,
        NgxMaskModule.forRoot(),
        StandardButtonModule,
        MatInputModule,
    ],
    declarations: [NewValueModalComponent],
    exports: [NewValueModalComponent],
})
export class NewValueModalModule {}
