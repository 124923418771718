<div
    [ngClass]="{ 'border-blue-300': isOpen, 'border-transparent': !isOpen }"
    class="rounded-full shadow-md flex z-99 overflow-hidden bg-card border"
>
    <div
        [ngClass]="{ 'bg-white text-blue-600': isOpen, 'bg-blue-400 text-white': !isOpen }"
        class="w-10 min-h-10 flex-shrink-0 flex items-center justify-center cursor-pointer"
        (click)="toggle()"
    >
        <mat-icon [ngClass]="{ 'text-blue-600': isOpen, 'text-white': !isOpen }" class="icon-size-5">
            {{ icon }}
        </mat-icon>
    </div>
    <div
        [ngClass]="{
            'py-2 px-4 w-auto': isOpen,
            'w-0 h-0 overflow-hidden p-0': !isOpen
        }"
        class="transition-all duration-300 ease-in-out"
    >
        <ng-content></ng-content>
    </div>
    <button *ngIf="isOpen" class="text-gray-700 pl-2 pr-4 text-lg" (click)="toggle()">X</button>
</div>
